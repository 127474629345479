import { ModalPrimaryButton, ModalSecondaryButton } from "../../../../components/Button/Button";
import ModalCompo from "../../../../design/ModalCompo";
import { ActivateApi,} from "./employeeAction";
import classes from './employee.module.css';
import Modal from "../../../../components/Modal/Modal";
import viewClass from "../../../../services/ViewRole";

function ActiveModal({modal,setModal,refresh}){

   const ActiveHandler=()=>{

    console.log("delete Cnfm",modal);
    let param={userId:modal?.id}
    ActivateApi(param,(res)=>{
        if(res){
          setModal();
          refresh();
        }
    })
   }
    return(
        <>
        <Modal show={modal!==undefined} closableRight={true} onCancel={()=>{setModal();}}
       style={{width:'70vw'}}
        header={<div style={{color:'#0086FF',width:"100%"}} className={classes.headerApprover}>Activate Profile</div>}
         className={classes.ActiveModal} >
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'1rem',flexDirection:'column'}}>
            <div style={{fontWeight:"500",color:'#162432'}}>Are you sure to Activate <span style={{color:'#0086FF'}}>"{modal?.name}"</span> Profile ?</div>
            <div style={{display:'flex',gap:'1rem',justifyContent:'center',marginTop:'2rem',marginBottom:'1rem'}}>
            <ModalPrimaryButton onSave={()=>{ActiveHandler();}} disabled={viewClass?.hrAssociate} >YES</ModalPrimaryButton>
            <ModalSecondaryButton  onCancel={()=>{setModal()}}>NO</ModalSecondaryButton>
            </div>
        </div>
        
        </Modal>
        </>
    )       
}
export default ActiveModal;