import TableCustom from "../../../components/tableCustom/customTable";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import { NoData } from "../../Training_essentialDocuments";
import { MobileCard } from "../Release/Release";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';



const ListViewTeamData = ({ ids, secLoader }) => {
    const { width } = useWindowDimensions();
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 30,
            }}
            spin
        />
    );

    const column = {
        0: {
            cols: "Associate",
            width: "28%",

            dataKey: "headc",
            onclick: () => { }
        },
        1: {
            cols: "Contact Details",
            width: "30%",
            innerWidth: "5rem",
            dataKey: "contactDetails",
            onclick: () => { }
        },
        2: {
            cols: "Designation",
            width: "18%",
            innerWidth: "5rem",
            dataKey: "designation",
            onclick: () => { }
        },
        3: {
            cols: "Status",
            width: "18%",
            dataKey: "statusC",
            onclick: () => { }
        },
        4: {
            cols: "Planned Hours",
            width: "14%",
            dataKey: "plannedhours",
            onclick: () => { }
        },
        5: {
            cols: "Team",
            width: "18%",
            dataKey: "teamName",
            onclick: () => { }
        },
        6: {
            cols: "Actions",
            width: "14%",
            dataKey: "actions",
            onclick: () => { }
        },
    }
    return (
        <>
            {
                width < 700 ?
                    <>

                        {
                            secLoader ?
                                <div style={{ display: "flex", height: "80vh", width: "100%", justifyContent: "center", alignItems: "center" }}>
                                    <Spin indicator={antIcon} />
                                </div>
                                :
                                ids?.list?.length ?
                                    <>
                                        {
                                            ids?.list?.map((item) => (
                                                <MobileCard
                                                    body={
                                                        (
                                                            <>
                                                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                    <div style={{ width: "30%", color: "#B1B0B0" }}>Email : </div>
                                                                    <div style={{ width: "60%", display: "flex", justifyContent: "flex-start", textOverflow: "ellipsis", overflow: 'hidden', }}>{item?.email}</div>
                                                                </div>
                                                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                    <div style={{ width: "30%", color: "#B1B0B0" }}>Phone : </div>
                                                                    <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.phone}</div>
                                                                </div>
                                                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                    <div style={{ width: "30%", color: "#B1B0B0" }}> Designation: </div>
                                                                    <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.designation}</div>
                                                                </div>
                                                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                    <div style={{ width: "30%", color: "#B1B0B0" }}>
                                                                        Status : </div>
                                                                    <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.statusC}</div>
                                                                </div>
                                                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                    <div style={{ width: "30%", color: "#B1B0B0" }}>Planned Hours : </div>
                                                                    <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.calculation?.plannedHour}</div>
                                                                </div>
                                                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                    <div style={{ width: "30%", color: "#B1B0B0" }}>Team : </div>
                                                                    <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.teamName}</div>
                                                                </div>

                                                            </>
                                                        )
                                                    }
                                                    headerRight={item?.actions}
                                                    headerLeft={item?.headc} />

                                            ))
                                        }
                                    </>
                                    :
                                    <>
                                        <NoData text={ids?.list === undefined?"Choose your Department & Team to continue!":!ids?.teamIds?.length?"Choose your Department & Team to continue!":!ids?.list?.length?"No Data":"Choose your Department & Team to continue!"}/>
                                    </>
                        }
                    </>
                    :
                    <>
                        <TableCustom
                            columns={column}
                            dataSource={ids?.list}
                            loading={secLoader}
                            EmptyText={ids?.list === undefined?"Choose your Department & Team to continue!":!ids?.teamIds?.length?"Choose your Department & Team to continue!":!ids?.list?.length?"No Data":"Choose your Department & Team to continue!"}
                            // EmptyText={((ids?.list!==undefined && ids?.list?.length) && ids?.teamIds?.length)?"No Data":"Choose your Department & Team to continue!"}
                        /*   pagination={
                              {
                              total: parseInt(associateInfo?.meta?.totalItems),
                              current: parseInt(paginationPage),
                              showSizeChanger: true,
                              pageSizeOptions: [10,20, 30, 50, 100],
                              pageSize: pageSize,                
                              onChange: (page,pageSize) =>{
                                  setPaginationPage(page);
                                  setPageSize(pageSize);
                              }, 
                              }} */
                        />
                    </>
            }

        </>
    )
}


export default ListViewTeamData;
