import React, { useState } from "react";
import dashboardIcon from "../../assets/Dashboard.svg";
import dashboardInactive from "../../assets/dashboard_inactive.svg"
import employeesActiveTab from "../../assets/employeesActive.svg";
import employeesInactiveTab from "../../assets/employessInactive.svg";
import department_grey_icon from '../../assets/Department_grey_icon.svg';
import department_blue_icon from '../../assets/department_blue_icon.svg';
import leave_grey_icon from '../../assets/leave_grey_icon.svg';
import leave_blue_icon from '../../assets/leave_blue_icon.svg';
import logs_grey_icon from "../../assets/logs_grey_icon.svg";
import logs_blue_icon from "../../assets/logs_blue_icon.svg";
import release_grey_icon from "../../assets/release_grey_icon.svg";
import release_blue_icon from "../../assets/release_blue_icon.svg";
import project_grey_icon from "../../assets/project_grey_icon.svg";
import project_blue_icon from "../../assets/Project_blue_icon.svg";
import migrate_manager_grey_icon from '../../assets/migrate_manager_grey_icon.svg';
import migrate_manager_blue_icon from '../../assets/migrate_manager_blue_icon.svg';

import "../../design/framework.css";
import SiderLayout from "../../components/siderLayout";
import { useEffect } from "react";
import viewClass from "../../services/ViewRole";

function ProjectMainPage() {
  const [navUrl,setNavUrl]=useState([
    {
    key:1,
    title:"Dashboard",
    url:"dashBoard",
    inactive:dashboardInactive,
    active:dashboardIcon,
  },
  {
    key:2,
    title:"Department",
    url:"department",
    inactive:department_grey_icon,
    active:department_blue_icon,
  },
  {
    key:3,
    title:"Teams",
    url:"teams",
    inactive:employeesInactiveTab,
    active:employeesActiveTab,
  },
  {
    key:4,
    title:"Projects & Tasks",
    url:"projects",
    inactive:project_grey_icon,
    active:project_blue_icon,
  },
  {
    key:5,
    title:"Logs",
    url:"logs",
    inactive:logs_grey_icon,
    active:logs_blue_icon,
  },
  {
    key:6,
    title:"Leave",
    url:"leave",
    inactive:leave_grey_icon,
    active:leave_blue_icon,
  },
  {
    key:7,
    title:"Release",
    url:"release",
    inactive:release_grey_icon,
    active:release_blue_icon,
  },

  {
    key:8,
    title:"Migrate Manager",
    url:"migrate_manager",
    inactive:migrate_manager_grey_icon,
    active:migrate_manager_blue_icon,
  },
])
const [data,setData]=useState([])
useEffect(()=>{
  viewClass.updateView();
  if(viewClass.superAdmin){
    setData(navUrl)
  }
  else if(viewClass.hod ){
    let restrict=[2,8];
 setData(navUrl.filter((val)=>(!restrict.includes(val?.key))))
  }
  else if(viewClass.manager){
    let restrict=[2,7,8];
    setData(navUrl.filter((val)=>(!restrict.includes(val?.key))))
  }
 else if(viewClass.hrAdmin || viewClass.hrAssociate || viewClass.associate){
    let restrict=[2,3,7,8];
    setData(navUrl.filter((val)=>(!restrict.includes(val?.key))))
  }
},[window.location.href,navUrl])

  return (
    <SiderLayout navList={data}/>
  );
}

export default ProjectMainPage;