
import React, { useEffect, useState } from "react";
import classes from './DeptRecommendModal.module.css';

import { DatePicker, Modal, Spin } from "antd";
import moment from "moment";
import { useFPStore } from "../store";
import { filterQuestions } from "../Action";
import loader from "../../Appraisal/store/loader";
import { NoData } from "./NoData";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";


const DeptRecommendModal=({showModal,setShowModal,data,})=>{
     const [dateMonth,setDateMonth]=useState(moment().format('YYYY-MM'));
    const {filteredQuestionsHandler,filteredQuestions}=useFPStore();
    const [loader,setLoader]=useState(true);
    const {width}=useWindowDimensions();
    const basicInfo=[
        {
            key:"Department:",
            value:data?.teamDetails?.departmentName?data?.teamDetails?.departmentName:"NA",
        },
        {
            key:"Manager:",
            value:data?.teamDetails?.managerName?data?.teamDetails?.managerName:"NA",
        },
        {
            key:"Team:",
            value:data?.teamDetails?.teamName?data?.teamDetails?.teamName:"NA",
        },
        {
            key:"Office Location:",
            value:"Bangalore",
        },
        {
            key:"Email ID:",
            value:data?.email,
        },
        {
            key:"Mobile number:",
            value:data?.phone,
        },
    ]
    useEffect(()=>{
        if(dateMonth&&data?.uid)
        {
            setLoader(true);
            filterQuestions((res)=>{
                filteredQuestionsHandler(res);
                setLoader(false);
            },data?.uid,dateMonth,false)
       }
        
    }
    ,[dateMonth,data?.userDetails?.uid])


    return(
        <Modal
        centered
        closable={false}
        footer={false}
        open={showModal?.feedback}
         onCancel={()=>{
            setShowModal({feedback:false,recommend:false});
        }}
        width={width>800?"50vw":"100vw"}
        bodyStyle={{display:"flex",alignItems:"center",justifyContent:"center",}}
        >
            <section className={classes.modalContainer}>
                <div className={classes.infoDiv}>
                    <div className={classes.nameDiv}>
                            <span>{data?.name}</span>
                            <span>{data?.uid}</span>
                            <span>{data?.designation}</span>
                    </div>
                    <div className={classes.basicInfo}>
                        {basicInfo?.map((item,index)=>{
                            return(
                                <div key={index} className={classes.eachInfo}>
                                    <span>{item?.key}</span>
                                    <span>{item?.value}</span>
                                </div>
                            )
                        })}

                    </div>

                </div>
                <div className={classes.datePicker}>
                <DatePicker style={{height:"2.3rem",marginRight:"1rem"}}  placeholder={`${moment().format('YYYY-MM')}`} picker="month" onChange={(date,datestring)=>{
                        if(datestring)
                        {
                            setDateMonth(datestring)
                        }
                        else
                        {
                            setDateMonth(moment().format('YYYY-MM'));
                        }
                       
                        }}/>
                </div>
                {loader?
                <Spin style={{height:"20vh",alignItems:"center",display:"flex",justifyContent:"center"}}></Spin>
                :<div className={classes.questionDiv}>
                    {
                        (filteredQuestions?.questions?.length===0||!filteredQuestions)?
                       
                        <NoData/>
                       
                    :filteredQuestions?.questions?.map((item,index)=>{
                        return(
                            <div key={index} className={classes.eachQuestion}>                     
                                <span>{index+1+". "+item?.question}</span>
                                <span>{"A."+(item?.inputType==="Multiple Choice"?item?.answer?.answers:item?.answer)}</span>
                            </div>
                        )
                    })}
                </div>}
            </section>

        </Modal>
    )
}

export default DeptRecommendModal;