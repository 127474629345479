import React from "react";
import Leave from "./leave";
import AssociateSummary from "./AssociateSummary";
import useStore from "./store";

const Main = ()=>{
    const {summaryModalOpen,leaveModalOpen} = useStore(state=>state)

    return(
        <div>
        {leaveModalOpen ?
             <Leave />
        : summaryModalOpen &&
            <AssociateSummary />
        }
        </div>
    )
}
export default Main;