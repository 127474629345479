import React, { useEffect, useState } from "react";
import { Input, Button, Select, Form, Upload, Radio } from "antd";
import classes from "./feedback.module.css";
import UploadIcon from "../../assets/uploadIcon.svg";
import Api from "../../services/Api";
import Notification from "../../services/Notification";
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "./store";
import Modal from "../../components/Modal/Modal";
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";

const CreateFeedbackModal = (props) => {
  const {openEditFeedbackModal,createModal,updateCreateModal,feedbackData,feedbackSaveState,updateFeedbackData,
    selectCardCount,UpdateFeedbackPage,updateFeedbackSaveState,updateFeedbackAddQuestions,updateEditFeedbackModal,selectedCard}=useStore();
    console.log("feedbackDatafeedbackData",feedbackData)
  const [imgFile, setImgFile] = useState("");
  const [imgFileName, setImgFileName] = useState("");
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [feedbackImage, setFeedbackImage] = useState("");
  const [form]=Form.useForm();
  const location = useLocation();
  const initialImageUrl = feedbackData?.feedbackImage || "";
  const { state } = location;
  const [activeRadio,setActiveRadio]=useState();
  const {width}=useWindowDimensions();

  useEffect(() => {
    if (feedbackData !== null) {
      setTitle(feedbackData?.title);
      setDescription(feedbackData?.description)
      setFeedbackImage(feedbackData?.feedbackImage);
      setActiveRadio(feedbackData?.monthlyFeedback)
    }
  }, [feedbackData]);

  const imgbeforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isJpgOrPng) {
      Notification.error("You can only upload JPG/PNG file!");
      setImgFile("");
    } 
    else if (!isLt5M) {
      Notification.error("Image must smaller than 5MB!");
      setImgFile("");
    } 
    else {
      getBase64(file, (url) => {      
        setImgFileName(file.name.split(".")[0]);
        setImgFile(file);
      });
    }
    return false
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  console.log("sssFeedbackData",feedbackData,)

  const onFinish=(values)=>{
        if(values?.name.trim() === ''){
          Notification.error("Error","Please enter a name")
        }
        else if(values?.description.trim() === ''){
          Notification.error("Error","Please enter a description")
        }
        else if(selectCardCount > 0 && feedbackSaveState !== "edit"){
          let paramsValue = {
            title:values.name,
            qping:imgFile,
            description:values.description,
            clone_data_id: selectedCard,
            monthlyFeedback:values?.monthlyFeedback,
          };
          Api.post('/resource-feedback/clone')
          .upload(paramsValue, (per,response) => { 
           if (response!==undefined && per===100) {    
            if(response?.data?.show?.type === "success"){      
             let data = {
               feedbackId: response?.data?.show?.id,
               title: response?.data?.show?.title,
               description:response?.data?.show?.description,
               feedbackImage: response?.data?.show?.feedbackImage,
               monthlyFeedback:response?.data?.show?.monthlyFeedback,
             };
             updateFeedbackData(data);  
            //  updateCreateFeedbackModal(false)        
             updateCreateModal(false)
             UpdateFeedbackPage(true)  
             navigate("/fbRound")
             setImgFile("");
             setImgFileName("");    
             setTitle("");
             setDescription("");  
           }}
         })     
        }
        else if(feedbackSaveState === "create"){
          let params = {
            title:values.name,
            qping:imgFile,
            description:values.description,
            monthlyFeedback:values?.monthlyFeedback,
          };
           Api.post('/resource-feedback/createFeedBackData')
           .upload(params, (per,response) => { 
            console.log("sssuploadResponse",response,per)
            if(response?.data?.show?.type === "success"){             
            if (response!==undefined && per===100) {   
              let data = {
                feedbackId: response?.data?.show?.id,
                title: response?.data?.show?.title,
                feedbackImage: response?.data?.show?.feedbackImage,
                monthlyFeedback:response?.data?.show?.monthlyFeedback,
              };
              updateFeedbackData(data);          
              updateCreateModal(false)
              UpdateFeedbackPage(true)  
              navigate("/fbRound")
              setImgFile("")
              setImgFileName("")      
              setTitle("");
              setDescription("");    
            }}
          })        
        }
        else if(feedbackData !== null && feedbackSaveState === "edit"){
          let paramsData = {}
          if(imgFile === ""){
            paramsData={title:values.name,
            // qping: imgFile,
            description:values.description,
            feedback_data_id: feedbackData.feedbackId,
            monthlyFeedback:values.monthlyFeedback,
            }
          }
          else{

            paramsData={title:values.name,
              qping: imgFile,
              description:values.description,
              feedback_data_id: feedbackData.feedbackId,
              monthlyFeedback:values.monthlyFeedback,
            }
          }
          Api.patch('/resource-feedback/updateFeedBackData')
          .uploadAndPatch(paramsData, (per,response) => { 
          //  console.log("sssuploadResponse",response,per)
          if (response!==undefined && per===100) {       
           if(response?.data?.show?.type === "success"){         
            let data = {
              feedbackId: response?.data?.show?.id,
              title: response?.data?.show?.title,
              feedbackImage: response?.data?.show?.feedbackImage,
              monthlyFeedback:response?.data?.show?.monthlyFeedback,
            };
            updateFeedbackData(data);  
            updateEditFeedbackModal(false)        
            updateCreateModal(false)
            UpdateFeedbackPage(true)  
            updateFeedbackSaveState("create")
            navigate("/fbRound")
            setImgFile("");
            setImgFileName("");    
            setTitle("");
            setDescription("");  
            Api.get(
              `/resource-feedback/getQuestions?dataId=${
                feedbackData?.feedbackId && !state?.id
                  ? feedbackData?.feedbackId
                  : state?.id
              }`
            ).send((response) => {
              if (typeof response !== "undefined") {
                console.log("sssResponse",response);
                let data = {
                  feedbackId: response?.feedbackData?.id,
                  title: response?.feedbackData?.title,
                  description: response?.feedbackData?.description,
                  feedbackImage: response?.feedbackData?.feedbackImage,
                  monthlyFeedback:response?.feedbackData?.monthlyFeedback,
                };
               updateFeedbackData(data);
              //  updateFeedbackInputType(response?.types);
               updateFeedbackAddQuestions(response?.feedbackquestions)    
              }
            });
          }
        }
        }) 
        }
  }

  const handleCancel=()=>{
    form.resetFields(); 
    setImgFile(""); 
    setImgFileName(""); 
    setTitle(""); 
    setDescription(""); 
    updateCreateModal(false);
    updateEditFeedbackModal(false);
    updateFeedbackSaveState("create");
  }

  const handleFileRemove = () => {
    setImgFile("");
    setImgFileName("");
  };

  return (
    <Modal
      show={createModal || openEditFeedbackModal}
      onCancel={handleCancel}
      closableRight={true}
      header={<h3 className={classes.modalTitle}>{feedbackData != null && feedbackSaveState === "edit"?
       "Update": selectCardCount > 0 ? "Clone" : "Create"} Form</h3>}
      style={{width:width>800?"60vw":"100vw"}}
      className={classes.createFeedback}
    >
      <div className={classes.modalContainer}>
        <Form layout="vertical" onFinish={onFinish} form={form} 
        initialValues={{
          name:feedbackData?.title,
          description:feedbackData?.description,
          image:feedbackData?.feedbackImage ,
          monthlyFeedback:feedbackData?.monthlyFeedback,
        }}
      
        >
            <Form.Item label={<span className={classes.itemTitle}>Feedback Form Name</span>} name="name" rules={[{required:true,message:"Please Enter form name!"}]}>
                <Input type="text" placeholder="Enter form name"  style={{height:"2.2rem",border:"1px solid #DFDFDF"}}/>
            </Form.Item>
            {/* {
              imgFile === "" ? 
              <div>{feedbackData?.feedbackImage}</div>
              : */}
            <Form.Item  label={<span className={classes.itemTitle}>image</span>} name="image"
              rules={[{required:true,message:"Please Upload Image!"}]}
              initialValue={feedbackData?.feedbackImage || ""}
            >
            <Upload.Dragger beforeUpload={imgbeforeUpload} style={{border:"1px dashed #DFDFDF"}} accept=".png, .jpg, .jpeg,"
             multiple={false} maxCount={1} onRemove={handleFileRemove}>
              <div className={classes.uploads}>
              {initialImageUrl ? (<>
                  {/* // Show the initial image thumbnail */}
                  <img
                    src={initialImageUrl}
                    alt="Initial"
                    style={{ height: "2.5rem", width: "2.5rem",}}
                  />
                  <p className="ant-upload-hint" style={{marginTop:"0.5rem" }}>Click or drag file to this area to Change</p>
                  </>
                ) : (<>
                <p className="ant-upload-drag-icon">
                  <img src={UploadIcon} style={{height:"2.5rem",width:"2.5rem",}}/>
                </p>
                <p className="ant-upload-hint">Click or drag file to this area to upload</p>
                </>
                )}
               </div>
              </Upload.Dragger>
            </Form.Item>
            {/* } */}
            <Form.Item label={<span className={classes.itemTitle}>Description</span>} name="description" rules={[{required:true,message:"Please Enter Description!"}]}>
                <Input.TextArea rows={4} type="text" placeholder="Enter description" style={{height:"2.2rem",border:"1px solid #DFDFDF"}}/>
            </Form.Item>

            <Form.Item  label={<span className={classes.itemTitle}>Disable punch out access until feedback is filled?</span>} 
            name="monthlyFeedback"
            rules={[{required:true,message:"Please select an option!"}]}
            // initialValue={false}
            >
              <Radio.Group 
              //  value={activeRadio}
              onChange={(e)=>{
                console.log("ActiveRadio",e)
                 setActiveRadio(e.target.value);
              }}
              >
                <Radio value={true} className={activeRadio===true?`${classes.radioButton} ${classes.activeRadio}`:`${classes.radioButton}`}>Yes</Radio>
                <Radio  value={false} className={activeRadio===false?`${classes.radioButton} ${classes.activeRadio}`:`${classes.radioButton}`}>No</Radio>
              </Radio.Group>
            </Form.Item>
            {/* <Form.Item> */}
            <div className={classes.buttonDesign}>
                <Button type="primary" htmlType="submit"
                //  disabled={imgFile !== "" || feedbackData?.feedbackImage ? false : true}
                style={{borderRadius:"5px",height:"2.2rem"}}>
                {feedbackData !== null && feedbackSaveState === "edit"
                  ? "Update"
                  :feedbackData !== null 
                  ?"Save"
                  : selectCardCount > 0
                  ? "Clone"
                  : "Create"}                
                </Button>
                <Button style={{borderRadius:"5px",border:"1px solid #0086FF",color:"#0086FF",height:"2.2rem"}} 
                onClick={handleCancel}>Cancel</Button>
            </div>
            {/* </Form.Item> */}
        </Form>
        </div>
    </Modal>
  );
};

export default CreateFeedbackModal;
