import React, { useEffect, useState } from "react";
import { Card, Input, Rate, Radio, Button, Typography, Upload, Slider, } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import { Color } from "../../services/color";
import editIcon from "../../assets/EditIconss.svg";
import classes from "./feedback.module.css";
import poweredBy_icon from '../../assets/poweredBy_icon.svg';
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "./store";
import Api from "../../services/Api";
import CreateFeedbackModal from "./createFeedbackModal";
import SmileyIcon1 from "../../assets/SmileyIcon1.svg";
import SmileyIcon2 from "../../assets/SmileyIcon2.svg";
import SmileyIcon3 from "../../assets/SmileyIcon3.svg";
import SmileyIcon4 from "../../assets/SmileyIcon4.svg";
import SmileyIcon5 from "../../assets/SmileyIcon5.svg";
import dashboardIcon from "../../assets/Dashboard.svg";
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";
import { useHeaderStore } from "../Header/store";
import MobileNavModal from "../../components/MobileNavModal/MobileNavModal";

function FeedbackPreview() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const {updateFeedbackAddQuestions,updateFeedbackData,updateFeedbackSaveState,feedbackSaveState,
    feedbackaddquestions,feedbackData,openEditFeedbackModal,updateEditFeedbackModal,}=useStore();  
    const {width}=useWindowDimensions();
    const {sidemenu,setSidemenu}=useHeaderStore();

  const options = (op) => {
    const Data = [];
    Object.keys(op).forEach((key) => {
      if (op[key] !== "") {
        Data.push(op[key]);
      }
    });
    return Data.map((element) => {
      return (
        <Input
          value={element} disabled={true}
          size="large"
          prefix={<Radio></Radio>}
          style={{
            marginBottom: "1rem",
          }}
        />
      );
    });
  };

  useEffect(() => {
    Api.get(
      `/resource-feedback/getQuestions?dataId=${
        feedbackData?.feedbackId && !state?.id
          ? feedbackData?.feedbackId
          : state?.id
      }`
    ).send((response) => {
      if (typeof response != "undefined") {
        
        let data = {
          feedbackId: response?.feedbackData?.id,
          title: response?.feedbackData?.title,
          description: response?.feedbackData?.description,
          feedbackImage: response?.feedbackData?.feedbackImage,
          monthlyFeedback:response?.feedbackData?.monthlyFeedback,
        };
        updateFeedbackData(data);
        updateFeedbackAddQuestions(response?.feedbackquestions)
      }
    });
  }, []);

  
  const handleEditModal = () => {
    updateEditFeedbackModal(true);
   updateFeedbackSaveState("edit");
  };

  const customIcons = {
    1: <div style={{display:"flex",alignItems:"center",flexDirection:"column",marginRight:"1.5rem",gap:"0.5rem"}}>
       <img src={SmileyIcon1} alt="" style={{height:"2rem",width:"2rem"}}/>
       <h6 style={{color:"black",marginTop:"-0.3rem"}}>Poor</h6>
       </div>,
    2: <div style={{display:"flex",alignItems:"center",flexDirection:"column",marginRight:"1.5rem",gap:"0.5rem"}}>
       <img src={SmileyIcon2} alt="" style={{height:"2rem",width:"2rem"}}/>
       <h6 style={{color:"black",marginTop:"-0.3rem"}}>Fair</h6>
       </div>,
    3: <div style={{display:"flex",alignItems:"center",flexDirection:"column",marginRight:"1.5rem",gap:"0.5rem"}}>
        <img src={SmileyIcon3} alt="" style={{height:"2rem",width:"2rem",}}/>
        <h6 style={{color:"black",marginTop:"-0.3rem"}}>Average</h6>
       </div>,
    4: <div style={{display:"flex",alignItems:"center",flexDirection:"column",marginRight:"1.5rem",gap:"0.5rem"}}>
        <img src={SmileyIcon4} alt="" style={{height:"2rem",width:"2rem",}}/>
        <h6 style={{color:"black",marginTop:"-0.3rem"}}>Happy</h6>
       </div>,
    5: <div style={{display:"flex",alignItems:"center",flexDirection:"column",marginRight:"1.5rem",gap:"0.5rem"}}>
        <img src={SmileyIcon5} alt="" style={{height:"2rem",width:"2rem"}}/>
        <h6 style={{color:"black",marginTop:"-0.3rem"}}>Excellent</h6>
        </div>,
  };

console.log("SssFeedback",feedbackData,openEditFeedbackModal,feedbackSaveState)

  return (
    <>
      {width<800&&<MobileNavModal sidemenu={sidemenu} setSidemenu={setSidemenu}>
     <div className={classes.sider}
     onClick={()=>{
      setSidemenu(false);
      navigate('/feedbackmanagement');
      window.location.reload();
     }}
     >
                  <div    onClick={()=>{
                 navigate('/feedbackmanagement');
                 window.location.reload();
              }}>
                              <img src={dashboardIcon}/>
                              <span>Dashboard</span>
                </div>
                <div>  
                  <img src={poweredBy_icon}></img>
                </div>
            </div>
      </MobileNavModal>}
    <div className={classes.container}>
        {width>800&&<div className={classes.sider}>
              <div>
                          <img src={dashboardIcon}/>
                          <span>Dashboard</span>
            </div>
            <div>  
              <img src={poweredBy_icon}></img>
            </div>
        </div>}
        <div className={classes.mainContent}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            padding:"1rem 2rem 1rem 1rem",
            background:"#fff",
            borderBottom:"1px solid #DFDFDF",
            boxSizing:"border-box",
            alignItems:"center",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem",alignItems:"center" }}>

        

          <svg
           onClick={() => {
            navigate("/fbRound");
          }}
          style={{cursor:"pointer"}}
           xmlns="http://www.w3.org/2000/svg" width="15" height="26" viewBox="0 0 15 26" fill="none">
            <path d="M13 2L2.35355 12.6464C2.15829 12.8417 2.15829 13.1583 2.35355 13.3536L13 24" stroke="#162432" stroke-width="3" stroke-linecap="round"/>
            </svg>
    
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                flexDirection: "column",
                gap: "0",
              }}
            >
              <div
                style={{
                  margin: "0",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={feedbackData?.feedbackImage} style={{height:"4rem",width:"6.5rem"}}/>
                <h4 style={{fontSize: "1.5rem", ontWeight: "700",textAlign: "left",marginLeft:"1rem", }}>
                  <Typography.Text style={{maxWidth: "50vw",fontSize:"1.2rem",fontWeight:"bold"}} ellipsis={{tooltip:true}}>{feedbackData?.title}</Typography.Text>
                </h4>
                <Button type="text" onClick={handleEditModal}>
                  <img
                    style={{ width: "1rem", height: "1rem",marginTop:"0.3rem"}}
                    src={editIcon}
                  />
                </Button>
              </div>
            </div>
          </div>
      </div>
     
      <div style={{ width: "100%", margin: "1.5rem auto",overflowY:"scroll",height:"100%",padding:"0 1.5rem" }}>
        <div style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
          {feedbackaddquestions?.length>0?
          feedbackaddquestions?.map((item, count) => {
            return (
              <Card
                style={{
                  boxShadow:"0px 4px 6px 0px rgba(0, 0, 0, 0.09)",
                  borderRadius:"10px",
                  background:"#fff",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    marginBottom: "1rem",
                    padding: "0 1rem",
                    alignItems: "center",
                    gap: "0.5rem",
                    border: `1px solid ${Color.midGrey}`,
                  }}
                >
                  <span>Q{count + 1}. </span>
                  <Input
                    size="large"
                    bordered={false}
                    value={item?.question}
                    disabled={true}
                  ></Input>
                </div>

                {item?.inputType == "Text" ? (
                  <Input
                    size="large"
                    style={{
                      marginBottom: "2rem",
                    }}
                    disabled={true}
                  />
                ) : item?.inputType == "Emoji-Rating" ? (
                  <div className={classes.emojiRating}>
                  <Rate
                    size="large"
                    style={{
                      border: "1px solid #E5E5E5",
                      width: "100%",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      borderRadius:"5px",
                      display:"flex",
                      justifyContent:"center",
                      alignItems:"center",
                      marginTop:"0.5rem",
                      minWidth:"25rem",
                    }}
                    character={({ index }) => customIcons[index + 1]}
                />
                 </div>
                ) : item?.inputType == "Multiple Choice" ? (
                  <>{options(item?.options)}</>
                ) : item?.inputType == "File Attachment" ? (
                  <Upload
                    multiple={true}
                    listType="text"
                    accept=""
                    action={"https://www.mocky.io/v2/5cc8019d300000980a055e76"}
                    showUploadList={{ showRemoveIcon: true }}
                  >
                    <Button>
                      <PaperClipOutlined />
                      Attach A File
                    </Button>
                  </Upload>
                ) : (
                  <Slider />
                )}
              </Card>
            );
          }):<span style={{height:"100%",display:"flex",alignItems:"center",justifyContent:"center",margin:" 20% auto",color:"#878787"}}>No data to display!</span>}
        </div>
      </div>
      </div>
      {openEditFeedbackModal&&<CreateFeedbackModal />}
      </div>
    </>
  );
}
export default FeedbackPreview;
