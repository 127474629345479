import React, { useState, useEffect } from 'react';
import { Select, Dropdown, Button, Space } from 'antd';
import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useFlagButtonStore } from '../Release/store';

const { Option } = Select;

const MultiSelectFilter = ({options, filtersListHandlerFunction,initialKey}) => {
    

  const {filtersList, 
      selectedTeamFilters, 
      setSelectedTeamFilters, 
      selectedProjectFilters, 
      setSelectedProjectFilters, 
      tasksTeamId, 
      tasksTeamName, 
      selectedAssociateFilters,
      setSelectedAssociateFilters,
    } = useFlagButtonStore(state=>state);

  const [tempSelectedValues, setTempSelectedValues] = useState();
  useEffect(()=>{
    console.log("CHECKTEAMFILTER", selectedTeamFilters)
    if(initialKey==="Team"){
        
        setTempSelectedValues(selectedTeamFilters);
      
    }
    else if(initialKey==="Project"){
      setTempSelectedValues(selectedProjectFilters)
    }
    else if(initialKey==="Associate"){
      setTempSelectedValues(selectedAssociateFilters);
    }
  },[])

//   useEffect(()=>{
//     if(selectedValues?.length !==0){
//    filtersListHandlerFunction(selectedValues);
//     }

//   },[selectedValues])

  // Array of options to loop through
  //const options = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];

  // Handle filter change but don't set it directly to state until confirmed
  const handleFilterChange = (values) => {
    setTempSelectedValues(values);
  };

  // Confirm filter selection
  const handleConfirm = (a) => {
    // setSelectedValues(tempSelectedValues);
    if(initialKey==="Team"){
    setSelectedTeamFilters(a)
    }
    else if(initialKey === "Project"){
      setSelectedProjectFilters(a)
    }
    else if(initialKey === "Associate"){
      setSelectedAssociateFilters(a)
    }
    filtersListHandlerFunction(a)
  };
  

  // Reset filter selection
  const handleReset = () => {
    setTempSelectedValues([]);
    if(initialKey==="Team"){
    setSelectedTeamFilters([]);
    }
    if(initialKey==="Project"){
      setSelectedProjectFilters([]);
    }
    if(initialKey==="Associate"){
      setSelectedAssociateFilters([]);
    }
    handleConfirm([]);
 
  };

  const filterMenu = (
    <div style={{ padding: '10px', width: '250px', backgroundColor:'#FFFFFF' }}>
      <Select
        mode="multiple"
        placeholder={"Select options"}
        value={tempSelectedValues}
        onChange={handleFilterChange }
        style={{ width: '100%' }}
        showSearch  // Enable search functionality
        optionFilterProp="children"  // Filter by the label (children)
        filterOption={(input, option) => option?.children?.toLowerCase().includes(input?.toLowerCase()?.trim())} // Case-insensitive filter
      >
        {options?.map((option, index) => (
          <Option key={index} value={option?.value}>
            {option?.label}
          </Option>
        ))}
      </Select>

      <div style={{ marginTop: '10px', textAlign: 'right' }}>
        <Button onClick={handleReset} style={{ marginRight: '8px' }}>
          Reset
        </Button>
        <Button type="primary" onClick={()=>{
          handleConfirm(tempSelectedValues)
        }}>
          Confirm
        </Button>
      </div>
    </div>
  );

  return (
    
    <Space direction="vertical">
      <Dropdown overlay={filterMenu} trigger={['click']} placement="topLeft" style={{backgroundColor:'#ffffff'}} >
        <Button icon={<FilterFilled 
                          style={{color:initialKey==="Team"?(selectedTeamFilters?.length>0)?'#000000':'#6A6A6A':initialKey==="Project"?selectedProjectFilters?.length>0?'#000000':'#6A6A6A':selectedAssociateFilters?.length>0?"#000000":"#6A6A6A", 
                                  width:'12px',
                                  alignItems:'center'}}/>} 
                style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            boxShadow: 'none',
                        }}>

        </Button>
        
      </Dropdown>

      {/* Showing selected filters */}
      {/* <div>
        <strong>Selected Filters: </strong>
        {selectedValues.length > 0 ? selectedValues.join(', ') : 'None'}
      </div> */}
    </Space>
  );
};

export default MultiSelectFilter;
