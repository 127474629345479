import React from "react";
import { useGlobalStore } from "../../../../store";
import classes from './mainpage.module.css';
const Cards = (props) => {
  const { changePage, changeTablePage } = useGlobalStore((state) => ({
    changePage: state.changePage,
    changeTablePage: state.changeTablePage,
  }));

  return (
    <div
      style={{
        borderRadius: "4px",
        // height: "21vh",
        background: "#fff",
        position: "relative",
        overflow: "hidden",
        display: "flex",
        boxShadow:'0px 4px 4px #16243230',
      }}
    >
     
     
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection:'column',
            padding:'16px 20px',
            gap:'16px',
          }}
        >
          <div style={{color: "#16243280",font:"normal 500 18px/22px Lato"}}>
            {props?.title}
          </div>
          <div style={{display:'flex' ,justifyContent:'space-between',alignItems:'center',width:'100%'}}>            
            <div style={{ display: "flex",justifyContent:'flex-start',color:'#162432',font:'normal 700 42px/48px Lato'}}>
                {props?.count ?? 0}
              </div>
                <div style={{ color: "#0086FF", cursor: "pointer",fontSize:"normal 600 20px/24px Lato",paddingTop:'16px' }} 
                    onClick={
                      props.title !== "Teams"
                        ? () => {
                            changePage("hodTables");
                            changeTablePage(props.title);
                          }
                        : () => {
                            changePage("teampage");
                          }
                    }
                  >
                    View List
                  
                </div>
            </div>
        </div>
       
       
      
    </div>
  );
};

export default Cards;
