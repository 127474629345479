import React from "react";
import classes from './CEOMessageModal.module.css';
import { Modal } from "antd";
import { useLoginStore } from "../store";
import ceo_image from '../../../assets/ceoImage.svg';
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";

const CEOMessageModal=()=>{
    const {ceoMessageModalHandler,ceoMessageModal}=useLoginStore();
    const {width}=useWindowDimensions();
    return(
        <Modal
        onCancel={()=>{ceoMessageModalHandler(false)}}
        open={ceoMessageModal}
        
        footer={false}
        centered
        width={width>700?"50vw":"100vw"}
        bodyStyle={{display:"flex",alignItems:"center",}}
        >
            <div className={classes.ceoMessage}>
                <span>A message from our CEO</span>
                <span>brigosha has come a long way in establishing for itself a consistent and positive reputation across its business of providing world class automotive embedded solutions and is today well known for its quality and time assurances. Founded on the core value of agility, transparency, quality and integrity, we can’t thank enough each and every associate of the company for their contribution in role modelling brigosha’s values and help sustain our image as a company that is well trusted and admired. <br/><br/>
                    As we brace ourselves to survive and make our presence felt in a highly competitive and ever-evolving business market, one thing that must remain forever constant is our unwavering and uncompromising devotion to our values, principles and standards for its these values and principles after all that set us apart, even more so than the solutions we deliver.<br/><br/>
                     We are here to set a benchmark in Work Ethic, Integrity, Camaraderie, Attitude, Team Work, Time Management and building an overall healthy work environment for competitors to envy and suppliers to emulate, it is our Code of Conduct which will forever remain our steady orbit around which all the company’s practices must revolve. With that vision in mind, I humbly call upon every associate to follow it and commit to upholding it.
                     <br/><br/> In anticipation of your best foot forward at workplace every single day.</span>
                <div className={classes.ceoProfile}>
                    <div>
                        <img src={ceo_image}/>
                    </div>
                    <div>
                        <span>Brijesh Singh</span>
                        <span>CEO of brigosha technologies</span>
                    </div>
                </div>
            </div>

        </Modal>
    )
}
export default CEOMessageModal;