import React, { useState } from "react";
import classes from './AllVideos.module.css';
import back_icon from '../../../assets/backIcon.svg';
import { useLoginStore } from "../store";
import VideoModal from "./VideoModal";
import { Pagination, Typography } from "antd";
import moment from "moment";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
const AllVideos=({setPage,videos})=>{
    const [video,setVideo]=useState();
    const {width}=useWindowDimensions();
 
    const {videoModalHandler,videoModal,current,limit,setCurrent,setLimit}=useLoginStore();

    
    const getDays=(date)=>{
        const givenDate = new Date(date);

    // Get today's date
    const todayDate = new Date();

    // Calculate the difference in days
    const differenceInDays = Math.floor((todayDate - givenDate) / (1000 * 60 * 60 * 24));

    // Convert days to months (assuming 30 days per month for simplicity)
    return differenceInDays;
    }

    const getMonth=(date)=>{
        const givenDate = new Date(date);

    // Get today's date
    const todayDate = new Date();

    // Calculate the difference in days
    const differenceInDays = Math.floor((todayDate - givenDate) / (1000 * 60 * 60 * 24));

    // Convert days to months (assuming 30 days per month for simplicity)
    const calculatedDifferenceInMonths = Math.floor(differenceInDays / 30);
    return calculatedDifferenceInMonths;
    }

    return(
        <div className={classes.container}>
            
            <div onClick={()=>{
                    setPage({page:1,events:false,videos:false, newsletter:false})
                    setCurrent(1)
                    setLimit(10)
                }}>
                    <img src={back_icon}/>
                    <span>Back</span>
                </div>
                <span>Videos</span>
                <div className={classes.videos}>
                    {videos?.items?.map((item,index)=>{
                        return(
                            <div key={index}
                            onClick={()=>{
                                setVideo(item);                                      
                                videoModalHandler(true);
                            }}
                            >
                                <div>
                                    <img src={item?.thumbnail}/>
                                </div>
                                <Typography.Text style={{margin:"0",fontSize:"1rem",gap:"0",fontWeight:"700",width:width>700?"17rem":"80vw"}} ellipsis={{tooltip:true,}}>
                                 {item.title}
                                </Typography.Text>
                               
                                <div>
                                    <span>{item.statistics.viewCount} views</span>
                                    <span></span>
                                    <span>{
                                      getMonth(moment(item.publishedTime).format('YYYY-MM-DD'))>1?`
                                      ${getMonth(moment(item.publishedTime).format('YYYY-MM-DD'))} months ago`:
                                      getDays(moment(item.publishedTime).format('YYYY-MM-DD'))>13?
                                      Math.floor(getDays(moment(item.publishedTime).format('YYYY-MM-DD'))/7)+' weeks ago':
                                      getDays(moment(item.publishedTime).format('YYYY-MM-DD'))>1?
                                      getDays(moment(item.publishedTime).format('YYYY-MM-DD'))+" days ago":
                                      getDays(moment(item.publishedTime).format('YYYY-MM-DD'))>0?
                                      '1 day ago':
                                      "Today"
                                    }</span>
                                </div>
                            </div>
                        )
                    })}
                </div>
               <div style={{display:'flex',justifyContent:'flex-end',}}>
                <Pagination  size={width>700?"middle":"small"} style={{display:"flex",justifyContent:"flex-end",marginTop:"0.5rem",marginRight:"1rem"}}
              current={current} showSizeChanger={true} total={parseInt(videos?.meta?.totalItems)?parseInt(videos?.meta?.totalItems):0} pageSize={limit} 
              onChange={(page,pageSize)=>{
                setCurrent(page)
                setLimit(pageSize)
                window.scrollTo(0, 0);
              }}
               onShowSizeChange={(page,pageSize)=>{
                setCurrent(page)
                setLimit(pageSize)
                window.scrollTo(0, 0);
              }} 
              pageSizeOptions={[10,20,30,50,100,200]}
              showTotal={(total, range) => <span>{range[0]}-{range[1]} of {total} items</span>} />
              </div>

                {videoModal&&<VideoModal video={video}/>}
        </div>
    )
}
export default AllVideos;