import { create } from "zustand"
import { persist } from "zustand/middleware"

const localStorageStore=(set)=>({
    isVisible:false,
    setisVisible:(payload)=>{
        set(state=>({
            isVisible:payload
        }))
    }
})

export const uselocalStorageStore=create(
    persist(localStorageStore,{name:"localData"})
)