import React, { useState } from "react";
import classes from './AllNewsletter.module.css';
import back_icon from '../../../assets/backIcon.svg';
import { Typography } from "antd";
import { useLoginStore } from "../store";
import NewsletterModal from "./NewsletterModal";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";

const AllNewsletter=({newsletters,setPage})=>{
    const {newsletterModalHandler,newsletterModal,setNewsletter}=useLoginStore();
    const {width}=useWindowDimensions();
   
    return(
        
                <div className={classes.container}>
                    <div onClick={()=>{
                            setPage({page:1,events:false,videos:false, newsletter:false})
                        }}>
                            <img src={back_icon}/>
                            <span>Back</span>
                        </div>

                        <span>Newsletter</span>

                        <div className={classes.newsletterItems}>
                            {newsletters?.map((item,index)=>{
                                return(
                                    <div className={classes.eachNews} onClick={()=>{
                                        newsletterModalHandler(true);
                                        setNewsletter(item);
                                    }}>
                                    <div>
                                        <img src={item.image}/>
                                    </div>
                                    <div>
                                        <span>{item.author}</span>
                                        <span>{item.createdAt.split("T0")?.[0]}</span>
                                    </div>
                                    <Typography.Text  ellipsis={{tooltip:true,}} style={{fontSize:"1rem",fontWeight:"700",maxWidth:width>700?"17vw":"80vw"}}>{item.title}</Typography.Text>
                                    {/* <Typography.Paragraph ellipsis={{tooltip:true, rows:3}}> */}
                                    <span style={{fontWeight:"0.85rem",fontWeight:"500"}}>{item.content.length>110?item.content.substring(0, 50)+"...":item.content} {item.content.length>90?<span style={{color:"#1089FF",marginLeft:"0.5vw"}}>Continue reading</span>:""}</span>
                                    {/* <span style={{fontWeight:"1.5vmin",fontWeight:"500"}}>{item.content}</span>
                                    </Typography.Paragraph> */}
                                    
                                </div>
                                )
                            })}
                        </div>

                    
                    

                </div>
        
    )
}
export default AllNewsletter;