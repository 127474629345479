import React, { memo, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Input,
  Switch,
  Button,
  Rate,
  Radio,
  Upload,
  Typography,
  Slider,
  Select,
} from "antd";
import {
  DownOutlined,
  FileTextOutlined,
  PaperClipOutlined,
  MenuOutlined,
  SlidersOutlined,
  SmileOutlined
} from "@ant-design/icons";
import "./textEditor.css";
import { Color } from "../../services/color";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useQuill } from "react-quilljs";
import {  useStore } from "./store";
import deleteIcon from "../../assets/delete.svg";
import editIcon from "./asset/Icon_material_edit.svg";
import Api from "../../services/Api";
import SmileyIcon1 from "../../assets/SmileyIcon1.svg";
import SmileyIcon2 from "../../assets/SmileyIcon2.svg";
import SmileyIcon3 from "../../assets/SmileyIcon3.svg";
import SmileyIcon4 from "../../assets/SmileyIcon4.svg";
import SmileyIcon5 from "../../assets/SmileyIcon5.svg";
import Notification from "../../services/Notification";
import classes from './feedback.module.css';


const { Option } = Select;
function CardData(props) {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [dropdownvalue, setDropDownvalue] = useState();
  const [dltLoader, setDltLoader] = useState(false);
  const [required, setRequired] = useState(false);
  const [options, setOptions] = useState("");
  const [editInput, setEditInput] = useState(false);
  const [editDropdown, setEditDroption] = useState(false);
  const [question, setQuestion] = useState("");
  const [multipleChoice, setMutipleChoice] = useState([]);
  const [options1, setOptions1] = useState("");
  const [multiple, setMultiple] = useState(new Array(5).fill(""));
  const [editOption, setEditOption] = useState(new Array(5).fill(false));
  const [data, setData] = useState([]);
  const [backupData, setBackupData] = useState([]);
  const [count, setCount] = useState(3);
  const [isaddmultiplechoice, setIsAddMultipleChoice] = useState([]);
  const [cardEditMode, setCardEditMode] = useState(false);
  const [cardStatus,setCardStatus]=useState(false)
  const[requirevalue,setReqvalue]=useState(props?.isArray?.require)
  const { quill, quillRef } = useQuill();
  const {updateFeedbackAddQuestions,feedbackData,feedbackInputType,updateEditFeedbackModal}=useStore();

  

    useEffect(() => {
      setReqvalue(props?.isArray?.require)
      
      let Data = [];
      if (
        props?.isArray?.options &&
        props?.isArray?.inputType === "Multiple Choice"
      ) {
        let propsValue = props?.isArray?.options;
        Object.keys(propsValue).forEach((key) => {
  
          Data.push(propsValue[key]);
        });
      }
      setData(Data);
      setIsAddMultipleChoice(Data?.filter((item) => item !== ""));
  
      setDropDownvalue(props?.isArray?.inputType);
    }, [props?.isArray]);
  
    const modules = {
      toolbar: [["bold", "italic", "underline", "strike"]],
    };

    function areAllValuesDefined(obj) {
      let definedKeys=0;
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (obj[key]?.trim()&&obj[key]?.trim()!=='') {
            definedKeys++;
          }
        }
      }
      return definedKeys>=2; // All values are defined
    }

    function removeEmptyKeys(obj) {
      const newObj = {};
    
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
    
          if (value !== null && value !== undefined && value?.trim() !== '') {
            newObj[key] = value;
          }
        }
      }
    
      return newObj;
    }
  
    useEffect(()=>{
      if(editDropdown && dropdownvalue === 'Multiple Choice' ){
          setIsAddMultipleChoice([1,1]);
      }
  },
  [editDropdown,dropdownvalue])
  
    const patchApi = () => {
      const obj = {
        a: editOption[0] ? multiple[0] : data[0],
        b: editOption[1] ? multiple[1] : data[1],
        c: editOption[2] ? multiple[2] : data[2],
        d: editOption[3] ? multiple[3] : data[3],
        e: editOption[4] ? multiple[4] : data[4],
        f: editOption[5] ? multiple[5] : data[5],
      };
      
      let params = {
        feedback_data_id: feedbackData?.feedbackId,
        feedback_question_id: props.isArray.id,
        inputType: dropdownvalue ? dropdownvalue : props.isArray.inputType,
        question: question !== "" ? question : props.isArray.question,
        options: removeEmptyKeys(obj),
        require: requirevalue,
      };
      if(params?.inputType==="Multiple Choice"&&params?.options&&!areAllValuesDefined(params?.options))
      {
        Notification.error("Please fill the options!")
        setEditInput(false);
            setEditDroption(false);
            setOptions("");
            setOptions1("");
            setMultiple(new Array(6).fill(""));
            setEditOption(new Array(6).fill(false));
            props.refresh();
            forceUpdate()
        return 
      }
    
      Api.patch("/resource-feedback/updateQuestions")
        .params(params)
        .send((response) => {
          if (response?.show?.type === "success") {
            setEditInput(false);
            setEditDroption(false);
            setOptions("");
            setOptions1("");
            setMultiple(new Array(6).fill(""));
            setEditOption(new Array(6).fill(false));
            updateFeedbackAddQuestions(response?.show?.questions)
            props.refresh();
            forceUpdate()
          }
          setCardEditMode(false);
        });
    };
    const Deleteapi = () => {
      setDltLoader(true);
    
      let params = {
        id: props.isArray.id,
      };
      Api.delete(`/resource-feedback/deleteQuestion?qid=${props.isArray.id}&dataId=${feedbackData?.feedbackId}`)
        // .params(params)
        .send((response) => {
          setDltLoader(false);
          setCardEditMode(false)
          props.refresh();
        });
    };
    React.useEffect(() => {
      if (quill) {
        quill.on("text-change", () => {
         
        });
      }
    }, [quill]);
  
   
    const addmultiplechoice = () => {
   
      if (isaddmultiplechoice.length < 6) {
      if(editDropdown && dropdownvalue === 'Multiple Choice' ){
          setIsAddMultipleChoice([1,1]);
      }
      const addOption = [...isaddmultiplechoice, 1];
       setIsAddMultipleChoice(addOption);
    }
  }

  const customIcons = {
    1: <div style={{display:"flex",alignItems:"center",flexDirection:"column",marginRight:"1.5rem",gap:"0.5rem"}}>
       <img src={SmileyIcon1} alt="" style={{height:"2rem",width:"2rem"}}/>
       <h6 style={{color:"black",marginTop:"-0.3rem"}}>Poor</h6>
       </div>,
    2: <div style={{display:"flex",alignItems:"center",flexDirection:"column",marginRight:"1.5rem",gap:"0.5rem"}}>
       <img src={SmileyIcon2} alt="" style={{height:"2rem",width:"2rem"}}/>
       <h6 style={{color:"black",marginTop:"-0.3rem"}}>Fair</h6>
       </div>,
    3: <div style={{display:"flex",alignItems:"center",flexDirection:"column",marginRight:"1.5rem",gap:"0.5rem"}}>
        <img src={SmileyIcon3} alt="" style={{height:"2rem",width:"2rem",}}/>
        <h6 style={{color:"black",marginTop:"-0.3rem"}}>Average</h6>
       </div>,
    4: <div style={{display:"flex",alignItems:"center",flexDirection:"column",marginRight:"1.5rem",gap:"0.5rem"}}>
        <img src={SmileyIcon4} alt="" style={{height:"2rem",width:"2rem",}}/>
        <h6 style={{color:"black",marginTop:"-0.3rem"}}>Happy</h6>
       </div>,
    5: <div style={{display:"flex",alignItems:"center",flexDirection:"column",marginRight:"1.5rem",gap:"0.5rem"}}>
        <img src={SmileyIcon5} alt="" style={{height:"2rem",width:"2rem"}}/>
        <h6 style={{color:"black",marginTop:"-0.3rem"}}>Excellent</h6>
        </div>,
  };

  return (
    <div>
      <Card
        bordered={false}
        style={{
          width: "100%",
          borderRadius: "0.6rem",
          borderLeft: cardEditMode ? `0.4rem solid ${Color.modalblue}` : "",
        }}
      >
        <div
          style={{
            marginBottom: "1.5rem",
            display: "flex",
            gap:"1rem"
          }}
        >
          <div
            style={{
              flex: "1 1 50%",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.5rem", }}
            >
              <div>Q{props.questionNum}.</div>
              <Input
                value={editInput  ? question : props.isArray.question}
                placeholder=" Enter question"
                size="large"
                disabled={!cardEditMode ? true : false}
                onChange={(event) => {
                  let value = event.target.value;
                  setQuestion(value);
                  setEditInput(true);
                }}
              />
            </div>
          </div>
          {cardEditMode && (
            <div>
              {/* <Space direction="vertical"> */}
                <Select
                  value={editDropdown ? dropdownvalue : props.isArray.inputType}
                  size="large"
                  placeholder="Select dropdown"
                  onChange={(e) => {
                    setEditDroption(true);
                    setDropDownvalue(e);
                  
                    if (e === "Multiple Choice") {
                      setMutipleChoice(props?.onAddMultiple);
                    }
                  }}
                  style={{ width: "10rem",marginBottom: "10px",}}
                  icon={<DownOutlined />}
                >
                  {feedbackInputType.map((item) => {
                    if (item === "Text") {
                      return (
                        <Option key={item?.id} value={item}>
                          <div>
                          <MenuOutlined style={{ marginRight: "10px", }} />
                          Text Input
                          </div>
                        </Option>
                      );
                    }
                    if (item === "Emoji-Rating") {
                      return (
                        <Option key={item?.id} value={item} size="large">
                          <div>
                          <SmileOutlined style={{ marginRight: "10px" }} />
                          Rating
                          </div>
                        </Option>
                      );
                    }
                    if (item === "Multiple Choice") {
                     
                      return (
                        <Option key={item?.id} value={item} size="large">
                          <div>
                          <FileTextOutlined style={{ marginRight: "10px" }} />
                          {item}
                          </div>
                        </Option>
                      );
                    }
                    if (item === "FileUpload") {
                      return (
                        <Option key={item?.id} value={item} size="large">
                          <div>
                          <PaperClipOutlined style={{ marginRight: "10px" }} />
                          File Attachment
                          </div>
                        </Option>
                      );
                    }
                    if (item === "Slider") {
                      return (
                        <Option key={item?.id} value={item} size="large">
                          <div>
                          <SlidersOutlined style={{ marginRight: "10px" }} />
                          {item}
                          </div>
                        </Option>
                      );
                    }
                  })}
                  <MenuOutlined />
                </Select>
              {/* </Space> */}
            </div>
          )}
        </div>
        <br />
        {editDropdown && dropdownvalue === "Text" && (
          <ReactQuill
            style={{ marginTop: "-30px", }}
            className="ql-toolbar.ql-snow"
            placeholder="Text Input"
            modules={{ toolbar: [] }}
          />
        )}

        {!editDropdown && props.isArray.inputType === "Text" && (
          <ReactQuill
            style={{ marginTop: "-30px",marginLeft:"25px"}}
            className="ql-toolbar.ql-snow"
            placeholder="Text Input"
            modules={{ toolbar: modules }}
          />
        )}

        {!editDropdown && props.isArray.inputType === "Emoji-Rating" && (
           <div style={{display:"flex",alignItems:"center",justifyContent:"center",}}>
            <div className={classes.emojiRating}>
          <Rate
            size="large"
            style={{
              border: "1px solid #E5E5E5",
              minWidth: "25rem",
              paddingTop: "5px",
              paddingBottom: "5px",
              borderRadius:"5px",
              display:"flex",
              justifyContent:"center",
              marginTop:"0.5rem",
            }}
            character={({ index }) => customIcons[index + 1]}
          />
          </div>
          </div>
        )}

        {editDropdown && dropdownvalue === "Emoji-Rating" && (
           <div style={{display:"flex",alignItems:"center",justifyContent:"center",}}>
            <div className={classes.emojiRating}>
          <Rate
            size="large"
            style={{
              border: "1px solid #E5E5E5",
              minWidth: "25rem",
              paddingTop: "5px",
              paddingBottom: "5px",
              borderRadius:"5px",
              display:"flex",
              justifyContent:"center",
              marginTop:"0.5rem",
            }}
            character={({ index }) => customIcons[index + 1]}
          />
          </div>
          </div>
        )}

        {editDropdown && dropdownvalue === "Multiple Choice" && (
          <>
            <Input
              disabled={!cardEditMode ? true : false}
              value={editOption[0] ? multiple[0] : data[0]}
              placeholder="Option"
              onChange={(e) => {
                // setOptions(e.target.value);
                setMultiple(multiple.map((item,index)=>{
                  if(index===0)
                  {
                    return e.target.value;
                  }
                  return item;
                }))
                setEditOption(
                  editOption.map((item, index) => {
                    if (index === 0) {
                      return true;
                    }
                    return item;
                  })
                );
              }}
              prefix={<Radio></Radio>}
            />
            <Input
              disabled={!cardEditMode ? true : false}
              value={editOption[1] ? multiple[1] : data[1]}
              style={{ marginTop: "1rem" }}
              placeholder="Option"
              onChange={(e) => {
                // setOptions1(e.target.value);
                setMultiple(multiple.map((item,index)=>{
                  if(index===1)
                  {
                    return e.target.value;
                  }
                  return item;
                }))
                setEditOption(
                  editOption.map((item, index) => {
                    if (index === 1) {
                      return true;
                    }
                    return item;
                  })
                );
              }}
              prefix={<Radio></Radio>}
            />
            {isaddmultiplechoice.map((item, index) => {
              
              if (index >= 2 && item != "") {
                return (
                  <Input
                    disabled={!cardEditMode ? true : false}
                    prefix={<Radio></Radio>}
                    placeholder="Option"
                    style={{ marginTop: "10px" }}
                    onChange={(e) => {
                      setEditOption(
                        editOption.map((item2, index2) => {
                          if (index2 === index) {
                            return true;
                          }
                          return item2;
                        })
                      );
                      setMultiple(
                        multiple.map((item3, index3) => {
                         
                          if (index3 === index ) {
                            return e.target.value;
                          }
                          return item3;
                        })
                      );
                    }}
                    value={
                      editOption[index] ? multiple[index] : data[index]
                    }
                  />
                );
              }
            })}
            {/* <Input
              prefix={<Radio></Radio>}
              placeholder="Add Option"
              disabled={!cardEditMode ? true : false}
              onClick={addmultiplechoice}
            /> */}
            <div
              onClick={addmultiplechoice}
              disabled={!cardEditMode ? true : false}
              {...() => setCount(count + 1)}
              style={{
                marginLeft: "0.7rem",
                cursor: "pointer",
                fontSize: "17px",
                color: "#D3D3D3",
                marginTop: "0.3rem",
                textAlign: "left",
              }}
            >
              <Radio style={{ marginTop: "10px" }}></Radio>Add Option or Add
              Others
            </div>
          </>
        )}

        {!editDropdown && props.isArray.inputType === "Multiple Choice" && (
          <>
          
            {isaddmultiplechoice.map((item, index) => {
             
              if (index >= 0 && item != "") {
                return (
                  <Input
                    disabled={!cardEditMode ? true : false}
                    prefix={<Radio></Radio>}
                    placeholder="Option"
                    style={{ marginTop: "1rem",marginLeft:"1.9rem",width:"49vw" }}
                    onChange={(e) => {
                      setEditOption(
                        editOption.map((item2, index2) => {
                          if (index2 === index) {
                            return true;
                          }
                          return item2;
                        })
                      );
                      // multiple.map((item3,index3) => {
                    
                      //     if (index3 === index){

                      //     }
                      // })
                      setMultiple(
                        multiple.map((item3, index3) => {
                         
                          if (index3 === index) {
                            return e.target.value;
                          }
                          return item3;
                        })
                      );
                    }}
                    value={editOption[index] ? multiple[index] : data[index]}
                  />
                );
              }
            })}
            {/* <Input
              prefix={<Radio></Radio>}
              style={{ marginTop: "1rem" }}
              placeholder="Add Option"
              disabled={!cardEditMode ? true : false}
              onClick={addmultiplechoice}
            /> */}
            <div
              disabled={!cardEditMode ? true : false}
              onClick={addmultiplechoice}
              {...() => setCount(count + 1)}
              style={{
                marginLeft: "2.6rem",
                cursor: "pointer",
                // fontSize: "17px",
                color: "#D3D3D3",
                marginTop: "0.3rem",
                textAlign: "left",
              }}
            >
              <Radio style={{ marginTop: "10px" }}></Radio>Add Option or Add
              Others
            </div>
          </>
        )}

        {!editDropdown && props.isArray.inputType === "FileUpload" && (
          <Upload
            multiple={true}
            listType="text"
            accept=""
            action={"https://www.mocky.io/v2/5cc8019d300000980a055e76"}
            showUploadList={{ showRemoveIcon: true }}
          >
            <Button style={{marginLeft:"2rem"}}>
              <PaperClipOutlined />
              Attach A File
            </Button>
          </Upload>
        )}

        {editDropdown && dropdownvalue === "FileUpload" && (
          <Upload
            multiple={true}
            listType="text"
            accept=""
            action={"https://www.mocky.io/v2/5cc8019d300000980a055e76"}
            showUploadList={{ showRemoveIcon: true }}
          >
            <Button style={{marginLeft:"2rem"}}>
              <PaperClipOutlined />
              Attach A File
            </Button>
          </Upload>
        )}

        {!editDropdown && props.isArray.inputType === "Slider" && <Slider />}

        {editDropdown && dropdownvalue === "Slider" && <Slider />}
        <Row
          style={{
            marginTop: "3rem",
            display: "flex",
          }}
        >
          <Col
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "flex-start",
              gap: "1rem",
            }}
          >
            {cardEditMode && (
              <Button
                style={{
                  width: "5rem",
                  height: "2rem",
                  color: Color.white,
                  background: Color.modalblue,
                }}
                onClick={() => {
                  patchApi();
                  setCardEditMode(false);
                }}
              >
                Save
              </Button>
            )}
            {cardEditMode && (
              <Button
                onClick={() => {
                  setEditInput(false)
                  props.refresh();
                  setCardEditMode(false);
                }}
                style={{ background: Color.midGrey }}
              >
                Discard
              </Button>
            )}
          </Col>

          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "0.5rem",
              alignItems: "center",
              justifySelf: "",
              width: "50%",
            }}
          >
            {!cardEditMode && (
              <Button
                type="text"
                style={{ background: "none", padding: "0" }}
                onClick={() => {
                  setCardEditMode(true);
                }}
              >
                <img src={editIcon} style={{ opacity:"8" }} />
              </Button>
            )}

            <Button
              type="text"
              onClick={() => Deleteapi()}
              disabled={!cardEditMode ? true : false}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={deleteIcon}
                style={{
                  color: !cardEditMode ? Color.lightGrey : Color.darkGrey,
                  opacity:"1"
                }}
              />
            </Button>
            <Col
              style={{
                fontWeight: "600",
                fontSize: "1rem",
                fontFamily: "Open Sans",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              Required
              <Switch
                disabled={!cardEditMode ? true : false}
                // checked={required===false?requirevalue:required}
                checked={requirevalue} 
                style={{ color: "#D3D3D3" }}
                onChange={(checked) => {
                  setCardStatus(!cardStatus)
                  setRequired(checked)
                  setReqvalue(checked)
              }}
              />
            </Col>
          </Col>
        </Row>
      </Card>
    </div>
  );
}
export default memo(CardData);
