import { Button, Modal } from "antd";
import ReactDom from "react-dom";
import React, { useEffect, useRef, useState } from "react";

import classes from "./ApplyFilter.module.css";

const ApplyFilter = ({ visible, setVisible, style, children }) => {
  const modalRef = useRef();
  const bodyRef = useRef();
  const [isMyModalVisisble, setIsMyModalVisisble] = useState();
  const [isbodyVisible, setIsbodyVisible] = useState();

  // console.log("columns data for table", modalRef);
  useEffect(() => {
    const observer = new IntersectionObserver((enteries) => {
      const entry = enteries[0];
      setIsMyModalVisisble(entry.isIntersecting);
    });
    const bodyObserver = new IntersectionObserver((enteries) => {
      const entry = enteries[0];
      setIsbodyVisible(entry.isIntersecting);
    });
    if (bodyRef.current !== null && bodyRef.current !== undefined) {
      bodyObserver.observe(bodyRef.current);
    }
    if (modalRef.current !== null && modalRef.current !== undefined) {
      observer.observe(modalRef.current);
    }
  }, [visible]);

  return ReactDom.createPortal(
    visible && (
      <div
        ref={(el) => {
          bodyRef.current = el;
        }}
        className={`${classes.body_container} ${isbodyVisible ? classes.body_visible:''}`}
        onClick={() => {
          setIsMyModalVisisble(false)
          setIsbodyVisible(false)
          setTimeout(()=>{
            setVisible(false);
          },1000)
        }}
      >
        <div
          ref={(el) => (modalRef.current = el)}
          className={`${classes.modal_container} ${
            isMyModalVisisble ? classes.show_modal : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {children}
        </div>
      </div>
    ),
    document.getElementById("portal")
  );
};

export default ApplyFilter;
