import { Button, Checkbox, Modal, Radio, Space, Spin, Tabs } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";

import "../index.css";
import lockColor from "../../../assets/Appraisal/lockColorIcon.svg";
import Api from "../../../services/Api";
import { useNavigate } from "react-router-dom";
import { useHeaderStore } from "../../Header/store";

const RoleLock = ({
  // handleLock,
  handleCancel,
  // roleModal,
  // hanldeRole,
  // setRoleModal,
}) => {
  const [selectedRole, setSelectedRole] = useState();
  const [loader, setLoader] = useState(false);
  const [activeTab, setActiveTab] = useState("lockRoles");
  const [lockState,setLockState] = useState('')
  const navigate = useNavigate();
  const {roleModal, setRoleModal}=useHeaderStore();
  const path = window.location.pathname;

  useEffect(() => {
    setLoader(true);
    Api.get("/appraisal-admin/role_lock").send((res) => {
      if (res) {
        setSelectedRole(Object.keys(res).filter((item) => res[item])[0]);
      }
      setLoader(false);
    });
  }, [roleModal]);


  const handleRole = (e) => {
    setSelectedRole(e.target.value);
  };

  const handleOk = (value) => {
    if (value !== undefined) {
      let selectedAssociateQuery = "";
      let selectedManagerQuery = "";
      let selectedBothQuery="";
      let lockState=''
      if (value.includes("associate")) {
        selectedAssociateQuery = "associate=true";
      } else {
        selectedAssociateQuery = "associate=false";
      }
      if (value.includes("manager")) {
        selectedManagerQuery = "manager=true";
      } else {
        selectedManagerQuery = "manager=false";
      }
      if (value.includes("both")) {
        selectedBothQuery = "both=true";
        
      } else {
        selectedBothQuery = "both=false";
       
      }
      if(activeTab==='unLockRoles'){
        lockState = 'lock=false&';
        // if (value.includes("associate")) {
        //   selectedAssociateQuery = "associate=false";
        // }
        // if (value.includes("manager")) {
        //   selectedManagerQuery = "manager=false";
        // } 
        // if (value.includes("both")) {
        //   selectedBothQuery = "both=false";
        // } 
      }else{
        lockState = 'lock=true&'
      }
      // value === "Associate" ? "associate=true" : "manager=true";
      Api.post(
        `/appraisal-admin/roleLock?${lockState}${selectedAssociateQuery}${
          selectedAssociateQuery !== "" ? "&" : ""
        }${selectedManagerQuery}${selectedManagerQuery !== "" ? "&" : ""}${selectedBothQuery}`
        
      ).send((res) => {
        if (res?.show?.type === "success") {
          navigate(path);
          setRoleModal(false);
          setActiveTab('')
        }
      });
    }
  };

  const LockRoleComp = ({ handleRole, selectedRole }) => {
    useEffect(() => {
    }, [selectedRole]);
    return (
      <div style={{ width: "100%", height: "30vh" }}>
        <p style={{ fontFamily: "Roboto-Regular", color: "#707070" }}>
          The super admin will lock the portal access to the selected roles.
        </p>
        <div style={{ marginTop: "1rem" }}>
          <Radio.Group onChange={handleRole} value={selectedRole}>
            <Space direction="vertical">
              <Radio
                value="both"
                style={{ fontSize: "16px", fontWeight: "400" }}
              >
                Both
              </Radio>
              <Radio
                value="associate"
                style={{ fontSize: "16px", fontWeight: "400" }}
              >
                Associate
              </Radio>
              <Radio
                value="manager"
                style={{ fontSize: "16px", fontWeight: "400" }}
              >
                Manager
              </Radio>
            </Space>
          </Radio.Group>
        </div>
      </div>
    );
  };

  const UnlockRoleComp = ({ handleRole, selectedRole }) => {
    return (
      <div style={{ width: "100%", height: "30vh" }}>
        <p style={{ fontFamily: "Roboto-Regular", color: "#707070" }}>
          The super admin will unlock the portal access to the selected roles.
        </p>
        <div style={{ marginTop: "1rem" }}>
          <Radio.Group onChange={handleRole} defaultValue={selectedRole}>
            <Space direction="vertical">
              <Radio
                value="both"
                style={{ fontSize: "16px", fontWeight: "400" }}
              >
                Both
              </Radio>
              <Radio
                value="associate"
                style={{ fontSize: "16px", fontWeight: "400" }}
              >
                Associate
              </Radio>
              <Radio
                value="manager"
                style={{ fontSize: "16px", fontWeight: "400" }}
              >
                Manager
              </Radio>
            </Space>
          </Radio.Group>
        </div>
      </div>
    );
  };

  const tabItems = [
    {
      key: "lockRoles",
      label: (
        <p style={{ fontFamily: "Roboto-Medium", fontSize: "16px" }}>
          Lock Roles
        </p>
      ),
      children: (
        <LockRoleComp selectedRole={selectedRole} handleRole={handleRole} />
      ),
    },
    {
      key: "unLockRoles",
      label: (
        <p style={{ fontFamily: "Roboto-Medium", fontSize: "16px" }}>
          Unlock Role
        </p>
      ),
      children: (
        <UnlockRoleComp selectedRole={selectedRole} handleRole={handleRole} />
      ),
    },
  ];

  const onChangeTabs = (value) => {
    setActiveTab(value);
  };

  return (
    <Modal
    centered
      title={
        <div
          style={{
            display: "flex",
            // border:'1px solid black'
          }}
        >
          <img src={lockColor} />
          <span
            style={{
              marginLeft: "1rem",
              color: "#0086FF",
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
          >
            Portal Access
          </span>
        </div>
      }
      open={roleModal}
      // onOk={handleLock}
      onCancel={()=>{handleCancel(); setActiveTab('')}}
      footer={true}
      bodyStyle={{ height: "53vh" }}
      width={1000}
      style={{ position: "relative" }}
    >
      {loader ? (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <>
          <div style={{ border: "1px solid lightGrey", width: "100%" }} />
          <div style={{ margin: "1rem", height: "40vh" }}>
            <Tabs
              items={tabItems}
              defaultActiveKey="lockRoles"
              onChange={onChangeTabs}
            />
          </div>
          <div
            style={{
              // border: "1px solid red",
              width: "100%",
              height: "10vh",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "30vw",
                display: "flex",
                justifyContent: "flex-end",
                gap: "1rem",
              }}
            >
              <Button
                type="default"
                style={{
                  borderColor: "#0086FF",
                  width: "10rem",
                  height: "3rem",
                }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                style={{ width: "10rem", height: "3rem" }}
                onClick={() => handleOk(selectedRole)}
                disabled={selectedRole === undefined}
              >
                {activeTab === "unLockRoles" ? <>Unlock</> : <>Lock</>}
              </Button>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default RoleLock;
