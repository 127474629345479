import React, { useState, useEffect } from "react";
// import "antd/dist/antd.css";
import "../index.css";
import { Row, Col, Table, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { managerActions } from "./store";
import Api from "../../../services/Api";
import Boxes from "./boxes";
import { useHistory, useNavigate } from "react-router-dom";
import { globalActions } from "../store/loader";
import LockPage from "../associate/LockPage";
import AssociateTable from "../components/Table/AssociateTable";

const Manager = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { managerTeam } = useSelector((state) => state.manager);
  const { loader } = useSelector((state) => state.global);
  const [lockStatus,setLockStatus] = useState([])
  // const history = useHistory();
  const navigate = useNavigate();

  console.log("Entered in Manager");

  useEffect(() => {
    dispatch(globalActions.updateLoader({ loader: true }));
    Api.get("/appraisal-manager/teams?managerId=" + user.id)
      .params({})
      .send((response, error) => {
        if (typeof response != "undefined") {
          dispatch(
            managerActions.updateManagerTeam({ managerTeam: response.teams })

          );
          setLockStatus(response?.lockStatus)
          dispatch(globalActions.updateLoader({ loader: false }));
          
        }
      });
    console.log("Appraisal Private Route user1", user);

    console.log("Appraisal Manager");
    return () => {
      // dispatch(managerActions.updateSelectedTeamId({ selectedTeamId: null }));
    };
  }, []);

  const onRankClick = (row) => {
    dispatch(managerActions.updateSelectedTeamId({ selectedTeamId: row.id }));
    navigate("/appraisal/manager/associate-rank");
    // history.push("/manager/associate-rank");
  };
  const onPerformanceClick = (row) => {
    dispatch(managerActions.updateSelectedTeamId({ selectedTeamId: row.id }));
    navigate("/appraisal/manager/performance-matrix");
    // history.push("/manager/performance-matrix");
  };
  const onFeedbackClick = (row) => {
    dispatch(managerActions.updateSelectedTeamId({ selectedTeamId: row.id }));
    navigate("/appraisal/manager/associate-list");
    // history.push("/manager/associate-list");
  };

  const dataSource = managerTeam?.map((team) => {
    return {
      key: team.teamId,
      id: team.teamId,
      name: team.teamName,
      workSummary: team.workSummary,
      teamRanked: team.teamRanked,
      performanceAttitudeRanked: team.performanceAttitudeRanked,
      feedbackCompleted: team.feedbackCompleted,
    };
  });

  const columns = [
    {
      title:<div style={{width:"100%",paddingLeft:"1rem"}}>Team Name</div> ,
      dataIndex: "name",
      key: "name",
      render:(text,item)=>{
        return(
          <div style={{paddingLeft:"1rem"}}>{item?.name}</div>
        )
      }
    },
    {
      title:<div style={{width:"100%"}}>Rank</div> ,
      dataIndex: "rank",
      key: "rank",
      render: (val, row) => {
        return (
          <Button
            type="primary"
            onClick={() => onRankClick(row)}
            disabled={!row?.teamRanked}
          >
            Rank Team
          </Button>
        );
      },
    },
    {
      title:<div style={{width:"100%"}}>Performance</div>,
      dataIndex: "performance",
      key: "performance",
      render: (val, row) => (
        <Button
          type="primary"
          onClick={() => onPerformanceClick(row)}
          disabled={!row?.performanceAttitudeRanked}
        >
          Performance Attitude
        </Button>
      ),
    },
    {
      title:<div style={{width:"100%"}}>Feedback</div> ,
      dataIndex: "feedback",
      key: "feedback",
      render: (val, row) => (
        <Button
          type="primary"
          onClick={() => onFeedbackClick(row)}
          disabled={!row?.feedbackCompleted}
        >
          Submit Feedback
        </Button>
      ),
    },
  ];
  if (loader) {
    return null;
  } else {
    return lockStatus?.includes("Manager") ? (
      <LockPage />
    ) : (
      <>
        <div style={{display:"flex",flexDirection:"column",width:"100vw" }}>
          {/* <div style={{ paddingLeft: "20px" }}> */}{" "}
          <div style={{borderBottom:"1px solid #dfdfdf",padding:"1.5rem 2rem"}}>
          <h2>Manager Name: {user.name} </h2>
          </div>
          {/* <hr style={{ borderTop: "3px solid rgb(95, 37, 255)" }}></hr> */}
          {/* </div> */}
          {/* <Row justify="start" align="top" gutter={[32, 40]}>
          {teamList}
        </Row> */}
          {/* <Table dataSource={dataSource} columns={columns} pagination={false} /> */}

          <AssociateTable
        // loading={loader}
         dataSource={dataSource}
        columns={columns}
         rowKey={(record) => record?.id}
        // meta={meta}
        style={{ width: "100%", height: "71vh" }}
        // onChange= {(page, pageSize) => handlePageChange(page, pageSize)}
      />
        </div>
      </>
    );
  }
};

export default Manager;
