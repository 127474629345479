export const getAllManager=(event=null,Api,callback,newManager=false)=>{
    Api.get("/project-migrateManager/allManager").params({departmentId:event,newManager:newManager}).send(res=>{
        if(callback){
            callback(res)
        }
    })
}
export const getAllTeamsData=(event,Api,callback, param=null)=>{
    Api.get("/project-migrateManager/allTeams").params({managerId:event,departmentId:param?.deptId}).send(res=>{
        if(callback){
            callback(res)
        }
    })
}
export const migrateTeamApi=(oldmanager,newManagerId,teamId,Api,callback)=>{
    Api.patch("/project-migrateManager/changeManager").params({newManagerId:newManagerId,teams:teamId,oldManagerId:oldmanager}).send(res=>{
        if(callback){
            callback(res)
        }
       
    })
}

export const getDepartment=(Api,callback)=>{
    Api.get("/project-release/allDepartment").params({}).send(res=>{
        if(callback){
            callback(res)
        }
    })
}


