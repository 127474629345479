import React, { useState, useEffect } from "react";
import { Button, Collapse, Empty,Select, Spin, Typography } from "antd";

import classes from "./teampage.module.css";
import DownloadIcon from "../../../../assets/Download Icon Blue.svg";
import "./teampage.css";
import { useGlobalStore } from "../../../../store";
import { getProjectByID, getTeamData } from "./action";
import TeamListModal from "./TeamListModal";
import { LeftOutlined } from "@ant-design/icons";
import useApiStore from "../../../../services/store";
import ModalCompo from "../../../../design/ModalCompo";
import { useProjecteStore } from "../../../ProjectManagement/store/store";
import { useNavigate } from "react-router";
import viewClass from "../../../../services/ViewRole";

const {Option} = Select;
function TeamPage() {
  const { Panel } = Collapse;
  const [departmentData, setDepartmentData] = useState([]);
  const [departmentFilteredData, setDepartmentFilteredData] = useState([]);
  const [teamListModal, setTeamListModal] = useState(false);
  const [deptFilter, setDeptFilter] = useState();
  const [team, setTeam] = useState();
  const [teamDetail, setDetail] = useState([]);
  const [projectModal,setProjectModal]=useState();
  const [defaultOpen,setDefaultOpen]=useState([0]);
  const [loader,setLoader]=useState(false);
  const [projectNavLoader,setProjectNavLoader]=useState(false);
  const {setProjectClickedData}=useProjecteStore(state=>state);
  const Navigate=useNavigate();
  const {
    changePage,
  } = useGlobalStore((state) => ({
    dashboardMainPage: state.dashboardMainPage,
    changePage: state.changePage,
    setTeamsDepartmentFilter: state.setTeamsDepartmentFilter,
    selectedTeamsDepartmentId: state.selectedTeamsDepartmentId,
  }));

  useEffect(() => {
    setLoader(true)
    getTeamData(
      {},
      (res) => {
        setDepartmentData(res?.allDepartments);
        setDepartmentFilteredData(res?.department);
        let arr=[];
        for(let i=0;i<res?.department?.length;i++){
          if(res?.department[i]?.teams?.length>0){
            arr.push(i);

          }
        }
        setDefaultOpen(arr);
        setLoader(false);
      },
      deptFilter
    );
    // setLoader(false)
  }, [deptFilter]);

  const downloadTeam=()=>{
    getTeamData(
      {teamDownload:true},
      (res) => {
        window.open(res, "_blank")?.focus();     
     },
      deptFilter
    );
  }

  const projectClickHandler=(projectId)=>{
    setProjectNavLoader(true);
    getProjectByID({projectId:projectId},(res)=>{
      setProjectClickedData({'navigationLocation':'resourceDash', ...res});
      Navigate('/project/projects');
      setProjectNavLoader(false);
    })
  }

  return (
    <div
      style={{
        width:"100%",
        
      }}
    >
      
        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%",padding:'16px 20px'}}>
        
        <div style={{display:'flex',alignItems:'center',gap:'6px'}}>
        <LeftOutlined  style={{fontSize:'18px'}}  onClick={() => {
            changePage("mainPage");
          }}/>
        <h3>Teams</h3>
        </div>
       
        <Button bordered={false} disabled={viewClass.hrAssociate&&!viewClass.hrAdmin} style={{background:"#fff",border:"none", color:'#0086ff',display:"flex",flexDirection:"row",
            alignItems:"center",justifyContent:"center",gap:"6px",fontWeight:"600"}}
            onClick={downloadTeam}> 
            <div>Download</div> 
            <img src={DownloadIcon} alt='' style={{opacity:"0.7",height:"1rem"}}/>
        </Button>
        </div>
        <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"flex-end",width:"100%",padding:'4px 20px'}}>
        
        <div style={{display:"flex",flexDirection:"row",justifyContent:"center",gap:"1rem"}}>
          <Select placeholder="All" style={{ width: "8rem",height:"2rem"}}
             onChange={(val)=>setDeptFilter(val)}>
               {[{departmentName:'All',departmentId:'All'}, ...departmentData]?.map((val, index) => {
                console.log("sssVal",val)
                return (
                <Option key={val.departmentName} value={val.departmentId}>
                  {val?.departmentName}
                </Option>
                )}
              )}
          </Select>
          </div>
          </div>
      {/* header part ends her */}
      {loader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><Spin /></div>: 
      departmentFilteredData.length !== 0 ? (
        <div className={classes.container}>
          <Collapse
            style={{ display:'flex',flexDirection:'column',gap:'24px',  padding:'16px 20px',borderRadius:'0px'}}
            defaultActiveKey={defaultOpen}
          >
            {departmentFilteredData.map((val, index) => (
              <Panel
                key={index}
                style={{ border:'1px solid #1624321a',backgroundColor:'#fff', borderRadius:'10px',boxShadow:'0px 4px 6px ##00000017',minHeight:'3rem',}}
                header={<h4>Department: {val.departmentName}</h4>}
                className="customPanel"
              >

              <div className={classes.WebViewList}  style={{background:'#fff'}}>
                <div className={classes.ListContainer}>
                    <div className={classes.ListHeader} style={{display:'flex',width:'100%'}}>
                          <div style={{width:'10%',justifyContent:'flex-start'}}>S.No.</div>
                          <div style={{width:'30%'}}>Team Name</div>
                          <div style={{width:'30%'}}>Manager Name</div>
                          <div style={{width:'15%'}}>Team members</div>
                          <div style={{width:'15%'}}>Projects</div>

                    </div>
                    <div className={classes.ListBodyContainer} style={{overflowY:'scroll',overflowX:'unset'}}>
                     
                     {
                     val?.teams?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><div>No data found !</div></div>:
                      val?.teams?.map((record,id)=>{
                        
                        return(
                          <div className={classes.rowContainer}>
                          <div style={{width:'10%',justifyContent:'flex-start',}}>
                            <div>{id+1}</div>
                          </div>
                          <div style={{width:'30%'}}>
                           <div >{record?.teamName} </div>
                          </div>
                          <div style={{width:'30%'}}>
                         <div>{record?.manager?.name}</div>
                          </div>
                          <div style={{width:'15%'}}>
                          <a
                                onClick={() => {
                                  setTeamListModal(true);
                                  setTeam({id:record?.teamId,name:record?.teamName})
                                  setDetail(record?.members)
                                }}
                              >
                                {record?.members?.length}
                              </a>
                          </div>
                          <div style={{width:'15%'}}>
                          <a
                                onClick={() => {
                                  setProjectModal(record?.projects)
                                }}
                              >
                                {record?.projects?.length}
                              </a>
                          </div>
                          </div>
                        )
                      })}
                    </div>
                </div>
                {/* <div style={{display:'flex',justifyContent:'flex-end',width:'100%'}} >
                      <Pagination 
                      onChange={handleTableChange}
                      showSizeChanger={true}
                       {...tableParams.pagination}
                      />
                </div> */}
              </div>
              <div className={classes.MobViewList}>
              <div className={classes.ListContainerMob}>
              {loader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><Spin /></div>:
              val?.teams?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><div>No data found !</div></div>:
              val?.teams?.map((record,id)=>{

                        return(
                          <div className={classes.MobListBody}>
                          <div>
                           
                            <div className={classes.listLine}>
                              <div className="header4Font" style={{minWidth:'90px'}}>Team Name: </div>
                              <Typography.Text className="header3Font" ellipsis={{tooltip:true}} style={{maxWidth:'200px',font:'normal 400 16px/22px Lato'}} >
                              {record?.teamName}
                              </Typography.Text>
                            </div>
                            <div className={classes.listLine}>
                              <div className="header4Font" style={{minWidth:'112px'}}>Manager Name: </div>
                              <Typography.Text className="header3Font" ellipsis={{tooltip:true}} style={{maxWidth:'200px',font:'normal 400 16px/22px Lato'}} >{record?.manager?.name}</Typography.Text>
                            </div>
                            <div className={classes.listLine}>
                              <div className="header4Font" style={{minWidth:'112px'}}>Team members: </div>
                              <div className="header3Font"> 
                              <a
                                onClick={() => {
                                  setTeamListModal(true);
                                  setTeam({id:record?.teamId,name:record?.teamName})
                                  setDetail(record?.members)
                                }}
                              > 
                                {record?.members?.length}
                              </a></div>
                              
                            </div>
                            <div className={classes.listLine}>
                              <div className="header4Font">Projects: </div>
                              <div className="header3Font"> 
                              <a
                                onClick={() => {
                                 setProjectModal(record?.projects)
                                }}
                              > 
                                {record?.projects?.length}
                              </a></div>
                              
                            </div>
                          </div>
                          

                        </div>)
                      })  } 
                    </div> 
             {/* {dashboardPage !== "Head Of Departments" && dashboardPage !== "Managers" &&   <div style={{display:'flex',justifyContent:'flex-end',width:'100%',padding:'0rem 2rem'}} >
                      <Pagination 
                      onChange={handlePageChange}
                      showSizeChanger={true}
                      pageSize={pageLimit}
                      current={pageCurrent}
                      total={totalItems}
                      />
                </div> }  */}
              </div>
              </Panel>
            ))}
          </Collapse>
        </div>
      ) : (
        <div className={classes.emptyContainer}>
          <Empty />
        </div>
      )}
      <ModalCompo  open={projectModal!==undefined} onCancel={()=>{setProjectModal()}} closableRight={true}
      className={classes.projectModal}
      >
      <div style={{width:'100%'}}>
        <div style={{font:'normal 700 22px/26px Lato',marginTop:'-2rem',borderBottom:'1px solid #16243280',height:'3rem'}}>Projects</div>
        {projectNavLoader?<Spin style={{width:"100%",display:"flex",height:"15vh",alignItems:"center",justifyContent:"center"}}></Spin>:<div>
          <div style={{width:'100%', maxHeight:'60vh',minHeight:'20vh', overflowY:'scroll',display:'flex',flexDirection:'column',margin:'10px 0px'}}>
            { projectModal?.length===0? <div style={{width:'100%',height:'20vh',display:'flex',justifyContent:'center',alignItems:'center'}}> <div>No data</div> </div>:
               projectModal?.map((val,id)=>{
                console.log("projectModal",val)
              return(
                <div style={{color:'#0086ff',font:'normal 600 18px/24px Lato',paddingTop:'1rem',}} key={id} >
                  <span style={{cursor:"pointer",}} onClick={()=>{projectClickHandler(val?.id)}}>{val?.name}</span>
                </div>
              )
            })}
          </div>
        </div>}
        
</div>
      </ModalCompo>
      <TeamListModal open={teamListModal} close={setTeamListModal} team={team} teamDetail={teamDetail} />
    </div>
  );
}

export default TeamPage;
