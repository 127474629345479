import React, { useEffect, useState } from 'react';
import classes from "./style.module.css";
import Logo from '../../assets/loginLogo.svg';
import PasswordResetImage from "../../assets/passwordResetImage.png";
import Union from "../../assets/Union.svg";
import Circle from "../../assets/Circle.svg";
import Image from "../../assets/resetPassword.svg";
import login_brigo_text from '../../assets/login_brigo_text.svg';
import   our_journey_img from '../../assets/our-journey-img.svg';
import   updated_icon from '../../assets/updated_icon.svg';
import Union1 from "../../assets/Union 1.svg";
import Union2 from "../../assets/Union 2.svg";
import { Card, Form, Input,Button,Row,Col,Spin } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined,MinusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Api from "../../services/Api";
import useStore from '../../pages/store';
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";
import { useLoginStore } from './store';
import { Header } from './login';
import Feed from './Feed/Feed';
import JobSearch from './JobSearch/jobSearch';

const PasswordReset=()=>{

  const navigate = useNavigate();
  console.log("jhdjhdhcdhjdkdfk;jw3o;ejropwpihe")
  const [loader,setLoader] = useState(false);
  const [title,setTitle] = useState("Password Reset");
  const [content,setContent] = useState("Your password has been successfully reset, Click below to log in.");
  const {newUser,user}=useStore(state=>state);
  const {width}=useWindowDimensions();
  const url = new URL(window.location.href);
  const userId = url.searchParams.get("userId");
  const token = url.searchParams.get("token");
  const timestamp = url.searchParams.get("timestamp"); 
  // const [curPage, setCurPage]=useState(1);
  const {curPage, setCurPage,setIsSignIn}=useLoginStore();
  const [activeTab,setActiveTab]=useState(3);
  
  // /resource-auth/confirmEmail?userId=36&timestamp=1690977406493
  
useEffect(()=>{
  if(user){
    window.localStorage.clear()
  }
},[])

  // useEffect(()=>{
    
  //   setLoader(true)
  //   setTimeout(() => {
  //     Api.get(`/resource-auth/confirmEmail/?userId=${userId}&timestamp=${timestamp}`)
  //        .send((response)=>{
  //         if(response?.show?.type === "success"){
  //           setLoader(false)
  //           window.localStorage.clear()
  //           setTitle("Email Verified")
  //           setContent("You email verified and password has been successfully updated, Click below to log in")
  //         }
  //        })
  //   }, 1000);
  // },[])

  return (<>
   
    {/* <div className={classes.container}>
    <div>
      <img src={Union1} alt='' style={{height:width<700&&"6rem",width:width<700&&"6rem"}}/>
    </div>
    <div>
      <img src={Logo} alt='' style={{height:width>700?"4rem":"4.5rem",width:"4rem"}}/>
    </div>
    <div className={classes.contents2}>
      <div ></div>
    </div>
    <div className={classes.contents}>
      <div className={classes.overlapContent}>
      <div style={{display:"flex",height:"100%",alignItems:"center",padding:width>700?"1rem":"0 1rem 1rem 1rem",borderRadius:5,flex:"50%"}}>
          <p style={{display:"flex",width:"100%",color:"#162432",justifyContent:"flex-start",alignItems:"center",flexDirection:"row",fontWeight:600,fontSize:"25px",marginLeft:width>700&&"1rem",marginTop:width<700&&"0.9rem",}}>
              <span style={{color:"#0086FF",marginRight:"0.4rem"}}>Hey, </span> Associate!
          </p>
            <img src={Image} alt='' style={{height:width>700?"40vh":"",width:width>700&&"35vw"}}/>
            <p style={{color:"#0086FF",display:"flex",width:"100%",justifyContent:"center",flexDirection:"row",fontWeight:width>700?600:590,marginTop:"1rem",fontSize:width>700?"20px":"17px"}}>
              " Self determination and integrity "</p>
          </div>
          <div style={{overflow:"auto",display:width>700&&"flex",height:"100%",alignItems:"center",padding:width>700?"1rem":"0 1rem 1rem 1rem",borderRadius:5,flex:"50%"}}>
                <div style={{backgroundColor:"#F5FCFF",padding:width>700?"1rem":"0.3rem 1rem 0.3rem 1rem",width:"100%",height:width>700&&"45vh",paddingTop:"2rem"}}>
             
                <h2 style={{marginTop:"1rem",color:"#162432"}}>{title}</h2>
                <p style={{fontSize:13,marginTop:"0.5rem",color:"#16243280"}}>{content}</p>
                <div className={classes.passReset} style={{marginBottom:width<700&&"2rem"}}>
                  <Button style={{width:"6rem",height:width<700&&"2.4rem",fontSize:width<700&&"16px"}} type="primary" 
                    onClick={()=>{navigate("/login")}}>
                      Login {" "}
                    
                  </Button>
                </div>
               </div>
               </div>
              </div>
            </div>
            <div className={classes.imageB}>
            <img src={Union2} alt='' style={{height:width<700&&"7rem",width:width<700&&"7rem"}}/>
            </div>
          </div> */}

      <Header activeTab={activeTab} setActiveTab={setActiveTab}/>
         {activeTab===1&&<Feed/>}
         {activeTab===2&&<JobSearch/>}
         {activeTab===3&&<div className={classes.login}>
      <section className={classes.imageSection}>
        
            {/* <img src={login_brigo_text}/> */}
            <img src={our_journey_img}/>
            <div className={classes.integrityText}>
              <span>Self determination and integrity</span>
              <span>Join our brigosha family today!</span>
            </div>
       

      </section>

      <section className={classes.loginWrapper}>
        {curPage!==1&&<div className={classes.backDiv} onClick={()=>setCurPage(curPage-1)}>
          <div>
           
            <ArrowLeftOutlined/>
            </div>
            <span>Back</span>
         
        </div>}
        <div className={classes.loginContainer}>
          <div className={classes.passUpdated}>
          <img src={updated_icon}/>
             <span>Password updated!</span>
             <span>Your password has been reset successfully</span>
               <button 
                 onClick={()=>{navigate("/login");
                 setCurPage(5);
                 setIsSignIn(true);
                 }}>
                   Sign in
               </button>
            </div>
        
        </div>
      </section>

    </div>}
   
  </>
)
}
export default PasswordReset;
