import React from "react";
import classes from "./ceoDesk.module.css";
import poweredBy_icon from '../../assets/poweredBy_icon.svg';
import CeoPhoto from "../../assets/CEOs_Photo.png";
import Fb from "../../assets/fb.svg";
import Youtube from "../../assets/youtube.svg";
import linkedIn from "../../assets/linkedin.svg";
import Twitter from "../../assets/twitter.svg";
import dashboardIcon from "../../assets/Dashboard.svg";
import { Button, Input, } from "antd";
import { useState } from "react";
import Api from "../../services/Api"; 
import { useEffect } from "react";
import { decryptData, SECRET_KEY } from "../../services/fileCode";
import CEoImage from '../../assets/ceo-_1_.svg';
import Response from "./Response";
import Modal from "../../components/Modal/Modal";
import viewClass from "../../services/ViewRole";
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";
import MobileNavModal from "../../components/MobileNavModal/MobileNavModal";
import { useHeaderStore } from "../Header/store";
import { useNavigate } from "react-router-dom";
import FPDashboard from "./Dashboard/Dashboard";
import FPDepartment from "./Department/Department";
import FPAssociates from "./Associates/Associates";



export const writeToCeo=(userId,params,callback=null)=>{
   
    params['message']=params?.message?.split("\n")?.map((item,index)=>{
        return(
            `<p>${`${item}`}</p>`
        )
    }).join('')

    console.log("writeto CEO",params)
    console.log("messagemessage", params['message'])
 
    params={...params,userId:userId}
  

    Api.post("/resource-user/saveCEOdesk").params(params).send((res)=>{
        if(res!==undefined && res?.show?.type!=="error"){
            callback()
        }
    })

    // Api.post(`/resource-user/saveCEOdesk?userId=${params?.userId}&subject=${params?.subject}&body=${params?.message}`).send((res)=>{
    //     if(res!==undefined && res?.show?.type!=="error"){
    //         callback()
    //     }
    // })
}

const CeoDesk=()=>{
    const [openModal,setOpenModal] = useState(false)
    const [tab,setTab]=useState('CEOs Desk');
    const [search,setSearch]=useState('');
    const [showSubModules,setShowSubModules]=useState(false);
    const [activeModule,setActiveModule]=useState(1);
    const [inputs,setInputs] =useState({
        subject:"",
        message :"",
        userId:""
    })
    const {width}=useWindowDimensions();
    const {sidemenu,setSidemenu}=useHeaderStore();
    
    const navigate=useNavigate();
    let u=localStorage.getItem('user');
    let user;
    if(u){
        user=decryptData(u,SECRET_KEY);
    }

    console.log("userROLE",user)

    useEffect(()=>{
setInputs({
    ...inputs,
    subject:"",
    message :"",
})
    },[openModal===true])

    const [userData, setUserData] = useState({})
    useEffect(() => {
        let token = localStorage.getItem('user');
        if (token) {
            let decryptedData = decryptData(token, SECRET_KEY);
            if (decryptedData) {
                setUserData(decryptedData);
                
            } else {
                console.error("Decryption failed. Invalid token or secret key.");
            }
        }
        setInputs({...inputs, userId:parseInt(userData?.id)})
    }, [localStorage.getItem('user')]);
    

   let href=window.location.href.split('/');
    let path=`${href[3]}`
    if(href[4])
    {
        path=`${href[3]}/${href[4]}`
    }

    console.log("pathhhhh",path)
    
    return(
        <>
               {/* {width<800&&<MobileNavModal sidemenu={sidemenu} setSidemenu={setSidemenu}>
                    <div className={classes.sider}
                    onClick={()=>{
                        setSidemenu(false);
                    }}
                    >
                        <div>
                                    <div 
                                    className={(path==="ceoDesk"&&activeModule===1)?classes.activeModule:""}
                                    onClick={()=>{
                                        navigate('/ceoDesk');
                                        setShowSubModules(false);
                                        setActiveModule(1);
                                    }}>
                                            <img src={dashboardIcon}/>
                                            <span>Dashboard</span>
                                    </div>
                                    {user?.designation==="CEO"&&<div
                                    className={(path!=="ceoDesk"||activeModule===2)?classes.activeModule:""}
                                     onClick={()=>{
                                        setShowSubModules(prev=>!prev);
                                        setActiveModule(2);
                                        navigate('dashboard')
                                    }}>
                                        <img src={dashboardIcon}/>
                                        <span>Feedback Portal</span>
                                    </div>}
                                    <div className={showSubModules?classes.showModules:classes.hideModules}>
                                        <div>
                                                <span
                                                className={path==="ceoDesk/dashboard"?classes.activeSubModule:""}
                                                 onClick={()=>{
                                                    navigate('dashboard')
                                                }}>Summary</span>
                                                <span
                                                className={path==="ceoDesk/department"?classes.activeSubModule:""}
                                                onClick={()=>{
                                                    navigate('department')
                                                }}
                                                >Departments</span>
                                                <span
                                                className={path==="ceoDesk/associates"?classes.activeSubModule:""}
                                                onClick={()=>{
                                                    navigate('associates')
                                                }}
                                                >Associtates</span>
                                        </div>
                                    </div>
                             </div>
                            <div>  
                            <img src={poweredBy_icon}></img>
                            </div>
                            </div>
            </MobileNavModal>} */}
                <div className={classes.container}>
                       

             <div className={classes.mainContent}>
                
                    
                                <div className={classes.header}>
                                        
                                            <h3>CEO's Desk</h3>
                                            {user?.resourceUser?.designation==="CEO"&&
                                            <div className={classes.tabDiv}>
                                                    <div className={classes.tab}>
                                                            <span className={tab==='CEOs Desk'?classes.activeTab:''} onClick={()=>{
                                                                setTab('CEOs Desk')
                                                            }}>CEO’s Desk</span>
                                                            <span 
                                                                onClick={()=>{
                                                                    setTab('Response')
                                                                }}
                                                                className={tab==='Response'?classes.activeTab:''}
                                                                >Responses</span>
                                                    </div>                                               
                                                        {tab==="Response"&&<div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                                                <circle cx="8.68103" cy="8.68103" r="7.93103" stroke="#BEBEBE" stroke-width="1.5"/>
                                                                <path d="M15.4473 15.6597L19.7878 20.0002" stroke="#BEBEBE" stroke-width="1.5" stroke-linecap="round"/>
                                                                </svg>
                                                                <Input type='text'  allowClear={true} placeholder='Search' bordered={false} onChange={(e)=>{
                                                                    setSearch(e.target.value);
                                                                }}/>
                                                        </div>}
                                            </div>}
                                    

                                </div>
                {tab==='CEOs Desk'&&
                <div style={{background:"#F4F4F4",height:"100%",overflowY:"auto"}}>
                                <div>
                                        <div className={classes.ceoinfo}>
                                    

                                            <div className={classes.textcontent}>
                                                <div className={classes.des}>
                                                <p style={{fontSize:"1.1rem"}}>From the CEO's Desk</p>

                                                <p style={{marginTop:"1rem",textAlign:"justify"}}>brigosha has come a long way in establishing for 
                                                itself a consistent and positive reputation across its business of providing world class automotive 
                                                embedded solutions and is today well known for its quality and time assurances. Founded on the core 
                                                value of agility, transparency, quality and integrity, we can’t thank enough each and every associate 
                                                of the company for their contribution in role modelling Brigosha’s values and help sustain our image 
                                                as a company that is well trusted and admired.
                                                </p>

                                                <p style={{marginTop:"1rem",textAlign:"justify"}}>
                                                As we brace ourselves to survive and make our presence felt in a highly competitive and ever-evolving 
                                                business market, one thing that must remain forever constant is our unwavering and uncompromising devotion 
                                                to our values, principles and standards for its these values and principles after all that set us apart, 
                                                even more so than the solutions we deliver.
                                                </p>
                                                <p style={{marginTop:"1rem",textAlign:"justify"}}>
                                                We are here to set a benchmark in Work Ethic, Integrity, Camaraderie, Attitude, Team Work, 
                                                Time Management and building an overall healthy work environment for competitors to envy and suppliers 
                                                to emulate, it is our Code of Conduct which will forever remain our steady orbit around which all the 
                                                company’s practices must revolve. With that vision in mind, I humbly call upon every associate to follow 
                                                it and commit to upholding it.
                                                </p>
                                                <p style={{marginTop:"1rem",}}>
                                                In anticipation of your best foot forward at workplace every single day.
                                                </p>
                                                </div>
                                                <div>
                                                {user?.resourceUser?.designation!=="CEO"&&<Button type="primary" style={{width:"10rem",height:"2.5rem",fontSize:"1rem",fontFamily:"Lato"}}
                                                onClick={()=>setOpenModal(true)}>
                                                    Write to CEO
                                                </Button>}
                                                </div>
                                            </div>
                                            <div className={classes.image}>
                                                <img src={CeoPhoto} alt="" style={{ height: "100%", width: "100%",}} className={classes.ceoImage}/>
                                                <div style={{ position: "absolute", bottom: "2rem", left: "3rem", color: "#ffffff", fontFamily:"Lato"}}>
                                                <h3 style={{fontSize:"1.3rem",fontWeight: "600",}}>Mr. Brijesh Singh</h3> 
                                                <span style={{fontSize:"0.9rem",fontWeight: "400",}}>CEO, brigosha technologies</span>
                                                </div>
                                            </div>
                                        </div>
                                </div>

                                <div>
                                <div className={classes.cards}>
                                    <span style={{display:"flex",alignItems:"center"}} className={classes.forward}
                                    onClick={()=>{
                                        window.open('https://www.brigosha.com/','_blank').focus();
                                    }}
                                    >Our Story</span><hr  style={{border:"1.5px solid #E6E6E6"}}/>
                                    <span style={{display:"flex",alignItems:"center"}} className={classes.forward}
                                    onClick={()=>{
                                        window.open('https://www.brigosha.com/teams','_blank').focus();
                                    }}
                                    >Teams</span><hr style={{border:"1.5px solid #E6E6E6"}}/>
                                    <span style={{display:"flex",alignItems:"center"}} className={classes.forward}
                                    onClick={()=>{
                                        window.open('https://www.brigosha.com/join-us','_blank').focus();
                                    }}
                                    >Join Us</span><hr style={{border:"1.5px solid #E6E6E6"}}/>
                                    <span style={{display:"flex",alignItems:"center"}} className={classes.forward}
                                    onClick={()=>{
                                        window.open('https://www.brigosha.com/get-in-touch','_blank').focus();
                                    }}
                                    >Get in Touch</span><hr style={{border:"1.5px solid #E6E6E6"}}/>
                                    <span style={{display:"flex",alignItems:"center"}} className={classes.forward}
                                    onClick={()=>{
                                        window.open('https://www.brigosha.com/technology','_blank').focus();
                                    }}
                                    >Mobility Solutions</span><hr style={{border:"1.5px solid #E6E6E6"}}/>
                                    <span style={{display:"flex",alignItems:"center"}} className={classes.forward}
                                    onClick={()=>{
                                        window.open('https://www.brigosha.com/technology/connected-solutions','_blank').focus();
                                    }}
                                    >Connected Solutions</span>
                                    {/* <hr style={{border:"1.5px solid #E6E6E6"}}/> */}
                                    {/* <span style={{display:"flex",alignItems:"center"}} className={classes.forward}
                                    onClick={()=>{
                                        window.open('https://www.brigosha.com/about','_blank').focus();
                                    }}
                                    >About Us</span> */}
                                </div>
                                </div>

                                <div style={{display:"flex",alignSelf:"self-end",width: "95%",margin:"2rem auto"}}>
                                <div className={classes.footerDiv}>

                                    <div style={{display:"flex",flexDirection:"row",gap:"2rem",fontWeight:"bold"}} className={classes.footer}>
                                    <span>2023 brigosha technologies</span>
                                    <span>ISO 9001:2015 Certified Company</span>
                                    </div>

                                    <div className={classes.socialDiv}>
                                        <div>
                                        <img src={Fb} className={classes.socialMedia}
                                        onClick={()=>{
                                            window.open('https://www.facebook.com/brigosha/','_blank').focus();
                                        }}
                                        />
                                        </div>

                                        <div>
                                        <img src={Twitter} className={classes.socialMedia}
                                        onClick={()=>{
                                            window.open('https://twitter.com/Brigosha','_blank').focus();
                                        }}
                                        />
                                        </div>

                                        <div>
                                        <img src={linkedIn} className={classes.socialMedia}
                                        onClick={()=>{
                                            window.open('https://www.linkedin.com/company/brigosha/mycompany/','_blank').focus();
                                        }}
                                        />

                                        </div>

                                        <div>
                                        <img src={Youtube} className={classes.socialMedia}
                                        onClick={()=>{
                                            window.open('https://www.youtube.com/@BRIGOSHA','_blank').focus();
                                        }}
                                        />
                                        </div>
                                    </div>
                                </div>
                                </div>
                    </div>}
                    {tab==="Response"&&
                    <Response search={search}/>
                    }
                </div>
                <Modal show={openModal} onCancel={()=>setOpenModal(false)} 
                style={{width:width>800?"70vw":"95vw",height:"85vh",overflowY:"auto"}}
                closableRight={true}
                header={<div style={{color:"#000",display:"flex",justifyContent:"flex-start",width:"100%",fontWeight:"600",fontSize:"1.3rem"}}>Write to CEO</div>}
                >
                    <div style={{height:"100%",display:"flex",flexDirection:"column",width:"100%",padding:width>800?"1rem":0,gap:"1rem",}}>

                    <div >
                    <Input value={inputs?.subject} onChange={(e)=>{
                        setInputs({...inputs,subject:e.target.value})
                    }} style={{marginTop:"0rem",border:"1px solid #DFDFDF",height:"2.5rem",fontSize:"1rem",fontFamily:"Lato"}} placeholder="Subject"/>
                    </div>

                    {/* <div style={{height:"1px",width: "100%",background: "#F1F1F1",}}></div> */}

                <div style={{border:'1px solid #DFDFDF',borderRadius:"10px"}}>
                        <Input.TextArea 
                        rows={12}            
                        bordered={false}
                        value={inputs?.message}
                        style={{fontSize:"1rem",fontWeight:"500",fontFamily:"Lato"}}
                        onChange={(e)=>{
                            setInputs({...inputs,message:e.target.value})
                        }} placeholder="Type Here...."/>
                        <div className={classes.thanks}>
                            <span>Thanks & Regards,</span>
                            <span>{userData?.name}</span>
                        </div>
                        </div>
                

                    <div style={{display:"flex",flexDirection:"row",justifyContent:"right",alignItems:"center",gap:"1rem",marginTop:"1rem",marginBottom:"1rem", padding:"1rem 0 0 0",borderTop:"1px solid #EBEBEB"}}>
                        <Button style={{height:"2.1rem",width:"6rem",color:"#0086FF",borderColor:"#0086FF"}}
                        onClick={()=>{setOpenModal(false)}}
                        >Cancel</Button>
                        <Button onClick={()=>{
                            writeToCeo(user?.resourceUser?.id,inputs,()=>setOpenModal(false))
                        }} type="primary" style={{height:"2.1rem",width:"6rem"}} disabled={inputs?.subject?.trim()===''||inputs?.message?.trim()===''||inputs?.userId===''}>Send</Button> 
                    </div>
                    </div>
                </Modal>
                </div>
        </>
    )
}

export default CeoDesk;