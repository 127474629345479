import { DatePicker, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import '../../../design/framework.css'
import { useProjecteStore } from '../store/store'
import { DownloadData, getTaskLogsteam } from '../action/action'
import moment from 'moment'
import ButtonCompo from '../../../design/ButonCompo'
import downloadIcon from "../../../assets/download_Icon_black.svg";
import backIcon from "../../../assets/backIcon.svg";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";

import useApiStore from '../../../services/store'
import { AiOutlineLeft } from "react-icons/ai";
import TableCustom from '../../../components/tableCustom/customTable'
import { MobileCard } from '../Release/Release'
const EmployeeSummaryTeam = ({ data, setRender }) => {
    const { taskLogs, setTaskLogs } = useProjecteStore(state => state)
    const [startDateVal, setStartDateVal] = useState(null)
    const [endDateVal, setEndDateVal] = useState(null)
    const { loader } = useApiStore(state => state)
    const { width } = useWindowDimensions();

    const DateHandler = (key, value) => {
        if (key === 'start') {
            setStartDateVal(value)
        } else {
            setEndDateVal(value)
        }
    }



    const tableHeight = 48
    const scrollHeight = { y: `${tableHeight}vh` }
    const downLoadHandler = () => {
        let param = { userId: data?.id, download: true }
        if (startDateVal) {
            param = { ...param, startDate: startDateVal }
        }
        if (endDateVal) {
            param = { ...param, endDate: endDateVal }
        }
        DownloadData(param, (res) => {
            let fileName = res?.split('/')[4] + "_" + taskLogs?.name;
            console.log("fileName", fileName);
            const link = document.createElement('a');
            link.download = fileName;
            link.href = res;
            link.click();
            console.log(res);
        }, "tasks")
    }

    const colorCodes = {
        'New': "#B33DC6",
        'Ongoing': "#33A0FF",
        'Over Due': "#F46A9B",
        'Completed': "#24C850",
        'On Hold': "#EDBF33",
        'Suspended': "#878787",
    }
    useEffect(() => {
        getTaskLogsteam(setTaskLogs, { userId: data?.id, startDate: startDateVal, endDate: endDateVal })
       
    }, [startDateVal, endDateVal])

    console.log(taskLogs?.TableData,"taskLogs?.TableData?");

    const tableColumn = {
        0: {
            cols: "Task",
            width: "45%",
            innerWidth:"5rem",
            dataKey: "task",
            onclick: () => { }
        },
        1: {
            cols: "Start Date",
            width: "39%",
            dataKey: "startDate",
            onclick: () => { }
        },
        2: {
            cols: "End Date",
            width: "39%",
            dataKey: "endDate",
            onclick: () => { }
        },
        3: {
            cols: "Project",
            width: "40%",
            innerWidth:"5rem",
            dataKey: "project",
            onclick: () => { }
        },
        4: {
            cols: "Planned Effort",
            width: "35%",
            dataKey: "plannedEffort",
            onclick: () => { }
        },
        5: {
            cols: "Actual Effort",
            width: "35%",
            dataKey: "actualEffort",
            onclick: () => { }
        },
        6: {
            cols: "Status",
            width: "40%",
            dataKey: "statusM",
            onclick: () => { }
        },
        7: {
            cols: "On Time Delivery",
            width: "50%",
            dataKey: "onTimeD",
            onclick: () => { }
        },

    }




    return (
        <div style={{ padding: '10px 15px' }}>

            <div style={{ display: 'flex', width: '100%', marginBottom: "0.5rem", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: width < 700 ? 'column' : "row", justifyContent: "space-between", alignItems: width < 700 ? 'self-start' : "center", margin: "0 0 1.5rem 0" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: width < 700 ? "self-start" : "center", cursor: "pointer", width: width < 700 ? '100%' : '50%' }}>
                        <div style={{ display: "flex", justifyContent: "space-around", }}>
                            <AiOutlineLeft 
                            style={{marginTop:1}}
                            onClick={() => {
                                setRender({ id: "", page: 1 })
                            }} size={20} />
                            <div onClick={() => { setRender({ id: "", page: 1 }) }} style={{ fontSize: "1.2rem", fontWeight: "700", marginLeft:5 }}>Summary</div>
                        </div>
                        {width < 700 && <span onClick={downLoadHandler} style={{ fontWeight: "600", fontSize: "0.85rem", display: "flex", gap: "0.3rem", cursor: "pointer", marginRight: width < 700 ? '10px' : '0' }}>Download<img src={downloadIcon} style={{ width: "1rem", height: "1rem" }} /></span>}

                    </div>
                    <div style={{ display: "flex", flexDirection: width < 700 ? 'column' : 'row', justifyContent: "space-between", width: width < 700 ? '100%' : "50%", alignItems: width < 700 ? "start" : "center", marginTop: width < 700 ? 10 : 0 }}>
                        <div style={{ display: 'flex', alignItems: width < 700 ? 'flex-start' : 'center', gap: '12px', justifyContent: "space-between" }}>


                            <span>From</span>
                            <DatePicker
                            
                            size={width < 700 ? 'small' : 'large'} onChange={(date, dateString) => { DateHandler('start', dateString) }} />

                            <span style={{ marginLeft: width < 700 ? '0' : "1.5rem", }}>To</span>
                            <DatePicker
                             disabledDate={(current) => {
                                return current && current < moment(startDateVal);
                            }}
                            size={width < 700 ? 'small' : 'large'} onChange={(date, dateString) => { DateHandler('end', dateString) }} />

                        </div>
                        {width > 700 &&

                            <span onClick={downLoadHandler} style={{ fontWeight: "600", fontSize: "0.85rem", display: "flex", gap: "0.3rem", cursor: "pointer", marginRight: width < 700 ? '10px' : '0' }}>Download<img src={downloadIcon} style={{ width: "1rem", height: "1rem" }} /></span>
                        }
                    </div>

                </div>

            </div>
            <>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: "center", margin: "0 0 1rem 0" }}>
                    <div style={{ fontWeight: 'bold', paddingLeft: '5px', alignSelf: "flex-end" }}>{taskLogs?.TableData?.[0]?.userName}</div>

                </div>
                
                <div>
                    {
                        width < 700 ?
                            <>
                                {
                                    taskLogs?.TableData?.map((item, index) => (
                                        <MobileCard
                                            body={
                                                (
                                                    <>
                                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                            <div style={{ width: "30%", color: "#B1B0B0" }}>Start Date : </div>
                                                            <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.startDate}</div>
                                                        </div>
                                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                            <div style={{ width: "30%", color: "#B1B0B0" }}>End Date : </div>
                                                            <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.endDate}</div>
                                                        </div>
                                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                            <div style={{ width: "30%", color: "#B1B0B0" }}>Project : </div>
                                                            <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.project}</div>
                                                        </div>
                                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                            <div style={{ width: "30%", color: "#B1B0B0" }}>
                                                                Planned Effort : </div>
                                                            <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.plannedEffort}</div>
                                                        </div>
                                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                            <div style={{ width: "30%", color: "#B1B0B0" }}>Actual Effort : </div>
                                                            <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.actualEffort}</div>
                                                        </div>
                                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                            <div style={{ width: "30%", color: "#B1B0B0" }}>Status : </div>
                                                            <div style={{ width: "60%", display: "flex", justifyContent: "flex-start",color:colorCodes[item?.status] }}>{item?.status}</div>
                                                        </div>
                                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                            <div style={{ width: "30%", color: "#B1B0B0" }}>On Time Delivery : </div>
                                                            <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.ontimeDelivery?"Yes":"No"}</div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                            headerLeft={
                                                (
                                                    <h3>
                                                        {item?.task}
                                                    </h3>
                                                )
                                            } />
                                    ))
                                }

                            </>
                            :
                            <TableCustom charBreak={12} columns={tableColumn} dataSource={taskLogs?.TableData} loading={loader} /* pagination={
                            {
                                total: departmentData?.meta?.totalItems,
                                current: parseInt(paginationPage),
                                showSizeChanger: true,
                                pageSizeOptions: [10, 20, 30, 50, 100],
                                pageSize: departmentData?.meta?.itemsPerPage,
                                onChange: (page, pageSize) => {
                                    setPaginationPage(page);
                                    setPageSize(pageSize);
                                },
                            }} */ />
                    }


                    {/* <Table
                        //   key={index}
                        loading={loader}
                        style={{ width: '100%' }}
                        size='small'
                        rowClassName={'row-style'}
                        dataSource={taskLogs?.TableData}
                        columns={columnHeads}
                        scroll={scrollHeight}
                        pagination={{
                            position: "bottomRight",
                            pageSize: 15,
                            size: "small",
                            pageSizeOptions: [10, 30, 50, 100],
                            // pageSize: this.state.pageSize,
                            // current: this.state.page,
                            // total: candidatePageMeta?.totalItems
                            //   ? candidatePageMeta?.totalItems
                            //   : 0,
                            // onChange: (page, pageSize) => this.handlePageChange(page, pageSize),
                            showSizeChanger: true,
                            showTotal: (total, range) =>
                                `${range[0]}-${range[1]} of ${total} items`,
                        }}
                        rowKey={({ Date }) => Date}
                    /> */}
                </div>
            </>
        </div>
    )
}

export default EmployeeSummaryTeam