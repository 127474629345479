import { AiOutlineCheck, AiFillCheckCircle } from "react-icons/ai";

import checkIcon from "../../../assets/Appraisal/Check Icon.svg";

const ThankYou = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          marginTop: "10%",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          // border: "1px solid black",
        }}
      >
        <div
          style={{
            width: "7rem",
            height: "7rem",
            // border: "1px solid black",
            background: "#5FB760",
            display: "flex",
            justifyContent:'center',
            alignItems: "center",
            borderRadius: "100%",
          }}
        >
          <img style={{width:'60%'}} src={checkIcon}></img>
        </div>

        <h2 style={{fontFamily:'Roboto-Bold',marginTop:'1rem',marginBottom:'0'}}>THANK YOU! </h2>
        <h2 style={{fontFamily:'Roboto-Bold',margin:'0'}}>Appraisal form completed!</h2>
        <h2 style={{fontFamily:'Roboto-Regular'}}>We will get back to you shortly.</h2>

        {/* <div className="box">
          <h2>Thank You, you have completed your appraisal!</h2>
        </div> */}
      </div>
    </>

    // You have completed your appraisal, Thank you
  );
};

export default ThankYou;
