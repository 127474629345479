import React, { useState, useEffect } from "react";
import { Button, Modal, Radio, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
import downloadIcon from "../../../assets/Appraisal/downloadBlueIcon.svg";

import userWhite from "../../../assets/Appraisal/userIcon.svg";
import cycleWhite from "../../../assets/Appraisal/cycleColor.svg";
import { CloseOutlined } from "@ant-design/icons";
import Api from "../../../services/Api";
import { getDepartment, getMemberList } from "./actionsAdmin";
import { adminActions } from "./store";

function CommonModal({callBackTODownload}) {
  const dispatch = useDispatch();
  const [defaultValue, setDefaultValue] = useState("");
  const [defaultCycle, setDefaultCycle] = useState("");
  const [defaultStatus, setDefaultStatus] = useState("");

  const {
    designationModalShow,
    modalType,
    designationList,
    cycleList,
    defaultUserDesignation,
    defaultUserCycle,
    defaultUserStatus,
    Id,
    page,
    pageSize,
    appraisalFilter,
    designationFilter,
    cycleFilter,
    formStatusFilter,
    sorting,
    emailSort,
    searchText,statusList,
    summaryNotificationFilter,
  } = useSelector((state) => state.admin);

  const pageNumber =
    page !== "" || typeof page !== "undefined" ? `page=${page}` : "";
  const limit =
    pageSize !== "" || typeof pageSize !== "undefined"
      ? `&limit=${pageSize}`
      : "";
  const searchData =
    searchText === "" || typeof searchText === undefined
      ? ""
      : pageNumber === "" && limit === ""
      ? `search=${searchText}`
      : `&search=${searchText}`;

  const appraisalStatus =
    appraisalFilter === "" ||
    appraisalFilter === null ||
    appraisalFilter === undefined
      ? ""
      : `&appraisalStatus=${appraisalFilter}`;
  const desigFilter =
    designationFilter === "" ||
    designationFilter === null ||
    designationFilter === undefined
      ? ""
      : `&designation=${designationFilter}`;

  const cycFilter =
    cycleFilter === "" || cycleFilter === null || cycleFilter === undefined
      ? ""
      : `&appraisalCycle=${cycleFilter}`;
  const formstatusFilter =
    formStatusFilter === "" ||
    formStatusFilter === null ||
    formStatusFilter === undefined
      ? ""
      : `&status=${formStatusFilter}`;
  const sortingByName =
    sorting === "" || sorting === null || sorting === undefined
      ? ""
      : `&sort=${sorting}`;
  const sortingByEmail =
    emailSort === "" || emailSort === null || emailSort === undefined
      ? ""
      : `&emailsort=${emailSort}`;
  const summaryFilterStatus =
    summaryNotificationFilter === "" ||
    summaryNotificationFilter === null ||
    summaryNotificationFilter === undefined
      ? ""
      : `&summaryStatus=${summaryNotificationFilter}`;

  const downloadOptionsList = [
    "Complete Feedback Data",
    "Rank Data",
    "Work Efforts",
    "Associate Summary",
    "Work summary status",
    "Form status",
    "Manager status",
  ];

  const teamDownloadOption = [
    "Download team data",
    "Download rank and feedback",
  ];

  useEffect(() => {
    setDefaultValue(modalType === "Edit" && defaultUserDesignation);
    setDefaultCycle(defaultUserCycle);
    setDefaultStatus(defaultUserStatus);
  }, [defaultUserDesignation, defaultUserCycle, modalType]);

  console.log("ssssDefaultValue",defaultValue,statusList)

  const updatePatch = () => {
    if (modalType === "Edit") {
      Api.patch(`/appraisal-admin/users/?userId=${Id}`)
        .params({
          associateLevel: defaultValue,
          appraisalCycle: defaultCycle,
          appraisalActive: defaultStatus,
        })
        .send((response, error) => {
          if(response){
          dispatch(
            adminActions.updateUser_Cycle_Designation({
              ...{},
              defaultCycle: "",
              defaultLevel: "",
            })
          );
          dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
          dispatch(adminActions.updateDesignationModal(false));
          }
        });
      return;
    }
    if(modalType === "teamDataDownload"){

      callBackTODownload(defaultValue)
    }
    if (modalType === "AllUserDownload") {
      let phrase = "";
      if (defaultValue === "Complete Feedback Data") {
        Api.get(
          `/appraisal-admin/downloadAllFeedback?${searchData}${appraisalStatus}${desigFilter}${cycFilter}${formstatusFilter}${summaryFilterStatus}`
        ).send((response, error) => {
          if (response) {
            window.open(`${response}`);
          }
          dispatch(
            adminActions.updateUser_Cycle_Designation({
              ...{},
              defaultCycle: "",
              defaultLevel: "",
            })
          );
          dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
        });
      }
    }
    if (defaultValue === "Rank Data") {
      let value = "download=true";
      let phrase =
        value === "" || value === null || value === undefined
          ? ""
          : `&${value}`;
      Api.get(
        `/appraisal-admin/users?${searchData}${appraisalStatus}${desigFilter}${cycFilter}${formstatusFilter}${sortingByName}${sortingByEmail}${phrase}${summaryFilterStatus}`
      ).send((response, error) => {
        if (response) {
          window.open(`${response}`);
        }
        dispatch(
          adminActions.updateUser_Cycle_Designation({
            ...{},
            defaultCycle: "",
            defaultLevel: "",
          })
        );
        dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
      });
    }
    if (defaultValue === "Work Efforts") {
      Api.get(
        `/appraisal-admin/downloadTotalEffortHours?${searchData}${appraisalStatus}${desigFilter}${cycFilter}${formstatusFilter}${sortingByName}${sortingByEmail}${summaryFilterStatus}`
      ).send((response, error) => {
        if (response) {
          window.open(`${response}`);
        }
        dispatch(
          adminActions.updateUser_Cycle_Designation({
            ...{},
            defaultCycle: "",
            defaultLevel: "",
          })
        );
        dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
      });
    }
    if (defaultValue === "Associate Summary") {
      Api.get(
        `/appraisal-admin/downloadAssociateSummary?${searchData}${appraisalStatus}${desigFilter}${cycFilter}${formstatusFilter}${sortingByName}${sortingByEmail}${summaryFilterStatus}`
      ).send((response, error) => {
        if (response) {
          window.open(`${response}`);
        }
        dispatch(
          adminActions.updateUser_Cycle_Designation({
            ...{},
            defaultCycle: "",
            defaultLevel: "",
          })
        );
        dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
      });
    }
    if (defaultValue === "Work summary status") {
      Api.get(
        `/appraisal-admin/work-summary-status?${searchData}${appraisalStatus}${desigFilter}${cycFilter}${formstatusFilter}${sortingByName}${sortingByEmail}${summaryFilterStatus}`
      ).send((response, error) => {
        if (response) {
          window.open(`${response}`);
        }
        dispatch(
          adminActions.updateUser_Cycle_Designation({
            ...{},
            defaultCycle: "",
            defaultLevel: "",
          })
        );
        dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
      });
    }
    if (defaultValue === "Form status") {
      Api.get(
        `/appraisal-admin/form-status?${searchData}${appraisalStatus}${desigFilter}${cycFilter}${formstatusFilter}${sortingByName}${sortingByEmail}${summaryFilterStatus}`
      ).send((response, error) => {
        if (response) {
          window.open(`${response}`);
        }
        dispatch(
          adminActions.updateUser_Cycle_Designation({
            ...{},
            defaultCycle: "",
            defaultLevel: "",
          })
        );
        dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
      });
    }
    if (defaultValue === "Manager status") {
      Api.get(
        `/appraisal-admin/managerStatus?${searchData}${appraisalStatus}${desigFilter}${cycFilter}${formstatusFilter}${sortingByName}${sortingByEmail}${summaryFilterStatus}`
      ).send((response, error) => {
        if (response) {
          window.open(`${response}`);
        }
        dispatch(
          adminActions.updateUser_Cycle_Designation({
            ...{},
            defaultCycle: "",
            defaultLevel: "",
          })
        );
        dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
      });
    }
    // dispatch(adminActions.updateDesignationModal(false));
    setDefaultValue("");
    setDefaultCycle("");
    setDefaultStatus("")
  };

  const onCancel = () => {
    dispatch(adminActions.updateDesignationModal(false));
    dispatch(
      adminActions.updateUser_Cycle_Designation({
        ...{},
        defaultCycle: "",
        defaultLevel: "",
      })
    );
    setDefaultValue("");
    setDefaultCycle("");
    setDefaultStatus("")
  };

  const designationValue = () => {
    return (
      <Space style={{ margin: "1rem 0" }} direction="horizontal">
        {Object.keys(designationList).map((val, index) => {
          return (
            <Radio key={index} value={designationList[val]}>
              {designationList[val]}
            </Radio>
          );
        })}
      </Space>
    );
  };

  const appraisalValue = () => (
    <Space style={{ margin: "1rem 0" }} direction="horizontal">
      {Object.values(cycleList).map((val, index) => {
        return (
          <Radio key={index} value={val?.appraisalCycle}>
            {val?.appraisalCycle}
          </Radio>
        );
      })}
    </Space>
  );

  const statusValue = () => (
    <Space style={{ margin: "1rem 0" }} direction="horizontal">
          <Radio key={1} value={true}>
            Active
          </Radio>
          <Radio key={2} value={false}>
            Inactive
          </Radio>
    </Space>
  );

  const downloadOptions = () => (
    <Space style={{ marginTop: "0.1rem"}} direction="vertical">
      {downloadOptionsList.map((val, index) => (
        <Radio key={index} value={val}>
          {val}
        </Radio>
      ))}
    </Space>
  );

  const teamDownload = () => (
    <Space style={{ marginTop: "0.5rem" }} direction="vertical">
      {teamDownloadOption.map((val, index) => (
        <Radio key={index} value={val}>
          {val}
        </Radio>
      ))}
    </Space>
  );

  const valueOnChange = (e) => {
      setDefaultValue(e.target.value);
  };

  return (
    <Modal
      open={designationModalShow}
      onOk={updatePatch}
      width={modalType === "Edit"?"50%":"50%"}
      title={
        <div>
          {modalType === "Edit" ? (
            <h3
              style={{
                display: "flex",
                justifyContent: "center",width: "100%",
                // border:'1px solid black'
              }}
            >
              {/* <img
                src={userWhite}
                style={{ width: "2rem", height: "1.5rem" }}
              /> */}
              {/* <h3
                style={{
                  marginLeft: "1rem",
                  // color: "#0086FF",
                  fontFamily: "Roboto-Bold",display:"flex",justifyContent: "center"
                }}
              > */}
                Edit
              {/* </h3> */}
             </h3>
          ) : (
            <div
              style={{
                height: "3.5rem",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
                padding: "1rem",
              }}
            >
              <img
                src={downloadIcon}
                style={{ width: "1.2rem", height: "1.5rem" }}
              />
              <h3
                style={{
                  marginLeft: "0.5rem",
                  color: "#0086FF",
                  fontFamily: "Roboto-Bold",
                }}
              >
                Download User Data
              </h3>
            </div>
          )}
        </div>
      }
      footer={
        modalType === "AllUserDownload" || modalType === "teamDataDownload"
          ? [
              <Button
                onClick={updatePatch}
                type="primary"
                style={{ width: "7rem", height: "2.2rem",borderRadius:5 }}
                disabled={defaultValue === ""}
              >
                Download
              </Button>,
            ]
          : [
              <Button
                onClick={onCancel}
                style={{
                  width: "6.5rem",
                  height: "2.2rem",
                  marginRight: "0.5rem",borderRadius:5
                }}
                size="small"
              >
                Cancel
              </Button>,
              <Button
                size="small"
                onClick={updatePatch}
                type="primary"
                style={{
                  width: "6.5rem",
                  height: "2.2rem",borderRadius:5
                }}
              >
                OK
              </Button>,
            ]
      }
      onCancel={onCancel} centered
      closeIcon={<CloseOutlined style={{ color: "" }} />}
      bodyStyle={{
        height:
          modalType === "AllUserDownload"
            ? "40vh"
            : modalType === "teamDataDownload"
            ? "30vh"
            : "",
      }}
    >
      {modalType === "Edit" &&
      <div style={{display:"flex",padding:"1.5rem 3.5rem",alignItems:"center",justifyContent:"flex-start",width:"100%"}}>
      {modalType === "Edit"?
      <div style={{width:"100%"}}>
        <span style={{fontSize:"1rem",fontWeight:500}}>Level</span>
        <div style={{}}>
            Specify the employment classifications for the associate.
        </div>
        <Radio.Group style={{width:"100%",borderBottom:"1px solid #DFDFDF",marginBottom:"1rem"}}
          value={defaultValue}
          onChange={(e)=>{setDefaultValue(e.target.value);}}
        >
         
            {designationValue()}
            
        </Radio.Group>
        <span style={{ marginTop:"1rem"}}>Cycle</span>
        <div style={{}}>
          Customize the appraisal cycle for the associate.
        </div>
        <Radio.Group style={{width:"100%",borderBottom:"1px solid #DFDFDF",marginBottom:"1rem"}}
          value={defaultCycle}
          onChange={(e)=>{setDefaultCycle(e.target.value);}}
        >
            {appraisalValue()}
        </Radio.Group>
        <span style={{ marginTop:"1rem"}}>Appraisal status</span>
        <div style={{}}>
          Configure the associate’s appraisal status.
        </div>
        <Radio.Group style={{width:"100%",marginBottom:"1rem"}}
          value={defaultStatus}
          onChange={(e)=>{setDefaultStatus(e.target.value);}}
        >
          {statusValue()}
        </Radio.Group>
      </div>
      :""
      }
      </div>
      }
      {(modalType !== "Edit") &&
      <div
        style={{
          height: modalType === "AllUserDownload" ? "15vh" : "20vh",
          display: "flex",
          fontWeight: "bold",
          justifyContent: "flex-start",
          fontSize: "1rem",margin:"0 3.5rem"
        }}
      >
        <Radio.Group
          value={defaultValue}
          onChange={valueOnChange}
          style={{ marginTop: "0rem", fontSize: "1rem" }}
        >
          {
          // modalType === "Designation"
          //   ? designationValue()
            // : modalType === "Appraisal"
            // ? appraisalValue()
            modalType === "AllUserDownload"
            ? downloadOptions()
            : teamDownload()
            }
        </Radio.Group>
      </div>
      }
      {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
      </div> */}
    </Modal>
  );
}

export default CommonModal;
