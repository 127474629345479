import React from "react";
import { getTeamListData } from "./action";
import ModalCompo from "../../../../design/ModalCompo";
import downloadIcon from "../../../../assets/Download Icon Blue.svg";
import {Typography } from "antd";
import classes from './teampage.module.css';
import viewClass from "../../../../services/ViewRole";
const TeamListModal = ({ open, close, team,teamDetail }) => {
  
  const downloadData=()=>{
    if(viewClass.hrAssociate&&!viewClass.hrAdmin)
    {
      return;
    }
    getTeamListData(
      {associateDownload:true,
       teamId:team?.id},
      (res) => {
        window.open(res, "_blank")?.focus();     
     },
    );
  }
 

  return (
    <ModalCompo
      open={open}
      onCancel={() => {
        close(false);
      }}
      closableRight={true}
      className={classes.TeamListModal}
      header={
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection:"row",
              justifyContent: "space-between",marginLeft:"-3rem"
            }}
          >
            <Typography.Text ellipsis={{tooltip:true}} style={{maxWidth:'25vw',font:'normal 600 16px/20px Lato',color:'#162432'}}>Team: {team?.name}</Typography.Text>
            <div
              style={{
                display: "flex",
                gap:'6px',
                alignItems: "center",
                background: "white",
                width:'5rem',cursor:"pointer",
                marginRight:'4rem',color:"#0086FF",
                cursor:viewClass.hrAssociate&&!viewClass.hrAdmin?"not-allowed":"pointer",
              }}
              
              onClick={downloadData}
            >
             <div>Download</div>
              <img
                style={{ width: "1rem", height: "1rem" }}
                src={downloadIcon}
              />
            </div>
          </div>
        }>
     

      <div className={classes.WebViewList}  style={{background:'#fff',width:'100%'}}>
                <div className={classes.ListContainer}>
                    <div className={classes.ListHeader} style={{display:'flex',width:'100%'}}>
                          <div style={{width:'20%',justifyContent:'flex-start'}}>Associate Name</div>
                          <div style={{width:'15%'}}>Employee Id</div>
                          <div style={{width:'45%'}}>Contact Details</div>
                          <div style={{width:'20%'}}>Status</div>
                    </div>
                    <div className={classes.ListBodyContainer} style={{ maxHeight:'58vh', overflowY:'scroll',overflowX:'unset'}}>
                     
                     {
                     teamDetail?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><div>No data found !</div></div>:
                      teamDetail?.map((record,id)=>{
                        
                        return(
                          <div className={classes.rowContainer}>
                          <div style={{width:'20%',justifyContent:'flex-start',}}>
                              <div>{record?.name}</div>
                          </div>
                          <div style={{width:'15%'}}>
                          <div>{record?.uid}</div>
                          </div>
                          <div style={{width:'45%'}}>
                          <div style={{display:'flex',alignItems:'center',gap:'4px'}}>
                            <Typography.Text ellipsis={{tooltip:true}} style={{maxWidth:'15vw',font:'normal 400 16px/22px Lato'}} >
                              {record?.email}
                            </Typography.Text> 
                            <span>|</span>
                            <span >
                              {record?.phone}
                            </span>
                          </div>
                          </div>
                          <div style={{width:'20%'}}>
                              <div>{record?.status?record?.status:"NA"}</div>
                          </div>
                      </div>
                        )
                      })}
                    </div>
                </div>
                {/* <div style={{display:'flex',justifyContent:'flex-end',width:'100%'}} >
                      <Pagination 
                      onChange={handlePageChange}
                      showSizeChanger={true}
                      pageSize={pageLimit}
                      current={pageCurrent}
                      total={totalItems}
                      />
                </div> */}
              </div>
              <div className={classes.MobViewList} style={{maxHeight:'80vh',overflowY:'scroll'}}>
              <div className={classes.ListContainerMob} >
              {teamDetail?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><div>No data found !</div></div>:
              teamDetail?.map((record,id)=>{

                        return(
                          <div className={classes.MobListBody}>
                          <div>
                           
                            <div className={classes.listLine}>
                              <div className="header4Font">Associate Name: </div>
                              <Typography.Text className="header3Font" ellipsis={{tooltip:true}} style={{maxWidth:'150px',font:'normal 400 16px/22px Lato'}} >
                              {record?.name}
                              </Typography.Text>
                            </div>
                            <div className={classes.listLine}>
                              <div className="header4Font">Employee Id: </div>
                              <div className="header3Font">{record?.uid}</div>
                            </div>
                            <div className={classes.listLine}>
                              <div className="header4Font">Email Id: </div>
                              <Typography.Text className="header3Font" ellipsis={{tooltip:true}} style={{maxWidth:'150px',font:'normal 400 16px/22px Lato'}} >
                                {record?.email}
                              </Typography.Text>
                            </div>
                            <div className={classes.listLine}>
                              <div className="header4Font">Phone No.: </div>
                              <div className="header3Font"> {record?.phone}</div>
                            </div>
                            <div className={classes.listLine}>
                              <div className="header4Font">Status: </div>
                              <div className="header3Font"> {record?.status}</div>
                            </div>
                          </div>
                          

                        </div>)
                      })  } 
                    </div> 
             {/* {dashboardPage !== "Head Of Departments" && dashboardPage !== "Managers" &&   <div style={{display:'flex',justifyContent:'flex-end',width:'100%',padding:'0rem 2rem'}} >
                      <Pagination 
                      onChange={handlePageChange}
                      showSizeChanger={true}
                      pageSize={pageLimit}
                      current={pageCurrent}
                      total={totalItems}
                      />
                </div> }  */}
              </div>
      {/* <Table 
          columns={columns}
          scroll={{ y: 250 }}
          pagination={{
            position: "bottomRight",
            pageSize: pageLimit,
            size: "small",
            pageSizeOptions: [10,25, 30, 50, 100],
            current:pageCurrent,
            // total:totalItems,
            onShowSizeChange: handlePageChange,
            onChange: handlePageChange,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          dataSource={teamDetail}
        /> */}
      
    </ModalCompo>
  );
};

export default TeamListModal;
