import React, { useEffect, useState } from "react";
import { Button, Input, Table, Select } from "antd";
import Modal from "antd/lib/modal/Modal";
import Api from "../../../../services/Api";
import { useDispatch, useSelector } from "react-redux";

import teamWhite from "../../../../assets/Appraisal/TeamBlue.svg";
import departmentWhite from "../../../../assets/Appraisal/DepartmentBlueIcon.svg";
import { CloseOutlined } from "@ant-design/icons";
import dropIcon from "../../../../assets/Appraisal/downBlackIcon.svg";
import { globalActions } from "../../store/loader";
import { adminActions } from "../store";

function TeamModal(props) {
  const data = props?.data;
  const data1Drop = props?.data2;
  const data2Drop = props?.dataDrop;
  const operation = props?.operation;

  const { managerDropdown } = useSelector((state) => state.admin);
  const dataValue = props?.data1;
  const dispatch = useDispatch();
  // console.log("wwwwwwwww", props);
  const [disabled, setDisabled] = useState(true);
  const [isTeamDisabled, setIsTeamDisabled] = useState(true);
  const [isManagerDisabled, setIsManagerDisabled] = useState(true);
  const [editButton, setSaveButton] = useState(false);

  const [teamName, setTeamName] = useState();
  const [loader, setLoader] = useState(false);

  const [addTeam, setAddTeam] = useState();
  const [ManagerAddId, setManagerAddId] = useState();
  const [ManagerEditId, setManagerEditId] = useState();

  const [ManagerDrop, setManagerDpId] = useState();
  const [teamDrop, setTeamDrop] = useState([]);
  const [depId, setDepId] = useState();
  const [managerDrop, setManagerDrop] = useState();

  const [disableSave, setDisableSave] = useState(true);
  const [memberData, setMemberData] = useState([]);
  const [blockButton, setBlockButton] = useState(true);

  const [addDepartment, setAddDepartment] = useState();
  const [editDepartmentName, setEditDepartmentName] = useState();
  const [selectEditDepartment, setSelectEditDepartment] = useState();
  const [editTeamName, setEditTeamName] = useState();
  const [selectEditTeamName, setSelectEditTeamName] = useState();

  const Option = Select.Option;

  const handleEditTeamName = (value, key) => {
    setTeamName(value);
    console.log("selected Team Name", value, key.key);
    dispatch(globalActions.updateLoader({ loader: true }));
    Api.get(`/appraisal-admin/getManagerDropdownData`)
      // .params({ departmentId: depId, teamId: key.key })
      .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          response = response.map((ele) => {
            return {
              id: ele?.managerId,
              name: ele?.managerName,
            };
          });
          setManagerDrop(response);
        }
        dispatch(globalActions.updateLoader({ loader: false }));
      });
    setIsManagerDisabled(false);
  };

  useEffect(() => {
    setLoader(true);
    let newManagerDropDown = managerDropdown.map((ele) => {
      return {
        id: ele?.managerId,
        name: ele?.managerName,
      };
    });
    setManagerDrop(newManagerDropDown);
    // setManagerDrop(managerDropdown)
    Api.get(`/appraisal-admin/getManagerDropdownData`)
      .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          console.log("Add Manager ", response);
          response = response.map((ele) => {
            return {
              id: ele?.managerId,
              name: ele?.managerName,
            };
          });
          setManagerDpId(response);
          setLoader(false);
        }
      });
    Api.get(`/appraisal-admin/getTeamDropdown`)
      // .params({ departmentId: value })
      .send((response, error) => {
        if (typeof response !== "undefined") {
          console.log("Add Team users", response);
          setTeamDrop(response);
        }
      });
    console.log("depfault department", data);
  }, []);

  useEffect(() => {
    if (addTeam && depId && ManagerAddId) {
      setBlockButton(false);
    } else {
      setBlockButton(true);
    }
    if (addDepartment) {
      setBlockButton(false);
    }
  }, [addTeam, depId, ManagerAddId, addDepartment]);

  useEffect(() => {
    if (selectEditDepartment && editDepartmentName) {
      setBlockButton(false);
    } else {
      setBlockButton(true);
    }
  }, [selectEditDepartment, editDepartmentName]);

  useEffect(() => {
    if (editTeamName && selectEditTeamName) {
      setBlockButton(false);
    } else {
      setBlockButton(true);
    }
  }, [editTeamName, selectEditTeamName]);

  useEffect(() => {
    if (dataValue) {
      dispatch(globalActions.updateLoader({ loader: true }));
      Api.get(`/appraisal-admin/memberTeamData`)
        .params({ teamId: dataValue?.id })
        .send((response, error) => {
          if (
            typeof response !== "undefined" &&
            response?.show?.type !== "error"
          ) {
            console.log("team id", response);
            setMemberData(response);
            setLoader(false);
          } else {
          }
        });
    }
  }, []);

  const handleAddTeamName = (e) => {
    console.log("team Name", e.target.value);
    setAddTeam(e.target.value);
  };

  const handleAddManagerName = (value) => {
    console.log(`selectedManager ${value}`);
    setManagerAddId(value);
  };

  const handleAddDep = (value) => {
    console.log("selected dep is", value);
    setDepId(value);
  };

  const handleEditManagerName = (value) => {
    console.log(`EditManagerselected ${value}`);
    setManagerEditId(value);
    setDisableSave(false);
  };

  // console.log("maanaana",memberData);

  const postApi = () => {
    // console.log("clickeedpost");
    Api.post(`/appraisal-admin/createTeam`)
      .params({
        teamName: addTeam,
        managerId: ManagerAddId,
        departmentId: depId,
      })
      .send((response, error) => {
        if (typeof response !== "undefined") {
          props?.refresh();
          console.log("Add Team users", response);
        }
        props.updateTeamModalOpen(false);
      });
  };

  const handleTeamOk = () => {
    postApi();
  };

  const handleEditOk = () => {
    setDisabled(false);

    Api.patch(`/appraisal-admin/updateTeam`)
      .params({
        teamName: teamName || data?.name,
        departmentId: depId ? depId : data?.departmentId,
        managerId: ManagerEditId,
      })
      .send((response, error) => {
        if (typeof response !== "undefined") {
          console.log("Edited List Team users", response);
          props?.refresh();
          props?.updateShowEditModal(false);
        }
        dispatch(
          adminActions.updateTeamRefreshEffect((oldData) => oldData + 1)
        );
      });
  };

  const handleEdit = () => {
    setDisabled(false);
    setSaveButton(true);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "",
      key: "age",
      render: (val, row) => {
        return (
          <div>
            {/* {console.log("memeee",row?.user?.name)} */}
            {row?.user?.name}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "",
      key: "",
      render: (val, row) => {
        return (
          <div>
            {/* {console.log("memeee",row?.user?.status)} */}
            {row?.user?.status}
          </div>
        );
      },
    },
  ];

  const handleDepDrop = (value) => {
    console.log("selected dep", value);
    setDepId(value);
    Api.get(`/appraisal-admin/getTeamDropdown`)
      .params({ departmentId: value })
      .send((response, error) => {
        if (typeof response !== "undefined") {
          console.log("Add Team users", response);
          setTeamDrop(response);
        }
      });
    setIsTeamDisabled(false);
  };

  const handleDepartment = (e) => {
    setAddDepartment(e.target.value);
  };
  const handleCreateDepartment = () => {
    Api.post(`/appraisal-admin/createDepartment`)
      .params({ name: addDepartment })
      .send((response, error) => {
        if (typeof response !== "undefined") {
          // console.log(" department ", response);
          props.updateDepartmentModalOpen(false);
          // setTimeout(() => {
          //   window.location.reload(false)
          // }, 1000);
          props?.refresh();
        }
      });
  };

  const handleEditDepartmentName = (e) => {
    setEditDepartmentName(e.target.value);
  };

  const handleSelectEditDepartment = (value) => {
    setSelectEditDepartment(value);
  };

  const handleEditDepartment = () => {
    Api.patch("/appraisal-admin/editDepartment")
      .params({
        departmentId: selectEditDepartment,
        name: editDepartmentName,
      })
      .send((res) => {
        if (res?.type !== "error") {
          dispatch(
            adminActions.updateTeamRefreshEffect((oldData) => oldData + 1)
          )
          props.updateDepartmentModalOpen(false);
         
        }
      });
  };

  const handleChangeTeamName = () => {
    Api.patch("/appraisal-admin/editTeam")
      .params({
        teamId: selectEditTeamName,
        name: editTeamName,
      })
      .send((res) => {
        if (res?.show?.type !== "error") {
          dispatch(
            adminActions.updateTeamRefreshEffect((oldData) => oldData + 1)
          );
          props.updateTeamModalOpen(false);
          
        }
      });
  };

  return (
    <>
      <Modal // modal for Add Team members
        bodyStyle={{ height:props?.operationState === "Edit"? "20rem": "28rem" }}
        open={props?.TeamModalOpen}
        style={{ top: 50 }}
        onCancel={() => {
          props.updateTeamModalOpen(false);
        }}
        title={
          <div
            style={{
              height: "2rem",
              display: "flex",
              justifyContent: "flex-start",
              // padding: "1rem",
            }}
          >
            <img src={teamWhite} style={{ width: "2rem", height: "1.5rem" }} />
            <h3
              style={{
                marginLeft: "0.5rem",
                color: "#0086FF",
                fontFamily: "Roboto-Bold",
                fontSize: "1.3rem",
              }}
            >
              Team Information
            </h3>
          </div>
        }
        onOk={handleTeamOk}
        footer={null}
        width={1000}
        closeIcon={<CloseOutlined style={{ color: "" }} />}
      >
        {props?.operationState === "Edit" ? (
          <div style={{ height: "100%" }}>
            <div>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                  color: "#707070",
                }}
              >
                Edit team name
              </p>
            </div>
            <div
              style={{
                width: "100%",
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              <label>
                Team Name <span style={{ color: "#FF0000" }}>*</span>
                <Input
                  required
                  placeholder="Enter the team name"
                  onChange={(e) => {
                    setEditTeamName(e.target.value);
                  }}
                  style={{ marginTop: "0.8rem" }}
                />
              </label>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                fontSize: "1.2rem",
                fontWeight: "bold",
                flexDirection: "column",
              }}
            >
              <div style={{ marginTop: "1rem" }}>
                <label>
                  Team <span style={{ color: "#FF0000" }}>*</span>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    style={{ width: "100%", marginTop: "0.8rem" }}
                    placeholder="Choose Team name"
                    suffixIcon={<img src={dropIcon} />}
                    onChange={(value) => {
                      setSelectEditTeamName(value);
                    }}
                    // onSearch={onDepSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {teamDrop &&
                      Object.values(teamDrop).map((item) => (
                        <Option
                          key={item?.id}
                          value={item?.id}
                          label={item?.name}
                          title={item?.name}
                        >
                          {item?.name}
                        </Option>
                      ))}
                  </Select>
                </label>
              </div>
            </div>
            <div
              style={{
                textAlign: "center",
                height: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: "1rem",
              }}
            >
              <Button
                onClick={() => {
                  props.updateTeamModalOpen(false);
                }}
                style={{
                  marginRight: 8,
                  width: "7rem",
                  border: "1px solid #0086FF",
                  color: "#0086FF",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleChangeTeamName}
                disabled={blockButton}
                style={{ width: "7rem" }}
                type="primary"
              >
                Edit
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div>
              <p
                style={{
                  // fontFamily: "Roboto-Bold",
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                  // padding: "1rem",
                  color: "#707070",
                }}
              >
                Create a new team
              </p>
            </div>
            <div
              style={{
                width: "100%",
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              <label>
                Team Name <span style={{ color: "#FF0000" }}>*</span>
                <Input
                  required
                  placeholder="Enter the team name"
                  onChange={handleAddTeamName}
                  style={{ marginTop: "0.8rem" }}
                />
              </label>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                fontSize: "1.2rem",
                fontWeight: "bold",
                flexDirection: "column",
              }}
            >
              <div style={{ marginTop: "1rem" }}>
                <label>
                  Department <span style={{ color: "#FF0000" }}>*</span>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    style={{ width: "100%", marginTop: "0.8rem" }}
                    placeholder="Choose Department name"
                    suffixIcon={<img src={dropIcon} />}
                    onChange={handleAddDep}
                    // onSearch={onDepSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {data2Drop &&
                      Object.values(data2Drop).map((item) => (
                        <Option
                          key={item?.id}
                          value={item?.id}
                          label={item?.name}
                          title={item?.name}
                        >
                          {item?.name}
                        </Option>
                      ))}
                  </Select>
                </label>
              </div>
              <div style={{ marginTop: "1rem" }}>
                <label>
                  Manager Name <span style={{ color: "#FF0000" }}>*</span>
                  <Select
                    style={{ width: "100%", marginTop: "0.8rem" }}
                    placeholder="Choose manager name"
                    onChange={handleAddManagerName}
                    showSearch
                    optionFilterProp="children"
                    suffixIcon={<img src={dropIcon} />}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {managerDrop?.map((val) => (
                      <Option
                        key={val?.id}
                        value={val?.id}
                        label={val?.name}
                        title={val?.name}
                      >
                        {val?.name}
                      </Option>
                    ))}
                  </Select>
                </label>
              </div>
            </div>
            <div
              style={{
                textAlign: "center",
                height: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: "1rem",
              }}
            >
              <Button
                onClick={() => {
                  props.updateTeamModalOpen(false);
                }}
                style={{
                  marginRight: 8,
                  width: "7rem",
                  border: "1px solid #0086FF",
                  color: "#0086FF",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleTeamOk}
                disabled={blockButton}
                style={{ width: "7rem" }}
                type="primary"
              >
                Add
              </Button>
            </div>
          </div>
        )}
      </Modal>
      <Modal //modal for Edit button
        open={props?.showEditModal}
        onCancel={() => {
          props.updateShowEditModal(false);
        }}
        title={
          <div
            style={{
              height: "3rem",
              display: "flex",
              justifyContent: "flex-start",
              padding: "1rem",
            }}
          >
            <img
              src={teamWhite}
              style={{ width: "1.3rem", height: "1rem", marginTop: "0.2rem" }}
            />
            <h3
              style={{
                marginLeft: "0.5rem",
                color: "#0086FF",
                fontSize: "1.3rem",
                fontFamily: "Roboto-Bold",
              }}
            >
              Team Information
            </h3>
          </div>
        }
        onOk={handleEditOk}
        footer={null}
        width={1000}
        bodyStyle={{ minHeight: "80vh" }}
        closeIcon={<CloseOutlined style={{ color: "" }} />}
        style={{ top: 10 }}
      >
        <div>
          <p
            style={{
              // fontFamily: "Roboto-Italic",
              fontSize: "1.3rem",
              fontWeight: "bold",
              color: "#707070",
            }}
          >
            Summary of employee working in a team.
          </p>
        </div>
        <div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
          <div>
            Department Name <span style={{ color: "#FF0000" }}>*</span>
          </div>
          <Select
            style={{ width: "100%", marginTop: "1rem" }}
            disabled={!editButton}
            defaultValue={data?.departmentName}
            onChange={handleDepDrop}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            suffixIcon={<img src={dropIcon} />}
          >
            {data1Drop &&
              Object.values(data1Drop).map((item) => (
                <Option
                  key={item?.id}
                  value={item?.id}
                  label={item?.name}
                  title={item?.name}
                >
                  {item?.name}
                </Option>
              ))}
          </Select>
          <div style={{ marginTop: "2rem" }}>
            Team Name <span style={{ color: "#FF0000" }}>*</span>
          </div>
          <Select
            style={{ width: "100%", marginTop: "1rem" }}
            disabled={!editButton}
            defaultValue={data?.name}
            onChange={handleEditTeamName}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            suffixIcon={<img src={dropIcon} />}
          >
            {Object.values(teamDrop).map((item) => (
              <Option
                key={item?.id}
                value={item?.name}
                label={item?.teams_name}
                title={item?.teams_name}
              >
                {item?.name}
              </Option>
            ))}
          </Select>

          <div style={{ marginTop: "2rem" }}>
            Manager Name <span style={{ color: "#FF0000" }}>*</span>
          </div>
          <Select
            style={{ width: "100%" }}
            disabled={!editButton}
            defaultValue={data?.manager}
            onChange={handleEditManagerName}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            suffixIcon={<img src={dropIcon} />}
          >
            {managerDrop?.map((val) => (
              <Option
                key={val?.id}
                value={val?.id}
                label={val?.name}
                title={val?.name}
              >
                {val?.name}
              </Option>
            ))}
          </Select>
        </div>
        <div
          style={{
            textAlign: "center",
            height: "100%",
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginTop: "4rem",
          }}
        >
          <Button
            onClick={() => {
              props?.updateShowEditModal(false);
            }}
            style={{
              marginRight: "2rem",
              width: "7rem",
              border: "1px solid #0086FF",
              color: "#0086FF",
            }}
          >
            Cancel
          </Button>

          {editButton ? (
            <Button
              onClick={handleEditOk}
              disabled={disableSave}
              style={{
                width: "7rem",
              }}
              type="primary"
            >
              Save
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={handleEdit}
              style={{
                width: "7rem",
                // backgroundColor: "#D3D3D3",
                // border: "1px solid #D3D3D3",
              }}
            >
              Edit
            </Button>
          )}
        </div>
      </Modal>
      <Modal // modal for member
        visible={props?.teamNameModal}
        onCancel={() => {
          props?.SetTeamNameModal(false);
        }}
        style={{ borderRadius: "1.1rem" }}
        footer={null}
        width={800}
        bodyStyle={{ height: "30rem" }}
        // closeIcon={<CloseOutlined style={{color:"white"}} />}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            }}
          >
            <h2>{dataValue?.name}</h2>
          </div>
          <Table
            dataSource={memberData}
            columns={columns}
            loading={loader}
            size="small"
            scroll={{
              y: 300,
            }}
            pagination={{
              position: "bottomLeft", // change the position to "bottomLeft"
              // pageSize: pageSize, // set pageSize to 10 to display only 10 records
              size: "small",
              pageSizeOptions: [5, 10, 20, 30, 50],
              // current: Number(meta?.currentPage),
              // total: meta?.totalItems ? meta?.totalItems : 0,
              // onChange: (page, pageSize) => handlePageChange(page, pageSize),
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </div>
      </Modal>
      //create Department
      <Modal
        title={
          <div
            style={{
              height: "3rem",
              display: "flex",
              justifyContent: "flex-start",
              padding: "1rem",
            }}
          >
            <img
              src={departmentWhite}
              style={{ width: "2rem", height: "1.5rem" }}
            />
            <h3
              style={{
                marginLeft: "0.5rem",
                color: "#0086FF",
                fontWeight: "bold",
                fontSize: "1.2rem",
              }}
            >
              {props?.operationState === "Edit"
                ? "Edit Department"
                : "Department"}
            </h3>
          </div>
        }
        bodyStyle={{ height: "20rem" }}
        visible={props?.departmentModalOpen}
        onCancel={() => {
          props?.updateDepartmentModalOpen(false);
        }}
        style={{ borderRadius: "1.1rem" }}
        footer={null}
        width={1200}
        closeIcon={<CloseOutlined style={{ color: "" }} />}
      >
        {props?.operationState === "Edit" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                // marginLeft: "2rem",
                fontSize: "14px",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              <label style={{ fontSize: "1.1rem" }}>
                Department Name <span style={{ color: "#FF0000" }}>*</span>
              </label>
              <div style={{ width: "100%" }}>
                <Input
                  required
                  placeholder="Enter new name"
                  onChange={handleEditDepartmentName}
                  style={{ marginTop: "1rem" }}
                />
              </div>
            </div>

            <div style={{ marginTop: "1rem", fontWeight: "bold" }}>
              <label style={{ fontSize: "1.1rem" }}>
                Select Department <span style={{ color: "#FF0000" }}>*</span>
              </label>
              <Select
                allowClear={false}
                trigger="click"
                type="text"
                placement="bottomLeft"
                // bordered={false}
                dropdownMatchSelectWidth={false}
                onChange={handleSelectEditDepartment}
                // value={initialFilter}
                removeIcon={true}
                placeholder={<p>Please select the department</p>}
                style={{ width: "100%" }}
              >
                {data2Drop &&
                  Object.values(data2Drop).map((item) => (
                    <Option
                      key={item?.id}
                      value={item?.id}
                      label={item?.name}
                      title={item?.name}
                    >
                      {item?.name}
                    </Option>
                  ))}
              </Select>
            </div>

            <div
              style={{
                textAlign: "center",
                height: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginRight: "2rem",
              }}
            >
              <Button
                onClick={() => {
                  props.updateDepartmentModalOpen(false);
                }}
                style={{
                  marginRight: 8,
                  width: "7rem",
                  border: "1px solid #0086FF",
                  color: "#0086FF",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleEditDepartment}
                disabled={blockButton}
                style={{ width: "7rem" }}
                type="primary"
              >
                Save
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                  color: "#707070",
                  marginLeft: "2rem",
                }}
              >
                After creating department kindly add team
              </p>
            </div>
            <div
              style={{
                width: "95%",
                marginLeft: "2rem",
                fontSize: "14px",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              <label style={{ fontSize: "1.1rem" }}>
                Department Name <span style={{ color: "#FF0000" }}>*</span>
              </label>
              <div style={{ width: "100%" }}>
                <Input
                  required
                  placeholder="Create Department Name"
                  onChange={handleDepartment}
                  style={{ marginTop: "1rem" }}
                />
              </div>
            </div>

            <div
              style={{
                textAlign: "center",
                height: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginRight: "2rem",
              }}
            >
              <Button
                onClick={() => {
                  props.updateDepartmentModalOpen(false);
                }}
                style={{
                  marginRight: 8,
                  width: "7rem",
                  border: "1px solid #0086FF",
                  color: "#0086FF",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleCreateDepartment}
                disabled={blockButton}
                style={{ width: "7rem" }}
                type="primary"
              >
                Add
              </Button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
}

export default TeamModal;
