import CircleProgressBar from "../../../components/CircleProgressBar/CircleProgressBar";
import classes from './Project.module.css';

function ProjectSummary({data}){

    return(
        <div className={classes.graphMain} >
            <div style={{fontSize:"1.1rem",color:'#162432',fontWeight:"600"}}>Project Summary </div>
            <CircleProgressBar strokeWidth={20} percentage={data} sqSize={215} progressColor="#bbb6fa" strokeColor="#F2F2F2" />
        </div>
    )
}
export default ProjectSummary;