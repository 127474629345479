import React, { useEffect, useState } from "react";
import classes from "./feedback.module.css";
import { Typography, Button, Checkbox, Spin, Modal, Pagination } from "antd";
import poweredBy_icon from '../../assets/poweredBy_icon.svg';
import Notification from "../../services/Notification";
import feedbackSvg from "../../assets/Feedback-5.svg";
import feedbackStar from "../../assets/Feedback-rafiki 2.png";
import CreateIcon from "../../assets/createPlusIcon.svg";
import { AiFillDelete } from 'react-icons/ai';
import dashboardIcon from "../../assets/Dashboard.svg";
import CreateFeedbackModal from "./createFeedbackModal";
import Api from "../../services/Api";
import { useStore } from "./store";
import { useNavigate } from "react-router-dom";
import SearchCompo from "../../design/SearchCompo";
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";
import MobileNavModal from "../../components/MobileNavModal/MobileNavModal";
import { useHeaderStore } from "../Header/store";
import viewClass from "../../services/ViewRole";


const FeedbackManagement = () => {
    const navigate = useNavigate();
    const [selectcard, setSelectCard] = useState([]);
    const [loader, setLoader] = useState(false);
    const [feedbackContent, setFeedbackContent] = useState([1]);
    const [cardChecked, setcardChecked] = useState([]);
    const {createModal,updateCreateModal,feedbacksquestions,updateFeedbackData,updateSelectedCardCount,
          updateFeedbackQuestion,updateSelectedCards}=useStore();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize,setPageSize]=useState(20);
    const[search,setSearch]=useState('');
    const  items  = feedbacksquestions;
    const {width}=useWindowDimensions();
    const {sidemenu,setSidemenu}=useHeaderStore();

    useEffect(() => {
      viewClass.updateView();
      if (items) {
        const updated = [1].concat(items);
        setFeedbackContent(updated);
      }
    }, [items]);

    useEffect(() => {
      updateSelectedCardCount(selectcard.length);
    }, [selectcard]);
  

    console.log("SssResponse",items,feedbackContent)

    useEffect(() => {
      fetchCard();
    }, [search]);

    const fetchCard = () => {
      setLoader(true)
      Api.get(`/resource-feedback/getQuestionsData?search=${search}`)
        // .params({search: ,})
        .send((response) => {
          console.log("ssssRes",response?.items)
          if (typeof response != "undefined") {
            updateFeedbackQuestion(response?.items);
            setLoader(false);
          }
        });
    };

    const Deleteapi = () => {
      setLoader(true)
      Api.patch("/resource-feedback/updateStatus")
         .params({ ids: selectcard,status:"InActive" }).send(
        (response) => {
          if (typeof response != "undefined" && response?.show?.type === "success") {
            updateFeedbackQuestion(response);
            setSelectCard([]);
            setcardChecked([]);
            setLoader(false);
            setDeleteModalOpen(false)
            fetchCard();
           }
          else{
            setLoader(false);
          }
        }
      );
    };

    const handlePageChange = (page,pageSize) => {
      setCurrentPage(page);
      setPageSize(pageSize);
      
    };

    const showModal =()=>{
      updateCreateModal(true);
      updateFeedbackData(null);
    }
  
    
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, feedbacksquestions?.length);
  let paginatedData
  if(endIndex)
  {
    // console.log("feedbacksquestions",feedbacksquestions)
    paginatedData= feedbacksquestions?.slice(startIndex, endIndex);
    // console.log("paginatedData",paginatedData)
  }
 

    const confirmDelete = () => {
      setDeleteModalOpen(true)
    };
  return (
    <>
      {width<800&&<MobileNavModal sidemenu={sidemenu} setSidemenu={setSidemenu}>
     <div className={classes.sider}
     onClick={()=>{
      setSidemenu(false);
      navigate('/feedbackmanagement');
     
        window.location.reload();
      
     }}
     >
                  <div>
                              <img src={dashboardIcon}/>
                              <span>Dashboard</span>
                </div>
                <div>  
                  <img src={poweredBy_icon}></img>
                </div>
            </div>
      </MobileNavModal>}
        <div className={classes.container}>
            {width>800&&<div className={classes.sider}>
                  <div onClick={()=>{
                     window.location.reload();
                  }}>
                              <img src={dashboardIcon}/>
                              <span>Dashboard</span>
                </div>
                <div>  
                  <img src={poweredBy_icon}></img>
                </div>
            </div>}
                  <div className={classes.cardContainer}>
                        <div>
                                <h3 style={{fontWeight:"600",fontSize:"1.4rem"}}> Welcome to the Feedback management</h3>
                        
                        {selectcard.length > 0 ? (
                          <div className={classes.cloneButton}>
                            <Button onClick={() => {
                                if (selectcard.length > 1) {
                                  Notification.error("select only one feedback for clone","")
                                } else {
                                  showModal();
                                }
                              }}
                            >
                              Clone
                            </Button>
                            <Button onClick={confirmDelete} style={{color:"#fff"}}>
                              Delete
                            </Button>
                          </div>
                        ) :
                      <div className={classes.createButtonDiv}>
                          <SearchCompo style={{border:"1px solid #BEBEBE",height:"2.5rem",}}            
                          setSearch={
                            setSearch
                        }
                          />
                          { (viewClass.superAdmin || viewClass.hrAdmin)?(feedbacksquestions?.length> 0?<Button style={{color:"#fff"}} onClick={showModal}>Create</Button>:search?
                          <Button style={{color:"#fff"}} onClick={showModal}>Create</Button>:""):''
                          }
                        </div>
                        } 
              
                      </div>
                <div  className={classes.innerContainer}>
                {loader ? (
                <div className={classes.spinDiv}>
                    <Spin />
                </div>
                ) : 
                feedbacksquestions?.length ===0&& !search ? (
             (viewClass.superAdmin ||viewClass.hrAdmin)?  
             <div className={classes.firstPageCard}>
                  <h3>Feedback Form</h3>
                  <div>
                    <div>
                   {width>800&&<img src={feedbackSvg} />}
                   </div>
                    <div>
                      <div>
                      <img src={feedbackStar} />
                      </div>
                      <div>
                        <h4 style={{ fontWeight: "900" }}>Create Feedback</h4>
                        <p>Create and save perfect feedback form for every occasion.</p>
                        <Button type="primary"
                            onClick={showModal}                    
                        >
                          <img src={CreateIcon} style={{height:"1rem",width:"1rem"}}/>
                          <span style={{marginRight:"2.5rem"}}>CREATE</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>:''
                ) : (
                <div style={{display:"flex",flexDirection:"column",width:"100%",height:"100%",width:"100%",}}>
                {  feedbacksquestions?.length === 0&&search?
                  <div style={{display:"flex",height:"100%",fontWeight:"600",color:"rgba(22, 36, 50, 0.50)",alignItems:"center",justifyContent:"center",height:"50vh"}}>
                    <span>No Data to Display!</span>
                  </div>
                  :<div className={classes.cardContainersDiv}>
                {/* <div className={classes.creatFeedbackCard} >
                              <div style={{ display: "flex", flexDirection: "column",}}>
                                <h4 style={{ fontWeight: "700",marginTop:"0.5rem" }}>Create Feedback Form</h4>
                              </div>
                              <div>
                                <img style={{
                                    width: "7.5rem",
                                    height: "7rem",
                                  }} src={CreateImage} />
                              </div>
                              <div style={{height:"2.4rem",borderRadius:"0 0 10px 10px",marginTop: "rem",backgroundColor:"#0086FF",color:"white",fontWeight:"500",cursor:"pointer",
                                display:"flex",alignItems:"center",justifyContent:"center",textAlign:"center" }}
                              onClick={showModal}>
                                  <img src={CreateIcon} style={{height:"1rem",width:"1rem",marginRight:"0.5rem"}}/>
                                  <span style={{marginRight:"rem"}}> CREATE</span>                        
                              </div>
                </div> */}
                  {paginatedData?.map((item, index) => {
                    // if (index === 0) {
                        // return (
                            // <div className={classes.creatFeedbackCard} key={index}>
                            //   <div style={{ display: "flex", flexDirection: "column",}}>
                            //     <h4 style={{ fontWeight: "700",marginTop:"0.5rem" }}>Create Feedback Form</h4>
                            //   </div>
                            //   <div>
                            //     <img style={{
                            //         width: "7.5rem",
                            //         height: "7rem",
                            //       }} src={CreateImage} />
                            //   </div>
                            //   <div style={{height:"2.4rem",borderRadius:"0 0 10px 10px",marginTop: "rem",backgroundColor:"#0086FF",color:"white",fontWeight:"500",cursor:"pointer",
                            //     display:"flex",alignItems:"center",justifyContent:"center",textAlign:"center" }}
                            //    onClick={showModal}>
                            //       <img src={CreateIcon} style={{height:"1rem",width:"1rem",marginRight:"0.5rem"}}/>
                            //       <span style={{marginRight:"rem"}}> CREATE</span>
                            //      {/* CREATE */}
                            //   </div>
                            // </div>
                          // );
                        // }
                  

                    
                        return (
                          <div className={classes.feedbackDetailsCard} key={index} >
                            <div>
                                      <div>
                                        <Checkbox
                                        size="large"                             
                                        checked={index===cardChecked.find(element=>element===index)}
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              setcardChecked([...cardChecked,index]);
                                              setSelectCard([...selectcard, item?.id]);
                                              let selectesCards=[...selectcard, item?.id];
                                              updateSelectedCards(selectesCards);
                                            } else {
                                              setcardChecked(cardChecked.filter(element=>element!==index))
                                              setSelectCard(
                                                selectcard.filter((a) => a !== item?.id)
                                              );
                                              updateSelectedCards(selectcard.filter((a) => a !== item?.id));
                                            }
                                          }}
                                        ></Checkbox>
                                        {item?.status==="Publish"&&<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 0C5.37 0 0 5.37 0 12C0 18.63 5.37 24 12 24C18.63 24 24 18.63 24 12C24 5.37 18.63 0 12 0ZM18 5.34L20.1599 7.49991L10.4999 17.1599L5.33991 11.9999L7.49983 9.84L10.4998 12.84L18 5.34Z" fill="#39CA6C"/>
                                        </svg>}
                                      </div>
                              <div style={{cursor:"pointer",}} onClick={() => {
                                if(item?.status === "Active"){
                                  navigate('/fbround', {
                                    state: {
                                      id: item?.id,
                                    },
                                  });
                                }else if(item?.status === "Publish"){
                                  navigate('/fbPublish', {
                                    state: {
                                      id: item?.id,
                                    },
                                  });
                                }
                                }}>
                                <img
                                  src={item?.feedbackImage}
                                  style={{
                                    width: "100%",
                                    height: "19vh",
                                    margin:0
                                  }}
                                />
                              </div>
                            </div>
                            <div style={{ marginBottom: "0.5rem",marginTop:"0.2rem" }}>
                              <Button
                                type="text"
                                style={{ padding: "0",background:"none",border:"none" }}
                               
                              onClick={() => {
                                if(item?.status === "Active"){
                                  navigate('/fbround', {
                                    state: {
                                      id: item?.id,
                                    },
                                  });
                                }else if(item?.status === "Publish"){
                                  navigate('/fbPublish', {
                                    state: {
                                      id: item?.id,
                                    },
                                  });
                                }
                                }}>
                                <Typography.Text style={{maxWidth:width>800? "15vw":"80vw",color:"#000",fontSize:"1rem",fontWeight:"700",display:"flex"}} ellipsis={{tooltip:true}}>{item?.title}</Typography.Text> 
                              </Button>
                            </div>
                          </div>
                        );
                      
                              
                      })}
                </div>}
                <div style={{width:"100%",paddingRight:"1.5rem",marginTop:"auto",}}>
                  <Pagination position="bottomRight" size="middle" style={{display:"flex",justifyContent:"flex-end",marginTop:"0.5rem"}}
                  current={currentPage} showSizeChanger={true} total={feedbacksquestions?.length} pageSize={pageSize} 
                  onChange={handlePageChange} onShowSizeChange={handlePageChange} pageSizeOptions={[20,30,50]}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} />
                </div>
                </div>
                )}
                </div>
                <Modal open={deleteModalOpen} onCancel={()=>setDeleteModalOpen(false)} onOk={()=>Deleteapi()}
                centered width={850} footer={false} >
                <div>
                <div className='r-jc' style={{ display: 'flex', alignItems: 'center' }}>
                                <AiFillDelete className='p-2' color='#F96363' fontSize={25} />
                                <h3 style={{color:"#F96363"}}>Delete</h3>
                            </div>
                  <div style={{display:"flex",alignItems:"center",justifyContent:"center",marginTop:"2rem",marginBottom:"2rem"}}>
                    <h4>Are you sure you want to delete this feedback ? </h4>
                  </div>
                  <div style={{margin:"1rem",display:"flex",flexDirection:"row",gap:"1rem",width:"100%",justifyContent:"center"}}>
                    <Button style={{width:"5rem"}} type="primary" onClick={()=>Deleteapi()}>Yes</Button>
                    <Button style={{width:"5rem"}} onClick={()=>setDeleteModalOpen(false)}>No</Button>
                  </div>
                </div>
                </Modal>
                {(createModal)&&<CreateFeedbackModal fetchCard={fetchCard} setcardChecked={setcardChecked} setSelectCard={setSelectCard} />}
            </div>
        </div>
    </>
  )
}

export default FeedbackManagement