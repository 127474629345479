import React from "react";
import { Route, Navigate } from "react-router-dom";
import useStore from '../pages/store';

const PrivateRoute = ({ children }) => {
  const user=localStorage.getItem('user');

  if (!user) {
    return <Navigate to="/login"  />
  }

  return children;
};

export default PrivateRoute;


