import React, { useEffect, useState } from 'react'
import { Input, DatePicker, Table, Pagination } from 'antd';
import { TiTick } from "react-icons/ti";
import { getDBlogger, getTeamData } from '../AttendancePortal/Settings/Action/action';
import { DataComparisonModal } from './datamodal';
import SearchCompo from '../../design/SearchCompo';
import moment from 'moment';
const { Search } = Input;
const { RangePicker } = DatePicker;

export const debounce = (setter, delay) => {
    let timeoutId;
    return (val) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            setter(val);
        }, delay);
    };
};

const ActivityLogs = () => {
    const [selectedDept, setSelectDept] = useState()
    const [search, setsearch] = useState()
    const [data, setData] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [columns, setcolumns] = useState(
        [
            {
                title: 'User name',
                dataIndex: 'userName',
                key: 'name',
                width: ""

            },
            {
                title: 'Time stamp',
                dataIndex: 'createdAt',
                key: 'createdAt',


                onFilter: (value, record) => record.department.indexOf(value) === 0,
            },
            {
                title: 'Server service ',
                dataIndex: 'service',
                key: 'service',
                filters: [

                    // Add more filters as needed
                ],
            },


            {
                title: 'Environment',
                dataIndex: 'endPoint',
                key: 'count',
            },
            {
                title: 'Previous & updated data',
                dataIndex: 'count',
                key: 'count',
            },

        ]
    )
    const [loader, setLoader] = useState({
        table: false
    })
    useEffect(()=>{
        setsearch("")
        fetchDataDBLogger(1,10, null,selectedDept,startDate,endDate)
    },[selectedDept])
    const [tablePagniation, setTablePagination] = useState({
        pagination: {
            showSizeChanger: true,
            current: 1,
            pageSize: 10,
            total: 0,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
        },
    });
    const setterCol = (dept) => {
        setcolumns([
            {
                title: 'User name',
                dataIndex: 'userName',
                key: 'userName',

            },
            {
                title: 'Time stamp',
                dataIndex: 'createdAt',
                key: 'timest',


            },
            {
                title: 'Server service ',
                dataIndex: 'service',
                key: 'service',
                filters: dept,
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                    <div style={{ height: "35vh", boxShadow: " 0px 10px 15px -3px rgba(0,0,0,0.1)" }}>
                        <div style={{ padding: 10, height: "30vh", overflow: "scroll" }}>
                            {dept?.map((item, index) => (
                                <div key={index} style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", fontSize: 14, cursor: "pointer", padding: 5 }} onClick={() => {

                                    setSelectDept(item)
                                    confirm()
                                }}>
                                    {item}
                                    <span >
                                        {
                                            selectedDept === item &&
                                            <TiTick color='green' />
                                        }
                                    </span>
                                </div>
                            ))}

                        </div>
                        <div onClick={() => {
                            setSelectDept(undefined)
                            confirm()
                        }} style={{ marginLeft: 10, fontSize: 16, fontWeight: 600, color: "#1089FF", cursor: "pointer" }}>Reset</div>
                    </div>
                ),

            },
            /*  {
                 title: 'Previous data',
                 dataIndex: 'pre',
                 key: 'count',
             },
             {
                 title: 'Updated data',
                 dataIndex: 'curr',
                 key: 'count',
                 
             }, */
            {
                title: 'Environment',
                dataIndex: 'endPoint',
                key: 'count',

            },
            {
                title: 'Previous & updated data',
                dataIndex: '',
                key: '',
                render: (record) => (
                    <>
                        <div
                            onClick={() => {
                                setData([record?.newValue, record?.oldValue])
                                setIsModalVisible(true)
                            }}
                            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <a>
                                View
                            </a>
                        </div>
                    </>
                ),
            },
        ])
    }
    const [teamData, setTeamData] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

    const onRangeChange = (dates, dateStrings) => {
        // setSelectedDates(dates);
        setStartDate(dateStrings[0]);
        setEndDate(dateStrings[1]);
        fetchDataDBLogger(1,10, null,null,dateStrings[0],dateStrings[1])
      };


    useEffect(() => {
        fetchDataDBLogger(tablePagniation?.pagination?.current, tablePagniation?.pagination?.pageSize, search)
    }, [])

    const fetchDataDBLogger = (page = 1, pageSize = 10, search = "", service = null, startDate = null, endDate = null) => {
        setLoader((rev) => { return { ...rev, table: true } })
        getDBlogger({ page: page, limit: pageSize, search: search, service: service, startDate: startDate, endDate: endDate }, (res) => {
            let temp = res?.final?.items;
            let dept = res?.filters;
            temp = temp.map((obj, ind) => {
                return {
                    ...obj,
                    timest:(
                        <>
                        <div>{moment(obj?.createdAt).format("YYYY-MM-DD")}</div>
                        </>
                    )
                }
            })
            setTeamData(temp)
            setTablePagination((prevState) => ({
                ...prevState,
                pagination: {
                    ...prevState.pagination,
                    total: res?.final?.meta?.totalItems
                },

            }))
            setterCol(res?.filters)
            setLoader((rev) => { return { ...rev, table: false } })
        })
    }


    const handleTableChange = (page, pageSize) => {
        setTablePagination((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                current: page,
                pageSize: pageSize,
            },
        }))
        fetchDataDBLogger(page, pageSize, search)
        // fetchData(page, pageSize)
    };
    return (
        <div>
            <div style={{ display: "flex", height: "10vh", backgroundColor: "#FFFFFF", padding: 20 }}>
                <div>
                    <div style={{ fontWeight: 750, fontSize: 18 }}>
                        Activity Logs
                    </div>
                    <div style={{ fontWeight: 400, fontSize: 12 }}>
                        Keep an eye on your activity updates
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", padding: 20 }}>
                <div>
                <SearchCompo
                    style={{ width: 200, marginBottom: 10 }}
                    placeholder="Search"
                    value={searchValue}
                    setSearch={(e) => {
                        fetchDataDBLogger(1,10, e)
                        // fetchData(1, 10, e,(selectedDept && selectedDept?.id !== null) ? [selectedDept?.id] : null)
                    }}
                    onSearch={(e) => setSearchValue(e)}
                ></SearchCompo>
                </div>
                <div>
                <RangePicker onChange={onRangeChange} />
                </div>
            </div>
            <Table
                scroll={{ y: 330, x: 50 }}
                pagination={false}
                dataSource={teamData}
                columns={columns}
                loading={loader?.table}

            />

            <Pagination position="bottomRight" size="small" style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem" }}
                current={tablePagniation.pagination.current}
                pageSize={tablePagniation.pagination.pageSize}
                total={tablePagniation.pagination.total}
                showTotal={(total, range) => {
                    console.log(total, range, "jjjignnnkink");
                    return `${range[0]}-${range[1]} of ${total} items`
                }}
                onChange={handleTableChange}
                onShowSizeChange={handleTableChange}
            />
            {isModalVisible&&
                <DataComparisonModal
                visible={isModalVisible}
                onClose={() => setIsModalVisible(false)}
                data={data}
            />}
        </div>
    )
}

export default ActivityLogs