import { Button, DatePicker, Input, Select, Spin } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Api from "../../../services/Api";
import { adminActions } from "./store";
import { Color } from "../../../services/color";
import { CloseOutlined } from "@ant-design/icons";
import dropBlackIcon from "../../../assets/summarysropdownicon.svg";

import MinusIcon from "../../../assets/Appraisal/minusIcon.svg";
import EditList from "../../../assets/Appraisal/editListIcon.svg";
import PluseIcon from "../../../assets/addPlusIcon.svg";
import moment from "moment";
import DatePickerCompo from "../../../components/DatePicker/DatePickerCompo.tsx";

const WorkSummaryModal = () => {
  const { workSummaryModal, rowData, departmentDropdown, teamDropdown } =
    useSelector((state) => state.admin);

  const initialWorkSummary = rowData?.workSummary;

  let [workSummary, setWorkSummary] = useState(initialWorkSummary);
  let [deletedWorkSummary, setDeletedWorkSummary] = useState([]);
  const [errorIndex, setErrorIndex] = useState(0);
  const [error, setError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [durationError, setDurationError] = useState(false);
  const [teamNameError, setTeamNameError] = useState(false);
  const [errorType, setErrorType] = useState("");
  const [durationErrorType, setDurationErrorType] = useState("");
  const [teamErrorType, setTeamErrorType] = useState("");
  const [titleErrorType, setTitleErrorType] = useState("");

  const [depAssigned, setDepAssignedDate] = useState();
  const [releaseDate, setReleaseDate] = useState();
  const [monthDifference, setMonthDifference] = useState(0);

  const [departmentId, setDepartmentId] = useState();
  const [teamId, setTeamId] = useState();
  const [managerId, setManagerId] = useState();

  const [disableSave, setDisableSave] = useState(false);

  const [counts, setCounts] = useState([1]);
  const [btnTeam, setBtnTeam] = useState(true);
  const [btnManager, setBtnManager] = useState(true);
  const [managerDrop, setManagerDropdown] = useState();
  const [duration, setDuration] = useState();
  const [loader, setLoader] = useState(false);
  // const [releaseDate,setRealeaseDate] = useState()

  const dispatch = useDispatch();

  useEffect(() => {
    updateArray(rowData?.workSummary);
    // dispatch(adminActions.updateTeamDropDown([]));
    setDeletedWorkSummary(deletedWorkSummary);
  }, [rowData?.workSummary, deletedWorkSummary]);

  const updateArray = (data) => {
    if (data) {
      const tempworkSummary = sortWorkSummary(data);
      setWorkSummary(tempworkSummary);
    }
  };

  const sortWorkSummary = (workSummary) => {
    if (workSummary) {
      let tempworkSummary = [...workSummary];
      return tempworkSummary?.sort((a, b) => {
        let date1 = moment(a.startDate);
        let date2 = moment(b.startDate);
        return date1 - date2;
      });
    }
  };

  const handleOk = () => {
    let max_duration = 0;

    Api.patch("/appraisal-admin/work-summary/?userId=" + rowData.id)
      .params({ workSummary, deletedWorkSummary })
      .send((response) => {
        if (response !== "undefined" && response?.show?.type === "success") {
          setError(false);
          setDurationError(false);
          setTeamNameError(false);
          setTitleError(false);
          dispatch(adminActions.updateWorkSummaryModal(false));
          dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
          setDeletedWorkSummary([]);
          setManagerDropdown([]);
        }
      });

    if (workSummary?.length === 0 && deletedWorkSummary?.length === 0) {
      setTitleError(true);
      setTitleErrorType("Please add work Summary");
      return;
    }

    if (deletedWorkSummary?.includes(undefined) && workSummary?.length === 0) {

      setTitleError(true);
      setTitleErrorType("Please add work Summary");
      return;
    }

    if (
      JSON.stringify(
        workSummary.find((element) => JSON.stringify(element) === "{}")
      ) === "{}"
    ) {
      setTitleError(true);
      setTitleErrorType("Please fill the details");
      return;
    }

    if (workSummary?.length > 0) {
      const verifyObject = (objects) => objects?.duration && objects?.team;
      // Below code is to validate duplicate teams
      for (let i = 0; i <= workSummary.length - 1; i++) {
        if (workSummary[i].duration > 17) {
          setTitleError(true);
          setTitleErrorType(
            "Invalid Input! Work duration should be less than 18 Months."
          );
          return;
        }
        for (let j = 0; j <= workSummary.length - 1; j++) {
          if (i != j) {
            if (workSummary[i]?.team?.id === workSummary[j]?.team?.id) {
              setTitleError(true);
              setTitleErrorType("Please select a different team!");
              return;
            }
          }
        }
      }

      // Below code is to find the sum of all the duration should be less than or equal to 18 months
      // workSummary?.map((summary) => {
      //   if (summary?.duration) {
      //     max_duration += summary?.duration;
      //   }
      // });

      // if (max_duration > 17) {
      //   setDurationError(false);
      //   setTitleError(true);
      //   setTitleErrorType("Invalid input! Please add a valid work duration.");
      //   max_duration = 0;
      //   return;
      // }

      // Below code is to find either duration or team exist or not in all the objects
      if (!workSummary?.every(verifyObject)) {
        workSummary?.map((summary, index) => {
          if (!summary?.workDuration || summary?.workDuration === null) {
            setErrorIndex(index);
            setDurationError(true);
            setTeamNameError(false);
            setTitleError(false);
            setDurationErrorType("Please enter a vaild month duration!");
          }
          if (!summary?.team?.id) {
            setErrorIndex(index);
            setTeamNameError(true);
            setDurationError(false);
            setTitleError(false);
            setTeamErrorType("Please select team and manager name!");
          }
        });
        return;
      }
    }

    setError(false);
    setTitleError(false);
    setDurationError(false);
    setTeamNameError(false);
  };

  const onCancel = () => {
    setError(false);
    setDurationError(false);
    setTeamNameError(false);
    setTitleError(false);
    setDeletedWorkSummary([]);
    setWorkSummary([]);
    setDepAssignedDate("");
    setReleaseDate("");
    setManagerDropdown(null);
    dispatch(adminActions.updateWorkSummaryModal(false));
  };

  const deleteWorkSummary = (index) => {
    let newArray = [...workSummary];
    let newRowData = { ...rowData };
    setDeletedWorkSummary([...deletedWorkSummary, newArray[index].id]);
    newRowData.workSummary = newArray.filter(
      (item) => newArray.indexOf(item) !== index
    );
    dispatch(adminActions.updateRowData(newRowData));
    setError(false);
    setTeamNameError(false);
    setDurationError(false);
    if (counts.length > 0) {
      setCounts(counts.slice(0, counts.length - 1));
    }
    setWorkSummary(newRowData.workSummary);
  };

  const addWorkSummary = () => {
    setDepAssignedDate("");
    setReleaseDate("");
    setManagerDropdown(null);
    setDuration("");
    const newArray = [
      ...workSummary,
      ...[{ joined: rowData?.joined, workDuration: "" }],
    ];
    setWorkSummary(newArray);
    setCounts([...counts, counts?.length + 1]);
  };

  const handleAssignedDate = (index, e) => {
    const updateWorkSummary = [...workSummary];
    let calDiff = 0;
    setDepAssignedDate(e ? moment(e).format("YYYY-MM-DD") : null);

    if (
      updateWorkSummary[index].endDate !== null ||
      updateWorkSummary[index].endDate !== undefined ||
      updateWorkSummary[index].endDate !== ""
    ) {
      calDiff = calculateMonthDifference(
        moment(e).format("YYYY-MM-DD"),
        updateWorkSummary[index].endDate
      );
    }
    updateWorkSummary[index] = {
      ...updateWorkSummary[index],
      startDate: e ? moment(e).format("YYYY-MM-DD") : null,
      workDuration: calDiff < 0 ? 0 : calDiff,
    };

    setWorkSummary(updateWorkSummary);
  };

  const handleReleasedDate = (index, e) => {
    const updateWorkSummary = [...workSummary];
    let calDiff = 0;
    if (
      updateWorkSummary[index].endDate !== null ||
      updateWorkSummary[index].endDate !== undefined ||
      updateWorkSummary[index].endDate !== ""
    ) {
      calDiff = calculateMonthDifference(
        updateWorkSummary[index].startDate
          ? updateWorkSummary[index].startDate
          : moment(workSummary[index].startDate).format("YYYY-MM-DD"),
        moment(e).format("YYYY-MM-DD")
      );
    }

    updateWorkSummary[index] = {
      ...updateWorkSummary[index],
      endDate: e ? e : null,
      workDuration: calDiff < 0 ? 0 : calDiff,
    };
    setWorkSummary(updateWorkSummary);
  };

  const handleDepartmentSelect = (index, value) => {
    const filteredDep = departmentDropdown.filter((val) => val.id === value)[0];
    const updateWorkSummary = [...workSummary];

    setDepartmentId(value);
    setLoader(true);
    Api.get("/appraisal-admin/getTeamDropdown")
      .params({ departmentId: value })
      .send((response) => {
        if (typeof response !== "undefined") {
          dispatch(adminActions.updateTeamDropDown(response));
          updateWorkSummary[index] = {
            ...updateWorkSummary[index],
            dropDownTeam: response,
            team:
              response?.length === 1
                ? { id: response[0]?.id, name: response[0]?.name }
                : null,
            manager:
              response?.length === 1
                ? { id: response[0]?.managerId, name: response[0]?.managerName }
                : null,
          };
          setManagerDropdown(null);
          setBtnTeam(false);
        }
        setLoader(false);
      });

    updateWorkSummary[index] = {
      ...updateWorkSummary[index],
      department: filteredDep,
      departmentId: value,
    };
    setWorkSummary(updateWorkSummary);
  };

  const handleTeamSelect = (index) => (value) => {
    const updateWorkSummary = [...workSummary];
    setTeamId(value);
    setLoader(true);
    Api.get("/appraisal-admin/getManagerDropdown")
      .params({ departmentId: departmentId, teamId: value })
      .send((response) => {
        if (typeof response !== "undefined") {
          // dispatch(adminActions.updateManagerDropDown(response));
          updateWorkSummary[index] = {
            ...updateWorkSummary[index],
            manager: [response],
          };
          setManagerDropdown(response);
          setBtnManager(false);
        }
        setLoader(false);
      });

    updateWorkSummary[index] = {
      ...updateWorkSummary[index],
      team: teamDropdown.filter((item) => item.id === value)[0],
    };
    setWorkSummary(updateWorkSummary);
  };

  const handleManagerSelect = (value, index) => {
    setManagerId(managerDrop.id);
    const updateWorkSummary = [...workSummary];
    updateWorkSummary[index] = {
      ...updateWorkSummary[index],
      manager: { id: managerDrop.id, name: managerDrop?.name },
    };
    setWorkSummary(updateWorkSummary);
    setManagerDropdown(null);
  };

  const handleDuration = (index, e) => {
    if (e.target.valueAsNumber !== NaN) {
      setDuration(e.target.valueAsNumber);
      const updateWorkSummary = [...workSummary];
      updateWorkSummary[index] = {
        ...updateWorkSummary[index],
        workDuration: e.target.value,
      };
      setWorkSummary(updateWorkSummary);
    }
  };

  const calculateMonthDifference = (startDate, endDate) => {
    if (startDate && endDate) {
      const start = moment(startDate);
      const end = moment(endDate);
      var duration = end.diff(start, "months");
      return Math.round(duration);
    } else {
      return 0;
    }
  };

  return (
    <Modal
      destroyOnClose={true}
      bodyStyle={{ padding: 0, height: "85vh" }}
      width={"55%"} centered
      // style={{ top: 1, position: "relative" }}
      visible={workSummaryModal}
      onCancel={onCancel}
      onOk={handleOk}
      okText="Save"
      cancelText="Cancel"
      footer={false}
      closeIcon={false}
    >
      <div
        style={{
          // backgroundColor: "#0086FF",
          // height: "3.5rem",
          display: "flex",
          justifyContent: "center",
          // padding: "1rem",
          // border:'1px solid black'
        }}
      >
        {/* <img
          src={EditList}
          style={{ width: "1.5rem", height: "1.2rem", marginTop: "0.4rem" }}
        /> */}
        <h3 style={{fontSize: "1.2rem" }}>
          Work Summary
        </h3>
      </div>

      <div
        style={{
          fontSize: "0.9rem",
          margin: "1rem 2rem",
          zIndex: 1,
          display: "flex",
          flexDirection: "row",justifyContent:"space-between"
        }}
      >
        <div style={{display:"flex",flexDirection:"column"}}>
        <span style={{color:"#959595"}}>Name:</span>
        <span style={{fontWeight:450}}>{rowData?.name}</span>
        </div>
        <div style={{display:"flex",flexDirection:"column"}}>
        <span style={{color:"#959595"}}>Employee ID:</span>
        <span style={{fontWeight:450}}>{rowData?.employeeId}</span>
        </div>
        <div style={{display:"flex",flexDirection:"column"}}>
        <span style={{color:"#959595"}}>Date of joining:</span>
        <span style={{fontWeight:450}}>{rowData?.joined}</span>
        </div>
        
      </div>

      <div
        style={{
          height: "65%",
          overflow: "auto",
          // border: "1px solid black",
          marginTop: "15px",
        }}
      >
        {workSummary?.length > 0
          ? workSummary?.map((item, index) => {
              return (
                <div 
                // style={{borderTop:"1px solid #DFDFDF"}}
                >
                  <div // delete icon
                    key={index}
                    style={{
                      width: "98%",
                      marginLeft:"0.6rem",
                      display: "flex",flexDirection:"row",
                      justifyContent: "flex-end",
                      padding: "0.7rem 1rem 0.1rem 1rem",
                      alignItems:"center",
                      cursor: "pointer",borderTop:"1px solid #DFDFDF"
                    }}
                  >
                      <img onClick={() => deleteWorkSummary(index)}
                        src={MinusIcon}
                        style={{
                          width: "0.9rem",
                          marginRight: "0.2rem",
                          marginTop: "0.1rem",
                        }}
                      />
                    <h4 style={{fontWeight:400,color:"red"}} onClick={() => deleteWorkSummary(index)}>Remove</h4>
                  </div>
                  <div
                    style={{
                      // border: "1px solid #E8E8E8",
                      width: "100%",
                      // height: "20rem",
                      padding: "0 1.5rem 1.5rem 1.5rem",
                      // borderRadius: "4px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "1rem",  
                        fontWeight:500,gap:"1rem",
                        fontSize: "0.95rem",
                        width: "100%",
                      }}
                    >
                      {/* <div style={{width:"50%"}}>
                        <label>
                          Joined Date{" "}
                          <span style={{ color: Color.red }}>*</span>
                        </label>
                        <div>
                          <DatePickerCompo
                            // onChange={handleJoinedDate}
                            disabled
                            style={{ width: "100%" }}
                            value={
                              item?.joined
                                ? moment(item?.joined)
                                : moment(rowData?.joined)
                            }
                            format={"YYYY-MM-DD"}
                          />
                        </div>
                      </div> */}
                      <div style={{width:"50%"}}>
                        <label style={{color:"#959595"}}>
                          Department <span style={{ color: Color.red }}>*</span>
                        </label>
                        <div>
                          <Select
                            showSearch size="medium"
                            key={index}
                            onChange={(e) => handleDepartmentSelect(index, e)}
                            style={{ width: "100%",fontSize:"0.8rem",borderRadius:2}}
                            value={item?.department?.name}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            suffixIcon={<img src={dropBlackIcon} />}
                          >
                            {departmentDropdown &&
                              Object.values(departmentDropdown)?.map((item) => (
                                <Select.Option
                                  key={item?.id}
                                  value={item?.id}
                                  label={item?.name}
                                  title={item?.name}
                                >
                                  {item?.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </div>
                      </div>
                      <div style={{width:"50%"}}>
                        <label style={{color:"#959595"}}>
                          Team Name <span style={{ color: Color.red }}>*</span>
                        </label>
                        <div>
                          <Select size="medium"
                            key={index}
                            onChange={handleTeamSelect(index)}
                            style={{ width: "100%",fontSize:"0.8rem" }}
                            value={
                              item?.dropDownTeam?.length === 1
                                ? item?.dropDownTeam[0]?.name
                                : item?.team?.name
                            }
                            disabled={
                              item?.dropDownTeam?.length > 0 ? false : btnTeam
                            }
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            suffixIcon={<img src={dropBlackIcon} />}
                            placeholder="Please Select the team"
                          >
                            {item?.dropDownTeam?.map(
                              (teamDropdownItem, index) => {
                                return (
                                  <Select.Option
                                    key={teamDropdownItem?.id}
                                    value={teamDropdownItem?.id}
                                    label={teamDropdownItem?.name}
                                    title={teamDropdownItem?.name}
                                  >
                                    {teamDropdownItem?.name}
                                  </Select.Option>
                                );
                              }
                            )}
                          </Select>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "1rem",gap:"1rem",
                        fontWeight:500,
                        fontSize: "0.95rem",
                        width: "100%",
                      }}
                    >
                      <div style={{width:"50%"}}>
                        <label style={{color:"#959595"}}>
                          Manager Name{" "}
                          <span style={{ color: Color.red }}>*</span>
                        </label>
                        <div>
                          <Select size="medium"
                            key={index}
                            onChange={(value) =>
                              handleManagerSelect(value, index)
                            }
                            style={{ width: "100%",fontSize:"0.8rem" }}
                            value={
                              item?.dropDownTeam?.length === 1
                                ? item?.dropDownTeam[0]?.managerName
                                : item?.manager !== null &&
                                  Array.isArray(item?.manager)
                                ? item?.manager[0]?.name
                                : item?.manager?.name
                                ? item?.manager?.name
                                : item?.dropDownTeam?.filter(
                                    (val) => val?.name === item?.team?.name
                                  )[0]?.managerName
                            }
                            disabled={
                              item?.dropDownTeam?.length > 0
                                ? false
                                : btnManager
                            }
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            suffixIcon={<img src={dropBlackIcon} />}
                            placeholder="Please Select the Manager"
                          >
                            {Array.isArray(item?.manager) &&
                            item?.manager !== null ? (
                              item?.manager?.map((ele) => (
                                <Select.Option
                                  key={ele?.id}
                                  value={ele?.id}
                                  label={ele?.name}
                                  title={ele?.name}
                                >
                                  {ele?.name}
                                </Select.Option>
                              ))
                            ) : (
                              <Select.Option
                                key={item?.manager?.id}
                                value={item?.manager?.id}
                                label={item?.manager?.name}
                                title={item?.manager?.name}
                              >
                                {item?.manager?.name}
                              </Select.Option>
                            )}
                          </Select>
                        </div>
                      </div>
                      <div style={{width:"50%"}}>
                        <label style={{color:"#959595"}}>
                          Department Assigned Date{" "}
                          <span style={{ color: Color.red }}>*</span>
                        </label>
                        <div>
                          <DatePickerCompo size="medium"
                            key={index}
                            onChange={(e) => {
                              handleAssignedDate(index, e);
                            }}
                            style={{ width: "100%",fontSize:"0.8rem" }}
                            value={
                              item?.startDate ? moment(item?.startDate) : null
                              // depAssigned
                              // ? moment(depAssigned)
                              // : null
                            }
                            format={"YYYY-MM-DD"}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",gap:"1rem",
                        marginTop: "1rem",
                        fontWeight:500,
                        fontSize: "0.95rem",
                        width: "100%",
                      }}
                    >
                      <div style={{width:"50%"}}>
                        <label style={{color:"#959595"}}>
                          Release Date{" "}
                          {/* <span style={{ color: Color.red }}>*</span> */}
                        </label>
                        <div>
                          <DatePickerCompo size="medium"
                            key={index}
                            onChange={(e) => handleReleasedDate(index, e)}
                            style={{ width: "100%",fontSize:"0.8rem" }}
                            disabledDate={(current) => {
                              return (
                                current && current < moment(item?.startDate)
                              );
                            }}
                            value={
                              item?.endDate
                                ? moment(item?.endDate)
                                : releaseDate
                                ? moment(releaseDate)
                                : null
                            }
                            defaultValue={moment(item?.releasedDate)}
                          />
                        </div>
                      </div>
                      <div style={{width:"50%"}}>
                        <label style={{color:"#959595"}}>Duration</label>
                        <div>
                          <Input size="medium"
                            type="number"
                            onChange={(e) => handleDuration(index, e)}
                            defaultValue={
                              item?.workDuration ? item?.workDuration : duration
                            }
                            style={{ width: "100%",fontSize:"0.8rem" }}
                            addonAfter="Months"
                            autoFocus={index === workSummary.length - 1}
                            value={
                              // item?.workDuration
                              //   ?
                              item?.workDuration < 0 ? 0 : item?.workDuration
                              // : duration < 0
                              // ? 0
                              // : duration
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div
                      style={{
                        display: "flex",
                        marginTop: "1rem",
                        // marginLeft: "3.2rem",
                        fontWeight: "bold",
                        fontSize: "1rem",
                        width: "100%",
                      }}
                    >
                     
                    </div> */}
                  </div>
                </div>
              );
            })
          : null}
      </div>

      <div
        style={{
          display: "flex",
          marginTop: "1.5rem",
          justifyContent: "space-between",alignItems:"center"
        }}
      >
        <div onClick={() => addWorkSummary()}>
          <h5
            style={{
              color: "#FD9644",
              fontWeight:500,
              marginLeft: "2rem",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",fontSize:"0.95rem"
            }}
          >
            <img
              src={PluseIcon}
              style={{
                width: "1rem",
                marginBottom: "0.2rem",marginRight:"0.4rem"
              }}
            />
            Add Summary
          </h5>
        </div>

        <div style={{ marginRight: "2rem" }}>
          <Button
            style={{
              width: "8rem",
              border: " 1px solid #0086FF",
              borderRadius: "4px",
              color: "#0086FF",
              marginRight: "2rem",
            }}
            onClick={onCancel}
          >
            Cancel
          </Button>

          <Button
            type="primary"
            style={{
              width: "8rem",
              border: " 1px solid #0086FF",
              borderRadius: "4px",
            }}
            onClick={handleOk}
            disabled={disableSave}
          >
            Save
          </Button>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end" }}></div>
      {/* </>
      )} */}
    </Modal>
  );
};

export default WorkSummaryModal;
