import React, { useState } from "react";
import dashboardIcon from "../../assets/Dashboard.svg";
import dashboardInactive from "../../assets/dashboard_inactive.svg"
import employeesActiveTab from "../../assets/employeesActive.svg";
import employeesInactiveTab from "../../assets/employessInactive.svg";
import department_grey_icon from '../../assets/Department_grey_icon.svg';
import department_blue_icon from '../../assets/department_blue_icon.svg';
import summary_active_icon from '../../assets/summary_active_icon.svg';
import summary_inactive_icon from '../../assets/summary_inactive_icon.svg';
import feedback_portal_active from '../../assets/feedback_portal_active.svg';
import feedback_portal_inactive from '../../assets/feedback_portal_inactive.svg';


import "../../design/framework.css";
import SiderLayout from "../../components/siderLayout";
import CeoDesk from "./ceoDesk";
import Layout from "./Layout";


function FeedbackMainPage() {
  const [navUrl,setNavUrl]=useState([
    {
    key:1,
    title:"Dashboard",
    url:"/ceoDesk/dashboard",
    inactive:dashboardInactive,
    active:dashboardIcon,
  },
  {
    key:2,
    title:"Feedback Portal",
    url:"summary",
    inactive:feedback_portal_inactive,
    active:feedback_portal_active,
  },
  {
    key:3,
    title:"Summary",
    url:"summary",
    inactive:summary_inactive_icon,
    active: summary_active_icon,
  },
  {
    key:4,
    title:"Department",
    url:"department",
    inactive:department_grey_icon,
    active:department_blue_icon,
  },
 {
  key:5,
  title:"Associates",
  url:"associates",
  inactive:employeesInactiveTab,
  active:employeesActiveTab,
 }
])
let path=window.location.href;


  return (
    <Layout navlist={navUrl} />
  );
}

export default FeedbackMainPage;