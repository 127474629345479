import React from "react";
import classes from "./AddNewsModal.module.css";
import Modal from "../../components/Modal/Modal";
import { Form, Input, Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { ModalPrimaryButton, ModalSecondaryButton } from "../../components/Button/Button";
import { EditNewsLetterApi, addNewsLetterApi, getNewsLetterDataApi } from "./Action";
import { useNewsLetterStore } from "./Store";
import Api from "../../services/Api";
const AddNewsModal=(props)=>{
    const [form]=Form.useForm();
    const {newsLetterDataHandler}=useNewsLetterStore();
    const isEdit=props?.isEdit;
    const getData=props?.getData;
    const screenType=props?.screenType;
    const editableNews=props?.editableNews;
    const search=props?.search;
    const status=props?.status;
    const formProps=isEdit?{
        layout:"vertical",
        form:form,
        initialValues:{
            title:editableNews?.title,
            author:editableNews?.author,
            content:editableNews?.content,
            image:editableNews?.image,
        }
    }
    :
    {
        layout:"vertical",
        form:form,
    }

    console.log("editableNews",editableNews)
    const validateWhitespace = (_, value) => {
        if (!value||value.trim() === '') {
          return Promise.reject('Input cannot be empty');
        }
        return Promise.resolve();
      };

      const validateImage=(_,value)=>{
        console.log("form.getFieldValue",form.getFieldValue(['image'])?.fileList?.length)
        if(!isEdit&&(!value||form.getFieldValue(['image'])?.fileList?.length===0))
        {
            return Promise.reject('PLease upload an image!');
        }
        return Promise.resolve();
      }
    return(
        <Modal 
        show={props.addNewsModal}
        onCancel={()=>{
            props.setAddNewsModal(false);
            props?.setEditNewsLetter(false);
        }}
        // style={{height:"90vh",overflowY:"auto"}}
        header={<div className={classes.modalHeader}>
            <h3>{isEdit?"Edit":"Add"} {screenType==="newsletter"?"Newsletter":"Announcement"}</h3>
        </div>}
        closableRight={true}
        >
            {props.addNewsModal&&<Form 
            {...formProps}
            className={classes.addNewsForm}
            onFinish={(event)=>{
              
               
                if(!isEdit)
                {
                    event={...event,image:event?.image?.fileList?.[0]?.originFileObj,}
                    if(screenType==="newsletter")
                    {
                        props?.setLoader(true);
                        addNewsLetterApi(event,()=>{
                            getNewsLetterDataApi(newsLetterDataHandler,search,status,()=>{
                                props.setLoader(false);       
                            });                     
                        });  
                    }
                    else{
                        props?.setLoader(true);
                        Api.post("/resource-announcement/createAnnounce")
                        .upload(event, (per,response) => { 
                         console.log("sssuploadResponse",response)
                         if (response!==undefined && per===100) {   
                           form.resetFields();
                           getData();  
                           props?.setLoader(false);     
                         }
                         
                     });

                    }
                    
                    form.resetFields();              
                }
                else if(isEdit)
                {
                    event={...event,image:event?.image?.fileList?.[0]?.originFileObj,newsletterId:editableNews?.id}
                    if(screenType==="announcement")
                    {
                        delete(event['newsletterId'])
                        event={...event,id:editableNews?.id}

                    }
                  console.log("Type Of IMG", event["image"])
                  if(event["image"]===undefined)
                  {
                    delete(event["image"]);
                  }                  
                    // event={...event,newsletterId:editableNews?.id,}
                    if(screenType==="newsletter")
                    {
                        props?.setLoader(true);
                        EditNewsLetterApi(event,()=>{
                            getNewsLetterDataApi(newsLetterDataHandler,search,status,()=>{
                                props?.setLoader(false);       
                            });                      
                        });
                    }                  
                    else{
                        props?.setLoader(true);
                        Api.patch(`/resource-announcement/editAnnouncement`).uploadAndPatch(event, (percentCompleted, response) => {
                            if (typeof response != "undefined") 
                            {
                                console.log("EDITNewsAPI",response)  
                                getData(); 
                                form.resetFields();  
                                //props?.setLoader(false);                                                   
                            }
                            
                          });
                    }
                }
               
                  props.setAddNewsModal(false);
                  props?.setEditNewsLetter(false);
            }}
            >
                <Form.Item name="title" label={<span className={classes.itemTitle}>Title</span>} rules={[{required:true,message:"Please enter the title!",validator:validateWhitespace}]}>
                    <Input type="text" placeholder="Enter title" size="large"/>
                </Form.Item>

                <Form.Item name="author" label={<span className={classes.itemTitle}>Written By</span>} rules={[{required:true,message:"Please enter the name!",validator:validateWhitespace}]}>
                    <Input type="text" placeholder="Enter name"  size="large"/>
                </Form.Item>
                <Form.Item name="image" label={<span className={classes.itemTitle}>Image</span>}  rules={[{required:true,message:"Please insert an image!",validator:validateImage}]}>
                    <Upload.Dragger  maxCount={1} accept=".svg, .png, .jpg, .jpeg,"
                    // style={{border:0}}
                    action={null}
                    showUploadList={true}   
                    beforeUpload={(e)=>{
                        const fileType=e.type;
                            let allowableFile=[ 'image/jpeg', 'image/jpg', 'image/png',  'image/svg+xml'];
                            if(!allowableFile.includes(fileType))
                                    {                                       
                                        message.error('You can only upload PDF files!');
                                        return Upload.LIST_IGNORE ;
                                    }                  
                    }}
                    itemRender={(existingCompo,file)=><div className={classes.itemRender}>
                        {existingCompo}
                    </div>}            
                    >
                    <div style={{textAlign:"center",display:"flex",flexDirection:"column",flexWrap:"nowrap",alignItems:"center"}}>
                <p className="ant-upload-drag-icon">
                  {editableNews?.image&&isEdit?<img src={editableNews?.image} style={{width:"2rem",height:"2rem"}}/>:<InboxOutlined />}
                </p>
                <p style={{color:"#ABABAB",fontSize:"0.8rem"}}>
                  Click or drag file to this area to upload
                </p>
               </div>
                    </Upload.Dragger>
                </Form.Item>
                <Form.Item name="content" label={<span className={classes.itemTitle}>Description</span>} rules={[{required:true,message:"Please enter the description!",validator:validateWhitespace}]}>
                    <Input.TextArea type="text" rows={5} placeholder="Enter Description"/>
                </Form.Item>
                <div className={classes.buttonDiv}>
                    <ModalPrimaryButton style={{width:"7rem"}} htmlType="submit">{isEdit?"Save":"Add"}</ModalPrimaryButton>
                    <ModalSecondaryButton style={{width:"7rem"}} onCancel={()=>{
                        form.resetFields();
                        props.setAddNewsModal(false);
                        props?.setEditNewsLetter(false);
                    }}>Cancel</ModalSecondaryButton>
                </div>
                
            </Form>}

        </Modal>
    )
}
export default AddNewsModal;