import Api from "../../../services/Api";
import moment from "moment";
import { actiondis, ionsdis, progressdis } from "./ProjectTable";
import { useFlagButtonStore } from "../Release/store";

export const getAllProjectApi=(setMetadata,params=null,callback,callbackData)=>{
    
    Api.get("/project-projects/allProjects").params(params).send((res)=>{
       
        if(res!==undefined){
            //console.log(res?.meta?.totalItems,"PROJECTSCOUNT1");
            setMetadata(res?.meta);
            
            callback(res?.output);
           
        }
    })
}
export const getAllProjectsCountApi=(params=null,callback,callbackData)=>{
    
    Api.get("/project-projects/allProjects").params(params).send((res)=>{
       
        if(res!==undefined){
            //console.log(res?.meta?.totalItems,"PROJECTSCOUNT1");
            //setMetadata(res?.meta);
            
            callback(res);
           
        }
    })
}
export const getFilterData=(params,callback)=>{
    Api.get("/project-projects/getAllData").params(params).send((res)=>{
        console.log("filterrrr",res);
        if(res!==undefined){
            callback(res);
        }
    })
}

export const getFilterApi=(ref=false,params,callback)=>{
    Api.get(`/project-projects/getFilterData${!ref?"Web":""}`).params(params).send((res)=>{
        if(res!==undefined){
            callback(res);
        }
    })
}
export const AddProjectApi=(params,callback)=>{

    Api.post("/project-projects/createProjects").params(params).send((res)=>{
console.log(res,"create project")
        if( res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        }
    })
}

export const deleteProjectApi=(params,callback)=>{
    Api.delete('/project-projects/deletproject').params(params).send((res)=>{
        if(res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        }
    })
}
export const EditProjectApi=(id,params,callback)=>{

    Api.patch(`/project-projects/updateProject/?projectId=${id}`).params(params).send((res)=>{
        if(res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        } 
    })
}

export const getAllTaskApi =(params,callback)=>{

    Api.get("/project-tasks/getAllTask").params(params).send((res)=>{
        if(res!=undefined){
            callback(res)
        }
    })
}

export const getAllUserTaskApi =(params,callback)=>{

    Api.get("/project-tasks/userTasks").params(params).send((res)=>{
        if(res!=undefined){
            callback(res)
        }
    })
}

export const getUserSearchApi =(callback)=>{

    Api.get("/project-tasks/users").send((res)=>{
        if(res!=undefined){
            callback(res)
        }
    })
}



export const DeleteTaskApi=(params,callback)=>{

    Api.delete("/project-tasks/deletTask").params(params).send((res)=>{
        if(res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        }
    })
}
export const DeleteTasksApi=(params,callback)=>{

    Api.delete("/project-tasks/deleteTasks").params(params).send((res)=>{
        if(res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        }
    })
}
export const UpdateTasksApi=(params,callback)=>{

    Api.post("/project-tasks/changeTasksStatus").params(params).send((res)=>{
        if(res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        }
    })
}
//transferTasks
export const TransferTasksApi=(params,callback)=>{

    Api.post("/project-tasks/transferTasks").params(params).send((res)=>{
        if(res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        }
    })
}
export const TaskAssociatesApi=(params,callback)=>{

    Api.get(`/project-team/all-team-members?userId=${params?.userId}`).send((res)=>{
        if(res!==undefined && res?.show?.type!=="error"){
            callback(res)
        }
        else{
            callback(false)
        }
    })
}
export const getAllProjectOnly=(callback,projectId)=>{

    Api.get("/project-tasks/getAllProjectIds").params({projectId:projectId}).send((res)=>{
        if(res!=undefined){
            callback(res)
        }
    })
}

export const taskTransferApi=(params,callback)=>{
    Api.patch("/project-tasks/transferTask").params(params).send((res)=>{
        if(res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        }
    })
}

export const getAllTeamMember=(params,callback)=>{
    Api.get("/project-tasks/getAllTeamMembers").params(params).send((res)=>{
        if(res!==undefined){
            callback(res)
        }
    })
}
export const AddTaskApi=(id,params,callback)=>{

    Api.post(`/project-tasks/createTask?projectId=${id}`).params(params).send((res)=>{
        console.log(res);
        if(res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        }
    })
}

export const setTeamLeadApi=(params,callback)=>{
Api.post("/project-projects/teamLead").params(params).send((res)=>{
    if(res!==undefined && res?.show?.type!=="error"){
        callback(true)
    }
    else{
        callback(false)
    }
})
}
export const getTeamLeadApi=(params,callback)=>{
    Api.get("/project-projects/getTeamLeads").params(params).send((res)=>{
        if(res!==undefined){
            callback(res)
        }
    })
}

export const resetTeamLeadApi=(params,callback)=>{
    Api.delete("/project-projects/deleteTeamLead").params(params).send((res)=>{
        if(res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        } 
    })
}
export const updateTaskApi=(id,params,callback)=>{
    Api.patch(`/project-tasks/updateTask/?taskId=${id}`).params(params).send((res)=>{
        if(res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        }  
    })
}

export const getDepartmentId=(params,callback)=>{
    Api.get("/project-projects/getAllDepartmentId").params(params).send((res)=>{
        // console.log("department id",res);
        callback(res)
    })
}
export const DownloadApi=(params,callback)=>{
    Api.get("/project-projects/allProjects").params(params).send((res)=>{
        console.log(res)
        if(res!==undefined){
            callback(res)
        }
    })
}

export const calculateHrs=(params,callBack)=>{
    console.log(params?.day,"dayparam");
    Api.get(`/project-tasks/taskLogs?userId=${params?.userId}&startDate=${params.startDate}&dueDate=${params?.endDate}&taskId=${params?.taskId}&day=${params?.day}&rate=${params?.rate}`).send((res)=>{
        console.log(res,"CHECKINGCAL")
        if(res){
            
            if(res.show){
                callBack("")
            }else{
             callBack(res)
            }
        }

    })
}