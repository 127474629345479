import { Button, Input, Tabs,Modal } from "antd";
import React, { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../../services/Api";
import { adminActions } from "../store";
import UserTable from "./user-table";
import searchIcon from "../../../../assets/search_Icon.svg";
import filterIconss from "../../../../assets/filterUserIcon.svg";
import filterIcons22 from "../../../../assets/filterUserIcons22.svg";
import "../../styles/index.css";
import classes from "./users.module.css";
import DownloadIcon from "../../../../assets/downloadOrange.svg";
import SearchIcon from "../../../../assets/Appraisal/search icon.svg";
import priviledgeUserIcon from "../../../../assets/Appraisal/priviledgeUserIcon.svg";
import UsersModal from "./usersModal";
import { globalActions } from "../../store/loader";
import SearchCustom from "../../components/SearchCustom";
import TabButtons from "../../components/TabButtons";
import ApplyFilter from "../../components/FilterModal/ApplyFilter";
import FilterModal from "./FilterModal";
import { useUsersStore } from "./store";
import { updateSummaryAll } from "../actionsAdmin";
const Admin = () => {
  const {
    page,
    pageSize,
    refreshEffect,
    appraisalFilter,
    designationFilter,
    cycleFilter,
    formStatusFilter,
    managerFormStatusFilter,
    roleFilter,
    sorting,
    emailSort,
    searchText,
    tabState,
    summaryNotificationFilter,
    sortByEmployeeID,
  } = useSelector((state) => state.admin);

  const [preModal, setPreModal] = useState(false);
  const [sendModal, setSendModal] = useState(false);
  const [localSearchText, setLocalSearchText] = useState("");
  const [localSearch, setLocalSearch] = useState(searchText);
  const [initialFilter, setInitialFilter] = useState({
    appraisalFilter: appraisalFilter,
    designationFilter: designationFilter,
    cycleFilter: cycleFilter,
    formStatusFilter: formStatusFilter,
    managerFormStatusFilter: managerFormStatusFilter,
    summaryNotificationFilter: summaryNotificationFilter,
    roleFilter:roleFilter,
  });
  const {search,setSearch} = useUsersStore((state) => state);
const [updateSummaryModal,setUpdateSummaryModal]=useState(false)
  const [filterVisible, setFilterVisible] = useState(false);
  const dispatch = useDispatch();


  const [isAprilCycle,setIsAprilCycle]=useState(new Date().getMonth()>=0 && new Date().getMonth()<=6)
  const items = [
    {
      key: "users",
      label: (
        <div
          style={{
            fontWeight: "bold",
            fontSize: "1.1rem",
            color: tabState === "users" ? "#162432" : "#A1A1A1",
          }}
        >
          All Users
        </div>
      ),
      children: <UserTable />,
    },
    {
      key: "active april users",
      label: (
        <div
          style={{
            fontWeight: "bold",
            fontSize: "1.1rem",
            color: tabState === "active april users" ? "#162432" : "#A1A1A1",
          }}
        >
          Active for April cycle
        </div>
      ),
      children: <UserTable April={tabState} />,
    },

    {
      key: "active october users",
      label: (
        <div
          style={{
            fontWeight: "bold",
            fontSize: "1.1rem",
            color: tabState === "active october users" ? "#162432" : "#A1A1A1",
          }}
        >
          Active for October cycle
        </div>
      ),
      children: <UserTable October={tabState} />,
    },
    {
      key: "inactive users",
      label: (
        <div
          style={{
            fontWeight: "bold",
            fontSize: "1.1rem",
            color: tabState === "inactive users" ? "#162432" : "#A1A1A1",
          }}
        >
          Inactive Users
        </div>
      ),
      children: <UserTable />,
    },
  ];

  const tabItems = [
    {
      title: "All Users",
      key: "users",
      active: tabState === "users",
      component: <UserTable />,
    },
    {
      title: "Active for April cycle",
      key: "active april users",
      active: tabState === "active april users",
      component: <UserTable />,
    },
    {
      title: "Active for October cycle",
      key: "active october users",
      active: tabState === "active october users",
      component: <UserTable />,
    },
    {
      title: "Inactive Users",
      key: "inactive users",
      active: tabState === "inactive users",
      component: <UserTable />,
    },
  ];

   
  useEffect(() => {
     console.log("initialFilterinitialFilter",initialFilter)
     dispatch(adminActions.updateFilter(initialFilter));
    dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
  }, [initialFilter]);

  const pageNumber =
    page !== "" || typeof page !== "undefined" ? `page=${page}` : "";

  const limit =
    pageSize !== "" || typeof pageSize !== "undefined"
      ? `&limit=${pageSize}`
      : "";

  const searchData =
    search === "" || typeof search === undefined
      ? ""
      : pageNumber === "" && limit === ""
      ? `search=${search}`
      : `&search=${search}`;

  const appraisalStatus =
    appraisalFilter === "" ||
    appraisalFilter === null ||
    appraisalFilter === undefined
      ? ""
      : `&appraisalStatus=${appraisalFilter}`;

  const desigFilter =
    designationFilter === "" ||
    designationFilter === null ||
    designationFilter === undefined
      ? ""
      : `&designation=${designationFilter}`;

  const cycFilter =
    cycleFilter === "" || cycleFilter === null || cycleFilter === undefined
      ? ""
      : `&appraisalCycle=${cycleFilter}`;

  const formstatusFilter =
    formStatusFilter === "" ||
    formStatusFilter === null ||
    formStatusFilter === undefined
      ? ""
      : `&status=${formStatusFilter}`;

  const sortingByName =
    sorting === "" || sorting === null || sorting === undefined
      ? ""
      : `&sort=${sorting}`;

  const sortingByEmail =
    emailSort === "" || emailSort === null || emailSort === undefined
      ? ""
      : `&emailsort=${emailSort}`;

  const sortByID =
    sortByEmployeeID === "" ||
    sortByEmployeeID === null ||
    sortByEmployeeID === undefined
      ? ""
      : `&employeeIdSort=${sortByEmployeeID}`;
  const summaryFilterStatus =
    summaryNotificationFilter === "" ||
    summaryNotificationFilter === null ||
    summaryNotificationFilter === undefined
      ? ""
      : `&summaryStatus=${summaryNotificationFilter}`;

      const rolefilter= roleFilter === "" ||
      roleFilter === null ||
      roleFilter === undefined
        ? "":
      `&role=${roleFilter}`

  const getUserData = () => {
    dispatch(globalActions.updateLoader({ loader: true }));
    console.log("change tabsssss", appraisalStatus);
    Api.get(`/appraisal-admin/users`
      // `/appraisal-admin/users?${pageNumber}${limit}${searchData}${appraisalStatus}${desigFilter}${cycFilter}${formstatusFilter}${sortingByName}${sortingByEmail}${summaryFilterStatus}${sortByID}${rolefilter}`
      )
      .params({
        search : search === "" || typeof search === undefined ? "" : pageNumber === "" && limit === ""
         ? search
        : search,
        page: page !== "" || typeof page !== "undefined" ? page : "",
        limit: pageSize !== "" || typeof pageSize !== "undefined" ? pageSize : "",
        appraisalStatus:  appraisalFilter === "" ||
          appraisalFilter === null ||
          appraisalFilter === undefined
            ? ""
            : appraisalFilter,
        designation: designationFilter === "" ||
          designationFilter === null ||
          designationFilter === undefined ? "" : designationFilter,
        appraisalCycle: cycleFilter === "" || cycleFilter === null || cycleFilter === undefined
            ? ""
            : cycleFilter,
        status:formStatusFilter === "" ||
          formStatusFilter === null ||
          formStatusFilter === undefined
            ? ""
            : formStatusFilter,
        ManagerStatus: managerFormStatusFilter === "" ||
            managerFormStatusFilter === null ||
            managerFormStatusFilter === undefined
            ? ""
            : managerFormStatusFilter,
        sort: sorting === "" || sorting === null || sorting === undefined
            ? ""
            : sorting,
        emailsort: emailSort === "" || emailSort === null || emailSort === undefined
            ? ""
            : emailSort,
        employeeIdSort: sortByEmployeeID === "" ||
          sortByEmployeeID === null ||
          sortByEmployeeID === undefined
            ? ""
            : sortByEmployeeID,
        summaryStatus: summaryNotificationFilter === "" ||
          summaryNotificationFilter === null ||
          summaryNotificationFilter === undefined
            ? ""
            : summaryNotificationFilter,
        role: roleFilter === "" ||
            roleFilter === null ||
            roleFilter === undefined
              ? "":
            roleFilter
        })
      .send((response, error) => {
        if (typeof response !== "undefined") {
          console.log("All users", response);
          dispatch(adminActions.updateUsers({ users: response.users.items }));
          dispatch(adminActions.updateMeta(response.users.meta));
          dispatch(adminActions.updateCycle(response.cycle));
          dispatch(adminActions.updateDesignation(response.designation));
          dispatch(adminActions.updateStatus(response.status));
          // dispatch(adminActions.updateManagerStatus(response.ManagerStatus));
          dispatch(adminActions.updateTeams(response.teams));
          dispatch(adminActions.updateAssociateCount(response.count));
        }
        dispatch(globalActions.updateLoader({ loader: false }));
      });
  };

  useEffect(() => {
    getUserData();
    dispatch(globalActions.updateLoader({ loader: true }));
    Api.get(`/appraisal-admin/users`
      // `/appraisal-admin/users?${pageNumber}${limit}${searchData}${appraisalStatus}${desigFilter}${cycFilter}${formstatusFilter}${sortingByName}${sortingByEmail}${summaryFilterStatus}${sortByID}${rolefilter}`
      )
      .params({
        search : search === "" || typeof search === undefined ? "" : pageNumber === "" && limit === ""
         ? search
        : search,
        page: page !== "" || typeof page !== "undefined" ? page : "",
        limit: pageSize !== "" || typeof pageSize !== "undefined" ? pageSize : "",
        appraisalStatus:  appraisalFilter === "" ||
          appraisalFilter === null ||
          appraisalFilter === undefined
            ? ""
            : appraisalFilter,
        designation: designationFilter === "" ||
          designationFilter === null ||
          designationFilter === undefined ? "" : designationFilter,
        appraisalCycle: cycleFilter === "" || cycleFilter === null || cycleFilter === undefined
            ? ""
            : cycleFilter,
        status:formStatusFilter === "" ||
          formStatusFilter === null ||
          formStatusFilter === undefined
            ? ""
            : formStatusFilter,
        managerStatus: managerFormStatusFilter === "" ||
            managerFormStatusFilter === null ||
            managerFormStatusFilter === undefined
            ? ""
            : managerFormStatusFilter,
        sort: sorting === "" || sorting === null || sorting === undefined
            ? ""
            : sorting,
        emailsort: emailSort === "" || emailSort === null || emailSort === undefined
            ? ""
            : emailSort,
        employeeIdSort: sortByEmployeeID === "" ||
          sortByEmployeeID === null ||
          sortByEmployeeID === undefined
            ? ""
            : sortByEmployeeID,
        summaryStatus: summaryNotificationFilter === "" ||
          summaryNotificationFilter === null ||
          summaryNotificationFilter === undefined
            ? ""
            : summaryNotificationFilter,
        role: roleFilter === "" ||
            roleFilter === null ||
            roleFilter === undefined
              ? "":
            roleFilter
        })
      .send((response, error) => {
        if (typeof response !== "undefined") {
          console.log("All users", response);
          dispatch(adminActions.updateUsers({ users: response.users.items }));
          dispatch(adminActions.updateMeta(response.users.meta));
          dispatch(adminActions.updateCycle(response.cycle));
          dispatch(adminActions.updateDesignation(response.designation));
          dispatch(adminActions.updateStatus(response.status));
          dispatch(adminActions.updateTeams(response.teams));
          dispatch(adminActions.updateAssociateCount(response.count));
        }
        dispatch(globalActions.updateLoader({ loader: false }));
      });
  }, [refreshEffect, search]);

  useEffect(() => {
    // getUserData();
    getPrivilegeUsers({}, (res) => {
      dispatch(adminActions.updatePrivilegeList(res?.user));
    });
    dropDownData();
  }, []);

  useEffect(() => {
    console.log("change tabs", tabState);
    let tempUsers = {
      appraisalFilter: "",
      designationFilter: "",
      cycleFilter: "",
      formStatusFilter: "",
      managerFormStatusFilter: "",
      summaryNotificationFilter: "",
      roleFilter:"",
    };
    if (tabState === "users") {
      setInitialFilter(tempUsers);
    } else if (tabState === "active april users") {
      setInitialFilter({
        ...tempUsers,
        appraisalFilter: true,
        cycleFilter: "April",
      });
    } 
    else if (tabState === "active october users") {
      setInitialFilter({
        ...tempUsers,
        appraisalFilter: true,
        cycleFilter: "October",
      });
    }
    else if (tabState === "inactive users") {
      setInitialFilter({
        ...tempUsers,
        appraisalFilter: false,
        cycleFilter: "",
      });
    }
    // getUserData()
  }, [tabState]);

  console.log("sssssSearchh",searchText,)

  const getPrivilegeUsers = (params, callback, loader) => {
    if (loader) loader(true);
    Api.get(`/appraisal-admin/privilege`)
      .params({})
      .send((response, error) => {
        if (response) {
          callback(response);
        }
        if (loader) {
          loader(false);
        }
      });
  };

  const handleSearch = (e) => {
    dispatch(adminActions.updatePage({ page: 1, pageSize: 30 }));

    console.log("sssssSearch",e.target.value);

    let tempUsers = {
      appraisalFilter: "",
      designationFilter: "",
      cycleFilter: "",
      formStatusFilter: "",
      managerFormStatusFilter: "",
      summaryNotificationFilter: "",
      roleFilter:""
    };
    if (tabState === "active april users") {
      setInitialFilter({
        ...tempUsers,
        appraisalFilter: true,
        cycleFilter: "April",
      });
    }else if (tabState === "active october users") {
        setInitialFilter({
          ...tempUsers,
          appraisalFilter: true,
          cycleFilter: "October",
        });
      }
    // setTimeout(() => {
    //   setLocalSearch(e);
    // }, 1000);
    setSearch(e.target.value)
    setLocalSearchText(e.target.value)
    setLocalSearch(e.target.value);
    dispatch(adminActions.updateSearchText(e.target.value));
  };

  const handleTabs = (e) => {
    dispatch(globalActions.updateLoader({ loader: true }));
    dispatch(adminActions.updateTabState(e));
  };

  const dropDownData = () => {
    dispatch(globalActions.updateLoader({ loader: true }));
    Api.get("/appraisal-admin/getManagerDropdownData")
      .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          dispatch(adminActions.updateManagerDropDown(response));
        }
      });
    Api.get("/appraisal-admin/getTeamDropdown")
      .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          dispatch(adminActions.updateTeamDropDown(response));
        }
      });
    Api.get("/appraisal-admin/getDepartments")
      .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          dispatch(adminActions.updateDepDropDown(response?.allDepartments));
        }
      });
    dispatch(globalActions.updateLoader({ loader: false }));
  };

 

  return (
    <div className={classes.container}>
      <div className={classes.titleBlock}>
        <h2 className={classes.title}>Users</h2>

        <div
          style={{
            // border:'1px solid black',
            width: "60%",
            display: "flex",
            justifyContent: "right",
            gap: "0.2rem",
            alignItems: "center",
          }}
        >
          {/* <SearchCustom
            style={{ height: "2.4rem",width:"20rem" }}
            placeholder="Search by name"
            setValue={setLocalSearchText}
            value={localSearchText}
            onChange={handleSearch}
          /> */}
           <Button
              style={{
                border: "none",
                background: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "none",
              }}
              onClick={() => {
                dispatch(adminActions.updateDesignationModal(true));
                dispatch(adminActions.updateModalType("AllUserDownload"));
              }}
            >
              <img
                style={{
                  width: "1rem",
                  height: "1rem",
                  marginRight: "0.2rem"
                }}
                src={DownloadIcon}
                alt=""
              />
              <span style={{ color: "#FD9644" }}>Download work summary</span>
            </Button>
          {tabState === "users" && (
            <Button
              type="primary"
              style={{
                // width: "10rem",
                height: "2.2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "4px",
              }}
              onClick={() => {
                setPreModal(true);
              }}
            >
              {/* <img
                src={priviledgeUserIcon}
                style={{ marginRight: "0.3rem", marginBottom: "4px" }}
              /> */}
              <span style={{ marginBottom: "" }}>Assign Privilege Role</span>
            </Button>
          )}

          {tabState === "active april users" && isAprilCycle && (
            <>
            <Button
              type="primary"
              style={{
                width: "10rem",
                height: "2.4rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "4px",
              }}
              onClick={() => {
                setSendModal(true);
              }}
            >
              Send All
            </Button>

            <Button
              type="primary"
              style={{
                width: "10rem",
                height: "2.4rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "4px",
              }}
              onClick={() => {
              
                setUpdateSummaryModal(true)
              }}
            >
              Update Work Summary
            </Button>
            </>
          )}

          {tabState === "active october users" && !isAprilCycle && (
            <div style={{display:'flex',flexDirection:'row',gap:10}}>
            <Button
              type="primary"
              style={{
                width: "10rem",
                height: "2.4rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "4px",
              }}
              onClick={() => {
                setSendModal(true);
              }}
            >
              Send All
            </Button>
            <Button
              type="primary"
              style={{
                width: "12rem",
                height: "2.4rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "4px",
              }}
              onClick={() => {
                setUpdateSummaryModal(true)
              }}
            >
              Update Work Summary
            </Button>
            </div>
          )}
        </div>
      </div>

      <div style={{ width: "100%", height: "78vh", marginTop: "0.7rem" }}>
        <div
          style={{
            // border: "1px solid black",
            width: "100%",
            height: "6vh",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div className={classes.tab_Container}>
            {tabItems.map((item) => (
              <TabButtons
                title={item.title}
                onClick={() => {
                  dispatch(adminActions.updateTabState(item.key));
                  dispatch(adminActions.updateSortFilter({
                    sortByEmail:undefined,
                    sortByEmployeeID:undefined,
                    sortByName: undefined,
                  }))
                  // console.log("Tab data changed",item.key)
                }}
                active={item.active}
              />
            ))}
          </div>
          <div className={classes.action_container}>
          <div className={classes.searchDiv}>
                      {" "}
                      <img
                        src={searchIcon}
                        style={{ width: "1rem", height: "1rem",opacity:"1" }}
                        alt="searchIcon"
                      />
                      <Input allowClear={true} bordered={false}  type="text" value={search}
                        placeholder="Search by name" onChange={handleSearch
                          // (e)=>{setSearch(e.target.value)}
                        }
                      />
              </div>
            <Button
              type="default"
              style={{
                border: "1px solid #DFDFDF",
                // width: "7.5rem",
                height: "2.1rem",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between"
              }}
              onClick={() => {
                setFilterVisible(true);
              }}
            >
              <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:"0.3rem"}}>
              <img src={filterIconss}
                        style={{ width: "1rem", height: "1rem",opacity:"1" }}
                        alt="searchIcon"
               />
              Filter by
              </div>
              <img src={filterIcons22}
                        style={{ width: "1.2rem",marginLeft:"0.9rem", height: "1.5rem",opacity:"1" }}
                        alt="searchIcon"
               />
            </Button>
           
          </div>
        </div>
        <div
          style={{
            // border: "1px solid black",
            width: "100%",
            height: "70vh",
            marginTop: "0.5rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <UserTable />
        </div>

        {/* <Tabs
          tabBarExtraContent={SearchBar}
          items={items}
          defaultActiveKey={tabState}
          onChange={handleTabs}
        /> */}
      </div>
      {preModal ? (
        <UsersModal preModal={preModal} setPreModal={setPreModal} />
      ) : null}
      {sendModal ? (
        <UsersModal sendModal={sendModal} setSendModal={setSendModal} />
      ) : null}
      {filterVisible&&<FilterModal visible={filterVisible} setVisible={setFilterVisible} />}


      {
        updateSummaryModal?(
          <Modal
          title={
            <div
              style={{
                display: "flex",
                fontSize: "1.3rem",
                fontWeight: "bold",
                color: "#0086FF",justifyContent:"center",textAlign:"center"
              }}
            >
              Update Work Summary
            </div>
          }
          open={updateSummaryModal}
          onCancel={() => setUpdateSummaryModal(false)}
          //   onOk={handlePreOk}
          footer={null}
          width={"50%"} centered
          // bodyStyle={{ padding: 0, height: "12rem" }}
          closeIcon={<CloseOutlined style={{ color: "" }} />}
          style={{}}
        >
          <div
            style={{
              fontSize: "1.1rem",
              fontWeight: 600,
              width: "100%",
              height: "100%",
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              {" "}
              Are you sure to update work summary of all?
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "3rem 0 1rem 0",
              }}
            >
              <Button
                style={{
                  width: "7rem",
                  marginRight: "1rem",
                  borderRadius: "4px",
                }}
                onClick={() => {
                  setUpdateSummaryModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                style={{ width: "7rem", borderRadius: "4px" }}
                onClick={() => {
                  dispatch(globalActions.updateLoader({ loader: true }));
                  updateSummaryAll((res) => {
                    if (res.show.type === "success") {
                      setUpdateSummaryModal(false);
                      dispatch(
                        adminActions.updateRefreshEffect(
                          (olddata) => olddata + 1
                        )
                      );
                    }
                    dispatch(globalActions.updateLoader({ loader: false }));
                  });
                }}
              >
                Update
              </Button>
            </div>
          </div>
        </Modal>
        ):null
      }
    </div>
  );
};

export default Admin;
