import React, { useEffect } from "react";
import ModalCompo from "../../../design/ModalCompo";
import useStore from "./store";
import classes from "./style.module.css";
import LeaveUseIcon from "../../../assets/LeaveUserIcon.svg";
import { Divider, Form, Input, Modal } from "antd";
import { ModalPrimaryButton, ModalSecondaryButton } from "../../../components/Button/Button";
import Api from "../../../services/Api";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";

const EditLeave=(props)=>{
    const {EditLeaveModal,openModal,editLeaveData,setEditLeaveData}=useStore(state=>state)
    const [form]=Form.useForm();
    const {width}=useWindowDimensions();
console.log("ssssEdite",editLeaveData)
    const onFinish=(values)=>{
     console.log("sssssValues",values)
        Api.patch("/attendance-attendance/updateLeaveData")
           .params({
            userViewId:editLeaveData?.uId,
            casual: parseFloat(values.casual),
            privilege:parseFloat(values.privilege),
            sick:parseFloat(values.sick),
            wedding:parseFloat(values.wedding),
            parental:parseFloat(values.parental),
            optional:parseFloat(values.optional),
            note:values.note,
            userViewId:editLeaveData?.userId,
            year:parseInt(props?.year)
           })
          .send((res)=>{
            if(res?.show?.type === 'success'){
            
            EditLeaveModal(false)
            setEditLeaveData({});
            props.refresh()
            }
          })
    }
    
    useEffect(() => {
        if (openModal) {
            console.log(editLeaveData,"editLeaveDataeditLeaveData");
          form.setFieldsValue({
            casual: editLeaveData?.cEdit,
            privilege: editLeaveData?.pEdit,
            sick: editLeaveData?.sEdit,
            wedding: editLeaveData?.wEdit,
            parental: editLeaveData?.parentEdit,
            optional: editLeaveData?.optional,
            note: editLeaveData?.note,
            comp_Off:editLeaveData?.compOff?editLeaveData?.compOff:0
          });
        }
      }, [openModal, editLeaveData]);
   

    return(
        <Modal open={openModal} centered footer={false} width={width > 700 ? "55vw" : "88vw"}
        onCancel={()=>{
            EditLeaveModal(false);
            setEditLeaveData({})
            form.resetFields();}}
        title={<div className={classes.modalHeader}><h3>Edit Leave</h3></div>}
        // contentStyle={{width:width > 700 ? "50vw" : "85vw",top:width > 700 ?"6vh":"10vh",height:width > 700 ? "89vh":"83vh",borderRadius:8}}
        >
            <div className={classes.modalBody} style={{width:width<700&&"100%"}}>
                {/* <div>
                    <Divider/>
                </div> */}
                <h4><img src={LeaveUseIcon}/> {editLeaveData?.userName}</h4>
                <Form layout="vertical" onFinish={onFinish}
                // initialValues={{
                //         casual:editLeaveData?.cl1,
                //         privilege:editLeaveData?.pl1,
                //         sick:editLeaveData?.sl1,
                //         wedding:editLeaveData?.wl,
                //         parental:editLeaveData?.pl,
                //         note:editLeaveData?.note,
                // }}
                form={form}
                >
                    <div className={classes.formBody} style={{width:"100%"}}>
                        <Form.Item name="privilege" label={<div className={classes.label}>Privilege</div>} 
                        rules={[{required:true,message:"Please enter privilege!"}]}>
                            <Input type="number" placeholder="Enter Privilege" min={0} step="any"/>
                        </Form.Item>
                        <Form.Item name="casual" label={<div className={classes.label}>Casual</div>} rules={[{required:true,message:"Please enter casual!"}]}>
                            <Input type="number" placeholder="Enter Casual"  min={0} step="any"/>
                        </Form.Item>
                        <Form.Item name="comp_Off" label={<div className={classes.label}>Comp Off</div>}>
                            <Input disabled={true} type="number" placeholder="Enter Comp Off"  min={0} step="any"/>
                        </Form.Item>
                        <Form.Item name="sick" label={<div className={classes.label}>Sick</div>} rules={[{required:true,message:"Please enter sick!"}]}>
                            <Input type="number" placeholder="Enter Sick" min={0} step="any"/>
                        </Form.Item>
                        <Form.Item name="wedding" label={<div className={classes.label}>Wedding</div>} rules={[{required:true,message:"Please enter wedding!"}]}>
                            <Input type="number" placeholder="Enter Wedding" min={0} step="any"/>
                        </Form.Item>
                        <Form.Item name="parental" label={<div className={classes.label}>Parental</div>} rules={[{required:true,message:"Please enter parental!"}]}>
                            <Input type="number" placeholder="Enter Parental" min={0} step="any"/>
                        </Form.Item>
                        <Form.Item name="optional" label={<div className={classes.label}>Optional</div>} rules={[{required:true,message:"Please enter parental!"}]}>
                            <Input type="number" placeholder="Enter Parental" min={0} step="any"/>
                        </Form.Item>
                        <Form.Item name="note" label={<div className={classes.label}>Notes</div>} rules={[{required:true,message:"Please enter notes!"}]}>
                            <Input type="text" className={classes.NoteInput} placeholder="Enter Notes"/>
                        </Form.Item>
                    </div>
                    <div className={classes.buttonDesign}>
                        <ModalPrimaryButton htmlType="submit">Save</ModalPrimaryButton>
                        <ModalSecondaryButton onCancel={()=>{ EditLeaveModal(false);
                         setEditLeaveData({})
                         form.resetFields();}}>Cancel</ModalSecondaryButton>
                    </div>
                </Form>
            </div>
        </Modal>
    )
}
export default EditLeave;