import { create } from "zustand";
import { persist } from "zustand/middleware";

 const  PDFReaderStore=(set=>({
    pdfUrl:"",
    pdfUrlHandler:(val)=>set(state=>({pdfUrl:val})),
}))


export const  usePDFReaderStore=create(
    persist(PDFReaderStore,{name:"Local Data"})
)