import React, { useEffect, useState } from "react";
import classes from './HealthInsurance.module.css';
import poweredBy_icon from '../../assets/poweredBy_icon.svg';
import delete_red_icon from './../../assets/Delete_Icon.svg';
import preview_icon  from './../../assets/Preview_Icon2.svg';
import SearchCompo from "../../design/SearchCompo";
import upload_icon from './../../assets/upload_icon.svg';
import profile_icon from './../../assets/profile_icon.svg';
import healthcare from './../../assets/healthcare.svg';
import dashboardIcon from "../../assets/Dashboard.svg";
import { Select, Table, Typography } from "antd";
import { useHealthInsuranceStore } from "./Store";
import UploadModal from "./UploadModal/UploadModal";
import DeleteModal from "./DeleteModal/DeleteModal";
import PreviewModal from "./PreviewModal/PreviewModal";
import { getInsuranceDataApi, publishApi, uploadDocumentApi } from "./Actions";
import Response from "./Response";
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";
import MobileNavModal from "../../components/MobileNavModal/MobileNavModal";
import { useHeaderStore } from "../Header/store";
const HealthInsurance=()=>{
    const {uploadModalHandler,
        deleteModalHandler,
        previewModalHandler,
        previewModal,
        insuranceDataHandler,
        insuranceData,
        fileList,
        setFileList,
        uploadModal,
    }=useHealthInsuranceStore();
    const [search,setSearch]=useState("");
    const [employeeInfo,setEmployeeInfo]=useState();
    const [insurranceCard,setInsuranceCard]=useState("");
    const [deleteInfo,setDeleteInfo]=useState({});
    const [page,setPage]=useState(1);
    const [pageSize,setPageSize]=useState(10);
    const [tableLoader,setTableLoader]=useState(false);
    const [filter,setFilter]=useState("All");
    const {width}=useWindowDimensions();
    const {sidemenu,setSidemenu}=useHeaderStore();

    const tableHeightVH=55;
  const scrollConfig = {
    y: `${tableHeightVH}vh`,
    x:1300
  };
    const refresh=()=>{
        setTableLoader(true);
        getInsuranceDataApi({page:(search||filter)?1:page,limit:pageSize,search:search,filter:filter},(res)=>{
            insuranceDataHandler(res);
            setTableLoader(false);
        });
    }
    useEffect(()=>{
        setTableLoader(true);
        getInsuranceDataApi({page:(search||filter)?1:page,limit:pageSize,search:search,filter:filter},(res)=>{
            insuranceDataHandler(res);
            setTableLoader(false);
        });
    },[search,page,pageSize,filter])
    const columns=[
        {
            title:<span>Associate Name</span>,
            dataIndex:"associate_name",
            key:"associate_name",
            width:"12rem",
            render:(text,record)=>{
                return(
                    <div className={classes.profile}>
                        <div>
                            <img src={record?.pofileImage?record?.pofileImage:profile_icon}/>
                        </div>
                        <div>
                                <span>{record?.name}</span>
                                <span>{record?.uid}</span>
                        </div>
                        
                    </div>
                )
            }
        },
        {
            title:<span>Contact Details</span>,
            dataIndex:"contanct_details",
            key:"contanct_details",
            width:"14rem",
            render:(text,record)=>{
                return(
                    <div style={{display:"flex",flexDirection:"column"}}>
                        <Typography.Text style={{maxWidth:"14rem",fontFamily:"Lato",fontSize:"14px",fontWeight:"510"}} ellipsis={{tooltip:true}}>{record?.email}</Typography.Text>
                        <span>{record?.phone}</span>
                    </div>
                )
            }
        },
        {
            title:<span>DOJ</span>,
            dataIndex:"joined",
            key:"joined",
            width:"6rem",
            render:(text,record)=>{
                return(
                    <div>
                        <span>{text?text:"NA"}</span>
                    </div>
                )
            }
        },
        {
            title:<span>Role</span>,
            dataIndex:"role",
            key:"role",
            width:"8rem",
            render:(text,record)=>{
                return(
                    <div>
                        <span>{text}</span>
                    </div>
                )
            }
        },
        {
            title:<span>Department</span>,
            dataIndex:"department",
            key:"department",
            width:"12rem",
            render:(text,record)=>{
                return(
                    <div>
                        <span>{text?text:"NA"}</span>
                    </div>
                )
            }
        },
        {
            title:<span>Actions</span>,
            dataIndex:"actions",
            key:"actions",
            width:"6rem",
            render:(text,record)=>{
                return(
                    <>
                      {!record?.healthInsuranceCard?.length>0&&<div>
                        <img src={upload_icon} style={{width:"2rem",height:"2rem",cursor:"pointer"}} 
                        onClick={()=>{
                            uploadModalHandler(true);
                            setEmployeeInfo(record);
                        }}
                        />
                    </div>}
                    {
                        record?.healthInsuranceCard?.length>0&&
                        <div className={classes.previewIcon}>
                            <img src={preview_icon} onClick={()=>{
                                previewModalHandler(true);
                                setInsuranceCard(record?.id);
                                setEmployeeInfo(record);
                            }}/>
                            <img src={delete_red_icon} onClick={()=>{
                                deleteModalHandler(true);
                                setDeleteInfo({id:record?.id,name:record?.name});
                            }}/>
                        </div>
                    }
                    </>                
                )
            }
        },
    ]

   var year = new Date().getFullYear().toString();
   var nextyear = (new Date().getFullYear()+1).toString().slice(2);
      
    return(
        <>
                  {width<800&&<MobileNavModal sidemenu={sidemenu} setSidemenu={setSidemenu} >
                      <div className={classes.sider} onClick={()=>{
                        setSidemenu(false);
                        window.location.reload();
                      }}>           
                                    <div>
                                            <img src={dashboardIcon}/>
                                            <span>Dashboard</span>
                                    </div>        
                                    <div>
                                                <img src={poweredBy_icon}></img>
                                    </div>            
                            </div>
                </MobileNavModal>}
                <div className={classes.container}>
                    {width>800&&<div className={classes.sider}>           
                            <div onClick={()=>{
                                window.location.reload();
                            }}>
                                    <img src={dashboardIcon}/>
                                    <span>Dashboard</span>
                            </div>        
                            <div>
                                        <img src={poweredBy_icon}></img>
                            </div>            
                    </div>}

                    <div className={classes.mainContent}>
                        <div className={classes.header}>
                            <div>
                                    <h3>Health Insurance Card {year}-{nextyear}</h3>
                            </div> 
                            <div>               
                            <div className={classes.searchDiv}>
                                                <div>
                                                    <SearchCompo style={{height:"2.5rem",border: "1px solid #BEBEBE"}} setSearch={
                                                        setSearch
                                                    }/>
                                                </div>
                                               
                            </div>
                            <Select value={filter}
                                  size="large"
                                  className={classes.select}
                                  onChange={(e)=>{
                                      setFilter(e);
                                  }}
                                  >
                                      {["All","Published","Unpublished",]?.map((el,ind)=>(<Select.Option key={ind} value={el}>{el}</Select.Option>))}

                              </Select>                  
                            <button onClick={()=>{
                                    publishApi();
                                    refresh();
                                }}>Publish</button>                           
                            </div> 
                        </div>
                    
                        <div style={{display:"flex",flex:1,}}>
                            {/* <Table
                            columns={columns}
                            scroll={scrollConfig}
                            rowClassName={(record, index) => index % 2 === 0 ? classes.tableRow :  classes.tableRowWhite}
                            dataSource={insuranceData?.data?.map((item,index)=>{
                                return(
                                    {...item,upload:true}
                                )
                            })}
                            loading={tableLoader}
                            pagination={{
                                pageSize:parseInt(insuranceData?.limit),
                                pageSizeOptions:[10,20,50,100,150,200],
                                showSizeChanger:true,
                                current:parseInt(insuranceData?.page),
                                total:parseInt(insuranceData?.total),
                                onChange:(page,pageSize)=>{
                                    setPage(page);
                                    setPageSize(pageSize);
                                }
                            }}
                            /> */}
                            <Response 
                            total={parseInt(insuranceData?.total)}
                            data={insuranceData?.data?.map((item,index)=>{
                                return(
                                    {...item,upload:true}
                                )
                            })}
                            loader={tableLoader}
                            limit={pageSize}
                            setLimit={setPageSize}
                            current={page}
                            setCurrent={setPage}
                            setInsuranceCard={setInsuranceCard}
                            setEmployeeInfo={setEmployeeInfo}
                            setDeleteInfo={setDeleteInfo}
                            />
                        </div>
                    </div>
                    {uploadModal&&<UploadModal
                    loader={tableLoader}
                    screenType={"health insurance"}
                    onfinish={(e)=>{
                        setTableLoader(true);
                        uploadDocumentApi({document:fileList?.map((item,index)=>(item.originFileObj))},`/resource-home/uploadHealthCard?employeeId=${employeeInfo?.id}&fileName=${e['fileName']}`,()=>{
                            
                                refresh();
                                uploadModalHandler(false);
                                setFileList([]);
                            
                        }); 
                    }}/>}
                    <DeleteModal deleteInfo={deleteInfo} refresh={refresh}/>
                    { console.log("insurranceCardProb",insurranceCard)}
                    {previewModal&&<PreviewModal insurranceCard={insuranceData?.data?.filter((item)=>item.id===insurranceCard)[0]?.healthInsuranceCard} refresh={refresh} employeeInfo={employeeInfo}/>}
                </div>
        </>
    )
}
export default HealthInsurance;