import React, { useEffect, useState } from 'react';
import { Input, Button } from 'antd';
import '../../../design/framework.css';
import calenderIcon from "../../../assets/calendarSetting.svg";
import { addLeave, allLeave, updateLeave } from './Action/action';
import { useNotificationStore } from './store';
import Views from '../../../services/ViewRole';




const InputComponent = ({ width,placeholder ,borderRadius, borderColor, marginTop, value, onChange, numericOnly }) => {
    const handleKeyPress = (e) => {
        if (numericOnly) {
            const keyCode = e.keyCode || e.which;
            const keyValue = String.fromCharCode(keyCode);
            const numericRegex = /^[0-9]*$/;
            const dotRegex = /\./;
            const currentValue = e.target.value;

            if (!numericRegex.test(keyValue) && !(keyValue === '.' && !dotRegex.test(currentValue))) {
                e.preventDefault();
            }
        }
    };


    return (
        <div style={{ width: width, borderRadius: borderRadius, borderColor: borderColor, marginTop: marginTop }}>
            <Input value={value} onChange={onChange} onKeyPress={handleKeyPress} placeholder={placeholder}/>
        </div>
    );
};





const TextComponent = ({ text, width, fontSize, backgroundColor, marginLeft, color, marginRight, fontWeight }) => {
    return (
        <div style={{ fontSize: '20px', width: width, fontSize: fontSize, marginLeft: marginLeft, color: color, marginRight: marginRight, fontWeight: fontWeight }}>
            {text}
        </div>
    );
};
function LeaveType({ setCenterModel, edit }) {
    const [loading, setloading] = useState(false)
    const { searchVal,setSearchVal,recordsStore,setleaveStore,currentPage,LeaveTypepageSize } = useNotificationStore();

    const s = {
        "leaveName": '',
        "numberOfLeaves": '',
    }
    const [leave, setleaveData] = useState(edit ? { ...recordsStore } : { ...s })

    useEffect(() => {
        Views.updateView();
        console.log("wmnbdjhebhfhegf", recordsStore,leave);
    }, [])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>


            <>
                <div className='r-jc' style={{ display: 'flex', alignItems: 'start', justifyContent: 'start' }}>
                    <img src={calenderIcon} className='p-2' style={{color:'#0086FF',marginRight:"0.4rem"}} />
                    {edit ?
                        <TextComponent className='p-2' text="Edit Leave type" color={'#0086FF'} fontWeight={700} fontSize={'18px'} /> :

                        <TextComponent className='p-2' text="Add Leave type" color={'#0086FF'} fontWeight={700} fontSize={'18px'} />}
                </div>
            </>


            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 20, }}>
                <TextComponent text="Leave Type" fontWeight={500} fontSize={14} />
                <InputComponent borderRadius={10} placeholder={"Leave type"} borderColor={'#E6E6E6'} marginTop={10} value={leave?.leaveName} onChange={(e) => { setleaveData({ ...leave, "leaveName": e.target.value }) }} />


            </div>

            <div style={{ display: 'Leave Days', flexDirection: 'column', justifyContent: 'space-between', marginTop: 20, }}>
                <TextComponent text="Leave Days" fontWeight={500} fontSize={14} />
                <InputComponent placeholder={"Leave days"} numericOnly={true} borderRadius={10} borderColor={'#E6E6E6'} marginTop={10} value={leave?.numberOfLeaves} 
                onChange={(e) => { setleaveData({ ...leave, "numberOfLeaves": e.target.value }) }} />


            </div>



            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
                <Button 
                    disabled={leave?.leaveName?.trim()==="" || leave?.numberOfLeaves==="" ? true : false}
                    loading={loading}
                    type='primary'
                    onClick={() => {
                        setloading(true)
                        if (!edit) {
                            addLeave(leave, (res) => {
                                if (res) {
                                    allLeave({ limit: LeaveTypepageSize, pageNo: currentPage,search:searchVal }, (res) => {
                                        setleaveStore(res)
                                        setCenterModel(false)
                                        setloading(false)
                                    })
                                } else {
                                    setloading(false)
                                }                                
                            });
                        } else {
                            let s = {
                                leaveName: leave?.leaveName,
                                numberOfLeaves: leave?.numberOfLeaves,
                            }
                            updateLeave(s, recordsStore?.id, (res) => {
                                if (res) {
                                    allLeave({ limit: LeaveTypepageSize, pageNo: currentPage,search:searchVal }, (res) => {
                                        setleaveStore(res)
                                        setCenterModel(false)
                                        setloading(false)
                                    })
                                } else {
                                    setloading(false)
                                }
                            })
                        }
                    }}
                    style={{borderRadius:8,alignItems:'center',padding:7,paddingLeft:16,height:"2.1rem",width:"5.5rem",paddingRight: 10,marginRight: 5}}>
                    <TextComponent text={'Save'} fontSize={16}></TextComponent>
                </Button>
                <Button
                    onClick={() => {
                        setCenterModel(false)
                    }}
                    style={{
                        borderRadius: 8, alignItems: 'center', margin: 10, padding: 5, borderWidth: 1, border: "1px solid #0086FF", paddingLeft: 5,
                        paddingRight: 5,height:"2.1rem",width:"5.5rem"
                    }}>
                    <TextComponent text={'Cancel'} color={'#0086FF'} fontSize={16}></TextComponent>
                </Button>
            </div>
        </div>
    );
}

export default LeaveType;