import { create } from "zustand";
export const  useTeamStore=set=>({
    isApplyFiltersModal:false,
    isAddTeamModal:false,
    teamData:[],
    filteredTeamData:null,
    departments:[],
    managerInfo:[],
    months:[],
    isEditTeamModal:false,
    isAddMembersModal:undefined,
    isReleaseModal:undefined,
    monthwiseData:[],
    usersInCalender:[],
    applyFilterData:[],
    initialFiltersValue:null,
    addMembersDropdown:[],
    teamDropDataForRelease:[],
    downloadModal:false,
    teamsByDep:[],
    checkTask:false,
   
    addTeamModalHandler:(val)=>set(state=>({isAddTeamModal:val})),
    getTeamDataHandler:(val)=>set(state=>({teamData:val})),
    getFilteredTeamDataHandler:(val)=>set(state=>({filteredTeamData:val})),
    editTeamModalHandler:(val)=>set(state=>({isEditTeamModal:val})),
    addMembersModalHandler:(val)=>set(state=>({isAddMembersModal:val})),
    applyFiltersModalHandler:(val)=>set(state=>({isApplyFiltersModal:val})),
    releaseModalHandler:(val)=>set(state=>({isReleaseModal:val})),
    departmentsHandler:(val)=>set(state=>({departments:val})),
    managerInfoHandler:(val)=>set(state=>({managerInfo:val})),
    monthsHandler:(val)=>set(state=>({months:val})),
    monthwiseDataHandler:(val)=>set(state=>({monthwiseData:val})),
    usersInCalenderHandler:(val)=>set(state=>({usersInCalender:val})),
    applyFilterDataHandler:(val)=>set(state=>({applyFilterData:val})),
    initialFiltersValueHandler:(val)=>set(state=>({initialFiltersValue:val})),
    addMembersDropdownHandler:(val)=>set(state=>({addMembersDropdown:val})),
    teamDropDataForReleaseHanlder:(val)=>set(state=>({teamDropDataForRelease:val})),
    downloadModalHandler:(val)=>set(state=>({downloadModal:val})),
    teamsByDepartment:(val)=>set(state=>({teamsByDep:val})),
    checkTaskHandler:(val)=>set(state=>({checkTask:val})),
})