import React, { useEffect } from "react";
import BrigoshaLogoIcon from "../../assets/Brigosha logo 1.svg";
import LikeIcon from "../../assets/like icon.svg";
import Api from "../../services/Api";

const AuthenticationConfirm=()=>{

    const url = new URL(window.location.href);
    const uid = url.searchParams.get("uid");
    const token = url.searchParams.get("token");

    useEffect(()=>{
        Api.patch(`/attendance-mobileauth/verify/?uid=${uid}&token=${token}`)
           .send((response)=>{
            
           })
    },[])


    return(
        <div style={{height:"100vh",display: "flex",justifyContent:"center",alignItems:"center",flexDirection:"column",
                    padding:"1rem",textAlign:"center"}}>

            <div style={{marginBottom:"6rem"}}>
                <div>
                    <img src={BrigoshaLogoIcon} alt="" style={{height:"8.5vh",width:"auto",}}/>
                </div>
                <div>
                    <h4 style={{ fontSize: "1.1rem", marginBottom: "0.5rem",marginTop:"1rem" }}>
                      Authentication Request has been sent. Kindly wait for approval
                    .</h4>
                </div>
                <div>
                  <img src={LikeIcon} alt="" 
                    style={{height:"2rem",width:"auto",marginTop:"0.5rem",filter: "brightness(90%)",}}
                    />
                </div>
            </div>
        </div>
    )
}

export default AuthenticationConfirm;