import Api from "../../../services/Api";

export const getCycleUsers = (params, callback, loader) => {
  if (loader) loader(true);
  Api.get(
    `/appraisal-admin/getcycleUsers?appraisalMonth=${
      params.appraisalMonth === undefined ? "" : params.appraisalMonth
    }&designation=${
      params.designation ? params.designation : ""
    }&appraisalStatus=${
      params.appraisalStatus === undefined ? "" : params.appraisalStatus
    }&sort=${params.sort ? params.sort : ""}&page=${
      params.page ? params.page : ""
    }&limit=${params.limit ? params.limit : ""}&download=${
      params.download ? params.download : ""
    }`
  )
    .params({search: params.search ? params.search : ""})
    .send((response, error) => {
      if (!params.download) {
        if (response) {
          callback(response);
        }
      } else {
        window.open(response, "_blank")?.focus();
      }
      if (loader) {
        loader(false);
      }
    });
};

export const getDepartment = (params, callback, loader) => {
  if (loader) loader(true);
  Api.get(
    `/appraisal-admin/getDepartments`)
    .params({search: params.search ? params.search : "",
    departmentId: params.departmentId ? params.departmentId : "",
    downloadTeam: params.downloadTeam ? params.downloadTeam : ""
    })
    .send((response, error) => {
      if (!params.downloadTeam) {
        if (response) {
          callback(response);
        }
      } else {
        window.open(response, "_blank")?.focus();
      }
      if (loader) {
        loader(false);
      }
    });
};

export const getMemberList = (params, callback, loader) => {
  if (loader) loader(true);
  Api.get(
    `/appraisal-admin/memberTeamData?teamId=${
      params.teamId ? params.teamId : ""
    }&sort=${
      params.sort ? params.sort : ""
    }&appraisalCycle=${params.appraisalCycle ? params.appraisalCycle : ""}`
  )
    .params({search: params.search ? params.search : ""})
    .send((response, error) => {
      if (response) {
        callback(response);
      }
      if (loader) {
        loader(false);
      }
    });
};

export const getPrivilegeUsers= (params, callback, loader) => {
  if (loader) loader(true);
  Api.get(
    `/appraisal-admin/privilege`
  )
    .params({})
    .send((response, error) => {
      if (response) {
        callback(response);
      }
      if (loader) {
        loader(false);
      }
    });
  };
  
  export const savePrivilege = (params, callback,loader) => {
    if (loader) loader(true);

    Api.post(`/appraisal-admin/savePrivilegeUsers`)
    .params({
      userIds: params.userIds?params.userIds:"",
      role: params.role
    })
      .send((response, error) => {
        if (response) {
          callback(response);
        }
        if (loader) {
          loader(false);
        }
      });
  };


export const sendAllRequest =(params,callback)=>{
  Api.patch(`/appraisal-admin/sendEmail`)
    .params({
      userIds: params.userIds?params.userIds:"",
    })
      .send((response, error) => {
        if (response) {
          callback(response);
        }
      });
}
  
export const notifyAll = (callback)=>{

  Api.post('/appraisal-admin/sendEmail').send((res,error)=>{
    if(res){
      callback(res)
    }
  })

}

export const updateSummaryAll = (callback)=>{

  Api.post('/appraisal-admin/addingSummary').send((res,error)=>{
    if(res){
      callback(res)
    }
  })

}