

import { Form } from "antd/lib";
import ModalCompo from "../../../../design/ModalCompo";
import './VerifyModal.css';
import OtpInput from 'react-otp-input';
import ButtonCompo from "../../../../design/ButonCompo";
import classes from './Fill.module.css';
import { useState } from "react";
import { useEffect } from "react";
import { useResourceStore } from "../../store/store";
import { generateOtp, verifyOtp } from "../../action/action";
import { SECRET_KEY, encryptData } from "../../../../services/fileCode";
import ApiClass from "../../../../services/Api";
import Notification from "../../../../services/Notification";
function VerifyModal({phone,id,saveVerified=()=>{}}){

  const [form]=Form.useForm();
  const [otp, setOtp] = useState('');
  const {otpModal,setOtpModal,setOtpVerified}=useResourceStore(state=>state)
  useEffect(()=>{
console.log(phone)
if(phone){
  generateOtp(`?userViewId=${id}&phone=${phone}`,(res)=>{

    console.log("otp generated",res)
  })
}

  },[phone])

  
  const verifyHandler = () => {
    // The value will be array of string
    // Check the field if there is no value, or value is undefined/empty string
    console.log('clickedddd',otp)
    if(otp?.length<6){
      Notification.error('Error!','Please enter six digit OTP!')
      return ;
    }
    if(otpModal?.place==='PrimaryPhone')
    verifyOtp(`?userViewId=${id}&phone=${phone}&otp=${otp}&token=${'true'}`,(res)=>{
      if(res?.show?.type==='success'){
        localStorage.setItem('b1faff',true);
        setOtpVerified('true');
      let token=res?.show?.accessToken; 
     let encryptedToken=encryptData(token,SECRET_KEY);
     localStorage.setItem('token',encryptedToken);
     ApiClass.header(token);
     setOtpModal({flag:false,place:'init'});
      }
    })
    else if(otpModal?.place==='emergencyContact'){
      verifyOtp(`?userViewId=${id}&phone=${phone}&otp=${otp}&type=${'emergencyContact'}`,(res)=>{
        if(res?.show?.type==='success'){
        saveVerified(otpModal?.index);
       setOtpModal({flag:false,place:'init'});
        }
      })
    }

  };
  const ResendHandler=()=>{
document.getElementById('resend').style.display='none';
    var second=90;
    function clock(){
      var counter=document.getElementById('counter');
      second--;
      if(counter){
        counter.innerHTML='Time Remaing:'+(second>60?'1:':'0:')+(second<10 || (second>60 && second<70)? "0" : "")+String(second>60?second-60:second);
      }
      if(second>0){
        setTimeout(() => {
          clock();
        }, 1000);
      }
      else{
    document.getElementById('resend').style.display='flex';
      }
    }
    clock();
    generateOtp(`?userViewId=${id}&phone=${phone}`,(res)=>{

      console.log("otp generated",res)
    })
  }
    return(
        <ModalCompo 
        open={otpModal?.flag} 
        className={'VerifyModal'}
        onCancel={()=>{setOtpModal({flag:false})}} closableLeft={true} >
            <div style={{display:'flex',gap:'24px',flexDirection:'column',width:'100%'}}>
                <div className={classes.header1} >Verify OTP</div>
                <div>Please Enter the OTP we've send to</div>
                <div>{phone}</div>
                <Form form={form} style={{margingTop:'20px'}}
                //  onFinish={verifyHandler}
                 >

                  <Form.Item 
                    name='otp'
                    className="center-error-message"
                    rules={[{ validator: async () => Promise.resolve() }]}
                  >
                      <OtpInput
                      containerStyle={'OtpInputMain'}
                      inputStyle={'OtpInput'}
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        inputType="number"
                        renderSeparator={<span> </span>}
                        renderInput={(props) => <input {...props} />}
                      />
                  </Form.Item>
                  <Form.Item>
                   <div style={{width:'100%',display:'flex',justifyContent:'space-between',marginTop:'16px'}}>
                    <div><div id='counter'></div></div>
                    <div style={{color:'#0086ff',cursor:'pointer'}} onClick={ResendHandler} id='resend'>Resend OTP</div>
                   </div> 
                  </Form.Item>
                  <Form.Item>
                    <div onClick={()=>{verifyHandler();}}><ButtonCompo title={'Verify'} type='submit' style={{color:'#fff',backgroundColor:'#0086ff'}} /></div>
                  </Form.Item>
                </Form>

            </div>

        </ModalCompo>
    )
}
export default VerifyModal;