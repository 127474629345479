
import { Col, Row, Upload, } from 'antd';
import { ProfileInput } from './detailField';
import uploadIcon from '../../../assets/Documents upload.svg'
import React from 'react';
import '../../../design/framework.css'


const DetailSection = ({ section, index,  Headerright,  }) => {
    return (
        <div key={index} style={{width:"100%",}}>
            <div className="r-jsb">
                <div style={{display:"flex",width:"100%"}}>
                    <div style={{fontSize: 16, fontWeight: 600, marginBottom: 5,marginBottom:"1.5rem"}}>{section.heading}</div>
                </div>
                {
                    Headerright &&
                    <Headerright />
                }


            </div>
            <Row style={{width:"100%"}}>
                {section.fields.map((field, index) =>
                    <>

                        <DetailField
                            field={field}
                            index={index}
                        />

                    </>
                )}
            </Row>

        </div>
    )
}



const DetailField = ({ field, index }) => {
    return field ? (
        <Col key={index} span={24}
        style={{ width: "100%", ...field.style, }}>
            <div style={{ width: "95%", display: "flex",alignItems:"center" }}>
                <ProfileInput
                    label={field.label}
                    type={field.type}
                    disabled={field.disabled}
                    required={field.required}
                    value={field.value}
                    onChange={e => field?.onChange ? field.onChange(e) : {}}
                    onSelect={(value) => field?.onSelect ? field.onSelect(value) : {}}
                    options={field.options}
                    placeholder={field.placeholder}
                    error={field.error}
                    onFocusChange={field.onFocusChange}
                    disabledDate={field?.disabledDate}
                    otpType={field.otpType}
                    verify={field?.verify}
                />
             
            </div>
        </Col>
    ) : null
}



export { DetailField, DetailSection }







