// import { doesNotMatch } from "assert";
import { handleFileDownload } from "../../../components/downloadAction";
import Api from "../../../services/Api";

export const getTeamApi = (getTeamDataHandler, Api, setPLoader) => {
    setPLoader(true)
    Api.get("/project-team/allTeam").send(res => {
        getTeamDataHandler(res);
        setPLoader(false)
    }
    )
}

export const getFilteredTeamApi = (getFilteredTeamDataHandler, event, Api) => {
    Api.post("/project-team/filteredTeams").params({ ...event }).send(res => {
        getFilteredTeamDataHandler(res);
    }
    )
}
export const createTeamApi = (addTeamModalHandler, Api, event) => {
    Api.post("/project-team/createTeam").params({ ...event }).
        send(res => {
            addTeamModalHandler(false);
        })
}
export const getDepartmentData = (departmentsHandler, Api,callback=null) => {
    Api.get("/project-department/deptDropDown").send(res => {
        departmentsHandler(res);
        if(callback){
            callback()
        }
    })
}
export const getManagerData = (managerInfoHandler, addDeptId, Api) => {
    Api.get(`/project-team/managersAndCoManagers?departmentId=${addDeptId}`).send(res => {
        managerInfoHandler(res);
    })
}
export const getMonthsApi = (monthsHandler, setLoader, Api) => {
    setLoader(true);
    Api.get("/attendance-attendance/months").send(res => {
        if (res) {

            monthsHandler(res);
            setLoader(false);
        }
    })
}
export const getMonthwiseData = (monthwiseDataHandler, userId, selectedMonth, download, setLoader, Api) => {
    setLoader(true);
    // let url=`/attendance-attendance/calendar?userId=${userId}`
    // if(selectedMonth?.split(',')[1]&&selectedMonth?.split(',')[0])
    // {
    //     url=`/attendance-attendance/calendar?year=${selectedMonth.split(',')[1]}&month=${selectedMonth.split(',')[0]}&userId=${userId}`;
    // }

    let par = { userId, };
    if (selectedMonth?.split(',')[1] && selectedMonth?.split(',')[0]) {
        par = { ...par, year: selectedMonth.split(',')[1], month: selectedMonth.split(',')[0] }
    }
    if (download) {
        par = { ...par, download }
    }

    Api.get("/attendance-attendance/calendar").params(par).send(res => {
        if (download) {
            if (res) {
                window.open(res.file, '_blank').focus();
            }

        }
        else {
            monthwiseDataHandler(res);
        }
        setLoader(false);

    })
}
export const getUsersForCalender = (usersInCalenderHandler, teamId, Api) => {
    Api.get(`/project-team/autocomplete-users?teamId=${teamId}`).send(res => {
        usersInCalenderHandler(res);
    })
}
export const getApplyFilterData = (applyFilterDataHandler, userId, departmentId, role, managerId, teamId, startDate, endDate, setLoader, Api) => {
    let par = {
        userId,
        departmentId: departmentId ? departmentId : "",
        role: role ? role : "",
        managerId: managerId ? managerId : "",
        teamId: teamId ? teamId : "",
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
    }
    setLoader(true);
    Api.get("/project-team/getManagerAndTeam").params(par).send(res => {
        applyFilterDataHandler(res);
        setLoader(false);
    })
}
export const editTeamApi = (editTeamModalHandler, teamId, event, Api) => {
    Api.patch(`/project-team/updateTeam/${teamId}`).params({ ...event }).send(res => {
        editTeamModalHandler(false);
    })
}
export const getAddmembersDropdownApi = (addMembersDropdownHandler, teamId, departmentId, managerId, Api) => {
    Api.get(`/project-team/membersDropDown?teamId=${teamId}&departmentId=${departmentId}&managerId=${managerId}`).
        send(res => {
            addMembersDropdownHandler(res);
        })
}
export const addMembersApi = (event, Api,) => {
    Api.post("/project-team/addMembers").params({ ...event }).send(res => {
    })
}

export const getTeamDropDataForRelease = (teamDropDataForReleaseHanlder, teamId = "", memberId = "", Api) => {
    console.log("Behenke Laude")
    Api.get(`/project-team/teamMembers?teamId=${teamId}&memberId=${memberId}`).send(res => {
        teamDropDataForReleaseHanlder(res);
    })
}

export const memberReleaseFromTeamApi = (reason, teamId, memberId, assignToId, releaseDate = "", Api) => {

    Api.get(`/project-team/memberRelease?reason=${reason}&teamId=${teamId}&memberId=${memberId}&assignToId=${assignToId}&releaseDate=${releaseDate}`).send(res => {
    })
}

export const downloadLeaveHistory = (teamId, Api, params) => {
    Api.get(`/attendance-attendance/leaveHistoryRe?teamId=${teamId}`).params(params).send(res => {
        if (res) {
            handleFileDownload(res?.file)
            // window.open(res.file, "_blank")?.focus();
        }
    })
}

export const downloadLeaveSummery = (teamId, Api, params) => {
    Api.get(`/attendance-attendance/leaveSummaryRe?teamId=${teamId}`).params(params).send(res => {
        if (res) {
            handleFileDownload(res?.file)
        }
    })
}
export const downloadTeamData = (teamId, Api, params) => {
    Api.get(`/project-team/membersData?teamId=${teamId}`).params(params).send(res => {
        if (res) {
            // window.open(res.file, "_blank")?.focus();
            handleFileDownload(res?.file)
        }
    })
}


export const getTeamByDepartment = (teamsByDepartment, departmentId, Api, callback = null) => {

    Api.get("/project-team/teamsByDept").params({ deptId: departmentId }).send(res => {
        teamsByDepartment(res)
        if (callback) {
            callback();
        }
        // console.log("teamdep",res);
    })
}

export const getCheckTaskApi = (memberId, Api, callback) => {
    Api.get(`/project-team/checkTask?memberId=${memberId}`).send(res => {
        callback(res);
    })
}


export const getSecDeptAndTeam = (callbackDept, callbackTeam) => {
    Api.get(`/project-team/secondTabDeps`).send(res => {
        if (res)
            callbackDept(res);
    })
}

export const getTeamsList = (callbackTeam, deptId) => {
    Api.get(`/project-team/secondTabTeams`).params({ departmentId: deptId }).send(res => {
        if (res)
            callbackTeam(res);
    })
}
export const getCandidateList = (callbackTeam, teamIds, Tooltip, IoMdContact,Dropdown,DropdownHandler,AiOutlineMore,search='') => {
    let arr = [];
    const colorCodes = {
        "Deployed": "#24C850",
        "On Job Training": "#FFCF21",
        "InActive": "",
        "Active": "",
        "Joined": "#B33DC6",
        "Training": "#C04FCA",
        "Released": "#9B9DA2",
        "On Notice": "#FD8600",
        "Exit": "#FF3434",
    }

    Api.get(`/project-team/teamListView`).params({ teamIds: teamIds, search:search }).send(res => {
        if (res) {
            let temp = res?.allMembers;
            console.log(temp, "temoppp")
            temp?.forEach(element => {
                let items = [
                    {
                        key: "Logs",
                        label: <div style={{ cursor: 'pointer' }}
                        > Task</div>
                    },
                    {
                        key: "Calnedar",
                        label: <div style={{ cursor: 'pointer' }}
                        > Calendar</div>
                    },
                    (element?.release) && {
                        key: "Release",
                        label: <div style={{ cursor: 'pointer' }}
                        > Release</div>
                    },
                ]
                let obj = {
                    ...element,
                    headc: (
                        <Tooltip title={element?.name}>
                            <div className="r-ac">
                                {
                                    element?.profilePicture ?
                                        <img style={{ height: 30, width: 30, margin: 10, borderRadius: 30 }} src={element?.profilePicture} alt="profilePicture" />
                                        :
                                        <IoMdContact color="#EDEDED" fontSize={34} style={{ margin: 10 }} />

                                }
                                <div >
                                    <div style={{ width: "5rem", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{element?.name}</div>
                                    <div style={{ marginTop: 8 }}>{element?.uid}</div>
                                </div>
                            </div>
                        </Tooltip>
                    ),
                    contactDetails: (
                        <Tooltip title={`${element?.email} | ${element?.phone}`}>
                            <div style={{ width: "10rem", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                                {`${element?.email}`}
                            </div>
                            <div>
                                {element?.phone}
                            </div>

                        </Tooltip>
                    ),
                    statusC: (
                        <div style={{ display: "flex", backgroundColor: colorCodes[element?.status], borderRadius: 10, alignItems: "center", justifyContent: "center", width: "70%", height: 30 }}>
                            <Tooltip title={element?.status}>
                                <span style={{ fontSize: 10, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", width: "50%", alignItems: "center", justifyContent: "center" }}>{element?.status}</span>
                            </Tooltip>
                        </div>
                    ),
                    plannedhours: (
                        <div>
                            {element?.calculation?.plannedHour}
                        </div>
                    ),
                    actions: (
                        <div>
                            <Dropdown trigger="hover" menu={{ items, onClick: (key) => { DropdownHandler(key, element) } }}><div ><AiOutlineMore style={{ fontSize: '25px', fontWeight: '600' }} /></div></Dropdown>

                        </div>
                    )
                }
                arr.push(obj)
            })
            callbackTeam(arr);
        }
    })
}

export const deleteTeam = (param, successCallBack,callback) => {
    Api.post("/project-team/deleteTeam").params(param).
        send(res => {
           if(res){
            successCallBack()
           }else{
            callback()
           }
        })
}