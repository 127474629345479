import React, { useEffect, useState,useForm } from 'react';
import { Select, Table, Modal,Form, DatePicker, Button, Input, Spin, Typography } from 'antd';
import { useAttendnaceStore } from '../store/store';
import ApplyCalenderIcon from "../../../assets/applyCalenderIcon.svg";
import { ModalPrimaryButton, ModalSecondaryButton } from '../../../components/Button/Button';
import Api from "../../../services/Api";
import classes from './attendance.module.css';
import { applyLeaveModalApi } from '../action/action';
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import { useParams } from "react-router";

const {Option}=Select;
const dateFormat="YYYY-MM-DD";

export const Cards = (props) => {
  return (
    <div
      style={{
        display: "flex",
       padding:"0.2rem 1rem",
        borderRadius: "5px",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        backgroundColor: props?.color,
        display:"flex",flexDirection:"column",alignItems:"center",textAlign:"center",justifyContent:"center",

      }}
    >
      <div>
        <h4>{props?.leave}</h4>
        <h4>{props?.count}</h4>
      </div>
    </div>
  )
}

const ApplyLeave = (props) => {
  const { applyLeaveModal, setApplyLeaveModal,applyLeaveData,setApplyLeaveData,selectedUser } = useAttendnaceStore(state => state);
  const [tableModal,setTableModal] = useState(false)
  const [startDate,setStartDate] = useState();
  const [endDate,setEndDate] = useState();
  const [reason,setReason] = useState();
  const [form] = Form.useForm();
  const {width}=useWindowDimensions();
  const { associateId } = useParams();
  const [loader,setLoader] = useState(false);

  const Columns=
    [{
        title:"Date",
        dataIndex:"date",
        key:"date",
    },{
        title:"Check In",
        dataIndex:"checkIn",
        key:"checkIn",
        render:(text,record)=>{
          return(
            <span>{text?text:"NA"}</span>
          )
        }
    },{
        title:"Check Out",
        dataIndex:"checkOut",
        key:"checkOut",
        render:(text,record)=>{
          return(
            <span>{text?text:"NA"}</span>
          )
        }
    },{
        title:"Reason",
        dataIndex:"reason",
        key:"reason",
    },{
        title:"Leave Type",
        dataIndex:"leave",
        key:"leave",
    },{
        title:"Approver 1",
        dataIndex:"approver1",
        key:"approver1",
    },{
        title:"Approver 2",
        dataIndex:"approvers2",
        key:"approver2",
    },]

    const handleStartDateChange = (date,dateString) => {
       
        setStartDate(dateString);
      };
    
      const handleEndDateChange = (date, dateString) => {
        
        setEndDate(dateString);
      };

    const onFinish=(values) => {
       
      Api.post(`/attendance-attendance/applyLeave?userId=${props?.uiSelectId ? props?.uiSelectId : Object.keys(selectedUser).length > 0 ? selectedUser?.userId :associateId}`)
         .params({
          leaveType: values?.leaveType,
          endDate:endDate,
          dayType: values?.duration,
          startDate:startDate,
          reason:reason,
          manualApply:true
         })
         .send((response)=>{
            
            if(response?.show?.type ==="success"){
                setApplyLeaveModal(false)
                setStartDate(""); 
                setEndDate(""); 
                setReason(""); 
                setApplyLeaveModal(false);
                form.resetFields();
                props?.reload();
                setLoader(true)
                applyLeaveModalApi(
                  {
                    userId:props?.uiSelectId ? props?.uiSelectId : Object.keys(selectedUser).length > 0 ? selectedUser?.userId :associateId,

                  },
                  (res) => {
                    setLoader(false)
                    setApplyLeaveData(res);
                  }
                );
            }
          
         })
    }

    // console.log("ssssssPending",applyLeaveData)
    useEffect(() => {
      if (applyLeaveModal) { 
          setLoader(true);
          applyLeaveModalApi(
              {
                  userId: props?.uiSelectId ? props?.uiSelectId : Object.keys(selectedUser).length > 0 ? selectedUser?.userId : associateId,
                  year:props?.year
              },
              (res) => {
                  setLoader(false);
                  setApplyLeaveData(res);
              }
          );
      }
  }, [applyLeaveModal, props?.uiSelectId, selectedUser, associateId]);

  return (
    <div>
      <Modal centered width={width>700?"70vw":""}
        open={applyLeaveModal} footer={false}
        onCancel={() => { setApplyLeaveModal(false);
           }}
        title={
          <div style={{ color: "#0086FF", display: "flex", flexDirection: "row", gap: "0.5rem",borderBottom:"1.3px solid #F1F1F1" ,paddingBottom:"0.5rem"}}>
            <img src={ApplyCalenderIcon} alt="Apply Calender Icon" />
            Apply Leave
          </div>
        }
        bodyStyle={{height:width>700?"80vh":"65vh",paddingBottom:"1rem"}}
      >
        <>
        {loader ?
        <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",height:"100%"}}>
          <Spin />
        </div>
        :
        <div style={{overflowY:"scroll",height:"100%"}}>
        <div style={{padding:width>700?"0 1rem":"0 0.5rem",marginTop:"1rem"}}>
          <h4>Total Available Leaves</h4>
          <div  className={classes.appyLeaveCard} style={{overflowX:width<700&&"scroll"}}>
            
         
              <Cards color={"#DAEEFF"} leave={"Casual"} count={applyLeaveData?.userLeaves?.casual}/>
              <Cards color={"#FFEDD9"} leave={"Privilege"} count={applyLeaveData?.userLeaves?.privilege}/>
              <Cards color={"#FFD7D7"} leave={"Sick"} count={applyLeaveData?.userLeaves?.sick}/>
           
            <Cards color={"#FFF7D8"} leave={"Comp Off"} count={applyLeaveData?.userLeaves?.comfOff}/>
            <Cards color={"#EEEEEE"} leave={"Parental"} count={applyLeaveData?.userLeaves?.parental}/>
            <Cards color={"#F7D7FF"} leave={"Wedding"} count={applyLeaveData?.userLeaves?.wedding}/>
            
          </div>
        </div>
        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",padding:width>700?"0 1rem":"0 0.5rem",margin:"1rem 0 0 0"}}>
          <h4>Total Pending Leaves: {applyLeaveData?.pendingCount}</h4>
          <Button type="primary" onClick={()=>{
            setTableModal(true);
            setApplyLeaveModal(false);
          }}>View Details</Button>
        </div>
        <Form layout="vertical" onFinish={onFinish} form={form}>
        <div style={{padding:width>700?"1rem 1rem 0 1rem":"0.5rem",}}>
            <Form.Item label={<h4>Leave Type</h4>} name="leaveType" rules={[{required:true,message:"Please Select Leave Type!"}]}>
                <Select placeholder="Select Leave Type">
                    <Option value={"Sick"}>Sick</Option>
                    <Option value={"Casual"}>Casual</Option>
                    <Option value={"Privilege"}>Privilege</Option>
                    <Option value={"Comp_off"}>Comp Off</Option>

                </Select>
            </Form.Item>
            <Form.Item label={<h4>Select Duration</h4>} name="duration" rules={[{required:true,message:"Please Select Duration!"}]}>
                <Select placeholder="Select Duration">
                    <Option value={"FullDay"}>Full Day</Option>
                    <Option value={"HalfDay"}>Half Day</Option>
                </Select>
            </Form.Item>
            <Form.Item label={<h4>Start Date</h4>} name="startDate" rules={[{required:true,message:"Please Select Start Date!"}]}>
                <DatePicker format={dateFormat} onChange={handleStartDateChange} style={{width:"100%"}}/>
            </Form.Item>
            <Form.Item label={<h4>End Date</h4>} name="endDate" rules={[{required:true,message:"Please Select End Date!"}]}>
                <DatePicker format={dateFormat} onChange={handleEndDateChange} style={{width:"100%"}}/>
            </Form.Item>
            <Form.Item label={<h4>Reason</h4>} name="Enter reason" rules={[{required:true,message:"Please Enter Reason!"}]}>
                <Input format={dateFormat} onChange={(e)=>{
                    setReason(e.target.value)
                }} style={{width:"100%"}}/>
            </Form.Item>
            </div>

          <div style={{display:"flex",flexDirection:"row",gap:"1rem",justifyContent:"center",marginTop:"0.5rem"}}>
                <ModalPrimaryButton htmlType="submit" style={{height:"2.2rem"}}>Save</ModalPrimaryButton>
                <ModalSecondaryButton style={{height:"2.2rem"}} onCancel={()=>{
                  setApplyLeaveModal(false);

                  // setApplyLeaveData(null)
                }}
                  >Cancel</ModalSecondaryButton>
            </div>
            </Form>
        </div>
        }
        </>
      </Modal>

      <Modal open={tableModal} centered footer={false} width="90%" onCancel={()=>{setTableModal(false)
        setApplyLeaveModal(true)
       }} title={<h3 style={{color:"#0086FF"}}>Pending Leave Details</h3>}>
        <div style={{padding:width>700&&"1rem"}}>
           {width>700 ? 
            <Table columns={Columns} dataSource={applyLeaveData?.result} size='small'/>
            :
            <div className={classes.ListContainer} style={{height:"65vh", overflowY:'scroll',overflowX:"scroll",width:"99%",backgroundColor:"#F9F9F9",padding:"0.4rem 0.5rem",}}>
            {
            // loader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'69vh',}}><Spin /></div>:
              applyLeaveData?.result?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'69vh'}}><div>No data found</div></div>:
              applyLeaveData?.result?.map((record,id,index)=>{
                 return(
                  <div style={{width:"100%",marginTop:"0.3rem",marginBottom:"1rem",borderRadius:10,backgroundColor:"#FFFFFF",padding:"0.8rem 0.5rem"}}>
                  
                  <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",marginTop:"0.6rem",}}>
                  <p style={{fontSize:"19px",color:"#16243280"}}>Date: </p>
                  <p style={{fontSize:"19px"}}>{record?.date ? record?.date :"NA"}</p>
                  </div>
                  
                  <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",marginTop:"0.6rem",}}>
                  <p style={{fontSize:"19px",color:"#16243280"}}>Check In: </p>
                  <p style={{fontSize:"19px"}}>{record?.checkIn ? record?.checkIn:"NA"}</p>
                  </div>
  
                  <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",marginTop:"0.6rem",}}>
                  <p style={{fontSize:"19px",color:"#16243280"}}>Check Out: </p>
                  <p style={{fontSize:"19px"}}>{record?.checkOut ? record?.checkOut :"NA"}</p>
                  </div>
  
                  <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",marginTop:"0.6rem",alignItems:"center"}}>
                  <p style={{fontSize:"19px",color:"#16243280"}}>Reason: </p>
                  <p><Typography.Text style={{width:"10vw",fontSize:20,fontWeight:"650px"}} ellipsis={{tooltip:true}}>
                    {record?.reason ? record?.reason :"NA"}</Typography.Text></p>
                  </div>
 
                  <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",marginTop:"0.6rem",}}>
                  <p style={{fontSize:"19px",color:"#16243280"}}>Leave Type: </p>
                  <p style={{fontSize:"19px"}}>{record?.leave ? record?.leave :"NA"}</p>
                  </div>
 
                  <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",marginTop:"0.6rem",}}>
                  <p style={{fontSize:"19px",color:"#16243280"}}>Approver 1: </p>
                  <p style={{fontSize:"19px"}}>{record?.approver1 ? record?.approver1 :"NA"}</p>
                  </div>

                  <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",marginTop:"0.6rem",}}>
                  <p style={{fontSize:"19px",color:"#16243280"}}>Approver 2: </p>
                  <p style={{fontSize:"19px"}}>{record?.approvers2 ? record?.approvers2 :"NA"}</p>
                  </div>

                  </div>
                 )
               })}
             </div> 
           }
            <div style={{display:"flex",alignItems:"center",justifyContent:"Center"}}>
            <Button style={{width:"6rem",height:"2.2rem"}}  type='primary' onClick={()=>{setTableModal(false)
                setApplyLeaveModal(true)
            }}>Close</Button>
            </div>
        </div>
      </Modal>
    </div>
  )
}

export default ApplyLeave;