import ModalCompo from "../../../../design/ModalCompo";
import deprtmentIcon from "../../../../assets/department_blue_icon.svg";
import {  Form, Input, Select } from "antd";
import { ModalPrimaryButton, ModalSecondaryButton } from "../../../../components/Button/Button";
import { AssignDepartmentApi } from "./employeeAction";
import moment from "moment";
import { useState } from "react";
import DatePickerCompo from "../../../../components/DatePicker/DatePickerCompo.tsx";
import classes from './employee.module.css';
import viewClass from "../../../../services/ViewRole";
function AssignDepartment({modal,setModal,list,refresh}){
let header=<div style={{display:'flex',alignItems:'center',gap:'8px',justifyContent:"left",width:"90%",position:"absolute",left:0,top:"0.5rem",paddingLeft:"1.5rem",}}>
    <img src={deprtmentIcon}></img>
    <div style={{font:'normal 600 20px/36px Poppins',color:'#0FB3FF'}}>Assign Department</div>
</div>
const [assignDate,setDate]=useState(moment(modal?.joined).format("YYYY-MM-DD"));
const SaveHandler=(val)=>{
let param={departmentId:val?.dept,date:assignDate,userId:modal?.id,reason:val?.reason}
AssignDepartmentApi(param,(res)=>{
    if(res){
        setModal();
        refresh();
    }
})

}
    return(
        <ModalCompo open={modal!==undefined} header={header} onCancel={()=>{setModal();}} className={classes.AssignDeptModal} closableRight={true}>
            <Form layout='vertical' initialValues={{date:moment(modal?.joined)}} onFinish={SaveHandler} style={{width:'100%',marginTop:"1.5rem"}}>
                
                <div style={{maxHeight:'60vh',overflowY:'scroll'}}>
                <Form.Item name={"dept"} label={<div style={{font:'normal 500 15px/27px Poppins',color:'#162432'}}>Department</div>} rules={[{required: true,message: "Please select department ",},]}>
                    <Select placeholder="Select a department">
                    {list?.map((val,id)=>{
                        return(
                            <Select.Option key={id} value={val?.id} >{val?.name}</Select.Option>
                        )
                    })}
                    </Select>
                </Form.Item>
                <Form.Item name="date" label={<div style={{font:'normal 500 15px/27px Poppins',color:'#162432',width:'100%'}}>Date of onboarding</div>} rules={[{required: true,message: "Please select date ",},]}>
                    <DatePickerCompo  style={{width:'100%'}} onChange={(date,dateString)=>{setDate(dateString)}}
                    disabledDate={(current) => {return (current &&  current <= moment(modal?.joined) )}}


                     />
                </Form.Item>
                <Form.Item name="reason" label={<div style={{font:'normal 500 15px/27px Poppins',color:'#162432',width:'100%'}}>Reason</div>} rules={[{required: true,message: "Please enter reason ", whitespace:true},]}>
                <Input.TextArea rows={5} placeholder="type here" />
                </Form.Item>
                </div>

                <div style={{display:'flex',flexDirection:"row",justifyContent:"flex-end",gap:'2rem',marginTop:'1rem',height:"2.1rem"}}>
                <Form.Item>
                    <ModalPrimaryButton disabled={viewClass?.hrAssociate} style={{height:"2.3rem",width:"7rem"}} htmlType="submit">Save</ModalPrimaryButton>
                </Form.Item>
                <Form.Item>
                    <ModalSecondaryButton style={{height:"2.3rem",width:"7rem",backgroundColor:"white"}} onCancel={()=>{setModal();}}>Cancel</ModalSecondaryButton>
                </Form.Item>
                </div>
            </Form>
        </ModalCompo>
    )
}
export default AssignDepartment;