import React, { useEffect, useState, useRef } from 'react';
import classes from "./leave.module.css";
import { Button, Table, Tooltip, } from "antd";
import calenderWhiteIcon from "../../../assets/calender_white_icon.svg";
import useStore from './store';
import { Cards } from './Cards';
import ApplyLeaveModal from './ApplyLeaveModal';
import DeleteIcon from "../../../assets/Delete_Icon.svg";
import Api from "../../../services/Api";
import SickIcon from "../../../assets/SickIcon.svg";
import CasualIcon from "../../../assets/CasualIcon.svg";
import PrivilegeIcon from "../../../assets/PrivilegeIcon.svg";
import WeddingIcon from "../../../assets/WeddingIcon.svg";
import MaternityIcon from "../../../assets/MaternityIcon.svg";
import PaternityIcon from "../../../assets/PaternityIcon.svg";
import moment from 'moment';
import ModalCompo from '../../../design/ModalCompo';
import { ModalPrimaryButton, ModalSecondaryButton } from '../../../components/Button/Button';
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import Modal from '../../../components/Modal/Modal';
import TableCustom from '../../../components/tableCustom/customTable';
import deleteIcon from "../../../assets/DeleteIcon.svg"
import { useWindowDimensions } from '../../../components/windowDimention/windowDimension';
import { MobileCard } from '../Release/Release';
import { NoData } from '../../Training_essentialDocuments';

const Leave = () => {
    const { applyLeaveModal, openModal } = useStore(state => state);
    const [data, setData] = useState([])
    const [cardDetail, setCardDetail] = useState({})
    const [deleteModal, setDeleteModal] = useState(false);
    const [recordDetails, setRecordDetails] = useState();
    const [dedutedLeaves, setDeductedLeaves] = useState(0);
    const [loader, setLoader] = useState(false);
    const containerRef = useRef(null);
    const { width } = useWindowDimensions();

    const clrf = {
        "Pending": "orange",
        "Approved": "green",
        "Cancelled": "red",
        "Rejected": "red"
    }
    const getData = () => {
        setLoader(true)
        let dataArr = []
        Api.get("/attendance-attendance/getLeaveDetails")
            .send((res) => {
                if (res !== undefined) {
                    let temp = res?.leaveHistory;
                    temp?.forEach(element => {

                        let obj = {
                            ...element,
                            duration: (
                                <>
                                    <Tooltip title={element.halfDay ? "Half Day" : "Full Day"}>
                                        <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', }}>{element.halfDay ? "Half Day" : "Full Day"}</div>
                                    </Tooltip>
                                </>
                            ),
                            "reasonM": (
                                <>
                                    <Tooltip title={element?.reason}>
                                        <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "5vw" }}>{element.reason}</div>
                                    </Tooltip>
                                </>
                            ),
                            "actions": (
                                <>
                                    <Button title='delete' style={{ height: 50, width: 50, display: "flex", alignItems: "center", justifyContent: "center" }} type="text" onClick={() => {
                                        setDeleteModal(true)
                                        setRecordDetails(element)
                                    }} disabled={(element?.approvedStatus === "Cancelled" || element?.approvedStatus === "Rejected" || element?.leave==="Comp_Init"|| element?.leave==="Comp_Req")}>
                                        <img src={deleteIcon} style={{ opacity: (element?.approvedStatus === "Cancelled" || element?.approvedStatus === "Rejected") && "0.5" }} />
                                    </Button>
                                </>
                            ),
                            "approvedStatusM": (
                                // approver1Date
                                <>
                                    <div>
                                        {/* <div style={{fontSize:14, fontWeight:600}}>{element?.approvedStatus}</div> */}
                                        {
                                            element?.approved === "Cancelled" ?
                                                <>
                                                    <Tooltip title={`${element?.approved} by ${element.approver1}`}>
                                                        <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "10vw", color: clrf[element?.approved] }}>{element?.approved} </div>
                                                    </Tooltip>
                                                    <div>
                                                        {
                                                            element?.commentApproved1 &&
                                                            <>

                                                                <Tooltip title={`${element?.approver1} commented on ${element?.approver1Date} : ${element?.commentApproved1}`}>
                                                                    <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "15vw" }}>Approver 1 {element?.approver1} commented on {element?.approver1Date} : {element?.commentApproved1} </div>
                                                                </Tooltip>
                                                            </>
                                                        }
                                                        {
                                                             element?.commentRejected1 &&
                                                             
                                                             <>
 
                                                                 <Tooltip title={`${element?.approver1} commented on ${element?.approver1Date} : ${element?.commentRejected1}`}>
                                                                     <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "15vw" }}>Approver 1 {element?.approver1} commented on {element?.approver1Date} : {element?.commentRejected1} </div>
                                                                 </Tooltip>
                                                             </>
                                                        }
                                                        {
                                                            element?.commentApproved2 &&
                                                            <>

                                                                <Tooltip title={`${element?.approver2} commented on ${element?.approver2Date} : ${element?.commentApproved2}`}>
                                                                    <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "15vw" }}>Approver 2 {element?.approver2} commented on {element?.approver2Date} : {element?.commentApproved2} </div>
                                                                </Tooltip>
                                                            </>
                                                        }
                                                        {
                                                             element?.commentRejected2 &&
                                                             
                                                             <>
 
                                                                 <Tooltip title={`${element?.approver2} commented on ${element?.approver2Date} : ${element?.commentRejected2}`}>
                                                                     <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "15vw" }}>Approver 2 {element?.approver2} commented on {element?.approver2Date} : {element?.commentRejected2} </div>
                                                                 </Tooltip>
                                                             </>
                                                        }
                                                         {
                                                         element?.commentCancelled1 &&
                                                         <>
 
                                                             <Tooltip title={`Commented for cancellation request on ${element?.approver1Date} : ${element?.commentCancelled1}`}>
                                                                 <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "15vw" }}>Approver 1 {element?.approver1}  Commented for cancellation request on {element?.commentCancelled1Date} : {element?.commentCancelled1} </div>
                                                             </Tooltip>
                                                         </>
                                                    }
                                                        {/* {
                                                            element?.commentRejected1 ?
                                                            <>

                                                                <Tooltip title={`${element?.commentRejected1}`}>
                                                                    <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "15vw" }}>Approver 1 Comment: {element?.commentRejected1} </div>
                                                                </Tooltip>
                                                            </>
                                                            :element?.commentApproved1 &&
                                                            <>

                                                                <Tooltip title={`${element?.commentApproved1}`}>
                                                                    <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "15vw" }}>Approver 1 Comment: {element?.commentApproved1} </div>
                                                                </Tooltip>
                                                            </>
                                                        } */}
                                                    </div>
                                                   {/*  <div>
                                                        {
                                                            element?.commentApproved2 &&
                                                            <>
                                                               
                                                                <Tooltip title={`${element?.commentApproved2}`}>
                                                                    <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "15vw" }}>Approver 2 Comment: {element?.commentApproved2} </div>
                                                                </Tooltip>
                                                            </>
                                                        }

                                                    </div> */}
                                                </>
                                                :
                                                <>
                                                    <div style={{ display: "flex", width: "16vw", marginTop: 5 }}>
                                                        <div style={{ width: "6vw" }}>
                                                            Approver 1:
                                                        </div>
                                                        <div>
                                                            <Tooltip title={`${element?.approver1Status} by ${element.approver1}`}>
                                                                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "10vw", color: clrf[element?.approver1Status] }}>{element?.approver1Status} by {element.approver1} </div>
                                                            </Tooltip>

                                                        </div>
                                                    </div>
                                                    <div style={{ fontSize: 12, fontWeight: 700, color: "#959595" }}>
                                                        {element?.approver1Date ? ` on (${moment(element?.approver1Date).format("DD/MM/YYYY")})` : ""}
                                                    </div>
                                                    {
                                                        element?.commentApproved1 &&
                                                        <>

                                                            <Tooltip title={` Commented on  ${element?.approver1Date} : ${element?.commentApproved1}`}>
                                                                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "15vw" }}>  Commented on {element?.approver1Date} : {element?.commentApproved1} </div>
                                                            </Tooltip>
                                                        </>
                                                    }
                                                    {
                                                         element?.commentRejected1 &&
                                                         <>
 
                                                             <Tooltip title={` Commented on ${element?.approver1Date} : ${element?.commentRejected1}`}>
                                                                 <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "15vw" }}>  Commented on {element?.approver1Date} : {element?.commentRejected1} </div>
                                                             </Tooltip>
                                                         </>
                                                    }
                                                    {
                                                         element?.commentCancelled1 &&
                                                         <>
 
                                                             <Tooltip title={`Commented for cancellation request on ${element?.commentCancelled1Date} : ${element?.commentCancelled1}`}>
                                                                 <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "15vw" }}>  Commented for cancellation request on {element?.commentCancelled1Date} : {element?.commentCancelled1} </div>
                                                             </Tooltip>
                                                         </>
                                                    }
                                                   
                                                    {/* {
                                                        element?.commentRejected1 ?
                                                        <>

                                                            <Tooltip title={`${element?.commentRejected1}`}>
                                                                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "15vw" }}>  Commented on {element?.approver1Date} : {element?.commentRejected1} </div>
                                                            </Tooltip>
                                                        </>
                                                        :
                                                        element?.commentApproved1 &&
                                                        <>

                                                            <Tooltip title={`${element?.commentApproved1}`}>
                                                                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "15vw" }}>  Commented on {element?.approver1Date} : {element?.commentApproved1} </div>
                                                            </Tooltip>
                                                        </>
                                                    } */}
                                                    {
                                                        element?.approver2 ?
                                                            <>
                                                                <div style={{ display: "flex", width: "16vw", marginTop: 5 }}>
                                                                    <div style={{ width: "6vw" }}>
                                                                        Approver 2:
                                                                    </div>
                                                                    <div>
                                                                        <Tooltip title={`${element?.approver2Status} by ${element.approver2}`}>
                                                                            <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "10vw", color: clrf[element?.approver2Status] }}>{element?.approver2Status} by {element.approver2} </div>
                                                                        </Tooltip>

                                                                    </div>
                                                                </div>
                                                                <div style={{ fontSize: 12, fontWeight: 700, color: "#959595" }}>
                                                                    {element?.approver2Date ? ` on (${moment(element?.approver2Date).format("DD/MM/YYYY")})` : ""}
                                                                </div>
                                                                {/* {
                                                                    element?.commentRejected2 ?
                                                                    <>
                                                                        
                                                                        <Tooltip title={`${element?.commentRejected2}`}>
                                                                            <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "15vw" }}>Commented on {element?.approver1Date} : {element?.commentRejected2} </div>
                                                                        </Tooltip>
                                                                    </>
                                                                    :element?.commentApproved2 &&
                                                                    <>
                                                                       
                                                                        <Tooltip title={`${element?.commentApproved2}`}>
                                                                            <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "15vw" }}>Commented on {element?.approver1Date} : {element?.commentApproved2} </div>
                                                                        </Tooltip>
                                                                    </>
                                                                } */}
                                                            
                                                    {
                                                        element?.commentApproved2 &&
                                                        <>

                                                            <Tooltip title={`Commented on ${element?.approver2Date} : ${element?.commentApproved2}`}>
                                                                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "15vw" }}>  Commented on {element?.approver2Date} : {element?.commentApproved2} </div>
                                                            </Tooltip>
                                                        </>
                                                    }
                                                    {
                                                         element?.commentRejected2 &&
                                                         <>
 
                                                             <Tooltip title={`Commented on ${element?.approver2Date} : ${element?.commentRejected2}`}>
                                                                 <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "15vw" }}>  Commented on {element?.approver2Date} : {element?.commentRejected2} </div>
                                                             </Tooltip>
                                                         </>
                                                    }
                                                    

                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                </>
                                        }


                                    </div>
                                </>
                            )
                        }
                        dataArr.push(obj)
                    });
                    console.log(dataArr, "dataArrdataArr");
                    setLoader(false)
                    setData([...dataArr]);
                    setCardDetail(res?.balanceLeave);
                }
            })
    }
    useEffect(() => {
        getData();
        Api.get(`/attendance-attendance/deductedLeave`)
            .send((res) => {
                if (res !== undefined) {
                    console.log("ssssResponse", res);
                    setDeductedLeaves(res);
                }
            })
    }, [])

    const DeleteData = () => {
        Api.delete(`/attendance-attendance/deleteLeave?id=${recordDetails?.id}`)
            //    .params({id:recordDetails?.id})
            .send((res) => {
                if (res?.show?.type === "success") {
                    getData();
                }
                setDeleteModal(false);
            })
    }
    const tableColumn = {
        0: {
            cols: "Leave Type",
            width: "15%",
            dataKey: "leave",
            onclick: () => { }
        },
        1: {
            cols: "Reason",
            width: "15%",

            dataKey: "reasonM",
            onclick: () => { }
        },
        2: {
            cols: "Date",
            width: "18%",
            dataKey: "date",
            innerWidth: "5rem",
            onclick: () => { }
        },
        3: {
            cols: "Application Date",
            width: "15%",
            dataKey: "timeStamp",
            onclick: () => { }
        },
        /*         4: {
                    cols: "Approvers",
                    width: "30%",
                    dataKey: "ApproversM",
                    onclick: () => { }
                }, */
        4: {
            cols: "Status",
            width: "45%",
            dataKey: "approvedStatusM",
            onclick: () => { }
        },
        5: {
            cols: "Duration",
            width: "10%",
            dataKey: "duration",
            onclick: () => { }
        },
        6: {
            cols: "Actions",
            width: "10%",
            dataKey: "actions",
            onclick: () => { }
        },
    }

    const handleScrollLeft = () => {
        const container = containerRef.current;
        container.scrollLeft -= 800;
    };

    const handleScrollRight = () => {
        const container = containerRef.current;
        container.scrollLeft += 800;
    };
    return (
        <div className={classes.container}>
            <div>
                <h2>Leave Management</h2>
                <Button type='primary' onClick={() => { applyLeaveModal(true) }}>
                    <img src={calenderWhiteIcon} alt='' /> Apply Leave
                </Button>
            </div>
            <div className={classes.cardsDiv}>
                <div className={classes.buttonsDiv} style={{ marginRight: "1.5rem", marginLeft: "0.5rem" }}>
                    <div onClick={handleScrollLeft}><LeftOutlined style={{ height: "" }} /></div>
                </div>
                <div className={classes.cardList} ref={containerRef}>
                    {/* <div className={classes.buttonsDiv}><Button onClick={handleScrollLeft}><LeftOutlined /></Button></div> */}
                    <Cards width={width} icon={SickIcon} color={"#DAEEFF"} leaves={"Sick"} leaveCount={cardDetail?.sick} />
                    <Cards width={width} icon={CasualIcon} color={"#FFEDD9"} leaves={"Casual"} leaveCount={cardDetail?.casual} />
                    <Cards width={width} icon={PrivilegeIcon} color={"#FFD7D7"} leaves={"Privilege"} leaveCount={cardDetail?.privilege} />
                    <Cards width={width} icon={CasualIcon} color={"#FFF7D8"} leaves={"Comp Off"} leaveCount={cardDetail?.compOff} />
                    <Cards width={width} icon={WeddingIcon} color={"#D9FFE6"} leaves={"Wedding"} leaveCount={cardDetail?.wedding} />
                    <Cards width={width} icon={PrivilegeIcon} color={"#F7D7FF"} leaves={"Deducted"} leaveCount={dedutedLeaves.count} />
                    <Cards width={width} widthInner={"18vw"} icon={MaternityIcon} color={"#F7D7FF"} leaves={"Maternity/Paternity"} leaveCount={cardDetail?.parental} />
                </div>
                <div className={classes.buttonsDiv} style={{ marginLeft: width > 700 ? "1.5rem" : "0.2rem", marginRight: "0.9rem" }}>
                    <div onClick={handleScrollRight}><RightOutlined /></div></div>
                {/* <div className={classes.buttonsDiv}><Button onClick={handleScrollRight}><RightOutlined /></Button></div> */}
            </div>
            <div className={classes.table}>
                {
                    width < 700 ?
                        <>
                            <h3>Leave History</h3>
                            <div style={{ display: "flex", width: "100%", height: "60vh", flexDirection: "column", padding: 5, }}>
                                {
                                    data?.length ?
                                        data?.map((item, index) => (
                                            <>
                                                <MobileCard headerRight={
                                                    (<> {item?.actions}</>)
                                                }
                                                    headerLeft={(
                                                        <div style={{ marginTop: 10 }}>
                                                            <h2>{item?.leave}</h2>
                                                        </div>
                                                    )}
                                                    body={(
                                                        <>

                                                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                <div style={{ width: "30%", color: "#B1B0B0" }}>Reason : </div>
                                                                <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.reason}</div>
                                                            </div>
                                                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                <div style={{ width: "30%", color: "#B1B0B0" }}>Date : </div>
                                                                <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.date}</div>
                                                            </div>
                                                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                <div style={{ width: "30%", color: "#B1B0B0" }}>Application Date : </div>
                                                                <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.timeStamp}</div>
                                                            </div>
                                                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                <div style={{ width: "30%", color: "#B1B0B0" }}>Status : </div>
                                                                <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.approvedStatus}</div>
                                                            </div>
                                                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                <div style={{ width: "30%", color: "#B1B0B0" }}>Duration : </div>
                                                                <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item.halfDay ? "Half Day" : "Full Day"}</div>
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </>
                                        ))
                                        :
                                        <NoData />
                                }
                            </div>


                        </>
                        :
                        <>
                            <h3>Leave History</h3>
                            <TableCustom scrollCap={true} columns={tableColumn} styles={{ height: "42vh" }} dataSource={data} loading={loader}
                            // {
                            //     total: departmentData?.meta?.totalItems,
                            //     current: parseInt(paginationPage),
                            //     showSizeChanger: true,
                            //     pageSizeOptions: [10, 20, 30, 50, 100],
                            //     pageSize: departmentData?.meta?.itemsPerPage,
                            //     onChange: (page, pageSize) => {
                            //         setPaginationPage(page);
                            //         setPageSize(pageSize);
                            //     },
                            // }
                            />
                            {/*  <Table columns={Column} dataSource={data} 
                            rowClassName={(record, index) => index % 2 === 0 ? classes.tableRow :  classes.tableRowWhite}
                            loading={loader} scroll={{y:`${45}vh`}}
                            pagination={{size:"small",showSizeChanger:true,pageSizeOptions:[10,25,30,50],
                            }}/> */}

                        </>

                }
            </div>
            <Modal show={deleteModal} onCancel={() => setDeleteModal(false)}
                closableRight={true}
                header={<div className={classes.DeleteHeader}>
                    <img src={DeleteIcon} /><h3>Delete</h3></div>}
                style={{ width: width < 700 ? "95%" : "50vw", top: "27vh" }}
            >
                <div className={classes.DeleteContainer}>
                    <div style={{ color: "#162432", fontSize: 18 }}>Are you sure you want  to delete leave application?</div>
                    <div className={classes.DeleteButtons}>
                        <ModalPrimaryButton style={{ borderRadius: 2 }} onSave={() => DeleteData()}>Yes</ModalPrimaryButton>
                        <ModalSecondaryButton style={{ borderRadius: 2 }} onCancel={() => { setDeleteModal(false) }}>No</ModalSecondaryButton>
                    </div>
                </div>
            </Modal>
            {openModal && <ApplyLeaveModal width={width} refresh={getData.bind(this)} />}
        </div>
    )
}
export default Leave;