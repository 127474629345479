import React, { useEffect, useState } from "react";
import BackRoundIcon from "../../../assets/backRoundIcon.svg";
import { Button, Form, Input, Radio, Select, Upload,Space,Modal,Popover } from 'antd';
import { ProfileStore } from "./store";
import UploadIcon from "../../../assets/UploadBlueIcon.svg";
import Api from "../../../services/Api";
import PhoneInput from '../../../components/PhoneInput/PhoneInput';
import classes from "./profile.module.css";
import SuccessIcon from "../../../assets/updated_icon.svg";
import ExclamationIcon from "../../../assets/ExclamationIcon.svg";
import { DownOutlined } from '@ant-design/icons';
import Notification from "../../../services/Notification";
import { SECRET_KEY, decryptData, encryptData } from "../../../services/fileCode";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";

const countryCodes = require('country-codes-list')
const myCountryCodesObject = countryCodes.customList('countryCode', '[{countryCode}] {countryNameEn}: +{countryCallingCode}');

const AccountDetails = () =>{
    const [workExperience,setWorkExperience] = useState(null);
    const [imgFile, setImgFile] = useState("");
    const [formValues, setFormValues] = useState({phone:'',email:'',emailOtp:'',phoneOtp:''});
    const {width}=useWindowDimensions();
    // const [phoneNumber, setPhoneNumber] = useState('');
    // const [emailId, setEmailId] = useState('');
    const [isFormChanged, setIsFormChanged] = useState(false); 
    const [phoneModal, setPhoneModal] = useState(false); 
    const [emailModal, setEmailModal] = useState(false); 
    const [phoneEmailModal, setPhoneEmailModal] = useState(false); 
    const [showInput, setShowInput] = useState(false); 
    const [EmailshowInput, setEmailShowInput] = useState(false); 
    const [mobileSuccessModal, setMobileSuccessModal] = useState(false); 
    const [emailSuccessModal, setEmailSuccessModal] = useState(false); 
    const [phoneEmailSuccessModal, setPhoneEmailSuccessModal] = useState(false); 
    const { currentPage,setCurrentPage,userData,phoneNumber, setPhoneNumber,emailId, setEmailId} = ProfileStore();
    const [form] = Form.useForm();
    const [countryCode,setCountryCode]=useState("+91");
    const [search,setSearch]=useState('');
    const [open,setOpen]=useState(false);
    const codes=Object.values(myCountryCodesObject);   
    const [mobOTPResponse,setMobOTPResponse]=useState("");
    const [emailOTPResponse,setEmailOTPResponse]=useState("");
    const user = decryptData(localStorage.getItem('user'), SECRET_KEY);

    const updateStore = (type,val) =>{
        const userOld = decryptData(localStorage.getItem('user'), SECRET_KEY);
            let temp = userOld;
            temp[type]=val;     
            localStorage.setItem('user', encryptData(temp, SECRET_KEY));  
             
    }

    console.log("sssUser",user)

    const initialValues = {
        name: userData.name,
        phone: userData.phone,
        email: userData.email,
    };

    useEffect(() => {
        // Compare current form values with initial values
        const isChanged = Object.keys(formValues).some(key => formValues[key] !== initialValues[key]);
        setIsFormChanged(isChanged);
    }, [formValues, initialValues]);

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const imgbeforeUpload = (file) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isJpgOrPng) {
          Notification.error("You can only upload JPG/PNG file!");
          setImgFile("");
        } 
        else if (!isLt5M) {
          Notification.error("Image must smaller than 5MB!");
          setImgFile("");
        } 
        else {
          getBase64(file, (url) => {      
            setImgFile(file);
          });
        }
        return false
      };

      const handleFileRemove = () => {
        setImgFile("");
      };

    const onFinishHandler =() =>{
        // if(values){
            let bodyParams = {candidateName :userData?.name}

            if (formValues.email !== '' && userData.email !== formValues.email) {
                bodyParams={...bodyParams, email:formValues.email,oldEmail:userData?.email}
            }
        
            if (formValues.phone !== '' && userData.phone !== formValues.phone) {
                bodyParams={...bodyParams, phone:formValues.phone,oldPhone:userData?.phone}
            }
            // if (bodyParams.length > 1) {
                Api.post(`/hiring-mobile/mailForUpdatingCandidateInfo`)
                    .params(bodyParams)
                    .send((res)=>{
                        if(res?.show?.type === "success"){
                            form.resetFields();
                            setFormValues({
                                phone: '',
                                phoneOtp: '',
                                email: '',
                                emailOtp: '' 
                              });
                        }
                })  
            // }
    }

    const OTPHandler = () =>{
        // if (/^\d{10}$/.test(formValues?.phone)) {
            Api.post('/auth-auth/sendMobileOTP').params({
                phone:formValues?.phone
              }).send(res=>{
                if(res?.show?.type === "success"){
                    setMobOTPResponse(res);
                    setShowInput(true);
                }
              });
        // } 
        // else {
        //     Notification.error("The phone number should be 10 characters long!")
        // }
    }

    const EmailOTPHandler = () =>{    
          Api.post('/auth-auth/sendEmailOTP').params({
            email:formValues.email,
            userName:userData.name
          }).send(res=>{
            if(res?.show?.type === "success"){
                setEmailOTPResponse(res);
                setEmailShowInput(true)
            }
          })
    }

    const PhoneEmailOTPHandler = () =>{    
        // if (/^\d{10}$/.test(formValues?.phone)) {
            Api.post('/auth-auth/sendMobileOTP').params({
                phone:formValues?.phone
              }).send(res=>{
                if(res?.show?.type === "success"){
                    setMobOTPResponse(res);
                    setShowInput(true);
                }
              });
            Api.post('/auth-auth/sendEmailOTP').params({
                email:formValues.email,
                userName:userData.name
              }).send(res=>{
                if(res?.show?.type === "success"){
                    setEmailOTPResponse(res);
                    setEmailShowInput(true)
                }
            })
        // } else {
        //     Notification.error("The phone number should be 10 characters long!")
        // }
  }

    const VerifyHandler = () =>{
        Api.post('/auth-auth/verifyMobileOTP').params({
            phone:formValues.phone,
            otp:formValues.phoneOtp,
            sessionId:mobOTPResponse?.show?.sessionId
        
          }).send(res=>{
            console.log('xzxzxzxz',res);
            if(res?.show?.type === "success"){
                onFinishHandler();
                if(user?.authRole.includes("Guest")){
                    setPhoneNumber(formValues?.phone);
                    updateStore("phone",formValues?.phone)
                    setPhoneModal(false);
                }
                else{
                    setMobileSuccessModal(true);
                    setPhoneNumber(formValues?.phone);
                    setPhoneModal(false);
                }
            }
          })
    }

    const EmailVerifyHandler = () =>{
        Api.post('/auth-auth/verifyEmailOTP').params({
            email:formValues.email,
            otp:formValues.emailOtp,
            modOTP:emailOTPResponse?.show?.modOTP,
            expiryTime:emailOTPResponse?.show?.expiryTime,
          }).send( res=>{
                if(res?.show?.type === "success"){
                    onFinishHandler();
                    if(user?.authRole.includes("Guest")){
                        setEmailId(formValues?.email);
                        updateStore("email",formValues?.email)
                        setEmailModal(false);
                    }
                    else{
                        setEmailSuccessModal(true);
                        setEmailId(formValues?.email);
                        setEmailModal(false);
                    }
                }          
            })
    }

    const PhoneEmailVerifyHandler = () =>{
        Api.post('/auth-auth/verifyMobileOTP').params({
            phone:formValues.phone,
            otp:formValues.phoneOtp,
            sessionId:mobOTPResponse?.show?.sessionId
        
          }).send(res=>{
            if(res?.show?.type === "success"){

                if(user?.authRole.includes("Guest")){
                    setPhoneNumber(formValues?.phone);
                    updateStore("phone",formValues?.phone)
                    setPhoneEmailModal(false);
                }
                else{
                   
                    setPhoneNumber(formValues?.phone);
                    setPhoneEmailModal(false);
                }
                Api.post('/auth-auth/verifyEmailOTP').params({
                    email:formValues.email,
                    otp:formValues.emailOtp,
                    modOTP:emailOTPResponse?.show?.modOTP,
                    expiryTime:emailOTPResponse?.show?.expiryTime,
                  }).send( res=>{
                        if(res?.show?.type === "success"){
                            onFinishHandler();
                            if(user?.authRole.includes("Guest")){
                                setEmailId(formValues?.email);
                                updateStore("email",formValues?.email)
                                setPhoneEmailModal(false);
                            }
                            else{
                                // setPhoneEmailSuccessModal(true);
                                setPhoneEmailSuccessModal(true);                    
                                setEmailId(formValues?.email);
                                setPhoneEmailModal(false);
                            }
                        }          
                    })
            }
        })

       
    }
    
    const isValidPhone = (phone) => {
        const phoneRegex = /^\d{10}$/;
        return phoneRegex.test(phone);
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return(
        <div style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",padding:"2vh 0"}}>
         <div style={{width: width>700? "40%":"90%",}}>
            <div style={{display:"flex",width:"100%",justifyContent:"flex-start",alignItems:"center",cursor:"pointer",fontSize:"0.9rem"}} onClick={()=>{
                setCurrentPage("profile");
            }}>
                <img src={BackRoundIcon} alt="" style={{borderRadius:"50%",marginRight:"0.7rem",height:"1.5vh",}}/> 
                Back
            </div>
            <h3 style={{display:"flex",width:"100%",fontSize:20,justifyContent:"flex-start",margin:"1vh 0 1vh 0"}}>Account Details</h3>
            <div style={{backgroundColor:"#FFFFFF",width:"100%",padding:"2.5vh 2vw",overflow:"auto",height:width>700? "78vh":"67vh"}}>
                {/* <Form layout="vertical" onFinish={onFinishHandler} initialValues={initialValues} form={form}> */}
                    <div style={{display:"flex",flexDirection:"column",height:width>700? "72vh":"60vh",justifyContent:"space-between"}}>
                    {/* <Form.Item name="name" label={<div>Name</div>} rules={[{ required: true,
                         message: "Please enter the name",
                     },]}> */}
                     <div style={{display:"flex",flexDirection:"column",gap:"1.5rem",}}>
                     <div>
                        <p>Name</p>
                        <Input type="text" placeholder="Name" onChange={(e)=>setFormValues({name:e.target.value})} disabled
                        style={{marginTop:"0.3rem",height:"2.3rem"}} value={userData?.name}/>
                    </div>
                    <div>
                        <p>Phone Number</p>
                        <Space.Compact
                        style={{
                            width: '100%',marginTop:"0.3rem",border:"2px solid #e3e3e3",display:"flex",borderRadius:"5px",flexDirection:"row",alignItems:"center"
                        }}
                        >
                       <div  className={classes.container} style={{border:"none",width:"90%"}}>
                        <Popover>
                                <div className={classes.code}>                 
                                    <span style={{fontSize:"0.8rem"}}>{countryCode}</span>
                                    <DownOutlined/>                
                                </div>
                        </Popover>
                        <Input type='number' value={phoneNumber ? phoneNumber : userData.phone} bordered={false} placeholder='Enter mobile number' style={{height:"2.3rem",border:"none"}}
                        onChange={(e)=>{setFormValues({phone:e.target.value})}}
                        />
                        </div>
                       <Button style={{marginRight:"0.1rem",color:"#0086FF",border:"none"}}
                       onClick={()=>setPhoneModal(true)} 
                       disabled={phoneNumber && !user?.authRole.includes("Guest") ? true : false}
                       >Update</Button>
                        </Space.Compact>
                        {(phoneNumber && !user?.authRole.includes("Guest")) && <span style={{color:"#808080",fontSize:"0.7rem",}}><img src={ExclamationIcon} style={{margin:"0.5rem 0.5rem 0 0",height:"2vh"}}/> 
                            Your request to update your mobile number has been recorded. Once approved by the admin, 
                            the new number will be seamlessly updated in your profile.
                        </span>}
                    </div>
                    <div>
                        <p>Email ID</p>
                         <Space.Compact
                        style={{
                            width: '100%',border:"2px solid #e3e3e3",height:"2.4rem",display:"flex",borderRadius:"5px",flexDirection:"row",alignItems:"center",marginTop:"0.3rem"
                        }}
                        >
                        <Input type="mail" placeholder="Email id" value={emailId ? emailId : userData.email} onChange={(e)=>setFormValues({email:e.target.value})} 
                        style={{border:"none"}}/>                       
                        <Button style={{marginRight:"0.1rem",color:"#0086FF",border:"none"}} 
                        disabled={ emailId && !user?.authRole.includes("Guest")? true : false}
                         onClick={()=>setEmailModal(true)}>Update</Button>
                        </Space.Compact>
                        {(emailId && !user?.authRole.includes("Guest")) && <span style={{color:"#808080",fontSize:"0.7rem",}}><img src={ExclamationIcon} style={{margin:"0.5rem 0.5rem 0 0",height:"2vh"}}/> 
                            Your request to update your email id has been recorded. Once approved by the admin, 
                            the new email id will be seamlessly updated in your profile.
                        </span>}
                    </div>
                    </div>

                    <div>
                        <Button style={{width:"100%",border:"none",color:"#0086FF"}} onClick={()=>{
                            setPhoneEmailModal(true)
                        }}
                         disabled={(phoneNumber || emailId) && !user?.authRole.includes("Guest")? true : false}
                         >Update mobile number and email ID</Button>
                    </div>
                    </div>
                {/* </Form> */}
            </div>
         </div>

         <Modal open={phoneModal} onCancel={()=>{setPhoneModal(false);setShowInput(false)}}
          centered footer={false} >
            <div style={{padding:"0.6rem 0",width:"100%",display:"flex",flexDirection:"column",gap:"5rem"}}>
                <div style={{width:"100%",alignItems:"center",display:"flex",flexDirection:"column",}}>
                <h3 style={{fontSize:20}}>Update mobile number</h3>
                <div style={{width:width>700?"80%":"95%",display:"flex",justifyContent:"flex-start",margin:"1rem 0 0.4rem 0"}}>New mobile number</div>
                <div style={{width:width>700?"80%":"95%"}}>
                <PhoneInput value={formValues?.phone} countryCode={countryCode}setCountryCode={setCountryCode} onChange={(e)=>{
                            setFormValues((prevValues) => ({
                                ...prevValues,
                                phone: e.target.value, // Update phone value
                              }));
                    }} style={{width:"100%"}}
                /> </div>
                {showInput && <>
                <div style={{width:width>700?"80%":"95%",margin:"1rem 0 0.4rem 0"}}>Mobile number OTP</div>
                <Input type="number" placeholder="Enter mobile number OTP" style={{width:width>700?"80%":"95%",height:"2.3rem"}} onChange={(e)=>{
                            setFormValues((prevValues) => ({
                                ...prevValues,
                                phoneOtp: e.target.value, 
                              }));
                    }} 
                /> </>}
                </div>
                
                <div style={{width:"100%",alignItems:"center",display:"flex",flexDirection:"column"}}>
                    {showInput ?<>
                            <div>Didn’t receive the codes? <span style={{color:"#0086FF",marginLeft:"1rem",cursor:"pointer"}}
                            onClick={OTPHandler}>Resend</span>
                            </div>
                            <Button type="primary" style={{marginTop:"1rem"}} onClick={VerifyHandler}
                                disabled={formValues?.phoneOtp ? false : true}>Verify OTP</Button>
                          </>
                        :
                        <Button type="primary" style={{marginTop:"1rem"}} onClick={OTPHandler}
                        disabled={!formValues.phone || !isValidPhone(formValues.phone)}
                        >Send OTP</Button>
                    }
                </div>
            </div>
         </Modal>

         <Modal open={emailModal} onCancel={()=>{setEmailModal(false);setFormValues({});setEmailShowInput(false)}}
         centered footer={false} >
            <div style={{padding:"0.6rem 0",width:"100%",display:"flex",flexDirection:"column",gap:"5rem"}}>
                <div style={{width:"100%",alignItems:"center",display:"flex",flexDirection:"column",}}>
                <h3 style={{fontSize:20}}>Update Email Id</h3>
                <div style={{width:width>700?"80%":"95%",display:"flex",justifyContent:"flex-start",margin:"1rem 0 0.4rem 0"}}>New Email Id</div>
                <Input type="mail" placeholder="Email id" onChange={(e)=>{
                    setFormValues((prevValues) => ({
                        ...prevValues,
                        email: e.target.value, 
                      }));
                }} 
                        style={{width:width>700?"80%":"95%",height:"2.3rem"}}/>         
                {EmailshowInput && <>
                <div style={{width:width>700?"80%":"95%",margin:"1rem 0 0.4rem 0"}}>Email Id OTP</div>
                <Input type="number" placeholder="Enter Email Id OTP" style={{width:width>700?"80%":"95%",height:"2.3rem"}} onChange={(e)=>{
                            setFormValues((prevValues) => ({
                                ...prevValues, emailOtp: e.target.value,
                              }));
                    }} 
                /> </>}
                </div>
                
                <div style={{width:"100%",alignItems:"center",display:"flex",flexDirection:"column"}}>
                    
                    {EmailshowInput ?<>
                            <div>Didn’t receive the codes? <span style={{color:"#0086FF",marginLeft:"1rem",cursor:"pointer"}}
                              onClick={EmailOTPHandler}>Resend</span>
                            </div>
                            <Button type="primary" style={{marginTop:"1rem"}} onClick={EmailVerifyHandler}
                                disabled={formValues?.emailOtp ? false : true}>Verify OTP</Button>
                          </>
                        :
                        <Button type="primary" style={{marginTop:"1rem"}} onClick={EmailOTPHandler}
                        disabled={!formValues.email || !isValidEmail(formValues.email)}
                        >Send OTP</Button>
                    }
                </div>
            </div>
         </Modal>

         <Modal open={phoneEmailModal} onCancel={()=>{setPhoneEmailModal(false);setFormValues({});setShowInput(false);setEmailShowInput(false)}}
         centered footer={false} >
            <div style={{padding:"0.6rem 0",width:"100%",display:"flex",flexDirection:"column",gap:"5rem"}}>
                <div style={{width:"100%",alignItems:"center",display:"flex",flexDirection:"column",}}>
                <h3 style={{fontSize:20}}>Update mobile number and email id</h3>
                <div style={{width:width>700?"80%":"95%",display:"flex",justifyContent:"flex-start",margin:"1rem 0 0.4rem 0"}}>New mobile number</div>
                <div style={{width:width>700?"80%":"95%"}}>
                    <PhoneInput value={formValues?.phone} countryCode={countryCode}setCountryCode={setCountryCode} onChange={(e)=>{
                                setFormValues((prevValues) => ({
                                    ...prevValues,
                                    phone: e.target.value, 
                                }));
                        }} style={{width:"100%"}}
                    /> 
                </div>
                <div style={{width:width>700?"80%":"95%",display:"flex",justifyContent:"flex-start",margin:"1rem 0 0.4rem 0"}}>New email id</div>
                    <Input type="mail" value={formValues.email} placeholder="Email id" onChange={(e)=>{
                        setFormValues((prevValues) => ({
                            ...prevValues,
                            email: e.target.value, 
                        }));
                    }} style={{width:width>700?"80%":"95%",height:"2.3rem"}}/>    
                {showInput && <>
                <div style={{width:width>700?"80%":"95%",margin:"1rem 0 0.4rem 0"}}>Mobile number OTP</div>
                <Input type="number" placeholder="Enter mobile number OTP" style={{width:width>700?"80%":"95%",height:"2.3rem"}} onChange={(e)=>{
                            setFormValues((prevValues) => ({
                                ...prevValues,
                                phoneOtp: e.target.value, 
                              }));
                    }} 
                /> </>}
                {EmailshowInput && <>
                <div style={{width:width>700?"80%":"95%",margin:"1rem 0 0.4rem 0"}}>Email Id OTP</div>
                <Input type="number" placeholder="Enter Email Id OTP" style={{width:width>700?"80%":"95%",height:"2.3rem"}} onChange={(e)=>{
                            setFormValues((prevValues) => ({
                                ...prevValues, emailOtp: e.target.value,
                              }));
                    }} 
                /> </>}
                </div>
                <div style={{width:"100%",alignItems:"center",display:"flex",flexDirection:"column"}}>
                    {showInput && EmailshowInput ?<>
                        <div>Didn’t receive the codes? <span style={{color:"#0086FF",marginLeft:"1rem",cursor:"pointer"}}
                          onClick={PhoneEmailOTPHandler}>Resend</span>
                        </div>
                        <Button type="primary" style={{marginTop:"1rem"}} onClick={PhoneEmailVerifyHandler}
                            disabled={!formValues?.phoneOtp || !formValues?.emailOtp}
                            >Verify OTP</Button>
                        </>
                        :
                        <Button type="primary" style={{marginTop:"1rem"}} onClick={PhoneEmailOTPHandler}
                        disabled={!formValues.phone || !formValues.email || !isValidPhone(formValues.phone) || !isValidEmail(formValues.email)}
                        >Send OTP</Button>
                    }
                </div>
            </div>
         </Modal>

        <Modal open={mobileSuccessModal} onCancel={()=>{setMobileSuccessModal(false)}}
         centered footer={false} >
            <div style={{padding:"0.6rem 2rem",width:"100%",display:"flex",justifyContent:"center",flexDirection:"column",gap:"1rem"}}>
                <div style={{width:"100%",alignItems:"center",display:"flex",justifyContent:"center"}}>
                 <img src={SuccessIcon} alt='' />
                </div>

                <div style={{width:"100%",alignItems:"center",display:"flex",justifyContent:"center"}}>
                <span>
                Your request to update your mobile number has been recorded. Once approved by the admin, the new number will be seamlessly updated in your profile.
                </span>
                </div>
                
                <div style={{width:"100%",alignItems:"center",display:"flex",justifyContent:"center"}}>
                    
                    <Button type="primary" style={{marginTop:"1rem"}} onClick={()=>{setMobileSuccessModal(false);
                    // onFinishHandler();
                    }}>
                        Okay</Button>
                </div>
            </div>
         </Modal>

         <Modal open={emailSuccessModal} onCancel={()=>{setEmailSuccessModal(false)}}
         centered footer={false} >
            <div style={{padding:"0.6rem 2rem",width:"100%",display:"flex",flexDirection:"column",alignItems:"center",gap:"1rem"}}>
            <div style={{width:"100%",alignItems:"center",display:"flex",justifyContent:"center"}}>
                 <img src={SuccessIcon} alt='' />
                </div>

                <div style={{width:"100%",alignItems:"center",display:"flex",justifyContent:"center"}}>
                <span>
                    Your request to update your email id has been recorded. Once approved by the admin, the new email id will be seamlessly updated in your profile.
                </span>
                </div>
                
                <div style={{width:"100%",alignItems:"center",display:"flex",justifyContent:"center"}}>
                    <Button type="primary" style={{marginTop:"1rem"}} onClick={()=>{setEmailSuccessModal(false);
                    // onFinishHandler();
                    }}>
                        Okay</Button>
                </div>
            </div>
         </Modal>

         <Modal open={phoneEmailSuccessModal} onCancel={()=>{setPhoneEmailSuccessModal(false)}}
         centered width="40%" footer={false} >
            <div style={{padding:"0.6rem 2rem",width:"100%",display:"flex",justifyContent:"center",flexDirection:"column",gap:"1rem"}}>
                <div style={{width:"100%",alignItems:"center",display:"flex",justifyContent:"center"}}>
                 <img src={SuccessIcon} alt='' />
                </div>

                <div style={{width:"100%",alignItems:"center",display:"flex",justifyContent:"center"}}>
                <span>
                Your request to update your mobile number and email id has been recorded. Once approved by the admin, the new number and email id will be seamlessly updated in your profile.
                </span>
                </div>
                
                <div style={{width:"100%",alignItems:"center",display:"flex",justifyContent:"center"}}>
                    
                    <Button type="primary" style={{marginTop:"1rem"}} onClick={()=>{setPhoneEmailSuccessModal(false);
                    //  onFinishHandler();
                     }}>
                     Okay
                    </Button>
                </div>
            </div>
         </Modal>


        </div>
    )
}
export default AccountDetails;