import { create } from "zustand";
const useApiStore=create((set)=>({
    loader:false,
    setLoader:(payload)=>{
        set((state)=>{
            // console.log("jhula",payload)
            return {loader:payload};
        })
    }
}))

export default useApiStore;