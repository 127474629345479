import { Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useLoginStore } from "../store";
import classes from './Notification.module.css';
import   notification_icon from './../../../assets/notification.svg';
import Api from "../../../services/Api";
import { SECRET_KEY, decryptData } from "../../../services/fileCode";
import moment from "moment";
import { NoData } from "../../Training_essentialDocuments";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";

const CanNotification=({setActiveTab})=>{
    const {notificationModal,notificationModalHandler,updateBasicDeatails,updateDeatails,hiringStatusUpdater}=useLoginStore();
    const [notifications,setNotifications]=useState();
    const [loader,setLoader]=useState(false);
    const {width}=useWindowDimensions();
    let u=localStorage.getItem('user');
    let user;
    if(u){
        user=decryptData(u,SECRET_KEY);
    }

    useEffect(()=>{
        setLoader(true);
        // user?.hiringUser?.email
        Api.get(`/hiring-mobile/getNotification?email=${user?.email}`).send(res=>{
            setNotifications(res);
            setLoader(false);
        })
    },[])
    return (
        <Modal
        open={notificationModal}
        onCancel={()=>{notificationModalHandler(false)}}
        style={{position:"absolute",right:0,top:0,bottom:0,borderRadius:"0",height:"100vh"}}
        footer={false}
        width={width>700?"35vw":"100vw"}
        title={<div style={{width:"100%",position:"absolute",top:0,left:0,right:0,height:"3.5rem",display:"flex",alignItems:"center",boxShadow:"0px 2px 16px #00000014",fontSize:"1.2rem",fontWeight:"600",paddingLeft:"3vmin"}}>Notifications</div>}
        className={classes.notification}

        >
            <div className={classes.content}>
                {
                    loader?<Spin style={{display:"flex",alignItems:"center",justifyContent:"center",height:"50vh"}}/>:
                
                notifications?.notiQuery?.length>0?
                notifications?.notiQuery?.map((item,index)=>{
                    return(
                        <div className={classes.eachNotification}>
                            <div>
                                <img src={notification_icon}/>
                            </div>
                            <div>
                                <span>{item.title}</span>
                                <span>{item.message}</span>
                                <div>
                                    <span>{moment(item.createdAt)?.format('DD/MM/YYYY')},{" "+moment(item.createdAt)?.format('hh:mm A')}</span>
                                    {!item.title?.includes("updating email/phone")?<span onClick={()=>{
                                         const getDetails = (sourceId) => {
                                            Api.get(`/hiring-mobile/appliedJobData?sourceId=${sourceId}`)
                                                .send((res, error) => {
                                                    updateDeatails(res)
                                                });
                                        } 
                                        // updateBasicDeatails(val)
                                        hiringStatusUpdater(2, () => {
                                            getDetails(item?.candidates?.id)
                                        })
                               
                                        setActiveTab(2);
                                        notificationModalHandler(false)
                                    }}>View details</span>:<span></span>}
                                </div>

                            </div>
                        </div>
                    )
                }):
                <div>
                    <NoData/>
                </div>
                }
            </div>

        </Modal>
    )
}

export default CanNotification;