import React, { useEffect, useState } from "react";
import { Modal } from "antd";

const ParticipantsJoinDetails = ({modalVisible,setModalVisible,RequestedUser,onRequestAction,setUserArray,requesteduserArray}) =>{
    console.log("requested",RequestedUser);

    useEffect(() =>{
 let datas=requesteduserArray.filter(ele =>ele?.userId!=RequestedUser?.userId)
 datas.push(RequestedUser)

  setUserArray(datas)
    },[RequestedUser])
 return(
<Modal
closable={false}
                                            open={modalVisible}
                                            onCancel={() => {
                                                //  setModalVisible(false)
                                                 }}
                                            footer={null}
                                            centered
                                            className='custom-modal'
                                            bodyStyle={{
                                                backgroundColor: '#2D2D2D',
                                                color: '#FFFFFF',
                                                textAlign: 'center',
                                                paddingLeft: 50,
                                                paddingRight: 50,
                                                marginTop: 20,
                                                padding: 10
                                            }}
                                        >
                                            <h2 style={{ color: '#FFFFFF' }}>Grant Permission</h2>

                                            <p>Participant(s) is requesting to join the session. Would you like to grant permission for this person to join?</p>

                                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginTop: 20,overflowY:"scroll" }}>
                                                {requesteduserArray?.map(ele =>{
                                                  return(
                                                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between",flexDirection:"row" }}>
                                                    {/* <div style={{ display: "flex", flexDirection: "column", gap: "0.3rem", alignItems: "flex-start" }}>
                                                        <div>name</div>
                                                        <div>Email ID</div>
                                                        <div>Mobile number</div>

                                                    </div> */}
                                                    <div style={{ display: "flex", flexDirection: "column", gap: "0.3rem", alignItems: "flex-start",width:"50%" }}>
                                                        <div style={{fontWeight:"bold"}}>{ele?.userName}</div>
                                                       
                                                        <div style={{fontSize:12}}>{ele.email}</div>
                                                        <div style={{fontSize:12}}>  {ele?.mobile}</div>
                                                    
                                                    </div>
                                                    <div style={{display:"flex",flexDirection:"row",width:"50%",justifyContent:"space-between"}}>
                                                    <div onClick={() => { onRequestAction("deny", ele,ele?.userId); 


                                                     }} style={{ height: "5vh", width: "40%", backgroundColor: "grey", borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                                                        Deny
                                                    </div>
                                                    <div onClick={() => { onRequestAction("allow", ele,ele?.userId); 
                                                    console.log("allow",ele);
                                                    
                                                     }} style={{ height: "5vh", width: "40%", backgroundColor: "#1089FF", borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer",marginRight:5 }}>
                                                        Allow
                                                    </div>
                                                    </div>
                                                </div>
                                                  )  
                                                })}
                                               
                                                {/* <div style={{ display: "flex", gap: "2rem", padding: 5, marginTop: 10 }}>
                                                   
                                                </div> */}

                                            </div>
                                        </Modal>
 )
}
export default ParticipantsJoinDetails