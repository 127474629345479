import React from "react";
import { NavbarContents,SubNavbarContents } from './style';
import { CloseOutlined } from "@ant-design/icons";


import brigoshaLogo from "../../assets/brigosha_logo.svg";

import annoouncementImage from '../../assets/loudspeakerHome.svg';

import newsletter_icon from '../../assets/newsletter_icon.svg';
import calendar from '../../assets/calendar.svg';

import project_icon from '../../assets/project_icon.svg';
import resource_icon from '../../assets/resource_icon.svg';
import attendance_icon from '../../assets/attendance_icon.svg';
import feedbackIcon from "../../assets/Feedback_icon.svg";
import training_doc_icon from '../../assets/report.svg';
import essential_doc_icon from '../../assets/documents.svg';
import healthcare from './../../assets/healthcare.svg';
// import annoouncementImage from '../../assets/loudspeakerHome.svg';
// import annoouncementImage from '../../assets/loudspeakerHome.svg';
import appraisal from "../../assets/appraisal.svg";
import CEoImage from '../../assets/ceo-_1_.svg';






const MobileNavModal=({setSidemenu,sidemenu,children,candidate=false,})=>{

    console.log("sidemenu",sidemenu)
    let currentURL = window.location.href;
let param = currentURL?.split('=')[1]?.split('%')[0];
console.log("pamasss", param)
currentURL = currentURL.split('/')[3];
console.log("currentURL", currentURL)

let logoDescription = <img style={{height:"5vh"}} src={brigoshaLogo} />;
if (currentURL === "newsletter") {
  logoDescription = <div style={{ display: "flex", alignItems: "center", flexDirection: "row", gap:"0.5rem", }}>
  <img src={newsletter_icon} style={{ width: "2rem", height: "2rem" }}></img>
  <h4 style={{fontWeight:"600"}}>Newsletter</h4>
</div>
 
}
if (currentURL === "health_insurance") {
  logoDescription =
  <div  style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",gap:"0.5rem"}}>
     <img src={healthcare}  style={{width:"2rem",height:"2rem",}}></img>
     <h4>Health Insurance Card</h4>
     {/* <h4>2023 - 2024</h4> */}
  </div>
}
if (currentURL === "attendance") {
  logoDescription =
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", gap:"0.5rem"}}>
      <img src={calendar} style={{ width: "2rem", height: "2rem" }}></img>
      <h4>Attendance</h4>
    </div>
}
if (currentURL === "attendancePortal") {
  logoDescription =
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row",gap:"0.5rem" }}>
      <img src={attendance_icon} style={{ width: "2rem", height: "2rem" }}></img>
      <h4>Attendance Portal</h4>
    </div>
}

if (currentURL === "project") {
  logoDescription =
    <div style={{ display: "flex", alignItems: "center", flexDirection: "row",gap:"0.5rem" }}>
      <img src={project_icon} style={{ width: "2rem", height: "2rem" }}></img>
      <h4>Project Management</h4>
    </div>
}
if (currentURL === "rmanagement") {
  logoDescription =
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row",gap:"0.5rem" }}>
      <img src={resource_icon} style={{ width: "2rem", height: "2rem" }}></img>
      <h4>Resource Management</h4>
    </div>
}
if (currentURL === "feedbackmanagement") {
  logoDescription =
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", gap:"0.5rem" }}>
      <img src={feedbackIcon} style={{ width: "2rem", height: "2rem" }}></img>
      <h4>Feedback Management</h4>
    </div>
}
if (currentURL === "announcement") {
  logoDescription = 
  <div  style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",gap:"0.5rem"}}>
  <img src={annoouncementImage}  style={{width:"2rem",height:"2rem"}}></img>
  <h4>Announcement</h4>
</div>
  // <div  style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
  //    <img src={annoouncementImage}  style={{width:"2rem",height:"2rem"}}></img>
  //    <h4>Announcement</h4>
  // </div>
}
if (currentURL === "ceoDesk") {
  logoDescription =
  <div  style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",gap:"0.5rem"}}>
     <img src={CEoImage}  style={{width:"2rem",height:"2rem"}}></img>
     <h4>CEO's Desk</h4>
  </div>
}
if (param === "Training") {
  logoDescription =
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", gap:"0.5rem"}}>
      <img src={training_doc_icon} style={{ width: "2rem", height: "2rem" }}></img>
      <h4>Training Documents</h4>
    </div>
}

if (param === "Essential") {
  logoDescription =
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row",gap:"0.5rem" }}>
      <img src={essential_doc_icon} style={{ width: "2rem", height: "2rem" }}></img>
      <h4>Essential Documents</h4>
    </div>
}

let mobileNav=<svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
<path d="M20.8389 8.77979H1.16111C0.519847 8.77979 0 9.46039 0 10.3V10.46C0 11.2996 0.519847 11.9802 1.16111 11.9802H20.8389C21.4802 11.9802 22 11.2996 22 10.46V10.3C22 9.46039 21.4802 8.77979 20.8389 8.77979Z" fill="#162432"/>
<path d="M20.8389 16.7808H1.16111C0.519847 16.7808 0 17.4614 0 18.3009V18.461C0 19.3005 0.519847 19.9811 1.16111 19.9811H20.8389C21.4802 19.9811 22 19.3005 22 18.461V18.3009C22 17.4614 21.4802 16.7808 20.8389 16.7808Z" fill="#162432"/>
<path d="M20.8389 0.778809H1.16111C0.519847 0.778809 0 1.45941 0 2.29899V2.459C0 3.29858 0.519847 3.97918 1.16111 3.97918H20.8389C21.4802 3.97918 22 3.29858 22 2.459V2.29899C22 1.45941 21.4802 0.778809 20.8389 0.778809Z" fill="#162432"/>
</svg>

if(currentURL === "appraisal"){
  // appraisal
  logoDescription=
  <div  style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
     <img src={appraisal}  style={{width:"2rem",height:"2rem"}}></img>
     <h4>Appraisal</h4>
  </div>
}



    return(

        <NavbarContents 
           style={{zIndex:'5' , position:'sticky',height:0,}}
            // className='content'
        
        >
        <div className={`${sidemenu ? 'sidemenu active' : 'sidemenu'}`} style={{padding:"0 0 2rem 0",marginTop:candidate?"-2.5vh":"-10vh"}}>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",gap:"1rem",margin:"10vh 2rem 0 2rem",alignItems:"center",}}>
                     <div style={{display:"flex",alignItems:"center",justifyContent:"center"}} >{logoDescription}</div>
                    <div 
                        onClick={() => setSidemenu(false)}
                        className='sidemenu-close-btn'
                    >
                        
                        <CloseOutlined style={{fontSize: 'calc(2vw + 10px)', color: '#0086FF'}}/>
                    </div>
                   
            </div>
                {children}
        </div>
    </NavbarContents>
    )
}

export default MobileNavModal;