import React from "react";
import ButtonCompo from "../../../design/ButonCompo";
import backIcon from '../../../assets/backIcon.svg';
import icon from '../../../assets/nojobIcon.svg';
import { ProfileStore } from "./store";
import classes from './profile.module.css';
import { useEffect, useState } from "react";
import {  Dropdown, Row, Spin, Tooltip, Typography } from "antd";
import LocIcon from '../../../assets/LocIcon.svg';
import ExpIcon from '../../../assets/ExpIcon.svg';
import ClockIcon from '../../../assets/ClockIcon.svg';
import DropDownIcon from '../../../assets/DropDownIcon.svg';
import ApplyJob from "../JobSearch/ApplyJob";
import { SaveJobApi, getAllJobApi, getAllSavedJobApi, getAuthId } from "../JobSearch/Action";
import { useLoginStore } from "../store";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";

const SavedJobs = () =>{
    const { currentPage,setCurrentPage} = ProfileStore();
    const {setActiveTab}=useLoginStore();
    const {width}=useWindowDimensions();
    const [allJob,setAllJob]=useState();
    const [clickedJob,setClickedJob]=useState();
    const [loader,setLoader]=useState(false);
const getApi=()=>{
    setLoader(true)
    let authId=getAuthId();
    let param={};
    getAllSavedJobApi(authId,param,(res)=>{
        setAllJob(res);
        setLoader(false);
    })
}

    const DropdownItems=[
        {
          key:'unSave',
          label:'Unsave',
        },
      ]
      const DropdownHandler = (key,id,saved) =>{
        
       if(key?.key==="unSave"){
        let authId=getAuthId();
         let param={jobId:id,userId:authId,save:!saved} 
          SaveJobApi(param,(res)=>{
            if(res){
                getApi();
            }
        })
      }
      
    }
    useEffect(()=>{
        getApi();
        
    },[])
    const RenderJob =({title,location,exp,id,saved,expType,applied})=>(
           <div className={applied?classes.disabled:'dddi'} style={{backgroundColor:'#fff',padding:'16px', borderRadius:5,gap:'16px',width:'100%'}}>
           <div style={{display:'flex',justifyContent:'space-between',gap:'16px',width:'100%'}}>
            <div style={{width:'40vw'}}  >
                <div style={{color:'#161616',font:'normal 600 18px/24px Lato',}}>{title}</div>
               <div style={{display:'flex', flexDirection:'column',gap:8,marginTop:'8px'}}>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <img src={ExpIcon} />
                    <div style={{marginLeft:8}}>{exp}</div>
                </div>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <img src={ClockIcon} />
                    <div style={{marginLeft:8}}>{'Full time'}</div>
                </div>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <img src={LocIcon} />
                    <Typography.Text ellipsis={{tooltip:true}} style={{width: width > 700 ? '17rem' : "19rem"}}>
                    {location?.map((loc,id)=>(<span>{loc?.name}{(location.length>1 && id<location.length-1)?',':''}</span>))}</Typography.Text>
                </div>
               </div>
            </div>
            <div style={{cursor:'pointer !important',}}  >
                <Dropdown onClick={(e)=>{ console.log('clicked');  e.stopPropagation()}} trigger="hover" menu={{items:DropdownItems,onClick:(key)=>{DropdownHandler(key,id,saved)}}}>
                              <img src={DropDownIcon} onClick={(e)=>{e.stopPropagation()}}   />
                </Dropdown>
            </div>
            </div>
            { !applied &&  <div style={{display:'flex',justifyContent:'flex-end'}}>
                <ButtonCompo title={"Apply Now"} onClick={()=>{setClickedJob({title:title,id:id,location:location,exp:exp,saved:saved,expType:expType});}}
                 style={{color:'#fff',backgroundColor:'#1089FF',marginTop:'-7%',marginRight:'1vh',height:'5.5vh'}} />
            </div>}
            {applied && <div style={{width:'100%',display:'flex',justifyContent:'flex-end',}}><div style={{marginTop:'-24px',marginRight:'10px'}}> Applied</div></div>}
    
            </div>
        )


    return(
        <>{(clickedJob===undefined)?
         <div style={{display:'flex',justifyContent:'center'}}>
            <div>
            <div className={classes.SavedJobsHeader} style={{margin: width < 700 && "0 1rem"}}> 
                <ButtonCompo title={'Back'} onClick={()=>{setCurrentPage("profile");}}  prefixIcon={backIcon} style={{color:'#161616',font:'normal 600 18px/24px Lato',justifyContent:'flex-start',marginLeft:'-24px'}}></ButtonCompo>
                <div style={{font:"normal normal 600 28px/38px Figtree"}}>Saved Jobs</div>
            </div>
                        {loader?<Row style={{height:'70vh',display:'flex',justifyContent:'center',alignItems:'center'}}><Spin  size="large"></Spin></Row>:
                        allJob?.length>0?
                        <div style={{display:'flex',display:'flex', flexDirection:'column',gap:'12px',marginTop:'16px',maxHeight:'70vh',overflowY:'scroll'}}> 
                        { allJob?.map((item)=>{
                            return (<RenderJob title={item?.title} location={item?.locations} exp={item?.experience} id={item?.id} saved={item?.saved} expType={item?.expType} applied={item?.apllied}></RenderJob>)
                            })}
                        </div>:
                            <div style={{padding:'1rem',margin: width < 700 && "0 1rem",backgroundColor:'#fff',display:'flex',justifyContent:'center',alignItems:'center',height: width < 700 ? "69vh":"75vh"}}>
                                <div style={{display:'flex',flexDirection:'column',gap:'16px'}}>
                                <img src={icon} style={{height:'200px',width:'200px'}} ></img>
                                <div style={{font: "normal normal 600 24px/29px Figtree"}}>Looks like you haven’t saved any jobs yet</div>
                                <div style={{font: "normal normal normal 18px/22px Figtree"}}>Start exploring and saving jobs you’re interested in.</div>
                                <ButtonCompo title={"Job Search"}  onClick={()=>{setActiveTab(3)}} style={{color:'#fff',backgroundColor:'#1089ff',marginTop:'24px'}} ></ButtonCompo>
                                </div>
                            </div> 
                        }
                
            </div>
        </div>
        :<div style={{display:'flex',justifyContent:'center',alignItems:'center',}}><ApplyJob job={clickedJob}  setJob={setClickedJob} /></div>}</>
    )
}
export default SavedJobs;