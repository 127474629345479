
import React from "react";
import {  Form,Select} from 'antd';
import { ModalPrimaryButton, ModalSecondaryButton } from "../../../components/Button/Button";
import DatePickerCompo from "../../../components/DatePicker/DatePickerCompo.tsx";
import { getFilterApi, } from "./ProjectAction";
import classes from "./Project.module.css";
import useProjectStore from "./store";
import moment from "moment";
import Modal from "../../../components/Modal/Modal";
import { useState } from "react";
import filter_icon from '../../../assets/filter_icon.svg';
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";

export let resetData;

const ApplyFiltersModalG=({modal,setModal,onApplyfilter,onResetFilter})=>{

    const [form]=Form.useForm();
    const Option=Select.Option;
    const [filterOption,setFilterOption,setAppliedFilter,appliedFilter]=useProjectStore((state)=>[state.filterOption,state.setFilterOption,state.setAppliedFilter,state.appliedFilter])

    const [deptId,setDeptId]=useState();
    const [managerId,setManagerId]=useState();
    const [disableB, setdisableB]=useState(true)
    const [role,setRole]=useState("")
    const {width}=useWindowDimensions();
    const [startDate,setsDate] =useState()

    resetData=()=>{
        setAppliedFilter({});
        form.resetFields();
        setRole("")
    }

    function areAllKeysFilledInArray(arr) {
        for (let i = 0; i < arr?.length; i++) {
          const obj = arr[i];
          for (let key in obj) {
            if((i>=0 && i<3) &&key==="value"){
                if (obj[key] === undefined || obj[key] === null || obj[key] === '') {
                  return false;
                }
            }
          }
        }
        return true;
      }
const FilterSubmitHandler=(val)=>{
    console.log(val,"ppppppppppppp")
    let ff={}
    if(role==="Associate"){
        ff={role:role}
    }
    if(role!=="Associate"&&val?.departmentId || appliedFilter?.departmentId){
        ff={...ff,departmentId:val?.departmentId?val?.departmentId:appliedFilter?.departmentId}
    }
    // if(val?.role || appliedFilter?.role){
    //     ff={...ff,role:val?.role?val?.role:appliedFilter?.role}
    // }
    if(val?.managerId || appliedFilter?.managerId){
        ff={...ff,managerId:val?.managerId?val?.managerId:appliedFilter?.managerId}
    }
    if(val?.coManagerId || appliedFilter?.coManagerId){
        ff={...ff,coManagerId:val?.coManagerId?val?.coManagerId:appliedFilter?.coManagerId}
    }
    if(val?.teamId || appliedFilter?.teamId){
        ff={...ff,teamId:val?.teamId?val?.teamId:appliedFilter?.teamId}
    }
    if(val?.startDate){
        ff={...ff,startDate:moment(val?.startDate).format("YYYY-MM-DD")}
    }
    if(val?.endDate){
        ff={...ff,endDate:moment(val?.endDate).format("YYYY-MM-DD")}
    }
    ff={...ff,role:role}
    console.log(ff,"nounsssss");
    setAppliedFilter(ff)
    onApplyfilter(ff)
    if(!disableB){
        setModal(false)
    }
    console.log(appliedFilter,"appliedFilterappliedFilter");
}
    return(
        <Modal show={modal} closableLeft={true}
        header={
            <div style={{display:"flex",justifyContent:"center", alignItems:"center", width:"100%"}}>
              <img src={filter_icon} style={{width:"1rem",height:"1rem",marginRight:5}}/>
               <h3>Apply Filter</h3>
           </div>}
         style={{width:width<700?"90%":"35vw",height:"100vh",borderRadius:" 20px 0px 0px 20px",overflowY:"scroll"}}
         right={true}
         onCancel={()=>setModal(false)}>           
                <Form layout="vertical" form={form} 
                onFieldsChange={(changedFields, allFields)=>{
                    console.log(allFields,"allfieldsssssss");
                    if(areAllKeysFilledInArray(allFields)){
                        setdisableB(false)
                    }else{
                        setdisableB(true)
                    }
                }}
                initialValues={appliedFilter?{
                //     departmentId:appliedFilter?.departmentId,
                //     roleId:appliedFilter?.roleId,
                //     managerId:appliedFilter?.managerId,
                //     coManagerId:appliedFilter?.coManagerId,
                //     teamId:appliedFilter?.teamId,
                    // startDate:appliedFilter?.startDate?moment(appliedFilter?.startDate):undefined,
                    // endDate:appliedFilter?.endDate?moment(appliedFilter?.endDate):undefined,
                    }:null}
                onFinish={FilterSubmitHandler}
                style={{width:'100%',display:'flex', flexDirection:'column',marginTop:'0rem',padding:"0 2rem"}}
                >

                    <Form.Item label={<span className={classes.heading1}>Department</span>} name="departmentId">
                        <Select allowClear  placeholder="Select" defaultValue={appliedFilter?.departmentId}  onChange={(val)=>{setDeptId(val);setManagerId(); form.resetFields(['managerId','teamId']); console.log("kaushal 123"); getFilterApi(false,{departmentId:val},(res)=>{setFilterOption({...filterOption,...res})})}}>
                            {filterOption?.departments?.map((item,index)=>{
                                return(
                                    <Option value={item.id} key={index}>{item.name}</Option>
                                )
                            })}

                        </Select>
                    </Form.Item>
                    {console.log(filterOption,"filterOptionfilterOptionfilterOptionfilterOption")}
                    <Form.Item label={<span className={classes.heading1}>Role</span>} name="roleId" >
                        <Select allowClear disabled={deptId===undefined} onChange={(e)=>{setRole(e);getFilterApi(false,{role:e, departmentId:deptId},(res)=>{setFilterOption({...filterOption,...res})})}}  placeholder="Select" defaultValue={appliedFilter?.role}>
                        {filterOption?.roles?.map((item,index)=>{
                                return(
                                    <Option value={item} key={index}>{item}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                   {/* <Form.Item label={<span className={classes.heading1}>Role</span>} name="role">
                        <Select  placeholder="Select" allowClear={true} defaultValue={appliedFilter?.role} >
                            {filterOption?.role?.map((item,index)=>{
                                return(
                                    <Option value={item} key={index}>{item}</Option>
                                )
                            })}

                        </Select>
                    </Form.Item> */}
                    
                    {
                        (role==="Super Admin"||role==="Department Head"||role==="")&&
                        <Form.Item label={<span className={classes.heading1}>Manager</span>} name="managerId">
                        <Select allowClear  disabled={role===""} placeholder="Select"  defaultValue={appliedFilter?.managerId} onChange={(val)=>{setManagerId(val); form.resetFields(['teamId']); console.log("kaushal 123"); getFilterApi(false,{departmentId:deptId,managerId:val},(res)=>{setFilterOption({...filterOption,...res})})}} >
                        {filterOption?.managers?.map((item,index)=>{
                                return(
                                    <Option value={item.id} key={index}>{item.name}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    }
                    
                    {/* {filterOption?.coManager?<Form.Item label={<span >Co-Manager</span>} name="coManagerId" >
                        <Select   placeholder="Select">
                            {filterOption?.coManager?.map((item,index)=>{
                                return(
                                    <Option value={item?.coManagerId} key={index}>{item?.coManagerName}</Option>
                                )
                            })}

                        </Select>
                    </Form.Item>:''} */}
                    {
                        (role!=="Associate"||role==="")&&
                        <Form.Item label={<span className={classes.heading1}>Team</span>} name="teamId" >
                        <Select allowClear disabled={!(role==="Super Admin"||role==="Department Head"||role==="")?false:managerId===undefined}  placeholder="Select" defaultValue={appliedFilter?.teamId}>
                        {filterOption?.teams?.map((item,index)=>{
                                return(
                                    <Option value={item.id} key={index}>{item.name}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    }
                    
                    <Form.Item label={<span className={classes.heading1}>Start Date</span>} name="startDate">
                        <DatePickerCompo
                        defaultValue={appliedFilter?.startDate?moment(appliedFilter?.startDate):undefined}
                        onChange={(date, dateString) => {setsDate(dateString)  }}
                        style={{
                             width: "100%", height: 30, 
                            border: "#E6E6E6 solid 0px",
                            borderRadius: "10px",
                            height: "2.5rem",
                            border:" 1px solid #ECECEC",
                        }} format="YYYY-MM-DD"/>
                    </Form.Item>

                    <Form.Item label={<span className={classes.heading1}>End Date</span>} name="endDate">
                        <DatePickerCompo
                        disabledDate={(current) => {
                           
                            return current && current < moment(startDate);
                          }}
                        defaultValue={appliedFilter?.endDate?moment(appliedFilter?.endDate):undefined}
                        style={{
                            width: "100%", height: 30, 
                            border: "#E6E6E6 solid 0px",
                            borderRadius: "10px",
                            height: "2.5rem",
                            border:" 1px solid #ECECEC",
                        }} format="YYYY-MM-DD"/>
                    </Form.Item>
                        <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center',gap:'16px'}}>
                        <Form.Item>
                        <ModalSecondaryButton  style={{backgroundColor:"#FFFFFF", height:40}} onCancel={()=>{setAppliedFilter({});form.resetFields();onResetFilter();setRole("");setModal(false)}}>Reset</ModalSecondaryButton>
                        </Form.Item>
                        <Form.Item>
                        <ModalPrimaryButton disabled={disableB} style={{cursor:disableB?"not-allowed":"pointer"}} htmlType="submit">Apply</ModalPrimaryButton>
                        </Form.Item>
                        </div>

                </Form>                             
            
            </Modal>
    )
}

export default React.memo(ApplyFiltersModalG);