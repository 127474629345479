import React, { useEffect, useState } from "react";
import BackRoundIcon from "../../../assets/backRoundIcon.svg";
import { Button, Form, Input, Modal, Select, Upload,Space,Typography,Spin } from 'antd';
import { ProfileStore } from "./store";
import UploadIcon from "../../../assets/UploadBlueIcon.svg";
import Api from "../../../services/Api";
import PhoneInput from '../../../components/PhoneInput/PhoneInput';
import classes from "./profile.module.css";
import PdfLogo from "../../../assets/PdfLogoIcon.svg";
import Notification from "../../../services/Notification";
import { UploadFile } from "../JobSearch/Action";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import { MobilePDFReader } from 'react-read-pdf';
import { Document, Page } from 'react-pdf';

const countryCodes = require('country-codes-list')
const myCountryCodesObject = countryCodes.customList('countryCode', '[{countryCode}] {countryNameEn}: +{countryCallingCode}');

const EditDetails = (props) =>{
    const [workExperience,setWorkExperience] = useState(null);
    const [imgFile, setImgFile] = useState("");
    const {width}=useWindowDimensions();
    const [initialValues, setInitialValues] = useState({})
    const [initialImageUrl, setInitialImageUrl] = useState("")
    const [formValues, setFormValues] = useState({});
    const [isFormChanged, setIsFormChanged] = useState(false); 
    const [viewResume, setViewResume] = useState(false); 
    const [resumeFile, setResumeFile] = useState(null); 
    const { currentPage,setCurrentPage,userData,candidateDetails, setCandidateDetails,loading,setLoading} = ProfileStore();
    const [form] = Form.useForm();
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
      }
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const getDetails=()=>{
        setLoading(true);
        Api.get(`/hiring-mobile/getProfile?email=${userData.email}`)
        .send((res)=>{
        if(res !== undefined){
         setCandidateDetails(res);
         setLoading(false)
        }
        })  
    }    
    
    useEffect(() => {
          getDetails();
    },[]);

    useEffect(() => {
        setInitialValues({
            yearExp: candidateDetails?.experienceYears,
            monthExp: candidateDetails?.experienceMonths,
            currentLocation: candidateDetails?.currentLocation,
            // preferredLocation: candidateDetails?.preferredLocation?.name,
            currentOrganization: candidateDetails?.currentCompany,
            noticePeriod: candidateDetails?.noticePeriod,
            currentSalary: candidateDetails?.currentCtc,
            expectedSalary: candidateDetails?.expectedCtc,
            resume: candidateDetails?.resume,
        })
        setInitialImageUrl(candidateDetails?.resume || "");
    
        form.setFieldsValue({
            yearExp: candidateDetails?.experienceYears,
            monthExp: candidateDetails?.experienceMonths,
            currentLocation: candidateDetails?.currentLocation,
            // preferredLocation: candidateDetails?.preferredLocation?.name,
            currentOrganization: candidateDetails?.currentCompany,
            noticePeriod: candidateDetails?.noticePeriod,
            currentSalary: candidateDetails?.currentCtc,
            expectedSalary: candidateDetails?.expectedCtc,
            resume: candidateDetails?.resume,
        });
    }, [candidateDetails, form]);

    const imgbeforeUpload = (file) => {
        const isPdf = file.type === "application/pdf" ;
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isPdf) {
          Notification.error("You can only upload pdf file!");
          setImgFile("");
        } 
        else if (!isLt5M) {
          Notification.error("Pdf must smaller than 5MB!");
          setImgFile("");
        } 
        else {
          getBase64(file, (url) => {      
            setImgFile(file);
          });
        }
        return false
      };

    const handleFileRemove = () => {
        setImgFile("");
    };


    const handleValuesChange = (changedValues, allValues) => {
        const isFormChanged = Object.keys(changedValues).some(
            (key) => changedValues[key] !== initialValues[key]
        );
        setIsFormChanged(isFormChanged);
    };

    const onFinishHandler =(values) =>{
       
        let params = {email:candidateDetails?.email};
        if (values.yearExp !== initialValues.yearExp) {
            params.experienceInYear = values.yearExp;
        }
        if (values.monthExp !== initialValues.monthExp) {
            params.experienceInMonth = values.monthExp;
        }
        if (values.currentLocation !== initialValues.currentLocation) {
            params.currentLocation = values.currentLocation;
        }
        if (values.currentOrganization !== initialValues.currentOrganization) {
            params.currentCompany = values.currentOrganization;
        }
        if (values.noticePeriod !== initialValues.noticePeriod) {
            params.noticePeriod = values.noticePeriod;
        }
        if (values.currentSalary !== initialValues.currentSalary) {
            params.currentCtc = values.currentSalary;
        }
        if (values.expectedSalary !== initialValues.expectedSalary) {
            params.expectedCtc = values.expectedSalary;
        }
        if (imgFile) {
            params.resume = imgFile;
        }
        if (Object.keys(params).length > 1) {
            if(imgFile){
               UploadFile({file:imgFile},(res)=>{
                delete params.resume;
                params.resume=res;
                Api.patch(`/hiring-mobile/jobUpdate`)
                .params(params) 
                .send((response) => { 
                    if (response!==undefined) {       
                        if(response?.show?.type === "success"){ 
                            props.refresh();
                            getDetails();
                        }
                    }
                });
               }) 
            }
            else if(values){
                Api.patch(`/hiring-mobile/jobUpdate`)
                .params(params) 
                .send((response) => { 
                    if (response!==undefined) {       
                        if(response?.show?.type === "success"){ 
                            props.refresh();
                            getDetails();
                        }
                    }
                });
            }
    }
    }

    return(
        <div style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",padding:"2vh 0"}}>
         <div style={{width: width >700? "40%":"90%",}}>
            <div style={{display:"flex",width:"100%",justifyContent:"flex-start",alignItems:"center",cursor:"pointer",fontSize:"0.9rem"}} onClick={()=>{
                setCurrentPage("profile");
            }}>
                <img src={BackRoundIcon} alt="" style={{borderRadius:"50%",marginRight:"0.7rem",height:"1.5vh",}}/> 
                Back
            </div>
            <h3 style={{display:"flex",width:"100%",fontSize:20,justifyContent:"flex-start",margin:"1vh 0 1vh 0"}}>Profile Details</h3>
            <div style={{backgroundColor:"#FFFFFF",width:"100%",padding:"2.5vh 2vw",overflow:"auto",height:"77.5vh"}}>
                {loading ? 
                <div style={{display:"flex",width:"100%",height:"100%",justifyContent:"center",alignItems:"center"}}>
                    <Spin />
                </div>
                :
                <Form   layout="vertical" initialValues={initialValues} form={form} onValuesChange={handleValuesChange}
                 onFinish={onFinishHandler}>
                    <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",height:"75vh"}}>
                        <div style={{display:'flex',alignItems:'center',gap:'0.5rem'}}>
                        <Form.Item style={{width:'100%'}} name="yearExp" label="Total work experience in year" rules={[{ required: true,message: "Please enter the experience year"},]}>
                            <Input onWheel={(e) => e.target.blur()} type="number" placeholder="Enter year" min={0} onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} />
                                
                        </Form.Item>
                        <Form.Item style={{width:'100%'}} name="monthExp" 
                         label="Total work experience in month" 
                         rules={[{ required: true,message: "Please enter the experience month"},]}>
                            <Input onWheel={(e) => e.target.blur()} type="number" placeholder="Enter month" min={0} max={11} onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} />
                        </Form.Item>
                        </div>
                        <Form.Item name="currentLocation" label={<div>Current location</div>} rules={[{ required: true,whitespace:true,
                            message: "Please enter the current location",
                        },]}>
                            <Input type="text" placeholder="Enter current location" />
                        </Form.Item>
                       
                        <Form.Item name="currentOrganization" label={<div>Current organization</div>} rules={[{ required: true,whitespace:true,
                            message: "Please enter the organization",
                        },]}>
                            <Input type="text" placeholder="Enter organization" />
                        </Form.Item>
                        <Form.Item name="noticePeriod" label={<div>Notice period</div>} rules={[{ required: true,
                            message: "Please enter the notice period",
                        },]}>
                            <Input onWheel={(e) => e.target.blur()} type="number" min={0} placeholder="Enter notice period" onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} suffix={<span style={{color:'#cacaca'}}>days</span>} />
                        </Form.Item>
                        <Form.Item name="currentSalary" label="Annual current CTC" rules={[{ required: true,
                            message: "Please enter the current ctc",
                        },]}>
                            <Input onWheel={(e) => e.target.blur()} type="number" placeholder="Enter current ctc" min={0} onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}  suffix={<span style={{color:'#cacaca'}}>₹</span>}/>
                        </Form.Item>
                        <Form.Item name="expectedSalary" label="Annual expected CTC" rules={[{ required: true,
                            message: "Please enter the expected ctc",
                        },]}>
                            <Input onWheel={(e) => e.target.blur()} type="number" placeholder="Enter expected ctc" min={0} onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}  suffix={<span style={{color:'#cacaca'}}>₹</span>}/>
                        </Form.Item>
                        <Form.Item name="resume" label={<div>Resume</div>} rules={[{ required: true,
                            message: "Please upload the resume",
                        },]}>
                          <Upload.Dragger beforeUpload={imgbeforeUpload}  accept=".pdf"
                            multiple={false} maxCount={1} onRemove={handleFileRemove} style={{backgroundColor:"#EAF4FF",borderColor:"#1089FF"}}>
                            <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",gap:"0.8rem"}}>
                            {initialImageUrl ? (<div style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"space-between",padding:"0 2vw"}}>
                                <div style={{display:"flex",flexDirection:"row",gap:"1rem",alignItems:"center"}}>
                                <img
                                    src={PdfLogo}
                                    alt="Initial"
                                    style={{ height: "2.5rem", width: "2.5rem",}}
                                />
                                <Typography.Text style={{width:"11rem"}} ellipsis={{tooltip:true}}>
                                    {initialImageUrl.split('/')[4]}
                                </Typography.Text>
                                {/* <p>{initialImageUrl.split('/')[4]}</p> */}
                                </div>
                                {width > 700 &&
                                <p className="ant-upload-hint" style={{marginTop:"0.5rem",color:"#0086FF"}}
                                onClick={()=>{ 
                                    // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
                                    // if (!isMobile) {
                                        window.open(initialImageUrl, '_blank');
                                    // } else {
                                    //     setViewResume(true);
                                    //     setResumeFile(initialImageUrl);
                                    // }
                                    // window.open(initialImageUrl, '_blank'); 
                                }}>View</p>
                                }
                                </div>
                                ) : 
                                (<>
                                <p className="ant-upload-drag-icon">
                                <img src={UploadIcon} style={{height:"2.5rem",width:"2.5rem",}}/>
                               </p>
                               <div style={{marginTop:"-1vh"}}>
                               <h3>Upload your file here</h3>
                               <p className="ant-upload-hint" style={{color:"#808080"}}>Only PDF files with max size of 5 MB</p> 
                               </div>
                               </>)
                            }
                            </div>
                          </Upload.Dragger>
                          {(width < 700 && initialImageUrl) &&
                                <div className="ant-upload-hint" style={{marginTop:"0.5rem",color:"#0086FF",width:"100%",textAlign:"center"}}
                                onClick={()=>{ 
                                        setViewResume(true);
                                        setResumeFile(initialImageUrl);
                                }}>View Resume</div>
                          }
                        </Form.Item>
                    {/* </div> */}
                    {/* :<div style={{marginTop:"8rem"}}></div>
                    } */}
                    <div style={{display:"flex",justifyContent:"center",width:"100%",marginBottom:"1vh",marginTop:"0.5rem"}}>
                    <Form.Item>
                    <Button type="primary" htmlType="submit"  style={{height:"5.5vh",width:"10rem"}} disabled={!isFormChanged}>
                      Update
                    </Button>
                    </Form.Item>
                    </div>
                    </div>
                </Form>
                }
            </div>
            {
                viewResume &&
                <Modal open={viewResume} footer={null} onCancel={() => { setViewResume(false);setResumeFile(null);}} centered>
                  
                <div className='jdPdf' style={{margin:'1.5rem auto 1rem auto',minHeight:'300px',maxHeight:'80vh'}}>
                    <Document file={resumeFile} onLoadSuccess={onDocumentLoadSuccess} width="100%" height="100%" >
                        {Array.from(new Array(numPages), (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                        ))}
                    </Document>
                    </div>
                    {/* <iframe
                        title="File Viewer"
                        style={{ width: '100%', height: '500px', marginTop: 20 }}
                        src={jd?.file}
                    ></iframe> */}
                </Modal>
            }
         </div>
        </div>
    )
}
export default EditDetails;