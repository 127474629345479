import React, { useEffect, useRef, useState } from "react";

import Cards from "./cards";

import { dashboardData } from "./action";
import BarChart from "../../../../components/BarChart/barChart";
import classes from "./mainpage.module.css";
import { Select, Spin } from "antd";
import {CarouselArrowButton,} from "../../../../components/Button/Button";
import rightArrow from "../../../../assets/right_arrow.svg";
import leftArrow from "../../../../assets/left_arrow.svg";
import moment from "moment";
import CircleProgressBar from "../../../../components/CircleProgressBar/CircleProgressBar";
import { KeyIsNotEmpty } from "../Employees/formData";
import BarChartData from "./BarGraphData";

const {Option} = Select;
function MainPage() {
  const [barData, setBarData] = useState([
    {
      joined:0,
      resigned:0,
      month:'Jan',
    },
    {
      joined:0,
      resigned:0,
      month:'Feb',
    },
    {
      joined:0,
      resigned:0,
      month:'Mar',
    },
    {
      joined:0,
      resigned:0,
      month:'Apr',
    },
    {
      joined:0,
      resigned:0,
      month:'May',
    },
    {
      joined:0,
      resigned:0,
      month:'Jun',
    },
    {
      joined:0,
      resigned:0,
      month:'Jul',
    },
    {
      joined:0,
      resigned:0,
      month:'Aug',
    },
    {
      joined:0,
      resigned:0,
      month:'Sep',
    },
    {
      joined:0,
      resigned:0,
      month:'Oct',
    },
    {
      joined:0,
      resigned:0,
      month:'Nov',
    },
    {
      joined:0,
      resigned:0,
      month:'Dec',
    },
  ]);
  const [cardCount, setCardCount] = useState({});
  const [selectValueYear,setSelectValueYear] = useState(moment().year());
  const [selectValueMonth,setSelectValueMonth] = useState([moment().month()+1])
  const [sum,setSum]=useState(0);
  const [statusData,setStatusData]=useState();
  const containerRef = useRef(null);
  const [loader,setLoader]=useState(false);
  const [statusLoader,setStatusLoader]=useState(false);

  useEffect(()=>{
    setLoader(true)
  },[])

  useEffect(() => {
    let donutColors = [
      "#B6EEFA",
      "#BBB6FA",
      "#FFC9C9",
      "#FFF0C9",
      "#FAB6DB",
      "#F4F4F4",
    ];
setStatusLoader(true)
let param={year:selectValueYear}
if(selectValueMonth!==0){
  param={...param,month:selectValueMonth}
}
    dashboardData(param, (response) => {
      let tempDonutData = [];
      setCardCount(response?.dashboard);
      let sum=0;
      Object.keys(response?.associateStatus).forEach((e, index) => {
        let associateValue=response?.associateStatus[e] === null
        ? 0
        : response?.associateStatus[e]
        sum+=associateValue;
        let dataObj = {
          name: e,
          value: associateValue,
          color: donutColors[index],
        };
        tempDonutData = [...tempDonutData, dataObj];
      });
      setSum(sum);
      setStatusData(response?.associateStatus);
      let tempArr=[]
        barData?.forEach((val)=>{
         
        if(KeyIsNotEmpty(response?.barChart[val?.month])){
          tempArr.push({...val,joined:response?.barChart[val?.month]?.joined,resigned:response?.barChart[val?.month]?.resigned})
        }
        else{
          tempArr.push(val)
        }
})

      setBarData(tempArr);
      setLoader(false);
setStatusLoader(false);
    });

    return () => {
    };
  }, [selectValueYear,selectValueMonth]);

  const handleScrollLeft = () => {
    const container = containerRef.current;
    container.scrollLeft -= 300; 
  };

  const handleScrollRight = () => {
    const container = containerRef.current;
    container.scrollLeft += 300; 
  };
  const MonthList=['All', 'Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  const YearList=[];
  for(let i=moment().year();i>2010;i--){
    YearList.push(i);
  }
  return (
    <div style={{width: "100%",background:'#fff'}}>
      <div style={{display:'flex',flexDirection:'column',padding:'16px 20px',gap:'6px'}}>
        <div style={{font:'normal 700 26px/32px Lato',color:'#162432'}}>Dashboard</div>
        <div style={{color:'#16243291',font:'normal 400 16px/20px Lato'}}>Fueling Success: Resources Aligned, Productivity Defined, Dreams Realized</div>
      </div>
      {loader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'80vh'}}><Spin /></div>:
      <div className={classes.dashBody} >
      <div className={classes.card_banner}>
        <CarouselArrowButton
          style={{
            width: "1rem",
            height: "1rem",
            style: "none",
            all: "unset",
            cursor:"pointer",
          }}
          icon={rightArrow}
          onClick={handleScrollLeft}
        />
        <div className={classes.cards_container} ref={containerRef}>
          <Cards title="Head Of Departments"  count={cardCount.HODs}  />
          <Cards title="Managers" count={cardCount.managers}  />
          <Cards
            title="Associates"
            count={cardCount.associates}
           
          />
          <Cards
            title="Departments"
            count={cardCount.departments}
           
          />
          <Cards title="Teams" count={cardCount.teams} />
        </div>
        <CarouselArrowButton
          style={{
            width: "1rem",
            height: "1rem",
            all: "unset",
            cursor: "pointer",
          }}
          icon={leftArrow}
          onClick={handleScrollRight}
        />
      </div>

      <div style={{padding:"20px 20px",display:'flex',flexDirection:'column',gap:'2rem'}}>
        
        <div style={{display:'flex',flexDirection:'column',width:'100%',background:'#fff',padding:'1rem',borderRadius:'10px',}}>
            <div 
            className={classes.recruitmentHeader}
            
            >
            
              <div style={{font:'normal 700 22px/24px Lato'}}>Associate Recruitment</div>
              <div style={{ display: "flex",alignItems: "center",gap:'8px',}}>
                
                  <div style={{background:'#0086FF',height:'12px',width:'12px',borderRadius:'50%'}}
                  />
                  <div style={{ fontWeight: "500",paddingRight:'8px' }}>Joined</div>
                
                <div style={{background:'#F7E29A', height:'12px',width:'12px',borderRadius:'50%',}}/>
                  
                  <div style={{ fontWeight: "500" }}>Resigned</div>
                
              </div>
          
            </div>

            <div
              style={{
                height: "100%",
                width:'100%',
                justifyContent:'center',
                alignItems:'center',
                flex: "1",
              
              }}
            > 
                {/* <BarChart data={barData} firstColor={'#0086FF'} secondColor={'#F7E29A'} /> */}
             <BarChartData data={barData} firstColor={'#0086FF'} secondColor={'#F7E29A'} />
            </div>
        </div>




          <div  style={{display:'flex',flexDirection:'column',gap:'1rem',width:'100%',background:'#fff',padding:'1rem',borderRadius:'10px',boxSizing:'border-box',}}>
             
              <div  className={classes.statusHeader} >
                <div 
                style={{
                  display: "flex",
                flexDirection:"row",
                justifyContent:"space-between",
                width:'100%',
                }}
              >
                <div style={{font:'normal 700 22px/24px Lato'}}>Associate Status</div>

                </div>
                <div style={{display:'flex',alignItems:'center',gap:'8px'}}>
                <Select placeholder="1Year" defaultValue={selectValueMonth}  style={{ width: "10rem",background:'#f9f9f9' }}
                onChange={(e)=>setSelectValueMonth(e)}>
                {MonthList?.map((val,id)=>{
                  return(
                    <Option value={id} key={id}>{val}</Option>
                  )
                })}
                </Select>
                <Select placeholder="1Year" defaultValue={selectValueYear} style={{ width: "10rem",background:'#f9f9f9' }}
                onChange={(e)=>setSelectValueYear(e)}>
                  {YearList?.map((val,id)=>{
                    return(
                      <Option value={val} key={id}>{val}</Option>
                    )
                  })}
                </Select>
            
                </div>
              </div>




              <div className={classes.circleContainer} >
      {statusLoader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><Spin /></div>:
            <div className={classes.associateStatus}>
              
             <div style={{display:'flex',flexDirection:'column',gap:'8px',alignItems:'center'}}>
              <CircleProgressBar strokeColor="#0086ff1a" progressColor="#0086ff" strokeWidth={10} sqSize={100} percentage={KeyIsNotEmpty(statusData?.joined)?Math.round(parseInt(statusData?.joined)*100/sum):0} />
              <div style={{display:'flex',alignItems:'center',gap:'6px'}}>
                <div style={{height:'1rem',width:'2rem',borderRadius:'10px',background:"#0086ff"}}></div>
                <div style={{font:'normal 600 16px/20px Lato',color:'#162432'}}>Joined</div>
              </div>
              </div>
             <div style={{display:'flex',flexDirection:'column',gap:'8px',alignItems:'center'}}>
              <CircleProgressBar strokeColor="#fd86001a" progressColor="#fd8600" strokeWidth={10} sqSize={100} percentage={KeyIsNotEmpty(statusData?.onNotice)?Math.round(parseInt(statusData?.onNotice)*100/sum):0}/>
              <div style={{display:'flex',alignItems:'center',gap:'6px'}}>
                <div style={{height:'1rem',width:'2rem',borderRadius:'10px',background:"#fd8600"}}></div>
                <div style={{font:'normal 600 16px/20px Lato',color:'#162432',minWidth:'75px'}}>On Notice</div>
              </div>
              </div>
             <div style={{display:'flex',flexDirection:'column',gap:'8px',alignItems:'center'}}>
              <CircleProgressBar strokeColor="#C04FCA1a" progressColor="#C04FCA" strokeWidth={10} sqSize={100} percentage={KeyIsNotEmpty(statusData?.training)?Math.round(parseInt(statusData?.training)*100/sum):0}/>
              <div style={{display:'flex',alignItems:'center',gap:'6px'}}>
                <div style={{height:'1rem',width:'2rem',borderRadius:'10px',background:"#C04FCA"}}></div>
                <div style={{font:'normal 600 16px/20px Lato',color:'#162432'}}>Training</div>
              </div>
              </div>
             <div style={{display:'flex',flexDirection:'column',gap:'8px',alignItems:'center'}}>
              <CircleProgressBar strokeColor="#FFCF211a" progressColor="#FFCF21" strokeWidth={10} sqSize={100} percentage={KeyIsNotEmpty(statusData?.onJobTraining)?Math.round(parseInt(statusData?.onJobTraining)*100/sum):0}/>
              <div style={{display:'flex',alignItems:'center',gap:'6px'}}>
                <div style={{height:'1rem',width:'2rem',borderRadius:'10px',background:"#FFCF21"}}></div>
                <div style={{font:'normal 600 16px/20px Lato',color:'#162432',minWidth:'87px'}}>Job Training</div>
              </div>
              </div>
             <div style={{display:'flex',flexDirection:'column',gap:'8px',alignItems:'center'}}>
              <CircleProgressBar strokeColor="#39CC7C1a" progressColor="#39CC7C" strokeWidth={10} sqSize={100} percentage={KeyIsNotEmpty(statusData?.deployed)?Math.round(parseInt(statusData?.deployed)*100/sum):0}/>
              <div style={{display:'flex',alignItems:'center',gap:'6px'}}>
                <div style={{height:'1rem',width:'2rem',borderRadius:'10px',background:"#39CC7C"}}></div>
                <div style={{font:'normal 600 16px/20px Lato',color:'#162432'}}>Deployed</div>
              </div>
              </div>
             <div style={{display:'flex',flexDirection:'column',gap:'8px',alignItems:'center'}}>
              <CircleProgressBar strokeColor="#FF34341a" progressColor="#FF3434" strokeWidth={10} sqSize={100} percentage={KeyIsNotEmpty(statusData?.exit)?Math.round(parseInt(statusData?.exit)*100/sum):0}/>
              <div style={{display:'flex',alignItems:'center',gap:'6px'}}>
                <div style={{height:'1rem',width:'2rem',borderRadius:'10px',background:"#FF3434"}}></div>
                <div style={{font:'normal 600 16px/20px Lato',color:'#162432'}}>Exit</div>
              </div>
              </div>
            </div>}

              </div>
          </div>


        </div>
      
      </div>}
    </div>
  );
}

export default MainPage;
