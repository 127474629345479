import React from "react";
import classes from './ContinueReading.module.css';
import { BackButton } from "../../components/Button/Button";
import moment from "moment";
import {Typography} from "antd";
import back_icon from '../../assets/backIcon.svg';
import poweredBy_icon from '../../assets/poweredBy_icon.svg';
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";
import { useHeaderStore } from "../Header/store";
import MobileNavModal from "../../components/MobileNavModal/MobileNavModal";

const ContinueReading=({setPage,continueData,recentNewsLetters,setContinueData})=>{
    const {width}=useWindowDimensions();
    const {sidemenu,setSidemenu}=useHeaderStore();
    return(
        <>
                <MobileNavModal sidemenu={sidemenu} setSidemenu={setSidemenu}>
                <div className={classes.sider} onClick={()=>{
                setPage(1);
                setSidemenu(false);
                }}>               
                                <div>                 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20" fill="none">
                                            <path d="M5.99647 0H1.19929C0.538209 0 0 0.542589 0 1.20905V6.04527C0 6.71173 0.538209 7.25432 1.19929 7.25432H5.99647C6.65756 7.25432 7.19577 6.71173 7.19577 6.04527V1.20905C7.19577 0.542589 6.65756 0 5.99647 0Z" fill="#0086FF"/>
                                            <path d="M15.8002 0H11.003C10.3419 0 9.80371 0.768667 9.80371 1.71283V8.56413C9.80371 9.50828 10.3419 10.277 11.003 10.277H15.8002C16.4613 10.277 16.9995 9.50828 16.9995 8.56413V1.71283C16.9995 0.768667 16.4613 0 15.8002 0Z" fill="#0086FF"/>
                                            <path d="M5.99647 9.72314H1.19929C0.538209 9.72314 0 10.4918 0 11.436V18.2873C0 19.2314 0.538209 20.0001 1.19929 20.0001H5.99647C6.65756 20.0001 7.19577 19.2314 7.19577 18.2873V11.436C7.19577 10.4918 6.65756 9.72314 5.99647 9.72314Z" fill="#0086FF"/>
                                            <path d="M15.8002 12.6885H11.003C10.3419 12.6885 9.80371 13.2311 9.80371 13.8975V18.7337C9.80371 19.4002 10.3419 19.9428 11.003 19.9428H15.8002C16.4613 19.9428 16.9995 19.4002 16.9995 18.7337V13.8975C16.9995 13.2311 16.4613 12.6885 15.8002 12.6885Z" fill="#0086FF"/>
                                            </svg>
                                    <span>Dashboard</span>
                                </div>
            
                                <div>
                                        <img src={poweredBy_icon}></img>
                                </div>      
                        </div>
                </MobileNavModal>
                <div className={classes.newsLetter}>
                {width>700&&<div className={classes.sider} onClick={()=>{
                            setPage(1);
                        }}>                  
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20" fill="none">
                                        <path d="M5.99647 0H1.19929C0.538209 0 0 0.542589 0 1.20905V6.04527C0 6.71173 0.538209 7.25432 1.19929 7.25432H5.99647C6.65756 7.25432 7.19577 6.71173 7.19577 6.04527V1.20905C7.19577 0.542589 6.65756 0 5.99647 0Z" fill="#0086FF"/>
                                        <path d="M15.8002 0H11.003C10.3419 0 9.80371 0.768667 9.80371 1.71283V8.56413C9.80371 9.50828 10.3419 10.277 11.003 10.277H15.8002C16.4613 10.277 16.9995 9.50828 16.9995 8.56413V1.71283C16.9995 0.768667 16.4613 0 15.8002 0Z" fill="#0086FF"/>
                                        <path d="M5.99647 9.72314H1.19929C0.538209 9.72314 0 10.4918 0 11.436V18.2873C0 19.2314 0.538209 20.0001 1.19929 20.0001H5.99647C6.65756 20.0001 7.19577 19.2314 7.19577 18.2873V11.436C7.19577 10.4918 6.65756 9.72314 5.99647 9.72314Z" fill="#0086FF"/>
                                        <path d="M15.8002 12.6885H11.003C10.3419 12.6885 9.80371 13.2311 9.80371 13.8975V18.7337C9.80371 19.4002 10.3419 19.9428 11.003 19.9428H15.8002C16.4613 19.9428 16.9995 19.4002 16.9995 18.7337V13.8975C16.9995 13.2311 16.4613 12.6885 15.8002 12.6885Z" fill="#0086FF"/>
                                        </svg>
                                <span>Dashboard</span>
                            </div>

                            <div>
                                    <img src={poweredBy_icon}></img>
                            </div>      
                    </div>}
                
            
                <div className={classes.continue}>
                    <div className={classes.backDiv}>
                        <div>
                        <svg onClick={()=>{
                            setPage(1)
                        }} xmlns="http://www.w3.org/2000/svg" style={{cursor:"pointer"}} width="15" height="20" viewBox="0 0 15 26" fill="none">
                            <path d="M13 2L2.35355 12.6464C2.15829 12.8417 2.15829 13.1583 2.35355 13.3536L13 24" stroke="#162432" stroke-width="3" stroke-linecap="round"/>
                        </svg>
                            <Typography.Text style={{maxWidth:"45rem",fontSize:"1.4rem",fontFamily:"Poppins",fontWeight:"600"}} ellipsis={{tooltip:true}} >   {continueData.title}</Typography.Text>    
                                {/* <img src={back_icon} onClick={()=>setPage(1)} style={{width:"1.3rem",height:"1.3rem",cursor:'pointer'}}/> */}
                            

                        </div>
                        <div>
                    
                            <img src={continueData.image} style={{height:"10rem"}}></img>
                    
                        <div>
                            <span>{moment(continueData.createdAt).format("MMM-DD-YYYY")}</span>
                        </div>
                        <div>
                            <div style={{display:'flex',flexDirection:'column',gap:'1vh'}}>{continueData?.content?.split('\r\n')?.map((val,id)=>{
                                return(
                                    <p  key={id} style={{textAlign:"justify"}}>{val}</p>
                                )
                            })}</div>
                        </div>
                        <span>{">> "} {continueData?.author}</span>
                        </div>

                    </div>
                    <div className={classes.recentPost}>
                        <h3>RECENT POSTS</h3>
                        <div>
                        {recentNewsLetters?.length===0?
                        <span style={{height:"100%",display:"flex",alignItems:"center",justifyContent:"center",color:"rgba(22, 36, 50, 0.50)"}}>No recent post is found!</span>
                        :
                        recentNewsLetters?.map((item,index)=>(
                            <div className={classes.post} onClick={()=>{
                                setContinueData(item);
                            }}>
                            
                            <div>
                            <img src={item.image}></img>
                            </div>
                            
                            <div>
                                <h4><Typography.Text style={{maxWidth:"13vw",fontSize:"1rem",}} ellipsis={{tooltip:false}} > {item.title}</Typography.Text></h4>
                                {/* <h4>{item.title}</h4> */}
                                <span>{moment(item.createdAt).format("MMM-DD-YYYY")}</span>
                            </div>
                        </div>
                                    
                                    ))}
                            

                        </div>
                    </div>

                </div>
                </div>
        </>
    )
}
export default ContinueReading;