import React, { useEffect, useState } from "react";

import dashboardIcon from "../../assets/Dashboard.svg";
import dashboardInactive from "../../assets/dashboard_inactive.svg"
import employeesActiveTab from "../../assets/employeesActive.svg";
import employeesInactiveTab from "../../assets/employessInactive.svg";
import departmentApprovalInactive from "../../assets/department_approval_inactive.svg";
import departmentApprovalActive from "../../assets/dep_app_active.svg"
import SiderLayout from "../../components/siderLayout";
import "../../design/framework.css";
import viewClass from "../../services/ViewRole";

function MainPage() {
  const [navUrl,setNavUrl]=useState([])
  useEffect(()=>{
    viewClass.updateView("resource se");
if(viewClass.superAdmin){
setNavUrl([
  {
  title:"Dashboard",
  url:"dashBoard",
  inactive:dashboardInactive,
  active:dashboardIcon,
},
{
  title:"Associates",
  url:"employees",
  inactive:employeesInactiveTab,
  active:employeesActiveTab,
},{
  title:"Department Approval",
  url:"department-approval",
  inactive:departmentApprovalInactive,
  active:departmentApprovalActive,
},
])
}
if((viewClass.hrAdmin || viewClass.hrAssociate) && !viewClass.superAdmin){
setNavUrl([
    {
    title:"Dashboard",
    url:"dashBoard",
    inactive:dashboardInactive,
    active:dashboardIcon,
  },
  {
    title:"Associates",
    url:"employees",
    inactive:employeesInactiveTab,
    active:employeesActiveTab,
  },]
)
}
  },[window.location])

  return (
    <SiderLayout navList={navUrl}/>
  );
}

export default MainPage;
