import React from "react";
import SiderLayout from "../../components/siderLayout";
import lockIconActive from './assets/Lock icon active.svg'
import lockIconInactive from "./assets/Lock icon inactive.svg"
import logsActive from "./assets/logs active.svg"
import logsInactive from "./assets/logs inactive.svg"
import userIconActive from "./assets/users active.svg"
import userIconInactive from "./assets/users inactive.svg"
import settingsIconActive from "./assets/settings active.svg"
import settingsIconInactive from "./assets/settings inactive.svg"
import outdoorIconActive from "./assets/outdoor active.svg"
import outdoorIconInactive from "./assets/outdoor inactive.svg"
import forgotPunchInactive from "./assets/forgot inactive.svg"
import leaveActive from "./assets/leave active.svg"
import leaveInactive from "./assets/leave inactive.svg"
import rmInactiveIcon from "./assets/resource portal inactive.svg";
import YearlyIcon from "../../assets/yearlyIcon.svg"
import YearlyInactiveIcon from "../../assets/yearlyInactiveIcon.svg"

const AttendanceMainPage = () => {
  const navUrl = [
    {
      title: "Authentication",
      url: "authentication",
      inactive:lockIconInactive,
      active:lockIconActive,
    },
    {
      title: "Logs",
      url: "attendance-logs",
      inactive:logsInactive,
      active:logsActive,
    },
    {
      title: "Yearly Logs",
      url: "attendance-yearly-logs",
      inactive:YearlyInactiveIcon,
      active:YearlyIcon,
    },
    {
      title: "Users",
      url: "users",
      inactive:userIconInactive,
      active:userIconActive,
    },
    {
      title: "Settings",
      url: "settings",
      inactive:settingsIconInactive,
      active:settingsIconActive,
    },
    {
      title: "Outdoor Punch",
      url: "outdoor-punch",
      inactive:outdoorIconInactive,
      active:outdoorIconActive,
    },
    {
      title: "Forgot Punch",
      url: "forgot-punch",
      inactive:forgotPunchInactive,
      active:forgotPunchInactive,
    },
    {
      title: "Leave",
      url: "leave",
      inactive:leaveInactive,
      active:leaveActive,
    },
    {
      title: "Resource Portal",
      url: "/rmanagement/dashboard",
      inactive:rmInactiveIcon,
      // active:departmentApprovalActive,
    },
  ];
  return <SiderLayout navList={navUrl} />;
};

export default AttendanceMainPage;