import { Modal, Button, Select, DatePicker, Divider } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useProjecteStore } from '../../ProjectManagement/store/store';
import { getFilterValues } from '../../ProjectManagement/action/action';
import { useState } from 'react';
import moment from 'moment';
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import dayjs from 'dayjs';

const { Option } = Select;

const DownloadModal = ({ visible, onCancel,onDownload,Tabs,applyFiltersModalHandler }) => {
    const { filteredData, setFilterValues } = useProjecteStore(state => state)
    const {width}=useWindowDimensions();
    const [values, setValues] = useState({
        departmentId: null,
        teamId: null,
        startDate: null,
        endDate: null,
    })
console.log("sssValues",values)
  return (
    <Modal centered
      title={
        <div style={{ color: '#008000', display: 'flex', alignItems: 'center',fontSize:width<700&&20 }}>
          <DownloadOutlined style={{ marginRight: 8 }} />
          Download
        </div>
      }
      width={900}
      open={visible}
      onCancel={onCancel}
      footer={
        <div style={{ display: 'flex',justifyContent: 'center', gap: 10, padding: 20 }}>
          <Button key="reset" style={{width:"6.5rem",height:width>700?"2.1rem":"2.4rem",fontSize:width<700&&17}} onClick={()=>{
             setValues({
              departmentId: null,
              teamId: null,
              startDate: null,
              endDate: null,
          })
          applyFiltersModalHandler(false)
          }}>
            Reset
          </Button>
          <Button style={{width:"7rem",height:width>700?"2.1rem":"2.4rem",fontSize:width<700&&17}}
           disabled={values.teamId&&values.departmentId&&values.startDate&&values.endDate?false:true}
           onClick={()=>{
            onDownload(values)
            setValues({
              departmentId: null,
              teamId: null,
              startDate: null,
              endDate: null,
          })
        }} key="download" type="primary">
            Download
          </Button>
        </div>
      }
    >
      <Divider></Divider>
      <div style={{ padding: 20,display:"flex",flexDirection:"column",width:"100%",justifyContent:"center",alignItems:"center",}}>
        <div style={{width:"93%" }}>
          <div style={{ display: 'flex', alignItems: 'center',fontSize:width<700&&17}}>
            Department<span style={{ color: 'red' }}>*</span>
          </div>
          <Select 
          value={values.departmentId} 
          size={width<700&&"large"}
          placeholder="Select department" onChange={(e)=>{
             setValues({ ...values, departmentId: e })
                  getFilterValues(setFilterValues, "teamAttendance", e);
          }} style={{ width: "100%", marginTop: 10 }}>
          {filteredData?.department?.result?.map((item, index) => {
            
                            return (
                                <Option style={{fontSize:width<700&&17}} value={item.departmentId} key={index}>{item.department}</Option>
                            )
                        })}
            {/* <Option value="dept3">Department 3</Option> */}
          </Select>
        </div>
        <div style={{ marginTop: 20,width:"93%" }}>
          <div style={{ display: 'flex', alignItems: 'center',fontSize:width<700&&17 }}>
            Team<span style={{ color: 'red' }}>*</span>
          </div>
          <Select 
          value={values.teamId} 
          size={width<700&&"large"}
          onChange={(e) => { setValues({ ...values, teamId: e })}} 
          placeholder="Select Team" style={{ width: "100%", marginTop: 10 }}>
          {filteredData?.team?.teams?.length ?filteredData?.team.teams?.map((item, index) => {
                        return (
                            <Option style={{fontSize:width<700&&17}} value={item.id} key={index}>{item.name}</Option>
                        )
                    }):null}
           
          </Select>
        </div>
        <div style={{ marginTop: 20,width:"93%" }}>
          <div style={{ display: 'flex', alignItems: 'center',fontSize:width<700&&17 }}>
            Start Date<span style={{ color: 'red' }}>*</span>
          </div>
          <DatePicker 
          size={width<700&&"large"}
          value={values.startDate && values.startDate !==  "Invalid date" ? dayjs(values.startDate, 'YYYY/MM/DD') : undefined}
           onChange={(date,dateString) => {
            setValues({ ...values, startDate: moment(dateString, "YYYY/MM/DD").format("YYYY/MM/DD") });
          }} style={{ width: "100%", marginTop: 10,fontSize:width<700&&17 }} />
        </div>
        <div style={{ marginTop: 20,width:"93%" }}>
          <div style={{ display: 'flex', alignItems: 'center',fontSize:width<700&&17 }}>
            End Date<span style={{ color: 'red' }}>*</span>
          </div>

          <DatePicker 
          value={values.endDate && values.endDate !==  "Invalid date" ? dayjs(values.endDate, 'YYYY/MM/DD') : undefined} 
          size={width<700&&"large"}
          onChange={(date,dateString) => {
                    setValues({ ...values, endDate: moment(dateString, "YYYY/MM/DD").format("YYYY/MM/DD") });
                }} style={{ width: "100%", marginTop: 10 }} />
        </div>
      </div>
    </Modal>
  );
};

export default DownloadModal;
