import Api from "../../../services/Api";

export const recentlyAuthenticationData = (params, callback) => {
  Api.get("/attendance-attendance/hrApprovedUsers?approve=true").params(params).send((response) => {
    callback(response);
  });
};
export const authenticationData = (params, callback) => {
  Api.get("/attendance-attendance/hrApprovedUsers?approve=false").params(params).send((response) => {
    callback(response);
  });
};
///attendance-mobileauth/approveFromHr/ changed this endpoint by gaddigesh
export const approveAuthentication = (id, callback) =>{
    Api.patch(`/attendance-users/approveFromHr/?userViewId=${id}`).send((response)=>{
        callback(response)
        
    })
}

export const searchAuthUsers = (params,approvalCallback,approvedCallback) =>{
    Api.get(`/attendance-attendance/hrApprovedUsers?approve=true&search=${params.search}`).send((response)=>{
        approvedCallback(response)
    })
    Api.get(`/attendance-attendance/hrApprovedUsers?approve=false&search=${params.search}`).send((response)=>{
        approvalCallback(response)
    })
}