import React, { useState } from "react";
import classes from './Dashboard.module.css';
import { DatePicker, Select, Spin, Typography } from "antd";
import Recommendation from "./Recommendation";
import Graphs from "./Graph";
import { useEffect } from "react";
import { UnnecessaryBackendSupport, getDashGraphInfo, getDeptExtraInfo, getFPDepartment, getRecommendation } from "../Action";
import { useFPStore } from "../store";
import moment from "moment";
import { InfoCircleOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import styled, { keyframes } from "styled-components";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";


const DeptCard=({item,key,selectedDept,data,setDeptData,index,setSelectedDept})=>{
    // const [extraInfo,setExtraInfo]=useState();
    const {deptExtraInfoHandler,deptExtraInfo}=useFPStore();
    const [showExtra,setShowRxtra]=useState(false);
    const [cardLoader,setCardLoader]=useState(false);
    return (
        <div style={{position:"relative"}}>
              <div onClick={()=>{
                  setCardLoader(true);  
                            console.log("Enter HEREEE",data,index)
                            setDeptData(data?.map((item,index2)=>{
                                if(index2===index)
                                {
                                
                                    return{...item,showExtra:!item?.showExtra}
                                }
                                return {...item,showExtra:false};
                            }))
                            setShowRxtra(prev=>!prev); 
                                  
                                getDeptExtraInfo((res)=>{
                                    deptExtraInfoHandler(res);
                                    setCardLoader(false);
                                },item?.departmentId,item?.headId);                          
                        }}
                        style={{cursor:"pointer",position:"absolute",right:"1rem",top:"1.3rem"}}
                        ><InfoCircleOutlined/>
                        </div>
                <div style={{cursor:"pointer"}}
                onClick={()=>{
                    setSelectedDept(item?.departmentId);
                }}
                className={`${classes.card} ${selectedDept===item?.departmentId?classes.selected:""}`} key={key}>
                    <div>
                        
                            <Typography.Paragraph style={{width:"11rem",fontSize:"1.2rem",fontWeight:"600",fontFamily:"Lato",alignItems:"center",margin:"0"}} ellipsis={{tooltip:true,rows:1}}>{item?.deptName} </Typography.Paragraph>
                      
                      
                        
                    </div>
                    { !item?.showExtra?
                    <div className={classes.members}>{item?.totalUserFeedbackData+"/"+item?.deptWiseTotalUserData}</div>:
                    cardLoader?<span style={{fontSize:"0.8rem",color:"#A0A0A0"}}>Loading...</span>:<div className={classes.extraInfo}>
                        <div>
                            <div>HOD:</div>
                            <div>{deptExtraInfo?.hodName?deptExtraInfo?.hodName:"NA"}</div>
                        </div>
                        <div>
                            <div>Number of Teams:</div>
                            <div>{deptExtraInfo?.team?deptExtraInfo?.team:"NA"}</div>
                        </div>
                    </div>}
                </div>
    </div>
    )
}

const DeptScroll=({loader,data,setCurrentIndex,currentIndex,selectedDept,setDeptData,setSelectedDept})=>{
    const {width}=useWindowDimensions();
    const showSlide = (index) => {
       
      if (index < 0) {
        // index = data?.length - 1;
        return;
      } else if (index >=data?.length) {
        // index = 0;
        return;
      }
      setCurrentIndex(index);
    };
  
    const prevSlide = () => {
        let index=currentIndex-0.25;
        if(width>700)
        {
            showSlide(index - 4);
        }
        else
        {
            showSlide(index - 1);
        }
    };
  
    const nextSlide = () => {
        
        let index=currentIndex+0.25;
        if(width>700)
        {
            showSlide(index + 4);
        }
        else
        {
            showSlide(index + 1);
        } 
    };
    let slideInAnimation;
    console.log("currentIndex",currentIndex,data?.length)
    if(!loader)
    {
        console.log("currentIndex",currentIndex)
        slideInAnimation = keyframes`
        0% {
          transform: translateX(0%);
        }
        100% {
       transform:  translateX(-${currentIndex * 100}%);
         
        }
      `;
    }
// Create a styled component with the animation
let AnimatedDiv = styled.div`
  animation: ${slideInAnimation} 0.5s linear forwards; /* Apply the keyframe animation */
`;

let tempCard=data?.filter((item)=>item?.departmentId==selectedDept)?.[0];
let tempIndex;
data?.forEach((item,index)=>{
    if(item?.departmentId===tempCard?.departmentId)
    {
        tempIndex=index;
    }
})
  
    return(
        <div className={classes.deptScroll}>
            <div onClick={prevSlide}>
                {/* <LeftOutlined/> */}
                </div>
            <div>
                {
                selectedDept?<DeptCard setSelectedDept={setSelectedDept} item={tempCard} key={0} index={tempIndex} selectedDept={selectedDept} data={data} setDeptData={setDeptData}/>:
                data?.map((item,index)=>{                 
                    return(
                       
                            <DeptCard item={item} key={index} index={index} selectedDept={selectedDept} data={data} setDeptData={setDeptData} setSelectedDept={setSelectedDept}/>
                                         
                    )
                })}
            </div>
            <div onClick={nextSlide}>
                {/* <RightOutlined/> */}
                </div>
        </div>
    )
}

const FPDashboard=()=>{
    const [tab,setTab]=useState(1);
    const Option=Select.Option;
    const [graphData,setGraphData]=useState([]);
    const [deptData,setDeptData]=useState();
    const {dashDeptInfoHandler,dashDeptInfo,dashGraphDataHandler,
        dashGraphData,recommendationDataHandler,
    }=useFPStore();
    console.log("dashGraphData",dashGraphData);
    const [dateMonth,setDateMonth]=useState(moment().format('YYYY-MM'));
    const [selectedDept,setSelectedDept]=useState("");
    const [PageLoader, setPageLodaer]=useState(false);
    const [loader,setLoader]=useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
   
    useEffect(()=>{
        setPageLodaer(true);
        UnnecessaryBackendSupport();
    },[]);

    useEffect(()=>{
        getFPDepartment((res)=>{
            dashDeptInfoHandler(res);
            setDeptData(res?.data?.map((item,index)=>({...item,showExtra:false})));
            setPageLodaer(false);
        },dateMonth);

    },[dateMonth])

    useEffect(()=>{
        setLoader(true);
        if(tab===1)
        {
            getDashGraphInfo((res)=>{
                dashGraphDataHandler(res);
                setLoader(false);
            },selectedDept,dateMonth);
        }   
    }
    ,[selectedDept,dateMonth,tab])
    return(
        <>
        {PageLoader?<Spin className={classes.spin}></Spin>:<div className={classes.dashboard}>
           <header className={classes.header}>
                <div>Department Feedbacks {(dashDeptInfo?.totalUserFeedback?dashDeptInfo?.totalUserFeedback:0)+"/"+(dashDeptInfo?.totalUser?dashDeptInfo?.totalUser:0)}</div>
                <div>
                    {dashDeptInfo&&<DeptScroll setSelectedDept={setSelectedDept} setDeptData={setDeptData}  loader={ tab===1?loader:loader} data={deptData} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} selectedDept={selectedDept}/>}
                </div>
                <div className={classes.tab}>
                    <div>
                        <div 
                        className={tab===1?classes.activeTab:""}
                        onClick={()=>{
                            setTab(1);
                            // setCurrentIndex(0);
                            // setSelectedDept("");
                        }}>Graphs</div>
                        <div
                        className={tab===2?classes.activeTab:""}
                        onClick={()=>{
                            setTab(2);
                            // setCurrentIndex(0);
                            // setSelectedDept("");
                        }}
                        >Recommendations</div>
                    </div>
                    <div>
                        <Select 
                        showSearch
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        style={{width:"12rem"}}
                        allowClear={true}
                        value={selectedDept?selectedDept:undefined}
                        placeholder="Select Department"
                        onChange={(e)=>{
                            setSelectedDept(e);
                            let index=dashDeptInfo?.data?.findIndex((el=>el.departmentId===e));
                            setCurrentIndex(index+(index*6.3/100));
                        }}
                        >
                            {dashDeptInfo?.data?.map((item,index)=>{
                                console.log("departmentIddepartmentId",item)
                                return <Option value={item?.departmentId}>{item?.deptName}</Option>
                            })}
                        </Select>
                        <DatePicker placeholder={`${moment().format('YYYY-MM')}`} picker="month" 
                        onChange={(date,datestring)=>
                            {
                                if(!datestring)
                                {
                                    setDateMonth(moment().format('YYYY-MM'));
                                }
                                else
                                {
                                    setDateMonth(datestring)
                                }                            
                                }}
                                />

                    </div>
                </div>

           </header>

           <div className={classes.contents}>
                {  tab===2?
                <Recommendation
                selectedDept={selectedDept}
                loader={loader}
                setLoader={setLoader}
                dateMonth={dateMonth}
                />:
                <section className={classes.graphDiv}>
                    {loader?<Spin className={classes.spin2}></Spin>:<Graphs data={dashGraphData} dateMonth={dateMonth} selectedDept={selectedDept} ></Graphs>}      
                </section>
                    }
           </div>
        </div>}
        </>
    )
}
export default FPDashboard