import { Col, Row, Input, Radio, Checkbox, Spin, Select } from "antd";
import DatePickerCompo from "../../../../components/DatePicker/DatePickerCompo.tsx";
import plusIcon from "../../../../assets/plus_icon_white.svg"
import Button from "../../../../components/buttonCustom";
import { AiOutlinePlus, } from "react-icons/ai";
import { BsCheck2Circle } from "react-icons/bs";
import { MdOutlineCancel } from "react-icons/md";
import { NavBar } from "../../../../components/topNavBar";
import '../../../../design/framework.css'
import React, { useEffect, useState } from "react";
import { KeyIsNotEmpty, getFormData, getPreviewData, postData, validateAadhar, validateEmail, validatePan, validatePhone, validatePincode } from "./formData";
import { DetailSection } from "../customInputComp";
import ModalCompo from "../../../../design/ModalCompo";
import classes from './EditProfile.module.css';
import { editProfilePatch, getUserDetailsById, revokeNoticePeriod } from "../../action/action";
import { useResourceStore } from "../../store/store";
import moment from "moment";
import Notification from "../../../../services/Notification";
import { DownLoadAttendnaceLogs } from "../../../AttendancePortal/action/action";
import UploadDocument, { uploadAcadamics, uploadExperiance, uploadList } from "./uploadDocument";
import UploadDocumentMulti from "./uploadDocumentMulti.js";
import ButtonCompo from "../../../../design/ButonCompo.js";
import Preview from "./Preview.js";
import { LeftOutlined } from "@ant-design/icons";
import viewClass from "../../../../services/ViewRole.js";



function EditProfile() {
    const { tab, setTab, page, setPage, openPage, navData } = useResourceStore(state => state)
    const [next, setNext] = useState(0);
    const [employeeProfile, setEmployeeProfile] = useState({});
    const [uploadedFiles, setUploadedFiles] = useState({})
    const [uploadedDocuments, setUploadedDocuments] = useState({})
    const [openModal, setOpenModal] = useState(false);
    const [value, setValue] = useState("Fresher");
    const [modalRender, setModalRender] = useState('')
    const [sameAsCurrent, setSameAsCurrent] = useState(false)
    const [render, setRender] = useState(false)
    const [emeEmp, setemeEmp] = useState([]);
    const [academics, setAcademics] = useState([]);
    const [workExperience, setWorkexperience] = useState([])
    const [loader, setLoader] = useState(false);
    const [skills, setSkills] = useState({ value: '', verified: false });

    const NotificationLable = { 'personalEmail': 'personal email', "bloodGroup": 'blood group', "dobActual": 'wish me on', "dob": 'DOB (on document)', "maritalStatus": 'marital status', 'accountNumber': 'account Number', 'addressLine1': 'address line 1', 'addressLine2': 'address line 2', 'panNumber': 'pan number', 'panName': 'pan name', 'aadharNumber': 'aadhar number', 'aadharName': 'aadhar name', 'pfNumber': 'pf accound number', 'uanNumber': 'uan number', 'dlNumber': 'driving liscence', 'voterIdNumber': 'voter id', 'passportNumber': 'passport number', ' yearofPassing': 'year of passing', 'gradePercentage': 'grade/percentage', 'startDate': 'start date', 'endDate': 'end date', 'associateName': 'associate name', 'joined': 'joining date' }

    useEffect(() => {
        setLoader(true);
        if (openPage) {
            getUserDetailsById((res) => {
                setValue(res?.workExperience?.type ? res?.workExperience?.type : "Fresher")
                setSkills({ value: res?.workExperience?.skills ? res?.workExperience?.skills : '', verified: res ? res?.workExperience?.skills_HRVerified : false?.workExperience?.skills_HRVerified });
                setEmployeeProfile({ '': 'nothing', ...res?.documents, ...res?.profile?.basicInformation, ...res?.profile?.associateProfile, ...res?.profile?.bankAccountDetails, "bankAccountDetails": { ...res?.profile?.bankAccountDetails }, ...res?.profile?.addressInformation, "noticePeriod": { ...res?.profile?.noticePeriod }, 'workExperience': res?.workExperience, 'academics': res?.academics })
                setUploadedFiles({
                    'Document': {
                        'photo': res?.profile?.associateProfile?.photo,
                        'resume': res?.workExperience?.resumeLink,
                        'pan': res?.documents?.pan?.panLink,
                        'aadhar': res?.documents?.aadhar?.aadharLink,
                        'passport': res?.documents?.passport?.passportLink,
                        'dl': res?.documents?.dl?.dlLink,
                        'voterId': res?.documents?.voterId?.voterIdLink,
                        'bloodGroup': res?.documents?.bloodDoc?.bloodDocLink,
                        'photo_HRVerified': res?.profile?.associateProfile?.photo_HRVerified,
                        'resume_HRVerified': res?.workExperience?.resumeLink_HRVerified,
                        'pan_HRVerified': res?.documents?.pan?.panLink_HRVerified,
                        'aadhar_HRVerified': res?.documents?.aadhar?.aadharLink_HRVerified,
                        'passport_HRVerified': res?.documents?.passport?.passportLink_HRVerified,
                        'dl_HRVerified': res?.documents?.dl?.dlLink_HRVerified,
                        'voterId_HRVerified': res?.documents?.voterId?.voterIdLink_HRVerified,
                        'bloodGroup_HRVerified': res?.documents?.bloodDoc?.bloodDocLink_HRVerified,
                        'others': res?.documents?.others,
                    },
                    'Academics': res?.academics?.academicsDocuments,
                    'Experience': res?.workExperience?.documents,
                })
                setLoader(false);

                if (res?.profile?.emergencyContact?.length > 0 && res?.profile?.emergencyContact?.length <= 2) {
                    setemeEmp([...res?.profile?.emergencyContact]);

                } else if (res?.profile?.emergencyContact?.length <= 0 || res?.profile?.emergencyContact === null) {
                    setemeEmp([{
                        name: "",
                        contact: "",
                        relation: "",
                        name_HRVerified: false,
                        contact_HRVerified: false,
                        relation_HRVerified: false,
                    }]);
                }
                setSameAsCurrent(res?.profile?.addressInformation?.sameAsCurrent)
                if (res?.academics?.academicsData?.length > 0 && res?.academics?.academicsData != "") {
                    setAcademics([...res?.academics?.academicsData]);
                } else {
                    setAcademics([
                        {
                            qualification: "",
                            yearofPassing: "",
                            gradePercentage: "",
                            qualification_HRVerified: false,
                            yearofPassing_HRVerified: false,
                            gradePercentage_HRVerified: false,
                        }
                    ])
                }
                if (res?.workExperience?.experienced?.length) {
                    setWorkexperience([...res?.workExperience?.experienced])
                } else {
                    setWorkexperience([{
                        companyName: "",
                        startDate: "",
                        endDate: "",
                        companyName_HRVerified: false,
                        startDate_HRVerified: false,
                        endDate_HRVerified: false,
                    }])
                }

            }, openPage);
        }

    }, [window.location.pathname, render, openPage]);


    useEffect(() => {
        // console.log("data change", openPage)
    }, [employeeProfile])
    const onSameAsCurrent = (e) => {
        setSameAsCurrent(e.target.checked)
    }


    const handleInputChange = (val, key, childkey1, childkey2 = '') => {
        console.log('kaushal', val, key, childkey1, childkey2)
        if (childkey1 === 'upload') {
            uploadedDocuments[key] = val;
            setUploadedDocuments({ ...uploadedDocuments })
        }
        if (!employeeProfile[childkey2]) {
            employeeProfile[childkey2] = {};
            if (!employeeProfile[childkey2][key]) {
                if (val?.method === "verify") {
                    console.log('1', key, childkey2, val,);

                    employeeProfile[childkey2][`${key}_HRVerified`] = val?.status
                }
                else {
                    employeeProfile[childkey2][key] = val;
                }
            }
        }
        else {
            if (childkey2 !== '') {
                if (val?.method === "verify") {
                    employeeProfile[childkey2][`${key}_HRVerified`] = val?.status;
                }
                else {
                    employeeProfile[childkey2][key] = val;
                }
            }
            else {
                if (val?.method === "verify") {
                    employeeProfile[`${key}_HRVerified`] = val?.status;
                }
                else {
                    employeeProfile[key] = val;
                }
            }
        }
        setEmployeeProfile({ ...employeeProfile });
    }

    const onSavePatch = async (files = null, academicsFiles = null, experianceFile = null, callBack = null, submit = false) => {
        let param = await postData(employeeProfile, emeEmp, academics, workExperience, sameAsCurrent, value, files, academicsFiles, experianceFile, skills, submit)
        editProfilePatch(param, openPage, (res) => {
            if (res) {
                setRender(!render);
                setOpenModal(false)
            }

        })

    }

    function areAllKeysFilledInArray(arr, section) {
        console.log('kaushal kaushal', section, arr)
        for (let i = 0; i < arr?.length; i++) {
            const obj = arr[i];
            for (let key in obj) {
                if (obj[key] === undefined || obj[key] === null || obj[key] === '') {
                    console.log("this is empty", obj, arr, key)
                    let label = KeyIsNotEmpty(NotificationLable[key]) ? NotificationLable[key] : key
                    Notification.error("Error!", `Please fill the ${label} field ${KeyIsNotEmpty(section) ? `in ${section}` : ''}`);
                    return false;
                }
                if (validatePhone(obj['contact'])) {
                    Notification.error('Error!', 'Please enter 10 digit contact no. in emergency contact');
                    return false;
                }
            }
        }
        return true;
    }

    function areAllKeysDefined(obj, keys, section) {
        for (let i = 0; i < keys?.length; i++) {
            const key = keys[i];
            if (obj?.[key] === undefined || obj?.[key] === null || obj?.[key] === "" || (Array.isArray(obj?.[key]) && obj?.[key]?.length === 0)) {
                console.log(key, obj, "this is the empty key kaushal");
                let label = KeyIsNotEmpty(NotificationLable[key]) ? NotificationLable[key] : key
                Notification.error("Error!", `Please fill the ${label} field ${KeyIsNotEmpty(section) ? `in ${section}` : ''}`);
                return false;
            }
        }
        return true;
    }
    const checkAddressIsFilled = () => {
        if (KeyIsNotEmpty(employeeProfile?.current) === false) {
            Notification.error('Error!', 'Please fill the current address section')
            return false;
        }
        else if (KeyIsNotEmpty(employeeProfile?.current)) {


            if (areAllKeysDefined(employeeProfile?.current, ['addressLine1', 'city', 'pincode', 'state'], 'Current Address')) {

                if (sameAsCurrent) {
                    return true;
                }
                else {
                    if (KeyIsNotEmpty(employeeProfile?.permanent) === false) {
                        Notification.error('Error!', 'Please fill the permanent address section')
                        return false;
                    }
                    else if (KeyIsNotEmpty(employeeProfile?.permanent)) {

                        return areAllKeysDefined(employeeProfile?.permanent, ['addressLine1', 'city', 'state', 'pincode',], 'Permanent Address');
                    }
                }
            }


        }

    }
    const checkOnBasicProfileFill = () => {
        const requiredKeys = ["associateName", "uid", "email", "joined", "phone", "designation", "userRoles", "privilege",];
        if (areAllKeysDefined(employeeProfile, requiredKeys)) {
            if (validatePhone(employeeProfile?.phone)) {
                Notification.error('Error!', 'Please enter 10 digit phone number');
                return false;
            }
            return true;
        }
        else {
            return false
        }
    }
    const checkOnPersonalDetailsFill = () => {
        const requiredKeys = ["personalEmail", "bloodGroup",
            "dobActual", "dob", "gender", "category", "maritalStatus",];
        if (areAllKeysDefined(employeeProfile, requiredKeys) && areAllKeysFilledInArray(emeEmp, 'Emergency Contact') && checkAddressIsFilled() && areAllKeysDefined(employeeProfile?.bankAccountDetails, ['name', 'accountNumber', 'IFSC'], 'Bank Account Details')) {

            if (validatePhone(employeeProfile?.secondaryPhone)) {
                Notification.error('Error!', 'Please enter 10 digit secondary phone number');
                return false;
            }
            if (validateEmail(employeeProfile?.personalEmail)) {
                Notification.error('Error!', 'Please enter valid personal mail')
                return false;
            }
            if (validatePincode(employeeProfile?.current?.pincode)) {
                Notification.error('Error!', 'Please enter 6 digit pincode in current address')
                return false;
            }
            if (!sameAsCurrent && validatePincode(employeeProfile?.permanent?.pincode)) {
                Notification.error('Error!', 'Please enter 6 digit pincode in permanent address')
                return false;
            }
            return true;
        }
        else {
            return false;
        }
    }


    const checkOnDocumentDetailsFill = () => {
        if (areAllKeysDefined({ ...employeeProfile?.pan, ...employeeProfile?.aadhar }, ["aadharName", "aadharNumber", "panName", "panNumber"])) {

            if (validatePan(employeeProfile?.pan?.panNumber)) {
                Notification.error('Error!', 'Please enter valid PAN')
                return false;
            }
            if (validateAadhar(employeeProfile?.aadhar?.aadharNumber)) {
                Notification.error('Error!', 'Please enter valid aadhar')
                return false;
            }
            return true;
        } else {
            return false;
        }
    }
    const checkAcademicsDetailsFill = () => {
        if (areAllKeysFilledInArray(academics)) {
            return true;
        } else {
            return false;
        }
    }
    const checkWorkexpDetailsFill = () => {
        if (value !== "Fresher") {
            if (areAllKeysFilledInArray(workExperience)) {
                return true;
            } else {
                return false;
            }
        } else {
            return true
        }
    }


    const onRemove = (index) => {
        let temp = [...emeEmp]
        temp.splice(index, 1)
        setemeEmp([...temp])
    }
    const handleFocusChange = (val, key) => {
        employeeProfile[key] = val;
        setEmployeeProfile({ ...employeeProfile })
    }

    const handleAdd = (getter, index, type, value, setter) => {
        getter[index][type] = value;
        if (!type.includes('_HRVerified') && !KeyIsNotEmpty(value)) {
            getter[index][`${type}_HRVerified`] = false
        }
        setter([...getter]);
    }

    const checkOnFileUpload = (tab, length) => {
        let ll = getPreviewData(employeeProfile, false, tab)[0].data?.length;
        if (ll < length) {


            if (tab === 'Work Experience' && value === 'Fresher') {
                return true
            }
            else {
                Notification.error('Error!', 'Please Upload Documents')
                return false;
            }
        }
        else {
            return true
        }


    }
    const checkBeforeVerify = (data) => {
        if (KeyIsNotEmpty(data)) {
            return true
        }
        else {
            Notification.error('Error!', 'Please fill the details');
            return;
        }
    }
    const handleAddrDuplicate = (val) => {
    }


    return (
        <div style={{ width: "100%", paddingLeft: "0.7rem", paddingRight: "0.7rem" }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '6px', fontSize: '20px', margin: '1rem 0rem' }}>

                    <LeftOutlined onClick={() => {
                        setPage({ prev: page?.curr, curr: page.prev, PD: page?.PD })
                    }} />
                    <div style={{ font: 'normal 700 20px/24px Lato' }}>Edit Associate Profile</div>
                </div>
                {tab === 'Work Experience' && value !== 'Fresher' && <ButtonCompo disabled={viewClass?.hrAssociate} title={"Download Background Verification Form"} style={{ color: '#fff', background: '#0086ff', height: '2rem' }}
                    onClick={() => {
                        if (viewClass?.hrAssociate) {
                            return;
                        }
                        DownLoadAttendnaceLogs(() => { }, "/resource-user/getBackgroundVForm", null, 1)
                    }} />}
            </div>
            <div style={{ width: "100%", height: "80%", display: "flex", overflowY: "scroll" }}>
                <div className="hover" style={{ width: "100%", height: "100%", overflowY: "auto" }}>
                    <div style={{ height: "1px", backgroundColor: "light grey", marginBottom: "0.4rem" }}></div>
                    <div className={classes.WebViewTab}>
                        <NavBar data={navData} defaultView={tab} onClick={(res) => {
                            setTab(res)
                        }} />
                    </div>

                    <div className={classes.MobViewTab}>
                        <Select style={{ width: '300px' }} onChange={(val) => { console.log('kaushal', val); setTab(val); }} defaultValue={tab}>
                            {navData?.map((val, id) => {
                                return (
                                    <Select.Option value={val} key={id}>{val}</Select.Option>
                                )
                            })}
                        </Select>
                    </div>
                    {loader ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh', }}><Spin /></div> : <>
                        <div>
                            {
                                tab === "Basic Profile" ?
                                    <div style={{ flexDirection: "row", paddingTop: 10, display: "flex", gap: '1rem' }}>
                                        <div className={`${classes.ScrollEdit} ${classes.EditField}`} >
                                            <div style={{ display: "flex", flexWrap: "wrap", }}>

                                                {
                                                    getFormData(employeeProfile, handleInputChange, handleFocusChange, handleAddrDuplicate, "Basic Profile")?.map((section, index) => (
                                                        <DetailSection section={section} index={index} />
                                                    ))
                                                }
                                            </div>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "95%", marginTop: "1rem", }}>
                                                <Button name={"save"} background={"#0086FF"} fontColor={"#FFFFFF"} disabled={viewClass?.hrAssociate} onclick={() => {
                                                    if (checkOnBasicProfileFill()) {
                                                        onSavePatch()
                                                    }
                                                }} />
                                                <Button name={"cancel"} background={"#FFFFFF"} fontColor={"#0086FF"} disabled={false} onclick={() => {
                                                    setPage({ prev: page?.curr, curr: page.prev, PD: page?.PD })
                                                }} borderColor={"#0086FF"} />
                                            </div>
                                        </div>
                                        {
                                            getPreviewData(employeeProfile, sameAsCurrent, "Basic Profile")?.map((res, id) => (
                                                <Preview type={res?.type} data={res?.data} />
                                            ))
                                        }
                                    </div>
                                    : tab === "Personal and Contact Data" ?
                                        <div style={{ flexDirection: "row", gap: '1rem', paddingTop: 10, display: "flex", }}>
                                            <div className={`${classes.ScrollEdit} ${classes.EditField}`}>
                                                <div style={{ height: "fit-content", padding: 10, display: 'flex', gap: '1rem' }}>
                                                    <div style={{ display: "flex", flexWrap: "wrap", width: '100%', }}>
                                                        {
                                                            getFormData(employeeProfile, handleInputChange, handleFocusChange, handleAddrDuplicate, "Basic Information")?.map((section, index) => (
                                                                <DetailSection section={section} index={index} />
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    next >= 1 &&
                                                    <>
                                                        <div style={{ height: "fit-content", padding: 10 }}>
                                                            <div style={{ display: "flex", flexWrap: "wrap", width: '100%' }}>
                                                                <div className="r-jsb">
                                                                    <div style={{ display: "flex", width: "100%", alignItems: "center", gap: "1.5rem", margin: "1rem 0 1rem 0", }}>
                                                                        <div style={{ fontSize: 18, fontWeight: 600, }}>Address Information</div>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    getFormData(employeeProfile, handleInputChange, handleFocusChange, handleAddrDuplicate, "Address Information")?.map((section, index) => (
                                                                        <DetailSection section={section} index={index} />
                                                                    ))
                                                                }
                                                                <div style={{ paddingBottom: '1rem' }}>
                                                                    <Checkbox defaultChecked={sameAsCurrent} onChange={onSameAsCurrent}> <b>Same as Current Address</b></Checkbox>
                                                                </div>
                                                                {
                                                                    getFormData(employeeProfile, handleInputChange, handleFocusChange, handleAddrDuplicate, "Address InformationP", sameAsCurrent)?.map((section, index) => (
                                                                        <DetailSection section={section} index={index} />
                                                                    ))
                                                                }
                                                                <div style={{ width: "100%" }}>
                                                                    <div className="r-jsb">
                                                                        <div style={{ display: 'flex', width: '95%', justifyContent: 'space-between' }} >
                                                                            <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 5 }}>Emergency Contact</div>
                                                                            {
                                                                                emeEmp.length <= 1 &&
                                                                                <Button name="Add" height="30px" background={"#0086FF"} borderColor={"white"} disabled={viewClass?.hrAssociate} fontColor={"white"} Prefix={() => <AiOutlinePlus style={{ border: "0px solid black", height: "15px", width: "15px" }} />} onclick={() => {
                                                                                    if (emeEmp.length <= 1) {
                                                                                        let temp = [...emeEmp];
                                                                                        temp.push({
                                                                                            name: "",
                                                                                            contact: "",
                                                                                            relation: "",
                                                                                            name_HRVerified: false,
                                                                                            contact_HRVerified: false,
                                                                                            relation_HRVerified: false,
                                                                                        })
                                                                                        setemeEmp(temp)
                                                                                    }

                                                                                }} />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        emeEmp.map((i, index) => (
                                                                            <>
                                                                                {
                                                                                    index >= 1 &&
                                                                                    <div style={{ color: "red", display: "flex", justifyContent: "flex-end", cursor: "pointer", width: '95%' }} onClick={() => {
                                                                                        onRemove(index)
                                                                                    }} >remove <span style={{ color: "red", fontSize: 10 }}><MdOutlineCancel style={{ border: "0px solid black", height: "20px", width: "20px", color: "red", marginTop: 2 }} /></span></div>
                                                                                }
                                                                                <Row>

                                                                                    <Col span={24} style={{ width: "100%", marginBottom: "1rem" }}>
                                                                                        <div>
                                                                                            <div className="r-jsb">
                                                                                                <div style={{ color: "#898989", fontWeight: 500 }}>{"Person Name"}{true && <span style={{ color: "red" }}> *</span>}</div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div style={{ width: "95%", display: "flex", alignItems: "center" }}>
                                                                                            <Input
                                                                                                onChange={(e) => { handleAdd(emeEmp, index, "name", e.target.value, setemeEmp) }}
                                                                                                value={i?.name}
                                                                                                style={{
                                                                                                    width: "100%",
                                                                                                    borderRadius: "5px",
                                                                                                    border: "#E6E6E6 solid 0px",
                                                                                                    borderRadius: "10px",
                                                                                                    height: "2.5rem",
                                                                                                    border: " 1px solid #dfdfdf",
                                                                                                    // boxShadow:'0px 4px 4px  rgba(0,0,0,0.2)',

                                                                                                }}
                                                                                            />

                                                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                                                {
                                                                                                    <BsCheck2Circle onClick={() => {
                                                                                                        if (checkBeforeVerify(i?.name)) {
                                                                                                            handleAdd(emeEmp, index, "name_HRVerified", !i.name_HRVerified, setemeEmp)
                                                                                                        }
                                                                                                    }}
                                                                                                        style={{ border: "0px solid black", fontSize: 25, marginLeft: 5, color: i.name_HRVerified ? "#0DAC45" : "#ABABAB" }} />
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col span={24} style={{ width: "100%" }}>
                                                                                        <div>
                                                                                            <div className="r-jsb">
                                                                                                <div style={{ color: "#898989", fontWeight: 500 }}>{"Contact No."}{true && <span style={{ color: "red" }}> *</span>}</div>

                                                                                                {validatePhone(i?.contact) && <div style={{
                                                                                                    color: "red",
                                                                                                    marginLeft: "30%",
                                                                                                }}
                                                                                                >
                                                                                                    {'Please Enter 10 digit phone no.'}</div>}

                                                                                            </div>
                                                                                        </div>

                                                                                        <div style={{ width: "95%", display: "flex", alignItems: "center" }}>
                                                                                            <Input
                                                                                                onChange={(e) => { handleAdd(emeEmp, index, "contact", e.target.value, setemeEmp) }}
                                                                                                value={i?.contact}
                                                                                                type={"number"}
                                                                                                onWheel={(e) => e.target.blur()}
                                                                                                style={{
                                                                                                    width: "100%",
                                                                                                    border: "#E6E6E6 solid 0px",
                                                                                                    borderRadius: "5px",
                                                                                                    height: "30px",
                                                                                                    border: "#E6E6E6 solid 0px",
                                                                                                    borderRadius: "10px",
                                                                                                    height: "2.5rem",
                                                                                                    border: " 1px solid #dfdfdf",
                                                                                                    // boxShadow:'0px 4px 4px  rgba(0,0,0,0.2)',

                                                                                                }}
                                                                                            />

                                                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                                                {

                                                                                                    <BsCheck2Circle onClick={() => {
                                                                                                        if (checkBeforeVerify(i?.contact)) {

                                                                                                            handleAdd(emeEmp, index, "contact_HRVerified", !i.contact_HRVerified, setemeEmp)
                                                                                                        }
                                                                                                    }}
                                                                                                        style={{ border: "0px solid black", fontSize: 25, marginLeft: 5, color: i.contact_HRVerified ? "#0DAC45" : "#ABABAB" }} />
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col span={24} style={{ width: "100%", marginBottom: "1rem" }}>
                                                                                        <div>
                                                                                            <div className="r-jsb">
                                                                                                <div style={{ color: "636363", fontWeight: 500 }}>{"Relation"}{true && <span style={{ color: "red" }}> *</span>}</div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div style={{ width: "95%", display: "flex" }}>
                                                                                            <Input
                                                                                                onChange={(e) => { handleAdd(emeEmp, index, "relation", e.target.value, setemeEmp) }}
                                                                                                value={i?.relation}
                                                                                                style={{
                                                                                                    width: "100%",
                                                                                                    border: "#E6E6E6 solid 0px",
                                                                                                    borderRadius: "5px",
                                                                                                    height: "30px",
                                                                                                    border: "#E6E6E6 solid 0px",
                                                                                                    borderRadius: "10px",
                                                                                                    height: "2.5rem",
                                                                                                    // boxShadow:'0px 4px 4px  rgba(0,0,0,0.2)',
                                                                                                    border: " 1px solid #dfdfdf",
                                                                                                }}
                                                                                            />

                                                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                                                {
                                                                                                    true &&
                                                                                                    <BsCheck2Circle onClick={() => {
                                                                                                        if (checkBeforeVerify(i?.relation)) {

                                                                                                            handleAdd(emeEmp, index, "relation_HRVerified", !i.relation_HRVerified, setemeEmp)
                                                                                                        }
                                                                                                    }}
                                                                                                        style={{ border: "0px solid black", fontSize: 25, marginLeft: 5, color: i.relation_HRVerified ? "#0DAC45" : "#ABABAB" }} />
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    next >= 2 &&
                                                    <>
                                                        <div style={{ height: 'fit-content', padding: 10 }}>
                                                            <div style={{ display: "flex", flexWrap: "wrap", width: '100%', }}>
                                                                {
                                                                    getFormData(employeeProfile, handleInputChange, handleFocusChange, handleAddrDuplicate, "Bank Account Details")?.map((section, index) => (
                                                                        <DetailSection section={section} index={index} />
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    (next >= 2) ?
                                                        <>
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "95%", marginTop: "1rem", }}>

                                                                <Button name={"save"} background={"#0086FF"} fontColor={"#FFFFFF"} disabled={viewClass?.hrAssociate} onclick={() => {
                                                                    if (checkOnPersonalDetailsFill()) {
                                                                        onSavePatch()
                                                                    }
                                                                }} />
                                                                <Button name={"cancel"} background={"#FFFFFF"} fontColor={"#0086FF"} disabled={false} onclick={() => {
                                                                    setPage({ prev: page?.curr, curr: page.prev, PD: page?.PD })
                                                                }} borderColor={"#0086FF"} />

                                                            </div>
                                                        </>
                                                        :
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", width: "95%", }}>
                                                            <Button name={"next"} borderColor={"#0086FF"} fontColor={"#0086FF"} disabled={false}
                                                                onclick={() => {
                                                                    if (next <= 4) {
                                                                        setNext(next + 1)
                                                                    }
                                                                }} />
                                                        </div>
                                                }
                                            </div>

                                            {
                                                getPreviewData(employeeProfile, sameAsCurrent, "Personal and Contact Data")?.map((res, id) => (
                                                    <Preview type={res?.type} data={res?.data} />
                                                ))
                                            }
                                        </div>
                                        :
                                        tab === 'Documents' ?
                                            <div style={{ flexDirection: "row", gap: '1rem', paddingTop: 10, display: "flex", }}>
                                                <div className={`${classes.ScrollEdit} ${classes.EditField}`} >
                                                    <div style={{ display: "flex", flexWrap: "wrap", width: '100%', }}  >   {
                                                        getFormData(employeeProfile, handleInputChange, handleFocusChange, handleAddrDuplicate, "Document Details")?.map((section, index) => (
                                                            <DetailSection section={section} index={index} />
                                                        ))
                                                    }
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                        <ButtonCompo title={"Upload Document"} suffixIcon={plusIcon} style={{ background: '#0086ff', color: '#fff', }} onClick={() => {
                                                            setOpenModal(true);
                                                            setModalRender({ type: 'Document Details', showV: true });
                                                        }} />
                                                    </div>
                                                    <div style={{ display: "flex", alignItems: "center", width: '95%', justifyContent: "flex-end", marginTop: 10 }}>
                                                        <Button name={"save"} background={"#0086FF"} fontColor={"#FFFFFF"} disabled={viewClass?.hrAssociate} onclick={() => {
                                                            if (checkOnDocumentDetailsFill() && checkOnFileUpload('Documents', 4)) {
                                                                onSavePatch()
                                                            }

                                                        }} />
                                                        <Button name={"cancel"} background={"#FFFFFF"} fontColor={"#0086FF"} disabled={false} onclick={() => {
                                                            setPage({ prev: page?.curr, curr: page.prev, PD: page?.PD })
                                                        }} borderColor={"#0086FF"} />
                                                    </div>
                                                </div>
                                                {
                                                    getPreviewData(employeeProfile, false, "Documents")?.map((res, id) => (
                                                        <Preview type={res?.type} data={res?.data} />
                                                    ))
                                                }

                                            </div>
                                            :
                                            tab === 'Academics' ?
                                                <>
                                                    <div style={{ flexDirection: "row", gap: '1rem', paddingTop: 10, display: "flex", }}>

                                                        <div className={`${classes.ScrollEdit} ${classes.EditField}`}  >
                                                            <div >
                                                                <div className="r-jsb">
                                                                    <div style={{ display: "flex", width: "100%", alignItems: "center", gap: "1.5rem", margin: "1rem 0 1rem 0", }}>
                                                                        <div style={{ fontSize: 16, fontWeight: 600, }}>Academics Details</div>
                                                                        <div style={{ display: "flex", width: "20%", justifyContent: "right" }}>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    academics.map((i, index) => (
                                                                        <>
                                                                            {
                                                                                index >= 1 &&
                                                                                <div style={{ color: "red", display: "flex", justifyContent: "flex-end", cursor: "pointer", width: '95%', }} onClick={() => {
                                                                                    let temp = [...academics]
                                                                                    temp.splice(index, 1)
                                                                                    setAcademics([...temp])
                                                                                }} >remove <span style={{ color: "red", fontSize: 12 }}><MdOutlineCancel style={{ border: "0px solid black", height: "20px", width: "20px", color: "red" }} /></span></div>
                                                                            }
                                                                            <Row>

                                                                                <Col span={24} style={{ width: "100%", marginBottom: "1rem" }}>
                                                                                    <div>
                                                                                        <div className="r-jsb">
                                                                                            <div style={{ color: "#898989", fontWeight: 500 }}>Qualification{true && <span style={{ color: "red" }}> *</span>}</div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div style={{ width: "95%", display: "flex", alignItems: "center", marginTop: '1rem' }}>
                                                                                        <Input
                                                                                            onChange={(e) => { handleAdd(academics, index, "qualification", e.target.value, setAcademics) }}
                                                                                            value={i?.qualification}
                                                                                            placeholder="Enter Qualification"
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                border: "#E6E6E6 solid 0px",
                                                                                                borderRadius: "5px",
                                                                                                height: "30px",
                                                                                                border: "#E6E6E6 solid 0px",
                                                                                                borderRadius: "10px",
                                                                                                height: "2.5rem",
                                                                                                border: " 1px solid #dfdfdf",
                                                                                                // boxShadow:'0px 4px 4px  rgba(0,0,0,0.2)',

                                                                                            }}
                                                                                        />

                                                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                                                            {
                                                                                                <BsCheck2Circle onClick={() => {
                                                                                                    if (checkBeforeVerify(i?.qualification)) {
                                                                                                        handleAdd(academics, index, "qualification_HRVerified", !i.qualification_HRVerified, setAcademics)
                                                                                                    }
                                                                                                }}
                                                                                                    style={{ border: "0px solid black", fontSize: 25, marginLeft: 5, color: i.qualification_HRVerified ? "#0DAC45" : "#ABABAB", }} />
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col span={24} style={{ width: "100%" }}>
                                                                                    <div>
                                                                                        <div className="r-jsb">
                                                                                            <div style={{ color: "#898989", fontWeight: 500 }}>Year of Passing{true && <span style={{ color: "red" }}> *</span>}</div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div style={{ width: "95%", display: "flex", alignItems: "center", marginBottom: "1rem", marginTop: '1rem' }}>
                                                                                        <Input
                                                                                            onChange={(e) => { handleAdd(academics, index, "yearofPassing", e.target.value, setAcademics) }}
                                                                                            value={i?.yearofPassing}
                                                                                            placeholder="Enter year of passing"
                                                                                            type="number"
                                                                                            onWheel={(e) => e.target.blur()}
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                borderRadius: "5px",
                                                                                                height: "30px",
                                                                                                borderRadius: "10px",
                                                                                                height: "2.5rem",
                                                                                                border: "1px solid #dfdfdf",
                                                                                                // boxShadow:'0px 4px 4px  rgba(0,0,0,0.2)',

                                                                                            }}
                                                                                        />

                                                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                                                            {
                                                                                                true &&
                                                                                                <BsCheck2Circle onClick={() => {
                                                                                                    if (checkBeforeVerify(i?.yearofPassing)) {
                                                                                                        handleAdd(academics, index, "yearofPassing_HRVerified", !i.yearofPassing_HRVerified, setAcademics)
                                                                                                    }
                                                                                                }}
                                                                                                    style={{ border: "0px solid black", fontSize: 25, marginLeft: 5, color: i.yearofPassing_HRVerified ? "#0DAC45" : "#ABABAB" }} />
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col style={{ width: "100%", marginBottom: "1rem" }}>
                                                                                    <div>
                                                                                        <div className="r-jsb">
                                                                                            <div style={{ color: "#898989", fontWeight: 500 }}>Grade/Percentage{true && <span style={{ color: "red" }}> *</span>}</div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div style={{ width: "95%", display: "flex", alignItems: "center", marginTop: '1rem' }}>
                                                                                        <Input
                                                                                            onChange={(e) => { handleAdd(academics, index, "gradePercentage", e.target.value, setAcademics) }}
                                                                                            value={i?.gradePercentage}
                                                                                            placeholder="Enter grade"
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                borderRadius: "5px",
                                                                                                borderRadius: "10px",
                                                                                                height: "2.5rem",
                                                                                                border: "1px solid #dfdfdf",
                                                                                                // boxShadow:'0px 4px 4px  rgba(0,0,0,0.2)',

                                                                                            }}
                                                                                        />

                                                                                        <div >
                                                                                            {

                                                                                                <BsCheck2Circle onClick={() => {
                                                                                                    if (checkBeforeVerify(i?.gradePercentage)) {
                                                                                                        handleAdd(academics, index, "gradePercentage_HRVerified", !i.gradePercentage_HRVerified, setAcademics)
                                                                                                    }
                                                                                                }}
                                                                                                    style={{ border: "0px solid black", fontSize: 25, marginLeft: 5, color: i.gradePercentage_HRVerified ? "#0DAC45" : "#ABABAB" }} />
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>

                                                                        </>
                                                                    ))
                                                                }
                                                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                    <ButtonCompo title={"Upload Document"} suffixIcon={plusIcon} style={{ background: '#0086ff', color: '#fff', marginTop: '2rem' }} onClick={() => {
                                                                        setOpenModal(true);
                                                                        setModalRender({ type: 'Academics', showV: true });
                                                                    }} />
                                                                </div>
                                                                <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '1rem' }}>
                                                                    {academics.length <= 4 && <ButtonCompo title={"+ Add Academic Details"} disabled={viewClass?.hrAssociate} style={{ border: '1px solid #0086ff', color: '#0086ff', }} onClick={() => {
                                                                        if (academics.length <= 4) {
                                                                            let temp = [...academics];
                                                                            temp.push({
                                                                                qualification: "",
                                                                                yearofPassing: "",
                                                                                gradePercentage: "",
                                                                                qualification_HRVerified: false,
                                                                                yearofPassing_HRVerified: false,
                                                                                gradePercentage_HRVerified: false,
                                                                            })
                                                                            setAcademics(temp)
                                                                        }
                                                                    }} />
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "95%", marginTop: 10 }}>
                                                                <Button name={"save"} background={"#0086FF"} fontColor={"#FFFFFF"} disabled={viewClass?.hrAssociate} onclick={() => {
                                                                    if (checkAcademicsDetailsFill() && checkOnFileUpload('Academics', 3)) {
                                                                        onSavePatch()
                                                                    }
                                                                }} />
                                                                <Button name={"cancel"} background={"#FFFFFF"} fontColor={"#0086FF"} disabled={false} onclick={() => {
                                                                    setPage({ prev: page?.curr, curr: page.prev, PD: page?.PD })
                                                                }} borderColor={"#0086FF"} />
                                                            </div>
                                                        </div>

                                                        {
                                                            getPreviewData(employeeProfile, false, "Academics")?.map((res, id) => (
                                                                <Preview type={res?.type} data={res?.data} />
                                                            ))
                                                        }

                                                    </div>


                                                </>
                                                :
                                                tab === 'Work Experience' ?
                                                    <>
                                                        <div style={{ flexDirection: "row", gap: '1rem', paddingTop: 10, display: "flex", }}>
                                                            <div className={`${classes.ScrollEdit} ${classes.EditField}`} >
                                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100%' }}>
                                                                    <div style={{ display: "flex", flexDirection: "row", gap: "2rem", alignItems: "center", justifyContent: "space-between", margin: "1rem 0" }}>
                                                                        <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 5 }}>Experience Details</div>
                                                                    </div>
                                                                    <div style={{ display: "flex", margin: "10px 0px 10px 0px", cursor: "pointer", alignItems: "center", justifyContent: "space-between", marginBottom: "1rem" }}>
                                                                        <Radio.Group value={value} onChange={(e) => { setValue(e.target.value) }} >
                                                                            <Radio value={"Fresher"}>Fresher</Radio>
                                                                            <Radio value={"Experience"}>Experience</Radio>
                                                                        </Radio.Group>

                                                                    </div>

                                                                    {value !== "Fresher" &&
                                                                        workExperience.map((i, index) => (
                                                                            <>


                                                                                <Row span={24}>

                                                                                    <Col span={24} style={{ width: "100%", marginBottom: "1rem", marginTop: '1rem' }}>
                                                                                        <div>
                                                                                            <div className="r-jsb">
                                                                                                <div style={{ color: "#898989", fontWeight: 500 }}>{"Company Name"}{true && <span style={{ color: "red" }}> *</span>}</div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div style={{ width: "95%", display: "flex", alignItems: "center", marginTop: '1rem' }}>
                                                                                            <Input
                                                                                                onChange={(e) => { handleAdd(workExperience, index, "companyName", e.target.value, setWorkexperience) }}
                                                                                                value={i?.companyName}
                                                                                                placeholder="Enter company name"
                                                                                                style={{
                                                                                                    width: "100%",
                                                                                                    borderRadius: "5px",
                                                                                                    borderRadius: "10px",
                                                                                                    height: "2.5rem",
                                                                                                    border: "1px solid #dfdfdf",
                                                                                                    // boxShadow:'0px 4px 4px  rgba(0,0,0,0.2)',
                                                                                                }}
                                                                                            />

                                                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                                                {

                                                                                                    <BsCheck2Circle onClick={() => {
                                                                                                        if (checkBeforeVerify(i?.companyName)) {
                                                                                                            handleAdd(workExperience, index, "companyName_HRVerified", !i.companyName_HRVerified, setWorkexperience)
                                                                                                        }
                                                                                                    }}
                                                                                                        style={{ border: "0px solid black", fontSize: 25, marginLeft: 5, color: i.companyName_HRVerified ? "#0DAC45" : "#ABABAB" }} />
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col span={24} style={{ width: "100%", marginTop: '1rem' }}>
                                                                                        <div>
                                                                                            <div className="r-jsb">
                                                                                                <div style={{ color: "#898989", fontWeight: 500 }}>{"Start Date"}{true && <span style={{ color: "red" }}> *</span>}</div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div style={{ width: "95%", display: "flex", alignItems: "center", marginTop: '1rem' }}>
                                                                                            <DatePickerCompo

                                                                                                onChange={(e) => {
                                                                                                    if (e) {
                                                                                                        handleAdd(workExperience, index, "startDate", moment(e), setWorkexperience)
                                                                                                    }
                                                                                                    else {
                                                                                                        handleAdd(workExperience, index, "startDate", undefined, setWorkexperience)
                                                                                                    }

                                                                                                }}
                                                                                                // allowClear={false}
                                                                                                className="profileDatepicker"
                                                                                                disabledDate={(current) => { return current && current >= moment().endOf('day'); }}
                                                                                                placeholder="Select startdate"
                                                                                                value={KeyIsNotEmpty(i.startDate) ? moment(i.startDate) : undefined}
                                                                                                style={{
                                                                                                    width: "100%",
                                                                                                    height: 30,
                                                                                                    borderRadius: "10px",
                                                                                                    height: "2.5rem",
                                                                                                    border: " 1px solid #dfdfdf",
                                                                                                    // boxShadow:'0px 4px 4px  rgba(0,0,0,0.2)',
                                                                                                }}
                                                                                            />

                                                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                                                {
                                                                                                    <BsCheck2Circle onClick={() => {
                                                                                                        if (checkBeforeVerify(i?.startDate)) {
                                                                                                            handleAdd(workExperience, index, "startDate_HRVerified", !i.startDate_HRVerified, setWorkexperience)
                                                                                                        }
                                                                                                    }}
                                                                                                        style={{ border: "0px solid black", fontSize: 25, marginLeft: 5, color: i.startDate_HRVerified ? "#0DAC45" : "#ABABAB" }} />
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col span={24} style={{ width: "100%", marginBottom: "1rem", marginTop: '1rem' }}>
                                                                                        <div>
                                                                                            <div className="r-jsb">
                                                                                                <div style={{ color: "#898989", fontWeight: 500 }}>{"End Date"}{true && <span style={{ color: "red" }}> *</span>}</div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div style={{ width: "95%", display: "flex", alignItems: "center", marginTop: '1rem' }}>
                                                                                            <DatePickerCompo
                                                                                                onChange={(e) => {
                                                                                                    if (e) {
                                                                                                        handleAdd(workExperience, index, "endDate", moment(e), setWorkexperience)
                                                                                                    }
                                                                                                    else {
                                                                                                        handleAdd(workExperience, index, "endDate", undefined, setWorkexperience)
                                                                                                    }
                                                                                                }}
                                                                                                allowClear={false}
                                                                                                className="profileDatepicker"
                                                                                                value={KeyIsNotEmpty(i.endDate) ? moment(i.endDate) : undefined}
                                                                                                disabledDate={(current) => { return (current >= moment().endOf('day') || current < moment(i.startDate)) }}
                                                                                                placeholder={"Select enddate"}
                                                                                                style={{
                                                                                                    width: "100%", height: 30, border: null,
                                                                                                    borderRadius: "10px",
                                                                                                    height: "2.5rem",
                                                                                                    border: " 1px solid #dfdfdf",
                                                                                                    // boxShadow:'0px 4px 4px  rgba(0,0,0,0.2)',
                                                                                                }}

                                                                                            />

                                                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                                                {
                                                                                                    <BsCheck2Circle onClick={() => {
                                                                                                        if (checkBeforeVerify(i?.endDate)) {
                                                                                                            handleAdd(workExperience, index, "endDate_HRVerified", !i.endDate_HRVerified, setWorkexperience)
                                                                                                        }
                                                                                                    }}
                                                                                                        style={{ border: "0px solid black", fontSize: 25, marginLeft: 5, color: i.endDate_HRVerified ? "#0DAC45" : "#ABABAB" }} />
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                                {index === 0 && <Row>
                                                                                    <Col style={{ width: "100%", marginBottom: "1rem", marginTop: '1rem' }}>
                                                                                        <div>
                                                                                            <div className="r-jsb">
                                                                                                <div style={{ color: "#898989", fontWeight: 500 }}>Skills</div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div style={{ width: "95%", display: "flex", alignItems: "center", marginTop: '1rem' }}>
                                                                                            <Input
                                                                                                onChange={(e) => {
                                                                                                    if (!KeyIsNotEmpty(e.target.value)) {
                                                                                                        setSkills((curr) => ({ skills_HRVerified: false, value: e.target.value }))
                                                                                                    }
                                                                                                    else {
                                                                                                        setSkills((curr) => ({ ...curr, value: e.target.value }))
                                                                                                    }

                                                                                                }}
                                                                                                value={skills.value}
                                                                                                placeholder="Enter skills"
                                                                                                style={{
                                                                                                    width: "100%",
                                                                                                    borderRadius: "5px",
                                                                                                    borderRadius: "10px",
                                                                                                    height: "2.5rem",
                                                                                                    border: " 1px solid #dfdfdf",
                                                                                                    // boxShadow:'0px 4px 4px  rgba(0,0,0,0.2)',
                                                                                                }}
                                                                                            />

                                                                                            <div >
                                                                                                {
                                                                                                    <BsCheck2Circle onClick={() => {
                                                                                                        if (checkBeforeVerify(skills?.value)) {
                                                                                                            setSkills((curr) => {
                                                                                                                return { ...curr, verified: !curr.verified }
                                                                                                            })
                                                                                                        }
                                                                                                    }}
                                                                                                        style={{ border: "0px solid black", fontSize: 25, marginLeft: 5, color: skills.verified ? "#0DAC45" : "#ABABAB" }} />
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>}
                                                                                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px', width: '95%' }}>

                                                                                    <div style={{ display: 'flex', justifyContent: 'flex-start', }}>
                                                                                        <ButtonCompo title={"Upload Document"} suffixIcon={plusIcon} style={{ background: '#0086ff', color: '#fff', }} onClick={() => {
                                                                                            console.log('raja chor')
                                                                                            setOpenModal(true);
                                                                                            setModalRender({ type: 'Work Experience', length: workExperience?.length, curr: index, showV: true });
                                                                                        }} />
                                                                                    </div>
                                                                                    {
                                                                                        index >= 1 && <div style={{ color: "red", display: "flex", justifyContent: "flex-end", cursor: "pointer", }} onClick={() => {
                                                                                            let temp = [...workExperience]
                                                                                            temp.splice(index, 1)
                                                                                            setWorkexperience([...temp])
                                                                                        }} >remove <span style={{ color: "red", fontSize: 12 }}><MdOutlineCancel style={{ border: "0px solid black", height: "20px", width: "20px", color: "red" }} /></span>
                                                                                        </div>}
                                                                                </div>

                                                                            </>
                                                                        ))
                                                                    }
                                                                    <>
                                                                        {value === "Fresher" && <Row>
                                                                            <Col style={{ width: "100%", marginBottom: "1rem", marginTop: '1rem' }}>
                                                                                <div>
                                                                                    <div className="r-jsb">
                                                                                        <div style={{ color: "#898989", fontWeight: 500 }}>Skills</div>
                                                                                    </div>
                                                                                </div>

                                                                                <div style={{ width: "95%", display: "flex", alignItems: "center", marginTop: '1rem' }}>
                                                                                    <Input
                                                                                        onChange={(e) => {
                                                                                            if (!KeyIsNotEmpty(e.target.value)) {
                                                                                                setSkills((curr) => ({ skills_HRVerified: false, value: e.target.value }))
                                                                                            }
                                                                                            else {
                                                                                                setSkills((curr) => ({ ...curr, value: e.target.value }))
                                                                                            }
                                                                                        }}
                                                                                        value={skills.value}
                                                                                        placeholder="Enter skills"
                                                                                        style={{
                                                                                            width: "100%",
                                                                                            borderRadius: "5px",
                                                                                            borderRadius: "10px",
                                                                                            height: "2.5rem",
                                                                                            border: " 1px solid #dfdfdf",
                                                                                            // boxShadow:'0px 4px 4px  rgba(0,0,0,0.2)',
                                                                                        }}
                                                                                    />

                                                                                    <div >
                                                                                        {
                                                                                            <BsCheck2Circle onClick={() => {
                                                                                                if (checkBeforeVerify(skills?.value)) {
                                                                                                    setSkills((curr) => {
                                                                                                        return { ...curr, verified: !curr.verified }
                                                                                                    })
                                                                                                }
                                                                                            }}
                                                                                                style={{ border: "0px solid black", fontSize: 25, marginLeft: 5, color: skills.verified ? "#0DAC45" : "#ABABAB" }} />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>}
                                                                    </>
                                                                    {value !== "Fresher" ? <>

                                                                        <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '1.5rem' }}>
                                                                            {workExperience.length <= 4 && <ButtonCompo title={"+ Add Experience Details"} style={{ border: '1px solid #0086ff', color: '#0086ff', }} disabled={viewClass?.hrAssociate} onClick={() => {
                                                                                if (workExperience.length <= 4) {
                                                                                    let temp = [...workExperience];
                                                                                    temp.push({
                                                                                        companyName: "",
                                                                                        startDate: "",
                                                                                        endDate: "",
                                                                                        companyName_HRVerified: false,
                                                                                        startDate_HRVerified: false,
                                                                                        endDate_HRVerified: false,
                                                                                    })
                                                                                    setWorkexperience(temp)
                                                                                }
                                                                            }} />}
                                                                        </div></> : null}

                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "95%", marginTop: 10 }}>

                                                                        <Button name={"Save"} background={"#0086FF"} fontColor={"#FFFFFF"} disabled={viewClass?.hrAssociate} onclick={() => {
                                                                            if (checkWorkexpDetailsFill() && checkOnFileUpload('Work Experience', 5 * workExperience?.length)) {
                                                                                onSavePatch()
                                                                            }
                                                                        }} />
                                                                        <Button name={"cancel"} background={"#FFFFFF"} fontColor={"#0086FF"} disabled={false} onclick={() => {
                                                                            setPage({ prev: page?.curr, curr: page.prev, PD: page?.PD })
                                                                        }} borderColor={"#0086FF"} />


                                                                    </div>
                                                                </div>

                                                            </div>
                                                            {
                                                                getPreviewData(employeeProfile, false, "Work Experience")?.map((res, id) => (
                                                                    <Preview type={res?.type} data={res?.data} />
                                                                ))
                                                            }
                                                        </div>

                                                    </>
                                                    :
                                                    tab === 'Notice Period' &&
                                                    <>
                                                        <div style={{ height: "fit-content", padding: 20, }}>
                                                            <div style={{ display: "flex", flexWrap: "wrap", }}>
                                                                {
                                                                    getFormData(employeeProfile, handleInputChange, handleFocusChange, handleAddrDuplicate, "notice Period")?.map((section, index) => (
                                                                        <DetailSection section={section} index={index} />
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "95%", marginTop: 10 }}>
                                                            <Button name={"Revoke"} background={"red"} fontColor={"#FFFFFF"} disabled={false} onclick={() => {
                                                                    revokeNoticePeriod({userViewId:openPage},()=>{
                                                                        setRender(!render);
                                                                    })
                                                            }} borderColor={"black"} />
                                                            <Button name={"cancel"} background={"#FFFFFF"} fontColor={"#0086FF"} disabled={false} onclick={() => {
                                                                setPage({ prev: page?.curr, curr: page.prev, PD: page?.PD })
                                                            }} borderColor={"#0086FF"} />
                                                            <Button name={"save"} background={"#0086FF"} fontColor={"#FFFFFF"} disabled={viewClass?.hrAssociate} onclick={() => {
                                                                if (areAllKeysDefined(employeeProfile?.noticePeriod, ["startDate", "endDate"])) {
                                                                    onSavePatch();
                                                                }
                                                            }} />


                                                        </div>

                                                    </>

                            }
                        </div></>}
                </div>
            </div>
            <ModalCompo
                contentStyle={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    paddingleft: 10,
                    height: "100vh",
                    borderRadius: "10px 0 0 10px"
                }}
                className={classes.UploadModal}
                open={openModal}
                closableLeft={true}
                onCancel={() => setOpenModal(false)}
                childrenModStyle={{ justifyContent: 'start' }}
                header={
                    <div className={classes.modalHeader} style={{ paddingLeft: '15px', width: '40vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <h3>Upload Document</h3>
                        <p style={{ fontSize: '10px', color: '#FF7B7B' }}>Note: File size should be less than 10 MB</p>
                    </div>
                }>
                <div style={{ width: '100%' }}>
                    <div style={{ color: '#ABABAB', fontSize: '10px' }}>Mandatory</div>
                    <div className={classes.UploadScroll}>
                        {modalRender?.type !== "Work Experience" ?
                            <UploadDocument uploadedFiles={uploadedFiles} onClose={() => { setOpenModal(false) }} onSavePatch={onSavePatch} modalRender={modalRender} ></UploadDocument> :
                            <UploadDocumentMulti uploadedFiles={uploadedFiles} onClose={() => { setOpenModal(false) }} onSavePatch={onSavePatch} modalRender={modalRender} />}

                    </div>

                </div>
            </ModalCompo>
        </div>
    )
}
export default EditProfile; 