import { DatePicker, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import '../../../design/framework.css'
import { useProjecteStore } from '../store/store'
import { DownloadData, getTaskLogs } from '../action/action'
import moment from 'moment'
import downloadIcon from "../../../assets/download_Icon_black.svg";
import backIcon from "../../../assets/backIcon.svg";
import useApiStore from '../../../services/store'
import viewClass from '../../../services/ViewRole'
import { AiOutlineLeft } from "react-icons/ai";
import TableCustom from '../../../components/tableCustom/customTable'
import { useWindowDimensions } from '../../../components/windowDimention/windowDimension'
import { MobileCard } from '../Release/Release'
const EmployeeSummary = ({ setRender }) => {
    const { taskLogs, setTaskLogs } = useProjecteStore(state => state)
    const [startDateVal, setStartDateVal] = useState(null)
    const [endDateVal, setEndDateVal] = useState(null)
    const { loader } = useApiStore(state => state)
    const [paginationPage, setPaginationPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [metaData, setMeta] = useState();
    const { width } = useWindowDimensions();
    const DateHandler = (key, value) => {
        if (key === 'start') {
            setStartDateVal(value)
        } else {
            setEndDateVal(value)
        }
    }

    console.log("taskLogstaskLogs",taskLogs)

    const tableHeight = 48
    const scrollHeight = { y: `${tableHeight}vh` }
    const downLoadHandler = () => {
        let param = { userId: taskLogs?.userId, download: true }
        if (startDateVal) {
            param = { ...param, startDate: startDateVal }
        }
        if (endDateVal) {
            param = { ...param, endDate: endDateVal }
        }
        DownloadData(param, (res) => {
            let fileName = res?.split('/')[4] + "_" + taskLogs?.name;
            console.log("fileName", fileName);
            const link = document.createElement('a');
            link.download = fileName;
            link.href = res;
            link.click();
            console.log(res);
        },"logs")
    }
    useEffect(() => {
        getTaskLogs(setTaskLogs, { userId: taskLogs?.userId, startDate: startDateVal, endDate: endDateVal, limit: pageSize, page: paginationPage }, (res) => { setMeta(res) })
    }, [startDateVal, endDateVal, paginationPage, pageSize])

    const tableColumn = {
        0: {
            cols: "Task",
            width: "90%",
            dataKey: "taskName",
            onclick: () => { }
        },
        1: {
            cols: "Start Date",
            width: "20%",
            dataKey: "startDate",
            onclick: () => { }
        },
        2: {
            cols: "End Date",
            width: "20%",
            dataKey: "endDate",
            onclick: () => { }
        },
        3: {
            cols: "Project",
            width: "50%",
            dataKey: "projectName",
            onclick: () => { }
        },
        4: {
            cols: "Planned Effort",
            width: "35%",
            dataKey: "plannedEffort",
            onclick: () => { }
        },
        5: {
            cols: "Actual Effort",
            width: "40%",
            dataKey: "actualEffort",
            onclick: () => { }
        },
        6: {
            cols: "Project",
            width: "40%",
            dataKey: "project",
            onclick: () => { }
        },
        7: {
            cols: "Status",
            width: "30%",
            dataKey: "status",
            onclick: () => { }
        },
        8: {
            cols: "On Time Delivery",
            width: "50%",
            dataKey: "ontimeDelivery",
            onclick: () => { }
        },

    }

    const columnHeads = [
        {
            title: (
                <h4 style={{ fontWeight: "bold", marginLeft: "1rem", textAlign: 'center' }}>Task</h4>
            ),
            dataIndex: "Task",
            key: "Task",
            render: (text, record) => {
                return (
                    <div>{record?.taskName}</div>
                )
            },
        },
        {
            title: (
                <h4 style={{ fontWeight: "bold", marginLeft: "1rem", textAlign: 'center' }}>Effort Date</h4>
            ),
            dataIndex: "date",
            key: "date",
            render: (text, record) => {
                return (
                    <div>{moment(record?.date).format("DD-MMM-YYYY")}</div>
                )
            },
        },

        {
            title: (
                <h4 style={{ fontWeight: "bold", marginLeft: "1rem", textAlign: 'center' }}>Project</h4>
            ),
            dataIndex: "Project",
            key: "Project",
            render: (text, record) => {
                return (
                    <div>{record?.projectName}</div>
                )
            },
        },
        {
            title: (
                <h4 style={{ fontWeight: "bold", marginLeft: "1rem", textAlign: 'center' }}>Planned Effort</h4>
            ),
            dataIndex: "Planned Effort",
            key: "Planned Effort",
            render: (text, record) => {
                return (
                    <div>{record?.plannedEffort}</div>
                )
            },
        },
        {
            title: (
                <h4 style={{ fontWeight: "bold", marginLeft: "1rem", textAlign: 'center' }}>Actual Effort</h4>
            ),
            dataIndex: "ActualEffort",
            key: "Actual Effort",
            render: (text, record) => {
                return (
                    <div>{record?.actualEffort ? record?.actualEffort : 0}</div>
                )
            },
        },
        {
            title: (
                <h4 style={{ fontWeight: "bold", marginLeft: "1rem", textAlign: 'center' }}>Status</h4>
            ),
            dataIndex: "Status",
            key: "Status",
            render: (text, record) => {
                return (
                    <div style={{
                        borderRadius: 10,
                        border: `0.6px solid ${record?.isApproved === false ? 'red' : record?.isApproved === null ? 'gray' : 'green'}`,
                        color: record?.isApproved === false ? 'red' : record?.isApproved === null ? 'gray' : 'green',
                        backgroundColor: record?.isApproved === false ? '#ffcaca' : record?.isApproved === null ? '#e2e2e2' : '#e2fcde'
                    }}>
                        {record?.isApproved === true ? "Approved" : record?.isApproved === false ? "Rejected" : record?.isApproved === null ? "Pending" : ""}
                    </div>


                )
            },
        },
        {
            title: (
                <h4 style={{ fontWeight: "bold", marginLeft: "1rem", textAlign: 'center' }}>On Time Delivery</h4>
            ),
            dataIndex: "On Time Delivery",
            key: "On Time Delivery",
            render: (text, record) => {
                return (
                    <div>{record?.ontimeDelivery ? 'Yes' : 'No'}</div>
                )
            },
        }
    ]

    return (
        <>
            {
                width < 700 ?
                    <div style={{ padding: 10 }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>

                            {
                                <div style={{ display: "flex" }}>
                                    {
                                        !((viewClass.hrAdmin || viewClass.hrAssociate) || (viewClass.associate && !viewClass.superAdmin)) ?
                                            <img
                                                src={backIcon}

                                                style={{

                                                    cursor: 'pointer', width: '1.5rem', marginRight: '0.5rem', color: 'black', marginTop: 10
                                                }}
                                                onClick={() => {
                                                    setRender(1);
                                                }}
                                            />
                                            :
                                            <div style={{ fontSize: "1.2rem", fontWeight: "700" }}>Summary</div>

                                    }
                                    {
                                        !((viewClass.hrAdmin || viewClass.hrAssociate) || (viewClass.associate && !viewClass.superAdmin)) &&
                                        <div style={{ fontWeight: 'bold', paddingLeft: '5px', alignSelf: "flex-end" }}>{taskLogs?.name}</div>

                                    }
                                </div>
                            }
                            {
                               (!(viewClass.associate) && (viewClass.superAdmin || viewClass.manager || viewClass.hod)) &&
                                <span onClick={downLoadHandler} style={{ fontWeight: "600", fontSize: "0.85rem", display: "flex", gap: "0.3rem", cursor: "pointer" }}>Download<img src={downloadIcon} style={{ width: "1rem", height: "1rem" }} /></span>
                            }
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '12px', padding: 10 }}>
                            <span>From</span>
                            <DatePicker size='large' onChange={(date, dateString) => {  DateHandler('start', dateString) }} />
                            <span style={{ marginLeft: "1.5rem" }}>To</span>
                            <DatePicker
                             disabledDate={(current) => {
                                return current && current < moment(startDateVal);
                            }}
                            size='large' onChange={(date, dateString) => { DateHandler('end', dateString) }} />
                        </div>
                        {
                            taskLogs?.TableData?.map((item, index) => (
                                <>
                                    <MobileCard
                                        headerLeft={
                                            (
                                                <h3>
                                                    {item?.taskName}
                                                </h3>
                                            )
                                        }
                                        headerRight={item?.actions}
                                        body={(
                                            <>
                                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                    <div style={{ width: "30%", color: "#B1B0B0" }}>Effort Date : </div>
                                                    <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.date}</div>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                    <div style={{ width: "30%", color: "#B1B0B0" }}>Project : </div>
                                                    <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.projectName}</div>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                    <div style={{ width: "30%", color: "#B1B0B0" }}>Planned Effort : </div>
                                                    <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.plannedEffort}</div>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                    <div style={{ width: "30%", color: "#B1B0B0" }}>Actual Effort : </div>
                                                    <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.actualEffort}</div>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                    <div style={{ width: "30%", color: "#B1B0B0" }}>Status : </div>
                                                    <div style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        width: "60%",
                                                        borderRadius: 10,
                                                        border: `0.6px solid ${item?.isApproved === false ? 'red' : item?.isApproved === null ? 'gray' : 'green'}`,
                                                        color: item?.isApproved === false ? 'red' : item?.isApproved === null ? 'gray' : 'green',
                                                        backgroundColor: item?.isApproved === false ? '#ffcaca' : item?.isApproved === null ? '#e2e2e2' : '#e2fcde'
                                                    }}>
                                                        {item?.isApproved === true ? "Approved" : item?.isApproved === false ? "Rejected" : item?.isApproved === null ? "Pending" : ""}
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                    <div style={{ width: "35%", color: "#B1B0B0" }}>On Time Delivery : </div>
                                                    <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.ontimeDelivery ? 'Yes' : 'No'}</div>
                                                </div>

                                            </>
                                        )} />
                                </>
                            ))
                        }
                    </div>
                    :
                    <>
                        <div style={{ padding: '7px 15px' }}>
                            <div style={{ display: 'flex', width: '100%', marginBottom: "0.5rem", flexDirection: "column" }}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", margin: "0 0 1.5rem 0" }}>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ display: "flex", justifyContent: "space-around", }}>
                                           {
                                               !((viewClass.hrAdmin || viewClass.hrAssociate) || (viewClass.associate && !viewClass.superAdmin)) ?
                                               <AiOutlineLeft
                                                   style={{ marginTop: 1, cursor:"pointer" }}
                                                   onClick={() => {
                                                       setRender(1);
                                                   }} size={20} />
                                                   :
                                                   null
                                           }
                                        </div>
                                                <div style={{ fontSize: "1.2rem", fontWeight: "700" }}>Summary</div>
                                    </div>
                                    {
                                        (!(viewClass.associate) && (viewClass.superAdmin || viewClass.manager || viewClass.hod)) &&
                                        <span onClick={downLoadHandler} style={{ fontWeight: "600", fontSize: "0.85rem", display: "flex", gap: "0.3rem", cursor: "pointer" }}>Download<img src={downloadIcon} style={{ width: "1rem", height: "1rem" }} /></span>
                                    }
                                </div>

                                {/*  {
                                    !((viewClass.hrAdmin || viewClass.hrAssociate) || (viewClass.associate && !viewClass.superAdmin)) &&
                                    <img
                                        src={backIcon}

                                        style={{

                                            cursor: 'pointer', width: '1.5rem', marginRight: '0.5rem', color: 'black'
                                        }}
                                        onClick={() => {
                                            setRender(1);
                                        }}
                                    />

                                } */}
                            </div>
                            <>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: "center", margin: "0 0 1rem 0" }}>
                                    <div style={{ fontWeight: 'bold', paddingLeft: '5px', alignSelf: "flex-end" }}>{taskLogs?.name}</div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '12px', }}>
                                        <span>From</span>
                                        <DatePicker size='large' onChange={(date, dateString) => { DateHandler('start', dateString) }} />
                                        <span style={{ marginLeft: "1.5rem" }}>To</span>
                                        <DatePicker
                                         disabledDate={(current) => {
                                            return current && current < moment(startDateVal);
                                        }}
                                        size='large' onChange={(date, dateString) => { DateHandler('end', dateString) }} />
                                    </div>
                                </div>
                                <div>
                                    {/* <TableCustom columns={tableColumn} dataSource={taskLogs?.TableData} loading={loader} pagination={{}
                        {
                        total:candidatePageMeta?.totalItems
                        //   ? candidatePageMeta?.totalItems
                        //   : 0,
                        ,
                        current: this.state.page,
                        showSizeChanger: true,
                        pageSizeOptions: [10,20, 30, 50, 100],
                        pageSize: departmentData?.meta?.itemsPerPage,                
                        onChange: (page, pageSize) => this.handlePageChange(page, pageSize),
                        }} /> */}
                                    <Table
                                        loading={loader}
                                        style={{ width: '100%' }}
                                        size='small'
                                        rowClassName={'row-style'}
                                        dataSource={taskLogs?.TableData}
                                        columns={columnHeads}
                                        scroll={scrollHeight}
                                        pagination={{
                                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                            position: "bottomRight",
                                            size: "small",
                                            pageSizeOptions: [10, 30, 50, 100],
                                            pageSize: metaData?.itemsPerPage,
                                            current: parseInt(paginationPage),
                                            total: metaData?.totalItems
                                                ? metaData?.totalItems
                                                : 0,
                                            onChange: (page, pageSize) => {
                                                setPaginationPage(page)
                                                setPageSize(pageSize);
                                            },

                                        }}
                                        rowKey={({ Date }) => Date}
                                    />
                                </div>
                            </>
                        </div>
                    </>
            }
        </>
    )
}

export default EmployeeSummary