import { Fragment, useState } from "react";
import WorkSummary from "./work-summary";
import AppraisalForm from "./appraisal-form";
import ThankYou from "./thank-you";
import CompanyFeedbackForm from "./companys-feedback-form";
import { AssociateStatus } from "./associate-status";
import Api from "../../../services/Api";
import { useDispatch, useSelector } from "react-redux";
import { associateActions } from "./store";
import ManagerFeedbackForm from "./manager-feedback-form";
import { useEffect } from "react";
import { SECRET_KEY, decryptData } from "../../../services/fileCode";
import { globalActions } from "../store/loader";
import LockPage from "./LockPage";
import { Spin } from "antd";

const AssociateHome = () => {
  console.log("aasociate entered")
  const { user } = useSelector((state) => state.auth);
  const [loader,setLoader] = useState(false);
  const dispatch = useDispatch();
  // let decrypteduserData = JSON.parse(decryptData("AppraisalUserData", SECRET_KEY));
  let lockStatus = ['Associate','Manager']
  useEffect(() => {
    dispatch(globalActions.updateLoader({ loader: true }));
    setLoader(true);
    Api.get("/appraisal-associate?userId=" + user.id)
      .params({})
      .send((response, error) => {
        if (typeof response != "undefined") {
          dispatch(associateActions.updateAssociate({ associate: response }));
          setLoader(false);
        }
    dispatch(globalActions.updateLoader({ loader: false }));
      });

  }, []);

  const { associate } = useSelector((state) => state.associate);

  let page = null;
  if(associate?.lockStatus?.includes('Associate')){
    page = <LockPage/>
  }
  else if (
    associate?.userStatus === AssociateStatus.none &&
    associate?.appraisalActive === true
  ) {
    page = <WorkSummary />;
  } else if (
    associate?.userStatus === AssociateStatus.workSummaryAccepted &&
    associate?.appraisalActive === true
  ) {
    page = <AppraisalForm />;
  } else if (
    associate?.userStatus === AssociateStatus.appraisalFormFilled &&
    associate?.appraisalActive === true
  ) {
    page = <ManagerFeedbackForm />;
  } else if (
    associate?.userStatus === AssociateStatus.managerFeedbackFilled &&
    associate?.appraisalActive === true
  ) {
    page = <CompanyFeedbackForm />;
  } else if (
    associate?.userStatus === AssociateStatus.completed ||
    associate?.userStatus === AssociateStatus.none
  ) {
    page = <ThankYou />;
  }
  return (
    <div style={{height:'90vh',overflow:'scroll'}}>
        {loader ? (
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",height:"80vh",alignItems:"center"}}>
                <Spin />
            </div>
        ) : (
            page 
        )}
    </div>
  )
};

export default AssociateHome;
