import { useNavigate } from "react-router-dom"
import { useResourceStore } from "../../store/store"
import { useEffect } from "react"


// this function has been created to access the  edit employye page when hr is clicking on mail link

function EditRender(){
    const {setTab,setPage,setOpenPage,page} = useResourceStore(state => state)
    const Navigate=useNavigate()
    
    useEffect(()=>{
        setTab("Basic Profile")
        let params= new  URLSearchParams(window.location.search)
        let id=params.get('userId')
        setOpenPage(id)
        console.log(params.get('userId'),"nishant")
        setPage({curr:"editProfile",prev:'employee',PD:'Profile',});
        
    },[window.location])

    // this is to make sure that page variable has been set then only navigate.
    useEffect(()=>{
        if(page?.curr==="editProfile"){

        Navigate('/rmanagement/employees')

        }

    },[page])

    return(
        <div>{''}</div>
    )
}
export default EditRender;