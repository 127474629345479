import React, { useEffect, useState } from "react";
import classes from './RecommendModal.module.css';

import { filterQuestions, getAssociateList } from "../Action";
import { useFPStore } from "../store";
import moment from "moment";
import ModalCompo from "../../../design/ModalCompo";
import { Modal } from "antd";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";



const RecommendModal=({showModal,setShowModal,modalData,dateMonth,})=>{
    const {filteredQuestionsHandler,filteredQuestions}=useFPStore();
    const [loader,setLoader]=useState(true);
    const {width}=useWindowDimensions();

    

    

    // useEffect(()=>{
    //     if(dateMonth&&modalData?.uid)
    //     {
    //         setLoader(true);
    //         filterQuestions((res)=>{
    //             filteredQuestionsHandler(res);
    //             setLoader(false);
    //         },modalData?.uid,dateMonth,true)
    //    }
        
    // }
    // ,[dateMonth,modalData?.userDetails?.uid])


    return(
        <Modal
        open={showModal?.recommend}
        onCancel={()=>{
            setShowModal({feedback:false, recommend:false});
        }}
        centered
        width={width>800?"48vw":"100vw"}
        bodyStyle={{display:"flex",alignItems:"center",justifyContent:"center",maxHeight:"70vh",overflowY:"auto"}}
       
        closable={false}
        footer={false}
        maskClosable={true}
        >
            <div className={classes.recommend}>
                <section className={classes.header}>
                    <div>
                        <div style={{fontWeight:"600"}}>{modalData?.name+"("+modalData?.uid+")"}</div>
                        <div>{modalData?.email+" | "+modalData?.phone}</div>
                    </div>
                   <div>
                        <div>{modalData?.teamDetails?.teamName?modalData?.teamDetails?.teamName:"NA"} | </div>
                        <div>{modalData?.teamDetails?.managerName?modalData?.teamDetails?.managerName:"NA"}</div>
                    </div>
                </section>
                <div className={classes.content}>{modalData?.remark?.answer?modalData?.remark?.answer:"NA"}</div>
            </div>

        </Modal>
    )
}
export default RecommendModal;