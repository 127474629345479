import React, { useEffect, useRef, useState } from 'react';
import { Table, Select, Input, Button, Pagination, Dropdown, Menu } from 'antd';
import EditSettingsModal from './setWorkingDays';
import { getManagerWeekDays, getTeamData, postManagerWeekDays } from './Action/action';
import pencil from "../../../assets/pencilmark.svg";
import SearchCompo from '../../../design/SearchCompo'
import { TiTick } from "react-icons/ti";
import { useWindowDimensions } from '../../../components/windowDimention/windowDimension';
import { IoSettingsOutline } from "react-icons/io5";
import ManagerialWorkdaysModal from '../Logs/managerweekdaysModal';
import viewClass from '../../../services/ViewRole';

const WorkingDays = () => {
    const width = useWindowDimensions()
    const [searchValue, setSearchValue] = useState('');
    // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedTeamRowKeys, setSelectedTeamRowKeys]=useState([]);
    const [selectedDeptRowKeys, setSelectedDeptRowKeys]=useState([]);
    const [editButtonDisabled, setEditButtonDisabled] = useState(true);
    const [settingsModal, setSettingModal] = useState(false);
    const onCallBackref = useRef()
    const disableAction = useRef()
    const [selectedManager, setSelectedManager] = useState(null)
    const [loader, setLoader] = useState({
        table: false
    })
    const [isManagerTeam, setisManagerTeam] = useState(false)
    const [managerTeams, setManagerTeams] = useState()
    const [initialTeamName,setInitialTeamName] = useState()
    const [teamData, setTeamData] = useState(null);
    const [selectedDept, setSelectDept] = useState("")
    const [columns, setcolumns] = useState(
        [
            {
                title: 'Team',
                dataIndex: 'name',
                key: 'name',
                width: ""

            },
            {
                title: 'Department',
                dataIndex: 'deptname',
                key: 'deptname',
                filters: [
                    { text: 'Development', value: 'Development' },
                    { text: 'Marketing', value: 'Marketing' },
                    // Add more filters as needed
                ],

                onFilter: (value, record) => record.department.indexOf(value) === 0,
            },
            {
                title: 'Number',
                dataIndex: 'count',
                key: 'count',
            },
            {
                title: 'Working Days',
                dataIndex: 'reqWeekDays',
                key: 'reqWeekDays',
                render: (record) => {
                    return (
                        <div style={{ display: "flex" }}>
                            {
                                record && record?.map((item, index) => (
                                    <div>
                                        {item} {index < record?.length - 1 ? "," : ""}
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'key',
                render: (value, record) => {
                    return (
                        <div
                       
                            >
                            <img 
                                src={pencil} 
                                style={{ height: 20, width: 20,  cursor: disableAction.current === 0 ? "pointer" : "not-allowed" }} 
                                onClick={() => {
                                    if (disableAction.current === 0) {
                                        if(record?.id)
                                        setSelectedTeamRowKeys([record])
                                        else 
                                        setSelectedDeptRowKeys([record])
    
                                        handleEdit()
                                    }
    
                                }}
                            />
                        </div>
                    )
                }
            },
        ]
    )
    const [tablePagniation, setTablePagination] = useState({
        pagination: {
            showSizeChanger: true,
            current: 1,
            pageSize: 10,
            total: 0,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
        },
    });
    useEffect(() => {
        if (selectedTeamRowKeys?.length ||selectedDeptRowKeys?.length ) {
            disableAction.current = 1
        } else {
            disableAction.current = 0
        }
    }, [selectedTeamRowKeys,selectedDeptRowKeys])

    const handleTableChange = (page, pageSize) => {
        setTablePagination((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                current: page,
                pageSize: pageSize,
            },
        }))
        fetchData(page, pageSize,searchValue,(selectedDept && selectedDept?.id !== null) ? [selectedDept?.id] : null)
    };
    useEffect(() => {
        if(selectedManager!==null){
            getManagerWeekDays({ managerId: selectedManager }, (res) => {
                setManagerTeams(res)
                if(res?.currentWeekDayTeam?.teamName){
                    setInitialTeamName(res?.currentWeekDayTeam?.teamName)
                }
            })
        }
    }, [selectedManager])
    useEffect(() => {
        setSearchValue("")
        fetchData(undefined, undefined, undefined, (selectedDept && selectedDept?.id !== null) ? [selectedDept?.id] : null)
    }, [selectedDept])

    const fetchData = (page = 1, pageSize = 10, search = "", deptId = null, number = null) => {
        setLoader((rev) => { return { ...rev, table: true } })
        getTeamData({ page: page, limit: pageSize, search: search, departmentIds: deptId, weekCount: number }, (res) => {
            let temp = res?.final?.items;
            let dept = res?.department;
            temp = temp.map((obj, ind) => {
                return { ...obj, deptname: obj?.department?.name, key: obj }
            })

            dept = dept.map((obj, ind) => {
                return { ...obj, label: obj?.name, value: obj, key: obj }
            })
            setTeamData(temp)
            setTablePagination((prevState) => ({
                ...prevState,
                pagination: {
                    ...prevState.pagination,
                    total: res?.final?.meta?.totalItems
                },

            }))
            setterCol(dept)
            setLoader((rev) => { return { ...rev, table: false } })
        })
    }

    useEffect(() => {
        //fetchData(1, 10, "")
        fetchData(1,10,searchValue,(selectedDept && selectedDept?.id !== null) ? [selectedDept?.id] : null)
        //setSearchValue("")
        setTablePagination({
            pagination: {
                showSizeChanger: true,
                current: 1,
                pageSize: 10,
                total: 0,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
            },
        })
        onCallBackref.current = 0
    }, [onCallBackref.current === 1,searchValue,selectedDept?.id])


    const setterCol = (dept) => {
        setcolumns([
            {
                title: 'Team',
                dataIndex: 'name',
                key: 'name',

            },
            {
                title: 'Manager',
                dataIndex: 'managerView',
                key: 'manager',
                render(record) {
                    return (
                        record?
                        <div style={{ cursor: "pointer", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                            <div style={{width:'80%'}}>
                                {record?.name}
                            </div>
                            <div onClick={() => {
                                setSelectedManager(record?.id)
                                setisManagerTeam(true)
                                getManagerWeekDays({ managerId: record?.id }, (res) => {
                                    setManagerTeams(res)
                                    if(res?.currentWeekDayTeam?.teamName){
                                        setInitialTeamName(res?.currentWeekDayTeam?.teamName)
                                    }
                                })
                            }}
                            
                            >
                               {(viewClass.hrAdmin || viewClass.superAdmin) ?
                                <IoSettingsOutline style={{ color: "#1089FF" }} />:null}
                            </div>
                        </div>:<div>NA</div>
                    );
                }

            },
            {
                title: 'Department',
                dataIndex: 'deptname',
                width:"15%",
                key: 'deptname',
                filters: dept,
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (

                    <div style={{ height: "35vh", boxShadow: " 0px 10px 15px -3px rgba(0,0,0,0.1)" }}>
                        <div style={{ padding: 10, height: "30vh", overflow: "scroll" }}>
                            {dept?.map((item, index) => (
                                <div key={index} style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", fontSize: 14, cursor: "pointer", padding: 5  }} onClick={() => {
                                    setSelectDept(item?.key)
                                    confirm()
                                }}>
                                    {item?.label}
                                    <span >
                                        {
                                            selectedDept?.id === item?.id &&
                                            <TiTick color='green' />
                                        }
                                    </span>
                                </div>
                            ))}

                        </div>
                        <div onClick={() => {
                            setSelectDept(undefined)

                            confirm()

                        }} style={{ marginLeft: 10, fontSize: 16, fontWeight: 600, color: "#1089FF", cursor: "pointer" }}>Reset</div>
                    </div>
                ),

            },
            // {
            //     title: 'Number',
            //     dataIndex: 'count',
            //     key: 'count',
            //     width: "10%",
            // },
            {
                title: 'Assigned Working Days',
                dataIndex: 'weekDays',
                width: "15%",
                key: 'weekDays',
                render: (record) => {
                    const arr=  record?Object.keys(record)?.filter(ele=>record[ele]):undefined;
                    return (
                        <div style={{ maxWidth:'100px',display:'flex',flexWrap:'wrap' }}>
                            {
                            
                                
                                arr ?
                                arr?.map((item, index)=>{
               
                    return <div > {item}{index < arr?.length - 1 ? ", " : ""} </div>
               
              })
                                
                                 :
                                    <div>NA</div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Requested Working Days',
                dataIndex: 'reqWeekDays',
                width: "15%",
                key: 'reqWeekDays',
                render: (record) => {
                    return (
                        <div style={{ maxWidth:'100px',display:'flex',flexWrap:'wrap'}}>
                            {
                                record ? record?.map((item, index) => (
                                    <div style={{}}>
                                        {item} {index < record?.length - 1 ? "," : ""}
                                    </div>
                                )) :
                                    <div>NA</div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'key',
                render: (value, record) => {
                    return (
                        <div
                           
                            >
                            <img 
                                src={pencil} 
                                style={{ height: 20, width: 20,cursor: (viewClass.hrAdmin|| viewClass.superAdmin) ? "pointer": "not-allowed" }}
                                onClick={() => {
                                    if (disableAction.current === 0 && (viewClass.hrAdmin || viewClass.superAdmin)) {
                                        if(record?.id)
                                        setSelectedTeamRowKeys([record])
                                        else 
                                        setSelectedDeptRowKeys([record])
                                        handleEdit()
                                    }
    
                                }}
                            />
                        </div>
                    )
                }
            },
        ])
    }


    const onSelectChange = selectedRowKeys => {
        setSelectedTeamRowKeys(selectedRowKeys?.filter(ele=>ele.id));
        setSelectedDeptRowKeys(selectedRowKeys?.filter(ele=>!ele.id));
        console.log("deadly",selectedRowKeys?.filter(ele=>ele.id),selectedRowKeys?.filter(ele=>!ele.id))
        if (selectedRowKeys.length >= 1) {
            setEditButtonDisabled(false);
        } else {
            setEditButtonDisabled(true);
        }
    };

    const handleEdit = () => {
        setSettingModal(true)
    };
    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between", padding: 10 }}>
                <SearchCompo
                    style={{ width: 200, marginBottom: 10 }}
                    placeholder="Search"
                    value={searchValue}
                    setSearch={(e) => {
                        fetchData(1, 10, e,(selectedDept && selectedDept?.id !== null) ? [selectedDept?.id] : null)
                    }}
                    onSearch={(e) => setSearchValue(e)}
                ></SearchCompo>
                <Button type="primary" style={{ marginBottom: 10 }} disabled={editButtonDisabled} onClick={handleEdit}>Edit</Button>

            </div>
            {
                selectedDept ?
                    <div style={{ marginLeft: 5, fontSize: 12, }}>
                        Selected department : <span style={{ color: "green", fontWeight: 700 }}>{selectedDept?.name}</span>
                    </div>
                    :
                    null

            }
            <Table
                scroll={{ y: window.innerHeight-380, x: 50 }}
                pagination={false}
                dataSource={teamData}
                columns={columns}
                loading={loader?.table}
                rowSelection={{
                    selectedRowKeys:[...selectedTeamRowKeys,...selectedDeptRowKeys], onChange: (selectedRowKeys) => {
                        console.log(selectedRowKeys, "select12323223");
                        onSelectChange(selectedRowKeys)
                    }
                }}
            />

            <Pagination position="bottomRight" size="small" style={{ display: "flex", justifyContent: "flex-end", marginTop: "1rem" }}
                current={tablePagniation.pagination.current}
                pageSize={tablePagniation.pagination.pageSize}
                total={tablePagniation.pagination.total}
                showTotal={(total, range) => {
                    console.log(total, range, "jjjignnnkink");
                    return `${range[0]}-${range[1]} of ${total} items`
                }}
                showSizeChanger={true}
                pageSizeOptions={[10,20,30,50,100,200]}
                onChange={handleTableChange}
                onShowSizeChange={handleTableChange}
            />
            <EditSettingsModal
                onCallBack={() => {
                    onCallBackref.current = 1
                    setEditButtonDisabled(true)
                }}
                selectedTeamRowKeys={selectedTeamRowKeys}
                selectedDeptRowKeys={selectedDeptRowKeys}
                settingsModal={settingsModal}
                onCancel={() => { setSettingModal(false); setSelectedTeamRowKeys([]);setSelectedDeptRowKeys([]); setEditButtonDisabled(true) }}
                onUpdate={() => { }}
            />
            {
                isManagerTeam &&
                <ManagerialWorkdaysModal managerTeams={managerTeams} visible={isManagerTeam} onCancel={() => {
                    setisManagerTeam(false)
                    setManagerTeams(null)
                    setManagerTeams(null)
                }} onUpdate={(selectedTeam) => {
                    postManagerWeekDays({ teamId: selectedTeam, managerId: selectedManager }, () => {
                        setisManagerTeam(false)
                        setManagerTeams(null)
                        setManagerTeams(null)
                    })
                }}  initialTeamName={initialTeamName}/>
            }
        </div>
    );
};

export default WorkingDays;