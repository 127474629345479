import { create } from "zustand";

export const useNotificationStore=create(set=>({
    id: null, 
    approveModal:false,
    approveAllModal:false,
    rejectModal:false,
    rejectAllModal:false,
    userSel:null,
    approveModalHandler:(val)=>set(state=>({approveModal:val})),
    approveAllModalHandler:(val)=>set(state=>({approveAllModal:val})),
    rejectModalHandler:(val)=>set(state=>({rejectModal:val})),
    rejectModalAllHandler:(val)=>set(state=>({rejectAllModal:val})),
    setId: (newId) => set((state) => ({ id: newId })),
    setSelUser : (user)=> set((state)=>({userSel:user})),

    user:'',
    Setuser:(val)=>set(state=>({user:val})),

    
}))