import React from "react";
import Modal from './../../../components/Modal/Modal'
import { useHealthInsuranceStore } from "../Store";
import classes from './DeleteModal.module.css';
import delete_icon from './../../../assets/Delete_Icon.svg';
import { ModalPrimaryButton, ModalSecondaryButton } from "../../../components/Button/Button";
import { deleteAllDocumentApi } from "../Actions";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";

const DeleteModal=({deleteInfo,refresh})=>{
    const {deleteModalHandler,deleteModal}=useHealthInsuranceStore();
    console.log("deleteModal2",deleteModal)
    const {width}=useWindowDimensions();
    return(
        <Modal 
        show={deleteModal}
        closableRight={true}
        header={<div className={classes.header}>
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="23" viewBox="0 0 19 23" fill="none">
            <path d="M18.05 3.72548H15.105C14.6505 1.55872 12.7063 0.00279412 10.45 0H8.54997C6.29361 0.00279412 4.34946 1.55872 3.89498 3.72548H0.949987C0.425319 3.72548 0 4.14245 0 4.65683C0 5.17121 0.425319 5.58824 0.949987 5.58824H1.89997V17.6961C1.90314 20.2667 4.02795 22.3499 6.65 22.3529H12.35C14.9721 22.3499 17.0969 20.2667 17.1 17.6961V5.58824H18.05C18.5747 5.58824 19 5.17126 19 4.65688C19 4.1425 18.5747 3.72548 18.05 3.72548ZM8.55001 15.8333C8.55001 16.3477 8.12469 16.7647 7.60003 16.7647C7.07531 16.7647 6.65 16.3477 6.65 15.8333V10.2451C6.65 9.73073 7.07531 9.31376 7.59998 9.31376C8.12465 9.31376 8.54997 9.73073 8.54997 10.2451V15.8333H8.55001ZM12.35 15.8333C12.35 16.3477 11.9247 16.7647 11.4 16.7647C10.8753 16.7647 10.45 16.3477 10.45 15.8333V10.2451C10.45 9.73073 10.8753 9.31376 11.4 9.31376C11.9247 9.31376 12.35 9.73073 12.35 10.2451V15.8333ZM5.86246 3.72548C6.26654 2.60997 7.34259 1.86416 8.55001 1.86272H10.45C11.6575 1.86416 12.7335 2.60997 13.1376 3.72548H5.86246Z" fill="#F96363"/>
            </svg>
            <div style={{color:"#F96363",fontSize:"1.3rem",fontWeight:"600"}}>Delete</div>
        </div>}
        onCancel={()=>{
            deleteModalHandler(false);
        }}>
            
            <div className={classes.deleteModal}>
               {width>800?<div>Are you sure to delete the Insurance Document of <div>“{deleteInfo?.name}”</div>?</div>:
               <div style={{textAlign:"center"}}>Are you sure to delete the Insurance Document of “{deleteInfo?.name}”?</div>
               }
               <div className={classes.buttonDiv}>
                <ModalPrimaryButton style={{padding:"0.4rem 2rem"}} onSave={()=>{
                    deleteAllDocumentApi(deleteInfo?.id,()=>{
                        refresh();
                        deleteModalHandler(false);
                    })
                }}>Yes</ModalPrimaryButton>
                <ModalSecondaryButton
                style={{padding:"0.4rem 2rem"}} 
                 onCancel={()=>{
                    deleteModalHandler(false);
                }}>No</ModalSecondaryButton>

               </div>

            </div>

        </Modal>
    )
}

export default DeleteModal;