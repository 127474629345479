import classes from "./SearchCompo.module.css";
function SearchCompo({style,value='', setSearch=()=>{},onSearch=()=>{},placeholder='Search',}){

    const changeHandler=(event)=>{
        event.preventDefault();
      
        setSearch(event.target.value);
        
        onSearch(event.target.value);

    }
    return (
            <input value={value} onChange={changeHandler} className={classes.inputSearch} style={style} type="text" placeholder={placeholder}></input>
    )
}

export default SearchCompo;

/*

*/