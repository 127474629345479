import React from "react";

const LockPage = () => {
  return (
    <div
      style={{
        width: "85%",
        height: "80vh",
        // border: "1px solid black",
        marginLeft: "15rem",
        display: "flex",
        flexDirection:'column',
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1>Locked</h1>
      <p>Dear Associate, The appraisal window is closed, please contact your admin.</p>
    </div>
  );
};

export default LockPage;
