import React, { useState } from "react";
import classes from './PhoneInput.module.css';
import { DownOutlined } from '@ant-design/icons';
import {  Input, Popover } from 'antd';
import { NoData } from "../../pages/Training_essentialDocuments";
const countryCodes = require('country-codes-list')
const myCountryCodesObject = countryCodes.customList('countryCode', '[{countryCode}] {countryNameEn}: +{countryCallingCode}');

const PhoneInput=({countryCode,setCountryCode,onChange,value,disabled=false})=>{
    const [search,setSearch]=useState('');
    const [open,setOpen]=useState(false);

  const codes=Object.values(myCountryCodesObject); 
  console.log('satarupa',disabled)   
    return(
        <div  className={`${classes.container} ${disabled?classes.disabled:''}`}>
        <Popover trigger='click'
        open={open}
        onOpenChange={(e)=>{
            setOpen(e);
            setSearch('');
        }}
        style={{width:'30vw',border:'2px solid red'}}
            placement="bottomLeft"
            content={
                <div className={classes.dropdown}>
                    <div>
                        <Input
                        
                        placeholder="Type to search"
                        type="text"
                        
                        value={search?search:undefined}
                        allowClear={true}
                        onChange={(e)=>{
                            setSearch(e.target.value);
                        }}/>
                     </div>                          
                        { (search?codes?.filter(item=>item.toLocaleLowerCase().includes(search.toLocaleLowerCase())):codes)?.map((item,index)=>{
                            return(                            
                                <span style={{cursor:"pointer"}} onClick={()=>{
                                    setCountryCode(item.split(':')[1]?.trim());
                                    setOpen(prev=>!prev);
                                }}>{item}</span>
                            )
                        })}
                        {search&&codes?.filter(item=>item.toLocaleLowerCase().includes(search.toLocaleLowerCase()))?.length==0&&
                        <NoData/>}
            </div>
            }
            >
                <div className={classes.code}>                 
                            <span>{countryCode}</span>
                            <DownOutlined/>                
                </div>
    </Popover>
    <Input  onWheel={(e) => e.target.blur()} type='number' bordered={false} placeholder='Enter mobile number' style={{height:"2.2rem"}}
     onChange={onChange}
     value={value?value:undefined}
     onKeyDown={(e)=>{
        if (e.key === 'e' || e.key === 'E') {
            e.preventDefault();
          }
     }}
    />
        </div>
    )
}
export default PhoneInput;