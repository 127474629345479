/**
 * This AlternatePrivateRoute.js file was create for temporary purpose to redirect from current production
 * to uat.brigosha.com appraisal.
 * This file will check if token exist and redirect
 */

import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { SECRET_KEY, decryptData, encryptData } from "../../services/fileCode";

import Api from "../../services/Api";
import { authActions } from "./login/store";
import { useDispatch } from "react-redux";
import useStore from "../../pages/store";
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";

export let userData = {};

const AlternatePrivateRoute = ({ children, ...rest }) => {
  const urlLocation = new URL(window.location);
  const searchQuery = urlLocation.search.slice(6);
  // const hash = searchQuery[0];
  const userId = searchQuery[1];
  //   const hash = { email: "praveen.kumar.k@brigosha.com", password: "12345678" };

  const navigate = useNavigate();
  const { updateToken, updateUser, updateNewUser } = useStore((state) => state);
  const [showPassword, setShowPassword] = useState(false);
  const token = localStorage.getItem("token");


  useEffect(() => {

    if (!token) {
      Api.get("/appraisal-noAuth/signIn")
        .params({
          hash: searchQuery,
        })
        .send((res) => {
          if (typeof res !== undefined) {
            Api.header(res?.accessToken);
            const encryptedToken = encryptData(res?.accessToken, SECRET_KEY);
            const encryptedUser = encryptData(res?.user, SECRET_KEY);
            localStorage.setItem("token", encryptedToken);
            // localStorage.setItem("user", encryptedUser);
            updateToken(res?.accessToken);
            // updateUser(res?.user);
            userData = res?.user;
            let user = {
              ...res?.userData?.appraisalUsers,
              email: res?.userData?.email,
              name: res?.userData?.name,
            };
            const encryptedAppraisalData = encryptData(
              JSON.stringify(user),
              SECRET_KEY
            );
            localStorage.setItem("AppraisalUserData", encryptedAppraisalData);
          }
        });
    }
  }, []);

  if (!token) {
    return <></>
  }

  return children;
};

export default AlternatePrivateRoute;
