import React, { useEffect, useState } from "react";
import BackRoundIcon from "../../../assets/backRoundIcon.svg";
import { Button, Form, Input, Modal, Radio, Select, Switch, Upload } from 'antd';
import { ProfileStore } from "./store";
import ManageNotify from "../../../assets/settingManageNotifi.svg";
import PasswordIcon from "../../../assets/settingPassword.svg";
import DeleteIcon from "../../../assets/settingDelete.svg";
import LogoutIcon from "../../../assets/settingLogout.svg";
import classes from "./profile.module.css";
import useLoginStorageStore from "../loginStorageStore";
import { useLoginStore } from "../store";
import Api from "../../../services/Api";
import { CiParking1 } from "react-icons/ci";
import SuccessIcon from "../../../assets/updated_icon.svg";
import { EyeOutlined,EyeInvisibleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { decryptData, encryptData,SECRET_KEY } from '../../../services/fileCode';
import circular_check_green from "../../../assets/circular_check_green.svg";
import circular_check_grey from "../../../assets/circular_check_grey.svg";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";

const { Option } = Select;

const Setting = () =>{
    const { currentPage,setCurrentPage,activeSetting,setActiveSetting,settingLists,userData,setUserData} = ProfileStore();
    const notificationList = [{ name:"Enable all",action:"Activate all notifications"},
                            { name:"Push notifications",action:"Activate push notifications"},
                            // { name:"Get WhatsApp alerts",action:"Permission to send important updates on WhatsApp"}
                          ]
    // const [activeSetting,setActiveSetting] = useState(settingLists[0].name);
    const [form] = Form.useForm(); 
    const {width}=useWindowDimensions();
    const [deleteModal, setDeleteModal] = useState(false); 
    const [logoutModal, setLogoutModal] = useState(false); 
    const [forgotPasswordModal, setForgotPasswordModal] = useState(false); 
    const [currentPassword, setCurrentPassword] = useState(""); 
    const [newPassword, setNewPassword] = useState(""); 
    const [reEnterPassword, setReEnterPassword] = useState(""); 
    const [forgotPasswordEmail, setForgotPasswordEmail] = useState(""); 
    const [successModal, setSuccessModal] = useState(false); 
    const [formValues, setFormValues] = useState(null); 
    const [whatsapp, setWhatsapp] = useState(false); 
    const [pushNotification, setPushNotification] = useState(false); 
    const [enableAll, setEnableAll] = useState(false); 
    const { setIsLogin,setIsCandidate}=useLoginStorageStore();
    const {setActiveTab}=useLoginStore();
    const [loadingItems, setLoadingItems] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showReEnterNewPassword, setShowReEnterNewPassword] = useState(false);
    const storedUser = localStorage.getItem('user');
    let decryptedUser = decryptData(storedUser, SECRET_KEY);

    const isValidEmail = (email) => {
        // Simple email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const onFinishHandler = (values) => {
        if(values) {
            setFormValues(values);
            Api.patch("/auth-auth/changePassword")
               .params({
                userId:decryptedUser?.authUserLoginId,
                oldPassword:values.currentPassword,
                newPassword:values.reEnterNewPassword,
               })
              .send((res)=>{
                if(res?.show?.type === "success"){
                    form.resetFields();
                }
             })
        }
    }

    const notificationApiHandler=()=>{
        Api.get(`/auth-auth/manageNotification?userId=${decryptedUser?.authUserLoginId}`)
           .send((res)=>{
            console.log("sssResponse",res);
            setWhatsapp(res?.whatsapp)
            setPushNotification(res?.mobile)
            setEnableAll(res?.whatsapp === true && res?.mobile === true ? true : false)
           })
    }

    useEffect(()=>{
        notificationApiHandler();
    },[])

    const handleStatusChange = (item,index) => {
        const itemId = index;
        const updatedItems = [...loadingItems, itemId]; 
        setLoadingItems(updatedItems);
        Api.patch("/auth-auth/manageNotification")
           .params({
                userId:decryptedUser?.authUserLoginId,
                pushNotification: index === 1 && pushNotification === false  ? true : 
                                  index === 1 && pushNotification === true  ? false :                                   
                                  index === 0 && pushNotification === true && whatsapp === true  ? false :
                                  index === 0 && pushNotification === false && whatsapp === false  ? true :
                                  index === 0 && pushNotification === false && whatsapp === true  ? true :
                                  index === 0 && pushNotification === true && whatsapp === false  ? true :
                                  pushNotification ,
                whatsappAlerts: index === 2 && whatsapp === false  ? true : 
                                index === 2 && whatsapp === true  ? false : 
                                index === 0 && pushNotification === true && whatsapp === true  ? false :
                                index === 0 && pushNotification === false && whatsapp === false  ? true :
                                index === 0 && pushNotification === false && whatsapp === true  ? true :
                                index === 0 && pushNotification === true && whatsapp === false  ? true :
                                whatsapp ,
           })
           .send((res)=>{
                setLoadingItems((prevItems) => prevItems.filter((id) => id !== index));
                notificationApiHandler();
           })
    };

    const deleteHandler = ()=>{
        Api.delete(`/auth-auth/deleteAccount?userLoginId=${decryptedUser?.authUserLoginId}`)
           .send((res)=>{
                if(res?.show?.type === 'success'){
                    setDeleteModal(false);
                    window.localStorage.clear();
                    setIsLogin(false);
                    setActiveTab(1);
                    setIsCandidate(false);
                }
           })
    }
    
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleReEnterNewPasswordVisibility = () => {
        setShowReEnterNewPassword(!showReEnterNewPassword);
    };


    return(
        <div className={classes.settingPage}>
         <div style={{width:width>700? "55%":"90%",}}>
            <div className={classes.settingBackIcon} onClick={()=>{
                setCurrentPage("profile");
                // setActiveSetting("Manage notifications")
                setActiveSetting("Change password")
            }}>
                <img src={BackRoundIcon} alt="" style={{borderRadius:"50%",marginRight:"0.7rem",height:"1.5vh",}}/> 
                Back
            </div>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%"}}>
                <h3 className={classes.settingH3}>Settings</h3>
                {width < 700 ?
                <Select value={activeSetting} placeholder="please select" size="medium" allowClear={false}
                style={{width:"13rem",height:"2.2rem"}}
                onChange={(e)=>{ setActiveSetting(e);}}>
                {settingLists.map((val,index)=>{
                    return(<Option key={index} value={val?.name}>{val?.name}</Option>)
                })}
                </Select>
                :""}
            </div>
            
            <div style={{display:"flex",flexDirection:"row",gap:"1.3vw"}}>
            {width >700 ?
            <div className={classes.settingPageDivide}>
                {settingLists.map((item)=>{
                   return(
                    <div className={`${classes.settingList} ${item.name === activeSetting ? classes.activeSetting : ""}`}
                     onClick={() => { setActiveSetting(item.name);}} >
                        <img src={item.image} alt={item.name} />
                        <span>{item.name}</span>
                    </div>
                    )
                })}
            </div>
            : ""}

            <div className={classes.settingPageDivide2} style={{width:width>700?"70%":"100%"}}>
             { activeSetting === "Change password" ? 
                <div style={{height:"100%"}}>
                    <h3 style={{padding:"0 1vw"}}>Change password</h3>

                    <Form layout="vertical" onFinish={onFinishHandler} style={{marginTop:"2vh",height:"95%"}}
                    form={form}>
                    <div className={classes.settingChangePassword}>
                    <div>
                    <Form.Item name="currentPassword" label={<div>Current password</div>} rules={[
                        {
                            required: true,
                            message: "Please enter the current password",
                        },
                        {
                            min: 8,
                            message: "Password must be at least 8 characters",
                        },
                    ]}>
                        <Input.Password  placeholder='Current password'
                        onChange={(e)=>{setCurrentPassword(e.target.value)}}
                        iconRender={(visible) =>
                            visible ? (
                            <EyeOutlined onClick={togglePasswordVisibility} />
                            ) : (
                            <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
                            )
                        }
                        />
                    </Form.Item>
                    <Form.Item name="newPassword" label={<div>New password</div>} rules={[
                        {
                            required: true,
                            message: "Please enter the new password",
                        },
                        {
                            min: 8,
                            message: "Password must be at least 8 characters",
                        },
                    ]}>
                        <Input.Password placeholder='New Password'
                        onChange={(e)=>{setNewPassword(e.target.value)}}
                        iconRender={(visible) =>
                            visible ? (
                            <EyeOutlined onClick={toggleNewPasswordVisibility} />
                            ) : (
                            <EyeInvisibleOutlined onClick={toggleNewPasswordVisibility} />
                            )
                        }
                        />
                    </Form.Item>
                    <div className={classes.passReq}>
                        <span style={{fontWeight:450,fontSize:14}}>Password Requirements:</span>
                        <div >
                            <img src={newPassword?.length>=8?circular_check_green:circular_check_grey}/>
                            <span><span>Must</span> contain 8 or more characters</span>
                        </div>
                        <div >
                            <img src={(/[a-z]/.test(newPassword))?circular_check_green:circular_check_grey}/>
                            <span><span>Must </span> contain at least one lowercase letter</span>
                        </div>
                        <div >
                            <img src={(/[A-Z]/.test(newPassword))?circular_check_green:circular_check_grey}/>
                            <span><span>Must </span> contain at least one uppercase letter</span>
                        </div>
                        <div >
                            <img src={(/\d/.test(newPassword))?circular_check_green:circular_check_grey}/>
                            <span><span>Must </span> contain at least one number</span>
                        </div>
                        <div >
                            <img src={(/[!@#$%^&*(),.?":{}|<>]/.test(newPassword))?circular_check_green:circular_check_grey}/>
                            <span><span>Must </span> contain special characters (@ ! # $ % ^ & *)</span>
                        </div>
                    </div>
                    <Form.Item name="reEnterNewPassword" label={<div>Re-enter new password</div>}  rules={[
                        {
                            required: true,
                            message: "Please re-enter new password",
                        },
                        {
                            min: 8,
                            message: "Password must be at least 8 characters",
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue("newPassword") === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error("The new password do not match"));
                            },
                          }),
                    ]}>
                        <Input.Password placeholder='Re-enter New Password'
                        onChange={(e)=>{setReEnterPassword(e.target.value)}}
                        iconRender={(visible) =>
                            visible ? (
                            <EyeOutlined onClick={toggleReEnterNewPasswordVisibility} />
                            ) : (
                            <EyeInvisibleOutlined onClick={toggleReEnterNewPasswordVisibility} />
                            )
                        }
                        />
                    </Form.Item>
                    </div>
                    <div>
                        <Button style={{color:"#0086FF",border:"none",height:"5.5vh",fontSize:"0.8rem",fontWeight:500}}
                         onClick={()=>{setForgotPasswordModal(true)}}>
                            Forgotten your password?
                        </Button>
                        <Button type="primary" htmlType="submit" style={{height:"5.5vh",fontSize:"0.8rem",marginBottom:"0.6rem"}}
                        //  onClick={()=>{}}
                        disabled={ currentPassword.length >=8 && newPassword.length >=8 && reEnterPassword.length >= 8 
                            && newPassword === reEnterPassword && currentPassword  !== newPassword
                            && !form.getFieldsError().some((field) => field.errors.length > 0) && (/[a-z]/.test(newPassword))
                            && (/\d/.test(newPassword)) && (/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)) && (/[A-Z]/.test(newPassword))
                            ? false : true}                        
                        >
                            Change password
                        </Button>
                    </div>
                    </div>
                    </Form>
                </div>
             : activeSetting === "Delete account" ? 
                <div className={classes.settingDelete} style={{height: width < 700 && "58vh",paddingLeft: width < 700 && "0.7rem"}}>
                    <div>
                    <h3>Delete account</h3>
                        <h4 style={{marginTop:"3.7vh",fontSize:"0.9rem"}}>Delete account</h4>
                        <span style={{color:"#808080",fontSize:"0.7rem"}}>This action will permanently remove your account.</span>
                    </div>
                    <div>
                        <Button style={{color:"#FC5C65",height:"5.5vh",fontSize:"0.8rem",fontWeight:500}}
                         onClick={()=>{setDeleteModal(true);}}>
                            Yes, delete account
                        </Button>
                    </div>
                </div>
             : activeSetting === "Logout" ? 
               <div className={classes.settingLogout} style={{height: width < 700 && "58vh",paddingLeft: width < 700 && "0.7rem"}}>
                   <div>
                       <h3>Logout</h3>
                        <h4 style={{marginTop:"3.7vh",fontSize:"0.9rem"}}>Logout</h4>
                        <span style={{color:"#808080",fontSize:"0.7rem"}}>Leaving already ?</span>
                    </div>
                    <div>
                        <Button style={{color:"#FC5C65",width:"9rem",height:"5.5vh",fontSize:"0.8rem",fontWeight:500}}
                        onClick={()=>{
                           setLogoutModal(true);
                        }}>Logout</Button>
                    </div>
                   
                </div>
             : 
                <div className={classes.settingLogout}>
                   <div>
                       <h3 style={{marginBottom:"2vh"}}>Notification preferences</h3>
                        {notificationList.map((item,index)=>{
                            return(
                                <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between",width:"100%",}}>
                                    <div>
                                    <h4 style={{marginTop:"3vh",fontSize:"0.9rem"}}>{item.name}</h4>
                                    <span style={{color:"#808080",fontSize:"0.7rem"}}>{item.action}</span>
                                    </div>
                                    <Switch size="medium"
                                        loading={loadingItems.includes(index)}
                                        checked={item?.name === "Get WhatsApp alerts" ? whatsapp 
                                                : item?.name === "Push notifications" ? pushNotification 
                                                : whatsapp === true && pushNotification === true ? true : false}
                                        onChange={() => handleStatusChange(item,index)} 
                                    />
                                </div>
                            )
                        })}
                    </div>
                    <div>
                        
                    </div>
                </div>
             }
            </div>
            </div>
         </div>
         {deleteModal && <Modal open={deleteModal} onCancel={()=>{setDeleteModal(false)}} centered 
          footer={false}>
            <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",width:"100%",gap:"0.4vh",padding:"1vh 0"}}>
                <h2>Confirm</h2>
                <span style={{fontSize:"0.8rem",fontWeight:500}}>Are you certain you want to proceed?</span>
                <div style={{display:"flex",gap:"1vw",marginTop:"8vh"}}>
                    <Button type="primary" style={{height:"5.5vh",fontSize:"0.8rem",}}
                    onClick={()=>{setDeleteModal(false);}}>
                        No, keep account
                    </Button>
                    <Button style={{color:"#FC5C65",height:"5.5vh",fontSize:"0.8rem",fontWeight:500}}
                    onClick={deleteHandler}>
                        Yes, confirm
                    </Button>
                </div>
            </div>
         </Modal>}

         {logoutModal && <Modal open={logoutModal} onCancel={()=>{setLogoutModal(false)}} centered 
          footer={false}>
            <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",width:"100%",gap:"0.4vh",padding:"1vh 0"}}>
                <h2>Confirm</h2>
                <span style={{fontSize:"0.8rem",fontWeight:500}}>Please confirm your logout.</span>
                <div style={{display:"flex",gap:"2vh",marginTop:"8vh",flexDirection:"row"}}>
                    <Button type="primary" style={{height:"5.5vh",fontSize:"0.8rem",}}
                    onClick={()=>{setLogoutModal(false);}}>
                        Cancel
                    </Button>
                    <Button style={{color:"#FC5C65",height:"5.5vh",fontSize:"0.8rem",fontWeight:500}}
                    onClick={()=>{
                        setIsLogin(false);
                        setActiveTab(1);
                        setIsCandidate(false);
                        localStorage.removeItem('user')
                        localStorage.removeItem('token')
                        setLogoutModal(false)
                    }}>
                        Confirm
                    </Button>
                    
                </div>
            </div>
         </Modal>}

         {forgotPasswordModal && <Modal open={forgotPasswordModal} onCancel={()=>{setForgotPasswordModal(false);setForgotPasswordEmail("")}}
          centered 
          footer={false}>
            <div style={{display:"flex",flexDirection:"column",width:"100%",gap:"0.4vh",padding:"1vh 3vw"}}>
                <h2 style={{display:"flex",justifyContent:"center",marginBottom:"1vh"}}>Forgot Password</h2>
                <span style={{fontSize:"0.8rem",fontWeight:500}}>Enter the email id you registered with and we will send you a link to reset your password</span>
                <span style={{marginTop:"2.5vh",fontSize:"0.8rem",}}>Email ID</span>
                <Input type="email" onChange={(e)=>{setForgotPasswordEmail(e.target.value)}} 
                  placeholder="Enter the email id" />
                <div style={{display:"flex",justifyContent:"center",marginTop:"25vh"}}>
                    <Button type="primary" style={{width:"100%",height:"5.5vh",fontSize:"0.8rem",}}
                     onClick={()=>{
                        Api.post('/auth-auth/forgotpassword').params({
                            email: forgotPasswordEmail,
                          }).send(res=>{
                            if(res?.show?.type === "success")
                            {
                                setForgotPasswordModal(false);
                                setSuccessModal(true);
                            }
                          })
                          }} 
                     disabled={!forgotPasswordEmail || !isValidEmail(forgotPasswordEmail)}
                    > Send link
                    </Button>
                </div>
            </div>
         </Modal>}

         {successModal && <Modal open={successModal} onCancel={()=>{setSuccessModal(false);setForgotPasswordEmail("")}} centered
          footer={false}>
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",width:"100%",gap:"0.4vh",padding:"1vh 3vw"}}>
                <img src={SuccessIcon} alt="" />
                <h2 style={{display:"flex",justifyContent:"center",marginBottom:"1vh"}}>Success!</h2>
                <span style={{fontSize:"0.8rem",fontWeight:500,alignItems:"center",display:"flex",justifyContent:"center"}}>We have send you a link to reset your password on your registered email id.</span>
                
                <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginTop:"8vh",width:"100%"}}>
                    <span style={{fontSize:"0.8rem",marginBottom:"2vh"}}>Didn't receive the link ?  
                    <span style={{color:"#0086FF",marginLeft:"0.8rem",cursor:forgotPasswordEmail?"pointer":"not-allowed"}}
                     onClick={()=>{
                       Api.post('/auth-auth/forgotpassword').params({
                         email:forgotPasswordEmail,
                       }).send(res=>{
                         if(res)
                         {
                           // navigate('/forgotPassword');
                         }
                       })}}>Resend</span>
                    </span>
                    <Button type="primary" style={{width:"7rem",height:"5vh",fontSize:"0.8rem",}}
                    onClick={()=>{setSuccessModal(false)}}>
                        Okay
                    </Button>
                </div>
            </div>
         </Modal>}

        </div>
    )
}
export default Setting;