

import React from 'react'
import ModalCompo from '../../../../design/ModalCompo';
import moment from 'moment';
import classes from './employee.module.css';
import { KeyIsNotEmpty } from './formData';
 function HistoryModal({modal,setModal}) {
  return (
    <ModalCompo
    open={modal!==undefined} onCancel={()=>{setModal()}}
    closableRight={true}
    className={classes.HistoryModal}
    >
    <div className={classes.historyContainer} >
    <div style={{font:'normal 700 20px/24px Lato',marginBottom:'1rem',}}>{modal?.name}'s Journey</div>
    <div className={classes.Hlist} style={{display:'flex',alignItems:'center',gap:'2rem'}}>
       
        <div  className={classes.headerblue}>Joined Date</div>
        <div style={{marginTop:'10px'}}>{moment(modal?.joined).format("DD-MMM-YYYY")}</div>
        </div>
        {modal?.data?.map((val,id)=>{
          if(val.flowStatus!=='Joined')
          {
            if(val.flowStatus && val.flowStatus.includes('Department')){
            return(
                <div className={classes.Hlist}>
               <div className={classes.headerblue}>{val?.flowStatus}</div>
                <div className={classes.contentBody}>
                <div >
                  <div className={classes.headergrayH}>Dept. Name : </div>
                  <div>{KeyIsNotEmpty(val?.department)?val?.department:'NA'}</div>
                </div>
                <div>
                    <div className={classes.headergrayH}> {val?.flowStatus?.includes('Released')?'Release':'Assign'} Date : </div>
                    <div>{moment(val?.date).format("DD-MMM-YYYY")}</div>
                </div>
                <div>
                    <div className={classes.headergrayH}>{val?.flowStatus?.includes('Released')?'Released':'Assigned'} By : </div>
                    <div>{KeyIsNotEmpty(val?.assignedBy)?val?.assignedBy:'NA'}</div>
                </div>
                <div>
                    <div className={classes.headergrayH}>Reason : </div>
                    <div>{KeyIsNotEmpty(val?.reason)?val?.reason:'NA'}</div>
                  </div>
                </div>
                </div>
            )
            }
            else if(val.flowStatus && val.flowStatus.includes('Team')){

              return(
                <div className={classes.Hlist}>
               <div className={classes.headerblue}>{val?.flowStatus}</div>
                <div className={classes.contentBody}>
                    <div>
                      <div className={classes.headergrayH}>Team Name : </div>
                      <div>{KeyIsNotEmpty(val?.team)?val?.team:'NA'}</div>
                    </div>
                    <div>
                      <div className={classes.headergrayH}> {val?.flowStatus?.includes('Released')?'Release':'Assign'} Date : </div>
                      <div>{moment(val?.date).format("DD-MMM-YYYY")}</div>
                  
                    </div>
                    <div>
                      <div className={classes.headergrayH}>{val?.flowStatus?.includes('Released')?'Released':'Assigned'} By : </div>
                      <div>{KeyIsNotEmpty(val?.assignedBy)?val?.assignedBy:'NA'}</div>
                  
                    </div>
                    <div>
                      <div className={classes.headergrayH}>Reason : </div>
                      <div>{KeyIsNotEmpty(val?.reason)?val?.reason:'NA'}</div>
                    
                    </div>
                </div>
                </div>
            )
            }
            
            }
        })}
    </div>
    </ModalCompo>
  )
}

export default HistoryModal;