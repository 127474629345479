import ModalCompo from "../../../design/ModalCompo";
import DeleteIcon from "../../../assets/DeleteIcon.svg"; 
import { ModalPrimaryButton, ModalSecondaryButton } from "../../../components/Button/Button";
import { DeleteTasksApi, DeleteTaskApi,deleteProjectApi } from "./ProjectAction";
import Modal from "../../../components/Modal/Modal";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import { Space, Button } from "antd";

function DeleteModal({modal,setModal,refresh}){
    const {width}=useWindowDimensions();
   const DeleteHandler=()=>{
 if(modal?.type==="task"){
        let param={taskId:modal?.id}
        DeleteTaskApi(param,(res)=>{
            if(res){
                setModal();
                refresh();
            }
        })
    }
    else if(modal?.type==="multipleTask"){
     
        let param={taskIds:modal?.ids?.join(',')}

        DeleteTasksApi(param,(res)=>{
            if(res){
                setModal();
                refresh();
            }
        })
    }
   }
    return(
        <>
        <Modal 
            show={modal!==undefined} style={{top:'20vh',width:width<700?"90%":'45vw'}}
            closableRight={true}
            onCancel={()=>{
                setModal();
            }}
            header={<div style={{display:'flex',justifyContent:'center',gap:'0',alignItems:'center',width:"100%"}}>
                    <div style={{color:'#EE4E4E',fontSize:"1.2rem",fontWeight:"600"}}>Delete</div>
                    </div>}
        >
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'1.5rem',flexDirection:'column',margin:"0 2rem 2rem 2rem"}}>
            <div style={{color:'#555555', fontFamily:'Roboto'}}>Are you sure you want to delete this task(s)? This action cannot be undone.</div>  
        </div>
         
        <div style={{display:'flex', justifyContent:'flex-end', gap:'10px'}}>
                
                <Button  onClick={()=>{setModal()}} style={{color:'#6A6A6A', borderRadius:'4px', border: '1px solid var(--Buttons-Neutral-default, #6A6A6A'}}>Cancel</Button>
                <Button  type="primary" danger style={{backgroundColor:'#EE4E4E', borderRadius:'4px',fontFamily:'Roboto'}} onClick={()=>{DeleteHandler();}}>Yes, Delete</Button>
            
            </div>    
        
        </Modal>
        </>
    )       
}
export default DeleteModal;