import React, {  useState } from 'react';
import { Input, Button, DatePicker } from 'antd';
import '../../../design/framework.css'
import { allHolidays, updateHoliday,allHolidaysDeptAssociate } from './Action/action';
import { CiLocationOn } from 'react-icons/ci';
import { useNotificationStore } from './store';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);


const InputComponent = ({ width, borderRadius, borderColor, marginTop, value, onChange, numericOnly, placeholder }) => {
    const handleKeyPress = (e) => {
        if (numericOnly) {
            const keyCode = e.keyCode || e.which;
            const keyValue = String.fromCharCode(keyCode);
            const numericRegex = /^[0-9]*$/;
            const dotRegex = /\./;
            const currentValue = e.target.value;

            if (!numericRegex.test(keyValue) && !(keyValue === '.' && !dotRegex.test(currentValue))) {
                e.preventDefault();
            }
        }
    };

    return (
        <div style={{ width: width, borderRadius: borderRadius, borderColor: borderColor, marginTop: marginTop }}>
            <Input placeholder={placeholder} value={value} onChange={onChange} onKeyPress={handleKeyPress} />
        </div>
    );
};

const TextComponent = ({ text, width, fontSize, backgroundColor, marginLeft, color, marginRight, fontWeight }) => {
    return (
        <div style={{ fontSize: '20px', width: width, fontSize: fontSize, marginLeft: marginLeft, color: color, marginRight: marginRight, fontWeight: fontWeight }}>
            {text}
        </div>
    );
};

function EditHolidayModel({ edit, setCenterModel }) {
    const [loading, setloading] = useState(false)
    const { recordsStore,
        fixedStore, settypeLoactionStore, setLoad,holidayListBy } = useNotificationStore();

    const s = {
        "leaveName": '',
        "date": '',



    }

    const [leave, setleaveData] = useState(edit ? { ...recordsStore } : { ...s })


    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>


            <>
                <div className='r-jc' style={{ display: 'flex', alignItems: 'start', justifyContent: 'start' }}>
                    <CiLocationOn className='p-2' color='#0086FF' fontSize={25} />
                    {edit ?
                        <TextComponent className='p-2' text="Edit Leave type" color={'#0086FF'} fontWeight={700} fontSize={'18px'} /> :

                        <TextComponent className='p-2' text="Add Leave type" color={'#0086FF'} fontWeight={700} fontSize={'18px'} />}
                </div>
            </>

            <div style={{ paddingLeft: '25px', paddingRight: '25px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 20, }}>
                    <TextComponent text="Holiday Name" fontWeight={600} fontSize={15} />
                    <InputComponent placeholder={'Enter Holiday Name'} borderRadius={10} borderColor={'#E6E6E6'} marginTop={10} value={leave?.accountOf} onChange={(e) => { setleaveData({ ...leave, "accountOf": e.target.value }) }} />


                </div>

                <div style={{ flexDirection: 'column', justifyContent: 'space-between', marginTop: 20, }}>
                    <TextComponent text="Date" fontWeight={600} fontSize={15} />
                    <DatePicker placement="bottom"
                        style={{ width: '100%' }}
                        format={'DD-MM-YYYY'}
                        defaultValue={leave?.date ? dayjs(leave?.date, 'DD-MM-YYYY'): null}
                        onChange={(date, dateString) => {
                            setleaveData({ ...leave, date: dateString });
                        }}
                    />

                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
                <Button
                    onClick={() => {
                        setloading(true)
                       const ss = {
                            accountOf: leave?.accountOf,
                            date: leave?.date,
                        }

                        updateHoliday(ss, recordsStore?.id, () => {  
                            // let url = `/attendance-setting/allHolidays?pageNo=${1}&limit=${100}&download=${false}&holidayType=${fixedStore?.type}&cityId=${fixedStore?.id}`;
                                    setLoad(true)
                                    if (holidayListBy === 'Location') {
                                        let url = `/attendance-setting/allHolidays?pageNo=${1}&limit=${100}&download=${false}&holidayType=${fixedStore?.type}&cityId=${fixedStore?.id}`;
                                        allHolidays(url,(res)=>{
                                          settypeLoactionStore(res)
                                          setLoad(false)
                                          setCenterModel(false)
                                        })
                                      }
                                      else if (holidayListBy === 'Department'){
                                        let url = `/attendance-setting/getHoidayDataByType?holidayType=${fixedStore?.type}&id=${fixedStore?.id}&type=${holidayListBy}`;
                                                           
                                        allHolidaysDeptAssociate(url,(res)=>{
                                           settypeLoactionStore(res)
                                           setLoad(false)
                                           setCenterModel(false)
                                        })                  
                                      }
                                      else if (holidayListBy === 'Associate'){
                                        let url = `/attendance-setting/getHoidayDataByType?holidayType=${fixedStore?.type}&id=${fixedStore?.id}&type=${holidayListBy}`;
                                                           
                                        allHolidaysDeptAssociate(url,(res)=>{
                                           settypeLoactionStore(res)
                                           setLoad(false)
                                           setCenterModel(false)
                                        })
                                      }
                                    // allHolidays(url,(res)=>{
                                    //     settypeLoactionStore(res)
                                    //     setCenterModel(false)
                                    //     setLoad(false)
                                    //  })    
                        })
                    }}
                    style={{
                        background: '#0086FF', color: 'white', borderRadius: 8, alignItems: 'center',
                        paddingRight: 16, marginRight: 5,width:"5.5rem",fontSize:15
                    }}>
                    Save
                </Button>
                <Button onClick={() => {
                    setCenterModel(false)
                }} style={{
                    borderRadius: 8, alignItems: 'center', margin: 10, borderWidth: 1, border: "1px solid #0086FF",
                    paddingRight: 5,width:"5.5rem",color:'#0086FF',fontSize:15
                }}>
                    Cancel
                </Button>
            </div>

        </div>
    );
}

export default EditHolidayModel;