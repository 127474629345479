import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/plots";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";

const DashboardGraph = React.memo(({color,data}) => {
    const { width } = useWindowDimensions();

    
    const config = {
        data,
        // minsToHrs, 
        xField: "label",
        yField: "value",
        tooltip: {
          /*   customContent: (val, item)=>{
                return(
                    <div style={{height: 70, minWidth: 100, backgroundColor: '#FFFFFF'}}>
                        <div style={{marginTop:10}}>Efficiency</div>
                        <div style={{fontWeight: 500, marginTop: 10}}>
                            {item}
                        </div>
                       
                    </div>
                )
            }  */
        },
        color:`${color}`,
        xAxis: {
        label: {
            autoHide: false,
            autoRotate: false,
            innerWidth: 10,
            style: width<700?{
                fontSize: 10, 
            }:{},
        },
        },
        meta: {
       
       
        },
        minColumnWidth: 35,
        maxColumnWidth: 35,
    };
    
    return <Column {...config} />
}
)
export default DashboardGraph;
