
import React, { useEffect, useState } from "react";
import classes from "./ApplyFiltersModal.module.css";
import { DatePicker, Form, Select } from 'antd';
import { ModalPrimaryButton, ModalSecondaryButton } from "../../components/Button/Button";
import Api from '../../services/Api';
import { useApplyFilterStore } from "./store";
import Modal from "../Modal/Modal";
import useStore from "../../pages/store";
import filter_icon from '../../assets/filter_icon.svg';
import DatePickerCompo from "../DatePicker/DatePickerCompo.tsx";
import moment from "moment"
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";

export let onSaveFilter =()=>{};


const ApplyFiltersModal = ({

    applyFilterDataHandler,
    applyFilterData,
    getApplyFilterData,
    role,
    setRole,
    deptId,
    setDeptId,
    managerId,
    setManagerId,
    teamId,
    setTeamId,
    setLoader,
    isApplied,
}) => {
    const [form] = Form.useForm();

    const { applyFiltersModalHandler, isApplyFiltersModal } = useApplyFilterStore();
    const { user } = useStore();
    const Option = Select.Option;

    const [startDate, setStartDate] = useState(null);
    const {width} = useWindowDimensions();
    const [screen, setscreen] = useState(width<700)
    const handleStartDateChange = (date) => {
        setStartDate(date);
        form.setFieldsValue({ endDate: null }); // Reset end date when start date changes
    };
    const disabledEndDate = (endValue) => {
        if (!startDate || !endValue) {
            return false;
        }
        return endValue.isBefore(startDate, 'day');
    };
    useEffect(() => {
        if (user?.resourceUser?.id) {
                       getApplyFilterData(applyFilterDataHandler, user?.resourceUser?.id, deptId, role, managerId, teamId, "", "", setLoader, Api);
        }

    }, [deptId, role, managerId, teamId, user?.resourceUser?.id])

    return (
        <Modal show={isApplyFiltersModal} closableLeft={true}
            right={true}
            header={
                <div className={classes.modalHeader}>
                    <img src={filter_icon} style={{ width: "1.2rem", height: "1.2rem" }} />
                    <h3>Apply Filter</h3>
                </div>}
            style={{ borderRadius: "12px 0 0 12px", width:screen?'90%': "35vw", overflowY: "auto" }}
            onCancel={() => {
                applyFiltersModalHandler(false)
                // form.setFieldsValue({})
                if (user?.resourceUser?.id) {
                    if (!deptId) {
                        setDeptId(undefined)
                        
                        getApplyFilterData(applyFilterDataHandler, user?.resourceUser?.id, "", "", "", "", "", "", setLoader, Api);
                    }
                }
            }}>
            {isApplyFiltersModal && <div className={classes.addTeamModal}>
                <Form layout="vertical"
                    initialValues={{
                        departmentId: deptId ? applyFilterData?.allDepartments?.filter(item => item.id === deptId)?.[0]?.name : undefined,
                        managerId: managerId ? applyFilterData?.managersDropdown?.filter(item => item.id === managerId)?.[0]?.name : undefined,
                        teamId: teamId ? applyFilterData?.teamDropDown?.filter(item => item.id === teamId)?.[0]?.name : undefined,
                        roles: role ? role : undefined,
                        // startDate: moment(applyFilterData?.startDate).format("YYYY-MM-DD"),
                        // endDate: moment(applyFilterData?.endDate).format("YYYY-MM-DD")
                    }}
                    form={form}
                    onFinish={(event) => {
                        event = { ...event, startDate: event['startDate']?.format('YYYY-MM-DD'), endDate: event['endDate']?.format('YYYY-MM-DD') }
                        onSaveFilter = () =>{
                            getApplyFilterData(applyFilterDataHandler, user?.resourceUser?.id, deptId, role, managerId, event["teamId"], event["startDate"], event["endDate"], setLoader, Api);
                        }
                        applyFiltersModalHandler(false);
                    }}>
                    <Form.Item label={<span>Department</span>} name="departmentId">
                        <Select
                            size="large"
                            placeholder="Select Department"
                            allowClear={true}
                            onChange={(event) => {
                                setDeptId(event);
                                form.setFieldsValue({
                                    // ['roles']: undefined, 
                                    ['managerId']: undefined, ['teamId']: undefined
                                });
                            }}
                        >
                            {applyFilterData?.allDepartments?.map((item, index) => {
                                return (
                                    <Option value={item.id} key={index}>{item.name}</Option>
                                )
                            })}

                        </Select>
                    </Form.Item>
                    <Form.Item label={<span>Role</span>} name="roles"
                    >
                        <Select
                            size="large"
                            placeholder="Select Role"
                            allowClear={true}
                            onChange={(event) => {
                                setRole(event);
                                form.setFieldsValue({ ['managerId']: undefined, ['teamId']: undefined });
                            }}
                        >
                            {applyFilterData?.roles?.map((item, index) => {
                                return (
                                    <Option value={item} key={index}>{item}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    {(role !== 'Manager'&& role !== 'Associate'&&role !== 'CoManager') && (
                        <Form.Item label={<span>Manager</span>} name="managerId">
                            <Select
                                size="large"
                                placeholder="Select Manager"
                                allowClear={true}
                                onChange={(event) => {
                                    setManagerId(event);
                                    form.setFieldsValue({ ['teamId']: undefined });
                                }}
                            >

                                {applyFilterData?.managersDropdown?.map((item2, index) => {
                                    return (
                                        <Option value={item2.id} key={index}>{item2.name}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    )}

                    {
                        role !== 'Associate'?
                    <Form.Item label={<span>Team</span>} name="teamId" >
                        <Select
                            size="large"
                            placeholder="Select Team"
                            allowClear={true}
                            onChange={(event) => {
                                setTeamId(event);
                            }}
                        >
                            {applyFilterData?.teamDropDown?.map((item, index) => {
                                return (
                                    <Option value={item.id} key={index}>{item.name}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    :
                    null

                    }
                    {
                        console.log(applyFilterData,"applyFilterData?.startDate")
                    }
                    <Form.Item label={<span>Start Date</span>} name="startDate">
                        <DatePickerCompo
                        defaultValue={isApplied&&applyFilterData?.startDate?moment(applyFilterData?.startDate):undefined}
                        // defaultValue={applyFilterData?.startDate?applyFilterData?.startDate:undefined}
                            // defaultValue={moment(applyFilterData?.startDate).format("YYYY-MM-DD")?moment(applyFilterData?.startDate).format("YYYY-MM-DD"):undefined}
                            onChange={handleStartDateChange} style={{ width: "100%" }}  />
                    </Form.Item>

                    <Form.Item label={<span>End Date</span>} name="endDate">
                        <DatePickerCompo
                        defaultValue={isApplied&&applyFilterData?.endDate?moment(applyFilterData?.endDate):undefined}
                            // defaultValue={moment(applyFilterData?.endDate).format("YYYY-MM-DD")?moment(applyFilterData?.endDate).format("YYYY-MM-DD"):moment(applyFilterData?.endDate).format("YYYY-MM-DD")}
                        disabledDate={disabledEndDate}  />
                    </Form.Item>

                    <div className={classes.buttonDiv}>
                        <ModalSecondaryButton
                            style={{ backgroundColor: "#FFFFFF" }}
                            onCancel={
                                () => {
                                    applyFiltersModalHandler(false);
                                    // form.resetFields();
                                    setDeptId(undefined)
                                    setManagerId(undefined)
                                    setRole(undefined)
                                    setTeamId(undefined)
                                    
                                    applyFilterDataHandler([]);
                                    if (user?.resourceUser?.id) {
                                        getApplyFilterData(applyFilterDataHandler, user?.resourceUser?.id, "", "", "", "", "", "", setLoader, Api);
                                    }
                                }

                            }>Reset</ModalSecondaryButton>
                        <ModalPrimaryButton htmlType="submit">Save</ModalPrimaryButton>

                    </div>

                </Form>
            </div>}
        </Modal>
    )
}
export default React.memo(ApplyFiltersModal);