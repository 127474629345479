import React, { useEffect, useRef, useState } from "react";
import classes from './Response.module.css';
import user_icon from './../../assets/Profile Icon.svg';
import Api from "../../services/Api";
import poweredBy_icon from '../../assets/poweredBy_icon.svg';
import delete_red_icon from './../../assets/Delete_Icon.svg';
import preview_icon  from './../../assets/preview_icon.svg';
import { Pagination, Spin, Typography } from "antd";
import { useHealthInsuranceStore } from "./Store";
import upload_icon from './../../assets/upload_icon.svg';
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";
// import PreviewModal from "./PreviewModal";

const Response=({
    total,
    data,
    setInsuranceCard,
    setEmployeeInfo,
    setDeleteInfo,
    limit,
    setLimit,
    current,
    setCurrent,
    loader,
})=>{
    // const [limit,setLimit]=useState(10);
    // const [current,setCurrent]=useState(1);
    // const [loader,setLoader]=useState(false);
    console.log("responseresponseinsuranceData",data)
    const {uploadModalHandler,
        deleteModalHandler,
        previewModalHandler,
    }=useHealthInsuranceStore();
    const [isScrollAdded,setIsScrollAdded]=useState(false);
    const ref=useRef(null);
    const {width}=useWindowDimensions();

    const innerElement= data?.length>0?data?.map((item,index)=>{
        console.log("itemInsurance",item)
        const actionElement=<>
        {!item?.healthInsuranceCard?.length>0&&<div>
            <svg
            style={{cursor:"pointer"}} 
            onClick={()=>{
                uploadModalHandler(true);
                setEmployeeInfo(item);
            }}
            xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <circle cx="20" cy="20" r="20" fill="#0086FF"/>
            <path d="M28.4371 24.2637C28.2879 24.2637 28.1448 24.3261 28.0393 24.4371C27.9338 24.5481 27.8746 24.6988 27.8746 24.8558V27.4433C27.8884 27.626 27.7656 27.7889 27.5933 27.8163H12.4065C12.232 27.7888 12.1083 27.6223 12.1253 27.4374V24.8558C12.1253 24.6442 12.0181 24.4487 11.8441 24.343C11.67 24.2373 11.4556 24.2373 11.2816 24.343C11.1076 24.4487 11.0003 24.6442 11.0003 24.8558V27.4433C10.9921 27.8456 11.1352 28.2353 11.3986 28.5269C11.662 28.8186 12.0244 28.9888 12.4065 29.0005H27.5933C27.9765 28.9889 28.3398 28.8178 28.6033 28.5248C28.8668 28.2318 29.0093 27.8407 28.9995 27.4373V24.8557C28.9995 24.6987 28.9403 24.5481 28.8348 24.437C28.7293 24.326 28.5862 24.2636 28.4371 24.2636V24.2637Z" fill="white"/>
            <path d="M20.9453 11.4899C20.7212 11.1718 20.3652 10.9892 19.991 11.0005C19.6168 10.9892 19.2609 11.1718 19.0368 11.4899L13.9741 17.0432C13.8526 17.1893 13.811 17.3916 13.8641 17.5781C13.9171 17.7646 14.0574 17.9086 14.2346 17.959C14.4119 18.0092 14.6011 17.9587 14.7348 17.8252L19.461 12.6549L19.461 25.5953C19.461 25.7963 19.562 25.9821 19.7261 26.0825C19.8901 26.183 20.0922 26.183 20.2562 26.0825C20.4202 25.9821 20.5213 25.7963 20.5213 25.5953L20.5213 12.6549L25.245 17.8252C25.3766 17.97 25.571 18.0292 25.7549 17.9806C25.9388 17.932 26.0844 17.7829 26.1367 17.5895C26.1889 17.3962 26.14 17.1879 26.0084 17.0432L20.9453 11.4899Z" fill="white"/>
            </svg>
        </div>}
        {
            item?.healthInsuranceCard?.length>0&&
            <div className={classes.previewIcon}>
                {/* <img src={preview_icon} onClick={()=>{
                    previewModalHandler(true);
                    setInsuranceCard(item?.id);
                    setEmployeeInfo(item);
                }}/> */}
                <svg 
                onClick={()=>{
                    previewModalHandler(true);
                    setInsuranceCard(item?.id);
                    setEmployeeInfo(item);
                }}
                xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                <circle cx="20" cy="20" r="20" fill="#0086FF" fill-opacity="0.15"/>
                <path d="M20.0004 22.0946C20.9887 22.0946 21.7899 21.2934 21.7899 20.3051C21.7899 19.3168 20.9887 18.5156 20.0004 18.5156C19.0121 18.5156 18.2109 19.3168 18.2109 20.3051C18.2109 21.2934 19.0121 22.0946 20.0004 22.0946Z" fill="#0086FF"/>
                <path d="M20 15C14.4842 15 10 20.3053 10 20.3053C10 20.3053 14.4842 25.6105 20 25.6105C25.5158 25.6105 30 20.3053 30 20.3053C30 20.3053 25.5158 15 20 15ZM20 23.7368C18.1053 23.7368 16.5684 22.2 16.5684 20.3053C16.5684 18.4105 18.1053 16.8737 20 16.8737C21.8947 16.8737 23.4316 18.4105 23.4316 20.3053C23.4316 22.2 21.8947 23.7368 20 23.7368Z" fill="#0086FF"/>
                </svg>
                <img src={delete_red_icon} onClick={()=>{
                    deleteModalHandler(true);
                    setDeleteInfo({id:item?.id,name:item?.name});
                }}/>
            </div>
        }
    </>  
        
            return(
             

            <>
                {width>800?<div className={classes.cardItem} key={index} style={{paddingRight:isScrollAdded?"0.7rem":"1rem"}}>
                    <div className={classes.profileDiv}>
                        <img src={item?.profileImage?item.profileImage:user_icon} style={{width:"2.5rem",height:"2.5rem",borderRadius:"50%"}}/>
                        <div >
                        <Typography.Text style={{maxWidth:"6rem"}} ellipsis={{tooltip:true,}}>
                            <span>{item?.name}</span>
                            </Typography.Text>
                            <span>{item?.uid}</span>
                        </div>
                    </div>
                    <div className={classes.contact}>
                        <Typography.Text style={{maxWidth:"7rem"}} ellipsis={{tooltip:true}}>
                        <span>{item?.email}</span>
                        </Typography.Text>
                        <span>{item?.phone}</span>
                        
                    </div>
                    <span>{item?.joined?item?.joined:"NA"}</span>
                    <span>{item?.role?item?.role:"NA"}</span>
                    <Typography.Paragraph ellipsis={{tooltip:true,rows:2}}  style={{display:"flex",alignItems:"center",margin:"0"}}>
                    <span>{item?.department?item?.department:"NA"}</span>
                    </Typography.Paragraph>
                    <span className={item?.healthInsuranceCard?.length===0?classes.noDocument: item?.healthInsuranceCard[0]?.published?classes.published:classes.unpublished}>{item?.healthInsuranceCard?.length===0?"No Document":item?.healthInsuranceCard[0]?.published?"Published":"Unpublished"}</span>
                    
                    <div>
                            {actionElement}                   
                        </div>

                </div>:
                <div className={classes.mobcard}>
                    <div className={classes.mobProfile}>
                        <div className={classes.profileDiv}>
                            <img src={item?.profileImage?item.profileImage:user_icon} style={{width:"2.5rem",height:'2.5rem',borderRadius:"50%"}}/>
                            <div >
                                <span style={{color:"#000"}}>{item?.name}</span>
                                <span>{item?.uid}</span>
                            </div>
                        </div>
                        {actionElement}
                    </div>
                    <div>
                        <div>Mail:</div>
                        <div>
                           <Typography.Text style={{maxWidth:"65vw",fontSize:"1rem"}} ellipsis={{tooltip:true}}><span> {item?.email}</span></Typography.Text>
                            </div>
                    </div>
                    <div>
                        <div>Phone:</div>
                        <div>{item?.phone}</div>
                    </div>
                    
                    <div>
                        <div>Date of Joining:</div>
                        <div>{item?.joined?item?.joined:"NA"}</div>
                    </div>
                    <div>
                        <div>Role:</div>
                        <div>{item?.role?item?.role:"NA"}</div>
                    </div>
                    <div>
                        <div>Department:</div>
                        <Typography.Text style={{maxWidth:"65vw",fontSize:"1rem"}} ellipsis={{tooltip:true}}><span>{item?.department?item?.department:"NA"}</span></Typography.Text>
                        {/* <div>{item?.department?item?.department:"NA"}</div> */}
                    </div>
                    <div>
                        <div>Status:</div>
                        <div className={item?.healthInsuranceCard?.length===0?classes.noDocument: item?.healthInsuranceCard[0]?.published?classes.published:classes.unpublished}>{item?.healthInsuranceCard?.length===0?"No Document":item?.healthInsuranceCard[0]?.published?"Published":"Unpublished"}</div>
                    </div>

                </div>
                }

            </>
            )
        
        }):<div className={classes.noData}>
            <span>No Data to Display!</span>
        </div>

    useEffect(() => {
        const divElement = ref.current;
        if(divElement)
        {
        
          const hasScrollbar = divElement.scrollHeight > divElement.clientHeight;
        setIsScrollAdded(hasScrollbar);
        } 
      }, [innerElement]);
     
      console.log(isScrollAdded,"isScrollAdded")


    

    return(
        <div className={classes.response}>
            {width>800&&<div className={classes.header}>
                <span>Associate Name</span>
                <span>Contact Details</span>
                <span>Date of Joining</span>
                <span>Role</span>
                <span>Department</span>
                <span>Status</span>
                <span>Actions</span>
            </div>}

            {loader?<Spin style={{display:"flex",alignItems:"center",justifyContent:"center",height:"50vh"}}></Spin>:<div style={{flex:1,boxSizing:"border-box",display:"flex",flexDirection:"column"}}>
            <div className={classes.cardContainer} ref={ref} >
                {
                  innerElement
                }

            </div>

            <Pagination position="bottomRight" size="middle" style={{display:"flex",justifyContent:"flex-end",marginTop:"auto",marginBottom:"2vh"}}
              current={current} showSizeChanger={true} 
              total={total} 
              pageSize={limit} 
              onChange={(page,pageSize)=>{
                setCurrent(page)
                setLimit(pageSize)
              }}
               onShowSizeChange={(page,pageSize)=>{
                setCurrent(page)
                setLimit(pageSize)
              }} 
              pageSizeOptions={[10,20,30,50,100,200]}
              showTotal={(total, range) => width>800?`${range[0]}-${range[1]} of ${total} items`:null} 
              />
              </div>}
        </div>
    )
}
export default Response;