import React, { useEffect, useState } from "react";
import classes from "./Calender.module.css";
import { Select, Spin, Tooltip } from "antd";
// import { DownLoadButton } from "../../../../components/Button/Button";
import back_icon from "../../../../assets/backIcon.svg";
import download_icon from "../../../../assets/download_Icon_black.svg";
import { useNavigate } from "react-router-dom";
import { getMonthsApi, getMonthwiseData, getUsersForCalender } from "../Action";
import Api from "../../../../services/Api";
// import { useTeamStore } from "../Store/Store";
import { useGlobalStore } from "../../../../store";
// import { BackwardOutlined } from "@ant-design/icons";
import { useWindowDimensions } from "../../../../components/windowDimention/windowDimension";

import moment from "moment"

const Calender = (props) => {
  const Option = Select.Option;
  // const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [loader, setLoader] = useState(false);
  // const location = useLocation();
  const { width } = useWindowDimensions();


  const {
    months,
    monthsHandler,
    monthwiseData,
    usersInCalender,
    monthwiseDataHandler,
    usersInCalenderHandler,
  } = useGlobalStore();

  let member = props?.calenderData;
  // if(location.state!==null)
  // {
  //     member=location?.state?.data;
  // }

  console.log("sssuserIdCaanmember", selectedMonth);
  useEffect(() => {
    getMonthsApi(monthsHandler, setLoader, Api);
  }, []);

  useEffect(() => {
    if (selectedMonth || member || userId) {
      getMonthwiseData(
        monthwiseDataHandler,
        userId ? userId : member?.id,
        selectedMonth,
        false,
        setLoader,
        Api
      );
    }
  }, [selectedMonth, userId, member]);


  useEffect(() => {
    if (props?.calenderData?.teamId) {
      getUsersForCalender(usersInCalenderHandler, props?.calenderData?.teamId, Api);
    }
  }, [props?.calenderData?.teamId]);

  const dayDescription = [
    "Present",
    "LOP",
    "Holiday",
    "Forgot",
    "Outdoor",
    "Leave",
    "Admin",
    "Optional",
    "Week Off",
    "Half Day",
    "Advanced Leave"
  ];
  const setColor = {
    absent: "#F64A3F",
    leave: "#FD8600",
    Advanced: "#581845",
    holiday: "linear-gradient(100deg, #FDDC7A 20%,red )",
    "Forgot Punch": "#FB29FF ",
    present: "#0AAD43",
    "Week Off": "#999999",
    admin: "",
    optional: "#34FFDA",
    // "half day": "#CC6760",
    "Outdoor Punch" : "#005000",
    // "paternity" : "#9A8745",
    // "wedding" : "#C2F339",
    // maternity:"#9A8745"
  };
  const colors = {
    present: "#0AAD43",
    leave: "#FD8600",
    Advanced: "#581845",
    "Week Off": "#999999",
    admin: "",
    optional: "#34FFDA",
    "Forgot Punch": "#FB29FF ",
    holiday: "linear-gradient(100deg, #FDDC7A 20%,red )",
    // "half day": "#CC6760",
    "Outdoor Punch" : "#005000",
    // "paternity" : "#9A8745",
    // "wedding" : "#C2F339",
    // "maternity": "#9A8745"
  };

  const colorsType = {
    "paternity" : "#9A8745",
    "wedding" : "#C2F339",
    "leave": "#FD8600",
    Advanced: "#581845",
    holiday: "linear-gradient(100deg, #FDDC7A 20%,red )",
    "Forgot Punch": "#FB29FF",
    "present": "#0AAD43",
    "Week Off": "#999999",
    admin: "",
    "optional": "#34FFDA",
    // "half day": "#CC6760",
    "Outdoor Punch": "#005000",
  };

  const dayDesColor = (item) => {
    let background = "rgba(10, 173, 67, 1)";
    if (item === "Holiday") {
      background = "linear-gradient(#FDDC7A, #FE9F58)";
    } else if (item === "LOP") {
      background = "rgba(246, 74, 63, 1)";
    } else if (item === "Leave") {
      background = "rgba(253, 134, 0, 1)";
    } else if (item === "Forgot") {
      background = "#FB29FF";
    } else if (item === "Outdoor") {
      background = "#005000";
    } else if (item === "Week Off") {
      background = "#B9C0CC";
    } else if (item === "Admin") {
      background = "#FFFFFF";
    } else if (item === "Optional") {
      background = "rgba(52, 255, 218, 1)";
    } else if (item === "Advanced Leave") {
      background = "#581845";
    } else if (item === "Half Day") {
      background = "linear-gradient(#39CA6C, #F64A3F)";
    }
    let Styles = {
      width: "1.9rem",
      height: "1rem",
      borderRadius: "3px",
      backgroundColor: background,
    };

    return Styles;
  };

  const dayDesColor2 = (item,itemVal) => {
    console.log(item, "00000000000000000");
    let background;
    let border;
    let color = "#000000";

    if (item === "present") {
      color = "#fff"
      background = "rgba(10, 173, 67, 1)";
    }
    else if (item === "half day") {
      background = "linear-gradient(#39CA6C, #F64A3F)";
      color = "#fff";
    } else if (item === "absent") {
      background = "rgba(246, 74, 63, 1)";
      color = "#fff"
    } 
    else if (item === "leave" && itemVal?.leaveType !=="Advanced") {
      background = "rgba(253, 134, 0, 1)";
      color = "#fff";
    } else if (item === "leave" && itemVal?.leaveType ==="Advanced") {
      background = "#581845";
      color = "#fff";
    } 
    else if (item === "Forgot Punch") {
      background = "#FB29FF";
      color = "#fff";
    } else if (item === "Outdoor Punch") {
      background = "#005000";
      color = "#fff";
    } else if (item === "Week Off") {
      color = "#D1D1D1";
    } else if (item === "Public Holiday") {
      background = "linear-gradient(#FDDC7A, #FE9F58)";
      color = "#fff";
    } else if (item === "holiday") {
      background = "linear-gradient(#FDDC7A, #FE9F58)";
      color = "#fff";
    }
    else if (item === "optional") {
      background = "rgba(52, 255, 218, 1)";
      color = "#fff";
    }
    else if (item === "") {
      border = ""
    }
    let Styles = { backgroundColor: background, color: color };
    if (border === "") {
      Styles = { ...Styles, border: border, color: "#000000" }
    }

    return Styles;
  };


  const getMonth = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const weekDays = ["S", "M", "T", "W", "T", "F", "S"];

  let monthDays = monthwiseData?.calendar;
  console.log(monthwiseData?.startDay,"monthwiseData?.calendar;monthwiseData?.calendar");

  const month_start_at_day = monthwiseData?.startDay;
  if (month_start_at_day === 1) {
    monthDays = [1].concat(monthDays);
  } else if (month_start_at_day === 2) {
    monthDays = [1, 1].concat(monthDays);
  } else if (month_start_at_day === 3) {
    monthDays = [1, 1, 1].concat(monthDays);
  } else if (month_start_at_day === 4) {
    monthDays = [1, 1, 1, 1].concat(monthDays);
  } else if (month_start_at_day === 5) {
    monthDays = [1, 1, 1, 1, 1].concat(monthDays);
  } else if (month_start_at_day === 6) {
    monthDays = [1, 1, 1, 1, 1, 1].concat(monthDays);
  }else if (month_start_at_day === 7) {
    monthDays = [1, 1, 1, 1, 1, 1,1].concat(monthDays);
  }

  return (
    <div className={classes.calender} >
      <header>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            {width < 700 && <img onClick={() => {
              props.setPage("homePage");
            }}
              src={back_icon} style={{ width: "1.3rem", height: "1.3rem", marginTop: 2, marginRight: 10 }} />}
            <h3>Calender</h3>
          </div>

          {width < 700 &&
            <div
              style={{ display: 'flex', justifyContent: 'flex-end' }}

              onClick={() => {
                getMonthwiseData(
                  monthwiseDataHandler,
                  userId ? userId : member?.id,
                  selectedMonth,
                  true,
                  setLoader,
                  Api
                );
              }}
            >


              <img src={download_icon} />
            </div>}
        </div>
      </header>

      <section className={classes.nameDiv} >
        {/* <h4>
          {userId
            ? usersInCalender?.filter((item) => item.userId === userId)?.[0]
                ?.userName
            : member?.name}
        </h4> */}


        {width > 700 && <img onClick={() => {
          props.setPage("homePage");
        }}
          src={back_icon} style={{ width: "1.3rem", height: "1.3rem" }} />}


        <div style={width < 700 ? { display: 'flex', flexDirection: width < 700 ? "row" : 'column', width: '100%', justifyContent: 'space-between' } : {}}>
          <div>
            <Select
              disabled={props?.calenderData?.released ? props?.calenderData?.released : false}
              size="large"
              style={width < 700 ? { width: '100%' } : { minWidth: "15rem" }}
              placeholder={member?.name}
              allowClear={true}
              onChange={(event) => {
                setUserId(event);
              }}
            >
              {usersInCalender?.map((item, index) => {
                return (
                  <Option value={item.userId} key={index}>
                    {item.userName}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div>
            {
              console.log(`${moment().format("MMM")}, ${moment().format("YYYY")}`, "dateeeeee")
            }
            <Select
              size="large"
              defaultValue={`${moment().format("MMM")}, ${moment().format("YYYY")}`}
              placeholder="Select Month"
              style={width < 700 ? { width: '100%' } : { minWidth: "12rem" }}
              allowClear={true}
              onChange={(event) => {
                setSelectedMonth(event);
              }}
            >
              {months?.map((item, index) => {
                return (
                  <Option key={index} value={`${item.month},${item.year}`}>{`${getMonth[item.month - 1]
                    }, ${item.year}`}</Option>
                );
              })}
            </Select>
          </div>
          {width > 700 && <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.3rem",
              cursor: "pointer",
              padding: "0 1rem",
              fontWeight: "600",
            }}
            onClick={() => {
              getMonthwiseData(
                monthwiseDataHandler,
                userId ? userId : member?.id,
                selectedMonth,
                true,
                setLoader,
                Api
              );
            }}
          >
            Download{" "}
            <img src={download_icon} style={{ width: "1rem", height: "1rem" }} />
          </div>}
        </div>
      </section>

      {loader ? (
        <Spin style={{ margin: "20% auto" }}></Spin>
      ) : (
        <div>
          <section className={classes.hours} style={width < 700 ? { display: 'flex', flexDirection: 'column', gap: 12 } : {}}>
            <div >
              <div>
                <span>Total Working Hours</span>
                <span>Forgot Punches</span>
                <span>Outdoor Punches</span>
              </div>
              <div>
                <span>{monthwiseData?.totalWorkingHours ? monthwiseData?.totalWorkingHours : 0} hrs</span>
                <span>{monthwiseData?.forgotPunches}</span>
                <span>{monthwiseData?.OutdoorPunches}</span>
              </div>
            </div>

            <div>
              <div>
                <span>Extra Working Days</span>
                <span>WFO</span>
                <span>WFH</span>
              </div>
              <div>
                <span>{monthwiseData?.extraWorkingHours} hrs</span>
                <span>{monthwiseData?.wfo} </span>
                <span>{monthwiseData?.wfh} </span>

              </div>
            </div>

            <div>
              <div>
              <span>Extra Working Hours</span>
                <span>Total Planned Hours</span>
                <span>Total Actual Hours</span>
              </div>
              <div>
              <span>{monthwiseData?.extraWorkingDays} </span>
                <span>{monthwiseData?.totalPlannedHours}</span>
                <span>{monthwiseData?.totalActualHours} hrs</span>
              </div>
            </div>
          </section>

          <div style={{ display: "flex", flexDirection: width < 700 ? 'column' : "row",width:"100%",gap:"3rem"}} >
            <section
              className={classes.calenderPart}
              // style={{border:"2px solid"}}
            >

              <div>

                <h4>
                  {getMonth[selectedMonth?.split(",")[0] - 1]
                    ? getMonth[selectedMonth?.split(",")[0] - 1]

                    : getMonth[monthwiseData?.month - 1]}

                </h4>

              </div>

              <div>
                <div className={classes.weekDays}>
                  {weekDays.map((item, index) => {
                    return <span key={index}>{item}</span>;
                  })}
                </div>

                <div className={classes.daySlot} >
                  {monthDays?.map((item, index) => {
                    console.log(item,"itemitemitemitemitemitemitemitem")
                    if (month_start_at_day > index) {
                      return <div></div>;
                    }
                    return (
                      <div key={index} >
                        <Tooltip title={
                          (<>
                            <div style={{ display: "flex" }}>
                              <div>
                                Check In : {item?.checkIn ? item?.checkIn : "00:00:00"}
                              </div>
                              <br></br>
                              <div>
                                Check Out : {item?.checkOut ? item?.checkOut : "00:00:00"}
                              </div>
                              <br></br>
                              <div>
                                Work type : {item?.punchInfo ? item?.punchInfo?.FinalType : "NA"}
                              </div>
                            </div>
                          </>)
                        }>
                          <div style={{color:
                                item?.status === "Pending"?"red"
                                :(item?.status==="Pending"&&(item?.admin||item?.hr))?""
                                :item?.effort === "holiitem" ? "#FFFFFF"  
                                :setColor[item?.effort] === "#F64A3F" ? "#FFFFFF"
                                :item?.effort==="half item" ? "#FFFFFF"
                                :item?.secondEffort === "absent" && item?.effort === "manual" && item?.half === true ? "#FFFFFF"
                                :colors[item?.effort] === "#999999" ? "#999999"
                                :item?.secondEffort === "manual" && item?.half === true ? "#FFFFFF"
                                :item?.effort === "manual" ? "#FFFFFF"
                                :item?.secondEffort === "absent" && item?.effort === "leave" && item?.half === true ? "#FFFFFF"
                                :setColor[item?.effort] ? "#FFFFFF" 
                                : "",
                          background:item.effort === "holiday" ?"linear-gradient(80deg, #FDDC7A 35%, #FE9F58)"
                              :item.effort === "half day" ? "linear-gradient(50deg, #39CA6C 50%, #F64A3F 50%)"
                              :item?.effort === "leave" && item.leaveType === "Comp_off" ? "linear-gradient(80deg, #0DAC45, #0086FF)"
                              :item?.effort === "holiday" ? "linear-gradient(100deg, #FDDC7A 40%,red)"
                              :item?.effort === "leave" &&  item?.leaveType === "Comp_off"&& item?.half !== true && item?.status !== "Pending" && item?.secondStatus!=="Pending" ? "linear-gradient(85deg, #0DAC45 30%, #0086FF )"
                              // :colorsType && item?.effort === "manual" &&  item?.status!=="Pending" && item?.half !== true? setColor[item?.effort]
                              // item?.half === true && item?.status!=="Pending"
                              :item?.secondEffort === "manual" && item?.type==="Forgot Punch" && item?.effort === "leave"&& item?.half === true && item?.status!=="Pending" && item?.secondStatus !== "Pending" && item?.leaveType !== "Advanced"? "linear-gradient( to right, #FB29FF  50%, #FD8600 50%)"
                              :item?.secondEffort === "manual" && item?.type==="Outdoor Punch" && item?.effort === "leave"&& item?.half === true && item?.status!=="Pending" && item?.secondStatus !== "Pending" && item?.leaveType !== "Advanced"? "linear-gradient( to right, #005000 50%, #FD8600 50%)"
                              :item?.secondEffort === "manual" && item?.type==="Forgot Punch" && item?.effort === "leave"&& item?.half === true && item?.status!=="Pending" && item?.secondStatus !== "Pending" && item?.leaveType === "Advanced"? "linear-gradient( to right, #FB29FF  50%, #581845 50%)"
                              :item?.secondEffort === "manual" && item?.type==="Outdoor Punch" && item?.effort === "leave"&& item?.half === true && item?.status!=="Pending" && item?.secondStatus !== "Pending" && item?.leaveType === "Advanced"? "linear-gradient( to right, #005000 50%, #581845 50%)"
                              :item?.secondEffort === "manual" && item?.type==="Forgot Punch" && item?.effort === "absent"&& item?.half === true && item?.status!=="Pending" && item?.secondStatus !== "Pending" ? "linear-gradient( to right, #FB29FF  50%, #F64A3F 50%)"
                              :item?.secondEffort === "manual" && item?.type==="Outdoor Punch" && item?.effort === "absent"&& item?.half === true && item?.status!=="Pending" && item?.secondStatus !== "Pending" ? "linear-gradient( to right, #005000 50%, #F64A3F 50%)"
                              :item?.secondEffort === "manual" && item?.type==="Forgot Punch" && item?.effort === "present"&& item?.half === true && item?.status!=="Pending" && item?.secondStatus !== "Pending" ? "linear-gradient( to right, #FB29FF  50%, #0AAD43 50%)"
                              :item?.secondEffort === "manual" && item?.type==="Outdoor Punch" && item?.effort === "present"&& item?.half === true && item?.status!=="Pending" && item?.secondStatus !== "Pending" ? "linear-gradient( to right, #005000 50%, #0AAD43 50%)"
                              :item?.secondEffort === "absent" && item?.type==="Forgot Punch" && item?.effort === "manual" && item?.half === true && item?.status!=="Pending" && item?.secondStatus !== "Pending" ? "linear-gradient( to right, #FB29FF 50%, #F64A3F 50%)"
                              :item?.secondEffort === "absent" && item?.type==="Outdoor Punch" && item?.effort === "manual" && item?.half === true && item?.status!=="Pending" && item?.secondStatus !== "Pending" ? "linear-gradient( to right, #005000 50%, #F64A3F 50%)"
                              :item?.secondEffort === "present" && item?.type==="Forgot Punch" && item?.effort === "manual" && item?.half === true && item?.status!=="Pending" && item?.secondStatus !== "Pending" ? "linear-gradient( to right, #FB29FF 50%, #0AAD43 50%)"
                              :item?.secondEffort === "present" && item?.type==="Outdoor Punch" && item?.effort === "manual" && item?.half === true && item?.status!=="Pending" && item?.secondStatus !== "Pending" ? "linear-gradient( to right, #005000 50%, #0AAD43  50%)"
                              :item?.secondEffort === "leave" && item?.type==="Forgot Punch" && item?.effort === "manual" && item?.half === true && item?.status!=="Pending" && item?.secondStatus !== "Pending" && item?.leaveType !== "Advanced"? "linear-gradient( to right, #FB29FF 50%, #FD8600 50%)"
                              :item?.secondEffort === "leave" && item?.type==="Outdoor Punch" && item?.effort === "manual" && item?.half === true && item?.status!=="Pending" && item?.secondStatus !== "Pending" && item?.leaveType !== "Advanced"? "linear-gradient( to right, #005000 50%, #FD8600 50%)"
                              :item?.secondEffort === "leave" && item?.type==="Forgot Punch" && item?.effort === "manual" && item?.half === true && item?.status!=="Pending" && item?.secondStatus !== "Pending" && item?.leaveType === "Advanced"? "linear-gradient( to right, #FB29FF 50%, #581845 50%)"
                              :item?.secondEffort === "leave" && item?.type==="Outdoor Punch" && item?.effort === "manual" && item?.half === true && item?.status!=="Pending" && item?.secondStatus !== "Pending" && item?.leaveType === "Advanced"? "linear-gradient( to right, #005000 50%, #581845 50%)"
                              :item?.secondEffort === "absent" && item?.effort === "leave" && item?.half === true && item?.status!=="Pending" && item?.secondStatus !== "Pending"&& item?.leaveType !== "Advanced" ? "linear-gradient( to right, #FD8600 50%, #F64A3F 50%)"
                              :item?.secondEffort === "present" && item?.effort === "leave" && item?.half === true && item?.status!=="Pending" && item?.secondStatus !== "Pending"&& item?.leaveType !== "Advanced" ? "linear-gradient( to right, #FD8600 50%, #0AAD43 50%)"
                              :item?.secondEffort === "absent" && item?.effort === "leave" && item?.half === true && item?.status!=="Pending" && item?.secondStatus !== "Pending" && item?.leaveType === "Advanced"? "linear-gradient( to right, #581845 50%, #F64A3F 50%)"
                              :item?.secondEffort === "present" && item?.effort === "leave" && item?.half === true && item?.status!=="Pending" && item?.secondStatus !== "Pending" && item?.leaveType === "Advanced"? "linear-gradient( to right, #581845 50%, #0AAD43 50%)"
                              // :item?.secondEffort === "manual" && item?.type==="Forgot Punch" && item?.half === true && item?.status!=="Pending" && item?.secondStatus !== "Pending" ? "linear-gradient( to right, #FB29FF  50%, #FD8600 50%)"
                              // :item?.secondEffort === "manual" && item?.type==="Outdoor Punch" && item?.half === true && item?.status!=="Pending" && item?.secondStatus !== "Pending" ? "linear-gradient( to right, #005000 50%, #FD8600 50%)"
                              :item?.secondEffort === "manual" && item?.effort === "leave" && item?.half === true && item?.status!=="Pending"&& item?.secondStatus==="Pending"&& item?.leaveType !== "Advanced" ? "linear-gradient( to right, #FD8600 50%, #9747FF 50%)"
                              :item?.secondEffort === "leave" && item?.effort === "manual" && item?.half === true && item?.status!=="Pending"&& item?.secondStatus==="Pending" && item?.leaveType !== "Advanced"? "linear-gradient( to right, #FD8600 50%, #9747FF 50%)"
                              :item?.secondEffort === "manual" && item?.effort === "leave" && item?.half === true && item?.status!=="Pending"&& item?.secondStatus==="Pending" && item?.leaveType === "Advanced"? "linear-gradient( to right, #581845 50%, #9747FF 50%)"
                              :item?.secondEffort === "leave" && item?.effort === "manual" && item?.half === true && item?.status!=="Pending"&& item?.secondStatus==="Pending" && item?.leaveType === "Advanced"? "linear-gradient( to right, #581845 50%, #9747FF 50%)"
                              :item?.secondEffort === "present" && item?.effort === "leave" && item?.half === true && item?.status!=="Pending"&& item?.secondStatus==="Pending" ? "linear-gradient( to right, #0AAD43 50%, #9747FF 50%)"
                              :item?.secondEffort === "leave" && item?.effort === "present" && item?.half === true && item?.status!=="Pending"&& item?.secondStatus==="Pending" ? "linear-gradient( to right, #0AAD43 50%, #9747FF 50%)"
                              :item?.secondEffort === "absent" && item?.effort === "leave" && item?.half === true && item?.status!=="Pending"&& item?.secondStatus==="Pending" ? "linear-gradient( to right, #F64A3F 50%, #9747FF 50%)"
                              :item?.secondEffort === "leave" && item?.effort === "absent" && item?.half === true && item?.status!=="Pending"&& item?.secondStatus==="Pending" ? "linear-gradient( to right, #F64A3F 50%, #9747FF 50%)"
                              :item?.effort==="half item" && item?.status!=="Pending" && item?.secondStatus!=="Pending" ? "linear-gradient( to right, #0AAD43 50%, #F64A3F 50%)" 
                              :item?.effort === "leave" && item?.status!=="Pending" && item?.secondStatus!=="Pending"&& item?.leaveType === "Advanced" ? "#581845"
                              :item?.effort === "leave" && item?.status!=="Pending" && item?.secondStatus!=="Pending" && item?.leaveType !== "Advanced"? "rgba(253, 134, 0, 1)"
                              :item?.effort === "leave" &&  item?.leaveType === "Optional" ? "#34FFDA"
                              :item?.effort === "manual"  && item?.status!=="Pending" && item?.secondStatus!=="Pending" ? setColor[item?.type]
                              :item?.effort !== "Week Off" && item?.status!=="Pending" && item?.secondStatus!=="Pending" && setColor[item?.effort],                            
                              ...dayDesColor2(item.effort === "manual" ? item.type : item.effort,item)}}
                            // dayDesColor2(item.effort === "manual" ? item.type : item.effort)}
                          >
                            {index - month_start_at_day + 1}
                            {/* {item?.day} */}
                          </div>
                        </Tooltip>

                      </div>
                    );
                  })}
                </div>
              </div>
            </section>

            <div style={{ backgroundColor: "#FFFFFF", borderRadius: "15px", display: "grid", flexWrap: "wrap", gap: "0.3rem", gridTemplateColumns: "repeat(3,1fr)", padding: "0.1rem",width:"31%"}}>

              {dayDescription.map((item, index) => (
                <div key={index} style={{ display: "flex", alignItems: "center", padding: "0", }}>
                  <div style={{background:item === "Holiday" ?"linear-gradient(80deg, #FDDC7A 35%, #FE9F58)"
                          :item === "Half Day" ? "linear-gradient(50deg, #39CA6C 50%, #F64A3F 50%)"
                          :item === "Comp Off" && "linear-gradient(80deg, #0DAC45, #0086FF)",
                            ...dayDesColor(item), border: item === "Admin" ? "1.5px solid #666666" : item === "Optional" ? "1.5px dotted #666666" : "none" }}></div>
                  <div style={{ padding: "0.3rem", fontSize: "0.85rem" }}>{item}</div>
                </div>
              ))}

            </div>
          </div>


        </div>
      )}
    </div>
  );
};
export default Calender;