class ChatClient {

    constructor(socket, userId, roomId,user, userName, privilege, role , image, occuranceId, orgId, orgUrl,successCallback) { 
        //information of the user personal

        this.userId = userId;
        this.userName = userName;
        this.roomId = roomId; 

        this.privilege = privilege;
        this.role = role ;
        this.image = image; 
        this.occuranceId= occuranceId; 
        this.orgId= orgId;
        this.orgUrl = orgUrl;
        this.user=user
        // this.offline = false;
  
  
  
        //socket variable
        this.socket = socket;
        this.channel = null;
  
        this.successCallback = successCallback;
  
        this.initSockets()
        console.log("constructor called 65")
    }
  
    initSockets() {
  
    (async () => {
      console.log("initsocket called");
    //   this.successCallback();
        (async () => {
            console.log("inside init socket",this.socket)
            for await (let event of this.socket.listener('connect')) {
              console.log('Socket is connected 36', event, this.userId, this.roomId);
              console.log("37 called 65",this.role,this.user)
              // this.offline = false;
              
              this.socket.request('initSockets', { 
                userId: this.userId,
                user: this.user,
                roomId: this.roomId,
                privilege: this.privilege, 
                userName: this.userName,
                streamId: this.roomId,
                role: this.role, 
                image: this.image, 
                occuranceId: this.occuranceId, 
                orgId: this.orgId, 
                orgUrl: this.orgUrl,
                }).then(async (data)=> {
                    console.log("Receieved from server", data);
                    // await this.resumeServices(data)
                })
                
            }
        })();
  
        // (async () => { 
        //     console.log("58 called 65")
        //   console.log("initsocket called in line number 47")
        //   this.socket.request('initSockets', { 
        //     userId: this.userId,
        //     user: this.user,
        //     roomId: this.roomId,
        //     userName: this.userName,
        //     privilege: this.privilege, 
        //     role: this.role, 
        //     streamId: this.roomId,
        //     image: this.image, 
        //     occuranceId: this.occuranceId, 
        //     orgId: this.orgId, 
        //     orgUrl: this.orgUrl,
        //     }).then(async (data)=> {
        //     console.log("Receieved from server", data);
        //     // await this.resumeServices(data)
        //   })
  
        // })();
  
  
        (async () => {
            let channel = this.socket.subscribe(this.roomId);
            console.log("84", channel)
            // this.channel = channel;
            for await (let data of channel) {
                console.log("eee",data?.event);
                if (data.data.type!='Event'){
                        console.log("Client got invokePublish",this.userId,this.userName, data.event,  data.data);
                }else{
                    if (data.data.data.user.id!=this.userId){
                        console.log("Client got invokePublish",this.userId, data.event,  data.data);
                    }
                }
            //   if(parseInt(data.data.fromUserId) == parseInt(this.userId)) return;
            //   if(parseInt(!(data.data.toUserId) == parseInt(this.userId))) return;
              switch(data.event){
  
                case 'rcvGroupChat':{
                    //handdle rcvGroupchat after getting response;
                    this.successCallback({
                        event: 'rcvChat',
                        data:data.data
                    })
                }
                break;

                case 'rcvPrivateChat':{
                    console.log("private chat receive", data.data)
                    this.successCallback({
                        event: 'privateChatRcv',
                        data:data.data
                    })
                }
                break;
  
                case 'userList':{
                    console.log("user list to of the data", data);
                    this.successCallback({
                        event: 'userList',
                        data:data.data
                    })
                }
                break;
  
                case 'userJoined':{
                    console.log("New User Joined", data);
                }
                break;
  
                case 'userRejoined':{
  
                }
                break;
  
                default:
                break;
  
              }
            
            }
          })();
  
  
        (async () => {
            let channel = this.socket.subscribe(''+this.userId);
            // this.channel = channel;
            for await (let data of channel) {
                console.log("Data chanel messages",this.userId, data.event,  data.data);  
                switch(data.event){
                    // case 'subscriberKube':{
                    //     console.log("Data chanel messages",this.userId, data.event,  data.data);  
                    // }
                    // break;    
                    case 'rcvPrivateChat':{
                        console.log("privateChatMeassge received",data.event,data.data);
                        this.successCallback({
                            event: 'privateChatRcv',
                            data:data.data
                        })
  
                    }
                    break;
                     
                    default:
                    break;
                }
            }  
        })();
  
  
        (async() => {
          for await (let event of this.socket.listener('disconnect')){
            console.log('socket is disconnected');
          }
        })();
  
  
      })();
  
  
    }
  
  
  
    async sendPrivateChat(userId,roomId,toUserId,data){
        let thiz = this;
        console.log("send private chat called",data, toUserId);
        this.socket.request('sendPrivateChat',{
            userId,
            roomId,
            toUserId:''+toUserId,
            data
        }).then(async function (response){
            thiz.successCallback({
                event: 'privateChatRcv',
                data: data,
                userId: toUserId
            })
            console.log('sendPrivateChat success');
        }).catch(e=>{
            console.log("error in sendingPrivateChat",e);
        })
    }
  
    async sendGroupChat(userId, roomId, data){
        console.log(userId,roomId,data,"confixx 206");
        if (data.type!='Event'){
            console.log("send group chat called",data);
        }
        this.socket.request('sendGroupChat',{
            userId,
            roomId,
            data
        }).then(async function(response){
            if (data.type!='Event'){
                console.log("send group chat success");
            }
        }).catch(e=>{
            console.log("error in sending group chat",e);
        })
    }
  
   
  
    // async exit(userId, roomId) {
    //     let clean =async function () {
    //     this._isOpen = false
    //         console.log('cleaning socket');
    //     }.bind(this)
    //     console.log("user Id and roomId on exit", userId, roomId);
  
    //     setTimeout(()=>{
    //       if (!this.offline) {
    //           this.socket.request('exitRoom',{
    //               userId,
    //               roomId,
    //           }).then(async function(response){
    //               console.log("exit group response",response);
    //           }).catch(e => console.warn(e)).finally(function () {
    //               setTimeout(()=>clean(),1000);
    //           }.bind(this))
    //       } 
    //       else {
    //           clean()
    //       }
    //     },2000)
  
    //     this.event(_EVENTS.exitRoom)
  
    // }
    async addUser(userId, roomId,data) {
        let thiz=this
        console.log("addUserToRoom for queue");
        try {
            const response = await this.socket.request("addUserToRoom", { userId, roomId,data });
            if(response){
                thiz.successCallback(
                    {event: 'addUser',
                data: data,
                userId: userId}
                )
            }
            console.log("response from addUserToRoom",response);
        } catch (e) {
            console.log("error response from addUserToRoom", e);
        }
      }
    
      async removeUser(userId, roomId) {
          console.log("removeUserFromRoom for queue");
          try {
              const response = await this.socket.request("removeUserFromRoom", { userId, roomId });
              console.log("response from removeUserFromRoom");
          } catch (e) {
              console.log("error response from removeUserFromRoom", e);
          }
      }
    
      async getUsers(roomId,userId) {
        let thiz=this
          console.log("getUsersFromRoom for queue");
          try {
              const response = await this.socket.request("getUsersFromRoom", { roomId });
              console.log("response from getUsersFromRoom");
              this.successCallback(
                {event: 'getUser',
                data: response,
                userId: userId}
              )
          } catch (e) {
              console.log("error response from getUsersFromRoom", e);
          }
      }
    disconnect(){
        this.socket.disconnect();
    }
  
    connect(){
        this.socket.connect();
    }
  
    isOpen() {
      return this._isOpen
  }
  
  }

export default ChatClient;