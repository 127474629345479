import { useState } from "react";
import { useEffect } from "react";
import {  SaveJobApi, getAuthId, getJobDataApi, referApi } from "./Action";
import backIcon from '../../../assets/backIcon.svg';
import LocIcon from '../../../assets/LocIcon.svg';
import ExpIcon from '../../../assets/ExpIcon.svg';
import share_job_icon from '../../../assets/share_job_icon.png';
import ClockIcon from '../../../assets/ClockIcon.svg';
import classes from './jobSearch.module.css';
import DownloadIcon from '../../../assets/Download Icon Blue.svg';
import ButtonCompo from "../../../design/ButonCompo";
import ApplyJobForm from "./ApplyJobForm";
import { Modal, Row, Spin, Typography } from "antd";
import useLoginStorageStore from "../loginStorageStore";
import { Document, Page,pdfjs } from "react-pdf";
// import { pdf } from "@react-pdf/renderer";
import './Pdf.css';
import ModalCompo from "../../../design/ModalCompo";
import backendUrl from "../../../credentials";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function ApplyJob({job,setJob}) {
    const [jobData,setJobData]=useState();
    const[applyForm,setApplyForm]=useState();
    const [loader,setLoader]=useState(false);
    const {isCandidate,isLogin}=useLoginStorageStore();
    const [numPages, setNumPages] = useState(null);
    const [pageHeight, setPageHeight] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {width}=useWindowDimensions();

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
  
    // let canavs= document.getElementsByClassName('react-pdf__Page__canvas');

    //   pdfjs.getDocument("https://brigosha-hackathon-test-brigosha-5777.s3.ap-south-1.amazonaws.com/description/e91badf4-b8b1-43ab-b81f-20b3a8ec0cd7.pdf").promise.then((pdf) => {
    //     pdf.getPage(1).then((page) => {
    //       const viewport = page.getViewport({ scale: 1 });
    //       setPageHeight(viewport.height);
    //     });
    //   });
    }
    const getApi=()=>{
        let authId=getAuthId();
        setLoader(true);
        let param={jobId:job?.id,authId:authId}
    getJobDataApi(param,(res)=>{
        setJobData(res);
        setTimeout(()=>{
            setLoader(false);
        },200)
        })
    }
    useEffect(()=>{
        getApi();
    },[])
   


    const copyLink=()=> { 
        var copyText = document.getElementById("jd-link");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
        
        var tooltip = document.getElementById("jd-Tooltip");
        console.log("clicked",tooltip,"copuied",copyText.value)
        tooltip.innerHTML = "Copied";
        // + copyText.value;
        // console.log("after innerHTML",tooltip)
      }
      
      const removeLink=()=>  {
        var tooltip = document.getElementById("jd-Tooltip");
        tooltip.innerHTML = "Copy to clipboard";
      } 
    const SaveHandler=()=>{
        let authId=getAuthId();
        let param={jobId:jobData?.jobId,save:!jobData?.saved,userId:authId}
        SaveJobApi(param,(res)=>{
            if(res){
                getApi();
            }
        })
    } 

    const downloadPDF = async (pdfUrl) => { 
        console.log('handlefiledownload')
        const xmlHttp = new XMLHttpRequest();
     xmlHttp.onreadystatechange = () => {
       if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
         const blobUrl = window.URL.createObjectURL(xmlHttp.response);
         const e = document.createElement('a');
         e.href = blobUrl;
         e.download = blobUrl.substr(blobUrl.lastIndexOf('/') + 1);
         document.body.appendChild(e);
         e.click();
         document.body.removeChild(e);
       }
     };
     xmlHttp.responseType = 'blob';
     xmlHttp.open('GET', pdfUrl, true);
     xmlHttp.send(null);
        // const link = document.createElement('a');
        // link.download = pdfUrl?.split('.com/')[1];
        // link.href = pdfUrl;
        // link.click();
        // window.open(pdfUrl,'__blank');
      };
  
        
       
    return (
       <>{applyForm===undefined?
        loader? <Row style={{height:'70vh',width:'50vw',display:'flex',justifyContent:'center',alignItems:'center'}}><Spin  size="large"></Spin></Row>:
       
        <div className={classes.applyJobWrapper}>
                <div className={classes.ApplyJobcontainer}>
                <div style={{display:'flex',justifyContent:'flex-start',}}>
                    <ButtonCompo title={'Back'} onClick={()=>{setJob();}}  prefixIcon={backIcon} style={{color:'#161616',font:'normal 600 18px/24px Lato',marginLeft:'-24px',}}></ButtonCompo>
                </div>
                        <div style={{display:'flex', flexDirection:'column',padding:"24px",backgroundColor:'#fff',borderRadius:5,gap:'16px'}}> 
                        <div >
                                <div style={{color:'#161616'}} className={classes.header2}>{jobData?.title}</div>
                            <div style={{display:'flex', flexDirection:'column',gap:8,marginTop:'8px'}}>
                                <div style={{display:'flex', flexDirection:'row'}}>
                                    <img src={ExpIcon} />
                                    <div style={{marginLeft:8,color:'#3d3d3d'}} className={classes.title2}>{jobData?.workExperience}</div>
                                </div>
                                <div style={{display:'flex', flexDirection:'row'}}>
                                    <img src={ClockIcon} />
                                    <div style={{marginLeft:8,color:'#3d3d3d'}} className={classes.title2}>{'Full time'}</div>
                                </div>
                                <div style={{display:'flex', flexDirection:'row'}}>
                                    <img src={LocIcon} />
                                    <Typography.Text ellipsis={{tooltip:true}}  className={classes.title2+" "+classes.locationText} style={{color:'#3d3d3d',}}>
                                        {jobData?.location?.map((loc,id)=>(<span>{loc?.name}{(jobData?.location?.length>1 && id<jobData?.location?.length-1)?',':''}</span>))}
                                    </Typography.Text>
                                </div>
                            </div>
                            </div>
                        <div style={{display:'flex',gap:'2rem'}} >
                        <ButtonCompo title={jobData?.disable?"Applied":"Apply now"} disabled={jobData?.disable} onClick={()=>{
                           if(!jobData?.disable){
                            setApplyForm(jobData)}
                            }} 
                            style={{backgroundColor:'#1089ff',color:'#fff',width:'100%',font:"normal normal 600 18px/22px Figtree"}} />   
                            {isLogin?<ButtonCompo disabled={jobData?.disable} title={jobData?.saved?'Saved':'Save'} onClick={()=>{
                                if(!jobData?.disable){
                                SaveHandler()}}} style={{border:'1px solid #cacaca',width:'100%',font: "normal normal 600 18px/22px Figtree"}}/> :<></>}            
                        </div>
                        
                        </div>

                        <div style={{backgroundColor:'#f4f4f4',flexDirection:'column',height:16}}></div>

                        <div  style={{backgroundColor:'white',}}>
                        <div className={classes.pdfContainer}  >
                            
                        <>   
                            <div className='jdPdf' style={{margin:'0 auto',minHeight:'300px',maxHeight:'600px'}}>
                            <Document file={jobData?.file} onLoadSuccess={onDocumentLoadSuccess} width="100%" height="100%" >
                                {Array.from(new Array(numPages), (el, index) => (
                                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                                ))}
                            </Document>
                            </div>
                            </> 
                            
                        </div>

                    <ButtonCompo title={" Download"} onClick={()=>{downloadPDF(jobData?.file);}} style={{color:'#1089ff',backgroundColor:'#fff',font: "normal normal 600 18px/22px Figtree"}} prefixIcon={DownloadIcon} />
                        </div>
                
                </div>
                <div className={classes.shareJob}>
                    <img src={share_job_icon}/>
                    <span>Share a job opportunity</span>
                    <span>If you know someone who would be a great fit, we invite you to share a job opportunity with them.</span>
                    <button onClick={()=>{
                        setIsModalOpen(true)
                        let authId=getAuthId();
                        let param;
                        if(authId){
                             param={jobId:job?.id,authId:authId}
                        }
                        else{
                             param={jobId:job?.id,authId:-1}
                        }
                        
                        referApi(param,(res)=>{
                            console.log('refered',res)
                        })
                        }}>Share</button>

                </div>
        </div>
        :
        <ApplyJobForm jobForm={applyForm} setJobForm={setApplyForm}/>}
        
        <ModalCompo 
        open={isModalOpen}
        onCancel={()=>{setIsModalOpen(false)}}
        closableRight={true}
        className={classes.ShareModal}
        >

            <div style={{width:'100%'}}>
                <div style={{color:'#161616',}} className={classes.header2}>{jobData?.title}</div>
                            <div style={{display:'flex', flexDirection:'column',gap:8,marginTop:'8px'}}>
                                <div style={{display:'flex', flexDirection:'row',alignItems:"center",marginTop:"0.8rem"}}>
                                    <img src={ExpIcon} />
                                    <div style={{marginLeft:8,color:'#3d3d3d'}} className={classes.title2}>{jobData?.workExperience}</div>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',alignItems:"center"}}>
                                    <img src={ClockIcon} />
                                    <div style={{marginLeft:8,color:'#3d3d3d'}} className={classes.title2}>{'Full time'}</div>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',alignItems:"center",gap:"0.4rem"}}>
                                    <img src={LocIcon} />
                                    <Typography.Text ellipsis={{tooltip:true}}  className={classes.title2+" "+classes.locationText} style={{color:'#3d3d3d',}}>
                                        {jobData?.location?.map((loc,id)=>(<span>{loc?.name}{(jobData?.location?.length>1 && id<jobData?.location?.length-1)?',':''}</span>))}
                                    </Typography.Text>
                                </div>
                            </div>

                                
        <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',background:'#FFF',border:'2px solid rgba(238, 238, 238, 1)',marginTop:'10px',}}>

                        <input style={{'color':'#002546',border:'none',width:width>700?'80%':"65%",padding:'3px', outline: 'none'}} id='jd-link'
                         value={ backendUrl+'/login?page=jobs&jobId='+jobData?.jobId} />
                      
                       <div className="tooltip">
                            <button onClick={copyLink} 
                            // onMouseOut={removeLink}
                             style={{'color':'#fff',border:'none',fontWeight:'700',cursor:'pointer',background:'rgba(0, 134, 255, 1)',padding:'0.5rem 1rem'}}>
                            <span className="tooltiptext" id="jd-Tooltip" >Copy to clipboard</span>
                            Copy Link
                            </button>
                            </div>
                   </div>
            
            </div>
        </ModalCompo>
       
        </>

        
    )
   
    
}

export default ApplyJob;

