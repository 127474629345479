import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header/main-header';
import { useWindowDimensions } from '../components/windowDimention/windowDimension';

const AuthLayout = () => {
  const {width}=useWindowDimensions()
  return (
    <div style={{display:"flex",flexDirection:"column",width:"100%",height:'100%',position:width>700?'fixed':'static'}}>
      <Header />
      <Outlet />
    </div>
  );
};

export default AuthLayout;