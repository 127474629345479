import React, { useEffect, useState } from "react";
import classes from './Associates.module.css';
import SearchCompo from "../../../design/SearchCompo";
import { DatePicker, Pagination, Select, Spin } from "antd";
import TableCustom from "../../../components/tableCustom/customTable";
import RecommendModal from "./RecommendModal";
import { useFPStore } from "../store";
import { UnnecessaryBackendSupport, getAssociateList } from "../Action";
import moment from "moment";
import { EyeOutlined } from "@ant-design/icons";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import Typography from "antd/es/typography/Typography";
import DeptRecommendModal from "../Department/DeptRecommendModal";
import { NoData } from "../../Training_essentialDocuments";
import user_icon from '../../../assets/Profile Icon.svg';

export const associatesTableColumn = {
    0: {
      cols: "Associates",
      width: "14%",
     
      dataKey: "associates",
      onclick: () => { }
    },
    1: {
      cols: "Contact Details",
      width: "18%",
      innerWidth:"5rem",
      dataKey: "contactDetails",
      onclick: () => { }
    },
    2: {
        cols: "Designation",
        width: "14%",
        innerWidth:"5rem",
        dataKey: "designation",
        onclick: () => { }
      },
    3: {
      cols: "Department",
      width: "14%",
      dataKey: "department",
      onclick: () => { }
    },
    4: {
        cols: "Team",
        width: "14%",
        dataKey: "team",
        onclick: () => { }
      },
      5: {
        cols: "Manager",
        width: "14%",
        dataKey: "manager",
        onclick: () => { }
      },
    6: {
      cols: "Recommendations",
      width: "18%",
      dataKey: "recommendations",
      onclick: () => { }
    },
  }
  export const  associatesData=(setShowModal,associateInfo,setModalData,width,screenType,)=>{

    console.log("ScreenWidth",width)
    
    return(
     
     
        width>700?associateInfo?.items?.map((item,index)=>{
            return  {
                    associates:
                    <div style={{marginLeft:"0.5rem",cursor:"pointer"}} onClick={()=>{
                      setModalData(item);
                        setShowModal({recommend:false, feedback:true});}}>
                        <div >
                          
                          <Typography.Text style={{width:"5rem"}} ellipsis={{tooltip:true}} >{item?.name}</Typography.Text>
                          </div>
                        <div className={classes.greyContent}>{item?.uid}</div>
                    </div>,
                    contactDetails:
                    <div onClick={()=>{ 
                      setModalData(item);
                       setShowModal({recommend:false, feedback:true});}}>
                        <div>
                          <Typography.Text style={{width:"5rem"}} ellipsis={{tooltip:true}}>{item?.email}</Typography.Text>
                          </div>
                        <div className={classes.greyContent}>{item?.phone}</div>
                    </div>,
                    designation:
                    <div onClick={()=>{  
                      setModalData(item);
                      setShowModal({recommend:false, feedback:true});}}>
                        <Typography.Text style={{width:"5rem"}} ellipsis={{tooltip:true}}> {item?.designation?item?.designation:"NA"}</Typography.Text>
                      </div>,
                    department:
                    <div onClick={()=>{
                      setModalData(item);
                        setShowModal({recommend:false, feedback:true});}}>
                        <Typography.Text style={{width:"5rem"}} ellipsis={{tooltip:true}}> {item?.teamDetails?.departmentName?item?.teamDetails?.departmentName:"NA"}</Typography.Text>
                      </div>,
                    team:
                    <div onClick={()=>{ 
                      setModalData(item);
                       setShowModal({recommend:false, feedback:true});}}> 
                        <Typography.Text style={{width:"5rem"}} ellipsis={{tooltip:true}}> {item?.teamDetails?.teamName?item?.teamDetails?.teamName:"NA"}</Typography.Text>                        
                      </div>,
                    manager:
                    <div onClick={()=>{ 
                      setModalData(item);
                       setShowModal({recommend:false, feedback:true});}}>
                      <Typography.Text style={{width:"5rem"}} ellipsis={{tooltip:true}}>  {item?.teamDetails?.managerName?item?.teamDetails?.managerName:"NA"}</Typography.Text>                    
                      </div>,
                    recommendations:
                    <>
                    {item?.remark==="NA"?<span style={{color:"#9B9B9B"}}>Not Provided</span>:<div 
                    style={{marginRight:"0.5rem"}}
                    className={classes.recommendations}
                    onClick={()=>{
                        setShowModal({feedback:false, recommend:true});
                        setModalData(item);
                        // if(screenType==="department")
                        // {
                        //   getAssociate();
                        // }
                    }}
                    >
                        <div>{"View Remarks"}</div>
                        <EyeOutlined/>
                    </div>}
                    </>
                 ,
                }
        
        })
        :       
        associateInfo?.items?.map((item,index)=>{
            return (
              <div  className={classes.mobCard} key={index} >
                    <div onClick={()=>{ 
                setModalData(item);
                 setShowModal({recommend:false, feedback:true});}}>
                        <div>associates:</div>
                        <div>
                        <img style={{height:"6vh",width:"6vh",borderRadius:"50%"}} src={item?.profileImage?item?.profileImage:user_icon}/>
                        <div>
                            {/* <div>
                              {item?.name?item?.name:"NA"}
                              </div> */}
                              <Typography.Text style={{width:"35vw",fontSize:"1.1rem",fontFamily:"Lato",padding:"0",}} ellipsis={{tooltip:true}}>{item?.name?item?.name:"NA"}</Typography.Text>
                            <div className={classes.greyContent}>{item?.uid?item?.uid:"NA"}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>contactDetails:</div>
                        <div>
                            
                            <Typography.Text style={{width:"40vw",fontSize:"1.1rem",fontFamily:"Lato",padding:"0",}} ellipsis={{tooltip:true}}>{item?.email?item?.email:"NA"}</Typography.Text>
                            <div className={classes.greyContent}>{item?.phone?item?.phone:"NA"}</div>
                        </div>
                    </div>
                    <div>
                        <div>designation:</div>
                        {/* <div>{item?.designation?item?.designation:"NA"}</div> */}
                        <Typography.Text style={{width:"50vw",fontSize:"1.1rem",fontFamily:"Lato",padding:"0",}} ellipsis={{tooltip:true}}>{item?.designation?item?.designation:"NA"}</Typography.Text>
                    </div>
                    <div>
                        <div>department:</div>
                        {/* <div>{item?.teamDetails?.departmentName?item?.teamDetails?.departmentName:"NA"}</div> */}
                        <Typography.Text style={{width:"50vw",fontSize:"1.1rem",fontFamily:"Lato",padding:"0",}} ellipsis={{tooltip:true}}>{item?.teamDetails?.departmentName?item?.teamDetails?.departmentName:"NA"}</Typography.Text>
                    </div>
                    <div>
                          <div>team:</div>
                          {/* <div>{item?.teamDetails?.teamName?item?.teamDetails?.teamName:"NA"}</div> */}
                          <Typography.Text style={{width:"60vw",fontSize:"1.1rem",fontFamily:"Lato",padding:"0",}} ellipsis={{tooltip:true}}>{item?.teamDetails?.teamName?item?.teamDetails?.teamName:"NA"}</Typography.Text>
                          
                    </div>
                    <div>
                        <div>manager:</div>
                        {/* <div>{item?.teamDetails?.managerName?item?.teamDetails?.managerName:"NA"}</div> */}
                        <Typography.Text style={{width:"55vw",fontSize:"1.1rem",fontFamily:"Lato",padding:"0",}} ellipsis={{tooltip:true}}>{item?.teamDetails?.managerName?item?.teamDetails?.managerName:"NA"}</Typography.Text>
                    </div>
                    <div>
                        <div>recommendations:</div>
                          {(item?.remark==="NA"||item?.remark?.answer==="NA"||item?.remark?.answer==="N/A"||item?.remark?.answer.trim()==="")?<div>Not Provided</div>:<div 
                          className={classes.recommendations}
                          style={{zIndex:"100 !important"}}
                          onClick={()=>{
                           
                            setShowModal({feedback:false, recommend:true});
                              setModalData(item);                        
                          }}
                          >
                              <div>{screenType==="department"?"View":"View Remarks"}</div>
                              <EyeOutlined/>
                        </div>}
                 </div>
                 </div>
            )
        
        })
        )              
  }

const FPAssociates=()=>{
   const {loader,setPageSize,setPaginationPage,pageSize,paginationPage,setLoader,
    showModal,setShowModal,
    associateInfoHandler,
    associateInfo,
    tableLoader,
    tableLoaderHandler
}=useFPStore();
const [dateMonth,setDateMonth]=useState(moment().format('YYYY-MM'));
const [selectedDept,setSelectedDept]=useState("");
const [modalData,setModalData]=useState();
const {width}=useWindowDimensions();
const [search,setSearch]=useState('');

// const [search,set]
    
    const {Option}=Select;
    console.log("showModal123",showModal)

    
useEffect(()=>{
  tableLoaderHandler(true);
    getAssociateList((res)=>{
      associateInfoHandler(res);
      tableLoaderHandler(false);
    },search?1:paginationPage,pageSize,selectedDept,search,dateMonth);
},[pageSize,paginationPage,selectedDept,search,dateMonth]);

useEffect(()=>{
  UnnecessaryBackendSupport();
},[])
  
    return(
        <div className={classes.associate}>
            <header className={classes.header}>
                <h3> Associates</h3>
                <div>
                    <SearchCompo setSearch={setSearch} style={{height:"2rem",border:"1px solid #dfdfdf"}}/>
                    <DatePicker placeholder={`${moment().format('YYYY-MM')}`} picker="month" onChange={(date,datestring)=>{
                        if(datestring)
                        {
                            setDateMonth(datestring)
                        }
                        else
                        {
                            setDateMonth(moment().format('YYYY-MM'));
                        }
                       
                        }}/>
                </div>
            </header>

            <section children className={classes.content}>
            
                    {width>700?
                    <>
                    {/* <TableCustom 
                    columns={associatesTableColumn}
                     dataSource={associatesData(setShowModal,associateInfo,setModalData,width)} 
                     loading={tableLoader}                 
                     pagination={
                        {
                        total: parseInt(associateInfo?.meta?.totalItems?associateInfo?.meta?.totalItems:0),
                        current: parseInt(paginationPage),
                        showSizeChanger: true,
                        pageSizeOptions: [10,20, 30, 50, 100],
                        pageSize: pageSize,                
                        onChange: (page,pageSize) =>{
                            setPaginationPage(page);
                            setPageSize(pageSize);
                        }, 
                        }} /> */}

                          <div className={classes.tableHeader}>
                                <span>Associates</span>
                                <span>Contact Details</span>
                                <span>Designation</span>
                                <span>Department</span>
                                <span>Team</span>
                                <span>Manager</span>
                                <span>Recommendations</span>                     
                          </div>
                          {tableLoader?<Spin style={{display:"flex",alignItems:"center",justifyContent:"center",height:"67vh"}}></Spin>:<div className={classes.rowContainer}>
                          {
                             associateInfo?.items?.length===0?
                             <NoData/>
                             :
                          associateInfo?.items?.map((item,index)=>{
                              return  (
                                <div className={classes.eachRow}>
                                     
                                      <div style={{cursor:"pointer"}}  onClick={()=>{
                                        setModalData(item);
                                          setShowModal({recommend:false, feedback:true});}}>
                                          
                                              <img style={{height:"4vh",width:"4vh",borderRadius:"50%"}} src={item?.profileImage?item?.profileImage:user_icon}/>
                                              <div>
                                          <div>                                         
                                            <Typography.Text style={{width:"5vw",}} ellipsis={{tooltip:true}} >{item?.name}</Typography.Text>
                                            </div>
                                          <div className={classes.greyContent}>{item?.uid}</div>
                                          </div>
                                      </div>
                                     
                                      <div onClick={()=>{ 
                                        setModalData(item);
                                        setShowModal({recommend:false, feedback:true});}}>
                                          <div>
                                            <Typography.Text style={{width:"8vw",}} ellipsis={{tooltip:true}}>{item?.email}</Typography.Text>
                                            </div>
                                          <div className={classes.greyContent}>{item?.phone}</div>
                                      </div>
                                     
                                      <div onClick={()=>{  
                                        setModalData(item);
                                        setShowModal({recommend:false, feedback:true});}}>
                                          <Typography.Text style={{width:"8vw",}} ellipsis={{tooltip:true}}> {item?.designation?item?.designation:"NA"}</Typography.Text>
                                        </div>
                                     
                                      <div onClick={()=>{
                                        setModalData(item);
                                          setShowModal({recommend:false, feedback:true});}}>
                                          <Typography.Text style={{width:"8vw",}} ellipsis={{tooltip:true}}> {item?.teamDetails?.departmentName?item?.teamDetails?.departmentName:"NA"}</Typography.Text>
                                        </div>
                                     
                                      <div onClick={()=>{ 
                                        setModalData(item);
                                        setShowModal({recommend:false, feedback:true});}}> 
                                          <Typography.Text style={{width:"8vw",}} ellipsis={{tooltip:true}}> {item?.teamDetails?.teamName?item?.teamDetails?.teamName:"NA"}</Typography.Text>                        
                                        </div>
                                    
                                      <div onClick={()=>{ 
                                        setModalData(item);
                                        setShowModal({recommend:false, feedback:true});}}>
                                        <Typography.Text style={{width:"8vw",}} ellipsis={{tooltip:true}}>  {item?.teamDetails?.managerName?item?.teamDetails?.managerName:"NA"}</Typography.Text>                    
                                        </div>
                                    
                                      <>
                                      {(item?.remark==="NA"||item?.remark?.answer==="NA"||item?.remark?.answer==="N/A"||item?.remark?.answer.trim()==="")?<span style={{color:"#9B9B9B",fontSize:"0.9rem",fontWeight:"500"}}>Not Provided</span>:<div 
                                      style={{marginRight:"0.5rem"}}
                                      className={classes.recommendations}
                                      onClick={()=>{
                                          setShowModal({feedback:false, recommend:true});
                                          setModalData(item);
                                         
                                          // if(screenType==="department")
                                          // {
                                          //   getAssociate();
                                          // }
                                      }}
                                      >
                                          <div>{"View Remarks"}</div>
                                          <EyeOutlined/>
                                      </div>}
                                      </>
                                      </div>
                                  
                          )
        
                            })}
                          </div>}
                          <Pagination position="bottomRight" size="middle" style={{display:"flex",justifyContent:"flex-end",marginTop:"0.5rem",marginRight:"1rem"}}
                          current={parseInt(paginationPage)} showSizeChanger={true} 
                          total={parseInt(associateInfo?.meta?.totalItems?associateInfo?.meta?.totalItems:0)}
                          pageSize={pageSize} 
                          onChange={(page,pageSize)=>{
                            setPaginationPage(page);
                            setPageSize(pageSize);
                          }}
                          onShowSizeChange={(page,pageSize)=>{
                            setPaginationPage(page);
                            setPageSize(pageSize);
                          }} 
                          pageSizeOptions={[10,20,30,50,100,200]}
                          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} />
                        </>            
                        :                                    
                      <div className={classes.mobContainer}>                      
                      {associatesData(setShowModal,associateInfo,setModalData,width)}                  
                      {associateInfo?.items?.length>0&&<Pagination position="bottomRight" size="middle" style={{display:"flex",justifyContent:"flex-end",marginTop:"0.5rem",marginRight:"1rem"}}
                          current={paginationPage} showSizeChanger={true} 
                          total= {parseInt(associateInfo?.meta?.totalItems?associateInfo?.meta?.totalItems:0)}
                           pageSize={pageSize} 
                          onChange={(page,pageSize)=>{
                            setPaginationPage(page);
                            setPageSize(pageSize);
                          }}
                          onShowSizeChange={(page,pageSize)=>{
                            setPaginationPage(page);
                            setPageSize(pageSize);
                          }} 
                          pageSizeOptions={[10,20,30,50,100,200]}
                          showTotal={(total, range) =>width>800? `${range[0]}-${range[1]} of ${total} items`:""} />}     
                      </div>}                 
            </section>
            {showModal?.feedback&&<DeptRecommendModal
                showModal={showModal}
                dateMonth={dateMonth}
                setDateMonth={setDateMonth}
                data={modalData}
                setShowModal={setShowModal}
                />}
            {showModal?.recommend&&<RecommendModal 
            modalData={modalData}
            showModal={showModal}
            setShowModal={setShowModal}
            dateMonth={dateMonth}
            />}
           
        </div>
    )
}
export default FPAssociates;