import Api from "../../../services/Api";

export const getUserApi=(params,callback)=>{

    Api.get("/attendance-users/getusers").params(params).send((res)=>{
        if(res!==undefined){
            callback(res)
        }
    })
}
export const getUserListApi=(params,callback)=>{

    Api.get("/attendance-users/supportApi").params(params).send((res)=>{
        if(res!==undefined){
            callback(res)
        }
    })
}

export  const removeWebAccessApi=(params,callback)=>{

    Api.patch("/attendance-users/updateWebAccess").params(params).send((res)=>{
        if(res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        } 
    })
}
export  const removeTempLocationApi=(userId,callback)=>{

    Api.patch(`/attendance-users/removeTempAccess?userId=${userId}`).send((res)=>{
        if(res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        } 
    })
}
export const giveWebAccessApi=(params,callback)=>{
    Api.patch("/attendance-users/updateWebAccess").params(params).send((res)=>{
        if(res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        } 
    })
}
export const locationAccessApi=(params,callback)=>{
    Api.patch('/attendance-users/locationAccessInterface').params(params).send((res)=>{
        if(res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        } 
    })
}
export const tempLocationAccessApi=(params,callback)=>{
    Api.patch("/attendance-users/updateUserTempLocationAccess").params(params).send((res)=>{
        if(res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        } 
    })
}

export const locationHistory=(params,callback,url="/attendance-users/locationHistory")=>{
    Api.get(url).params(params).send((res)=>{
        if(res){
            callback(res)
        } 
    })
}