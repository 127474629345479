import React, { useEffect, useState } from "react";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useNotificationStore } from "./store";

const AutoCompleteGeofence = ({onSelect,setEdit}) =>{
    const [value, setValue] = useState('')
    const {addCityName,SetaddCityName} = useNotificationStore();

    useEffect(()=>{
      SetaddCityName(value?.label)
      
    },[value])

    
    
return(
    <>    
            <GooglePlacesAutocomplete
            style={{width:"10%"}}
             selectProps={{
                    value,
                    onChange: setValue,
                    placeholder:setEdit?'Select City':'Search city',
                }}
                apiKey="AIzaSyCfVOeSxREHxv3LqvV6eLBlROKwV-64gKQ"
                onPress={(data)=>{console.log("ssss-098765432345678",data,);}}
            />
    </>
)
}

export default AutoCompleteGeofence;