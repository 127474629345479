

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { AiFillGithub } from "react-icons/ai";
import { IoVideocam } from "react-icons/io5";
import { FaMicrophone } from "react-icons/fa6";
import { RiPassPendingFill } from "react-icons/ri";

import { Tooltip } from 'antd';
import { MdPeople, MdCallEnd } from "react-icons/md";
import VideoView from './videoView';
import BrigoshaMedia from './media';
import { EventRegister } from 'react-native-event-listeners';
import { HiVideoCameraSlash } from "react-icons/hi2";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { IoMicOff } from "react-icons/io5";
import ChatScreen from './chat';
// import AskToJoinModal from './askToJoin';
import conferenceStore from './store';
import NotFound from '../NoData/noData';
import { useNavigate } from 'react-router-dom';
import Participates from './participates';
import { setUpChatSocket } from './utils';
import ChatClient from './clientChat';
import { postUserJoined } from './action';
import Info from './infomodal';
import { Modal, message } from "antd";
import moment from 'moment';
import chatIcon from '../../assets/chatcon.svg'
import Participents from '../../assets/participateicon.svg'
import stopsharing from '../../assets/stopsharing.svg'
import sharescreen from '../../assets/sharescreen.svg'
import PinDisabled from "../../assets/pin-disabled.svg"
import PinEnabled from "../../assets/pin-enabled.svg"
import Notification from '../../services/Notification';
import "./conference.css"


import ParticipantsJoinDetails from './participantJoinModal';
import RoomClient from './roomClient';
import { setUpRoomClientSocket } from './utils';
import PendingApproval from './pendingApproval';


let roomClientAulasRecording = null
let broadcastHealthTimer = null


const playBeepSound = () => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  
    // Create an oscillator (the source of sound)
    const oscillator = audioContext.createOscillator();
    const gainNode = audioContext.createGain();
  
    // Set the waveform type to 'sine' for a smooth, pleasant tone
    oscillator.type = 'sine';
  
    // Set the frequency to a higher pitch, similar to an iPhone notification
    oscillator.frequency.setValueAtTime(1200, audioContext.currentTime); // 1200Hz is a good starting point
  
    // Control the volume (gain node)
    gainNode.gain.setValueAtTime(0.3, audioContext.currentTime); // Start with medium volume
  
    // Gradually fade the sound out to mimic an iPhone notification
    gainNode.gain.exponentialRampToValueAtTime(0.001, audioContext.currentTime + 0.5);
  
    // Connect the oscillator to the gain node (volume control) and then to the speakers
    oscillator.connect(gainNode);
    gainNode.connect(audioContext.destination);
  
    // Start the oscillator and stop it after 0.5 seconds for a short notification-like sound
    oscillator.start();
    oscillator.stop(audioContext.currentTime + 0.5);
  };
  



const IconButton = ({ children, onClick, active, title, noOfPeople = false, count, mssgRead }) => {
 
    // const handleClick = () => {
    //   onClick
    // };
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div
                onClick={onClick}
                style={{
                    padding: '1%',
                    height: 36,
                    width: 36,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 18,
                    border: '1.5px solid #FFFFFF',
                    backgroundColor: active ? '#FC5C65' : 'transparent',
                    position: "relative",
                    cursor: "pointer"
                }}
            >
                {children}
            </div>
            {
                noOfPeople ?
                    <div style={{ height: 20, width: 20, backgroundColor: "white", position: "absolute", display: "flex", marginLeft: 20, marginTop: -10, borderRadius: 10, alignItems: "center", justifyContent: "center", color: "#161616", fontSize: 12 }}>
                        {count}
                    </div>
                    :
                    null
            }
            {
                mssgRead ?
                    <div style={{ height: 20, width: 20, backgroundColor: "#FD9644", position: "absolute", display: "flex", marginLeft: 20, marginTop: -10, borderRadius: 10, alignItems: "center", justifyContent: "center", color: "#161616", fontSize: 12 }}>

                    </div>
                    :
                    null
            }
            <div style={{ color: "white", fontSize: 14, fontWeight: 600, marginTop: 10 }}>
                {title}
            </div>
        </div>
    );
};

const VideoHeader = ({ roundDetails, onClickI, recordingStatus, setRecModal, userG,localMicStreamRef,pendingApprovals,recordingStatusRef }) => {
    // console.log(roundDetails,"roundDetailsroundDetails");
    const [min,setMin]=useState(0)
    const [sec,setSec]=useState(0)
    useEffect(() =>{
        if((userG?.email===roundDetails?.hostEmail || userG?.role?.includes("Admin")) &&recordingStatus===true){
            Notification.success("Your meet is being recorded")
        }
           
        let timer= null
        if(recordingStatus){
            
   timer =setInterval(() =>{
    setSec(prevSec => {
        if (prevSec >= 60) {
          setMin(prevMin => prevMin + 1);
          return 0;
        }
        return prevSec + 1;
      });
      },1000)
    }
    else{
        setMin(0)
        setSec(0)
    }

      return () =>clearInterval(timer)
    },[recordingStatus])
    const formatTime = time => String(time).padStart(2, '0');
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h4 style={{ color: '#FFFFFF',fontWeight:500 }}>{roundDetails?.info}</h4>
                <div></div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", gap: "0.5rem" }}>
                    {
                        <div style={{ color: "white", fontSize: 18, flexDirection: "row", display: "flex", borderRadius: 8, padding: 4, fontStyle: "oblique", cursor:userG?.email === roundDetails?.hostEmail? "pointer":"default" }} onClick={() => {
                            if(!localMicStreamRef.current){
                                Notification.error("local Mic stream not captured")
                                return
                            }
                            if ((userG?.email === roundDetails?.hostEmail || userG?.role?.includes("Admin")) && recordingStatus === false) {
                                setRecModal(true)
                            }
                        }}> {(userG?.email === roundDetails?.hostEmail || userG?.role?.includes("Admin")) ? "Rec" : ""} {recordingStatus &&<div style={{display:"flex",flexDirection:"row"}}> <div style={{ height: 14, width: 14, borderRadius: 7, backgroundColor: "red", marginLeft: 6, paddingTop: 4 }}></div><div style={{fontWeight:200,}}>Meeting is getting recorded</div>{recordingStatusRef.current===true &&<div style={{marginLeft:4}}>{formatTime(min)}:{formatTime(sec)}</div>}</div> }</div>}
                         {roundDetails?.hostEmail===userG?.email&&<div onClick={() => { onClickI("pending") }}>
                        <Tooltip title="Pending Request">
                        <RiPassPendingFill style={{ cursor: "pointer", height: 20, width: 20, color: "white" }} />
                        </Tooltip>
                        {/* <span class="tooltip-text">This is a custom tooltip</span> */}
                        {pendingApprovals.length>0&&<div style={{backgroundColor:"orange",height:10,width:10,borderRadius:5,position:"absolute",top:8,right:40}}></div>}
                    </div>}
                    <div onClick={() => { onClickI() }}>
                        <IoMdInformationCircleOutline style={{ cursor: "pointer", height: 20, width: 20, color: "white" }} />
                    </div>
                   
                    {/* <div style={{ color: "red", width: "4rem", height: "2rem", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#000000A3", fontSize: 12, borderRadius: 10 }}>REC ⦾</div> */}
                </div>
            </div>
        </>
    )
}

export const ImageHandler = ({ image, name, size }) => {
    const nameD = name?.substring(0, 2).toUpperCase();
    return (
        <>
            {
                image ?
                    <img src={image} style={{ height: 25, width: 25, borderRadius: 15 }} />
                    :
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: size === "small" ? 25 : 60, width: size === "small" ? 25 : 60, backgroundColor: "grey", color: "#2D2D2D", fontWeight: 800, fontSize: size === "small" ? 10 : 17, borderRadius: size === "small" ? 15 : 30 }}>
                        {nameD}
                    </div>
            }
        </>
    )
}



const Room = () => {
    const localVideoRef = useRef(null);
    const recordingStatusRef = useRef(null);
    const hostUserRecIdRef=useRef(null)
    const insideUserRef=useRef(null)
    const activeMicref=useRef({})
    const chatModalRef=useRef(false)
    const remoteAudioRef=useRef([])
   const  remoteMediaRef=useRef([])
    const [triggerPeople,setTriggerPeople]=useState([])
    // const [localStream,setLocalStreams]=useState(null)
    const {totalMicStreams,setChatSocketConnected,storeJoinedUser,joinedUser,activeMicList, firstTimeMic, updateFirstTimeMic, updateMediaReady, approveEvent, activeUserList, updateUserLeft, updateActiveMicList, mssgRead, updateMssgsentRed, isMicOn, setIsMicOn, isCameraOn, setIsCameraOn, RequestedUser, mssgToken, updateRequestedUser, share, setMeetView, setshare, userG, meetIdG, setChatClient, updateGroupChat, mssgSent, updateMssgsent, updateActiveUser, activeUsers, chatClient, roundDetails, meetView } = conferenceStore(state => state);
    const [chat, setchat] = useState(false);
    const [people, setpeople] = useState(false);
    const brigoshaMedia = new BrigoshaMedia();
    const [remoteVideo, setRemoteVideo] = useState([]);
    const [onlyScreenArr,setOnlyScreen]=useState({})
    const navigate = useNavigate();

    const [info, setInfo] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [localMicStream, setLocalMicStream] = useState(null);
    const [pinScreen, setPinScreen] = useState(0)
    const [streams, setStreams] = useState([]);
    const [localMediaval, setLocal] = useState()
    const [localScreenval, setScreenLocal] = useState()
    const remoteData = useRef([])
    const [totalMicStream,setTotMicStream]=useState()
    const [remoteStreamMic,setRemStreamMic]=useState(null)
    const [userLeftJoined, setuserLeftJoined] = useState("")
    const [recordingStatus, setRecordingStatus] = useState(false)
    const [recModal, setRecModal] = useState(false)
    const [startrec, setStartrec] = useState(false)
    const [requesteduserArray, setUserArray] = useState([])
    const [pendingApprovals,setPendingApproval]=useState([])
    const [pendingModal,setPendingModal]=useState(false)
    const [insideChatUserList,setInsChatUserList]=useState([])
    // const [hostUserId,setHostUserId]=useState()
    const [micList,setMicList]=useState({})
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const onShareScreenClick = () => {
        if (share) {
            setshare(false);

            brigoshaMedia?.closeProducer('screenType');
            if (pinScreen == -1) {
                setPinScreen(0)
            }

            // onRemoteStreamRcv()
        } else {
            brigoshaMedia.produce('screenType');
            setshare(true);

            setPinScreen(-1)
        }

    }

    const onCameraClick = () => {
       
        if (isCameraOn) {
            setIsCameraOn(false);
            brigoshaMedia?.closeProducer('videoType');
        } else {
            setIsCameraOn(true);
            brigoshaMedia.produce('videoType');
        }
    }



    useEffect(() => {
        
       forceUpdate()
     
     
  
             setTimeout(() =>{
                setshare(false)
                brigoshaMedia.produce("audioType")
            if (isCameraOn) {
                brigoshaMedia.produce("videoType")
            }
           
           
             },1300)

    }, [])
    
    const localMicStreamRef=useRef(null)
    useEffect(() =>{
        
        if(localMicStream){
        // if ((userG?.email === roundDetails?.hostEmail && userG?.role?.includes("Panelist")) || userG?.role?.includes("Admin") &&recordingStatus===false) {
        //     setRecModal(true)
        // }
        if(firstTimeMic){
            updateFirstTimeMic(false)    
        }
        if(!isMicOn){
            brigoshaMedia.pauseProducer("audioType")
        }
    }
  
  
   
   
    },[localMicStream])
    const chatClientRef = useRef(null);

    const clientChatCallback = (e) => {
       
console.log("evv",e);
   
       if(e.event==="getUser"){
        setPendingApproval(e?.data?.message)

       }
        if (e?.data?.type === "record") {
            console.log("hostt",e?.data);
            hostUserRecIdRef.current=e?.data?.hostUserId
        //    updateHostRecId(e?.data?.userId)
            if (e?.data?.data?.record === true) {
               
                setRecordingStatus(true)
                
            }
            else if (e?.data?.data?.record === false) {
                setRecordingStatus(false)
                recordingStatusRef.current=false
                
                // Notification.error("recording stopped")
            }

        }
      
        if (e?.data?.type === "mic") {
     
          if(e?.data?.userId!==userG?.id){
           
            if (e?.data?.data?.mic === true) {
                
                 setMicList({ id: e?.data?.userId, mic: true })
            }
            else {
               
                setMicList({ id: e?.data?.userId, mic: false })
             
            }
        }
        

        }
        if (e?.event === "userList") {
            insideUserRef.current=e?.data
            setInsChatUserList(e?.data)
           
            updateActiveUser(e?.data)
        }
        
        if (e?.data?.askToJoin  /* (roundDetails?.hostEmail === userG?.hiringUser?.email) */) {
            if (approveEvent && ((userG?.role?.includes("Panelist") &&userG?.email==roundDetails?.hostEmail)||userG?.role?.includes("Admin")) ) {
                setModalVisible(true)
            }
           
            
            updateRequestedUser(e?.data)
        } else if ((e?.data?.data) && (e?.data?.type === "Text" || e?.data?.type === "Image")) {
          if(!chatModalRef.current){
            console.log("entered chat");
            playBeepSound()
          }
            if (chat) {
                console.log("entered chat1");
                updateMssgsentRed(false)
            }
            if (e?.data?.userId !== userG?.id) {
                console.log("entered chat2");
                updateMssgsentRed(true)

            }
            else {
                console.log("entered chat3");
                updateMssgsentRed(false)
            }

            // groupChat.filter(ele =>moment(ele?.sentOn,"HH:mm:ss A").diff(moment(value[0]?.joinedTime,"HH:mm:ss A")))
            updateGroupChat(e)

        }
    }
    const getClient = useCallback(() => {
      
        return new ChatClient(setUpChatSocket(setChatSocketConnected), userG?.id, meetIdG, userG, userG?.name, "", `${userG?.email}`, "", "2", "4cb2509d-70f5-435e-8792-d24937743b53", "test.aulas.in", clientChatCallback)
    }, [])

useEffect(() =>{
  console.log("active<icList",activeMicList);
},[activeMicList])

    // useEffect(() => {
    //     chatClientRef.current = getClient()
    //    chatClientRef.current.getUsers(meetIdG,userG?.id)
    //     setChatClient(chatClientRef.current)
    //     chatClientRef?.current?.sendGroupChat(userG?.id, meetIdG,
    //         {
    //             type: 'mic',
    //             data:{mic:isMicOn},
    //             userId: userG?.id,
    //             roomId: meetIdG,
    //             userName: userG?.name,
    //             token: mssgToken,
    //             // sentOn: time
    //         }
    //     )
    //     updateGroupChat([])
    //     const handleBeforeUnload = (event) => {

            


    //         // Perform any additional cleanup
    //         onUnmount();

    //         return ""

    //         // const message = "Are you sure you want to leave?";
    //         // event.returnValue = message; // Gecko, Trident, Chrome 34+

    //     };

    //     const handleLeaveButtonClick = () => {

    //         onUnmount();
    //         // window.location.reload();
    //     };
    //     window.addEventListener('beforeunload', handleBeforeUnload);
    //     window.addEventListener('unload', () => {

    //         const leaveButton = document.querySelector('.your-leave-button-class');
    //         if (leaveButton) {
    //             leaveButton.addEventListener('click', handleLeaveButtonClick);
    //         }
    //     });

    //     return () => {


    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //     };
    // }, [])



    const onRemoteStreamRcv = (data) => {
  
        if (data?.length === 0) {
            setRemoteVideo([])

            return
        }
        
            console.log("eventRegister",data,data.filter(item =>item?.audio!==null),remoteMediaRef.current.length);
         
        
      
        let temp = 0;
      
        let obj = {}
        let mediaScreenArr = []

        temp = remoteData.current.length;
       
        let streamclone=null
        // let dataMicList={...activeMicList}
    
        data?.forEach((item, index) => {
            
           if(item.audio){
            console.log("remoteAudio",brigoshaMedia.getConsumerData())
            const mediaStreamAudio=item.audio?.stream
            if(streamclone===null){
           
                streamclone=mediaStreamAudio
            
            }
            else{
              streamclone=streamclone.clone()
               streamclone?.addTrack(mediaStreamAudio?.getAudioTracks()[0])
              
            }
            // console.log("localStreamsAdd",localStreams,streamclone);
           }
            if (item.video && item.screen && item.audio) {
                 
                const mediaStreamVideo = new MediaStream([
                    item.video?.stream.getVideoTracks()[0],
                    item.audio?.stream.getAudioTracks()[0],
                ]);
                const mediaStreamScreen = new MediaStream([
                    item.screen?.stream.getVideoTracks()[0],
                    item.audio?.stream.getAudioTracks()[0],
                ])
               
                mediaScreenArr.push({ mediaStream: mediaStreamScreen, user: item.user, type: "screenType" })
                // if (remoteVideoRef.current && remoteVideoRef.current.length && remoteVideoRef.current[temp]) {
                //     remoteVideoRef.current[temp].srcObject = mediaStreamScreen
                // }
                temp += 1
             
                // userNamesArray.push(item.userName);
                mediaScreenArr.push({ mediaStream: mediaStreamVideo, user: item?.user, type: "videoType", })
                // if (remoteVideoRef.current && remoteVideoRef.current.length && remoteVideoRef.current[temp]) {
                //     remoteVideoRef.current[temp].srcObject = mediaStreamVideo
                // }
                temp += 1
                obj[item?.user?.id]=true
                // arr.push(item?.user?.id)
            }
            else if (item.video && item.screen) {
                const mediaStreamVideo = new MediaStream([
                    item.video?.stream.getVideoTracks()[0],
                    // item.audio?.stream.getAudioTracks()[0],
                ]);
                const mediaStreamScreen = new MediaStream([
                    item.screen?.stream.getVideoTracks()[0],
                    // item.audio?.stream.getAudioTracks()[0],
                ])

                // if (remoteVideoRef.current && remoteVideoRef.current.length && remoteVideoRef.current[temp]) {
                //     console.log(" income entering screen")
                //     remoteVideoRef.current[temp].srcObject = mediaStreamScreen
                // }
                mediaScreenArr.push({ mediaStream: mediaStreamScreen, user: item?.user, type: "screenType" })
                temp += 1

                // userNamesArray.push(item.userName);
                // if (remoteVideoRef.current && remoteVideoRef.current.length && remoteVideoRef.current[temp]) {
                //     console.log(" income entering video")
                //     remoteVideoRef.current[temp].srcObject = mediaStreamVideo
                // }
                mediaScreenArr.push({ mediaStream: mediaStreamVideo, user: item?.user, type: "videoType" })
                temp += 1
                obj[item?.user?.id]=true
               
                
            }
            else if (item.screen && item.audio) {
            
                const mediaStream = new MediaStream([
                    item.screen?.stream.getVideoTracks()[0],
                    item.audio?.stream.getAudioTracks()[0],
                ]);
                // if (remoteVideoRef.current && remoteVideoRef.current.length && remoteVideoRef.current[temp]) {
                //     remoteVideoRef.current[temp].srcObject = mediaStream
                // }
                mediaScreenArr.push({ mediaStream: mediaStream, user: item?.user, type: "screenType", })
                temp += 1
                obj[item?.user?.id]=false
                // userNamesArray.push(item.userName);
            }
            else if (item.video && item.audio) {
               
                const mediaStream = new MediaStream([
                    item.video?.stream.getVideoTracks()[0],
                    item.audio?.stream.getAudioTracks()[0],
                ]);
                
                // if (remoteVideoRef.current && remoteVideoRef.current.length && remoteVideoRef.current[temp]) {
                //     remoteVideoRef.current[temp].srcObject = mediaStream
                // }
                mediaScreenArr.push({ mediaStream: mediaStream, user: item?.user, type: "videoType", })
                temp += 1
                obj[item?.user?.id]=true
                // userNamesArray.push(item.userName);
            } else if (item.video) {
                

                // if (remoteVideoRef.current && remoteVideoRef.current.length && remoteVideoRef.current[temp]) {
                //     console.log('incoming tringgger video',item)
                //     remoteVideoRef.current[temp].srcObject = item.video.stream
                // }
                mediaScreenArr.push({ mediaStream: item.video.stream, user: item?.user, type: "videoType" })
                temp += 1
                obj[item?.user?.id]=true
                // userNamesArray.push(item.userName);
            } else if (item.audio) {
                // if (remoteVideoRef.current && remoteVideoRef.current.length && remoteVideoRef.current[temp]) {
                //     remoteVideoRef.current[temp].srcObject = item.audio.stream
                // }
                mediaScreenArr.push({ mediaStream: item.audio.stream, user: item?.user, type: "audioType" })
                temp += 1
                temp += 1
                obj[item?.user?.id]=true

                // userNamesArray.push(item.userName);
            }
            else if (item.screen) {
               
                // arr.push("screenType")
                mediaScreenArr.push({ mediaStream: item.screen.stream, user: item?.user, type: "screenType" })
                temp += 1
                obj[item?.user?.id]=false
            }

        })
       
         
       
        setOnlyScreen(obj)
        
        setRemoteVideo(mediaScreenArr)

        console.log("mergingstreams3",streamclone);
         setRemStreamMic(streamclone)
         try{
            //  if(data.filter(item =>item?.audio!==null).length!==remoteMediaRef.current.length) { 
          brigoshaMedia.request({event:"producerMuteStates",data:{roomId:meetIdG}})
            //  }
            }
            catch(err){
                console.log("error1",err);
            }
      
    
     

        // setStreamTypes(arr)
        // setUSerNameArr(userNamesArray)
        // console.log("===>incoming outgoing remote data",remoteVideoRef.current);

    };
    const totalMicStreamsRef = useRef(totalMicStreams);
  useEffect(() =>{

    totalMicStreamsRef.current=totalMicStream
    if(recordingStatusRef.current){
    roomClientAulasRecording?.replaceAudioTracks(totalMicStreamsRef.current)
    }
 

  },[totalMicStream])

useEffect(() =>{
    console.log("local Stream",localMicStream?.getAudioTracks()[0]);
    if(localMicStream){
  
      
        if(remoteStreamMic){
            let streamClone = remoteStreamMic.clone();
        
       
            streamClone?.addTrack(localMicStreamRef.current?.getAudioTracks()[0]); // Add the track to the cloned stream
            
            setTotMicStream(streamClone); 
       
        }
        else{
       
            setTotMicStream(localMicStream)
        }
   
        }
        else{
            setTotMicStream(remoteStreamMic) 
        }
},[localMicStream,remoteStreamMic])
    useEffect(() => {

        const updateStreams = () => {
          
            remoteVideo.forEach(video => {
              
                // obj[`${video?.user?.id}_${video?.type}`] = {
                //     remote: true,
                //     type: video?.type
                // };
                if (video?.user?.id === pinScreen?.id &&(pinScreen?.type === undefined ||pinScreen?.type==="audioType")) {
                    setPinScreen({ id: pinScreen?.id, type: video?.type });
                }
            });

            const arrs = activeUserList.filter(ele =>
                onlyScreenArr[ele?.id]!==true&&
                ele?.id !== 0 &&
                ele?.id !== userG?.id
            );


            const newStreams = remoteVideo.concat(arrs);
        console.log("newStreams",remoteVideo,activeUserList);
            setStreams(newStreams)

            if(pinScreen?.type==="videoType" &&newStreams.filter(ele =>(ele?.user?.id==pinScreen?.id ||ele?.id===pinScreen?.id)&&(ele?.type!=="videoType")).length>=1){
                if(newStreams.filter(ele =>(ele?.user?.id==pinScreen?.id &&ele?.type==="videoType" )).length==1){

                }
                else{
                setPinScreen({ id: pinScreen?.id, type:newStreams.filter(ele =>(ele?.user?.id==pinScreen?.id ||ele?.id===pinScreen?.id)&&(ele?.type!=="videoType"))[0]?.type==="audioType"?"audioType": undefined })
                }
            }
            if(pinScreen?.type==="screenType" &&remoteVideo.filter(ele =>ele?.user?.id==pinScreen?.id&&ele?.type==="screenType").length==0){
            setPinScreen(0)
           
            }
          
           
        };

        updateStreams();

    
      






    }, [remoteVideo, activeUserList])  
    EventRegister.addEventListener('chatRcv', data => {

    });

    const onLocalStreamRcv = useCallback((data) => {
  
        setLocal(data?.stream)
        // setTimeout(() => {
        //     if (localVideoRef?.current) {
        //         localVideoRef.current.srcObject = data?.stream

        //     }

        // }, 1000);
    }, []);


    const screenVideoRef = useRef(null);
    const localscreen = useRef([])
    const onLocalScreenStreamRcv = (data, callPin = true) => {
     
        localscreen.current = data
        // if (callPin) {
        //     setPinScreen(-1)
        // }
        // onRemoteStreamRcv(remoteData?.current)
        setTimeout(() => {


            // screenVideoRef.current.srcObject = data?.stream
            setScreenLocal(data?.stream)
            /*  setMeetView((
                 <div style={{ display: "flex", backgroundColor: "#2D2D2D", width: "50vw", height: "60vh", borderRadius: 10, position: "relative", zIndex: 0 }}>
                     <VideoView key="localvideo" ref={screenVideoRef} style={{ height: "100%", width: "100%", borderRadius: 10 }} visible={true} />
                 </div>), "mainScrean") */

        }, 100);
    };
    const eventListeners = useRef(null);

    const setUpListeners = useCallback(() => {
        window.addEventListener("beforeunload", onUnmount);
        EventRegister.addEventListener('remoteMedia', onRemoteStreamRcv);
        EventRegister.addEventListener('localMedia', onLocalStreamRcv);
        EventRegister.addEventListener('audioReady', (data ) =>{
            console.log("audioReady",data);
            localMicStreamRef.current=data.stream
            setLocalMicStream(data.stream)
        });
        // EventRegister.addEventListener('remoteMedia', onRemoteStreamRcv);
        EventRegister.addEventListener('localScreenMedia', onLocalScreenStreamRcv);
        eventListeners.current = EventRegister.addEventListener("broadcast", data => {
            if (data === "connectionCompleted") {
                // console.log("eventtt", data,totalMicStreamsRef.current);
         
                roomClientAulasRecording.produce("audioType",totalMicStreamsRef.current)
                
            }
        })
        EventRegister.addEventListener('consumerPauseRcv', data => {
            console.log(data, "confixx 298");
            if (data?.kind === "audio") {

               console.log("active micref",activeMicref.current);
               console.log("before",activeMicref.current);
               activeMicref.current[data?.producer_userId]=false
               console.log("after",activeMicref.current);
                updateActiveMicList(activeMicref.current)
           

                
            }
        });
        EventRegister.addEventListener('consumerResumeRcv', data => {
            console.log(data, "confixx 301");
            if (data?.kind === "audio") {
               let datas={...activeMicref.current}
               console.log("before",activeMicref.current);
               activeMicref.current[data?.producer_userId]=true
               console.log("after",activeMicref.current);
               updateActiveMicList(activeMicref.current)
               
            }
        });
        EventRegister.addEventListener('chatRcv', data => {

        });
        EventRegister.addEventListener('userLeft', data => {
            // updateActiveUserList()
            if (pinScreen?.id == data?.user?.id || pinScreen == 0) {
                setPinScreen(0)
            }
           
            if( hostUserRecIdRef.current===data?.user?.id){
                setRecordingStatus(false)
                // if(recordingStatusRef.current===true){
                // recordingStatusRef.current=false
                // }
            }
         
            // let datas={...activeMicref.current,[data?.user?.id]:false}
            // activeMicref.current=datas
            // console.log("datasAfter",datas);
            // updateActiveMicList(datas)
            // else{
                
            // }
           
            setuserLeftJoined({ name: data?.user?.name, joined: false })
            // let datas={...activeMicList}
            // datas[data?.user?.id ]=false
           
          
         
             updateUserLeft(data)
          
        });
        EventRegister.addEventListener('userJoined', data => {
            // updateActiveUserList(data,"userJoined")

        
            setuserLeftJoined({ name: data?.user?.name, joined: true })
            storeJoinedUser(data?.user)
        });
        /*     EventRegister.addEventListener('userList', data => {
                updateActiveUserList(data)
                console.log(data,"userlist151");
                // dispatch(updateOnlineUsers(data));
              }); */
       EventRegister.addEventListener('producerMuteStatesRcv',(data) =>{
        console.log("unmute State,",data);
        let values=data
        activeMicref.current={}
        values.forEach(ele =>{
            if(ele?.userId!==userG?.id){
            activeMicref.current[ele?.userId]=true
            }
            
        })
        // console.log("activeMicRef",activeMicref.current);
        updateActiveMicList(activeMicref.current)
       })
        EventRegister.addEventListener('sharePermission', data => {
           
            if (data === false) {
                setshare(false)
                if (pinScreen === -1) {
                    setPinScreen(0)
                }
                else {
                    let data = pinScreen
                    setPinScreen(data)
                }
            }
        });
    }, [])
    const removeListeners = useCallback(() => {
        window.removeEventListener("beforeunload", onUnmount)
        EventRegister.removeEventListener('localMedia', onLocalStreamRcv);
        EventRegister.removeEventListener('remoteMedia', onRemoteStreamRcv);
        EventRegister.removeEventListener('localScreenMedia', onLocalScreenStreamRcv);
        EventRegister.removeEventListener(eventListeners.current)
    }, []);

    const onMount = useCallback(() => {
        let time = moment().format('HH:mm:ss A');

        postUserJoined({ email: userG?.email, roomId: meetIdG, enter: true }, (res) => {
            
        })
        // updateActiveUserList({name:userG?.hiringUser?.name, joinedOn:time, mic:isMicOn, video:isCameraOn, profile:userG?.profileImage?userG?.profileImage:null},"initial")


    }, []);

    useEffect(() => {
       
        

        setUpListeners();
        onMount();
        chatClientRef.current = getClient()
       chatClientRef.current.getUsers(meetIdG,userG?.id)
        setChatClient(chatClientRef.current)
      
        updateGroupChat([])
        const handleBeforeUnload = (event) => {

            


            // Perform any additional cleanup
            onUnmount();

            return ""
        };

        const handleLeaveButtonClick = () => {

            onUnmount();
            // window.location.reload();
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('unload', () => {

            const leaveButton = document.querySelector('.your-leave-button-class');
            if (leaveButton) {
                leaveButton.addEventListener('click', handleLeaveButtonClick);
            }
        });

       

        return () => {
            onUnmount();
            window.removeEventListener('beforeunload', handleBeforeUnload);
        
        }
    }, []);
    useEffect(() =>{
        if(joinedUser?.id  &&chatClient){
   setTimeout(() =>{
 
 if(recordingStatusRef.current===true ){
        chatClient.sendPrivateChat(userG?.id, meetIdG, joinedUser?.id,
            {
                type: 'record',
                data: {record:recordingStatus},
                userId: joinedUser?.id,
                roomId: meetIdG,
                userName: joinedUser?.name,
                token: mssgToken,
                hostUserId:userG?.id
               
            })
        }
   },6000)
}
    },[joinedUser])
    useEffect(() => {
        if (userLeftJoined) {
            if(userLeftJoined?.joined){
                Notification.success(`${userLeftJoined?.name} joined the meet`)
            }
            else{
                Notification.warning(`${userLeftJoined?.name} left the meet`)
            }
          
            setuserLeftJoined()
        }
    }, [userLeftJoined])

    const endCall = () => {
      
        brigoshaMedia.updateClassJoinStatus(false);
        brigoshaMedia.exit();
        // const client = new ChatClient(setUpChatSocket(), meetIdG?.hiringUser?.id, meetIdG, meetIdG?.hiringUser?.name, "", "", "https://picsum.photos/200/300?grayscale", "", "4cb2509d-70f5-435e-8792-d24937743b53", "eks2.aulas.one", clientChatCallback)
        // client.disconnect();
    };
    const onUnmount = useCallback(() => {
     
        setRecordingStatus(false)
  if(recordingStatusRef.current===true){
    recordingStatusRef.current=null
  }
        brigoshaMedia?.closeProducer("screenType")
        brigoshaMedia?.closeProducer("videoType")
        brigoshaMedia?.closeProducer("audioType")
        removeListeners();
        endCall();
       
     
        chatClient?.disconnect();

        setChatClient(null);
        if (chatClientRef?.current) {
            chatClientRef?.current?.disconnect();
            chatClientRef.current = null;
        }
        const videoElement = localVideoRef.current;
        if (videoElement && videoElement.srcObject) {
            const tracks = localMediaval.srcObject.getTracks();
            tracks.forEach(track => track.stop());
        }
        localMicStreamRef.current=null
        setIsCameraOn(false)
        setIsMicOn(false)
        updateFirstTimeMic(true)
        updateMediaReady(false)
        postUserJoined({ email: userG?.email, roomId: meetIdG, enter: false }, (res) => {
              window.location.reload()
        })

    }, []);

    function onRequestAction(action, data,) {
        
        let datas = requesteduserArray.filter(ele => ele?.userId !== data?.userId)
        setUserArray(datas)

        // updateRequestedUser(datas)
        if (datas?.length == 0) {
            setModalVisible(false)
        }

        chatClient?.sendPrivateChat(userG?.id, meetIdG, data?.userId,
            {
                type: 'Request',
                data: null,
                userId: data?.userId,
                roomId: meetIdG,
                userName: data?.userName,
                token: mssgToken,
                status: action
            }
        )
    }
    const socketCallback = (event, data) => {
        if (event === 'muxError') {
        } else if (event === 'connect') {
            clearTimeout(broadcastHealthTimer)
        }
        else if (event === 'disconnect') {
            console.log('19x socket is disconnected');
            if (broadcastHealthTimer) {
                clearTimeout(broadcastHealthTimer)
            }
            broadcastHealthTimer = setTimeout(() => {
                navigate(-1)
                // if (window.location.pathname==='/broadcast'){
                //   Notification.error("", "Socket disconnected, join again to resume the class")
                // //   history.goBack();
                // navigate(-1)
                // }
                console.log("19x exitRoom called after 10 sec");
            }, 10000);
        }
    }

    const recordingCallback = async (event, dataReceived) => {
        console.log("318eee", event, dataReceived)
        if(event==="SharePermissionDenied"){
            setStartrec(false)
        }
        if (event === 'rtmpStarted') {
  
            chatClient?.sendGroupChat(userG?.id, meetIdG,
                {
                    type: 'record',
                    data: { record: true },

                    userId: userG?.id,
                    roomId: meetIdG,
                    userName: userG?.name,
                    token: mssgToken,
                    hostUserId:userG?.id
                    // sentOn: time
                }
            )
            recordingStatusRef.current=true
            // ismanuallystoppingrecording.current = false
            // setRecordTimer(0);
            // start();
            // isRecording.current = 2
            // setRecording(2)
            // setRecordModalVisible(false)
        } else if (event === 'rtmpRemoved') {
            chatClient?.sendGroupChat(userG?.id, meetIdG,
                {
                    type: 'record',
                    data: { record: false },

                    userId: userG?.id,
                    roomId: meetIdG,
                    userName: userG?.name,
                    token: mssgToken,
                    hostUserId:userG?.id
                    // sentOn: time
                }
            )
            // if (ismanuallystoppingrecording.current){
            setTimeout(() => {

                setStartrec(false)
                   Notification.error("", 'recording has stopped')
                setTimeout(() => {
                    roomClientAulasRecording.closeProducer('audioType');
                }, 1000);
                roomClientAulasRecording.closeProducer('screenType');
            }, 1000);
            
        } else if (event === 'audioAdded') {
            
            
            roomClientAulasRecording.produce('screenType')
             
            
        }
    }
    useEffect(() => {
  
        if ((userG?.email === roundDetails?.hostEmail || userG?.role?.includes("Admin")) && startrec) {
           

            roomClientAulasRecording = new RoomClient({ room_id: `${meetIdG}`, refer: false, user: userG, name: userG?.name, user_id: userG?.id, socket: setUpRoomClientSocket("/aulasrecordingdev.io", socketCallback), eventCallback: recordingCallback, setStreamCallback: () => { } })
        }
    }, [startrec])
    useEffect(() => {

        if (localScreenval === undefined) {
            setshare(false)
            if (pinScreen == -1) {
                setPinScreen(0)
            }
        }
    }, [localScreenval])
    // useEffect(() =>{
    //     console.log("isMicOn",isMicOn,localMicStreamRef.current?.getAudioTracks()[0]);
    //     if(chatClient &&firstTimeMic===false ){
    //         chatClient.sendGroupChat(userG?.id, meetIdG,
    //         {
    //             type: 'mic',
    //             data:{mic:isMicOn},
    //             userId: userG?.id,
    //             roomId: meetIdG,
    //             userName: userG?.name,
    //             token: mssgToken,
                
    //         })
    //     }
    // },[isMicOn,])
    const VideoFooter = ({ onUnmount, onChatClick, activeUserList }) => {
        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '4%', alignItems: 'center', position: "relative", width: "100%", height: "100%" }}>
                    <IconButton
                        title={isCameraOn ? "Camera on" : "Camera off"}
                        onClick={onCameraClick}
                        active={!isCameraOn}>
                        {
                            !isCameraOn ?
                                <HiVideoCameraSlash style={{ height: 15, width: 15, color: "white" }} />
                                :
                                <IoVideocam style={{ height: 15, width: 15, color: "white" }} />
                        }
                    </IconButton>
                    <IconButton onClick={() => {
                   
                        if (isMicOn) {
                          
                            brigoshaMedia.pauseProducer('audioType');
                            setIsMicOn(false)
                        } else {
                          setIsMicOn(true)
                            if (firstTimeMic === true) {
                                brigoshaMedia.produce("audioType")
                                
                                 updateFirstTimeMic(false)
                                return
                            }
                           
                            brigoshaMedia.resumeProducer('audioType');

                        }
                    
                  
                       
                    }} title={isMicOn ? "Microphone on" : "Microphone off"} active={!isMicOn}>
                        {
                            isMicOn ?
                                <FaMicrophone style={{ height: 15, width: 15, color: "white" }} />
                                :
                                <IoMicOff style={{ height: 20, width: 20, color: "white" }} />
                        }
                    </IconButton>
                    <IconButton onClick={() => { onShareScreenClick() }} title={"Share screen"} active={share}>
                        {
                            !share ?
                                <img style={{ height: 20, width: 20 }} src={sharescreen} />
                                :
                                <img style={{ height: 20, width: 20 }} src={stopsharing} />
                        }
                        {/* <TbShare2 style={{ height: 15, width: 15, color: "white" }} /> */}
                    </IconButton>
                    <IconButton
                        // shownewmssg={true}
                        mssgRead={chat===false?mssgRead:false}
                        onClick={() => {
                            onChatClick()
                            if (people) {
                                setpeople(!people)
                            }
                            setchat(!chat)
                             chatModalRef.current=!chat
                        }} title={"Chat"} active={chat} >

                        <img style={{ height: 20, width: 20 }} src={chatIcon} />

                        {/* <IoChatbubblesOutline style={{ height: 15, width: 15, color: "white" }} /> */}
                    </IconButton>
                    <IconButton
                        count={activeUserList?.length}
                        noOfPeople={true}
                        onClick={() => {
                            // onChatClick()
                            if (chat) {
                                setchat(!chat)
                                chatModalRef.current=!chat
                            }
                            setpeople(!people)
                        }} title={"Participants"} active={people}>
                        <img style={{ height: 20, width: 20 }} src={Participents} />
                        {/* <MdPeople style={{ height: 15, width: 15, color: "white" }} /> */}
                    </IconButton>
                    <IconButton onClick={() => {
                        onUnmount();


                        navigate(-1)
                    }} title={"Leave"} active={true}>
                        <MdCallEnd style={{ height: 15, width: 15, color: "white" }} />
                    </IconButton>
                </div>
            </>
        )
    }
    const widthFnc = (arr) => {

        let width;
        if (arr === 1) {
            width = '91%';
        } else if (arr >= 2 && arr <= 4) {
            width = '46%';
        } else if (arr > 4 && arr < 10) {
            width = '30%';
        }
        else if (arr >= 10 && arr <= 12) {
            width = '22%';
        }
        else if (arr > 12 && arr < 16) {
            width = '18%';
        }
        else if (arr > 16 && arr < 26) {
            width = '15%';
        }
        else {
            width = 'auto'; // Or any other default value
        }
   
        return width
    }
    const heightFnc = (arr) => {

        let height;
        if (arr <= 1) {
            height = '91%';
        } else if (arr > 2 && arr <= 6) {
            height = '46%';
        } else if (arr > 6 && arr < 16) {
            height = '29%';
        }
        else if (arr > 16 && arr < 24) {
            height = '22%';
        }
        //    else if (arr >= 10 && arr <= 12) {
        //     height = '24%';
        //  }
        //    else if (arr >12 && arr <16){
        //       height = '19%';
        //   }
        else {
            height = 'auto'; // Or any other default value
        }

        return height
    }

    return (
        <>
     
            {
                meetIdG != "" ?
                    <>

                        <div style={{ backgroundColor: '#161616', display: 'flex', flexDirection: 'column', padding: 12, height: "100vh", width: "100%", position: "relative" }}>
                            <div style={{ height: "4%" }}>
                                <VideoHeader onClickI={(val) => {
                                    if(val==="pending"){
                                        setPendingModal(true)
                                        return
                                    }
                                   
                                    setInfo(!info)
                                }}recordingStatusRef={recordingStatusRef} roundDetails={roundDetails} recordingStatus={recordingStatus} setRecModal={setRecModal} userG={userG} localMicStreamRef={localMicStreamRef} pendingApprovals={pendingApprovals} />
                            </div>
                            <div style={{ display: "flex", gap: "1rem", height: "80%", marginTop: 0,width:"100%", }}>
                                {(streams?.length == 0 && share === false) ? <div style={{ boxShadow: "0px 2px 3px grey", zIndex: 2, height: "100%", display: "flex", width: "80%", borderRadius: 10, position: "relative", backgroundColor: "#636363",margin: "auto" }}>


                                    {localMediaval && isCameraOn ? <VideoView key="localvideo" stream={localMediaval} style={{ height: "100%", width: "100%", borderRadius: 10 }} visible={true} objectFit={"cover"} type={"videoType"}  /> : <div style={{ height: "100%", width: "100%", borderRadius: 10, justifyContent: "center", display: "flex", color: "white", alignItems: "center", fontSize: 54, fontWeight: "bold" }}>{userG?.profileImage ? <img src={userG?.profileImage} style={{ height: (streams?.length == 0 && share === false) ? 120 : 80, width: (streams?.length == 0 && share === false) ? 120 : 80, borderRadius: (streams?.length == 0 && share === false) ? 60 : 40, border: "solid 2px white" }} /> : userG?.name?.[0]}</div>}
                                    <div style={{ display: "flex", position: "absolute", bottom: 5, left: 10, fontSize: 10, color: "white", textShadow: "2px 2px 2px grey", fontWeight: "bold" }}>You</div>


                                </div> : ""} 
                                {((streams?.length > 0 || share === true)) ?
                                    <>
                                       {/* {activeUserList.length <= 2 && <div style={{ boxShadow: "0px 2px 3px grey", zIndex: 2, position: "relative", display: "flex", width: "16%", height: "16%", borderRadius: 10, position: "absolute", borderRadius: 10, backgroundColor: "#636363", right: 10, bottom: 10 }}>

                                            {localMediaval && isCameraOn ? <VideoView key="localvideo" stream={localMediaval} style={{ height: "100%", width: "100%", borderRadius: 10 }} visible={true} type={"videoType"} /> : 
                                            <div style={{ height: "100%", width: "100%", borderRadius: 10, justifyContent: "center", display: "flex", color: "white", alignItems: "center", fontSize: 54, fontWeight: "bold" }}>{userG?.profileImage ? <img src={userG?.profileImage} style={{ height: (streams?.length == 0 && share === false) ? 120 : 80, width: (streams?.length == 0 && share === false) ? 120 : 80, borderRadius: (streams?.length == 0 && share === false) ? 60 : 40, border: "solid 2px white" }} /> : userG?.name[0]}</div>}
                                            <div style={{ display: "flex", position: "absolute", bottom: 5, left: 10, fontSize: 10, color: "white", }}>You</div>

                                        </div>} */}
                                        {pinScreen === 0 ?
                                            <div style={{ flexDirection: "row", width: "100%", display: "flex", justifyContent: "center", flexWrap: "wrap",gap:40  }}>
                                             {share && <div style={{ display: "flex", height: heightFnc(share ? streams?.length + 2 : streams?.length+1), width: widthFnc(share ? streams?.length + 2 : streams?.length+1), borderRadius: 10, position: "relative", backgroundColor: "#636363" }} onClick={() => { }}>
                                                    <VideoView key="localvideo1" stream={localScreenval} style={{ height: "100%", width: "100%", borderRadius: 10,padding:2 }} visible={true} objectFit={"contain"}/>
                                                    <div style={{ position: "absolute", top: 10, left: 10, color: "white", zIndex: 5, textShadow: "2px 2px 2px grey", fontWeight: "bold" }}>
                                                        You are presenting
                                                    </div>
                                                    <button style={{ position: "absolute", top: 10, right: 10, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); setPinScreen(-1); }}>
                                                        <img style={{ height: 20, width: 20 }} src={PinEnabled} />
                                                    </button>
                                                </div>}
                                                
                                                <div style={{ display: "flex", height: heightFnc(share ? streams?.length + 2 : streams?.length+1), width: widthFnc(share ? streams?.length + 2 : streams?.length+1), borderRadius: 10, position: "relative", backgroundColor: "#636363" }} onClick={() => { }}>
                                                {localMediaval && isCameraOn? <VideoView key="localvideo" stream={localMediaval} style={{ height: "100%", width: "100%", borderRadius: 10 }} visible={true} type={"videoType"} />:
                                                 <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 600, fontSize: 40, width: "100%", height: "100%", color: "white" }}> {userG?.profileImage ? <img src={userG?.profileImage} style={{ height: 60, width: 60, borderRadius: 30, border: "solid 2px white" }} /> : userG?.name?.[0]}</div>}
                                                    <div style={{ position: "absolute", top: 10, left: 10, color: "white", zIndex: 5, textShadow: "2px 2px 2px grey", fontWeight: "bold" }}>
                                                       You
                                                    </div>
                                                    <button style={{ position: "absolute", top: 10, right: 10, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); setPinScreen(-2); }}>
                                                        <img style={{ height: 20, width: 20 }} src={PinEnabled} />
                                                    </button>
                                                    {( !isMicOn)&&
                                                    <div style={{ position: "absolute", top: 10, right: 50, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5,}}>
                                                    
                                    
                                        <IoMicOff style={{ height: 20, width: 20, color: "white" }} />
                                        
                                
                                                         </div>}
                                                </div>


                                               
                                                {streams.map((stream, index) => {

                                                    if (stream.mediaStream) {
                                                        return (
                                                            <div key={index} style={{ position: "relative", gap: 0, height: heightFnc(share ? streams?.length + 2 : streams?.length+1), width: widthFnc(share ? streams?.length + 2 : streams?.length+1), backgroundColor: "#636363",borderRadius:10 }} >
                                                                {/* <VideoView stream={stream?.mediaStream} style={{ height: "100%", width: "100%", borderRadius: 10 }} visible={true} /> */}
                                                             
                                                                {stream?.mediaStream && <VideoView stream={stream?.mediaStream} style={{ height: "100%", width: "100%", borderRadius: 10 }} visible={true} type={stream?.type} />}
                                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 600, fontSize: 40, width: "100%", height: "100%", color: "white" }}> {stream?.user?.profileImage ? <img src={stream?.user?.profileImage} style={{ height: 60, width: 60, borderRadius: 30, border: "solid 2px white" }}  /> : stream?.name?.[0] || stream?.user?.name?.[0]}</div>
                                                                {/* <div style={{ color: "white" }} > {stream?.user?.name}</div> */}
                                                                <div style={{ position: "absolute", top: 10, left: 10, color: "white", zIndex: 5, textShadow: "2px 2px 2px grey", fontWeight: "bold",width:"75%" }}>
                                                                    {stream?.user?.name} {stream?.type === "screenType" ? " is presenting" : ""}
                                                                </div>
                                                                {/* {(!share && streams?.length === 1) ? "" : */}
                                                                 <button style={{ position: "absolute", top: 10, right: 10, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }} onClick={(e) => {
                                                                    // if (!share && streams?.length === 1) {
                                                                    //     return
                                                                    // }
                                                                    e.stopPropagation(); setPinScreen({ id: stream?.user?.id, type: stream?.type });
                                                                }}>
                                                                    <img style={{ height: 20, width: 20 }} src={PinEnabled} />
                                                                </button>
                                                                { 
                                    ( !activeMicList[stream?.user?.id])&&<div style={{ position: "absolute", top: 10, right: 50, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }}>
                                                    
                                        <IoMicOff style={{ height: 20, width: 20, color: "white" }} />
                                        
                                
                                                         </div>}
                                                                {/* { 
                                    ( micListed[stream?.user?.id])?
                                        <FaMicrophone style={{ height: 15, width: 15, color: "white" }} />
                                        :
                                        <IoMicOff style={{ height: 20, width: 20, color: "white" }} />
                                } */}
                                                           
                                                            </div>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <div style={{ position: "relative", height: heightFnc(share ? streams?.length + 2 : streams?.length+1), width: widthFnc(share ? streams?.length + 2 : streams?.length+1), borderWidth: 1, borderColor: "red", backgroundColor: "#636363", color: "white", borderRadius: 5, display: "flex", }}>
                                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 600, fontSize: 40, width: "100%", height: "90%", color: "white" }}> {stream?.profileImage ? <img src={stream?.profileImage} style={{ height: 60, width: 60, borderRadius: 30, border: "solid 2px white" }}  /> : stream?.name?.[0]}</div>
                                                                <div style={{ position: "absolute", top: 10, left: 10, marginLeft: 5, }}> {stream?.name}</div>
                                                                {/* {(!share && streams?.length === 1) ? "" :  */}
                                                                
                                                                <button style={{ position: "absolute", top: 10, right: 10, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }} onClick={(e) => {
                                                                    // if (!share && streams?.length === 1) {
                                                                    //     return
                                                                    // }
                                                                    e.stopPropagation(); setPinScreen({ id: stream?.id, type: undefined });
                                                                }}>
                                                                    {<img style={{ height: 20, width: 20 }} src={PinEnabled} />}
                                                                </button>
                                                                {/* { micListed[stream?.user?.id]?
                                        <FaMicrophone style={{ height: 15, width: 15, color: "white" }} />
                                        :
                                        <IoMicOff style={{ height: 20, width: 20, color: "white" }} />} */}
                                                                {/* } */}
                                                            </div>
                                                        )
                                                    }
                                                })}




                                            </div> :
                                            <div style={{ flexDirection: "row", width: "100%", display: "flex", justifyContent: "space-between", }}>
                                                <div style={{ width: "70%", }}>
                                                    {pinScreen == -1 && share && <div style={{ display: "flex", height: "100%", width: "calc(100% - 10px)", position: "relative", borderRadius: 10, backgroundColor: "#636363" }} onClick={() => { }}>
                                                        <VideoView key="localvideo1" stream={localScreenval} style={{ height: "100%", width: "100%", borderRadius: 10 }} visible={true} objectFit={"contain"} type={"screenType"} />
                                                        <div style={{ position: "absolute", top: 10, left: 10, color: "white", zIndex: 1, textShadow: "2px 2px 2px grey", fontWeight: "bold" }}>
                                                            {userG?.name} is presenting
                                                        </div>
                                                        <button style={{ position: "absolute", top: 10, right: 10, padding: 5, background: "rgba(0, 0, 0, 0.5)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); setPinScreen(0); }}>
                                                            <img style={{ height: 20, width: 20 }} src={PinDisabled} />
                                                        </button>
                                                    </div>}
                                                    {pinScreen == -2 && <div style={{ display: "flex", height: "100%", width: "calc(100% - 10px)", position: "relative", borderRadius: 10, backgroundColor: "#636363" }} onClick={() => { }}>
                                                        {localMediaval && isCameraOn? <VideoView key="localvideo" stream={localMediaval} style={{ height: "100%", width: "100%", borderRadius: 10 }} visible={true} type={"videoType"} objectFit={"cover"}  />:
                                                         <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 600, fontSize: 40, width: "100%", height: "100%", color: "white" }}> {userG?.profileImage ? <img src={userG?.profileImage} style={{ height: 60, width: 60, borderRadius: 30, border: "solid 2px white" }} /> : userG?.name?.[0]}</div>}
                                                        <div style={{ position: "absolute", top: 10, left: 10, color: "white", zIndex: 1, textShadow: "2px 2px 2px grey", fontWeight: "bold" }}>
                                                        You
                                                        </div>
                                                        <button style={{ position: "absolute", top: 10, right: 10, padding: 5, background: "rgba(0, 0, 0, 0.5)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); setPinScreen(0); }}>
                                                            <img style={{ height: 20, width: 20 }} src={PinDisabled} />
                                                        </button>
                                                        { 
                                    ( !isMicOn)&&<div style={{ position: "absolute", top: 10, right: 50, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }}>
                                                    
                                        <IoMicOff style={{ height: 20, width: 20, color: "white" }} />
                                        
                                
                                                         </div>}
                                                    </div>}

                                                    {streams.filter(ele => ((ele?.user?.id && ele?.user?.id === pinScreen?.id) || ele?.id === pinScreen?.id) && (ele?.type === pinScreen?.type)).map((stream, index) => {
                                                      

                                                        return (
                                                            <div key={index} style={{ position: "relative", borderRadius: 10, gap: 5, height: `100%`, width: "calc(100% - 10px)", backgroundColor: "#636363" }} >
                                                                {stream?.mediaStream && <VideoView stream={stream?.mediaStream} style={{ height: "100%", width: "100%", borderRadius: 10 }} visible={true} type={stream?.type} objectFit={stream?.type==="screenType"?"contain":"cover"}  />}
                                                                {<div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 600, fontSize: 40, width: "100%", height: "100%", color: "white" }}>{stream?.profileImage || stream?.user?.profileImage ? <img src={stream?.profileImage || stream?.user?.profileImage} style={{ height: 60, width: 60, borderRadius: 30, border: "solid 2px white" }} /> : stream?.name?.[0] || stream?.user?.name?.[0]} </div>}
                                                                {/* <div style={{ color: "white" }} > {stream?.user?.name}</div> */}
                                                                <div style={{ position: "absolute", top: 10, left: 10, color: "white", zIndex: 5, textShadow: "2px 2px 2px grey", fontWeight: "bold" }}>
                                                                    {stream?.user?.name || stream?.name} {stream?.type === "screenType" ? " is presenting" : ""}
                                                                </div>
                                                                <button style={{ position: "absolute", top: 10, right: 10, padding: 5, background: "rgba(0, 0, 0, 0.5)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); setPinScreen(0); }}>
                                                                    <img style={{ height: 20, width: 20 }} src={PinDisabled} />
                                                                </button>
                                                                { 
                                    ( !activeMicList[stream?.user?.id])&&<div style={{ position: "absolute", top: 10, right: 50, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }}>
                                                    
                                        <IoMicOff style={{ height: 20, width: 20, color: "white" }} />
                                        
                                
                                                         </div>}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <div style={{ flexDirection: "column", width: "25%", overflowY: "scroll", height: "100%" }}>
                                                    {pinScreen !== -1 && share && <div style={{ display: "flex", height: `25%`, width: "90%", position: "relative", borderRadius: 10, backgroundColor: "#636363" }} onClick={() => { setPinScreen(-1); onLocalScreenStreamRcv(localscreen?.current, false) }}>
                                                        <VideoView key="localvideo1" stream={localScreenval} style={{ height: "100%", width: "100%", borderRadius: 10,padding:2 }} visible={true} type={"screenType"} />
                                                        <div style={{ position: "absolute", top: 10, left: 10, color: "white", zIndex: 1,width:"75%" }}>
                                                            {userG?.name} is presenting
                                                        </div>
                                                        <button style={{ position: "absolute", top: 10, right: 10, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); setPinScreen(-1); }}>
                                                            <img style={{ height: 20, width: 20 }} src={PinEnabled} />
                                                        </button>
                                                    </div>}
                                                    {(pinScreen !== -2 ) && <div style={{ display: "flex", height: `25%`, width: "90%", position: "relative", borderRadius: 10, backgroundColor: "#636363" }} onClick={() => { setPinScreen(-1); onLocalScreenStreamRcv(localscreen?.current, false) }}>
                                                        {localMediaval&& isCameraOn?<VideoView key="localvideo" stream={localMediaval} style={{ height: "100%", width: "100%", borderRadius: 10,padding:2 }} visible={true} type={"videoType"} />
                                                       : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 600, fontSize: 40, width: "100%", height: "100%", color: "white" }}> {userG?.profileImage ? <img src={userG?.profileImage} style={{ height: 60, width: 60, borderRadius: 30, border: "solid 2px white" }} /> : userG?.name?.[0]}</div>}
                                                        <div style={{ position: "absolute", top: 10, left: 10, color: "white", zIndex: 1, width:"75%"}}>
                                                        You
                                                        </div>
                                                        <button style={{ position: "absolute", top: 10, right: 10, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); setPinScreen(-2); }}>
                                                            <img style={{ height: 20, width: 20 }} src={PinEnabled} />
                                                        </button>
                                                        { 
                                    ( !isMicOn)&&<div style={{ position: "absolute", top: 10, right: 50, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }}>
                                                    
                                        <IoMicOff style={{ height: 20, width: 20, color: "white" }} />
                                        
                                
                                                         </div>}
                                                    </div>}
                                                    {streams
                                                        .filter(ele => !(((ele?.user?.id && ele?.user?.id === pinScreen?.id) || ele?.id === pinScreen?.id) && ele?.type === pinScreen?.type))
                                                        .map((stream, index) => {
                                                           
                                                            if (stream?.mediaStream) {
                                                                if (stream?.user?.id !== pinScreen?.id || (stream?.user?.id === pinScreen?.id && stream?.type !== pinScreen?.type)) {
                                                                    return (
                                                                        <div key={index} style={{ position: "relative", marginTop: 8, height: "25%", width: "90%", backgroundColor: "#636363", borderRadius: 10 }} >
                                                                            <VideoView stream={stream?.mediaStream} style={{ height: "100%", width: "100%", borderRadius: 10 }} visible={true} type={stream?.type}  />
                                                                            {<div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 600, fontSize: 40, width: "100%", height: "100%", color: "white" }}>{stream?.user?.profileImage ? <img src={stream?.user?.profileImage} style={{ height: 60, width: 60, borderRadius: 30, border: "solid 2px white" }} /> : stream?.name?.[0] || stream?.user?.name?.[0]} </div>}
                                                                            {/* <div style={{ color: "white" }} > {stream?.user?.name}</div> */}
                                                                            <div style={{ position: "absolute", top: 10, left: 10, color: "white", zIndex: 5, textShadow: "2px 2px 2px grey", fontWeight: "bold",width:"75%" }}>
                                                                                {`${stream?.user?.name} ${stream?.type === "screenType" ? " is presenting" : ""}`}
                                                                            </div>
                                                                            <button style={{ position: "absolute", top: 10, right: 10, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); setPinScreen({ id: stream?.user?.id, type: stream?.type }); }}>
                                                                                <img style={{ height: 20, width: 20 }} src={PinEnabled} />
                                                                            </button>
                                                                            { 
                                    ( !activeMicList[stream?.user?.id])&&<div style={{ position: "absolute", top: 10, right: 50, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }}>
                                                    
                                        <IoMicOff style={{ height: 20, width: 20, color: "white" }} />
                                        
                                
                                                         </div>}
                                                                            {/* ( micListed[item?.id])?
                                        <FaMicrophone style={{ height: 15, width: 15, color: "white" }} />
                                        :
                                        <IoMicOff style={{ height: 20, width: 20, color: "white" }} /> */}
                                                                        </div>
                                                                    )
                                                                }
                                                                else {
                                                                    return ""
                                                                }
                                                            }
                                                            else {
                                                                if (stream?.id) {
                                                                    return (
                                                                        <div style={{ position: "relative", marginTop: 8, height: `25%`, width: "90%", borderWidth: 1, borderColor: "red", backgroundColor: "#636363", color: "white", borderRadius: 5 }}>
                                                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 600, fontSize: 40, width: "100%", height: "80%", color: "white" }}>{stream?.profileImage ? <img src={stream?.profileImage} style={{ height: 60, width: 60, borderRadius: 30, border: "solid 2px white" }} /> : stream?.name?.[0]}</div>
                                                                            <div style={{ marginLeft: 5 }}> {stream?.name}</div>
                                                                            <button style={{ position: "absolute", top: 10, right: 10, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); setPinScreen({ id: stream?.id, type: undefined }); }}>
                                                                                <img style={{ height: 20, width: 20 }} src={PinEnabled} />
                                                                            </button>
                                                                        </div>
                                                                    )
                                                                }
                                                                else {
                                                                    return ""
                                                                }
                                                            }
                                                        })}
                                                </div>
                                            </div>}</>

                                    : ""}







                                {(chat && !people) &&
                                    <div>
                                        <ChatScreen brigoshaMedia={brigoshaMedia} setchat={setchat}    chatModalRef={chatModalRef} />
                                    </div>
                                }
                                {
                                    (!chat && people) &&
                                    <div>
                                        <Participates  setpeople={setpeople} remoteAudioRef={remoteAudioRef} people={people} triggerPeople={triggerPeople}  />
                                    </div>
                                }

                                {
                                    modalVisible &&

                                    <ParticipantsJoinDetails modalVisible={modalVisible} setModalVisible={setModalVisible} RequestedUser={RequestedUser} onRequestAction={onRequestAction} approveEvent={approveEvent} setUserArray={setUserArray} requesteduserArray={requesteduserArray} />

                                }
                                {pendingModal&&<PendingApproval modalVisible={pendingModal} setModalVisible={setPendingModal} pendingApprovals={pendingApprovals} onRequestAction={onRequestAction} chatClientRef={chatClientRef} insideChatUserList={insideChatUserList} />}
                                {/* </div> */}
                            </div>
                            <div style={{ height: "9%", marginTop: 18 }}>
                                <VideoFooter activeUserList={activeUserList} onChatClick={() => {
                                    updateMssgsentRed(false)
                                    // if (!(!chat && !people))
                                    //     onRemoteStreamRcv(remoteData?.current)
                                }} onUnmount={onUnmount} />
                            </div>

                        </div>
                    </>
                    :
                    <NotFound />
            }
            {
                info && <Info roundDetails={roundDetails} info={info} setInfo={setInfo} />
            }
            <Modal
                okText="Start"
                onOk={() => {
                    setStartrec(true)
                    setRecModal(false)

                }}
                onCancel={() => {
                    setRecModal(false)

                }}
                open={recModal}>
                <div>Kindly click on start for sharing and screen recording.</div>
            </Modal>
        </>
    );
};

export default Room;
