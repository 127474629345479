import React, {forwardRef, useState, useRef,useEffect} from "react";
import  Utils  from "../../services/utils";

const LandingCamera = forwardRef((props, ref) => {
    const videoRef = useRef(null);

    useEffect(() => {
        console.log("props.streams",props?.stream);
      if (videoRef.current && props.stream) {
        videoRef.current.srcObject = props.stream;
      }
    }, [props?.stream]);
 return(
    <video style={{ width: "100%", height: '100%', borderRadius: 10,overflow: 'hidden',objectFit:"cover",transform: "scaleX(-1)"  }} ref={videoRef} autoPlay ></video>
 )
})
export default LandingCamera