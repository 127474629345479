import { Checkbox, Spin , Tooltip} from "antd"
import deleteIcon from "../../../assets/DeleteIcon.svg";
import editIcon from "../../../assets/editBlack.svg";
import transferIcon from "../../../assets/transferIcon.svg";
// import LineProgressBar from "../../../components/LineProgessBar/LineProgress";
import moment from "moment";
import useApiStore from "../../../services/store";
import classes from "./Project.module.css";
import "./comp.css";
import CircleProgressBar from "../../../components/CircleProgressBar/CircleProgressBar";
import viewClass from "../../../services/ViewRole";
import ButtonCompo from "../../../design/ButonCompo";
import { NoData } from "../../Training_essentialDocuments";
import TableCustom from "../../../components/tableCustom/customTable";
import { useEffect } from 'react';
import logefforticon from "../../../assets/logefforticon.svg"


function TaskTable({ data, onEdit, onDelete, onTransfer, onLogChange }) {
    const [loader] = useApiStore(state => [state.loader])
    const editHandler = (data) => {
        onEdit(data);
    }
    const deleteHandler = (data) => {
        onDelete({ id: data?.id, name: data?.name });
    }

    const transferHandler = (data) => {
        onTransfer({ id: data?.id, name: data?.name });
    }
    const newData = data?.map(element => ({
        ...element,
        prog: (
            <>
                <div>
                    <CircleProgressBar strokeWidth={5} sqSize={50} percentage={element?.progress ? element?.progress : 0} />
                </div>
            </>
        ),
        loge:(
            <>
            {((element?.logEffort))?<ButtonCompo onClick={()=>{onLogChange({id:element?.id,name:element?.name,task:element})}} title={"Log Effort"} style={{color:'#fff',backgroundColor:'#0086ff'}} />:null  }
            </>
        ),
        other:
        (viewClass.manager&&element?.isManager)?
        (
            <>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'8rem', gap:"2px"}} >
                    {(viewClass.manager&&element?.isManager)?
                    <Tooltip title='Edit'>
                        <img src={editIcon} onClick={()=>{editHandler(element);}} style={{height:'30px',width:'30px',cursor:'pointer'}}></img>
                    </Tooltip>
                    :null}
                    {(viewClass.manager&&element?.isManager)?
                    <Tooltip title='Transfer'>
                        <img src={transferIcon} onClick={()=>{transferHandler(element);}} style={{height:'30px',width:'30px',cursor:'pointer'}}></img>
                    </Tooltip>
                    :null}
                    {(viewClass.manager&&element?.isManager)?
                    <Tooltip title="Delete">
                        <img src={deleteIcon} onClick={()=>{deleteHandler(element);}} style={{height:'30px',width:'30px',cursor:'pointer'}}></img>
                    </Tooltip>
                    :null}
                </div>
            </>
        )
        :null
    }));
    console.log(data, "dataaaaaaatat");
    const tableColumn = {
        0: {
            cols: "Name",
            width: "30%",
            dataKey: "name",
            onclick: () => { }
        },
        1: {
            cols: "Associate",
            width: "30%",
            dataKey: "associate",
            onclick: () => { }
        },
        2: {
            cols: "Team",
            width: "30%",
            //   innerWidth:"7rem",
            dataKey: "teamName",
            onclick: () => { }
        },
        3: {
            cols: "Start Date",
            color: "#39CA6C",
            width: "25%",
            dataKey: "startDate",
            onclick: () => { }
        },
        4: {
            cols: "Due Date",
            width: "25%",
            color: "#FF3434",
            dataKey: "dueDate",
            onclick: () => { }
        },
        5: {
            cols: "Status",
            width: "20%",
            dataKey: "status",
            onclick: () => { }
        },
        6: {
            cols: "Progress",
            width: "0%",
            dataKey: "prog",
            onclick: () => { }
        },
        7: {
            cols: "",
            width: "50%",
            dataKey: "other",
            onclick: () => { }
        },
        
        8: {
            cols: "",
            width: "10%",
            dataKey: "loge",
            onclick: () => { }
        },
    }
    return (
        <>
           {/*  <TableCustom columns={tableColumn} dataSource={newData} loading={loader}
            /> */}
             {loader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><Spin /></div>:(data?.length===0 || data===undefined)?
            <NoData/>
        :
        <div style={{displa:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <div style={{width:'100%',height:'5vh',display:'flex',justifyContent:'space-around',alignItems:'center',gap:'1rem',backgroundColor:'#fafafa'}}>
               
                <div style={{width:'100%',height:'100%',marginLeft:'1.5rem',display:'flex',alignItems:'center'}}>Name</div>
                <div style={{width:'100%',height:'100%',display:'flex',alignItems:'center'}}>Associate</div>
                <div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',marginLeft:'0.5rem'}}>Team</div>
                <div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',marginLeft:'0.5rem'}}>Start Date</div>
                <div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',marginLeft:'0.5rem'}}>Due Date</div>
                <div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',marginRight:'1rem'}}>Status</div>
                <div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',marginRight:'2.5rem'}} >Progress</div>
                {
                    ((viewClass.manager && data.filter((item)=>item.isManager === true).length > 0) || data.filter((item)=>item.logEffort === true).length > 0) && (
                        <div style={{ width: '100%', height: '100%',marginRight:'8rem',display:'flex',justifyContent:'center',alignItems:'center' }}><p style={{marginLeft:'3rem'}}>Actions</p></div>
                    )
                }
            </div>
        <div style={{display:'flex',flexDirection:'column',backgroundColor:'#f9f9f9',height:window.innerHeight-420,overflowY:'scroll'}}>
        {data?.map((item,id)=>{
            return(
            <div key={id} className={classes.ListConatiner}>
                <div style={{width:'10rem',display:'flex',flexDirection:'column',justifyContent:'space-around'}}>
                   {/* {id===0 && ( )} */}
                   <Tooltip title={item?.name}>
                    <div style={{width:'90%',height:'2.5vh',color:'black',overflow:'hidden',textOverflow:'ellipsis'}}>{item?.name}</div>
                   </Tooltip>
                </div>
                <div style={{width:'10rem',display:'flex',flexDirection:'column',justifyContent:'space-around'}}>
                   {/* {id===0 && (  )} */}
                   <Tooltip title={item?.associate}>

                    <div style={{color:'black',width:'90%',height:'2.5vh',color:'black',overflow:'hidden',textOverflow:'ellipsis'}}>{item?.associate}</div>
                   </Tooltip>
                </div>
                <div style={{width:'10rem',display:'flex',flexDirection:'column',justifyContent:'space-around'}}>
                   {/* {id===0 && (  )} */}
                   <Tooltip title={item?.teamName}>

                    <div style={{color:'black'}}>{item?.teamName}</div>
                   </Tooltip>
                </div>
                <div style={{width:'10rem',display:'flex',flexDirection:'column',justifyContent:'space-around'}}>
                   {/* {id===0 && (  )} */}
                    <div style={{color:'#39CA6C'}}>{moment(item?.startDate).format('DD-MMM-YYYY')}</div>
                </div>
                <div style={{width:'10rem',display:'flex',flexDirection:'column',justifyContent:'space-around'}}>
                   {/* {id===0 && (  )} */}
                    <div style={{color:'#FF3434'}}>{moment(item?.dueDate).format("DD-MMM-YYYY")}</div>
                </div>
                <div style={{width:'10rem',display:'flex',flexDirection:'column',justifyContent:'space-around'}}>
                    {/* {id===0 && ( )} */}
                    <div className={item?.status}>{item?.status}</div>
                </div>
                <div style={{width:'10rem',display:'flex',flexDirection:'column',justifyContent:'space-around',gap:'0.5rem'}}>
               {/* {id===0 && (  )} */}
                    <div> 
                    <CircleProgressBar strokeWidth={5} sqSize={50} percentage={item?.progress?item?.progress:0}  />
                    </div>
                </div>
                {
                    ((viewClass.manager&&item?.isManager) || (item?.logEffort)) && (

                <div style={{
                    // border:'1px solid red',
                width:'20rem'}}>
               <div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'0.2rem', 
            //    border:'1px solid black'
               }} >
                     {(viewClass.manager&&item?.isManager)?<img src={editIcon} onClick={()=>{editHandler(item);}} style={{height:'36px',width:'36px',cursor:'pointer'}}></img>:null}
                    {(viewClass.manager&&item?.isManager)?<img src={transferIcon} onClick={()=>{transferHandler(item);}} style={{height:'36px',width:'36px',cursor:'pointer'}}></img>:null}
                    {(viewClass.manager&&item?.isManager)?<img src={deleteIcon} onClick={()=>{deleteHandler(item);}} style={{height:'36px',width:'36px',cursor:'pointer'}}></img>:null}
                    {/* {((!viewClass.manager&&!viewClass.superAdmin&&!viewClass.hod&&item?.webAccess))?<ButtonCompo onClick={()=>{onLogChange({id:item?.id,name:item?.name,task:item})}} title={"Log Effort"} style={{color:'#fff',backgroundColor:'#0086ff'}} />:null  } */}
                    {
                        // ((item?.webAccess && item?.logEffort))?<ButtonCompo onClick={()=>{onLogChange({id:item?.id,name:item?.name,task:item})}} title={"Log Effort"} style={{color:'#fff',backgroundColor:'#0086ff'}} />:null 
                        ((item?.webAccess && item?.logEffort)) &&
                        <Tooltip title='Log Effort'>

                            <div style={{ cursor: "pointer", paddingTop:'5px' }} onClick={(e) => { onLogChange({ id: item?.id, name: item?.name, task: item }) }}>
                                <img src={logefforticon} style={{ height: 25, width: 25 }} />
                            </div>
                        </Tooltip> 
                    }

                    {/* {true?<ButtonCompo onClick={()=>{onLogChange({id:item?.id,name:item?.name,task:item})}} title={"Log Effort"} style={{color:'#fff',backgroundColor:'#0086ff'}} />:null  } */}
                </div> 
                </div>
                    )
                }
            </div>)
        })}
          
            </div>
        </div>}
        </>
    )
}
export default TaskTable;

    // <Table
    //     columns={taskColumn}
    //     dataSource={data}
    //     loading={loader}
    //     scroll={{
    //     y:"500px",
    //     }}
    //     />