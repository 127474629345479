import { Button, Table, Select, Popover, Tabs, Spin,Pagination,Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import Api from "../../../../services/Api";
import { adminActions } from "../store";
import WorkSummaryModal from "../work-summary-modal";
import refreshIcon from "../../../../assets/Appraisal/refresh-Black.svg";
import CommonModal from "../commonModal";
import {
  AiFillFilter,
  AiFillCaretUp,
  AiFillCaretDown,
} from "../../../../services/Icons";
import { Color } from "../../../../services/color";
import "../styles/tableHeader.css";
import ManualIcon from "../../../../assets/Appraisal/manual.png";
import UserIcon from "../../../../assets/Appraisal/userIcon.svg";
import cycleStatus from "../../../../assets/Appraisal/refreshBlue.svg";
import info from "../../../../assets/Appraisal/information.svg";
import sentIcon from "../../../../assets/Appraisal/sentIcon.svg";
import unsentIcon from "../../../../assets/Appraisal/unsentIcon.svg";
import pendingIcon from "../../../../assets/Appraisal/pendingIcon.svg";
import { sendAllRequest } from "../actionsAdmin";
import WorkSummary from "../../associate/work-summary";
import AssociateTable from "../../components/Table/AssociateTable";
import classes from "./users.module.css";
import EditIcon from "../../../../assets/EditProfIcon.svg";
import SummaryIcon from "../../../../assets/SummaryAppIcon.svg";
import styled from 'styled-components';

const CustomTable = styled(Table)`
  .ant-table-thead > tr > th {
    padding: 0; /* Remove padding from table header cells */
  }`;

const UserTable = (props) => {
  const {
    users,
    usersDetail,
    meta,
    designationList,
    cycleList,
    statusList,
    appraisalFilter,
    designationFilter,
    managerStatusList,
    cycleFilter,
    formStatusFilter,
    managerFormStatusFilter,
    sorting,
    page,
    refreshEffect,
    tabState,
    associateCount,
    tableLoader,
    summaryNotificationFilter,
    roleFilter,
  } = useSelector((state) => state.admin);
  const { loader } = useSelector((state) => state.global);
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const history = useNavigate();
  const Option = Select.Option;

  const [isTabActive, setIsTabActive] = useState("1");
  const [initialFilter, setInitialFilter] = useState({
    appraisalFilter: appraisalFilter,
    designationFilter: designationFilter,
    cycleFilter: cycleFilter,
    formStatusFilter: formStatusFilter,
    managerFormStatusFilter: managerFormStatusFilter,
    summaryNotificationFilter: summaryNotificationFilter,
    roleFilter:roleFilter
  });

 

  const [filterStatusChanged, setFilterStChanged] = useState(false);
  const [filterLevelChanged, setFilterLevelChanged] = useState(false);
  const [filterFormChanged, setFilterFormChanged] = useState(false);
  const [filterCycleChanged, setFilterCycleChanged] = useState(false);
  const [filterSummaryChanged, setfilterSummaryChanged] = useState(false);
  const [hovered, setHovered] = useState(null);
  const [hoveredCycle, setHoveredCycle] = useState(null);
  const [hoveredStatus, setHoveredStatus] = useState(null);

  const designationContent = <div>Change Designation</div>;
  const cycleContent = <div>Change Cycle</div>;
  const statusContent = <div>Change Status</div>;
  const sortAscending = <h4>Click to sort ascending </h4>;
  const sortDescending = <h4>Click to sort descending </h4>;

  const appraisalOptions = {
    active: "Active",
    inactive: "Inactive",
  };

  const summaryNotificationStatusList = {
    notSent: "Not Sent",
    approved: "Approved",
    rejected: "Rejected",
  };

  const shouldShowColumn = false;

  useEffect(()=>{
    setInitialFilter({
      appraisalFilter: appraisalFilter,
      designationFilter: designationFilter,
      cycleFilter: cycleFilter,
      formStatusFilter: formStatusFilter,
      managerFormStatusFilter: managerFormStatusFilter,
      summaryNotificationFilter: summaryNotificationFilter,
      roleFilter:roleFilter,
    })
  },
  [appraisalFilter,designationFilter,cycleFilter,formStatusFilter,managerFormStatusFilter,summaryNotificationFilter,roleFilter])

  useEffect(() => {
    console.log("initialFilterinitialFilter",initialFilter)
     dispatch(adminActions.updateFilter(initialFilter));
    dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
  }, [initialFilter]);

  // useEffect(() => {
  //   if (refreshEffect) {
  //     setInitialFilter({
  //       appraisalFilter: "",
  //       designationFilter: "",
  //       cycleFilter: "",
  //       formStatusFilter: "",
  //       summaryNotificationFilter: "",
  //     });
  //   } else {
  //     console.log("not refreshing");
  //   }
  // }, []);

  useEffect(() => {
    if (history.action === "POP" || history.action === "PUSH") {
      dispatch(adminActions.updateSearchText(""));
    }
  }, [history]);

  const handleSummaryNotificationFilter = (value) => {
    console.log("Summary Status filters", value);
    if (value === "undefined") {
      setInitialFilter(prev=>({
        ...prev,
        appraisalFilter: true,
        cycleFilter: "October",
      }));
    } else {
      setInitialFilter(prev=>({
        ...prev,
        appraisalFilter: true,
        cycleFilter: "October",
        summaryNotificationFilter: value,
      }));
    }

    setfilterSummaryChanged(!filterSummaryChanged);
  };

  const handlePageChange = (page, pageSize) => {
    console.log("ppp", tabState);
    dispatch(adminActions.updatePage({ page: page, pageSize: pageSize }));
    if (tabState === "active users") {
      setInitialFilter((prev)=>({
        ...prev,
        appraisalFilter: true,
        cycleFilter: "October",
      }));
      return;
    }
    if (tabState === "inactive users") {
      setInitialFilter(prev=>({
        ...prev,
        appraisalFilter: false,
      }));
      return;
    }
  
    dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
  };



  const openCommonModal = (row) => {
    console.log("Common Modal Data", row);
    // setAppraisalCycleOpen(true);
    dispatch(
      adminActions.updateUser_Cycle_Designation({
        Id: row?.id,
        defaultCycle: row?.appraisalCycle,
        defaultLevel: row?.associateLevel,
        defaultStatus: row?.appraisalActive
      })
    );
    dispatch(adminActions.updateDesignationModal(true));
  };

  const onAppraisalStatusClick = (data) => {
    const appraisalActive = data.appraisalActive ? false : true;
    Api.patch("/appraisal-admin/users/?userId=" + data.id)
      .params({ appraisalActive })
      .send((response, error) => {
        if (response !== "undefined" && response?.show?.type === "success") {
          dispatch(adminActions.updateSingleUser({ user: response.data }));
          dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
        }
      });
  };

  const onWorkSummaryClick = (data) => {
    dispatch(adminActions.updateWorkSummaryModal(true));
    dispatch(adminActions.updateRowData(data));
  };

  const handleMouseEnterLevel = (rowId) => {
    setHovered(rowId);
  };

  const handleMouseLeaveLevel = () => {
    setHovered(null);
  };
  const handleMouseEnterCycle = (rowId) => {
    setHoveredCycle(rowId);
  };

  const handleMouseLeaveCycle = () => {
    setHoveredCycle(null);
  };
  const handleMouseEnterStatus = (rowId) => {
    setHoveredStatus(rowId);
  };

  const handleMouseLeaveStatus = () => {
    setHoveredStatus(null);
  };

  const handleTab = (e) => {
    console.log("tabbb", e);
    setIsTabActive(e);
  };

  const content = (
    <div>
      <h4
        style={{
          paddingTop: "0.5rem",
          color: "#000000",
        }}
      >
        Appraisal Cycle
      </h4>
    </div>
  );
  const content1 = (
    <div>
      <h4
        style={{
          paddingTop: "0.5rem",
          color: "#000000",
        }}
      >
        Appraisal Form Status
      </h4>
    </div>
  );
  const content2 = (
    <div>
      <h4
        style={{
          paddingTop: "0.5rem",
          color: "#000000",
        }}
      >
        Appraisal Status
      </h4>
    </div>
  );

  const infoContent = (
    <Tabs defaultActiveKey="1" onChange={handleTab}>
      <TabPane
        tab={
          <div
            style={{
              fontWeight: "bold",
              color: isTabActive === "1" ? "#000000" : "#A1A1A1",
            }}
          >
            Details
          </div>
        }
        key="1"
      >
        <div
          style={{
            width: "20rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <img src={UserIcon} />
            <span
              style={{
                marginLeft: "0.5rem",
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Total October Active Associates
            </span>
          </div>
          <div
            style={{ color: "#0086FF", fontWeight: "bold", fontSize: "1rem" }}
          >
            {associateCount?.totalAssociate}
          </div>
        </div>
        <div
          style={{
            width: "20rem",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <div>
            <img src={sentIcon} />
            <span
              style={{
                marginLeft: "0.5rem",
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Sent
            </span>
          </div>
          <div
            style={{ color: "#39CA6C", fontWeight: "bold", fontSize: "1rem" }}
          >
            {associateCount?.sent}
          </div>
        </div>
        <div
          style={{
            width: "20rem",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <div>
            <img src={unsentIcon} />
            <span
              style={{
                marginLeft: "0.5rem",
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Unsent
            </span>
          </div>
          <div
            style={{ color: "#FF3434", fontWeight: "bold", fontSize: "1rem" }}
          >
            {associateCount?.notsent}
          </div>
        </div>
      </TabPane>
      <TabPane
        tab={
          <div
            style={{
              fontWeight: "bold",
              color: isTabActive === "2" ? "#000000" : "#A1A1A1",
            }}
          >
            Status
          </div>
        }
        key="2"
      >
        <div
          style={{
            width: "20rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <img src={pendingIcon} />
            <span
              style={{
                marginLeft: "0.5rem",
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Pending
            </span>
          </div>
          <div
            style={{ color: "#FFB70F", fontWeight: "bold", fontSize: "1rem" }}
          >
            {associateCount?.pending}
          </div>
        </div>
        <div
          style={{
            width: "20rem",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <div>
            <img src={sentIcon} />
            <span
              style={{
                marginLeft: "0.5rem",
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Approved
            </span>
          </div>
          <div
            style={{ color: "#39CA6C", fontWeight: "bold", fontSize: "1rem" }}
          >
            {associateCount?.approved}
          </div>
        </div>
        <div
          style={{
            width: "20rem",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <div>
            <img src={unsentIcon} />
            <span
              style={{
                marginLeft: "0.5rem",
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Rejected
            </span>
          </div>
          <div
            style={{ color: "#FF3434", fontWeight: "bold", fontSize: "1rem" }}
          >
            {associateCount?.rejected}
          </div>
        </div>
      </TabPane>
    </Tabs>
  );
  const inactiveInfoContent = (
    <Tabs defaultActiveKey="1" onChange={handleTab}>
      <TabPane
        tab={
          <div
            style={{
              fontWeight: "bold",
              color: isTabActive === "1" ? "#000000" : "#A1A1A1",
            }}
          >
            Details
          </div>
        }
        key="1"
      >
        <div
          style={{
            width: "20rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <img src={UserIcon} />
            <span
              style={{
                marginLeft: "0.5rem",
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Total Inactive User
            </span>
          </div>
          <div
            style={{ color: "#0086FF", fontWeight: "bold", fontSize: "1rem" }}
          >
            {associateCount?.totalInactiveUsers}
          </div>
        </div>
      </TabPane>
    </Tabs>
  );

  const columns = [
    {
      title:<div style={{fontSize:"0.95rem",fontWeight:"700", padding: "0", margin: "0" }}>Employee ID</div>,
      dataIndex: "employeeId",
      key: "employeeId",
      width: "8rem",
      fixed: 'left',
      render: (val, row) => {
        return (
          <div style={{}}>            
              <span style={{ fontSize: "0.85rem", margin: "0" }}>
                {row?.employeeId}
              </span>
          </div>
        );
      },
    },
    {
      title:<div style={{fontSize:"0.95rem",fontWeight:"700", padding: "0", margin: "0" }}>Associate</div>,
      dataIndex: "associate",
      key: "associate",
      width: "9rem",
      fixed: 'left',
      render: (val, row) => {
        let flag = row?.manualChange;
        return (
          <div style={{ display: "flex", }} onClick={() => {
            history("/appraisal/admin/associateDetails", {
              state: row?.id,
            })}}
          >
            <img
                            style={{
                              height: "1.5rem",
                              width: "1.5rem",
                              marginRight: "0.2rem",
                              // marginTop: "1rem",
                            }}
                            src={UserIcon}
                          />
                          <Typography.Text style={{color: "#162432",width:"10rem",fontSize:"0.83rem",fontStyle: "Lato",cursor:"pointer"}} 
                            ellipsis={{tooltip:true}}>
                                    {/* <span /style={{color: "#162432",fontSize:"0.8rem",fontStyle: "Lato",cursor:"pointer"}} > */}
                                      {row?.name}
                                      {/* </span> */}
                          </Typography.Text>
            {/* {console.log("rorrrrrrr", row?.id)} */}
              {/* <img
                style={{
                  height: "1.5rem",
                  width: "1.5rem",
                  marginRight: "0.2rem",
                  // marginTop: "1rem",
                }}
                src={UserIcon}
              /> */}
            {/* <div
              style={{
                color: "black",
                 width: "5rem",
                whiteSpace: "nowrap",
                marginLeft: "0.2rem",
              }}
            > */}
              {/* <a type="link">
                <Popover content={row?.name}>
                  <h5
                    style={{
                      fontSize: "0.9rem",
                      width: "100%",
                      textOverflow: "ellipsis",
                      overflow: "hidden ",
                      cursor: "pointer",
                      margin: "0",
                    }}
                    onClick={() => {
                      history("/appraisal/admin/associateDetails", {
                        state: row?.id,
                      });
                    }}
                  >
                    {row?.name}
                  </h5>
                </Popover>
              </a> */}
              {/* <Popover content={row?.userView?.designation}>
                <h6
                  style={{
                    fontSize: "0.75rem",
                    margin: "0",
                    width: "5rem",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden ",
                  }}
                >
                  {row?.designation}
                </h6>
              </Popover> */}
            </div>
            
        );
      },
    },
    // {
    //   title:
    //   <div style={{fontSize:"1rem",width:"100%",fontWeight:"600"}}>Contact Details</div>,
    //   dataIndex: "contactDetails",
    //   key: "contactDetails",
    //   width: "7rem",

    //   render: (val, row) => (
    //     <div
    //       style={{
    //         color: "black",
    //         width: "100%",
    //         display: "flex",
    //         // justifyContent: "center",
    //         flexDirection: "column",
    //         whiteSpace: "nowrap",
    //         // marginLeft:'1rem',
    //       }}
    //     >
    //       <Popover content={row?.email}>
    //         <h5
    //           style={{
    //             fontSize: "1rem",
    //             width: "7rem",
    //             textOverflow: "ellipsis",
    //             overflow: "hidden ",
    //             cursor: "pointer",
    //             color: "#162432",
    //             fontFamily: "Roboto-Regular",
    //           }}
    //         >
    //           {row?.email}
    //         </h5>
    //       </Popover>
    //       <span style={{ fontSize: "0.75rem" }}>{row?.phone}</span>
    //     </div>
    //   ),
    // },
    // {
    //   title:
    //   <div style={{fontSize:"1rem",fontWeight:"600",width:"100%"}}>Work Summary</div>,
    //   width: "7rem",
    //   dataIndex: "workSummary",
    //   key: "workSummary",
    //   render: (val, row) => (
    //     <div
    //       style={{ width: "100%", display: "flex", }}
    //     >
    //       {
    //         <a
    //           type="text"
    //           style={{
    //             color: "black",
    //             fontSize: "0.90rem",
    //             display:"flex",
    //             cursor:"pointer"
    //           }}
    //           onClick={() => onWorkSummaryClick(row)}
    //         >
    //           <PlusCircleOutlined style={{ marginRight: "0.3rem" }} />

    //           {row?.workSummary?.length > 0 ? (
    //             <div> Edit Summary</div>
    //           ) : (
    //             <div> Add Summary</div>
    //           )}
    //           {/* <span>{row?.workSummary?.length}</span> */}
    //         </a>
    //       }
    //     </div>
    //   ),
    // },
    {
      title:
      <div style={{fontSize:"0.95rem",fontWeight:"700",}}>Level</div>,
      key: "level",
      width: "8rem",

      render: (val, row) => {
        return (
          <div style={{ display: "flex",  }}>
            {/* <div style={{ width: "8rem", fontSize: "1rem" }}> */}
              {row?.associateLevel}
            {/* </div> */}
            
          </div>
        );
      },
    },
    {
      title:
      <div style={{fontSize:"0.95rem",fontWeight:"700",}}>Cycle</div>,
      dataIndex: "appraisalCycle",
      key: "appraisalCycle",
      width: "8rem",
      render: (val, row) => {
        return (
          <div style={{ display: "flex", }}>
            {/* <div style={{ width: "5rem" }}> */}
              {row?.appraisalCycle}
              {/* </div> */}

            {/* <div style={{}}> */}
              {/* <a
                type="primary"
                onClick={() => {
                  dispatch(adminActions.updateModalType("Edit"));
                  openCommonModal(row);
                  console.log("ssRoww");
                }}
              > */}
                {/* <Popover
                  key={row.id}
                  content={cycleContent}
                  trigger="hover"
                >
                  <img
                 
                    onMouseEnter={() => handleMouseEnterCycle(row?.id)}
                    onMouseLeave={handleMouseLeaveCycle}
                    src={hoveredCycle === row.id ? refreshIcon : cycleStatus}
                    style={{ width: "1.2rem",cursor:"pointer" }}
                    alt=""
                  />
                </Popover> */}
              {/* </a> */}
            {/* </div> */}
          </div>
        );
      },
    },
    {
      title:
      <div style={{fontSize:"0.95rem",fontWeight:"700",}}>Associate's form Status</div>,
      width: "13rem",
      dataIndex: "formStatus",
      key: "formStatus",
      render: (val, row) => {
        return (
          <div
            style={{
              // width: "8rem",
              display: "flex",
              // justifyContent: "center",
            }}
          >
             <Typography.Text style={{color: "#162432",width:"11rem",fontSize:"0.83rem",fontStyle: "Lato",}} 
                            ellipsis={{tooltip:true}}>
                {/* <span style={{color: "#162432",fontSize:"0.8rem",fontStyle: "Lato",cursor:"pointer"}} > */}
                {row?.formStatus}
                {/* </span> */}
            </Typography.Text>
          </div>
        );
      },
    },
    {
      title:
      <div style={{fontSize:"0.95rem",fontWeight:"700",}}>Manager</div>,
      // width: "7rem",
      dataIndex: "managerName",
      key: "managerName",
      onCell: (row, index) => {
        if (index === 0) {
          return {
            columnSpan: row.workSummary.length,
          };
        }
      },
      render: (val, row) => {
        return row.workSummary.map((item, index) => (
          <div
            key={index}
            style={{
              marginBottom:"1rem",
             
            }}
          >
            <Typography.Text style={{color: "#162432",width:"11rem",fontSize:"0.83rem",fontStyle: "Lato",}} 
                            ellipsis={{tooltip:true}}>
                                    {item.manager ? item.manager.name : "None"}
            </Typography.Text>
            
          </div>
        ));
      },
    },
    {
      title:
      <div style={{fontSize:"0.95rem",fontWeight:"700",display:"flex",justifyContent:"flex-start",width:"100%"}}>
        Manager's form Status
      </div>,
      width: "14rem",
      dataIndex: "managerFormStatus",
      key: "managerFormStatus",
      onCell: (row, index) => {
        // console.log("ssssRecord",row)
        if (index === 0) {
          return {
            columnSpan: row.workSummary.length,
          };
        }
      },
      render: (val, row) => {
        return row.workSummary.map((item, index) => (
          <div
            key={index}
            style={{
              marginBottom:"1rem",display:"flex",justifyContent:"flex-start"
              // alignItems: "center",
              // justifyContent: "center",
              // height: "100%",
            }}
          >
            <Typography.Text style={{color: "#162432",maxWidth:"11rem",fontSize:"0.83rem",}} 
                            ellipsis={{tooltip:true}}>
                {/* <span style={{color: "#162432",fontSize:"0.8rem",}} > */}
                  {item.manager ? item.managerStatus : "None"}
                  {/* </span> */}
            </Typography.Text>
          </div>
        ));
      },
    },
    {
      title:
      <div style={{fontSize:"0.95rem",fontWeight:"700",}}>Work Summary Status</div>,
      width: "14rem",
      dataIndex: "workSummaryStatus",
      key: "workSummaryStatus",
      render: (val, row) => {
        return (
          <div style={{}}>
              {row?.sentMail ? "Link sent" : "Not sent"}
          </div>
        );
      },
    },
    {
      title:
      <div style={{fontSize:"0.95rem",fontWeight:"700",}}>Appraisal Status</div>,
      width: "11rem",
      dataIndex: "appraisalStatus",
      key: "appraisalStatus",
      render: (val, row) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="text"
                style={{
                  background: row?.appraisalActive ? "#EFFCF3" : "#EFEFEF",
                  color: row?.appraisalActive ? "#26DE81" : "#808080",
                  
                  width: "6rem",
                  fontSize: "0.87rem",
                  borderRadius: "4px",
                }}
                onClick={() => onAppraisalStatusClick(row)}
              >
                {row?.appraisalActive ? "Active" : "Inactive"}
              </Button>
              {/* <a style={{ position: "relative", left: 10 }}>
                <Popover
                  key={row.id}
                  content={statusContent}
                  trigger="hover"
                  // visible={hoveredStatus === row.id}
                  placement="bottomLeft"
                >
                  <img
                    onMouseEnter={() => handleMouseEnterStatus(row?.id)}
                    onMouseLeave={handleMouseLeaveStatus}
                    src={hoveredStatus === row.id ? refreshIcon : cycleStatus}
                    style={{ width: "1.2rem",cursor:"pointer" }}
                    alt=""
                    onClick={() => onAppraisalStatusClick(row)}
                  />
                </Popover>
              </a> */}
          </div>
        );
      },
    },
    {
      title:
      <div style={{fontSize:"0.95rem",fontWeight:"700",width:"100%"}}>Actions</div>,
      width: "8.5rem",
      dataIndex: "actions",
      key: "actions",
      fixed: 'right',
      render: (val, row) => {
        return (
          <div style={{display:"flex",flexDirection:"row",gap:"0.8rem"}}>
              <img src={EditIcon} style={{height:"1.2rem",width:"1.3rem",cursor:"pointer"}} 
                              onClick={()=>{
                                dispatch(adminActions.updateModalType("Edit"));
                                openCommonModal(row);
              }} />
              <img src={SummaryIcon} style={{height:"1.3rem",width:"1.3rem",cursor:"pointer"}}
                              onClick={()=>{
                                onWorkSummaryClick(row)
              }} />              
          </div>
        );
      },
    },
  ];

  const modifiedColumns = [
    ...columns.filter(
      (column) => shouldShowColumn || column.key !== "appraisalCycle"
    ),
    {
      title: (
        <div style={{ display: "flex", justifyContent: "center",
        color: "#000000",
      }}>
          Actions
          <Popover placement="left" content={infoContent} trigger="click">
            <img
              src={info}
              style={{ marginLeft: "0.6rem", cursor: "pointer" }}
            />
          </Popover>
          <Select
            suffixIcon={
              <AiFillFilter
                style={{
                  color:
                    appraisalFilter === "" ||
                    appraisalFilter === null ||
                    appraisalFilter === undefined
                      ? "#787b83"
                      : Color.modalblue,
                }}
              />
            }
            allowClear={filterSummaryChanged}
            trigger="click"
            type="text"
            placement="bottomLeft"
            bordered={false}
            dropdownMatchSelectWidth={false}
            onChange={handleSummaryNotificationFilter}
            value={initialFilter}
            removeIcon={true}
            // disabled={tabState === "active users" ? true : false}
          >
            {Object.values(summaryNotificationStatusList).map((item) => (
              <Option value={item} label={item} title="" />
            ))}
          </Select>
        </div>
      ),
      dataIndex: "actions",
      key: "actions",
      width: "8rem",
      render: (val, row) => {
        return (
          <div
            style={{
              // border: "1px solid black",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <h5
              style={{
                fontWeight: "600",
                // border: "1px solid black",
              }}
            >
              {row?.status}
            </h5>
            <div
              style={{
                width: "10rem",
                display: "flex",
                justifyContent: "space-between",
                // border: "1px solid black",
              }}
            >
              <Button
                type="primary"
                style={{ borderRadius: "4px" }}
                onClick={() => {
                  sendAllRequest({ userIds: [row?.id] });
                  dispatch(
                    adminActions.updateRefreshEffect((olddata) => olddata + 1)
                  );
                }}
                disabled={
                  row?.status === "Pending" ||
                  row?.status === "Approved" ||
                  row?.status === "Rejected"
                    ? true
                    : false
                }
              >
                Send
              </Button>
              <Button
                type="primary"
                style={
                  {
                    // color: "#0086FF",
                    // borderColor: "#0086FF",
                    // borderRadius: "4px",
                  }
                }
                onClick={() => {
                  sendAllRequest({ userIds: [row?.id] });
                  dispatch(
                    adminActions.updateRefreshEffect((olddata) => olddata + 1)
                  );
                }}
                disabled={
                  row?.status === "Pending" ||
                  row?.status === "Approved" ||
                  row?.status === "NotSent"
                    ? true
                    : false
                }
              >
                Resend
              </Button>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div style={{width:'100%',height:'100%'}}>
      {/* <AssociateTable
        loading={loader}
        dataSource={users}
        columns={props?.October === "active users" ? modifiedColumns : columns}
        rowKey={(record) => record?.id}
        meta={meta}
        style={{ width: "100%", height: "71vh" }}
        onChange= {(page, pageSize) => handlePageChange(page, pageSize)}
      /> */}

      {/* <div style={{backgroundColor:"#F9F9F9",width:"100%",marginTop:"0.5rem"}}>
            <div className={classes.ListContainer}>
            <div className={classes.ListHeader} style={{fontSize: "0.93rem",fontWeight: 500}}>
                        <div className={classes.FixedColumn} style={{borderRight:"1px solid #DFDFDF"}}>
                          <span style={{paddingLeft:"0.7rem",minWidth:"8rem",display:"flex",flexDirection:"row",alignItems:"top"}}>
                            Employee Id
                            
                          </span>
                          <span style={{minWidth:"12rem"}}>Name</span>
                        </div>
                          <span style={{minWidth:"8rem",paddingLeft:"0.7rem"}}>Level</span>
                          <span style={{minWidth:"7rem"}}>Cycle</span>
                          <span style={{minWidth:"14rem"}}>Associate’s form status</span>
                          <span style={{minWidth:"11rem"}}>Manager</span>
                          <span style={{minWidth:"14rem"}}>Manager's form status</span>
                          <span style={{minWidth:"10rem"}}>Appraisal Status</span>
                          <div className={classes.FixedColumnRight} style={{borderLeft:"1px solid #DFDFDF"}}>
                          <span style={{minWidth:"8rem",paddingLeft:"1.5rem"}}>Actions</span>
                          </div>
            </div>
            {loader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'71%',width:"100%"}}><Spin /></div>:
                     users?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'40vh',width:"100%"}}><div>No data found</div></div>:
                     users?.map((record,index)=>{
                        return(
                          <div className={classes.rowContainer}
                          style={{fontSize: "0.9rem",fontStyle: "Lato",}}>
                          <div className={classes.FixedColumns} style={{borderRight:"1px solid #DFDFDF"}}>
                          <div style={{minWidth:"8rem",paddingLeft:"0.7rem",fontSize: "0.89rem"}} >{record?.employeeId}</div>
                          <div style={{minWidth:"12rem",alignItems:"center",display:"flex"}} 
                          onClick={() => {
                            history("/appraisal/admin/associateDetails", {
                              state: record?.id,
                            });
                          }}>
                          <img
                            style={{
                              height: "1.5rem",
                              width: "1.5rem",
                              marginRight: "0.2rem",
                              // marginTop: "1rem",
                            }}
                            src={UserIcon}
                          />
                          <Typography.Text style={{color: "#162432",maxWidth:"10rem",fontSize:"0.8rem",fontStyle: "Lato",}} 
                            ellipsis={{tooltip:true}}>
                                    <span style={{color: "#162432",fontSize:"0.8rem",fontStyle: "Lato",cursor:"pointer"}} >{record?.name}</span>
                          </Typography.Text>
                          </div>
                          </div>
                          <div style={{minWidth:"8rem",paddingLeft:"0.7rem"}} >{record?.associateLevel}</div>
                          <div style={{minWidth:"7rem"}} >{record?.appraisalCycle}</div>
                          <div style={{minWidth:"14rem"}} >{record?.formStatus}</div>
                          <div style={{minWidth:"11rem"}} >
                            {record?.workSummary?.length > 0 ? 
                            <div style={{display:"flex",flexDirection:"column",overflow:record?.workSummary?.length > 1&&"auto",height:record?.workSummary?.length > 1&&"4rem",gap:"0.9rem",width:"100%"}} 
                              className={classes.scrollDesign}>
                              {record?.workSummary?.map((item,index)=>{
                                return(
                                <div style={{
                                  // borderBottom:"1px solid #DFDFDf",
                                  }}>
                                    <Typography.Text style={{maxWidth:"9rem",fontSize:"0.8rem",fontWeight:410}} 
                                      ellipsis={{tooltip:true}}>
                                            <span style={{fontSize:"0.8rem",fontWeight:410}}>
                                              {Object.keys(item?.manager)?.length > 0 ?
                                              item?.manager?.name
                                              :"None"
                                              }
                                            </span>
                                    </Typography.Text>
                                  </div>)
                              })}
                            </div>
                            :
                              "None"
                            }
                            </div>
                          <div style={{minWidth:"14rem"}} >
                          {record?.workSummary?.length > 0 ? 
                            <div style={{display:"flex",flexDirection:"column",gap:"0.9rem",width:"100%",overflow:record?.workSummary?.length > 1&&"auto",height:record?.workSummary?.length > 1&&"4rem"}} 
                              className={classes.scrollDesign}>
                              {record?.workSummary?.map((item,index)=>{
                                return(<div style={{
                                  // borderBottom:"1px solid #DFDFDf",
                                  }}>
                                    {item?.managerStatus}
                                  </div>)
                              })}
                            </div>
                            : 
                            "None"
                          }
                          </div>
                          <div style={{minWidth:"10rem"}} >
                          <div
                            style={{
                              background: record?.appraisalActive ? "#EFFCF3" : "#EFEFEF",
                              color: record?.appraisalActive ? "#26DE81" : "#808080",
                              width: "6rem",
                              fontSize: "0.9rem",
                              padding:"0.4rem 0",textAlign:"center",
                              borderRadius: "10px",
                            }}
                          >
                            {record?.appraisalActive ? "Active" : "Inactive"}
                          </div>
                          </div>
                          <div className={classes.FixedColumnsRight} style={{borderLeft:"1px solid #DFDFDF"}}>
                          <div style={{minWidth:"8rem",paddingLeft:"1.5rem",}} >
                            <div style={{display:"flex",flexDirection:"row",gap:"0.8rem"}}>
                              <img src={EditIcon} style={{height:"1.2rem",width:"1.3rem",cursor:"pointer"}} 
                              onClick={()=>{
                                dispatch(adminActions.updateModalType("Edit"));
                                openCommonModal(record);
                              }} />
                              <img src={SummaryIcon} style={{height:"1.3rem",width:"1.3rem",cursor:"pointer"}}
                              onClick={()=>{
                                onWorkSummaryClick(record)
                              }} />
                            </div>
                          </div>
                          </div>
                          </div>
                        )
                      })
            }
            </div>
          <div style={{display:"flex",justifyContent:"flex-end"}}>
          <Pagination
              position="bottomRight"
              pageSize={Number(meta?.itemsPerPage)}
              size="small"
              pageSizeOptions={[10, 20, 30, 50]}
              current={Number(meta?.currentPage)}
              total={Number(meta?.totalItems) ? Number(meta?.totalItems) : 0}
              onChange={(page, pageSize) => handlePageChange(page, pageSize)}
              showSizeChanger={true}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            />
          </div>
            </div> */}
        

       <Table bordered = {true}
        loading={loader} 
        dataSource={users}
        className={classes.scrollDesign}
        columns={props?.April === "active users" ? modifiedColumns : columns}
        rowKey={(record) => record?.id}
        pagination={{
          position: "bottomLeft", // change the position to "bottomLeft"
          pageSize: Number(meta?.itemsPerPage), // set pageSize to 10 to display only 10 records
          size: "small",
          pageSizeOptions: [5, 10, 20, 30, 50],
          current: Number(meta?.currentPage),
          total: Number(meta?.totalItems) ? Number(meta?.totalItems) : 0,
          onChange: (page, pageSize) => handlePageChange(page, pageSize),
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          // defaultPageSize: Number(meta?.itemsPerPage)
        }}
        scroll={{
          y: "57vh",x:1700
        }}
        style={{ borderCollapse: 'collapse',padding:0,}} 
      /> 

      <WorkSummaryModal />
      <CommonModal />
    </div>
  );
};

export default UserTable;
