import React, { useEffect, useState, useRef } from 'react';
import { Input, Image } from "antd";
import { FaImage } from "react-icons/fa";
import { IoMdSend } from "react-icons/io";
import conferenceStore from './store';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { UploadFile } from './action';
import ProfileIcon from "../../assets/profileIcon.svg";
import Notification from '../../services/Notification';
import CloseIcon from "../../assets/closeIcon.svg";

const ChatScreen = ({ brigoshaMedia, setchat,chatModalRef }) => {
    const [newMessage, setNewMessage] = useState('');
    const [joined, setJoined] = useState("");
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const scrollingDivRef = useRef(null);
    const urlRegex = /((https?|ftp):\/\/[^\s/$.?#].[^\s]*)/i;
    const { userG, meetIdG, chatClient, groupChat, updateMssgsent, updateMssgToken, mssgToken, updateMssgsentRed, activeUserList } = conferenceStore(state => state);
    const fileInputRef = useRef(null);
    useEffect(() => {
        let data = activeUserList.filter(ele => ele?.id === userG?.id);
        console.log("data", data, groupChat);
        setJoined(data?.[0]?.joined);
        return () => {
            updateMssgsentRed(false);
        };
    }, []);

    const generateRandomString = () => {
        let uvid = uuidv4();
        updateMssgToken(uvid);
    };

    const handleSendMessage = async () => {
        if (newMessage.trim() !== '') {
            generateRandomString();
            updateMssgsent(true);
            OnSendMessage(newMessage);
            setNewMessage('');
        }
        if (selectedImage) {
            generateRandomString();
            updateMssgsent(true);
            sendImageMessage(selectedImage);
            setSelectedImage(null);
            setImagePreviewUrl('');
        }
    };

    const handleFileUpload = (event) => {
        console.log("imageUrl",event);
        const fileList = event.target.files;
        const imageFiles = Array.from(fileList).filter(file => file.type.startsWith('image/'));
        if (imageFiles.length === 0) {
            Notification.error("Kindly upload image file");
            return;
        }
        const file = imageFiles[0];
        setSelectedImage(file);
        setImagePreviewUrl(URL.createObjectURL(file));
        console.log("imageUrl",URL.createObjectURL(file));
    };

    const sendImageMessage = (file) => {
        UploadFile({ file: [file] }, (res, per) => {
            if (per === 100 && res) {
                console.log("res", res);
                let time = moment().format('HH:mm:ss A');
                chatClient.sendGroupChat(userG?.id, meetIdG,
                    {
                        type: 'Image',
                        data: {
                            type: "Image",
                            image: res,
                            createdAt: time,
                        },
                        userId: userG?.id,
                        roomId: meetIdG,
                        userName: userG?.name,
                        token: mssgToken,
                        sentOn: time,
                        profileImage: userG?.profileImage || null,
                    }
                );
            }
        });
    };
    const scrollToBottom = () => {
        if (scrollingDivRef.current) {
            scrollingDivRef.current.scrollTop = scrollingDivRef.current.scrollHeight;
        }
    };
    // useEffect(() => {
    //     if (scrollingDivRef.current) {
    //         console.log("Scrolling to bottom", scrollingDivRef.current.scrollHeight);
    //         scrollingDivRef.current.scrollTop =scrollingDivRef.current.scrollHeight;
    //     }
    // }, [groupChat]);
    useEffect(() => {
        // Use a timeout to ensure the DOM is updated before scrolling
        setTimeout(scrollToBottom, 100);
    }, [groupChat]);

    useEffect(() => {
        // Initial scroll to bottom
        setTimeout(scrollToBottom, 100);
    }, []);
    const handleLinkClick = (e, link) => {
        e.preventDefault();
        window.open(link, '_blank');
    };

    const OnSendMessage = (text) => {
        let time = moment().format('HH:mm:ss A');
        chatClient?.sendGroupChat(userG?.id, meetIdG,
            {
                type: 'Text',
                data: text,
                userId: userG?.id,
                roomId: meetIdG,
                userName: userG?.name,
                token: mssgToken,
                profileImage: userG?.profileImage || null,
                sentOn: time
            }
        );
    };

    return (
        <div
            style={{
                height: '100%',
                width: '20vw',
                position: "relative",
                top: 0,
                zIndex: 3,
                backgroundColor: "#2D2D2D",
                borderRadius: 10
            }}
        >
            <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end", width: "100%", marginTop: 0, padding: 10, height: 32, cursor: "pointer" }} onClick={() => {setchat(false) 
                chatModalRef.current=false
            }}>
                <img src={CloseIcon} style={{ height: 14, width: 14 }} />
            </div>
            <div
                ref={scrollingDivRef}
                style={{
                    height: "56vh",
                    overflowY: 'scroll',
                }}
            >
                {groupChat?.length ?
                    groupChat.filter(ele => moment(ele?.sentOn, "HH:mm:ss A").diff(moment(joined, "HH:mm:ss A")) > 0)?.map((message, index) => (
                        <React.Fragment key={index}>
                            {message?.type === "Image" ? (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        padding: 5,
                                        marginLeft: 5,
                                        marginTop: 4,
                                        alignItems: message.sender === 'user' ? 'flex-end' : 'flex-start'
                                    }}
                                >
                                    <div style={{ backgroundColor: "#959595", borderRadius: 5, width: "15vw", padding: 10, display: "flex", flexDirection: "column" }}>
                                        <div style={{ color: "#CACACA", fontSize: 8, fontWeight: 500 }}>{message?.userName}</div>
                                        <div style={{ color: "white", fontSize: 10, fontWeight: 300, display: "flex", flexWrap: "wrap", width: "13vw", justifyContent: "center", alignItems: "center" }}>
                                            <Image style={{ height: 50 }} src={message?.data?.image} />
                                        </div>
                                    </div>
                                    <div style={{ color: "#959595", fontSize: 9, marginLeft: message.sender === 'user' ? 50 : 5,marginTop:4,fontWeight:"inherit" }}>
                                        <img src={message?.profileImage || ProfileIcon} style={{ height: 26, width: 26, borderRadius: 13, padding: 1, backgroundColor: "gray" }} />
                                        {moment(message?.sentOn, 'HH:mm:ss').format('hh:mm A')}
                                    </div>
                                </div>
                            ) : (
                                message?.data && (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: 5,
                                            marginLeft: 5,
                                            marginTop: 8,
                                            alignItems: message.sender === 'user' ? 'flex-end' : 'flex-start'
                                        }}
                                    >
                                        <div style={{ backgroundColor: "#959595", borderRadius: 5,width:"15vw", paddingTop: 10,paddingBottom: 10, display: "flex", flexDirection: "column" }}>
                                            <div style={{ color: "#CACACA", fontSize: 10, fontWeight: 500,paddingLeft:5,fontWeight:500 }}>{message?.userName}</div>
                                            <div style={{ color: "white", fontSize: 14, fontWeight: 300, display: "flex",paddingLeft:5, flexWrap: "wrap", width: "100%", wordWrap: 'break-word' }}>
                                                {urlRegex.test(message.data) ?
                                                    <a href={message.data} onClick={(e) => handleLinkClick(e, message.data)} style={{ wordBreak: 'break-all',color:"#3455eb" }}>{message.data}</a>
                                                    :
                                                    message.data
                                                }
                                            </div>
                                        </div>
                                        <div style={{ color: "#959595", fontSize: 9, marginLeft: message.sender === 'user' ? 50 : 5,marginTop:4,fontWeight:"bold" }}>
                                            <img src={message?.profileImage || ProfileIcon} style={{ height: 26, width: 26, padding: 1, borderRadius: 13, backgroundColor: "gray" }} />
                                            {moment(message?.sentOn, 'HH:mm:ss').format('hh:mm A')}
                                        </div>
                                    </div>
                                )
                            )}
                        </React.Fragment>
                    ))
                    :
                    <></>
                }
            </div>

            {imagePreviewUrl && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 4 }}>

                    <img src={imagePreviewUrl} alt="Image Preview" style={{ maxHeight: '45px',width:"35px", borderRadius: '5px',marginBottom:5}} />
                    <img  onClick={() => { setSelectedImage(null); setImagePreviewUrl(''); }}  src={CloseIcon} style={{cursor:"pointer",height:10,width:10,}}  />
                    
                </div>
            )}

            <div style={{ display: "flex", border: "0.5px solid #FFFFFF", gap: 6, height: "10%", justifyContent: "center", width: "90%", margin: "5%" }}>
            
                <textarea
                    value={newMessage}
                    name="Text1"
                    cols="40"
                    rows="5"
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            if (event.shiftKey) {
                                return;
                            } else {
                                handleSendMessage();
                                event.preventDefault();
                            }
                        }
                    }}
                    onChange={(e) => setNewMessage(e.target.value)}
                    style={{ border: "0px solid green", height: "100%", backgroundColor: "#2D2D2D", color: "white", outline: 'none', padding: '5px', width: "80%" }}
                    placeholder='Type here'
                ></textarea>

                <input
                 ref={fileInputRef}
                    type="file"
                    id="fileInput"
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                    
                />
                <FaImage onClick={() => {fileInputRef.current.click()}} style={{ color: "white", height: 20, width: 20,marginTop:4 }} />
                <IoMdSend onClick={handleSendMessage} style={{ color: "white", height: 20, width: 20, cursor: "pointer",marginTop:4 }} />
            </div>

            <style>
                {`
                    ::-webkit-scrollbar {
                        width: 4px;
                    }
                `}
            </style>
        </div>
    );
};

export default ChatScreen;
