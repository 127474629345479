import React, {  useState,  } from 'react';

import { Col, Row } from 'antd';
import BarGraph from '../BarGraph';
import './Graph.css';
import { NoData } from '../Department/NoData';

function Graphs(props) {
  // console.log("mila mila", props)







if(props?.data?.length!==0&&props?.data)
{
  return (
    <Row className='graph-content' >
{ props?.data?.map((val,index)=>{
 let data=[{y:val?.count?.oneStar,x:"😒",id:val?.details?.questionId,},{y:val?.count?.twoStar, x:"😧"},{y:val?.count?.threeStar,x:'🤨'},{y:val?.count?.fourStar,x:"😃"},{y:val?.count?.fiveStar,x:"😍"}]
 let title={y:"Number of Associates", x:"Rating"};
 let question=val?.details?.question;
//  for(let i=0;i<5;i++)
//  {
//  data.push({type:val?.ratingsData[i]?.rating, sales:val?.ratingsData[i]?.count})
//  }
//  console.log("zzzzzz",data)
            return(
          <Col style={{width:"100%",}}>
<BarGraph dateMonth={props?.dateMonth} selectedDept={props?.selectedDept} data={data} graphHeight={345} barColor="#5E8CFC" question={index+1+". "+question} title={title} date={props?.date} deptId={props?.deptId}></BarGraph>
</Col>
            )    
                
               
         })}
    </Row>

    )
  }
  else
  {
    return(
      <div>
        <NoData height="45vh"/>
      </div>
    )
  }
}

export default Graphs;