import Api from "../../../services/Api";

export const  getLogsDepartmentApi=(callback, params=null)=>{
    Api.get("/project-logs").params(params).send(res=>{

        callback(res);
    })
}

export const  getTasks=(params,callback)=>{
    Api.get(`/project-logs/all-logs`).params(params)
    .send((response) => {
        if (response) {
          callback(response);
        }
      });
}


export const  getLogsList=(callback, params=null,Tooltip,RenderButton,NameComp)=>{
  let arr=[]
  Api.get("/project-logs").params(params).send(res=>{
    let temp = res;
    temp?.forEach(element => {
        let obj = {
            ...element,
            contactDetails: (
                <Tooltip title={`${element?.email} | ${element?.phone}`}>
                    <div style={{ width: "20rem", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                        {`${element?.email}`}
                    </div>
                    <div>
                        {element?.phone}
                    </div>

                </Tooltip>
            ),
            actions: (
                <div>
                    <RenderButton record={element}/>
                </div>
            ),
            nameC: (
                <>
                <NameComp element={element}/>
                </>
            )
        }
        arr.push(obj)
    })
      callback(arr);
  })
}