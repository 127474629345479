import React, { useState , useEffect} from "react";
import Notification from "../services/Notification";

export const NavBar = ({data,onClick,defaultView}) =>{
    useEffect(()=>{
        SetNav(defaultView)
    },[defaultView])
    const [nav,SetNav]=useState(defaultView);
    return (
        <>
            <div style={{display:"flex",width:"100%", cursor:"pointer",gap:'2.5rem'}}>
                   { data.map((item,index)=>(
                        <div onClick={()=>{
                            let flag=localStorage.getItem('b1faff')
                            if(flag && window.location.pathname==="/profileDetails/"){
                                if(flag==='false'){
                                    Notification.error("error",'Please verify your number')
                                    return;
                                }
                            }
                            SetNav(item)
                            onClick(item)
                            }}>
                                <div style={{color:nav===item?"#0086FF":"#A6A9B8"}}>{item}</div>
                                {
                                    nav===item&&
                                    <div style={{height:"3px",backgroundColor:"#0086FF", borderRadius:10}}></div>
                                }
                                
                        </div>
                   ))}
            </div>
        </>
    )
}
