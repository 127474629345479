import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import Mailicons from "../assets/MailIcons.svg";
import ExclamationIcon from "../assets/ExclamationIcon.svg";
import Api from "../services/Api";

const RejectRequest = () =>{
    const [pageValidaty,setPageValidity] = useState(null);
    const [errorMessage,setErrorMessage] = useState(null);
    const [loading,setLoading] = useState(false);
    const url = new URL(window.location.href);
    const phone = url.searchParams.get("phone");
    const email = url.searchParams.get("email");
    const accept = url.searchParams.get("accept");
    const oldPhone = url.searchParams.get("oldPhone");
    const oldEmail = url.searchParams.get("oldEmail"); 


    useEffect(()=>{
        setLoading(true);
        Api.get(`/hiring-publicapi/updateCandidateInfoDetails?oldPhone=${oldPhone}&phone=${phone}&oldEmail=${oldEmail}&email=${email}&accept=${accept}`)
           .send((res)=>{
            setLoading(false);
            if(res?.show?.type === "success"){
                setPageValidity("success");
            }else{
                setPageValidity("error");
                setErrorMessage(res?.show?.message)
            }
           })
    },[]);

    return(
        <div style={{height:"100vh",width:"100%",display:"flex",flexDirection:"column",gap:"0.3rem",alignItems:"center",justifyContent:"center"}}>
            {loading ? 
            <Spin />
            :
            <div>
                {pageValidaty === "success" ?<>
                 <img src={Mailicons} alt="Mail" />
                 <h2>Rejected!</h2>
                 <span style={{fontSize:"0.96vw"}}>You have officially rejected the request.</span>
                </>
                :<div style={{width:"100%",display:"flex",gap:"0.5rem",justifyContent:"center",alignItems:"center"}}>
                 <img src={ExclamationIcon} alt="Error" />
                 <span style={{color:"#808080",fontSize:20,fontWeight:550}}>Action already taken, contact Technical Team.</span>
                </div>
                }
            </div>
         }
        </div>
    )
}
export default RejectRequest;