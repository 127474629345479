import React, {useState, useEffect, useRef} from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Pagination, Tooltip } from 'antd';
import NoDataImg from '../../assets/nodata.svg';

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);

const TableCustom = ({ columns, dataSource, loading, pagination = null, styles = null, charBreak = 20, EmptyText = "No Data", scrollCap=false }) => {
    const scrollRef = useRef(null);


    useEffect(() => {
        const savedScrollPosition = localStorage.getItem('TSP1900');
        if (savedScrollPosition && scrollRef.current) {
            scrollRef.current.scrollTo(0, parseInt(savedScrollPosition));
        }
    }, [dataSource?.length]);

    const handleScroll = (e) => {
        if (scrollRef.current) {
            const scrollTop = scrollRef.current.scrollTop;
            localStorage.setItem('TSP1900', scrollTop.toString());
        }
    };
    return (
        <>
            <div  style={{ display: "flex", flexDirection: "column", width: "fit-content", height: "90%", backgroundColor: "#F9F9F9", padding: 10,minWidth:'100%' }}>
                <div style={{ display: "flex", gap: 50, height: 30, alignItems: "flex-end" }}>
                    {
                        Object.keys(columns).map((item, index) => (
                            <div style={{ width: columns[item].width, display: "flex", alignItems: "center", justifyContent: "flex-start", fontSize: 15, color: "#16243280", fontWeight: 700, paddingLeft: index === 0 ? 10 : 0 }}>
                                {columns[item].cols}
                            </div>
                        ))
                    }
                </div>
                {
                    loading ?
                        <div style={{ width: "100%", height: styles?.height ? styles?.height : "60vh", display: "flex", alignItems: "center", justifyContent: "center", }}>
                            <Spin indicator={antIcon} />
                        </div>
                        :
                        dataSource?.length ?
                            <>
                                <div ref={scrollRef}  onScroll={scrollCap?handleScroll:null} style={{ marginTop: "1rem", height: styles?.height ? styles?.height : "60vh", overflowY: "auto", display: "flex", flexDirection: "column", gap: "1rem", width:'fit-content',minWidth:'100%'}}>
                                    {
                                        dataSource?.map((data, index) => (
                                            <div style={{ display: "flex", gap: 50, backgroundColor: "#FFFFFF", width: "100%", borderRadius: 5, padding: "17px 0px 17px 0px", boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 8px" }}>
                                                {
                                                    Object.keys(columns).map((item, index) => {
                                                        return (
                                                            <>
                                                                {
                                                                    typeof data[columns[item].dataKey] === "string" ?
                                                                        <>
                                                                            <Tooltip style={{ backgroundColor: "white", color: "black" }} title={!(columns?.[item]?.cols === "Actions") ? data[columns[item].dataKey] : null}>
                                                                                <div id={index} style={{ width: columns[item].width, display: "flex", alignItems: "center", justifyContent: "flex-start", color: "#162432", fontSize: 14, fontWeight: 400, fontStyle: "Lato", marginLeft: 5, }}>
                                                                                    <div style={{ width: columns[item].innerWidth ? columns[item].innerWidth : "", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", color: columns[item].color ? columns[item].color : "" }}>

                                                                                        {data[columns[item].dataKey]}
                                                                                    </div>
                                                                                </div>
                                                                            </Tooltip>

                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div id={index} style={{ width: columns[item].width, display: "flex", alignItems: "center", justifyContent: "flex-start", color: "#162432", fontSize: 14, fontWeight: 400, fontStyle: "Lato", marginLeft: 5, }}>
                                                                                {data[columns[item].dataKey]?data[columns[item].dataKey]:"NA"}
                                                                            </div>
                                                                        </>
                                                                }
                                                            </>

                                                        )
                                                    }
                                                    )
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            </>
                            :
                            <>
                                <div style={{ height: styles?.height ? styles?.height : "60vh", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: 'column', marginTop: 20, backgroundColor: "#FFFFFF" }}>
                                    <img src={NoDataImg}></img>
                                    <div style={{ color: "#ABABAB" }}>{EmptyText}</div>
                                </div>
                            </>
                }
            </div>
            {
                pagination != null ?
                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: "30%", width: "100%", paddingRight: 50, padding: "0.8rem 2rem 0.8rem 0" }}>
                        <Pagination onChange={(page, pageSize) => {
                            pagination.onChange(page, pageSize)
                        }}
                            pageSizeOptions={pagination?.pageSizeOptions} current={pagination?.current} total={pagination?.total} showSizeChanger={pagination?.showSizeChanger} />
                    </div>
                    :
                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: "30%", height: "8vh", width: "100%", paddingRight: 50 }}>
                    </div>
            }
        </>
    )
}

export default TableCustom;