import React, { useEffect, useState } from "react";
import classes from './logs.module.css';
import {
  Table,
  Row,
  Space,
  DatePicker,
  Select,
  Tooltip,
  Typography,
  Spin,
  Pagination,
  Button,
  Checkbox,Radio,Modal,Divider
} from "antd";
import AscendingSortIcon from "../../../assets/AscendingSortIcon.svg";
import DescendingSortIcon from "../../../assets/DescendingSortIcon.svg";
import UserIcon from "../../../assets/UserIcon.svg";
import moment from "moment";
import { useLocation } from "react-router";
import Api from "../../../services/Api";
import SearchCompo from "../../../design/SearchCompo";
import viewClass from "../../../services/ViewRole";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import dayjs from "dayjs";
import DownloadIcon from "../../../assets/Download Icon Blue.svg";
import { AiOutlineVerticalAlignBottom, AiOutlineReload, } from "react-icons/ai";
import { getFilterValues } from "../../ProjectManagement/action/action";
import { useProjecteStore } from "../../ProjectManagement/store/store";
import FilterIcon from "../../../assets/filterIcon.svg";

const { Option } = Select;

export const DownLoadAttendnaceLogs = (successCallBack, url, params = null,ref=null) => {
  Api.get(url)
      .params(params)
      .send((response) => {
          if (response !== undefined && response) {
              if(ref===1){
              window.open(response, "_blank")?.focus();
              }
              else if(ref === 2){
              window.open(response.file, "_blank")?.focus();
              }
              else{
              window.open(response.file, "_blank")?.focus();
              }

              if (successCallBack) {
                  successCallBack()
              }
          }
      });
}

const YearlyLogs = () =>{

    const [loader, setLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [search, setSearch] = useState("");
    const [tableData, setTableData] = useState([]);
    const [statusFilter, setStatusFilter] = useState("");
    const {width}=useWindowDimensions();
    const [yearData, setYearData] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [sortingEmployee, setSortingEmployee] =useState('ASC');
    const dateFormat = "YYYY-MM-DD"
    const [isApplyFiltersModal, applyFiltersModalHandler] = useState(false);
    const [value, setValue] = useState(1);
    const [btnLoader, setBtnLoader] = useState(false);
    const [paginationVal, setPaginationVal] = useState(false);
    const [tabs, setTabs] = useState("Active Users");
    const [date,setDate]=useState( `${moment().format("MM").slice(0, 3)},${selectedYear}`);
    const [filteredDeptValue,setFilteredDeptValue]=useState(null);
    const [filteredManagerValue,setFilteredManagerValue]=useState(null);
    const { setFilterValues, filteredData } = useProjecteStore((state) => state);
    const [currentPage, setCurrentPage]=useState(null);
    const [filterModal,setFilterModal,] = useState(false);
    const [filterModalLogs,setFilterModalLogs] = useState(false);

    useEffect(()=>{
        function getLastTenYears() {
            const currentYear = new Date().getFullYear();
            const years = [];
        
            for (let i = 0; i < 10; i++) {
                years.push(currentYear - i);
            }
        
            return years;
        }
        
        const lastTenYears = getLastTenYears();
        setYearData(lastTenYears);        

    },[])

    const [tablePagniation, setTablePagination] = useState({
      pagination: {
        showSizeChanger: true,
        page: 1,
        limit: 10,
        showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`
      },
    });
    const location = useLocation();
  
    const handleTableChange = (page,pageSize) => {
      setTablePagination((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: page,
          limit: pageSize,
        },}))
        setPaginationVal(true);
    };
    const onChange = (e) => {
      setValue(e.target.value);
    };
    const getTableData = () => {
        let params = {
          search: search,
          year:selectedYear,
          page:tablePagniation.pagination.page,
          limit:tablePagniation.pagination.limit,
          exEmployee: tabs === "Inactive Users" ? true : false,
          departmentId: filteredDeptValue,
          managerId: filteredManagerValue,
          employeeIdSort: sortingEmployee
        };
        setLoader(true);
        Api.get(`/attendance-attendance/yearlyLogs`)
          .params(params)
          .send((res) => {
            if (res !== undefined) {
              setTableData(res?.items);
              setTablePagination({
                ...tablePagniation,
                pagination: {
                  ...tablePagniation.pagination,
                  total: res?.meta?.totalItems,
                  showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`
                },
              });
              setLoader(false);
            }
          });
    };
  
    useEffect(() => {  
      viewClass.updateView();
      getFilterValues(setFilterValues, "departmentAttendance");
      getTableData();

    }, [search,paginationVal && JSON.stringify(tablePagniation),selectedYear,tabs,sortingEmployee,filteredDeptValue,filteredManagerValue]);
  
    const handleSearchChange = (newSearch) => {
      setSearch(newSearch);
      setTablePagination((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: 1,
        },
      }));
    };

    const showModal = () => {
      setOpen(true);
    };
    const handleCancel = () => {
      setOpen(false);
    };
  
    return (
      <div style={{marginTop:8,width:width > 700 ? "100%" : "100vw",display:"flex",flexDirection:"column",backgroundColor:"#FFFFFF"}}>
        <div style={{display:"flex",flexDirection:"row",width:"100%",alignItems:"center",gap:"1rem",justifyContent:"space-between",marginBottom:width>700?"0.4rem":"0.5rem",padding:width>700?"0.2rem 0.7rem":"0 0.7rem"}}>
            <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",alignItems:"center",}}>
              <h2 style={{ fontSize:width > 700 ? "1.4vw" : 21,}}>Yearly Logs</h2>
            </div>
            <div style={{display:"flex",flexDirection:"row",gap:"1rem"}}>
            <SearchCompo 
                //value={search}
                onSearch={handleSearchChange} 
                setSearch={handleSearchChange}
                style={{ width:width > 700 ?"21vw":"12rem",height:"2.1rem",border:"1px solid #e0e0e0",fontWeight:"100", }}
              >
            </SearchCompo>
            {width > 700 && 
            <div className="m-t-10" style={{ cursor: "pointer", color: "#0086FF" }}
              onClick={() => {                
                  setBtnLoader(true);
                  Api.get(`/attendance-attendance/yearlyLogs`)
                    .params({
                      exEmployee: tabs === "Inactive Users" ? true : false,
                      search: search,
                      departmentId: filteredDeptValue,
                      managerId: filteredManagerValue,
                      year: selectedYear,
                      employeeIdSort: sortingEmployee,
                      download: true,
                    })
                    .send((response) => {
                      window.open(response.file, "_blank")?.focus();
                      setBtnLoader(false);
                    })
              }}
            >
              Download{" "}
              <span style={{ marginLeft: "0.2rem" }}>
                <img src={DownloadIcon} style={{ height: "1rem" }} />
              </span>
            </div>}
            </div>
        </div>
  
        {/* {width > 700 && 
          <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",margin:"0.1rem 0.7rem 0.7vh 0.7rem"
              }}
            >
              <SearchCompo 
                value={search}
                onSearch={handleSearchChange} 
                setSearch={handleSearchChange}
                style={{ width:width > 700 ?"21vw":"13.5rem",height:"2.1rem",border:"1px solid #e0e0e0",fontWeight:"100", }}
              >
              </SearchCompo> 
              <div style={{display:"flex",flexDirection:"row",gap:"0.9rem"}}>
              <Select placeholder="Year" size="medium"
                    allowClear={false}
                    value={selectedYear}
                    style={{
                      width: "11vw",
                      fontSize:"1vw",height:"2.1rem"
                    }}
                    onChange={(e) => {
                      setSelectedYear(e);
                      setTablePagination((prevState) => ({
                        ...prevState,
                        pagination: {
                          ...prevState.pagination,
                          page: 1,
                        },
                      }));
                    }}
                    disabled={false}
                  >
                  {yearData?.map((item, index) => {
                    return (
                    <Option key={index} value={item}>
                     {item}
                  </Option>)
                  })}
             </Select>
            </div>
          </div>
        } */}

        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: width > 700 ? "0 0.8rem" : "0.4rem 0.8rem" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              // border:"2px solid",
              gap: "1.8rem",
              cursor: "pointer",
            }}
          >
            <div
              onClick={() => {
                setTabs("Active Users");
                // setSearch("")
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: tabs === "Active Users" ? "#0086FF" : "#A6A9B8", fontSize: width < 700 ? 18 : "1.2vw"
                }}
              >
                Active Users
              </div>
              {tabs === "Active Users" ? (
                <div
                  style={{
                    height: 1,
                    border: "2px solid #0086FF",
                    borderRadius: 5,
                  }}
                ></div>
              ) : (
                <div style={{ height: 1 }}></div>
              )}
            </div>
            <div
              onClick={() => {
                setTabs("Inactive Users");
                // setSearch("")
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: tabs === "Inactive Users" ? "#0086FF" : "#A6A9B8", fontSize: width < 700 ? 18 : "1.2vw"
                }}
              >
                Inactive Users
              </div>
              {tabs === "Inactive Users" ? (
                <div
                  style={{
                    height: 1,
                    border: "2px solid #0086FF",
                    borderRadius: 5,
                  }}
                ></div>
              ) : (
                <div style={{ height: 1 }}></div>
              )}
            </div>

          </div>
          {width > 700 ?
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                // width: "40%",
                // marginTop:"0.4rem",
              }}
            >
              <div
                style={{
                  display: "flex", flexDirection: "row", gap: "0.7rem",
                  justifyContent: "flex-end",
                  alignItems: "center"
                }}
              >
                <Select placeholder="Year" size="medium"
                    allowClear={false}
                    value={selectedYear}
                    style={{
                      width: "11vw",
                      fontSize:"1vw",height:"2.1rem"
                    }}
                    onChange={(e) => {
                      setSelectedYear(e);
                      setTablePagination((prevState) => ({
                        ...prevState,
                        pagination: {
                          ...prevState.pagination,
                          page: 1,
                        },
                      }));
                    }}
                    disabled={false}
                  >
                  {yearData?.map((item, index) => {
                    return (
                    <Option key={index} value={item}>
                     {item}
                  </Option>)
                  })}
                </Select>
                <Select placeholder="Manager" allowClear size="medium"
                  getPopupContainer={(trigger) => trigger.parentElement}
                  showSearch
                  value={filteredManagerValue}
                  style={{
                    width: "10vw", height: "2.1rem"
                  }}
                  onChange={(e) => {
                    setFilteredManagerValue(e);
                    setCurrentPage(1);
                  }}
                  disabled={false}
                  optionFilterProp="children"

                >
                  {filteredData?.department?.allManager?.map((option, index) => {
                    return (<>{option.name !== undefined &&
                      <Option key={index} value={option.id}>
                        {option.name}
                      </Option>}</>)
                  })}
                </Select>
                <Select placeholder="Department" allowClear size="medium"
                  getPopupContainer={(trigger) => trigger.parentElement}
                  showSearch
                  value={filteredDeptValue}
                  style={{
                    width: "10vw", height: "2.1rem"
                  }}
                  onChange={(e) => {
                    setFilteredDeptValue(e);
                    setCurrentPage(1);
                  }}
                  disabled={false}
                  optionFilterProp="children"
                >
                  {filteredData?.department?.result?.map((option, index) => {
                    return (<>{option.department !== undefined &&
                      <Option key={index} value={option.departmentId}>
                        {option.department}
                      </Option>}</>)
                  })}
                </Select>

              </div>
            </div>
            :
            <div style={{ display: "flex", flexDirection: "row", gap: "0.6rem" }}>
              <div className="m-t-10" style={{ cursor: "pointer" }}
                onClick={() => {
                  setFilterModal(true);
                }}>
                <img src={FilterIcon} style={{ height: "1.4rem" }} />
              </div>
              <div
                className="m-t-10"
                style={{ cursor: "pointer", color: "#0086FF" }}
                onClick={() => {
                  setBtnLoader(true);
                  Api.get(`/attendance-attendance/yearlyLogs`)
                    .params({
                      exEmployee: tabs === "Inactive Users" ? true : false,
                      search: search,
                      departmentId: filteredDeptValue,
                      managerId: filteredManagerValue,
                      year: selectedYear,
                      employeeIdSort: sortingEmployee,
                      download: true,
                    })
                    .send((response) => {
                      window.open(response.file, "_blank")?.focus();
                      setBtnLoader(false);
                    })
                }}
              >
                <img src={DownloadIcon} style={{ height: "1.5rem" }} />
              </div>
            </div>
          }
        </div>
        
        {/* {width<700 && <div
          style={{
            display: "flex",flexDirection:"row",
            justifyContent: "space-between",
            alignItems: "center", 
            gap:"1rem",
            marginBottom:"0.5rem",padding:"0 0.7rem",width:"100%"
          }}
        >
          <div style={{width:"65%"}}>
          <SearchCompo 
            value={search}
            onSearch={handleSearchChange} 
            setSearch={handleSearchChange}
            style={{ width:width > 700 ?"300px":"100%",height:width > 700 ?"2rem":"2.1rem",border:"1px solid #e0e0e0",fontWeight:"100", }}
          >
          </SearchCompo>
          </div>
          <Select placeholder="Year" size="medium"
                    allowClear={false}
                    // className={classes.SelectScroll}
                    value={selectedYear}
                    style={{
                      width: "6rem",
                      fontSize:"1vw",marginRight:"0.5rem"
                      // height:"2.1rem"
                    }}
                    onChange={(e) => {
                      setSelectedYear(e);
                      setTablePagination((prevState) => ({
                        ...prevState,
                        pagination: {
                          ...prevState.pagination,
                          page: 1,
                          // pageSize: 10,
                        },
                      }));
                    }}
                    disabled={false}
                  >
                  {yearData?.map((item, index) => {
                    return (
                    <Option key={index} value={item}>
                     {item}
                  </Option>)
                  })}
             </Select>
        </div>} */}
           {width > 700 ?
            <div className={classes.ListContainer} style={{marginTop:"0.2rem"}}>
              <div className={classes.ListHeader} style={{display:'flex',width:'100%',gap:"0.4rem",fontSize: "0.9rem", color: "black", fontWeight: 600}}>
                            {/* <div style={{width:'13%',paddingLeft:"0.5rem"}}>Employee ID</div> */}
                            <div style={{ paddingLeft: "0.5rem", width:'13%', display: "flex", flexDirection: "row", alignItems: "top" }}>
                                Employee ID {sortingEmployee === 'ASC' ? <img src={AscendingSortIcon} alt='' onClick={() => { setSortingEmployee('DESC'); 
                                  setTablePagination((prevState) => ({
                                    ...prevState,
                                    pagination: {
                                      ...prevState.pagination,
                                      page: 1,
                                    },
                                  }));}}
                                style={{ cursor: "pointer", marginLeft: "0.2rem" }} />
                                : <img src={DescendingSortIcon} alt='' onClick={() => { setSortingEmployee('ASC'); 
                                setTablePagination((prevState) => ({
                                    ...prevState,
                                    pagination: {
                                      ...prevState.pagination,
                                      page: 1,
                                    },
                                  })); }} style={{ cursor: "pointer", paddingLeft: "0.2rem" }} />}
                            </div>
                            <div style={{width:'18%',justifyContent:'flex-start'}}>Associate Name</div>
                            <div style={{width:'20%'}}>Contact details</div>
                            <div style={{width:'15%'}}>Department</div>
                            <div style={{width:'15%'}}>Manager</div>
                            <div style={{width:'7%'}}>P</div>
                            <div style={{width:'7%'}}>LOP</div>
                            <div style={{width:'7%'}}>H</div>
                            <div style={{width:'7%'}}>OH</div>
                            <div style={{width:'7%'}}>L</div>
                            <div style={{width:'7%'}}>AL</div>
              </div>
              <div className={classes.ListBodyContainer} style={{height:"61vh", overflowY:'scroll',overflowX:"scroll",width:"100%"}}>
                       
                       {loader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><Spin /></div>:
                       tableData?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><div>No data found</div></div>:
                       tableData?.map((record,id)=>{
                          return(
                            <div style={{width:"100%",fontSize:14}}>
                            <div className={classes.rowContainer} style={{color: "#162432", fontSize: 14, fontWeight: 400, fontStyle: "Lato",gap:"0.4rem",}}>
                            
                            <div style={{width:'13%',paddingLeft: "0.5rem"}}>
                            {record?.uid}
                            </div>
                            <div style={{width:'18%',justifyContent:'flex-start'}}>
                            <div style={{display:'flex',alignItems:'center',gap:'5px',flexDirection:'row',}}>
                                <div>
                                <img style={{ height:"2.3rem",width:"2.3rem",border:"none",borderRadius:"50%",border:"1px dotted #e3e3e3"}} src={UserIcon} />
                                </div>
                                <Typography.Text style={{width:"7vw",color: "#162432", fontSize: 14, fontWeight: 400, fontStyle: "Lato",}} ellipsis={{tooltip:true}}>
                                  {record?.name}</Typography.Text>
                            </div>
                              {/* {<img src={UserIcon} style={{height:"2.3rem",width:"2.3rem",borderRadius:"50%",border:"1px dotted #e3e3e3"}} />}
                            <div style={{display:"flex",flexDirection:"column"}}>
                              <Typography.Text style={{width:width>700?"10rem":"9.5rem",fontSize:14}} ellipsis={{tooltip:true}}>{record?.name ? record?.name :"NA"}</Typography.Text>
                              <Typography.Text style={{width:"10rem",fontSize:14}} ellipsis={{tooltip:true}}><span>{record?.email ? record?.email :"NA"}</span></Typography.Text>
                              <div>{record?.phone ? record?.phone :"NA"}</div>
                            </div> */}
                            </div>
                            <div style={{width:'20%',display:"flex",flexDirection:"column",gap:5,alignItems:"flex-start",padding:0,paddingTop:14}}>
                                <Typography.Text style={{width:"10vw",color: "#162432", fontSize: 14, fontWeight: 400, fontStyle: "Lato",}} ellipsis={{tooltip:true}}>
                                  {record?.email}</Typography.Text>
                                <span>{record?.phone}</span>
                            </div>
                            <div style={{width:'15%'}}>
                                <Typography.Text style={{width:"8vw",color: "#162432", fontSize: 14, fontWeight: 400, fontStyle: "Lato",}} ellipsis={{tooltip:true}}>
                                  {record?.department ? record?.department :"NA"}</Typography.Text>
                            </div>
                            <div style={{width:'15%'}}>
                                <Typography.Text style={{width:"8vw",color: "#162432", fontSize: 14, fontWeight: 400, fontStyle: "Lato",}} ellipsis={{tooltip:true}}>
                                  {record?.manager ? record?.manager :"NA"}</Typography.Text>
                            </div>
                            <div style={{width:'7%'}}>
                            <div>{record?.present}</div>
                            </div>
                            <div style={{width:'7%'}}>
                              {record?.absent}
                            </div>
                            <div style={{width:'7%'}} >
                            <div>{record?.holiday}</div>
                            </div>
                            <div style={{width:'7%'}}>
                              {record?.optional}
                            </div>
                            <div style={{width:'7%'}}>
                              {record?.leave}
                            </div>
                            <div style={{width:'7%'}}>
                              {record?.advanced}
                            </div>
                            </div>
                            </div>
                          )
                        })}
              </div>  
            </div> 
            :
            <div className={classes.ListBodyContainer} style={{height:"67vh", overflowY:'scroll',overflowX:"scroll",width:"100%",backgroundColor:"#F9F9F9",padding:"0.5rem 0.9rem"}}>
            {loader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'69vh'}}><Spin /></div>:
               tableData?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'69vh'}}><div>No data found</div></div>:
               tableData?.map((record,id,index)=>{
                  return(
                   <div style={{width:"100%",marginTop:"0.3rem",marginBottom:"1rem",borderRadius:10,backgroundColor:"#FFFFFF",padding:"0.8rem 0.7rem"}}>
                   <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between"}}>
                     <div style={{display:"flex",flexDirection:"column",gap:""}}>
                     <Typography.Text style={{width:"40vw",fontSize:"20px",fontWeight:"550"}} ellipsis={{tooltip:true}}>
                       {record?.name ? record?.name :"NA"}
                     </Typography.Text>
                     <p style={{fontSize:"17px",color:"#16243280"}}>{record?.uid ? record?.uid :"NA"}</p>
                     </div>
                     <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
                       
                     </div>
                   </div>
   
                   <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",marginTop:"0.6rem",alignItems:"center"}}>
                   <p style={{fontSize:"19px",color:"#16243280"}}>Email: </p>
                   <Typography.Text style={{width:"50vw",fontSize:"20px",fontWeight:"500"}} ellipsis={{tooltip:true}}>
                       {record?.email ? record?.email :"NA"}
                     </Typography.Text>
                   {/* <p style={{fontSize:"19px"}}>{record?.email ? record?.email :"NA"}</p> */}
                   </div>
                   
                   <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",marginTop:"0.6rem",}}>
                   <p style={{fontSize:"19px",color:"#16243280"}}>Phone: </p>
                   <p style={{fontSize:"19px"}}>{record?.phone ? record?.phone:"NA"}</p>
                   </div>
   
                   <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",marginTop:"0.6rem",}}>
                   <p style={{fontSize:"19px",color:"#16243280"}}>Present: </p>
                   <p style={{fontSize:"19px"}}>{record?.present}</p>
                   </div>
   
                   <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",marginTop:"0.6rem",alignItems:"center"}}>
                   <p style={{fontSize:"19px",color:"#16243280"}}>Absent: </p>
                   {/* <p style={{fontSize:"19px"}}>{record?.timeStamp ? record?.timeStamp :"NA"}</p> */}
                   <p><Typography.Text style={{width:"60vw",fontSize:20,fontWeight:"650px"}} ellipsis={{tooltip:true}}>
                     {record?.absent}</Typography.Text></p>
                   </div>
  
                   <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",marginTop:"0.6rem",}}>
                   <p style={{fontSize:"19px",color:"#16243280"}}>Leave: </p>
                   <p style={{fontSize:"19px"}}>{record?.leave}</p>
                   </div>
  
                   {/* <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",marginTop:"0.6rem",}}>
                   <p style={{fontSize:"19px",color:"#16243280"}}>Status: </p>
                   <p style={{fontSize:"19px"}}>{record?.status ? record?.status :"NA"}</p>
                   </div> */}
  
                </div>
                )
              })}
            </div>  
            }
            <div>
             <Pagination position="bottomRight" size="small" style={{display:"flex",justifyContent:"flex-end",marginTop:"0.5rem",fontSize:width<700&&13,}}
              current={tablePagniation.pagination.page}
              pageSize={tablePagniation.pagination.limit}
              total={tablePagniation.pagination.total}
              showSizeChanger={tablePagniation.pagination.showSizeChanger}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              onChange={handleTableChange}
              onShowSizeChange={handleTableChange}
            /> 
            </div>
            
      {filterModal && (
        <Modal centered
          onCancel={() => { setFilterModal(false); setFilterModalLogs(false) }}
          title={<div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", fontSize: "21px" }}>
            Filters
          </div>
          }
          footer={
            <div className="r-c-c" style={{ margin: "1rem", marginTop: "1.4rem", display: "flex", flexDirection: "row", gap: "0.5rem" }}>
              <Button
                name="Cancel" style={{}}
                background={"#FFFFFF"}
                onClick={() => { setFilterModal(false); setFilterModalLogs(false) }}
                fontSize={17}
                fontColor={"#0086FF"}
                height="2.4rem"
                width="5.5rem"
                marginLeft="5px"
                borderRadius={8}
              >Cancel</Button>
              <Button type="primary"
                name="Apply"
                background={"#0086FF"}
                onClick={() => {
                  if (filterModalLogs === true) {
                    setFilterModal(false)
                    setFilterModalLogs(false)
                  } else {
                    // getLogsData(
                    //   { ExEmployee: tabs === "Inactive Users" ? true : false, page:search === ""?currentPage:"",search: search,
                    //   limit:search === ""?pageSize:"",departmentId:filteredDeptValue,managerId:filteredManagerValue },
                    //   (res) => {
                    //     setLogData(res);
                    //     setTableMetaData(res?.meta)
                    //   },
                    //   setLoader
                    // );  
                    setFilterModalLogs(false)
                    setFilterModal(false)
                  }
                }}
                fontSize={17}
                fontColor={"#FFFFFF"}
                height="2.4rem"
                width="5.5rem"
                borderRadius={8}
                style={{ marginRight: "0.5rem" }}
              >Apply</Button>
            </div>
          }
          open={filterModal} width={750}
        >
          <Divider />
          {filterModalLogs ?
            <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "0.5rem" }}>
              <p style={{ fontSize: "18px", color: "#878787" }}>Select Associate</p>
              {/* <Select
                placeholder="Select Associate"
                value={
                  selectAssociate
                    ? selectAssociate
                    : selectedUser?.name
                }
                onChange={(e, val) => {
                  setLoader(true);
                  setSelectAssociate(val?.children);
                  setUiSelectId(e)
                  getLogsDetails(
                    (res) => {
                      setAttendanceLogsData(res);
                    },
                    {
                      userId: e,

                    },
                    setLoader
                  );
                  applyLeaveModalApi(
                    {
                      userId: e,
                    },
                    (res) => {
                      setApplyLeaveData(res);
                    }
                  );
                }}
                style={{ height: "3rem", fontSize: "19px", }}
                showSearch
                size="large"
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                allowClear
              >
                {userCalendarDrop.map((user, index) => (
                  <>
                    <option key={user?.key} value={user.userId} style={{ fontSize: "19px" }}>
                      {user?.userName}
                    </option>
                  </>
                ))}
              </Select> */}
            </div>
            :
            <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "0.5rem" }}>
              <p style={{ fontSize: "18px", color: "#878787" }}>Select Year</p>
                <Select placeholder="Year" size="large"
                    allowClear={false}
                    value={selectedYear}
                    style={{
                      // width: "11vw",
                      fontSize:"18px",height:"2.1rem"
                    }}
                    onChange={(e) => {
                      setSelectedYear(e);
                      setTablePagination((prevState) => ({
                        ...prevState,
                        pagination: {
                          ...prevState.pagination,
                          page: 1,
                        },
                      }));
                    }}
                    disabled={false}
                  >
                  {yearData?.map((item, index) => {
                    return (
                    <Option key={index} value={item}>
                     {item}
                  </Option>)
                  })}
                </Select>
              <p style={{ fontSize: "18px", color: "#878787" }}>Select Manager</p>
              <Select placeholder="Manager" allowClear
                getPopupContainer={(trigger) => trigger.parentElement}
                showSearch
                value={filteredManagerValue}
                size="large"
                style={{
                  height: "3rem", fontSize: "18px"
                }}
                onChange={(e) => {
                  setFilteredManagerValue(e);
                  setCurrentPage(1);
                }}
                disabled={false}
                optionFilterProp="children"

              >
                {filteredData?.department?.allManager?.map((option, index) => {
                  return (<>{option.name !== undefined &&
                    <Option key={index} value={option.id} style={{ fontSize: "19px" }}>
                      {option.name}
                    </Option>}</>)
                })}
              </Select>
              <p style={{ fontSize: "18px", color: "#878787" }}>Select Department</p>
              <Select placeholder="Department" allowClear
                getPopupContainer={(trigger) => trigger.parentElement}
                showSearch
                value={filteredDeptValue}
                size="large"
                style={{
                  height: "3rem", fontSize: "18px"
                }}
                onChange={(e) => {
                  setFilteredDeptValue(e);
                  setCurrentPage(1);
                }}
                disabled={false}
                optionFilterProp="children"

              >
                {filteredData?.department?.result?.map((option, index) => {
                  return (<>{option.department !== undefined &&
                    <Option key={index} value={option.departmentId} style={{ fontSize: "19px" }}>
                      {option.department}
                    </Option>}</>)
                })}
              </Select>
            </div>
          }
        </Modal>
      )}
      </div>
    );

}
export default YearlyLogs;