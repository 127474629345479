import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import DownloadIcon from "../../../../assets/Appraisal/downloadBlackIcon.svg";
import AppraisalCycleModal from "./appraisalCycle-modal";
import Api from "../../../../services/Api";
import "../styles/tableHeader.css";
import pluseIcon from "../../../../assets/Appraisal/pluse-circle.svg";
import viewIcon from "../../../../assets/Appraisal/viewIcon.svg";
import { globalActions } from "../../store/loader";
import { useDispatch } from "react-redux";
import AssociateTable from "../../components/Table/AssociateTable";

import classes from "./appraisal_cycle.module.css";
import SearchCustom from "../../components/SearchCustom";

function AppraisalCycle() {
  const [showEditModal, updateEditModal] = useState(false);
  const [showAddModal, updateAddModal] = useState(false);
  const [details, updateDetails] = useState();
  const [cycleData, setCycleData] = useState([]);
  const [loader, setLoader] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [downloadCycle, setDownloadCycle] = useState("downloadCycle");

  const dispatch = useDispatch();

  const getTableData = () => {
    setLoader(true);
    dispatch(globalActions.updateLoader({ loader: true }));
    Api.get(`/appraisal-admin/cycle`)
      .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          setCycleData(response?.mergedData); //after api integration user this
          // setCycleData(response);
          setLoader(false);
          dispatch(globalActions.updateLoader({ loader: false }));
        }
      });
  };


  useEffect(() => {
    getTableData();
  }, []);

  const handleSearchInput = (e) => {
    setSearchQuery(e.target.value);
    Api.get(`/appraisal-admin/cycle?search=${e.target.value}`).send(
      (response, error) => {
        if (typeof response !== "undefined") {
          setLoader(false);
          setCycleData(response);
        }
      }
    );
  };

  const handleSearchTeam = () => {
    setLoader(true);
    Api.get(`/appraisal-admin/cycle?search=${searchQuery}`).send(
      (response, error) => {
        if (typeof response !== "undefined") {
          setLoader(false);
          setCycleData(response);
        }
      }
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchTeam();
    }
  };

  const handleDownload = () => {
    setLoader(true);

    Api.get(`/appraisal-admin/getcycleUsers?download=true`)
      // .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          const url = response;
          const link = document.createElement("a");
          link.href = url;
          link.download = "allData.csv"; // set the file name
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setLoader(false);
        }
      });
  };

  const sortAscending = <h4>Click to sort ascending </h4>;
  const sortDescending = <h4>Click to sort descending </h4>;

  const handleSortByCycle = (value) => {
    setLoader(true);
    Api.get(`/appraisal-admin/cycle`, {
      sort: value,
      sortBy: "appraisalCycle",
    })
      // .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          setLoader(false);
          setCycleData(response);
        }
      });
  };
  const handleSortByStartDate = (value) => {
    setLoader(true);
    Api.get(`/admin/cycle`, {
      sort: value,
      sortBy: "startDate",
    })
      // .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          setLoader(false);
          setCycleData(response);
        }
      });
  };
  const handleSortByEndDate = (value) => {
    setLoader(true);
    Api.get(`/admin/cycle`, {
      sort: value,
      sortBy: "endDate",
    })
      // .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          setLoader(false);
          setCycleData(response);
        }
      });
  };
  const handleSortByDuration = (value) => {
    setLoader(true);
    Api.get(`/admin/cycle`, {
      sort: value,
      sortBy: "duration",
    })
      // .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          setLoader(false);
          setCycleData(response);
        }
      });
  };

  const columns = [
    {
      title: (
        <div
          style={{
            fontSize: "1rem",
            color: "#000000",
            marginLeft: "1rem",
            display: "flex",
            flexDirection: "column",
            width:"100%",
            
          }}
        >
          Appraisal Cycle
        </div>
      ),
      dataIndex: "cycle_appraisalCycle",
      key: "1",
      width: "12rem",
      render: (val, row) => {
        return (
          <div style={{ display: "flex", marginLeft: "1rem" }}>
            {row?.cycle_appraisalCycle}
          </div>
        );
      },
    },
    {
      title: (
        <div
          style={{
            fontSize: "1rem",
            color: "#000000",
            display: "flex",
            justifyContent: "flex-start",
            width:"100%",
            
          }}
        >
          Start Date
        </div>
      ),
      dataIndex: "cycle_startDate",
      key: "2",
      width: "12rem",
      render: (val, row) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {row?.cycle_startDate}
          </div>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            color: "#000000",
            width:"100%",
            fontSize:"1rem",
            
          }}
        >
          End Date
          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "0px",
              width: "10rem",
              position: "relative",
              bottom: "25px",
            }}
          >
            <Popover content={sortAscending}>
              <Button
                type="text"
                style={{
                  height: "0.6rem",
                  margin: "0",
                  padding: "0",
                  width: "20px",
                  marginLeft: "5rem",
                  bottom: "2px",
                  color: "#787b83",
                }}
                onClick={() => handleSortByEndDate("ASC")}
              >
                <AiFillCaretUp />
              </Button>
            </Popover>
            <Popover content={sortDescending}>
              <Button
                type="text"
                style={{
                  height: "0.8rem",
                  margin: "0",
                  padding: "0",
                  width: "20px",
                  marginLeft: "5rem",
                  color: "#787b83",
                }}
                onClick={() => handleSortByEndDate("DESC")}
              >
                {<AiFillCaretDown />}
              </Button>
            </Popover>
          </div> */}
        </div>
      ),
      dataIndex: "cycle_endDate",
      key: "3",
      width: "12rem",
      render: (val, row) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {row?.cycle_endDate}
          </div>
        );
      },
    },
    {
      title: (
        <div
          style={{
           
            display: "flex",
            justifyContent: "flex-start",
            color: "#000000",
            fontSize:"1rem",
            width:"100%",
            
          }}
        >
          Associates Count
          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "0px",
              width: "10rem",
              position: "relative",
              bottom: "25px",
            }}
          >
            <Popover content={sortAscending}>
              <Button
                type="text"
                style={{
                  height: "0.6rem",
                  margin: "0",
                  padding: "0",
                  width: "20px",
                  marginLeft: "4rem",
                  bottom: "2px",
                  color: "#787b83",
                }}
                onClick={() => handleSortByDuration("ASC")}
              >
                <AiFillCaretUp />
              </Button>
            </Popover>
            <Popover content={sortDescending}>
              <Button
                type="text"
                style={{
                  height: "0.8rem",
                  margin: "0",
                  padding: "0",
                  width: "20px",
                  marginLeft: "4rem",
                  color: "#787b83",
                }}
                onClick={() => handleSortByDuration("DESC")}
              >
                {<AiFillCaretDown />}
              </Button>
            </Popover>
          </div> */}
        </div>
      ),
      dataIndex: "cycleCountsData",
      key: "4",
      width: "12rem",
      render: (val, row) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {row?.cycleCountsData}
          </div>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent:"flex-start",
            color: "#000000",
            width:"100%",
            fontSize:"1rem",
            
          }}
        >
          View
        </div>
      ),
      dataIndex: "edit",
      key: "5",
      width: "12rem",
      render: (val, row) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              style={{
                backgroundColor: "#FAFAFA",

                border: "none",
              }}
              shape="circle"
              onClick={() => {
                updateEditModal(true);
                updateDetails(row);
              }}
              // disabled={true}
            >
              <img src={viewIcon} style={{}} />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.titleBlock}>
        <h2 className={classes.title}>Appraisal Cycle</h2>

        <div
          style={{
            // border:'1px solid black',
            width: "60%",
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          {/* <SearchCustom
            style={{ height: "5vh" }}
            placeholder="Search by name"
            setValue={setLocalSearchText}
            value={localSearchText}
          /> */}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "0.5rem",
              marginTop: "0.4rem",
              // border:'1px solid black'
            }}
          >
              <a
                style={{
                  fontSize: "1rem",
                  fontFamily: "Roboto-Medium",
                  color: "#162432",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",marginRight:"0.5rem"
                }}
                onClick={handleDownload}
              >
                <span style={{ color: "#0086FF" }}>
                  
                Download
                </span>
                <img
                  style={{
                    width: "1.2rem",
                    height: "1.2rem",
                    marginLeft: "0.5rem",
                    alignItems: "center",
                  }}
                  src={DownloadIcon}
                  alt=""
                />
              </a>
            <Button
              type="primary"
              style={{
                width: "8rem",
                height: "2.2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "4px",
                backgroundColor: "#0086FF",
              }}
              onClick={() => {
                updateAddModal(true);
              }}
            >
              <img
                src={pluseIcon}
                style={{
                  width: "0.6rem",
                  // height: "3rem",
                  marginRight: "0.3rem",
                  // marginBottom: "0.1rem",
                }}
              />
              <span>Add Cycle</span>
            </Button>
          </div>
        </div>
      </div>

      <div style={{ width: "100%", height: "78vh", }}>
        {/* <div
          style={{
            // border: "1px solid black",
            width: "100%",
            height: "6vh",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div className={classes.action_container}>
            <div></div>
            
          </div>
        </div> */}
        <div
          style={{
            // border: "1px solid black",
            width: "100%",
            height: "70vh",
            marginTop: "1rem",
            display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            // border:"2px solid red"
          }}
        >
          <AssociateTable
            dataSource={cycleData}
            columns={columns}
            loading={loader}
            pagination={false}
          />
        </div>
      </div>
      {showAddModal && (
        <AppraisalCycleModal
          showAddModal={showAddModal}
          refresh={getTableData.bind(this)}
          updateAddModal={updateAddModal}
        />
      )}
      {showEditModal && (
        <AppraisalCycleModal
          refresh={getTableData.bind(this)}
          showEditModal={showEditModal}
          updateEditModal={updateEditModal}
          data={details}
        />
      )}
    </div>
  );
}

export default AppraisalCycle;
