
import { create } from "zustand"
export const useHealthInsuranceStore=create(set=>({
    uploadModal:false,
    deleteModal:false,
    previewModal:false,
    insuranceData:[],
    fileList:[],
    uploadModalHandler:(val)=>set(state=>({uploadModal:val})),
    deleteModalHandler:(val)=>set(state=>({deleteModal:val})),
    previewModalHandler:(val)=>set(state=>({previewModal:val})),
    insuranceDataHandler:(val)=>set(state=>({insuranceData:val})),
    setFileList:(val=>set(state=>({fileList:val})))
}))