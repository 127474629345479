import React, { useEffect,useState } from "react";
import classes from "./AddTeamModal.module.css";
import { Form, Input, Select } from "antd";
import {
    ModalPrimaryButton,
    ModalSecondaryButton,
} from "../../../../components/Button/Button";
import addteam_icon from "../../../../assets/addteam_icon.svg";
import Api from "../../../../services/Api";
import { createTeamApi, editTeamApi, getManagerData } from "../Action";
 import Modal from "../../../../components/Modal/Modal";
import { useGlobalStore } from "../../../../store";
import { useWindowDimensions } from "../../../../components/windowDimention/windowDimension";
const AddTeamModal = ({
    initialDepartment,
    refresh,
    team,
}) => {
    const [form] = Form.useForm();
    const [addDeptId,setAddDeptId]=useState(initialDepartment?.id);
    const Option = Select.Option;
    const {width} = useWindowDimensions();
    const {
      isAddTeamModal,
      addTeamModalHandler,
      editTeamModalHandler,
      isEditTeamModal,
      departments,
      managerInfo,
      managerInfoHandler,
    }=useGlobalStore();

    console.log("managerInfo",managerInfo)
    const formProps=isEditTeamModal?
    { 
          layout: "vertical",
          form: form,
          initialValues: 
          {
                name: team?.teamName,
                departmentId: initialDepartment?.name,
                managerId: team?.manager?.name,
                coManagerId: team?.coManager?.name,
            },
      }:
      { 
        layout: "vertical",
        form: form,
        }
    useEffect(() => {
        if ((isAddTeamModal||isEditTeamModal)&&addDeptId) {
      getManagerData(managerInfoHandler,addDeptId, Api);
    }
  }, [isAddTeamModal,addDeptId,isEditTeamModal]);
  let isShow =isAddTeamModal || isEditTeamModal;
  return (
    <Modal
      show={isShow}
      closableRight={true}
      style={{width:width<700?"90vw":'70vw',height:'70vh',overflowY:'auto'}}
      header={
        <div className={classes.modalHeader} style={{color:"#0086FF"}}>
          <h3>{isAddTeamModal? "Add" : "Edit"} Team</h3>
        </div>
      }
      right={false}
      onCancel={() => {
        if (isAddTeamModal) {
          addTeamModalHandler(false);
        } else if (isEditTeamModal) {
          editTeamModalHandler(false);
        }
        form.resetFields();
      }}
    >
      {isShow && (
        <div className={classes.addTeamModal}>
          <Form
            {...formProps}
            onFinish={(event) => {
              if (isAddTeamModal) {
                createTeamApi(addTeamModalHandler, Api, event);               
              } 
              else if (isEditTeamModal) {
                if (typeof event["departmentId"] === "string") {
                  event["departmentId"] = initialDepartment?.id;
                }
                if (typeof event["managerId"] === "string") {
                  event["managerId"] = team?.manager?.id;
                }
                if (typeof event["coManagerId"] === "string") {
                  event["coManagerId"] = team?.coManager?.id;
                }
                if ( event["coManagerId"] === undefined) {
                  event["coManagerId"] = -1;
                }
                editTeamApi(editTeamModalHandler, team?.teamId, event, Api);
              }
              form.resetFields();
              refresh();
            }}
          >
            <Form.Item
              label={<span style={{fontWeight:"600"}}>Team Name</span>}
              name="name"
              rules={[{ required: true, message: "Please Enter the Name!" }]}
            >
              <Input type="text" placeholder="Enter Team Name" size="large" style={{border:"1.3px solid #ECECEC"}}/>
            </Form.Item>
            <Form.Item
              label={<span style={{fontWeight:"600"}}>Department</span>}
              name="departmentId"
              rules={[{ required: true, message: "Please select an option!" }]}
            >
              <Select  placeholder="Select" size="large" onChange={(e)=>{
                setAddDeptId(e);
              }}>
                {departments?.allDepartments?.map((item, index) => {
                  return (
                    <Option value={item.id} key={index}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={<span style={{fontWeight:"600"}}>Manager</span>}
              name="managerId"
              rules={[{ required: true, message: "Please select an option!" }]}
            >
              <Select  placeholder="Select" size="large">
                {managerInfo?.managers?.map((item, index) => {
                  return (
                    <Option value={item.id} key={index}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label={<span style={{fontWeight:"600"}}>Co-Manager</span>} name="coManagerId">
              <Select allowClear placeholder="Select" size="large" style={{fontWeight:"600"}}>
                {managerInfo?.coManagers?.map((item, index) => {
                  return (
                    <Option value={item.id} key={index}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <div className={classes.buttonDiv}>
            <ModalPrimaryButton style={{ borderRadius:2}}  htmlType="submit">Save</ModalPrimaryButton>
              <ModalSecondaryButton
              style={{backgroundColor:"#FFFFFF", height:40, borderRadius:2}}
                onCancel={() => {
                  if (isAddTeamModal) {
                    addTeamModalHandler(false);               
                  } else if (isEditTeamModal) {
                    editTeamModalHandler(false);
                  }
                  form.resetFields();                
                }}
              >
                Cancel
              </ModalSecondaryButton>
             
            </div>
          </Form>
        </div>
      )}
    </Modal>
  );
};
export default React.memo(AddTeamModal);
