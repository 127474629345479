import { create } from "zustand";
import {persist} from "zustand/middleware";

const projectStore=(set)=>({
    filterOption:{},
    appliedFilter:{},
    setFilterOption:(payload)=>{
        set((state)=>{
            console.log("init",state.filterOption,"payload",payload)
            return{
            filterOption:payload}}
            )
    },
    setAppliedFilter:(payload)=>{
        set((state)=>{
            console.log(state.appliedFilter,payload)
            return{
                appliedFilter:payload
            }
        })
    }

})

const useProjectStore=create(
    // devtools(
        // projectStore,
        persist(projectStore,{name:"filterOption",})
    // )
);
export default useProjectStore;