import React, { useEffect, useState } from "react";
import classes from "./Project.module.css";
import { GridListView } from "../../Homepage/components/gridListView";
import ButtonCompo from "../../../design/ButonCompo";
import { Select, Pagination, Button } from "antd";
import SearchCompo from "../../../design/SearchCompo";
import ProjectCards from "./ProjectCards";
import ProjectTable, { actiondis, ionsdis, progressdis } from "./ProjectTable";
import ProjectDetails from "./ProjectDetails";
import "../../../App.css";
import AddProject from "./AddProject";

import downloadIcon from "../../../assets/downloadBlue.svg";
import filterIcon from "../../../assets/filter.svg";
import unFilterIcon from "../../../assets/unfilter.png";
import { getAllProjectApi, getFilterApi } from "./ProjectAction";
import FilterModal, { resetData } from "./FilterModal";
import useProjectStore from "./store";
import "./comp.css";
import { SECRET_KEY, decryptData } from "../../../services/fileCode";
import useApiStore from "../../../services/store";
import viewClass from "../../../services/ViewRole";
import AddProjectIcon from "../../../assets/createPlusIcon.svg"

import { updateHeader } from "../../Header/main-header";
import moment from "moment";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";

import TabsMenu from "../Projects/TabsMenu";
import DeleteProjectModal from "./DeleteTasksModal";
import StatusTasksModal from "./statusTasksModal";
import { getAllUserTaskApi} from "./ProjectAction";
import transferIconWhite from "../../../assets/transferIconWhite.svg";
import { useFlagButtonStore } from "../Release/store";
import { Tooltip } from "antd/lib";





//!(allProject?.[0]?.isCoManager)
//userId is coming on click of release button which is also stored in userReleaseId, have to set it here in userId that handles the setting of search component
//else when ever a value(username) is searched in the search bar then the userId for that respective user to be set in userId and hit the api

// const onResetFilter = (setMetadata, setAllProject, updatedData, setFilterOption, filterOption) => {
//     console.log("its here");
//     // 
//     getAllProjectApi(setMetadata, {}, (res) => {
//         console.log(res);
//         setAllProject(res);
//         updatedData(res)
//         getFilterApi(false, {}, (res) => {
//             setFilterOption({ ...filterOption, departments: res?.departments });
//         })
//     })
// }




const ProjectsAndTasks= () => {

    const [name, setName] = useState('');
    const [checkedIds,setCheckedIds] = useState([]);
    //const [cancelFlag, setCancelFlag] = useState(false);
    // const [localCancelFlag, setLocalCancelFlag] = useState(false);

    const [userId, setUserId] = useState();

    const [transferModal, setTransferModal] =useState();
    const [updateModal, setUpdateModal] = useState();
    const [deleteModal, setDeleteModal] = useState();
    //const [pageMeta, setPageMeta] = useState();
    //const [search, setSearch] = useState();
    const [pageCurrent, setPageCurrent] = useState();
    //const [overallStatus, setOverallStatus] = useState('Active');
    const [pageSize, setPageSize] = useState();
    //const [buttonFlag, setButtonFlag] = useState(false);
    //const [allTask, setAllTask] = useState();
    


    const {flagButton,
        setFlagButton,
        allTask,
        setAllTask,
        checkedUserIdsList,
        filtersList,
        setFiltersList,
        setCheckedUserIdsList,
        cancelFlag,
        checkedIdsList,
        setCheckedIdsList,
        setCancelFlag,
        enableTransfer,
        setEnableTransfer,
        pageMeta,
        setPageMeta,
        tasksUserId,
        overallStatus,
        setOverallStatus,
        tasksTeamId,
        allSearch,
        setTasksCount,

    } = useFlagButtonStore(state=>state);

    




    const refreshProject = () => {
        // let param = { search: search, ...paramData, ...filterParams, overallStatus: overallStatus   }
        // let param = {
        //     search: search, page: pageCurrent,
        //     overallStatus: overallStatus,
        //     limit: pageSize,
        //     //userId: userId,
        //     userId: tasksUserId?tasksUserId:null,
        // }
        // let param = { 
        //     search: allSearch,
        //     page: pageMeta?.currentPage ,
        //     overallStatus: overallStatus, 
        //     userId:tasksUserId ,
        //     limit: pageMeta?.itemsPerPage,
        //     status: filtersList?filtersList?.status: null,
        //     teamId: filtersList? filtersList?.teamId : tasksTeamId? tasksTeamId.toString(): null,
        //     projectId: filtersList?filtersList?.projectId:null,
        //     associateId: filtersList?filtersList?.associateId:null
        //   }
          let param = {
            search: allSearch, page: pageMeta?.currentPage,
            overallStatus: overallStatus,
            limit: pageMeta?.itemsPerPage,
            userId: tasksUserId?tasksUserId:null,
            status: filtersList?filtersList?.status: null,
            //teamId: tasksTeamId? tasksTeamId.toString():filtersList?filtersList?.teamId: null,
            teamId: (filtersList && filtersList?.teamId)? filtersList?.teamId : tasksTeamId? tasksTeamId.toString(): null,
            projectId: filtersList?filtersList?.projectId:null,
            associateId: (filtersList && filtersList?.associateId)?filtersList?.associateId:null,
        }

        //This API gives you all tasks if userId is not given aswell as user specific tasks if userId is given
        getAllUserTaskApi(param, (res) => {
            setAllTask(res?.data);
            setPageMeta(res?.meta)
            setTasksCount(res?.meta?.totalItems)
            console.log(pageMeta,"PROJECTANDTASKS")
        })
        setCancelFlag(true);
    }
   


    const handleDeleteButton = ()=>{
        //setDeleteModal({ type: "multipleTask", ids: checkedIds })
        setDeleteModal({ type: "multipleTask", ids: checkedIdsList })
    }
    const handleStatusButton = ()=>{
        //setUpdateModal({type: "multipleTaskStatus", ids: checkedIds})
        //console.log(checkedIdsList,"VIJAYA");
        setUpdateModal({type: "multipleTaskStatus", ids: checkedIdsList})
    }
    const handletransferButton = ()=>{
        //setTransferModal({type: "multipleTasksTransfer", ids: checkedIds, userId: userId})
        setTransferModal({type: "multipleTasksTransfer", ids: checkedIdsList, userId: tasksUserId?tasksUserId:''})
    }
    const handleCancelButton =() =>{

        setCancelFlag(!cancelFlag);

       
        setCheckedIdsList([]);
        setCheckedUserIdsList([]);
        
        
    }


    return (
        
        <div style={{margin:'30px 10px 10px 0px'}}>
            <div style={{display:'flex', flexDirection:'row',justifyContent:'space-between', marginLeft:'24px'}}>
                <h3 style={{fontSize:'1.5rem'}}>Projects & Tasks</h3>
                <div>
                    {(viewClass.manager) &&(flagButton) ? 
                    <div style={{ display: "flex", justifyContent: 'space-between',gap:'8px'}}>
                        <Button type="primary" ghost onClick={handleCancelButton} style={{borderRadius:'3px',color:'#6A6A6A',border: '1px solid var(--Buttons-Neutral-default, #6A6A6A)', fontFamily:'Roboto'}}>Cancel </Button>
                        <Button type="primary" ghost onClick={handleStatusButton} style={{color:'#1089FF',border: '1px solid var(--Buttons-Neutral-default, #1089FF)', borderRadius:'3px', fontFamily:'Roboto'}}>Change Status </Button>
                        <Button style={{borderRadius:'3px',border: '1px solid var(--Buttons-Warning-default, #EE4E4E)', fontFamily:'Roboto'}} danger onClick={handleDeleteButton}>Delete </Button>
                        {enableTransfer?<Button type="primary"  style={{borderRadius:'3px',background: 'var(--Buttons-Tertiary-default, #FF8B2F)', fontFamily:'Roboto'}} onClick={handletransferButton} ><img src={transferIconWhite} alt="TransferIcon" style={{marginRight:'2px'}}/>Transfer </Button>
                        :<Tooltip title="Transfer tasks is enabled amongst team members of the same team, Please select a team that the associate belongs to from the team filter"><Button type="primary" disabled style={{borderRadius:'3px',background: 'var(--Buttons-Tertiary-default, #FF8B2F)', fontFamily:'Roboto', cursor:'not-allowed', opacity:'0.5'}} ><img src={transferIconWhite} alt="TransferIcon" style={{marginRight:'2px'}}/>Transfer </Button></Tooltip>}
                        </div>
                        : ''}
                </div>
            </div>
            <TabsMenu />
            {deleteModal !== undefined ? <DeleteProjectModal modal={deleteModal} setModal={setDeleteModal} refresh={refreshProject.bind(this)} /> : ''}
            {<StatusTasksModal modal={updateModal} setModal={setUpdateModal} refresh={refreshProject.bind(this)}/>}
            {<StatusTasksModal modal={transferModal} setModal={setTransferModal} refresh={refreshProject.bind(this)}/>}
       </div>
       
    )
}
export default ProjectsAndTasks;