import { create } from "zustand";
import {persist} from "zustand/middleware";

const loginStorageStore=(set)=>({
  isCandidate:false,
  isLogin:false, 
  setIsCandidate:(payload)=>set(state=>({isCandidate:payload})),                 
    setIsLogin:(val)=>set(state=>({isLogin:val})),
})

const useLoginStorageStore=create(
        persist(loginStorageStore,{name:"loginData",})
);
export default useLoginStorageStore;