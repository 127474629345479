
import React, { useEffect, useState } from "react";

import { Tooltip } from "antd";
import  classes from './BarGraph.module.css';
const BarChartData = (props) => {
  const firstColor=props?.firstColor?props?.firstColor:'#b6eefa';
  const secondColor=props?.secondColor?props?.secondColor:"#FFCBCB";
  const [index, setIndex] = useState();


  /////


  useEffect(() => {
    let data = { name: [], joined: [], resigned: [] };
    props.data.forEach((ele) => {
      data?.name?.push(ele?.month);
      data?.joined?.push(ele?.joined);
      data?.resigned?.push(ele?.resigned);
    });
    let max = Math.max(Math.max(...data.joined), Math.max(...data.resigned));

    let index = Math.ceil(max / 20) * 5;
    index=index===0?1:index;
    setIndex(index);
  }, [props.data]);

 

  ///////
  const graphHeight=300;  
  let graphlength=index*4;
      return (
          <>
      <div style={{width:"100%",}}>
     <div style={{ display:'flex',flexDirection:'row',height:"100%", width:"100%", alignItems:"flex-end", backgroundColor:"white",}}>
              <div style={{marginRight:'6px'}}>
                  {Array(5).fill().map((item, id)=>{
                     return(
                          <div style={{height:Math.floor(graphHeight/4),display:"flex", justifyContent:"flex-end", alignItems:"flex-end",}}>
                             { index*(4-id)} 
                          </div>
                      )
                  
                  })}
                  <div style={{height:'30px'}}></div>
              </div>
              <div style={{position:"relative" , width:"100%",overflowX:'scroll',overflowY:'unset'} }>
                  <div className={classes.ColumnGap}  style={{display:'flex',flexDirection:'row', height:graphHeight+40, alignItems:'flex-end',}}>
                      {
                              props?.data?.map((item,id)=>{
                                 let joinedHeight=Math.floor(item.joined*graphHeight/graphlength);
                                 let resignedHeight=Math.floor(item.resigned*graphHeight/graphlength);
                              return(
                                 
                                  <div id={id}  style={{width:'100%', display:"flex", alignItems:"center",height:"100%",flexDirection:"column",gap:'10px' }}>
                                     <div className={classes.BarGap} style={{display:'flex'}}>
                                     {/* { id===0 &&<div style={{height:graphHeight,background:'black',width:'1px',marginRight:'16px'}}></div>} */}
                                      <div  style={{  height:graphHeight, display:"flex", justifyContent:"flex-end",alignItems:'flex-end',background:'#e8e8e8',borderRadius:'5px',}}>
                                         <Tooltip trigger={'hover'} placement="right" title={item?.joined}>  <div className={classes.BarWidth} style={{backgroundColor:firstColor, height:joinedHeight, borderRadius:'4px',cursor:'pointer',}}>
                                          </div> </Tooltip>
                                      </div>
                                      <div  style={{  height:graphHeight, display:"flex", justifyContent:"flex-end",alignItems:'flex-end',background:'#e8e8e8',borderRadius:'5px'}}>
                                         <Tooltip trigger={'hover'} placement="right" title={item?.resigned} >  <div className={classes.BarWidth} style={{backgroundColor:secondColor, height:resignedHeight, borderRadius:'4px',cursor:'pointer'}}>
                                          </div> </Tooltip>
                                      </div>
                                      </div>
                                      <div style={{fontSize:16 }}>{item.month}</div>
                                  </div>
                                 
                                 
                              )
                          })
                      }
                  </div>
                  {/* <div style={{width:'100%',marginTop:-graphHeight-60,overflowX:'scroll'}}>
                    {(Array(5).fill().map((val,id)=>{
                      return(<div style={{width:'95%',height:Math.floor(graphHeight/4),display:'flex',justifyContent:'flex-end',flexDirection:'column',alignItems:'flex-end'}}>
                        <div style={{width:'100%',height:'1px',border:'1px dashed #ccc'}}></div>
                        </div>
                      )
                    }))}
                    <div style={{height:'30px'}}></div>
                  </div> */}
              </div>
        </div>
     </div>
     </>
    
    
  
   
        )
    
  }

export default BarChartData;