import { DatePicker, Pagination, Select, Spin, Typography } from "antd";
import React, { useState } from "react";
import classes from './Department.module.css';
import Graphs from "../Dashboard/Graph";
import { useEffect } from "react";
import TableCustom from "../../../components/tableCustom/customTable";
import { useFPStore } from "../store";
import { associatesData, associatesTableColumn } from "../Associates/Associates";
import { EyeOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import styled, { keyframes } from 'styled-components';
import { UnnecessaryBackendSupport, getAssociateList, getDashGraphInfo, getDeptExtraInfo, getFPDepartment } from "../Action";
import moment from "moment";
import RecommendModal from "../Associates/RecommendModal";
import DeptRecommendModal from "./DeptRecommendModal";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import SearchCompo from "../../../design/SearchCompo";
import { NoData } from "../../Training_essentialDocuments";
import user_icon from '../../../assets/Profile Icon.svg';


const DeptCard=({item,key,selectedDept,setSelectedDept})=>{
    return (
    <div style={{cursor:"pointer"}}  className={`${classes.card} ${selectedDept===item?.departmentId?classes.selected:""}`} key={key} onClick={()=>{
        setSelectedDept(item?.departmentId);
    }}>   
            <span><Typography.Text className={classes.cardDeptName} ellipsis={{tooltip:true}}>{item?.deptName}</Typography.Text></span>
    </div>
    )
}

const DeptScroll=({dashDeptInfo,selectedDept,currentIndex,setCurrentIndex,loader,setSelectedDept})=>{
   const {width}=useWindowDimensions();
  
    const showSlide = (index) => {
      if (index < 0) {
        index = dashDeptInfo?.data?.length - 1;
      } else if (index >= dashDeptInfo?.data?.length) {
        index = 0;
      }
      setCurrentIndex(index);
    };
  
    const prevSlide = () => {
        let index=currentIndex-0.25
        if(width>700)
        {
            showSlide(index - 4);
        }
        else
        {
            showSlide(index - 1);
        }
    };
  
    const nextSlide = () => {
        let index=currentIndex+0.25

        if(width>700)
        {
            showSlide(index + 4);
        }
        else
        {
            showSlide(index + 1);
        } 
    };
    let slideInAnimation;
    if(!loader)
    {
        slideInAnimation = keyframes`
        0% {
          transform: translateX(0%);
        }
        100% {
       transform:  translateX(-${currentIndex * 100}%);
         
        }
      `;
    }
// Create a styled component with the animation
let AnimatedDiv = styled.div`
  animation: ${slideInAnimation} 0.5s linear forwards; /* Apply the keyframe animation */
`;
let tempCard=dashDeptInfo?.data?.filter((item)=>item?.departmentId==selectedDept)?.[0];
    return(
        <div className={classes.deptScroll}>
            <div 
            // onClick={prevSlide}
            >
                {/* <LeftOutlined/> */}
                </div>
            <div >
                {
                    selectedDept?<DeptCard item={tempCard} key={1} index={0} selectedDept={selectedDept} />:
                
                dashDeptInfo?.data?.map((item,index)=>{
                    return(
                       
                        <DeptCard item={item} key={index} selectedDept={selectedDept} setSelectedDept={setSelectedDept}/>
                        
                    )
                })}
            </div>
            <div
            //  onClick={nextSlide}
             >
                {/* <RightOutlined/> */}
                </div>
        </div>
    )
}



const FPDepartment=()=>{
    const Option=Select.Option;
    const [tab,setTab]=useState(1);
    const [graphData,setGraphData]=useState([]);
    const [dateMonth,setDateMonth]=useState(moment().format('YYYY-MM'));
    const [selectedDept,setSelectedDept]=useState("");
    const [modalData,setModalData]=useState();
    const [PageLoader, setPageLodaer]=useState(false);
    const [loader,setLoader]=useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const {width}=useWindowDimensions();
    const [search,setSearch]=useState('');



    const {setPageSize,setPaginationPage,pageSize,paginationPage,
        showModal,setShowModal,
        associateInfoHandler,
        associateInfo,
        dashDeptInfoHandler,
        dashDeptInfo,
        dashGraphDataHandler,
        dashGraphData,
        deptExtraInfoHandler,
        deptExtraInfo,
        tableLoader,
        tableLoaderHandler
    }=useFPStore();
    let screenType="department"

    useEffect(()=>{
        setLoader(true);
        if(tab===1)
        {
            getDashGraphInfo((res)=>{
                dashGraphDataHandler(res);
                setLoader(false);
            },selectedDept,dateMonth);
        }  
    }
    ,[selectedDept,dateMonth,tab])

    useEffect(()=>{
        UnnecessaryBackendSupport();
     }
     ,[])

     useEffect(()=>{
        getFPDepartment((res)=>{
            dashDeptInfoHandler(res);
            setPageLodaer(false);
        },dateMonth);

    },[dateMonth])

     const getAssociateCall=()=>{
        tableLoaderHandler(true);
        getAssociateList((res)=>{
            associateInfoHandler(res);
            tableLoaderHandler(false);
        },search?1:paginationPage,pageSize,selectedDept,search,dateMonth);
     }
    useEffect(()=>{
        if(tab===2)
        {
            getAssociateCall();
        }
    },[pageSize,paginationPage,selectedDept,search,tab,dateMonth]);

   

    let tempAssociates=dashDeptInfo?.data?.filter((item)=>item.departmentId===selectedDept)?.[0];
    console.log("showModal123",showModal)

    return(
        <>
            {PageLoader?<Spin className={classes.spin}></Spin>:<div>
                    <header className={classes.header}>
                        <div>Departments</div>
                       {dashDeptInfo&&<DeptScroll currentIndex={currentIndex} loader={ tab===1?loader:tableLoader} setCurrentIndex={setCurrentIndex} dashDeptInfo={dashDeptInfo} selectedDept={selectedDept} setSelectedDept={setSelectedDept}/>}
                        <div>

                        </div>
                        {selectedDept&&<div className={classes.basicInfo}>
                            <div>
                                <div>Department Head:</div>
                                <div>{deptExtraInfo?.hodName}</div>
                            </div>
                            <div>
                                <div>No Of Teams:</div>
                                <div>{deptExtraInfo?.team}</div>
                            </div>
                            <div>
                                <div>Associates:</div>
                                <div>{(tempAssociates?.totalUserFeedbackData?tempAssociates?.totalUserFeedbackData:0)+"/"+(tempAssociates?.deptWiseTotalUserData?tempAssociates?.deptWiseTotalUserData:0)}</div>
                            </div>
                        </div>}
                        <div className={classes.tab}>
                            <div>
                                <div
                                className={tab===1?classes.activeTab:""}
                                onClick={()=>{
                                    setTab(1);
                                    // setCurrentIndex(0);
                                    // setSelectedDept("");
                                }}
                                >Graph View</div>
                                <div
                                className={tab===2?classes.activeTab:""}
                                onClick={()=>{
                                    setTab(2);
                                    // setCurrentIndex(0);
                                    // setSelectedDept("");
                                }}
                                >Associate List</div>
                            </div>
                            <div>
                            {tab===2?<SearchCompo setSearch={setSearch} style={{height:"2rem",border:"1px solid #dfdfdf",width:width>800?"13rem":"15rem"}}/>:<div></div>}
                            <div>
                            <Select 
                             showSearch
                             filterOption={(input, option) =>
                                 option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                               }
                            style={{width:width>800?"12rem":"40vw"}}
                            allowClear={true}
                            placeholder="Select Department"
                            value={selectedDept?selectedDept:undefined}
                            onChange={(e)=>{
                                setSelectedDept(e);
                                let item=dashDeptInfo?.data?.filter((item)=>item.departmentId===e)?.[0];
                                getDeptExtraInfo(deptExtraInfoHandler,item?.departmentId,item?.headId);
                                let index=dashDeptInfo?.data?.findIndex((el=>el.departmentId===e));
                                setCurrentIndex(index+(index*6.2/100));
                            }}
                            >
                                {/* dashDeptInfo?.data */}
                                {dashDeptInfo?.data?.map((item,index)=>{
                                    return <Option value={item?.departmentId}>{item?.deptName}</Option>
                                })}
                            </Select>
                                <DatePicker style={{width:width>800?"10rem":"40vw"}} placeholder={`${moment().format('YYYY-MM')}`} picker="month" onChange={(date,datestring)=>{
                            if(!datestring)
                            {
                                setDateMonth(moment().format('YYYY-MM'));
                            }
                            else
                            {
                                
                                setDateMonth(datestring)
                            }
                        
                            }}/>
                            </div>
                            </div>
                        </div>
                    </header>

                    <div>
                    <div className={classes.content}>
                    {tab===1?
                    <section className={classes.graphDiv}>
                         {loader?<Spin className={classes.spin2}></Spin>:<Graphs data={dashGraphData} dateMonth={dateMonth} selectedDept={selectedDept} ></Graphs>}
                </section>
                    :
                    width>700?
                    <>
                    {/* <TableCustom
                    style={{height:"70vh",}}
                    columns={associatesTableColumn}
                    dataSource={associatesData(setShowModal,associateInfo,setModalData,width,screenType)} 
                    loading={tableLoader} 
                    pagination={
                        {
                        total: parseInt(associateInfo?.meta?.totalItems),
                        current: parseInt(paginationPage),
                        showSizeChanger: true,
                        pageSizeOptions: [10,20, 30, 50, 100],
                        pageSize: pageSize,                
                        onChange: (page,pageSize) =>{
                            setPaginationPage(page);
                            setPageSize(pageSize);
                        }, 
                        }} /> */}

                            <div className={classes.tableHeader}>
                                <span>Associates</span>
                                <span>Contact Details</span>
                                <span>Designation</span>
                                <span>Department</span>
                                <span>Team</span>
                                <span>Manager</span>
                                <span>Recommendations</span>                     
                          </div>
                          {tableLoader?<Spin style={{display:"flex",alignItems:"center",justifyContent:"center",height:"67vh"}}></Spin>:<div className={classes.rowContainer}>
                          {
                            associateInfo?.items?.length===0?
                            <NoData/>
                            :
                          associateInfo?.items?.map((item,index)=>{
                              return  (
                                <div className={classes.eachRow}>
                                     
                                      <div onClick={()=>{
                                        setModalData(item);
                                          setShowModal({recommend:false, feedback:true});}}>
                                            <img style={{height:"4vh",width:"4vh",borderRadius:"50%"}} src={item?.profileImage?item?.profileImage:user_icon}/>
                                            <div>
                                          <div>
                                            
                                            <Typography.Text style={{width:"8vw",}} ellipsis={{tooltip:true}} >{item?.name}</Typography.Text>
                                            </div>
                                          <div className={classes.greyContent}>{item?.uid}</div>
                                          </div>
                                      </div>
                                     
                                      <div onClick={()=>{ 
                                        setModalData(item);
                                        setShowModal({recommend:false, feedback:true});}}>
                                          <div>
                                            <Typography.Text style={{width:"8vw",}} ellipsis={{tooltip:true}}>{item?.email}</Typography.Text>
                                            </div>
                                          <div className={classes.greyContent}>{item?.phone}</div>
                                      </div>
                                     
                                      <div onClick={()=>{  
                                        setModalData(item);
                                        setShowModal({recommend:false, feedback:true});}}>
                                          <Typography.Text style={{width:"8vw",}} ellipsis={{tooltip:true}}> {item?.designation?item?.designation:"NA"}</Typography.Text>
                                        </div>
                                     
                                      <div onClick={()=>{
                                        setModalData(item);
                                          setShowModal({recommend:false, feedback:true});}}>
                                          <Typography.Text style={{width:"8vw",}} ellipsis={{tooltip:true}}> {item?.teamDetails?.departmentName?item?.teamDetails?.departmentName:"NA"}</Typography.Text>
                                        </div>
                                     
                                      <div onClick={()=>{ 
                                        setModalData(item);
                                        setShowModal({recommend:false, feedback:true});}}> 
                                          <Typography.Text style={{width:"8vw",}} ellipsis={{tooltip:true}}> {item?.teamDetails?.teamName?item?.teamDetails?.teamName:"NA"}</Typography.Text>                        
                                        </div>
                                    
                                      <div onClick={()=>{ 
                                        setModalData(item);
                                        setShowModal({recommend:false, feedback:true});}}>
                                        <Typography.Text style={{width:"8vw",}} ellipsis={{tooltip:true}}>  {item?.teamDetails?.managerName?item?.teamDetails?.managerName:"NA"}</Typography.Text>                    
                                        </div>
                                    
                                      <>
                                      {(item?.remark==="NA"||item?.remark?.answer==="NA"||item?.remark?.answer==="N/A"||item?.remark?.answer.trim()==="")?<span style={{color:"#9B9B9B",fontSize:"0.9rem",fontWeight:"500"}}>Not Provided</span>:<div 
                                      style={{marginRight:"0.5rem"}}
                                      className={classes.recommendations}
                                      onClick={()=>{
                                          setShowModal({feedback:false, recommend:true});
                                          setModalData(item);
                                          // if(screenType==="department")
                                          // {
                                          //   getAssociate();
                                          // }
                                      }}
                                      >
                                          <div>{"View Remarks"}</div>
                                          <EyeOutlined/>
                                      </div>}
                                      </>
                                      </div>
                                  
                          )
        
                            })}
                          </div>}
                          <Pagination position="bottomRight" size="middle" style={{display:"flex",justifyContent:"flex-end",marginTop:"0.5rem",marginRight:"1rem"}}
                          current={parseInt(paginationPage)} showSizeChanger={true} 
                          total={parseInt(associateInfo?.meta?.totalItems?associateInfo?.meta?.totalItems:0)}
                          pageSize={pageSize} 
                          onChange={(page,pageSize)=>{
                            setPaginationPage(page);
                            setPageSize(pageSize);
                          }}
                          onShowSizeChange={(page,pageSize)=>{
                            setPaginationPage(page);
                            setPageSize(pageSize);
                          }} 
                          pageSizeOptions={[10,20,30,50,100,200]}
                          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} />
                        </>
                        
                        :
                        <div className={classes.mobContainer}>
                       
                        {associatesData(setShowModal,associateInfo,setModalData,width)}
                     
                        {associateInfo?.items?.length>0&&<Pagination position="bottomRight" size="middle" style={{display:"flex",justifyContent:"flex-end",marginTop:"0.5rem",marginRight:"1rem",marginBottom:"1rem"}}
                            current={paginationPage} showSizeChanger={true} 
                            total= {parseInt(associateInfo?.meta?.totalItems?associateInfo?.meta?.totalItems:0)}
                             pageSize={pageSize} 
                            onChange={(page,pageSize)=>{
                              setPaginationPage(page);
                              setPageSize(pageSize);
                            }}
                            onShowSizeChange={(page,pageSize)=>{
                              setPaginationPage(page);
                              setPageSize(pageSize);
                            }} 
                            pageSizeOptions={[10,20,30,50,100,200]}
                            showTotal={(total, range) => width>800?`${range[0]}-${range[1]} of ${total} items`:""} />}
  
        
                        </div>
                    }
                    </div>
                    </div>
                 {showModal?.feedback&&<DeptRecommendModal
                 setDateMonth={setDateMonth}
                 dateMonth={dateMonth}
                showModal={showModal}
                data={modalData}
                setShowModal={setShowModal}
                />}
                {showModal?.recommend&&<RecommendModal 
                dateMonth={dateMonth}
            modalData={modalData}
            showModal={showModal}
            setShowModal={setShowModal}
            />}
            </div>}
        </>
    )
}
export default FPDepartment;