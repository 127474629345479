import React, { useEffect, useState } from "react";
import classes from './profileWorkExp.module.css';
import { useResourceStore } from "../../store/store";
import { EditOutlined } from "@ant-design/icons";
import pdf_doc_icon from './../../../../assets/pdf_doc_logo.svg';
import { KeyIsNotEmpty, getPreviewData } from "./formData";
import moment from "moment";
import { getLink } from "../../../../Config";
const ProfileWorkExp=({workExperience})=>{
    const {page,setPage,setTab}=useResourceStore(state=>state);
    const [workExpInfo,setWorkExpInfo]=useState([])
    useEffect(()=>{
        let tempArr=[]
        for(let i=0; i<workExperience?.experienced?.length;i++){
            tempArr.push(
                {
                    title:"Work Experience",
                    key1:"Company Name",
                    value1:`${workExperience?.experienced[i]?.companyName?workExperience?.experienced[i]?.companyName:"NA"}`,
                    key2:"Start Date",
                    value2:`${workExperience?.experienced[i]?.startDate? moment(workExperience?.experienced[i]?.startDate).format('DD-MMM-YYYY'):"NA"}`,
                    key3:"Experience",
                    value3:KeyIsNotEmpty(workExperience?.experienced[i]?.totalExperience)?workExperience?.experienced[i]?.totalExperience:'NA',
                    key4:"End Date",
                    value4:`${workExperience?.experienced[i]?.endDate?moment(workExperience?.experienced[i]?.endDate).format('DD-MMM-YYYY'):"NA"}`,
                }, 
            )
        }
        setWorkExpInfo(tempArr);

    },[])
    return(
        <div className={classes.cardContainer}>
            {workExpInfo?.map((item,index)=>{
                return(
                    <div key={index} className={classes.card}>
                        <div>
                                    <div style={{font:'normal 600 18px/24px Lato',color:'162432'}} >{item.title}</div>
                                    <div style={{display:'flex',gap:'6px',color:'#0086ff'}} onClick={()=>{
                                        setPage({prev:page?.curr, curr:"editProfile",PD:'Work Experience'});
                                    setTab("Work Experience");
                                    }}>
                                        <EditOutlined />
                                        <span>Edit</span>
                                    </div>
                        </div>
                        <div>
                                <div>
                                        <div>
                                                <span>{item.key1}</span>
                                                <span>{item.value1}</span>
                                        </div>
                                        <div>
                                                <span>{item.key2}</span>
                                                <span>{item.value2}</span>
                                        </div>
                                </div>

                                <div>
                                        <div>
                                                <span>{item.key3}</span>
                                                <span style={{minWidth:'130px'}}>{item.value3}</span>
                                        </div>
                                        <div>
                                                <span>{item.key4}</span>
                                                <span>{item.value4}</span>
                                        </div>
                                </div>
                        </div>
                    </div>

                )
            })}

            <div className={classes.documents}>
                <div>
                <div style={{font:'normal 600 18px/24px Lato'}}>Documents Uploaded</div>
                </div>
                <div>

                    {getPreviewData({workExperience:workExperience},false,'Work Experience')[0]?.data?.length===0 && <div>No data found!</div>}
                    {getPreviewData({workExperience:workExperience},false,'Work Experience')[0]?.data?.map((item,index)=>{
                        if(item?.value && typeof(item?.value)==='string')
                        {
                            let link=(item?.value)
                        return(
                            <div key={index}>
                              {link?.includes('.pdf')?<img src={pdf_doc_icon} style={{cursor:"pointer",}} onClick={()=>{
                                    window.open(link,'_blank')?.focus();
                                }}/>:<img src={link} style={{cursor:"pointer",}} onClick={()=>{
                                    window.open(link,'_blank')?.focus();
                                }}/>}
                                <div>
                                    <>{item.label}</>
                                </div>
                            </div>
                        )
                            }

                    })}

                </div>

            </div>
        </div>
    )
}
export default ProfileWorkExp;