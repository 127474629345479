
import {EventRegister} from 'react-native-event-listeners';
import BrigoshaMedia from './media';
import Notification from '../../services/Notification';
import { throttleHigh } from './utils';
// import conferenceStore from './store';


// let socketTimer = false;
const brigoshaMedia = new BrigoshaMedia();
const throttledInitSocket = throttleHigh(()=>{
  // Notification.warning("Socket connection closed,Reconnecting")
  // brigoshaMedia.resumeServices()
  // setTimeout(()=>{
  //   brigoshaMedia.initSockets(false)
  // }, 3000);
}, 3000, {leading:true, trailing:false})
export const initListeners = (user, isTeacher, navigate,updateActiveUserList,updateLocalStreamData,updateMediaReady) =>  {
    EventRegister.addEventListener('mediaReady', roomInfo => {
      updateMediaReady(true)
      console.log("mediaready");
    // brigoshaMedia.produce('videoType');
     console.log("transport",brigoshaMedia.producerTransport,brigoshaMedia.consumerTransport);
    // brigoshaMedia.produce('audioType');
    if (window.location.pathname!='/room'){

        navigate("/room")
  
    
    
      
    }else{
      console.log("trying to produce")
      brigoshaMedia.produce('videoType');
     brigoshaMedia.produce('audioType');
    }
  });

  EventRegister.addEventListener('srvAVcontrols', data => {
    // dispatch(updateAVMute(data));
  });

  EventRegister.addEventListener('chatRcv', data => {
        console.log(data,"dataaaaarvcrecieved");
  });

  EventRegister.addEventListener('userList', data => {
    let temp= data.reverse()
    updateActiveUserList(temp)
    console.log(data,"userlist30");
    // dispatch(updateOnlineUsers(data));
  });

  EventRegister.addEventListener('userJoined', data => {
    console.log(data,"joined candidate");
    
    // if (data.user) {
    //   dispatch(
    //     updateChat(
    //       Utils.sendMessage(
    //         `${data.user.name} Joined`,
    //         data.user,
    //         {
    //           system: true,
    //         },
    //         new Date(),
    //       ),
    //     ),
    //   );
    // }
  });

  EventRegister.addEventListener('userLeft', data => {
    // if (data.user) {
    //   dispatch(
    //     updateChat(
    //       Utils.sendMessage(
    //         `${data.user.name} Left`,
    //         data.user,
    //         {
    //           system: true,
    //         },
    //         new Date(),
    //       ),
    //     ),
    //   );
    // }
  });

  EventRegister.addEventListener('muteAudio', () => {
    // dispatch(toggleAudio(false));
  });

  // EventRegister.addEventListener('openBoard', () => {
  //   if (window.location.pathname!='/liveclass'){
  //     history.push("/liveclass")
  //   }
  //   dispatch(toggleTeacherCanvas(true));
  // });

  EventRegister.addEventListener('recordingStarted', () => {
    // dispatch(updateScreenRecordingStatus(true));
  });

  EventRegister.addEventListener('pauseAudio', () => {
    // dispatch(updateScreenRecordingStatus(false));
  });

  // EventRegister.addEventListener('closeBoard', () => {
  //   dispatch(toggleTeacherCanvas(false));
  //   if (window.location.pathname!='/liveclass'){
  //     history.push("/liveclass")
  //   }
  // });





  EventRegister.addEventListener('joinFail', () => {
    // clearTimeout(socketTimer);
    // dispatch(updateStartLoader(false));
    // setTimeout(() => {
    //   const message = {
    //     type: 'error',
    //     text: 'Failed to join. Please check your network and try again.',
    //   };
    //   dispatch(toggleSnackbar(message));
    // }, 200);
  });

  EventRegister.addEventListener('localMedia', data => {
    console.log(data,"log110eventlistner");
    // updateLocalStreamData(data)
    // dispatch(updateLocalMedia(data));
  });
  EventRegister.addEventListener('audioReady', () => {


      // brigoshaMedia.pauseProducer('audioType');
    
  });
  EventRegister.addEventListener('remoteMedia', data => {
    console.log("media cccc",data);
    // dispatch(updateRemoteMedia(data));
  });
  EventRegister.addEventListener('activeSpeaker', data => {
    // dispatch(updateActiveSpeaker(data));
  });
  EventRegister.addEventListener('mediaPause', type => {
    // if (type === 'audioType') {
    //   dispatch(toggleAudio(false));
    // }
  });
  EventRegister.addEventListener('mediaResume', type => {
    // if (type === 'audioType') {
    //   dispatch(toggleAudio(true));
    // }
  });
  EventRegister.addEventListener('reconnect', data => {
    // dispatch(updateReconnectModal(false));
    // if (Object.keys(data.roomInfo.boardData).length !== 0) {
    //   dispatch(
    //     updateStudentPages(
    //       data.roomInfo.boardData.path,
    //       data.roomInfo.boardData.currentPage,
    //       true,
    //     ),
    //   );
    //   dispatch(updateCanvasReconnect(false));
    // }

    // if (data.roomInfo.board) {
    //   dispatch(toggleTeacherCanvas(true));
    // }
    // if (window.location.pathname!='/liveclass'){
    //   history.push("/liveclass")
    // }

    // dispatch(updateHandRaiseQ(data.roomInfo.handRequestQueue));

    // if (!data.produce) {
    //   return;
    // }
    // if (isTeacher) {
    //   brigoshaMedia.produce('videoType');
    // }
    // brigoshaMedia.produce('audioType');
  });

  EventRegister.addEventListener('disconnect', () => {});

  const reconnectSocket = () => (dispatch, getState) => {
    // dispatch(updateReconnectModal(true));
    // clearTimeout(socketTimer);
    // socketTimer = setTimeout(() => {
    //   const {isClassJoined} = getState().liveClass;
    //   const {networkState} = getState().common;

    //   if (isClassJoined && networkState.isConnected) {
    //     brigoshaMedia.initSockets(false);
    //   } else if (isClassJoined && !networkState.isConnected) {
    //     dispatch(reconnectSocket());
    //   } else {
    //     // not in class
    //     dispatch(updateReconnectModal(false));
    //     clearTimeout(socketTimer);
    //     socketTimer = false;
    //   }
    // }, 3000);
  };

  EventRegister.addEventListener('socketClose', () => {
    if(navigator.onLine && window.location.pathname=="/room"){
// brigoshaMedia.updateClassJoinStatus(false);
// brigoshaMedia.exit();
      // throttledInitSocket();
    // brigoshaMedia.initSockets(false)

    }
    if(window.location.pathname=="/meet/brigosha"){
      window.location.reload()

    }
    // dispatch(updateReconnectModal(true));
    // const {isClassJoined} = getState().liveClass;
/*     if (isClassJoined) {
      // dispatch(reconnectSocket());
    } */
  });

  // EventRegister.addEventListener('healthFail', () => {
  //   dispatch(updateReconnectModal(true));
  // });

  // EventRegister.addEventListener('socketReconnected', () => {
  //   dispatch(updateReconnectModal(false));
  // });

  EventRegister.addEventListener('producerClosed', kind => {
  /*   const {isClassJoined} = getState().liveClass;
    if (isClassJoined) {
     
    } else {
      // Do nothing
    } */
    brigoshaMedia.handleProducerFail(kind.streamType);
  });

  EventRegister.addEventListener('producerReinit', kind => {
  /*   if (isTeacher && kind === 'video') {
      brigoshaMedia.produce('videoType');
    }
    brigoshaMedia.produce('audioType'); */
  });

  EventRegister.addEventListener('producerTransportClosed', () => {
  /*   const {isClassJoined} = getState().liveClass;
    if (isClassJoined) {
      brigoshaMedia.handleProducerTransportFail();
    } else {
      // Do nothing
    } */
  });

  EventRegister.addEventListener('internetConnected', () => {
    // dispatch(updateReconnectModal(false));
  });

  EventRegister.addEventListener('internetDisconnected', () => {
    // dispatch(updateReconnectModal(true));
  });

  EventRegister.addEventListener('consumerTransportClosed', () => {
   /*  const {isClassJoined} = getState().liveClass;
    if (isClassJoined) {
      brigoshaMedia.handleConsumerTransportFail();
    } else {
      // Do nothing
    } */
  });

  EventRegister.addEventListener('dataProducerClosed', label => {
  /*   const {isClassJoined} = getState().liveClass;
    if (isClassJoined) {
      if (label == 'board') {
        dispatch(updateReconnectModal(true));
      }
      brigoshaMedia.handleDataProducerClosed(label);
    } else {
      // Do nothing
    } */
  });

  EventRegister.addEventListener('dataProducerConnected', label => {
   /*  if (label == 'board') {
      dispatch(updateReconnectModal(false));
    } */
  });

  EventRegister.addEventListener('exitRoom', () => {
  //  const {isClassJoined} = getState().liveClass;
  //   if (!isClassJoined) {
  //     return;
  //   }
    // dispatch(updateReconnectModal(false));
    brigoshaMedia.updateClassJoinStatus(false);
    brigoshaMedia.exit();
    if (window.location.pathname==="/room"){
    navigate(-1)
    }
    setTimeout(() => {
      const message = {
        type: 'error',
        text: 'Something went wrong. Please check your network and try again.',
      };
      // dispatch(toggleSnackbar(message));
    }, 200);
  });
};

export default initListeners;
