import React from 'react';
import { Modal } from 'antd';
import calIcon from "../../assets/calmeet.svg";
import clock from "../../assets/clockmeet.svg";
import Copy from "../../assets/copyLink.svg"
import Notification from '../../services/Notification';

const Info = ({ roundDetails, info, setInfo }) => {
    return (
        <Modal
            open={info}
            onCancel={() => { setInfo(false) }}
               
            footer={null}
            header={null}
            width={"40vw"}
            // backgroundColor={"#2D2D2D"}
            padding={null}
            style={{ backgroundColor: "#2D2D2D",padding:5 }}
        >
            <div style={{
                marginTop: 20,
               
                backgroundColor: "#2D2D2D",
                borderRadius: 5
            }}>
                <div style={{ width: "100%", display: "flex", padding: 15, flexDirection: "column" }}>
                    <div style={{ color: "white", fontSize: 16, fontWeight: 300, marginTop: 10 }}>
                        Session details
                    </div>
                    <div style={{ color: "white", fontSize: 16, fontWeight: 600, marginTop: 20 }}>
                        {roundDetails?.info}
                    </div>
                    <div style={{ display: "flex", width: "100%", gap: "1rem", marginTop: 20 }}>
                        <div style={{ display: "flex", width: "100%", gap: "1rem", alignItems: "center" }}>
                            <img src={calIcon} style={{ height: 15, width: 15 }} alt="calendar icon" />
                            <div style={{ color: "white", fontSize: 10, fontWeight: 300 }}>{roundDetails?.date}</div>
                        </div>
                        <div style={{ display: "flex", width: "100%", gap: "1rem", alignItems: "center" }}>
                            <img src={clock} style={{ height: 15, width: 15 }} alt="clock icon" />
                            <div style={{ color: "white", fontSize: 10, fontWeight: 300 }}>{roundDetails?.time}</div>
                        </div>
                    </div>
                    <div onClick={() => {
                       
                            // Get the text or link you want to copy
                            const linkToCopy = roundDetails?.link;

                            // Create a temporary textarea element
                            const textarea = document.createElement('textarea');
                            textarea.value = linkToCopy;

                            // Append the textarea to the document
                            document.body.appendChild(textarea);

                            // Create a range and select the text
                            const range = document.createRange();
                            range.selectNode(textarea);
                            window.getSelection().removeAllRanges();
                            window.getSelection().addRange(range);

                            // Execute the copy command
                            document.execCommand('copy');

                            // Remove the temporary textarea
                            document.body.removeChild(textarea);
                             Notification.success("Link copied to clipboard")
                            // You can provide some feedback to the user (optional)
                            // alert('Link copied to clipboard!');
                       

                    }} style={{ color: "#1089FF", fontSize: 14, fontWeight: 400, marginTop: 20, cursor:"pointer" }}>
                        <div style={{color:"#1089FF"}}>{roundDetails?.link} <img src={Copy} style={{height:12,width:12}}/></div>
                        
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default Info;
