import React from 'react'
import SiderLayout from '../../components/siderLayout';


import AppraisalApp from './AppraisalApp';
import { Provider } from 'react-redux';

import store from './store'

const Appraisal = () => {
  
  return (
   <Provider store={store}>
    <AppraisalApp/>
   </Provider>
  )
}

export default Appraisal