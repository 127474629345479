// import React from 'react';
// import { Select } from 'antd';
// import {getUserSearchApi} from "./ProjectAction"
// import { useState,useEffect } from 'react';

// import { useFlagButtonStore } from '../Release/store';





// const SearchComponent = () => {

//     const {flagButton,
//         setFlagButton,
//         cancelFlag,
//         allTask,
//         tasksUserId,
//         checkedIdsList,
//         setCheckedIdsList,
//         setCancelFlag,
//         setAllTask,
//         setTasksUserId,
//         tasksUserName,
//         setTasksUserName,
        
    
//     } = useFlagButtonStore(state=>state)

//     const [options, setOptions] = useState();



//     const onChange = (value) => {
//         console.log(`selected ${value}`);
//         setTasksUserId(value);
//     };
    
//     const onSearch = (value) => {
//         //setTasksUserId(value);
//         console.log(value,"SEARCHCOMPONENT");
//     };

//     const onClear = () => {
//         setTasksUserId(null); // Reset tasksUserId
//         console.log("Cleared selection");
//     };

//     const mappedOptions = options?.map(option => ({
//         label: option?.name,
//         value: option?.id
//     }));


  

 
//     useEffect(()=>{
//         getUserSearchApi( (res)=>{
//             setOptions(res);
            
//         })
//     },[]);
//     useEffect(() => {
//         if (tasksUserId) {
//             console.log(tasksUserId, "SEARCHCOMPONENT");
//             console.log(tasksUserName, "SEARCHCOMPONENT");
//         }
//     }, [tasksUserId]);

//     return (

//   <Select
//     showSearch
//     placeholder={tasksUserId?tasksUserName:"Search"}
//     optionFilterProp="label"
//     onChange={onChange}
//     onSearch={onSearch}
//     onClear={onClear} 
    
//     options={mappedOptions}
//     allowClear
//     style={{height:'40px', width:'250px'}}
//   />
// )};
// export default SearchComponent;
import React, { useState, useEffect } from 'react';
import { Input, Dropdown, Menu } from 'antd';
import { getUserSearchApi } from './ProjectAction';
import { useFlagButtonStore } from '../Release/store';
import { current } from '@reduxjs/toolkit';

const SearchComponent = () => {
    const {
        flagButton,
        setFlagButton,
        cancelFlag,
        allTask,
        tasksUserId,
        checkedIdsList,
        setCheckedIdsList,
        setCancelFlag,
        setAllTask,
        setTasksUserId,
        tasksUserName,
        setTasksUserName,
        allSearch,
        setAllSearch,
        pageMeta,
        setPageMeta
    } = useFlagButtonStore(state => state);

    const [options, setOptions] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);
    //const [searchValue, setSearchValue] = useState('');

    // useEffect(() => {
    //     // Fetch user data and set options
    //     getUserSearchApi((res) => {
    //         setOptions(res);
    //     });
    // }, []);

    useEffect(() => {
        if (tasksUserId||tasksUserName) {
            console.log(tasksUserId, 'SEARCHCOMPONENT');
            console.log(tasksUserName, 'SEARCHCOMPONENT');
            setAllSearch(tasksUserName);
        }
    }, [tasksUserId, tasksUserName]);

    

    // Filter options based on search input
    const handleSearch = (value) => {
        //setSearchValue(value);
        setAllSearch(value);
        setPageMeta({currentPage:1})
        console.log(pageMeta,"PAGEMETACHECK");

        
        // if (value) {
        //     const filtered = options.filter((option) =>
        //         option.name.toLowerCase().includes(value.toLowerCase())
        //     );
        //     setFilteredOptions(filtered);
        // } else {
        //     setFilteredOptions([]);
        // }
    };

    // Handle selecting an option from the dropdown
    // const handleSelect = (value, option) => {
    //     setTasksUserId(option.key); // Option's id as the value
    //     setTasksUserName(option.label); // Option's name as the label
    //     setSearchValue(option.label); // Set the input to the selected option
    // };

    // Handle clearing the input
    const handleClear = () => {
        //setTasksUserId(null);
        //setTasksUserName('');
        //setSearchValue('');
        setAllSearch('')
        //setFilteredOptions([]);
    };

    // Create a menu for the dropdown
    // const menu = (
    //     <Menu>
    //         {filteredOptions.map((option) => (
    //             <Menu.Item key={option.id} label={option.name} onClick={() => handleSelect(option.id, option)}>
    //                 {option.name}
    //             </Menu.Item>
    //         ))}
    //     </Menu>
    // );

    return (
        <div style={{ position: 'relative', width: '250px' }}>
            <Input
                value={allSearch}
                placeholder='Search'
                onChange={(e) => handleSearch(e.target.value)}
                allowClear
                onClear={handleClear}
                style={{ height: '40px' }}
            />
            
        </div>
    );
};

export default SearchComponent;
// import React, { useState, useEffect } from 'react';
// import { Input, Dropdown, Menu } from 'antd';
// import { CloseCircleOutlined } from '@ant-design/icons';
// import { getUserSearchApi } from './ProjectAction';
// import { useFlagButtonStore } from '../Release/store';

// const SearchComponent = () => {
//     const {
//         flagButton,
//         setFlagButton,
//         cancelFlag,
//         allTask,
//         tasksUserId,
//         checkedIdsList,
//         setCheckedIdsList,
//         setCancelFlag,
//         setAllTask,
//         setTasksUserId,
//         tasksUserName,
//         setTasksUserName
//     } = useFlagButtonStore(state => state);

//     const [options, setOptions] = useState([]);
//     const [filteredOptions, setFilteredOptions] = useState([]);
//     const [searchValue, setSearchValue] = useState('');

//     useEffect(() => {
//         // Fetch user data and set options
//         getUserSearchApi((res) => {
//             setOptions(res);
//         });
//     }, []);

//     useEffect(() => {
//         if (tasksUserId) {
//             console.log(tasksUserId, 'SEARCHCOMPONENT');
//             console.log(tasksUserName, 'SEARCHCOMPONENT');
//         }
//     }, [tasksUserId]);

//     // Filter options based on search input
//     const handleSearch = (value) => {
//         setSearchValue(value);
//         if (value) {
//             const filtered = options.filter((option) =>
//                 option.name.toLowerCase().includes(value.toLowerCase())
//             );
//             setFilteredOptions(filtered);
//         } else {
//             setFilteredOptions([]);
//         }
//     };

//     // Handle selecting an option from the dropdown
//     const handleSelect = (value, option) => {
//         setTasksUserId(option.key); // Option's id as the value
//         setTasksUserName(option.label); // Option's name as the label
//         setSearchValue(option.label); // Set the input to the selected option
//     };

//     // Handle clearing the input
//     const handleClear = () => {
//         setTasksUserId(null);
//         setTasksUserName('');
//         setSearchValue('');
//         setFilteredOptions([]);
//     };

//     // Create a menu for the dropdown
//     const menu = (
//         <Menu>
//             {filteredOptions.map((option) => (
//                 <Menu.Item key={option.id} label={option.name} onClick={() => handleSelect(option.id, option)}>
//                     {option.name}
//                 </Menu.Item>
//             ))}
//         </Menu>
//     );

//     return (
//         <div style={{ position: 'relative', width: '250px' }}>
//             <Input
//                 value={searchValue}
//                 placeholder={tasksUserId ? tasksUserName : 'Search'}
//                 onChange={(e) => handleSearch(e.target.value)}
//                 style={{ height: '40px', paddingRight: '30px' }} // Add padding to make room for the clear button
//                 suffix={
//                     searchValue ? (
//                         <CloseCircleOutlined
//                             onClick={handleClear}
//                             style={{ color: 'rgba(0, 0, 0, 0.45)', cursor: 'pointer' }}
//                         />
//                     ) : null
//                 }
//             />
//             {searchValue && filteredOptions.length > 0 && (
//                 <Dropdown overlay={menu} visible>
//                     <div />
//                 </Dropdown>
//             )}
//         </div>
//     );
// };

// export default SearchComponent;
