
import Api from "../../../../services/Api";

export const allGeolocation = (params, callback) => {
  Api.get("/attendance-setting/allGeoLocations")
    .params(params)
    .send((response) => {
      if (response) {
        const modifiedResponse = response.items.map((item) => {
          const { city, ...rest } = item;
          return {
            ...rest,
            locationId: city.id,
            cityName: city.cityName
          };
        });

        // Call the callback function with the modified response
        callback({ totalItem: response?.meta?.totalItems, data: modifiedResponse });
      }
    });
};

export const deleteGeoLocation = (params, callback) => {
  Api.delete("/attendance-setting/deleteGeoLocation")
    .params(params)
    
    .send((response, error) => {
      if (error) {
        callback(0);
      } else {
        callback(response);
      }})
  // Return a cleanup function that can be called to check if the API call has been mad
};


export const addGeoLocation = (params, callback) => {
 
  let apiCalled = false;

  
  Api.post("/attendance-setting/addGeoLocation")
    .params(params)
    
    .send((response, error) => {
     
      apiCalled = true;

      if (error) {
       
        console.error("API call error:", error);
        callback(false, error); 
      } else if (response && response.status === "success") {
       
        console.log("API call successful");
        callback(true, null);
      } else {
        
        console.error("API call response:", response);
        callback(false, null); 
      }
     });

  // Return a cleanup function that can be called to check if the API call has been made
  return () => {
    if (!apiCalled) {
      console.log("API call has not been made yet");
      // Handle the case where the API call has not been made
      // e.g., show a loading indicator or display an error message
    }
  };
};

export const updateGeoLocation = (params, callback) => {
  Api.patch(`/attendance-setting/updateGeoLocation?id=${params?.id}`)
    .params(params)    
    .send((response, error) => {
      if (error) {
        callback(0);
      } else {
        callback(response);
      }})
};

export const allLeave = (params, callback) => {

  Api.get("/attendance-setting/allLeaves")
  .params(params)
  .send((response, error) => {
    if (error) {
      callback(0);
    } else {
      callback(response);
    }})
};


export const addCityData = (params, callback) => {
  Api.post("/attendance-setting/addCity")
  .params(params)
  .send((response, error) => {
    if (error) {
      callback(0);
    } else {
      callback(response);
    }})
};
export const updateCity = (params,id, callback) => {
  Api.patch(`/attendance-setting/updateCity?id=${id}`)
  .params(params)
  .send((response) => {
    if (response) {
      callback(response);
    }
  });
}; 

export const allCity = (callback) => {
  Api.get("/attendance-setting/allCity")
  .send((response, error) => {
    if (error) {
      callback(response);
    } else {
       // No error, pass the response to the callback
      callback(response);
    }})
};

export const allDepartmentList = (callback) => {
  Api.post("/project-department/deptDropDownforAttendance")
  .send((response, error) => {
    if (error) {
      callback(response);
    } else {
      callback(response);
    }})
};

export const allAssociateList = (callback) => {
  Api.post("/attendance-setting/associateList")
  .send((response, error) => {
    if (error) {
      callback(response);
    } else {
      callback(response);
    }})
};

export const allDeptandAssociate = (holidayListBy,currentPageHoliday,pageSize,searchVal,callback) => {

  Api.post(`/attendance-setting/getHolidayData?type=${holidayListBy}&page=${currentPageHoliday}&limit=${pageSize}&search=${searchVal}`)
    // .params(params)
    .send((response, error) => {
      if (error) {
        callback(0);
      } else {
        callback(response);
      }})
};


export const deleteCity = (id,callback) => {
  Api.delete(`/attendance-setting/deleteCity?id=${id}`)
  .send((response, error) => {
    if (error) {
      callback(0);
    } else {
      callback(response);
    }})
};

export const deleteHoliday = (id,callback,check) => {
  const param = check ? 'cityId' : 'id';
Api.delete(`/attendance-setting/deleteHoliday?${param}=${id}`)
  .send((response, error) => {
    if (error) {
      callback(0);
    } else {
      callback(response);
    }})
};

export const deleteDeptAssociateHoliday = (holidayListBy,id,TypeId,callback) => {
Api.delete(`/attendance-setting/deleteHolidaysData?type=${holidayListBy}&holidayId=${id}&id=${TypeId}`)
  .send((response, error) => {
    if (error) {
      callback(0);
    } else {
      callback(response);
    }})
};

export const addLeave = (params, callback) => {
  Api.post("/attendance-setting/addLeave")
    .params(params)
    .send((response, error) => {
      if (error) {
        callback(0);
      } else {
        callback(response);
      }})
    
};
export const addHoliday = (params, callback) => {

  Api.post("/attendance-setting/addHoliday")

    .params(params)
    .send((response, error) => {
      if (error) {
        callback(0);
      } else {
        callback(response);
      }})
    
};

export const updateLeave = (params,id, callback) => {

Api.patch(`/attendance-setting/updateLeave?id=${id}`)

    .params(params)
    .send((response, error) => {
      if (error) {
        callback(0);
      } else {
        callback(response);
      }})
    

};

export const updateHoliday = (params,id, callback) => {
  Api.patch(`/attendance-setting/updateHoliday?holidayId=${id}`) 
      .params(params)
      .send((response, error) => {
        if (error) {
          callback(0);
        } else {
          callback(response);
        }})
  };

export const deleteLeave = (id, callback) => {

  Api.delete(`/attendance-setting/deleteLeave?id=${id}`)
  .send((response, error) => {
    if (error) {
      callback(0);
    } else {
      callback(response);
    }})
};

export const allHolidays = (url, callback) => {

  Api.get(url)
  .send((response) => {
    if (response) {
      const modifiedResponse = response.data.map((item) => {
        const { city, ...rest } = item;
        
        return {
          ...rest,
         
          locationId: city.id,
          cityName: city.cityName
        };
      });
      
      // Call the callback function with the modified response
      callback({ countFinal: response.countFinal, data: modifiedResponse });
      
    }
  });
};
export const allHolidaysDeptAssociate = (url, callback) => {

  Api.post(url)
  .send((response) => {
    if (response) {
      const modifiedResponse = response.data.map((item) => {
        const { departmentIds, ...rest } = item;
        
        return {
          ...rest,
          locationId: departmentIds,
          cityName: "departmentName"
        };
      });
      callback({ countFinal: response.countFinal, data: modifiedResponse });
    }
  });
};

export const downLoadData = (successCallBack,url,params=null) => {
  Api.get(url)
      .params(params)
      .send((response) => {
          if (response !== undefined) {
            window.open(response, "_blank")?.focus();
          }
      });
}

export const downLoadDataDeptAssct = (successCallBack,url) => {
  Api.post(url)
     .send((response) => {
          if (response !== undefined) {
            window.open(response, "_blank")?.focus();
          }
      });
}

export const postWorkingDays = (params,callback) => {
  Api.post(`/project-team/assignWeekDays`)
  .params(params)
  .send((response) => {
    
    if (response) {
      
      callback(response);
      
    }
  
    
  });
};

export const getTeamData = (param,callback) => {
  Api.post(`/project-projects/getAllTeamsForWeekDays`).params(param)
  .send((response) => {
    if (response) {
      callback(response);
    }
  });
};
export const getDBlogger = (param,callback) => {
  Api.post(`/attendance-auditlogs/getAllLogs`).params(param)
  .send((response) => {
    if (response) {
      callback(response);
    }
  });
};

export const getManagerWeekDays = (param,callback) => {
  Api.post(`/project-team/getManagersForweekdays`).params(param)
  .send((response) => {
    if (response) {
      callback(response);
    }
  });
};
export const postManagerWeekDays = (param,callback) => {
  Api.post(`/project-team/updateMnagerWeekdays`).params(param)
  .send((response) => {
    if (response) {
      callback(response);
    }
  });
};