import React, { useState } from "react";
import { Form,Select,DatePicker,Input , Divider} from "antd";
import classes from "./leave.module.css";
import ApplyLeaveIcon from "../../../assets/ApplyLeaveIcon.svg";
import { ModalPrimaryButton, ModalSecondaryButton } from "../../../components/Button/Button";
import useStore from "./store";
import Api from "../../../services/Api";
import moment from "moment";
import Modal from '../../../components/Modal/Modal';
const {Option}=Select;
const dateFormat="YYYY-MM-DD";
const ApplyLeaveModal=(props)=>{
const {openModal,applyLeaveModal}=useStore(state=>state);
const [startDate,setStartDate] = useState("");
const [enddate,setEndDate]=useState('')
const [loader,setLoader] = useState(false)
const onFinish=(values)=>{
    setLoader(true)
    console.log("sssValue",values,moment(values.startDate).format("YYYY-MM-DD"),
    moment.utc(values.endDate.toLocaleString()).format("YYYY-MM-DD"))
    let params={
        leaveType:values.leaveType,
        dayType:values.duration,
        startDate:moment(startDate).format("YYYY-MM-DD"),
        endDate:moment(enddate).format("YYYY-MM-DD"),
        reason:values.reason,
    }
    Api.post("/attendance-attendance/applyLeave")
       .params(params)
       .send((res)=>{
         if(res?.show?.type==="success"){
            setLoader(false)
            applyLeaveModal(false)
            props.refresh();
         }else{
            setLoader(false)
         }
       })
}
return(
    <Modal 
    show={openModal}

     closableRight={true} 
     style={{width:props?.width<700?"95%":"60vw"}}
     onCancel={()=>applyLeaveModal(false)}
    header={<div className={classes.modalHeader}> <h3>Apply Leave</h3></div>}
    >
     <div  >
        <Form layout="vertical" onFinish={onFinish}>
            <Form.Item label={<div>Leave Type</div>} name="leaveType" rules={[{required:true,message:"Please Select Leave Type!"}]}>
                <Select placeholder="Select Leave Type">
                    <Option value={"Sick"}>Sick Leave</Option>
                    <Option value={"Lop"}>LOP</Option>
                    <Option value={"Privilege"}>Privilege Leave</Option>
                    <Option value={"Casual"}>Casual Leave</Option>
                    <Option value={"Comp_off"}>Comp Off</Option>
                    <Option value={"Wedding"}>Wedding Leave</Option>
                    <Option value={"Parental"}>Maternity / Paternity</Option>
                </Select>
            </Form.Item>
            <Form.Item label={<div>Select Duration</div>} name="duration" rules={[{required:true,message:"Please Select Duration!"}]}>
                <Select placeholder="Select Duration">
                    <Option value={"FullDay"}>Full Day</Option>
                    <Option value={"HalfDay"}>Half Day</Option>
                </Select>
            </Form.Item>
            <Form.Item label={<div>Start Date</div>} name="startDate" rules={[{required:true,message:"Please Select Start Date!"}]}>
               
                <DatePicker popupStyle={{ position:"absolute", top:"50%"}}  size="small" className={classes.dateInput} format={dateFormat} onChange={(date,dateString)=>setStartDate(dateString)}/>
              
            </Form.Item>
            <Form.Item label={<div>End Date</div>} name="endDate" rules={[{required:true,message:"Please Enter End Date!"}]}>
                <DatePicker popupStyle={{ position:"absolute", top:"50%"}}  size="small" disabledDate={(current) => current && current < moment(startDate).startOf('day')} className={classes.dateInput} format={dateFormat} onChange={(date,dateString)=>setEndDate(dateString)}/>
            </Form.Item>
            <Form.Item  label={<div>Reason</div>} name="reason" rules={[{required:true,message:"Please Enter Reason!"}]}>
                <Input placeholder="Reason" type="text"/>
            </Form.Item>
            <Divider/>
            <div className={classes.buttonDesign}>
                <ModalPrimaryButton htmlType="submit" disabled={loader}>Save</ModalPrimaryButton>
                <ModalSecondaryButton onCancel={()=>applyLeaveModal(false)}>Cancel</ModalSecondaryButton>
            </div>
        </Form>
     </div>
    </Modal>
)
}
export default ApplyLeaveModal;