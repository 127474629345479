import React, { useState } from "react";
import classes from "./ApproveModal.module.css";
import ModalCompo from "../../design/ModalCompo";
import { useNotificationStore } from "./store";
import {  ModalSecondaryButton } from "../../components/Button/Button";
import approve_header_icon from '../../assets/approve_header_icon.svg';
import { manualPunchs, request } from "./action";
import { Button, Input } from "antd";
import Modal from "../../components/Modal/Modal";
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";

const ApproveAllModal=(props)=>{
    const {approveAllModal,approveAllModalHandler,id,user,userSel}=useNotificationStore();
    const {width}=useWindowDimensions();
    const [comment,setComment] = useState()
    const compArr = ['Sick request','Casual request','Privilege request','Wedding request','Parental request','Lop request','Comp_off Leave request','Sick Leave request','Casual Leave request','Privilege Leave request','Wedding Leave request','Parental Leave request','Lop Leave request']
    console.log(userSel,"-0987654321234567890-098765");
    console.log("jkenfjkb",user,id);

    const [loading, setloading] = useState(false)
    console.log("ssssLoad",props)
    return(
        <Modal
        closableRight={true}
        show={approveAllModal}
    
        header={<div className={classes.header}>
            <img src={approve_header_icon}/>
            <h3>Approve</h3>
        </div>}
       onCancel={()=>{
        approveAllModalHandler(false)   
       }}
       style={{width:width>700?"50vw":"98vw"}}
        >
           <div style={{padding:"1rem"}}>
            <span className={classes.message}>
                <span>{userSel.message?.length?userSel.message:""}</span>
                
                <span>Are you sure, you want to approve?</span>
                 </span>
                 {
                    compArr.includes(userSel?.title) &&
                    <>
                    {
                                                            userSel.info?.comments &&
                                                            <>

                                                            <div style={{ fontSize: '13px', marginTop: 10,fontWeight:600 }}>{userSel.info?.comments?.commentRejected1 ? `Approver 1 comment : ${userSel.info?.comments?.commentRejected1}` 
                                                            : userSel.info?.comments?.commentApproved1 ? `Approver 1 comment : ${userSel.info?.comments?.commentApproved1}` : null}</div>
                                                            <div style={{ fontSize: '13px', marginTop: 10,fontWeight:600 }}>{userSel.info?.comments?.commentRejected2 ? `Approver 2 comment : ${userSel.info?.comments?.commentRejected2}` 
                                                            : userSel.info?.comments?.commentApproved2 ? `Approver 2 comment : ${userSel.info?.comments?.commentApproved2}` : null}</div>
                                                        </>
                                                        }
                        <div style={{ flex: 1, fontSize: 13 }}>Comment</div>
                        <Input style={{ flex: 1, marginTop: 5 }} onChange={(e) => { setComment(e.target.value) }} placeholder="add comment"></Input>
                    </>
                }
            <div className={classes.buttonDiv}>
                <Button 
                style={{color:'white',background:'#0086FF',height:"2.4rem",width:"5rem"}}
                loading={loading}
                onClick={()=>{
                    setloading(true)
                    const manualPunch = {
                        old:userSel?.old,
                        notificationId: userSel?.notificationId,
                        status: 'Accepted',
                        comment:comment
                    }
                    manualPunchs(manualPunch, (res) => {
                        // request('Pending','','', (res) => {
                        //     setloading(false)
                        //     approveModalHandler(false)   
                        //     setgetRequest(res)
                        // },user?.id)
                        if(res?.show?.type === "success"){
                            setloading(false);
                            approveAllModalHandler(false)   
                            props.refresh();
                        }
                        else{
                            setloading(false)
                        }
                    }
                    )
                }}>Yes</Button>
                <ModalSecondaryButton onCancel={()=>{
                    approveAllModalHandler(false)
                }}>No</ModalSecondaryButton>
            </div>
           </div>
        </Modal>
    )
}
export default ApproveAllModal;