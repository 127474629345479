import { AiFillCalendar } from "react-icons/ai";
import React from "react";
import { CiLocationOn } from "react-icons/ci";
import { create } from "zustand";
import { applyLeaveModal, applyLeaveModalApi, getLogsDetails } from "../action/action";
import moment from "moment";
import UserIcon from "../../../assets/UserIcon.svg";
import { Typography } from "antd";
import LogMapIcon from "../../../assets/LogMapIcon.svg";
import LogCalenderIcon from "../../../assets/LogCalenderIcon.svg";

export const useAttendnaceStore = create(set => ({
    year: moment().format("YYYY"),
    logsData: [],
    logsTableData: [],
    logFilters: { department: [], manager: [] },
    render:1,
    selectedUser:{},
    applyLeaveModal:false,
    loader:false,
    attendanceLogsData:{},
    openGeofence:false,
    updateModal:false,
    manualPunchModal:false,
    open:false, 
    userCalendarDrop:[],
    selectAssociate:"",
    applyLeaveData:[],
    tableMetaData:{},
    userId:"",
    uiSelectId: null,
    filterModal:false,
    filterModalLogs:false,
    tabs:"Active Users", 
    value:1, 
    Data:null, 
    selectData:null,
    search:"",
    date:`${moment().format("MM").slice(0, 3)},${moment().format("YYYY")}`,
    startDate:null,
    endDate:null,
    currentPage:1, 
    pageSize:10,
    filteredDeptValue:null,
    filteredManagerValue:null,
    sortingEmployee:'ASC',
    isApplyFiltersModal:false, 
    btnLoader:false, 
    recordId:null,
    recordName:null,
    selectedRowId:null,
    associateId:null,
    associateIdInLog:null,
    setAssociateIdInLog:(e)=>{
      set(state=>({associateIdInLog:e}))},
    mapCenter:{ lat: 12.9002874, lng: 77.650783 },
    setAssociateId:(e)=>{set(state=>({associateId:e}))},
    setMapCenter:(e)=>{set(state=>({mapCenter:e}))},
    setSelectedRowId:(e)=>{set(state=>({selectedRowId:e}))},
    setRecordName:(e)=>{set(state=>({recordName:e}))},
    setRecordId:(e)=>{set(state=>({recordId:e}))},
    setBtnLoader:(e)=>{set(state=>({btnLoader:e}))},
    applyFiltersModalHandler:(e)=>{set(state=>({isApplyFiltersModal:e}))},
    setSortingEmployee:(e)=>{set(state=>({sortingEmployee:e}))},
    setFilteredManagerValue:(e)=>{set(state=>({filteredManagerValue:e}))},
    setFilteredDeptValue:(e)=>{set(state=>({filteredDeptValue:e}))},
    setPageSize:(e)=>{set(state=>({pageSize:e}))},
    setCurrentPage:(e)=>{set(state=>({currentPage:e}))},
    setDate:(e)=>{set(state=>({date:e}))},
    setStartDate:(e)=>{set(state=>({startDate:e}))},
    setEndDate:(e)=>{set(state=>({endDate:e}))},
    setSearch:(e)=>{set(state=>({search:e}))},
    setSelectData:(e)=>{set(state=>({selectData:e}))},
    setData:(e)=>{set(state=>({Data:e}))},
    setUiSelectId:(e)=>{set(state=>({uiSelectId:e}))},
    setValue:(e)=>{set(state=>({value:e}))},
    setTabs:(e)=>{set(state=>({tabs:e}))},
    monthsVal:`${moment().format("MM").slice(0, 3)},${moment().format("YYYY")}`,
    monthsValSub:`${moment().format("MMM")}, ${moment().year()}`,
    setApplyLeaveData :(e)=>{set(state=>({applyLeaveData:e}))},
    setOpen:(event)=>{set(state=>({open:event}))},
    setFilterModal:(event)=>{set(state=>({filterModal:event}))},
    setFilterModalLogs:(event)=>{set(state=>({filterModalLogs:event}))},
    setUserId:(e)=>{set(state=>({userId:e}))},
    setMonthsVal:(e)=>{set(state=>({monthsVal:e}))},
    setMonthsValSub:(e)=>{set(state=>({monthsValSub:e}))},
    setTableMetaData:(e)=>{set(state=>({tableMetaData:e}))},
    setSelectAssociate:(e)=>{set(state=>({selectAssociate:e}))},
    setUserCalendarDrop:(e)=>{set(state=>({userCalendarDrop:e}))},
    setSelectedUser:(event)=>{set(state=>({selectedUser:event}))},
    setManualPunchModal:(event)=>{set(state=>({manualPunchModal:event}))},
    setUpdateModal:(event)=>{set(state=>({updateModal:event}))},
    setOpenGeofence:(val)=>{set(state=>({openGeofence:val}))},
    setAttendanceLogsData: (res)=>{ set(state=>({attendanceLogsData:res}))},
    setLoader:(bool)=>{set(state=>({loader:bool}))},
    setApplyLeaveModal:(event)=>{set(state=>({applyLeaveModal:event}))},
    setRender:(val)=>{set(state=>({render:val}))},
    setLogData: (val) => {
        set(state => ({ logsData: val }))
        set(state => ({ logsTableData: [] }))
        let temp = [...val?.data?.logs];
        let width = window.innerWidth
        const uniqueDepartments = new Set(temp.map(element => element?.department));
        const uniqueManagers = new Set(temp.map(element => element?.manager));
        set(state => ({
            logFilters: {
              ...state.logFilters,
              department: [...uniqueDepartments].map(dep => ({ text: dep, value: dep })),
              manager: [...uniqueManagers].map(mgr => ({ text: mgr, value: mgr }))
            }
          }));
        temp.forEach((element, index) => {
            // console.log("eleName",element)
            let objTD = {
                "Contact Details":(<div style={{display:"flex",flexDirection:"column"}}> <Typography.Text style={{width:"11.2rem",fontSize:"1.03vw"}} ellipsis={{tooltip:true}}><span>{element?.email ? element?.email :"NA"}</span></Typography.Text>
                  <div>{element?.phone ? element?.phone :"NA"}</div></div>),
                "Phone": <Typography.Text style={{width:"15rem",fontSize:width<700?18:15}} ellipsis={{tooltip:true}}><span>{element?.phone ? element?.phone :"NA"}</span></Typography.Text>,
                "Email": <Typography.Text style={{width:"15rem",fontSize:width<700?18:15}} ellipsis={{tooltip:true}}><span>{element?.email ? element?.email :"NA"}</span></Typography.Text>,
                "Associate Name": (<div style={{display:"flex",gap:"0.3rem",flexDirection:"row",alignItems:"center"}}>
                  {/* <div > */}
                    {<img src={UserIcon} style={{width:width>700?"2.5vw":"3.5rem",height:width>700?"6vh":"3.5rem",borderRadius:"50%",border:"1px dotted #e3e3e3"}} />}
                {/* </div> */}
                <div style={{display:"flex",flexDirection:"column"}}>
                  <Typography.Text style={{width:width>700?"11.5rem":"9.5rem",fontSize:width<700?18:15}} ellipsis={{tooltip:true}}>{element?.name ? element?.name :"NA"}</Typography.Text>
                  <Typography.Text style={{width:"11.5rem",fontSize:15}} ellipsis={{tooltip:true}}><span>{element?.email ? element?.email :"NA"}</span></Typography.Text>
                  <div>{element?.phone ? element?.phone :"NA"}</div>
                 </div>
                 </div>),
                "Employee Id":<Typography.Text style={{width:width>700?"8vw":"8rem",fontSize:width<700?18:15,}} ellipsis={{tooltip:true}}><span>{element?.employeeId ? element?.employeeId :"NA"}</span></Typography.Text>,
                "Department": <Typography.Text style={{width:width > 700?"9rem":"15rem",fontSize:width<700?18:15}} ellipsis={{tooltip:true}}><span>{element?.department ? element?.department :"NA"}</span></Typography.Text>,
                "Manager": <Typography.Text style={{width:width > 700?"8.5rem":"15rem",fontSize:width<700?18:15}} ellipsis={{tooltip:true}}><span>{element?.manager ? element?.manager :"NA"}</span></Typography.Text>,
                "Working Hour":width>700? element?.workingHours ? element?.workingHours :"0.00":<div style={{fontSize:18}}>{element?.workingHours ? element?.workingHours :"0.00"}</div>,
                "W D": element?.workingDays ? element?.workingDays : 0,
                "P": element?.present ? element?.present : 0,
                "H": element?.holiday ? element?.holiday : 0,
                "O H": element?.optional ? element?.optional : 0,
                "A":element?.absent ? element?.absent : 0,
                "L": element?.leave ? element?.leave : 0,
                "L": element?.leave ? element?.leave : 0,
                "AL": element?.advanced ? element?.advanced : 0,
                "employeeId": element?.employeeId ? element?.employeeId : null,
                "UserId" : element?.userId ? element?.userId : 0,
                "Element" : element ? element : "",
                "Actions": (
                <div className='r-jsb' style={{display:"flex",flexDirection:"row",gap:"0.6rem"}}>
                     <img src={LogMapIcon}
                    onClick={()=>{
                        set(state=>({selectedUser:element}))
                        set(state=>({openGeofence:true}))}}
                    style={{ height:width>700?"1.8rem":"2.3rem",width:width>700?"1.8rem":"2.3rem",border: "0px solid black", color: "#39CA6C", cursor: "pointer" }} />
                    <img src={LogCalenderIcon} onClick={()=>{
                       set(state=>({userId:element?.userId}))
                       set(state=>({loader:true}))
                        getLogsDetails((res)=>{
                            set(state=>({loader:false}))
                            set(state=>({selectAssociate:"",}))
                            set(state=>({attendanceLogsData:res}))
                        },{userId:element?.userId,month:moment().format('M'),year:moment().format("YYYY")})
                        set(state=>({render:2,selectedUser:element}));
                        applyLeaveModalApi(
                            {
                              userId:element?.userId,
                            },
                            (res) => {
                              set(state=>({applyLeaveData:res}));
                            }
                          );
                        }} style={{ height:width>700?"1.8rem":"2.3rem",backgroundColor:"white", width:width>700?"1.8rem":"2.3rem",borderRadius:"50%",border: "0px solid black", color: "#0086FF", cursor: "pointer" }} />
                </div>)
            }
            set(state => ({ logsTableData: [...state?.logsTableData, objTD] }))
        });

    },


}))