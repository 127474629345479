import { create } from "zustand";
export const useReleaseStore=create(set=>({
    department:[],
    releasableMembers:[],
    loader:false,
    releaseModal:false,
    departmentHandler:(val)=>set(state=>({department:val})),
    releasableMembersHandler:(val)=>set(state=>({releasableMembers:val})),
    loaderHandler:(val)=>set(state=>({loader:val})),
    releaseModalHandler:(val)=>set(state=>({releaseModal:val})),
}))


export const useStore = create((set) => ({
  userReleaseId: null,
  userReleaseDepId: null,
  userReleaseDep:null,
  finalCount: -1,
  setUserReleaseId: (id) => set({ userReleaseId: id }),
  setUserReleaseDepId: (id) => set({ userReleaseDepId: id }),
  setUserReleaseDep:(id)=>set({userReleaseDep: id}),
  setFinalCount: (id) => set({ finalCount: id }),
  getUserReleaseId: () => set((state) => state.userReleaseId),
  getUserReleaseDepId: () => set((state) => state.userReleaseDepId),
   
}));


export const useTaskReleaseStore = create((set) => ({
  userTaskReleaseId: null,
  userTaskReleaseDepId: null,
  userTaskReleaseDep:null,
  finalTaskCount: -1,
  setUserTaskReleaseId: (id) => set({ userTaskReleaseId: id }),
  setUserTaskReleaseDepId: (id) => set({ userTaskReleaseDepId: id }),
  setUserTaskReleaseDep:(id)=>set({userTaskReleaseDep: id}),
  setFinalTaskCount: (id) => set({ finalTaskCount: id }),
  getUserReleaseId: () => set((state) => state.userReleaseId),
  getUserReleaseDepId: () => set((state) => state.userReleaseDepId),
   
}));


export const useFlagButtonStore = create((set) => ({
  flagButton: false, // to show buttons on the main page
  checkedIdsList: null, 
  checkedUserIdsList: null,
  cancelFlag: false, // to communicate between cancel button and checked list
  allTask:null, // to set all tasks when get user tasks or all tasks api is called
  tasksUserId: null, //redirecting from teams to tasks userId
  filtersList :null, // to send filters of project, team, status
  allProjectNames:null,//project filter dropdown
  allTeamNames:null, //team filter dropdown
  tasksUserName: null, //redirectign from teams to tasks userName
  overallStatus: "Active", // overall status filter 
  enableTransfer:false, //checking enable transfer
  tasksTeamId:null, // redirecting from teams to tasks teamId
  pageMeta:null, //pagenation page
  selectedTeamFilters:null, 
  selectedProjectFilters:null,
  tasksTeamName:null, // redirecting from teams to tasks teamName
  allAssociateNames: null,
  selectedAssociateFilters: null,
  allSearch:null,
  releaseDepId:null,
  projectsCount:null,
  tasksCount: null,



  setFlagButton: (id) => set({ flagButton: id }),
  setCheckedIdsList: (id)=> set({checkedIdsList: id}),
  setCheckedUserIdsList : (id) => set({checkedUserIdsList: id}),
  setCancelFlag: (id)=>set({cancelFlag: id}),
  setAllTask: (id)=>set({allTask: id}),
  setAllProjectNames: (id)=>set({allProjectNames: id}),
  setTasksUserId: (id)=>set({tasksUserId: id}),
  setTasksUserName: (id)=>set({tasksUserName: id}),
  setFiltersList: (id)=> set({filtersList:id}),
  setAllTeamNames: (id)=>set({allTeamNames: id}),
  setOverallStatus: (id)=>set({overallStatus: id}),
  setEnableTransfer: (id)=>set({enableTransfer: id}),
  setTasksTeamId: (id)=>set({tasksTeamId: id}),
  setPageMeta: (id)=>set({pageMeta:id}),
  setSelectedTeamFilters:(id)=>set({selectedTeamFilters: id}),
  setSelectedProjectFilters:(id)=>set({selectedProjectFilters:id}),
  setTasksTeamName:(id)=>set({tasksTeamName:id}),
  setAllAssociateNames: (id)=> set({allAssociateNames:id}),
  setSelectedAssociateFilters: (id)=> set({selectedAssociateFilters:id}),
  setAllSearch: (id)=> set({allSearch:id}),
  setReleaseDepId: (id)=> set({releaseDepId: id}),
  setProjectsCount: (id)=> set({projectsCount:id}),
  setTasksCount:(id)=> set({tasksCount:id}),

  resetFilters : ()=>set({
    flagButton: false, // to show buttons on the main page
    checkedIdsList: null, 
    checkedUserIdsList: null,
    cancelFlag: false, // to communicate between cancel button and checked list
    allTask:null, // to set all tasks when get user tasks or all tasks api is called
    tasksUserId: null, //redirecting from teams to tasks userId
    filtersList :null, // to send filters of project, team, status
    allProjectNames:null,//project filter dropdown
    allTeamNames:null, //team filter dropdown
    tasksUserName: null, //redirectign from teams to tasks userName
    overallStatus: "Active", // overall status filter 
    enableTransfer:false, //checking enable transfer
    tasksTeamId:null, // redirecting from teams to tasks teamId
    pageMeta:null, //pagenation page
    selectedTeamFilters:null, 
    selectedProjectFilters:null,
    tasksTeamName:null, // redirecting from teams to tasks teamName
    allAssociateNames: null,
    selectedAssociateFilters: null,
    allSearch:null,
    releaseDepId:null,
    //projectsCount:null,

  })
   
}));


export const useManageTasks = create((set) => ({
  manageTasks: false,
  setManageTasks: (id)=> set({manageTasks:id}),
 
  
   
}));

