import ModalCompo from "../../../design/ModalCompo";
import userIcon from "../../../assets/LeaveUserIcon.svg";
import { Button, Form, Input, Modal, Select, Switch } from "antd";
import classes from "./user.module.css";
import { useState, useEffect, useForm, useRef } from "react";
import DatePickerCompo from "../../../components/DatePicker/DatePickerCompo.tsx";
import {
  ModalPrimaryButton,
  ModalSecondaryButton,
} from "../../../components/Button/Button";
import useAUserStore from "./store";
import moment from "moment";
import { locationAccessApi, tempLocationAccessApi } from "./userAction";
import viewClass from "../../../services/ViewRole";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import Views from "../../../services/ViewRole";

function EditUser({ modal, setModal, refresh }) {
  const { width } = useWindowDimensions();
  const tab = width > 700 ? ["Location Access", "Temporary Location Access"] : ["Location", "Temporary Location"];
  const [tabSelected, setTabSelected] = useState(0);
  const [locationList] = useAUserStore(
    (state) => [
      state.locationList,
      state.userList,
      state.setUserList,
      state.setLocationList,
    ]
  );
  console.log(locationList, "klklkkikllok", modal);
  const [OfficeLocation, setOfficeLocation] = useState();
  const [OfficeLocationT, setOfficeLocationT] = useState();

  const [checked, setChecked] = useState();
  const [checkedP, setCheckedP] = useState();

  const [reset, setReset] = useState(false);
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();

  const saveform1 = useRef(null)
  const saveform2 = useRef(null)
  const [save1, setsave1] = useState(false)
  const [save2, setsave2] = useState(false)
  const [tempLocSwitch, setSwitch] = useState(false)
  const [ischanged, setIsChanged] = useState(true)
  const [isFormEdited, setIsFormEdited] = useState({
    F1: false,
    F2: false,
    F3: false
  })


  const formRef1 = useRef(null);
  const formRef2 = useRef(null);
  const header = (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row", width: "100%",
        gap: "0.3rem",
        // color:"#0086FF",
        // marginLeft:width>700&&"-3.9rem"
      }}
    >
      {/* <img src={userIcon} style={{ height: "20px", width: "20px" }}></img> */}
      <h2 style={{ fontSize: "20px" }}>Edit User</h2>
    </div>
  );
  useEffect(() => {
    setSwitch(modal?.user?.tempLocEnabled)

    console.log(locationList, "locationListlocationList");
    let off = locationList?.filter(
      (val) => val?.id === modal?.user?.city?.id
    );
    console.log("goood", off)
    setOfficeLocation(off[0]?.geoFenceSetting);

    let offt = locationList?.filter(
      (val) => val?.id === modal?.user?.tempCity?.id
    );
    //    console.log("goood",off)
    setOfficeLocationT(offt[0]?.geoFenceSetting);


    //    console.log("goood",off)
  }, [locationList]);


  useEffect(() => {
console.log(save2,"triggeref94",isFormEdited, saveform1.current, saveform2.current );
    if (tempLocSwitch) {
      if (saveform1.current !== null && saveform2.current !== null) {
        if (isFormEdited?.F1 && isFormEdited?.F2) {
          console.log("trig98");
          locationAccessApi({ tempLocEnabled: tempLocSwitch, disableTempLocId: modal?.id, tempPayload: saveform2.current, permPayload: saveform1.current }, (res) => {
            if (res) {
              setModal();
              refresh();
            }
          });
        } else if (isFormEdited?.F1 && (!isFormEdited?.F2)) {
          console.log("trig106");
          locationAccessApi({ tempLocEnabled: tempLocSwitch, disableTempLocId: modal?.id, tempPayload: null, permPayload: saveform1.current }, (res) => {
            if (res) {
              setModal();
              refresh();
            }
          });
        } else if ((!isFormEdited?.F1) && isFormEdited?.F2) {
          console.log("trig114");
          locationAccessApi({ tempLocEnabled: tempLocSwitch, disableTempLocId: modal?.id, tempPayload: saveform2.current, permPayload: null }, (res) => {
            if (res) {
              setModal();
              refresh();
            }
          });
        }

      }
    } else {
      if ((saveform1.current !== null)) {
        if (isFormEdited?.F1 && isFormEdited?.F2) {
          console.log("trig127");
          locationAccessApi({ tempLocEnabled: tempLocSwitch, disableTempLocId: modal?.id, tempPayload: saveform2.current, permPayload: saveform1.current }, (res) => {
            if (res) {
              setModal();
              refresh();
            }
          });
        } else if (isFormEdited?.F1 && (!isFormEdited?.F2)) {
          console.log("trig135");
          locationAccessApi({ tempLocEnabled: tempLocSwitch, disableTempLocId: modal?.id, tempPayload: null, permPayload: saveform1.current }, (res) => {
            if (res) {
              setModal();
              refresh();
            }
          });
        } else if ((!isFormEdited?.F1) && isFormEdited?.F2) {
          console.log("trig143");
          locationAccessApi({ tempLocEnabled: tempLocSwitch, disableTempLocId: modal?.id, tempPayload: saveform2.current, permPayload: null }, (res) => {
            if (res) {
              setModal();
              refresh();
            }
          });
        }
      }
    }
    setsave1(false)
    setsave2(false)
  }, [save1 === true, save2 === true])


  const LocationHandler = (val) => {
    console.log("ssssValueOnfinish", val,isFormEdited?.F3)
    let offIds = [];
    let param = {
      userViewIds: [modal?.id],
      cityId: val?.city,
      date: moment(val?.date).format("YYYY-MM-DD"),
    };

    if (checked) {
      OfficeLocation?.forEach((val) => {
        offIds.push(val?.id);
      });
    }
    if(isFormEdited?.F3){
      param = {
        ...param,
        webAccess_startDate:isFormEdited?.F3? moment(val?.dateWebaccess).format("YYYY-MM-DD"):null,
        webAccessDuration:isFormEdited?.F3?  val?.durationPerm: null
      }
    }
    if (val?.webAccess !== undefined) {
      param = { ...param, webAccess: val?.webAccess };
    }
    // console.log("kkkkkkkkkkkkkkkkkkkk",val?.office!==undefined,offIds?.length===0)
    if (val?.office !== undefined && offIds?.length === 0) {
      // console.log("lllll");
      param = { ...param, placeIds: val?.office };
    } else if (offIds?.length !== 0) {
      param = { ...param, placeIds: offIds };
    }
    // console.log("params", param);
    // console.log("params val", val);
    saveform1.current = param;
    setsave2(true)
    /*  locationAccessApi(param, (res) => {
       if (res) {
         setModal();
         refresh();
       }
     }); */
    // console.log("location handler",val,offIds)
  };
  const officeIdsT = modal?.user?.tempOffices.map(office => office.id)
  /*   useEffect(()=>{
      setTimeout(() => {
        setSwitch(false)
      }, 1000);
    },[]) */

  useEffect(()=>{
    setIsFormEdited({ ...isFormEdited, F3: modal?.user?.webAccess })
  },[])
  const TempLocationHandler = (val) => {
    console.log(val, "tempcheckval106");
    let offIds = [];
    let param = {
      duration: val?.duration,
      userViewIds: [modal?.id],
      cityId: val?.tempCity,
      startDate: moment(val?.startDate).format("YYYY-MM-DD"),
    };
    if (checkedP) {
      OfficeLocationT?.forEach((val) => {
        offIds.push(val?.id);
      });
    }
    // console.log("llllllll",val?.tempOffice!==undefined,offIds?.length===0,offIds)
    if (val?.tempOffice !== undefined && offIds?.length === 0) {
      param = { ...param, placeIds: val?.tempOffice };
    } else if (offIds?.length !== 0) {
      console.log("kkkkk");
      param = { ...param, placeIds: offIds };
    }
    // console.log("paraam", param);
    saveform2.current = param;
    setsave1(true)
    /*  tempLocationAccessApi(param, (res) => {
       if (res) {
         setModal();
         refresh();
       }
     });
     setModal()*/
  };

  useEffect(() => {
    Views.updateView();
  }, [])

  const oldValues = {

    startDate: modal?.user?.tempOffice_startDate !== null ? moment(modal?.user?.tempOffice_startDate, "YYYY-MM-DD") : moment(),
    tempCity: modal?.user?.tempCity?.id,
    tempOffice: officeIdsT,
    // duration:
    //   1 +
    //   moment(modal?.user?.tempOffice_endDate).diff(
    //     moment(modal?.user?.tempOffice_startDate),
    //     "days"
    //   ),
    duration: modal?.duration,
  }

  // console.log("foooo",oldValues);
  const newInitialValues = {
    startDate: moment(),
    tempCity: null,
    tempOffice: null,
    duration: null,
  }
  //  console.log("foooofff",reset);

  // useEffect(() => {
  //   // form.setFieldsValue({
  //   //   office: undefined, // Set to undefined to clear the selected value
  //   // });
  // }, [form, form.getFieldValue("tempCity")]);

  const officeIds = modal?.user?.currentOffices.map(office => office.id)



  const handleButtonClick = () => {

    console.log(tempLocSwitch, "tempLocSwitchtempLocSwitch");
    // formRef2.current.submit();
    // formRef1.current.submit();
    if (tempLocSwitch) {
      try {
        formRef2.current.submit();
      } catch (error) {
        console.error('Error submitting form:', error);
      }
      try {
        formRef1.current.submit();
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    } else {
      try {
        formRef1.current.submit();
      } catch (error) {
        console.log("triigered296");
        console.error('Error submitting form:', error);
      }
    }
  };

  return (
    <Modal
      open={modal !== undefined} centered footer={false} width={width > 700 ? "900px" : "90vw"}
      onCancel={() => {
        setModal();
      }}
      title={header}
    // style={{fontSize:width<700&&"19px"}}
    // closableRight={true}
    // contentStyle={{ width:width>700&&"900px", height: "80vh",padding:"13px",top:"10vh",borderRadius:"8px",margin:width<700&&15 }}
    >
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "100%", gap: "32px", paddingLeft: "10px" }}>
        <div className={classes.tabContainer}>
          {/* {tab?.map((val, id) => {
            return (
              <div
                onClick={() => {
                  setChecked();
                  setOfficeLocation();
                  setTabSelected(id);
                }}
                className={id === tabSelected ? classes.activeTab : ""}
              >
                {val}
              </div>
            );
          })} */}
        </div>
        {/* fields={[{name:'date', value:moment()},{name:'city',value:modal?.user?.city?.id},]} */}

        <>
          <Form
            key={1}
            ref={formRef1}
            onFinish={LocationHandler}
            form={form1}
            initialValues={{
              durationPerm: modal?.user?.webAccessDuration ? modal?.user?.webAccessDuration : 7,
              dateWebaccess: modal?.user?.webAccess_startDate? moment(modal?.user?.webAccess_startDate, "YYYY-MM-DD") : moment(),
              webAccess: modal?.user?.webAccess,
              date: modal?.user?.permanentOffice_startDate !== null && modal?.user?.permanentOffice_startDate !== undefined ?
                moment(modal?.user?.permanentOffice_startDate, "YYYY-MM-DD") : moment(),
              city: modal?.user?.city?.id,
              office: officeIds,
            }}
            layout="vertical"
            className={classes.editForm}
            style={{ width: "100%" }}
            onValuesChange={(changedValues, allValues) => {
              setIsChanged(false)
              setIsFormEdited({ ...isFormEdited, F1: true, F3:allValues?.webAccess })
              
              if ('city' in changedValues) {
                // form.resetFields(['office']);
                form1.setFieldsValue({
                  office: undefined,
                });
              }
            }}
          >
            <div >
              <Form.Item
                label={<div style={{ fontSize: width < 700 && "18px" }}>Name</div>}
                rules={[
                  {
                    required: true,
                    message: "please enter name",
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  style={{ color: "#8b9299", fontSize: width < 700 && "16px" }}
                  disabled
                  defaultValue={modal?.name}
                  placeholder="type name"
                />
              </Form.Item>
              <Form.Item
                label={<div style={{ fontSize: width < 700 && "18px" }}>Emp ID</div>}
                rules={[{ required: true, message: "please enter emp id" }]}
              >
                <Input
                  style={{ color: "#8b9299", fontSize: width < 700 && "16px" }}
                  defaultValue={modal?.uid}
                  disabled
                  placeholder="type employee id"
                  type="number"
                />
              </Form.Item>

              <Form.Item
                label={<div style={{ fontSize: width < 700 && "18px" }}>Email</div>}
                rules={[{ required: true, message: "please enter email" }]}
              >
                <Input
                  style={{ color: "#8b9299", fontSize: width < 700 && "16px" }}
                  defaultValue={modal?.email}
                  disabled
                  placeholder="type mail"
                  type="email"
                />
              </Form.Item>
              <Form.Item name="webAccess" label={<div style={{ fontSize: width < 700 && "18px" }}>Web Access</div>}>
                <Select style={{ fontSize: width < 700 && "16px" }}
                  placeholder="select web access"
                // defaultValue={modal?.user?.webAccess}
                // value={modal?.user?.webAccess}
                >

                  {[{ value: true, key: "Yes" }, { value: false, key: "No" }]?.map((val, id) => {
                    return (
                      <Select.Option value={val?.value} key={id} style={{ fontSize: width < 700 && "16px" }}>
                        {val?.key}
                      </Select.Option>
                    );
                  })}

                  {/*  <Select.Option style={{ fontSize: width < 700 && "16px" }} value={true}>Yes</Select.Option>
                  <Select.Option style={{ fontSize: width < 700 && "16px" }} value={false}>No</Select.Option> */}
                </Select>
              </Form.Item>
              {
                isFormEdited?.F3 &&
              <>
              <Form.Item
                    name={"durationPerm"}
                    label={<div style={{ fontSize: width < 700 && "18px" }}>Web Access Duration</div>}
                    /*  rules={(fields)=>{console.log(fields,"fieldsssss");
                      return[{ required: true, message: "please select duration" }]}} */
                    rules={[{ required: true, message: "please select duration" }]}
                  >
                    <Select placeholder="Please select duration" style={{ fontSize: width < 700 && "16px" }}>
                      <Select.Option value={7} key={3} style={{ fontSize: width < 700 && "16px" }}>
                        7 Days
                      </Select.Option>
                      <Select.Option value={14} key={4} style={{ fontSize: width < 700 && "16px" }}>
                        14 Days
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                name="dateWebaccess"
                label={<div style={{ fontSize: width < 700 && "18px" }}>Web Access  Startdate</div>}
                rules={[{ required: true, message: "please enter date" }]}
              >
                <DatePickerCompo disabledDate={(current)=>{
                   return current && current < moment(modal?.joined) || current && current > moment().endOf('day');
                }}  style={{ width: "100%", fontSize: width < 700 && "16px" }} />
              </Form.Item>
              </>
              }
              <div style={{ color: "#5E5E5E", fontSize: 14, fontWeight: 600, paddingBottom: 10 }}>PERMANENT LOCATION</div>
              <Form.Item
                name="city"
                label={<div style={{ fontSize: width < 700 && "18px" }}>Work City Location</div>}
                rules={[
                  {
                    required: true,
                    message: "please select work city location",
                  },
                ]}
              >
                <Select style={{ fontSize: width < 700 && "16px" }}
                  placeholder="select work city location"
                  onChange={(val) => {
                    locationList?.forEach((item) => {
                      if (item?.id === val) {
                        setOfficeLocation(item?.geoFenceSetting);
                        // form.resetFields(['office']);
                      }
                    });
                  }}
                >
                  {locationList?.map((val, id) => {
                    return (
                      <Select.Option value={val?.id} key={id} style={{ fontSize: width < 700 && "16px" }}>
                        {val?.cityName}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name="date"
                label={<div style={{ fontSize: width < 700 && "18px" }}>Date</div>}
                rules={[{ required: true, message: "please enter date" }]}
              >
                <DatePickerCompo disabledDate={(current) => {
                  return current && current < moment(modal?.joined);
                }} style={{ width: "100%", fontSize: width < 700 && "16px" }} />
              </Form.Item>
              <Form.Item
                name="office"
                label={<div>
                  <div style={{ fontSize: width < 700 && "18px" }}>Work Office Place</div>{" "}
                  <div>
                    <input
                      name="checkBox"
                      onChange={(val) => {
                        setChecked(val?.target?.checked);
                        setIsChanged(false)
                      }}
                      type="checkbox"
                    />{" "}
                    <label style={{ fontSize: width < 700 && "16px" }}>All</label>
                  </div>{" "}
                </div>}
                rules={[
                  {
                    required: !checked,
                    message: "please enter office location",
                  },
                ]}
              >
                <Select
                  disabled={checked}
                  placeholder="select work location"
                  allowClear
                  mode="multiple" style={{ fontSize: width < 700 && "16px" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {console.log(OfficeLocation, "OfficeLocationOfficeLocation")}
                  {OfficeLocation?.map((val, id) => {
                    return (
                      <Select.Option value={val?.id} key={id} style={{ fontSize: width < 700 && "16px" }}>
                        {val?.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>

            </div>
            {/* <div className={classes.buttonDiv} style={{marginTop:"1rem"}}>
                <Button style={{backgroundColor:"white",border:"1px solid #0086FF",color:"#0086FF",height:"2.1rem",width:"6rem",borderRadius:"7px"}}
                  onClick={() => {
                    setModal();
                  }}
                >
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" style={{height:"2.1rem",width: "6rem",borderRadius:"7px"}}
                disabled={ Views?.hrAssociate && !Views?.hrAdmin  ? true : false}
                >Save</Button>
              </div> */}
          </Form>
        </>
        <div style={{ display: "flex", justifyContent: "space-between", padding: 10 }}>
          <div>
            <div style={{ color: "#5E5E5E", fontSize: 14, fontWeight: 600 }}>TEMPORARY LOCATION</div>
            <div style={{ color: "#5E5E5E", fontSize: 12, fontWeight: 400, paddingBottom: 10 }}>Enable a temporary location for enhanced flexibility.</div>
          </div>

          <Switch checked={tempLocSwitch} onChange={(e) => {
            setSwitch(e)
            setIsChanged(false)
            setIsFormEdited({ ...isFormEdited, F2: true })
          }} />
        </div>
        <Form
          key={2}
          ref={formRef2}
          form={form2}
          layout="vertical"
          // fields={[
          //   { name: "startDate", value: modal?.user?.tempOffice_startDate !== null ? moment(modal?.user?.tempOffice_startDate, "YYYY-MM-DD") : moment() },
          //   { name: "tempCity", value: modal?.user?.tempCity?.id },
          //   { name: "tempOffice", value: modal?.user?.tempOfficeIds },
          //   { name: "duration", value: modal?.duration },
          // ]}
          initialValues={oldValues}
          className={classes.editForm}
          onFinish={TempLocationHandler}
          style={{ width: "100%", display: tempLocSwitch ? "" : "none" }}
          onValuesChange={(changedValues, allValues) => {
            setIsFormEdited({ ...isFormEdited, F2: true })
            setIsChanged(false)
            console.log("sssChangeValuetemp", changedValues)
            if ('tempCity' in changedValues) {
              // form.resetFields(['office']);
              form2.setFieldsValue({
                tempOffice: undefined,
              });
            }
          }}
        >
          {

            <div >
              <Form.Item
                // name="tempCity"
                name={"tempCity"}
                label={<div style={{ fontSize: width < 700 && "18px" }}>Work City Location</div>}
                rules={[
                  {
                    required: true,
                    message: "please select work city location",
                  },
                ]}
              >
                <Select style={{ fontSize: width < 700 && "16px" }}
                  placeholder="select work city location"
                  onChange={(val) => {
                    locationList?.forEach((item) => {
                      if (item?.id === val) {
                        setOfficeLocationT(item?.geoFenceSetting);
                      }
                    });
                  }}
                >
                  {locationList?.map((val, id) => {
                    return (
                      <Select.Option value={val?.id} key={id} style={{ fontSize: width < 700 && "16px" }}>
                        {val?.cityName}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name={"tempOffice"}
                label={<div>
                  <div style={{ fontSize: width < 700 && "18px" }}>Temporary Work Office Place</div>{" "}
                  <div>
                    <input
                      onChange={(val) => {
                        setIsChanged(false)
                        setCheckedP(val?.target?.checked);
                      }}
                      type="checkbox"
                    />{" "}
                    <label style={{ fontSize: width < 700 && "16px" }}>All</label>
                  </div>{" "}
                </div>}
                rules={[
                  {
                    required: !checkedP,
                    message: "please select office location",
                  },
                ]}
              >
                <Select
                  disabled={checkedP} style={{ fontSize: width < 700 && "16px" }}
                  placeholder="select work location"
                  mode="multiple"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >

                  {OfficeLocationT?.map((val, id) => {
                    return (
                      <Select.Option value={val?.id} key={id} style={{ fontSize: width < 700 && "16px" }}>
                        {val?.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                // name={reset?"":"startDate"}
                name="startDate"
                label={<div style={{ fontSize: width < 700 && "18px" }}>Start Date</div>}
                rules={[
                  { required: true, message: "please select start date" },
                ]}
              >
                <DatePickerCompo disabledDate={(current) => {
                  return current && current < moment(modal?.joined);
                }} style={{ width: "100%", fontSize: width < 700 && "16px" }} />
              </Form.Item>
              <Form.Item
                name={reset ? "" : "duration"}
                label={<div style={{ fontSize: width < 700 && "18px" }}>Duration</div>}
                /*  rules={(fields)=>{console.log(fields,"fieldsssss");
                  return[{ required: true, message: "please select duration" }]}} */
                rules={[{ required: true, message: "please select duration" }]}
              >
                <Select placeholder="Please select duration" style={{ fontSize: width < 700 && "16px" }}>
                  <Select.Option value={7} key={3} style={{ fontSize: width < 700 && "16px" }}>
                    7 Days
                  </Select.Option>
                  <Select.Option value={14} key={4} style={{ fontSize: width < 700 && "16px" }}>
                    14 Days
                  </Select.Option>
                </Select>
              </Form.Item>
            </div>

          }

        </Form>
        <div className={classes.buttonDiv} style={{ marginTop: "1rem" }}>
          {/* <Button
                  disabled={viewClass.superAdmin?false:true}
                    danger={true}
                    type="primary"
                    onClick={()=>{
                      setReset(true);
                      form.resetFields();
                      form.setFieldsValue(reset ===false ? oldValues : newInitialValues);
                    }}
                    style={{ width: "6rem",height:"2.1rem"}}
                  >
                    Reset
                  </Button> */}
          <Button style={{ backgroundColor: "white", border: "1px solid #0086FF", color: "#0086FF", height: "2.1rem", width: "6rem", borderRadius: "7px" }}
            onClick={() => { setModal(); }}>
            Cancel
          </Button>
          <Button type="primary" onClick={() => { handleButtonClick(tempLocSwitch) }} style={{ height: "2.1rem", width: "6rem", borderRadius: "7px" }}
            disabled={Views?.hrAssociate && !Views?.hrAdmin ? true : ischanged}
          >Save</Button>
        </div>
      </div>
    </Modal>
  );
}
export default EditUser;