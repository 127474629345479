import { create } from "zustand";
// import { persist } from "zustand/middleware";

import { DashboardStore } from "../pages/ResourceManagement/SubComponents/Dashboard/Store/store";
import { useTeamStore } from "../pages/ProjectManagement/Team/Store/Store";

export const log = (config) => (set, get, api) =>
  config(
    (...args) => {
        console.log("====================================");
      console.log("Previous State", get());
      console.log("====================================");
      console.log("applying", args);
      set(...args);
      console.log("====================================");
      console.log("New State", get());
    },
    get,
    api
  );

export const useGlobalStore = create(
  log(
    (...a) => ({
      ...DashboardStore(...a),
      ...useTeamStore(...a)
    })
  )
);
