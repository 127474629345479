import React from "react";
import { Table, Select, Collapse, Tooltip } from "antd";

import {
  AiFillFilter,
  AiFillCaretUp,
  AiFillCaretDown,
} from "../../../../../services/Icons";
import { Color } from "../../../../../services/color";
import MinusIcon from "../../../../../assets/Appraisal/minusIconBlack.svg";
import PluseIcon from "../../../../../assets/Appraisal/pluseIconBlack.svg";
import UserIcon from "../../../../../assets/Appraisal/UserIconGray.svg";

import "../../styles/tableHeader.css";
import { getMemberList } from "../../actionsAdmin";
import Api from "../../../../../services/Api";
import { DownloadOutlined } from "@ant-design/icons";
import AssociateTable from "../../../components/Table/AssociateTable";
import { useSelector } from "react-redux";
const { Panel } = Collapse;

const SubTeam = ({
  setActiveKey,
  activeKey,
  setTeamId,
  setMemberList,
  memberList,
  sortedValue,
  handleSort,
  cycleFilter,
  filterCycleChanged,
  setFilterCycleChanged,
  setCycleFilter,
  teamId,
  initialFilter,
  cycleList,
  teamData,
  loader,
}) => {

  console.log("cycleFilter",cycleFilter)
  const handleTeamCollapse = (e) => {
    console.log("Active team collapse", e);
    setActiveKey(activeKey === e ? null : e);
    setTeamId(e);
    getMemberList({ teamId: e }, (res) => {
      setMemberList(res);
    });
  };

  const {
    meta,
  } = useSelector((state) => state.admin);
  const columns = [
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            fontSize: "0.95rem",
            fontWeight: "600",
            color: "#080808",
            position: "relative",
            width:"100%",
           paddingLeft:"1rem",
          }}
        >
          Associate
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "0.5rem",
            }}
          >
            <Tooltip title={"Click to sort ascending"}>
              <div style={{ marginBottom: -11, cursor: "pointer" }}>
                {" "}
                <AiFillCaretUp
                  style={{
                    color:
                      sortedValue === "" ||
                      sortedValue === null ||
                      sortedValue === undefined
                        ? "#787b83"
                        : sortedValue === "ASC"
                        ? Color.modalblue
                        : "#787b83",
                  }}
                  onClick={() => handleSort("ASC")}
                />
              </div>
            </Tooltip>
            <Tooltip title={"Click to sort descending"}>
              <div style={{ cursor: "pointer" }}>
                <AiFillCaretDown
                  style={{
                    color:
                      sortedValue === "" ||
                      sortedValue === null ||
                      sortedValue === undefined
                        ? "#787b83"
                        : sortedValue === "DESC"
                        ? Color.modalblue
                        : "#787b83",
                  }}
                  onClick={() => handleSort("DESC")}
                />
              </div>
            </Tooltip>
          </div>
        </div>
      ),
      dataIndex: "associate",
      key: "name",
      render: (val, row) => {
        return (
          <div style={{ display: "flex", justifyContent: "flex-start",paddingLeft:"0.5rem" }}>
            <img
              src={UserIcon}
              style={{ width: "1.5rem", height: "1.5rem", marginTop: "1rem" }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "0.5rem",
              }}
            >
              <div style={{ fontSize: "1rem",fontWeight:"500" }}>
                {row?.user?.userView?.name}
              </div>

              <div style={{ fontSize: "0.7rem",fontWeight:"500" }}>
                {row?.user?.userView?.designation}
              </div>

              <div style={{ fontSize: "0.7rem",fontWeight:"500" }}>
                {row?.user?.employeeId}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            fontSize: "0.95rem",
            fontWeight: "600",
            color: "#080808",
            width:"100%",
          }}
        >
          Contact Details
        </div>
      ),
      dataIndex: "manager",
      key: "age",
      render: (val, row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            fontWeight:"500",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ fontSize: "1rem" }}>
              {row?.user?.userView?.email}
            </div>
            <div style={{ fontSize: "0.7rem" }}>
              {row?.user?.userView?.phone}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            fontSize: "0.95rem",
            fontWeight: "600",
            color: "#080808",
            position: "relative",
            width:"100%",
          }}
        >
          Appraisal Cycle
          <div style={{ position: "relative", bottom: 4 }}>
            <Select
              suffixIcon={
                <AiFillFilter
                  style={{
                    color:
                      cycleFilter === "" ||
                      cycleFilter === null ||
                      cycleFilter === undefined
                        ? "#787b83"
                        : Color.modalblue,
                  }}
                />
              }
              allowClear={filterCycleChanged}
              trigger="click"
              type="text"
              placement="bottomLeft"
              bordered={false}
              dropdownMatchSelectWidth={false}
              onChange={(e) => {
                setFilterCycleChanged(e !== undefined ? true : false);
                setCycleFilter(e);
                console.log("Edited work summary", e);
                getMemberList(
                  { teamId: teamId, appraisalCycle: e, sort: sortedValue },
                  (res) => {
                    setMemberList(res);
                  }
                );
              }}
              value={initialFilter}
              removeIcon={true}
            >
              {cycleList && Object.values(cycleList).map((item) => (
                <>
                  <Select.Option
                    value={item?.appraisalCycle}
                    label={item?.appraisalCycle}
                    title=""
                  >
                    {item?.appraisalCycle}
                  </Select.Option>
                </>
              ))}
            </Select>
          </div>
        </div>
      ),
      dataIndex: "cycle",
      key: "address",
      render: (val, row) => (
        <div style={{ display: "flex", justifyContent: "center",fontWeight:"500" }}>
          {row?.user?.appraisalCycle}
        </div>
      ),
    },
  ];

  // console.log("memberList", memberList);
  return (
    <div
      style={{
        width: "100%",
        height: "70vh",
        overflowY: "scroll",
        marginTop: "1rem",
      }}
    >
      {teamData &&
        teamData?.map((item, deptIndex) => {
          return (
            <div
              key={deptIndex}
              style={{
                boxShadow: "0px 3px 6px #00000029",
                backgroundColor: "#F3F3F3",
                padding: "1rem",
                fontSize: "1.2rem",
                fontWeight: "bold",
                borderRadius: "10px",
                marginTop: "1rem",
                width: "97%",
                margin: "1rem",
              }}
            >
              Department :&nbsp;&nbsp;{item?.name}
              {item?.teams &&
                item?.teams.map((i, index) => {
                  return (
                    <div key={index}>
                      <Collapse
                        key={i?.id}
                        style={{
                          marginTop: "1rem",
                          borderRadius: "10px",
                          borderRadius: "10px",
                        }}
                        expandIconPosition="end"
                        expandIcon={(e) => {
                          return (
                            <div>
                              
                            <img src={!e.isActive ? PluseIcon : MinusIcon} />
                            </div>
                          );
                        }}
                        activeKey={activeKey === i.id ? [i.id] : []}
                         onChange={() => handleTeamCollapse(i?.id)}
                        accordian={true}
                      >
                        <Panel
                          key={i?.id}
                          style={{
                            backgroundColor: "#F3F3F3",
                            borderRadius: "10px",
                            marginBottom: "1rem",
                          }}
                          header={
                            <div className="team-header">
                                <div
                                  style={{ fontWeight: "bold", fontSize: "1rem" }}
                                >
                                  Team Name:&nbsp;&nbsp;{i?.name}
                                </div>
                             
                          </div>
                          }
                        >
                          <div style={{ margin: "-0.9rem" }}>
                            {/* <Table
                              loading={loader}
                              columns={columns}
                              dataSource={memberList}
                            /> */}
                             {<div className="team-download" style={{margin:"1rem 0",}}>
                              <div style={{cursor:"pointer"}} onClick={()=>{
                                Api.get(`/appraisal-admin/downloadRank?teamId=${i?.id}&appraisalCycle=${cycleFilter?cycleFilter:""}&sort=${sortedValue}`).send(res=>{
                                  window.open(res,'_blank').focus();
                                })
                              }}
                              >
                                
                                <span>Download</span>
                                <DownloadOutlined/>
                              </div>
                              </div>}

                          <AssociateTable
                                   loading={loader}
                                  dataSource={memberList}
                                  columns={columns}
                                  rowKey={(record) => record?.id}
                                  //  meta={meta}
                                  style={{ width: "100%", maxHeight: "50vh" }}
                                  //  onChange= {(page, pageSize) => {
                                  //   dispatch(adminActions.updatePage({ page: page, pageSize: pageSize }));
                                  //  }}
                                />

                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  );
                })}
            </div>
          );
        })}
    </div>
  );
};

export default SubTeam;
