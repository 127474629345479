import React, { useEffect, useState } from 'react';
import classes from "./login.module.css";
import Logo from '../../assets/loginLogo.svg';
import ForgotPasswordImage from "../../assets/forgotPasswordImage.png";
import Vector from "../../assets/Vector.png";
import Union from "../../assets/Union.svg";
import Circle from "../../assets/Circle.svg";
import Image from "../../assets/loginImg.svg";
import Union1 from "../../assets/Union 1.svg";
import login_brigo_text from '../../assets/login_brigo_text.svg';
import   our_journey_img from '../../assets/our-journey-img.svg';
import Union2 from "../../assets/Union 2.svg";
import { Card, Form, Input,Button,Row,Col } from 'antd';
import { ArrowRightOutlined,ArrowLeftOutlined,MinusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Api from "../../services/Api"
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";
import { useLoginStore } from './store';
import { Header } from './login';
import Feed from './Feed/Feed';
import JobSearch from './JobSearch/jobSearch';

const Page7=({onFinish,})=>{
  const [form]=Form.useForm();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [email,setEmail]=useState("");
  const [valid,setValid]=useState(false);
  const {isSignInModal}=useLoginStore();

  const handleFormChange = () => {
    const formValues = form.getFieldsValue();
    const isDisabled = Object.values(formValues).some(value => !value);
    setIsSubmitDisabled(isDisabled);
  };
  const validateEmail = (_, value) => {
    if (!value||value.trim() === ''||!value.trim().includes('@')||!(value.trim().includes('.com')||value.trim().includes('.in'))) {
      setValid(false);
      return Promise.reject('Enter a valid email!');
    }
    setValid(true);
    return Promise.resolve();
  };

  console.log("isSubmitDisabled",isSubmitDisabled)
  return(
    <div className={isSignInModal?classes.page5:classes.page2}>
      <span>Forgot password</span>
      <span>Enter the email id you registered with and we will send you a link to reset your password</span>

      <Form layout='vertical' form={form}
      className={classes.page3Form}
      // style={{height:"44vh"}}
      onFieldsChange={
        handleFormChange
      }
      onFinish={onFinish}
      >
        <Form.Item name="email" label={<span>Email ID</span>}  rules={[{validator:validateEmail}]}>
           <Input type='text' placeholder='Enter email ID' style={{height:"2.4rem"}} onChange={(e)=>{
            setEmail(e.target.value);
           }}/>
        </Form.Item>
        
          <div className={classes.page3button} style={{marginTop:"3rem"}}>
          <div style={{fontSize:"0.8rem"}}>
              <span>Didn’t receive the link?</span>
              <span 
              style={{cursor:email?"pointer":"not-allowed"}}
              onClick={()=>{
                Api.post('/auth-auth/forgotpassword').params({
                  email:email,
                }).send(res=>{
                  if(res)
                  {
                    // navigate('/forgotPassword');
                  }
                })
              }}>Resend</span>
            </div>
            <button htmlType="submit" disabled={isSubmitDisabled||!valid}>Send Link</button>
        </div>
      </Form>
    </div>
  )
}

const ForgotPassword=()=>{

    const navigate = useNavigate();
    const {width}=useWindowDimensions();
    // const [curPage, setCurPage]=useState(1);
    const {curPage, setCurPage,activeTab,setActiveTab}=useLoginStore();
    console.log("activeTab",activeTab)
    // const [activeTab,setActiveTab]=useState(3);

    // useEffect(()=>{
    //   setCurPage(1);
    // },[activeTab])

    const onFinish=(value)=>{
      
      // console.log("Values",value);
      // Api.post("/resource-auth/forgotpassword") .params({
      //     email:value.email,
      //   })
      //   .send((res)=>{
      //     if(res?.show?.type === "success"){
      //       // navigate("/checkMail")
      //     }
      //   });

     
        Api.post('/auth-auth/forgotpassword').params({
          email:value.email,
        }).send(res=>{
          if(res)
          {
            // navigate('/forgotPassword');
          }
        })
      
    }

    return (
      <>
      {/* <div className={classes.container}>
       <div>
      <img src={Union1} alt='' style={{height:width<700&&"6rem",width:width<700&&"6rem"}}/>
      </div>
      <div>
      <img src={Logo} alt='' style={{height:width>700?"4rem":"4.5rem",width:"4rem"}}/>
      </div>
      <div className={classes.contents2}>
        <div ></div>
      </div>
      <div className={classes.contents}>
        <div className={classes.overlapContent}>
        <div style={{display:"flex",height:"100%",alignItems:"center",padding:width>700?"1rem":"0.5rem 1rem 1rem 1rem",borderRadius:5,flex:"50%"}}>
            <p style={{display:"flex",width:"100%",color:"#162432",justifyContent:"flex-start",alignItems:"center",flexDirection:"row",fontWeight:600,fontSize:"25px",marginLeft:width>700&&"1rem",marginTop:width<700&&"0.5rem"}}>
              <span style={{color:"#0086FF",marginRight:"0.4rem"}}>Hey, </span> Associate!
            </p>
              <img src={Image} alt='' style={{height:width>700?"40vh":"",width:width>700?"35vw":"80vw"}}/>
              <p style={{color:"#0086FF",display:"flex",width:"100%",justifyContent:"center",flexDirection:"row",fontWeight:width>700?600:590,marginTop:"1rem",fontSize:width>700?"20px":"17px"}}>
                " Self determination and integrity "</p>
            </div>
            <div style={{display:width>700&&"flex",height:"100%",alignItems:"center",padding:width>700?"1rem":"0 1rem 1rem 1rem",borderRadius:5,flex:"50%"}}>
                  <div style={{backgroundColor:"#F5FCFF",padding:width>700?"1rem":"1rem 1rem 0.5rem 1rem",width:"100%",height:width>700&&"45vh"}}>
                    <h2 style={{color:"#162432"}}>Forgot Password</h2>
                    <p style={{marginBottom:"1rem",marginTop:"0.5rem",color:"#16243280",fontSize:width<700?"13.5px":13}}>No worries!, We'll send you reset instructions</p>
                    <Form onFinish={onFinish}>
                    <Form.Item
                        name="email"
                        rules={[{
                            required: true,
                            message: "Please enter your E-mail!",
                          },]}>                
                  
                        <Input type="email" placeholder='Email' style={{height:width<700&&"2.4rem",fontSize:width<700&&"17px"}}
                        ></Input>
                   
                    </Form.Item>
                    <Form.Item>
                    <div className={classes.resetPassword}>
                      <Button type="primary" htmlType="submit" style={{fontSize:"16px"}}>
                          Reset Password {" "}
                         
                      </Button>
                      <Button type="link" onClick={()=>{navigate("/login")}} style={{fontSize:"15px"}}>
                       
                         Back to log in
                      </Button>
                    </div>
                   </Form.Item>
                    </Form>
                   </div>
                 
                </div>
              </div>
           </div>
           <div className={classes.imageB}>
           <img src={Union2} alt='' style={{height:width<700&&"7rem",width:width<700&&"7rem"}}/>
            </div>
         </div> */}

      

      
          <Page7 
          onFinish={onFinish}
          />
        
      
         </>
    )
}
export default ForgotPassword;