import { update } from "react-spring";
import { create } from "zustand";


export const useLoginStore=create(set=>({
    curPage:1,
    resendPhone:null,
    isSignIn:false,
    isSignInModal:false,
    ceoMessageModal:false,
    videoModal:false,
    eventModal:false,
    cultureData:null,
    notificationModal:false,
    newsletterModal:false,
    newsletter:null,
    magazineData:null,
    event:null,
    activeTab:1,
    current:1,
    limit:20,
    clickedJob:undefined,
    isComingfromLidar:false,
    referrerURL:{
        curr:"",
        pre:""
    },
    hiringstatus: {
        render: 1,
        updater: () => { },
        details: {},
        basicDetails:{},
        ref:"hiring",//if notification update this key
    },
    setIsComingFromLidar:(val)=>set(state=>({...state, isComingfromLidar:val})),
    setResendPhone:(val)=>set(state=>({resendPhone:val})),
    setClickedJob:(val)=>set(state=>({clickedJob:val})),
    setUrl:(val)=>set(state=>({referrerURL:val})),
    setCurPage:(val)=>set(state=>({curPage:val})),
    setIsSignIn:(val)=>set(state=>({isSignIn:val})),
    setIsSignInModal:(val)=>set(state=>({isSignInModal:val})),
    ceoMessageModalHandler:(val)=>set(state=>({ceoMessageModal:val})),
    videoModalHandler:(val)=>set(state=>({videoModal:val})),
    cultureDataHandler:(val)=>set(state=>({cultureData:val})),
    eventModalHandler:(val)=>set(state=>({eventModal:val})),
    notificationModalHandler:(val)=>set(state=>({notificationModal:val})),
    setActiveTab:(val)=>set(state=>({activeTab:val})),
    newsletterModalHandler:(val)=>set(state=>({newsletterModal:val})),
    setNewsletter:(val)=>set(state=>({newsletter:val})),
    setEvent:(val)=>set(state=>({event:val})),
    setCurrent:(val)=>set(state=>({current:val})),
    setLimit:(val)=>set(state=>({limit:val})),
    magazineDataHandler:(val)=>set(state=>({magazineData:val})),
    updateRender:(val) => set((state) => ({
        ...state,
        hiringstatus: { ...state.hiringstatus,render:val }
    })),
    updateDeatails: (val) => set((state) => ({
        ...state,
        hiringstatus: { ...state.hiringstatus,details:val }
    })),
    updateBasicDeatails: (val) => set((state) => ({
        ...state,
        hiringstatus: { ...state.hiringstatus,basicDetails:val }
    })),
    hiringStatusUpdater: (page, updater,ref) =>
        set((state) => ({
            ...state,
            hiringstatus: { ...state.hiringstatus, render: page, updater: updater, ref:ref }
        })),
     

}))
