import React from "react";
import '../design/framework.css'
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

const Button = ({onclick=()=>{}, background, fontColor, height="35px", width="6vw", borderRadius=10, fontSize=14, name='save', borderColor="", disabled=false, Prefix=null, loader=false}) =>{
    return (
        <>
          <div onClick={()=>{
            if(!disabled){
                onclick();
            }
          }} className="r-c-c m-t-10 " style={{ color: fontColor, backgroundColor:disabled?"grey": background, height: height, width: width, borderRadius: borderRadius, fontSize: fontSize, cursor:disabled?"not-allowed":"pointer", border:`1px solid ${borderColor}`, margin:"5px 5px 5px 5px" , minWidth:"100px"}}>
            {
                loader&&<Spin indicator={antIcon} style={{color:"white"}}/>
            }
            {
                !loader&&
                Prefix&&
                <Prefix/>
            }
                {name}
            </div>
        </>
    )
}
export default Button;