import { configureStore } from "@reduxjs/toolkit";

import logger from "redux-logger";
import { saveToLocalStorage, loadFromLocalStorage } from "../../../services/Storage";

// add all reducers here
import counterReducer from "../counter/redux";
import associateReducer from "../associate/store";
import adminReducer from "../admin/store";
import managerReducer from "../manager/store";
import authReducer from "../login/store";
import globalReducer from "./loader";

const preloadedState = loadFromLocalStorage();

const store = configureStore({
  reducer: {
    counter: counterReducer,
    associate: associateReducer,
    manager: managerReducer,
    admin: adminReducer,
    auth: authReducer,
    global: globalReducer,
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat(logger),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat(logger),
  preloadedState,
});

// store.subscribe(() => saveToLocalStorage({ auth: store.getState().auth }));

window.store = store;

export default store;
