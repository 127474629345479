import brigoshaLogo from "../../../../assets/brigosha_logo.svg"
import ButtonCompo from "../../../../design/ButonCompo";
import thankIcon from "../../../../assets/ThankYouImage.svg";
import './ThankYou.css';
function ThankYou(){

    return(
        <div className='mainThankYoupage' >
            <div className="ThankYouContainer">
                <div>
                    <img src={brigoshaLogo} ></img>
                </div>
                <div>
               <span style={{font:'normal 600 24px/36px Poppins',color:'#0086ff'}}> Profile Submitted Successfully! </span>
                </div>
                <div>
                    <img src={thankIcon} ></img>
                </div>
                <div>
                    <ButtonCompo title={"Take me home"} style={{color:'#fff',backgroundColor:'#0086ff'}} 
                    onClick={()=>{window.open("https://brigosha.com/","_self").focus();}}
                     />
                </div>
            </div>
        </div>
    )
}
export default ThankYou;