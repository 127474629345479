import { create } from "zustand";

export const useFPStore=create(set=>({
    search:"",
    loader:false,
    showModal:{feedback:false,recommend:false},
    pageSize:10,
    paginationPage:1,
    dashDeptInfo:null,
    dashGraphData:null,
    selectedDept:[],
    eachRateInfo:[],
    recommendationData:[],
    associateInfo:null,
    filteredQuestions:null,
    deptExtraInfo:null,
    tableLoader:false,
    setShowModal:(val)=>set(state=>({showModal:val})),
    setSearch:(val)=>set(state=>({search:val})),
    setLoader:(val)=>set(state=>({loader:val})),
    setPageSize:(val)=>set(state=>({pageSize:val})),
    setPaginationPage:(val)=>set(state=>({paginationPage:val})), 
    dashDeptInfoHandler:(val)=>set(state=>({dashDeptInfo:val})),
    dashGraphDataHandler:(val)=>set(state=>({dashGraphData:val})),
    eachRateInfoHandler:(val)=>set(state=>({eachRateInfo:val})),
    recommendationDataHandler:(val)=>set(state=>({recommendationData:val})),
    associateInfoHandler:(val)=>set(state=>({associateInfo:val})),
    filteredQuestionsHandler:(val)=>set(state=>({filteredQuestions:val})),
    deptExtraInfoHandler:(val)=>set(state=>({deptExtraInfo:val})),
    tableLoaderHandler:(val)=>set(state=>({tableLoader:val})),
}))