import React, { useState } from 'react';
import { Modal, Button, Radio, Spin } from 'antd';

const ManagerialWorkdaysModal = ({ visible, onCancel, onUpdate, managerTeams, initialTeamName }) => {

    console.log(initialTeamName,"MANAGERCHECK")
    const [selectedTeam, setSelectedTeam] = useState(null);

    const handleTeamChange = e => {
        setSelectedTeam(e.target.value);
    };

    return (
        <Modal
            title={<div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>Managerial Workdays</div>}
            open={visible}
            centered
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="update" type="primary" onClick={()=>onUpdate(selectedTeam)} disabled={!selectedTeam }>
                    Update
                </Button>,
            ]}
        >
            <p style={{ textAlign: 'left', marginBottom: '16px' }}>
                Choose which team's schedule the manager should adhere to for working days.
            </p>
            {initialTeamName !== undefined?
            <p style={{ textAlign: 'left', marginBottom: '16px' }}>The manager is currently adhered to <span style={{fontWeight:'700'}}>{initialTeamName}</span></p>:null}
            <h5 style={{ marginBottom: '8px', fontWeight: 'bold' }}>ALL TEAMS</h5>
            {
                console.log(managerTeams,"sssmanagerTeamsmanagerTeams")
            }
            {
                managerTeams ?
                    <div style={{height:"50vh",overflowY:"scroll"}}>
                        <Radio.Group onChange={handleTeamChange} value={selectedTeam}>
                            {
                                managerTeams?.managerAllTeams?.map((item, index) => (
                                    <Radio style={{ display: 'block' }} value={item?.id}>
                                        {item?.name}
                                    </Radio>

                                ))
                            }

                        </Radio.Group>
                    </div>
                    :
                    <div style={{height:"40vh",display:"flex", alignItems:"center",justifyContent:"center", width:"100%"}}>
                        <Spin></Spin>
                    </div>
            }
        </Modal>
    );
};

export default ManagerialWorkdaysModal;
