import React, { useEffect, useState } from "react";
import classes from "./AddMembersModal.module.css";
import { Form, Select, Input, DatePicker, } from "antd";
import {
  ModalPrimaryButton,
  ModalSecondaryButton,
} from "../../../../components/Button/Button";
import addteam_icon from "../../../../assets/addteam_icon.svg";
import {
  addMembersApi,
  getAddmembersDropdownApi,
} from "../Action";
import Api from "../../../../services/Api";
import { useGlobalStore } from "../../../../store";
import Modal from "../../../../components/Modal/Modal";
import DatePickerCompo from "../../../../components/DatePicker/DatePickerCompo.tsx";
import moment from "moment";
import { useWindowDimensions } from "../../../../components/windowDimention/windowDimension";
const AddMembersModal = ({
  department,
  refresh,
}) => {
  const [form] = Form.useForm();
  const {
    isAddMembersModal,
    addMembersModalHandler,
    addMembersDropdownHandler,
    addMembersDropdown,
  } = useGlobalStore((state) => state);
  const Option = Select.Option;
  const [reason, setReason] = useState("");
  const {width}=useWindowDimensions()
  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  console.log("DEEEEEPPPP",department)
  useEffect(() => {
    if (isAddMembersModal !== undefined) {
      if(isAddMembersModal?.teamId&&department?.id&&  isAddMembersModal?.manager?.id)
      {
        getAddmembersDropdownApi(
          addMembersDropdownHandler,
          isAddMembersModal?.teamId,
          department?.id,
          isAddMembersModal?.manager?.id,
          Api
        );
      } 
    }
  }, [
    isAddMembersModal?.teamId,
    isAddMembersModal?.manager?.id,
    department?.id,
    isAddMembersModal,
  ]);
  return (
    <Modal
      show={isAddMembersModal}
      closableRight={true}
      right={false}
      header={
        <div className={classes.modalHeader} style={{display:"flex", justifyContent:"flex-start"}}>
          <h3>Add Members</h3>
        </div>
      }
      style={{height:"85vh", width:width<700?"90vw":"70vw"}}
      // style={{maxHeight:"100vh",overflowY:'auto'}}
      onCancel={() => addMembersModalHandler(false)}
    >
      {isAddMembersModal && (
        <div>
          <Form
          className={classes.addTeamModal}
            layout="vertical"
            form={form}
            onFinish={(event) => {
              event["departmentId"] = department?.id;
              event["teamId"] = isAddMembersModal?.teamId;
              event["managerId"] = isAddMembersModal?.manager?.id;
              event["reason"] = reason;
              event['date']=event['date']?.format('YYYY-MM-DD')
              addMembersApi(event, Api);
              addMembersModalHandler(false);
              refresh();
            }}
          >
            <Form.Item
             
              label={<span style={{fontWeight:"600"}}>Department</span>}
              initialValue={department?.name}
              name="departmentId"
              rules={[{ required: true, message: "Please select an option!" }]}
            >
              <Select placeholder="Select" disabled={true} size="medium"></Select>
            </Form.Item>
            <Form.Item
              label={<span style={{fontWeight:"600"}}>Manager</span>}
              initialValue={isAddMembersModal?.manager?.name}
              name="managerId"
              rules={[{ required: true, message: "Please select an option!" }]}
            >
              <Select placeholder="Select" disabled={true} size="medium"></Select>
            </Form.Item>
            <Form.Item
              label={<span style={{fontWeight:"600"}}>Team</span>}
              initialValue={isAddMembersModal?.teamName}
              name="teamId"
              rules={[{ required: true, message: "Please select an option!" }]}
            >
              <Select placeholder="Select" disabled={true} size="medium"></Select>
            </Form.Item>
            <Form.Item
              label={<span style={{fontWeight:"600"}}>Members</span>}
              name="members"
              rules={[{ required: true, message: "Please select an option!" }]}
            >
              <Select placeholder="Select" mode="multiple" size="medium" allowClear={true}>
                {addMembersDropdown?.map((item, index) => {
                  return (
                    <Option value={item.id} key={index}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={<span style={{fontWeight:"600"}}>Select Date </span>}
              name="date"
              initialValue={moment()}
              // rules={[{ required: true, message: "Please select an option!" }]}
            >
              <DatePickerCompo style={{width:'100%'}} size="medium"/>
            </Form.Item>

            <Form.Item label={<span style={{fontWeight:"600"}}>Reason</span>}>
              <Input.TextArea
                rows={1}
                value={reason}
                onChange={handleReasonChange}
              />
            </Form.Item>
            <div className={classes.buttonDiv}>
              <ModalPrimaryButton htmlType="submit">Save</ModalPrimaryButton>
              <ModalSecondaryButton
                onCancel={() => addMembersModalHandler(false)}
              >
                Cancel
              </ModalSecondaryButton>
            </div>
          </Form>
        </div>
      )}
    </Modal>
  );
};
export default AddMembersModal;
