import { create } from "zustand";

export const useStore = create(set => ({
  createModal:false,
  updateCreateModal:(event)=>set(state=>({createModal:event})),
  feedbackQuestionPage:false,
  UpdateFeedbackPage:(event)=>set(state=>({feedbackQuestionPage:event})),
  feedbackSaveState:"create",
  updateFeedbackSaveState : (event)=>set(state=>({feedbackSaveState:event})),
  openEditFeedbackModal: false,
  updateEditFeedbackModal:(event)=>set(state=>({openEditFeedbackModal:event})),
  feedbacksquestions:[],
  updateFeedbackQuestion:(event)=>set(state=>({feedbacksquestions:event})),
  selectedCard:'',
  updateSelectedCards:(event)=>set(state=>({selectedCard:event})),
  selectCardCount:0,
  updateSelectedCardCount:(event)=>set(state=>({selectCardCount:event})),
  feedbackaddquestions: [],
  updateFeedbackAddQuestions:(event)=>set(state=>({feedbackaddquestions:event})),
  feedbackData: null,
  updateFeedbackData:(event)=>set(state=>({feedbackData:event})),
  feedbackInputType:["Text","Emoji-Rating","Multiple Choice","FileUpload",],
  updateFeedbackInputType:(event)=>set(state=>({feedbackInputType:event})),
  feedbackStatus: false,
  updateFeedbackStatus:(event)=>set(state=>({feedbackStatus:event})),
}))