export const getDepartmentApi=(departmentHandler,Api)=>{
    Api.get("/project-release/allDepartment").send(res=>{
        if(res!==undefined || res?.show?.type!=='error'){
        departmentHandler(res);
    }
    })
}
export const getReleasbleMembersApi=(Action,IoMdContact,releasableMembersHandler,event,loaderHandler,Api,Tooltip)=>{
    loaderHandler(true);
    Api.get("/project-release/allEmployees").params({departmentId:event}).send(res=>{
        releasableMembersHandler(res?.map((item,index)=>{
            let obj = {
                actions:(
                <>
                    <Action record={item}/>
                </>),
                  asso:(
                    <>
                        <Tooltip title={item?.userView?.name} style={{display:"flex",  flexDirection:"column"}}>
                       <div className="r-ac">
                          <IoMdContact color="#EDEDED" fontSize={34} style={{ margin: 10 }} />
                          <div style={{width:'7rem',overflow:'hidden',textOverflow:'ellipsis', whiteSpace:"nowrap"}}>
                          {item?.userView?.name}<br />
                             <div style={{ marginTop: 8 , color:"#16243280",  fontSize:13}}>{item?.userView?.phone} </div>
                          </div>
                       </div>
                        </Tooltip>
                    </>
                    ),
                  contactDetails:(
                    <>
                    <Tooltip title={`${item?.userView?.email} | ${item?.userView?.uid}`}>
                       <div >
                       {`${item?.userView?.email} | ${item?.userView?.uid}`}
                    </div>
                    </Tooltip>
                    
                    </>
                  ) 
            }
            return {...item,sno:index+1,...obj,...item?.userView}
        }));
        loaderHandler(false);
    })
}
export const releaseMemberApi=(employeeId,date,reason,releaseModalHandler,Api,deptId, callback)=>{
    Api.patch(`/project-release/releaseEmployee`).params({
        uid:employeeId,
        date,
        reason,
        deptId
    }).send(res=>{
        if(res?.show?.type==="success")
        {
            releaseModalHandler(false);
            if(callback){
                callback()
            }
        }     
    }) 
}