import Api from "../../services/Api";
import { KeyIsNotEmpty } from "../ResourceManagement/SubComponents/Employees/formData";

export const getDocuments = (setter, params, ) => {
    Api.get(`/resource-home/documents`)
        .params(params)
        .send((response) => {
            if (response) {
                setter(response)
            }
        });
}

export const deleteDocuments = (setter, params ) => {
    Api.delete(`/resource-home/deleteDocument`)
        .params(params)
        .send((response) => {
            setter();
        });
}

export const publishApi=(params)=>{
    let url=`/resource-home/publish?documentName=${params?.documentName}`
    // if(params?.documentId)
    // {
    //     url=`/resource-home/publish?documentName=${params?.documentName}&documentId=${params?.documentId}`
    // }
    let param={}
    if(KeyIsNotEmpty(params?.documentId)){
        param={documentId:[params?.documentId]}
    }
    Api.patch(`${url}`).params(param).send(res=>{ 
    })
}



