import Api from "../../services/Api"
export const getInsuranceDataApi=(par,callback)=>{
    Api.get(`/resource-home/getEmployeeDetails?page=${par.page}&limit=${par.limit}&search=${par.search}&filter=${par?.filter}`).send(res=>{
        console.log("InsuranceData",res);
        callback(res);
    })
}

export const uploadDocumentApi=(par,url,callback)=>{
    console.log(url,"567890987654567890987");
    let params = {};
    if(par?.documentType){
     params={document:par?.document, documentType:par?.documentType,fileName:par?.fileName}
    }else{
        params={document:par?.document,fileName:par?.fileName}
    }
    Api.post(url).upload(params,(per,res)=>{
        console.log("uploaddingRes",res)
        if(res){
            callback();
        }
    })
}

export const publishApi=()=>{
    Api.patch("/resource-home/publish").send(res=>{
        console.log("Publish Api Res",res)
    })
}

const download= (name,url)=>{
    console.log("downloadRes")
    const link =  document.createElement('a');
    link.download = name;
    link.href = url;
    link.click();
}


const exportFile = (file) =>{
    let a = document.createElement('a');
    a.href = URL.createObjectURL(file);
    a.setAttribute('download', '');
    a.click();
}




export const  downloadMyCardApi=( callback)=>{

    Api.get(`/resource-home/healthCard?download=${true}`).send(res=>{
        callback(res);
        // res?.forEach((el,id) => {
        //     setTimeout(() => {
        //         const link = document.createElement('a');
        //             link.download = el?.fileName;
        //             link.href = el?.filePath;
        //             link.setAttribute('download','');
        //             link.setAttribute('key',id)
        //              link.click();
        //     }, 100);
        //        //  downloadData.push(link)
        //   });
       
        // console.log("downloadRes",res)
  /*     let downloadData=[] */
    //   res?.forEach((el,id) => {
    //     const link = document.createElement('a');
    //         link.download = el?.fileName;
    //         link.href = el?.filePath;
    //         link.setAttribute('download','');
    //         link.setAttribute('key',id)
    //         // link.click();
    //         downloadData.push(link)
    //   });
// console.log("kaushal",downloadData);
/* Promise.all(res).then((data)=>{
    data.forEach((val,id)=>{
        console.log("kaushal",val)

        const link = document.createElement('a');
            link.download = val?.fileName;
            link.href = val?.filePath;
            link.setAttribute('target','__blank');
            link.setAttribute('key',id)
            link.click();
    })
    // val.click();
}) */

        // if(res)
        // {
        //   window.open(res.file, "_blank")?.focus();
        // }
    })
}


export const deleteSingleDocumentApi=(id,callback)=>{
    console.log("consoleId Here",id)
    Api.delete("/resource-home/deleteDocument").params({
        id:id,
         healthInsuranceCard:true,
    }).send(res=>{
        console.log("Show Response",res);
        callback();
    });
}

export const deleteAllDocumentApi=(userId,callback)=>{
    Api.delete("/resource-home/deleteDocument").params({
        userId:userId,
        healthInsuranceCard:true,
    }).send(res=>{
        console.log("Show Response",res);
        callback();
    });
}