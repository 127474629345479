import React, { useEffect, useState } from 'react';
import Notification from '../../../../services/Notification';
import uploadIcon from '../../../../assets/AddBlueButton.svg';
import { postUploadedDocuments } from '../../action/action';
import { Button, Checkbox, Typography } from 'antd';
import documentIcon from "../../../../assets/docIcon.svg"
import { DeleteOutlined } from '@ant-design/icons';
import { getLink, sendLink } from '../../../../Config';
import { BsCheck2Circle } from "react-icons/bs";
import viewClass from '../../../../services/ViewRole';

// link key will be used to access the uploaded document directly
// and formate key will be used to send the key of uploaded document to backend.
export let uploadList = [
    {
        label: "Photo",
        checked: false,
        mandatary: true,
        formate: '',
        link:'',
        name: 'photo',
        showV:true,
        verify:false,
    },
    {
        label: "Resume",
        checked: false,
        mandatary: true,
        formate: '',
        link:'',
        name: 'resume',
        showV:true,
        verify:false,


    },
    {
        label: "Pan Card",
        checked: false,
        mandatary: true,
        formate: '',
        link:'',
        name: 'pan',
        showV:true,
        verify:false,

    },
    {
        label: "Aadhar Card",
        checked: false,
        mandatary: true,
        formate: '',
        link:'',
        name: 'aadhar',        
        showV:true,
        verify:false,


    },
    {
        checked: false,
        label: "Passport",
        mandatary: false,
        formate: '',
        link:'',
        name: 'passport',
        showV:true,
        verify:false,


        

    },
    {
        label: "Driving Licence",
        checked: false,
        mandatary: false,
        formate: '',
        link:'',
        name: 'dl',
        showV:true,
        verify:false,
    },
    {
        label: "Voter ID",
        checked: false,
        mandatary: false,
        formate: '',
        link:'',
        name: 'voterId',
        showV:true,
        verify:false,


    },
    {
        label: "Blood Group",
        checked: false,
        mandatary: false,
        formate: '',
        link:'',
        name: 'bloodGroup',
        showV:true,
        verify:false,


    }
]

export let uploadExperiance = [
    {
        label: "Offer Letter",
        checked: false,
        mandatary: false,
        formate: '',
        link:'',
        name: 'offerLetter',
        showV:true,
        verify:false,


    },
    {
        label: "Experience Letter",
 
        checked: false,
        mandatary: true,
        formate: '',
        link:'',
        name: 'expLetter',
        showV:true,
        verify:false,


    },
    {
        label: "Pay Slip 1",
        checked: false,
        mandatary: true,
        formate: '',
        link:'',
        name: 'paySlip1',
        showV:true,
        verify:false,


    },
    {
        label: "Pay Slip 2",
        checked: false,
        mandatary: true,
        formate: '',
        link:'',
        name: 'paySlip2',
        showV:true,
        verify:false,


    }, 
    {
        label: "Pay Slip 3",
        checked: false,
        mandatary: true,
        formate: '',
        link:'',
        name: 'paySlip3',
        showV:true,
        verify:false,


    },
    {
        label: "Background Verification",
        checked: false,
        mandatary: true,
        formate: '',
        link:'',
        name: 'backgroundV',
        showV:true,
        verify:false,


    },
    {
        label: "Relieving Letter",
        checked: false,
        mandatary: false,
        formate: '',
        link:'',
        name: 'relievingLetter',
        showV:true,
        verify:false,


    },
    {
        label: "Form 16",
        checked: false,
        mandatary: false,
        formate: '',
        link:'',
        name: 'form16',
        showV:true,
        verify:false,


    },
    {
        label: "Hike letter",
        checked: false,
        mandatary: false,
        formate: '',
        link:'',
        name: 'hikeLetter',
        showV:true,
        verify:false,


    }
]
export let uploadAcadamics = [
    {
        label: "10th Mark Sheet",
        checked: false,
        mandatary: false,
        formate: '',
        link:'',
        name: 'marksheet10',
        showV:true,
        verify:false,

    },
    {
        label: "10th Certificate",
        checked: false,
        mandatary: true,
        formate: '',
        link:'',
        name: 'certificate10',
        showV:true,
        verify:false,

    },
    {
        label: "12th Mark Sheet",
        checked: false,
        mandatary: false,
        formate: '',
        link:'',
        name: 'marksheet12',
        showV:true,
        verify:false,

    },
    {
        label: "12th Certificate",
        checked: false,
        mandatary: true,
        formate: '',
        link:'',
        name: 'certificate12',
        showV:true,
        verify:false,

    },
    {
        label: "UG Mark Sheet",
        checked: false,
        mandatary: false,
        formate: '',
        link:'',
        name: 'marksheetUG',
        showV:true,
        verify:false,

    },
    {
        label: "UG Degree Certificate",
        checked: false,
        mandatary: true,
        formate: '',
        link:'',
        name: 'certificateUG',
        showV:true,
        verify:false,

    },
    {
        label: "UG Profivsional Certificate",
        checked: false,
        mandatary: false,
        formate: '',
        link:'',
        name: 'certificatePUG',
        showV:true,
        verify:false,

    },

    {
        label: "PG Marksheet",
        checked: false,
        mandatary: false,
        formate: '',
        link:'',
        name: 'marksheetPG',
        showV:true,
        verify:false,

    },
    {
        label: "PG Degree Certificate",
        checked: false,
        mandatary: false,
        formate: '',
        link:'',
        name: 'certificatePG',
        showV:true,
        verify:false,

    },
    {
        label: "PG Profivsional Certificate",
        checked: false,
        mandatary: false,
        formate: '',
        link:'',
        name: 'certificatePPG',
        showV:true,
        verify:false,

    },
 
]

// in EditProfile we are setting every uploaded files in uploadedFiles
function UploadDocument({onSavePatch,modalRender,onClose,uploadedFiles}) {
  const [otherName, setOtherName] = useState('');
  const [other, setOther] = useState(false);
  const [docList,setDocList]=useState([]);
const[upload,setUpload]=useState([]);
  const [saveEnable,setSaveenable]=useState(false)
  useEffect(()=>{
    if(modalRender?.type==='Academics'){
        setUpload(uploadAcadamics)
    }
    else if(modalRender?.type==='Document Details'){
        setUpload(uploadList)
    }
    else{
        setUpload(uploadExperiance)
    }
  },[modalRender])
useEffect(()=>{
    if(modalRender?.type==='Academics'){
        let files=uploadedFiles?.Academics;
        let array=[];
        //updating all the upload key with the already uploaded documents which is coming in uploadedFiles
        upload?.forEach((val)=>{
            if(files){
            array.push({...val,verify:files[`${val?.name}_HRVerified`], link:files[val?.name]?getLink(files[val?.name]):'', formate:files[val?.name]?sendLink(files[val?.name]):'',checked:files[val?.name]?true:false})
            }
            else{
                array.push(val)
            }
        }) 
        if(files?.others){
            let obj=Object.entries(files?.others)
            for (const [key, value] of obj){
                array.push({label:key,name:key,link:getLink(value), formate:sendLink(value),checked:true,type:'other'})
            }
        }
        setDocList(array);
    }
    else if(modalRender?.type==='Document Details'){
        let files=uploadedFiles?.Document;
        let array=[];
        console.log('data12345',files)
            upload?.forEach((val)=>{
                if(files){
            array.push({...val,verify:files[`${val?.name}_HRVerified`], link:files[val?.name]?getLink(files[val?.name]):'', formate:files[val?.name]?sendLink(files[val?.name]):'',checked:files[val?.name]?true:false})
                }
                else{
                    array.push(val)
                }
            
        })
        if(files?.others){
            let obj=Object.entries(files?.others)
            for (const [key, value] of obj){
                array.push({label:key,name:key,link:getLink(value), formate:sendLink(value),checked:true,type:'other'})
            }
        }
    
        setDocList(array); 
    }
    else{
        let files=uploadedFiles?.Experience;
        let array=[];
        
            upload?.forEach((val)=>{
                if(files){
            array.push({...val,verify:files[`${val?.name}_HRVerified`],link:files[val?.name]?getLink(files[val?.name]):'', formate:files[val?.name]?sendLink(files[val?.name]):'',checked:files[val?.name]?true:false})
            }
            else{
                array.push(val)
            }
        })
        if(files?.others){
            let obj=Object.entries(files?.others)
            for (const [key, value] of obj){
                array.push({label:key,name:key,link:getLink(value), formate:sendLink(value),checked:true,type:'other'})
            }
        }
        setDocList(array); 
    }
},[upload])

// checking for all mandatroy file is uploaded or not to enabale save button
  useEffect(()=>{
    let flag=true;
    docList?.forEach(element => {
        if(element.mandatary){
            if(element.formate!==''){
                flag=flag & true;
            }else{
                flag=flag & false;
            }
        }
       }); 
       setSaveenable(flag)
  },[docList])

  const handleFileUpload = (event, index) => {
    console.log("1234567890", event.target.files,);
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    const allowedExtensions = ["png", "pdf", "jpeg", "jpg"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
        Notification.error("Error", "Only JPG, PNG and PDF files are allowed");
        return;
    }

    if (file.size > 10000000) {
      Notification.error("Error", "File should be under 10MB");
      return;
    }

    // Perform the upload logic here

    // Read the file as binary data
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryData = e.target.result;
      console.log("Binary Data:", binaryData);
    };
    reader.readAsArrayBuffer(file);
    if (other) {
      postUploadedDocuments({ ["other"]: file,["fileName"]:otherName }, (res) => {
        const s = {
          label: otherName,
          checked: true,
          mandatary: false,
          formate: sendLink(res?.other),
          link:res?.other,
          name: otherName,
          type:'other',
        //   size:res?.size,
        }

        let k = [...docList];
        k.push(s);
        setDocList(k);
        setOtherName('');
        // setOther(false);
      });
    } else {
      postUploadedDocuments({ [docList[index]?.name]: file }, (res) => {
        let u = [...docList];
        u[index].formate = sendLink(res?.[docList[index]?.name]);
        u[index].link=res?.[docList[index]?.name];
        u[index].checked = true;
        // u[index].size=res?.size ;
            setDocList(u)
        });
   
    }

  };
  
  const removeHandler =(index)=>{
    let u=[...docList]
 
    if(u[index].type==='other'){
        u.splice(index, 1);
  }
  else{
    u[index].formate='';
    u[index].link='';
    u[index].checked=false;
    u[index].verify=false;
  }
setDocList(u);
  }
  const verifyHandler=(index,status)=>{

    console.log('sanskar',index,status,docList[index])
    let u=[...docList]
    u[index].verify=status;
    setDocList(u);
  }
    return (
        <>
        
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ flexWrap: 'wrap',  }}>
                {
                docList?.map((item, index) => (
                    <>
                    <div key={index} style={{ display: 'flex', padding: '16px 0', width: '90%',height:"10%" ,justifyContent:'space-between' }}>
                        <div style={{ width: 'fit-content',display:'flex',alignItems:'center' }}>
                            <Checkbox checked={item?.checked} style={{marginRight:'10px'}}></Checkbox>
                            {item?.label}
                            {item?.mandatary ? <span style={{ color: '#FF3434', paddingLeft: '5px' }}>*</span> : null}
                        </div>
                        {item?.formate===''?<div>
                            <label htmlFor={`file-upload-${index}`}>
                                <img onClick={() => { setOther(false) }} src={uploadIcon} style={{ height: '22px', width: '22px', cursor: 'pointer' }} alt="Upload" />
                            </label>
                            <input
                                id={`file-upload-${index}`}
                                type="file"
                                accept=".png, .jpg, .jpeg, .pdf"
                                style={{ display: 'none' }}
                                onChange={(event) => {
                                    if(item.name==='photo'){
                                       let exe= event.target.files[0]?.name.split(".").pop().toLowerCase();
                                       if(!['jpg','png','jpeg']?.includes(exe)){
                                         Notification.error("Error","Please select jpg/png file")
                                         return;
                                       }
                                    }

                                    handleFileUpload(event, index)}}
                            />
                        </div>:''}
                        {(item?.formate!=='' && item?.showV===true && modalRender?.showV)?<>
                        <div style={{fontSize: 14,}}>
                        {
                           
                            <BsCheck2Circle onClick={()=>{
                                verifyHandler(index,!item.verify)
                            }} 
                                  style={{  fontSize: 25, marginLeft: 5, color: item?.verify? "#0DAC45" : "#ABABAB" }}
                                 />
                        }
                        
                    </div>
                            </>:''
                        }
                        
                    </div>
                   {item?.formate!==''?(
                     <div style={{width:"90%",display:'flex',alignItems:'center',justifyContent:'space-between', padding:'8px',background:"#dbf3ff",borderRadius:'10px'}}>
                        {/* {console.log("sssFormatttt",item?.formate.startsWith("https://"))} */}
                        <div  onClick={()=>{
                        if(item?.formate){
                            if(item?.formate.startsWith("https://")){
                                window.open(item?.formate, "_blank")?.focus();
                            }
                            else{
                                window.open(getLink(item?.formate), "_blank")?.focus();   
                            }
                        }
                     }} style={{display:"flex",alignItems:'center',gap:'1rem',marginTop:'-0.5rem',width:'80%',cursor:'pointer'}}>
                           <img src={documentIcon} style={{width:"2rem",height:"2rem",marginLeft:"10px"}} />
                            <Typography.Text style={{height:"20px",overflow:"hidden",width:'20vw'}} ellipsis={{tooltip:true}}> {item?.formate?.split("/")?.[item?.formate?.split("/").length-1]}</Typography.Text>

                        </div>
                        <DeleteOutlined onClick={()=>{removeHandler(index);}} style={{color:'red',fontSize:'18px'}} />
                         
                    
                    
                 </div>
                   ):''
                   }
                    </>
                ))}
                <div style={{ display: 'flex', flexDirection: 'column' ,paddingTop:'10px'}}>
                    <h7>Others:</h7>
                    <div >
                        <div style={{ display: 'flex', width: '90%',height:"10%" ,alignItems:'center',justifyContent:'space-between'}}>
                            <input
                            value={otherName}
                                onChange={(e) => {
                                    setOtherName(e.target.value)
                                }}
                                style={{ display: 'flex', width: '80%', borderWidth: 0.5, borderColor: 'black', marginTop: 10,}}></input>
                            <label htmlFor={`file-upload-other`}>
                                <img onClick={(e) => {
                                    if(otherName?.trim()?.length===0){
                                        document.getElementById(`file-upload-other`).disabled = true;
                                        Notification.error('Error!','Please enter name for document')
                                        e.stopPropagation();
                                        return false;
                                    }
                                    setOther(true)
                                }} src={uploadIcon} style={{ height: '22px', width: '22px', cursor: 'pointer',marginTop:'8px',}} alt="Upload" />
                            </label>
                            <input
                                id={`file-upload-other`}
                                type="file"
                                accept=".png, .jpg, .jpeg, .gif, .pdf"
                                style={{ display: 'none' }}
                                onChange={(event) => handleFileUpload(event)}
                                key={Math.random()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems:"center",width:"100%" }}>
                <Button
                      disabled={!saveEnable||viewClass?.hrAssociate}
                    style={{
                        backgroundColor: (!saveEnable||viewClass?.hrAssociate) ? 'gray' : '#0086FF',
                        color: 'white', marginTop: 30,marginBottom:10,
                    }}
                    onClick={()=>{
                        if(modalRender?.type==='Academics'){     
                            onSavePatch(null,docList,null,(res)=>{
                                onClose(res)
                            })
                        }else if(modalRender?.type==='Document Details'){
                            onSavePatch(docList,null,null,(res)=>{onClose(res)})
                        }else {
                            onSavePatch(null,null,docList,(res)=>{onClose(res)})
                        }
                    }}
                >
                    Save
                </Button>
            </div>
        </>
    );
}

export default UploadDocument;


