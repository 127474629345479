
import { create } from "zustand";

export const  useResourceStore=create(set=>({
    page:{curr:"employee",prev:'',next:'',PD:''},
    navData:["Basic Profile","Personal and Contact Data", "Documents", "Academics", "Work Experience", "Notice Period"],
    tab:"Basic Profile",
    otpModal:{flag:false,place:'initial'},
    otpVerified:localStorage.getItem('b1faff'),
    setOtpModal:(value)=>{
        set((state)=>({
            otpModal:value
        }))
    },
    setOtpVerified:(value)=>{
        set((state)=>({
            otpVerified:value
        }))
    },
    setTab:(value)=>{
        set((state)=>({
            tab:value
        }))
    },
    openPage:null,
    setOpenPage:(value)=>{
        set((state)=>({
            openPage:value,
        }))
    },
    editProfileData:{},
    ParsedData:{emeEmp:[], academic:[], workExp:[]},
    setEditProfileData:(value)=>{        
        set(state=>({
        editProfileData:value,
        ParsedData: { ...state.ParsedData, emeEmp: JSON.parse(state.editProfileData?.academics)},
        ParsedData: { ...state.ParsedData, academic: JSON.parse(state.editProfileData?.profile?.emergencyContact)},
        ParsedData: { ...state.ParsedData, workExp: JSON.parse(state.editProfileData?.workExperience?.experienced)}
    }))
    },
    setPage:(value)=>{
        set(state=>({
            page: value,
        }))
    }
}))