import React, { useEffect, useState } from "react";
import { Button, Input, Select, Tabs, Collapse, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";

import DownloadIcon from "../../../../assets/Appraisal/downloadBlackIcon.svg";
import SearchIcon from "../../../../assets/Appraisal/search icon.svg";
import Api from "../../../../services/Api";
import pluseIcon from "../../../../assets/Appraisal/pluse-circle.svg";
import EditIconBlue from "../../../../assets/Appraisal/editIconBlue.svg";
import dropIcon from "../../../../assets/Appraisal/downBlackIcon.svg";
import { Color } from "../../../../services/color";

// import { getDepartment, getMemberList } from "../actionsAdmin";
import { globalActions } from "../../store/loader";
import Department from "./Department/Department";
import SubTeam from "./Team/SubTeam";
import TeamModal from "./commonTeam-modal";
import "../styles/tableHeader.css";
import classes from "./team.module.css";
import SearchCustom from "../../components/SearchCustom";
import TabButtons from "../../components/TabButtons";
import ApplyFilter from "../../components/FilterModal/ApplyFilter";
import { adminActions } from "../store";
import CommonModal from "../commonModal";

const { Panel } = Collapse;

function Team() {
  const { cycleList, teamRefreshEffect } = useSelector((state) => state.admin);
  const [showEditModal, updateShowEditModal] = useState(false);
  const [TeamModalOpen, updateTeamModalOpen] = useState(false);
  const [departmentModalOpen, updateDepartmentModalOpen] = useState(false);
  const [operationsState, setOperaionsState] = useState("");
  const [teamData, setTeamData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [details, updateDetails] = useState();
  const [depDropDown, setDepDropDown] = useState([]);
  const [filterDepId, setFilterDepId] = useState();
  const [depList, setDepList] = useState([]);
  const [tabs, setTabs] = useState("department");
  const [teamNameModal, SetTeamNameModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeKey, setActiveKey] = useState(null);
  const [memberList, setMemberList] = useState([]);
  const [initialFilter, setInitialFilter] = useState({});
  const [filterCycleChanged, setFilterCycleChanged] = useState(false);
  const [teamId, setTeamId] = useState();
  const [sortedValue, setSortedValue] = useState();
  const [cycleFilter, setCycleFilter] = useState();
  const [filterVisible, setFilterVisible] = useState(false);
  const dispatch = useDispatch();

  const handleDepartmentFilter = (value) => {
    setFilterDepId(value);
    setSearchQuery("")
    getDepartment({ departmentId: value }, (res) =>
      setDepList(res?.mappedData)
    );
  };
  const handleSort = (value) => {
    value === sortedValue ? setSortedValue(undefined) : setSortedValue(value);
    getMemberList(
      {
        teamId: teamId,
        appraisalCycle: cycleFilter,
        sort: value === sortedValue ? "" : value,
      },
      (res) => {
        setMemberList(res);
      }
    );
  };

  const teamOperations = (operation) => {
    setOperaionsState(operation);
    updateTeamModalOpen(true);
  };

  const departmentOperations = (operation) => {
    setOperaionsState(operation);
    updateDepartmentModalOpen(true);
  };

  const getTableData = () => {
    setLoader(true);
    // dispatch(adminActions.updatePage({ page: 1, pageSize: pageSize }));
    dispatch(globalActions.updateLoader({ loader: true }));
    Api.get(`/appraisal-admin/teams`)
      .params({search: searchQuery})
      .send((response) => {
        if (typeof response !== "undefined") {
          setLoader(false);
          setTeamData(response);
          console.log("Team users", response);
        }
        dispatch(globalActions.updateLoader({ loader: false }));
      });
  };
  
  useEffect(() => {
    setLoader(true);
    // dispatch(adminActions.updatePage({ page: 1, pageSize: pageSize }));
    dispatch(globalActions.updateLoader({ loader: true }));
    Api.get(`/appraisal-admin/teams`)
      .params({search: searchQuery})
      .send((response) => {
        if (typeof response !== "undefined") {
          setLoader(false);
          setTeamData(response);
          console.log("Team users", response);
        }
        dispatch(globalActions.updateLoader({ loader: false }));
      });
    getDepartment(
      {
        departmentId: filterDepId,
        search: searchQuery,
      },
      (res) => {
        setDepDropDown(res?.allDepartments);
        setDepList(res?.mappedData);
      }
    );
  }, [searchQuery, teamRefreshEffect]);

  const getDepartment = (params, callback) => {
    setLoader(true);
    console.log("Edited work summary", params);
    Api.get(
      `/appraisal-admin/getDepartments`)
      .params({
        departmentId: params.departmentId ? params.departmentId : "",
        search: params.search ? params.search : "",
        downloadTeam: params.downloadTeam ? params.downloadTeam : ""
      })
      .send((response, error) => {
        if (!params.downloadTeam) {
          if (response) {
            // console.log("depart", response);
            callback(response);
          }
        } else {
          window.open(response, "_blank")?.focus();
        }
        setLoader(false);
      });
  };

  const getRankAndFeedback = (params) => {
    Api.get(
      `/appraisal-admin/downloadRank?departmentId=${
        params?.departmentId ? params?.departmentId : ""
      }&downloadTeam=${
        params?.downloadTeam ? params?.downloadTeam : ""
      }`
    )
      .params({search: params?.search ? params?.search : ""})
      .send((response, error) => {
        if (!params.downloadTeam) {
          if (response) {
            console.log("depart", response);
            // callback(response);
          }
        } else {
          window.open(response, "_blank")?.focus();
        }
        setLoader(false);
      });
  };

  const getMemberList = (params, callback) => {
    setLoader(true);
    Api.get(
      `/appraisal-admin/memberTeamData?teamId=${
        params.teamId ? params.teamId : ""
      }&sort=${
        params.sort ? params.sort : ""
      }&appraisalCycle=${params.appraisalCycle ? params.appraisalCycle : ""}`
    )
      .params({search: params.search ? params.search : ""})
      .send((response, error) => {
        if (response) {
          // console.log("member", response);
          callback(response);
        }
        setLoader(false);
      });
  };

  // // department function

  // const sideItems = (
  //   <>
  //     {tabs === "teams" ? (
  //       <div
  //         style={{
  //           display: "flex",
  //           width: "35rem",
  //           justifyContent: "flex-end",
  //           alignItems: "center",
  //         }}
  //       >
  //         <Input
  //           style={{
  //             width: "15rem",
  //             height: "2.2rem",
  //             marginRight: "2rem",
  //           }}
  //           placeholder="Search"
  //           allowClear={true}
  //           onChange={(e) => {
  //             console.log("Search Value", e.target.value);
  //             setTimeout(() => {
  //               setSearchQuery(e.target.value);
  //             }, 1000);
  //           }}
  //           prefix={
  //             <img
  //               style={{ width: "1.5rem", height: "1.5rem" }}
  //               src={SearchIcon}
  //             />
  //           }
  //         />

  //         <div
  //           style={{
  //             width: "7rem",
  //             fontSize: "1rem",
  //             fontWeight: "bold",
  //             cursor: "pointer",
  //             display: "flex",
  //             alignItems: "center",
  //             justifyContent: "space-around",
  //           }}
  //           onClick={() => {
  //             if (teamData.length > 0) {
  //               getDepartment({
  //                 departmentId: filterDepId,
  //                 search: searchQuery,
  //                 downloadTeam: true,
  //               });
  //             } else {
  //               console.log("No data to download");
  //             }
  //           }}
  //         >
  //           Download <img src={DownloadIcon} />
  //         </div>
  //       </div>
  //     ) : (
  //       <div
  //         style={{
  //           display: "flex",
  //           width: "35rem",
  //           justifyContent: "space-between",
  //           alignItems: "center",
  //         }}
  //       >
  //         <div>
  //           <Select
  //             style={{ width: 200, textAlign: "center" }}
  //             placeholder="Select Department"
  //             onChange={handleDepartmentFilter}
  //             suffixIcon={
  //               <img
  //                 src={dropIcon}
  //                 style={{
  //                   color: "#999999",
  //                 }}
  //               />
  //             }
  //             allowClear
  //           >
  //             <Select.Option value="">All Department</Select.Option>
  //             {depDropDown &&
  //               Object.values(depDropDown).map((item) => (
  //                 <Select.Option
  //                   key={item?.id}
  //                   value={item?.id}
  //                   label={item?.name}
  //                   title={item?.name}
  //                 >
  //                   {item?.name}
  //                 </Select.Option>
  //               ))}
  //           </Select>
  //         </div>
  //         <Input
  //           style={{
  //             width: "15rem",
  //             height: "2.2rem",
  //           }}
  //           placeholder="Search"
  //           allowClear={true}
  //           onChange={(e) => {
  //             console.log("Search Value", e.target.value);
  //             setSearchQuery(e.target.value);
  //             setTimeout(() => {
  //               getDepartment({ search: e.target.value }, (res) =>
  //                 setDepList(res?.mappedData)
  //               );
  //             }, 1000);
  //           }}
  //           prefix={
  //             <img
  //               style={{ width: "1.5rem", height: "1.5rem" }}
  //               src={SearchIcon}
  //             />
  //           }
  //         />

  //         <div
  //           style={{ fontSize: "1rem", fontWeight: "bold", cursor: "pointer" }}
  //           onClick={() => {
  //             if (depList.length > 0) {
  //               getDepartment({
  //                 departmentId: filterDepId,
  //                 search: searchQuery,
  //                 downloadTeam: true,
  //               });
  //             } else {
  //               console.log("No data to download");
  //             }
  //           }}
  //         >
  //           Download <img src={DownloadIcon} />
  //         </div>
  //       </div>
  //     )}
  //   </>
  // );

  const tabItems = [
    {
      title: "Department",
      key: "department",
      active: tabs === "department",
      component: (
        <Department
          setActiveKey={setActiveKey}
          activeKey={activeKey}
          updateShowEditModal={updateShowEditModal}
          updateDetails={updateDetails}
          depList={depList}
          loader={loader}
        />
      ),
    },
    {
      title: "Teams",
      key: "teams",
      active: tabs === "teams",
      component: (
        <SubTeam
          setActiveKey={setActiveKey}
          activeKey={activeKey}
          setTeamId={setTeamId}
          setMemberList={setMemberList}
          memberList={memberList}
          sortedValue={sortedValue}
          handleSort={handleSort}
          cycleFilter={cycleFilter}
          filterCycleChanged={filterCycleChanged}
          setFilterCycleChanged={setFilterCycleChanged}
          setCycleFilter={setCycleFilter}
          teamId={teamId}
          initialFilter={initialFilter}
          cycleList={cycleList}
          teamData={teamData}
          loader={loader}
        />
      ),
    },
  ];
  // const items = [
  //   {
  //     key: "department",
  //     label: (
  //       <div
  //         style={{
  //           fontWeight: "bold",
  //           fontSize: "1.1rem",
  //         }}
  //       >
  //         Department
  //       </div>
  //     ),
  //     children: (
  //       <Department
  //         setActiveKey={setActiveKey}
  //         activeKey={activeKey}
  //         updateShowEditModal={updateShowEditModal}
  //         updateDetails={updateDetails}
  //         depList={depList}
  //         loader={loader}
  //       />
  //     ),
  //   },
  //   {
  //     key: "teams",
  //     label: (
  //       <div
  //         style={{
  //           fontWeight: "bold",
  //           fontSize: "1.1rem",
  //           // color: isActive === "active users" ? "#162432" : "#A1A1A1",
  //         }}
  //       >
  //         Teams
  //       </div>
  //     ),
  //     children: (
  //       <SubTeam
  //         setActiveKey={setActiveKey}
  //         activeKey={activeKey}
  //         setTeamId={setTeamId}
  //         setMemberList={setMemberList}
  //         memberList={memberList}
  //         sortedValue={sortedValue}
  //         handleSort={handleSort}
  //         cycleFilter={cycleFilter}
  //         filterCycleChanged={filterCycleChanged}
  //         setFilterCycleChanged={setFilterCycleChanged}
  //         setCycleFilter={setCycleFilter}
  //         teamId={teamId}
  //         initialFilter={initialFilter}
  //         cycleList={cycleList}
  //         teamData={teamData}
  //         loader={loader}
  //       />
  //     ),
  //   },
  // ];

  return (
    <div className={classes.container}>
      <div className={classes.titleBlock}>
        <h2 className={classes.title}>Team</h2>

        <div
          style={{
            // border:'1px solid black',
            width: "60%",
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <SearchCustom
            style={{ height: "2.4rem" }}
            placeholder="Search by name"
            setValue={setSearchQuery}
            value={searchQuery}
            onChange={(e) => {
              console.log("Search Value", e);
              setSearchQuery(e);
              setTimeout(() => {
                getDepartment({ search: e }, (res) =>
                  setDepList(res?.mappedData)
                );
              }, 1000);
            }}
          />
          {tabs === "teams" && (
            <div style={{ display: "flex", gap: "1rem" }}>
              <Button
                type="secondary"
                style={{
                  borderRadius: "4px",
                  border: `1px solid ${Color.modalblue}`,
                  display: "flex",
                  alignItems: "center",
                  height:"2.4rem",
                }}
                onClick={() => {
                  teamOperations("Edit");
                }}
              >
                <div
                  style={{
                    height: "1.5rem",
                    width: "1.5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    
                  }}
                >
                  <img src={EditIconBlue} style={{ height: "0.7rem" }} />
                </div>
                <h4 style={{ color: Color.modalblue }}>Edit Team</h4>
              </Button>
              <Button
                type="primary"
                style={{ borderRadius: "4px" ,height:"2.4rem"}}
                onClick={() => {
                  teamOperations("Add");
                }}
              >
                <img
                  src={pluseIcon}
                  style={{ marginRight: "4px", height: "0.8rem" }}
                />{" "}
                Add Team
              </Button>
            </div>
          )}

          {tabs === "department" && (
            <div style={{ display: "flex", gap: "1rem" }}>
              <Button
                type="secondary"
                style={{
                  borderRadius: "4px",
                  border: `1px solid ${Color.modalblue}`,
                  display: "flex",
                  alignItems: "center",
                  height:"2.4rem"
                }}
                onClick={() => {
                  departmentOperations("Edit");
                }}
              >
                <div
                  style={{
                    height: "1.5rem",
                    width: "1.5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={EditIconBlue} style={{ height: "0.7rem" }} />
                </div>
                <h4 style={{ color: Color.modalblue }}>Edit Department</h4>
              </Button>
              <Button
                type="primary"
                style={{ borderRadius: "4px",height:"2.4rem" }}
                onClick={() => {
                  departmentOperations("Add");
                }}
              >
                <img
                  src={pluseIcon}
                  style={{ marginRight: "4px", height: "0.8rem" }}
                />{" "}
                Add Department
              </Button>
            </div>
          )}
        </div>
      </div>

      <div style={{ width: "100%", height: "78vh", marginTop: "1rem" }}>
        <div
          style={{
            // border: "1px solid black",
            width: "100%",
            height: "6vh",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div className={classes.tab_Container}>
            {tabItems.map((item) => (
              <TabButtons
                title={item?.title}
                onClick={() => {
                  // console.log("Tab data changed",item.key)
                  setTabs(item?.key);
                }}
                active={item.active}
              />
            ))}
          </div>
          <div className={classes.action_container}>
            {tabs !== "teams" ? (
              <Select
                style={{ width: 200, textAlign: "center" }}
                placeholder="Select Department"
                onChange={handleDepartmentFilter}
                suffixIcon={
                  <img
                    src={dropIcon}
                    style={{
                      color: "#999999",
                    }}
                  />
                }
                allowClear
              >
                <Select.Option value="">All Department</Select.Option>
                {depDropDown &&
                  Object.values(depDropDown).map((item) => (
                    <Select.Option
                      key={item?.id}
                      value={item?.id}
                      label={item?.name}
                      title={item?.name}
                    >
                      {item?.name}
                    </Select.Option>
                  ))}
              </Select>
            ) : (
              <></>
            )}
            <Button
              style={{
                border: "none",
                background: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "none",
              }}
              onClick={() => {
                if (teamData.length > 0) {
                  dispatch(adminActions.updateDesignationModal(true));
                  dispatch(adminActions.updateModalType("teamDataDownload"));
                  // getDepartment({
                  //   departmentId: filterDepId,
                  //   search: searchQuery,
                  //   downloadTeam: true,
                  // });
                } else {
                  console.log("No data to download");
                }
              }}
            >
              <span style={{ color: "#0086FF" }}>Download</span>
              <img
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  marginLeft: "0.8rem",
                  marginBottom: "0.2rem",
                }}
                src={DownloadIcon}
                alt=""
              />
            </Button>
          </div>
        </div>
        <div
          style={{
            // border: "1px solid black",
            width: "100%",
            height: "70vh",
            marginTop: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loader ? (
            <Spin />
          ) : (
            <div style={{ width: "100%", height: "100%" }}>
              {tabs === "department" && (
                <Department
                  setActiveKey={setActiveKey}
                  activeKey={activeKey}
                  updateShowEditModal={updateShowEditModal}
                  updateDetails={updateDetails}
                  depList={depList}
                  loader={loader}
                />
              )}
              {tabs === "teams" && (
                <SubTeam
                  setActiveKey={setActiveKey}
                  activeKey={activeKey}
                  setTeamId={setTeamId}
                  setMemberList={setMemberList}
                  memberList={memberList}
                  sortedValue={sortedValue}
                  handleSort={handleSort}
                  cycleFilter={cycleFilter}
                  filterCycleChanged={filterCycleChanged}
                  setFilterCycleChanged={setFilterCycleChanged}
                  setCycleFilter={setCycleFilter}
                  teamId={teamId}
                  initialFilter={initialFilter}
                  cycleList={cycleList}
                  teamData={teamData}
                  loader={loader}
                />
              )}
            </div>
          )}
          {/* <UserTable /> */}
        </div>
      </div>
      {showEditModal && (
        <TeamModal
          refresh={getTableData.bind(this)}
          showEditModal={showEditModal}
          updateShowEditModal={updateShowEditModal}
          data={details}
          data2={depDropDown}
        />
      )}
      {departmentModalOpen && (
        <TeamModal
          departmentModalOpen={departmentModalOpen}
          updateDepartmentModalOpen={updateDepartmentModalOpen}
          operationState={operationsState}
          dataDrop={depDropDown}
        />
      )}
      {TeamModalOpen && (
        <TeamModal
          refresh={getTableData.bind(this)}
          TeamModalOpen={TeamModalOpen}
          updateTeamModalOpen={updateTeamModalOpen}
          dataDrop={depDropDown}
          operationState={operationsState}
        />
      )}
      <CommonModal
        callBackTODownload={(option) => {
          console.log("Select Options", option);
          if (option === "Download rank and feedback") {
            getRankAndFeedback({
              departmentId: filterDepId,
              search: searchQuery,
              downloadTeam: true,
            });
          }
          if (option === "Download team data") {
            getDepartment({
              departmentId: filterDepId,
              search: searchQuery,
              downloadTeam: true,
            });
          }
        }}
      />
      <ApplyFilter visible={filterVisible} setVisible={setFilterVisible} />
    </div>
  );
}

// export default withRouter(Team);
export default Team;
