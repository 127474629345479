

import ModalCompo from "../../../design/ModalCompo";
import classes from './jobSearch.module.css';
import SuccessIcom from "../../../assets/SuccessICon.svg";
import ButtonCompo from "../../../design/ButonCompo";
import { useNavigate } from "react-router";
import { ProfileStore } from "../Profile/store";
import { useLoginStore } from "../store";

function JobSuccessPopup({modal,setModal}) {
   const {setActiveTab,setClickedJob}=useLoginStore()
const Navigate=useNavigate();
   const {setCurrentPage}=ProfileStore();
    return (
    <div style={{justifyContent:'center',alignItems:'center',}}>
        <ModalCompo
        open={modal}
        // onCancel={() => setModal(false)}
        
        className={classes.SuccessPopup}
        >
        
            <div style={{backgroundColor:'white',padding:40,justifyContent:'center',alignItems:'center'}}>
                <div>
                    <img src={SuccessIcom} />
                </div>
            <div style={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center', }}>
                <div style={{fontSize:24,color:'#161616',marginBottom:16}}>Success!</div>
                <div style={{fontSize:14,color:'3d3d3d',marginTop:'8px'}}>Your application has been submitted.</div>
                <div style={{fontSize:14,color:'3d3d3d',marginTop:'8px'}}>We’ll be in contact shortly.</div>
             
             <ButtonCompo onClick={()=>{
                     setModal(false);
                        Navigate('/login');
                        
                        // setActiveTab(1);
                        // setTimeout(()=>{
                            setActiveTab(3);
                            setClickedJob();
                        // },10)
                }} title={"Go back to job search"} style={{color:'white',backgroundColor:'#0086ff',marginTop:'24px'}} />
                
            </div>
            </div>
        </ModalCompo>
    </div>
     ); 
}

export default JobSuccessPopup;

