import React, {forwardRef, useState, useRef,useEffect,memo} from "react";
import  Utils  from "../../services/utils";

const VideoView = forwardRef((props, ref) => {
    const videoRef = useRef(null);

    useEffect(() => {
        console.log("props.streams",props?.stream);
      if (videoRef.current && props.stream) {
        videoRef.current.srcObject = props.stream;
      }
    }, [props?.stream]);
    let width = props.style?.width?props.style?.width: 200;
    let height = props.style?.height?props.style?.height: 150
    let videoFocused = useRef(false)
    const [translate, setTranslate] = useState({
        x: props.style && props.style.left?props.style.left:0,
        y: props.style && props.style.top?props.style.top:0
      });

    // const onPointerDown = (e) => {
    //     videoFocused.current = true
    // }
    // const onPointerUp = (e) => {
    //     videoFocused.current = false
    // }
    // const onPointerMove = (e) => {
    //     if (videoFocused.current){
    //         setTranslate({
    //             x: Utils.clamp(translate.x + e.movementX, 0, window.innerWidth-width),
    //             y: Utils.clamp(translate.y + e.movementY, -window.innerHeight/2+height/2, window.innerHeight/2-height/2)
    //           });
    //     }
    // }
    const onMouseLeave = () => {
        videoFocused.current = false
    }
    return (
        <video ref={videoRef} autoPlay playsInline style={{borderRadius:10, width: width, height:height, position:"absolute", transform:props?.type=="videoType"? "scaleX(-1)":"scaleX(1)", border:'0px solid grey',
        visibility:props.visible!=undefined?props.visible?"visible":"hidden":"visible", objectFit:props?.objectFit || "cover"
        }} 
        // onPointerDown={onPointerDown} 
        // onPointerUp={onPointerUp} 
        // onPointerMove={onPointerMove}
         onMouseLeave={onMouseLeave}></video>
    )
})

export default memo (VideoView);