// import Api from "../../../../services/Api";
import Api from '../../services/Api'

export const getNotification = (param,callback) => {
  Api.get(`/project-notifications/getNotification`).params(param)
  .send((response) => {
    if (response) {
      callback(response);
    }
  });
};


export const request = (status,msg,title,page,pageSize,startDate,endDate,callback,id,download,downCall=null) => {
    let url = `/project-notifications/requests?userId=${id}`;
    if(download){
      url+= `&download=${true}`
    }
    if (status) {
      url += `&status=${status}`;
    }
    if(title){
        url +=`&searchTitle=${title}`
    }if(msg){
        url +=`&searchMessage=${msg}`
    }
    if(page){
      url+=`&page=${page}`;
    }
    if(pageSize){
      url+=`&limit=${pageSize}`
    }
    if(startDate){
      url+=`&startDate=${startDate}`
    }
    if(endDate){
      url+=`&endDate=${endDate}`
    }
    Api.get(url)
    .send((response) => {
      
      if (response) {
        if(download){
          downCall();
          window.open(response, "_blank")?.focus();
        }else{
          callback(response);
        }
      }
    });
  };

  export const manualPunchs = (params,callback) => {
    Api.patch(`/project-notifications/requestAction`)
    .params(params)
    .send((response) => {
      if (response) {
        callback(response);
      }
    });
  };