import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { SECRET_KEY, decryptData } from "../../services/fileCode";

import Api from "../../services/Api";
import { authActions } from "./login/store";
import { useDispatch } from "react-redux";

const AppraisalPrivateRoute = ({ role, children, ...rest }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userData = localStorage.getItem("AppraisalUserData", SECRET_KEY);

  const token = localStorage.getItem("token", SECRET_KEY); //Added for temporary

  let decrypteduserData = {};
  if (userData) {
    decrypteduserData = JSON.parse(decryptData(userData, SECRET_KEY));
  }
  dispatch(authActions.updateUser({ user: decrypteduserData }));
  if (token) { //Added for temporary
    if (decrypteduserData?.role?.includes(role)) {
      return children;
    }
  }//Added for temporary

  return <Navigate to="/appraisal/associate" />;
};

export default AppraisalPrivateRoute;
