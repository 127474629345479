import React, { useState, useEffect } from "react";
import { FaMicrophone } from "react-icons/fa";
import moment from "moment";
import conferenceStore from "./store";
import { ImageHandler } from "./conferenceRoom";
import { IoMicOff } from "react-icons/io5";
import CloseIcon from "../../assets/closeIcon.svg"
const Participates = ({ setpeople,micArr,setMicArr,micListed }) => {
    const { userG, activeUserList,activeMicList, isMicOn, } = conferenceStore(state => state);
   console.log("activeList",activeMicList);
    const [joinedTime, setTime] = useState(moment());
  
    
 let items=activeUserList.filter(item =>item?.id === userG?.id)[0].joined

    return (
        <>
            <div style={{
                height: "100%",
                width: '20vw',
                position: "relative",
                backgroundColor: "#2D2D2D",
                borderRadius: 5,
                overflowY: 'auto',
                zIndex:3
            }}>
                                    <div style={{display:"flex",alignItems:"flex-end",justifyContent:"flex-end",width:"100%",marginTop:0,padding:8,height:30,cursor:"pointer"}} onClick={() =>setpeople(false)}><img src={CloseIcon} style={{height:14,width:14}}/></div>
                {/* <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", padding: 15 }}>
                    <div style={{ display: "flex" }}>
                        <div style={{ height: 25, width: 25, borderRadius: 15, backgroundColor: "grey" }}></div>
                        <div style={{ marginLeft: 5 }}>
                            <div style={{ fontSize: 12, color: "#FFFFFF" }}>You</div>
                            <div style={{ fontSize: 8, color: "#959595" }}>{joinedTime.format('HH:mm:ss A')}</div>
                        </div>
                    </div>
                    <div>
                        <FaMicrophone style={{ height: 15, width: 15, color: "white" }} />
                    </div>
                </div> */}
                  <div key={items.id} style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", padding: 15 }}>
                            <div style={{ display: "flex" }}>

                                {/* <img style={{ height: 25, width: 25, borderRadius: 15 }} src={item.image} alt={item.name.charAt(0)} /> */}
                                <ImageHandler image={activeUserList.filter(item =>item?.id === userG?.id)[0]?.profileImage} name={activeUserList.filter(item =>item?.id === userG?.id)[0]?.name} size={"small"} />

                                <div style={{ marginLeft: 5, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    <div style={{ fontSize: 11, color: "#FFFFFF" }}>You</div>
                                     <div style={{ fontSize: 10, color: "#959595" }}>
                                    Joined {Math.abs(moment(moment(activeUserList.filter(item =>item?.id === userG?.id)[0].joined, "HH:mm:ss A"),"minutes").diff(moment(),"minutes"))>=60?`${Math.floor((Math.abs(moment(moment(activeUserList.filter(item =>item?.id === userG?.id)[0].joined, "HH:mm:ss A"),"minutes").diff(moment(),"minutes"))/60))} hours and ${Math.abs(moment(moment(activeUserList.filter(item =>item?.id === userG?.id)[0].joined, "HH:mm:ss A"),"minutes").diff(moment(),"minutes"))%60} minute(s) ago`:`${Math.abs(moment(moment(activeUserList.filter(item =>item?.id === userG?.id)[0].joined, "HH:mm:ss A"),"minutes").diff(moment(),"minutes"))} minutes ago`}
                                        {/* {moment.duration(moment().diff(moment(item?.joined))).asMinutes() <= 1 ? "Joined just now" : `${moment.duration(moment().diff(moment(item?.joined))).asMinutes()} minutes ago`} */}
                                    </div>
                                </div>
                            </div>
                            <div>
                           
                                { 
                                    ( isMicOn)?
                                        <FaMicrophone style={{ height: 15, width: 15, color: "white" }} />
                                        :
                                        <IoMicOff style={{ height: 20, width: 20, color: "white" }} />
                                }
                            </div>
                        </div>
                {activeUserList?.length ?
                  activeUserList.filter(item =>item?.id !== userG?.id).
                    map((item) => (
                        <div key={item.id} style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", padding: 15 }}>
                            <div style={{ display: "flex" }}>

                                {/* <img style={{ height: 25, width: 25, borderRadius: 15 }} src={item.image} alt={item.name.charAt(0)} /> */}
                                <ImageHandler image={item.profileImage} name={item.name} size={"small"} />

                                <div style={{ marginLeft: 5, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    <div style={{ fontSize: 11, color: "#FFFFFF" }}>{(item?.email) ? item.name : "You"}</div>
                                     <div style={{ fontSize: 10, color: "#959595" }}>
                                    Joined {Math.abs(moment(moment(item?.joined, "HH:mm:ss A"),"minutes").diff(moment(),"minutes"))>=60?`${Math.floor((Math.abs(moment(moment(item?.joined, "HH:mm:ss A"),"minutes").diff(moment(),"minutes"))/60))} hours and ${Math.abs(moment(moment(item?.joined, "HH:mm:ss A"),"minutes").diff(moment(),"minutes"))%60} minute(s) ago`:`${Math.abs(moment(moment(item?.joined, "HH:mm:ss A"),"minutes").diff(moment(),"minutes"))} minutes ago`}
                                        {/* {moment.duration(moment().diff(moment(item?.joined))).asMinutes() <= 1 ? "Joined just now" : `${moment.duration(moment().diff(moment(item?.joined))).asMinutes()} minutes ago`} */}
                                    </div>
                                </div>
                            </div>
                            <div>
                           
                                { 
                                    ( activeMicList[item?.id])?
                                        <FaMicrophone style={{ height: 15, width: 15, color: "white" }} />
                                        :
                                        <IoMicOff style={{ height: 20, width: 20, color: "white" }} />
                                }
                            </div>
                        </div>
                    )) : null
                }
            </div>
        </>
    )
}

export default Participates;
