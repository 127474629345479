import React, { useState,useEffect } from 'react';
import classes from "./style.module.css";
import Logo from '../../assets/loginLogo.svg';
import setImage from "../../assets/setPassword.png";
import PasswordIcon from "../../assets/Password.png";
import login_brigo_text from '../../assets/login_brigo_text.svg';
import   our_journey_img from '../../assets/our-journey-img.svg';
import circular_check_grey from "../../assets/circular_check_grey.svg";
import circular_check_green from "../../assets/circular_check_green.svg";
import Union from "../../assets/Union.svg";
import Image from "../../assets/reset password 1.svg";
import Circle from "../../assets/Circle.svg";
import error_red_icon from "../../assets/error_red_icon.svg";
import Union1 from "../../assets/Union 1.svg";
import Union2 from "../../assets/Union 2.svg";
import { Card, Form, Input,Button,Row,Col, Spin } from 'antd';
import { ArrowRightOutlined,MinusOutlined,EyeOutlined,EyeInvisibleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Api from "../../services/Api";
import useStore from '../../pages/store';
import Notification from '../../services/Notification';
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";
import { useLoginStore } from './store';
import { Header, validatePassword } from './login';
import Feed from './Feed/Feed';
import JobSearch from './JobSearch/jobSearch';
import UpdatePassSuccessModal from './SuccessModal';

const Page8=({setCurPage,
  confirmHandler,setPassword,password,
  confirmPassword,setConfirmPass

})=>{
  const [form]=Form.useForm();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
 

  const handleFormChange = () => {
    const formValues = form.getFieldsValue();
    const isDisabled = Object.values(formValues).some(value => !value);
    setIsSubmitDisabled(isDisabled);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };



  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const validateAll=()=>{
    return(!password?.length>=8||!/[a-z]/.test(password)||!/[A-Z]/.test(password)||!/\d/.test(password)||!/[!@#$%^&*(),.?":{}|<>]/.test(password))
  }

  

  console.log("isSubmitDisabled",isSubmitDisabled)
  return(
    <div className={classes.page2}>
      <span>Reset password</span>
      <span>Reset your password to reclaim your account</span>

      <Form layout='vertical' 
       className={classes.page2Form}
      >
                <Form.Item label="New password"
                    name="firstpassword"
                    style={{marginBottom:"0",paddingBottom:"0"}}
                  //   rules={[
                  //     { required: true, message: 'Please enter your password!' },
                  //   { min: 8, message: 'Password must be at least 8 characters long!' }]}
                  //  style={{marginTop:"0.5rem"}}
                  >
             
                    
                    <Input.Password  placeholder='Password'
                      onChange={(e)=>{setPassword(e.target.value)}}
                      iconRender={(visible) =>
                        visible ? (
                          <EyeOutlined onClick={togglePasswordVisibility} />
                        ) : (
                          <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
                        )
                      }
                    />
              
                </Form.Item>
                {/* {password&&password?.length<8&&<span style={{margin:"0.2rem 0 0 0",fontSize:"0.8rem",display:"flex",alignItems:"center",color:"#FC5C65",gap:"0.4rem"}}><img src={error_red_icon} style={{width:"0.8rem",height:"0.8rem"}}/>Password should be at least 8 characters long</span>} */}
                {/* {password&&!validatePassword(password)&&<span style={{margin:"0.2rem 0 0 0",fontSize:"0.8rem",display:"flex",color:"#FC5C65",gap:"0.4rem"}}><img src={error_red_icon} style={{width:"0.8rem",height:"0.8rem"}}/>Password should be at least 8 characters long, include at least one capital letter, one smaller letter, one special character and one decimal digit</span>} */}
                <div className={classes.passReq}>
          <span>Password Requirements:</span>
          <div >
            <img src={password?.length>=8?circular_check_green:circular_check_grey}/>
            <span><span>Must</span> contain 8 or more characters</span>
          </div>
          <div >
            <img src={(/[a-z]/.test(password))?circular_check_green:circular_check_grey}/>
            <span><span>Must </span> contain at least one lowercase letter</span>
          </div>
          <div >
            <img src={(/[A-Z]/.test(password))?circular_check_green:circular_check_grey}/>
            <span><span>Must </span> contain at least one uppercase letter</span>
          </div>
          <div >
            <img src={(/\d/.test(password))?circular_check_green:circular_check_grey}/>
            <span><span>Must </span> contain at least one number</span>
          </div>
          <div >
            <img src={(/[!@#$%^&*(),.?":{}|<>]/.test(password))?circular_check_green:circular_check_grey}/>
            <span><span>Must </span> contain special characters (@ ! # $ % ^ & *)</span>
          </div>

        </div>

                <Form.Item label="Re-enter new password"
                    name="confirmPassword"
                    style={{marginTop:"1rem"}}
                  //   rules={[
                  //     { required: true, message: 'Please enter your password!' },
                  //   { min: 8, message: 'Password must be at least 8 characters long!' }
                  // ]}
                  >
                {/* <div className={classes.inputDesign2}>
                    <div>
                      <img src={PasswordIcon}></img>
                    </div> */}
                   
                    <Input.Password placeholder='Confirm Password'
                      onChange={(e)=>{setConfirmPass(e.target.value)}}
                      iconRender={(visible) =>
                        visible ? (
                          <EyeOutlined onClick={toggleConfirmPasswordVisibility} />
                        ) : (
                          <EyeInvisibleOutlined onClick={toggleConfirmPasswordVisibility} />
                        )
                      }
                    />
                {/* </div> */}
                </Form.Item>

                <div className={classes.page2button} >
                      <button htmlType="submit"
                      onClick={confirmHandler}
                      disabled={validateAll()||confirmPassword.length<8}>Submit</button>
                </div>

                {/* <Row justify="center" className={classes.setButton}>
                <Form.Item>
                  <Button type="primary" htmlType='submit' onClick={confirmHandler}
                  disabled={password.length >=8 && confirmPassword.length >= 8 ? false : true}>
                      Confirm 
                  </Button>
                  </Form.Item>
                </Row> */}
              </Form>


      {/* <Form layout='vertical' form={form}
      className={classes.page2Form}
      onFieldsChange={
        handleFormChange
      }
      onFinish={(event)=>{
        console.log("Page 2 Form event",event);
        setCurPage(5);
      }}
      >
        <Form.Item name="password" label={<span>Password</span>} >
           <Input.Password type='password' showPassword placeholder='Enter password' style={{height:"4.4vmin"}}
            iconRender={(visible) =>
                                      visible ? (
                                        <EyeOutlined onClick={togglePasswordVisibility} style={{marginTop:"0.2rem",}}/>
                                      ) : (
                                        <EyeInvisibleOutlined onClick={togglePasswordVisibility} style={{marginTop:"0.2rem",}}/>
                                       )
                                    }
           />
        </Form.Item>

        <Form.Item name="confirmedPassword" label={<span>Re-enter password</span>} >
           <Input.Password type='password' placeholder='Enter password' style={{height:"4.4vmin"}}/>
        </Form.Item>    
          <div className={classes.page2button} >
        <button htmlType="submit" disabled={isSubmitDisabled}>Next</button>
        </div>
      </Form>   */}
    </div>
  )
}

const SetNewPassword=()=>{

  const navigate = useNavigate();
  const [password,setPassword]=useState("")
  const [confirmPassword,setConfirmPass]=useState("");
  const {newUser,user}=useStore(state=>state);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loader,setLoader] = useState(false);
  const {width}=useWindowDimensions();
  const url = new URL(window.location.href);
  const userId = url.searchParams.get("userId");
  const token = url.searchParams.get("token");
  const timestamp = url.searchParams.get("timestamp"); 
  const [successModal,setSuccessModal]=useState(false);
   const {curPage, setCurPage,setActiveTab}=useLoginStore();
  const [validateUser,setValidateUser]=useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };



  const confirmHandler =(values)=>{
    if(confirmPassword !== password) {
      return Notification.error('Error', 'Password and Confirm Password should be the same!');
    }    
    let paramsVal;
    // if(newUser === true){
    //   paramsVal ={userId: parseInt(user?.id), password:password, passwordToken: "new",}
    // }else{
    //   paramsVal ={userId: parseInt(userId), password:password, passwordToken: token}
    // }
    // Api.patch("/resource-auth/updatepassword")
    //    .params(paramsVal)
    //    .send((res)=>{
    //     console.log(res);
    //     if(res?.show.type === 'success'){
    //       if(res?.show.message==="Check your mail for verification"){
    //         // navigate("/checkMail")
    //         navigate("/passwordReset")
    //       }
    //       else if(res?.show.message==="Password updated successfully"){
    //         navigate("/passwordReset")
    //       }
    //     }
    //    });
    if(token === "new"){
      paramsVal ={userId: parseInt(userId), password:password, mobileToken: "new",newUserValidation:true}
    }else{
      paramsVal ={userId: parseInt(userId), password:password, mobileToken: token}
    }
    Api.patch("/auth-auth/updatepassword")
       .params(paramsVal)
       .send((res)=>{
        console.log(res);
        if(res?.show.type === 'success'){
          setSuccessModal(true);
        }
       });
  }

  useEffect(()=>{
    // setLoader(true)
    if(token && timestamp){
      setLoader(true)
    setTimeout(() => {

      Api.get(`/auth-auth/confirmEmail?userId=${userId}&timestamp=${timestamp}&token=${token}`)
         .send((response)=>{
          setValidateUser(response?.show);
          setLoader(false)
         })
    }, 1000);
  }
  },[])

  return (<>
   
    {/* <div className={classes.container}>
    <div>
      <img src={Union1} alt='' style={{height:width<700&&"6rem",width:width<700&&"6rem"}}/>
    </div>
    <div>
      <img src={Logo} alt='' style={{height:width>700?"4rem":"4.5rem",width:"4rem"}}/>
    </div>
    <div className={classes.contents2}>
      <div ></div>
    </div>
    <div className={classes.contents}>
      <div className={classes.overlapContent}>
      <div style={{display:"flex",height:"100%",alignItems:"center",padding:width>700?"1rem":"0 1rem 1rem 1rem",borderRadius:5,flex:width>700?"50":"40%"}}>
          <p style={{display:"flex",width:"100%",color:"#162432",justifyContent:"flex-start",alignItems:"center",flexDirection:"row",fontWeight:600,fontSize:"25px",marginLeft:width>700&&"1rem",marginTop:width<700&&"0.5rem"}}>
            <span style={{color:"#0086FF",marginRight:"0.4rem"}}>Hey, </span> Associate!
          </p>
            <img src={Image} alt='' style={{height:width>700?"40vh":"",width:width>700?"35vw":"35vw"}}/>
            <p style={{color:"#0086FF",display:"flex",width:"100%",justifyContent:"center",flexDirection:"row",fontWeight:width>700?600:590,marginTop:"1rem",fontSize:width>700?"20px":"17px"}}>
              " Self determination and integrity "</p>
          </div>
          <div style={{overflow:"auto",display:width>700&&"flex",height:"100%",alignItems:"center",padding:width>700?"1rem":"0 1rem 1rem 1rem",borderRadius:5,flex:width>700?"50%":"60%"}}>
              <div style={{backgroundColor:"#F5FCFF",padding:width>700?"0 1rem":"0.3rem 1rem 0 1rem",width:"100%",height:width>700&&"60vh",display:"flex",flexDirection:"column",justifyContent:"center"}}>
                <h2 style={{color:"#162432"}}>Set New Password</h2>
              
                <p style={{color:"#16243280",fontSize:13,marginTop:"0.4rem"}}>Your new password must be different to previously used passwords.</p>
               
                <Form layout='vertical' style={{marginTop:"0.5rem"}}>
                <Form.Item label={<h5 style={{fontSize:"15px",fontWeight:"600"}}>Password</h5>}
                    name="firstpassword"
                    rules={[
                      { required: true, message: 'Please enter your password!' },
                    { min: 8, message: 'Password must be at least 8 characters long!' }]}
                   style={{marginTop:"0.5rem"}}
                  >
               
                    
                    <Input.Password  placeholder='Password'
                      onChange={(e)=>{setPassword(e.target.value)}}
                      iconRender={(visible) =>
                        visible ? (
                          <EyeOutlined onClick={togglePasswordVisibility} />
                        ) : (
                          <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
                        )
                      }
                    />
               
                </Form.Item>

                <Form.Item label={<h5 style={{fontSize:"15px",fontWeight:"600"}}>Confirm Password</h5>}
                    name="confirmPassword"
                    rules={[
                      { required: true, message: 'Please enter your password!' },
                    { min: 8, message: 'Password must be at least 8 characters long!' }
                  ]}
                  >
              
                   
                    <Input.Password placeholder='Confirm Password'
                      onChange={(e)=>{setConfirmPass(e.target.value)}}
                      iconRender={(visible) =>
                        visible ? (
                          <EyeOutlined onClick={toggleConfirmPasswordVisibility} />
                        ) : (
                          <EyeInvisibleOutlined onClick={toggleConfirmPasswordVisibility} />
                        )
                      }
                    />
              
                </Form.Item>

                <Row justify="center" className={classes.setButton}>
                <Form.Item>
                  <Button type="primary" htmlType='submit' onClick={confirmHandler}
                  disabled={password.length >=8 && confirmPassword.length >= 8 ? false : true}>
                      Confirm 
                  </Button>
                  </Form.Item>
                </Row>
              </Form>
               </div>
            </div>
          </div>
        </div>
        <div className={classes.imageB}>
        <img src={Union2} alt='' style={{height:width<700&&"7rem",width:width<700&&"7rem"}}/>
        </div>
    </div> */}

{
  loader?<Spin style={{display:"flex",alignItems:"center",justifyContent:"center",height:"100vh"}}/>:
validateUser?.type==="error"?<div style={{width:"100%",display:"flex",height:"100vh",alignItems:"center",justifyContent:"center",fontSize:"2.5vmin"}}>{validateUser?.message}</div>:<>

    {<div className={classes.login}>
    <section className={classes.imageSection}>
        
            {/* <img src={login_brigo_text}/> */}
            <img src={our_journey_img}/>
            <div className={classes.integrityText}>
              <span>Self determination and integrity</span>
              {/* <span>Join our brigosha family today!</span> */}
            </div>
       

      </section>

      <section className={classes.loginWrapper}>
        {<div className={classes.backDiv} onClick={()=>{
        navigate('/login');
        setActiveTab(5);
        // setCurPage(curPage-1)
        }}>
          <div>
           
            <ArrowLeftOutlined/>
            </div>
            <span>Back</span>
         
        </div>}
        <div className={classes.loginContainer}>
          <Page8 setCurPage={setCurPage} confirmHandler={confirmHandler}
          setConfirmPass={setConfirmPass}
          confirmPassword={confirmPassword}
          setPassword={setPassword}
          password={password}
          />
        
        </div>
      </section>

    </div>}
    {successModal&&<UpdatePassSuccessModal successModal={successModal} setSuccessModal={setSuccessModal}/>}
    </>}
  
 </>
)
}
export default SetNewPassword;