import React, { useState } from "react";
import { Button, Modal, Upload } from "antd"
import { ProfileStore } from "./store";
import profileIcon from "../../../assets/profileIconForProfile.svg";
import Api from "../../../services/Api";
import { decryptData, encryptData,SECRET_KEY } from '../../../services/fileCode';
import Notification from "../../../services/Notification";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";

const EditImageModal = (props) =>{
    const { currentPage,setCurrentPage,editImageModal,setEditImageModal,userData,setUserData} = ProfileStore();
    const [imgFile, setImgFile] = useState("");
    const [imgFileName, setImgFileName] = useState("");
    const storedUser = localStorage.getItem('user');
    const {width}=useWindowDimensions();

    let decryptedUser = decryptData(storedUser, SECRET_KEY);

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const imgbeforeUpload = (file) => {
        setImgFileName(file);
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isJpgOrPng) {
          Notification.error("You can only upload JPG/PNG file!");
          setImgFile("");
        } 
        else if (!isLt5M) {
          Notification.error("Image must smaller than 5MB!");
          setImgFile("");
        } 
        else {
          getBase64(file, (url) => {      
            setImgFile(file);
          });
        }
        return false
    };

    const handleFileRemove = () => {
        setImgFile("");
    };

    const submitHandler = () => {
        Api.patch(`/auth-auth/editProfileImage`)
        .uploadAndPatch({
            authUserId:decryptedUser?.authUserLoginId,
            profileImage: imgFile,
        }, (per,response) => { 
            if (response!==undefined && per===100) {       
                if(response?.data?.show?.type === "success"){ 
                    console.log("sssREsponse",response);
                    setEditImageModal(false);
                    props.refresh();
                }
            }
        });
    }   

    return(
        <Modal open={editImageModal} onCancel={()=>{setEditImageModal(false)}}
         footer={false} centered >
            <div style={{display:"flex",flexDirection:"column",gap:"2.5vh",width:"100%",justifyContent:"center",alignItems:"center",padding:"1vh 0"}}>
                <h3>Upload your picture</h3>
                <img src={imgFile ? URL.createObjectURL(imgFile) : userData?.profileImage ? userData?.profileImage : profileIcon} 
                    alt="" style={{borderRadius:"50%",height: width >700? "15vh":"13vh", width: "6rem",border:"1px dotted #0086FF"}}/>
                <div style={{display:"flex",flexDirection:"row",gap:"1vw",marginTop:"1vw"}}>
                    {imgFile ? 
                    <div style={{display:"flex",gap:"1vw"}}>
                   <Upload accept=".png, .jpg, .jpeg," beforeUpload={imgbeforeUpload}
                     multiple={false} maxCount={1} onRemove={handleFileRemove} showUploadList={false}>
                        <Button style={{color:"#0086FF",border:"1px solid #0086FF",}}>
                            Choose photo
                        </Button>
                    </Upload>
                    <Button type="primary" style={{width:"8rem"}} onClick={submitHandler}>Save</Button>
                    </div>
                    :
                    <Upload accept=".png, .jpg, .jpeg," beforeUpload={imgbeforeUpload}
                     multiple={false} maxCount={1} onRemove={handleFileRemove} showUploadList={false}>
                        <Button style={{color:"#0086FF",border:"1px solid #0086FF"}}>
                            Choose photo
                        </Button>
                    </Upload>
                    }
                </div>
            </div>
        </Modal>
    )
}
export default EditImageModal;