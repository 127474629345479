import classes from './Preview.module.css';
import cs1 from './EditProfile.module.css';
import { KeyIsNotEmpty } from './formData';
import pdf_doc_icon from './../../../../assets/pdf_doc_logo.svg';
import { getLink } from '../../../../Config';

function Preview({type,data}){

    console.log("PREviewData",data)

    return(
        <div className={`${cs1.ScrollEdit} ${cs1.PreviewField}`} style={{width:'40%'}}>
        <div style={{font:'normal 600 18px/24px Lato',color:'#162432'}}>Preview</div>
        {type==="AlphaNumeric"?
        <div className={classes.LableConatiner}>
            {data?.map((val,id)=>{
                return (
                    <>
                    <div style={{color:'#878787'}}>{val?.label}</div>
                    <div style={{color:'#162432'}} >{val?.value}</div>
                    </>)
            })}
        </div>:
        <div className={classes.LableConatinerFile}>
          {data?.length===0?<div>No data found!</div>:
            data?.map((val,id)=>{
                if(KeyIsNotEmpty(val?.value)){
                     let link= val?.value.startsWith("https://") ? val?.value : getLink(val?.value)
                    // let link=val?.value;
                return  (
                    <div className={classes.FileCard}  onClick={()=>{window.open(link,'_blank')?.focus();}} >
                       {val?.value?.includes('.pdf')?<img className={classes.FilePDF} src={pdf_doc_icon} />:
                       <img className={classes.FileImage} src={link}  />}
                        <div style={{cursor:'pointer'}} >{val?.label} </div>
                    </div>
                )
                }
            })}
        </div>}


        </div>
    )
}
export default Preview;
//  {/* <embed   src={val?.value} style={{height:'110px',width:'110px'}} />: */}