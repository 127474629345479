import React, { useEffect, useState } from "react";
import classes from './jobSearch.module.css';
import { Input, Row, Spin, Typography } from "antd";
import LocIcon from '../../../assets/LocIcon.svg';
import ExpIcon from '../../../assets/ExpIcon.svg';
import ClockIcon from '../../../assets/ClockIcon.svg';
import SearchCompo from "../../../design/SearchCompo";
import { getAllJobApi, getAuthId } from "./Action";
import ApplyJob from "./ApplyJob";
import useLoginStorageStore from "../loginStorageStore";
import ButtonCompo from "../../../design/ButonCompo";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import { useLoginStore } from "../store";

const JobSearch=()=>{
    const [search,setSearch]=useState('');
    const [allJob,setAllJob]=useState();
    const {clickedJob,setClickedJob}=useLoginStore();
    const [loader,setLoader]=useState(false);
    const {isLogin} =useLoginStorageStore();
    const {width}=useWindowDimensions();

    console.log('iddddd',)
    useEffect(()=>{
        setLoader(true)
    let id=getAuthId();
   
        let param={search:search,page:1,limit:100}

        if(isLogin){
            param={...param,authId:id}
        }

        getAllJobApi(param,(res)=>{
            setAllJob(res?.jobs?.items);
            setLoader(false);
        })
        
    },[search])
    const RenderJob =({title,location,exp,id,saved,expType,disabled})=>(
        // disabled?classes.disabled+' '+classes.jobcontainer:
        <div style={{cursor:'pointer',opacity:disabled?0.5:1}}  className={classes.jobcontainer} onClick={()=>{setClickedJob({id:id,});}}>
            <div>
                <div style={{color:'#161616',}} className={classes.header2}>{title}</div>
               <div style={{display:'flex', flexDirection:'column',gap:8,marginTop:'8px'}}>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <img src={ExpIcon} />
                    <div style={{marginLeft:8,color:'#3d3d3d'}} className={classes.title2}>{exp}</div>
                </div>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <img src={ClockIcon} />
                    <div style={{marginLeft:8,color:'#3d3d3d'}} className={classes.title2}>{'Full time'}</div>
                </div>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <img src={LocIcon} />
                    <Typography.Text ellipsis={{tooltip:true}} className={classes.title2+" "+classes.locationText} style={{color:'#3d3d3d',width: width > 700 ? "19rem":"16rem"}}>
                        {location?.map((loc,id)=>(<span>{loc?.name}{(location.length>1 && id<location.length-1)?',':''}</span>))}
                    </Typography.Text>
                </div>
               </div>

            </div>
         { !disabled &&  <div >
                <div className={classes.applyButton}><ButtonCompo title={"Apply Now"} 
                 style={{color:'#fff',backgroundColor:'#1089FF',font: "normal normal 600 18px/22px Figtree"}} />
                 </div>
            </div>}
            {disabled && <div style={{width:'100%',display:'flex',justifyContent:'flex-end',}}>
                <div style={{marginTop: width > 700 && '-26px',marginRight:'10px',border: width < 700 &&"1px solid #e3e3e3",textAlign: width < 700 &&"center",width:width<700&&"100%",padding:width<700&&"0.4rem"}}>
                     Applied</div></div>}
        </div>

        )

    return(
        <div className={classes.jobsearch}>
{(clickedJob===undefined)?
        <div className={classes.Container1} >
            <div>
            <div style={{color:'#161616',}} className={classes.header1}>Job</div>
                <div style={{color:'#3d3d3d',}} className={classes.title1}>Discover your next career move in a world of opportunities with our job listings.</div>
            </div>
        
            <div>
            <div className={classes.searchConatiner} style={ {display:'flex',justifyContent:'flex-start',marginTop:'16px'}}>
                <SearchCompo placeholder="Search jobs" onSearch={(val)=>{setSearch(val)}} value={search}  />
            
            </div>
            </div>
           <div style={{display:'flex',display:'flex', flexDirection:'column',gap:'16px',marginTop:'16px',maxHeight:'70vh',overflowY:'scroll',paddingRight:'6px'}}> 
           {loader?<Row style={{height:'70vh',display:'flex',justifyContent:'center',alignItems:'center'}}><Spin  size="large"></Spin></Row>:
               allJob?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'40vh',}}> <div>No Jobs found</div></div>:
                allJob?.map((item)=>{
               return (<RenderJob title={item?.title} location={item?.locations} exp={item?.experience} id={item?.id} saved={item?.saved} expType={item?.expType} disabled={item?.disable}></RenderJob>)
            })  }
            </div>

        
        </div>
        :<ApplyJob job={clickedJob}  setJob={setClickedJob} />}
        </div>
    )
}
export default JobSearch;