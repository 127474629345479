


import React, { useState, useEffect } from "react";

import classes from "./Dashboard.module.css";
import "./dashboard.css";
import DonutChart from "../../../components/DonutChart/donutChart";
import { ResetButton, SelectButton } from "../../../components/Button/Button";
// import { attendance, leaves, projects, tasks } from "../sample_datasets";
import { Select } from 'antd';
import profile_card_icon from "../../../assets/single_profile_black_icon.svg";
import group_card_icon from "../../../assets/double_profile_black_icon.svg";
import { projectDashBoard } from "../../ResourceManagement/SubComponents/Dashboard/action";
import moment from "moment";
import DatePickerCompo from "../../../components/DatePicker/DatePickerCompo.tsx";
import viewClass from "../../../services/ViewRole";
import CircleProgressBar from "../../../components/CircleProgressBar/CircleProgressBar";
import { NoData } from "../../Training_essentialDocuments";
import { v4 as uuidv4 } from "uuid";
import Card from "./cards";
import Carousel from "./Carousel";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Progress } from 'antd';
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import DashboardGraph from "./graph";

function Dashboard() {
  const Option = Select.Option;
  const [attendanceData, setAttendanceData] = useState();
  const [leavesData, setLeavesData] = useState([]);
  const [projectsData, setProjectsData] = useState();
  const [tasksData, setTasksData] = useState();
  const [projectDashBoardData, setProjectDashBoardData] = useState();
  const [attendanceDuration, setAttendanceDuration] = useState('month');
  const [projectDuration, setProjectDuration] = useState('month');
  const [taskDuration, setTaskDuration] = useState('month');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [deptId, setDeptId] = useState();
  const [managerId, setManagerId] = useState();
  const [projectId, setProjectId] = useState();
  const [res, setres] = useState()
  const { width } = useWindowDimensions();
  const [graphData, setGraphData] = useState([]);
  const [corIndex, setCI] = useState(1);
  // const [userRole,setUserRole]=useState();


  const durationFilter = [
    'month', 'quarter', 'year'
  ]
  const departmentList = projectDashBoardData?.options?.allDepartments;
  const managerList = projectDashBoardData?.options?.uniqueOptionsArray;
  const projectList = projectDashBoardData?.options?.projects;

  const colorCodes = {
    "New": "#B33DC6",
    "Ongoing": "#33A0FF",
    "OnHold": "#EDBF33",
    "Completed": "#24C850",
    "Overdue": "#F46A9B",
    "Suspended": "#878787",
    "present": "#0086FF",
    "lop": "#F64A3F",
    "halfDay": "#CC6760",
    "leave": "#FD8600",
  }

  useEffect(() => {
    viewClass.updateView();
    setProjectsData(undefined);
    setLeavesData(undefined);
    setTasksData(undefined);
    setAttendanceData(undefined);
    console.log("logx 76");
    let donutColors = [
      "#B6EEFA",
      "#BBB6FA",
      "#FAB6DB",
      "#FFF0C9",
      "#FFC9C9",
      "#F4F4F4",
    ];
    projectDashBoard({ attendanceDuration, projectDuration, taskDuration, deptId, managerId, projectId, startDate, endDate }, (response) => {
      setProjectDashBoardData(response?.dashboard);
      setStartDate(response?.dashboard?.startDate);
      setEndDate(response?.dashboard?.endDate);
      setres(response?.leavedata)
      //  setUserRole(response?.dashboard?.user?.role)
      let data = response;
      Object.keys(data).forEach((key, i) => {
        console.log("dataKey", key)
        let tempDonutData = [];

        Object.keys(data[key])?.forEach((e, index) => {

          let percent =
            response[key][e] === null
              ? 0

              : response[key][e];
          let dataObj = {
            name: e,
            value: percent,
            color: colorCodes[e],
          };

          tempDonutData = [...tempDonutData, dataObj];
          // setDonutData((prev) => [...prev, dataObj]);
        });



        if (key == 'project') {
          console.log('project', tempDonutData)
          setProjectsData(tempDonutData)
        } else if (key == 'leavedata') {
          console.log('leave', tempDonutData)
          setLeavesData(tempDonutData);
        } else if (key == 'task') {
          console.log('task', tempDonutData)
          setTasksData(tempDonutData)
        } else if (key == 'attendanceData') {
          console.log('task', tempDonutData)
          setAttendanceData(tempDonutData)
        }
      })
      let tempArr = [];
      projectDashBoardData?.labels?.forEach((element, index) => {
        tempArr.push({ label: element, value: projectDashBoardData?.efficiency[index] })
      });
      setGraphData([...tempArr])

    });

    return () => {
      console.log("Cleanup function");
    };
  }, [attendanceDuration, projectDuration, taskDuration, deptId, managerId, projectId]);

  const OnDateSelected = () =>{
    projectDashBoard({ attendanceDuration, projectDuration, taskDuration, deptId, managerId, projectId, startDate, endDate }, (response) => {
      setProjectDashBoardData(response?.dashboard);
      setStartDate(response?.dashboard?.startDate);
      setEndDate(response?.dashboard?.endDate);
      setres(response?.leavedata)
      //  setUserRole(response?.dashboard?.user?.role)
      let data = response;
      Object.keys(data).forEach((key, i) => {
        console.log("dataKey", key)
        let tempDonutData = [];

        Object.keys(data[key])?.forEach((e, index) => {

          let percent =
            response[key][e] === null
              ? 0

              : response[key][e];
          let dataObj = {
            name: e,
            value: percent,
            color: colorCodes[e],
          };

          tempDonutData = [...tempDonutData, dataObj];
          // setDonutData((prev) => [...prev, dataObj]);
        });



        if (key == 'project') {
          console.log('project', tempDonutData)
          setProjectsData(tempDonutData)
        } else if (key == 'leavedata') {
          console.log('leave', tempDonutData)
          setLeavesData(tempDonutData);
        } else if (key == 'task') {
          console.log('task', tempDonutData)
          setTasksData(tempDonutData)
        } else if (key == 'attendanceData') {
          console.log('task', tempDonutData)
          setAttendanceData(tempDonutData)
        }
      })
      let tempArr = [];
      projectDashBoardData?.labels?.forEach((element, index) => {
        tempArr.push({ label: element, value: projectDashBoardData?.efficiency[index] })
      });
      setGraphData([...tempArr])

    });
  }
  
  useEffect(() => { }, [attendanceData])

  const selectFromDate = (date, dateString) => {
      date !== null &&
      setStartDate(dateString);
      OnDateSelected()
  };

  const selectToDate = (date, dateString) => {
      date !== null &&
      setEndDate(dateString)
      OnDateSelected()
  };



  return (
    <div className={classes.container}>
      <h2>
        Dashboard
      </h2>

      {
        attendanceData !== undefined && projectsData !== undefined && leavesData !== undefined && tasksData !== undefined ?
          <>
            {
              corIndex === 1 ?
                <>
                  <Carousel
                    cards={[
                      {
                        key: uuidv4(),
                        content: (
                          <div className={classes.grid_item} style={{
                            height: width < 650 ? 190 : 400, width: width < 650 ? 290 : 600, backgroundColor: 'white', borderColor: '#D4D4D4', borderWidth: 1, borderStyle: 'solid'
                          }}>

                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%'
                              }}
                            >
                              {console.log(attendanceDuration, "attendanceDuration")}
                              <h3 style={{}}>Attendance</h3>
                              <Select
                                style={{ width: 130 }}
                                size="samll"

                                value={attendanceDuration}
                                placeholder="select"
                                onChange={(e) => {
                                  setCI(1)
                                  setAttendanceDuration(e);
                                }}>
                                {durationFilter && durationFilter?.map((item, index) => {
                                  return (
                                    <Option value={item} key={item}>{item}</Option>
                                  )
                                })}
                              </Select>
                            </div>

                            {
                              // attendanceData?.length ?
                              <DonutChart style={{ height: '100%', width: "100%", }} data={attendanceData} mwidth={width} />
                              // :
                              // <NoData />
                            }
                          </div>
                        )
                      },
                      {
                        key: uuidv4(),
                        content: (
                          <div className={classes.grid_item} style={{ height: width < 650 ? 190 : 400, width: width < 650 ? 290 : 600, backgroundColor: 'white', borderColor: '#D4D4D4', borderWidth: 1, borderStyle: 'solid' }}>


                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',

                                width: '100%'

                              }}
                            >
                              <h3 style={{ margin: "0", padding: "0" }}>Projects</h3>
                              <Select
                                style={{ width: 130 }}
                                size="samll"

                                value={projectDuration}
                                placeholder="select"
                                onChange={(e) => {
                                  setCI(2)
                                  setProjectDuration(e);
                                }}>
                                {durationFilter && durationFilter?.map((item, index) => {
                                  return (
                                    <Option value={item} key={item}>{item}</Option>
                                  )
                                })}
                              </Select>
                            </div>
                            {
                              projectsData?.length ?
                                <DonutChart style={{ height: '100%', width: "100%", }} data={projectsData} mwidth={width} />
                                :
                                <NoData />
                            }
                          </div>
                        )
                      },
                      {
                        key: uuidv4(),
                        content: (
                          <>
                            {
                              width < 700 ?
                                <div style={{ height: width < 650 ? 190 : 400, width: width < 650 ? 290 : 600, boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px", backgroundColor: "white", padding: 10, borderRadius: 10 }}>
                                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                    <h3 style={{ marginTop: "0", padding: "0" }}>Leaves</h3>
                                  </div>
                                  <div style={{ display: "flex", alignItems: "center", width: "100%" }}></div>
                                  <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                    <div style={{ width: "60%" }}>
                                      <span style={{ fontSize: 13, color: "#ABABAB", fontWeight: 500 }}>Sick Leave : {res?.totalLeaves?.Sick ? res?.totalLeaves?.Sick : 0} </span>

                                    </div>
                                    <div style={{ width: "30%" }}>
                                      <Progress color="#EAA6B9" percent={Math.round(leavesData[0]?.value * 100 / 6)} />
                                    </div>
                                  </div>
                                  <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                    <div style={{ width: "60%" }}>
                                      <span style={{ fontSize: 13, color: "#ABABAB", fontWeight: 500 }}>Casual Leave : {res?.totalLeaves?.Casual ? res?.totalLeaves?.Casual : 0} </span>

                                    </div>
                                    <div style={{ width: "30%" }}>
                                      <Progress color="#163C86" percent={Math.round(leavesData[1]?.value * 100 / 6)} />
                                    </div>
                                  </div>
                                  <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                    <div style={{ width: "60%" }}>
                                      <span style={{ fontSize: 13, color: "#ABABAB", fontWeight: 500 }}>Privilege Leave : {res?.totalLeaves?.Privilege ? Math.round(res?.totalLeaves?.Privilege) : 0} </span>

                                    </div>
                                    <div style={{ width: "30%" }}>
                                      <Progress color="#FFCF21" percent={Math.round(leavesData[2]?.value * 100 / 6)} />
                                    </div>
                                  </div>
                                </div>
                                :
                                <div className={classes.grid_item} style={{ height: width < 650 ? 190 : 400, width: width < 650 ? 290 : 600, backgroundColor: 'white', borderColor: '#D4D4D4', borderWidth: 1, borderStyle: 'solid' }}>

                                  <h3 style={{ marginTop: "0", padding: "0" }}>Leaves</h3>
                                  {
                                    leavesData?.length !== 0 ?
                                      <>
                                        <div className={classes.leaveCircle} >
                                          {leavesData?.length !== 0 ? <>
                                            <div className={classes.leaveType} >
                                              <CircleProgressBar strokeWidth={width < 700 ? 6 : 12} fontSize={20} fontWeight={700} sqSize={width < 700 ? 50 : 100} progressColor="#EAA6B9" text={leavesData[0]?.value} percentage={leavesData[0]?.value * 100 / 6} prefix="" />
                                              <span>Sick</span>
                                            </div>
                                            <div className={classes.leaveType}>
                                              <CircleProgressBar strokeWidth={width < 700 ? 6 : 12} fontSize={20} fontWeight={700} sqSize={width < 700 ? 50 : 100} progressColor="#163C86" text={leavesData[1]?.value} percentage={leavesData[1]?.value * 100 / 6} prefix="" />
                                              <span>Casual</span>
                                            </div>
                                            <div className={classes.leaveType}>
                                              <CircleProgressBar strokeWidth={width < 700 ? 6 : 12} fontSize={20} fontWeight={700} sqSize={width < 700 ? 50 : 100} progressColor="#FFCF21" text={leavesData[2]?.value} percentage={leavesData[2]?.value * 100 / 12} prefix="" />
                                              <span>Privilege</span>
                                              {console.log(res, "resresres")}
                                            </div>
                                            <div className={classes.leaveInfo}>
                                              <div>
                                                <span>Total Sick Leave</span>
                                                <span>:</span>
                                                <span>{res?.totalLeaves?.Sick ? res?.totalLeaves?.Sick : 0}</span>
                                              </div>
                                              <div>
                                                <span>Total Casual Leave</span>
                                                <span>:</span>
                                                <span>{res?.totalLeaves?.Casual ? res?.totalLeaves?.Casual : 0}</span>
                                              </div>
                                              <div>
                                                <span> Total Privilege Leave</span>
                                                <span>:</span>
                                                <span>{res?.totalLeaves?.Privilege ? res?.totalLeaves?.Privilege : 0}</span>
                                              </div>
                                            </div>
                                          </> :
                                            null}
                                        </div>
                                      </>
                                      :
                                      <NoData />
                                  }
                                  {/* <DonutChart style={{height:'100%',width:"100%",}} data={leavesData} /> */}
                                </div>
                            }


                          </>
                        )
                      },
                      {
                        key: uuidv4(),
                        content: (
                          <div className={classes.grid_item} style={{ height: width < 650 ? 190 : 400, width: width < 650 ? 290 : 600, backgroundColor: 'white', borderColor: '#D4D4D4', borderWidth: 1, borderStyle: 'solid' }}>

                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%'

                              }}
                            >
                              <h3 style={{ margin: "0", padding: "0" }}>Tasks</h3>
                              <Select
                                style={{ width: 130 }}
                                size="samll"

                                value={taskDuration}
                                placeholder="select"
                                onChange={(e) => {
                                  setCI(3);
                                  setTaskDuration(e);
                                }}>
                                {durationFilter && durationFilter?.map((item, index) => {
                                  return (
                                    <Option value={item} key={item}>{item}</Option>
                                  )
                                })}
                              </Select>

                            </div>

                            <DonutChart style={{ height: '100%', width: "100%", }} data={tasksData} mwidth={width}/>



                          </div>
                        )
                      },
                    ]}
                    height="500px"
                    width={width < 700 ? "100%" : "80%"}
                    margin="0 auto"
                    offset={2}
                    goToSlideDelay={3000}

                    showArrows={false}
                    onChange={() => { }}

                  />
                </>
                :
                corIndex === 2 ?
                  <>
                    <Carousel
                      cards={[
                        {
                          key: uuidv4(),
                          content: (
                            <div className={classes.grid_item} style={{ height: width < 650 ? 190 : 400, width: width < 650 ? 290 : 600, backgroundColor: 'white', borderColor: '#D4D4D4', borderWidth: 1, borderStyle: 'solid' }}>


                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',

                                  width: '100%'

                                }}
                              >
                                <h3 style={{ margin: "0", padding: "0" }}>Projects</h3>
                                <Select
                                  style={{ width: 130 }}
                                  size="samll"

                                  value={projectDuration}
                                  placeholder="select"
                                  onChange={(e) => {
                                    setCI(2)
                                    setProjectDuration(e);
                                  }}>
                                  {durationFilter && durationFilter?.map((item, index) => {
                                    return (
                                      <Option value={item} key={item}>{item}</Option>
                                    )
                                  })}
                                </Select>
                              </div>
                              {
                                projectsData?.length ?
                                  <DonutChart style={{ height: '100%', width: "100%", }} data={projectsData} mwidth={width} />
                                  :
                                  <NoData />
                              }
                            </div>
                          )
                        },
                        {
                          key: uuidv4(),
                          content: (
                            <div className={classes.grid_item} style={{
                              height: width < 650 ? 190 : 400, width: width < 650 ? 290 : 600, backgroundColor: 'white', borderColor: '#D4D4D4', borderWidth: 1, borderStyle: 'solid'
                            }}>

                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%'
                                }}
                              >
                                {console.log(attendanceDuration, "attendanceDuration")}
                                <h3 style={{}}>Attendance</h3>
                                <Select
                                  style={{ width: 130 }}
                                  size="samll"

                                  value={attendanceDuration}
                                  placeholder="select"
                                  onChange={(e) => {
                                    setCI(1)
                                    setAttendanceDuration(e);
                                  }}>
                                  {durationFilter && durationFilter?.map((item, index) => {
                                    return (
                                      <Option value={item} key={item}>{item}</Option>
                                    )
                                  })}
                                </Select>
                              </div>

                              {
                                // attendanceData?.length ?
                                <DonutChart style={{ height: '100%', width: "100%", }} data={attendanceData} mwidth={width} />
                                // :
                                // <NoData />
                              }
                            </div>
                          )
                        },

                        {
                          key: uuidv4(),
                          content: (
                            <>
                              {
                                width < 700 ?
                                  <div style={{ height: width < 650 ? 190 : 400, width: width < 650 ? 290 : 600, boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px", backgroundColor: "white", padding: 10, borderRadius: 10 }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                      <h3 style={{ marginTop: "0", padding: "0" }}>Leaves</h3>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", width: "100%" }}></div>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                      <div style={{ width: "60%" }}>
                                        <span style={{ fontSize: 13, color: "#ABABAB", fontWeight: 500 }}>Sick Leave : {res?.totalLeaves?.Sick ? res?.totalLeaves?.Sick : 0} </span>

                                      </div>
                                      <div style={{ width: "30%" }}>
                                        <Progress color="#EAA6B9" percent={Math.round(leavesData[0]?.value * 100 / 6)} />
                                      </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                      <div style={{ width: "60%" }}>
                                        <span style={{ fontSize: 13, color: "#ABABAB", fontWeight: 500 }}>Casual Leave : {res?.totalLeaves?.Casual ? res?.totalLeaves?.Casual : 0} </span>

                                      </div>
                                      <div style={{ width: "30%" }}>
                                        <Progress color="#163C86" percent={Math.round(leavesData[1]?.value * 100 / 6)} />
                                      </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                      <div style={{ width: "60%" }}>
                                        <span style={{ fontSize: 13, color: "#ABABAB", fontWeight: 500 }}>Privilege Leave : {res?.totalLeaves?.Privilege ? Math.round(res?.totalLeaves?.Privilege) : 0} </span>

                                      </div>
                                      <div style={{ width: "30%" }}>
                                        <Progress color="#FFCF21" percent={Math.round(leavesData[2]?.value * 100 / 6)} />
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  <div className={classes.grid_item} style={{ height: width < 650 ? 190 : 400, width: width < 650 ? 290 : 600, backgroundColor: 'white', borderColor: '#D4D4D4', borderWidth: 1, borderStyle: 'solid' }}>

                                    <h3 style={{ marginTop: "0", padding: "0" }}>Leaves</h3>
                                    {
                                      leavesData?.length !== 0 ?
                                        <>
                                          <div className={classes.leaveCircle} >
                                            {leavesData?.length !== 0 ? <>
                                              <div className={classes.leaveType} >
                                                <CircleProgressBar strokeWidth={width < 700 ? 6 : 12} fontSize={20} fontWeight={700} sqSize={width < 700 ? 50 : 100} progressColor="#EAA6B9" text={leavesData[0]?.value} percentage={leavesData[0]?.value * 100 / 6} prefix="" />
                                                <span>Sick</span>
                                              </div>
                                              <div className={classes.leaveType}>
                                                <CircleProgressBar strokeWidth={width < 700 ? 6 : 12} fontSize={20} fontWeight={700} sqSize={width < 700 ? 50 : 100} progressColor="#163C86" text={leavesData[1]?.value} percentage={leavesData[1]?.value * 100 / 6} prefix="" />
                                                <span>Casual</span>
                                              </div>
                                              <div className={classes.leaveType}>
                                                <CircleProgressBar strokeWidth={width < 700 ? 6 : 12} fontSize={20} fontWeight={700} sqSize={width < 700 ? 50 : 100} progressColor="#FFCF21" text={leavesData[2]?.value} percentage={leavesData[2]?.value * 100 / 12} prefix="" />
                                                <span>Privilege</span>
                                                {console.log(res, "resresres")}
                                              </div>
                                              <div className={classes.leaveInfo}>
                                                <div>
                                                  <span>Total Sick Leave</span>
                                                  <span>:</span>
                                                  <span>{res?.totalLeaves?.Sick ? res?.totalLeaves?.Sick : 0}</span>
                                                </div>
                                                <div>
                                                  <span>Total Casual Leave</span>
                                                  <span>:</span>
                                                  <span>{res?.totalLeaves?.Casual ? res?.totalLeaves?.Casual : 0}</span>
                                                </div>
                                                <div>
                                                  <span> Total Privilege Leave</span>
                                                  <span>:</span>
                                                  <span>{res?.totalLeaves?.Privilege ? res?.totalLeaves?.Privilege : 0}</span>
                                                </div>
                                              </div>
                                            </> :
                                              null}
                                          </div>
                                        </>
                                        :
                                        <NoData />
                                    }
                                    {/* <DonutChart style={{height:'100%',width:"100%",}} data={leavesData} /> */}
                                  </div>
                              }


                            </>
                          )
                        },
                        {
                          key: uuidv4(),
                          content: (
                            <div className={classes.grid_item} style={{ height: width < 650 ? 190 : 400, width: width < 650 ? 290 : 600, backgroundColor: 'white', borderColor: '#D4D4D4', borderWidth: 1, borderStyle: 'solid' }}>

                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%'

                                }}
                              >
                                <h3 style={{ margin: "0", padding: "0" }}>Tasks</h3>
                                <Select
                                  style={{ width: 130 }}
                                  size="samll"

                                  value={taskDuration}
                                  placeholder="select"
                                  onChange={(e) => {
                                    setCI(3);
                                    setTaskDuration(e);
                                  }}>
                                  {durationFilter && durationFilter?.map((item, index) => {
                                    return (
                                      <Option value={item} key={item}>{item}</Option>
                                    )
                                  })}
                                </Select>

                              </div>

                              <DonutChart style={{ height: '100%', width: "100%", }} data={tasksData} mwidth={width}/>



                            </div>
                          )
                        },
                      ]}
                      height="500px"
                      width={width < 700 ? "100%" : "80%"}
                      margin="0 auto"
                      offset={2}
                      goToSlideDelay={3000}

                      showArrows={false}
                      onChange={() => { }}

                    />
                  </>
                  :
                  corIndex === 3 ?
                    <>
                      <Carousel
                        cards={[
                          {
                            key: uuidv4(),
                            content: (
                              <div className={classes.grid_item} style={{ height: width < 650 ? 190 : 400, width: width < 650 ? 290 : 600, backgroundColor: 'white', borderColor: '#D4D4D4', borderWidth: 1, borderStyle: 'solid' }}>

                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%'

                                  }}
                                >
                                  <h3 style={{ margin: "0", padding: "0" }}>Tasks</h3>
                                  <Select
                                    style={{ width: 130 }}
                                    size="samll"

                                    value={taskDuration}
                                    placeholder="select"
                                    onChange={(e) => {
                                      setCI(3);
                                      setTaskDuration(e);
                                    }}>
                                    {durationFilter && durationFilter?.map((item, index) => {
                                      return (
                                        <Option value={item} key={item}>{item}</Option>
                                      )
                                    })}
                                  </Select>

                                </div>

                                <DonutChart style={{ height: '100%', width: "100%", }} data={tasksData} mwidth={width}/>



                              </div>
                            )
                          },
                          {
                            key: uuidv4(),
                            content: (
                              <>
                                {
                                  width < 700 ?
                                    <div style={{ height: width < 650 ? 190 : 400, width: width < 650 ? 290 : 600, boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px", backgroundColor: "white", padding: 10, borderRadius: 10 }}>
                                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                        <h3 style={{ marginTop: "0", padding: "0" }}>Leaves</h3>
                                      </div>
                                      <div style={{ display: "flex", alignItems: "center", width: "100%" }}></div>
                                      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                        <div style={{ width: "60%" }}>
                                          <span style={{ fontSize: 13, color: "#ABABAB", fontWeight: 500 }}>Sick Leave : {res?.totalLeaves?.Sick ? res?.totalLeaves?.Sick : 0} </span>

                                        </div>
                                        <div style={{ width: "30%" }}>
                                          <Progress color="#EAA6B9" percent={Math.round(leavesData[0]?.value * 100 / 6)} />
                                        </div>
                                      </div>
                                      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                        <div style={{ width: "60%" }}>
                                          <span style={{ fontSize: 13, color: "#ABABAB", fontWeight: 500 }}>Casual Leave : {res?.totalLeaves?.Casual ? res?.totalLeaves?.Casual : 0} </span>

                                        </div>
                                        <div style={{ width: "30%" }}>
                                          <Progress color="#163C86" percent={Math.round(leavesData[1]?.value * 100 / 6)} />
                                        </div>
                                      </div>
                                      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                        <div style={{ width: "60%" }}>
                                          <span style={{ fontSize: 13, color: "#ABABAB", fontWeight: 500 }}>Privilege Leave : {res?.totalLeaves?.Privilege ? Math.round(res?.totalLeaves?.Privilege) : 0} </span>

                                        </div>
                                        <div style={{ width: "30%" }}>
                                          <Progress color="#FFCF21" percent={Math.round(leavesData[2]?.value * 100 / 6)} />
                                        </div>
                                      </div>
                                    </div>
                                    :
                                    <div className={classes.grid_item} style={{ height: width < 650 ? 190 : 400, width: width < 650 ? 290 : 600, backgroundColor: 'white', borderColor: '#D4D4D4', borderWidth: 1, borderStyle: 'solid' }}>

                                      <h3 style={{ marginTop: "0", padding: "0" }}>Leaves</h3>
                                      {
                                        leavesData?.length !== 0 ?
                                          <>
                                            <div className={classes.leaveCircle} >
                                              {leavesData?.length !== 0 ? <>
                                                <div className={classes.leaveType} >
                                                  <CircleProgressBar strokeWidth={width < 700 ? 6 : 12} fontSize={20} fontWeight={700} sqSize={width < 700 ? 50 : 100} progressColor="#EAA6B9" text={leavesData[0]?.value} percentage={leavesData[0]?.value * 100 / 6} prefix="" />
                                                  <span>Sick</span>
                                                </div>
                                                <div className={classes.leaveType}>
                                                  <CircleProgressBar strokeWidth={width < 700 ? 6 : 12} fontSize={20} fontWeight={700} sqSize={width < 700 ? 50 : 100} progressColor="#163C86" text={leavesData[1]?.value} percentage={leavesData[1]?.value * 100 / 6} prefix="" />
                                                  <span>Casual</span>
                                                </div>
                                                <div className={classes.leaveType}>
                                                  <CircleProgressBar strokeWidth={width < 700 ? 6 : 12} fontSize={20} fontWeight={700} sqSize={width < 700 ? 50 : 100} progressColor="#FFCF21" text={leavesData[2]?.value} percentage={leavesData[2]?.value * 100 / 12} prefix="" />
                                                  <span>Privilege</span>
                                                  {console.log(res, "resresres")}
                                                </div>
                                                <div className={classes.leaveInfo}>
                                                  <div>
                                                    <span>Total Sick Leave</span>
                                                    <span>:</span>
                                                    <span>{res?.totalLeaves?.Sick ? res?.totalLeaves?.Sick : 0}</span>
                                                  </div>
                                                  <div>
                                                    <span>Total Casual Leave</span>
                                                    <span>:</span>
                                                    <span>{res?.totalLeaves?.Casual ? res?.totalLeaves?.Casual : 0}</span>
                                                  </div>
                                                  <div>
                                                    <span> Total Privilege Leave</span>
                                                    <span>:</span>
                                                    <span>{res?.totalLeaves?.Privilege ? res?.totalLeaves?.Privilege : 0}</span>
                                                  </div>
                                                </div>
                                              </> :
                                                null}
                                            </div>
                                          </>
                                          :
                                          <NoData />
                                      }
                                      {/* <DonutChart style={{height:'100%',width:"100%",}} data={leavesData} /> */}
                                    </div>
                                }


                              </>
                            )
                          },
                          {
                            key: uuidv4(),
                            content: (
                              <div className={classes.grid_item} style={{
                                height: width < 650 ? 190 : 400, width: width < 650 ? 290 : 600, backgroundColor: 'white', borderColor: '#D4D4D4', borderWidth: 1, borderStyle: 'solid'
                              }}>

                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%'
                                  }}
                                >
                                  {console.log(attendanceDuration, "attendanceDuration")}
                                  <h3 style={{}}>Attendance</h3>
                                  <Select
                                    style={{ width: 130 }}
                                    size="samll"

                                    value={attendanceDuration}
                                    placeholder="select"
                                    onChange={(e) => {
                                      setCI(1)
                                      setAttendanceDuration(e);
                                    }}>
                                    {durationFilter && durationFilter?.map((item, index) => {
                                      return (
                                        <Option value={item} key={item}>{item}</Option>
                                      )
                                    })}
                                  </Select>
                                </div>

                                {
                                  // attendanceData?.length ?
                                  <DonutChart style={{ height: '100%', width: "100%", }} data={attendanceData} mwidth={width} />
                                  // :
                                  // <NoData />
                                }
                              </div>
                            )
                          },
                          {
                            key: uuidv4(),
                            content: (
                              <div className={classes.grid_item} style={{ height: width < 650 ? 190 : 400, width: width < 650 ? 290 : 600, backgroundColor: 'white', borderColor: '#D4D4D4', borderWidth: 1, borderStyle: 'solid' }}>


                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',

                                    width: '100%'

                                  }}
                                >
                                  <h3 style={{ margin: "0", padding: "0" }}>Projects</h3>
                                  <Select
                                    style={{ width: 130 }}
                                    size="samll"

                                    value={projectDuration}
                                    placeholder="select"
                                    onChange={(e) => {
                                      setCI(2)
                                      setProjectDuration(e);
                                    }}>
                                    {durationFilter && durationFilter?.map((item, index) => {
                                      return (
                                        <Option value={item} key={item}>{item}</Option>
                                      )
                                    })}
                                  </Select>
                                </div>
                                {
                                  projectsData?.length ?
                                    <DonutChart style={{ height: '100%', width: "100%", }} data={projectsData} mwidth={width} />
                                    :
                                    <NoData />
                                }
                              </div>
                            )
                          },


                        ]}
                        height="500px"
                        width={width < 700 ? "100%" : "80%"}
                        margin="0 auto"
                        offset={2}
                        goToSlideDelay={3000}

                        showArrows={false}
                        onChange={() => { }}

                      />
                    </>
                    :
                    <></>
            }
          </>

          :
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: 400 }}>
            <LoadingOutlined
              style={{
                fontSize: 24,
              }}
              spin
            />
          </div>
      }







      <div style={{ borderTop: "1.5px solid #DDDDDDDD", margin: "2rem 0 0 0", paddingTop: "2rem" }}>
        <h3 style={{ padding: 10 }}>
          Organization Summary
        </h3>
        <span style={{ fontWeight: "700", marginLeft: "0.8rem", fontSize: width < 700 ? 12 : 16 }}>{`Showing data from ${startDate ? startDate : "00:00:0000"} to ${endDate ? endDate : "00:00:0000"}`}</span>
      </div>
      {
        width < 700 ?
          <>
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 10 }}>
              <div style={{ width: width < 700 ? '40%' : "80%" }}>
                <h5>From</h5>
                <DatePickerCompo allowClear={false} style={{ height: "2rem", border: "1px solid #ECECEC", width: width < 700 ? '90%' : "80%" , fontSize: '0.7rem',}} onChange={selectFromDate} value={startDate ? moment(startDate) : undefined} />
              </div>
              <div style={{ width: width < 700 ? '40%' : "80%" }}>
                <h5>To</h5>
                <DatePickerCompo allowClear={false} style={{ height: "2rem", border: "1px solid #ECECEC", width: width < 700 ? '90%' : "80%" ,fontSize: '0.7rem',}} onChange={selectToDate} value={endDate ? moment(endDate) : undefined} />
              </div>
              <div
                style={{

                  width: "30%",
                  display: "flex",
                  alignItems: "flex-end",
                  marginTop: -3


                }}
              >
                <ResetButton
                  style={{ height: 30, borderRadius: 3 }}
                  // icon={<img src={reset_icon} />}    
                  onClick={() => {
                    setStartDate("");
                    setEndDate("");
                    setDeptId("");
                    setManagerId("");
                    setProjectId("");
                    OnDateSelected()
                    var selectedDept = document.getElementById("select-dept");
                    if (selectedDept)
                      selectedDept.selectedIndex = 0;
                    var selectedManager = document.getElementById("select-manager");
                    if (selectedManager)
                      selectedManager.selectedIndex = 0;
                    var selectedProject = document.getElementById("select-project");
                    if (selectedProject)
                      selectedProject.selectedIndex = 0;
                  }}
                  title="Reset"
                />
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 10, gap: 5 }}>
              {(viewClass.superAdmin || viewClass.hod) ?
                <div style={{ width: "100%" }}>
                  <h5 style={{
                    maxHeight: '1.2em',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}>Select Department</h5>
                  <Select
                    style={{ marginTop: 10, width: 110 }}
                    size="large"
                    allowClear={true}
                    value={deptId ? deptId : undefined}
                    placeholder="Select "
                    onChange={(e) => {
                      // console.log('anil', e.target.value)
                      setDeptId(e);
                    }}>
                    {departmentList && departmentList?.map((item, index) => {
                      return (
                        <Option value={item.id} key={index}>{item.name}</Option>
                      )
                    })}
                  </Select>
                </div> : null
              }
              {(viewClass.superAdmin || viewClass.hod) ?
                <div style={{ width: "100%" }}>
                  <h5>Select Manager</h5>

                  <Select
                    style={{ marginTop: 10, width: 110 }}
                    size="large"
                    allowClear={true}
                    value={managerId ? managerId : undefined}
                    placeholder="Select " onChange={(e) => {
                      // console.log('anil', e.target.value)
                      setManagerId(e);
                    }}>
                    {managerList && managerList?.map((item, index) => {
                      return (
                        <Option value={item.id} key={index}>{item.name}</Option>
                      )
                    })}
                  </Select>

                </div> : null}
              {(viewClass.superAdmin || viewClass.hod) ?
                <div style={{ width: "100%" }}>
                  <h5>Select Project</h5>
                  <Select
                    style={{ marginTop: 10, width: 110 }}
                    size="large"
                    allowClear={true}
                    value={projectId ? projectId : undefined}
                    placeholder="Select "
                    onChange={(e) => {

                      setProjectId(e);
                    }}>
                    {projectList && projectList?.map((item, index) => {
                      return (
                        <Option value={item.id} key={index}>{item.name}</Option>
                      )
                    })}
                  </Select>

                </div> : null}
            </div>
          </>
          :
          <>
            {
              (viewClass.superAdmin || viewClass.hod || viewClass.hrAdmin || viewClass.hrAssociate || viewClass.manager) ?
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: 10, width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between", width: "30%" }}>
                    <div style={{ width: width < 700 ? '100%' : "80%" }}>
                      <h5>From</h5>{console.log("sssDatee",startDate,endDate)}
                      <DatePickerCompo style={{ height: "2.5rem", border: "1px solid #ECECEC", width: width < 700 ? '68%' : "80%" }} onChange={selectFromDate} value={startDate ? moment(startDate) : undefined} />
                    </div>
                    <div style={{ width: width < 700 ? '100%' : "80%" }}>
                      <h5>To</h5>
                      <DatePickerCompo style={{ height: "2.5rem", border: "1px solid #ECECEC", width: width < 700 ? '68%' : "80%" }} onChange={selectToDate} value={endDate ? moment(endDate) : undefined} />
                    </div>


                  </div>
                  <div style={{ display: "flex", justifyContent:(viewClass.superAdmin || viewClass.hod)? "space-between":"flex-end", width: "50%", gap:"0.5rem" }}>
                    {(viewClass.superAdmin || viewClass.hod) ?
                      <div style={{ width: "100%" }}>
                        <h5>Select Department</h5>
                        <Select
                          style={{ marginTop: 10, width: 150 }}
                          size="medium"
                          allowClear={true}
                          value={deptId ? deptId : undefined}
                          placeholder="Select "
                          onChange={(e) => {
                            // console.log('anil', e.target.value)
                            setDeptId(e);
                          }}>
                          {departmentList && departmentList?.map((item, index) => {
                            return (
                              <Option value={item.id} key={index}>{item.name}</Option>
                            )
                          })}
                        </Select>
                      </div> : <div style={{width:150}}></div>
                    }

                    {(viewClass.superAdmin || viewClass.hod) ?
                      <div style={{ width: "100%" }}>
                        <h5>Select Manager</h5>

                        <Select
                          style={{ marginTop: 10, width: 150 }}
                          size="medium"
                          allowClear={true}
                          value={managerId ? managerId : undefined}
                          placeholder="Select " onChange={(e) => {
                            // console.log('anil', e.target.value)
                            setManagerId(e);
                          }}>
                          {managerList && managerList?.map((item, index) => {
                            return (
                              <Option value={item.id} key={index}>{item.name}</Option>
                            )
                          })}
                        </Select>

                      </div> : <div style={{width:150}}></div>}
                    {(viewClass.superAdmin || viewClass.hod) ?
                      <div style={{ width: "100%" }}>
                        <h5>Select Project</h5>
                        <Select
                          style={{ marginTop: 10, width: 150 }}
                          size="medium"
                          allowClear={true}
                          value={projectId ? projectId : undefined}
                          placeholder="Select "
                          onChange={(e) => {

                            setProjectId(e);
                          }}>
                          {projectList && projectList?.map((item, index) => {
                            return (
                              <Option value={item.id} key={index}>{item.name}</Option>
                            )
                          })}
                        </Select>

                      </div> : <div style={{width:150}}></div>}

                    <div
                      style={{

                        width: "40%",
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent:"flex-end"


                      }}
                    >
                      <ResetButton
                        style={{ height: 32, borderRadius: 3 }}
                        // icon={<img src={reset_icon} />}    
                        onClick={() => {
                          setStartDate("");
                          setEndDate("");
                          setDeptId("");
                          setManagerId("");
                          setProjectId("");
                          OnDateSelected()
                          var selectedDept = document.getElementById("select-dept");
                          if (selectedDept)
                            selectedDept.selectedIndex = 0;
                          var selectedManager = document.getElementById("select-manager");
                          if (selectedManager)
                            selectedManager.selectedIndex = 0;
                          var selectedProject = document.getElementById("select-project");
                          if (selectedProject)
                            selectedProject.selectedIndex = 0;
                        }}
                        title="Reset"
                      />
                    </div>
                  </div>

                </div>
                : null
            }

          </>
      }












      <div
        style={{
          width: "90%",
          height: "60vh",
          //   border: "1px solid black",
          display: "flex",
          flexDirection: width > 700 ? 'row' : 'column',


        }}
      >
        <div
          style={{
            width: "35%",
            display: "flex",
            flexDirection: "column",
            padding: "1rem",
            gap: width < 700 ? "1rem" : "4rem",
            justifyContent: "space-between",
            padding: "2rem 1rem",
            marginTop: width < 700 ? 5 : 50,
            marginLeft: width < 700 ? "20%" : "0%"

          }}
        >
          <div
            style={{
              width: width < 700 ? 188 : "17vw",
              height: width < 700 ? 100 : "10rem",
              border: width < 700 ? "1px solid #D4D4D4" : 0,
              borderRadius: "15px",
              background: "#fff",
              display: "flex",
              alignItems: 'center',
              justifyContent: 'space-around',
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
            }}
          >
            <div
              style={{
                width: width < 700 ? 60 : "5.5vw",
                background: "#FFCCAA",
                borderRadius: width < 700 ? 25 : "15px",
                display: "flex",
                height: width < 700 ? 50 : '100%',
                justifyContent: "center",
                alignItems: "center",
                marginLeft: width < 700 ? 10 : 0
              }}
            >
              <img
                style={{ width: "2rem", height: "2rem" }}
                src={profile_card_icon}
              />
            </div>
            <div
              style={{
                width: "100%",
                // border: "1px solid red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h4 style={{ color: "#7BC0FF" }}>{viewClass?.associate ? "My Output" : "My PMO"}</h4>
              <h2>{projectDashBoardData?.my_pmo ? projectDashBoardData?.my_pmo : 0}</h2>
            </div>
          </div>
          {(viewClass.superAdmin || viewClass.hod || viewClass.manager) ?
            <div
              style={{
                width: width < 700 ? 188 : "17vw",
                height: width < 700 ? 100 : "10rem",
                border: width < 700 ? "1px solid #D4D4D4" : 0,
                borderRadius: "15px",
                background: "#fff",
                display: "flex",
                alignItems: 'center',
                justifyContent: 'space-around',
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"

              }}
            >
              <div
                style={{
                  width: width < 700 ? 60 : "5.5vw",
                  background: "#FFCCAA",
                  borderRadius: width < 700 ? 25 : "15px",
                  display: "flex",
                  height: width < 700 ? 50 : '100%',
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: width < 700 ? 10 : 0

                }}
              >
                <img
                  style={{ width: "1.5rem", height: "1.5rem" }}
                  src={group_card_icon}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  // border: "1px solid red",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <h4 style={{ color: "#7BC0FF" }}>Selected PMO</h4>
                <h2>{projectDashBoardData?.pmo ? projectDashBoardData?.pmo : 0}</h2>
              </div>
            </div> : null}
        </div>
        {(viewClass.superAdmin || viewClass.hod || viewClass.manager) ?
          <div
            style={{
              width: width < 700 ? "100%" : "90%",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              marginLeft: width < 700 ? 0 : "5rem",
              padding: "1rem",
              background: "#fff",
              borderRadius: "15px",
              overflowX: width < 700 ? 'visible' : "",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <h4>Deployment Efficiency</h4>
            </div>
            {
              graphData?.length ?
                <DashboardGraph data={graphData} color={"#CC6760"} />
                :
                <NoData></NoData>
            }
          </div> : null}
      </div>

    </div>
  );
}

export default Dashboard;
