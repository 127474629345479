import React from "react";
import classes from "./leave.module.css"

 

export const Cards=(props)=>{
    return(
        <div className={classes.cards} style={{height:"13vh", minWidth:props?.width<700?"60%":null, alignItems:"center"}}>
            <div style={{width:props?.width<700?"100%":props?.widthInner?props?.widthInner:null}}>
                <div style={{ width:props?.width<700?"30%":null}}>
                    <img style={{height:40,width:40}} src={props?.icon} alt="" />
                </div>
                <div style={{marginLeft:10}}>
                    <div>
                    <div style={{marginBottom:5, color:"#16243280"}}>{props?.leaves} {props?.leaves === "Comp Off" ? "":"Leave"}</div>
                    <p style={{fontSize:16}}> {props?.leaveCount?props?.leaveCount:0}</p>
                    </div>
                </div>
            </div>
        </div>
    )}
