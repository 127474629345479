import React, { useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';

import '../../../design/framework.css'
import { allCity, allGeolocation, allHolidays, allLeave, deleteCity,allHolidaysDeptAssociate, deleteGeoLocation, deleteHoliday, deleteLeave,deleteDeptAssociateHoliday } from './Action/action';
import { useNotificationStore } from './store';
import { Button } from 'antd';
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";

const TextComponent = ({ text, width, fontSize, backgroundColor, marginLeft, color, marginRight, fontWeight }) => {
    return (
        <div style={{ fontSize: '20px', width: width, fontSize: fontSize, marginLeft: marginLeft, color: color, marginRight: marginRight, fontWeight: fontWeight }}>
            {text}
        </div>
    );
};

function DeleteModel({ setCenterModel, setDelete, }) {
    const {  setAllCityStore,searchVal, deleteStore,  recordsStore, setgeoLoactionStore, deleteCityStore, 
        notFixed, setleaveStore, settypeLoactionStore, setLoad, fixedStore,holidayListBy } = useNotificationStore();
    const [loading, setloading] = useState(false)
    const {width}=useWindowDimensions();


    return (

        <>

            <div style={{ padding: 10 }}>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}>


                    <>
                        <div className='r-jc' style={{ display: 'flex', alignItems: 'center' }}>
                            <AiFillDelete className='p-2' color='#F96363' fontSize={25} />
                            <TextComponent className='p-2' text="Delete" fontSize={17} color={'#F96363'} fontWeight={700} />
                        </div>
                    </>

                    <div style={{ width: '100%', height: 0.8, backgroundColor: '#F1F1F1', marginTop: 10 }} />

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>

                        {
                            deleteStore == 'locationCity' &&
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    // Adjust the height as needed
                                }}
                            >
                                <div style={{ fontSize: '16px', fontWeight: '600', textAlign: 'center' }}>
                                    Are you sure to delete{' '}
                                    <span style={{ color: '#0086FF', display: 'block' }}>{` “ ${recordsStore?.cityName} “ `}</span> Leave from Leave Type?
                                </div>
                            </div>
                        }
                        {
                            deleteStore == 'Geofence' &&
                            <div style={{ fontSize: '16px', fontWeight: '600', alignItems: 'center', justifyContent: 'center' }}>
                                Are you sure to delete
                                <span style={{ color: '#0086FF' }}>{` “ ${recordsStore?.name} “ `}</span>
                                Location from Geofence?
                            </div>
                        }
                        {
                            deleteStore == 'fixed' &&
                            <div style={{ fontSize: '16px', fontWeight: '600', alignItems: 'center',display:"flex",flexDirection:"column", justifyContent: 'center',marginTop:"0.5rem" }}>
                                <span>Are you sure to delete 
                                <span style={{ color: '#0086FF' }}>{`  ${recordsStore?.accountOf}  `}</span>{console.log("sssRecordStore",recordsStore,fixedStore)}
                                from {recordsStore?.typeOfLeave === "Optional" ? "Optional":"Fixed"} Holiday {width < 700 && ` in ${recordsStore?.cityName}?`}</span>
                                {width>700&&<span> in {holidayListBy === "Location" ? recordsStore?.cityName:fixedStore?.cityName}?</span>}
                            </div>
                        }
                        {
                            deleteStore == "Location Holidays" &&
                            <div style={{ fontSize: '16px', fontWeight: '600', alignItems: 'center',display:"flex",flexDirection:"column", justifyContent: 'center',marginTop:"0.5rem" }}>
                            <span>Are you sure to delete all Holidays from <span style={{ color: '#0086FF' }}>{recordsStore?.cityName}</span> Location?</span>
                            {/* {width>700&&<span>Holiday Locations? </span>} */}
                            </div>
                        }
                        {
                            deleteStore == 'Leave' &&
                            <div style={{ fontSize: '16px', fontWeight: '600', alignItems: 'center', justifyContent: 'center' }}>
                                Are you sure to delete
                                <span style={{ color: '#0086FF' }}>{` “ ${recordsStore?.leaveName} “ `}</span>
                                Leave?
                            </div>
                        }
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',marginTop:"1.5rem" }}>
                        <Button
                            loading={loading}
                            onClick={() => {
                                console.log("kn fj j", recordsStore);

                                if (deleteStore == 'locationCity') {
                                    setloading(true)
                                    deleteCity(recordsStore?.id, () => {
                                        allCity((res) => {
                                            setloading(false)
                                            if (searchVal) {
                                                let filteredData = res.filter((item) =>
                                                item.cityName.toLowerCase().includes(searchVal.toLowerCase())
                                                );
                                                setAllCityStore(filteredData);
                                            }
                                            else{
                                                setAllCityStore(res)
                                            }    
                                            // setAllCityStore(res)
                                            setCenterModel(false)
                                            setDelete(false)
                                        })
                                    })
                                }
                                if (deleteStore == 'Geofence') {
                                    setloading(true)
                                    deleteGeoLocation({ id: recordsStore?.id }, () => {
                                        allGeolocation({ limit: 10, pageNo: 1,search:searchVal }, (res) => {
                                            // setRefresh(false)
                                            setloading(false)
                                            setCenterModel(false)
                                            setgeoLoactionStore(res)
                                        })
                                    });
                                }
                                if (deleteStore == 'Leave') {
                                    setloading(true)
                                    deleteLeave(recordsStore?.id, () => {
                                        allLeave({ limit: 100, pageNo: 1, }, (res) => {
                                            setloading(false)

                                            setCenterModel(false)
                                            setleaveStore(res)
                                        })
                                    });
                                }
                                if (deleteStore == 'fixed') {
                                  setloading(true)
                                  if(holidayListBy === "Location"){
                                    deleteHoliday(recordsStore?.id, () => {
                                        if (notFixed) {
                                            allCity((res) => {
                                                if (searchVal) {
                                                    let filteredData = res.filter((item) =>
                                                    item.cityName.toLowerCase().includes(searchVal.toLowerCase())
                                                    );
                                                    setAllCityStore(filteredData);
                                                }
                                                else{
                                                    setAllCityStore(res)
                                                }    
                                                // setAllCityStore(res)
                                                setCenterModel(false)
                                                setLoad(false)
                                            })
                                        } else {
                                            let url = `/attendance-setting/allHolidays?pageNo=${1}&limit=${100}&download=${false}&holidayType=${fixedStore?.type}&cityId=${fixedStore?.id}`;
                                            setLoad(true)
                                            allHolidays(url, (res) => {
                                                settypeLoactionStore(res)
                                                setCenterModel(false)
                                                setLoad(false)
                                            })
                                        }
                                    }, deleteCityStore);
                                  }
                                  else{
                                    deleteDeptAssociateHoliday(holidayListBy,recordsStore?.id,fixedStore?.id, (res) => {
                                        if(res?.show?.type === "success"){
                                            let url = `/attendance-setting/getHoidayDataByType?holidayType=${fixedStore?.type}&id=${fixedStore?.id}&type=${holidayListBy}`;
                                                           
                                            allHolidaysDeptAssociate(url,(res)=>{
                                               settypeLoactionStore(res)
                                               setLoad(false)
                                               setCenterModel(false)
                                            }) 
                                        }
                                    });
                                  }
                                }
                                if (deleteStore == 'Location Holidays') {
                                    setloading(true)
                                    deleteHoliday(recordsStore?.id, () => {
                                        if (notFixed) {
                                            allCity((res) => {
                                                if (searchVal) {
                                                    let filteredData = res.filter((item) =>
                                                    item.cityName.toLowerCase().includes(searchVal.toLowerCase())
                                                    );
                                                    setAllCityStore(filteredData);
                                                }
                                                else{
                                                    setAllCityStore(res)
                                                }    
                                                // setAllCityStore(res)
                                                setCenterModel(false)
                                                setLoad(false)
                                            })

                                        // } else {
                                        //     let url = `/attendance-setting/allHolidays?pageNo=${1}&limit=${100}&download=${false}&holidayType=${fixedStore?.type}&cityId=${fixedStore?.id}`;
                                        //     setLoad(true)
                                        //     allHolidays(url, (res) => {
                                        //         settypeLoactionStore(res)
                                        //         setCenterModel(false)
                                        //         setLoad(false)
                                        //     })
                                        }
                                    }, deleteCityStore);

                                }
                                //  setRefresh(true)
                                // if (type == "leave") {
                                //     deleteLeave(record?.id, () => {
                                //      allLeave({ limit: 100, pageNo: 1 }, (res) => {
                                //                 setRefresh(false)
                                //                 setCenterModel(false)

                                //                 setAllLeaves(res)
                                //               })


                                //     })
                                // } else {
                                //     deleteGeoLocation({ id: record?.id }, () => {
                                //        allGeolocation({ limit: 100, pageNo: 1 }, (res) => {
                                //                 setRefresh(false)
                                //                 setCenterModel(false)
                                //                 setAllGeoLoaction(res)
                                //             })

                                //     });
                                // }
                            }

                            }
                            style={{ background: '#0086FF', color: 'white', borderRadius: 8,width:"5.5rem", alignItems: 'center',fontSize:15 }}>Yes</Button>
                        <Button
                            // loading={loading}
                            onClick={() => {
                                setCenterModel(false)
                                setDelete(false)
                            }} style={{ color: '#0086FF', borderRadius: 8, alignItems: 'center',width:"5.5rem", margin: 10, borderWidth: 1,border: "1px solid #0086FF",fontSize:15 }}>No</Button>

                    </div>

                </div>
            </div>
        </>
    );
}

export default DeleteModel;