import TableCustom from "../../../components/tableCustom/customTable";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import { NoData } from "../../Training_essentialDocuments";
import { MobileCard } from "../Release/Release";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';


const ListViewLogData = ({ ids ,setSecLoader}) => {
    const { width } = useWindowDimensions();
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 30,
            }}
            spin
        />
    );
    const column = {
        0: {
            cols: "Associate Name",
            width: "20%",
            innerWidth:"5rem",
            dataKey: "nameC",
            onclick: () => { }
        },
        1: {
            cols: "Contact Details",
            width: "37%",
            // innerWidth: "5rem",
            dataKey: "contactDetails",
            onclick: () => { }
        },
        2: {
            cols: "Effort",
            width: "15%",
            innerWidth: "5rem",  
            dataKey: "effort",
            onclick: () => { }
        },
       
        3: {
            cols: "Actions",
            width: "15%",
            dataKey: "actions",
            onclick: () => { }
        },
    }
    return (
        <>
            {
                width < 700 ?
                    <>
                        {
                            setSecLoader ?
                                <div style={{ display: "flex", height: "80vh", width: "100%", justifyContent: "center", alignItems: "center" }}>
                                    <Spin indicator={antIcon} />
                                </div>
                                :
                                ids?.list?.length ?
                                    <>
                                        {
                                            ids?.list?.map((item) => (
                                                <MobileCard
                                                    body={
                                                        (
                                                            <>
                                                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                    <div style={{ width: "30%", color: "#B1B0B0" }}>Email : </div>
                                                                    <div style={{ width: "60%", display: "flex", justifyContent: "flex-start", textOverflow: "ellipsis", overflow: 'hidden', }}>{item?.email}</div>
                                                                </div>
                                                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                    <div style={{ width: "30%", color: "#B1B0B0" }}>Phone : </div>
                                                                    <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.phone}</div>
                                                                </div>
                                                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                    <div style={{ width: "30%", color: "#B1B0B0" }}> Effort: </div>
                                                                    <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.effort} hrs</div>
                                                                </div>
                                                               

                                                            </>
                                                        )
                                                    }
                                                    headerRight={item?.actions}
                                                    headerLeft={item?.nameC} />

                                            ))
                                        }
                                    </>
                                    :
                                    <>
                                        <NoData text={ids?.list === undefined?"Choose your Department & Team to continue!":!ids?.teamIds?.length?"Choose your Department & Team to continue!":!ids?.list?.length?"No Data":"Choose your Department & Team to continue!"}/>
                                    </>
                        }
                    </>
                    :
                    <>
                        <TableCustom
                            columns={column}
                          dataSource={ids?.list} 
                          loading={setSecLoader} 
                          EmptyText={ids?.list === undefined?"Choose your Department & Team to continue!":!ids?.teamIds?.length?"Choose your Department & Team to continue!":!ids?.list?.length?"No Data":"Choose your Department & Team to continue!"}
                        /*   pagination={
                              {
                              total: parseInt(associateInfo?.meta?.totalItems),
                              current: parseInt(paginationPage),
                              showSizeChanger: true,
                              pageSizeOptions: [10,20, 30, 50, 100],
                              pageSize: pageSize,                
                              onChange: (page,pageSize) =>{
                                  setPaginationPage(page);
                                  setPageSize(pageSize);
                              }, 
                              }} */
                        />
                    </>
            }

        </>
    )
}


export default ListViewLogData;
