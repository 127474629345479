import DonutChart from "../../../components/DonutChart/donutChart";
import classes from "./Project.module.css";

function TaskOverview({data, project}){
console.log("junia",data)
    const tasks = [
        {
            name: "New",
            value:data?.new?data?.new:0,
            color: "#B33DC6",
          },
          {
            name: "Ongoing",
            value:data?.ongoing?data?.ongoing:0,
            color: "#33A0FF",
          },
          {
            name: "Completed",
            value:data?.completed?data?.completed:0,
            color: "#24C850",
          },
          {
            name: "Overdue",
            value:data?.overdue?data?.overdue:0,
            color: "#F46A9B",
          },
          {
            name: "On Hold",
            value:data?.onHold?data?.onHold:0,
            color: "#EDBF33",
          },
          {
            name: "Suspended",
            value:data?.suspended?data?.suspended:0,
            color: "#878787",
          },
    ]
    return(
        <div className={classes.graphMain} >
            <div style={{fontSize:"1.1rem",color:'#162432',fontWeight:"600",color:'#162432'}}  >Task Overview</div>
            <DonutChart project={project}  data={tasks}  />
        </div>
    )
}
export default TaskOverview;