import { Modal, Button, DatePicker, Divider, TimePicker, Select } from "antd";
import { useEffect, useState } from "react";
import { getManualPunch } from "../action/action";
import moment from "moment";
import { useAttendnaceStore } from "../store/store";
import dayjs from "dayjs";
import Notification from "../../../services/Notification";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import { useParams } from "react-router";

const ManualPunchModal = ({ visible, onCancel, reload, uiSelectId }) => {
  const { selectedUser, manualPunchModal, setManualPunchModal } =
    useAttendnaceStore((state) => state);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [checkInTime, setCheckInTime] = useState(null);
  const [checkOutTime, setCheckOutTime] = useState(null);
  const [timeDifference, setTimeDifference] = useState(0);
  const [disableSave, setDisableSave] = useState(true);
  const [inTime, setInTime] = useState(null);
  const [outTime, setOutTime] = useState(null);
  const { width } = useWindowDimensions();
  const { associateId } = useParams();
  const [workType,setWorkType] = useState(null)

  const dateFormat = "YYYY-MM-DD"

  console.log(uiSelectId,selectedUser,"kkklklklkl");

  const handleFromDate = (date, dateString) => {
    if (dateString) {
      setFromDate(dateString);
    }
    else {
      setFromDate(null);
    }
  };
  const handleToDate = (date, dateString) => {
    setToDate(dateString);

  };
  const handleCheckIn = (time, timeString) => {

    setCheckInTime(time);
    setInTime(timeString);
    calculateTimeDifference(time, checkOutTime);

  };
  const handleCheckOut = (time, timeString) => {

    setCheckOutTime(time);
    setOutTime(timeString);
    calculateTimeDifference(checkInTime, time);

  };
  const calculateTimeDifference = (checkIn, checkOut) => {
    if (checkIn && checkOut) {
      const diffInMilliseconds = checkOut.diff(checkIn);
      const diffInHours = moment
        .duration(diffInMilliseconds)
        .asHours()
        .toFixed(1);
      setTimeDifference(`${diffInHours} hrs`);
    } else {
      setTimeDifference("0.0 hrs");
    }
  };

  useEffect(() => {
    if (fromDate && toDate && checkInTime && checkOutTime && (workType!==null)) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  }, [fromDate, toDate, checkInTime, checkOutTime]);

  const handleLogoutTimeOpenChange = (open) => {
    if (open && checkInTime) {
      const disabledHours = () => {
        const hours = [];
        for (let i = 0; i < checkInTime.hour(); i++) {
          hours.push(i);
        }
        return hours;
      };

      const disabledMinutes = (selectedHour) => {
        if (selectedHour === checkInTime.hour()) {
          const minutes = [];
          for (let i = 0; i < checkInTime.minute(); i++) {
            minutes.push(i);
          }
          return minutes;
        }
        return [];
      };

      setCheckOutTime(null);
      // Notification.warning('Logout time should be greater than login time');
      return { disabledHours, disabledMinutes };
    }
  };


  return (
    <Modal
      title={<div style={{ color: "#0086FF" }}>Manual Punch</div>}
      width={600} centered
      open={manualPunchModal}
      onCancel={() => {
        setFromDate(null);
        setToDate(null);
        setCheckInTime(null);
        setCheckOutTime(null);
        setTimeDifference(null);
        setManualPunchModal(false);
      }}
      footer={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: 5,
            margin: 20,
          }}
        >
          <Button
            key="save"
            type="primary"
            style={{ width: "5.5rem" }}
            onClick={() => {
              getManualPunch(
                {
                  // userId: uiSelectId ? uiSelectId : selectedUser?.userId,
                  userId: uiSelectId ? uiSelectId : Object.keys(selectedUser).length > 0 ? selectedUser?.userId : associateId,
                  fromDate: moment(fromDate).format("YYYY-MM-DD"),
                  toDate: moment(toDate).format("YYYY-MM-DD"),
                  check_in: inTime,
                  check_out: outTime,
                  workType:workType
                },
                setManualPunchModal(false)
              );
              setTimeout(() => { reload(); setManualPunchModal(false); }, 1000)

            }}
            disabled={disableSave}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              setFromDate(null);
              setToDate(null);
              setCheckInTime(null);
              setCheckOutTime(null);
              setTimeDifference(null);
              setManualPunchModal(false);
            }}
            key="cancel"
            style={{ width: "5.5rem" }}
          >
            Cancel
          </Button>
        </div>
      }
    >
      {/* <Divider></Divider> */}
      <div style={{ padding: 20, width: "100%" }}>
        <div>
        <div>Work type</div>
          <Select placeholder='Select work type' style={{width:"100%", marginTop:5,marginBottom:10}} onChange={(e)=>{
            setWorkType(e)
          }}>
            <Select.Option value="WFO">WFO</Select.Option>
            <Select.Option value="WFH">WFH</Select.Option>
          </Select>
        </div>
        <div>
          <div>From Date</div>
          <DatePicker
            // defaultValue={fromDate === null ? null : dayjs(fromDate, dateFormat)}
            value={fromDate === null ? null : dayjs(fromDate, dateFormat)}
            onChange={handleFromDate}
            style={{ width: "100%", marginTop: 10 }}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <div>To Date</div>
          <DatePicker
            onChange={handleToDate}
            value={toDate !== null && toDate !== "" && toDate !== undefined ? dayjs(toDate, dateFormat) : undefined}
            style={{ width: "100%", marginTop: 10 }}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <div>Check In </div>
          <TimePicker
            format="HH:mm"
            onChange={handleCheckIn}
            style={{ width: "100%", marginTop: 10 }}
            value={checkInTime !== null ? dayjs(checkInTime, "HH:mm") : null}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <div>Check Out </div>
          <TimePicker
            format="HH:mm"
            onChange={handleCheckOut}
            style={{ width: "100%", marginTop: 10 }}
            value={checkOutTime !== null ? dayjs(checkOutTime, "HH:mm") : null}
          // onOpenChange={handleLogoutTimeOpenChange}
          />
        </div>

        <div style={{ marginTop: 20 }}>
          Worked Hours: &nbsp;{timeDifference}
        </div>
      </div>
    </Modal>
  );
};

export default ManualPunchModal;
