import React, { useState } from "react";
import Modal from "../../../components/Modal/Modal";
import { useHealthInsuranceStore } from "../Store";
import classes from './PreviewModal.module.css';
import { ModalPrimaryButton } from "../../../components/Button/Button";
import pdf_doc_logo from './../../../assets/pdf_box.svg';
import { Popover, Spin, Typography } from "antd";
import { deleteSingleDocumentApi } from "../Actions";
import { UploadOutlined } from "@ant-design/icons";
import DeleteEmployeeModal from "../../../components/deleteModal";
const PreviewModal=({insurranceCard,refresh,employeeInfo})=>{
    const {previewModal,previewModalHandler,uploadModalHandler}=useHealthInsuranceStore();
    const [previewLoader,setPreviewLoader]=useState(false);
    const[deleteModal,setDeleteModal]=useState(false);
    const [docId,setDocId]=useState();
   
    return(
        <div>
        <Modal show={previewModal}
        closableRight={true}
        style={{zIndex:100}}
        header={<div className={classes.header}>
            <div>Documents Uploaded</div>
        </div>}
        onCancel={()=>{
            previewModalHandler(false);
        }}
        >
            {previewModal&&<div className={classes.previewModal}>
                {previewLoader?<Spin></Spin>:
                
                <div>
                    <div  className={classes.headerDiv}>
                        <div>{employeeInfo?.name}</div>
                        <button onClick={()=>{
                            uploadModalHandler(true);
                            previewModalHandler(false);
                        }}> <UploadOutlined/> Upload</button>
                    </div>
                <div className={classes.documents}>
                    {insurranceCard?.map((item,index)=>{
                        return(
                            <div className={classes.eachItem}>
                               <div>
                                        <img src={pdf_doc_logo} onClick={()=>{
                                            window.open(item.filePath,"_blank")?.focus();
                                        }}/>
                               </div>
                               
                                <div >
                                    <Typography.Text style={{maxWidth:"14rem",fontSize:"0.8rem",fontWeight:"600",fontFamily:"Lato"}} ellipsis={{tooltip:true}}>
                                    {item.fileName} 
                                    </Typography.Text>

                                    <Popover
                                     trigger="hover"
                                     placement="bottom"
                                     content={<span onClick={()=>{
                                        setDeleteModal(true);
                                        setDocId(item?.id);
                                     }} style={{fontSize:"0.8rem",fontWeight:"500",cursor:"pointer"}}>Delete</span>}
                                    >
                                        <span style={{fontSize:"1rem",fontWeight:"600",cursor:"pointer"}}>...</span>
                                    </Popover>                          
                                </div>                               
                            </div>
                        )
                    })}
                </div>
                </div>
                }

                <div className={classes.buttonDiv}>
                    <ModalPrimaryButton style={{padding:"0.5rem 2rem",fontSize:"1rem"}} onSave={()=>{
                        previewModalHandler(false);
                    }}>Close</ModalPrimaryButton>
                </div>

            </div>}
        </Modal>

        <DeleteEmployeeModal modal={deleteModal} setModal={setDeleteModal} DeleteHandler={()=>{
             setPreviewLoader(true);
             deleteSingleDocumentApi([docId],()=>{
                 refresh();
                 setPreviewLoader(false);
                 setDeleteModal(false);
             });
      }}/>

        

        
        </div>
    )
}
export default PreviewModal;