import React from "react";
import classes from './profilePersonal.module.css';
import { useResourceStore } from "../../store/store";
import { EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { KeyIsNotEmpty } from "./formData";
import { Typography } from "antd";
const ProfilePersonal=(props)=>{
    const {page,setPage,setTab}=useResourceStore(state=>state)

    const userFlow=props?.userFlow;
    const teamAssign=userFlow?.filter(item=>item?.flowStatus==="Team Assigned")?.[0];
    const teamRelease=userFlow?.filter(item=>item?.flowStatus==="Team Released")?.[0];
    const deptAssign=userFlow?.filter(item=>item?.flowStatus==="Department Assigned")?.[0];
    const deptRelease=userFlow?.filter(item=>item?.flowStatus==="Department Released")?.[0];

    const cardContent=[
        {
            title:"Personal Information",
            key1:"Associate Name",
            value1:`${props?.profile?.associateProfile?.associateName ? props?.profile?.associateProfile?.associateName : "NA"}`,
            key2:"Employee ID",
            value2:`${props?.profile?.associateProfile?.uid ? props?.profile?.associateProfile?.uid : "NA"}`,
            key3:"Joining Date",
            value3:`${props?.profile?.associateProfile?.joined? moment(props?.profile?.associateProfile?.joined).format('DD-MMM-YYYY'): "NA"}`,
            key4:"Designation",
            value4:`${props?.profile?.associateProfile?.designation ? props?.profile?.associateProfile?.designation :"NA"}`,
        },
    ]
    return(
        <div className={classes.cardContainer}>
            {cardContent?.map((item,index)=>{
                return( 
                    <div key={index} className={classes.card}>
                         <div>
                                    <div style={{font:'normal 600 18px/24px Lato',color:'162432'}}>{item.title}</div>
                                    {item.title==="Personal Information" &&
                                    <div style={{display:'flex',gap:'6px',color:'#0086ff'}} onClick={()=>{
                                        setPage({prev:page?.curr, curr:"editProfile",PD:'Profile'})
                                    setTab("Personal and Contact Data");}}>
                                        <EditOutlined />
                                        <span>Edit</span>
                                    </div>
                                    }
                        </div>
                        <div>
                                <div>
                                        <div>
                                                <span className={classes.headergrayH}>{item.key1}:</span>
                                                <Typography.Text className={classes.headerBlack} ellipsis={{tooltip:true}} style={{maxWidth:'30vw'}}>{item.value1}</Typography.Text>
                                        </div>
                                        <div>
                                                <span className={classes.headergrayH}>{item.key2}:</span>
                                                <span className={classes.headerBlack}>{item.value2}</span>
                                        </div>
                                </div>

                                <div>
                                        <div>
                                                <span className={classes.headergrayH}>{item.key3}:</span>
                                                <span className={classes.headerBlack}>{item.value3}</span>
                                        </div>
                                        <div>
                                                <span className={classes.headergrayH}>{item.key4}:</span>
                                                <span className={classes.headerBlack}>{item.value4}</span>
                                        </div>
                                </div>
                        </div>
                    </div>
                )
            })}
        {userFlow?.map((val,index)=>{
          if(val.flowStatus!=='Joined')
          {
            if(val.flowStatus && val.flowStatus.includes('Department')){
            return(
                <div key={index} className={classes.card}>
                <div style={{font:'normal 600 18px/24px Lato',color:'162432'}} >{val?.flowStatus}</div>
                <div>
                    <div>
                                        <div>
                                                <span className={classes.headergrayH}>{val?.flowStatus?.includes('Released')?'Release':'Assign'} Date :</span>
                                                <span className={classes.headerBlack}>{moment(val?.date).format("DD-MMM-YYYY")}</span>
                                        </div>
                                        <div>
                                                <span className={classes.headergrayH}>{val?.flowStatus?.includes('Released')?'Released':'Assigned'} By : </span>
                                                <span className={classes.headerBlack}>{KeyIsNotEmpty(val?.assignedBy)?val?.assignedBy:'NA'}</span>
                                        </div>
                        </div>
                    <div>
                                        <div>
                                                <span className={classes.headergrayH}>Dept. Name :</span>
                                                <span className={classes.headerBlack}>{KeyIsNotEmpty(val?.department)?val?.department:'NA'}</span>
                                        </div>
                                        <div>
                                                <span className={classes.headergrayH}>Reason :</span>
                                                <Typography.Text className={classes.headerBlack} ellipsis={{tooltip:true,}} style={{maxWidth:'25vw'}}>{KeyIsNotEmpty(val?.reason)?val?.reason:'NA'}</Typography.Text>
                                        </div>
                        </div>
                    </div>
                </div>
            )
            }
            else if(val.flowStatus && val.flowStatus.includes('Team')){

              return(
                <div key={index} className={classes.card}>
               <div style={{font:'normal 600 18px/24px Lato',color:'162432'}}>{val?.flowStatus}</div>
               <div>
                    <div>
                                        <div>
                                                <span className={classes.headergrayH}>{val?.flowStatus?.includes('Released')?'Release':'Assign'} Date :</span>
                                                <span className={classes.headerBlack}>{moment(val?.date).format("DD-MMM-YYYY")}</span>
                                        </div>
                                        <div>
                                                <span className={classes.headergrayH}>{val?.flowStatus?.includes('Released')?'Released':'Assigned'} By : </span>
                                                <Typography.Text className={classes.headerBlack} ellipsis={{tooltip:true,}} style={{maxWidth:'30vw'}}>{KeyIsNotEmpty(val?.assignedBy)?val?.assignedBy:'NA'}</Typography.Text>
                                        </div>
                        </div>
                    <div>
                                        <div>
                                                <span className={classes.headergrayH}>Dept. Name :</span>
                                                <span className={classes.headerBlack}>{KeyIsNotEmpty(val?.team)?val?.team:'NA'}</span>
                                        </div>
                                        <div>
                                                <span className={classes.headergrayH}>Reason :</span>
                                                <Typography.Text className={classes.headerBlack} ellipsis={{tooltip:true,}} style={{maxWidth:'25vw',font:'normal '}}>{KeyIsNotEmpty(val?.reason)?val?.reason:'NA'}</Typography.Text>
                                        </div>
                        </div>
                    </div>
                </div>
            )
            }
            
            }
        })}

        </div>
    )
}
export default ProfilePersonal;

// {
//     title:"Dept. Assign",
//     key1:"Assign Date",
//     value1:`${deptAssign?.date ?moment(deptAssign?.date).format('DD-MMM-YYYY') :"NA"}`,
//     key2:"Assigned By",
//     value2:`${deptAssign?.assignedBy ? deptAssign?.assignedBy :"NA"}`,
//     key3:"Dept. Name",
//     value3:`${deptAssign?.department?deptAssign?.department :"NA"}`,
//     key4:"Reason",
//     value4:`${deptAssign?.reason ? deptAssign?.reason :"NA"}`,
// },
// {
//     title:"Team Assign",
//     key1:"Assign Date",
//     value1:`${teamAssign?.date ?moment(teamAssign?.date).format('DD-MMM-YYYY') :"NA"}`,
//     key2:"Assigned By",
//     value2:`${teamAssign?.assignedBy ? teamAssign?.assignedBy :"NA"}`,
//     key3:"Team Name",
//     value3:`${teamAssign?.team ? teamAssign?.team :"NA"}`,
//     key4:"Reason",
//     value4:`${teamAssign?.reason ? teamAssign?.reason :"NA"}`,
// },
// {
//     title:"Team Release",
//     key1:"Release Date",
//     value1:`${teamRelease?.date ? moment(teamRelease?.date).format('DD-MMM-YYYY') :"NA"}`,
//     key2:"Released By",
//     value2:`${teamRelease?.assignedBy ? teamRelease?.assignedBy :"NA"}`,
//     key3:"Team Name",
//     value3:`${teamRelease?.team ? teamRelease?.team :"NA"}`,
//     key4:"Reason",
//     value4:`${teamRelease?.reason ? teamRelease?.reason :"NA"}`,
// },

// {
//     title:"Dept. Release",
//     key1:"Release Date",
//     value1:`${deptRelease?.date ?moment(deptRelease?.date).format('DD-MMM-YYYY') :"NA"}`,
//     key2:"Released By",
//     value2:`${deptRelease?.assignedBy ? deptRelease?.assignedBy :"NA"}`,
//     key3:"Dept. Name",
//     value3:`${deptRelease?.department ? deptRelease?.department :"NA"}`,
//     key4:"Reason",
//     value4:`${deptRelease?.reason ? deptRelease?.reason :"NA"}`,
// },

{/* <div >
                    <div >Dept. Name : </div>
                    <div>{KeyIsNotEmpty(val?.department)?val?.department:'NA'}</div>
                    </div>
                    <div>
                        <div > {val?.flowStatus?.includes('Released')?'Release':'Assign'} Date : </div>
                        <div>{moment(val?.date).format("DD-MMM-YYYY")}</div>
                    </div>
                    <div>
                        <div >{val?.flowStatus?.includes('Released')?'Released':'Assigned'} By : </div>
                        <div>{KeyIsNotEmpty(val?.assignedBy)?val?.assignedBy:'NA'}</div>
                    </div>
                    <div>
                        <div >Reason : </div>
                        <div>{KeyIsNotEmpty(val?.reason)?val?.reason:'NA'}</div>
                    </div> */}