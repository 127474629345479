import { ModalPrimaryButton, ModalSecondaryButton } from "./Button/Button";
import ModalCompo from "../design/ModalCompo";
import DeleteIcon from "../assets/DeleteIcon.svg";
import Modal from "./Modal/Modal";
import { useWindowDimensions } from "./windowDimention/windowDimension";


function DeleteEmployeeModal({modal,setModal,DeleteHandler,content}){
const {width}=useWindowDimensions();
   
    return(
        <>
        <Modal show={modal}
        closableRight={true}
        
        header={
            <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",gap:"0rem",}}>
                <img src={DeleteIcon}  style={{width:"1.8rem",height:"1.8rem"}}/>
                <h4 style={{color:'#F96363',fontSize:"1rem"}}>Delete</h4>
            </div>
        }
       
        onCancel={()=>{
            setModal(false);
        }}
        >
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'1rem',flexDirection:'column'}}>
            <div style={{fontSize: "1rem",color:'#162432',width:width<700?"80vw":"65vw",display:"flex",justifyContent:"center",alignItems:"center",margin:"1rem 0 0 2rem"}}>{content?content:"Do you really want to delete this document(s)?"}</div>
            <div style={{display:'flex',gap:'1rem',justifyContent:'center',marginTop:'1rem',marginBottom:'1rem'}}>
            <ModalPrimaryButton onSave={()=>{DeleteHandler();}}>Yes</ModalPrimaryButton>
            <ModalSecondaryButton  onCancel={()=>{setModal(false)}}>No</ModalSecondaryButton>
            </div>
        </div>
        
        </Modal>
        </>
    )       
}
export default DeleteEmployeeModal;