import React, { useEffect, useState } from "react";
import classes from "./style.module.css";
import { Button, Card, Divider, Radio, Spin, Tabs,Modal } from "antd";
import backIcon from "../../../assets/backIcon.svg";
import useStore from "./store";
import CardList from "./cardList";
import ProfileDetails from "./profileDetails";
import { getAssociateSummary,carryForwardButton } from "../action/action";
import viewClass from "../../../services/ViewRole";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import profileIcon from "../../../assets/Profile Icon.svg";
import MobileBackIcon from "../../../assets/mobileBackIcon.svg";
import moment from "moment";
import Api from "../../../services/Api";

const { TabPane } = Tabs;

const AssociateSummary=()=>{
  const { LeaveModal,  leavesData, setSummaryData, summaryData, selectedUserData,setSelectedData } = useStore(state => state);
    const [leaveTab,setLeaveTab]=useState("Fixed Holidays");
    const [loader,setLoader]=useState(false);
    const {width}=useWindowDimensions();
    const [carryForwardModal,setCarryForwardModal]=useState(false)
    const [halfValues,setHalfValues]=useState(false);
    const [halfValNumber,setHalfValNumber]=useState(null);

    useEffect(()=>{
        setLoader(true)
        getAssociateSummary((res)=>{
            setLoader(false)
            setSummaryData(res)},{userId:selectedUserData.userId,type:leaveTab.split(" ")?.[0]})
        },[leaveTab])
       
    
    const carryForwardButton = () =>{
      Api.post(`/attendance-attendance/carryForward?half=${halfValues}&userId=${selectedUserData.userId}`)
      .send((response) => {
          console.log("resss",response);
      });
    }
    
    return(
        <div className={classes.summaryContain}>
          {width > 700 && <h3>Associate Summary</h3>}
          <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",marginTop:"0.9rem",padding:"0.2rem 0.8rem 0.1rem 0.8rem"}}>
            <div onClick={()=>{LeaveModal(true);setSelectedData({});setSummaryData({})}}>
                <img src={width > 700 ? backIcon : MobileBackIcon} alt=""
                 style={{height:width > 700 ? "2rem" : "1.5rem",width:"1.3rem",cursor:"pointer"}}                                                
                /> 
            </div>
            <Button type="primary" disabled={viewClass.superAdmin?false:true} style={{height:width>700?"2rem":"2.4rem",borderRadius:5,fontSize:width>700?13.5:17}}
            // onClick={()=>{carryForwardButton()}}
            onClick={()=>{setCarryForwardModal(true)}}
            >Carry-Forward</Button>
          </div>

          {width > 700 && <CardList  data={leavesData?.intialLeaves} />}
          {/* {loader ?  */}
        {/* <div style={{display:"flex",flexDirection:"column",justifyContent:"center",width:"100%",height:"50vh",
          alignItems:"center"}}><Spin /></div>
        :  */}
        {width > 700 ?
          <div>
          <Tabs defaultActiveKey="Fixed Holidays"
            onChange={(key) => {
                setLeaveTab(key);
            }}>
              <TabPane tab={<div style={{ color: leaveTab === "Fixed Holidays" ? "#0086FF" : "#A6A9B8",cursor:"pointer",fontSize:"16px" }}>Fixed Holidays</div>} 
              key="Fixed Holidays">
              <div className={classes.TapDiv}>
              {summaryData?.fixedHolidaysData?.length > 0 ?
              <div>
                {summaryData?.fixedHolidaysData?.map((item)=>{
                     return(
                        <Card className={classes.cardsTag} size="small">
                        <div className={classes.associateCard}>
                            <div>Date   <h4>{item.date}</h4></div>
                            <div>Holiday Name <h4>{item.accountOf}</h4></div>
                        </div>
                        </Card>
                    )
                })}
                </div>
                :
                  <div style={{height:"100%",backgroundColor:"#FFFFFF",display:"flex",alignItems:"center",flexDirection:"row",justifyContent:"center"}}>
                    No data</div>
                  }
                <ProfileDetails data={summaryData?.user}/>  
              </div>
              </TabPane>
              <TabPane tab={<div style={{ color: leaveTab === "Optional Holidays" ? "#0086FF" : "#A6A9B8",cursor:"pointer",fontSize:"16px" }}>Optional Holidays</div>} 
              key="Optional Holidays">
                <div className={classes.TapDiv} >
                  {summaryData?.optionalHolidaysData?.length > 0 ?
                  <div>
                    {summaryData?.optionalHolidaysData?.map((item)=>{
                        return(
                            <Card className={classes.cardsTag} size="small">
                            <div className={classes.associateCard}>
                                <div>Date   <h4>{item.date}</h4></div>
                                <div>Holiday Name <h4>{item.reason}</h4></div>
                            </div>
                            </Card>
                        )
                    })}
                  </div>
                  :
                  <div style={{height:"100%",backgroundColor:"#FFFFFF",display:"flex",alignItems:"center",flexDirection:"row",justifyContent:"center"}}>
                    No data</div>
                  }
                  <ProfileDetails />  
                </div>       
              </TabPane>
              <TabPane tab={<div style={{ color: leaveTab === "Comp Offs" ? "#0086FF" : "#A6A9B8",cursor:"pointer",fontSize:"16px" }}>Comp Offs</div>} 
              key="Comp Offs">
                <div className={classes.TapDiv} >
                  {summaryData?.compOffData?.applied?.length > 0 ?
                  <div>
                    {summaryData?.compOffData?.applied?.map((item)=>{
                        return(
                            <Card className={classes.cardsTag} size="small">
                            <div className={classes.associateCard}>
                                <div>Date   <h4>{item.date}</h4></div>
                                <div>Reason <h4>{item.reason}</h4></div>
                            </div>
                            </Card>
                        )
                    })}
                  </div>
                  :
                  <div style={{height:"100%",backgroundColor:"#FFFFFF",display:"flex",alignItems:"center",flexDirection:"row",justifyContent:"center"}}>
                    No data</div>
                  }
                  <ProfileDetails />  
                </div>       
              </TabPane>
         </Tabs>           
         </div>
         :
         <div style={{marginTop:"1rem",}}>
          <div style={{display:"flex",flexDirection:"row",gap:"1rem",padding:"0.2rem 0.8rem 0.1rem 0.8rem"}}>
                <img src={summaryData?.profileImage !== null ?summaryData?.profileImage:profileIcon} alt="" 
                style={{height:"5rem",width:"5rem"}}/>
                <div>
                    <p style={{fontSize:"18px",fontWeight:"650px",marginBottom:"0.4rem"}}>{summaryData?.user?.name ? summaryData?.user?.name :"NA"}</p>
                    <p style={{color:"#16243280",fontSize:"17px",marginBottom:"0.4rem"}}>{summaryData?.user?.email ? summaryData?.user?.email :"NA"}</p>
                    <p style={{color:"#16243280",fontSize:"17px",marginBottom:"0.4rem"}}>{summaryData?.user?.uid ? summaryData?.user?.uid :"NA"}</p>
                    
                </div>
          </div>

          <div style={{display:"flex",flexDirection:"row",gap:"0.4rem",marginTop:"0.5rem",padding:"0.2rem 0.8rem 0.1rem 0.8rem"}}>
          <p style={{color:"#16243280",fontSize:"17px",}}>Department: </p>
          <p style={{fontSize:"17px"}}>{summaryData?.user?.departmentName ? summaryData?.user?.departmentName:"NA"}</p>
          </div>

          <div style={{display:"flex",flexDirection:"row",gap:"0.4rem",marginTop:"0.3rem",padding:"0.2rem 0.8rem 0.1rem 0.8rem"}}>
          <p style={{color:"#16243280",fontSize:"17px",}}>Team: </p>
          <p style={{fontSize:"17px"}}>{summaryData?.user?.teamName ? summaryData?.user?.teamName :"NA"}</p>
          </div>

          <div style={{display:"flex",flexDirection:"row",gap:"0.4rem",marginTop:"0.3rem",marginBottom:"0.6rem",padding:"0.2rem 0.8rem 0.1rem 0.8rem"}}>
          <p style={{color:"#16243280",fontSize:"17px",}}>Role: </p>
          <p style={{fontSize:"17px"}}>{summaryData?.user?.designation ? summaryData?.user?.designation :"NA"}</p>
          </div>
          <div style={{backgroundColor:"#F9F9F9",}}>
            <div style={{padding:"0.5rem 0.8rem 0.1rem 0.8rem"}}>
              <p style={{fontSize:"20px",fontWeight:600}}>Fixed Holidays</p>
              <div className={classes.scrollHoliday}
              style={{ display:"flex",flexDirection:"row",gap:"0.5rem",width:"100%",height:"31vh",overflowY:"scroll"}}>
              {summaryData?.fixedHolidaysData?.length > 0 ?
              <div style={{width:"100%"}}>
                {summaryData?.fixedHolidaysData?.map((item)=>{
                     return(
                        <div style={{height:"5rem",width:"100%",alignItems:"center",display:"flex",gap:"0.6rem",flexDirection:"row",marginTop:"0.8rem",backgroundColor:"#FFFFFF",padding:"0.9rem 1.5rem"}}>
                            <div style={{borderRight:"1px solid #D3D3D3"}}>
                              <p style={{fontSize:"23px",color:"#0086FF",fontWeight:"600",marginLeft:"0.3rem"}}>
                                {moment(item.date, "DD-MMM").format("DD")}</p>
                              <p style={{fontSize:"23px",fontWeight:"550",marginRight:"1.3rem"}}>
                                {moment(item.date, "DD-MMM").format("MMM")}</p>
                            </div>
                            <div style={{}}></div>
                            <div style={{fontSize:"20px"}}>{item.accountOf}</div>
                        </div>
                    )
                })}
              </div>
                :
              <div style={{width:"100%",height:"31vh",backgroundColor:"#FFFFFF",display:"flex",alignItems:"center",flexDirection:"row",justifyContent:"center",marginTop:"0.5rem"}}>
                No data</div>
              }
              </div>
            </div>
            <div style={{padding:"0.5rem 0.8rem 0.1rem 0.8rem"}}>
              <p style={{fontSize:"20px",fontWeight:600}}>Optional Holidays</p>
              <div className={classes.scrollHoliday}
              style={{ display:"flex",flexDirection:"row",gap:"0.rem",width:"100%",height:"31vh",overflowY:"scroll"}}>
              {summaryData?.optionalHolidaysData?.length > 0 ?
              <div style={{width:"100%"}}>
                {summaryData?.optionalHolidaysData?.map((item)=>{
                     return(
                      <div style={{height:"5rem",width:"100%",alignItems:"center",display:"flex",gap:"0.6rem",flexDirection:"row",marginTop:"0.8rem",backgroundColor:"#FFFFFF",padding:"0.9rem 1.5rem"}}>
                        <div style={{borderRight:"1px solid #D3D3D3"}}>
                          <p style={{fontSize:"23px",color:"#0086FF",fontWeight:"600",marginLeft:"0.3rem"}}>
                            {moment(item.date, "DD-MMM").format("DD")}</p>
                          <p style={{fontSize:"23px",fontWeight:"550",marginRight:"1.3rem"}}>
                            {moment(item.date, "DD-MMM").format("MMM")}</p>
                        </div>
                        <div style={{}}></div>
                        <div style={{fontSize:"20px"}}>{item.reason}</div>
                      </div>
                    )
                })}
              </div>
                :
              <div style={{width:"100%",height:"31vh",backgroundColor:"#FFFFFF",display:"flex",alignItems:"center",flexDirection:"row",justifyContent:"center",marginTop:"0.5rem"}}>
                No data</div>
              }
              </div>
            </div>
            <div style={{padding:"0.5rem 0.8rem 0.1rem 0.8rem"}}>
              <p style={{fontSize:"20px",fontWeight:600}}>Comp Offs</p>
              <div className={classes.scrollHoliday}
              style={{ display:"flex",flexDirection:"row",gap:"0.5rem",width:"100%",height:"31vh",overflowY:"scroll"}}>
              {summaryData?.compOffData?.applied?.length > 0 ?
              <div style={{width:"100%"}}>
                {summaryData?.compOffData?.applied?.map((item)=>{
                     return(
                      <div style={{height:"5rem",width:"100%",alignItems:"center",display:"flex",gap:"0.6rem",flexDirection:"row",marginTop:"0.8rem",backgroundColor:"#FFFFFF",padding:"0.9rem 1.5rem"}}>
                        <div style={{borderRight:"1px solid #D3D3D3"}}>
                          <p style={{fontSize:"23px",color:"#0086FF",fontWeight:"600",marginLeft:"0.3rem"}}>
                            {moment(item.date, "DD-MMM").format("DD")}</p>
                          <p style={{fontSize:"23px",fontWeight:"550",marginRight:"1.3rem"}}>
                            {moment(item.date, "DD-MMM").format("MMM")}</p>
                        </div>
                        <div style={{}}></div>
                        <div style={{fontSize:"20px"}}>{item.reason}</div>
                      </div>
                    )
                })}
              </div>
                :
              <div style={{width:"100%",height:"30vh",backgroundColor:"#FFFFFF",display:"flex",alignItems:"center",flexDirection:"row",justifyContent:"center",marginTop:"0.5rem"}}>
                No data</div>
              }
              </div>
            </div>
          </div>
         </div>
        } 
      {carryForwardModal&&
      <Modal open={carryForwardModal} centered width={700}
         onOk={()=>{
          setCarryForwardModal(false)
          setHalfValNumber(null)
         }} onCancel={()=>{setCarryForwardModal(false);setHalfValNumber(null)}}
          footer={<div style={{margin:width>700?"1rem":"3rem 0.5rem 1rem 1rem",display:"flex",justifyContent:"flex-end",flexDirection:"row",gap:"0rem"}}>
            <Button key="submit" type="primary" disabled={halfValNumber === null ? true :false}
            onClick={()=>{
              carryForwardButton()
              setCarryForwardModal(false)
            }} style={{width:"5rem"}}>
              Save
            </Button>
            <Button key="back" onClick={()=>setCarryForwardModal(false)}>
              Cancel
            </Button>
          </div>}>
          <div style={{height:"32vh",padding:0,margin:0,}}>
          <h3 style={{marginBottom:"0.5rem",fontSize:18}}>Carry Forward Leaves</h3>
          <Divider />
          <span style={{fontSize:16}}>Privilege Leaves: </span>

          <div style={{marginTop:"2rem",width:"100%",display:"flex",flexDirection:"row",justifyContent:"space-between",marginBottom:"1rem",}}>
          
          <Radio.Group onChange={(e)=>{
            setHalfValNumber(e.target.value)
            if(e.target.value === 1){
              setHalfValues(false)
            }
            else{
              setHalfValues(true)
            }
          }} >
            {width > 700 ?
            <div style={{width:"100%",display:"flex",flexDirection:"row",gap:"2rem",}}>
            <Radio value={1} style={{border:"1px solid #878787",height:"2.5rem",width:"17rem",display:"flex:",alignItems:"center",padding:"0 0.5rem",fontSize:15}}>
              Full Privilege Leaves</Radio>
            <Radio value={2} style={{border:"1px solid #878787",height:"2.5rem",width:"17rem",display:"flex:",alignItems:"center",padding:"0 0.5rem",fontSize:15}}>
              Half Privilege Leaves</Radio>
            </div>
            :
            <div style={{display:"flex",flexDirection:"column",gap:"1.5rem",}}>
            <Radio value={1} style={{border:"1px solid #878787",height:"2.5rem",width:"17rem",display:"flex:",alignItems:"center",padding:"0 0.5rem",fontSize:15}}>
              Full Privilege Leaves</Radio>
            <Radio value={2} style={{border:"1px solid #878787",height:"2.5rem",width:"17rem",display:"flex:",alignItems:"center",padding:"0 0.5rem",fontSize:15}}>
              Half Privilege Leaves</Radio>
            </div>
            }
          </Radio.Group>

          </div>
          <Divider style={{marginTop:"2rem"}}/>
        </div>
      </Modal>}
        </div>
    )
}
export default AssociateSummary;