import React, { useEffect, useState } from 'react';
import Notification from '../../../../services/Notification';
import uploadIcon from '../../../../assets/AddBlueButton.svg';
import { postUploadedDocuments } from '../../action/action';
import { Button, Checkbox, Typography } from 'antd';
import documentIcon from "../../../../assets/docIcon.svg"
import { DeleteOutlined } from '@ant-design/icons';
import { getLink, sendLink } from '../../../../Config';
import { BsCheck2Circle } from "react-icons/bs";
import { KeyIsNotEmpty } from './formData';
import classes from './uploadDocument.module.css';
import viewClass from '../../../../services/ViewRole';




function UploadDocumentMulti({onSavePatch,modalRender,onClose,uploadedFiles}) {
  const [otherName, setOtherName] = useState('');
  const [other, setOther] = useState(false);
  const [docList,setDocList]=useState({});
const[upload,setUpload]=useState([]);
  const [saveEnable,setSaveenable]=useState(false)
    const[filenum,setFilenum]=useState(modalRender?.curr);
const[renderArr,setRenderArr]=useState([])
  useEffect(()=>{
    if  (modalRender?.type==="Work Experience"){
        setFilenum(modalRender?.curr)
        console.log('key',modalRender?.curr)
        let length=modalRender?.length;
        let data={}
        for(let i=0;i<length;i++){
            data[i]=[];
             data[i]=[
                {
                    label: "Offer Letter",
                    checked: false,
                    mandatary: false,
                    formate: '',
                    link:'',
                    showV:true,
                    verify:false,
                    name: 'offerLetter'
                },
                {
                    label: "Experience Letter",
             
                    checked: false,
                    mandatary: true,
                    formate: '',
                    link:'',
                    showV:true,
                    verify:false,
                    name: 'expLetter'
                },
                {
                    label: "Pay Slip 1",
                    checked: false,
                    mandatary: true,
                    link:'',
                    showV:true,
                    verify:false,
                    formate: '',
                    name: 'paySlip1'
                },
                {
                    label: "Pay Slip 2",
                    checked: false,
                    mandatary: true,
                    link:'',
                    showV:true,
                    verify:false,
                    formate: '',
                    name: 'paySlip2'
                }, {
                    label: "Pay Slip 3",
                    checked: false,
                    mandatary: true,
                    link:'',
                    showV:true,
                    verify:false,
                    formate: '',
                    name: 'paySlip3'
                },
                {
                    label: "Background Verification",
                    checked: false,
                    mandatary: true,
                    link:'',
                    showV:true,
                    verify:false,
                    formate: '',
                    name: 'backgroundV'
                },
                {
                    label: "Relieving Letter",
                    checked: false,
                    mandatary: false,
                    link:'',
                    showV:true,
                    verify:false,
                    formate: '',
                    name: 'relievingLetter'
                },
                {
                    label: "Form 16",
                    checked: false,
                    mandatary: false,
                    link:'',
                    showV:true,
                    verify:false,
                    formate: '',
                    name: 'form16' 
                },
                {
                    label: "Hike letter",
                    checked: false,
                    mandatary: false,
                    link:'',
                    showV:true,
                    verify:false,
                    formate: '',
                    name: 'hikeLetter' 
                }
            ]
            
        }
        setUpload(data); 
        console.log('kkkkkkkkkkkk',data)
    }
  },[modalRender])
useEffect(()=>{
    if(modalRender?.type==="Work Experience"){
        let defaultFile=KeyIsNotEmpty(uploadedFiles?.Experience)?uploadedFiles?.Experience:{};
        let bigArray={}
        let dataObj=Object.entries(upload);
        for(const[key,value] of dataObj ){
            let array=[];
            let files=defaultFile[key];
            value?.forEach((val)=>{
                if(files){
            array.push({...val,verify:files[`${val?.name}_HRVerified`], link:files[val?.name]?getLink(files[val?.name]):'', formate:files[val?.name]?sendLink(files[val?.name]):'',checked:files[val?.name]?true:false})
            }
            else{
                array.push(val)
            }
        })
        if(files?.others){
            let obj=Object?.entries(files?.others)
            for (const [key, value] of obj){
                array?.push({label:key,name:key,link:getLink(value), formate:sendLink(value),checked:true,type:'other'})
            }
        }
        bigArray[key]=[]
        bigArray[key]=array
        }
        setDocList(bigArray); 
    }
},[upload,])



  useEffect(()=>{
    console.log('render 123',docList,'key',filenum)
    if(modalRender?.type==="Work Experience"){
        let flag=true;
        docList[filenum]?.forEach(element => {
            if(element?.mandatary){
                if(element?.formate!==''){
                   flag=flag & true
                }else{
                    flag=flag &false
                }
            }
           });
           setSaveenable(flag)
       }
    if(filenum!==undefined && docList!==undefined && docList[filenum]!==undefined){
        setRenderArr(docList[filenum]);
    }
  },[docList,filenum])


  const HandleUploadLink=(preData,key,index,value,checked,setData,other,)=>{
    if(other!==undefined){       
        preData[key]=other
    }
    else{
    preData[key][index].formate=sendLink(value);
    preData[key][index].link=value;
    preData[key][index].checked=checked;
    preData[key][index].verify=false;
    }
    setData({...preData})

  }

  const handleFileUpload = (event, index) => {
    const file = event.target.files[0];
 

    if (!file) {
      return;
    }

    const allowedExtensions = ["png", "pdf", "jpeg", "jpg"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
        Notification.error("Error", "Only JPG, PNG and PDF files are allowed");
        return;
    }

    if (file.size > 10000000) {
      Notification.error("Error", "File should be under 10MB");
      return;
    }

    // Perform the upload logic here

    // Read the file as binary data
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryData = e.target.result;
    };
    reader.readAsArrayBuffer(file);
  
    if (other) {
      postUploadedDocuments({ ["other"]: file,["fileName"]:otherName }, (res) => {
        const s = {
          label: otherName,
          checked: true,
          mandatary: false,
          formate:sendLink(res?.other),
          link:res?.other,
          name: otherName,
          type:'other',
        }

        let k = [...renderArr];
        k.push(s);
        HandleUploadLink(docList,filenum,index,res?.[renderArr[index]?.name],true,setDocList,k)
        setOtherName('');
      });
    } else {
      postUploadedDocuments({ [renderArr[index]?.name]: file }, (res) => {
       
        HandleUploadLink(docList,filenum,index,res?.[renderArr[index]?.name],true,setDocList)
        });
    }
  };
  const removeHandler =(index)=>{
    if(renderArr[index]?.type==='other'){
        let u=[...renderArr]
        u.splice(index, 1);
        setDocList({...docList,[filenum]:u})
    }
    else{
    HandleUploadLink(docList,filenum,index,'',false,setDocList)
    }
  }
  const verifyHandler=(index,status)=>{
    let u = [...renderArr];
    u[index].verify=status;
    setDocList({...docList,[filenum]:u})

  }
    return (
        <>
        <div style={{ display: 'flex', flexDirection: 'column', }}>
            <div  style={{ flexWrap: 'wrap',  }} id={Math.random()}>
                {
                renderArr?.map((item, index) => (
                    <>
                    <div key={Math.random()} style={{ display: 'flex', padding: '16px 0', width: '90%',height:"10%" ,justifyContent:'space-between' }}>
                        <div style={{ width: 'fit-content',display:'flex',alignItems:'center' }}>


                            <Checkbox checked={item?.checked} style={{marginRight:'10px'}}></Checkbox>
                            {item?.label}
                            {item?.mandatary ? <span style={{ color: '#FF3434', paddingLeft: '5px' }}>*</span> : null}
                        </div>
                        
                        {item?.formate===''?<div>
                            <label htmlFor={`file-upload-${index}-${filenum}`}>
                                <img onClick={() => { setOther(false) }} src={uploadIcon} style={{ height: '22px', width: '22px', cursor: 'pointer' }} alt="Upload" />
                            </label>
                            <input
                                id={`file-upload-${index}-${filenum}`}
                                type="file"
                                accept=".png, .jpg, .jpeg, .pdf"
                                style={{ display: 'none' }}
                                onChange={(event) => handleFileUpload(event, index)}
                                
                            />
                        </div>:''}
                        {(item?.formate!=='' && item?.showV===true && modalRender?.showV)?<>
                        <div style={{fontSize: 14, }}>
                        {
                           
                            <BsCheck2Circle onClick={()=>{
                                verifyHandler(index,!item.verify)
                            }} 
                                  style={{  fontSize: 25, marginLeft: 5, color: item?.verify? "#0DAC45" : "#ABABAB" }}
                                 />
                        }
                        
                    </div>
                            </>:''
                        }
                    </div>
                   {item?.formate!==''?(
                 <div style={{width:"90%",display:'flex',alignItems:'center',justifyContent:'space-between', padding:'8px',background:"#dbf3ff",borderRadius:'10px'}}>
                        <div  onClick={()=>{
                        if(item?.formate){
                            if(item?.formate.startsWith("https://")){
                                window.open(item?.formate, "_blank")?.focus();
                            }
                            else{
                                window.open(getLink(item?.formate), "_blank")?.focus();   
                            }
                        }
                     }} style={{display:"flex",alignItems:'center',gap:'1rem',marginTop:'-0.5rem',width:'80%',cursor:'pointer'}}>
                           <img src={documentIcon} style={{width:"2rem",height:"2rem",marginLeft:"10px"}} />
                            <Typography.Text style={{height:"20px",overflow:"hidden",width:'20vw'}} ellipsis={{tooltip:true}}> {item?.formate?.split("/")?.[item?.formate?.split("/").length-1]}</Typography.Text>

                        </div>
                        <DeleteOutlined onClick={()=>{removeHandler(index);}} style={{color:'red',fontSize:'18px'}} />
                         
                    
                    
                 </div>
                   ):''}
                    </>
                ))
                
                }
                <div style={{ display: 'flex', flexDirection: 'column' ,marginTop:'10px'}}>
                    <h7>Others:</h7>
                    <div >
                        <div style={{ display: 'flex',width:'90%',  alignItems: 'center', justifyContent: 'space-between' }}>
                            <input
                            value={otherName}
                                onChange={(e) => {
                                    setOtherName(e.target.value)
                                }}
                                style={{ display: 'flex', width: '80%', borderWidth: 0.5, borderColor: 'black', marginTop: 10, justifyContent: 'space-evenly' }}></input>
                            <label htmlFor={`file-upload-other`}>
                                <img onClick={(e) => {
                                     if(otherName?.trim()?.length===0){
                                        document.getElementById(`file-upload-other`).disabled = true;
                                        Notification.error('Error!','Please enter name for document')
                                        e.stopPropagation();
                                        return false;
                                    }
                                    setOther(true)
                                }} src={uploadIcon} style={{ height: '22px', width: '22px', cursor: 'pointer', marginTop: 8 }} alt="Upload" />
                            </label>
                            <input
                                id={`file-upload-other`}
                                type="file"
                                accept=".png, .jpg, .jpeg, .gif, .pdf"
                                style={{ display: 'none' }}
                                onChange={(event) => handleFileUpload(event)}
                                key={Math.random()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems:"center",width:"100%" }}>
                <Button
                      disabled={!saveEnable||viewClass?.hrAssociate}
                    style={{
                        backgroundColor: (!saveEnable||viewClass?.hrAssociate) ? 'gray' : '#0086FF',
                        color: 'white', marginTop: 30
                    }}
                    onClick={()=>{
                        if(modalRender?.type==='Academics'){     
                            onSavePatch(null,docList,null,(res)=>{
                                onClose(res)
                            })
                        }else if(modalRender?.type==='Document Details'){
                            onSavePatch(docList,null,null,(res)=>{onClose(res)})
                        }else {
                            onSavePatch(null,null,docList,(res)=>{onClose(res)})
                        }
                    }}
                >
                    Save
                </Button>
            </div>
        </>
    );
}

export default UploadDocumentMulti;


