
import { Typography } from "antd";
import moment from "moment";

export const validateEmail = (emailId) => {
    if (!emailId || emailId === "") {
        return false
    }
    const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailId?.match(email)) {
        return false
    } else {
        return true
    }
}
export const validatePincode=(pincode)=>{
    if(!pincode || pincode===''){
        return  false
       }
        if(pincode?.length!==6 ){
            return true
        }
        const pincodeCheck=/^\d+$/
        if(pincodeCheck.test(pincode)){
            return false;
        }
        return true
}
export const validatePhone=(phone)=>{
    if(!phone || phone===''){
        return  false
       }
        if(phone?.length!==10 ){
            return true
        }
        const phoneCheck=/^\d+$/
        if(phoneCheck.test(phone)){
            return false;
        }
        return true
}
export const validatePan=(pan)=>{
    if(!pan || pan===''){
        return false;
    }
    if(pan?.length!==10){
        return true;
    }
    const panMatch=/^[A-Za-z]{5}\d{4}[A-Za-z]$/
    if(panMatch.test(pan)){
        return false
    }
    return true
}
export const validateAadhar=(aadhar)=>{
   if(!aadhar || aadhar===''){
    return  false
   }
    if(aadhar?.length!==12 ){
        return true
    }
    const aadharCheck=/^\d+$/
    if(aadharCheck.test(aadhar)){
        return false;
    }
    return true

}
export const KeyIsNotEmpty=(key)=>{

    if(key && key!==''){
        return true;
    }
    else{
        return false;
    } 
    
}

export const KeyIsNotNull=(key)=>{
    if(key!==null){
        return true
    }
    else {
        return false
    }
}

export const getFormData = (employeeProfile, handleInputChange, validateData, handleAddrDuplicate, type, sameAsCurrent = false) => {
 
    function disabledFutureDate(current) {
        return current && current >= moment().endOf('day');
      }
    if (type === 'Basic Profile') {
        return [
            {
                heading: "Associate Profile",
                fields: [
                    {
                        label: "Name",
                        type: "text",
                        value: employeeProfile?.associateName,
                        required: true,
                        disabled: false,
                        onChange: value => handleInputChange(value, "associateName"),
                        placeholder: "Enter name"
                    },
                    {
                        label: "Employee ID", type: "text",
                        value: employeeProfile?.uid, required: true, disabled: false,
                        onChange: value => handleInputChange(value, "uid"),
                        // options: designations,
                        placeholder: "Enter employee Id",
                        disabled: false,
                    },
                    {
                        label: "Email Id", type: "text", value: employeeProfile?.email, required: true,
                        placeholder: "Enter email",
                        disabled: false,
                        error: validateEmail(employeeProfile?.email) ? "Enter valid email id" : null,
                        onChange: value => handleInputChange(value, "email"),
                    },
                    {
                        label: "Joining Date", type: "date", value: employeeProfile?.joined, required: true,
                        disabled: true,
                        onChange: value => handleInputChange(value,"joined"),
                        placeholder: "Select joining date",
                        // disabledDate: (current) => {
                        //     let customDate = moment().format("YYYY-MM-DD");
                        //     return current && current < moment(customDate, "YYYY-MM-DD");
                        // }
                    },
                    {
                        label: "Phone No.", type: "number", value: employeeProfile?.phone, required: true, disabled: false,
                        placeholder: "Enter phone",
                        error: validatePhone(employeeProfile?.phone) ? "Please Enter 10 digit phone no." : null,
                        onChange: value => handleInputChange(value, "phone"),
                    },
                    {
                        label: "Designation", type: "text", value: employeeProfile?.designation, required: true, disabled: false,
                        onChange: value => handleInputChange(value, "designation"),
                        placeholder: "Enter designation",
                    },
                    {
                        label: "Roles", type: "select", value: employeeProfile?.userRoles,
                        required: true,
                        placeholder: "select role",
                        disabled: false,
                        options:[{label:"Super Admin",value: "Super Admin"},{label:"HOD",value: "Department Head"},{label:"HR Admin",value: "HR"},{label:"HR Associate",value: "HR Associate"},{label:"Manager",value: "Manager"},{label:"Associate",value: "Associate"}] ,
                        onChange: value => handleInputChange(value, "userRoles"),
                    },
                    {
                        label: "Privileges", type: "select", value: employeeProfile?.privilege,
                        required: true,
                        placeholder: "select privilege",
                        disabled: false,
                        options:["None","Admin"],
                        onChange: value => handleInputChange(value, "privilege"),
                    },
                    // {
                    //     label: "Department", type: "select", value: employeeProfile?.department, required: false, disabled: false,
                    //     onChange: value => handleInputChange(value, "department"),
                    //     options: ["dept-1", "dept-2", "dept-2"],
                    //     placeholder: "",  
                    // },
                ]
            },
        ]
    } 
    else if (type === 'Basic Information') {
        return [
            {
                heading: "Basic Information",
                fields: [
                    {
                        label: "Personal Email Id",
                        type: "text",
                        value: employeeProfile?.personalEmail,
                        required: true,
                        disabled: false,
                        onChange: value => handleInputChange(value, "personalEmail"),
                        placeholder: "Enter email",
                        error: validateEmail(employeeProfile?.personalEmail) ? "Enter valid email id" : null,
                        verify:{status:employeeProfile?.personalEmail_HRVerified,show:true},
                    },
                    {
                        label: "Secondary Phone No.", type: "number",
                        value: employeeProfile?.secondaryPhone, required: false, disabled: false,
                        onChange: value => handleInputChange(value, "secondaryPhone"),
                        // options: designations,
                        placeholder: "Enter phone",
                        error: validatePhone(employeeProfile?.secondaryPhone) ? "Please Enter 10 digit phone no." : null,
                        verify:{status:employeeProfile?.secondaryPhone_HRVerified,show:true},

                    },
                    {
                        label: "Blood Group", type: "select", value: employeeProfile?.bloodGroup,
                        required: true,
                        placeholder: "Select blood group",
                        disabled: false,
                        options: ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'],
                        onChange: value => handleInputChange(value, "bloodGroup"),
                        verify:{status:employeeProfile?.bloodGroup_HRVerified,show:true},

                    },
                    {
                        label: "Wish Me On", type: "date", value: employeeProfile?.dob, required: true,
                        disabled: true,
                        onChange: e => handleInputChange(e, "dob"),
                        placeholder: "Select date",
                        disabledDate:disabledFutureDate,
                        verify:{status:employeeProfile?.dob_HRVerified,show:true},


                    },
                    {
                        label: "DOB (On Document)", type: "date", value: employeeProfile?.dobActual, required: true,
                        disabled: true,
                        onChange: e => handleInputChange(e, "dobActual"),
                        placeholder: "Select date",
                        disabledDate:disabledFutureDate,
                        verify:{status:employeeProfile?.dobActual_HRVerified,show:true},

                    },
                    {
                        label: "Gender", type: "select", value: employeeProfile?.gender,
                        required: true,
                        placeholder: "Select gender",
                        disabled: false,
                        options: ['Male', 'Female', 'Non-binary', 'Other'],
                        onChange: value => handleInputChange(value, "gender"),
                        verify:{status:employeeProfile?.gender_HRVerified,show:true},

                    },
                    {
                        label: "Category", type: "select", value: employeeProfile?.category,
                        required: true,
                        placeholder: "Select category",
                        disabled: false,
                        options: ["General", "OBC", "SC/ST"],
                        onChange: value => handleInputChange(value, "category"),
                        verify:{status:employeeProfile?.category_HRVerified,show:true},

                    },
                    {
                        label: "Marital Status", type: "select", value: employeeProfile?.maritalStatus,
                        required: true,
                        placeholder: "Enter marital status",
                        disabled: false,
                        options: ['Single', 'Married', 'Divorced', 'Widowed', 'Separated'],
                        onChange: value => handleInputChange(value, "maritalStatus"),
                        verify:{status:employeeProfile?.maritalStatus_HRVerified,show:true},

                    },
                    {
                        label: "Children",
                        type: "text",
                        value: employeeProfile?.children,
                        required: false,
                        disabled: false,
                        onChange: value => handleInputChange(value, "children"),
                        placeholder: "Enter children",
                        verify:{status:employeeProfile?.children_HRVerified,show:true},

                    },
                ]
            },


        ]
    } 
    else if (type === 'Basic Information Associate') {
        return [
            {
                heading: "Basic Information",
                fields: [
                    {
                        label: "Personal Email Id",
                        type: "text",
                        value: employeeProfile?.personalEmail,
                        required: true,
                        disabled: false,
                        onChange: value => handleInputChange(value, "personalEmail"),
                        placeholder: "Enter email",
                        error: validateEmail(employeeProfile?.personalEmail) ? "Enter valid email id" : null,
                    },
                    {
                        label: "Primary Phone No.", type: "number",
                        value: employeeProfile?.primaryPhone, required: false, disabled: false,
                        onChange: value => handleInputChange(value, "primaryPhone"),
                        // options: designations,
                        placeholder: "Enter phone",
                        otpType:true,
                        disabled:true,
                    },
                    {
                        label: "Blood Group", type: "select", value: employeeProfile?.bloodGroup,
                        required: true,
                        placeholder: "Enter blood group",
                        disabled: false,
                        options: ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'],
                        onChange: value => handleInputChange(value, "bloodGroup"),
                    },
                    {
                        label: "Secondary Phone No.", type: "number",
                        value: employeeProfile?.secondaryPhone, required: false, disabled: false,
                        onChange: value => handleInputChange(value, "secondaryPhone"),
                        // options: designations,
                        error: validatePhone(employeeProfile?.secondaryPhone) ? "Please Enter 10 digit phone no." : null,
                        placeholder: "Enter phone",
                    },
                    
                    {
                        label: "DOB (On Document)", type: "date", value: employeeProfile?.dobActual, required: true,
                        disabled: true,
                        onChange: e => handleInputChange(e, "dobActual"),
                        placeholder: "Select Date",
                        disabledDate:disabledFutureDate,

                    },
                    {
                        label: "Wish Me On", type: "date", value: employeeProfile?.dob, required: true,
                        disabled: true,
                        onChange: e => handleInputChange(e, "dob"),
                        placeholder: "Select Date", 
                        disabledDate:disabledFutureDate,
                                       },
                    {
                        label: "Gender", type: "select", value: employeeProfile?.gender,
                        required: true,
                        placeholder: "Select gender",
                        disabled: false,
                        options: ['Male', 'Female', 'Non-binary', 'Other'],
                        onChange: value => handleInputChange(value, "gender"),
                    },
                    {
                        label: "Category", type: "select", value: employeeProfile?.category,
                        required: true,
                        placeholder: "Select category",
                        disabled: false,
                        options: ["General", "OBC", "SC/ST"],
                        onChange: value => handleInputChange(value, "category"),
                    },
                    {
                        label: "Marital Status", type: "select", value: employeeProfile?.maritalStatus,
                        required: true,
                        placeholder: "Select marital status",
                        disabled: false,
                        options: ['Single', 'Married', 'Divorced', 'Widowed', 'Separated'],
                        onChange: value => handleInputChange(value, "maritalStatus"),
                    },
                    {
                        label: "Children",
                        type: "text",
                        value: employeeProfile?.children,
                        required: false,
                        disabled: false,
                        onChange: value => handleInputChange(value, "children"),
                        placeholder: "",
                    },
                ]
            },


        ]
    }
    else if (type === "notice Period") {
        return [
            {
                heading: "Notice Period Details",
                fields: [
                    {
                        label: "Start Date", type: "date", value: employeeProfile?.noticePeriod?.startDate, required: true,
                        disabled: true,
                        onChange: e => handleInputChange(e, "startDate",null,"noticePeriod"),
                        placeholder: "Select start date",
                        style: { justifyContent: "space-between"},
                        disabledDate:disabledFutureDate,
                    },
                    {
                        label: "End Date", type: "date", value: employeeProfile?.noticePeriod?.endDate, required: true,
                        disabled: true,
                        onChange: e => handleInputChange(e, "endDate",null,"noticePeriod"),
                        placeholder: "Select end date",
                        disabledDate: (current) => {
                            let customDate = moment(employeeProfile?.noticePeriod?.startDate).format("YYYY-MM-DD");
                            return (current && current < moment(customDate, "YYYY-MM-DD"));
                        }
                    }
                ]

            }
        ]
    } 
    else if (type === "Bank Account Details") {
        return [
            {
                heading: "Bank Account Details",
                fields: [
                    {
                        label: "Name as per account",
                        type: "text",
                        value: employeeProfile?.bankAccountDetails?.name,
                        required: true,
                        // type:"number"
                        disabled: false,
                        onChange: value => handleInputChange(value, "name", null, "bankAccountDetails"),
                        placeholder: "Enter name",
                        verify:{status:employeeProfile?.bankAccountDetails?.name_HRVerified,show:true},

                    },
                    {
                        label: "Account No.",
                        type: "number",
                        value: employeeProfile?.bankAccountDetails?.accountNumber,
                        required: true,
                        disabled: false,
                        onChange: value => handleInputChange(value, "accountNumber", null, "bankAccountDetails"),
                        placeholder: "Enter account no",
                        verify:{status:employeeProfile?.bankAccountDetails?.accountNumber_HRVerified,show:true},

                    },
                    {
                        label: "IFSC Code",
                        type: "text",
                        value: employeeProfile?.bankAccountDetails?.IFSC,
                        required: true,
                        disabled: false,
                        onChange: value => handleInputChange(value, "IFSC", null, "bankAccountDetails"),
                        placeholder: "Enter IFSC",
                        verify:{status:employeeProfile?.bankAccountDetails?.IFSC_HRVerified,show:true},

                    },
                ]
            }
        ]
    }
    else if (type === "Address Information") {
        return [
            {
                heading: "Current Address",
                fields: [
                    {
                        label: "Address Line 1",
                        type: "text",
                        value: employeeProfile?.current?.addressLine1,
                        required: true,
                        disabled: false,
                        onChange: value => handleInputChange(value, "addressLine1", null, "current"),
                        placeholder: "Address Line 1",
                        multipleInput: true,
                        verify:{status:employeeProfile?.current?.addressLine1_HRVerified,show:true},

                    },
                    {
                        label: "Address Line 2",
                        type: "text",
                        value: employeeProfile?.current?.addressLine2,
                        required: false,
                        disabled: false,
                        onChange: value => handleInputChange(value, "addressLine2", null, "current"),
                        placeholder: "Address Line 2",
                        multipleInput: true,
                        verify:{status:employeeProfile?.current?.addressLine2_HRVerified,show:true},

                    },
                    {
                        label: "Current City",
                        type: "text",
                        value: employeeProfile?.current?.city,
                        required: true,
                        disabled: false,
                        onChange: value => handleInputChange(value, "city", null, "current"),
                        placeholder: "Enter city",
                        verify:{status:employeeProfile?.current?.city_HRVerified,show:true},

                    },
                    {
                        label: "Current State",
                        type: "text",
                        value: employeeProfile?.current?.state,
                        required: true,
                        disabled: false,
                        onChange: value => handleInputChange(value, "state", null, "current"),
                        placeholder: "Enter state",
                        verify:{status:employeeProfile?.current?.state_HRVerified,show:true},

                    },
                    {
                        label: "Current Pincode",
                        type: "number",
                        value: employeeProfile?.current?.pincode,
                        required: true,
                        disabled: false,
                        error:validatePincode(employeeProfile?.current?.pincode)?'Please enter valid pincode':null,
                        onChange: value => handleInputChange(value, "pincode", null, "current"),
                        placeholder: "Enter pincode",
                        verify:{status:employeeProfile?.current?.pincode_HRVerified,show:true},

                    }
                ]
            }
        ]
    } 
    else if (type === 'Document Details') {
        return [
            {
                heading: <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between:", alignItems: "center", margin: "1rem 0" }}>
                    <h4>Document Details</h4>

                </div>,
                //  "Document Details",
                fields: [
                    {
                        label: "PAN Number",
                        type: "text",
                        value: employeeProfile?.pan?.panNumber,
                        required: true,
                        disabled: false,
                        onChange: value => handleInputChange(value, "panNumber",null,"pan"),
                        placeholder: "Enter number",
                        error: validatePan(employeeProfile?.pan?.panNumber) ? "Enter valid pan " : null,
                        verify:{status:employeeProfile?.pan?.panNumber_HRVerified,show:true},

                    },
                    {
                        label: "Name as per Pan", type: "text",
                        value: employeeProfile?.pan?.panName, required: true, disabled: false,
                        onChange: value => handleInputChange(value, "panName",null,"pan"),
                        // options: designations,
                        placeholder: "Enter name",
                        verify:{status:employeeProfile?.pan?.panName_HRVerified,show:true},

                    },
                    {
                        label: "Adhaar Number", type: "number", value: employeeProfile?.aadhar?.aadharNumber, required: true,
                        placeholder: "Enter number",
                        disabled: false,
                        error: validateAadhar(employeeProfile?.aadhar?.aadharNumber) ? "Enter valid aadhar" : null,
                        onChange: value => handleInputChange(value, "aadharNumber",null,"aadhar"),
                        verify:{status:employeeProfile?.aadhar?.aadharNumber_HRVerified,show:true},

                    },
                    {
                        label: "Name as per Adhaar", type: "text", value: employeeProfile?.aadhar?.aadharName, required: true,
                        disabled: false,
                        onChange: value => handleInputChange(value, "aadharName",null,"aadhar"),
                        placeholder: "Enter number",
                        verify:{status:employeeProfile?.aadhar?.aadharName_HRVerified,show:true},

                    },
                    {
                        label: "PF Account Number", type: "number", value: employeeProfile?.pf?.pfNumber, required: false, disabled: false,
                        placeholder: "Enter number",
                        onChange: value => handleInputChange(value,"pfNumber",null,"pf"),
                        verify:{status:employeeProfile?.pf?.pfNumber_HRVerified,show:true},

                    },
                    {
                        label: "UAN Number", type: "number", value: employeeProfile?.uan?.uanNumber, required: false, disabled: false,
                        onChange: value => handleInputChange(value,"uanNumber",null, "uan"),
                        placeholder: "Enter number",
                        verify:{status:employeeProfile?.uan?.uanNumber_HRVerified,show:true},

                    },
                    {
                        label: "Driving Licence", type: "text", value: employeeProfile?.dl?.dlNumber, required: false, disabled: false,
                        onChange: value => handleInputChange(value,"dlNumber",null, "dl"),
                        // options: ["dept-1", "dept-2", "dept-2"],
                        placeholder: "Enter number",
                        verify:{status:employeeProfile?.dl?.dlNumber_HRVerified,show:true},

                    },
                    {
                        label: "Voter ID", type: "text", value: employeeProfile?.voterId?.voterIdNumber, required: false, disabled: false,
                        onChange: value => handleInputChange(value,"voterIdNumber",null, "voterId"),
                        // options: ["dept-1", "dept-2", "dept-2"],
                        placeholder: "Enter number",
                        verify:{status:employeeProfile?.voterId?.voterIdNumber_HRVerified,show:true},

                    },
                    {
                        label: "Passport Number", type: "text", value: employeeProfile?.passport?.passportNumber, required: false, disabled: false,
                        onChange: value => handleInputChange(value,"passportNumber",null, "passport"),
                        // options: ["dept-1", "dept-2", "dept-2"],
                        placeholder: "Enter number",
                        verify:{status:employeeProfile?.passport?.passportNumber_HRVerified,show:true},

                    }
                ],
                uploadList: [

                    {
                        label: "Photo",
                        value: employeeProfile?.photoUpload ? employeeProfile?.photoUpload : null,
                        required: true,
                        disabled: false,
                        onChange: (e) => handleInputChange(e?.file, "photoUpload"),
                        placeholder: "",
                    },
                    {
                        label: "Pan Card",
                        value: employeeProfile?.panUpload ? employeeProfile?.panUpload : null,
                        required: true,
                        disabled: false,
                        onChange: e => handleInputChange(e?.file, "panUpload"),
                        placeholder: "",
                    },
                    {
                        label: "Aadhar Card",
                        value: employeeProfile?.aadharUpload ? employeeProfile?.aadharUpload : null,
                        required: true,
                        disabled: false,
                        onChange: e => handleInputChange(e?.file, "aadharUpload"),
                        placeholder: "",
                    },
                    {
                        label: "Passport",
                        value: employeeProfile?.passportUpload ? employeeProfile?.passportUpload : null,
                        required: true,
                        disabled: false,
                        onChange: e => handleInputChange(e?.file, "passportUpload"),
                        placeholder: "",
                    },
                    {
                        label: "Driving Licence",
                        value: employeeProfile?.licenceUpload ? employeeProfile?.licenceUpload : null,
                        required: true,
                        disabled: false,
                        onChange: e => handleInputChange(e?.file, "licenceUpload"),
                        placeholder: "",
                    },
                    {
                        label: "Voter ID",
                        value: employeeProfile?.voteridUpload ? employeeProfile?.voteridUpload : null,
                        required: true,
                        disabled: false,
                        onChange: e => handleInputChange(e?.file, "voteridUpload"),
                        placeholder: "",
                    }
                ]
            },


        ]
    } 
    else if (type === "Address InformationP") {
        return [
            {
                heading: "Permanent Address",
                fields: [
                    {
                        label: "Address Line 1",
                        type: "text",
                        value: sameAsCurrent ? employeeProfile?.current?.addressLine1 : employeeProfile?.permanent?.addressLine1,
                        required: true,
                        disabled: sameAsCurrent,
                        onChange: value => handleInputChange(value, "addressLine1", null, "permanent"),
                        placeholder: "Address Line 1",
                        multipleInput: true,
                        verify:{status:employeeProfile?.permanent?.addressLine1_HRVerified,show:true},

                    },
                    {
                        label: "Address Line 2",
                        type: "text",
                        value: sameAsCurrent ? employeeProfile?.current?.addressLine2 : employeeProfile?.permanent?.addressLine2,
                        required: false,
                        disabled: sameAsCurrent,
                        onChange: value => handleInputChange(value, "addressLine2", null, "permanent"),
                        placeholder: "Address Line 2",
                        multipleInput: true,
                        verify:{status:employeeProfile?.permanent?.addressLine2_HRVerified,show:true},

                    },
                    {
                        label: "Permanent City",
                        type: "text",
                        value: sameAsCurrent ? employeeProfile?.current?.city : employeeProfile?.permanent?.city,
                        required: true,
                        disabled: sameAsCurrent,
                        onChange: value => handleInputChange(value, "city", null, "permanent"),
                        placeholder: "Enter city",
                        verify:{status:employeeProfile?.permanent?.city_HRVerified,show:true},

                    },
                    {
                        label: "Permanent State",
                        type: "text",
                        value: sameAsCurrent ? employeeProfile?.current?.state : employeeProfile?.permanent?.state,
                        required: true,
                        disabled: sameAsCurrent,
                        onChange: value => handleInputChange(value, "state", null, "permanent"),
                        placeholder: "Enter state",
                        verify:{status:employeeProfile?.permanent?.state_HRVerified,show:true},

                    },
                    {
                        label: "Permanent Pincode",
                        type: "number",
                        value: sameAsCurrent ? employeeProfile?.current?.pincode : employeeProfile?.permanent?.pincode,
                        required: true,
                        disabled: sameAsCurrent,
                        onChange: value => handleInputChange(value, "pincode", null, "permanent"),
                        placeholder: "Enter pincode",
                        error:(!sameAsCurrent && validatePincode(employeeProfile?.permanent?.pincode))?'Please enter valid pincode':null,
                        verify:{status:employeeProfile?.permanent?.pincode_HRVerified,show:true},

                    },
                ]
            }
        ]
    }
    else if (type === "Bank Account Details Associate") {
        return [
            {
                heading: "Bank Account Details",
                fields: [
                    {
                        label: "Name as per account",
                        type: "text",
                        value: employeeProfile?.bankAccountDetails?.name,
                        // type:"number"
                        disabled: false,
                        onChange: value => handleInputChange(value, "name", null, "bankAccountDetails"),
                        placeholder: "Enter name",

                    },
                    {
                        label: "Account No.",
                        type: "number",
                        value: employeeProfile?.bankAccountDetails?.accountNumber,
                        disabled: false,
                        onChange: value => handleInputChange(value, "accountNumber", null, "bankAccountDetails"),
                        placeholder: "Enter account no",

                    },
                    {
                        label: "IFSC Code",
                        type: "text",
                        value: employeeProfile?.bankAccountDetails?.IFSC,
                        disabled: false,
                        onChange: value => handleInputChange(value, "IFSC", null, "bankAccountDetails"),
                        placeholder: "Enter IFSC",

                    },
                ]
            }
        ]
    } 
    else if (type === "Address Information Associate") {
        return [
            {
                heading: "Current Address",
                fields: [
                    {
                        label: "Address Line 1",
                        type: "text",
                        value: employeeProfile?.current?.addressLine1,
                        required: true,
                        disabled: false,
                        onChange: value => handleInputChange(value, "addressLine1", null, "current"),
                        placeholder: "Address Line 1",
                        multipleInput: true,

                    },
                    {
                        label: "Address Line 2",
                        type: "text",
                        value: employeeProfile?.current?.addressLine2,
                        required: false,
                        disabled: false,
                        onChange: value => handleInputChange(value, "addressLine2", null, "current"),
                        placeholder: "Address Line 2",
                        multipleInput: true,

                    },
                    {
                        label: "Current City",
                        type: "text",
                        value: employeeProfile?.current?.city,
                        required: true,
                        disabled: false,
                        onChange: value => handleInputChange(value, "city", null, "current"),
                        placeholder: "Enter city",

                    },
                    {
                        label: "Current State",
                        type: "text",
                        value: employeeProfile?.current?.state,
                        required: true,
                        disabled: false,
                        onChange: value => handleInputChange(value, "state", null, "current"),
                        placeholder: "Enter state",

                    },
                    {
                        label: "Current Pincode",
                        type: "number",
                        value: employeeProfile?.current?.pincode,
                        required: true,
                        disabled: false,
                        error:validatePincode(employeeProfile?.current?.pincode)?'Please enter valid pincode':null,
                        onChange: value => handleInputChange(value, "pincode", null, "current"),
                        placeholder: "Enter pincode",

                    }
                ]
            }
        ]
    } 
    else if (type === 'Document Details Associate') {
        return [
            {
                heading: <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between:", alignItems: "center",  margin: "1rem 0" }}>
                    <h4>Document Details</h4>

                </div>,
                //  "Document Details",
                fields: [
                    {
                        label: "PAN Number",
                        type: "text",
                        value: employeeProfile?.pan?.panNumber,
                        required: true,
                        disabled: false,
                        onChange: value => handleInputChange(value, "panNumber",null,"pan"),
                        placeholder: "Enter number",
                        error: validatePan(employeeProfile?.pan?.panNumber) ? "Enter valid pan " : null,

                    },
                    {
                        label: "Name as per Pan", type: "text",
                        value: employeeProfile?.pan?.panName, required: true, disabled: false,
                        onChange: value => handleInputChange(value, "panName",null,"pan"),
                        // options: designations,
                        placeholder: "Enter name",

                    },
                    {
                        label: "Adhaar Number", type: "number", value: employeeProfile?.aadhar?.aadharNumber, required: true,
                        placeholder: "Enter number",
                        disabled: false,
                        error: validateAadhar(employeeProfile?.aadhar?.aadharNumber) ? "Enter valid aadhar" : null,
                        onChange: value => handleInputChange(value, "aadharNumber",null,"aadhar"),

                    },
                    {
                        label: "Name as per Adhaar", type: "text", value: employeeProfile?.aadhar?.aadharName, required: true,
                        disabled: false,
                        onChange: value => handleInputChange(value, "aadharName",null,"aadhar"),
                        placeholder: "Enter number",

                    },
                    {
                        label: "PF Account Number", type: "number", value: employeeProfile?.pf?.pfNumber, required: false, disabled: false,
                        placeholder: "Enter number",
                        onChange: value => handleInputChange(value,"pfNumber",null,"pf"),

                    },
                    {
                        label: "UAN Number", type: "number", value: employeeProfile?.uan?.uanNumber, required: false, disabled: false,
                        onChange: value => handleInputChange(value,"uanNumber",null, "uan"),
                        placeholder: "Enter number",

                    },
                    {
                        label: "Driving Licence", type: "text", value: employeeProfile?.dl?.dlNumber, required: false, disabled: false,
                        onChange: value => handleInputChange(value,"dlNumber",null, "dl"),
                        // options: ["dept-1", "dept-2", "dept-2"],
                        placeholder: "Enter number",

                    },
                    {
                        label: "Voter ID", type: "text", value: employeeProfile?.voterId?.voterIdNumber, required: false, disabled: false,
                        onChange: value => handleInputChange(value,"voterIdNumber",null, "voterId"),
                        // options: ["dept-1", "dept-2", "dept-2"],
                        placeholder: "Enter number",

                    },
                    {
                        label: "Passport Number", type: "text", value: employeeProfile?.passport?.passportNumber, required: false, disabled: false,
                        onChange: value => handleInputChange(value,"passportNumber",null, "passport"),
                        // options: ["dept-1", "dept-2", "dept-2"],
                        placeholder: "Enter number",

                    }
                ],
                uploadList: [

                    {
                        label: "Photo",
                        value: employeeProfile?.photoUpload ? employeeProfile?.photoUpload : null,
                        required: true,
                        disabled: false,
                        onChange: (e) => handleInputChange(e?.file, "photoUpload"),
                        placeholder: "",
                    },
                    {
                        label: "Pan Card",
                        value: employeeProfile?.panUpload ? employeeProfile?.panUpload : null,
                        required: true,
                        disabled: false,
                        onChange: e => handleInputChange(e?.file, "panUpload"),
                        placeholder: "",
                    },
                    {
                        label: "Aadhar Card",
                        value: employeeProfile?.aadharUpload ? employeeProfile?.aadharUpload : null,
                        required: true,
                        disabled: false,
                        onChange: e => handleInputChange(e?.file, "aadharUpload"),
                        placeholder: "",
                    },
                    {
                        label: "Passport",
                        value: employeeProfile?.passportUpload ? employeeProfile?.passportUpload : null,
                        required: true,
                        disabled: false,
                        onChange: e => handleInputChange(e?.file, "passportUpload"),
                        placeholder: "",
                    },
                    {
                        label: "Driving Licence",
                        value: employeeProfile?.licenceUpload ? employeeProfile?.licenceUpload : null,
                        required: true,
                        disabled: false,
                        onChange: e => handleInputChange(e?.file, "licenceUpload"),
                        placeholder: "",
                    },
                    {
                        label: "Voter ID",
                        value: employeeProfile?.voteridUpload ? employeeProfile?.voteridUpload : null,
                        required: true,
                        disabled: false,
                        onChange: e => handleInputChange(e?.file, "voteridUpload"),
                        placeholder: "",
                    }
                ]
            },


        ]
    } 
    else if (type === "Address InformationP Associate") {
        return [
            {
                heading: "Permanent Address",
                fields: [
                    {
                        label: "Address Line 1",
                        type: "text",
                        value: sameAsCurrent ? employeeProfile?.current?.addressLine1 : employeeProfile?.permanent?.addressLine1,
                        required: true,
                        disabled: sameAsCurrent,
                        onChange: value => handleInputChange(value, "addressLine1", null, "permanent"),
                        placeholder: "Address Line 1",
                        multipleInput: true,

                    },
                    {
                        label: "Address Line 2",
                        type: "text",
                        value: sameAsCurrent ? employeeProfile?.current?.addressLine2 : employeeProfile?.permanent?.addressLine2,
                        required: false,
                        disabled: sameAsCurrent,
                        onChange: value => handleInputChange(value, "addressLine2", null, "permanent"),
                        placeholder: "Address Line 2",
                        multipleInput: true,

                    },
                    {
                        label: "Permanent City",
                        type: "text",
                        value: sameAsCurrent ? employeeProfile?.current?.city : employeeProfile?.permanent?.city,
                        required: true,
                        disabled: sameAsCurrent,
                        onChange: value => handleInputChange(value, "city", null, "permanent"),
                        placeholder: "Enter city",

                    },
                    {
                        label: "Permanent State",
                        type: "text",
                        value: sameAsCurrent ? employeeProfile?.current?.state : employeeProfile?.permanent?.state,
                        required: true,
                        disabled: sameAsCurrent,
                        onChange: value => handleInputChange(value, "state", null, "permanent"),
                        placeholder: "Enter state",

                    },
                    {
                        label: "Permanent Pincode",
                        type: "number",
                        value: sameAsCurrent ? employeeProfile?.current?.pincode : employeeProfile?.permanent?.pincode,
                        required: true,
                        disabled: sameAsCurrent,
                        error:(!sameAsCurrent && validatePincode(employeeProfile?.permanent?.pincode) )?'Please enter valid pincode':null,
                        onChange: value => handleInputChange(value, "pincode", null, "permanent"),
                        placeholder: "Enter pincode",

                    },
                ]
            }
        ]
    }
}


export const getPreviewData=(employeeProfile,sameAsCurrent=false,previewPlace)=>{
if(previewPlace==='Basic Profile'){
    return [{type:'AlphaNumeric',
            data:[
        {
            label: <div>Name</div>,
            value: <div>{employeeProfile?.associateName?employeeProfile?.associateName:'-'}</div>,
        },
        {
            label: <div>Employee ID</div>,
            value:<div>{employeeProfile?.uid?employeeProfile?.uid:'-'}</div>,
        },
        {
            label: <div>Email ID</div> ,
            value: <div>{employeeProfile?.email?employeeProfile?.email:'-'}</div>,
        },
        {
            label: <div>Joining Date</div>,
            value: <div>{employeeProfile?.joined?moment(employeeProfile?.joined).format('YYYY-MMM-DD'):'-'}</div>,
        },
        {
            label: <div>Phone No.</div>,
            value: <div>{employeeProfile?.phone?employeeProfile?.phone:'-'}</div>,
        },
        {
            label: <div>Designation</div>,
            value: <div>{employeeProfile?.designation?employeeProfile?.designation:'-'}</div>,
        },
        {
            label: <div>Roles</div>,
            value: <div >{employeeProfile?.userRoles?employeeProfile?.userRoles?.map((val)=>(<div>{val}</div>)):'-'}</div>,
        },
        {
            label: <div>Privileges</div>,
            value: <div>{employeeProfile?.privilege?employeeProfile?.privilege?.map((val)=>(<div>{val}</div>)):'-'}</div>,
        },
    ] }]
}
else if(previewPlace==="Personal and Contact Data"){

    return [{type:'AlphaNumeric',
             data:[
        {
            label:<div>Personal Email Id</div>,
            value:<div>{employeeProfile?.personalEmail?employeeProfile?.personalEmail:'-'}</div>,
        },
        {
            label:<div>Secondary Phone No.</div>,
            value:<div>{employeeProfile?.secondaryPhone?employeeProfile?.secondaryPhone:'-'}</div>,
        },
        {
            label:<div>Blood Group</div>,
            value:<div>{employeeProfile?.bloodGroup?employeeProfile?.bloodGroup:'-'}</div>,
        },
        {
            label:<div>Wish Me On</div>,
            value:<div>{employeeProfile?.dob?moment(employeeProfile?.dob).format('YYYY-MMM-DD'):'-'}</div>,
        },
        {
            label:<div>DOB (On Document)</div>,
            value:<div>{employeeProfile?.dobActual?moment(employeeProfile?.dobActual).format("YYYY-MMM-DD"):'-'}</div>,
        },
        {
            label:<div>Gender</div>,
            value:<div>{employeeProfile?.gender?employeeProfile?.gender:'-'}</div>,
        },
        {
            label:<div>Category</div>,
            value:<div>{employeeProfile?.category?employeeProfile?.category:'-'}</div>,
        },
        {
            label:<div>Marital Status</div>,
            value:<div>{employeeProfile?.maritalStatus?employeeProfile?.maritalStatus:'-'}</div>,
        },
        {
            label:<div>Children</div>,
            value:<div>{employeeProfile?.children?employeeProfile?.children:'-'}</div>,
        },
        {
            label:<div>Current Address</div>,
            value:<Typography.Paragraph ellipsis={{tooltip:true,symbol:'...more',rows:3}} style={{maxWidth:'15vw',font:'normal normal 16px/20px Roboto',color:"#162432"}} >{employeeProfile?.current?.addressLine1?(employeeProfile?.current?.addressLine1 +","+ (employeeProfile?.current?.addressLine2?employeeProfile?.current?.addressLine2:'')):'-'}</Typography.Paragraph>,
        },
        {
            label:<div>Current City</div>,
            value:<div>{employeeProfile?.current?.city?employeeProfile?.current?.city:'-'}</div>,
        },
        {
            label:<div>Current State</div>,
            value:<div>{employeeProfile?.current?.state?employeeProfile?.current?.state:'-'}</div>,
        },
        {
            label:<div>Current Pincode</div>,
            value:<div>{employeeProfile?.current?.pincode?employeeProfile?.current?.pincode:'-'}</div>,
        },
        {
            label:<div>Permanent Address</div>,
            value:<Typography.Paragraph ellipsis={{tooltip:true,symbol:'...more',rows:3}} style={{maxWidth:'15vw',font:'normal normal 16px/20px Roboto',color:"#162432"}}>{sameAsCurrent?(employeeProfile?.current?.addressLine1?(employeeProfile?.current?.addressLine1+","+(employeeProfile?.current?.addressLine2?employeeProfile?.current?.addressLine2:'')):'-') : employeeProfile?.permanent?.addressLine1?(employeeProfile?.permanent?.addressLine1+","+(employeeProfile?.permanent?.addressLine2?employeeProfile?.permanent?.addressLine2:'')):'-'}</Typography.Paragraph>,
        },
        {
            label:<div>Permanent City</div>,
            value:<div>{sameAsCurrent ? employeeProfile?.current?.city : employeeProfile?.permanent?.city}</div>,
        },
        {
            label:<div>Permanent State</div>,
            value:<div>{sameAsCurrent ? employeeProfile?.current?.state : employeeProfile?.permanent?.state}</div>,
        },
        {
            label:<div>Permanent Pincode</div>,
            value:<div>{sameAsCurrent ? employeeProfile?.current?.pincode : employeeProfile?.permanent?.pincode}</div>,
        },
        {
            label:<div>Name as per account</div>,
            value:<div>{employeeProfile?.bankAccountDetails?.name?employeeProfile?.bankAccountDetails?.name:'-'}</div>,
        },
        {
            label:<div>Account No.</div>,
            value:<div>{employeeProfile?.bankAccountDetails?.accountNumber?employeeProfile?.bankAccountDetails?.accountNumber:'-'}</div>,
        },
        {
            label:<div>IFSC Code</div>,
            value:<div>{employeeProfile?.bankAccountDetails?.IFSC?employeeProfile?.bankAccountDetails?.IFSC:'-'}</div>,
        },
    ] 
}]
}
else if(previewPlace==="Documents"){
    let tempObj=[];

    if(employeeProfile?.photo){
        tempObj.push( {
            label:<div>Photo</div>,
            value:employeeProfile?.photo,
        })
    }
    if(employeeProfile?.workExperience?.resumeLink){
        tempObj.push(
            {
                label:<div>Resume</div>,
                value:employeeProfile?.workExperience?.resumeLink,
            }
        )
    }
    if(KeyIsNotEmpty(employeeProfile?.aadhar?.aadharLink)){
        tempObj.push({
            label:<div>Aadhar</div>,
            value:employeeProfile?.aadhar?.aadharLink,
        })
    }
    if(KeyIsNotEmpty(employeeProfile?.pan?.panLink)){
        tempObj.push({
            label:<div>Pan</div>,
            value:employeeProfile?.pan?.panLink,
        })
    }
    if(KeyIsNotEmpty(employeeProfile?.passport?.passportLink)){
        tempObj.push({
            label:<div>Passport</div>,
            value:employeeProfile?.passport?.passportLink,
        })
    }
    if(KeyIsNotEmpty(employeeProfile?.dl?.dlLink)){
        tempObj.push({
            label:<div>Driving licence</div>,
            value:employeeProfile?.dl?.dlLink,
        })
    }
    if(KeyIsNotEmpty(employeeProfile?.voterId?.voterIdLink)){
        tempObj.push({
            label:<div>Voter ID</div>,
            value:employeeProfile?.voterId?.voterIdLink,
        })
    }
    if(KeyIsNotEmpty(employeeProfile?.bloodDoc?.bloodDocLink)){
        tempObj.push({
            label:<div>Blood group</div>,
            value:employeeProfile?.bloodDoc?.bloodDocLink,
        })
    }
    if(KeyIsNotEmpty(employeeProfile?.others)){
        for(const [key,value] of Object.entries(employeeProfile?.others)){
            if(KeyIsNotEmpty(key) && KeyIsNotEmpty(value)){
                tempObj.push({
                    label:<div>{key}</div>,
                    value:value,
                })
            }
        }

    }

    return [{
        type:'file',
        data:tempObj,
    }]

}

else if(previewPlace==="Academics"){
    let tempObj=[];
    let objData=employeeProfile?.academics?.academicsDocuments;

    if(KeyIsNotEmpty(objData) && Object.keys(objData)?.length>0){
    for(const [key,value] of Object.entries(objData)){
        if(!key?.includes('_HRVerified')){
            if(KeyIsNotEmpty(value) && key!=='others'){
                    tempObj.push(
                    { 
                        label:<div>{key}</div>,
                        value:value,
                    }
                    )
            }
            else if(key==='others' && Object.keys(value)?.length>0){
                for(const [key1,value1] of Object.entries(value)){
                     console.log('111111333333',key1,value1)

                    if(KeyIsNotEmpty(value1)){
                        tempObj.push(
                        { label:<div>{key1}</div>,
                            value:value1,
                            }
                        )
        
                    }
                }
                
            }
        }
    }
}

    return [{
        type:'file',
        data:tempObj,
    }]
}

else if (previewPlace==='Work Experience'){
    let tempObj=[];
    let objData=employeeProfile?.workExperience?.documents;
   
    let length= KeyIsNotEmpty(objData)?Object.keys(objData)?.length:0;
    if(KeyIsNotEmpty(objData) && length>0){
    for(const [key2,value2] of Object.entries(objData)){
        if( KeyIsNotEmpty(value2) && Object.keys(value2)?.length>0){

            for(const [key,value] of Object.entries(value2)){
                if(!key?.includes('_HRVerified')){
                    if(KeyIsNotEmpty(value) && key!=='others'){
                            tempObj.push(
                            { 
                                label:<div>{key}{'_'}{length>1?(parseInt(key2)+1):''}</div>,
                                value:value,
                            }
                            )
                    }
                    else if(key==='others' && Object.keys(value)?.length>0){
                        for(const [key1,value1] of Object.entries(value)){
                             console.log('111111333333',key1,value1)
        
                            if(KeyIsNotEmpty(value1)){
                                tempObj.push(
                                { label:<div>{key1}</div>,
                                    value:value1,
                                    }
                                )
                
                            }
                        }
                        
                    }
                }
            }
        }

        
    }
}

    return [{
        type:'file',
        data:tempObj,
    }]
}
}


export const postData = (employeeProfile, emergencyContactArray, academicsArray, workExpArray, sameAsCurrent = false, value, files = null,academicsFiles=null,experianceFile=null,skills={value:'',verified:false},submit=false) => {
    let workExpDocs = {};
    let DocumentOther={};
    let AcademicsOther={};
    // let expOther={};
   
  console.log(academicsFiles,experianceFile,files,"academicsFiles",);
  if(files){

    for(let i=8;i<files.length;i++){
        DocumentOther[files[i].name]=files[i].formate;
    }
    console.log('academicsFiles',files,employeeProfile)
  }
    let academicsObj = {}
    if(academicsFiles!=null){
        for(let i=10;i<academicsFiles.length;i++){
            AcademicsOther[academicsFiles[i].name]=academicsFiles[i].formate;
        }
academicsObj={
    'marksheet10':academicsFiles?.[0].formate,
    'certificate10':academicsFiles?.[1].formate,
    'marksheet12':academicsFiles?.[2].formate,
    'certificate12':academicsFiles?.[3].formate,
    'marksheetUG':academicsFiles?.[4].formate,
    'certificateUG':academicsFiles?.[5].formate,
    'certificatePUG':academicsFiles?.[6].formate,
    'marksheetPG':academicsFiles?.[7].formate,
    'certificatePG':academicsFiles?.[8].formate,
    'certificatePPG':academicsFiles?.[9].formate,

    'marksheet10_HRVerified':academicsFiles?.[0].verify,
    'certificate10_HRVerified':academicsFiles?.[1].verify,
    'marksheet12_HRVerified':academicsFiles?.[2].verify,
    'certificate12_HRVerified':academicsFiles?.[3].verify,
    'marksheetUG_HRVerified':academicsFiles?.[4].verify,
    'certificateUG_HRVerified':academicsFiles?.[5].verify,
    'certificatePUG_HRVerified':academicsFiles?.[6].verify,
    'marksheetPG_HRVerified':academicsFiles?.[7].verify,
    'certificatePG_HRVerified':academicsFiles?.[8].verify,
    'certificatePPG_HRVerified':academicsFiles?.[9].verify,

    'others':AcademicsOther,
}
    }
    if(experianceFile!=null){
        
       for (let [key,value] of Object.entries(experianceFile)){
        let workExp={};
        let expOther={};
        console.log('999999999999999',key,value);
        for(let i=9;i<value.length;i++){
            expOther[value[i].name]=value[i].formate;
        }
        workExp={
            'offerLetter':value?.[0].formate,
            'expLetter':value?.[1].formate,
            'paySlip1':value?.[2].formate,
            'paySlip2':value?.[3].formate,
            'paySlip3':value?.[4].formate,
            "backgroundV":value?.[5].formate,
            "relievingLetter":value?.[6].formate,
            'form16':value?.[7].formate,
            'hikeLetter':value?.[8].formate,

            'offerLetter_HRVerified':value?.[0].verify,
            'expLetter_HRVerified':value?.[1].verify,
            'paySlip1_HRVerified':value?.[2].verify,
            'paySlip2_HRVerified':value?.[3].verify,
            'paySlip3_HRVerified':value?.[4].verify,
            "backgroundV_HRVerified":value?.[5].verify,
            "relievingLetter_HRVerified":value?.[6].verify,
            'form16_HRVerified':value?.[7].verify,
            'hikeLetter_HRVerified':value?.[8].verify,
            "others":expOther,
    
        }
        workExpDocs={[key]:workExp, ...workExpDocs}
       }
     
    }
    if(submit){
        return({submit:true})
    }
    return {
        "profile": {
            "associateProfile": {
                "name": employeeProfile?.associateName,
                "uid": employeeProfile?.uid,
                "email": employeeProfile?.email,
                "joined": employeeProfile?.joined,
                "phone": employeeProfile?.phone,
                "designation": employeeProfile?.designation,
                "photo":KeyIsNotNull(files)?files?.[0]?.formate:employeeProfile?.photo,
                "photo_HRVerified":KeyIsNotNull(files)?files?.[0]?.verify:employeeProfile?.photo_HRVerified,
                "userRoles":employeeProfile?.userRoles,
                "privilege":employeeProfile?.privilege,
            },
            "basicInformation": {
                "personalEmail": employeeProfile?.personalEmail,
                "secondaryPhone": employeeProfile?.secondaryPhone,
                "bloodGroup": employeeProfile?.bloodGroup,
                "dobActual": employeeProfile?.dobActual,
                "dob": employeeProfile?.dob,
                "gender": employeeProfile?.gender,
                "category": employeeProfile?.category,
                "maritalStatus": employeeProfile?.maritalStatus,
                "children": employeeProfile?.children,
                "personalEmail_HRVerified": employeeProfile?.personalEmail_HRVerified?employeeProfile?.personalEmail_HRVerified:false,
                "secondaryPhone_HRVerified":employeeProfile?.secondaryPhone_HRVerified?employeeProfile?.secondaryPhone_HRVerified:false,
                "bloodGroup_HRVerified":employeeProfile?.bloodGroup_HRVerified?employeeProfile?.bloodGroup_HRVerified:false,
                "dobActual_HRVerified":employeeProfile?.dobActual_HRVerified?employeeProfile?.dobActual_HRVerified:false,
                "dob_HRVerified": employeeProfile?.dob_HRVerified?employeeProfile?.dob_HRVerified:false,
                "gender_HRVerified":employeeProfile?.gender_HRVerified?employeeProfile?.gender_HRVerified:false,
                "category_HRVerified":employeeProfile?.category_HRVerified?employeeProfile?.category_HRVerified:false,
                "maritalStatus_HRVerified": employeeProfile?.maritalStatus_HRVerified?employeeProfile?.maritalStatus_HRVerified:false,
                "children_HRVerified": employeeProfile?.children_HRVerified?employeeProfile?.children_HRVerified:false,
            },
            "addressInformation": {
                "current": { 
                    "addressLine1":employeeProfile?.current?.addressLine1,
                    "addressLine2":employeeProfile?.current?.addressLine2,
                    "city": employeeProfile?.current?.city,
                    "state":employeeProfile?.current?.state,
                    "pincode":employeeProfile?.current?.pincode,
                    "addressLine1_HRVerified":employeeProfile?.current?.addressLine1_HRVerified?employeeProfile?.current?.addressLine1_HRVerified:false,
                    "addressLine2_HRVerified":employeeProfile?.current?.addressLine2_HRVerified?employeeProfile?.current?.addressLine2_HRVerified:false,
                    "city_HRVerified":employeeProfile?.current?.city_HRVerified?employeeProfile?.current?.city_HRVerified:false,
                    "state_HRVerified":employeeProfile?.current?.state_HRVerified?employeeProfile?.current?.state_HRVerified:false,
                    "pincode_HRVerified":employeeProfile?.current?.pincode_HRVerified?employeeProfile?.current?.pincode_HRVerified:false,
                    },
                "permanent": sameAsCurrent ? { 
                    "addressLine1":employeeProfile?.current?.addressLine1,
                    "addressLine2":employeeProfile?.current?.addressLine2,
                    "city": employeeProfile?.current?.city,
                    "state":employeeProfile?.current?.state,
                    "pincode":employeeProfile?.current?.pincode,
                    "addressLine1_HRVerified":employeeProfile?.current?.addressLine1_HRVerified?employeeProfile?.current?.addressLine1_HRVerified:false,
                    "addressLine2_HRVerified":employeeProfile?.current?.addressLine2_HRVerified?employeeProfile?.current?.addressLine2_HRVerified:false,
                    "city_HRVerified":employeeProfile?.current?.city_HRVerified?employeeProfile?.current?.city_HRVerified:false,
                    "state_HRVerified":employeeProfile?.current?.state_HRVerified?employeeProfile?.current?.state_HRVerified:false,
                    "pincode_HRVerified":employeeProfile?.current?.pincode_HRVerified?employeeProfile?.current?.pincode_HRVerified:false,
                    
                 } : 
                { 
                    "addressLine1":employeeProfile?.permanent?.addressLine1,
                    "addressLine2":employeeProfile?.permanent?.addressLine2,
                    "city": employeeProfile?.permanent?.city,
                    "state":employeeProfile?.permanent?.state,
                    "pincode":employeeProfile?.permanent?.pincode,
                    "addressLine1_HRVerified":employeeProfile?.permanent?.addressLine1_HRVerified?employeeProfile?.permanent?.addressLine1_HRVerified:false,
                    "addressLine2_HRVerified":employeeProfile?.permanent?.addressLine2_HRVerified?employeeProfile?.permanent?.addressLine2_HRVerified:false,
                    "city_HRVerified":employeeProfile?.permanent?.city_HRVerified?employeeProfile?.permanent?.city_HRVerified:false,
                    "state_HRVerified":employeeProfile?.permanent?.state_HRVerified?employeeProfile?.permanent?.state_HRVerified:false,
                    "pincode_HRVerified":employeeProfile?.permanent?.pincode_HRVerified?employeeProfile?.permanent?.pincode_HRVerified:false,
                    },
                "sameAsCurrent": sameAsCurrent
            },
            "noticePeriod":{...employeeProfile?.noticePeriod},
            "emergencyContact": emergencyContactArray,
            "bankAccountDetails": { 
                "name_HRVerified":employeeProfile?.bankAccountDetails?.name_HRVerified?employeeProfile?.bankAccountDetails?.name_HRVerified:false,
                "accountNumber_HRVerified":employeeProfile?.bankAccountDetails?.accountNumber_HRVerified?employeeProfile?.bankAccountDetails?.accountNumber_HRVerified:false,
                "IFSC_HRVerified":employeeProfile?.bankAccountDetails?.IFSC_HRVerified?employeeProfile?.bankAccountDetails?.IFSC_HRVerified:false,
                "name": employeeProfile?.bankAccountDetails?.name,
                "accountNumber": employeeProfile?.bankAccountDetails?.accountNumber,
                "IFSC": employeeProfile?.bankAccountDetails?.IFSC,
                }

        },
        "academics": {
            "academicsData":academicsArray,
            "academicsDocuments":Object.keys(academicsObj).length!==0?academicsObj:employeeProfile?.academics?.academicsDocuments

        },
        "workExperience": {
            "experienced": value === "Fresher" ? null : workExpArray,
            "type": value,
            "resumeLink":KeyIsNotNull(files)?files?.[1].formate:employeeProfile?.workExperience?.resumeLink,
            "resumeLink_HRVerified":KeyIsNotNull(files)?files?.[1].verify:(employeeProfile?.workExperience?.resumeLink_HRVerified?employeeProfile?.workExperience?.resumeLink_HRVerified:false),
            "skills":skills?.value,
            "skills_HRVerified":skills.verified?skills.verified:false,
            "documents":value === "Fresher" ? null :(KeyIsNotNull(experianceFile)?workExpDocs:employeeProfile?.workExperience?.documents)
        },
        "documents": {
            "pan": {
                "panName": employeeProfile?.pan?.panName?employeeProfile?.pan?.panName:'',
                "panName_HRVerified": employeeProfile?.pan?.panName_HRVerified?employeeProfile?.pan?.panName_HRVerified:false,

                "panNumber":employeeProfile?.pan?.panNumber ,
                "panNumber_HRVerified":employeeProfile?.pan?.panNumber_HRVerified?employeeProfile?.pan?.panNumber_HRVerified:false ,
                "panLink": KeyIsNotNull(files)?files?.[2]?.formate:employeeProfile?.pan?.panLink,
                "panLink_HRVerified":KeyIsNotNull(files)?files?.[2]?.verify:(employeeProfile?.pan?.panLink_HRVerified?employeeProfile?.pan?.panLink_HRVerified:false),
            },
            "aadhar": {
                "aadharName": employeeProfile?.aadhar?.aadharName,
                "aadharName_HRVerified": employeeProfile?.aadhar?.aadharName_HRVerified?employeeProfile?.aadhar?.aadharName_HRVerified:false,

                "aadharNumber": employeeProfile?.aadhar?.aadharNumber,
                "aadharNumber_HRVerified": employeeProfile?.aadhar?.aadharNumber_HRVerified?employeeProfile?.aadhar?.aadharNumber_HRVerified:false,
                "aadharLink_HRVerified": KeyIsNotNull(files)?files?.[3].verify:(employeeProfile?.aadhar?.aadharLink_HRVerified?employeeProfile?.aadhar?.aadharLink_HRVerified:false),
                "aadharLink": KeyIsNotNull(files)?files?.[3].formate:employeeProfile?.aadhar?.aadharLink
            },
            "pf": {
                "pfNumber":employeeProfile?.pf?.pfNumber,
                "pfNumber_HRVerified":employeeProfile?.pf?.pfNumber_HRVerified?employeeProfile?.pf?.pfNumber_HRVerified:false,
                "pfLink": ""
            },
            "uan": {
                "uanNumber":employeeProfile?.uan?.uanNumber ,
                "uanNumber_HRVerified":employeeProfile?.uan?.uanNumber_HRVerified?employeeProfile?.uan?.uanNumber_HRVerified:false ,
                "uanLink": ""
            },
            "dl": {
                "dlNumber": employeeProfile?.dl?.dlNumber,
                "dlNumber_HRVerified": employeeProfile?.dl?.dlNumber_HRVerified?employeeProfile?.dl?.dlNumber_HRVerified:false,
                "dlLink_HRVerified":KeyIsNotNull(files)?files?.[5].verify:(employeeProfile?.dl?.dlLink_HRVerified?employeeProfile?.dl?.dlLink_HRVerified:false),
                "dlLink": KeyIsNotNull(files)?files?.[5].formate:employeeProfile?.dl?.dlLink,
            },
            "voterId": {
                "voterIdNumber_HRVerified": employeeProfile?.voterId?.voterIdNumber_HRVerified?employeeProfile?.voterId?.voterIdNumber_HRVerified:false,
                "voterIdNumber": employeeProfile?.voterId?.voterIdNumber,
                "voterIdLink_HRVerified": KeyIsNotNull(files)?files?.[6].verify:(employeeProfile?.voterId?.voterIdLink_HRVerified?employeeProfile?.voterId?.voterIdLink_HRVerified:false),
                "voterIdLink": KeyIsNotNull(files)?files?.[6].formate:employeeProfile?.voterId?.voterIdLink
            },
            "bloodDoc":{
                "bloodDocLink":KeyIsNotNull(files)?files?.[7]?.formate:employeeProfile?.bloodDoc?.bloodDocLink?employeeProfile?.bloodDoc?.bloodDocLink:'',
                "bloodDocLink_HRVerified":KeyIsNotNull(files)?files?.[7]?.verify:(employeeProfile?.bloodDoc?.bloodDocLink_HRVerified?employeeProfile?.bloodDoc?.bloodDocLink_HRVerified:false),

            },
            "passport": {
                "passportNumber_HRVerified": employeeProfile?.passport?.passportNumber_HRVerified?employeeProfile?.passport?.passportNumber_HRVerified:false,
                "passportNumber": employeeProfile?.passport?.passportNumber,
                "passportLink_HRVerified":KeyIsNotNull(files)?files?.[4].verify:(employeeProfile?.passport?.passportLink_HRVerified?employeeProfile?.passport?.passportLink_HRVerified:false),
                "passportLink": KeyIsNotNull(files)?files?.[4].formate:employeeProfile?.passport?.passportLink
            },
            "others":KeyIsNotNull(files)?DocumentOther:employeeProfile?.others,
            
        }
    }
}