import React, { useEffect, useState } from "react";
import { AiOutlineBars } from "react-icons/ai";
import { TiThLargeOutline, TiThLarge } from "react-icons/ti";
export const GridListView = ({defaultview,onChange}) =>{
    const [view,setView]=useState();
   
useEffect(()=>{
if(defaultview==="grid")
{
    setView(true)
}
else if (defaultview==="list")
{
    setView(false)
}
   

},[defaultview])

    function Handlepress (type){
        if(type==="grid"){
            setView(true)
        }else{
            setView(false)
        }
        onChange(type)
    }

    return(
        <>
         <div  style={{display:"flex",cursor:"pointer",}}>
                          <div className='r-c-c' style={{height:"35px",width:view?"70px":"30px",backgroundColor:view?"#0086FF":"#F3F3F3",borderRadius:5,height:"2.3rem"}} onClick={()=>{Handlepress("grid")}}>
                                <TiThLarge style={{color:view?"white":"#727272",fontSize:20,height:"2rem"}}/>
                                {
                                  view?
                                  <div className='m-l-2 m-b-2' style={{color:view?"white":"black",fontSize:15}}>Grid</div>
                                  :
                                  null
                                }
                          </div>
                          <div className='r-c-c m-l-10' style={{height:"35px",width:!view?"70px":"30px",backgroundColor:!view?"#0086FF":"#F3F3F3",borderRadius:5,height:"2.3rem"}} onClick={()=>{Handlepress("list")}}>
                                <AiOutlineBars style={{color:!view?"white":"#727272",fontSize:20,}}/>
                                {
                                  !view?
                                  <div className='m-l-2 m-b-2' style={{color:!view?"white":"black",fontSize:16}}>List</div>
                                  :
                                  null
                                }
                          </div>
                    </div>
        
        </>
    )
}