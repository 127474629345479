import React from "react";

const TabButtons = ({ style, title, className, value, onClick, children,active }) => {
//   const onClick = ()=>{operation()}}

  return (
    <div>
      {children ? (
        children
      ) : (
        <div
          style={{
            width: "100%",
            height: "4vh",
            padding: "0.5rem",
            border: "none",
            borderRadius: "3px",
            borderBottom: active ? '3px solid #0086ff' : 'none',
            ...style,
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {/* <img src={SearchIcon} style={{width:'18px',height:'18px',marginRight:'0.5rem'}}/> */}
            <button
              style={{
                border: "none",
                width: "100%",
                height: "100%",
                boxShadow: "none",
                background:'none',
                fontWeight:'600',
                fontSize:'1rem',
                marginBottom:'0.5rem'
              }}
            //   title={title}
              className={className}
              value={value}
              onClick={onClick}
            >
                <p style={{color:active?'#0086ff':'#999999'}}>{title}</p></button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TabButtons;
