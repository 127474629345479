import React from "react";

import MainPage from "./mainPage";
import HODdashboard from "./commonPage";
import TeamPage from "./teamPage";

import { useGlobalStore } from "../../../../store";

function Dashboard() {

  const { dashboardMainPage } = useGlobalStore((state) => ({
    dashboardMainPage: state.dashboardMainPage,
  }));

  return (
    <div style={{ height: "98%", display: "flex", flexDirection: "column" }}>
      {dashboardMainPage === "mainPage" ? (
        <MainPage />
      ) : dashboardMainPage === "hodTables" ? (
        <HODdashboard />
      ) : (
        <TeamPage />
      )}
    </div>
  );
}

export default Dashboard;
