import { Form, Input, Select } from "antd"
import ModalCompo from "../../../design/ModalCompo"
import { ModalPrimaryButton, ModalSecondaryButton } from "../../../components/Button/Button"
import transferIcon from "../../../assets/transferIcon.svg";
import classes from "./Project.module.css";
import {  getAllProjectOnly, taskTransferApi } from "./ProjectAction";
import { useEffect, useState } from "react";
import Modal from "../../../components/Modal/Modal";

function TransferTask({ modal,setModal,refresh, projectId}){
    const Option=Select.Option;
    const [projectList,setProjectList]=useState();
    let modalHeader=<div style={{display:'flex',justifyContent:"center",alignItems:'center',gap:'4px',width:"100%"}}>
    <img src={transferIcon}  style={{height:'36px',width:'36px'}}/>
    <div style={{fontWeight:"600",fontSize:"1.2rem",color:"#0DAC45"}}>{"Transfer"}</div>
</div>

useEffect(()=>{
    getAllProjectOnly((res)=>{
        console.log(res);
        setProjectList(res);
    },projectId)
},[])

const TransferHandler=(val)=>{
let param={taskId:modal?.id,projectId:val?.project}
console.log("transfer Handler",param)
taskTransferApi(param,(res)=>{
    if(res){
        setModal();
        refresh();
    }
})
}                
return(
<>
<Modal header={modalHeader} show={modal!=undefined} onCancel={()=>{setModal()}} closableRight={true} style={{width:'75vw',top:'10vh'}} >
<Form fields={[{ name: "name",value:modal?.name}]} layout="vertical"  style={{width:'100%',maxHeight:'57vh',overflowY:'scroll', padding:"0.5rem 2rem"}} onFinish={TransferHandler}>
<Form.Item  label={<span className={classes.heading1}>Name</span>} name="name" rules={[{required: true,message: "Please enter project name",},]}>
<Input placeholder="task name" readOnly size="large"/>
</Form.Item>
<Form.Item  label={<span className={classes.heading1}>Transfer to Project</span>} name="project" rules={[{required: true,message: "Please select department ",},]}>
    <Select   placeholder="Select" size="large" defaultActiveFirstOption>
        {projectList?.map((item,index)=>{
            return(
                <Option value={item?.projectId} key={index}>{item?.projectName}</Option>
            )
        })}
    </Select>
</Form.Item>
<div style={{display:'flex',justifyContent:"center",gap:'1rem',marginTop:'5rem'}}>                        <Form.Item >
        <ModalPrimaryButton htmlType="submit">Save</ModalPrimaryButton>
    </Form.Item>
    <Form.Item>
    <ModalSecondaryButton onCancel={()=>{setModal()}}>Cancel</ModalSecondaryButton>
    </Form.Item>
    </div>
</Form>
</Modal>
</>
)  
}
export default TransferTask;