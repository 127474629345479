import {
  Modal,
  Button,
  Select,
  Divider,
  TimePicker,
  DatePicker,
  Form
} from "antd";
import {
  CalendarOutlined,
} from "@ant-design/icons";
import { applyLeave, getOptionalHoliday, updateAttendance, updateAttendanceRest } from "../action/action";
import { useEffect, useRef, useState } from "react";
import { useAttendnaceStore } from "../store/store";
import moment from "moment";
import GeoFence from "./geoFence";
import dayjs from "dayjs";
import Notification from "../../../services/Notification";
import viewClass from '../../../services/ViewRole';
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import Views from "../../../services/ViewRole";
import { GoogleMap, LoadScript, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { useParams } from "react-router";
import { IoReload } from "react-icons/io5";
import Api from "../../../services/Api";

const { Option } = Select;
const containerStyle = {
  width: '100%',
  height: '400px',
};

const UpdateAttendanceModal = ({
  Data,
  onSuccess,
  reload,
  uiSelectId, setData, selectDate
}) => {
  const {
    selectedUser,
    updateModal,
    setUpdateModal,
  } = useAttendnaceStore((state) => state);
  const [select, setSelect] = useState(null);
  const [checkIn, setCheckIn] = useState();
  const [checkOut, setCheckOut] = useState();
  const [timeDifference, setTimeDifference] = useState(null);
  const [geofenceOpen, setGeofenceOpen] = useState(false);
  const [timeIn, setTimeIn] = useState(null);
  const [timeOut, setTimeOut] = useState(null);
  // const checkInMoment = moment(Data?.checkIn, "HH:mm:ss");
  // const checkOutMoment = moment(Data?.checkOut, "HH:mm:ss");
  const checkInMoment = moment(
    (Data?.effort === "manual" || Data?.secondEffort === "manual") ? Data?.checkInManual : Data?.checkIn,
    "HH:mm:ss"
  );
  const checkOutMoment = moment(
    (Data?.effort === "manual" || Data?.secondEffort==="manual") ? Data?.checkOutManual : Data?.checkOut,
    "HH:mm:ss"
  );
  
  const formattedCheckIn = checkInMoment.format("HH:mm");
  const formattedCheckOut = checkOutMoment.format("HH:mm");
  const [type, setType] = useState(null)
  const { width } = useWindowDimensions();
  const [mapLoaded, setMapLoaded] = useState(false);
  const { associateId } = useParams();
  const [tabsel, setsel] = useState(1)
  const [LeaveModal, setLeaveModal] = useState(false)
  const { Option } = Select;
  const { form } = Form.useForm();
  const formRef1 = useRef(null);
  const [loader, setLoader] = useState(false)
  const [listofHolidays, setHolidays] = useState([])
  const [isOptionalSelected, setSelected] = useState(false)

  const handleMapLoad = (map) => {
    // Ensure the map is loaded before accessing properties
    setMapLoaded(true);
    mapRef.current = map;
  };
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    // googleMapsApiKey: 'AIzaSyCfVOeSxREHxv3LqvV6eLBlROKwV-64gKQ&language=en',
    googleMapsApiKey: 'AIzaSyCfVOeSxREHxv3LqvV6eLBlROKwV-64gKQ',
  });
  const mapRef = useRef(null);
  const lat = parseFloat(Data?.latitude);
  const lng = parseFloat(Data?.longitude);

  const isValidLatLng = !isNaN(lat) && !isNaN(lng) && Data?.longitude !== '0' && Data?.latitude !== '0' && Data?.latitude !== "0.00000000" && Data?.longitude !== "0.00000000";
  useEffect(() => {
    setsel(1)
    return () => {
      setsel(1)
    }
  }, [updateModal])
  const mapCenter = isValidLatLng ? { lat, lng } : null;
  console.log(Data, "DataDataDataData", uiSelectId);

  useEffect(() => {
    if (Data?.punchInfo) {
      setType(Data?.punchInfo?.FinalType)
    }
    return () => {
      setType(null)
    }
  }, [updateModal])
  useEffect(() => {
    if (isLoaded && mapRef.current && isValidLatLng) {
      mapRef.current.panTo(mapCenter);
    }
  }, [isLoaded, mapCenter, isValidLatLng]);
  useEffect(() => {
    if (Data?.punchInfo) {
      setType(Data?.punchInfo?.FinalType)
    }
    return () => {
      setType(null)
    }
  }, [updateModal])

  useEffect(() => {
    if (isOptionalSelected) {
      getOptionalHoliday({ date: Data?.date }, (res) => {
        setHolidays(res)
      })
    }
  }, [isOptionalSelected])

  const calculateDifference = (checkIn, checkOut) => {
    const checkInMoment = moment(checkIn, "HH:mm");
    const checkOutMoment = moment(checkOut, "HH:mm");

    if (checkInMoment.isValid() && checkOutMoment.isValid()) {
      const diffInMilliseconds = checkOutMoment.diff(checkInMoment);
      const diffInHours = moment
        .duration(diffInMilliseconds)
        .asHours()
        .toFixed(1);
      return `${diffInHours} hrs`;
    } else {
      return "Invalid time format";
    }
  };

  const getTimeDifference = calculateDifference(
    formattedCheckIn,
    formattedCheckOut
  );

  const handleSelect = (val) => {
    setSelect(val);
  };
  const handleCheckIn = (time, timeString) => {
    setTimeIn(time);
    setCheckIn(timeString);
    calculateTimeDifference(time, timeOut);
  };
  const handleCheckOut = (time, timeString) => {
    setTimeOut(time);
    setCheckOut(timeString);
    calculateTimeDifference(timeIn, time);
  };
  const handleSave = () => {
    if (select && (checkIn || formattedCheckIn) && (checkOut || formattedCheckOut)) {
      updateAttendance(
        {
          // userId:  uiSelectId ? uiSelectId : selectedUser?.userId,
          userId: uiSelectId ? uiSelectId : Object.keys(selectedUser).length > 0 ? selectedUser?.userId : associateId,
          date: Data?.date,
          reason: "manualPunch",
          checkIn: (checkIn || formattedCheckIn),
          checkOut: (checkOut || formattedCheckOut),
          punchType: select,
          workType: type,
        },
        (e) => {
          setUpdateModal(e);
          reload();
          setSelect(null);
          setCheckIn(null);
          setCheckOut(null);
          setTimeDifference(null);
          setTimeOut(null);
          setTimeIn(null);
          setType(null)
        }
      );
      return () => {
        setSelect(null);
        setCheckIn(null);
        setCheckOut(null);
        setTimeDifference(null);
        setTimeOut(null);
        setTimeIn(null);
        setType(null)
      };
    } else {
      Notification.error("Please fill all the fields");
      return;
    }
  };

  useEffect(() => {
    Views.updateView();
  }, [])

  const handleRest = (type) => {
    setSelect(null);
    setCheckIn(null);
    setCheckOut(null);
    setTimeDifference(null);
    setData(null);
    setTimeOut(null);
    setTimeIn(null);
    setType(null)
    updateAttendanceRest(
      {
        // userId: uiSelectId ? uiSelectId : selectedUser?.userId,
        userId: uiSelectId ? uiSelectId : Object.keys(selectedUser).length > 0 ? selectedUser?.userId : associateId,
        date: Data?.date,
        effort: type
      },
      (e) => {
        setUpdateModal(e);
        reload();
      });
  };
  const onFinishHandler = (callback=null) => {
    Api.post("/attendance-attendance/compOffApplyHr")
      .params({
        userId: uiSelectId ? uiSelectId : Object.keys(selectedUser).length > 0 ? selectedUser?.userId : associateId,
        compOffdate: Data?.date,
      })
      .send((res) => {
        if (res?.show?.type === "success") {
          if(callback){
            callback()
          }
          /* setLogModal()
          setCompoffInitModal(false)
          setReasonVal("");
          refresh();
          ModalRef.current= null
          setCongfirm(false)
          if(callback){
            callback()
          } */
        }
      })
  }

  const calculateTimeDifference = (checkIn, checkOut) => {

    if ((formattedCheckIn === "Invalid date" ? checkIn : formattedCheckIn) &&
      (formattedCheckOut === "Invalid date" ? checkOut : formattedCheckOut)) {
      const diffInMilliseconds = (checkOut === null ? dayjs(formattedCheckOut, "HH:mm") : checkOut).diff((checkIn === null ? dayjs(formattedCheckIn, "HH:mm") : checkIn));
      const diffInHours = moment
        .duration(diffInMilliseconds)
        .asHours()
        .toFixed(1);
      setTimeDifference(`${diffInHours} hrs`);
    } else {
      setTimeDifference("0.0 hrs");
    }
  };

  return (
    <>
      <Modal centered
        title={
          <div style={{ color: "#0086FF" }}>
            <CalendarOutlined style={{ marginRight: 8 }} />
            Update Attendance
          </div>
        }
        width={900}
        open={updateModal}
        // onCancel={onCancel}
        onCancel={() => {
          setsel(1)
          setSelect(null);
          setCheckIn(null);
          setCheckOut(null);
          setTimeDifference(null);
          setData(null);
          setTimeOut(null);
          setTimeIn(null);
          setUpdateModal(false);
        }}
        footer={
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 10,
              padding: "0 20px 40px 20px",
            }}
          >
            {
              tabsel === 1 ?
                <>
                  {
                    (Data?.effort === "Week Off" || Data?.effort === "holiday" || Data?.effort === "optional") ?
                      <>
                        <Button
                          onClick={() => {
                            onFinishHandler(()=>{
                              setUpdateModal(false);  
                              reload()
                              //
                            })
                          
                            
                          }}
                          disabled={Views?.hrAssociate && !Views?.hrAdmin ? true : false}
                          style={{ background: "#0E64A0", color: "white", width: "7rem" }}
                          key="map"
                        >
                          Comp Off
                        </Button>
                      </>
                      :
                      <></>
                  }

                  <Button
                    onClick={() => {
                      setUpdateModal(false);
                      setLeaveModal(true)
                    }}
                    disabled={Views?.hrAssociate && !Views?.hrAdmin ? true : false}
                    style={{ background: "green", color: "white", width: "7rem" }}
                    key="map"
                  >
                    Apply Leave
                  </Button>
                  <Button
                    onClick={() => {
                      setGeofenceOpen(true);
                      setUpdateModal(false);
                    }}
                    // disabled={Views?.hrAssociate && !Views?.hrAdmin  ? true : false}
                    style={{ background: "#0FB3FF", color: "white", width: "5.5rem" }}
                    key="map"
                  >
                    Map
                  </Button>
                  <Button style={{ width: "5.5rem" }}
                    key="cancel"
                    // disabled={Views?.hrAssociate && !Views?.hrAdmin  ? true : false}
                    onClick={() => {
                      setSelect(null);
                      setCheckIn(null);
                      setCheckOut(null);
                      setTimeDifference(null);
                      setData(null);
                      setTimeOut(null);
                      setTimeIn(null);
                      setUpdateModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button key="save" type="primary" onClick={handleSave} style={{ width: "5.5rem" }}
                    disabled={(type !== null) && select && (formattedCheckOut === "Invalid date" ? checkOut : formattedCheckOut) &&
                      (formattedCheckIn === "Invalid date" ? checkIn : formattedCheckIn) && !Views?.hrAssociate ? false
                      : select && (formattedCheckOut === "Invalid date" ? checkOut : formattedCheckOut) &&
                        (formattedCheckIn === "Invalid date" ? checkIn : formattedCheckIn) && Views?.hrAdmin ? false
                        : select && (formattedCheckOut === "Invalid date" ? checkOut : formattedCheckOut) &&
                          (formattedCheckIn === "Invalid date" ? checkIn : formattedCheckIn) ? false
                          : true}
                  >
                    Update
                  </Button>
                </>
                :
                <>
                </>
            }

          </div>
        }
      >
        <Divider></Divider>
        <div style={{ display: "flex", width: "100%", gap: "1rem", marginLeft: 5 }}>
          <div style={{ color: tabsel === 1 ? "#1089FF" : "#959595", cursor: "pointer" }} onClick={() => { setsel(1) }}>
            Stage 1
          </div>
          {
            Data?.effort2 ?
              <div style={{ color: tabsel === 2 ? "#1089FF" : "#959595", cursor: "pointer" }} onClick={() => { setsel(2) }}>
                Stage 2
              </div>
              : null
          }
          {
             Data?.effort3 ?
            <div style={{ color: tabsel === 3 ? "#1089FF" : "#959595", cursor: "pointer" }} onClick={() => { setsel(3) }}>
              Comp Off
            </div>
            :null
          }

        </div>
        <Divider></Divider>
        {tabsel === 1 ?
          <div style={{ height: "65%" }}>
            {
              Data?.effort1 ?
                <>
                  <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 15, alignItems: "center" }}>
                    <div>
                      <div style={{ color: "#959595", fontSize: 10, fontWeight: 600 }}>
                        State 1
                      </div>
                      <div style={{ color: "#161616", fontSize: 12, fontWeight: 400 }}>
                        {Data?.effort1}
                      </div>
                    </div>
                    <div onClick={() => { handleRest(Data?.effort1) }} style={{ color: "#FC5C65", fontSize: 14, display: "flex", alignItems: "center", cursor: "pointer" }}>
                      <IoReload style={{ height: 20, width: 20, marginRight: 5, }} />
                      <div>Reset</div>
                    </div>
                  </div>
                </>
                :
                <></>
            }

            {/*   {
          Data?.secondEffort?
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 15, alignItems:"center" }}>
          <div>
            <div style={{ color: "#959595", fontSize: 10, fontWeight: 600 }}>
              State 2
            </div>
            <div style={{ color: "#161616", fontSize: 12, fontWeight: 400 }}>
              {Data?.secondEffort}
            </div>
          </div>
          <div onClick={()=>{handleRest(Data?.secondEffort)}} style={{color:"#FC5C65", fontSize:14, display:"flex", alignItems:"center",cursor:"pointer"}}>
          <IoReload style={{height:20,width:20, marginRight:5, }}/>
            <div>Reset</div>
          </div>
        </div>
          :
          <>
          </>
        } */}
            <div style={{ padding: "0 20px 20px 20px", width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", }}>
              <div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ marginTop: 10, fontWeight: "600" }}>Select Type </div>
                  {/*   <Button
                key="reset"
                disabled={Views?.hrAssociate && !Views?.hrAdmin  ? true : false}
                style={{ background: Views?.hrAssociate && !Views?.hrAdmin  ? "#e3e3e3":"#F64A3F", color: "white",marginTop:"-0.5rem",width:"5.5rem", }}
                onClick={handleRest}
              >
                reset
              </Button> */}

                </div>
                <Select placeholder="Select"
                  style={{ width: "100%", marginTop: 10, fontWeight: "600" }}
                  onChange={handleSelect}
                  value={select ? select : Data?.type}
                >
                  {viewClass.superAdmin ? <Option value="Outdoor Punch">Outdoor Punch</Option> : ''}
                  <Option value="Forgot Punch">Forgot Punch</Option>
                </Select>
              </div>
              <div>
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: 5 }}>
                  <div style={{ marginTop: 10, fontWeight: "600" }}>Select worktype </div>
                </div>
                <Select placeholder="Select"
                  style={{ width: "100%", marginTop: 10, fontWeight: "600" }}
                  onChange={(e) => { setType(e) }}
                  value={type}
                >
                  <Option value="WFO">WFO</Option>
                  <Option value="WFH">WFH</Option>
                </Select>
              </div>
              <div style={{ marginTop: 20, fontWeight: "600" }}>
                <div>Check In </div>

                <TimePicker
                  format="HH:mm"
                  style={{ width: "100%", marginTop: 10 }}
                  onChange={handleCheckIn}
                  value={
                    checkIn
                      ? dayjs(checkIn, "HH:mm")
                      : formattedCheckIn === "Invalid date"
                        ? null
                        : dayjs(formattedCheckIn, "HH:mm")
                  }
                  allowClear
                />
              </div>
              <div style={{ marginTop: 20, fontWeight: "600" }}>
                <div>Check Out </div>
                <TimePicker
                  format="HH:mm"
                  style={{ width: "100%", marginTop: 10 }}
                  onChange={handleCheckOut}
                  //  value={formattedCheckOut}
                  value={
                    checkOut
                      ? dayjs(checkOut, "HH:mm")
                      : formattedCheckOut === "Invalid date"
                        ? null
                        : dayjs(formattedCheckOut, "HH:mm")
                  }
                  allowClear
                />
              </div>

              <div style={{ marginTop: "1rem" }}>
                Worked Hours:&nbsp;
                {timeDifference
                  ? timeDifference
                  : getTimeDifference === "Invalid time format"
                    ? "0.0hrs"
                    : getTimeDifference}
              </div>
            </div>
          </div>
          :
          tabsel === 3 ?
            <>
              <div style={{ height: "65%" }}>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 15, alignItems: "center" }}>
                  <div>
                    <div style={{ color: "#959595", fontSize: 10, fontWeight: 600 }}>

                    </div>
                    <div style={{ color: "#161616", fontSize: 14, fontWeight: 600 }}>
                      {Data?.effort3}
                    </div>
                  </div>
                 {/*  <div onClick={() => { handleRest(Data?.effort2) }} style={{ color: "#FC5C65", fontSize: 14, display: "flex", alignItems: "center", cursor: "pointer" }}>
                    <IoReload style={{ height: 20, width: 20, marginRight: 5, }} />
                    <div>Reset</div>
                  </div> */}
                </div>
                <div id="1" style={{ display: "flex", padding: 10 }}>
                  <div style={{ width: "50%" }}>
                    <div style={{ color: "#959595", fontSize: 12, fontWeight: 600 }}>Type</div>
                    <div style={{ color: "#161616", fontSize: 14, fontWeight: 600 }}>{Data?.compLeaveDetails?.type ? Data?.compLeaveDetails?.type : "NA"}</div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div style={{ color: "#959595", fontSize: 12, fontWeight: 600 }}>Status</div>
                    <div style={{ color: "#161616", fontSize: 14, fontWeight: 600 }}>{Data?.compLeaveDetails?.status ? Data?.compLeaveDetails?.status : "NA"}</div>
                  </div>
                </div>
                <div id="2" style={{ display: "flex", padding: 10 }}>
                  <div style={{ width: "50%" }}>
                    <div style={{ color: "#959595", fontSize: 12, fontWeight: 600 }}>Date</div>
                    <div style={{ color: "#161616", fontSize: 14, fontWeight: 600 }}>{Data?.date ? Data?.date : "NA"}</div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div style={{ color: "#959595", fontSize: 12, fontWeight: 600 }}>Duration</div>
                    <div style={{ color: "#161616", fontSize: 14, fontWeight: 600 }}>{Data?.duration ? Data?.duration : "NA"}</div>
                  </div>
                </div>

                <div style={{ padding: 10 }}>
                  <div style={{ color: "#959595", fontSize: 12, fontWeight: 600 }}>
                    Applied on
                  </div>
                  <div style={{ color: "#161616", fontSize: 14, fontWeight: 600 }}>
                    {Data?.compLeaveDetails?.appliedOn ? Data?.compLeaveDetails?.appliedOn : "NA"}
                  </div>
                </div>
                <div style={{ padding: 10 }}>
                  <div style={{ color: "#959595", fontSize: 12, fontWeight: 600 }}>
                    Reason
                  </div>
                  <div style={{ color: "#161616", fontSize: 14, fontWeight: 600 }}>
                    {Data?.compLeaveDetails?.reason ? Data?.compLeaveDetails?.reason : "NA"}
                  </div>
                </div>
                <Divider></Divider>
                <div style={{ display: "flex", flexDirection: "column", padding: 10 }}>
                  <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ width: "30%" }}>
                      <div style={{ color: "#5E5E5E", fontSize: 14 }}>Approver(s)</div>
                      <div style={{ color: "#5E5E5E", fontSize: 14 }}>
                        {Data?.compLeaveDetails?.approverOne_ ? Data?.compLeaveDetails?.approverOne_ : "NA"}
                      </div>
                    </div>
                    <div style={{ width: "30%" }}>
                      <div style={{ color: "#5E5E5E", fontSize: 14 }}>Approved on</div>
                      <div style={{ color: "#5E5E5E", fontSize: 14 }}>
                        {Data?.compLeaveDetails?.approverOneDate ? Data?.compLeaveDetails?.approverOneDate : "NA"}
                      </div>
                    </div>
                    <div style={{ width: "30%" }}>
                      <div style={{ color: "#5E5E5E", fontSize: 14 }}>Status</div>
                      <div style={{ color: "#5E5E5E", fontSize: 14 }}>
                        {Data?.compLeaveDetails?.approverOneStatus ? Data?.compLeaveDetails?.approverOneStatus : "NA"}
                      </div>
                    </div>
                  </div>
                  {
                    Data?.compLeaveDetails?.approverTwo_ ?
                      <>
                        <div style={{ display: "flex", width: "100%" }}>
                          <div style={{ width: "30%" }}>
                            <div style={{ color: "#5E5E5E", fontSize: 14 }}>
                              {Data?.compLeaveDetails?.approverTwo_ ? Data?.compLeaveDetails?.approverTwo_ : "NA"}
                            </div>
                          </div>
                          <div style={{ width: "30%" }}>
                            <div style={{ color: "#5E5E5E", fontSize: 14 }}>
                              {Data?.compLeaveDetails?.approverTwoDate ? Data?.compLeaveDetails?.approverTwoDate : "NA"}
                            </div>
                          </div>
                          <div style={{ width: "30%" }}>
                            <div style={{ color: "#5E5E5E", fontSize: 14 }}>
                              {Data?.compLeaveDetails?.approverTwoStatus ? Data?.compLeaveDetails?.approverTwoStatus : "NA"}
                            </div>
                          </div>
                        </div>
                      </>
                      :
                      <></>
                  }

                </div>

              </div>
            </>
            :
            <>
              {
                Data?.effort2 ?
                  <>
                    <div style={{ height: "65%" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 15, alignItems: "center" }}>
                        <div>
                          <div style={{ color: "#959595", fontSize: 10, fontWeight: 600 }}>

                          </div>
                          <div style={{ color: "#161616", fontSize: 14, fontWeight: 600 }}>
                            {Data?.effort2}
                          </div>
                        </div>
                        <div onClick={() => { handleRest(Data?.effort2) }} style={{ color: "#FC5C65", fontSize: 14, display: "flex", alignItems: "center", cursor: "pointer" }}>
                          <IoReload style={{ height: 20, width: 20, marginRight: 5, }} />
                          <div>Reset</div>
                        </div>
                      </div>
                      <div id="1" style={{ display: "flex", padding: 10 }}>
                        <div style={{ width: "50%" }}>
                          <div style={{ color: "#959595", fontSize: 12, fontWeight: 600 }}>Type</div>
                          <div style={{ color: "#161616", fontSize: 14, fontWeight: 600 }}>{Data?.leaveType ? Data?.leaveType : "NA"}</div>
                        </div>
                        <div style={{ width: "50%" }}>
                          <div style={{ color: "#959595", fontSize: 12, fontWeight: 600 }}>Status</div>
                          <div style={{ color: "#161616", fontSize: 14, fontWeight: 600 }}>{Data?.leaveDetails?.status ? Data?.leaveDetails?.status : "NA"}</div>
                        </div>
                      </div>
                      <div id="2" style={{ display: "flex", padding: 10 }}>
                        <div style={{ width: "50%" }}>
                          <div style={{ color: "#959595", fontSize: 12, fontWeight: 600 }}>Date</div>
                          <div style={{ color: "#161616", fontSize: 14, fontWeight: 600 }}>{Data?.date ? Data?.date : "NA"}</div>
                        </div>
                        <div style={{ width: "50%" }}>
                          <div style={{ color: "#959595", fontSize: 12, fontWeight: 600 }}>Duration</div>
                          <div style={{ color: "#161616", fontSize: 14, fontWeight: 600 }}>{Data?.duration ? Data?.duration : "NA"}</div>
                        </div>
                      </div>

                      <div style={{ padding: 10 }}>
                        <div style={{ color: "#959595", fontSize: 12, fontWeight: 600 }}>
                          Applied on
                        </div>
                        <div style={{ color: "#161616", fontSize: 14, fontWeight: 600 }}>
                          {Data?.leaveDetails?.appliedOn ? Data?.leaveDetails?.appliedOn : "NA"}
                        </div>
                      </div>
                      <div style={{ padding: 10 }}>
                        <div style={{ color: "#959595", fontSize: 12, fontWeight: 600 }}>
                          Reason
                        </div>
                        <div style={{ color: "#161616", fontSize: 14, fontWeight: 600 }}>
                          {Data?.leaveDetails?.reason ? Data?.leaveDetails?.reason : "NA"}
                        </div>
                      </div>
                      <Divider></Divider>
                      <div style={{ display: "flex", flexDirection: "column", padding: 10 }}>
                        <div style={{ display: "flex", width: "100%" }}>
                          <div style={{ width: "30%" }}>
                            <div style={{ color: "#5E5E5E", fontSize: 14 }}>Approver(s)</div>
                            <div style={{ color: "#5E5E5E", fontSize: 14 }}>
                              {Data?.leaveDetails?.approverOne_ ? Data?.leaveDetails?.approverOne_ : "NA"}
                            </div>
                          </div>
                          <div style={{ width: "30%" }}>
                            <div style={{ color: "#5E5E5E", fontSize: 14 }}>Approved on</div>
                            <div style={{ color: "#5E5E5E", fontSize: 14 }}>
                              {Data?.leaveDetails?.approverOneDate ? Data?.leaveDetails?.approverOneDate : "NA"}
                            </div>
                          </div>
                          <div style={{ width: "30%" }}>
                            <div style={{ color: "#5E5E5E", fontSize: 14 }}>Status</div>
                            <div style={{ color: "#5E5E5E", fontSize: 14 }}>
                              {Data?.leaveDetails?.approverOneStatus ? Data?.leaveDetails?.approverOneStatus : "NA"}
                            </div>
                          </div>
                        </div>
                        {
                          Data?.leaveDetails?.approverTwo_ ?
                            <>
                              <div style={{ display: "flex", width: "100%" }}>
                                <div style={{ width: "30%" }}>
                                  <div style={{ color: "#5E5E5E", fontSize: 14 }}>
                                    {Data?.leaveDetails?.approverTwo_ ? Data?.leaveDetails?.approverTwo_ : "NA"}
                                  </div>
                                </div>
                                <div style={{ width: "30%" }}>
                                  <div style={{ color: "#5E5E5E", fontSize: 14 }}>
                                    {Data?.leaveDetails?.approverTwoDate ? Data?.leaveDetails?.approverTwoDate : "NA"}
                                  </div>
                                </div>
                                <div style={{ width: "30%" }}>
                                  <div style={{ color: "#5E5E5E", fontSize: 14 }}>
                                    {Data?.leaveDetails?.approverTwoStatus ? Data?.leaveDetails?.approverTwoStatus : "NA"}
                                  </div>
                                </div>
                              </div>
                            </>
                            :
                            <></>
                        }

                      </div>

                    </div>
                  </>
                  :
                  <>
                  </>
              }
            </>


        }

      </Modal>
      {LeaveModal &&
        <Modal
          title="Apply Leave"
          open={LeaveModal}
          onCancel={() => { setLeaveModal(false); setLoader(false); setSelected(false) }}
          footer={[
            <Button key="cancel" onClick={() => { setLeaveModal(false) }}>
              Cancel
            </Button>,
            <Button
              key="save"
              type="primary"
              loading={loader}
              onClick={() => {
                try {
                  formRef1.current.submit();
                } catch (error) {
                  console.error('Error submitting form:', error);
                }

              }}
            >
              Save
            </Button>,
          ]}
          width={800}
          centered
        >
          <div>
            <Form
              ref={formRef1}
              /*   initialValues={{
                  dayType: null,
           
                  leaveType: null,
  
                  optionalHoliday:null
                }} */
              onFieldsChange={(changedField, field) => {
                console.log(changedField, "field600", changedField?.value === "Optional");
                if (changedField[0]?.name?.includes("leaveType")) {
                  if (changedField[0]?.value === "Optional") {
                    setSelected(true)
                  } else if (changedField[0]?.value !== "Optional") {
                    setSelected(false)
                  }
                }
              }}
              onFinish={(values) => {
                setLoader(true)
                let param = {
                  dayType: values?.dayType,
                  date: Data?.date,
                  leaveType: values?.leaveType,
                  userViewId: associateId,
                  reason: values?.optionalHoliday

                }
                applyLeave(param, () => {
                  setLoader(false)
                  reload();
                  setLeaveModal(false)
                  setSelected(false)
                })
              }} layout="vertical" form={form}>
              {/* Day Type dropdown */}
              <Form.Item
                name="dayType"
                label="Day Type"
                rules={[{ required: true, message: 'Please select a day type!' }]}
              >
                <Select placeholder="Select a day type">
                  <Option value="HalfDay">Half Day</Option>
                  <Option value="FullDay">Full Day</Option>
               
                  
                </Select>
              </Form.Item>

              {/* Date Picker */}
              {/*  <Form.Item
                name="date"
                label="Date"
                rules={[{ required: true, message: 'Please select a date!' }]}
              >
                <DatePicker style={{width:"100%"}}/>
              </Form.Item> */}

              {/* Leave Type dropdown */}
              <Form.Item
                name="leaveType"
                label="Leave Type"
                rules={[{ required: true, message: 'Please select a leave type!' }]}
              >
                <Select placeholder="Select a leave type">
                  <Option value={"Sick"}>Sick Leave</Option>
                  <Option value={"Privilege"}>Privilege Leave</Option>
                  <Option value={"Casual"}>Casual Leave</Option>
                  <Option value={"Wedding"}>Wedding Leave</Option>
                  <Option value={"Parental"}>Maternity / Paternity</Option>
                  <Option value={"Optional"}>Optional Leave</Option>
                  <Option value={"Advanced"}>Advanced Leave</Option>
                  <Option value="Comp_off">Comp Off</Option>
                  {/* Add more leave types as needed */}
                </Select>
              </Form.Item>
              {
                isOptionalSelected &&
                <Form.Item
                  name="optionalHoliday"
                  label="Holidays"
                  rules={[{ required: true, message: 'Please select holiday!' }]}
                >
                  <Select placeholder="Select a day type">
                    {
                      listofHolidays?.map((item) => (
                        <Option value={item}>{item}</Option>
                      ))
                    }

                    {/* <Option value="FullDay">Full Day</Option> */}
                  </Select>
                </Form.Item>
              }
            </Form>
          </div>
        </Modal>
      }
      {geofenceOpen &&
        <Modal
          title="Geo Fence"
          open={geofenceOpen}
          onCancel={() => setGeofenceOpen(false)}
          footer={null}
          width={800}
          centered
        >
          {isLoaded && (!isNaN(lat) || !isNaN(lng)) && Data?.latitude !== '0' && Data?.longitude !== '0'
            && Data?.latitude !== "0.00000000" && Data?.longitude !== "0.00000000" ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={mapCenter}
              zoom={10}
              onLoad={map => (mapRef.current = map)}
            >
              {isValidLatLng && (
                <MarkerF position={mapCenter} />
              )}
            </GoogleMap>
          ) : (
            <div style={{ height: "50vh", width: "100%", justifyContent: "center", alignItems: "center", display: "flex" }}>
              No Data for Punch in and punch out...</div>
          )}
        </Modal>
      }
    </>
  );
};

export default UpdateAttendanceModal;
