
import { create } from "zustand";

export const  useProjecteStore=create(set=>({
    filteredData:{other:[],department:[],team:[],manager:[],role:[],selDepartment:"",selTeam:"",selmanager:"",startDate:"",endDate:""},
    logsData:[],
    taskLogs:{name:'',userId:'',TableData:[],dateRange:''},
    projectClickedData:undefined,
    setProjectClickedData:(value)=>{
        set((state)=>({
            projectClickedData:value,
        }))
    },
    setFilterValues:(key,value)=>{        
        set(state=>({
            filteredData:{ ...state.filteredData,[key]:value},
    }))
    },
    setLogData:(val)=>{
        set(state=>({logsData:val}))
    },
    setTaskLogs:(key,value)=>{
        set(state=>({
            taskLogs:{...state.taskLogs,[key]:value}
        }))
    },
    selectedAssociateName:null,
    setSelectedAssociateName:(event) => set(state => ({ selectedAssociateName: event }))
}))