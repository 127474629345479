import React, { useEffect, useState } from "react";
import profileIcon from "../../../assets/profileIconForProfile.svg";
import EditIcon from "../../../assets/EditProfIcon.svg";
import SettingIcon from "../../../assets/SettingProfIcon.svg";
import ManageNotifiIcon from "../../../assets/Manage notifications Icon.svg";
import JobsearchIcon from "../../../assets/Job Search ICon.svg";
import PasswrodIcon from "../../../assets/Change password Icon.svg";
import RateIcon from "../../../assets/RateIcon.svg";
import playstore from "../../../assets/ic-playstore icon for profile.png";
import appstore from "../../../assets/appstore.svg";

import WorldIcon from "../../../assets/WorldIcon.svg";
import SavedJobs from "../../../assets/savedJobsIcon.svg";
import BackgroundIcon from "../../../assets/Background.svg";
import AppliedIcon from "../../../assets/appliedJobIcon.svg";

import AccountDetailsIcon from "../../../assets/accountdetailsIcon.svg";
import CameraIcon from "../../../assets/CameraIcon.svg";
import classes from "./profile.module.css";
import { ProfileStore } from "./store";
import EditDetails from "./EditDetails";
import SavedJobModal from "./SavedJobs";
import Setting from "./Setting";
import EditImageModal from "./EditImageModal";
import { useLoginStore } from "../store";
import Api from "../../../services/Api";
import { decryptData, encryptData, SECRET_KEY } from '../../../services/fileCode';
import AccountDetails from "./AccountDetails";
import FooterDetails from "./footerDetails";
import HiringStatus from "../HiringStatus/HiringStatus";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";

export const ProfileMain = () => {
  const { currentPage, setCurrentPage, editImageModal, setEditImageModal, activeSetting, setActiveSetting,
    userData, setUserData, candidateDetails, setCandidateDetails, loading, setLoading ,setfooterRef} = ProfileStore();
  const { setActiveTab } = useLoginStore();
  const [isProgress,setProgress]=useState(false);
  // const [userData,setUserData] = useState({})
  const {width}=useWindowDimensions();
  const storedUser = localStorage.getItem('user');
  let decryptedUser = decryptData(storedUser, SECRET_KEY);

  const getProfile = () => {
    Api.get(`/auth-auth/getProfile?authId=${decryptedUser?.authUserLoginId}`)
      .send((res) => {
        if (res !== undefined) {
          setUserData(res)
        }
      })
  }

  const checkProgress=(email)=>{
    Api.get('/hiring-mobile/checkInprogress').params({email:email}).send((res)=>{
      //if inProgress is true that means we have to disable the camera icon.
      setProgress(!res?.inProgress);
    })
  }
  console.log("ssssUserRole",decryptedUser)

  useEffect(() => {
   
    getProfile();
  }, [])
  useEffect(()=>{
    if(userData?.email){
      checkProgress(userData?.email);
    }
  },[userData?.email])
  const footerHandler = (type) => {
    setfooterRef(type)
    setCurrentPage("footer")

  }
  const getOS = () => {
    const platform = window.navigator.platform;
    const userAgent = window.navigator.userAgent;

    if (platform.toLowerCase().includes('win')) {
      return 'PlayStore';
    } else if (platform.toLowerCase().includes('mac')) {
      return 'AppStore'; 
    } else if (userAgent.toLowerCase().includes('android')) {
      return 'PlayStore'; 
    } else if (userAgent.toLowerCase().includes('iphone') || userAgent.toLowerCase().includes('ipad') || userAgent.toLowerCase().includes('ipod')) {
      return 'AppStore'; 
    } else {
      return 'Store';
    }
  };
  return (
    <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
      <div style={{ height: "18vh", width: "100%",marginTop:width<700&&"-2rem" }}>
        <img src={BackgroundIcon} alt="" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
      </div>
      <div style={{ height: "80vh", display: "flex", alignItems: "center", justifyContent: "center", width: "100%", marginTop: "-6vh" }}>

        <div style={{ display: "flex", flexDirection: "column", gap: "0.8rem", justifyContent: "center", width: width > 700 ? "50%":"90%", }}>

          <div style={{ backgroundColor: "#FFFFFF", borderRadius: "5px", padding: "0 1vw 2vh 1vw", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <img src={userData?.profileImage ? userData?.profileImage : profileIcon} alt="" style={{ marginTop: "-5vh", height: width >700? "15vh":"13vh", width: "6rem", borderRadius: "50%", border: "1px dotted #0086FF" }} />
            { isProgress && <img src={CameraIcon} alt="" style={{ marginTop: "-5vh", height: width >700? "5vh":"7vh", width:width >700? "6vw":"8vw", objectFit: "cover", marginLeft: "3rem", cursor: "pointer" }}
              onClick={() => {
                setEditImageModal(true);
              }} /> }
            <h3>{userData?.name}</h3>
            <div style={{ fontSize: "0.9rem", margin: "0.6vh 0" }}>{userData?.phone}</div>
            <div style={{ fontSize: "0.9rem" }}>{userData?.email}</div>
          </div>

          <div style={{ backgroundColor: "#FFFFFF", borderRadius: "5px", padding: "3vh 1vw", display: "flex", flexDirection: "column", }}>
            <div style={{ borderBottom: "2px solid #E6E6E6", padding: "1vh 2vw", display: "flex", gap: "1rem", alignItems: "center", fontSize:"0.8rem" }} onClick={() => {
              setCurrentPage("accountDetails");
            }}>
              <img src={AccountDetailsIcon} style={{height: "2.6vh"}} /> <span style={{ cursor: "pointer", }}>Account details</span>
            </div>

            <div style={{ borderBottom: "2px solid #E6E6E6", padding: "1.5vh 2vw 2vh 2vw", display: "flex", gap: "1rem", alignItems: "center", fontSize: "0.8rem" }} onClick={() => {
              setCurrentPage("editDetails");
            }}>
              <img src={EditIcon} style={{height: "2.6vh"}} /> <span style={{ cursor: "pointer", }}>Profile details</span>
            </div>

            <div style={{ borderBottom: "2px solid #E6E6E6", padding: "1.5vh 2vw 2vh 2vw", display: "flex", gap: "1.5rem", alignItems: "center", fontSize: "0.8rem" }} onClick={() => {
              setCurrentPage("savedJobs");
            }}>
              <img src={SavedJobs} style={{height: "2.6vh"}} /> <span style={{ cursor: "pointer", }}>Saved Jobs</span>
            </div>
            {/* {decryptedUser?.hiringUser?.role.includes("Agency")} */}
            <div style={{ borderBottom: "2px solid #E6E6E6", padding: "1.5vh 2vw 2vh 2vw", display: "flex", gap: "1rem", alignItems: "center", fontSize: "0.8rem" }} onClick={() => {
              setCurrentPage("appliedJob");
            }}>
              <img src={AppliedIcon} style={{height: "2.6vh"}} /> <span style={{ cursor: "pointer", }}>Applied Jobs</span>
            </div>

            <div style={{ borderBottom: "2px solid #E6E6E6", padding: "1.5vh 2vw 2vh 2vw", display: "flex", gap: "1.05rem", alignItems: "center", fontSize: "0.8rem" }} onClick={() => {
              setCurrentPage("settings");
            }}>
              <img src={SettingIcon} style={{height: "2.6vh"}} /> <span style={{ cursor: "pointer", }}>Settings</span>
            </div>
          </div>

          <div style={{ backgroundColor: "#FFFFFF", borderRadius: "5px", padding: "2vh 1vw", width: "100%" }}>
            <h3 style={{ color: "#808080" }}>QUICK LINKS</h3>
            {width >700 ? 
            <div className={classes.Links} style={{justifyContent:"space-evenly",gap:0}}>
              <span onClick={() => window.open("https://brigosha.com")} style={{fontSize:width >700?"0.8rem":"0.6rem",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",}}>
                <img src={WorldIcon} /> Explore brigosha expertise
              </span>
              {/* :
              <span onClick={() => window.open("https://brigosha.com")} style={{fontSize:width >700?"0.8rem":"0.6rem",display:"flex",flexDirection:"column",}}>
                <img src={WorldIcon} /> <span>Explore brigosha</span><span>expertise</span>
              </span>
              } */}

              <span onClick={() => {
                  if(getOS()==="PlayStore"){
                    window.location.href = 'https://play.google.com/store/apps/details?id=com.brigosha.appNew&hl=en-IN';
                  }else{
                    window.location.href = 'https://apps.apple.com/app/your_app_name/id6467048804';
                  }
                 }} style={{fontSize:width >700?"0.8rem":"0.6rem",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",}}>
                <img src={getOS()==="PlayStore"?playstore:appstore} style={{margin:"0.1vh"}}/>Rate us on {getOS()}
              </span>
              {/* <span><img src={RateIcon} /> Rate us on App Store</span> */}
              <span onClick={() => setActiveTab(3)} style={{fontSize:width >700?"0.8rem":"0.6rem",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",}}>
                <img src={JobsearchIcon} /> Job search</span>
              {/* <span onClick={() => { setCurrentPage("settings"); setActiveSetting("Manage notifications") }}>
                <img src={ManageNotifiIcon} /> Manage notifications</span> */}
             {/*  <span onClick={() => { setCurrentPage("settings"); setActiveSetting("Change password") }}>
                <img src={PasswrodIcon} /> Change password</span> */}
            </div>
            :
            <div className={classes.Links} style={{justifyContent:"space-evenly",gap:0}}>
              <span onClick={() => window.open("https://brigosha.com")} style={{fontSize:width >700?"0.8rem":"0.6rem",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",}}>
                <img src={WorldIcon} /> 
                <span style={{marginTop:"0.5rem"}}>Explore brigosha</span> <span>expertise</span>
              </span>
              <span onClick={() => {
                  if(getOS()==="PlayStore"){
                    window.location.href = 'https://play.google.com/store/apps/details?id=com.brigosha.appNew&hl=en-IN';
                  }else{
                    window.location.href = 'https://apps.apple.com/app/your_app_name/id6467048804';
                  }
                 }} style={{fontSize:width >700?"0.8rem":"0.6rem",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",}}>
                <img src={getOS()==="PlayStore"?playstore:appstore} style={{margin:"0.1vh"}}/>
                <span style={{marginTop:"0.5rem"}}>Rate us on</span>
                <span>{getOS()}</span>
              </span>
              <span onClick={() => setActiveTab(3)} style={{fontSize:width >700?"0.8rem":"0.6rem",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",}}>
                <img src={JobsearchIcon} /> <span style={{marginTop:"0.5rem"}}>Job search</span>
                <span>{" "}</span>
              </span>
            </div>
            }
          </div>

          <div style={{ backgroundColor: "#FFFFFF", padding: "2vh 1vw", gap: "1vw", borderRadius: "5px", display: "flex", flexDirection: "row", justifyContent: "center", width: "100%" }}>
            <span onClick={() => { window.location.href="https://brigosha.com/teams" }} style={{ color: "#CACACA", fontSize: "0.8rem", borderRight: "1px solid #CACACA", padding: "0 1vw" , cursor:"pointer"}}>About Us</span>
            <span onClick={() => { footerHandler("terms") }} style={{ color: "#CACACA", fontSize: "0.8rem", borderRight: "1px solid #CACACA", padding: "0 1vw",cursor:"pointer" }}>Terms of use</span>
            <span onClick={() => { footerHandler("privacy") }} style={{ color: "#CACACA", fontSize: "0.8rem", padding: "0 1vw",cursor:"pointer" }}>Privacy policy</span>
          </div>
        </div>
      </div>
      {editImageModal && <EditImageModal refresh={getProfile.bind(this)} />}
    </div>
  )
}

const Profile = ({ setIsLogin, setActvieTab }) => {

  const { currentPage, userData, setCandidateDetails, setLoading, setCurrentPage } = ProfileStore();

  const getCandidateProfileDetails = () => {
    setLoading(true);
    Api.get(`/hiring-mobile/getProfile?email=${userData?.email}`)
      .send((res) => {
        setLoading(false);
        setCandidateDetails(res);
      })
  }

  useEffect(() => {

    if(userData?.email){
      getCandidateProfileDetails();
    }
  }, [userData?.email]);

  return (
    <>
      {
        currentPage === "accountDetails" ? <AccountDetails />
          : currentPage === "editDetails" ? <EditDetails refresh={getCandidateProfileDetails.bind(this)} />
            : currentPage === "savedJobs" ? <SavedJobModal setActvieTab={setActvieTab} />
              : currentPage === "settings" ? <Setting />
                :
                currentPage === "footer" ? <FooterDetails onBackClick={()=>{setCurrentPage("")}}/>
                :currentPage==="appliedJob"?<HiringStatus reference={"profile"} onBackClickProfile={()=>{setCurrentPage("profile")}}/>
                : <ProfileMain />
      }
    </>
  )
}
export default Profile;