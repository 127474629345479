import { Button, DatePicker, Form, Input, Select, Spin, Typography, Upload, } from "antd";

import classes from './profileDetails.module.css';
import { useState } from "react";
import ProfilePersonal from "./profilePersonal";
import ProfileDocument from "./profileDocument";
import ProfileWorkExp from "./profileWorkExp";
import profile_icon from '../../../../assets/profile_icon.svg';
import SemiCircleProgressBar from "react-progressbar-semicircle";

import { useEffect } from "react";
import mail_white_icon from '../../../../assets/mail_white_icon.svg';
import { useResourceStore } from "../../store/store";
import { getUserDataApi, lastDateChangeApi, mailPDFApi, profileUploadApi } from "./employeeAction";
import { generatePdfBlob } from "./pdfgeneration/PDFDesign";
import { EditOutlined, LeftOutlined } from "@ant-design/icons";
import Notification from "../../../../services/Notification";
import { NavBar } from "../../../../components/topNavBar";
import { getLink } from "../../../../Config";
import ModalCompo from "../../../../design/ModalCompo";
import viewClass from "../../../../services/ViewRole";
import { ModalPrimaryButton, ModalSecondaryButton } from "../../../../components/Button/Button";

function ProfileDetails({departmentList}){
    const [profileType,setProfileType]=useState("Profile");
    const {page,setPage,openPage}=useResourceStore(state=>state);
    const [openDateModal,setOpenModal] = useState(false);
    const [releaseLastDate,setReleaseLastDate]=useState("");
    const [loader,setLoader]=useState(false);
    const [profileData,setProfileData]=useState();
    const navData=['Profile','Documents','Work Experience'];
    const [form] = Form.useForm();
    const handleReset = () => {
        form.resetFields(); // This will clear the form fields
    };
    // const getblob=async()=>{
    //     let pdfBlob=await generatePdfBlob(profileData);
    //     let pdfUrl=URL.createObjectURL(pdfBlob)
    //     setfile(pdfUrl);
    // }

    // useEffect(()=>{
    //     getblob();
    // },[profileData])
    useEffect(()=>{
        setProfileType(page?.PD)
    },[page?.PD])
    const refreshUser=()=>{
        setLoader(true)
        getUserDataApi(openPage,(res)=>{
            setProfileData(res)
            setLoader(false)
        })
    }

    const mailPDFHandler= async()=>{
        console.log("profileData2",profileData);
        let pdfBlob;
        try{
             pdfBlob= await generatePdfBlob(profileData);
        }
        catch(err)
        {
          Notification.error("Profile completion should not be 0!");
          return;
        }
    
    // this is used in backend to split the name and uid ,"%-#-%", if you are removing it please contact backend.
    let name=(await profileData?.profile?.associateProfile?.uid+"%-#-%"+profileData?.profile?.associateProfile?.associateName);
    let formData = new FormData();
    
    formData.append('pdf', pdfBlob,`BioData_${name}.pdf`);
   const param={'userId':openPage,'pdfBuffer':formData.get('pdf')} 
   mailPDFApi(param,(res)=>{
    console.log('api success',res)
   })
    }
useEffect(()=>{
    setLoader(true)
    getUserDataApi(openPage,(res)=>{
        setProfileData(res);
        setLoader(false);
    })
},[openPage])

    return(
        <>
                {loader?<Spin style={{margin:"20% auto auto 50%",display:"flex"}}></Spin>:<div className={classes.profileDetails}>
                 
                    <div className={classes.header}>
                        <div style={{display:'flex',alignItems:'center',gap:'6px',fontSize:'20px'}}>
                            <LeftOutlined onClick={()=>{setPage({prev:'profileDetail', curr:'employee',PD:''})}} />
                            <div style={{font:'normal 700 20px/24px Lato'}} >Associate Profile</div>
                        </div>
                        <Button type="primary" disabled={viewClass?.hrAssociate} onClick={()=>{
                                mailPDFHandler();}}>
                            <img src={mail_white_icon} />
                            Mail PDF
                        </Button>
                    </div>

                    <section className={classes.content}>
                        <div style={{width:'100%',background:'#f8f8f8',maxHeight:'80vh',overflowY:'scroll'}}>
                            <div className={classes.profileCommon}>
                                <div>
                                    <div style={{display:'flex',flexDirection:'column',gap:'6px',alignItems:'center',justifyContent:'center'}}> 
                                        <img style={{height:'120px',width:'120px',borderRadius:'50%'}} src={profileData?.profile?.associateProfile?.profileImage && profileData?.profile?.associateProfile?.profileImage.startsWith("https://") ? profileData?.profile?.associateProfile?.profileImage
                                        :profileData?.profile?.associateProfile?.profileImage && !profileData?.profile?.associateProfile?.profileImage.startsWith("https://") ? getLink(profileData?.profile?.associateProfile?.profileImage):profile_icon}/>  
                                        {!viewClass?.hrAssociate&&<label htmlFor={`file-upload-profile-image`} style={{color:'#0086ff',borderBottom:'2px dashed #0086ff'}}>Edit Photo</label>}
                                        <input  type="file" accept=".png, .jpg, .jpeg," id={`file-upload-profile-image`} style={{display:'none'}} key={Math.random()} showUploadList={false} onChange={(e)=>{
                                        
                                            console.log("UPLOADEDPHOTO",e.target.files[0])
                                            let exe= e.target.files[0]?.name.split(".").pop().toLowerCase();
                                       if(!['jpg','png','jpeg']?.includes(exe)){
                                         Notification.error("Error","Please select jpg/png file")
                                         return;
                                       }
                                       else{
                                            profileUploadApi(openPage,e.target.files[0],(res)=>{
                                                refreshUser();
                                            });}
                                        }}>
                                        </input>                           
                                                                       
                                    </div>
                                    <div>
                                        <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",alignItems:"center"}}>
                                        <Typography.Text ellipsis={{tooltip:true}} style={{font:'normal 700 22px/26px Lato' ,color:'#162432',maxWidth:'25vw'}}>{profileData?.profile?.associateProfile?.associateName}</Typography.Text>
                                        <span className={`header3Font ${profileData?.profile?.associateProfile?.status==="On Job Training"?"onJobTraining":profileData?.profile?.associateProfile?.status}`}>{profileData?.profile?.associateProfile?.status}</span>
                                        </div>
                                        <Typography.Text ellipsis={{tooltip:true}} style={{color:'#16243280',font:'normal 400 16px/22px Lato',maxWidth:'25vw'}}>{profileData?.profile?.associateProfile?.email}</Typography.Text>
                                        <div>{profileData?.managerDetails?.name}</div>
                                        <div>
                                            <span>Dept. Last Date:</span>
                                            <span style={{color:'#16243280',font:'normal 400 16px/22px Lato'}} >{profileData?.profile?.associateProfile?.releaseDate?profileData?.profile?.associateProfile?.releaseDate:"NA"}</span>
                                            {!viewClass?.hrAssociate&&<EditOutlined style={{color:'#0086ff'}} onClick={()=>{setOpenModal(true)}} />}      
                                            <div>
                                            </div>
                                        </div>
                                        <div>
                                            <span className='header3Font'>Profile Completion: </span>
                                            <span style={{color:'#39c16c'}}>{profileData?.profileCompletion?Math.round(profileData?.profileCompletion):0}%</span>
                                        </div>
                                        <div>
                                        <Typography.Text ellipsis={{tooltip:true}} style={{maxWidth:'120px'}} className='header3Font'>Verification Completion</Typography.Text>:
                                        <span style={{color:'#39c16c',fontWeight:'550'}}>{profileData?.verifiedPercentage?Math.round(profileData?.verifiedPercentage):0}%</span>
                                        </div>

                                    </div>
                                </div>
                                {/* <div className={classes.WebProgress}> */}
                                    <div>
                                        <span>Profile Completion</span>
                                            <div>
                                                    <SemiCircleProgressBar percentage={profileData?.profileCompletion?Math.round(profileData?.profileCompletion):0} showPercentValue />
                                            </div>
                                            <div>
                                                <span>0%</span>
                                                <span>100%</span>
                                            </div>
                                        <div>
                                        </div>

                                    </div>
                                    <div>
                                        <span>Verification Completion</span>
                                            <div>
                                                    <SemiCircleProgressBar percentage={profileData?.verifiedPercentage?Math.round(profileData?.verifiedPercentage):0} showPercentValue />
                                            </div>
                                            <div>
                                                <span>0%</span>
                                                <span>100%</span>
                                            </div>
                                        <div>
                                        </div>

                                    </div>
                                {/* </div> */}
                            </div>
                            <div style={{height:'3rem', paddingLeft:'20px', background:'#fff',display:'flex',alignItems:'center'}}>
                                <NavBar data={navData} defaultView={profileType} onClick={(res) => {
                            setProfileType(res)
                        }}  />
                            </div>
                            {/* <embed src={fileppp?fileppp:''} style={{width:'50vw',height:'60vh'}} /> */}
                            <ModalCompo className={classes.LastDateModal} open={openDateModal} closable={true} footer={false} closableRight={true}
                            onCancel={()=>setOpenModal(false)}>
                                <div style={{padding:"1.5rem",display:"flex",alignItems:"center",justifyContent:"center"}}> 
                               {(profileData?.profile?.associateProfile?.status==='Released' &&  profileData?.profile?.associateProfile?.releaseDate==null)?
                               <div>
                        
                               <Form form={form} layout="vertical"
                                    style={{ padding: "0 2rem",maxHeight:'60vh',overflowY:'scroll' }}
                                    onFinish={(event) => {
                                    let param={...event, userId:openPage,lastDate:releaseLastDate}
                                    lastDateChangeApi(param,()=>{
                                            setOpenModal(false)
                                                refreshUser();
                                            })
                                    console.log('REELESE EVENT', event['reason'], );
                                      }}>
                        <Form.Item name="departmentId" label={<span>Select Department</span>} rules={[{ required: true, message: "Kindly select a department!" }]}>
                        <Select   placeholder="Department" style={{width:'100%',borderRadius:'5px'}}>
                            {departmentList?.map((val,id)=>{
                                return(
                                <Select.Option value={val?.id} key={id} >{val?.name}</Select.Option>
                                )
                            })}
                        </Select>
                        </Form.Item>
                        <Form.Item name="lastDate" label={<span>Select Date of Release</span>} rules={[{ required: true, message: "Kindly select a date!" }]}>
                            <DatePicker style={{ width: "100%" }} size="large"  onChange={(date,dateString)=>{setReleaseLastDate(dateString);}}/>
                        </Form.Item>

                        <Form.Item name="reason" label={<span>Reason</span>}>
                            <Input.TextArea rows={6} />
                        </Form.Item>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "1.5rem", justifyContent: "flex-start", margin: "3rem auto" }}>
                            <ModalSecondaryButton
                                onCancel={() => {
                                    setOpenModal(false);
                                    handleReset()
                                }}
                            >Cancel</ModalSecondaryButton>
                            <ModalPrimaryButton htmlType="submit">Save</ModalPrimaryButton>
                        </div>
                    </Form>
                               </div>
                                   :<div style={{display:"flex",flexDirection:"column",gap:"1rem",justifyContent:"center"}}>  
                                    <div style={{display:"flex",justifyContent:"center"}}><h3>Dept. Last Date</h3></div>
                                    <div style={{display:"flex",justifyContent:"center"}}>
                                    <DatePicker placement='bottomRight' format={"YYYY-MM-DD"} style={{width:"17rem"}} onChange={(date,dateString)=>{
                                        setReleaseLastDate(dateString);
                                    }}/></div>
                                    <div style={{display:"flex",flexDirection:"row",gap:"1rem",marginTop:"1rem"}}>
                                        <Button style={{width:"5rem"}} disabled={!releaseLastDate} type="primary" htmlType="submit"onClick={()=>{
                                            let param={userId:openPage,lastDate:releaseLastDate}
                                            lastDateChangeApi(param,()=>{
                                                
                                                refreshUser();
                                            })
                                         setOpenModal(false)
                                        }}>Save</Button>
                                        <Button style={{backgroundColor:"white",width:"5rem"}} onClick={()=>setOpenModal(false)}>Cancel</Button>
                                    </div>
                                    </div>}
                                </div>
                            </ModalCompo>
                            <div >
                                {profileType==="Profile"&&<ProfilePersonal  profile={profileData?.profile} userFlow={profileData?.userFlow} />}
                                {profileType==="Documents"&&<ProfileDocument documents={profileData?.documents} allDocuments={profileData?.allDocuments}  />}
                                {profileType==="Work Experience"&&<ProfileWorkExp  workExperience={profileData?.workExperience} experience={profileData?.experience} />}
                            </div>

                        </div>

                    </section>
                        
                </div>}
        </>
    )
}
export default ProfileDetails;