import React, { useEffect, useState } from 'react';
import {  AiOutlineDownload } from 'react-icons/ai';

import '../../../design/framework.css'
import { downLoadData,downLoadDataDeptAssct } from './Action/action';
import { Button, Radio, Select } from 'antd';
import { allCity,allDepartmentList,allAssociateList } from './Action/action';
import { useNotificationStore } from './store';


const Option = Select.Option
const TextComponent = ({ text, width, fontSize, backgroundColor, marginLeft, color, marginRight, fontWeight }) => {
    return (
        <div style={{ fontSize: '20px', width: width, fontSize: fontSize, marginLeft: marginLeft, color: color, marginRight: marginRight, fontWeight: fontWeight }}>
            {text}
        </div>
    );
};



function DownloadModel({ setdownload, setCenterModel, record }) {
    const { allCityStore, setAllCityStore,holidayListBy,setAllDepartment,setAllAssociate,
        allAssociate,allDepartment } = useNotificationStore();

    const [value, setValue] = useState(1);
    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
        setdetails({...details,download:value==1?false:true})
    };
    const [views, setViews] = useState(false)
    const [refresh, setRefresh] = useState(true)

    useEffect(() => {
        setRefresh(false)
        allCity((res) => {
            setRefresh(false)
            setAllCityStore(res)
        })
        allDepartmentList((res) => {
            setRefresh(false)
            setAllDepartment(res)
          })
        allAssociateList((res) => {
            setRefresh(false)
            setAllAssociate(res)
        })
    }, [])

    const [details, setdetails] = useState({
        download:true,
        cityId:'',
        type:'Fixed',
        id:'',
    })
   
  

    return (

        <>

            <div style={{ padding: 10 }}>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}>


                    <>
                        <div className='r-jc' style={{ display: 'flex',flexDirection:"row",justifyContent:"center" }}>
                            <AiOutlineDownload size={22} color='#0DAC45' />
                            <div style={{ fontSize: '17px', color: '#0DAC45', marginLeft: 10, fontWeight: '600' }}>Download</div>
                        </div>
                    </>

                    <div style={{ width: '100%', height: 0.8, backgroundColor: '#F1F1F1', marginTop: 10 }} />

                    {!views ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <Radio.Group onChange={onChange} value={value} style={{ display: 'flex', flexDirection: 'column' }}>
                            <Radio style={{ padding: 10 }} value={1}>Custom download</Radio>
                            <Radio style={{ padding: 10 }} value={2}>Download All</Radio>
                        </Radio.Group>
                    </div> :
                        <div style={{ background: 'white', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }} >
                            {holidayListBy === "Location" ?
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                                <TextComponent text="Location city" fontWeight={500} fontSize={15} />
                                
                                <Select placeholder="Select city"
                                    style={{ marginTop: 10 }}
                                    loading={refresh} 
                                    value={details?.cityId}
                                    onChange={(value) => {
                                       
                                        setdetails({ ...details, cityId: value })}}
                                    tokenSeparators={[',']}
                                >
                                   
                                    {allCityStore.map((item) => (
                                        <Option key={item?.id}>{item?.cityName}</Option>
                                    ))}
                                </Select>
                            </div>
                            :holidayListBy === "Department" ?
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                                <TextComponent text="Department Name" fontWeight={500} fontSize={15} />
                                
                                <Select placeholder="Select Department"
                                    style={{ marginTop: 10 }}
                                    loading={refresh} 
                                    value={details?.id}
                                    onChange={(value) => {
                                    
                                        setdetails({ ...details, id: value })}}
                                    tokenSeparators={[',']}
                                >
                                
                                    {allDepartment.map((item) => (
                                    <Option key={item?.id} value={item?.id}>{item?.name}</Option>
                                    ))}
                                </Select>
                            </div>
                            :
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                                <TextComponent text="Associate Name" fontWeight={500} fontSize={15} />
                                
                                <Select placeholder="Select Associate"
                                    style={{ marginTop: 10 }}
                                    loading={refresh} 
                                    value={details?.id}
                                    onChange={(value) => {
                                    
                                        setdetails({ ...details, id: value })}}
                                    tokenSeparators={[',']}
                                >
                                    {allAssociate.map((item) => (
                                        <Option key={item?.id} value={item?.id}>{item?.name}</Option>
                                    ))}
                                </Select>
                            </div>
                            }

                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 20, marginBottom: 20 }}>
                                <TextComponent text="Holiday Type" fontWeight={500} fontSize={15} />
                                <Select
                                    defaultValue="Fixed"
                                    style={{ marginTop: 10 }}
                                    onChange={(e) => {
                                       
                                        setdetails({ ...details, type: e })

                                    }}
                                    options={[
                                        { value: 'Fixed', label: 'Fixed' },
                                        { value: 'Optional', label: 'Optional' },
                                    ]}
                                />
                            </div>
                        </div>}
                        
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
                        <Button 
                        // disabled={!views && (details.cityId === "" || details.type === "")}
                            onClick={() => {
                                if (value == 1) {
                                    setViews(true)
                                    if(holidayListBy === "Location"){
                                      if(details?.cityId){
                                        let url = `/attendance-setting/allHolidays?pageNo=${1}&limit=${100}&download=${true}&holidayType=${details?.type}&cityId=${details?.cityId}`;
                                        downLoadData(()=>{
                                            setCenterModel(false)
                                            setdownload(false)
                                        },url)
                                     }
                                    }else{
                                        if(details?.id){
                                        let url = `/attendance-setting/downloadDataDS?type=${holidayListBy}&holidayType=${details?.type}&id=${details?.id}`;
                                        downLoadDataDeptAssct(()=>{
                                            setCenterModel(false)
                                            setdownload(false)
                                        },url)
                                     }
                                    }
                                }else{
                                  if(holidayListBy === "Location"){
                                    let url =`/attendance-setting/allHolidays?pageNo=${1}&limit=${100}&download=${true}`;
                                    downLoadData(()=>{
                                        setCenterModel(false)
                                        setdownload(false)
                                    },url)
                                  }else{
                                    let url =`/attendance-setting/downloadDataDS`;
                                    downLoadDataDeptAssct(()=>{
                                        setCenterModel(false)
                                        setdownload(false)
                                    },url)
                                  }
                                }
                                
                            }}
                            style={{ background: '#0086FF', color: 'white', borderRadius: 10,width:"6rem",height:"2.2rem",
                            alignItems: 'center', margin: 10, 
                            paddingRight: 20 }}>{ value === 2 ? "Download" : !views ? "Next" : "Download"}
                            </Button>
                        <Button onClick={() => {
                            setCenterModel(false)
                            setdownload(false)
                        }} style={{color: '#0086FF',height:"2.2rem", borderRadius: 5, alignItems: 'center', borderRadius: 10, margin: 10, padding:5, borderWidth: 1, paddingLeft: 20, paddingRight: 20, border: "1px solid #0086FF" }}>Cancel</Button>

                    </div>



                </div>
            </div>
        </>
    );
}

export default DownloadModel;