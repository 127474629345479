import { Table, Button, Space, Select, Form, DatePicker, Input, Tooltip} from "antd";
import Vector from "../../../assets/Vector.svg"
import React, { useEffect, useState } from "react";
import { useManageTasks, useReleaseStore, useStore , useFlagButtonStore} from "./store";
import { getDepartmentApi, getReleasbleMembersApi, releaseMemberApi } from "./Actions";
import Api from "../../../services/Api";
import profile_icon from "../../../assets/profile_icon.svg";
import './release.css';
import Modal from "../../../components/Modal/Modal";
import { IoMdContact } from "react-icons/io";

import { ModalPrimaryButton, ModalSecondaryButton } from "../../../components/Button/Button";
import nodatagifimg from '../../../assets/nodatagifimg.gif';
import releaseaction from '../../../assets/releaseaction.svg'
import TableCustom from "../../../components/tableCustom/customTable";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import { Radio } from "antd/lib";
import { useNavigate } from 'react-router-dom';
import ManageTasks from "../Projects/ManageTasks";

export const MobileCard = ({ headerLeft, headerRight, body , onCardClick= ()=>{}}) => {
    return (
        <>
            <div onClick ={(e)=>{e.stopPropagation(); onCardClick()}} style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", width: "100%", height: "fit-content", padding: 10 , backgroundColor:"#FFFFFF"}}>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", }}>
                    <div style={{ width: "50%", fontSize: 12 }}>
                        {headerLeft}
                    </div>
                    <div style={{ width: "35%", justifyContent: "flex-end", display: "flex" }}>
                        {headerRight}
                    </div>
                </div>
                <div style={{ width: "100%", fontSize: 13 }}>
                    {body}
                </div>
            </div>
        </>
    )
}

//checking modal rendering with the help of page has been changed to check modal rendering with the help of count which stores activetaskscount
const Release = () => {
    const [employeeInfo, setEmployeeInfo] = useState();
    const [deptId, setDeptId] = useState("");
    const [page, setPage] = useState();
    const [closeTasksPage, setCloseTasksPage]  = useState(1);
    const [form] = Form.useForm();
    const [check, setcheck] = useState('');
    const [data, setData] = useState('');
    const [count,setCount] = useState();

    // const [flag, setFlag] = useState(false);
    const [status, setStatus] = useState();

    const { width } = useWindowDimensions();
    const { department,
        releasableMembers,
        loader,
        releaseModal,
        closeTasksModal,
        departmentHandler,
        releasableMembersHandler,
        loaderHandler,
        releaseModalHandler,
        closeTasksModalHandler,
    } = useReleaseStore(state => state);


    const {userReleaseId,
        userReleaseDepId,
        userReleaseDep,
    
        setUserReleaseId,
        setUserReleaseDepId,
        setUserReleaseDep,
      
        getUserReleaseId,
        getUserReleaseDepId,
    } = useStore(state =>state);

    const {tasksUserId, userTasksUserName,setTasksUserId, setTasksUserName,setSelectedAssociateFilters,setFiltersList,setReleaseDepId,releaseDepId} =  useFlagButtonStore(state=>state);
    

    const {manageTasks, setManageTasks} = useManageTasks(state=>state);
    const Option = Select.Option;


    
 

    const handleReset = () => {
        form.resetFields(); // This will clear the form fields
        // setFlag(false);
    };

   
    useEffect(() => {
        //console.log(userReleaseDepId,"ZXCV")
        setDeptId(releaseDepId);
        getDepartmentApi(departmentHandler, Api);
        if(releaseDepId ){
            getReleasbleMembersApi(Action, IoMdContact, releasableMembersHandler, releaseDepId, loaderHandler, Api,Tooltip);
            //setcheck(userReleaseDepId);
         
          
         }
        // return()=>{
        //     const releaseButton = document.getElementById(`release-${userReleaseId}`);
        //     console.log(releaseButton,"FINALCHECK1");
        //     if(releaseButton){
        //         console.log(releaseButton, "FINALCHECK2");
        //     releaseButton.click();
        //     }

        // }
    }, [releaseDepId])

    // useEffect(() => {
    //     const element = document.getElementById(`release-${userReleaseId}`);
    //     if (element && userReleaseDepId && userReleaseId && !finalCount) {
    //         console.log(finalCount,element && userReleaseDepId && userReleaseId && !finalCount,"qwertyu");
    //         //setPage(0);
    //       element.click(); // Programmatically trigger the click event
    //     }
    //   }, [userReleaseDepId]); // Re-run effect when `record` changes
    

    // useEffect(()=>{
    //     getDepartmentApi(departmentHandler, Api);
    //     console.log("data-rr",releasableMembers)
    // },[]);


    const tableColumn = {
        0: {
            cols: "Associate",
            width: "45%",
            dataKey: "asso",
            onclick: () => { }
        },
        1: {
            cols: "Contact Details",
            width: "70%",
            dataKey: "contactDetails",
            onclick: () => { }
        },
        2: {
            cols: "Role",
            width: "25%",

            dataKey: "role",
            onclick: () => { }
        },
        4: {
            cols: "Status",
            width: "25%",
  
            dataKey: "status",
            onclick: () => { }
        },
        6: {
            cols: "Actions",
            width: "25%",
            dataKey: "actions",
            onclick: () => { }
        },
        5: {
            cols: "Designation",
            width: "30%",
            innerWidth:"6rem",

            dataKey: "designation",
            onclick: () => { }
        },
    }

    const Action = ({ record }) => {
        return (
            <>
                <div id={`release-${record?.userView?.id}`}
                 style={{cursor:record?.release?"pointer":(record?.userView?.status==="Training")?"pointer":"not-allowed"}} title="release"
                    onClick={() => {
                        setStatus(record?.userView?.status);
                        setPage(1);
                      
                        console.log("qwertyu",record);
                        setData(record);
                        setCount(record?.activeTaskCount);
                        setUserReleaseDepId(record?.departmentId);
                        setUserReleaseId(record?.userView?.id);
                        if(record?.release){
                            releaseModalHandler(true);
                            setEmployeeInfo({ uid: record?.userView?.uid, name: record?.userView?.name });
                        }else if(record?.userView?.status==="Training"){
                            releaseModalHandler(true);
                            setEmployeeInfo({ uid: record?.userView?.uid, name: record?.userView?.name });
                        }
                        // else if(record?.userView?.status==="Deployed"){
                        //     console.log("qwertyu",page)
                        //     setPage(0);
                        //     releaseModalHandler(true);
                        //     setEmployeeInfo({ uid: record?.userView?.uid, name: record?.userView?.name });
                            
                            
                        // }
                    }}
                >
                    <img src={releaseaction} style={{ width: 30, height: 30 }} />
                </div>
            </>
        )
    }

    const navigate = useNavigate();
    const handleClick = () => {

        releaseModalHandler(false);
        setManageTasks(true);
        
        const userId = data?.userView?.id;
        const userName = data?.userView?.name;
        //setTasksUserId(userId);
        setFiltersList({associateId: userId+''});
        setSelectedAssociateFilters([userId+'']);
        setTasksUserName(userName);
        const departmentId = data?.departmentId;
        setReleaseDepId(data?.departmentId);
        
        navigate(`/project/projects`,{state:departmentId});
    };

    return (
        <>
            {
                width < 700 ?
                    <>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 10, height:"90%" }}>
                            <h3>Release</h3>
                            <div style={{ display: "flex", justifyContent: "space-around", width: "60%", alignItems: "center" }}>
                                <h4>Department</h4>
                                <Select
                                    allowClear={true}
                                    placeholder="Select"
                                    style={{width:"25vw"}}
                                    value={deptId}
                                    onChange={(event, options) => {
                                        console.log("Project release", event, options)

                                        setcheck(options)
                                        if (event) {
                                            getReleasbleMembersApi(Action, IoMdContact, releasableMembersHandler, event, loaderHandler, Api,Tooltip);
                                            setDeptId(event);
                                        }



                                    }}
                                >
                                    {department?.map((item, index) => {
                                        return (<Option key={index} value={item?.id}>{item?.name}</Option>)
                                    })}
                                </Select>
                            </div>
                        </div>
                        <div>
                            {/* <h4>Team</h4> */}
                            {!deptId ?
                                <div style={{ width: '100%', display: 'flex', marginTop: 80, flexDirection: "column", paddingBottom: 100, alignItems: 'center', justifyContent: 'center' }}>
                                    <img src={nodatagifimg} alt="Example GIF" />
                                    <h4 style={{ fontWeight: 500, color: "#878787" }}>Please select Department</h4>
                                </div>
                                :
                                <>
                                    {console.log(releasableMembers, "releasableMembersreleasableMembers")}
                                    {
                                        releasableMembers?.map((item, index) => (
                                            <div style={{padding:5}}>
                                                <MobileCard headerLeft={(<div>{item?.asso}</div>)}
                                                    headerRight={item?.actions}
                                                    body={
                                                        (
                                                            <>
                                                               
                                                                <div >
                                                                <div style={{display:"flex", justifyContent:"space-between", width:"100%", padding:5}}>
                                                                        <div style={{width:"30%",color:"#B1B0B0"}}>Email : </div>
                                                                        <div title={item?.email} style={{width:"60%", display:"flex", justifyContent:"flex-start",textOverflow:"ellipsis", overflow:"hidden", whiteSpace:"nowrap"}}>{item?.email}</div>
                                                                </div>
                                                                <div style={{display:"flex", justifyContent:"space-between", width:"100%", padding:5}}>
                                                                        <div style={{width:"30%",color:"#B1B0B0"}}>id : </div>
                                                                        <div style={{width:"60%", display:"flex", justifyContent:"flex-start"}}>{item?.uid}</div>
                                                                </div>
                                                                <div style={{display:"flex", justifyContent:"space-between", width:"100%", padding:5}}>
                                                                        <div style={{width:"30%",color:"#B1B0B0"}}>Role : </div>
                                                                        <div style={{width:"60%", display:"flex", justifyContent:"flex-start"}}>{item?.role}</div>
                                                                </div>
                                                                <div style={{display:"flex", justifyContent:"space-between", width:"100%", padding:5}}>
                                                                        <div style={{width:"30%",color:"#B1B0B0"}}>Status : </div>
                                                                        <div style={{width:"60%", display:"flex", justifyContent:"flex-start"}}>{item?.status}</div>
                                                                </div>
                                                                <div style={{display:"flex", justifyContent:"space-between", width:"100%", padding:5}}>
                                                                        <div style={{width:"30%",color:"#B1B0B0"}}>Designation : </div>
                                                                        <div style={{width:"60%", display:"flex", justifyContent:"flex-start"}}>{item?.designation}</div>
                                                                </div>
                                                                </div>
                                                            </>
    
                                                        )
                                                    }
                                                />
                                            </div>

                                        ))
                                    }

                                </>
                            }
                        </div>
                    </>
                    :
                    <div className="release">
                        <div>
                            <h2>Release</h2>
                            <div>
                                <h4>Department</h4>
                                <Select
                                    allowClear={true}
                                    placeholder="Select"
                                   value={deptId} 
                                    onChange={(event, options) => {
                                        console.log("Project release", event, options)

                                        setcheck(options)
                                        if (event) {
                                            getReleasbleMembersApi(Action, IoMdContact, releasableMembersHandler, event, loaderHandler, Api,Tooltip);
                                            setDeptId(event);
                                            //setUserReleaseDep(event);
                                            
                                        }



                                    }}
                                >
                                    {department?.map((item, index) => {
                                        return (<Option key={index} value={item?.id}>{item?.name}</Option>)
                                    })}
                                </Select>
                            </div>
                        </div>
                        <div>
                            {/* <h4>Team</h4> */}
                            {!deptId ?
                                <div style={{ width: '100%', display: 'flex', marginTop: 80, flexDirection: "column", paddingBottom: 100, alignItems: 'center', justifyContent: 'center' }}>
                                    <img src={nodatagifimg} alt="Example GIF" />
                                    <h4 style={{ fontWeight: 500, color: "#878787" }}>Please select Department</h4>
                                </div>
                                :
                                <>
                                    <TableCustom charBreak={15} responsive={true} columns={tableColumn} dataSource={releasableMembers} loading={loader} />
                                </>
                                /*  <Table
                                     dataSource={releasableMembers}
                                     rowClassName={(record, index) => index % 2 === 0 ? 'tableRow' : 'tableRowWhite'}
                                     columns={columns}
                                     loading={loader}
                                     pagination={{ size: "small", showSizeChanger: true, pageSizeOptions: [10, 25, 30, 50], }}
                                     scroll={{ y: 520 }}
                                 /> */
                            }
                        </div>


                    </div>
            }
            <div>
            <Modal show={releaseModal}
                onCancel={() => {
                    releaseModalHandler(false);
                    setPage(1);
                    handleReset();
                    setStatus('');
                }}
                closableRight={true}
                
                style={{ width:width<700?"80vw": "50vw", display: "flex" }}
                header={
                    <div style={{ color: "#f94449", fontSize: "20px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "0.5rem", width: "100%", }}>
                        <img src={Vector} style={{ width: "1.1rem", height: "1.1rem" }} />{" "}
                        <h3 style={{ fontSize: "1.2rem", fontFamily:'Roboto' }}>Release</h3>
                    </div>}
            >
                
                {/* {((count !== 0  || page===0) ) ? <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around" }}>
                    <span style={{  marginBottom: 20, fontFamily:'Roboto', fontSize:'0.9rem' , color:'#555555'}} >
                        There are <span style={{fontWeight:'600'}}>{count} active tasks</span> for the associate.
                        <span style={{ color: "#0086FF" }}>{employeeInfo?.name} . </span>
                    </span>
                    <span style={{marginBottom:'20px',textAlign:'center',fontFamily:'Roboto', fontSize:'0.9rem', color:'#555555'}}>The associate must have no tasks assigned in order to proceed with their release. Please reassign or manage their tasks before moving forward.</span>
                    <Button type="primary" 
                        style={{ backgroundColor:'#1089FF',
                                fontFamily:'Roboto',
                                

                            }} 
                        onClick={handleClick}
                         >Manage Task(s)
                    </Button>
                    
                    <Space 
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',  // Aligns items to the right
                        marginTop: '40px',
                        alignItems: 'center',
                        marginLeft: 'auto',  // Adjusts the left margin to push content to the right
                        position: 'relative',  // Positions the Space container absolutely within its parent
                        bottom: '20px',  // Aligns it to the bottom with 20px margin
                        right: '20px'  // Aligns it to the right with 20px margin
                    }}
                    >
                        <ModalSecondaryButton
                            style={{ width: "6.5rem" }}
                            onCancel={() => {
                                releaseModalHandler(false);
                                
                            }} >Cancel</ModalSecondaryButton>
                        <ModalPrimaryButton
                            style={{ width: "6.5rem" }}
                            onSave={() => {
                                console.log(page,"CHECKK");
                                setPage(1);
                            }}
                            disabled>Next</ModalPrimaryButton>

                    </Space>
                </div> :  */}
                {page === 1  ?(<div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <span style={{ fontWeight: "600", marginBottom: 20 }} >
                        Are you sure to release <span style={{ color: "#0086FF" }}>{employeeInfo?.name}?</span>
                    </span>
                    <Space>
                        <ModalSecondaryButton
                            style={{ width: "6.5rem" }}
                            onCancel={() => {
                                releaseModalHandler(false);
                                
                            }} >Cancel</ModalSecondaryButton>
                        <ModalPrimaryButton
                            style={{ width: "6.5rem" }}
                            onSave={() => {
                                console.log(page,"CHECKK");
                                setPage(2);
                            }}>Next</ModalPrimaryButton>

                    </Space>
                </div>) :
                page===2?
                    <Form form={form} layout="vertical"
                        style={{ padding: "0 2rem" }}
                        onFinish={(event) => {
                            event = { ...event, date: event['date']?.format('YYYY-MM-DD') }
                            console.log('REELESE EVENT', event['reason'], deptId);
                            releaseMemberApi(employeeInfo?.uid, event['date'], event['reason'], releaseModalHandler, Api, deptId, () => {
                                setPage(1)
                            });
                            getReleasbleMembersApi(Action, IoMdContact, releasableMembersHandler, deptId, loaderHandler, Api,Tooltip);
                            handleReset();

                        }}>
                        <Form.Item name="date" label={<span>Select Date to Release</span>} rules={[{ required: true, message: "Kindly select a date!" }]}>
                            <DatePicker style={{ width: "100%" }} size="large" />
                        </Form.Item>

                        <Form.Item name="reason" label={<span>Reason</span>}>
                            <Input.TextArea rows={6} />
                        </Form.Item>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "1.5rem", justifyContent: "flex-start", margin: "3rem auto" }}>
                            <ModalSecondaryButton
                                onCancel={() => {
                                    releaseModalHandler(false);
                                    setPage(1);
                                    handleReset()
                                    
                                }}
                            >Cancel</ModalSecondaryButton>
                            <ModalPrimaryButton htmlType="submit">Save</ModalPrimaryButton>
                        </div>
                    </Form>
                    :null

                    
                }
                

  
                


            </Modal>
            {/* <Modal show={flag}
                onCancel={() => {
                    setFlag(false);
                    setCloseTasksPage(1);
                    handleReset();
                }}
               
                closableRight={true}
                
                style={{ width:width<700?"80vw": "50vw", display: "flex" }}
                header={
                    <div style={{ color: "red", fontSize: "20px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "0.5rem", width: "100%", }}>
                        <img src={Vector} style={{ width: "1.1rem", height: "1.1rem" }} />{" "}
                        <h3 style={{ fontSize: "1rem" }}>Release</h3>
                    </div>}
            >
                {closeTasksPage === 1 ? 
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <span style={{ fontWeight: "600", marginBottom: 20 }} >
                        Please Close the Active tasks before releasing <span style={{ color: "#0086FF" }}>{employeeInfo?.name}?</span>
                    </span>
                    
                        <Radio value="close">Close active tasks</Radio>
                    <Space>
                        <ModalSecondaryButton
                            style={{ width: "6.5rem" }}
                            onCancel={() => {
                                releaseModalHandler(false);
                            }} >Cancel</ModalSecondaryButton>
                        <ModalPrimaryButton
                            style={{ width: "6.5rem" }}
                            onSave={() => {
                                setPage(2);
                            }}>Next</ModalPrimaryButton>

                    </Space>
                </div>
                :

                    <Form form={form} layout="vertical"
                        style={{ padding: "0 2rem" }}
                        onFinish={(event) => {
                            event = { ...event, date: event['date']?.format('YYYY-MM-DD') }
                            console.log('REELESE EVENT', event['reason'], deptId);
                            releaseMemberApi(employeeInfo?.uid, event['date'], event['reason'], releaseModalHandler, Api, deptId, () => {
                                setPage(1)
                            });
                            getReleasbleMembersApi(Action, IoMdContact, releasableMembersHandler, deptId, loaderHandler, Api,Tooltip);
                            handleReset();

                        }}>
                        <Form.Item name="date" label={<span>Select Date to Release</span>} rules={[{ required: true, message: "Kindly select a date!" }]}>
                            <DatePicker style={{ width: "100%" }} size="large" />
                        </Form.Item>

                        <Form.Item name="reason" label={<span>Reason</span>}>
                            <Input.TextArea rows={6} />
                        </Form.Item>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "1.5rem", justifyContent: "flex-start", margin: "3rem auto" }}>
                            <ModalSecondaryButton
                                onCancel={() => {
                                    releaseModalHandler(false);
                                    setPage(1);
                                    handleReset()
                                }}
                            >Cancel</ModalSecondaryButton>
                            <ModalPrimaryButton htmlType="submit">Save</ModalPrimaryButton>
                        </div>
                    </Form>


                }

            </Modal> */}
            </div>
        </>
    )
}
export default Release;