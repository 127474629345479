export default {
    iBelongUrl: 'https://ibelong.brigosha.com',
    controlPanelUrl: "http://controlpanel.brigosha.com/brigosha-login",
    appraisalUrl:"https://testappraisal.brigosha.com/admin/team",
    ceoFeedbackUrl:"https://feedback.brigosha.com",
} 


//control panel test server:"https://testassociate.brigosha.com/brigosha-blog"
//control panel uat server:"https://associate.brigosha.com/brigosha-blog"
//test urls