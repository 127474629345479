import React from 'react';
import ManageTasks from './ManageTasks';
import Projects from './Projects';
import { useFlagButtonStore, useManageTasks } from '../Release/store';
import { Tabs } from 'antd'; // Assuming you're using Ant Design Tabs
import { useState } from 'react';

const TabsMenu = () => {
  // Call useManageTasks inside the component
  const { manageTasks, setManageTasks } = useManageTasks(state => state);
  const {resetFilters, pageMeta, projectsCount, setProjectsCount,tasksCount,setTasksCount} = useFlagButtonStore(state=>state);
  const [count,setCount] = useState(projectsCount);

  const onChange = (key) => {
    console.log(key);
    if(key==='1'){
      resetFilters();
      setManageTasks(false);
      //setTasksCount(pageMeta?.totalItems);
    }
    else if(key === '2'){
      setManageTasks(true);
      //setTasksCount(pageMeta?.totalItems);
    }
  };

  const items = [
    {
      key: '1',
      label: (
        <>
          <div style={!manageTasks?{alignItems:'center',display:'flex',flexDirection:'row',gap:'2%'}:{color:'#DADADA',alignItems:'center', display:'flex',flexDirection:'row',gap:'2%'}}> <div>Projects</div><span style={manageTasks?{
            display: 'inline-block', 
            color: '#ffffff', 
            backgroundColor: '#DADADA', 
            borderRadius: '8px', 
            fontSize: '10px', 
            width: '32px', 
            height: '20px', 
            lineHeight: '20px', // Center the text vertically
            textAlign: 'center' // Center the text horizontally
          }:{ 
                          display: 'inline-block', 
                          color: '#FFFFFF', 
                          backgroundColor: '#1089FF', 
                          borderRadius: '8px', 
                          fontSize: '10px', 
                          width: '32px', 
                          height: '20px', 
                          lineHeight: '20px', // Center the text vertically
                          textAlign: 'center' // Center the text horizontally
                          
                }}>
            {projectsCount}</span></div>
        </>
      ),
      children: <Projects />,
    },
    {
      key: '2',
      label: (
        <>
           <div style={manageTasks?{alignItems:'center',display:'flex',flexDirection:'row',gap:'2%'}:{color:'#DADADA',alignItems:'center', display:'flex',flexDirection:'row',gap:'2%'}}><div>Tasks</div>  <span style={manageTasks?{ 
                          display: 'inline-block', 
                          color: '#FFFFFF', 
                          backgroundColor: '#1089FF', 
                          borderRadius: '8px', 
                          fontSize: '10px', 
                          width: '32px', 
                          height: '20px', 
                          lineHeight: '20px', // Center the text vertically
                          textAlign: 'center' // Center the text horizontally
                          
                }:{
                  display: 'inline-block', 
                  color: '#ffffff', 
                  backgroundColor: '#DADADA', 
                  borderRadius: '8px', 
                  fontSize: '10px', 
                  width: '32px', 
                  height: '20px', 
                  lineHeight: '20px', // Center the text vertically
                  textAlign: 'center' // Center the text horizontally
                }}>
            {pageMeta?.totalItems?pageMeta?.totalItems:pageMeta?.totalItems===0?0:tasksCount}</span></div>
        </>
      ),
      children: <ManageTasks />,
    },
  ];

  return (
    
    <Tabs
      defaultActiveKey={manageTasks ? "2" : "1"}
      items={items}
      onChange={onChange}
      style={{marginLeft:'24px'}}
      
    />
    
  );
};

export default TabsMenu;
