import Api from "../../services/Api"
export const getFPDepartment=(callbak,date)=>{
    Api.get(`/resource-ceoFeedbackPortal/departmentData?date=${date}`).send(res=>{
        callbak(res);
    })
}
export const getDashGraphInfo=(callback,dept,month)=>{
    Api.get(`/resource-ceoFeedbackPortal/graph?departmentId=${dept}&date=${month}`).send(res=>{
        callback(res);
    })
}
export const getEachRateInfo=(callback,dept,date,questionId,rate)=>{
    Api.get(`/resource-ceoFeedbackPortal/graphAssociate?departmentId=${dept}&date=${date}&questionId=${questionId}&rate=${rate}`).send(res=>{
            callback(res);
    })
}
export const getDeptExtraInfo=(setExtraInfo,deptId,headId)=>{
    Api.get(`/project-department/departmentDetail?departmentId=${deptId}&headId=${headId}`).send(res=>{
       
            setExtraInfo(res);
        
    })
}
export const getRecommendation=(callback,page,limit,selectedDept,date)=>{
    Api.get(`/resource-ceoFeedbackPortal/recommendation?page=${page}&limit=${limit}&departmentId=${selectedDept}&date=${date}`).send(res=>{
       callback(res);
    })
}
export const UnnecessaryBackendSupport=()=>{
    Api.get('/resource-ceoFeedbackPortal/teamAndManager').send(res=>{
    })
}
export const getAssociateList=(callback,page,limit,dept,search,date)=>{
    Api.get(`/resource-ceoFeedbackPortal/fullAssociateDetail?page=${page}&limit=${limit}&departmentId=${dept}&search=${search}&date=${date}`).send(res=>{
        callback(res);
    })
}

export const filterQuestions=(callback,uid,date,remark)=>{
    Api.get(`/resource-ceoFeedbackPortal/forSingleuser?uid=${uid}&date=${date}&remark=${remark}`).send(res=>{
      callback(res);
    })
    
}