import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  managerTeam: [],
  associateList: [],
  appraisal: {
    questions: [],
    rating: [],
  },
  selectedTeamId: null,
  selectedAssociateId: null,
};

const managerSlice = createSlice({
  name: "manager",
  initialState,
  reducers: {
    updateManagerTeam(state = initialState, action) {
      state.managerTeam = [...action.payload.managerTeam];
    },
    updateAssociateRank(state = initialState, action) {
      state.associateRank = { ...action.payload.associateRank };
    },
    updateAssociateList(state = initialState, action) {
      state.associateList = [...action.payload.associates];
    },
    updateSingleAssociateRank(state = initialState, action) {
      const newAssociates = state.associateList.map((associate) => {
        if (associate.user_id === action.payload.associate.user_id) {
          associate.workSummary_teamRank = action.payload.associate.workSummary_teamRank;
        }
        return {...associate};
      });
  
      state.associateList = [...newAssociates];
    },
    updateSingleAssociatePerformanceAttitude(state = initialState, action) {
      const newAssociates = state.associateList.map((associate) => {
        if (associate.user_id === action.payload.associate.user_id) {
          associate.workSummary_attitude =
            action.payload.associate.workSummary_attitude;
          associate.workSummary_performance =
            action.payload.associate.workSummary_performance;
        }
        return associate;
      });
      state.associateList = [...newAssociates];
    },
    updateAppraisal(state = initialState, action) {
      state.appraisal = { ...action.payload.appraisal };
    },
    updateSelectedTeamId(state = initialState, action) {
      state.selectedTeamId = action.payload.selectedTeamId;
    },
    updateSelectedAssociateId(state = initialState, action) {
      state.selectedAssociateId = action.payload.selectedAssociateId;
    },
  },
});

export default managerSlice.reducer;

export const managerActions = managerSlice.actions;
