import { create } from "zustand";

export const useNotificationStore=create(set=>({
   

    allCityStore:[],
    setAllCityStore:(val)=>set(state=>({allCityStore:val})),
    allDeptandAssociateStore:[],
    setAllDeptandAssociate:(val)=>set(state=>({allDeptandAssociateStore:val})),
    allDepartment:[],
    setAllDepartment:(val)=>set(state=>({allDepartment:val})),
    totalItems:null,
    setTotalItems:(val)=>set(state=>({totalItems:val})),
    pageSizeHoliday:10,
    setPageSizeHoliday:(val)=>set(state=>({pageSizeHoliday:val})),
    allAssociate:[],
    setAllAssociate:(val)=>set(state=>({allAssociate:val})),
    addCityName:[],
    SetaddCityName:(val)=>set(state=>({addCityName:val})),
    holidayListBy:"Location",
    setHolidayListBy:(val)=>set(state=>({holidayListBy:val})),
    deleteStore:'',
    SetDeleteStore:(val)=>set(state=>({deleteStore:val})),
    recordsStore:null,
    setrecordStore:(val)=>set(state=>({recordsStore:val})),
    geoLoactionStore:null,
    setgeoLoactionStore:(val)=>set(state=>({geoLoactionStore:val})),
    leaveStore:null,
    setleaveStore:(val)=>set(state=>({leaveStore:val})),
    locationHolidayStore:null,
    setlocationHolidayStore:(val)=>set(state=>({locationHolidayStore:val})),
    refresh:false,
    setRefresh:(val)=>set(state=>({refresh:val})),
    fixedStore:'',
    setFixedStore:(val)=>set(state=>({fixedStore:val})),
    typeHolidayStore:true,
    settypeHolidayStore:(val)=>set(state=>({typeHolidayStore:val})),
    load:false,
    setLoad:(val)=>set(state=>({load:val})),
    typeLoactionStore:[],
    settypeLoactionStore:(val)=>set(state=>({typeLoactionStore:val})),

    deleteCityStore:false,
    setdeleteCity:(val)=>set(state=>({deleteCityStore:val})),

    fromHoliday:false,
    setfromHoliday:(val)=>set(state=>({fromHoliday:val})),

    notFixed:false,
    setNotfixed:(val)=>set(state=>({notFixed:val})),

    addCity : false,
    setAddCity : (val)=>set(state=>({addCity:val})),

    searchVal:'',
    setSearchVal:(val)=>set(state=>({searchVal:val})),

    currentPage : 1,
    setCurrentPage:(val)=>set(state=>({currentPage:val})),

    currentPageHoliday : 1,
    setCurrentPageHoliday:(val)=>set(state=>({currentPageHoliday:val})),

    LeaveTypepageSize : 10,
    setLeaveTypePageSize:(val)=>set(state=>({LeaveTypepageSize:val}))
}))