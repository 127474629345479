import React, { useEffect, useState } from "react";
import './Layout.css';
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { uselocalStorageStore } from "./localStorage";
import { useHeaderStore } from "../Header/store";
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";
import MobileNavModal from "../../components/MobileNavModal/MobileNavModal";
import { SECRET_KEY, decryptData } from "../../services/fileCode";
import poweredBy_icon from '../../assets/poweredBy_icon.svg';

const Layout=(props)=>{
    const [isClicked,setIsClicked]=useState(false);
    const {isVisible, setisVisible}=uselocalStorageStore();
    const {width}=useWindowDimensions();
    const {sidemenu,setSidemenu}=useHeaderStore();
    const location=useLocation();
    console.log("Layout Props",props);
    let u=localStorage.getItem('user');
    let user;
    if(u){
        user=decryptData(u,SECRET_KEY);
    }
    let path=window?.location?.href?.split('/');
    useEffect(()=>{
        if(path?.includes('dashboard'))
        {
            setisVisible(false);
        }
    },[])
    return(
        <>
          {width<800&&<MobileNavModal sidemenu={sidemenu} setSidemenu={setSidemenu}>
          <div className="sider" >
                {props?.navlist?.slice(0,2)?.map((item,index)=>{
                    const clss=(isactive,index)=>(["nav-common",(isVisible&&index===1)||isactive?"module-active":"module-inactive"].join(" "));
                    if(user?.resourceUser?.designation!=="CEO"&&index===1)
                    {
                        return;
                    }
                        return(
                            <div onClick={()=>{setSidemenu(false)}}>
                                <NavLink
                                    key={index}
                                    to={item?.url}
                                    style={{textDecoration:"none"}}
                                    className={({isActive})=>clss(isActive,index)}
                                    onClick={()=>{
                                        if(index!==0)
                                        {
                                            setIsClicked(true);
                                            setisVisible(true);
                                        }
                                        else
                                        {
                                            setisVisible(false);
                                        }
                                        if(item.url===location.pathname.split('/')[location.pathname.split('/')?.length-1])
                                        {
                                          window.location.reload();
                                        }
                                        if(item.url==="/ceoDesk/dashboard")
                                        {
                                            if(item.url===location.pathname)
                                            {
                                              window.location.reload();
                                            }
                                        }
                                    }}
                                    children={({isActive})=>{
                                        return(
                                            <div>
                                                    <div >
                                                        <img style={{width:"2.5rem",height:"2.5rem"}}  src={isActive?item?.active:item?.inactive}/>
                                                        <span>{item?.title}</span>
                                                    </div>                                                                                           
                                            </div>
                                        )
                                    }}
                                    />
                                                                     
                            </div>
                        )                                              
                })}
                <div className={isVisible?"visible":isClicked?"hide":"show-not"}>
                {props?.navlist?.slice(2,6)?.map((item,index)=>{
                        const clss=(isactive)=>(["nav-common",isactive?"sub-module-active":"sub-module-inactive",].join( " "))                
                            return(
                                <div onClick={()=>{setSidemenu(false)}}>
                                    <NavLink
                                        key={index}
                                        to={item?.url}
                                        style={{textDecoration:"none"}}
                                        className={({isActive})=>clss(isActive)}
                                        onClick={()=>{
                                            if(item.url===location.pathname.split('/')[location.pathname.split('/')?.length-1])
                                            {
                                              window.location.reload();
                                            }
                                        }}
                                        children={({isActive})=>{
                                            return(
                                                <div>
                                                        <div >
                                                            <img style={{width:"2.5rem",height:"2.5rem"}} src={isActive?item?.active:item?.inactive}/>
                                                            <span>{item?.title}</span>
                                                        </div>                                                                                         
                                                </div>
                                            )
                                        }}
                                        />                                   
                                </div>
                            )                                                               
                        })}
                </div>
                <div style={{justifyContent:"center",alignItems:"center",display:"flex",marginBottom:"2rem",marginTop:"2rem"}}>
                    <img src={poweredBy_icon}></img>
                </div>
            </div>
            </MobileNavModal>}
        <div className="layout">
            {width>800&&
            <div className="sider">
                {props?.navlist?.slice(0,2)?.map((item,index)=>{
                    const clss=(isactive,index)=>(["nav-common",(isVisible&&index===1)||isactive?"module-active":"module-inactive"].join(" "));
                    if(user?.resourceUser?.designation!=="CEO"&&index===1)
                    {
                        return;
                    }
                        return(
                            <div>
                                <NavLink
                                    key={index}
                                    to={item?.url}
                                    
                                    style={{textDecoration:"none"}}
                                    className={({isActive})=>clss(isActive,index)}
                                    onClick={()=>{
                                        if(index!==0)
                                        {
                                            setIsClicked(true);
                                            setisVisible(true);
                                        }
                                        else
                                        {
                                            setisVisible(false);
                                        }
                                       
                                        if(item.url===location.pathname.split('/')[location.pathname.split('/')?.length-1])
                                        {
                                          window.location.reload();
                                        }
                                        if(item.url==="/ceoDesk/dashboard")
                                        {
                                            if(item.url===location.pathname)
                                            {
                                              window.location.reload();
                                            }
                                        }
                                        
                                    }}
                                    children={({isActive})=>{
                                        return(
                                            <div>
                                                    <div >
                                                        <img style={{width:"2.5rem",height:"2.5rem"}} src={((isVisible&&index===1)||isActive)?item?.active:item?.inactive}/>
                                                        <span>{item?.title}</span>
                                                    </div>                                                                                           
                                            </div>
                                        )
                                    }}
                                    >
                                        </NavLink>                                
                            </div>
                        )                                              
                })}
                <div className={isVisible?"visible":isClicked?"hide":"show-not"}>
                {props?.navlist?.slice(2,6)?.map((item,index)=>{
                        const clss=(isactive)=>(["nav-common",isactive?"sub-module-active":"sub-module-inactive",].join( " "))                
                            return(
                                <div>
                                    <NavLink
                                        key={index}
                                        to={item?.url}
                                        onClick={()=>{
                                            if(item.url===location.pathname.split('/')[location.pathname.split('/')?.length-1])
                                            {
                                              window.location.reload();
                                            }
                                        }}
                                        style={{textDecoration:"none"}}
                                        className={({isActive})=>clss(isActive)}
                                        children={({isActive})=>{
                                            return(
                                                <div>
                                                        <div >
                                                            <img style={{width:"2.5rem",height:"2.5rem"}} src={isActive?item?.active:item?.inactive}/>
                                                            <span>{item?.title}</span>
                                                        </div>                                                                                         
                                                </div>
                                            )
                                        }}
                                        />                                   
                                </div>
                            )                                                               
                        })}
                </div>

                <div style={{justifyContent:"center",alignItems:"center",display:"flex",marginBottom:"2rem",marginTop:"auto"}}>
                    <img src={poweredBy_icon}></img>
                </div>
            </div>}
            <div className="main-content">
            <Outlet/>
            </div>
        </div>
        </>
    )
}
export default Layout;