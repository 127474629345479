import { Button, Modal } from "antd";
import React from "react";
import   updated_icon from '../../assets/updated_icon.svg';
import { useLoginStore } from "./store";
import { useNavigate } from "react-router-dom";
import classes from './login.module.css';
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";

const UpdatePassSuccessModal=({successModal,setSuccessModal})=>{
    const {curPage, setCurPage,setIsSignIn,setActiveTab}=useLoginStore();
    const {width}=useWindowDimensions();
    const navigate=useNavigate();
    return(
      <Modal open={successModal} onCancel={()=>{setSuccessModal(false);}} centered width={width>800?"28%":"100%"}
      closable={false}
    footer={false}>
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",width:"100%",gap:"0.4vh",padding:"1vh 3vw"}}>
                <img src={updated_icon} alt="" style={{width:width>800?"25vmin":"40vmin",height:width>800?"25vmin":"40vmin"}}/>
                <h2 style={{display:"flex",justifyContent:"center",marginBottom:"1vh",fontSize:width>800?"2vmin":"4vmin"}}>Password updated!</h2>
                <span style={{fontSize:width>800?"1.5vmin":"2.8vmin",fontWeight:500,alignItems:"center",display:"flex",justifyContent:"center",color:"#191919",textAlign:"center"}}>Your password has been reset successfully</span>
                
                <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginTop:"8vh",width:"100%"}}>
                  
                  
                    
                    {/* <Button type="primary" style={{width:"8vw",height:"6vh",fontSize:"1vw",}}
                    onClick={()=>{setSuccessModal(false)}}>
                        Okay
                    </Button> */}
                     <button   
                     className={classes.signIn}               
                 onClick={()=>{navigate("/login");
                 setCurPage(5);
                 setIsSignIn(true);
                 setActiveTab(5);
                 }}>
                   Sign in
               </button>
                </div>
            </div>
         </Modal>
    )
}
export default UpdatePassSuccessModal;