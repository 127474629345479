import React from 'react';
import { useNavigate } from "react-router";
const NotFound = ({text}) => {
  const Navigate=useNavigate()
  console.log("windowww",window.location);
  return (
    <div style={{display:"flex", justifyContent:"center",alignItems:"center", width:"100%", height:"60vh",flexDirection:"column" }}>
      <h1>404 - Not Found</h1>
      <p>{text?text:"Sorry, the page you are looking for does not exist."}</p>
      {window.location?.pathname==="/room"
      &&
<div  style={{color:'#0086ff',padding:'40px',cursor:"pointer",fontSize:30}} onClick={()=>{Navigate(-1)}}>Go to home</div>}
    </div>
  );
};

export default NotFound;