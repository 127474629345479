import React from "react";
import "./Modal.css";
import Backdrop from './Backdrop/Backdrop';
import closeIcon from '../../assets/closeIcon.svg';

const Modal = ({style,right,show,closableLeft,closableRight,header,children,onCancel}) => {

  return (
    <div >
 <Backdrop show={show} right={right}>
    <div className="Modal" style={style}>
    <div className="header" >
      <div>
          {closableLeft&&<img src={closeIcon} onClick={()=>onCancel()}></img>}
      </div>
      {header}
      <div>
          {closableRight&&<img src={closeIcon} onClick={()=>onCancel()}></img>}
      </div>
      </div>
    <div style={{padding:"1rem",overflow:'scroll'}}>
            {children}
      </div>
    </div>
    </Backdrop>
    </div>
  );
};

export default Modal;
