import React, { useEffect, useState } from "react";
import classes from "./Project.module.css";
import { GridListView } from "../../Homepage/components/gridListView";
import ButtonCompo from "../../../design/ButonCompo";
import { Select, Pagination } from "antd";
import SearchCompo from "../../../design/SearchCompo";
import ProjectCards from "./ProjectCards";
import ProjectTable, { actiondis, ionsdis, progressdis } from "./ProjectTable";
import ProjectDetails from "./ProjectDetails";
import "../../../App.css";
import AddProject from "./AddProject";
import DeleteProjectModal from "./DeleteModal";
import downloadIcon from "../../../assets/downloadBlue.svg";
import filterIcon from "../../../assets/filter.svg";
import unFilterIcon from "../../../assets/unfilter.png";
import { getAllProjectApi, getFilterApi, getAllUserTaskApi } from "./ProjectAction";
import FilterModal, { resetData } from "./FilterModal";
import useProjectStore from "./store";
import "./comp.css";
import { SECRET_KEY, decryptData } from "../../../services/fileCode";
import useApiStore from "../../../services/store";
import viewClass from "../../../services/ViewRole";
import AddProjectIcon from "../../../assets/createPlusIcon.svg"
import { useProjecteStore } from "../store/store";
import { updateHeader } from "../../Header/main-header";
import moment from "moment";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import Filter from "../../../assets/filter.svg";
import { useFlagButtonStore, useManageTasks } from "../Release/store";

const onResetFilter = (setMetadata, setAllProject, updatedData, setFilterOption, filterOption) => {
    console.log("its here");
    // 
    getAllProjectApi(setMetadata, {}, (res) => {
        console.log(res);
        setAllProject(res);
        updatedData(res)
        //setProjectsCount(metaData?.totalItems);
        getFilterApi(false, {}, (res) => {
            setFilterOption({ ...filterOption, departments: res?.departments });
        })
    })
}

export let onBackPress;
const Projects = () => {
    const { setLoader } = useApiStore(state => state)
    const { projectClickedData, setProjectClickedData } = useProjecteStore(state => state)
    const {setProjectsCount, projectsCount, setTasksCount} = useFlagButtonStore(state=>state);
    const viewRole = localStorage?.getItem('user');
    let role;
    if (viewRole) {
        role = decryptData(viewRole, SECRET_KEY);
    }
    const [appliedFilter, setFilterOption, filterOption] = useProjectStore((state) => [state.appliedFilter, state.setFilterOption, state.filterOption])
    const [search, setSearch] = useState("");
    const [tableData, setdata] = useState([])
    const [filterModal, setFilterModal] = useState(false)
    const statusList = ['All', "Active", 'New', 'Ongoing', "Over Due", "Completed", "On Hold", "Suspended", "Draft", "Rejected"]
    const [view, setView] = useState("grid");
    const [status, setStatus] = useState("Active");
    const [allProject, setAllProject] = useState([
    ]);
    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(1);
    const [paramData, setParamsData] = useState({});
    const [projectModal, setProjectModal] = useState();
    const [deleteModal, setDeleteModal] = useState();
    const [metaData, setMetadata] = useState();
    const { width } = useWindowDimensions();
    const [filterParams, setparams] = useState(undefined)
    const [ondownload, setdownload] = useState(false);


    const {manageTasks} = useManageTasks(state=>state);

    const updatedData = (res) => {
        let arrr = [];
        let temp = res;
        for (let index = 0; index < temp.length; index++) {
            console.log("helloooo");
            const element = temp[index];
            arrr.push({
                ...element,
                "Due Ond": (
                    <div>
                        {moment(element?.dueDate).format("DD-MMM-YYYY")}
                    </div>
                ),
                "statusd": (<>{ionsdis(element)}</>),
                "progressd": (<>{progressdis(element)}</>),
                "actiond": (<>{actiondis(element, index)}</>)
            })

        }
        /*    temp?.forEach((element,index) => {
               console.log(element,"shaata");
                   
               }); */
        console.log(arrr, "09876543234567890");
        setdata(arrr)



    }
    useEffect(()=>{
        setProjectsCount(metaData?.totalItems);
       
    },[metaData])


    const refreshProject = () => {
        let param = { search: search, ...paramData, ...filterParams, status: status !== "All" ? status : undefined  }
        getAllProjectApi(setMetadata, param, (res) => {
            setAllProject(res);
            updatedData(res);
            setProjectsCount(metaData?.totalItems);
            console.log(projectsCount,"PROJECTSCOUNT");
        })
       
    }
    onBackPress = () => {
        setSearch('');
        getAllProjectApi(setMetadata, { ...filterParams,...paramData, status: status !== "All" ? status : undefined }, (res) => {
            setAllProject(res);
            updatedData(res);
            setProjectsCount(metaData?.totalItems);
            console.log(projectsCount,"PROJECTSCOUNT");
        })
        
    }
    useEffect(() => {
        setParamsData({
            page: pageCurrent,
            limit: pageSize
        })
    }, [pageCurrent, pageSize])

    // console.log(paramData, "paramDataparamDataparamDataparamData",filterParams);
    const DownloadHandler = () => {
        setdownload(true)
        let param = { search: search, download: true, ...paramData, ...appliedFilter ? appliedFilter : null, status: status }
        if (appliedFilter) {
            param = { ...param, ...appliedFilter }
        }
        if (status !== undefined && status !== "All") {
            param = { ...param, status: status }
        }
        getAllProjectApi(setMetadata, param, (res) => {
            console.log('Download', res)
            window.open(res, "_blank")?.focus();
            setProjectsCount(metaData?.totalItems);
            console.log(projectsCount,"PROJECTSCOUNT");
        })

    }
    const onApplyfilter = (param) => {
        setparams(param)
        getAllProjectApi(setMetadata, { search: search,...param, status: status !== "All" ? status : undefined }, (res) => {
            setAllProject(res);
            updatedData(res)
            setProjectsCount(metaData?.totalItems);
            console.log(projectsCount,"PROJECTSCOUNT");
            getFilterApi(false, { ...param, status: status !== "All" ? status : undefined }, (res) => {
                setFilterOption({ ...filterOption, departments: res?.departments });
            })
        })
    }



    useEffect(() => {
        viewClass.updateView();
        let param = { search: search, ...paramData, ...filterParams, ...appliedFilter ? appliedFilter : null }

        if (status !== undefined && status !== "All") {
            param = { ...param, status: status }
        }
        if(!manageTasks){
            getFilterApi(false, param, (res) => {
                setFilterOption({ ...filterOption, departments: res?.departments });
            })
           
        }
        if(!manageTasks){
            getAllProjectApi(setMetadata, param, (res) => {
                console.log("inside action3");
                setAllProject(res);
                updatedData(res);
                setProjectsCount(metaData?.totalItems);
                console.log(projectsCount,"PROJECTSCOUNT");
            })
            
        }

    }, [search, status, paramData, manageTasks])

    useEffect(()=>{
        let params = {}
        getAllUserTaskApi(params,res=>{
            //console.log(res,"TASKSCOUNT");
            setTasksCount(res?.meta?.totalItems)
        })
    },[])


    useEffect(() => {
        return (() => {
            if (projectClickedData?.navigationLocation !== 'resourceDash') {
                setProjectClickedData(undefined);
                onResetFilter(setdownload, setMetadata, setAllProject, updatedData, setFilterOption, filterOption);
                resetData();

            }

            // onResetFilter();
            // resetData();

        })
    }, [])

    const handlePageApproveChange = (page, pageSize) => {
        setPageCurrent(page);
        setPageSize(pageSize)
    }

    return (
        <div style={{ height: "86vh", overflowY: "auto"}}>
            <div >
                {projectClickedData === undefined ?
                    <>
                        {
                            width < 700 ?
                                <>
                                    
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: "100%" }}>
                                        <SearchCompo style={{ width: "14rem",height:"2.5rem" }} setSearch={(val) => { setPageCurrent(1); setSearch(val) }}></SearchCompo>
                                        {(viewClass.superAdmin || viewClass.hod || viewClass.manager) ? <ButtonCompo title={"Add Project"} style={{ backgroundColor: '#0086FF', color: "white", borderRadius: 5, height:40, marginLeft:10, fontSize:7 }} prefixIcon={AddProjectIcon} onClick={() => { setProjectModal({ type: "add", data: '' }) }}></ButtonCompo> : ''}
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: "100%", marginTop:15, marginBottom:10 }}>
                                        <Select allowClear style={{ width: "110px" }} size="large"  value={status} onChange={(val) => {console.log(val,val==undefined,"bcvallll"); setPageCurrent(1); setStatus(val===undefined?"Active":val) }}>
                                            {statusList?.map((val) => {
                                                return (
                                                    <Select.Option key={val}>{val}</Select.Option>
                                                )
                                            })}
                                        </Select>
                                        <div style={{display:"flex"}}>
                                        {(viewClass.superAdmin || viewClass.hod || viewClass.manager) ?
                                                <div
                                                    style={{ backgroundColor: "#FFFFFF", borderRadius: 5, color: "#162432", marginRight: 10, display: "flex", justifyContent: "center", alignItems: "center" }} onClick={() => setFilterModal(true)}
                                                >  <img src={Filter} style={{ height: 30, width:40}} />
                                                </div>
                                            : ''}
                                            {(viewClass.superAdmin || viewClass.hod || viewClass.manager) ? <span style={{ color: '#162432', fontWeight: "600", fontSize: "14px", display: "flex", gap: "0.3rem", alignItems: "center", flexDirection: "row", margin: "0 0 0 1rem", cursor: "pointer",color:"#0086FF" }} onClick={DownloadHandler}> Download <img src={downloadIcon} style={{ width: "1rem", height: "1rem" }} /></span> : ''}
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                        
                                        <div style={{ display: "flex", justifyContent: 'right',}}>
                                            <SearchCompo style={{ width: "16rem",height:"2.3rem",marginRight:"0.5rem" }} setSearch={(val) => { setPageCurrent(1); setSearch(val) }}></SearchCompo>
                                            {(viewClass.superAdmin || viewClass.hod || viewClass.manager) && !(allProject?.[0]?.isCoManager) ? <ButtonCompo title={"Add Project"} style={{ backgroundColor: '#0086FF', color: "white", borderRadius: 5 }} prefixIcon={AddProjectIcon} onClick={() => { setProjectModal({ type: "add", data: '' }) }}></ButtonCompo> : ''}
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: "1.5rem 0", alignItems: 'center', }}>

                                        <div style={{ display: 'flex', alignItems: 'center', gap: '20px', }}>
                                            <GridListView defaultview={view} onChange={(res) => { setView(res); setLoader(true); setTimeout(() => { setLoader(false) }, 500) }}></GridListView>

                                            <Select allowClear style={{ width: "110px" }} size="large"  value={status} onChange={(val) => {console.log(val,val==undefined,"bcvallll"); setPageCurrent(1); setStatus(val==undefined?"Active":val) }}>
                                                {statusList?.map((val) => {
                                                    return (
                                                        <Select.Option key={val}>{val}</Select.Option>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px', }}>
                                            {(viewClass.superAdmin || viewClass.hod || viewClass.manager) ? <ButtonCompo title={"Filter By"} onClick={() => { setFilterModal(true) }} style={{ color: '#0086FF', border: '1px solid black', borderRadius: 3, color: "black" }}></ButtonCompo> : ''}
                                            {(viewClass.superAdmin || viewClass.hod || viewClass.manager) ? <span style={{ color: '#162432', fontWeight: "600", fontSize: "14px", display: "flex", gap: "0.3rem", alignItems: "center", flexDirection: "row", margin: "0 0 0 1rem", cursor: "pointer" ,color:"#0086FF"}} onClick={DownloadHandler}> Download <img src={downloadIcon} style={{ width: "1rem", height: "1rem" }} /></span> : ''}


                                        </div>
                                    </div>
                                </>
                        }
                        {
                            width < 700 ?
                                <>
                                    <div >
                                        <ProjectCards data={allProject} onEdit={(res) => { console.log("edit clicked", res); setProjectModal({ type: "edit", data: res }) }} onDelete={(res) => { console.log("delete clicked", res); setDeleteModal({ type: "project", name: res?.projectName, id: res?.projectId }); }} onClick={(res) => { console.log(res); setProjectClickedData(res) }} />
                                    </div>
                                </>
                                :
                                <>
                                    <div className={classes.projectContainer}  style={{height:window.innerHeight-390}}>
                                        {view === "grid" ? <ProjectCards data={allProject} onEdit={(res) => { console.log("edit clicked", res); setProjectModal({ type: "edit", data: res }) }} onDelete={(res) => { console.log("delete clicked", res); setDeleteModal({ type: "project", name: res?.projectName, id: res?.projectId }); }} onClick={(res) => { console.log(res); setProjectClickedData(res) }} /> : view === "list" ?
                                            <ProjectTable tableData={tableData} data={allProject} onEdit={(res) => { console.log(res); setProjectModal({ type: "edit", data: res }) }} onDelete={(res) => { console.log("delete clicked", res); setDeleteModal({ type: "project", name: res?.projectName, id: res?.projectId }); }} onClick={(res) => { console.log(res); setProjectClickedData(res) }} /> : ""}

                                    </div>
                                </>
                        }

                        <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", marginTop: "auto", paddingTop: 15 }}>
                            <Pagination
                                size={width < 700 ? "small" : "large"}
                                current={pageCurrent}
                                pageSize={pageSize}
                                total={metaData?.totalItems ? metaData?.totalItems : 0}
                                pageSizeOptions={[10, 30, 50, 100]}
                                showSizeChanger={true}
                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                onChange={handlePageApproveChange}
                                onShowSizeChange={handlePageApproveChange}
                            />
                        </div>
                    </> : <ProjectDetails role={role} data={projectClickedData} back={setProjectClickedData} />}
            </div>
            {<FilterModal onApplyfilter={onApplyfilter} onResetFilter={() => { onResetFilter(setdownload, setMetadata, setAllProject, updatedData, setFilterOption, filterOption) }} modal={filterModal} setModal={setFilterModal} />}
            {projectModal !== undefined ? <AddProject modal={projectModal} setModal={setProjectModal} refresh={refreshProject.bind(this)} ></AddProject> : ''}
            {deleteModal !== undefined ? <DeleteProjectModal modal={deleteModal} setModal={setDeleteModal} refresh={refreshProject.bind(this)} /> : ''}
        </div>
    )
}
export default Projects;