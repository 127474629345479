import { create } from "zustand";

const useStore =create((set)=>({
    openModal:false,
    summaryModalOpen:false,
    leaveModalOpen:true,
    leavesData:[],
    tableData:[],
    summaryData:{},
    editLeaveData:{},
    selectedUserData:{},
    render:1,
    currentPage:1,
    pageSize:10,
    setPageSize:(val)=>{set(state=>({pageSize:val}))},
    setCurrentPage:(val)=>{set(state=>({currentPage:val}))},
    setRender:(val)=>{set(state=>({render:val}))},
    setSelectedData:(val)=>{set(state=>({selectedUserData:val}))},
    setSummaryData:(val)=>{set(state=>({summaryData:val}))},
    setEditLeaveData:(val)=>{set(state=>({editLeaveData:val}))},
    setLeaveData:(event,pageSize,currentPage)=>{
        set(state=>({tableData:[]}))
        set(state=>({leavesData:event}))
        event?.records?.forEach((element,index) => {

        let arr= [
            {
              sNo: index+1,
              edit: "",
              name: `${element.userName} ${element?.userUid}`,
              userEmployeeId:element?.userUid,
              userName:`${element.userName}`,
              pl1: element?.yearOpeningBalance?.Privilege,
              cl1: element?.yearOpeningBalance?.Casual,
              sl1: element?.yearOpeningBalance?.Sick,
              oh1: element?.yearOpeningBalance?.Optional,
              cmof1:element?.yearOpeningBalance?.CompOff,
              pl2: element?.openingBalance?.Privilege,
              cl2: element?.openingBalance?.Casual,
              sl2: element?.openingBalance?.Sick,
              oh2: element?.openingBalance?.Optional,
              pl4: element?.closingBalance?.Privilege,
              cl4: element?.closingBalance?.Casual,
              sl4: element?.closingBalance?.Sick,
              oh4: element?.closingBalance?.Optional,
              pl3: element?.monthLeave?.Privilege,
              cl3: element?.monthLeave?.Casual,
              sl3: element?.monthLeave?.Sick,
              oh3: element?.monthLeave?.Optional,
              pl5: element?.modifiedBalance?.Privilege,
              cl5: element?.modifiedBalance?.Casual,
              sl5: element?.modifiedBalance?.Sick,
              oh5: element?.modifiedBalance?.Optional,
              pEdit: element?.privilege,
              cEdit: element?.casual,
              sEdit: element?.sick,
              wEdit: element?.wedding,
              parentEdit: element?.parental,
              optional: element?.optional,
              wl: element?.wedding,
              pl:element?.parental,
              note: element?.note,
              uId:element?.userViewId,
              userId:element?.userId,
              compOff:element?.compOff
            }]
           set(state=>({tableData:[...state.tableData,...arr]}))
           
    });
   
},
    LeaveModal:(event)=>set(state=>({leaveModalOpen:event})),
    EditLeaveModal:(event)=>set(state=>({openModal:event})),
    SummaryModal:(event)=>set(state=>({summaryModalOpen:event})),
}))
export default useStore;
