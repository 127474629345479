import { Checkbox, Form, Input, Select } from "antd";
import React, { useState, useEffect } from "react";
import classes from "./department.module.css"
import { ModalPrimaryButton, ModalSecondaryButton } from "../../../components/Button/Button";
import { useDepartmentStore } from "./store";
import { addDepartmentApi, editDepartmentApi } from "./Actions";
import Api from "../../../services/Api";
import search_icon from '../../../assets/search_Icon.svg';
const AddDept = ({
  isEditDepartment,
  editableDept,
  setModal,
  refresh,
  managementDept,
}) => {
  const { hodDetails } = useDepartmentStore();
  const Option = Select.Option;
  const [form] = Form.useForm();

  const [isManagementDept, setIsManagementDept] = useState(false);
  const [compOff, setCompOff] = useState(isEditDepartment ? editableDept?.compOff : false);

  useEffect(() => {
    if (!isEditDepartment) {
      setIsManagementDept(managementDept)
    }
  }, [managementDept])

  return (
    <div style={{ flexDirection: "column", display: "flex", width: "100%", padding: "0 1rem", overflowY: "auto" }}>
      <Form layout="vertical" form={form} onFinish={(event) => {
        if (isEditDepartment) {
          if (typeof event["hodId"] === "string") {
            event["hodId"] = hodDetails?.filter(item => item.name === event["hodId"])?.[0]?.id;
          }
          console.log(isManagementDept, "isManagementDeptisManagementDeptisManagementDept");
          editDepartmentApi(Api, event["hodId"], event["deptId"], editableDept?.id, isManagementDept,event?.feedback,compOff);
        }
        else {
          addDepartmentApi(Api, event["hodId"], event["deptId"], managementDept ? !managementDept : isManagementDept,event?.feedback,compOff);
        }
        refresh();
        setModal(false);
      }}>
        <div >

          <Form.Item
            style={{ width: "100%", marginTop: 20 }}
            label={
              <div>
                <span style={{ fontWeight: "600", marginRight: "2rem", fontWeight: "500", color: "#878787" }}> Department Name</span>
                <Checkbox style={{ fontWeight: "600" }} disabled={isEditDepartment ? managementDept ? !(editableDept?.isManagement) : false : managementDept}
                  defaultChecked={editableDept?.isManagement}
                  onChange={(e) => {
                    setIsManagementDept(e.target.checked);
                    console.log("e.target.checked", e.target.checked)
                  }}
                >Management department</Checkbox>

              </div>}
            name="deptId"
            rules={[{ required: true, message: "Enter the department name" }]}
            initialValue={isEditDepartment ? editableDept?.name : undefined}
          >
            <Input
              //  defaultValue={isEditDepartment?editableDept?.name:undefined}
              style={{ border: "1.3px solid #ECECEC" }}
              placeholder="Enter the Department Name"
              size="large" />
          </Form.Item>
          <Form.Item
            style={{ width: "100%" }}
            label={<span style={{ fontWeight: "500", color: "#878787" }}>Department Head</span>}
            name="hodId"
            initialValue={isEditDepartment ? editableDept?.head : undefined}
            rules={[{ required: true, message: "Select the department head" }]}
            className={classes.select}
          >
            <Select
              placeholder="Select"
              showSearch={true}
              size="large"
              filterOption={(input, option) =>
                option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
              }
              dropdownStyle={{ maxHeight: "13rem", overflow: "scroll" }}
            >
              {hodDetails?.map((item, index) => (
                <Option key={index} value={item.id}>{item.name}</Option>
              )

              )}

            </Select>
          </Form.Item>
          <Form.Item
            style={{ width: "100%" }}
            label={<span style={{ fontWeight: "500", color: "#878787" }}>CEO Feedback, Select the Category</span>}
            name="feedback"
            initialValue={isEditDepartment ? editableDept?.departmentType : undefined}
            rules={[{ required: true, message: " Select the Category" }]}
            className={classes.select}
          >
            <Select
              placeholder="Select"
              showSearch={false}
              size="large"
            
              dropdownStyle={{ maxHeight: "13rem", overflow: "scroll" }}
            >
              {["Management", "Internal", "Client Projects", "Operations", "None"]?.map((item) => (
                <Option key={item} value={item}>{item}</Option>
              )

              )}

            </Select>
          </Form.Item>

          <Form.Item
            style={{ width: "100%" }}
            name="compOff"
            rules={[{ required: false }]}
            className={classes.select}
          >
            <Checkbox style={{ fontWeight: "600" }}
              defaultChecked={compOff}
              onChange={(e) => {
                setCompOff(e.target.checked)
              }}
            >Enable CompOff</Checkbox>

          </Form.Item>
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: 'flex-end', justifyContent: 'flex-start', gap: "1rem", height: "20vh" }}>
          <ModalPrimaryButton
            htmlType="submit">
            Save
          </ModalPrimaryButton>
          <ModalSecondaryButton style={{ backgroundColor: "white" }} onCancel={() => {
            setModal(false);
          }}>Cancel</ModalSecondaryButton>
        </div>
      </Form>
    </div>
  )
}
export default AddDept