import React, { useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { UpdateTasksApi, getAllUserTaskApi } from './ProjectAction';
import { useFlagButtonStore } from '../Release/store';

const DropdownButton = ({ initialStatus, associateId, overallStatus }) => {
  const [updateModal, setUpdateModal] = useState();
  const { setAllTask,tasksUserId ,setPageMeta, pageMeta, filtersList, tasksTeamId, allSearch} = useFlagButtonStore(state => state);

  const handleMenuClick = (e) => {
    let param = { taskIds: associateId.toString(), status: e.key };

    UpdateTasksApi(param, (res) => {
      if (res) {
        // let param = { 
        //   search: allSearch,
        //   page: pageMeta?.currentPage ,
        //   overallStatus: overallStatus, 
        //   userId:tasksUserId ,
        //   limit: pageMeta?.itemsPerPage,
        //   status: filtersList?filtersList?.status: null,
        //   teamId: filtersList? filtersList?.teamId : tasksTeamId? tasksTeamId.toString(): null,
        //   projectId: filtersList?filtersList?.projectId:null,
        //   associateId: filtersList?filtersList?.associateId:null
        // }
        let param = {
          search: allSearch, page: pageMeta?.currentPage,
          overallStatus: overallStatus,
          limit: pageMeta?.itemsPerPage,
          userId: tasksUserId?tasksUserId:null,
          status: filtersList?filtersList?.status: null,
          //teamId: tasksTeamId? tasksTeamId.toString():filtersList?filtersList?.teamId: null,
          teamId: (filtersList && filtersList?.teamId)? filtersList?.teamId : tasksTeamId? tasksTeamId.toString(): null,
          projectId: filtersList?filtersList?.projectId:null,
          associateId: (filtersList && filtersList?.associateId)?filtersList?.associateId:null,
      }

      //   let param = {
      //     search: search, page: pageCurrent,
      //     overallStatus: overallStatus,
      //     limit: pageSize,
      //     userId: tasksUserId?tasksUserId:null,
      //     status: filtersList?filtersList?.status: null,
      //     //teamId: tasksTeamId? tasksTeamId.toString():filtersList?filtersList?.teamId: null,
      //     teamId: filtersList? filtersList?.teamId : tasksTeamId? tasksTeamId.toString(): null,
      //     projectId: filtersList?filtersList?.projectId:null,
      // }
        getAllUserTaskApi(param, (res) => {
          setAllTask(res?.data);
          setPageMeta(res?.meta);
        });
      }
    });
  };

  // Function to get button background color based on status
  const getButtonColor = (status) => {
    switch (status) {
      case 'On Hold':
        return '#FFF3CD'; // Light yellow for On Hold
      case 'Suspended':
        return '#FDEDED'; // Light red for Suspended
      case 'Completed':
        return '#D4EDDA'; // Light green for Completed
      case 'Over Due':
        return '#FDEDED'; // Light pink for Over Due
      case 'Ongoing':
        return '#E7F3FF'; // Light blue for Ongoing
      case 'New':
        return '#FFF3EA'; // Light grey for New
      default:
        return '#E6F4FF'; // Default light blue for any other status
    }
  };
  const getButtonTextColor = (status) => {
    switch (status) {
      case 'On Hold':
        return '#FED330'; // dark yellow for On Hold
      case 'Suspended':
        return '#EE4E4E'; // dark pink for Suspended
      case 'Completed':
        return '#AABEAE'; // Light green for Completed
      case 'Over Due':
        return '#EE4E4E'; // dark pink for Over Due
      case 'Ongoing':
        return '#1089FF'; // dark blue for Ongoing
      case 'New':
        return '#FF8B2F'; // dark orange for New
      default:
        return '#E6F4FF'; // Default light blue for any other status
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="On Hold">On Hold</Menu.Item>
      <Menu.Item key="Suspended">Suspended</Menu.Item>
      <Menu.Item key="Completed">Completed</Menu.Item>
      {/* <Menu.Item key="Over Due">Over Due</Menu.Item>
      <Menu.Item key="Ongoing">Ongoing</Menu.Item> */}
      <Menu.Item key="New">New</Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown
        overlay={menu}
        trigger={['click']}
        overlayStyle={{
          maxHeight: '200px', // Set max height for dropdown
          overflowY: 'auto',  // Enable scroll if overflow
        }}
      >
        <Button
          style={{
            backgroundColor: getButtonColor(initialStatus), // Dynamic background color
            borderRadius: '2px',        // Rounded corners
            border: 'none',             // Remove button border
            color: getButtonTextColor(initialStatus),           // Blue text
            fontFamily: 'Roboto',       // Font family
            padding: '6px 8px',         // Smaller padding
            width: '90px',              // Reduced fixed button width
            whiteSpace: 'nowrap',       // Prevent text from wrapping
            overflow: 'hidden',         // Hide overflowed text
            textOverflow: 'ellipsis',   // Show ellipsis when text overflows
            display: 'inline-flex',     // Make sure icon stays aligned
            alignItems: 'center',       // Vertically align icon and text
            justifyContent: 'space-between', // Space between text and icon
          }}
        >
          <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{initialStatus}</span>
          <DownOutlined style={{ color: '#888888' }} />
        </Button>
      </Dropdown>
      {/* {<StatusTasksModal modal={updateModal} setModal={setUpdateModal} refresh={refreshProject.bind(this)}/>} */}
    </>
  );
};

export default DropdownButton;
