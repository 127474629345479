import React, { useEffect, useRef, useState } from "react";
import classes from './Response.module.css';
import user_icon from './../../assets/Profile Icon.svg';
import Api from "../../services/Api";
import { Pagination, Spin, Typography } from "antd";
import PreviewModal from "./PreviewModal";
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";

const Response=({search})=>{
    //  const [search,setSearch]=useState();
    const [limit,setLimit]=useState(10);
    const [current,setCurrent]=useState(1);
    const [response,setResponse]=useState();
    const [showPreview,setShowPreview]=useState(false);
    const [previewContent,setPreviewContent]=useState();
    const [loader,setLoader]=useState(false);
    console.log("responseresponse",response)
    const [isScrollAdded,setIsScrollAdded]=useState(false);
    const{width}=useWindowDimensions();
    const ref=useRef(null);

    const innerElement= response?.length>0?response?.map((item,index)=>{
            return(
                <>
                {width>800?<div className={classes.cardItem} >
                    <div className={classes.profileDiv}>
                        <img src={item?.profileImage?item?.profileImage:user_icon} style={{width:"2.5rem",height:"2.5rem",borderRadius:"50%"}}/>
                        <div >
                            <span>{item?.name}</span>
                            <span>{item?.uid}</span>
                        </div>
                    </div>
                    <div className={classes.contact}>
                        <Typography.Text style={{maxWidth:"15rem"}} ellipsis={{tooltip:true}}>
                        <span>{item?.email}</span>
                        </Typography.Text>
                        <span>{item?.phone}</span>
                        
                    </div>
                    <Typography.Paragraph ellipsis={{tooltip:true,rows:2}}>
                    <span>{item?.subject}</span>
                    </Typography.Paragraph>
                    <div>
                            <svg 
                            style={{cursor:"pointer"}}
                            onClick={()=>{
                                setShowPreview(true);
                                setPreviewContent(item);
                            }} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <circle cx="20" cy="20" r="20" fill="#0086FF" fill-opacity="0.15"/>
                                <path d="M20.0004 22.0946C20.9887 22.0946 21.7899 21.2934 21.7899 20.3051C21.7899 19.3168 20.9887 18.5156 20.0004 18.5156C19.0121 18.5156 18.2109 19.3168 18.2109 20.3051C18.2109 21.2934 19.0121 22.0946 20.0004 22.0946Z" fill="#0086FF"/>
                                <path d="M20 15C14.4842 15 10 20.3053 10 20.3053C10 20.3053 14.4842 25.6105 20 25.6105C25.5158 25.6105 30 20.3053 30 20.3053C30 20.3053 25.5158 15 20 15ZM20 23.7368C18.1053 23.7368 16.5684 22.2 16.5684 20.3053C16.5684 18.4105 18.1053 16.8737 20 16.8737C21.8947 16.8737 23.4316 18.4105 23.4316 20.3053C23.4316 22.2 21.8947 23.7368 20 23.7368Z" fill="#0086FF"/>
                        </svg>                     
                    </div>

                </div>:

                <div className={classes.mobCard}>
                    <div className={classes.mobProfile}>
                            <div className={classes.profileDiv}>
                                <img src={item?.profileImage?item?.profileImage:user_icon} style={{width:"2.5rem",height:"2.5rem",borderRadius:"50%"}}/>
                                <div >
                                    <span style={{color:"#000"}}>{item?.name}</span>
                                    <span>{item?.uid}</span>
                                </div>
                            </div>
                            <div>
                            <svg 
                            style={{cursor:"pointer"}}
                            onClick={()=>{
                                setShowPreview(true);
                                setPreviewContent(item);
                            }} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <circle cx="20" cy="20" r="20" fill="#0086FF" fill-opacity="0.15"/>
                                <path d="M20.0004 22.0946C20.9887 22.0946 21.7899 21.2934 21.7899 20.3051C21.7899 19.3168 20.9887 18.5156 20.0004 18.5156C19.0121 18.5156 18.2109 19.3168 18.2109 20.3051C18.2109 21.2934 19.0121 22.0946 20.0004 22.0946Z" fill="#0086FF"/>
                                <path d="M20 15C14.4842 15 10 20.3053 10 20.3053C10 20.3053 14.4842 25.6105 20 25.6105C25.5158 25.6105 30 20.3053 30 20.3053C30 20.3053 25.5158 15 20 15ZM20 23.7368C18.1053 23.7368 16.5684 22.2 16.5684 20.3053C16.5684 18.4105 18.1053 16.8737 20 16.8737C21.8947 16.8737 23.4316 18.4105 23.4316 20.3053C23.4316 22.2 21.8947 23.7368 20 23.7368Z" fill="#0086FF"/>
                        </svg>                     
                    </div>
                    </div>
                    <div>
                        <div>Mail:</div>
                        <div>{item?.email}</div>
                    </div>
                    <div>
                        <div>Phone:</div>
                        <div>{item?.phone}</div>
                    </div>
                    <div>
                        <div>Subject:</div>
                        <div>{item?.subject}</div>
                    </div>
                    
                </div>
                }
                </>
            )
        
        }):<div className={classes.noData}>
            <span>No Data to Display!</span>
        </div>
     

    useEffect(() => {
        const divElement = ref.current;
        if(divElement)
        {
        
          const hasScrollbar = divElement.scrollHeight > divElement.clientHeight;
        setIsScrollAdded(hasScrollbar);
        } 
      }, [innerElement]);
     
      console.log(isScrollAdded,"isScrollAdded")

    useEffect(()=>{
        setLoader(true);
        Api.get(`/resource-user/getCeoDesk/?search=${search}&limit=${limit}&page=${search?"":current}`).send(res=>{
            if(res){
                setResponse(res);
            }
            setLoader(false);
        })
    },
    [limit,current,search])

    return(
        <div className={classes.response}>
            {width>800&&<div className={classes.header}>
                <span>Associate Name</span>
                <span>Contact Details</span>
                <span>Subject</span>
                <span>Actions</span>
            </div>}

            {loader?<Spin style={{display:"flex",alignItems:"center",justifyContent:"center",height:"50vh"}}></Spin>:<div>
            <div className={classes.cardContainer} ref={ref} style={{marginRight:isScrollAdded?"0.7rem":"1rem",}}>
                {
                  innerElement
                }

            </div>

            <Pagination position="bottomRight" size="middle" style={{display:"flex",justifyContent:"flex-end",marginTop:"0.5rem",marginRight:"1rem"}}
              current={current} showSizeChanger={true} total={parseInt(response?.[0]?.total_rows_in_cte)?parseInt(response?.[0]?.total_rows_in_cte):0} pageSize={limit} 
              onChange={(page,pageSize)=>{
                setCurrent(page)
                setLimit(pageSize)
              }}
               onShowSizeChange={(page,pageSize)=>{
                setCurrent(page)
                setLimit(pageSize)
              }} 
              pageSizeOptions={[10,20,30,50,100,200]}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} />
              </div>}

              <PreviewModal showPreview={showPreview} setShowPreview={setShowPreview} previewContent={previewContent}/>

        </div>
    )
}
export default Response;