
import React, { useEffect, useState } from 'react';
import { Modal, Checkbox, Button, Divider, Select, DatePicker ,Radio} from 'antd';
import { postWorkingDays } from './Action/action';
import moment from 'moment';
import DatePickerCompo from '../../../components/DatePicker/DatePickerCompo.tsx';
import settingIcons from "../../../../src/assets/settingIcon.svg"
import ErrorModal from './errorModal.js';
// import './EditSettingsModal.css'; // Import CSS file for custom styling

const EditSettingsModal = ({ settingsModal, onCancel, selectedTeamRowKeys,selectedDeptRowKeys , onCallBack}) => {
  const CheckboxGroup = Checkbox.Group;
  const { Option } = Select;
  const plainOptions = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  let defaultCheckedList =[]
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [userSelectedTeam, setSelectedTeam] = useState([])
  const [userSelectedDept, setSelectedDept] = useState([])
  const [startDate, setStartDate] = useState(null)
  const checkAll = plainOptions.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;
  const [errorModal, setErrorModal] = useState(false);
  const [errorData, setErrorData] = useState();

  const onChange = (list) => {
    setCheckedList(list);
  };

  const dayToAbbreviation = {
    'sunday': 'sun',
    'monday': 'mon',
    'tuesday': 'tue',
    'wednesday': 'wed',
    'thursday': 'thu',
    'friday': 'fri',
    'saturday': 'sat',
    'sun': 'sunday',
    'mon': 'monday',
    'tue': 'tuesday',
    'wed': 'wednesday',
    'thu': 'thursday',
    'fri': 'friday',
    'sat': 'saturday'
  };

  const fillWeeks = ()=> { 
    let temparr = []
    if(selectedTeamRowKeys?.length===1 && selectedTeamRowKeys[0]?.reqWeekDays!==null){
      for(const days of selectedTeamRowKeys?.[0]?.reqWeekDays ){
      // if( selectedTeamRowKeys?.[0]?.weekDays[days]){
           temparr.push(dayToAbbreviation[days])
           console.log("tempaar", days);
      //  }
      }
    }else if(selectedDeptRowKeys?.length===1 && selectedDeptRowKeys[0]?.reqWeekDays!==null){
      for(const days of selectedDeptRowKeys?.[0]?.reqWeekDays  ){
      //  if( selectedDeptRowKeys?.[0]?.weekDays[days]){
           temparr.push(dayToAbbreviation[days])
           console.log("tempaar", days);
      //  }
      }
    }
  
    return temparr
  }

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
  };
  useEffect(() => {
console.log("slected rowssss",selectedDeptRowKeys,selectedTeamRowKeys)
    if (selectedTeamRowKeys?.length >= 1) {
      let temp = [];
      selectedTeamRowKeys?.forEach(element => {
        temp.push({ title: element?.name, value: element?.id })
      });
      setStartDate()
      setCheckedList([])
      setSelectedTeam(temp)
    }
    if(selectedTeamRowKeys?.length ==1 &&selectedDeptRowKeys.length==0 ){
      let arr = fillWeeks()
      setCheckedList(arr)
      setStartDate((selectedTeamRowKeys?.length==1&&selectedTeamRowKeys[0]?.commenceWeekDaysDate && new Date(selectedTeamRowKeys[0]?.commenceWeekDaysDate)>new Date())? selectedTeamRowKeys[0]?.commenceWeekDaysDate : null)
    }

    if(selectedDeptRowKeys?.length>=1){
      let temp = [];
      selectedDeptRowKeys?.forEach(element => {
        temp.push({ title: element?.department?.name, value: element?.department?.id })
      });
      setStartDate()
      setCheckedList([])
      setSelectedDept(temp)
    }
    if(selectedDeptRowKeys?.length==1 && selectedTeamRowKeys?.length==0){
      
        let arr = fillWeeks()
        setCheckedList(arr)
        setStartDate((selectedDeptRowKeys?.length==1&&selectedDeptRowKeys[0]?.commenceWeekDaysDate && new Date(selectedDeptRowKeys[0]?.commenceWeekDaysDate)>new Date())? selectedDeptRowKeys[0]?.commenceWeekDaysDate : null)
      
    }
    
    
   
  }, [settingsModal])

  const onUpdate = () => {
    console.log(moment(startDate).format("YYYY-MM-DD"),"jgnjgnjnjnjj");
    let daysObject = {
      'sun': false,
      'mon': false,
      'tue': false,
      'wed': false,
      'thu': false,
      'fri': false,
      'sat': false
    };
    if(selectedTeamRowKeys?.length >= 1 && selectedDeptRowKeys?.length==0){
      checkedList?.forEach((ele)=>{
        daysObject[dayToAbbreviation[ele]]=true
      })
      
      postWorkingDays({teamId:userSelectedTeam?.map((ele)=>ele?.value)?.filter((value) => value != null) , weekDays:daysObject,startDate:moment(startDate).format("YYYY-MM-DD")},
      (e)=>{
        onCancel()
        onCallBack()
        if(e?.show?.data){
          setErrorModal(true)
          setErrorData(e?.show?.data)
        }
      },
     
      )
    }else  if(selectedDeptRowKeys?.length >= 1 && selectedTeamRowKeys?.length==0){
      checkedList?.forEach((ele)=>{
        daysObject[dayToAbbreviation[ele]]=true
      })
      
      postWorkingDays({deptId:userSelectedDept?.map((ele)=>ele?.value)?.filter((value) => value != null) , weekDays:daysObject,startDate:moment(startDate).format("YYYY-MM-DD")},
      (e)=>{
        if(e?.show?.type ==="success"){
          onCancel()
          onCallBack()
          if(e?.show?.data){
            setErrorModal(true)
            setErrorData(e?.show?.data)
          }
        }
        else{
          //onCancel()
          onCallBack()
          if(e?.show?.data){
            setErrorModal(true)
            setErrorData(e?.show?.data)
          }
        }
      },
     
      )
    }else  if(selectedTeamRowKeys?.length >= 1 && selectedDeptRowKeys?.length>=1){
      checkedList?.forEach((ele)=>{
        daysObject[dayToAbbreviation[ele]]=true
      })
      
  
      postWorkingDays({teamId:userSelectedTeam?.map((ele)=>ele?.value)?.filter((value) => value != null) , 
        deptId:userSelectedDept?.map((ele)=>ele?.value)?.filter((value) => value != null) ,
        weekDays:daysObject,startDate:moment(startDate).format("YYYY-MM-DD")},
      (e)=>{
        onCancel()
        onCallBack()
        if(e?.show?.data){
          setErrorModal(true)
          setErrorData(e?.show?.data)
        }
      },
     
      )
    }
    // else if(selectedTeamRowKeys?.length ==1 && selectedDeptRowKeys?.length==1){
    //   checkedList?.forEach((ele)=>{
    //     daysObject[dayToAbbreviation[ele]]=true
    //   })
      
    //   let payload={
    //     teamId:selectedTeamRowKeys[0]?.id?[selectedTeamRowKeys[0]?.id]:undefined, 
    //     weekDays:daysObject,
    //     startDate:moment(startDate).format("YYYY-MM-DD"),
    //     deptId:selectedDeptRowKeys[0]?.department?.id?[selectedDeptRowKeys[0]?.department?.id]:undefined
    //   }
    //   postWorkingDays(payload,
    //   (e)=>{
    //     onCancel()
    //     onCallBack()
    //     if(e?.show?.data){
    //       setErrorModal(true)
    //       setErrorData(e?.show?.data)
    //     }
    //   },
    //   )
    // }

  }
  return (
    <>
    <Modal
      title={<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Edit Settings</div>}
      centered
      open={settingsModal}
      onCancel={onCancel}
      width={"50%"}
      // bodyStyle={{ height: "65vh" }}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="update" disabled={startDate===null} type="primary" onClick={onUpdate}>
          Update
        </Button>,
      ]}
    >
          
      {

        selectedTeamRowKeys?.length > 1 ?
          <>
          <div style={{ color: "#959595", fontSize: 12, fontWeight: 600, paddingBottom: 10 }}> Selected Team(s)</div>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Please select"
              onChange={(change) => {
                 let temp = userSelectedTeam?.filter(item => change.includes(item.value))
                setSelectedTeam(temp)
              }}
              value={userSelectedTeam}
            >
              {
                userSelectedTeam.map(item => (
                  <Option key={item.title} value={item.value} label={item.title}>
                    {item.title}
                  </Option>
                ))}
            </Select>
          </>
          : selectedTeamRowKeys?.length==1?
          <>
            <div>
            <div style={{ color: "#959595", fontSize: 12, fontWeight: 600, paddingBottom: 10 }}> Selected Team(s)</div>
              <div id="1" style={{ display: "flex", padding: 10 }}>
                <div style={{ width: "50%" }}>
                  <div style={{ color: "#959595", fontSize: 12, fontWeight: 600 }}>Team</div>
                  <div style={{ color: "#161616", fontSize: 14, fontWeight: 600 }}>{selectedTeamRowKeys[0]?.name}</div>
                </div>
                <div style={{ width: "50%" }}>
                  <div style={{ color: "#959595", fontSize: 12, fontWeight: 600 }}>Department</div>
                  <div style={{ color: "#161616", fontSize: 14, fontWeight: 600 }}>{selectedTeamRowKeys[0]?.deptname?selectedTeamRowKeys[0]?.deptname:selectedTeamRowKeys[0]?.department?.name}</div>
                </div>
              </div>
            </div>
          </>:null
      }


      {

        selectedDeptRowKeys?.length > 1 ?
          <>
          <div style={{ color: "#959595", fontSize: 12, fontWeight: 600, paddingBottom: 10,marginTop:10 }}> Selected Department(s)</div>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Please select"
              onChange={(change) => {
                 let temp = userSelectedDept?.filter(item => change.includes(item.value))
                setSelectedDept(temp)
              }}
              value={userSelectedDept}
            >
              {
                userSelectedDept.map(item => (
                  <Option key={item.title} value={item.value} label={item.title}>
                    {item.title}
                  </Option>
                ))}
            </Select>
          </>
          : selectedDeptRowKeys?.length ==1?
          <>
            <div>
            <div style={{ color: "#959595", fontSize: 12, fontWeight: 600, paddingBottom: 10,marginTop:10 }}> Selected Department(s)</div>
              <div id="1" style={{ display: "flex", padding: 10 }}>
                <div style={{ width: "50%" }}>
                  <div style={{ color: "#959595", fontSize: 12, fontWeight: 600 }}>Team</div>
                  <div style={{ color: "#161616", fontSize: 14, fontWeight: 600 }}>{selectedDeptRowKeys[0]?.name}</div>
                </div>
                <div style={{ width: "50%" }}>
                  <div style={{ color: "#959595", fontSize: 12, fontWeight: 600 }}>Department</div>
                  <div style={{ color: "#161616", fontSize: 14, fontWeight: 600 }}>{selectedDeptRowKeys[0]?.deptname?selectedDeptRowKeys[0]?.deptname:selectedDeptRowKeys[0]?.department?.name}</div>
                </div>
              </div>
            </div>
          </>:null
      }



      {  (selectedDeptRowKeys?.length ==1 &&   selectedTeamRowKeys?.length ==0)||(selectedDeptRowKeys?.length ==0 &&   selectedTeamRowKeys?.length ==1)?
     <>
      <Divider></Divider>
      <div>
        <div style={{ color: "#161616", fontSize: 12, fontWeight: 600,marginBottom:10 }}>Assigned Working days</div>
      
        {selectedDeptRowKeys?.length==1 && selectedDeptRowKeys?.[0]?.weekDays?
        <>

        {Object.keys(selectedDeptRowKeys?.[0]?.weekDays)?.map(ele=>{
            if(selectedDeptRowKeys?.[0]?.weekDays[ele]){
              return  <Radio checked disabled={true}>{ele}</Radio>
            }else{
              return null
            }
         })
        }
        <div style={{ color: "#161616", fontSize: 12, fontWeight: 600,marginBottom:10,marginTop:10 }}>Assigned Date</div>
        <div>{selectedDeptRowKeys?.[0]?.pastdate}</div>
        </>
      
        :selectedTeamRowKeys?.length==1 && selectedTeamRowKeys?.[0]?.weekDays ?
        <>
        {Object.keys(selectedTeamRowKeys?.[0]?.weekDays)?.map(ele=>{
            if(selectedTeamRowKeys?.[0]?.weekDays?.[ele]){
              return  <Radio checked disabled={true}>{ele}</Radio>
            }else{
              return 
            }
         })}
         <div style={{ color: "#161616", fontSize: 12, fontWeight: 600,marginBottom:10,marginTop:10 }}>Assigned Date</div>
        <div>{selectedTeamRowKeys?.[0]?.pastdate}</div>
        </>
    
         

        
        :<div>NA</div>}
       
      </div>
      </>
      :null}

      <Divider></Divider>
      <div>
        <div style={{ color: "#161616", fontSize: 12, fontWeight: 600 }}>Requested Working days</div>
        <div style={{ color: "#5E5E5E", fontSize: 14, fontWeight: 400 }}>{"Working days are the days when associates are scheduled to work, excluding holidays."}</div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 20 }}>
        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
          Select all
        </Checkbox>
        <div>
          Selected {checkedList?.length} of 7
        </div>
      </div>
      <div style={{ marginTop: 20, }}>
        <CheckboxGroup style={{ gap:10}} options={plainOptions} value={checkedList} onChange={onChange} />
      </div>
      <div>
        {
          
          <>
          <div style={{marginTop:15,  color: "#161616", fontSize: 12, fontWeight: 600 }}>Start Date <span style={{color:"red"}}>*</span></div>
          <div style={{ color: "#5E5E5E", fontSize: 14, fontWeight: 400 }}>
          Choose a starting date to activate this setting.
          </div>
          <DatePickerCompo style={{width:"100%", marginBottom: 5, marginTop:5}}  
                      
                      onChange={(e)=>{
                        setStartDate(e)
                      }}
                      value={startDate?moment(startDate):null} disabled={false}/>
          {startDate > moment().format("YYYY-MM-DD") ? 
          <div style={{color:"#0086FF",marginBottom: 20,fontSize:"0.75rem"}}>
            <img src={settingIcons} alt="" style={{height:"0.8rem",width:"0.8rem"}} /> The modifications applied with the adjustments to {checkedList.join(", ")}, will take effect and reflect from the start date of {startDate}.
          </div>
          :""
          }
          </>
        }
      </div>
    </Modal>
    {errorModal && (
      <ErrorModal errorData={errorData} errorModal={errorModal} onClose={() => setErrorModal(false)} />
    )}

    </>
   
  );
};

export default EditSettingsModal;