import { AES, enc } from "crypto-js";
import { geolocated } from "react-geolocated";
import Notification from "./Notification";

 export const SECRET_KEY = 'secret-key';

export const encryptData = (data, secretKey) => {
   const encryptedData = AES.encrypt(JSON.stringify(data), secretKey).toString();
   return encryptedData;
}

export const decryptData = (encryptedData, secretKey) => {
    
   const decryptedData = AES.decrypt(encryptedData, secretKey).toString(enc.Utf8);
   if (decryptedData) {
     try {
       return JSON.parse(decryptedData);
     } catch (error) {
       console.error("Error parsing JSON:", error);
     }
   } else {
     console.error("Empty decrypted data");
   }
}

export  const getLocation=( callback)=>{
  let ans;
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        ans = { latitude, longitude };

      console.log("Latitude: " + latitude);
      console.log("Longitude: " + longitude);
      callback(ans);

    }, function (error) {
          Notification.error("An error occurred while getting user location.");
    },{
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 10000,
      // altitude: true, 
      // altitudeAccuracy: 10
      // maximumAge:5000
    });
  } 
  else {
    console.error("Geolocation is not available in this browser.");
  }
}; 
// export const getLocation = (callback) => {
//   let ans;

//   const options = {
//     enableHighAccuracy: false,
//     timeout: 5000,
//     maximumAge: 0  // Set to 0 to always get fresh location
//   };

//   if ('geolocation' in navigator) {
//     navigator.geolocation.getCurrentPosition(
//       function (position) {
//         const latitude = position.coords.latitude;
//         const longitude = position.coords.longitude;
//         ans = { latitude, longitude };

//         console.log("Latitude: " + latitude);
//         console.log("Longitude: " + longitude);
//         callback(ans);
//       },
//       function (error) {
//         console.error("An error occurred while getting user location.");
//       },
//       options
//     );
//   } else {
//     console.error("Geolocation is not available in this browser.");
//   }
// };
