import React, { useEffect, useState } from 'react';
import { AiFillPlusCircle, AiFillDelete} from 'react-icons/ai';
import { GrEdit } from 'react-icons/gr';
import {  Table, Modal, Button, Divider, Spin, Typography, Popover, Pagination } from 'antd';
import DeleteModel from './deleteModel';
import {MoreOutlined } from '@ant-design/icons';
import AddHolidayModel from './addHolidayModel';
import { useNotificationStore } from './store';
import EditHolidayModel from './editHolidayModel';
import Views from "../../../services/ViewRole";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import classes from "./setting.module.css";
import moment from 'moment';
import { useRef } from 'react';
import DeleteIcon from "../../../assets/SettingDeleteIcon.svg";

function FixedHolidayModel() {

    const { SetDeleteStore,setdeleteCity, setrecordStore, load, setfromHoliday,holidayListBy,
            setNotfixed, fixedStore, typeLoactionStore } = useNotificationStore();
    const [centerModel, setCenterModel] = useState(false);
    const [edit, setEdit] = useState(false)
    const [holiday, setHoliday] = useState(false)
    const [deletes, setdalete] = useState(false)
    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(1);
    const {width}=useWindowDimensions();
    const [popoverVisible, setPopoverVisible] = useState(false);

useEffect(() => {
    setdeleteCity(false)
    setfromHoliday(true)
    setNotfixed(false)
}, [])



    const columns = [
        {
            title: "S.No",
            dataIndex: "sno",
            key: "sno",
            render: (text, record, index) => {
                return (
                    <div>
                        <span>{index + 1}</span>
                    </div>
                )
            }
        },

        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (text, record) => {
                return (
                    <div>
                        <span>{text}</span>
                    </div>
                )
            }
        },

        {
            title: "Day",
            dataIndex: "day",
            key: "day",
            render: (text, record) => {
                return (
                    <div>
                        <span>{text}</span>
                    </div>
                )
            }
        },
        {
            title: "Holiday Name",
            dataIndex: "accountOf",
            key: "accountOf",
            render: (text, record) => {
                return (
                    <div>
                        <span>{text}</span>
                    </div>
                )
            }
        },

        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (text, record) => {
                return (
                    <>
                        <div style={{ display: 'flex', }}>
                            <div style={{ borderRadius: 40,cursor:Views?.hrAssociate?"not-allowed":"pointer", backgroundColor: '#ebeded', width: '4vh', height: '4vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <GrEdit size={16} onClick={() => {
                                    // console.log('kishan',item);
                                    // setcityid(item?.id)
                                    // setaddCity(true)
                                    if(!Views?.hrAssociate){
                                    setEdit(true)
                                    setCenterModel(true)
                                    setHoliday(false)
                                    setdalete(false)
                                    setrecordStore(record)
                                  }
                                    // SetaddCityName(item?.cityName)

                                }} style={{}} />
                            </div>

                            <div style={{ borderRadius:40,cursor: Views?.hrAssociate?"not-allowed":"pointer", width:'2rem',height:'2rem',display:'flex',alignItems:'center',justifyContent:'center',marginLeft: 10 }}>
                                <img src={DeleteIcon}
                                    onClick={() => {
                                    if(!Views?.hrAssociate){
                                    SetDeleteStore('fixed')
                                    setEdit(false)
                                    setCenterModel(true)
                                    setdalete(true)
                                    setHoliday(false)
                                    setrecordStore(record)
                                   }

                                    }} color='#FF3232' />
                            </div>
                        </div>
                    </>
                )
            }
        },
    ]

    const [loadingItems, setLoadingItems] = useState([]);
    const startIndex = (pageCurrent  - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, typeLoactionStore?.data?.length);
    const paginatedData = typeLoactionStore?.data?.slice(startIndex, endIndex);
    
    return (
        <div style={{ display: 'flex', flexDirection: "column" }}>
            {width > 700 ?
            <div style={{ display: 'flex' }}>
                <Button
                disabled={Views?.hrAssociate?true:false}
                    onClick={() => {
                        setEdit(false)
                        setdalete(false)
                        setHoliday(true)
                        setCenterModel(true)
                    }}
                    style={{
                        display: 'inline-flex',
                        background: '#0086FF',
                        height: '2rem',
                        width: '11rem',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '1vh',
                        borderRadius: 7,
                        // cursor: 'pointer'
                    }}>
                    <AiFillPlusCircle color='white' />
                    <div style={{ fontSize: '12px', color: 'white', marginLeft: 2 }}>
                        {fixedStore?.type=='Fixed' ? "Add Fixed Holiday" : 'Add Optional Holiday'}
                    </div>
                </Button>

                {holidayListBy === "Location" ?
                <h3 style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft:"2vw",fontSize:18 }}>
                {`${fixedStore?.type} Holidays in ${fixedStore?.cityName}`}</h3>
                :
                <h3 style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft:"2vw",fontSize:18 }}>
                {`${fixedStore?.type} Holidays for ${fixedStore?.cityName}`}</h3>
                }
            </div>
            :
            <div>
                <h4 style={{ width: '100%',fontSize:"22px"}}>{`${fixedStore?.type} Holidays`}</h4>
            </div>
            }
            <div style={{ marginTop: width > 700 ? '0.5rem' :"0.5rem" }}>
             {/* <Table
                //     loading={load} scroll={{y:"63.5vh"}}
                //     columns={columns} dataSource={typeLoactionStore?.data} 
                //     pagination={{
                //         position: "bottomRight",
                //         size: "small",
                //         pageSizeOptions: [10,30, 50,100],
                //         pageSize: pageSize,
                //         current: pageCurrent,
                //         // total:
                //         onChange: (page, pageSize) => {setPageCurrent(page);setPageSize(pageSize);},
                //         onShowSizeChange: (page, pageSize) => {setPageCurrent(page);setPageSize(pageSize);},
                //         showSizeChanger: true,
                //         showTotal: (total, range) =>
                //           `${range[0]}-${range[1]} of ${total} items`,
                // }}/> */}
            {width > 700 ?
            <div className={classes.ListContainer}>
            <div className={classes.ListHeader} style={{display:'flex',width:'100%',gap:"0.5rem",paddingTop:"0.7rem",paddingBottom:"0.5rem",fontSize: 15, color: "#16243280", fontWeight: 700,}}>
            
                    <div style={{width:'20%',paddingLeft:"0.7rem"}}>S.No</div>
                    <div style={{width:'20%'}}>Date</div>
                    <div style={{width:'20%'}}>Day</div>
                    <div style={{width:'20%'}}>Holiday Name</div>
                    <div style={{width:'20%'}}>Actions</div>
            </div>
            
            <div className={classes.ListBodyContainer} style={{height:"70vh", overflowY:'scroll',overflowX:"scroll",width:"100%"}}>
                  {load?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><Spin /></div>:
                     typeLoactionStore?.data?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><div>No data found</div></div>:
                     paginatedData?.map((record,id)=>{
                        return(
                          <div className={classes.rowContainer} style={{gap:"0.5rem",color: "#162432", fontSize: 15, fontWeight: 400, fontStyle: "Lato",}}>

                          <div style={{width:'20%',paddingLeft:"0.7rem"}}>
                          <div>{id+1}</div>
                          </div>
                          <div style={{width:'20%',}}>
                          <Typography.Text style={{width:"13vw",}} ellipsis={{tooltip:true}}>
                          <span>{record?.date ? record?.date :"NA"}</span></Typography.Text>
                          </div>
                          <div style={{width:'20%',}}>
                          <Typography.Text style={{width:"9rem",color: "#162432", fontSize: 15,}} ellipsis={{tooltip:true}}>
                          {record?.day ? record?.day :"NA"}</Typography.Text>
                          </div>
                          <div style={{width:'20%',}}>
                          <Typography.Text style={{width:"9rem",color: "#162432", fontSize: 15,}} ellipsis={{tooltip:true}}>
                          {record?.accountOf ? record?.accountOf :"NA"}</Typography.Text>
                          </div>
                          
                          <div style={{width:'20%',}}>
                          {holidayListBy === "Location" ?
                          <div style={{ display: 'flex',alignItems:"center"}}>
                          <div style={{ borderRadius: 40,cursor:Views?.hrAssociate?"not-allowed":"pointer", backgroundColor: '#ebeded', width: '2rem', height: '2rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <GrEdit size={18} onClick={() => {
                                    // console.log('kishan',item);
                                    // setcityid(item?.id)
                                    // setaddCity(true)
                                    if(!Views?.hrAssociate){
                                    setEdit(true)
                                    setCenterModel(true)
                                    setHoliday(false)
                                    setdalete(false)
                                    setrecordStore(record)
                                  }
                                    // SetaddCityName(item?.cityName)

                                }} style={{}} />
                          </div>

                            {/* <div style={{ borderRadius: 40,cursor:Views?.hrAssociate?"not-allowed":"pointer",width:'1.5rem',height:'1.5rem',display:'flex',alignItems: 'center', justifyContent: 'center', marginLeft: 10 }}>
                                <img src={DeleteIcon} style={{height:"2rem",}}
                                    onClick={() => {
                                    if(!Views?.hrAssociate){
                                    SetDeleteStore('fixed')
                                    setEdit(false)
                                    setCenterModel(true)
                                    setdalete(true)
                                    setHoliday(false)
                                    setrecordStore(record)
                                   }
                                    }} color='#FF3232' />
                            </div> */}
                          </div>
                          :
                          <div style={{ display: 'flex',alignItems:"center"}}>
                          <div style={{ borderRadius: 40,cursor:Views?.hrAssociate?"not-allowed":"pointer", backgroundColor: '#ebeded', width: '2rem', height: '2rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <GrEdit size={18} onClick={() => {
                                    // console.log('kishan',item);
                                    // setcityid(item?.id)
                                    // setaddCity(true)
                                    if(!Views?.hrAssociate){
                                    setEdit(true)
                                    setCenterModel(true)
                                    setHoliday(false)
                                    setdalete(false)
                                    setrecordStore(record)
                                  }
                                    // SetaddCityName(item?.cityName)

                                }} style={{}} />
                          </div>

                            <div style={{ borderRadius: 40,cursor:Views?.hrAssociate?"not-allowed":"pointer",width:'1.5rem',height:'1.5rem',display:'flex',alignItems: 'center', justifyContent: 'center', marginLeft: 10 }}>
                                <img src={DeleteIcon} style={{height:"2rem",}}
                                    onClick={() => {
                                    if(!Views?.hrAssociate){
                                    SetDeleteStore('fixed')
                                    setEdit(false)
                                    setCenterModel(true)
                                    setdalete(true)
                                    setHoliday(false)
                                    setrecordStore(record)
                                   }
                                    }} color='#FF3232' />
                            </div>
                          </div>
                          }
                          </div>
                          </div>
                        )
                      })}
            </div>  
            </div>
                :
                <div style={{backgroundColor:"#F9F9F9",padding:"0.4rem 0.4rem"}}>
                <div className={classes.ListBodyContainer} style={{height:"60vh", overflowY:'scroll',overflowX:"scroll",width:"100%",}}>
                  {load?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><Spin /></div>:
                     typeLoactionStore?.data?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><div>No data found</div></div>:
                     paginatedData?.map((record,id)=>{
                        let dates = record?.date.split("-")
                        const isVisible = popoverVisible[id];
                        return(
                          <div className={classes.rowContainer} style={{gap:"0.5rem",color: "#162432", fontSize: 15, fontWeight: 400, fontStyle: "Lato",}}>
                          
                          <div style={{display:"flex",flexDirection:"row",width:"100%",gap:"1rem",padding:"0 0.6rem"}}>
                          
                          <div style={{display:"flex",alignItems:"center",flexDirection:"column"}}>
                          <h3>{record?.date ? dates[0]:"NA"}</h3>
                          <h3 style={{color:"#0086FF"}}>{record?.date ? moment(dates[1]).format("MMM"):"NA"}</h3>
                          </div>

                          <div>
                          <Typography.Text style={{width:"45vw",color: "#162432", fontSize: 18,fontWeight:700}} ellipsis={{tooltip:true}}>
                          {record?.accountOf ? record?.accountOf :"NA"}</Typography.Text>
                          <p style={{color:"#0086FF",fontWeight:600,fontSize:18}}>{record?.day}</p>
                          </div>

                          <div>
                          <Popover placement="rightBottom" content={<div style={{fontSize:17,}}>
                          <p onClick={() => {
                                    if(!Views?.hrAssociate){
                                    setEdit(true)
                                    setCenterModel(true)
                                    setHoliday(false)
                                    setdalete(false)
                                    setrecordStore(record)
                                    setPopoverVisible({ ...popoverVisible, [id]: false });
                                  }
                                }}>Edit</p>
                          {/* <p 
                            onClick={() => {
                            if(!Views?.hrAssociate){
                            SetDeleteStore('fixed')
                            setEdit(false)
                            setCenterModel(true)
                            setdalete(true)
                            setHoliday(false)
                            setrecordStore(record)
                            setPopoverVisible({ ...popoverVisible, [id]: false });
                            }
                          }}>Delete</p> */}
                          </div>} visible={isVisible}
                          trigger="click" onVisibleChange={(visible) => setPopoverVisible({ ...popoverVisible, [id]: visible })}

                          >
                          <MoreOutlined style={{fontSize:"20px",paddingRight:"0.8rem"}}/>
                          </Popover>
                          </div>

                          </div>
                          </div>
                        )
                      })}
                </div>  
                </div>
                }
                {width > 700 ? 
                <Pagination position="bottomRight" size="small" style={{display:"flex",justifyContent:"flex-end",marginTop:"0.5rem",fontSize:width<700&&15}}
                    current={pageCurrent}
                    pageSize={pageSize}
                    total={typeLoactionStore?.data?.length
                    ? typeLoactionStore?.data?.length
                    : 0}
                    pageSizeOptions={[10, 30, 50, 100]}
                    showSizeChanger={true}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                    onChange={(page, pageSize) => {setPageCurrent(page);setPageSize(pageSize);}}
                    onShowSizeChange={(page, pageSize) => {setPageCurrent(page);setPageSize(pageSize);}}
                /> 
                :
                <div style={{borderBottom:"1px solid #e3e3e3"}}>
                <Pagination position="bottomRight" size="small" style={{display:"flex",justifyContent:"flex-end",marginTop:"0.5rem",fontSize:width<700&&15}}
                    current={pageCurrent}
                    pageSize={pageSize}
                    total={typeLoactionStore?.data?.length
                    ? typeLoactionStore?.data?.length
                    : 0}
                    pageSizeOptions={[10, 30, 50, 100]}
                    showSizeChanger={true}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                    onChange={(page, pageSize) => {setPageCurrent(page);setPageSize(pageSize);}}
                    onShowSizeChange={(page, pageSize) => {setPageCurrent(page);setPageSize(pageSize);}}
                /> 
                </div>
                }
                {/* {width < 700 && <Divider></Divider>} */}
                {width < 700 && <div>
                <Button
                    disabled={Views?.hrAssociate?true:false}
                    onClick={() => {
                        setEdit(false)
                        setdalete(false)
                        setHoliday(true)
                        setCenterModel(true)
                    }}
                    style={{
                        display: 'inline-flex',
                        background: '#0086FF',
                        height: '2.5rem',
                        // width: '11rem',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '1vh',
                        borderRadius: 7,
                        margin:"1rem 0",
                        // cursor: 'pointer'
                    }}>
                        <AiFillPlusCircle color='white' style={{fontSize:19}}/>
                    <div style={{ fontSize: '15px', color: 'white', marginLeft: 2 }}>
                        {fixedStore?.type=='Fixed' ? "Add Fixed Holiday" : 'Add Optional Holiday'}
                    </div>
                </Button>
                </div>
                }
            </div>

            {centerModel && <Modal
                color='red'
                centered
                open={centerModel}
                footer={null}
                // bodyStyle={{height:"30vh"}}
                onOk={() => setCenterModel(false)}
                onCancel={() => setCenterModel(false)}
                width={880}
                // style={{marginTop:"3rem"}}
            >

                {edit &&
                    <EditHolidayModel edit={edit} setCenterModel={setCenterModel} />
                }
                {
                    holiday && <AddHolidayModel setCenterModel={setCenterModel} setHoliday={setHoliday} />
                }
                {
                  deletes&&   <DeleteModel setCenterModel={setCenterModel}  setDelete={setdalete}    />
                }


            </Modal>}

        </div>);
}

export default FixedHolidayModel;