import Api from "../../services/Api";

export const getNewsLetterDataApi=(newsLetterDataHandler,search,status,callback)=>{
    Api.get(`/resource-newsletters/all?search=${search}&filter=${status}`).send(res=>{
        newsLetterDataHandler(res);
        callback();
        console.log("resource-newsletters",res)
    })
}

export const addNewsLetterApi=(params,callback)=>{
    console.log("ULOADLOARPAR",params)
    Api.post("/resource-newsletters/create").upload(params, (percentCompleted, response) => {
        if (typeof response != "undefined") 
        {
            console.log("AddNewsAPI",response)  
            callback();
        }
      });
}

export const EditNewsLetterApi=(params,callback)=>{
    // Api.patch("/resource-newsletters/edit").params({...params}).send(res=>{
    //     console.log("REREEEE",res)
    //     callback();
    // })
    
    Api.patch("/resource-newsletters/edit").uploadAndPatch(params, (percentCompleted, response) => {
        if (typeof response != "undefined") 
        {
            console.log("EDITNewsAPI",response)  
            callback();
        }
      });
}