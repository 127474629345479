
import React, { useState } from "react";
import Modal from "../../../components/Modal/Modal";
import { useHealthInsuranceStore } from "../Store";
import { ModalPrimaryButton, ModalSecondaryButton } from "../../../components/Button/Button";
import classes from './UploadModal.module.css';
import { Button, Form, Input, Typography, Upload, message } from "antd";
import { DeleteOutlined, InboxOutlined } from "@ant-design/icons";
import render_doc_icon from '../../../assets/render_doc_icon.svg'

const UploadModal=({onfinish,screenType,loader})=>{
    const {uploadModal,uploadModalHandler,fileList,setFileList}=useHealthInsuranceStore();
    const [form]=Form.useForm();
    // const [fileList, setFileList] = useState([]);
    

    const isPDF = file => {
        const fileType = file.type;
        return fileType === 'application/pdf';
      };
    return(
        <Modal show={uploadModal} 
        closableRight={true}
        style={{zIndex:200}}
        header={
            <div style={{width:"100%",fontSize:"1rem",}}>
                <div style={{fontWeight:"600",fontSize:"1.4rem"}}>Upload Document</div>

            </div>
        
    }
        onCancel={()=>{
            uploadModalHandler(false);

        }}>
            {uploadModal&&<div className={classes.container}>

           {uploadModal&&<Form layout="vertical"
           form={form}
           onFinish={onfinish}
           >
            <Form.Item name="fileName"  label={<div style={{fontWeight:"600",color:"#878787"}}>File Name</div>}
             rules={[{required:true,message:"Please enter a valid file name!",validator:(_, value) => {
              if (!value||value?.trim()==='') {
                return Promise.reject('Input cannot be empty');
              }
              return Promise.resolve();
            }}]}   
            >
              <Input size="large" placeholder="Enter the file name."/>

            </Form.Item>
           <Form.Item name="document" label={<div style={{fontWeight:"600",color:"#878787"}}>Upload Document</div>}  
           rules={[{required:true,message:"Please upload a valid PDF file!",validator:(_, value) => {
        if (fileList?.length===0) {
          return Promise.reject('Input cannot be empty');
        }
        return Promise.resolve();
      }}]}                  
                >
                    <Upload.Dragger   accept="application/pdf"
                    style={{padding:"2rem"}}
                    maxCount={screenType==="document"?1:10}
                    beforeUpload={file => {
                        if (!isPDF(file)) {
                          message.error('You can only upload PDF files!');
                          return Upload.LIST_IGNORE; // Prevent file from being uploaded
                        }
                        // return true; // Allow file upload
                      }}
                    showUploadList={true}  
                    fileList={fileList}                
                    onChange={(info)=>{   
                      console.log("raju",info)              
                         setFileList(info?.fileList?.filter(file => isPDF(file)));
                    }}
                    itemRender={(existingCompo,file)=><div className={classes.itemRender}>  
                    <div >
                        <img src={render_doc_icon}/> 
                        <div>
                              {/* <span>{file?.name}</span> */}
                              <Typography.Text style={{width:"13rem"}} ellipsis={{tooltip:true}}>{file?.name}</Typography.Text>
                              <span>{parseInt(file?.size/1000)+" KB"}</span>
                        </div>                                            
                    </div>              
                       <DeleteOutlined style={{color:"grey",fontSize:"16px"}} onClick={()=>{
                        setFileList(fileList?.filter(item=>item.uid!==file?.uid))
                       }}/>
                    </div>}            
                    >
                    <div style={{textAlign:"center",display:"flex",flexDirection:"column",flexWrap:"nowrap",alignItems:"center"}}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                {(screenType==="health insurance"&&fileList?.length>0)?
                <span style={{color:"#0086ff",fontWeight:"600",fontSize:"1rem"}}>Add more file(s)</span>
                :<p className="ant-upload-text" style={{color:"#ABABAB",fontSize:"0.8rem"}}>
                  Click or drag file to this area to upload
                </p>}
               </div>
                    </Upload.Dragger>
                </Form.Item>
                <div className={classes.buttonDiv}>
                <Button
                loading={loader}
                 htmlType="submit"             
                   type="primary" style={{padding:"0 2rem", height:"2.4rem"}}>Save</Button>
                <ModalSecondaryButton onCancel={()=>{
                     uploadModalHandler(false)
                     setFileList([]);

                }
                   
                    }>Cancel</ModalSecondaryButton>
            </div>
           </Form>}

            
            
            </div>}

        </Modal>
    )

}
export default UploadModal;