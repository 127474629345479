

import React, { useEffect, useState } from 'react';
import { Select, Divider } from 'antd';
import user_blue_icon from '../../../assets/user_blue_icon.svg';
import addteam_icon from '../../../assets/addteam_icon.svg';
import { ModalPrimaryButton } from '../../../components/Button/Button';
import {
  getAllManager,
  getDepartment,
  migrateTeamApi,
  getAllTeamsData
} from './Actions';
import Api from '../../../services/Api';
import { useWindowDimensions } from '../../../components/windowDimention/windowDimension';

const MigrateManager = () => {
  const Option = Select.Option;
  const [dept, setDept] = useState([]);
  const [allmanager, setAllmanager] = useState([]);
  const [newManager, SetManager] = useState([]);
  const [team, setTeam] = useState([]);
  const { width } = useWindowDimensions();
  const [ids, setIds] = useState({
    deptId: '',
    oldManId: '',
    newManId: '',
    teamId: []
  });
  const [ref, setref] = useState(false)
  const refresh = () => window.location.reload(true)
  useEffect(() => {
    getDepartment(Api, (res) => {
      setDept(res);
    });
  }, [ref]);
  function checkKeysFilled(obj) {
    const { deptId, oldManId, newManId, teamId } = obj;

    if (deptId !== '' && oldManId !== '' && newManId !== '' && teamId.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  const clearSelectedItems = () => {
    setIds({
      deptId: '',
      oldManId: '',
      newManId: '',
      teamId: []
    });
    setAllmanager([]);
    SetManager([]);
    setTeam([]);
  };




  return (
    <>
      <div>
        <div style={{ marginTop: 20, marginLeft: 20 }}>
          {
            width < 700 ?
              <h4 style={{ fontWeight: 500 }}>Migrate Manager</h4>
              :
              <h2 >Migrate Manager</h2>
          }
        </div>
        <Divider />

      </div>
      <div style={{ padding: "10px 10px 0px 10px", width: width < 700 ? "100%" : "60%" }}>
        <div>
          <span style={{ fontWeight: 450, fontSize: width < 700 ? 12 : 15, color: '#878787' }}>
            Select Department
          </span>
        </div>
        <Select
          className='m-t-10'
          style={{ width: '100%' }}
          placeholder='Select'
          size='large'
          // allowClear={true}
          // value={s.dep}
          onChange={(event, option) => {
            // console.log('jjjjflmfmfm,fm,fmmfm ',option.children);
            // s.dep=option.children;
            clearSelectedItems();
            setIds({ ...ids, deptId: event });
            getAllManager(event, Api, (res) => {
              setAllmanager(res);
            });
          }}
        >
          {dept?.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
        <div className='m-t-20'>
          <span style={{ fontWeight: 450, fontSize: width < 700 ? 12 : 15, color: '#878787' }}>
            Select Old Manager</span>

        </div>
        <Select

          className='m-t-10' style={{ width: "100%" }} placeholder="Select" size='large' allowClear={true}
          // value={}
          onChange={(event) => {

            setIds({
              ...ids,
              oldManId: "",
              newManId: "",
              teamId: []
            })
            setIds({ ...ids, oldManId: event })
            SetManager([])
            setTeam([])
            getAllTeamsData(event, Api, (res) => { setTeam(res) }, { deptId: ids?.deptId });

          }}
        >
          {allmanager?.map((item, index) => (
            <Option value={item.viewId}>{item.name}</Option>
          ))}
        </Select>
        <div className='m-t-20'>

          <span style={{ fontWeight: 450, fontSize: width < 700 ? 12 : 15, color: '#878787' }}>

            Select Teams</span>
        </div>
        <Select

          className='m-t-10' style={{ width: "100%" }} placeholder="Select" size='large' mode='multiple' showSearch={false}/* allowClear={true} */ onChange={(event) => {
            setIds({
              ...ids,
              newManId: "",
              teamId: []
            })
            setIds({ ...ids, teamId: event })
            SetManager([])
            getAllManager(null, Api, (res) => { SetManager(res) }, true);

          }}>
          {team?.map((item, index) => (
            <Option value={item.id}>{item.name}</Option>
          ))}
        </Select>
        <div className='m-t-20'>
          <span style={{ fontWeight: 450, fontSize: width < 700 ? 12 : 15, color: '#878787' }}>

            Select New Manager</span>
        </div>
        <Select className='m-t-10' style={{ width: "100%" }} placeholder="Select" allowClear={true} size='large' onChange={(event) => {
          setIds({
            ...ids,
            newManId: "",
          })

          setIds({ ...ids, newManId: event })
        }}>
          {newManager?.map((item, index) => (
            <Option value={item.id}>{item.name}</Option>
          ))}
        </Select>
        <div>

        </div>
        <ModalPrimaryButton
          disabled={!checkKeysFilled(ids)}
          style={{ width: '10rem', margin: '2rem auto', backgroundColor: '#0086FF', borderRadius: 3 }}
          onSave={() => {
            migrateTeamApi(ids.oldManId, ids.newManId, ids.teamId, Api, (res) => {
              //   clearSelectedItems();
              if (res) {
                setTimeout(() => {
                  refresh()
                }, 2500);
              }
            });
          }}
        >
          Migrate
        </ModalPrimaryButton>
      </div>

    </>

  );
};

export default MigrateManager;
