import { Checkbox, Spin , Tooltip, Popconfirm,Button, Progress, Dropdown} from "antd";
import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import deleteIcon from "../../../assets/deleteButton.svg";
import editIcon from "../../../assets/editButton.svg";
import transferIcon from "../../../assets/defaultbutton.svg";
import logefforticon from "../../../assets/logefforticon.svg"

import FilterIcon from "../../../assets/headingicons.svg"
// import LineProgressBar from "../../../components/LineProgessBar/LineProgress";
import moment from "moment";
import useApiStore from "../../../services/store";
import classes from "./Project.module.css";
import "./comp.css";
import CircleProgressBar from "../../../components/CircleProgressBar/CircleProgressBar";
import viewClass from "../../../services/ViewRole";
import ButtonCompo from "../../../design/ButonCompo";
import { NoData } from "../../Training_essentialDocuments";
import TableCustom from "../../../components/tableCustom/customTable";
import { useEffect, useState } from 'react';
import "../Projects/Checkbox.module.css";
import DropdownButton from "./Dropdownbutton";
import {useFlagButtonStore, useManageTasks} from "../Release/store";
import { getAllUserTaskApi } from "./ProjectAction";
import MultiSelectFilter from "./MultiSelectFilter";


function TaskTableRelease({ data, dataProject,onEdit, onDelete, onTransfer, onLogChange }) {


    const {flagButton,
        setFlagButton,
        cancelFlag,
        checkedUserIdsList,
        setCheckedUserIdsList,
        filtersList,
        setFiltersList,
        allTask,
        setAllTask,
        allTeamNames,
        setAllTeamNames,
        allProjectNames,
        checkedIdsList,
        setCheckedIdsList,
        setAllProjectNames,
        setCancelFlag,
        overallStatus,
        setEnableTransfer,
        tasksTeamId,
        setPageMeta,
        pageMeta,
        allAssociateNames,
        selectedTeamFilters,
        selectedAssociateFilters,
        

    } = useFlagButtonStore(state=>state);





    const [loader] = useApiStore(state => [state.loader])
    const [checkedList, setCheckedList]  = useState([]);
    const [userIdCheckedList, setUserIdCheckedList] = useState([]);
    const [checkedStatusList,setCheckedStatusList] = useState([]);
    const [specialChecked,setSpecialChecked] = useState(false);
    const [releaseType,setReleaseType] = useState("managerRelease");
    const [selectedProjectOptions, setSelectedProjectOptions] = useState([]);
    const [selectedTeamOptions, setSelectedTeamOptions] = useState(selectedTeamFilters);
    const [selectedStatusOptions, setSelectedStatusOptions] = useState([]);
    const [associateOptions, setAssociateOptions] = useState(selectedAssociateFilters);
  // const [singleDeleteModal, setSingleDeleteModal]=useState(false);





    const {manageTasks} = useManageTasks(state=>state);

    //console.log(dataProject, "PROJECTNAMESCHECK");
    //const filteredProjectNamesData = dataProject?.filter(item => item?.projects); // Example filter
    const projectNameOptions = allProjectNames?.map(item => ({
        label: item?.project,
        value: `${item?.id}`,
    }));



    console.log(projectNameOptions,"PROJECTNAMES");


    const associateNameOptions = allAssociateNames?.map(item =>({
        label: item?.name,
        value: `${item?.id}`,
    }))

    //const filteredTeamNamesData = data?.filter(item => item?.teamName); // Example filter
    const teamNameOptions = allTeamNames?.map(item => ({
        label: item?.name,
        value: `${item?.id}`,
    }));
   console.log(teamNameOptions, allTeamNames,"TEAMNAMES");
  
    const statusActiveOptions=[
        {
            value: 'New',
            label: 'New',
        },
        {
            value: 'Ongoing',
            label: 'Ongoing',
        },
        {
            value: 'Over Due',
            label: 'Over Due',
        },
        
        ]

    const statusInactiveOptions = [{
        value: 'Completed',
        label: 'Completed',
    },
    {
        value: 'On Hold',
        label: 'On Hold',
    },
    {
        value: 'Suspended',
        label: 'Suspended',
    },]
    const statusOptions = [{
        value: 'New',
        label: 'New',
    },
    {
        value: 'Ongoing',
        label: 'Ongoing',
    },
    {
        value: 'Over Due',
        label: 'Over Due',
    },
    {
        value: 'Completed',
        label: 'Completed',
    },
    {
        value: 'On Hold',
        label: 'On Hold',
    },
    {
        value: 'Suspended',
        label: 'Suspended',
    }

    ]

    // const handleProjectNameCheckboxChange = (checkedValues) => {
        
    //     setSelectedProjectOptions(checkedValues);
    // };
    // const handleTeamNameCheckboxChange = (checkedValues) => {
    //     setSelectedTeamOptions(checkedValues);
    // };
    const handleStatusNameCheckboxChange = (checkedValues) => {
        console.log(checkedValues,"STATUS");
        setSelectedStatusOptions(checkedValues);
    };

    useEffect(()=>{
        //handleCheckedList(checkedList);
        setCheckedIdsList(checkedList);
        setCheckedUserIdsList(userIdCheckedList);
        if(checkedList.length > 0){
            console.log("CC",checkedList.length);
            //setFlag(true);
            setFlagButton(true);
        }
        else{
            //setFlag(false);
            setFlagButton(false);
        }
    },[checkedList]);

   

    const handleConfirm = (a,b,c,d)=>{
        setPageMeta({currentPage: 1,itemsPerPage: 10})
        const objList = {
            "status": a?.join(","),
            "projectId": b?.join(","),
            "teamId" : c?.join(","),
            "associateId": d?.join(","),
        };

        setFiltersList(objList);
    }
    useEffect(()=>{
        
        handleConfirm(selectedStatusOptions,selectedProjectOptions,selectedTeamOptions,associateOptions);
        //console.log("reload",selectedTeamOptions)
    },[selectedProjectOptions,selectedTeamOptions, associateOptions])

  

    useEffect(()=>{
        if(selectedTeamOptions?.length===1){
            setEnableTransfer(true);
        }
        else{
            setEnableTransfer(false)
        }
    },[selectedTeamOptions])

    
    


    const enabledDataIds = data?.filter(item => item?.isManager == true && viewClass.manager).map(item =>item?.id) ;
    const enabledUserDataIds = data?.filter(item => item?.isManager == true && viewClass.manager).map(item => item?.userId);

    //const checkedAll = data?.length === checkedList.length && data?.length!==0;
    const checkedAll = enabledDataIds?.length === checkedList.length && enabledDataIds.length!==0;

    const taskDataIds = data?.map(item => item?.id);
    const userDataIdsChecked = data?.map(item => item?.userId);
    console.log(taskDataIds,"CHECKBOXCHECK");
    console.log(userDataIdsChecked,"CHECKBOCHECK");

    const indeterminate = checkedList.length > 0 && checkedList.length < enabledDataIds?.length;
    

    useEffect(()=>{
       
        if(cancelFlag){
            
            setCheckedList([]);//wheneve checkedList changes it sets checkedidslist globally
            setUserIdCheckedList([]);
            setCancelFlag(false);
        }
        

    },[cancelFlag]);

    useEffect(()=>{
        console.log(pageMeta,"CHECKRENDER2")
        getAllUserTaskApi(res=>{
            console.log(pageMeta,"CHECKRENDER2")
            if(res){
                setAllProjectNames(res?.projects);
                setPageMeta(res?.meta);
                console.log(pageMeta,"CHECKRENDER2")
            }
        })
    },[manageTasks])

    const onCheckAllChange = (e) => {
        //setCheckedList(e.target.checked ? data?.length : []);
        const isChecked = e.target.checked;
        setSpecialChecked(isChecked);
        
    
        if (isChecked) {
          // Check all normal checkboxes
          //setCheckedList(taskDataIds);
          setCheckedList(enabledDataIds);
          //setUserIdCheckedList(userDataIdsChecked);
          setUserIdCheckedList(enabledUserDataIds);
        

        } 
        
        else {
          // Uncheck all normal checkboxes
          setCheckedList([]);
          setUserIdCheckedList([]);
        }
    };

    const onCheckChange = (e,id,status,userId)=>{
        console.log(checkedList,"CHECKBOXCHECK");
        const isChecked = e.target.checked;

        if (isChecked) {
        // If checked, add the id to the checkedList
        setCheckedList([...checkedList, id]);
        setUserIdCheckedList([...userIdCheckedList, userId]);
        setCheckedStatusList([...checkedStatusList, status]);
        } else {
        // If unchecked, remove the id from the checkedList
        setCheckedList(checkedList.filter(item => item !== id));
        setUserIdCheckedList(userIdCheckedList.filter(item => item !== userId));
        setCheckedStatusList(checkedStatusList.filter(item => item !== status));
        }
    };


    const editHandler = (data) => {
        onEdit(data);
    }
    const deleteHandler = (data) => {
        //setSingleDeleteModal(true);
        onDelete({ id: data?.id, name: data?.name });
    }

    const transferHandler = (data) => {
        console.log(data,"TRANSFERCHECK")
        onTransfer({id:data?.id, userId: data?.userId});
    }
    const newData = data?.map(element => ({
        
        ...element,
        prog: (
            <>
                <div>
                    <CircleProgressBar strokeWidth={5} sqSize={50} percentage={element?.progress ? element?.progress : 0} />
                </div>
            </>
        ),
        loge:(
            <>
            {((element?.logEffort))?<ButtonCompo onClick={()=>{onLogChange({id:element?.id,name:element?.name,task:element})}} title={"Log Effort"} style={{color:'#fff',backgroundColor:'#0086ff'}} />:null  }
            </>
        ),
        other:
        (releaseType=='managerRelease' && viewClass.manager&&element?.isManager)?
        (
            <>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'8rem', gap:"2px"}} >
                    {/* {(viewClass.manager&&element?.isManager) ? */}
                    <Tooltip title='Edit'>
                        <img src={editIcon} onClick={()=>{editHandler(element);}} style={{height:'30px',width:'30px',cursor:'pointer'}}></img>
                    </Tooltip>
                    {/* :null} */}
                    {/* {(viewClass.manager&&element?.isManager)? */}
                    <Tooltip title='Transfer'>
                        <img src={transferIcon} onClick={()=>{transferHandler(element);}} style={{height:'30px',width:'30px',cursor:'pointer'}}></img>
                    </Tooltip>
                    {/* :null} */}
                    {/* {(viewClass.manager&&element?.isManager)? */}
                    <Tooltip title="Delete">
                        <img src={deleteIcon} onClick={()=>{deleteHandler(element);}} style={{height:'30px',width:'30px',cursor:'pointer'}}></img>
                    </Tooltip>
                    {/* :null} */}
                </div>
            </>
        )
        :  (releaseType=='associateRelease' && viewClass.manager)?<>
    <p>hi</p>
        </>:null
    }));
    console.log(data, "dataaaaaaatat");
    const tableColumn = {
        0: {
            cols: "Name",
            width: "30%",
            dataKey: "name",
            onclick: () => { }
        },
        1: {
            cols: "Associate",
            width: "30%",
            dataKey: "associate",
            onclick: () => { }
        },
        2: {
            cols: "Team",
            width: "30%",
            //   innerWidth:"7rem",
            dataKey: "teamName",
            onclick: () => { }
        },
        3: {
            cols: "Start Date",
            color: "#39CA6C",
            width: "25%",
            dataKey: "startDate",
            onclick: () => { }
        },
        4: {
            cols: "Due Date",
            width: "25%",
            color: "#FF3434",
            dataKey: "dueDate",
            onclick: () => { }
        },
        5: {
            cols: "Status",
            width: "20%",
            dataKey: "status",
            onclick: () => { }
        },
        6: {
            cols: "Progress",
            width: "0%",
            dataKey: "prog",
            onclick: () => { }
        },
        7: {
            cols: "",
            width: "50%",
            dataKey: "other",
            onclick: () => { }
        },
        
        8: {
            cols: "",
            width: "10%",
            dataKey: "loge",
            onclick: () => { }
        },
        9:{
            cols: "Actions",
            width: "10%",
            dataKey: "Action",
            onclick: () => { }
        }
    }

    return (
        <>
           {/*  <TableCustom columns={tableColumn} dataSource={newData} loading={loader}
            /> */}
             {loader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><Spin /></div>:

        <div style={{displa:'flex',flexDirection:'column',justifyContent:'left',alignItems:'left'}}>
                <div style={{width:'100%',minWidth:'1000px', overflowX:'scroll',height:'5vh',display:'flex',alignItems:'center',gap:'1.5rem',backgroundColor:'#CCCCCC',paddingLeft:'1rem'}}>
                {/* {(viewClass.manager)? <div ><Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkedAll} style={{fontFamily:'Roboto', color:'#555555', fontSize:'14px'}}>S.No</Checkbox></div>:null} */}
                <div style={{width:'5%'}}>
                    <Checkbox
                        indeterminate={indeterminate}
                        onChange={onCheckAllChange}
                        checked={checkedAll}
                        disabled={!viewClass.manager || !(data?.filter((item)=>item.isManager === true).length > 0)} // Disable checkbox when viewClass.manager is false
                        style={{
                        fontFamily: 'Roboto',
                        color: (viewClass.manager && data?.filter((item)=>item.isManager === true).length >0) ? '#555555' : 'rgba(85, 85, 85, 0.5)', // Change color when disabled
                        fontSize: '14px',
                        cursor: (viewClass.manager && data?.filter((item)=>item.isManager === true).length >0) ? 'pointer' : 'not-allowed',
                        }}
                    >
                        S.No
                    </Checkbox>
                </div>
                <div style={{fontFamily:'Roboto', color:'#555555', fontSize:'14px', width:'10%'}}>Task Name</div>
                <div style={{fontFamily:'Roboto', color:'#555555', fontSize:'14px', alignItems:'center', display:'flex', flexDirection:'row',gap:'2%', width:'10%'}}>
                    <p>Associate</p>
                    {(viewClass.manager|| viewClass.hod || viewClass.superAdmin )?
                    <MultiSelectFilter options={associateNameOptions} filtersListHandlerFunction={(value)=>{setAssociateOptions(value)}} initialKey={"Associate"} />:null}
                </div>
                <div style={{fontFamily:'Roboto', color:'#555555', fontSize:'14px', alignItems:'center', display:'flex', flexDirection:'row',gap:'2%', width:'10%'}}>
                    <p>Project</p> 
                    {/* <Popconfirm
                        
                        description={
                           
                            <Checkbox.Group
                              options={projectNameOptions}
                              value={selectedProjectOptions}
                              onChange={handleProjectNameCheckboxChange}
                              style={{
                                maxHeight: '150px', // Set a fixed height for the scroll area
                                overflowY: 'auto', // Enable vertical scrollbar when content overflows
                                //overflowX: 'hidden', // Hide the horizontal scrollbar
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            />
                          
                          }
                          icon={null}
                          okText="Confirm"  // Customize OK button text
                          cancelText="Reset"  // Customize Cancel button text
                          onConfirm={handleConfirm}  // Handle OK click
                          onCancel={()=>{
                            setSelectedProjectOptions([]);
                            handleConfirm();
                          }}  // Handle Cancel click
                        onOpenChange={() => console.log('open change')}
                        >
                        <img src={FilterIcon} alt="FilterIcon"/>
                    </Popconfirm> */}
                   
                    <MultiSelectFilter options={projectNameOptions} filtersListHandlerFunction={(value)=>{setSelectedProjectOptions(value)}} initialKey={"Project"} />
                </div>
                <div style={{fontFamily:'Roboto', color:'#555555', fontSize:'14px', alignItems:'center', display:'flex', flexDirection:'row',gap:'2%', width:'10%'}}>
                    <p>Team </p> 
                    {/* <Popconfirm
                        
                        description={
                            <Checkbox.Group
                              options={teamNameOptions}
                              value={selectedTeamOptions}
                              onChange={handleTeamNameCheckboxChange}
                            />
                          }
                          icon={null}
                        
                        onOpenChange={() => console.log('open change')}
                        okText="Confirm"  // Customize OK button text
                          cancelText="Reset"  // Customize Cancel button text
                          onConfirm={handleConfirm}  // Handle OK click
                          onCancel={()=>{
                            setSelectedTeamOptions([]);
                            handleConfirm();
                          }}  // Handle Cancel click
                        >
                       
                        <img src={FilterIcon} alt="FilterIcon"/>
                    </Popconfirm> */}
                
                    <MultiSelectFilter options={teamNameOptions} filtersListHandlerFunction={(value)=>{setSelectedTeamOptions(value)}} initialKey={"Team"} />
                </div>
                <div style={{fontFamily:'Roboto', color:'#555555', fontSize:'14px', width:'10%'}}> Start Date</div>
                <div style={{fontFamily:'Roboto', color:'#555555', fontSize:'14px', width:'10%'}}>Due Date</div>
                <div style={{fontFamily:'Roboto', color:'#555555', fontSize:'14px', alignItems:'center', display:'flex', flexDirection:'row',gap:'2%', width:'10%'}}>
                    <p>Status</p> 
                  
                    <Popconfirm
                        
                        description={
                            <div
                            style={{
                              maxHeight: '150px', // Set a fixed height for the scroll area
                              overflowY: 'auto', // Enable vertical scrollbar when content overflows
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            
                            <Checkbox.Group
                              options={overallStatus==="Active"?statusActiveOptions: overallStatus==="All"? statusOptions:statusInactiveOptions}
                              value={selectedStatusOptions}
                              onChange={handleStatusNameCheckboxChange}
                              style={{
                                maxHeight: '150px', // Set a fixed height for the scroll area
                                overflowY: 'auto', // Enable vertical scrollbar when content overflows
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            />
                            </div>
                          }
                          icon={null}
                          okText="Confirm"  // Customize OK button text
                          cancelText="Reset"  // Customize Cancel button text
                          onConfirm={()=>{
                            handleConfirm(selectedStatusOptions,selectedProjectOptions,selectedTeamOptions,associateOptions)
                          }}  // Handle OK click
                          onCancel={()=>{
                            setSelectedStatusOptions([])
                            handleConfirm([],selectedProjectOptions,selectedTeamOptions,associateOptions);
                          }}  // Handle Cancel click
                        
                        onOpenChange={() => console.log('open change')}
                        >
                        {/* <Button type="primary">Open Popconfirm with Promise</Button> */}
                        
                        <Button 
                            icon={<FilterFilled 
                                        style={{color:(selectedStatusOptions?.length>0)?'#000000':'#6A6A6A', 
                                                width:'12px',
                                                alignItems:'center'}}
                                  />
                                } 
                            style={{
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        boxShadow: 'none',
                                    }}>
                        </Button>
                    </Popconfirm>
                   
                    

                </div>
                <div style={{fontFamily:'Roboto', color:'#555555', fontSize:'14px', width:'10%'}}>Progress</div>
                {/* <div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',marginRight:'2.5rem',fontSize:'14px', fontWeight:'600',color:'#808080'}} >Actions</div>  || data?.filter((item)=>item.logEffort === true).length > 0*/}

                {
                    // ((viewClass.manager && data?.filter((item)=>item.isManager === true).length > 0)) && 
                    // (
                        <div style={{fontFamily:'Roboto', color:'#555555', fontSize:'14px',marginLeft:'0.75rem', marginRight:'0.75rem', width:'10%'}}>Actions</div>
                    //)
                }
                </div>
            {(data?.length===0 || data===undefined)?
            <NoData/>
        :

        <div style={{display:'flex',minWidth:'1000px', overflowX:'scroll',flexDirection:'column',backgroundColor:'#F6F6F6',height:window.innerHeight-330,overflowY:'scroll'}}>
        {data?.map((item,id)=>{
            const access = viewClass.manager && item?.isManager
            return(
                <>
            <div key={id} className={classes.ListConatiner}>
                {/* {(viewClass.manager)?
                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <div><Checkbox  checked={checkedList.includes(item?.id)} onChange={(e)=> onCheckChange(e,item?.id,item?.status,item?.userId)}>{id+1}.</Checkbox></div>
                        
                </div>:null} */}
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' ,width:'5%'}}>
                        <div>
                            <Checkbox
                            checked={checkedList.includes(item?.id)}
                            onChange={(e) => onCheckChange(e, item?.id, item?.status, item?.userId)}
                            disabled={!access} // Disable checkbox when viewClass.manager is false
                            style={{
                                fontFamily: 'Roboto',
                                color: access ? '#555555' : 'rgba(85, 85, 85, 0.5)', // Change color when disabled
                                fontSize: '14px',
                                cursor: access ? 'pointer' : 'not-allowed',
                            }}
                            >
                            {id + 1}.
                            </Checkbox>
                        </div>
                </div>

                
                <div style={{ maxHeight: '40px', overflowY: 'hidden',marginLeft:'25px' ,alignItems:'flex-start'}}> 
                    <Tooltip title={item?.name}>
                        <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'black',
                        whiteSpace: 'normal',  // Allow wrapping
                        overflow: 'hidden',    // Hide overflow content
                        textOverflow: 'ellipsis',  // Add ellipsis once the content overflows
                        display: '-webkit-box',   // Flex-like container
                        WebkitLineClamp: 2,       // Show only 2 lines
                        WebkitBoxOrient: 'vertical',  // Vertical orientation for ellipsis
                        maxWidth: '90%',  
                        }}>
                        {item?.name}
                        </div>
                    </Tooltip>
                </div>


              
                <div style={{ maxHeight: '40px', overflowY: 'hidden',marginLeft:'5px' ,alignItems:'flex-start' }}>
                   {/* {id===0 && (  )} */}
                   <Tooltip title={item?.associate}>

                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'black',
                        whiteSpace: 'normal',  // Allow wrapping
                        overflow: 'hidden',    // Hide overflow content
                        textOverflow: 'ellipsis',  // Add ellipsis once the content overflows
                        display: '-webkit-box',   // Flex-like container
                        WebkitLineClamp: 2,       // Show only 2 lines
                        WebkitBoxOrient: 'vertical',  // Vertical orientation for ellipsis
                        maxWidth:'90%'
                        }}>{item?.associate}</div>
                   </Tooltip>
                </div>

                <div style={{ maxHeight: '40px', overflowY: 'hidden',marginLeft:'25px' ,alignItems:'flex-start' }}>
                   {/* {id===0 && (  )} projectName*/}
                   <Tooltip title={item?.projectName}>

                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'black',
                        whiteSpace: 'normal',  // Allow wrapping
                        overflow: 'hidden',    // Hide overflow content
                        textOverflow: 'ellipsis',  // Add ellipsis once the content overflows
                        display: '-webkit-box',   // Flex-like container
                        WebkitLineClamp: 2,       // Show only 2 lines
                        WebkitBoxOrient: 'vertical',  // Vertical orientation for ellipsis
                        maxWidth:'90%'
                        }}>{item?.projectName}</div>
                   </Tooltip>
                </div>
                <div style={{ maxHeight: '40px', overflowY: 'hidden' ,marginLeft:'5px' ,alignItems:'flex-start'}}>
                   {/* {id===0 && (  )} */}
                   <Tooltip title={item?.teamName}>

                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'black',
                        whiteSpace: 'normal',  // Allow wrapping
                        overflow: 'hidden',    // Hide overflow content
                        textOverflow: 'ellipsis',  // Add ellipsis once the content overflows
                        display: '-webkit-box',   // Flex-like container
                        WebkitLineClamp: 2,       // Show only 2 lines
                        WebkitBoxOrient: 'vertical',  // Vertical orientation for ellipsis
                        maxWidth:'90%',
                        }}>{item?.teamName}</div>
                   </Tooltip>
                </div>
                <div style={{alignItems:'center',overflowY: 'hidden' ,marginLeft:'5px' ,alignItems:'center'}}>
                   {/* {id===0 && (  )} */}
                    <div style={{color:'#161616'}}>{moment(item?.startDate).format('DD-MMM-YYYY')}</div>
                </div>
                <div style={{alignItems:'center',overflowY: 'hidden' ,marginLeft:'5px' ,alignItems:'center'}}>
                   {/* {id===0 && (  )} */}
                    <div style={{color:'#161616'}}>{moment(item?.dueDate).format("DD-MMM-YYYY")}</div>
                </div>
                <div style={{alignItems:'center' ,marginLeft:'5px' ,alignItems:'center'}}>
                    {/* {id===0 && ( )} */}
                    {/* <div className={item?.status}>{item?.status}</div> i have to send associate Id from item*/}
                    {(access)?<DropdownButton initialStatus={item?.status} associateId={item?.id} overallStatus={overallStatus}/>
                    :   <div 
                            className={item?.status} 
                            //style={{color:'#161616'}}
                        >
                            {item?.status}
                        </div>

                    }
                </div>
                <div style={{alignItems:'center'}}>
               {/* {id===0 && (  )}strokeWidth={5} sqSize={50}  */}
                    <div style={{width:'90%', marginLeft:'20px'}}>
                    <Progress status="active" size="small" percent={item?.progress} 
                    strokeColor="#1089FF"
                     trailColor="#f0f0f0"
                    />
                    </div>
                </div>
                {


                    //((viewClass.manager&&item?.isManager) ?
                    //|| (item?.logEffort)) && (
                
                        <div style={{display:'flex',justifyContent:'space-evenly',alignItems:'center',gap:'0.1rem', flexDirection:'row', marginRight:'0px',marginLeft:'1rem'}} >
                            {(viewClass.manager || viewClass.hod || viewClass.superAdmin) && (
                                <>
                                <img 
                                    src={transferIcon} 
                                    onClick={() => {
                                        if (checkedList?.length === 0) {
                                        transferHandler(item);
                                        }
                                    }}
                                    style={{
                                        height: '24px',
                                        width: '24px',
                                        cursor: access && checkedList.length === 0 ? 'pointer' : 'not-allowed',
                                        opacity: access ? (checkedList.length > 0 ? 0.5 : 1) : 0.5,
                                        pointerEvents: access ? (checkedList.length > 0 ? 'none' : 'auto') : 'none',
                                    }}
                                >
                                </img>
                        
                        
                                <img 
                                    src={editIcon} 
                                    onClick={() => {
                                        if (access && checkedList.length === 0) {
                                        editHandler(item);
                                        }
                                    }}
                                    style={{
                                        height: '24px',
                                        width: '24px',
                                        cursor: access && checkedList.length === 0 ? 'pointer' : 'not-allowed',
                                        opacity: access ? (checkedList.length > 0 ? 0.5 : 1) : 0.5,
                                        pointerEvents: access ? (checkedList.length > 0 ? 'none' : 'auto') : 'none',
                                    }}
                                >
                                </img>
                    
                                <img
                                    src={deleteIcon}
                                    onClick={() => {
                                    if (access && checkedList.length === 0) {
                                        deleteHandler(item);
                                    }
                                    }}
                                    style={{
                                    height: '24px',
                                    width: '24px',
                                    cursor: access && checkedList.length === 0 ? 'pointer' : 'not-allowed',
                                    opacity: access ? (checkedList.length > 0 ? 0.5 : 1) : 0.5,
                                    pointerEvents: access ? (checkedList.length > 0 ? 'none' : 'auto') : 'none',
                                }}
                                />
                                </>
                            )}
                     
                        {/* {((!viewClass.manager&&!viewClass.superAdmin&&!viewClass.hod&&item?.webAccess))?<ButtonCompo onClick={()=>{onLogChange({id:item?.id,name:item?.name,task:item})}} title={"Log Effort"} style={{color:'#fff',backgroundColor:'#0086ff'}} />:null  }  */}
                        {/* {true?<ButtonCompo onClick={()=>{onLogChange({id:item?.id,name:item?.name,task:item})}} title={"Log Effort"} style={{color:'#fff',backgroundColor:'#0086ff'}} />:null  } */}
                   
                
                            {(item?.webAccess && item?.logEffort) ?
                                <Tooltip title='Log Effort'>

                                    <div style={{ cursor: "pointer",paddingTop:'5px' }} onClick={(e) => { onLogChange({ id: item?.id, name: item?.name, task: item}) }}>
                                        <img src={logefforticon} style={{ height: 25, width: 25 }} />
                                    </div>
                                </Tooltip>:null}
                        </div> 
                    
                    
                }
            </div>
                </>
            )
        })}
          
            </div>}
        </div>}
        </>
    )
}
export default TaskTableRelease;

    // <Table
    //     columns={taskColumn}
    //     dataSource={data}
    //     loading={loader}
    //     scroll={{
    //     y:"500px",
    //     }}
    //     />