import { Select, Spin, Divider, Row, Col, Pagination } from "antd";
import ButtonCompo from "../../../design/ButonCompo";
// import deleteIcon from "../../../assets/DeleteIcon.svg";
// import approveIcon from "../../../assets/ApproveIcon.svg";
import downloadIcon from "../../../assets/download_Icon_black.svg";
import classes from "./Project.module.css";
import TaskOverview from "./TaskOverview";
import ProjectSummary from "./ProjectSummary";
import { GridListView } from "../../Homepage/components/gridListView";
import { useEffect, useState } from "react";
import SearchCompo from "../../../design/SearchCompo";
import TaskCards from "./TaskCards";
import TaskTable from "./TaskTable";
import AddTask from "./AddTask";
import DeleteModal from "./DeleteModal";
import TransferTask from "./TransferTask";
import moment from "moment";
import { getAllTaskApi, getAllTeamMember } from "./ProjectAction";
// import { getTeamApi } from "../Team/Action";
import useApiStore from "../../../services/store";
import viewClass from "../../../services/ViewRole";
import { AiOutlineLeft } from "react-icons/ai";
import LogEffort from "./LogEffort";
import { useNavigate } from "react-router";
import { onBackPress } from "./Projects";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import { useProjecteStore } from "../store/store";


function ProjectDetails({ data, back, role }) {
    const Navigate = useNavigate();
    const [search, setSearch] = useState('');
    const taskFilter = ["All", "Myself", "Others"];
    const [view, setView] = useState('grid');
    const [taskStatus, setTaskStatus] = useState("Active");
    const [allTask, setAllTask] = useState()
    const [taskModal, setTaskModal] = useState();
    const [deleteTask, setDeleteTask] = useState();
    const [transferTask, setTransferTask] = useState();
    const [logEffort, setLogEffort] = useState();
    const [userList, setUserList] = useState();
    // const [teamLead,setTeamLead]=useState([]);
    const Tab = ["Project Details", "Task Details"];
    const [activeTab, setActiveTab] = useState(0);
    const { loader, setLoader } = useApiStore(state => state)
    const [filter, setFilter] = useState('All');
    const { projectClickedData } = useProjecteStore(state => state)
    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(1);
    const [pageMeta, setPageMeta] = useState()
    const { width } = useWindowDimensions();
    const downloadHandler = () => {
        let param = { search: search, projectId: data?.projectId, download: true, filter: filter }
        if (taskStatus !== undefined && taskStatus !== "All") {
            param = { ...param, status: taskStatus }
        }
        getAllTaskApi(param, (res) => {
            console.log(res)
            window.open(res?.buffer, "_blank").focus();
        })
    }
    // const TeamLeadHandler=(type)=>{
    //     if(type==="save"){
    //         let param={projectId:data?.projectId,memberId:teamLead}
    //         setTeamLeadApi(param,(res)=>{
    //             if(res){
    //                 getTeamLead();
    //             }
    //         })

    //     }
    //     else if(type==="reset"){
    //         resetTeamLeadApi({projectId:data?.projectId},(res)=>{
    //             if(res){
    //                 getTeamLead();
    //             }
    //         })
    //     }
    // }
    console.log(role,"ROLECHECK1");
    const TaskEditHandler = (data) => {
        console.log("edit Clicked", data);
        setTaskModal({ type: 'edit', data: data });
    }
    const TaskDeleteHandler = (data) => {
        console.log("delete click", data);
        setDeleteTask({ type: "task", id: data?.id, name: data?.name });
    }
    const TaskTransferHandler = (data) => {
        console.log("transfer clicked", data);
        setTransferTask(data);
    }
    const LogEffortHandler = (data) => {
        setLogEffort(data);
    }
    const statusList = ['All', "Active", 'New', 'Ongoing', "Over Due", "Completed", "On Hold", "Suspended"]
    // const getTeamLead=()=>{
    //     getTeamLeadApi( {projectId:data?.projectId},(res)=>{
    //         setTeamLead(res);
    //     })
    // }
    useEffect(() => {
        let param = { projectId: data?.projectId }
        getAllTeamMember(param, (res) => {
            console.log(res);
            setUserList(res);
            // getTeamLead();
        })
    }, [])
    const refreshTask = () => {
        let param = {
            search: search, projectId: data?.projectId, filter: filter, page: pageCurrent,
            status:(taskStatus !== undefined && taskStatus !== "All")? taskStatus:null,
            limit: pageSize
        }
       /*  if (taskStatus !== undefined && taskStatus !== "All") {
            param = { ...param, status: taskStatus }
        } */
        getAllTaskApi(param, (res) => {
            setAllTask(res?.data);
            setPageMeta(res?.meta)
        })
    }

    useEffect(() => {
        let param = {
            search: search, projectId: data?.projectId, filter: filter, page: pageCurrent,
            limit: pageSize
        }
        if (taskStatus !== undefined && taskStatus !== "All") {
            param = { ...param, status: taskStatus }
        }
        getAllTaskApi(param, (res) => {
            setAllTask(res?.data);
            setPageMeta(res?.meta)
        })
    }, [search, taskStatus, filter, pageCurrent, pageSize])

    const handlePageApproveChange = (page, pageSize) => {
        setPageCurrent(page);
        setPageSize(pageSize)
    }
    return (
        <>
            {
                width < 700 ?
                    <>
                        <div style={{ display: 'flex', cursor: "pointer" }}>
                            <AiOutlineLeft onClick={() => {
                                if (data?.navigationLocation === 'resourceDash') {
                                    console.log('navigate to -1')
                                    Navigate(-1);
                                }
                                onBackPress();
                                back();
                            }} size={20} />
                            <div onClick={() => {
                                if (data?.navigationLocation === 'resourceDash') {
                                    console.log('navigate to -1')
                                    Navigate(-1);
                                }
                                onBackPress();
                                back();
                            }} style={{ cursor: "pointer", fontSize: "1.1rem", fontWeight: "600", marginLeft: 10, display: "flex", alignItems: "center", justifyContent: "center" }}>{data?.projectName}</div>
                        </div>
                        {
                            activeTab === 1 ?
                                <>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: "16px", padding: 10, width: "100%" }}>
                                        <SearchCompo setSearch={setSearch} style={{ width: '100%' }}></SearchCompo>


                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                        <div className={classes.title2}>Task Status</div>
                                        <Select style={{ width: "110px" }} allowClear  value={taskStatus} onChange={(val) => { setTaskStatus(val===undefined?"Active":val) }}>
                                            {statusList?.map((val) => {
                                                return (
                                                    <Select.Option key={val}>{val}</Select.Option>
                                                )
                                            })}
                                        </Select>
                                        {(viewClass.manager && projectClickedData?.isManager) && (<ButtonCompo title={"Add Task"} onClick={() => { setTaskModal({ type: 'add', id: data?.projectId, userList: userList }) }} style={{ backgroundColor: '#0086ff', color: '#fff', width: "40%" }} />)}
                                    </div>


                                </>
                                :
                                <>
                                </>
                        }
                        <div className={classes.tabContainer} style={{ width: "100%", justifyContent: "space-evenly", padding: 10 }}>
                            {Tab?.map((val, id) => {
                                console.log(id,"jjjlkkklll");
                                return (
                                    <div className={activeTab === id ? classes.activeTab : ''} key={id} onClick={() => {
                                        setActiveTab(id);
                                        if(id==1){
                                            setSearch('')
                                        }
                                        setLoader(true);
                                        setTimeout(() => { setLoader(false) }, 500)
                                    }}>{val}</div>
                                )
                            })}
                        </div>
                        <Divider />



                        {activeTab === 0 ? loader ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}><Spin /></div> : <>
                            <div>
                                {
                                    [(<>
                                        <div className={classes.projectDetailsMain} style={{ marginTop: '2rem', border: "1px solid #DFDFDF" }}>
                                            <div>
                                                <div className={classes.title1}>Created By</div>
                                                <div className={classes.title1}>Manager</div>
                                                <div className={classes.title1}>Department</div>
                                            </div>
                                            <div>
                                                <div className={classes.title2}>{data?.createdBy?data?.createdBy:"NA"}</div>
                                                <div className={classes.title2}>{data?.managerName?data?.managerName:"NA"}</div>
                                                <div className={classes.title2}>{data?.departmentName?data?.departmentName:"NA"}</div>
                                            </div>
                                        </div>
                                    </>),
                                    (<>
                                        <div className={classes.projectDetailsMain} style={{ marginTop: '2rem', border: "1px solid #DFDFDF" }}>
                                            <div>
                                                <div className={classes.title1}>Team</div>
                                                <div className={classes.title1}>Started On</div>
                                                <div className={classes.title1}>Status</div>
                                            </div>
                                            <div>
                                                <div className={classes.title2}>{data?.teamName?data?.teamName:"NA"}</div>
                                                <div className={classes.title2}>{moment(data?.startDate).format('DD-MMM-YYYY')?moment(data?.startDate).format('DD-MMM-YYYY'):"NA"}</div>
                                                <div className={classes.title2}>{data?.status?data?.status:'NA'}</div>
                                            </div>
                                        </div>
                                    </>),
                                    (<>
                                        <div className={classes.projectDetailsMain} style={{ marginTop: '2rem', border: "1px solid #DFDFDF" }}>
                                            <div>
                                                <div className={classes.title1}>Planned effort</div>
                                                <div className={classes.title1}>Actual effort</div>
                                                <div className={classes.title1}> Due on</div>
                                            </div>
                                            <div>
                                                <div className={classes.title2}>{data?.planEffort?data?.planEffort:'NA'}</div>
                                                <div className={classes.title2}>{data?.actualEffort?data?.actualEffort:"NA"}</div>
                                                <div className={classes.title2}>{moment(data?.dueDate).format('DD-MMM-YYYY')?moment(data?.dueDate).format('DD-MMM-YYYY'):'NA'}</div>
                                            </div>
                                        </div>
                                    </>)]?.map((item) => (
                                        <>
                                            {item}
                                        </>
                                    ))
                                }
                            </div>
                            <div style={{ width: "100%", height: "fit-content", border: "1px solid #DFDFDF", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ProjectSummary data={data?.progress ? data?.progress : 0} />
                            </div>
                            <div style={{ width: "100%", height: "fit-content", border: "1px solid #DFDFDF" }}>
                                <TaskOverview project={true} data={{ ...data?.taskCounts, total: data?.task }} />
                            </div>

                            {/*  <div className={classes.projectDetailsMain} style={{ marginTop: '2rem', border: "1px solid #DFDFDF" }}> */}

                            {/*  <div style={{ height: '12vh', background: '#cfcfcf', width: '1px' }}></div>

                       
                        <div style={{ height: '12vh', background: '#cfcfcf', width: '1px' }}></div>
                       
                        </div> */}
                            {/*  </div> */}
                            {/*    <div style={{ display: "flex", width: "100%", marginTop: 20, justifyContent: "space-between" }}>
                                <div style={{ width: "35%", height: "fit-content", border: "1px solid #DFDFDF", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <ProjectSummary data={data?.progress ? data?.progress : 0} />
                                </div>
                                <div style={{ width: "60%", height: "fit-content", border: "1px solid #DFDFDF" }}>
                                    <TaskOverview project={true} data={{ ...data?.taskCounts, total: data?.task }} />
                                </div>
                            </div> */}
                            {/*  <div className={classes.graphContainer} >
                        <ProjectSummary data={data?.progress ? data?.progress : 0} />
                    </div> */}
                        </> :
                            activeTab === 1 ? <>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>


                                </div>


                                <div className={classes.projectContainer}>
                                    {view === "grid" ? <TaskCards onLogChange={(res) => { LogEffortHandler(res) }} OnTransfer={(res) => { TaskTransferHandler(res) }} onDelete={(res) => { TaskDeleteHandler(res); }} onEdit={(res) => { TaskEditHandler(res); }} data={allTask} /> : view === "list" ? <TaskTable onLogChange={(res) => { LogEffortHandler(res) }} onTransfer={(res) => { TaskTransferHandler(res) }} onDelete={(res) => { TaskDeleteHandler(res); }} onEdit={(res) => { TaskEditHandler(res); }} data={allTask} /> : ""}
                                </div>
                                {taskModal !== undefined ? <AddTask modal={taskModal} setModal={setTaskModal} refresh={refreshTask.bind(this)} /> : ''}
                                {deleteTask !== undefined ? <DeleteModal modal={deleteTask} setModal={setDeleteTask} refresh={refreshTask.bind(this)} /> : ''}
                                {transferTask !== undefined ? <TransferTask modal={transferTask} setModal={setTransferTask} refresh={refreshTask.bind(this)} /> : ''}
                                {logEffort !== undefined ? <LogEffort role={role} modal={logEffort} setModal={setLogEffort} refresh={refreshTask.bind(this)} /> : ''}
                            </> : ''}
                    </>
                    // else is here 





                    :
                    <>
                        <div>
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                <div style={{ display: 'flex', padding: 10, cursor: "pointer" }}>
                                    <AiOutlineLeft onClick={() => {
                                        if (data?.navigationLocation === 'resourceDash') {
                                            console.log('navigate to -1')
                                            Navigate(-1);
                                        }
                                        onBackPress();
                                        back();
                                    }} size={20} />
                                    <div onClick={() => {
                                        if (data?.navigationLocation === 'resourceDash') {
                                            console.log('navigate to -1')
                                            Navigate(-1);
                                        }
                                        onBackPress();
                                        back();
                                    }} style={{ cursor: "pointer", fontSize: "1.1rem", fontWeight: "600", marginLeft: 10, display: "flex", alignItems: "center", justifyContent: "center" }}>{data?.projectName}</div>
                                </div>
                                {
                                    activeTab === 1 ?
                                        <div style={{ display: 'flex', alignItems: 'center', gap: "16px" }}>
                                            <SearchCompo setSearch={setSearch} style={{ width: '300px' }}></SearchCompo>
                                            <div className={classes.title2}>Task Status</div>
                                            <Select style={{ width: "110px" }} allowClear value={taskStatus} onChange={(val) => { setTaskStatus(val===undefined?"Active":val) }}>
                                                {statusList?.map((val) => {
                                                    return (
                                                        <Select.Option key={val}>{val}</Select.Option>
                                                    )
                                                })}
                                            </Select>
                                            {console.log(allTask, "allTaskallTask")}
                                            {(viewClass.manager && projectClickedData?.isManager) && (<ButtonCompo title={"Add Task"} onClick={() => { setTaskModal({ type: 'add', id: data?.projectId, userList: userList }) }} style={{ backgroundColor: '#0086ff', color: '#fff' }} />)}
                                        </div>
                                        :
                                        <>
                                        </>
                                }

                            </div>

                            <div 
                                // style={{ marginTop: '1.5rem', marginLeft: 10 }}
                            >
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', }}>
                                    <div style={{ height: 50, display: "flex", alignItems: "center" }}>
                                        <div className={classes.tabContainer}>
                                            {Tab?.map((val, id) => {
                                                return (
                                                    <div className={activeTab === id ? classes.activeTab : ''} key={id} onClick={() => {
                                                        setActiveTab(id);
                                                        if(id==1){
                                                            setSearch('')
                                                        }
                                                        setLoader(true);
                                                        setTimeout(() => { setLoader(false) }, 500)
                                                    }}>{val}</div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-around", width: "40%" }}>
                                        {activeTab === 1 &&
                                            <>
                                                <Select style={{ width: "110px" }} size="large" allowClear value={filter} onChange={(val) => { setFilter(val===undefined?taskFilter[0]:val) }}>
                                                    {taskFilter?.map((val) => {
                                                        return (
                                                            <Select.Option value={val} key={val}>{val}</Select.Option>
                                                        )
                                                    })}
                                                </Select>
                                                <GridListView onChange={(res) => { setView(res); setLoader(true); setTimeout(() => { setLoader(false) }, 500) }} defaultview={view} />
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                                                    {(viewClass.superAdmin || viewClass.hod || viewClass.hrAdmin || viewClass.hrAssociate || viewClass.manager) ? activeTab === 1 ? <span style={{ color: '#162432', fontWeight: "600", fontSize: "14px", display: "flex", gap: "0.3rem", flexDirection: "row", margin: "0 0 0 1rem", cursor: "pointer" }} onClick={downloadHandler}>Download <img src={downloadIcon} style={{ width: "1rem", height: "1rem" }} /></span> : '' : ''}
                                                </div>
                                            </>}
                                    </div>
                                </div>
                                <Divider style={{margin: '10px 0'}}/>

                                {activeTab === 0 ? loader ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}><Spin /></div> :
                                 <div style={{height:window.innerHeight-100}}>
                                    <div className={classes.projectDetailsMain} style={{ marginTop: '2rem', border: "1px solid #DFDFDF" }}>
                                        <div>
                                            <div className={classes.title1}>Created By</div>
                                            <div className={classes.title1}>Manager</div>
                                            <div className={classes.title1}>Department</div>
                                        </div>
                                        <div>
                                            <div className={classes.title2}>{data?.createdBy}</div>
                                            <div className={classes.title2}>{data?.managerName}</div>
                                            <div className={classes.title2}>{data?.departmentName}</div>
                                        </div>

                                        <div style={{ height: '12vh', background: '#cfcfcf', width: '1px' }}></div>

                                        <div>
                                            <div className={classes.title1}>Team</div>
                                            <div className={classes.title1}>Started On</div>
                                            <div className={classes.title1}>Status</div>
                                        </div>
                                        <div>
                                            <div className={classes.title2}>{data?.teamName}</div>
                                            <div className={classes.title2}>{moment(data?.startDate).format('DD-MMM-YYYY')}</div>
                                            <div className={classes.title2}>{data?.status}</div>
                                        </div>
                                        <div style={{ height: '12vh', background: '#cfcfcf', width: '1px' }}></div>
                                        <div>
                                            <div className={classes.title1}>Planned effort</div>
                                            <div className={classes.title1}>Actual effort</div>
                                            <div className={classes.title1}> Due on</div>
                                        </div>
                                        <div>
                                            <div className={classes.title2}>{data?.planEffort}</div>
                                            <div className={classes.title2}>{data?.actualEffort}</div>
                                            <div className={classes.title2}>{moment(data?.dueDate).format('DD-MMM-YYYY')}</div>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", width: "100%", marginTop: 20, justifyContent: "space-between" }}>
                                        <div style={{ width: "35%", height: "fit-content", border: "1px solid #DFDFDF", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <ProjectSummary data={data?.progress ? data?.progress : 0} />
                                        </div>
                                        <div style={{ width: "60%", height: "fit-content", border: "1px solid #DFDFDF" }}>
                                            <TaskOverview project={true} data={{ ...data?.taskCounts, total: data?.task }} />
                                        </div>
                                    </div>
                                    {/*  <div className={classes.graphContainer} >
                        <ProjectSummary data={data?.progress ? data?.progress : 0} />
                    </div> */}
                                </div> :
                                    activeTab === 1 ? <>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>


                                        </div>

                                        {
                                            view === "grid" &&
                                            <div className={classes.projectContainer} style={{height:window.innerHeight-380 }}>
                                                {view === "grid" ? <TaskCards onLogChange={(res) => { LogEffortHandler(res) }} OnTransfer={(res) => { TaskTransferHandler(res) }} onDelete={(res) => { TaskDeleteHandler(res); }} onEdit={(res) => { TaskEditHandler(res); }} data={allTask} /> :
                                                    ""}

                                            </div>

                                        }
                                        {
                                            view === "list" &&
                                        <div style={{height:window.innerHeight-385}}>
                                        {
                                            view === "list" &&
                                            <TaskTable onLogChange={(res) => { LogEffortHandler(res) }} onTransfer={(res) => { TaskTransferHandler(res) }} onDelete={(res) => { TaskDeleteHandler(res); }} onEdit={(res) => { TaskEditHandler(res); }} data={allTask} />
                                        }
                                        </div>
                                        }
                                    </> : ''}

                            </div>
                        </div>

                        {taskModal !== undefined ? <AddTask modal={taskModal} setModal={setTaskModal} refresh={refreshTask.bind(this)} /> : ''}
                        {deleteTask !== undefined ? <DeleteModal modal={deleteTask} setModal={setDeleteTask} refresh={refreshTask.bind(this)} /> : ''}
                        {transferTask !== undefined ? <TransferTask projectId={projectClickedData?.projectId} modal={transferTask} setModal={setTransferTask} refresh={refreshTask.bind(this)} /> : ''}
                        {logEffort !== undefined ? <LogEffort role={role} modal={logEffort} setModal={setLogEffort} refresh={refreshTask.bind(this)} /> : ''}
                    </>
            }
            {
                activeTab === 1 &&
                <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", marginTop: "0.5rem", paddingTop: 5 }}>
                    <Pagination
                        size={width < 700 ? "small" : "large"}
                        current={pageCurrent}
                        pageSize={pageSize}
                        total={pageMeta?.totalItems ? pageMeta?.totalItems : 0}
                        pageSizeOptions={[10, 30, 50, 100]}
                        showSizeChanger={true}
                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        onChange={handlePageApproveChange}
                        onShowSizeChange={handlePageApproveChange}
                    />
                </div>
            }
        </>

    )
}
export default ProjectDetails;