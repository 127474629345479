
import React, { useEffect, useState } from 'react';
import {  AiFillDelete} from 'react-icons/ai';
import { GrEdit } from 'react-icons/gr';
import {  Modal, Pagination, Typography } from 'antd';
import DeleteModel from './deleteModel';
import { allLeave } from './Action/action';
import { Spin } from 'antd';
import LeaveType from './leaveType';
import { useNotificationStore } from './store';
import viewClass from '../../../services/ViewRole';
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import DeleteIcon from "../../../assets/SettingDeleteIcon.svg";
import EditIcon from "../../../assets/userEditIcons.svg";
import classes from "./setting.module.css";

const TextComponent = ({ text, width, fontSize, backgroundColor, marginLeft, color, marginRight, fontWeight }) => {
    return (
        <div style={{ fontSize: '20px', width: width, fontSize: fontSize, marginLeft: marginLeft, color: color, marginRight: marginRight, fontWeight: fontWeight }}>
            {text}
        </div>
    );
};
function Leave() {
    const { allCityStore,searchVal,setSearchVal, setAllCityStore, addCityName, SetaddCityName, deleteStore, SetDeleteStore, recordsStore,
        setrecordStore, setgeoLoactionStore, geoLoactionStore, leaveStore, setleaveStore, load, setLoad,
    currentPage,setCurrentPage,LeaveTypepageSize,setLeaveTypePageSize } = useNotificationStore();
    const [centerModel, setCenterModel] = useState(false);
    const [edit, setEdit] = useState(true)
    const [deletes, setDelete] = useState(false)
    const {width}=useWindowDimensions();
    // const [allLeaves, setAllLeaves] = useState([]);
    const [refresh, setRefresh] = useState(true)
    const [error, seterror] = useState(false)
    const [addLeave, setaddLeave] = useState(false)
    // const [currentPage, setCurrentPage] = useState(1);
    // const [pageSize,setPageSize]=useState(10);
    const startIndex = (currentPage - 1) * LeaveTypepageSize;
    const endIndex = Math.min(startIndex + LeaveTypepageSize, leaveStore?.totalItem);
    const paginatedData = leaveStore?.data?.slice(startIndex, endIndex);

    const handlePageChange = (page,pageSize) => {
        setCurrentPage(page);
        setLeaveTypePageSize(pageSize);
      };

    useEffect(() => {
        viewClass?.updateView();
        setRefresh(true)
        allLeave({ limit: LeaveTypepageSize, pageNo: currentPage,search:searchVal }, (res) => {
            if (res) {
                setRefresh(false)
                setleaveStore(res)
                seterror(false)
            } else {
                setRefresh(false)
                seterror(true)
            }
        })

    }, [searchVal,LeaveTypepageSize,currentPage])

    console.log("sssLeaveStore",leaveStore)

    return (<>
        <div style={{ display: 'flex', padding: "",flexDirection:"column",marginTop:"0.3rem"}}>
            {refresh ? <Spin style={{ display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",height:"60vh" }} /> :
                <div style={{display:"flex",flexDirection:"column"}}>
                {width > 700 ?
                <div style={{ width: '100%',height:"70vh",overflowY:"scroll",padding:"0 10px",backgroundColor:"#F9F9F9" }}>
                    {leaveStore?.data?.map((item, index) => (
                        <div key={index} style={{ display: 'flex', padding: '10px', backgroundColor: 'white', borderRadius: 8, margin: '10px 0 10px 0', 
                         alignItems: 'center',marginBottom:"0.9rem",height:"4rem",boxShadow: "0 2px 6px rgba(0, 0,0,0.2)" }}>
                            <span style={{ margin: '0 15px 0 15px', fontWeight: '500', color: '#8B8B8B', marginRight: '1px', width: '15%',fontSize:"15px", paddingLeft: '10px', paddingRight: '10px' }} >Leave Type</span>
                            <span style={{ marginRight: '5px', fontWeight: '500', width: '15%',fontSize:"15px" }}>{item?.leaveName ? item?.leaveName :"NA"}</span>
                            <span style={{ fontWeight: '500', color: '#8B8B8B', width: '45%',fontSize:"15px" }}>Total number of {item?.leaveName} through the year</span>
                            <span style={{ fontWeight: '500', width: '10%',fontSize:"15px" }}>{item?.numberOfLeaves ? item?.numberOfLeaves :0}</span>
                            <div style={{ display: 'flex', width: '10%',fontSize:"15px" }}>
                                <div style={{ borderRadius:'40px',backgroundColor:'#ebeded',width:'2rem',height:'2rem',display:'flex',cursor:!viewClass.hrAssociate?'pointer':"not-allowed", alignItems: 'center', justifyContent: 'center' }}>
                                    <GrEdit size={16} onClick={() => {
                                      if(!viewClass?.hrAssociate){
                                        setaddLeave(true)
                                        setrecordStore(item)
                                        setDelete(false);
                                        setEdit(true);
                                        setCenterModel(true)
                                      }


                                    }} style={{}} />
                                </div>

                                <div style={{ cursor:!viewClass.hrAssociate?'pointer':"not-allowed", display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 10 }}>
                                    <img src={DeleteIcon}
                                    style={{height:"2rem",}}
                                        onClick={() => {

                                           if(!viewClass?.hrAssociate){
                                            setaddLeave(false)
                                            setDelete(true)
                                            setCenterModel(true)
                                            SetDeleteStore('Leave')
                                            setrecordStore(item)
                                            setEdit(false)
                                           }

                                        }} color='#FF3232' />
                                </div>
                            </div>


                        </div>))}
                    {
                        (error||leaveStore?.totalItem == 0) &&
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10%', fontWeight: '700' }}>
                            No leave type is created
                        </div>
                    }
                   
                </div>
                :
                <div className={classes.ListBodyContainer} style={{height:"64vh", overflowY:'scroll',overflowX:"scroll",width:"100%",backgroundColor:"#F9F9F9",padding:"0.2 2rem",marginTop:"0.5rem"}}>
                {leaveStore?.data?.map((item,index)=>{
                 return(
                    <div style={{width:"",marginTop:"0.3rem",borderRadius:10,backgroundColor:"#FFFFFF",padding:"1rem",margin:"1rem"}}>
                        
                        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%"}}>
                        <div style={{ display: 'flex', alignItems: 'center',gap:"0.3rem"}}>
                        <span style={{fontWeight: '500', color: '#8B8B8B',fontSize:"19px",}} >Leave Type: </span>
                        <p style={{fontWeight: '500',fontSize:"19px" }}>
                        <Typography.Text style={{width:"7rem",fontSize:20}} ellipsis={{tooltip:true}}>
                        {item?.leaveName ? item?.leaveName :"NA"}</Typography.Text></p>                        
                        </div>
                        <div style={{ display: 'flex',fontSize:"19px",gap:"0.3rem" }}>
                                    <img src={EditIcon} size={16} onClick={() => {
                                      if(!viewClass?.hrAssociate){
                                        setaddLeave(true)
                                        setrecordStore(item)
                                        setDelete(false);
                                        setEdit(true);
                                        setCenterModel(true)
                                      }


                                    }} style={{}} />

                                    <img src={DeleteIcon}
                                        onClick={() => {
                                           if(!viewClass?.hrAssociate){
                                            setaddLeave(false)
                                            setDelete(true)
                                            setCenterModel(true)
                                            SetDeleteStore('Leave')
                                            setrecordStore(item)
                                            setEdit(false)
                                           }
                                        }} />
                        </div>
                        </div>
                        <div style={{width:"70%",marginTop:"0.4rem"}}>
                        <span style={{ fontWeight: '500', color: '#8B8B8B',fontSize:"17px",width:"50%" }}>
                            Total number of {item?.leaveName} through the year : 
                            <span style={{color:"black",marginLeft:"0.7rem"}}>{item?.numberOfLeaves ? item?.numberOfLeaves :0}</span>
                        </span>
                        </div>
                  </div>
                )
              })}
                </div> 
            }
                 <div style={{marginTop:"0.5rem"}}>
                 <Pagination position="bottomRight" size='small' style={{display:"flex",justifyContent:"flex-end",fontSize:width<700&&15}}
                     current={currentPage} showSizeChanger={true} pageSize={LeaveTypepageSize} total={leaveStore?.totalItem} 
                     onChange={handlePageChange} onShowSizeChange={handlePageChange} pageSizeOptions={[10,20,30,50]}
                     showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                 />
                </div>
                </div>
            }
            {centerModel && <Modal
                color='red'
                centered
                open={centerModel}
                footer={null}
                // bodyStyle={{height:"30vh"}}
                onOk={() => setCenterModel(false)}
                onCancel={() => setCenterModel(false)}
                width={850}
            >

                {
                    edit &&
                    <LeaveType edit={edit} setCenterModel={setCenterModel}></LeaveType>
                }


                {deletes &&
                    <DeleteModel setCenterModel={setCenterModel} setDelete={setDelete} />
                }



            </Modal>}

        </div>
    </>);
}

export default Leave;