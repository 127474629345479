import React, { useState, useEffect } from "react";
import { FaMicrophone } from "react-icons/fa";
import moment from "moment";
import conferenceStore from "./store";
import { ImageHandler } from "./conferenceRoom";
import { IoMicOff } from "react-icons/io5";
import CloseIcon from "../../assets/closeIcon.svg"
const PendingApproval = ({ setModalVisible, micArr, setMicArr, pendingApprovals, onRequestAction, chatClientRef,insideChatUserList }) => {
    const { userG, activeUsers, activeMicList, activeUserList, isMicOn, updateActiveUser, meetIdG } = conferenceStore(state => state);
    console.log("activeList", activeUsers,activeUserList, pendingApprovals);

useEffect(() =>{
    let obj={}
  for(let i=0;i<pendingApprovals.length;i++){
  obj[pendingApprovals[i]]=true
  }
  for(let j=0;j<activeUserList.length;j++){
    if(obj[activeUserList[j]?.id]===true){
        chatClientRef.current.removeUser(activeUserList[j]?.id,meetIdG)
    }
  }
  chatClientRef.current.getUsers(meetIdG)
},[activeUserList])
    const [joinedTime, setTime] = useState(moment());




    return (
        <>
            <div style={{
                height: "100%",
                width: '25vw',
                position: "relative",
                backgroundColor: "#2D2D2D",
                borderRadius: 5,
                overflowY: 'auto',
                zIndex: 3
            }}>
                <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end", width: "100%", marginTop: 0, padding: 8, height: 30, cursor: "pointer" }} onClick={() => setModalVisible(false)}><img src={CloseIcon} style={{ height: 14, width: 14 }} /></div>
                {pendingApprovals.length>0?<>
                {activeUsers.map(ele => {
                    if (pendingApprovals.find(eles => eles === ele?.
                        userId)) {
                        return (<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 5 }}>
                            <div>
                                <div style={{ color: "white", fontSize: 12, display: "flex", flexDirection: "row" }}>{ele?.userName} <div style={{
                                    marginLeft: 5, height: 10, width: 10, borderRadius: 5, backgroundColor: ele?.statusToShow === "online" ? "green" : "red"
                                }}></div></div>

                                <div style={{ color: "white", fontSize: 9 }}>{ele?.role.split("_")[0]}</div>
                            </div>
                            <div onClick={() => {

                               if(ele?.statusToShow === "online"){
                                onRequestAction("deny", ele, ele?.userId);
                               }

                                chatClientRef.current.removeUser(ele?.userId, meetIdG)
                                chatClientRef.current.getUsers(meetIdG)
                               


                            }} style={{ height: "4vh", width: "20%", backgroundColor: ele?.statusToShow !== "online" ? "red" : "grey", borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                                {ele?.statusToShow !== "online" ? "remove" : "Deny"}
                            </div>
                            {ele?.statusToShow === "online"&&<div onClick={() => {

                                onRequestAction("allow", ele, ele?.userId);
                                let data = activeUsers.filter(item => item?.userId !== ele?.userId)
                                updateActiveUser(data)
                                chatClientRef.current.removeUser(ele?.userId, meetIdG)
                                chatClientRef.current.getUsers(meetIdG)
                               

                            }} style={{ height: "4vh", width: "20%", backgroundColor: "#1089FF", borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer", marginRight: 5 }}>
                                Allow
                            </div>}
                        </div>
                        )
                    }
                    else {
                        return ""
                    }
                })}</>:<div style={{color:"white",  display: "flex", 
                justifyContent: "center", 
                // alignItems: "center", 
                height: "100%"}}> No Pending requests to display</div>}


            </div>
        </>
    )
}

export default PendingApproval;