import Api from "../../../../services/Api";

export  const  getDepartmentData=(params,callback)=>{
Api.get("/resource-user/department").params(params).send((res)=>{
    if(res!==undefined){
        callback(res)
    }
})
};

export const ApproveApi=(params,callback)=>{
    Api.post("/resource-user/approve").params(params).send((res)=>{
        console.log("all approve",res);
        if(res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        }  
    });
}

export const RejectApi=(params,callback)=>{
    Api.post("/resource-user/reject").params(params).send((res)=>{
        console.log("rejected",res);
        if(res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        }  
    })
}
