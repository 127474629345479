import React, { useEffect, useState } from 'react';
import { CiLocationOn } from "react-icons/ci";
import { GrEdit } from 'react-icons/gr';
import { Modal,  Pagination, Switch, Typography } from 'antd';
import Maps from "../../../assets/rectangle.svg";
import DeleteModel from './deleteModel';
import AddCityModel from './addCity';
import { useNotificationStore } from './store';
import { allCity, updateCity } from './Action/action';
import ContentLoader from 'react-content-loader';
import Views from "../../../services/ViewRole";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import SettingEditIcon from "../../../assets/settingEditIcon.svg";

const LocationCity = () => {
  const { allCityStore, searchVal,setAllCityStore,setAddCity,addCity} = useNotificationStore();
  // const [addCity, setAddCity] = useState(false);
  const [centerModel, setCenterModel] = useState(false);
  const [edit, setEdit] = useState(true);
  const [deletes, setDelete] = useState(false);
  const [cityid, setCityId] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize,setPageSize]=useState(10);
  const [error, setError] = useState(false)
  const {width}=useWindowDimensions();

  useEffect(() => {
    Views.updateView();
    setRefresh(true);
    allCity((res) => {
      if (res) {
        setRefresh(false);
        console.log(res);
        
        // else{
          setAllCityStore(res);
        // }
      } else {
        setRefresh(false);
        setError(true)
      }
    });

  }, []);

  useEffect(() => {
    if (searchVal !== "") {
      let filteredData = allCityStore.filter((item) =>
        item.cityName.toLowerCase().includes(searchVal.toLowerCase())
      );
      setAllCityStore(filteredData);
      setCurrentPage(1);
    }
    else{
      allCity((res) => {
        if (res) {
          setRefresh(false);
          setAllCityStore(res);
          setCurrentPage(1);
        } else {
          setRefresh(false);
          setError(true);
        }
      });
    }
    console.log("sssSearchValue", searchVal, allCityStore);
  }, [searchVal]); 


  const handlePageChange = (page,pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    
  };
  const [loadingItems, setLoadingItems] = useState([]);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, allCityStore?.length);
  const paginatedData = allCityStore?.slice(startIndex, endIndex);

  console.log("sssPagination",startIndex,endIndex,paginatedData)

  const handleStatusChange = (item) => {
    const itemId = item.id;
    const updatedItems = [...loadingItems, itemId]; // Add the item ID to the loadingItems array
    setLoadingItems(updatedItems);

    updateCity(
      { status: item.status === 'Active' ? 'InActive' : 'Active' },
      itemId,
      (ress) => {
        if (ress?.show?.type === 'error') {
          console.log("ssssRespo",ress);
          setRefresh(false);
          allCity((res) => {
            setLoadingItems((prevItems) => prevItems.filter((id) => id !== itemId)); // Remove the item ID from the loadingItems array
            setRefresh(false);
            if (searchVal !== "") {
              let filteredData = res.filter((item) =>
                item.cityName.toLowerCase().includes(searchVal.toLowerCase())
              );
              setAllCityStore(filteredData);
              setCurrentPage(1);
            }else{
            setAllCityStore(res);
            }
          });
        } else {
          allCity((res) => {
            setLoadingItems((prevItems) => prevItems.filter((id) => id !== itemId)); // Remove the item ID from the loadingItems array
            setRefresh(false);
            if (searchVal !== "") {
              let filteredData = res.filter((item) =>
                item.cityName.toLowerCase().includes(searchVal.toLowerCase())
              );
              setAllCityStore(filteredData);
              setCurrentPage(1);
            }
            else{
            setAllCityStore(res);
            }
          });
        }
      }
    );
  };
  return (
    <>
    { width > 700 ?
      <div style={{ display: 'flex', justifyContent: 'space-around',marginTop:"0.4rem",}}>
        <div style={{ width: '40%',paddingTop:"5px",paddingBottom:"5px",paddingRight: '5px',alignItems: 'center' }}>
          {refresh ?
            <div style={{ display: 'flex', flexDirection: 'column', padding: '7px' }}>
              <ContentLoader
                speed={2}
                width={300}
                height={100}
                viewBox="0 0 300 100"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb">
                  <rect x="10" y="5" rx="3"  ry="3" width="280" height="30" />
                  <rect x="10" y="50" rx="3" ry="3" width="200" height="30" />
                  <rect x="10" y="50" rx="3" ry="3" width="250" height="30" />
                  <rect x="10" y="5" rx="3"  ry="3" width="280" height="30" />
                  <rect x="10" y="50" rx="3" ry="3" width="200" height="30" />
                  <rect x="10" y="50" rx="3" ry="3" width="250" height="30" />
              </ContentLoader>
            </div>
            :
            error ?
            <div style={{ display: 'flex',height:"67vh", alignItems: 'center', justifyContent: 'center',}}>
              No location city is created
            </div>
            :
             <div style={{height:"67.5vh",overflowY:"scroll",padding:"1px" }}>
              {paginatedData?.map((item, index) => (
                <div key={index} style={{display: 'flex',backgroundColor: 'white',alignItems: 'center',padding:"10px",
                  backgroundColor:"white",borderRadius:"5px",height:"4rem",boxShadow: "0 2px 6px rgba(0, 0,0,0.2)",justifyContent:"center",marginBottom:"1rem" }}>
                  <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                    <div style={{ alignItems: 'start', justifyContent: 'start', display: 'flex', width: '90%' }}>
                      <div style={{ width: '10%' }}>
                        <CiLocationOn color='#0086FF' size={16} style={{ alignItems: 'start', justifyContent: 'start', height: '20px' }} />
                      </div>
                      <div style={{ width: '80%' }}>
                        <h5> {item?.cityName}</h5>
                      </div>
                    </div>
                   
                    <div style={{ display: 'flex'}}>
                      <div style={{ borderRadius: 40, backgroundColor: '#ebeded', width: '4vh', height: '4vh', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                        <GrEdit size={16} 
                        onClick={() => {
                          if(!Views?.hrAssociate){
                          // setAddCity(false);
                          // setEdit(false);
                          // setCenterModel(false);
                          // }else{
                          setCityId(item?.id);
                          setAddCity(true);
                          setEdit(true);
                          setCenterModel(true);
                          setDelete(false);
                         }
                        }} style={{cursor:Views?.hrAssociate ?"not-allowed":"pointer"}} />
                      </div>
                      <div style={{ borderRadius: 40, width: '4vh', height: '4vh', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 18,marginRight:5 }}>
                        {/* <AiFillDelete onClick={() => {
                      SetDeleteStore('locationCity')
                      setDelete(true);
                      setCenterModel(true);
                      setAddCity(false);
                      setrecordStore(item);
                    }} color='#FF3232' /> */}
                        <Switch
                          loading={loadingItems.includes(item.id)} // Check if the item ID exists in the loadingItems array
                          checked={item.status === 'Active'}
                          disabled={Views?.hrAssociate ? true : false}
                          onChange={() => handleStatusChange(item)} // Pass the item to the handleStatusChange function
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          }
          {/* {
           
            
          }  */}
          {/* {allCityStore?.length > pageSize && ( */}
            <Pagination position="bottomRight" size='small' style={{display:"flex",justifyContent:"flex-end",marginTop:"0.8rem"}}
             current={currentPage} showSizeChanger={true} total={allCityStore?.length} pageSize={pageSize} 
             onChange={handlePageChange} onShowSizeChange={handlePageChange} pageSizeOptions={[5,10,20,30,50]}
             showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} />
          {/* )} */}

        </div>
        <div style={{ width: '57%', display: 'flex', flexDirection: 'column', height: '67vh', background: 'white',paddingRight:"1%",
            paddingLeft:"1%",paddingBottom:"1%", paddingTop: '4px', borderRadius: 10 }}>
          <h4>Location map</h4>
          <div style={{ flexGrow: 1 }}>
            <div style={{ borderRadius: '20px', marginTop: '10px',height: '67vh', }}>
              <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={Maps} alt="Location map" />
            </div>
          </div>
        </div>
      </div>
      :
      <div style={{backgroundColor:"#F9F9F9",padding:"5px 15px",marginTop:"0.4rem",display:"flex",flexDirection:"column",gap:"1rem",overflow:"scroll",height:"70vh"}}> 
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', height: '32vh',
            paddingLeft:"1%",paddingBottom:"1%", paddingTop: '4px', borderRadius: 10 }}>
          <p style={{fontSize:"24px",fontWeight:"600"}}>Location map</p>
          <div style={{ flexGrow: 1,}}>
            <div style={{ borderRadius: '30px', marginTop: '10px',height: '32vh', }}>
              <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={Maps} alt="Location map" />
            </div>
          </div>
        </div>
        <div style={{ width: '100%',marginTop:"0.5rem",alignItems: 'center',marginTop:"2rem" }}>
          {refresh ?
            <div style={{ display: 'flex', flexDirection: 'column', padding: '7px' }}>
              <ContentLoader
                speed={2}
                width={300}
                height={100}
                viewBox="0 0 300 100"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb">
                  <rect x="10" y="5" rx="3"  ry="3" width="280" height="30" />
                  <rect x="10" y="50" rx="3" ry="3" width="200" height="30" />
                  <rect x="10" y="50" rx="3" ry="3" width="250" height="30" />
                  <rect x="10" y="5" rx="3"  ry="3" width="280" height="30" />
                  <rect x="10" y="50" rx="3" ry="3" width="200" height="30" />
                  <rect x="10" y="50" rx="3" ry="3" width="250" height="30" />
              </ContentLoader>
            </div>
            :
            error ?
            <div style={{ display: 'flex',height:"40vh", alignItems: 'center', justifyContent: 'center',}}>
              No location city is created
            </div>
            :
             <div style={{height:"40vh",overflowY:"scroll",marginTop:"1rem",}}>
              {paginatedData?.map((item, index) => (
                <div key={index} style={{display: 'flex',backgroundColor: 'white',alignItems: 'center',padding:"10px",
                  backgroundColor:"white",borderRadius:"5px",height:"4rem",boxShadow: "0 2px 6px rgba(0, 0,0,0.2)",justifyContent:"center",marginBottom:"1rem" }}>
                  <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                    <div style={{ justifyContent: 'start', display: 'flex', width: '90%',alignItems:"center" }}>
                      <div style={{ width: '10%' }}>
                        <CiLocationOn color='#0086FF' size={30} style={{marginTop:"0.3rem", alignItems: 'start', justifyContent: 'start', height: '26px' }} />
                      </div>
                      <div style={{ width: '80%' }}>
                        <p><Typography.Text style={{maxWidth:"11.8rem",fontSize: 20, fontWeight: 550,marginLeft:"0.5rem"}} ellipsis={{tooltip:true}}>
                           {item?.cityName}
                        </Typography.Text></p>
                      </div>
                    </div>

                    <div style={{ display: 'flex'}}>
                      <div style={{ borderRadius: 40, width: '4vh', height: '4vh', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                        <img src={SettingEditIcon} size={16} 
                        onClick={() => {
                          if(!Views?.hrAssociate){
                          //   setAddCity(false);
                          //   setEdit(false);
                          //   setCenterModel(false);
                          // }else{
                          setCityId(item?.id);
                          setAddCity(true);
                          setEdit(true);
                          setCenterModel(true);
                          setDelete(false);
                         }
                        }} style={{cursor:Views?.hrAssociate?"not-allowed":"pointer",height:"1.5rem"}} />
                      </div>
                      <div style={{ borderRadius: 40, width: '4vh', height: '4vh', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 10,marginRight:5 }}>
                        {/* <AiFillDelete onClick={() => {
                      SetDeleteStore('locationCity')
                      setDelete(true);
                      setCenterModel(true);
                      setAddCity(false);
                      setrecordStore(item);
                    }} color='#FF3232' /> */}
                        <Switch
                          loading={loadingItems.includes(item.id)} 
                          checked={item.status === 'Active'}
                          disabled={Views?.hrAssociate ? true : false}
                          onChange={() => handleStatusChange(item)} 
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          }

          <Pagination position="bottomRight" size='small' style={{display:"flex",justifyContent:"flex-end",marginTop:"0.8rem",fontSize:width<700&&15}}
             current={currentPage} showSizeChanger={true} total={allCityStore?.length} pageSize={pageSize} 
             onChange={handlePageChange} onShowSizeChange={handlePageChange} pageSizeOptions={[5,10,20,30,50]}
             showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} 
          />
        </div>
      </div>
    }
    
        {centerModel && <Modal
          color='red'
          centered
          open={centerModel}
          footer={null}
          // bodyStyle={{height:"30vh"}}
          onOk={() => setCenterModel(false)}
          onCancel={() => setCenterModel(false)}
          width={900}

        >
          {addCity && <AddCityModel setCenterModel={setCenterModel} setEdit={edit} id={cityid} />}
          {deletes && <DeleteModel setCenterModel={setCenterModel} setDelete={setDelete} />}

        </Modal>}
        {/* {centerModel && (
          <Modal
            color='red'
            centered
            open={centerModel}
            footer={null}
            onOk={() => setCenterModel(false)}
            onCancel={() => setCenterModel(false)}
            width={500}

          >
            {addCity && <AddCityModel setAddCity={setAddCity} setCenterModel={setCenterModel} setEdit={edit} id={cityid} />}
            {deletes && <DeleteModel setCenterModel={setCenterModel} setDelete={setDelete} />}

          </Modal>
        )} */}
    </>
  );
}

export default LocationCity;
