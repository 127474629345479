import React, { useEffect, useState } from 'react';
import classes from './mainPage.module.css';
import newsletter_icon from '../../assets/newsletter.svg'
import poweredBy_icon from '../../assets/poweredBy_icon.svg';
import edit_icon from '../../assets/edit_blue_icon.svg';
import ContinueReading from './ContinueReading';
import AddNewsModal from './AddNewsModal';
import { useNewsLetterStore } from './Store';
import { getNewsLetterDataApi } from './Action';
import moment from 'moment';
import {Checkbox, Input, Popover, Select, Spin, Typography} from 'antd';
import plus_icon_white from '../../assets/plus_icon_white.svg';
import viewClass from '../../services/ViewRole';
import dashboardIcon from "../../assets/Dashboard.svg";
import DeleteEmployeeModal from '../../components/deleteModal';
import Api from '../../services/Api';
import { useWindowDimensions } from '../../components/windowDimention/windowDimension';
import MobileNavModal from './../../components/MobileNavModal/MobileNavModal';
import { useHeaderStore } from "../../pages/Header/store";

export const plus_blue_icon=(
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9 9H5.99539C5.45564 9 5 9.44768 5 10C5 10.5561 5.44561 11 5.99539 11H9V14.0046C9 14.5444 9.44768 15 10 15C10.5561 15 11 14.5544 11 14.0046V11H14.0046C14.5444 11 15 10.5523 15 10C15 9.44393 14.5544 9 14.0046 9H11V5.99539C11 5.45564 10.5523 5 10 5C9.44393 5 9 5.44561 9 5.99539V9ZM10 20C4.47714 20 0 15.5229 0 10C0 4.47714 4.47714 0 10 0C15.5229 0 20 4.47714 20 10C20 15.5229 15.5229 20 10 20Z" fill="#0086FF"/>
</svg> 
)

export const DotPopover=({id,status,getData,setLoader,newsLetterDataHandler,setEditNewsLetter,setAddNewsModal,setEditableNews,item,search,screenType})=>{
  const [deleteModal, setdeletemodal] = useState(false);
  const [deleteId,setdeleteId] =useState();
  const [showPopover,setShowPopover]=useState(false);
  return(
    <div>
      <Popover
      placement="bottomRight"
      onOpenChange={(e)=>{
        setShowPopover(e);
      }}
      open={showPopover}
      content={
        <div style={{display:"flex",flexDirection:"column",fontSize:"0.8rem",gap:"0.5rem"}}>
          <span style={{cursor:"pointer"}} onClick={()=>{
            setEditNewsLetter(true);
                   setAddNewsModal(true);
                  setEditableNews(item);
                  setShowPopover(false);      
          }}>Edit</span>
          <span
           onClick={()=>{
            setShowPopover(false);
            if(screenType==="newsletter")
            {
              Api.patch(`/resource-home/publish?documentName=${"newsletter"}`).params({documentId:[item?.id]}).send(res=>{
               if(res)
               {
                getNewsLetterDataApi(newsLetterDataHandler,search,status,()=>{
                  setLoader(false);                
                });
               }
              })
            }
            else
            {
              Api.patch(`/resource-home/publish?documentName=${"announcement"}`).params({documentId:[item?.id]}).send(res=>{
                console.log(res);
                if(res)
                {
                  getData();
                  setLoader(false); 
                }
              })
            }                 
          }}
          style={{cursor:"pointer"}}
          >Publish</span>
          <span onClick={()=>{
            setdeleteId(id);
            setdeletemodal(true);
            setShowPopover(false);
          }} style={{cursor:"pointer"}}>Delete</span>
          
        </div>
      } 
       trigger="click">
        <span style={{cursor:"pointer",fontSize:"1.2rem"}}
        onClick={()=>{
          setShowPopover(true);
        }}
        >...</span>
    </Popover>
    <DeleteEmployeeModal modal={deleteModal} content={`Do you really want to delete this ${screenType==="newsletter"? "newsletter":"announcement"}?`} setModal={setdeletemodal} DeleteHandler={()=>{
        
        if(screenType==="newsletter")
        {
          setLoader(true);
          Api.delete(`/resource-newsletters/deleteNewsletter`).params({documentId:[item?.id]}).send(res=>{
            console.log(res);
            if(res){
              // setLoader(true);
            
              getNewsLetterDataApi(newsLetterDataHandler,search,status,()=>{
                setLoader(false);
                setdeletemodal(false);
              });
            }
          })

        }
        else
        {
          setLoader(true);
          Api.delete(`/resource-announcement/deleteAnnouncement`).params({documentId:[item?.id]}).send(res=>{
            console.log(res);
            if(res)
            {
              getData();
              setdeletemodal(false);
            } 
            setLoader(false);
          })

        }

      
      }}/>
    </div>
  )
}

const Newsletter = ({screenType}) => {
  const [page,setPage]=useState(1);
  const [addNewsModal,setAddNewsModal]=useState(false);
  const [editNewsLetter,setEditNewsLetter]=useState(false);
  const [editableNews,setEditableNews]=useState();
  const [continueData,setContinueData]=useState();
  const {newsLetterData,newsLetterDataHandler}=useNewsLetterStore();
  const [loader,setLoader]=useState(false);
  const [search,setSearch]=useState('');
  const [dataList, setDataList] = useState([])
  const allNewsletters=newsLetterData?.data;
  const {width}=useWindowDimensions();
  const [status,setStatus]=useState("All");
  const [selectedCard,setSelectedCard]=useState([]);
  console.log("selectedCard",selectedCard)
  const {Option}=Select;
  const {sidemenu,setSidemenu}=useHeaderStore();
  const [deleteModal, setdeletemodal] = useState(false);

  const filterInfo=["All","Published","Unpublished",];

  const getData=()=>{
    setLoader(true);
    Api.get(`/resource-announcement/allAnnounce?search=${search}&filter=${status}`)
    .send((response)=>{
     console.log("sssResponse",response)
     if (response!==undefined){
         setDataList(response)
     }
     setLoader(false);
    })
  }
  // useEffect(()=>{
  //     getData()
  // },[screenType])

  const refresh=()=>{
    setLoader(true);
    if(screenType==="newsletter")
    {
      getNewsLetterDataApi(newsLetterDataHandler,search,status,()=>{
        setLoader(false);
      }); 
    }
    else if (screenType==="announcement")
    {
      getData()
    }
  }

  useEffect(()=>{
    viewClass.updateView();
    setLoader(true);
    if(screenType==="newsletter")
    {
      getNewsLetterDataApi(newsLetterDataHandler,search,status,()=>{
        setLoader(false);
      }); 
    }
    else if (screenType==="announcement")
    {
      getData()
    }
   
  },[search,screenType,status])


  



  return (
    <>
    <MobileNavModal sidemenu={sidemenu} setSidemenu={setSidemenu}>
    <div className={classes.sider} onClick={()=>{
      setPage(1);
      setSidemenu(false);
      window.location.reload();
    }}>               
                      <div>                 
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20" fill="none">
                                <path d="M5.99647 0H1.19929C0.538209 0 0 0.542589 0 1.20905V6.04527C0 6.71173 0.538209 7.25432 1.19929 7.25432H5.99647C6.65756 7.25432 7.19577 6.71173 7.19577 6.04527V1.20905C7.19577 0.542589 6.65756 0 5.99647 0Z" fill="#0086FF"/>
                                <path d="M15.8002 0H11.003C10.3419 0 9.80371 0.768667 9.80371 1.71283V8.56413C9.80371 9.50828 10.3419 10.277 11.003 10.277H15.8002C16.4613 10.277 16.9995 9.50828 16.9995 8.56413V1.71283C16.9995 0.768667 16.4613 0 15.8002 0Z" fill="#0086FF"/>
                                <path d="M5.99647 9.72314H1.19929C0.538209 9.72314 0 10.4918 0 11.436V18.2873C0 19.2314 0.538209 20.0001 1.19929 20.0001H5.99647C6.65756 20.0001 7.19577 19.2314 7.19577 18.2873V11.436C7.19577 10.4918 6.65756 9.72314 5.99647 9.72314Z" fill="#0086FF"/>
                                <path d="M15.8002 12.6885H11.003C10.3419 12.6885 9.80371 13.2311 9.80371 13.8975V18.7337C9.80371 19.4002 10.3419 19.9428 11.003 19.9428H15.8002C16.4613 19.9428 16.9995 19.4002 16.9995 18.7337V13.8975C16.9995 13.2311 16.4613 12.6885 15.8002 12.6885Z" fill="#0086FF"/>
                                </svg>
                        <span>Dashboard</span>
                      </div>

                      <div>
                              <img src={poweredBy_icon}></img>
                      </div>      
             </div>
    </MobileNavModal>
    {page===1&&<div className={classes.newsLetter}>
             {width>1000&&<div className={classes.sider} onClick={()=>{
                    setPage(1);
                    window.location.reload();
                  }}>                  
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20" fill="none">
                                <path d="M5.99647 0H1.19929C0.538209 0 0 0.542589 0 1.20905V6.04527C0 6.71173 0.538209 7.25432 1.19929 7.25432H5.99647C6.65756 7.25432 7.19577 6.71173 7.19577 6.04527V1.20905C7.19577 0.542589 6.65756 0 5.99647 0Z" fill="#0086FF"/>
                                <path d="M15.8002 0H11.003C10.3419 0 9.80371 0.768667 9.80371 1.71283V8.56413C9.80371 9.50828 10.3419 10.277 11.003 10.277H15.8002C16.4613 10.277 16.9995 9.50828 16.9995 8.56413V1.71283C16.9995 0.768667 16.4613 0 15.8002 0Z" fill="#0086FF"/>
                                <path d="M5.99647 9.72314H1.19929C0.538209 9.72314 0 10.4918 0 11.436V18.2873C0 19.2314 0.538209 20.0001 1.19929 20.0001H5.99647C6.65756 20.0001 7.19577 19.2314 7.19577 18.2873V11.436C7.19577 10.4918 6.65756 9.72314 5.99647 9.72314Z" fill="#0086FF"/>
                                <path d="M15.8002 12.6885H11.003C10.3419 12.6885 9.80371 13.2311 9.80371 13.8975V18.7337C9.80371 19.4002 10.3419 19.9428 11.003 19.9428H15.8002C16.4613 19.9428 16.9995 19.4002 16.9995 18.7337V13.8975C16.9995 13.2311 16.4613 12.6885 15.8002 12.6885Z" fill="#0086FF"/>
                                </svg>
                        <span>Dashboard</span>
                      </div>

                      <div>
                              <img src={poweredBy_icon}></img>
                      </div>      
             </div>}
             <div>
              <div className={classes.header}>
                <h3>{screenType==="newsletter"?"Newsletter":"Announcement"}</h3>


                  <div>
                      <div className={classes.search}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                              <circle cx="8.68103" cy="8.68103" r="7.93103" stroke="#BEBEBE" stroke-width="1.5"/>
                              <path d="M15.4473 15.6597L19.7878 20.0002" stroke="#BEBEBE" stroke-width="1.5" stroke-linecap="round"/>
                              </svg>
                              <Input type='text' value={search} placeholder='Search'  bordered={false} allowClear={true}
                              onChange={(e)=>{
                                setSearch(e.target.value);
                              }}
                              />
                      </div>
                      {(viewClass.superAdmin || viewClass.hrAdmin)&&
                      <div className={classes.filterDiv}>
                      <Select value={status}
                      size='large'
                      className={classes.filter}
                      onChange={(e)=>{
                        setStatus(e);
                      }}
                      >
                        {filterInfo.map((item,index)=>{
                          console.log("FilterBy",item)
                        return(
                          <Option value={item} key={index}>{item}</Option>
                        )
                        })}

                      </Select>
                      {selectedCard?.length>0&&width<800?<button
                                        
                                        onClick={()=>{
                                        if(screenType==="newsletter")
                                        {
                                          Api.patch(`/resource-home/publish?documentName=${"newsletter"}`).params({documentId:selectedCard}).send(res=>{
                                            console.log(res);
                                            setSelectedCard([]);   
                                          })
                                        }
                                        else
                                        {
                                          Api.patch(`/resource-home/publish?documentName=${"announcement"}`).params({documentId:selectedCard}).send(res=>{
                                            console.log(res);
                                            setSelectedCard([]);   
                                          })

                                        }
                                        refresh();                 
                                      }}
                                      className={`${classes.mobPublish} ${classes.publish}`}
                                      >Publish</button>:
                                      width<800?
                                      <button
                                      className={`${classes.mobAdd} ${classes.addButton}`}
                                       onClick={()=>{
                                          setAddNewsModal(true);
                                        }}>
                                          {/* {plus_blue_icon} */}
                                          Add {screenType==="newsletter"?"Newsletter":"Announcement"}</button>:null
                                      }
                      </div>
                      }

                  
                        {(viewClass.superAdmin || viewClass.hrAdmin)?
                          <div className={`${classes.mobButtonDiv} ${classes.buttonDiv}`}>
                                    {selectedCard?.length===0&&width>800&&
                                    <button
                                    className={classes.addButton}
                                     onClick={()=>{
                                        setAddNewsModal(true);
                                      }}>
                                        {/* {plus_blue_icon} */}
                                        Add {screenType==="newsletter"?"Newsletter":"Announcement"}</button>}

                                        {selectedCard?.length>0&&
                                        <button
                                        className={classes.cancel}
                                        onClick={()=>{
                                          setSelectedCard([]);
                                        }}
                                        >Cancel</button>}
                                        {selectedCard?.length>0&&
                                        <button
                                        className={classes.delete}
                                        onClick={()=>{
                                          setdeletemodal(true);                                                                                             
                                        }}
                                        >Delete</button>}
                                       {selectedCard?.length>0&&width>800&&<button
                                        onClick={()=>{
                                        if(screenType==="newsletter")
                                        {
                                          Api.patch(`/resource-home/publish?documentName=${"newsletter"}`).params({documentId:selectedCard}).send(res=>{
                                            console.log(res);
                                            setSelectedCard([]);   
                                          })
                                        }
                                        else
                                        {
                                          Api.patch(`/resource-home/publish?documentName=${"announcement"}`).params({documentId:selectedCard}).send(res=>{
                                            console.log(res);
                                            setSelectedCard([]);   
                                          })

                                        }
                                        refresh();                 
                                      }}
                                      className={classes.publish}
                                      >Publish</button>}
                            </div>
                            :null}
                         </div>
              </div>                     
              {loader===true?<Spin style={{width:width<800?"100vw":"100%",height:"60vh",alignItems:"center",display:"flex",justifyContent:"center",}}></Spin>:<div>
              {(screenType==="newsletter"? allNewsletters:dataList?.data)?.map((item,index)=>(
                <div className={classes.eachNewsCard} key={index}>
                 
              
                  
                  
                <div>
                      <div className={classes.image}>
                      {(viewClass.superAdmin || viewClass.hrAdmin)?<Checkbox 
                      checked={selectedCard.includes(item.id)}
                      onChange={(e)=>{
                        if(e.target.checked)
                        {
                          setSelectedCard(prev=>{
                            if(prev.includes(item.id))
                            {
                              return prev;
                            }
                            return  [...prev,item.id]
                           });
                        }
                        else
                        {
                          setSelectedCard(selectedCard?.filter((el=>el!==item.id)))
                        }
                              
                            }}></Checkbox>:<div></div>}
                            <img src={item.image}></img>
                            
                      </div>
                      <div>
                        <div>
                        <h3>{item.title}</h3>
                        <div>
                                {(viewClass.hrAdmin || viewClass.superAdmin)&&
                                <DotPopover screenType={screenType} search={search} getData={getData} id={item?.id} setEditNewsLetter={setEditNewsLetter} setAddNewsModal={setAddNewsModal} setEditableNews={setEditableNews} item={item} newsLetterDataHandler={newsLetterDataHandler} setLoader={setLoader} status={status}/>
                                        }
                          </div>
                          </div>
                        <div>
                        <span>{item.author}</span>
                          <span>{" |"} {width>800?moment(item.createdAt).format("MMM-DD-YYYY"):moment(item.createdAt).format("MMM-DD-YYYY")}</span>  
                          {(viewClass.superAdmin || viewClass.hrAdmin)&&item?.published&&<span className={classes.published}>Published</span>}
                          {(viewClass.superAdmin || viewClass.hrAdmin)&&!item?.published&&<span className={classes.unPublished}>Unpublished</span>}                     
                        </div>
                      
                              <Typography.Paragraph ellipsis={{rows:3}} style={{fontSize:"1rem",textAlign:"justify"}}>{item.content}</Typography.Paragraph>
                        
                        <div className={classes.continueButton}>
                  <span onClick={()=>{
                    setPage(2);
                    setContinueData(item);
                    }}>Continue Reading
                              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" viewBox="0 0 10 14" fill="none">
                              <path d="M8.87215 5.47147C9.81908 6.27051 9.81908 7.72949 8.87215 8.52853L3.71024 12.8843C2.40988 13.9815 0.420436 13.0572 0.420436 11.3557V2.64428C0.420436 0.942835 2.40989 0.018482 3.71024 1.11575L8.87215 5.47147Z" fill="#0086FF"/>
                              </svg>
                    </span>
                </div>
                       
                      </div>
                  
                </div>
          </div>
              ))}
             
              {(!allNewsletters?.length||allNewsletters?.length===0)&&(!dataList?.data?.length||dataList?.data?.length===0)&&
              <div className={classes.noData}>
                 <span>No data to display</span>
              </div>}
              </div>}
                  
             </div>
    </div>}
    {console.log("editableNewseditableNews",editableNews)}
    {page===2&&<ContinueReading setPage={setPage} continueData={continueData}  setContinueData={setContinueData} recentNewsLetters={screenType==="newsletter"? newsLetterData?.recentData:dataList?.recentData}/>}
    {addNewsModal&&<AddNewsModal search={search} status={status} addNewsModal={addNewsModal} isEdit={editNewsLetter}setEditNewsLetter={setEditNewsLetter} editableNews={editableNews} setAddNewsModal={setAddNewsModal}  setLoader={setLoader} screenType={screenType} getData={getData}/>}
    {deleteModal&&
    <DeleteEmployeeModal modal={deleteModal} content={`Do you really want to delete the selected ${screenType==="newsletter"? "newsletter(s)":"announcement(s)"}?`} setModal={setdeletemodal} DeleteHandler={()=>{
      if(screenType==="newsletter")
      {
        setLoader(true);
        Api.delete(`/resource-newsletters/deleteNewsletter`).params({documentId:selectedCard}).send(res=>{
          console.log(res);
          if(res)
          { 
            setSelectedCard([]);                                                                                                                                      
            refresh();
            setdeletemodal(false);
          }
        })

      }
      else
      {
        setLoader(true);
        Api.delete(`/resource-announcement/deleteAnnouncement`).params({documentId:selectedCard}).send(res=>{
          console.log(res);
          if(res)
          {
            setSelectedCard([]); 
            refresh();    
            setdeletemodal(false);                                       
          } 
          setLoader(false);
        })

      }
    }}/>
    }
    </>
    // <SiderLayout navList={[]}/>
  )
}
export default Newsletter