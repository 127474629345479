import React, { useState } from 'react';
import { Modal, Radio, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const DownloadModal = ({ visible, onCancel, onDownload }) => {
    const [locationOption, setLocationOption] = useState('locationHistory');

    const handleLocationChange = e => {
        setLocationOption(e.target.value);
    };

    return (
        <Modal
            title={<div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>Download</div>}
            open={visible}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button
                    key="download"
                    type="primary"
                    onClick={() => onDownload(locationOption)}
                    icon={<DownloadOutlined />}
                >
                    Download
                </Button>,
            ]}
        >
            <div style={{paddingLeft:10}}>
                <Radio.Group
                    onChange={handleLocationChange}
                    value={locationOption}
                    style={{ marginTop: '10px', width: "10rem"}}
                >
                    <Radio value="locationHistory">Location History</Radio>
                    <Radio style={{marginTop:10}} value="currentLocation">Current Location</Radio>
                </Radio.Group>

            </div>
        </Modal>
    );
};

export default DownloadModal;
