import React, { useRef } from "react";
import { LeftOutlined,RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import classes from "./style.module.css";
import SickIcon from "../../../assets/SickIcon.svg";
import CasualIcon from "../../../assets/CasualIcon.svg";
import PrivilegeIcon from "../../../assets/PrivilegeIcon.svg";
import WeddingIcon from "../../../assets/Wedding leave.svg";
import MaternityIcon from "../../../assets/Maternity.svg";
import PaternityIcon from "../../../assets/Paternity.svg";
import OptionalIcon from "../../../assets/Optional.svg";
import useStore from "./store";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";

export const Cards=(props)=>{
    // const {selectedUserData,setSelectedData,}=useStore(state=>state)
    const {width}=useWindowDimensions();

    return(
        <div>
        {width > 700 ? 
        <div className={classes.cards}>
            <div>
                <div style={{backgroundColor:""}}>
                    <img src={props?.icon} alt="" style={{height:"4rem"}}/>
                </div>
                <div>
                <div>
                    <p>{props?.leaves} {props?.leaves === "Comp Off" ? "":"Leave"}</p>
                    {/* <p>Count</p> */}
                    <p>{props?.leaveCount}</p>
                </div>
                </div>
            </div>
        </div>
        :
        <div style={{display:"flex",width:"19.5rem",height:"12vh",marginTop:"0.8rem",marginBottom:"0.5rem",borderRadius:10,boxShadow:"1px 4px 4px 0px rgba(0, 0, 0, 0.25)",backgroundColor:"#FFFFFF"}}>
            <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:"2rem",width:"100%",margin:"1rem"}}>
                <div style={{backgroundColor:""}}>
                    <img src={props?.icon} alt="" style={{height:"4rem"}}/>
                </div>
                <div>
                <div>
                    <p style={{fontSize:"18px",color:"#16243280"}}>{props?.leaves} {props?.leaves === "Comp Off" ? "":"Leave"}</p>
                    {/* <p>Count</p> */}
                    <p style={{fontSize:"26px"}}>{props?.leaveCount}</p>
                </div>
                </div>
            </div>
        </div>
        }
        </div>
    )}

const CardList=({data})=>{
    const {summaryData}=useStore(state=>state)
    const containerRef = useRef(null);
    const {width}=useWindowDimensions();

    const handleScrollLeft = () => {
        const container = containerRef.current;
        container.scrollLeft -= 1010; 
      };
    
      const handleScrollRight = () => {
        const container = containerRef.current;
        container.scrollLeft += 1010; 
      };
   
    return(
    <div className={classes.cardsDiv}>
        <div className={classes.buttonsDiv} style={{marginRight:"0.5rem"}}>
         <div onClick={handleScrollLeft}><LeftOutlined style={{height:""}}/></div>
         </div>
        <div className={classes.cardList} ref={containerRef}>
            <Cards icon={SickIcon} color={"#DAEEFF"} leaves={"Sick"} leaveCount={Object.keys(summaryData).length !== 0?summaryData?.user?.user?.userLeave[0]?.sick : data?.Sick}
            />
            <Cards icon={CasualIcon} color={"#FFEDD9"} leaves={"Casual"} leaveCount={Object.keys(summaryData).length !== 0 ? summaryData?.user?.user?.userLeave[0]?.casual :data?.Casual}/>
            <Cards icon={PrivilegeIcon} color={"#FFD7D7"} leaves={"Privilege"} leaveCount={Object.keys(summaryData).length !== 0 ? summaryData?.user?.user?.userLeave[0]?.privilege :data?.Privilege}/>
            <Cards icon={CasualIcon} color={"#FFF7D8"} leaves={"Comp Off"} leaveCount={Object.keys(summaryData).length !== 0 ? summaryData?.compOffData?.available : data?.compOff ? data?.compOff : 0}/>
            <Cards icon={WeddingIcon} color={"#D9FFE6"} leaves={"Wedding"} leaveCount={Object.keys(summaryData).length !== 0?summaryData?.user?.user?.userLeave[0]?.wedding :data?.Wedding}/>
            <Cards icon={MaternityIcon} color={"#F7D7FF"} leaves={"Maternity/Paternity"} leaveCount={Object.keys(summaryData).length !== 0?summaryData?.user?.user?.userLeave[0]?.parental :data?.Parental}/>
            {/* <Cards icon={PaternityIcon} color={"#EEEEEE"} leaves={"Paternity"} leaveCount={Object.keys(summaryData).length !== 0?summaryData?.user?.user?.userLeave[0]?.parental :data?.Parental}/> */}
            <Cards icon={OptionalIcon} color={"#EEEEEE"} leaves={"Optional"} leaveCount={Object.keys(summaryData).length !== 0?summaryData?.user?.user?.userLeave[0]?.optional :data?.Optional}/>

        </div>
        <div className={classes.buttonsDiv} style={{marginLeft:width > 700 ? "0.6rem":"0.2rem"}}>
            <div onClick={handleScrollRight}><RightOutlined /></div></div>
    </div>
    )
}
export default CardList;