import ModalCompo from "../../../../design/ModalCompo";
import classes from "./employee.module.css";
import {  Form, Input, Select } from "antd";
import { ModalPrimaryButton, ModalSecondaryButton } from "../../../../components/Button/Button";
import moment from "moment";
import { addAssociateApi } from "./employeeAction";
import DatePickerCompo from "../../../../components/DatePicker/DatePickerCompo.tsx";
function AddAssociateModal({open,setCloseModal,refresh}){
    let AssociateHeader=<div  className={classes.ModalAssociateHeader} >
        <div className={classes.headingAssociate}> Add Associate</div>
    </div>
    // HR will act as hr admin and hr associate will act as hr associate, hr associate will not have the write control in portal's cards.
    const roleOption=[{label:"Super Admin",value: "Super Admin"},{label:"HOD",value: "Department Head"},{label:"HR Admin",value: "HR"},{label:"HR Associate",value: "HR Associate"},{label:"Manager",value: "Manager"},{label:"Associate",value: "Associate"}]
    const privilegeOption=["None","Admin"]

    const AddAssoicateHandler=(val)=>{
        let param={name:val?.name,uid:val?.uid,email:val?.email,phone:val?.phone,joined:moment(val?.joiningDate).format("YYYY-MM-DD"),designation:val?.designation,userRoles:val?.role,privilege:val?.privilege}
        addAssociateApi(param,(res)=>{
            if(res){
                refresh();
                setCloseModal(false);
            }
        })
    }
    return(
        <ModalCompo open={open} header={AssociateHeader} onCancel={()=>{setCloseModal(false)}} closableRight={true} className={classes.addAssociateModal}>
        <div style={{width:'99%', borderTop:'1px solid #dfdfdf',paddingTop:'10px'}}>
            <div style={{height:"1px",}}></div>
        {/* <div style={{font:'normal 600 18px/23px Poppins',color:'#162432',marginBottom:'1rem'}}>Create New Associate Profile</div> */}
        <Form layout="vertical" initialValues={{joiningDate:moment()}} onFinish={AddAssoicateHandler} >
        <div className={classes.addAssoicateForm}>
            <Form.Item  label={<div className={classes.labelHeading}>Name</div>} name="name" rules={[{required:true,message:'please enter associate name',whitespace:true}]}>
                <Input placeholder="type name" type="text"/>
            </Form.Item>
            <Form.Item label={<div className={classes.labelHeading}>Email ID</div>} name="email" rules={[{required:true,message:'please enter associate email'}]}>
                <Input placeholder="type email " type="email" />
            </Form.Item>
            <Form.Item label={<div className={classes.labelHeading}>Phone No.</div>} name="phone" rules={[{required:true,message:'please enter associate 10 digit phone number',len:10}]}>
                <Input placeholder="type phone number" type="number" onWheel={(e) => e.target.blur()}  />
            </Form.Item>
            <Form.Item label={<div className={classes.labelHeading}>Employee ID</div>} name="uid" rules={[{required:true,message:'please enter associate employee ID'}]}>
                <Input type="number" placeholder="type employee id" onWheel={(e) => e.target.blur()} />
            </Form.Item>
            <Form.Item label={<div className={classes.labelHeading}>Joining Date</div>} name="joiningDate" rules={[{required:true,message:'please select associate joining date'}]}>
               <DatePickerCompo  style={{width:'100%'}}/>
            </Form.Item>
           
            <Form.Item label={<div className={classes.labelHeading}>Designation</div>} name="designation" rules={[{required:true,message:'please enter associate designation',whitespace:true}]}>
                <Input placeholder="type designation" />
            </Form.Item>
            <Form.Item label=<div className={classes.labelHeading}>Roles</div> name="role" rules={[{required:true,message:'please select role',}]}>
                <Select  mode="multiple"  placeholder="Select role">
                    {roleOption?.map((val,id)=>{
                        return(
                            <Select.Option value={val?.value} key={id}>{val?.label}</Select.Option>
                        )
                    })}
                </Select>
            </Form.Item> 
            <Form.Item label=<div className={classes.labelHeading}>Privileges</div> name="privilege" rules={[{required:true,message:'please select privilege',}]}>
                <Select  mode="multiple"  placeholder="Select privilege">
                    {privilegeOption?.map((val,id)=>{
                        return(
                            <Select.Option value={val} key={id}>{val}</Select.Option>
                        )
                    })}
                </Select>
            </Form.Item>
            <div style={{display:'flex',justifyContent:'flex-start',gap:'10px',marginTop:'0.5rem'}}>
            <Form.Item>
            <ModalPrimaryButton  htmlType="submit">Save</ModalPrimaryButton>
            </Form.Item>
            <Form.Item>
            <ModalSecondaryButton style={{backgroundColor:"white"}} onCancel={()=>{setCloseModal(false)}}>Cancel</ModalSecondaryButton>
            </Form.Item>
          </div>    
            </div>  
            
        </Form>
        </div>
        </ModalCompo>
    )
}
export default AddAssociateModal;