import React, { useEffect, useState } from 'react';
import { AiFillPlusCircle, AiFillDelete, AiOutlineDownload } from 'react-icons/ai';
import { GrEdit } from 'react-icons/gr';
import { Table, Modal, Input, Typography, Pagination, Spin, Select } from 'antd';
import GeofenceModel from './jeofenceModelEdit';
import DeleteModel from './deleteModel';
import { allGeolocation, allLeave } from './Action/action';
import { LoadingOutlined } from '@ant-design/icons';
import LeaveType from './leaveType';
import searchIcon from "../../../assets/search_Icon.svg";
import classes from "./setting.module.css";
import DownloadModel from './downloadModel';
import AddHolidayModel from './addHolidayModel';
import AddCityModel from './addCity';
import LocationCity from './locationCity';
import Leave from './leave';
import LocationHoliday from './locationHoliday';
import { useNotificationStore } from './store';
import DownloadIcon from "../../../assets/Download Icon Blue.svg";
import viewClass from '../../../services/ViewRole';
import EditIcon from "../../../assets/userEditIcons.svg";
import DeleteIcon from "../../../assets/SettingDeleteIcon.svg";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import WorkingDays from './workingDays';

const { Column } = Table;

const Settings = () => {
  const { searchVal, setSearchVal, setCurrentPageHoliday, SetDeleteStore, setrecordStore, setgeoLoactionStore, geoLoactionStore,
    holidayListBy, setHolidayListBy, setFixedStore, setAddCity, addCity, currentPage, setCurrentPage } = useNotificationStore();
  const [centerModel, setCenterModel] = useState(false);
  const [edit, setEdit] = useState(false)
  const [deletes, setDelete] = useState(false)
  const [holiday, setHoliday] = useState(false)
  const [selectedTab, setSelectedTab] = useState('Location Cities');
  const [allGeoLoaction, setAllGeoLoaction] = useState([]);
  const [allLeaves, setAllLeaves] = useState([]);
  // const [openModal, setModal] = useState(false)
  const [records, setRecords] = useState(false)
  const [download, setdownload] = useState(false)
  const [type, setType] = useState('jeofence')
  // const [addCity, setaddCity] = useState(false)
  const [loader, setLoader] = useState(false)
  const [addGeofence, setaddGeofence] = useState(false)
  const [addLeave, setaddLeave] = useState(false)
  const [addHoliaday, setaddHoliday] = useState(false)
  const [pageSize, setPageSize] = useState(10);
  const [pageCurrent, setPageCurrent] = useState(1);
  const { width } = useWindowDimensions();
  const tabOptions = ["Location Cities", "Geofence", "Location Holidays", "Leave Type", "Working days"]

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    // setSearchVal("")
  };

  const geoData = () => {
    let params = { limit: pageSize, pageNo: pageCurrent, search: searchVal }
    setLoader(true)
    allGeolocation({ limit: pageSize, pageNo: pageCurrent, search: searchVal }, (res) => {
      if (res) {
        console.log("sssssalll", res);
        setLoader(false)
        setgeoLoactionStore(res)
      }
    })
  }

  useEffect(() => {
    viewClass.updateView()
    geoData()
  }, [searchVal, pageSize, pageCurrent])

  // useEffect(() => {
  //   setRefresh(true)

  //   allLeave({ limit: 100, pageNo: 1,search:searchVal }, (res) => {
  //     setRefresh(false)
  //     setAllLeaves(res)
  //     console.log("all leaves", res);
  //   })
  //   // allCity((res)=>{setCity(res)})

  // }, [searchVal])

  const handlePageChange = (page, pageSize) => {
    setPageCurrent(page);
    setPageSize(pageSize)
  }

  return (
    <div style={{ paddingTop: "", paddingLeft: width > 700 && 10, paddingRight: width > 700 && 10, width: width < 700 && "100vw", backgroundColor: width < 700 && "#FFFFFF", height: width < 700 && "100vh" }}>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: width < 700 && "0 0.5rem", marginTop: 10 }}>
        {/* <TextComponent text={'Settings'} fontSize={17} fontWeight={600} /> */}
        <div style={{ color: "#162432", fontSize: width > 700 ? 20 : 24, fontWeight: 600 }}><h3>Settings</h3></div>
        {(width > 700 &&selectedTab!== "Working days") &&
          <div
            onClick={() => {
              if (!viewClass?.hrAssociate) {
                setAddCity(false)

                if (selectedTab === 'Geofence') {
                  setAddCity(false)
                  setaddGeofence(true)
                  setaddLeave(false)
                  setaddHoliday(false)
                  setdownload(false)
                  setHoliday(false)
                  setDelete(false)
                  setEdit(false);
                  setCenterModel(true)
                } else if (selectedTab === 'Leave Type') {
                  setaddGeofence(false)
                  setaddLeave(true)
                  setdownload(false)
                  setaddHoliday(false)
                  setHoliday(false);
                  setDelete(false);
                  setEdit(false);
                  setCenterModel(true)
                } else if (selectedTab === 'Location Cities') {
                  setAddCity(true)
                  setaddGeofence(false)
                  setaddLeave(false)
                  setaddHoliday(false)
                  setdownload(false)
                  setHoliday(false)
                  setDelete(false)
                  setCenterModel(true)
                }
                else {
                  setaddGeofence(false)
                  setaddLeave(false)
                  setaddHoliday(true)
                  setDelete(false);
                  setEdit(false);
                  setHoliday(true)
                  setFixedStore({
                    type: 'Add',
                    id: ''
                  })
                  setdownload(false)
                  setCenterModel(true)
                }
              }
            }}
            style={{
              display: 'flex',
              background: '#0086FF',
              marginLeft: 50,
              height: '1.9rem',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '1vh',
              borderRadius: 7,
              cursor: !viewClass?.hrAssociate ? 'pointer' : "not-allowed",
              whiteSpace: 'nowrap',
              width: selectedTab === 'Leave Type' ? "9.2rem" : selectedTab === 'Geofence' ? "8.5rem" : selectedTab === 'Location Holidays' ? "7.6rem" : "6.5rem",
            }}>
            <AiFillPlusCircle color='white' />
            <div style={{ fontSize: '14px', color: 'white', marginLeft: 5, }}>
              {selectedTab === 'Geofence' ? 'Add Geofence' : selectedTab === 'Leave Type' ? 'Add Leave Type' : selectedTab === 'Location Holidays' ? 'Add Holiday' : 'Add City'}
            </div>
          </div>
          }
      </div>
      {width < 700 &&
        <div style={{ padding: "0 10px", display: "flex", flexDirection: "row", width: "100%", gap: "0.5rem", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
          <div className={classes.searchDiv} style={{ width: "70%", height: "2.5rem", marginTop: "0.2rem" }}>
            <input allowClear={true} type="text" style={{ fontSize: "17px", height: "100%", width: "100%", }}
              placeholder="Search" value={searchVal} onChange={(e) => {
                setSearchVal(e.target.value); setCurrentPage(1);
                setCurrentPageHoliday(1);
                setPageCurrent(1);
              }}
            />
          </div>
          <div style={{ width: "30%", height: "2.5rem" }}>
            <div
              onClick={() => {
                if (!viewClass?.hrAssociate) {
                  setAddCity(false)

                  if (selectedTab === 'Geofence') {
                    setAddCity(false)
                    setaddGeofence(true)
                    setaddLeave(false)
                    setaddHoliday(false)
                    setdownload(false)
                    setHoliday(false)
                    setDelete(false)
                    setEdit(false);
                    setCenterModel(true)
                  } else if (selectedTab === 'Leave Type') {
                    setaddGeofence(false)
                    setaddLeave(true)
                    setdownload(false)
                    setaddHoliday(false)
                    setHoliday(false);
                    setDelete(false);
                    setEdit(false);
                    setCenterModel(true)
                  } else if (selectedTab === 'Location Cities') {
                    setAddCity(true)
                    setaddGeofence(false)
                    setaddLeave(false)
                    setaddHoliday(false)
                    setdownload(false)
                    setHoliday(false)
                    setDelete(false)
                    setCenterModel(true)
                  }
                  else {
                    setaddGeofence(false)
                    setaddLeave(false)
                    setaddHoliday(true)
                    setDelete(false);
                    setEdit(false);
                    setHoliday(true)
                    setFixedStore({
                      type: 'Add',
                      id: ''
                    })
                    setdownload(false)
                    setCenterModel(true)
                  }
                }
              }}
              style={{
                display: 'flex',
                background: '#0086FF',
                height: "100%",
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 7,
                cursor: !viewClass?.hrAssociate ? 'pointer' : "not-allowed", paddingLeft: "0.3rem",
              }}>
              <AiFillPlusCircle color='white' fontSize={21} />
              <Typography.Text style={{ width: "25vw", fontSize: 17, color: 'white', marginLeft: 2 }} ellipsis={{ tooltip: true }}>
                {selectedTab === 'Geofence' ? 'Add Geofence' : selectedTab === 'Leave Type' ? 'Add Leave Type' : selectedTab === 'Location Holidays' ? 'Add Holiday' : 'Add City'}
              </Typography.Text>
            </div>
          </div>
        </div>
      }

      {width > 700 ?
        <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', marginTop: "0.1rem", }}>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'stretch',
              borderRadius: 10,
              borderWidth: 1,
              // border:"2px solid",
              fontSize: "16px",
              borderColor: '#F3F3F3',
            }}
          >
            <div
              style={{
                height: 40,
                display: 'flex',
                alignItems: 'center',
                color: selectedTab === 'Location Cities' ? '#0086FF' : '#999999',
                cursor: 'pointer',
                fontWeight: '600',
                borderBottom: selectedTab === 'Location Cities' ? '3px solid #0086FF' : 0,
              }}
              onClick={() => { handleTabClick('Location Cities'); setSearchVal(""); setHolidayListBy("Location") }}
            >
              Location Cities
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                color: selectedTab === 'Geofence' ? '#0086FF' : '#999999',
                cursor: 'pointer',
                paddingLeft: 30,
                paddingRight: 30,
                height: 40,
                fontWeight: '700',
                borderBottom: selectedTab === 'Geofence' ? '3px solid #0086FF' : 0,
              }}
              onClick={() => { handleTabClick('Geofence'); setSearchVal(""); setHolidayListBy("Location") }}
            >
              Geofence
            </div>

            <div
              style={{
                height: 40,
                display: 'flex',
                alignItems: 'center',
                padding: '0 20px',
                color:
                  selectedTab === 'Location Holidays' ? '#0086FF' : '#999999',
                cursor: 'pointer',
                fontWeight: '600',
                borderBottom:
                  selectedTab === 'Location Holidays' ? '3px solid #0086FF' : 0,
              }}
              onClick={() => { handleTabClick('Location Holidays'); setSearchVal(""); setHolidayListBy("Location") }}
            >
              Holidays
            </div>

            <div
              style={{
                height: 40,
                display: 'flex',
                alignItems: 'center',
                // padding: '0 20px',
                color: selectedTab === 'Leave Type' ? '#0086FF' : '#999999',
                cursor: 'pointer',
                fontWeight: '600',
                borderBottom: selectedTab === 'Leave Type' ? '3px solid #0086FF' : 0,
                marginRight: 30,
                marginLeft: 30,
                borderBottomRadius: selectedTab === 'Leave Type' ? '40px' : '0',
              }}
              onClick={() => { handleTabClick('Leave Type'); setSearchVal(""); setHolidayListBy("Location") }}
            >
              Leave Type
            </div>
            <div
              style={{
                height: 40,
                display: 'flex',
                alignItems: 'center',
                // padding: '0 20px',
                color: selectedTab === 'Working days' ? '#0086FF' : '#999999',
                cursor: 'pointer',
                fontWeight: '600',
                borderBottom: selectedTab === 'Working days' ? '3px solid #0086FF' : 0,
                marginRight: 30,
                marginLeft: 30,
                borderBottomRadius: selectedTab === 'Working days' ? '40px' : '0',
              }}
              onClick={() => { handleTabClick('Working days'); setSearchVal("") }}
            >
              Working days
            </div>
          </div>
          {selectedTab === 'Location Holidays' ?
            <div style={{ display: "flex", flexDirection: "row", gap: "", alignItems: "center" }}>
              <Select
                defaultValue="Location"
                style={{ marginRight: "0.7rem", height: "", borderRadius: 0, width: "7rem" }}
                onChange={(value) => { setHolidayListBy(value); setSearchVal("") }}
                options={[
                  { value: 'Location', label: 'Location' },
                  { value: 'Department', label: 'Department' },
                  { value: 'Associate', label: 'Associate' },
                ]}
              />
              <div className={classes.searchDiv}>
                <img
                  src={searchIcon}
                  style={{ width: "1rem", height: "1rem", opacity: "1" }}
                  alt="searchIcon"
                />
                <input allowClear={true} size="medium" type="text"
                  placeholder="Search" value={searchVal} onChange={(e) => {
                    setSearchVal(e.target.value); setCurrentPage(1);
                    setCurrentPageHoliday(1);
                    setPageCurrent(1);
                  }}
                />
              </div>
              <div onClick={() => {
                setdownload(true)
                setAddCity(false)
                setHoliday(false)
                setaddHoliday(false)
                setaddGeofence(false)
                setaddLeave(false)
                setDelete(false)
                setCenterModel(true)
              }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}>
                <div style={{ fontSize: '16px', color: 'black', marginLeft: 10, marginRight: 5, color: "#0086FF" }}>Download</div>
                <img src={DownloadIcon} alt='' style={{ height: "1rem" }} />
              </div>
            </div>
            :
            selectedTab=== "Working days"?
            <>
            </>
            :
            <div className={classes.searchDiv}>

              <img
                src={searchIcon}
                style={{ width: "1rem", height: "1rem", opacity: "1" }}
                alt="searchIcon"
              />
              <input allowClear={true} size="medium" type="text" value={searchVal}
                placeholder="Search" onChange={(e) => {
                  setSearchVal(e.target.value); setCurrentPage(1);
                  setCurrentPageHoliday(1);
                  setPageCurrent(1);
                }}
              />
            </div>}
        </div>
        :
        <div style={{ padding: "0 10px", marginTop: "0.9rem", fontSize: "17px", display: "flex", alignItems: "center", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
          <Select allowClear value={selectedTab} size='large' style={{ borderRadius: 5 }}
            placeholder="select"
            onChange={(val) => {
              handleTabClick(val);
            }}
          >
            {tabOptions.map((item, index) => {
              return (
                <Select.Option style={{ fontSize: "17px" }} value={item} key={index}>{item}</Select.Option>
              )
            })}
          </Select>
          <div style={{ display: "flex", flexDirection: "row", gap: "0.3rem" }}>
            <Select
              defaultValue="Location" size='large'
              style={{ marginRight: "0.7rem", height: "2.5rem", borderRadius: 0, width: "7rem" }}
              onChange={(value) => { setHolidayListBy(value); setSearchVal("") }}
              options={[
                { value: 'Location', label: 'Location' },
                { value: 'Department', label: 'Department' },
                { value: 'Associate', label: 'Associate' },
              ]}
            />
            <div onClick={() => {
              setdownload(true)
              setAddCity(false)
              setHoliday(false)
              setaddHoliday(false)
              setaddGeofence(false)
              setaddLeave(false)
              setDelete(false)
              setCenterModel(true)
            }}
              style={{}}
            >
              <img src={DownloadIcon} alt='' style={{ height: "1.6rem", width: "1.6rem", marginRight: "0.4rem" }} />
            </div>
          </div>
        </div>
      }

      {selectedTab === 'Geofence' ? <div style={{ display: 'flex', marginTop: "0.5rem" }}>
        <div style={{ width: '100%' }}>
          {/* <Table
            loading={refresh} scroll={{y:"53vh"}}
            pagination={{
              position: "bottomRight",
              size: "small",
              pageSizeOptions: [10,30, 50,100],
              pageSize: pageSize,
              current: pageCurrent,
              // total:
              onChange: (page, pageSize) => {setPageCurrent(page);setPageSize(pageSize);},
              onShowSizeChange: (page, pageSize) => {setPageCurrent(page);setPageSize(pageSize);},
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            dataSource={geoLoactionStore?.data}>
            {/* <Table dataSource={data}> */}


          {/* <Column title="Location Name" dataIndex="name" key=" " render={(text,record)=>{
              return(<div>
                <Typography.Text style={{width:"9rem",}} ellipsis={{tooltip:true}}>
                <span>{record?.name ? record?.name :"NA"}</span></Typography.Text>
              </div>)
            }}/>
            <Column title="Location City" dataIndex="cityName" key="cityName" render={(text,record)=>{
              return(<div>
                <Typography.Text style={{width:"8rem",}} ellipsis={{tooltip:true}}>
                <span>{record?.cityName ? record?.cityName :"NA"}</span></Typography.Text>
              </div>)
            }}/>
            <Column title="Latitude" dataIndex="latitude" key="latitude" />
            <Column title="Longitude" dataIndex="longitude" key="longitude" />
            <Column title="Radius" dataIndex="radius" key="radius" />

            <Column
              title="Action"
              dataIndex="tags"
              key="tags"
              render={(text, record) => (
                <>
                  <div style={{ display: 'flex', }}> */}
          {/* <GrEdit
                    
                    onClick={() => {
                      if(viewClass.superAdmin){
                      setaddGeofence(true)
                      setaddHoliday(false)
                      setaddLeave(false)
                      setAddCity(false)
                      setHoliday(false)
                      setDelete(false)
                      setEdit(true)
                      setRecords(record)

                      setCenterModel(true)}
                    }} style={{ marginRight: '20%' ,cursor:viewClass.superAdmin?'pointer':"not-allowed"}} />
                    <AiFillDelete
                      style={{cursor:viewClass.superAdmin?'pointer':"not-allowed"}}
                      onClick={() => {
                        if(viewClass.superAdmin){
                        setrecordStore(record)
                        SetDeleteStore('Geofence')
                        setaddGeofence(false)
                        setaddHoliday(false)
                        setaddLeave(false)
                        setHoliday(false)
                        setRecords(record)
                        setDelete(true)
                        setCenterModel(true)}
                      }} color='#FF3232' />
                  </div>
                </>
              )}
            />
          </Table> */}
          {width > 700 ?
            <div className={classes.ListContainer}>
              <div className={classes.ListHeader} style={{ display: 'flex', width: '100%', gap: "0.5rem", paddingTop: "0.7rem", paddingBottom: "0.5rem", fontSize: 15, color: "#16243280", fontWeight: 700, }}>

                <div style={{ width: '30%', paddingLeft: "0.7rem" }}>Location Name</div>
                <div style={{ width: '30%' }}>Location City</div>
                <div style={{ width: '20%' }}>Latitude</div>
                <div style={{ width: '20%' }}>Longitude</div>
                <div style={{ width: '20%' }}>Radius</div>
                <div style={{ width: '20%' }}>Actions</div>
              </div>

              <div className={classes.ListBodyContainer} style={{ height: "62vh", overflowY: 'scroll', overflowX: "scroll", width: "100%" }}>
                {loader ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}><Spin /></div> :
                  geoLoactionStore?.data?.length === 0 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}><div>No data found</div></div> :
                    geoLoactionStore?.data?.map((record, id) => {
                      return (
                        <div className={classes.rowContainer} style={{ gap: "0.5rem", color: "#162432", fontSize: 15, fontWeight: 400, fontStyle: "Lato", }}>

                          <div style={{ width: '30%', paddingLeft: "0.7rem" }}>
                            <Typography.Text style={{ width: "13vw", color: "#162432", fontSize: 15, }} ellipsis={{ tooltip: true }}>
                              {record?.name ? record?.name : "NA"}</Typography.Text>
                          </div>
                          <div style={{ width: '30%', }}>
                            <Typography.Text style={{ width: "13vw", }} ellipsis={{ tooltip: true }}>
                              <span>{record?.cityName ? record?.cityName : "NA"}</span></Typography.Text>
                          </div>
                          <div style={{ width: '20%', }}>
                            <Typography.Text style={{ width: "9rem", color: "#162432", fontSize: 15, }} ellipsis={{ tooltip: true }}>
                              {record?.latitude ? record?.latitude : "NA"}</Typography.Text>
                          </div>
                          <div style={{ width: '20%', }}>
                            <Typography.Text style={{ width: "9rem", color: "#162432", fontSize: 15, }} ellipsis={{ tooltip: true }}>
                              {record?.longitude ? record?.longitude : "NA"}</Typography.Text>
                          </div>
                          <div style={{ width: '20%', }}>
                            <Typography.Text style={{ width: "9rem", color: "#162432", fontSize: 15, }} ellipsis={{ tooltip: true }}>
                              {record?.radius ? record?.radius : "NA"}</Typography.Text>
                          </div>
                          <div style={{ width: '20%', }}>
                            <div style={{ display: 'flex', alignItems: "center", gap: "0.5rem" }}>
                              <div style={{ borderRadius: '40px', backgroundColor: '#ebeded', width: '2rem', height: '2rem', display: 'flex', cursor: !viewClass?.hrAssociate ? 'pointer' : "not-allowed", alignItems: 'center', justifyContent: 'center' }}>
                                <GrEdit size={16} onClick={() => {
                                  if (!viewClass?.hrAssociate) {
                                    setaddGeofence(true)
                                    setaddHoliday(false)
                                    setaddLeave(false)
                                    setAddCity(false)
                                    setHoliday(false)
                                    setDelete(false)
                                    setEdit(true)
                                    setRecords(record)

                                    setCenterModel(true)
                                  }
                                }} style={{}} />
                              </div>
                              {/* <GrEdit
                            style={{ marginRight: '25%',width:"2rem",height:"3rem" ,cursor:viewClass.superAdmin?'pointer':"not-allowed"}} /> */}
                              <img src={DeleteIcon}
                                style={{ cursor: !viewClass?.hrAssociate ? 'pointer' : "not-allowed", height: "2rem", }}
                                onClick={() => {
                                  if (!viewClass?.hrAssociate) {
                                    setrecordStore(record)
                                    SetDeleteStore('Geofence')
                                    setaddGeofence(false)
                                    setaddHoliday(false)
                                    setaddLeave(false)
                                    setHoliday(false)
                                    setRecords(record)
                                    setDelete(true)
                                    setCenterModel(true)
                                  }
                                }} color='#FF3232' />
                            </div>
                          </div>
                        </div>
                      )
                    })}
              </div>
            </div>
            :
            <div className={classes.ListBodyContainer} style={{ height: "64vh", overflowY: 'scroll', overflowX: "scroll", width: "100%", backgroundColor: "#F9F9F9", padding: "0.2 2rem" }}>
              {loader ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '67vh', }}><Spin /></div> :
                geoLoactionStore?.data?.length === 0 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '67vh' }}><div>No data found</div></div> :
                  geoLoactionStore?.data?.map((record, id, index) => {
                    return (
                      <div style={{ width: "", marginTop: "0.3rem", borderRadius: 10, backgroundColor: "#FFFFFF", padding: "1rem", margin: "1rem" }}>

                        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", }}>
                          <div style={{ display: "flex", flexDirection: "row", gap: "0.3rem", marginTop: "0.5rem", }}>
                            <p style={{ fontSize: "19px", color: "#16243280" }}>
                              <Typography.Text style={{ width: "37vw", color: "#16243280", fontSize: 19.8, }} ellipsis={{ tooltip: true }}>
                                Location Name:
                              </Typography.Text></p>
                            <p style={{ fontSize: "19px" }}>
                              <Typography.Text style={{ width: "26vw", color: "#162432", fontSize: 19.8, }} ellipsis={{ tooltip: true }}>
                                {record?.name ? record?.name : "NA"}
                              </Typography.Text></p>
                          </div>
                          <div style={{ display: 'flex', gap: "0.6rem" }}>
                            <img src={EditIcon}
                              onClick={() => {
                                if (!viewClass?.hrAssociate) {
                                  setaddGeofence(true)
                                  setaddHoliday(false)
                                  setaddLeave(false)
                                  setAddCity(false)
                                  setHoliday(false)
                                  setDelete(false)
                                  setEdit(true)
                                  setRecords(record)

                                  setCenterModel(true)
                                }
                              }} style={{ marginRight: '', width: "2rem", height: "3rem", cursor: !viewClass?.hrAssociate ? 'pointer' : "not-allowed" }} />
                            <img src={DeleteIcon}
                              style={{ cursor: !viewClass?.hrAssociate ? 'pointer' : "not-allowed", height: "3rem", width: "2rem" }}
                              onClick={() => {
                                if (!viewClass?.hrAssociate) {
                                  setrecordStore(record)
                                  SetDeleteStore('Geofence')
                                  setaddGeofence(false)
                                  setaddHoliday(false)
                                  setaddLeave(false)
                                  setHoliday(false)
                                  setRecords(record)
                                  setDelete(true)
                                  setCenterModel(true)
                                }
                              }} color='#FF3232' />
                          </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0.2rem", }}>
                          <p style={{ fontSize: "19px", color: "#16243280" }}>
                            <Typography.Text style={{ width: "32vw", color: "#16243280", fontSize: 19.8, }} ellipsis={{ tooltip: true }}>
                              Location City:
                            </Typography.Text></p>
                          <p style={{ fontSize: "19px" }}>
                            <Typography.Text style={{ width: "55vw", color: "#162432", fontSize: 19.8, }} ellipsis={{ tooltip: true }}>
                              {record?.cityName ? record?.cityName : "NA"}
                            </Typography.Text></p>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0.5rem", }}>
                          <p style={{ fontSize: "19px", color: "#16243280" }}>Latitude: </p>
                          <p style={{ fontSize: "19px" }}>{record?.latitude ? record?.latitude : "NA"}</p>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0.5rem", }}>
                          <p style={{ fontSize: "19px", color: "#16243280" }}>Longitude: </p>
                          <p style={{ fontSize: "19px" }}>{record?.longitude ? record?.longitude : "NA"}</p>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0.6rem", }}>
                          <p style={{ fontSize: "19px", color: "#16243280" }}>Radius: </p>
                          <p style={{ fontSize: "19px" }}>{record?.radius ? record?.radius : "NA"}</p>
                        </div>

                      </div>
                    )
                  })}
            </div>
          }
          {/* <div> */}
          <Pagination position="bottomRight" size="small" style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem", fontSize: width < 700 && 15 }}
            current={pageCurrent}
            pageSize={pageSize}
            total={geoLoactionStore?.totalItem
              ? geoLoactionStore?.totalItem
              : 0}
            pageSizeOptions={[10, 30, 50, 100]}
            showSizeChanger={true}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            onChange={handlePageChange}
            onShowSizeChange={handlePageChange}
          />
          {/* </div> */}
        </div>
      </div> :
        <>
          {
            selectedTab === 'Leave Type' ?
              <>
                <Leave allLeaves={allLeaves} />

              </> :
              <>
                {selectedTab == 'Location Holidays' ?
                  <LocationHoliday />

                  :
                  selectedTab=== "Working days"?
                  <>
                  <WorkingDays/>
                  </>
                  :
                  <LocationCity />
                }
              </>
          }
        </>
      }


      {centerModel && <Modal

        color='red'
        centered
        open={centerModel}
        footer={null}
        onOk={() => setCenterModel(false)}
        onCancel={() => setCenterModel(false)}
        // style={{marginTop:"2vh"}}
        width={holiday ? 900 : addGeofence ? 900 : download ? 900 : addLeave ? 900 : 900}

      >
        {
          console.log("hello", addGeofence, addLeave, addCity, holiday, download, deletes)
        }
        {addGeofence &&
          <GeofenceModel refreshApi={geoData.bind(this)} setCenterModel={setCenterModel} edit={edit} setEdit={setEdit} record={records} refresh={loader} setRefresh={setLoader} />
        }
        {
          addCity && <AddCityModel setCenterModel={setCenterModel}></AddCityModel>
        }
        {
          addLeave &&
          <LeaveType edit={edit} setCenterModel={setCenterModel} ></LeaveType>
        }



        {
          holiday && <AddHolidayModel setCenterModel={setCenterModel} setHoliday={setHoliday} />
        }

        {
          download &&
          <DownloadModel setCenterModel={setCenterModel} record={records} setdownload={setdownload} />
        }


        {deletes &&
          <DeleteModel setCenterModel={setCenterModel} record={records} setDelete={setDelete} type={type} setAllGeoLoaction={setAllGeoLoaction} setRefresh={setLoader} setAllLeaves={setAllLeaves} />
        }



      </Modal>}
    </div>
  );
};

export default Settings;