import React, { useState, useEffect } from "react";
import { getNotification } from "./action";
import classes from './AllRequest.module.css';
import { DatePicker, Input, Pagination, Select, Spin, Table, Typography } from "antd";
import select_icon from '../../assets/select_icon.svg';
import reject_icon from '../../assets/reject_icon.svg';
import { useNotificationStore } from "./store";
import ApproveModal from "./ApproveModal";
import RejectModal from "./RejectModal";
import TableCustom from "../../components/tableCustom/customTable";
import SearchCompo from "../../design/SearchCompo";
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";
import { NoData } from "../Training_essentialDocuments";
import dayjs from "dayjs";
import moment from "moment";

const AllNotificationModal = ({ setAllNotModal }) => {

    const dateFormat = "YYYY-MM-DD"
    const [refresh, setrefresh] = useState(true)
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const { approveModalHandler, rejectModalHandler, setId, id, user, setSelUser, userSel } = useNotificationStore();

    const [getRequests, setgetRequest] = useState([]);
    const [getRequests2, setgetRequest2] = useState([])
    const [message, setmessage] = useState('')
    const [title, settitle] = useState('')
    const [status, setStatus] = useState('')
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const { height, width } = useWindowDimensions();
    console.log("windowDimension", height);

    let selecteduser = ""

    const getRequest = () => {
        setrefresh(true);
        let arr = [];
        let param = {
            userId: user?.resourceUser?.id, page: page, limit: pageSize, searchTitle: title,
            searchMessage: message,
        }
        if (endDate) {
            param = { ...param, startDate: startDate, endDate: endDate }
        }
        getNotification(param, (res) => {
            setgetRequest2(res);
            setrefresh(false);
            console.log("GET REQUEST", res)
            let temp = res?.allNotifications;
            temp?.forEach((record, id) => {
                let obj = {
                    ...record,

                    serialNo:
                        <div style={{ paddingLeft: "1rem" }}>
                            <span>{id + 1 + (page - 1) * pageSize}</span>
                        </div>,
                    title:
                        <div>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '10vw' }} >
                                {record?.title}
                            </Typography.Text>
                        </div>,
                    message:
                        <div>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '20vw' }} >
                                {record?.message}
                            </Typography.Text>
                        </div>,
                    comments:
                        <div style={{ display: "flex", flexDirection: "column" }}>
                             {
                                 record?.info?.comments?
                                 <>
                                            {
                                                 record?.info?.comments?.commentApproved1 &&
                                                 <>
                                                     <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '20vw', color:"green" }} >
                                                         <span style={{ fontWeight: 600 }}> Approver 1  ({record?.info?.approvers?.approverOneName}) commented on {record.info?.comments?.commentApproved1Date} : </span> '{record?.info?.comments?.commentApproved1}'
                                                     </Typography.Text>
     
                                                 </>
                                            }
                                        {
                                            record?.info?.comments?.commentRejected1 ?
                                            <>
                                                <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '20vw' , color:"red"}} >
                                                <span style={{ fontWeight: 600 }}> Approver 1  ({record?.info?.approvers?.approverOneName}) commented on {record.info?.comments?.commentRejected1Date} : </span> '{record?.info?.comments?.commentRejected1}'
                                                    {/* <span style={{ fontWeight: 600 }}> Approver 1 comment:</span> {record?.info?.comments?.commentRejected1} */}
                                                </Typography.Text>

                                            </>
                                            :
                                           null
                                        }
                                        {
                                             record?.info?.comments?.commentApproved2 &&
                                             <>
                                                 <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '20vw', color:"green" }} >
                                                 <span style={{ fontWeight: 600 }}> Approver 2  ({record?.info?.approvers?.approverTwoName}) commented on {record.info?.comments?.commentApproved2Date} : </span> '{record?.info?.comments?.commentApproved2}'
                                                    
                                                     {/* <span style={{ fontWeight: 600  }}> Approver 2 comment:</span> {record?.info?.comments?.commentApproved2} */}
                                                 </Typography.Text>
 
                                             </>
                                        }
                                        {
                                            record?.info?.comments?.commentRejected2 ?
                                            <>
                                                <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '20vw'  , color:"red" }} >
                                                <span style={{ fontWeight: 600 }}> Approver 2  ({record?.info?.approvers?.approverTwoName}) commented on {record.info?.comments?.commentRejected2Date} : </span> '{record?.info?.comments?.commentRejected2}'
                                                    {/* <span style={{ fontWeight: 600}}> Approver 2 comment:</span> {record?.info?.comments?.commentRejected2} */}
                                                </Typography.Text>

                                            </>
                                            :
                                           null
                                        }
                                        {
                                             record?.info?.comments?.commentCancelled1 ?
                                             <>
                                                 <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '13vw',color:record?.status==='Accepted'?"green":"red" }} >
                                                     {/* <span style={{ fontWeight: 600 }}> Approver 1 comment:</span> {record?.info?.comments?.commentCancelled1} */}
                                                     <span style={{ fontWeight: 600 }}> Approver 1  ({record?.info?.approvers?.approverOneName}) commented for cancellation request on {record.info?.comments?.commentCancelled1Date}: </span> '{record?.info?.comments?.commentCancelled1}'

                                                 </Typography.Text>
     
                                             </>
                                             :null
                                        }
                                    </>
                                    :
                                    <>
                                    {
                                       

                                       <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '13vw' }} >
                                         Comment: NA
                                       </Typography.Text>
                                   }
                                    </>
                            }
                        </div>,

                };
                arr.push(obj);

            });
            setgetRequest({ ...res, ['allRequests']: arr })
        })
    }

    useEffect(() => {
        if (user?.resourceUser?.id) {
            getRequest();
        }
    }, [message, title, user?.resourceUser?.id, page, pageSize, endDate && startDate, endDate,])


    const Option = Select.Option;

    const options = ["All", "Pending", 'Approved', 'Rejected']

    const tableColumn = {
        0: {
            cols: "S.No",
            width: "5%",
            dataKey: "serialNo",
            onclick: () => { }
        },
        1: {
            cols: "Title",
            width: "15%",
            dataKey: "title",
            onclick: () => { }
        },
        2: {
            cols: "Message",
            width: "30%",
            dataKey: "message",
            onclick: () => { }
        },
        3: {
            cols: "Comment",
            width: "30%",
            dataKey: "comments",
            onclick: () => { }
        },
    }

    const handleSearchChange = (newSearch) => {
        settitle(newSearch);
        setPage(1)
    };
    const handleSearchChangeDD = (newSearch) => {
        setmessage(newSearch);
        setPage(1)
    };

    return (
        <div className={classes.request}>
            <div className={classes.header}>

                <svg style={{ cursor: "pointer", }} onClick={() => {
                    setAllNotModal(false)
                }} xmlns="http://www.w3.org/2000/svg" width="15" height="22" viewBox="0 0 15 26" fill="none">
                    <path d="M13 2L2.35355 12.6464C2.15829 12.8417 2.15829 13.1583 2.35355 13.3536L13 24" stroke="#162432" stroke-width="3" stroke-linecap="round" />
                </svg>
                <h3 style={{ color: '#000', fontSize: "1.4rem", fontWeight: "600" }}> All Updates</h3>

            </div>

            <section className={classes.requestTable}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
                    // className={classes.searchDiv}
                    >
                        <SearchCompo
                            // setSearch={settitle}
                            onSearch={handleSearchChange}
                            setSearch={handleSearchChange}
                            placeholder="Search by title" type="text" style={{ height: "2.3rem", width: "20vw", border: "1px solid #dfdfdf" }} />
                        <SearchCompo setSearch={handleSearchChangeDD}
                            onSearch={handleSearchChangeDD} type="text" placeholder="Search by message" style={{ height: "2.3rem", width: "20vw", border: "1px solid #dfdfdf" }} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", gap: "1rem", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "row", gap: "0.2rem", alignItems: "center", justifyContent: "center" }}>
                            <span>From:</span>
                            <DatePicker style={{ width: "8vw", height: "2.1rem", }}
                                value={startDate !== "" && startDate !== undefined && startDate !== null ? dayjs(startDate, dateFormat) : null}
                                placeholder="Select Date"
                                onChange={(date, dateString) => {
                                    setStartDate(dateString);
                                    setEndDate("");
                                    setPage(1)
                                }}
                            />
                        </div>{"-"}
                        <div style={{ display: "flex", flexDirection: "row", gap: "0.2rem", alignItems: "center", justifyContent: "center" }}>
                            <span>To:</span>
                            <DatePicker style={{ width: "8vw", height: "2.1rem" }}
                                value={endDate !== "" && endDate !== undefined && endDate !== null ? dayjs(endDate, dateFormat) : null}
                                disabledDate={(current) => {
                                    return current && current < moment(startDate);
                                }}
                                placeholder="Select Date"
                                onChange={(date, dateString) => {
                                    setEndDate(dateString);
                                    setPage(1)
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div>

                    {width > 800 ?
                        <TableCustom columns={tableColumn} dataSource={getRequests?.allRequests} loading={refresh} pagination={
                            {
                                total: parseInt(getRequests?.meta?.total),
                                current: parseInt(page),
                                showSizeChanger: true,
                                pageSizeOptions: [10, 20, 30, 50, 100],
                                pageSize: parseInt(pageSize),
                                onChange: (page, pageSize) => {
                                    setPage(page);
                                    setPageSize(pageSize);
                                },
                            }}
                            styles={{ height: height < 648 ? "41vh" : height < 738 ? "45vh" : "50vh" }}
                        />
                        :
                        <div className={classes.mobileDiv} style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
                            {console.log("getRequests2", getRequests2)}
                            {refresh ? <Spin style={{ display: "flex", width: "100%", height: "50vh", alignItems: "center", justifyContent: "center" }}></Spin> : getRequests?.allRequests?.length > 0 ?
                                getRequests2?.allNotifications?.map((item, index) => {
                                    console.log("ITEEEM", item)
                                    return (
                                        <div className={classes.mobCard} key={index}>
                                            <div>
                                                <div>Title:</div>
                                                <div>{item.title}</div>
                                            </div>
                                            <div>
                                                <div>Message:</div>
                                                <div>{item?.message}</div>
                                            </div>
                                            <div>
                                                <div>Comment:</div>
                                                <div>
                                                    {
                                                        item?.info?.comments ?
                                                            <>
                                                                {
                                                                    item?.info?.comments?.commentApproved1 &&
                                                                    <>
                                                                        <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '20vw', color: "green" }} >
                                                                            <span style={{ fontWeight: 600 }}> Approver 1  ({item?.info?.approvers?.approverOneName}) commented on {item.info?.comments?.commentApproved1Date} : </span> '{item?.info?.comments?.commentApproved1}'
                                                                        </Typography.Text>

                                                                    </>
                                                                }
                                                                {
                                                                    item?.info?.comments?.commentRejected1 ?
                                                                        <>
                                                                            <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '20vw', color: "red" }} >
                                                                                <span style={{ fontWeight: 600 }}> Approver 1  ({item?.info?.approvers?.approverOneName}) commented on {item.info?.comments?.commentRejected1Date} : </span> '{item?.info?.comments?.commentRejected1}'
                                                                                {/* <span style={{ fontWeight: 600 }}> Approver 1 comment:</span> {item?.info?.comments?.commentRejected1} */}
                                                                            </Typography.Text>

                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    item?.info?.comments?.commentApproved2 &&
                                                                    <>
                                                                        <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '20vw', color: "green" }} >
                                                                            <span style={{ fontWeight: 600 }}> Approver 2  ({item?.info?.approvers?.approverTwoName}) commented on {item.info?.comments?.commentApproved2Date} : </span> '{item?.info?.comments?.commentApproved2}'

                                                                            {/* <span style={{ fontWeight: 600  }}> Approver 2 comment:</span> {item?.info?.comments?.commentApproved2} */}
                                                                        </Typography.Text>

                                                                    </>
                                                                }
                                                                {
                                                                    item?.info?.comments?.commentRejected2 ?
                                                                        <>
                                                                            <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '20vw', color: "red" }} >
                                                                                <span style={{ fontWeight: 600 }}> Approver 2  ({item?.info?.approvers?.approverTwoName}) commented on {item.info?.comments?.commentRejected2Date} : </span> '{item?.info?.comments?.commentRejected2}'
                                                                                {/* <span style={{ fontWeight: 600}}> Approver 2 comment:</span> {item?.info?.comments?.commentRejected2} */}
                                                                            </Typography.Text>

                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    item?.info?.comments?.commentCancelled1 ?
                                                                        <>
                                                                            <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '13vw', color: item?.status === 'Accepted' ? "green" : "red" }} >
                                                                                {/* <span style={{ fontWeight: 600 }}> Approver 1 comment:</span> {item?.info?.comments?.commentCancelled1} */}
                                                                                <span style={{ fontWeight: 600 }}> Approver 1  ({item?.info?.approvers?.approverOneName}) commented on {item.info?.comments?.commentApproved1Date} : </span> '{item?.info?.comments?.commentCancelled1}'

                                                                            </Typography.Text>

                                                                        </>
                                                                        : null
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {


                                                                    <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '13vw' }} >
                                                                        NA
                                                                    </Typography.Text>
                                                                }
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) :
                                <NoData />
                            }

                            <Pagination position="bottomRight" size="middle" style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem" }}
                                current={parseInt(page)} showSizeChanger={true} total={parseInt(getRequests?.meta?.total)} pageSize={parseInt(pageSize)}
                                onChange={(page, pageSize) => {
                                    setPage(page);
                                    setPageSize(pageSize);
                                }}
                                onShowSizeChange={(page, pageSize) => {
                                    setPage(page);
                                    setPageSize(pageSize);
                                }}
                                pageSizeOptions={[20, 30, 50, 100, 200]}
                            />

                        </div>
                    }
                </div>
            </section>
        </div>
    )
}
export default AllNotificationModal;