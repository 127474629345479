import React, { useEffect, useState } from "react";
// import classes from './MagazineModal.module.css';
import { Modal, Spin } from "antd";
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";
import HTMLFlipBook from "react-pageflip";
import { FullscreenOutlined } from "@ant-design/icons";
 import './MagazineModal.css';
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import { useLoginStore } from "../store";
import classes from './Magazine.module.css';
import axios from "axios";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs?.version}/pdf.worker.js`;

const Magazine=()=>{
    const {magazineData,magazineDataHandler}=useLoginStore();
    const magazine=magazineData;
    const [pageNum, setPageNum] = useState();
    const [num, setNum] = useState(1);
    const [fullscreen, setFullscreen] = useState(false);
    const [magazineId, setMagazineId] = useState();
    const {width}=useWindowDimensions();
    const [loader, setLoader]=useState(true);
   

    const handleFullScreenOpen = () => {
        setFullscreen(false);
    }
    const handleFullScreenCancel = () => {
        setFullscreen(false);
        console.log('close');
        setNum(1);
    }
   

    const openFullScreenModal = () => {
        console.log("hello");
        setFullscreen(true);
        // setMagazineModal(false);
        setNum(0);
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        // console.log(numPages)
        setPageNum(numPages)
    }
    useEffect(()=>{
        axios.get('https://controlpanel.brigosha.com/api/public-apis/allEmagazine')
        .then(res => {
            if(res?.data)
            {
                // console.log("MAGRESSS",res)
                magazineDataHandler(res?.data);
                setLoader(false);
            }
           
        })
        .catch(error => {
        })
    },[])
    const Page = React.forwardRef(({ pageNumber }, ref) => {
        return (
            <div ref={ref}>
                <ReactPdfPage pageNumber={pageNumber} width={580} />
            </div>
        );
    });

    const PageCover = React.forwardRef((props, ref) => {
        return (
            <div className="page page-cover" ref={ref} data-density="hard">
                <div className="page-content">
                    <h2>{props.children}</h2>
                </div>
            </div>
        );
    });

    return(
        <>
        {loader?
        
        <Spin style={{display:"flex",alignItems:"center",justifyContent:"center",height:"80vh",}}/>:
        <div 
        style={{ height: '100%' ,
        // background: 'linear-gradient(45deg, #0086FF, #fff) ',
        width:"100vw",top:"",}}
        >
        <div>
        {/* <div className="frontmagazine" style={{ position: "absolute", top:"300px",left: '200px', font: ' bold 25px/30px Poppins',color:"white", }}>Click on the page <p>to open the magazine</p></div> */}
        {width>700?
         <Document
         file={{
             url: magazine?.[0]?.emagazine
         }}
         onLoadSuccess={onDocumentLoadSuccess}
         className={classes.magDocs}    
     >
         <HTMLFlipBook  
          style={{height:"min-content !important", 
          background: 'linear-gradient(45deg, #0086FF, #fff) ',
          paddingLeft:width>700?"3rem":"0"}}
          className="FlipbookcontentFullView"
              width={(window.innerWidth-100)/2} 
              height={(window.innerHeight)} 
             
             showCover={true}>
             {Array.from(Array(pageNum).keys()).map(i => {
                 if (i === 0) {
                     return <PageCover><Page pageNumber={i + 1} /> </PageCover>
                 } else if (i == pageNum - 1) {
                     return <PageCover>
                         <Page pageNumber={i + 1} />
                     </PageCover>
                 } else {
                     return <Page pageNumber={i + 1} />
                 }
             })}
         </HTMLFlipBook>
     </Document>:
        <div className='flipbookConatiner' style={{width:"100vw",height:"min-content",}}>
        <Document
            file={{
                url: magazine?.[0]?.emagazine
            }}
            onLoadSuccess={onDocumentLoadSuccess}
            className={classes.magDocs}
        >
            <HTMLFlipBook  
             style={{height:"min-content !important", background: 'linear-gradient(45deg, #0086FF, #fff) ',paddingLeft:width>700?"3rem":"0"}}
             className="FlipbookcontentFullView"
                 width={window.innerWidth} 
                 height={(window.innerHeight)} 
                
                showCover={true}>
                {Array.from(Array(pageNum).keys()).map(i => {
                    if (i === 0) {
                        return <PageCover><Page pageNumber={i + 1} /> </PageCover>
                    } else if (i == pageNum - 1) {
                        return <PageCover>
                            <Page pageNumber={i + 1} />
                        </PageCover>
                    } else {
                        return <Page pageNumber={i + 1} />
                    }
                })}
            </HTMLFlipBook>
        </Document>
        </div>}
    </div>
    </div>}
    </>
    )
}

export default Magazine;