import React from "react";
import classes from "./Button.module.css";
import "./Button.css";
// import back_icon from "../../assets/back_icon.svg";

import dropdown_polygon from "../../assets/dropdown_polygon.svg";
export const ModalPrimaryButton = (props) => {
  return (
    <button
      disabled={props.disabled}
      className={classes.primaryButton}
      onClick={props.onSave}
      type={props?.htmlType}
      style={props?.style}
    >
      {props.children}
    </button>
  );
};

export const ModalSecondaryButton = (props) => {
  return (
    <button
      onClick={props.onCancel}
      className={classes.secondaryButton}
      style={props?.style}
    >
      {props.children}
    </button>
  );
};

export const DownLoadButton = (props) => {
  return (
    <button
      className={classes.downloadButton}
      onClick={props?.onDownload}
      style={props?.style}
    >
      {props?.children}
    </button>
  );
};

export const ResetButton = (props) => {
  return (
    <button
    disabled={props?.disabled}
      style={props?.style}
      type="reset"
      className={classes.resetButton}
      onClick={props?.onClick}
    >
      {props.icon} {props.title}
    </button>
  );
};

export const SelectButton = ({ children, placeholder, style, onClick,value,id }) => {
  return (
    <div style={{ ...style }} className={classes.selectContainer}>
      <select
      id={id}
        className={classes.selectBox}
        placeholder={placeholder}
        onChange={onClick}
        // value={undefined}
      >
        {placeholder?
        <option>Select</option>:null}
        {children}
      </select>
      <div className={classes.iconContainer}>
        <img src={dropdown_polygon} />
      </div>
    </div>
  );
};

export const BackButton = ({ children, onClick, style }) => {
  return (
    <button style={style} className={classes.backButton} onClick={onClick}>
      {/* <img src={back_icon} /> */}
      {children}
    </button>
  );
};

export const CarouselArrowButton = ({ style, icon, onClick,type }) => {
  return (
    <button style={style} onClick={onClick}  >
      <img src={icon} />
    </button>
  );
};
