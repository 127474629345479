import Api from "../../services/Api";

export const postLandingInfo = (param, callback) => {
console.log(param,"parammm");
    Api.post("/hiring-mobile/landingPageInfo").params(param).send((res) => {
        if (res) {
            callback(res)
        }
    })
}


export const postGuestUserData = (param, callback) => {

    Api.post("/hiring-mobile/saveGuestDetails").params(param).send((res) => {
        if (res) {
            callback(res)
        }
    })
}

export const postUserJoined = (param, callback) => {
    console.log("insideaction");
    Api.post("/hiring-mobile/updateMeetingGuestDetails").params(param).send((res) => {
        callback(res)
          
            
        
    })
}

export const getUserJoined = (param, callback=()=>{}) => {

    Api.post("/hiring-mobile/getAttendees").params(param).send((res) => {
            if(res)
            callback(res)
        
    })
}
export const UploadFile=(param,callback)=>{
    
    Api.post('/hiring-mobile/uploadWithUrl').upload(param,(per,res)=>{
        console.log("ress",res,"percent",per)
        callback(res?.data,per);
    })

}