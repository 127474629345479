import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import SiderLayout from "../../components/siderLayout";
import UsersActive from "../../assets/Appraisal/users_active.svg";
import UsersInactive from "../../assets/Appraisal/users_Inactive.svg";
import TeamActive from "../../assets/Appraisal/team_active.svg";
import TeamInactive from "../../assets/Appraisal/team_Inactive.svg";
import AppraisalCycleActive from "../../assets/Appraisal/appraisalCycle_active.svg";
import AppraisalCycleInactive from "../../assets/Appraisal/appraisalCycle_inactive.svg";
import RoleLockActive from "../../assets/Appraisal/roleLock_active.svg";
import RoleLockInactive from "../../assets/Appraisal/roleLock_Inactive.svg";
import RoleLock from "./admin/RoleLock";
import { useNavigate } from "react-router";
import { SECRET_KEY, decryptData } from "../../services/fileCode";
import { useHeaderStore } from "../Header/store";

const AppraisalApp = () => {
  const { navigateTo } = useSelector((state) => state.global);
  const [showNav, setShowNav] = useState();
  const path = window.location.pathname;
  const { loader } = useSelector((state) => state.global);
  // const [roleModal, setRoleModal] = useState(false);
  const {roleModal, setRoleModal}=useHeaderStore();
  const [decryptedUserData, setDecryptedUserData] = useState({});

  const navigate = useNavigate();
  const userData = localStorage.getItem("AppraisalUserData");

  // useEffect(() => {}, []);

  useEffect(() => {
    setShowNav(path.startsWith("/appraisal/admin"));
    // setRoleModal(path === "/appraisal/admin/roleLock");
    // return setShowNav(false)
  }, [path]);

  console.log("ssssPath",path)


  useEffect(() => {
    const windowPath = window.location.pathname
    if(windowPath === '/appraisal'){
      navigateToPath()
    }
  }, []);
 

  const navigateToPath = async () =>{
    let linkR = await navigatePath()
    navigate(linkR);
    
  }

  const navigatePath = async () => {
    let dUserData = await JSON.parse(decryptData(userData, SECRET_KEY));
    if (await dUserData?.role?.includes("Admin")) {
      return "/appraisal/admin/users";
    } else if (await dUserData?.role?.includes("Manager")) {
      return "/appraisal/manager";
    } else if (await dUserData?.role?.includes("Associate")) {
      return "/appraisal/associate";
    }
    else{
      return "/appraisal/associate";
    }
  };
 
  const navUrl = [
    {
      title: "Users",
      url: "admin/users",
      inactive: UsersInactive,
      active: UsersActive,
    },
    {
      title: "Appraisal Cycle",
      url: "admin/appraisalLink",
      inactive: AppraisalCycleInactive,
      active: AppraisalCycleActive,
    },
    {
      title: "Team",
      url: "admin/team",
      inactive: TeamInactive,
      active: TeamActive,
    },
    {
      title: "Role lock",
      // url: "admin/roleLock",
      inactive: RoleLockInactive,
      active: RoleLockActive,
    },
  ];

  const handleCancel = () => {
    navigate(path);
    setRoleModal(false);
  };

  return (
    <>
      <SiderLayout navList={navUrl} noNavBar={showNav ? undefined : true} />
      {/* <LoadingModal visible={loader} /> */}
      <RoleLock
        // handleLock={handleOk}
        handleCancel={handleCancel}
        roleModal={roleModal}
        // setRoleModal={setRoleModal}
      />
    </>
  );
};

export default AppraisalApp;
