import React from "react";

import "./donutChart.css";
import { useWindowDimensions } from "../windowDimention/windowDimension";

const DonutChart = (props) => {
  const {width} =useWindowDimensions();
  const viewBoxWidth = '600'
  const viewBoxHeight = '600'
  const cx = viewBoxWidth / 2;
  const cy = viewBoxHeight / 2;
  const strokeWidth = "2.8rem";
  const radius = 200;
  const dashArray = 2 * Math.PI * radius;
  const startAngle = -90;
const prefix=props?.prefix?props?.prefix:'';
  let filled = 0;
  const sum = props?.data?.reduce((sum, item) => {
    return sum + item.value;
  }, 0);

  const ratio = 100 / sum;

  props?.data?.forEach((obj) => {
    const itemRatio = ratio * obj.value;

    obj.itemRatio = itemRatio;
    obj.angle = (filled * 360) / 100 + startAngle;
    obj.offset = dashArray - (dashArray * itemRatio) / 100;
    obj.filled = filled;

    filled += itemRatio;
  });


  return (
    <div className="donut-chart" style={props?.project?{width:width<700?"100%":"60%"}:props?.style}>
      <svg viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}` }>
        {props?.data?.map((item, index) => (
          
          <>
        
            <circle
              key={index}
              cx={cx}
              cy={cy}
              r={radius}
              fill="transparent"
              strokeWidth="2rem"
              stroke="#fff"
              strokeDasharray={dashArray}
              strokeDashoffset={item.offset}
              transform={`rotate(${item.angle} ${cx} ${cy})`}
              strokeLinecap="round"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                from={`${startAngle} ${cx} ${cy}`}
                to={`${item.angle} ${cx} ${cy}`}
                dur="1s"
              />
            </circle>
            <circle
              key={index}
              cx={cx}
              cy={cy}
              r={radius}
              fill="transparent"
              strokeWidth={strokeWidth}
              stroke={item.color}
              strokeDasharray={dashArray}
              strokeDashoffset={item.offset}
              transform={`rotate(${item?.angle} ${cx} ${cy})`}
              strokeLinecap="round"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                from={`${startAngle} ${cx} ${cy}`}
                to={`${item?.angle} ${cx} ${cy}`}
                dur="1s"
              />
            </circle>
          </>
        ))}
      </svg>
      <ul className="items-names" >
        {props?.data?.map((item, index2) => (
          <li key={index2} style={{display:'flex',flexDirection:'column',justifyContent:'space-between',margin:2}}>
            <div
              style={{
                display: "flex",
                
                justifyContent: "space-between",
                paddingRight: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "1rem",
                }}
              >
                <div
                  style={{
                    width: props?.mwidth<700?"1rem":"2rem",
                    height: props?.mwidth<700?"0.5rem": "1rem",
                    background: `${item.color}`,
                    borderRadius: "3px",
                  
                  
                   
                  }}
                />{" "}
                <span style={{color:"#878787",fontSize:props?.mwidth<700?'0.6rem':"0.8rem"}}>{item.name}</span>
              </div>
              <span style={{color:"#878787",fontSize:props?.mwidth<700?'0.6rem':"0.8rem"}}>{item?.value}{prefix}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DonutChart;
