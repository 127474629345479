import React, { useEffect, useState } from "react";
import classes from "./ReleaseModal.module.css";
// import ModalCompo from "../../../../design/ModalCompo";
// import { useTeamStore } from "../Store/Store";
import release_red_icon from "../../../../assets/release_red_icon.svg";
import {getCheckTaskApi, getTeamByDepartment } from "../Action";
import {
  ModalPrimaryButton,
  ModalSecondaryButton,
} from "../../../../components/Button/Button";
import { Radio, Button } from 'antd';
import { DatePicker, Form, Select,Input, Spin, Space } from "antd";
import Api from "../../../../services/Api";
import {
  getApplyFilterData,
  getTeamDropDataForRelease,
  memberReleaseFromTeamApi,
} from "../Action";
import { useGlobalStore } from "../../../../store";
import Modal from "../../../../components/Modal/Modal";
import { useNavigate } from "react-router-dom";
import { useFlagButtonStore, useTaskReleaseStore } from "../../Release/store";
import { useManageTasks } from "../../Release/store";
import { getAllProjectsCountApi } from "../../Projects/ProjectAction";



const ReleaseModal = ({ team, setLoader, department, role, memberId,memberName,deptId,teamsByDepartment,refresh , activeTaskCount}) => {
  const {
    isReleaseModal,
    releaseModalHandler,
    teamDropDataForRelease,
    teamDropDataForReleaseHanlder,
    applyFilterDataHandler,
    checkTaskHandler,
    checkTask,
  } = useGlobalStore();



  const {userTaskReleaseId,
    userTaskReleaseDepId,
    finalTaskCount,
    setUserTaskReleaseId,
    setUserTaskReleaseDepId,
    setFinalTaskCount,
    getUserReleaseId,
    getUserReleaseDepId,
} = useTaskReleaseStore(state =>state);

const {tasksUserId, setTasksUserId, tasksUserName, setTasksUserName, setTasksTeamId, tasksTeamId, 
  setEnableTransfer,setTasksTeamName, setFiltersList,filtersList,setSelectedTeamFilters, setSelectedAssociateFilters, setProjectsCount}  = useFlagButtonStore(state=>state);

const {manageTasks, setManageTasks} = useManageTasks(state=>state);
  // const {TextArea} = Input;
  const [reason, setReason] = useState("");
  const [page, setPage] = useState(1);
  const [assignToId, setAssignToId] = useState("");
  const [form] = Form.useForm();
  const[pageLoader,setPageLoader]=useState();
  console.log("checkTask",isReleaseModal);
  console.log(checkTask,"II");
  useEffect(() => {
    if(isReleaseModal)
    {
      getTeamDropDataForRelease(
        teamDropDataForReleaseHanlder,
        isReleaseModal?.teamId,
        isReleaseModal?.id,
        Api
      );

    }
   
  }, [isReleaseModal]);

  useEffect(()=>{
    setPageLoader(true);
    if(memberId&&isReleaseModal)
    {
      getCheckTaskApi(memberId,Api,(res)=>{
        checkTaskHandler(res);
        setPageLoader(false);
      });
    } 
  },[memberId,isReleaseModal])
  const contentStyle = {
    width: "35rem",
    marginTop: "10%",
  };
  const handleReasonChange = (e) => {
    setReason(e.target.value);
    console.log("reason", e.target.value);
  };
  console.log("qqqqqq",team);
  
  const [visible, setVisible] = useState(true); // Controls visibility of the modal
  const [selectedOption, setSelectedOption] = useState(null); // Stores the selected radio button

  const handleCancel = () => {
   
    setVisible(false);
  };

  const handleNext = () => {
    // Handle the next action here
    console.log('Selected Option:', selectedOption);
    setVisible(false);
  };


  const navigate = useNavigate();
  const handleClick = () => {
      
      setManageTasks(true);
      releaseModalHandler(false);
      //setTasksUserId(memberId);
      setTasksUserName(memberName);
      //setTasksTeamId(isReleaseModal?.teamId);
      setFiltersList({teamId: isReleaseModal?.teamId+'',associateId: memberId+''});
      setSelectedAssociateFilters([memberId+''])
      setSelectedTeamFilters([isReleaseModal?.teamId+''])
      setTasksTeamName(isReleaseModal?.teamName);
      setEnableTransfer(true);
      let param ={}
      getAllProjectsCountApi(param,res=>{
        setProjectsCount(res?.meta?.totalItems);
      })
      navigate(`/project/projects`);
      
  };



  return (
    <Modal
      show={isReleaseModal}
      onCancel={() => {
        releaseModalHandler(false);
      }}
      //contentStyle={contentStyle}
      closableRight={true}
      header={
        <div className={classes.modalHeader}>
          {/*<img src={release_red_icon} />*/}
          <h3 style={{color:'#EE4E4E'}}>Release</h3>
        </div>
      }
    //   footer={
    //     <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}> {/* Flex layout to avoid overlap */}
    //   <Button
    //     key="cancel"
    //     onClick={handleCancel}
    //     style={{
    //       cursor: 'pointer',        // Ensures the hand cursor is visible
    //       padding: '10px 20px',     // Adds some padding
    //       width: 'auto',            // Adjusts width to content
    //     }}
    //   >
    //     Cancel
    //   </Button>
    //   <Button
    //     key="next"
    //     type="primary"
    //     disabled={!selectedOption}
    //     onClick={handleNext}
    //     style={{
    //       cursor: 'pointer',
    //       padding: '10px 20px',
    //       width: 'auto',
    //     }}
    //   >
    //     Next
    //   </Button>
    // </div>
    
    //   }
    >
      <div className={classes.wrapper}>
       {pageLoader?<Spin style={{margin:"15.4% 50%"}}></Spin>:
      <>
      {(page === 1&&checkTask &&(activeTaskCount!==0)) && (
        // <div className={classes.container}>
        //   <h3 style={{ textAlign: "center",fontSize:"1rem" }}>
        //     Please close all the Task or reassign the task to another associate and then release.
        //   </h3>
        //   <Form
        //     layout="vertical"
        //     style={{
        //       display: "flex",
        //       flexDirection: "column",
        //       width: "90%",
        //       marginTop: "1rem",
        //     }}
        //     form={form}
        //     onFinish={(event) => {
        //       event = { ...event, date: event["date"]?.format("YYYY-MM-DD") };
        //       console.log("DatePicker", event);
        //     }}
        //   >
        //     <Form.Item name="team_member"  rules={[{required:true,message:"Please select a member!"}]} label={<span style={{fontWeight:"600"}}
        //     >Team Members</span>}
        //     style={{width:"40%",margin:"1rem auto"}}
        //     >
        //       <Select
        //       size="large"
        //       allowClear={true}
        //         placeholder="Select"
        //         onChange={(event) => {
        //           setAssignToId(event);
        //         }}
        //       >
        //         {teamDropDataForRelease?.members?.map((item, index) => (
        //           <Select.Option value={item.id}>{item.name}</Select.Option>
        //         ))}
        //       </Select>
        //     </Form.Item>
        //     <div className={classes.buttonDiv} style={{margin:"3rem auto 5rem auto"}}>
        //       <ModalPrimaryButton
        //         htmlType="submit"
        //         onSave={() => {
        //           if(form.getFieldValue(["team_member"]))
        //           {
        //             setPage(2);

        //           }
                 
        //         }}
        //       >
        //         Next
        //       </ModalPrimaryButton>
        //       <ModalSecondaryButton
        //         onCancel={() => {
        //           releaseModalHandler(false);
        //         }}
        //       >
        //         Cancel
        //       </ModalSecondaryButton>
        //     </div>
        //   </Form>
        // </div>
       <div className={classes.container}>
       <div style={{ textAlign: "center",fontSize:"1rem", fontFamily:'Roboto', gap:'20px', display:'flex', flexDirection:'column', justifyContent:'space-around' }}>
          <p style={{color:'#555555'}}>There are {activeTaskCount} active task(s) for the associate. </p>
          <p style={{color:'#555555'}}>The associate must have no active tasks assigned in order to proceed with their release. Please reassign or manage their tasks before moving forward.</p>
          <div style={{display:'flex', flexDirection:'column',justifyContent:'center', alignItems:'center'}}><Button type="primary" onClick={handleClick} style={{backgroundColor:'#1089FF', fontFamily:'Roboto'}}>Manage Task(s)</Button></div>
       </div>
       <Form
         layout="vertical"
         style={{
           display: "flex",
           flexDirection: "column",
           width: "100%",
           marginTop: "1rem",
         }}
         form={form}
        //  onFinish={(event) => {
        //    event = { ...event, date: event["date"]?.format("YYYY-MM-DD") };
        //    console.log("DatePicker", event);
        //  }}
       >
         {/* <Form.Item name="team_member_"  rules={[{required:true,message:"Please select a member!"}]} 
         
         >
          <Radio.Group onChange={(e) => setSelectedOption(e.target.value)} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
            <Radio value="reassign">Re-assign active tasks</Radio>
            <Radio value="close">Close active tasks</Radio>
          </Radio.Group> */}


           {/* <Select
           size="large"
           allowClear={true}
             placeholder="Select"
             onChange={(event) => {
               setAssignToId(event);
             }}
           >
             {teamDropDataForRelease?.members?.map((item, index) => (
               <Select.Option value={item.id}>{item.name}</Select.Option>
             ))}
           </Select>
         </Form.Item> */}
         <div className={classes.buttonDiv}  style={{margin:"0rem 0rem 0rem 30rem"}}>
         <ModalSecondaryButton
             onCancel={() => {
               releaseModalHandler(false);
             }}
           >
             Cancel
           </ModalSecondaryButton>
           <ModalPrimaryButton
             htmlType="submit"
             onSave={() => {
              //  if(form.getFieldValue(["team_member"]))
              //  {
              //    setPage(2);

              //  }
               if(selectedOption === 'close'){
                setPage(2);
               }
               if(selectedOption === 'reassign'){
                setPage(3);

               }
              
             }}
             disabled
           >
             Next
           </ModalPrimaryButton>
           
         </div>
       </Form>
     </div>
      )}
      {(page === 2||(page===1&&!checkTask)||(activeTaskCount==0))? (
        <div className={classes.container}>
          <Form
          layout="vertical"
            className={classes.form}
            form={form}
            onFinish={(event) => {
              event = { ...event, date: event["date"]?.format("YYYY-MM-DD") };
              memberReleaseFromTeamApi(
                reason,
                isReleaseModal?.teamId,
                memberId,
               checkTask? assignToId:undefined,
                event["date"],
                Api
              );
              getApplyFilterData(
                  applyFilterDataHandler,
                  1,
                  department?.id,
                  role,
                  team?.manager?.id,
                  team?.id,
                  "",
                  "",
                  setLoader,
                  Api
                  );
            getTeamByDepartment(teamsByDepartment, deptId, Api);
            releaseModalHandler(false);
            refresh()
            }}
          >
            <Form.Item name="date" label={<span style={{fontWeight:"600"}}>Select Date to Release</span>} rules={[{required:true,message:"Please select the date !"}]}>
              <DatePicker className={classes.datePicker} size="large" />
            </Form.Item>
           
            <Form.Item name="reason" label={<span style={{fontWeight:"600"}}>Reason</span>} rules={[{required:true,message:"Please enter the reason!"}]}>
              <Input.TextArea
                rows={4}
                value={reason}
                onChange={handleReasonChange}
              />
            </Form.Item>
            <div className={classes.buttonDiv}>
              <ModalPrimaryButton style={{ width: "6rem" }} htmlType="submit">
                Save
              </ModalPrimaryButton>
              <ModalSecondaryButton
                style={{ width: "6rem" }}
                onCancel={() => {
                  if((page===1&&!checkTask))
                  {
                    releaseModalHandler(false);
                  }
                  else
                  {
                    setPage(3);
                  }
                 
                }}
              >
                {(page===1&&!checkTask)?"Cancel":"Back"}
              </ModalSecondaryButton>
            </div>
          </Form>
        </div>
      ):null}
      {(page === 3) &&(
                <div className={classes.container}>
          <h3 style={{ textAlign: "center",fontSize:"1rem" }}>
            Please close all the Task or reassign the task to another associate and then release.
          </h3>
          <Form
            layout="vertical"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "90%",
              marginTop: "1rem",
            }}
            form={form}
            onFinish={(event) => {
              event = { ...event, date: event["date"]?.format("YYYY-MM-DD") };
              console.log("DatePicker", event);
            }}
          >
            <Form.Item name="team_member"  rules={[{required:true,message:"Please select a member!"}]} label={<span style={{fontWeight:"600"}}
            >Team Members</span>}
            style={{width:"40%",margin:"1rem auto"}}
            >
              <Select
              size="large"
              allowClear={true}
                placeholder="Select"
                onChange={(event) => {
                  setAssignToId(event);
                }}
              >
                {teamDropDataForRelease?.members?.map((item, index) => (
                  <Select.Option value={item.id}>{item.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <div className={classes.buttonDiv} style={{margin:"3rem auto 5rem auto"}}>
              <ModalPrimaryButton
                htmlType="submit"
                onSave={() => {
                  if(form.getFieldValue(["team_member"]))
                  {
                    setPage(2);

                  }
                 
                }}
              >
                Next
              </ModalPrimaryButton>
              <ModalSecondaryButton
                style={{ width: "6rem" }}
                onCancel={() => {
                  if((page===1&&!checkTask))
                  {
                    releaseModalHandler(false);
                  }
                  else
                  {
                    setPage(1);
                  }
                 
                }}
              >
                {(page===1&&!checkTask)?"Cancel":"Back"}
              </ModalSecondaryButton>
            </div>
          </Form>
        </div>
      )}
      </>}
      </div>
   
    </Modal>
    
  );
};
export default ReleaseModal;
