import Api from "../../../services/Api";


export const getLogsData = (params, setter, loader) => {
    if (loader)
        loader(true)
    Api.get(`/attendance-logs`)
        .params(params)
        .send((response) => {
            if (response) {
                if (setter)
                    setter(response)
            }
            if (loader) {
                loader(false);
            }
        })
}

export const getRecalculateApi = (params, setter, loader) => {
    if (loader)
        loader(true)
    // Api.get(`/scheduler/run`)
      Api.get('/attendance-attendance/recalculate')
        .params(params)
        .send((response) => {
            if (response) {
                if (setter)
                    setter(response)
            }
            if (loader) {
                loader(false);
            }
        })
}


export const DownLoadAttendnaceLogs = (successCallBack, url, params = null,ref=null) => {
    console.log("sssREfff",ref)
    Api.get(url)
        .params(params)
        .send((response) => {
            if (response !== undefined && response) {
                if(ref===1){
                window.open(response, "_blank")?.focus();
                }
                else if(ref === 2){
                window.open(response.file, "_blank")?.focus();
                }
                else{
                window.open(response.file, "_blank")?.focus();
                }

                if (successCallBack) {
                    successCallBack()
                }
            }
        });
}


export const getLogsDetails = (setter, params,loader) => {
    if (loader)
    loader(true)
    Api.get(`/attendance-attendance/calendar?userId=${params.userId}&month=${params.month}&year=${params.year}&download=${params.download}`)
        .params({})
        .send((response) => {
            if(!params.download){
                if (response) {
                    if(setter)
                    setter(response)
                }
            }else{
                if (response && response.file) {
                    window.open(response.file, "_blank")?.focus();
                }
                // window.open(response.file, "_blank")?.focus();
            }
            if(loader){
                loader(false)
            }
        });
}

// export const searchLogs =(params,callBack)=>{
//     Api.get(`/attendance-attendance/search?search=${params.search}`)
//     .params({})
//     .send((response) => {
//         if (response) {
//             callBack(response)
//         }
//     });
// }

export const updateAttendanceRest = (params,onSuccess) => {
Api.delete(`/attendance-attendance/reset-logs?userId=${params.userId}&date=${params.date}&effort=${params?.effort}`)
.params({})
.send((response) => {
    if (response?.show?.type==="success") {
        console.log("insideSuccess")
       onSuccess(false);
    }else if(response?.show?.type==="error"){
        onSuccess(false);
    }
})
}

export const applyLeaveModalApi = (params,callBack)=>{
    // console.log("params:",params)
    Api.get(`/attendance-attendance/pendingLeaves?userId=${params.userId}&year=${params?.year}`)
        .params({})
        .send((response) => {
            if (response) {
                callBack(response)
            }
        });
}


export const userSelectCalendar=(callBack)=>{
    Api.get("/attendance-attendance/autocomplete-users").send(res=>{
        if(res){
            callBack(res)
        }
    })
}

export const getGeoFence = (params,setter) => {
    Api.get(`/attendance-logs/geolocation?userId=${params.userId}&year=${params.year}&month=${params.month}`)
        .params({})
        .send((response) => {
            if (response) {
                if(setter)
                setter(response)
            }
        });
}
export const getLeavesData = (params, setter, loader = null) => {
    if (loader)
        loader(true)
    Api.post('/attendance-attendance/leaveSummary')
        .params(params)
        .send((response) => {
            if (response) {
                // if (params.download && response) {
                //     // let fileName = response;
                //     // fileDownload(response, fileName);
                //     window.open(response, "_blank").focus(); 
                // }
                if (setter) setter(response);
            }
            if (loader) {
                loader(false);
            }
            // if(params.download){
            //     let fileName = response;
            //     fileDownload(response, fileName);
            // }
            // if (response) {
            //     if (setter)
            //         setter(response)
            //     }
            //     if (loader) {
            //         loader(false);
            //     }
            })
}

export const getDownloadData = (params, setter, loader = null) => {
    if (loader)
        loader(true)
    Api.post('/attendance-attendance/leaveSummary')
        .params(params)
        .send((response) => {
            if (response !== undefined) {
                if (setter){
                    setter(response);
                }
            }
        });
}

export const getAssociateSummary = (successCallBack, params = null) => {
    Api.get(`/attendance-attendance/userLeaves?userId=${params.userId}&type=${params.type}`)
        .params({})
        .send((response) => {
            if (response !== undefined) {
                if (successCallBack) {
                    successCallBack(response)
                }
            }
        });
    }
export const getManualPunch = (params,onSuccess) => {
    Api.post(`/attendance-attendance/applyMultipleManualPunch`)
    .params(params)
    .send((response) => {
        if (response?.show?.type==="success") {
            onSuccess(false);
        }else if (response?.show?.type==="error") {
            // console.log("insideSuccess Manual")
            onSuccess(false);
        }
    });
}

export const updateAttendance=(params,onSuccess) => {
    Api.post(`/attendance-attendance/applyManualPunch`)
    .params(params)
    .send((response) => {
        console.log("resss",response?.error);
        if (response?.show?.type==="success") {
            console.log("insideSuccess")
           onSuccess(false);
        }else if(response?.show?.type==="error"){
            onSuccess(false);
        }
    });
}

export const carryForwardButton = () =>{
    Api.post(`/attendance-attendance/carryForward`)
    .send((response) => {
        console.log("resss",response);
    });
}

export const applyLeave = (params,callback) =>{
    Api.post(`/attendance-attendance/updateLeave`)
    .params(params).send((response) => {
      if(response)
      callback()
    });
}

export const getOptionalHoliday = (params,callback) =>{
    Api.post(`/attendance-attendance/getHohidays`)
    .params(params).send((response) => {
      if(response)
      callback(response)
    });
}