import { Row, Button, Space, Pagination, Spin , Tooltip} from "antd";
import React, { useEffect, useState } from "react";
// import deleteIcon from "../../../assets/Vectordelete.svg"

import edit from "../../../assets/editpen.svg"

// import ModalCompo from "../../../design/ModalCompo"
import Modal from "../../../components/Modal/Modal";
import AddDept from "./AdddeptContent";
import { IoMdContact } from "react-icons/io";
import { ModalPrimaryButton, ModalSecondaryButton } from "../../../components/Button/Button";
import { deleteDeptApi, getDepartmentApi, getHodDetailsApi } from "./Actions";
import { BsPersonFillAdd } from "react-icons/bs";
import Api from "../../../services/Api";
import { useDepartmentStore } from "./store";
import SearchCompo from "../../../design/SearchCompo";
import TableCustom from "../../../components/tableCustom/customTable";
import editIcon from '../../../assets/editicon.svg'
import deleteIcon from "../../../assets/deleteiconWB.svg"
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import { MobileCard } from "../Release/Release";





const Department = () => {
  const [openModal, setModal] = useState(false)
  const [isEditDepartment, setIsEditDepartment] = useState(false);
  const [deletableDept, setDeletableDept] = useState(false);
  const [openDelete, setDelete] = useState(false)
  const [editableDept, setEditableDept] = useState("");
  const [loader, setLoader] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const { width } = useWindowDimensions();

  const { hodDetailsHandler, departmentDataHandler, departmentData } = useDepartmentStore();
  const header = (
    <div style={{ display: 'flex', width: "100%", justifyContent: "flex-start", marginLeft: -30 }}>
      <h3 style={{ color: "#162432", fontWeight: 600, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
        {isEditDepartment ? "Edit Department" : "Add Department"}
      </h3>
    </div>

  )

  const Actions = ({ element }) => {
    return (
      <Space>
          <Tooltip title={width<700?null:"edit"}>
          <Button  style={{ height: 60, width: 60, display: "flex", alignItems: "center", justifyContent: "center" }} type="text" onClick={() => {
          setIsEditDepartment(true);
          setEditableDept(element)
          setModal(true)
        }}>
          <img style={{ height: width < 700 ? 30 : 40, width: width < 700 ? 30 : 40 }} src={editIcon} />
        </Button>
          </Tooltip>
       <Tooltip  title={width<700?null:"delete"} >
        <Button style={{ height: 60, width: 60, display: "flex", alignItems: "center", justifyContent: "center" }} type="text" onClick={() => {
          setDeletableDept(element);
          setDelete(true);
        }}>
          <img style={{ height: width < 700 ? 30 : 40, width: width < 700 ? 30 : 40 }} src={deleteIcon} />
        </Button>

       </Tooltip>
      </Space>
    )
  }

  const tableHeightVH = 62;

  const scrollConfig = {
    y: `${tableHeightVH}vh`,
  };
  const tableColumn = {
    0: {
      cols: "Head of Department",
      width: "45%",
      dataKey: "headc",
      onclick: () => { }
    },
    1: {
      cols: "Contact Details",
      width: "60%",
      dataKey: "contactDetails",
      onclick: () => { }
    },
    2: {
      cols: "Department",
      width: "25%",
      innerWidth:"7rem",
      dataKey: "name",
      onclick: () => { }
    },
    3: {
      cols: "Actions",
      width: "40%",
      dataKey: "actions",
      onclick: () => { }
    },
  }


  const handleSearch = (newSearch) => {
    setSearch(newSearch);
    setPaginationPage(1);
  };


  const refresh = () => {
    getDepartmentApi(Tooltip,Actions, IoMdContact, departmentDataHandler, setLoader, pageSize, paginationPage, Api,search);
  }

  useEffect(() => {
    getDepartmentApi(Tooltip,Actions, IoMdContact, departmentDataHandler, setLoader, pageSize, paginationPage, Api, search);
  }, [pageSize, paginationPage, search])

  /*   useEffect(()=>{
      setSearchData(searchDepartments(departmentData?.allDepartments,search))
    },[search]) */

  useEffect(() => {
    getHodDetailsApi(hodDetailsHandler, Api)
  }, [])

  return (
    <div style={{ maxWidth: "99.5%" }}>
      <Row style={{ margin: "1.2rem", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }} >
        <h3 style={{ fontSize: width < 700 ? "1.3rem" : "1.5rem", }}>Departments</h3>
        <div style={{ display: "flex", justifyContent: "space-between", width: width < 700 ? "100%" : "40%" }}>
          <SearchCompo style={{ width: width < 700 ? "100%" : "60%" }} onSearch={handleSearch} setSearch={handleSearch}></SearchCompo>
          <Button style={{ backgroundColor: "#0086FF", color: "white", alignItems: 'center', display: 'flex', fontSize: "0.9rem",height:"2.1rem", marginLeft:width<700?10:null }}  onClick={() => {
            setModal(true)
            setIsEditDepartment(false);
          }}>
            <BsPersonFillAdd />
            <div style={{ paddingLeft: 4 }}>Add Department</div>
          </Button>
        </div>

      </Row>
      {
        width < 700 ?
          <>
            {
              loader ?
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height:"100%" }}>
                  <Spin />
                </div>
                :
                <>
                <div style={{display:"flex", flexDirection:"column", overflow:"scroll", height:"70vh",backgroundColor:"#F9F9F9" }}>
                  {
                    departmentData?.allDepartments?.map((item, index) => (
                      <>
                        <MobileCard headerLeft={item?.headc} headerRight={item?.actions} body={(
                          <>
                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                              <div style={{ width: "30%", color: "#B1B0B0" }}>Email : </div>
                              <div style={{ width: "60%", display: "flex", justifyContent: "flex-end" }}>{item?.email}</div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                              <div style={{ width: "30%", color: "#B1B0B0" }}>EmployeeId : </div>
                              <div style={{ width: "60%", display: "flex", justifyContent: "flex-end" }}>{item?.employeeId}</div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                              <div style={{ width: "30%", color: "#B1B0B0" }}>Department : </div>
                              <div style={{ width: "60%", display: "flex", justifyContent: "flex-end" }}>{item?.name}</div>
                            </div>
                          </>
                        )} />
                      </>
                    ))
                  }
                </div>
                  <div style={{ display: "flex", justifyContent: "flex-end", padding: 10 }}>

                    <Pagination responsive={true} onChange={(page, pageSize) => {
                      setPaginationPage(page);
                      setPageSize(pageSize);
                    }} showSizeChanger={true} pageSizeOptions={[10, 20, 30, 50, 100]} total={departmentData?.meta?.totalItems} current={parseInt(paginationPage)} />
                  </div>
                </>

            }
          </>


          :
          <TableCustom columns={tableColumn} dataSource={departmentData?.allDepartments} loading={loader} pagination={
            {
              total: departmentData?.meta?.totalItems,
              current: parseInt(paginationPage),
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 30, 50, 100],
              pageSize: departmentData?.meta?.itemsPerPage,
              onChange: (page, pageSize) => {
                setPaginationPage(page);
                setPageSize(pageSize);
              },
            }} />
      }


      {openModal && <Modal closableRight={true} style={{ width: width < 700 ? "100%" : "50vw", }} show={openModal} onCancel={() => {
        setModal(false)
        setDelete(false);
        setIsEditDepartment(false);
      }} header={header}>
        <AddDept
          managementDept={departmentData?.managementDept}
          isEditDepartment={isEditDepartment}
          editableDept={editableDept}
          setModal={setModal}
          refresh={refresh}
          departmentDataHandler={departmentDataHandler}
          setLoader={setLoader}
        />
      </Modal>}
      {openDelete ? <Modal style={{ width: width < 700 ? "100%" : "45vw" }} closableRight={true} show={openDelete} onCancel={() => {
        setModal(false)
        setDelete(false);
        setIsEditDepartment(false);
      }} header={
        <div style={{ width: "100%", display: "flex", flexDirection: 'row', gap: "0.3rem", justifyContent: "center", alignItems: "center", }}>
          <img src={deleteIcon} style={{ width: "1.2rem", height: "1.2rem" }} />
          <h3 style={{ color: "#F96363", }}>Delete Department</h3>
        </div>

      }>
        <div style={{ justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <span style={{ fontWeight: "600" }} >Are You sure you want to delete <b style={{ color: '#0086FF' }}>"{deletableDept?.name}"</b> department?</span>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: 20 }}>
            <ModalPrimaryButton onSave={() => {
              deleteDeptApi(Api, deletableDept?.id);
              refresh();
              setDelete(false);
            }}>Yes</ModalPrimaryButton>
            <ModalSecondaryButton style={{ marginLeft: "5%", backgroundColor: "white" }} onCancel={() => setDelete(false)}>No</ModalSecondaryButton>
          </div></div>
      </Modal> : ""}
    </div>
  )
}
export default Department;
