import React, { useState,useEffect } from 'react';
import { Spin } from 'antd';
import Api from "../../services/Api";
import Mailicons from "../../assets/MailIcons.svg";



const ReactivationAccount = () =>{
  const [loader,setLoader] = useState(true);
  let params= new  URLSearchParams(window.location.search)
  const userLoginId = params.get("userLoginId");
  const token = params.get("token");
  const [status,setStatus]=useState(false);
  const [msg,setMsg]=useState()
  useEffect(()=>{

        setTimeout(() => {
            Api.post(`/auth-auth/authorizeReactivation`).params({userLoginId:userLoginId,token:token})
            .send((res)=>{
                setLoader(false)
                if(res?.show?.type === "success"){
                  setStatus(true);
                }
                else{
                  setStatus(false);
                  setMsg(res?.show?.message)
                }
            })
        }, 1000);
    },[]);


  return (<div style={{height:"100vh",width:"100%",display:"flex",flexDirection:"column",gap:"0.3rem",alignItems:"center",justifyContent:"center"}}>
        {
        loader?<Spin style={{display:"flex",alignItems:"center",justifyContent:"center",height:"100vh"}}/>:
        status? <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                <img src={Mailicons} alt="Mail" />
                
                <h2>Success!</h2>
                <span style={{fontSize:"0.96vw"}}>The user's account has been re-activated successfully.</span>
            </div>:<div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
            <div>Unexpected Error</div>
            <div>
            
Whoops! Something unexpected happened. Please try again!</div>
            
            </div>
      }
 </div>
)
}

export default ReactivationAccount;