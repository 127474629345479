import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
  usersDetail: [],
  teams: [],
  designationModalShow: false,
  defaultUserDesignation: "",
  defaultUserCycle: "",
  defaultUserStatus: "",
  Id: "",
  workSummaryModal: false,
  rowData: {},
  modalType: "",
  page: 1,
  pageSize: 30,
  refreshEffect: 0,
  teamRefreshEffect:0,
  meta: {},
  cycleList: {},
  designationList: {},
  statusList: {},
  managerStatusList:  {
    none : "None",
    teamRanked : "Team Ranked",
    performanceAttitudeRanked : "Performance Attitude Ranked",
    appraisalFormFilled : "Appraisal Form Filled",
    completed : "Completed"
},
  cycleFilter: "",
  searchText: "",
  appraisalFilter: null,
  designationFilter: "",
  formStatusFilter: "",
  managerFormStatusFilter: "",
  roleFilter:"",
  sorting: "",
  sortingTeam: "",
  sortingManager: "",
  emailSort: "",
  sortByEmployeeID:'',
  departmentDropdown: [],
  teamDropdown: [],
  managerDropdown: [],
  teamNameModal: false,
  cycleUsers: [],
  tabState: "users",
  privileges: [],
  roleLockModal: false,
  associateCount:{},
  tableLoader:false,
  summaryNotificationFilter:''
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    updateUsers(state = initialState, action) {
      state.users = [...action.payload.users];
    },
    updateUsersDetail(state = initialState, action) {
      state.usersDetail = [...action.payload.usersDetail];
    },

    updateSingleUser(state = initialState, action) {
      const newUsers = state.users.map((user) => {
        if (user.id === action.payload.user.id) {
          user = action.payload.user;
        }
        return user;
      });

      state.users = [...newUsers];
    },
    updateTeams(state = initialState, action) {
      state.teams = [...action.payload];
    },
    updateDesignationModal(state = initialState, action) {
      state.designationModalShow = action.payload;
    },
    updateModalType(state = initialState, action) {
      state.modalType = action.payload;
    },
    updateWorkSummaryModal(state = initialState, action) {
      state.workSummaryModal = action.payload;
    },
    updateMeta(state = initialState, action) {
      state.meta = { ...action.payload };
    },
    updatePage(state = initialState, action) {
      state.page = action.payload.page;
      state.pageSize = action.payload.pageSize;
    },
    updateRefreshEffect(state = initialState, action) {
      state.refreshEffect = action.payload;
    },
    updateTeamRefreshEffect(state=initialState,action){
      state.teamRefreshEffect = action.payload;
    },
    updateCycle(state = initialState, action) {
      state.cycleList = { ...action.payload };
    },
    updateDesignation(state = initialState, action) {
      state.designationList = { ...action.payload };
    },
    updateStatus(state = initialState, action) {
      state.statusList = { ...action.payload };
    },
    updateManagerStatus(state = initialState, action) {
      state.managerStatusList = { ...action.payload };
    },
    updateUser_Cycle_Designation(state = initialState, action) {
      state.Id = action.payload.Id;
      state.defaultUserCycle = action.payload.defaultCycle;
      state.defaultUserDesignation = action.payload.defaultLevel;
      state.defaultUserStatus = action.payload.defaultStatus;
    },
    updateRowData(state = initialState, action) {
      state.rowData = { ...action.payload };
    },
    updateFilter(state = initialState, action) {
      state.appraisalFilter = action.payload.appraisalFilter;
      state.designationFilter = action.payload.designationFilter;
      state.cycleFilter = action.payload.cycleFilter;
      state.formStatusFilter = action.payload.formStatusFilter;
      state.managerFormStatusFilter = action.payload.managerFormStatusFilter;
      state.roleFilter = action.payload.roleFilter;
      state.summaryNotificationFilter = action.payload.summaryNotificationFilter
    },
    updateSortFilter(state = initialState, action) {
      state.sorting = action.payload.sortByName;
      state.emailSort = action.payload.sortByEmail;
      state.sortByEmployeeID = action.payload.sortByEmployeeID
    },
    updateSortFilterTeam(state = initialState, action) {
      state.sortingTeam = action.payload.sortByName;
      // state.emailSort = action.payload.sortByEmail
    },
    updateSortFilterManager(state = initialState, action) {
      state.sortingManager = action.payload.sortByName;
      // state.emailSort = action.payload.sortByEmail
    },
    updateSearchText(state = initialState, action) {
      state.searchText = action.payload;
    },
    updateTeamDropDown(state = initialState, action) {
      state.teamDropdown = [...action.payload];
    },
    updateDepDropDown(state = initialState, action) {
      state.departmentDropdown = [...action.payload];
    },
    updateManagerDropDown(state = initialState, action) {
      state.managerDropdown = [...action.payload];
    },
    updateTeamNameModal(state = initialState, action) {
      state.teamNameModal = action.payload.teamNameModal;
    },
    updateCycleUsers(state = initialState, action) {
      state.cycleUsers = [...action.payload];
    },
    updateRoleLockModal(state = initialState, action) {
      state.roleLockModal = [...action.payload];
    },
    updateTabState(state = initialState, action) {
      state.tabState = action.payload;
    },
    updatePrivilegeList(state = initialState, action) {
      state.privileges = [...action.payload];
    },
    updateAssociateCount(state=initialState,action){
      state.associateCount= action.payload
    },
    updateTableLoader(state=initialState,action){
      state.tableLoader = action.payload
    }
  },
});

export default adminSlice.reducer;

export const adminActions = adminSlice.actions;
