import React, { useEffect, useState } from 'react';
import classes from "./style.module.css";
import { Button, Select, Typography, Spin,Table,Tabs, Pagination, Form, Modal, Radio, Space, Divider,Upload, DatePicker, Input,InputNumber } from "antd";
import DownloadIcon from "../../../assets/Download Icon Blue.svg";
import SearchCompo from "../../../design/SearchCompo";
import EditIcon from "../../../assets/userEditIcons.svg";
import Excel from "../../../assets/excel.png";
import ProfileIcon from "../../../assets/LeaveProfileIcon.svg";
import useStore from './store';
import uploadIcon from '../../../assets/UploadResume.svg';
import EditLeave from './editLeave';
import CardList from './cardList';
import "./table.css"
import { getLeavesData } from '../action/action';
import moment from 'moment/moment';
import viewClass from '../../../services/ViewRole';
import UserIcon from "../../../assets/UserIcon.svg";
import { AiOutlineVerticalAlignBottom } from 'react-icons/ai';
import Api from "../../../services/Api";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import leaveCalenderIcon from "../../../assets/leaveCalenderIcon.svg";
import FilterIcon from "../../../assets/filterIcon.svg";
import Notification from '../../../services/Notification';
import { getFilterValues } from "../../ProjectManagement/action/action";
import { useProjecteStore } from "../../ProjectManagement/store/store";
import AscendingSortIcon from "../../../assets/AscendingSortIcon.svg";
import DescendingSortIcon from "../../../assets/DescendingSortIcon.svg";

const { Option } = Select;
const { TabPane } = Tabs;

const AttendanceLeave = () => {
  const year = moment().format("YYYY");
  const { EditLeaveModal, SummaryModal, LeaveModal, 
    setLeaveData,setEditLeaveData, leavesData, tableData, setSelectedData } = useStore(state => state);
  const [monthsVal, setMonthsVal] = useState(`${moment().format("MMMM").slice(0, 3)} - ${year}`);
  const [selectedYear,setSelectedYear] = useState(moment().format("YYYY"));
  const [selectedMonthDown,setSelectedMonth] = useState(`${moment().format("MMMM").slice(0, 3)} - ${year}`);
  const [tabs, setTabs] = useState("Active Users");
  const [loader,setLoader]=useState(false)
  const [downloadModal,setDownloadModal]=useState(false)
  const [downloadValue,setDownloadValue]=useState("")
  const [downloadWise,setDownloadWise]=useState("")
  const [pageLimit,setPageLimit]=useState(20)
  const [pageCount,setPageCount]=useState(1)
  const [pageSize,setPageSize] = useState(20);
  const [currentPage,setCurrentPage] = useState(1);
  const [pageMeta,setPageMeta] = useState();
  const [searchVal,setSearchVal] = useState("");
  const [carryForwardModal,setCarryForwardModal]=useState(false)
  const {width}=useWindowDimensions();
  const [halfValues,setHalfValues]=useState(false)
  const [halfValNumber,setHalfValNumber]=useState(null);
  const [filterModal,setFilterModal] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [importTableModal, setImportTableModal] = useState(false);
  const [btnLoader,setBtnLoader] = useState(false);
  const [downBtnLoader,setDownBtnLoader] = useState(false);
  const [detailData,setDetailData] = useState([]);
  const [Tabloader,setTabloader] = useState(false);
  const [saveFile,setSaveFile] = useState(null);
  const [tabKey,setTabKey]=useState("1")
  const [changedData,setChangedData] = useState([]);
  const [unChangedData,setUnChangedData] = useState([]);
  const { setFilterValues, filteredData } = useProjecteStore((state) => state);
  const [filteredDeptValue,setFilteredDeptValue]=useState(null);
  const [sortingEmployee,setSortingEmployee] = useState('ASC');

  const generateMonthOptions = () => {
    const monthOptions = [];
    const currentDate = moment();
    for (let i = 0; i < 24; i++) {
      const monthYear = currentDate.clone().subtract(i, 'months');
      const formattedMonthYear = monthYear.format('YYYY-MMM');
      const displayText = monthYear.format('YYYY-MMM');
      monthOptions.push({ value: formattedMonthYear, displayText: displayText });
    }
    return monthOptions;
  };
  
  const months = generateMonthOptions();

  const allMonths = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const currentYear = new Date().getFullYear();

  // Initialize an array to hold the years
  const lastTenYears = [];

  // Loop to populate the array with the last 10 years
  for (let i = 0; i < 3; i++) {
    lastTenYears.push(currentYear - i);
  }
  // console.log("ssssYEarrrrr",lastTenYears);

  const getData=()=>{
    let params
    if(monthsVal !== undefined){
      
      const year = moment(monthsVal).format("YYYY");
      const selectedMonth = moment(monthsVal, "MMM - YYYY").format("MM");
      
    if(searchVal === ""){
      params={
        yearMonth: `${year}-${selectedMonth}`,
        "deleted": tabs === "Active Users" ? false : true,
        "search": searchVal,
        // "download":false,
        "page":currentPage,
        "limit":pageSize,
        departmentId: filteredDeptValue,
        employeeIdSort: sortingEmployee
      }
    }else{
      params={
      "page": currentPage,
      yearMonth: `${year}-${selectedMonth}`,
      "deleted": tabs === "Active Users" ? false : true,
      "search": searchVal,
      departmentId: filteredDeptValue,
      employeeIdSort: sortingEmployee
    }}
    }
    getLeavesData(params, (res) => { setLeaveData(res,pageSize,currentPage)
    setPageMeta(res?.pageMeta)
    }, setLoader)
  }

  const onFinish = (values) => {
    if((!values?.dragger) || (values?.dragger.length===0))
    {   
      let title="please import file"
      Notification.error(title)
      return
      
    }
    setSaveFile(values?.dragger[0]?.originFileObj)
    const params = {
      excel:values?.dragger[0]?.originFileObj,
      save:false
    };
    setBtnLoader(true)
    setTabloader(true)
    Api.post("/attendance-attendance/addLeaves")
    .upload(params, (percent,response) => {
      if (typeof response != "undefined") {
        setBtnLoader(false)
        setImportModal(false);
        setDetailData(response.data);
        setImportTableModal(true);
        setTabloader(false);
        // getData();
    }});
  };
  
  const onFinishHandler = () => {
    const params = {
      excel:saveFile,
      save:true,
    };
    setBtnLoader(true)
  
    Api.post("/attendance-attendance/addLeaves")
    .upload(params, (percent,response) => {
      if (typeof response != "undefined") {
        setBtnLoader(false)
        
        setImportTableModal(false);
        getData();
    }});
  };

  useEffect(()=>{
     const changedDatas = detailData.filter(item => item.change === true);
     setChangedData(changedDatas);
     
     const datas = detailData.filter(item => item.change === false);
     setUnChangedData(datas);

  },[detailData])

  useEffect(() => {
    viewClass?.updateView();
    getData()
  }, [tabs,monthsVal,currentPage,pageSize,searchVal,filteredDeptValue,sortingEmployee])

  useEffect(()=>{
    getFilterValues(setFilterValues, "departmentAttendance");
  },[])

  const handlePageChange = (page,pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize)
  };

  const columns = [
    {
      title:<div style={{ fontSize: "1.1rem",}}>Name </div>,
      dataIndex: "name",
      key: "name",
      render: (record) => (
        <div style={{ fontSize: "1rem",}}>{record}</div>
      ),
    },
    {
      title:<div style={{ fontSize: "1.1rem",}}>EmployeeId</div>,
      dataIndex: "uid",
      key: "uid",
      render: (record) => (
        <div style={{ fontSize: "1rem",}}>{record}</div>
      ),
    },
    {
      title:<div style={{ fontSize: "1.1rem",}}>Privilege</div>,
      dataIndex: "privilege",
      key: "privilege",
      width: "",
      render: (record) =>
        <div style={{ fontSize: "1rem",}}>{record}</div>
    },
    {
      title:<div style={{ fontSize: "1.1rem",}}>Casual</div>,
      dataIndex: "casual",
      key: "casual",
      width: "",
      render: (record) =>
        <div style={{ fontSize: "1rem",}}>{record}</div>
    },
    {
      title:<div style={{ fontSize: "1.1rem",}}>Sick</div>,
      dataIndex: "sick",
      key: "sick",
      width: "",
      render: (record) =>
        <div style={{ fontSize: "1rem",}}>{record}</div>
    },
    {
      title:<div style={{ fontSize: "1.1rem",}}>Wedding</div>,
      dataIndex: "wedding",
      key: "wedding",
      width: "",
      render: (record) =>
          <div style={{ fontSize: "1rem",}}>{record}</div>
    },
    {
      title:<div style={{ fontSize: "1.1rem",}}>Parental</div>,
      dataIndex: "paternal",
      key: "paternal",
      width: "",
      render: (record) =>
          <div style={{ fontSize: "1rem",}}>{record}</div>
    },
    {
      title:<div style={{ fontSize: "1.1rem",}}>Optional</div>,
      dataIndex: "optional",
      key: "optional",
      width: "",
      render: (record) =>
          <div style={{ fontSize: "1rem",}}>{record}</div>
    },
  ];

  const calculateSNo = (currentPage, pageSize, index) => {
    return (currentPage - 1) * pageSize + (index + 1);
  };

  const carryForwardButton = () =>{
    Api.post(`/attendance-attendance/carryForward?half=${halfValues}`)
    .send((response) => {
        console.log("resss",response);
    });
}

  return (
    <div className={classes.container}>
      <div style={{width:"100%",display:"flex",justifyContent:"space-between",flexDirection:"record"}}>
      {width > 700 ?
        <h3 style={{fontSize:"22px"}}>Leave</h3>
        :
        <span style={{fontSize:"30px",fontWeight:"600"}}>Leave</span>
      }
        {width > 700 &&  <div style={{display:"flex",flexDirection:"row",gap:"0.9rem",}}>
        {/* <SearchCompo style={{width:"17rem",height:"2rem",display:"flex",alignItems:"center",gap:"0.3rem",borderRadius:5}} 
          setSearch={(e) => {
              setSearchVal(e)
            }} /> */}
         <p style={{color:"#6BCB77",cursor:"pointer",font: "normal normal bold 13px/22px Open Sans",borderRadius:5,padding:"0 0.4rem",border:"1px solid",display:"flex",flexDirection:"row",gap:"0.3rem",alignItems:"center"}}
          onClick={()=>{
              Api.get("/attendance-attendance/user-leave-bulk-upload")
                .send(res=>{
                  if(res)
                  {
                    window.open(res.file, "_blank")?.focus();                               
                  }
              })
          }}
        >
            <img style={{ width: "1rem",height:"1rem" }} src={Excel} />
            <span>Download Excel Template</span>
        </p>
        <Button style={{color:"#FFFFFF",backgroundColor:"#0086FF",fontWeight:"500",borderRadius:5}} 
          disabled={viewClass?.superAdmin || viewClass?.hrAdmin ?false:true} 
             onClick={()=>{setCarryForwardModal(true)}}
        >Carry-Forward</Button>
        </div>}
      </div>

        {width < 700 &&  <div className={classes.searchdivScroll} style={{display:"flex",flexDirection:"row",justifyContent:"space-between",gap:"1rem",width:"100%",padding:"0 1rem",overflow:"auto"}}>
        
        <SearchCompo style={{width:"50vw",height:"2.4rem",display:"flex",alignItems:"center",gap:"0.3rem",borderRadius:5}} 
          setSearch={(e) => {
              setCurrentPage(1);
              setSearchVal(e)
            }} />
        <Button style={{color:"#FFFFFF",height:"2.4rem",backgroundColor:"#0086FF",fontWeight:"500",borderRadius:5}} 
          disabled={viewClass?.superAdmin || viewClass?.hrAdmin ?false:true} 
          onClick={()=>{setCarryForwardModal(true)}}
        >Carry-Forward</Button>
        </div>}
      <div style={{}}>
      <div style={{ display: "flex", justifyContent: "space-between",width:"100%",marginBottom:"0.5rem",marginTop:width>700?"0.4rem":"1.2rem",padding:"0 1rem"}}>
          <div style={{gap:"1rem",display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <div onClick={() => { setTabs("Active Users") }} style={{ display: "flex", flexDirection: "column",fontSize:"16ppx" }}>
              <div style={{ color: tabs === "Active Users" ? "#0086FF" : "#A6A9B8",cursor:"pointer",fontSize:width < 700?"19px":"16px"}}>
                Active Users
              </div>
              {
                tabs === "Active Users" ?
                  <div style={{ height: 1, border: "2px solid #0086FF", borderRadius: 5,}}></div>
                  :
                  <div style={{ height: 1 }}></div>
              }
            </div>
            <div onClick={() => { setTabs("Inactive Users") }} style={{ display: "flex", flexDirection: "column",fontSize:"16px" }}>
              <div style={{ color: tabs === "Inactive Users" ? "#0086FF" : "#A6A9B8",cursor:"pointer",fontSize:width < 700?"19px":"16px" }}>
                Inactive Users
              </div>
              {
                tabs === "Inactive Users" ?
                  <div style={{ height: 1, border: "2px solid #0086FF", borderRadius: 5 }}></div>
                  :
                  <div style={{ height: 1 }}></div>
              }
            </div>
          </div>

          <div className={classes.extraContentTag}>
            {width > 700 ?
            <div style={{display:"flex",flexDirection:"row",gap:"0.4rem",alignItems:"center"}}>
             {/* <p style={{ fontSize: "18px", color: "#878787" }}>Select Department</p> */}
              <Select placeholder="Department" allowClear
                getPopupContainer={(trigger) => trigger.parentElement}
                showSearch
                value={filteredDeptValue}
                size="medium"
                style={{
                  height: "2.2rem", fontSize: "18px",marginRight:"0.3rem"
                }}
                onChange={(e) => {
                  setFilteredDeptValue(e);
                }}
                disabled={false}
                optionFilterProp="children"

              >
                {filteredData?.department?.result?.map((option, index) => {
                  return (<>{option.department !== undefined &&
                    <Option key={index} value={option.departmentId} style={{ fontSize: "" }}>
                      {option.department}
                    </Option>}</>)
                })}
              </Select>
            <Button type="primary" style={{width:"6rem",height:"2rem",fontWeight:500,borderRadius:"5px",color:"#FFFFFF",backgroundColor:"#0086FF",}}
              onClick={()=>setImportModal(true)} disabled={viewClass?.hrAssociate}
            >
              IMPORT
            </Button>
            <SearchCompo style={{width:"17rem",height:"1.9rem",display:"flex",alignItems:"center",gap:"0.3rem",borderRadius:5}} 
              setSearch={(e) => {
                setSearchVal(e)
              }} />
            <Select placeholder="All" 
              getPopupContainer={(trigger) => trigger.parentElement}
              className='m-t-5'
              defaultValue={null}
              style={{width:"9vw",height:"2rem",border:"0px solid #E6E6E6",display:"flex",alignItems:"center" }}
              value={monthsVal}
              onChange={(e) => { setMonthsVal(e) }}
              disabled={false}
              optionLabelProp="label"
            >
               {months?.map((option, index) => (
                  <Option key={option.value} value={option.value} label={option.displayText}>
                    {option.displayText}
                  </Option>
                ))}
            </Select>
            </div>
            :
            <div>
              <img src={FilterIcon} alt="" onClick={()=>{
                  setFilterModal(true);
                }}/>
            </div>
            }
            <div style={{cursor:"pointer",marginRight:"0.4rem",color:"#0086FF"}} onClick={()=>{
                // const year = moment().format("YYYY");
                // const selectedMonth = moment(monthsVal, "MMM - YYYY").format("MM");
                // getLeavesData({
                //   yearMonth: `${year}-${selectedMonth}`,
                //   "deleted": tabs === "Active Users" ? false : true,
                //   "search": searchVal,
                //   "download":true
                // }, (res) => { setLoader(false)
                //   window.open(res, "_blank")?.focus(); 
                // })
                setDownloadModal(true);
            }}>{width > 700 && "Download"} <img src={DownloadIcon} alt='' style={{height:width > 700?"1rem":"1.5rem"}}/></div>
          </div>
      </div>
      <div style={{backgroundColor:"#F8F8F8",padding:"0 1rem",}}>

      <div style={{width:"100%"}}>
      <CardList data={leavesData?.intialLeaves} />
      </div>

      <div style={{marginTop:"0.6rem"}}>
        {/* <Table
          columns={columns}
          dataSource={tableData}
          loading={loader}
          bordered
          size="middle"
          scroll={{x: 'calc(200px + 10%)', y: "35vh",}}
          className="table-header"
          pagination={{
            showSizeChanger:true,
            pageSize:pageSize,
            current:currentPage,
            total:pageMeta?.totalItems,
            onChange:handlePageChange,
            onShowSizeChange:handlePageChange,
            pageSizeOptions:[10, 30, 50, 100],
            position: "bottomRight",
            size: "small",
            showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
           }}
        /> */}
        {width > 700 ?
        <div className={classes.ListContainer}>
            <div className={classes.ListHeader} style={{display:'flex',width:'100%',gap:"0.5rem",paddingTop:"0.7rem",paddingBottom:"0.5rem",fontSize: 15, color: "#16243280", fontWeight: 700,}}>
                    <div style={{width:'5%',display:"flex",flexDirection:"column",gap:"0.9vh"}} >
                      <div>S.No</div>
                      <div ></div>
                    </div>
                    {/* <div style={{width:'15%',display:"flex",flexDirection:"column",gap:"0.9vh"}}> */}
                      {/* <div>Associates</div> */}
                      <span style={{ paddingLeft: "0.4rem", width: "15%", display: "flex", flexDirection: "row", alignItems: "top" }}>
                      Associates {sortingEmployee === 'ASC' ? <img src={AscendingSortIcon} alt='' onClick={() => { setSortingEmployee('DESC');}}
                          style={{ cursor: "pointer", marginLeft: "0.2rem" }} />
                          : <img src={DescendingSortIcon} alt='' onClick={() => { setSortingEmployee('ASC');}} style={{ cursor: "pointer", marginLeft: "0.2rem" }} />}
                      </span>                      
                    {/* </div> */}
                    <div style={{width:'23%',display:"flex",flexDirection:"column",gap:"0.9vh"}}>
                      <div>Year Opening Balance</div>
                      <div style={{width:"95%",display: 'flex', flexDirection: 'row',justifyContent:"space-between" }}>
                        <span>PL</span>
                        <hr style={{  height: '100%',}} />
                        <span>CL</span>
                        <hr style={{ height: '100%',}} />
                        <span>SL</span>
                        <hr style={{  height: '100%',}} />
                        <span>OH</span>
                      </div>
                    </div> 
                    <div style={{width:'20%',display:"flex",flexDirection:"column",gap:"0.9vh"}}>
                      <div>Opening Balance</div>
                      <div style={{width:"95%",display: 'flex', flexDirection: 'row',justifyContent:"space-between" }}>
                        <span>PL</span>
                        <hr style={{ margin: '0', height: '100%',}} />
                        <span>CL</span>
                        <hr style={{ margin: '0', height: '100%',}} />
                        <span>SL</span>
                        <hr style={{ margin: '0', height: '100%',}} />
                        <span>OH</span>
                      </div>
                    </div>
                    <div style={{width:'20%',display:"flex",flexDirection:"column",gap:"0.9vh"}}>
                      <div>Current Accural</div>
                      <div style={{width:"100%",display: 'flex', flexDirection: 'row',justifyContent:"space-between" }}>
                        <span>PL</span>
                        <hr style={{ margin: '0', height: '100%',}} />
                        <span>CL</span>
                        <hr style={{ margin: '0', height: '100%',}} />
                        <span>SL</span>
                        <hr style={{ margin: '0', height: '100%',}} />
                        <span>OH</span>
                      </div>
                    </div>
                    <div style={{width:'20%',display:"flex",flexDirection:"column",gap:"0.9vh"}}>
                      <div>Closing Balance</div>
                      <div style={{width:"100%",display: 'flex', flexDirection: 'row',justifyContent:"space-between" }}>
                        <span>PL</span>
                        <hr style={{ margin: '0', height: '100%',}} />
                        <span>CL</span>
                        <hr style={{ margin: '0', height: '100%',}} />
                        <span>SL</span>
                        <hr style={{ margin: '0', height: '100%',}} />
                        <span>OH</span>
                      </div>
                    </div>
                    <div style={{width:'20%',display:"flex",flexDirection:"column",gap:"0.9vh"}}>
                      <div>Modified Leaves</div>
                      <div style={{width:"100%",display: 'flex', flexDirection: 'row',justifyContent:"space-between" }}>
                        <span>PL</span>
                        <hr style={{ margin: '0', height: '100%',}} />
                        <span>CL</span>
                        <hr style={{ margin: '0', height: '100%',}} />
                        <span>SL</span>
                        <hr style={{ margin: '0', height: '100%',}} />
                        <span>OH</span>
                      </div>
                    </div>
                    <div style={{width:'10%'}}>Action</div>
            </div>
            
            <div className={classes.ListBodyContainer} style={{height:"42.5vh", overflowY:'scroll',overflowX:"scroll",width:"100%"}}>
                  {loader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><Spin /></div>:
                     tableData?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><div>No data found</div></div>:
                     tableData?.map((record,id,index)=>{
                        return(
                          // <div style={{width:"100%",}}>
                          <div className={classes.rowContainer} style={{gap:"0.5rem",color: "#162432", fontSize: 15, fontWeight: 400, fontStyle: "Lato",}}>
                          <div style={{width:'5%'}}>
                          <div>{record?.sNo}</div>
                          </div>
                          <div style={{width:'15%',}}>
                          <div style={{display:'flex',alignItems:'center',justifyContent:"flex-start",width:"100%",flexDirection:"row",gap:'px',}}
                          >
                            <img style={{ height: "2.5rem",  width: "3rem", }} src={UserIcon} />
                            <div style={{display:"flex",flexDirection:"column",justifyContent:"flex-start",cursor:"pointer"}}
                            onClick={() => {
                              setSelectedData(record)
                              SummaryModal(true)
                              LeaveModal(false)
                            }}>
                            <Typography.Text style={{maxWidth:"5vw"}} ellipsis={{tooltip:true}}>
                              <span style={{color:"#0086FF"}}>{record?.userName}</span></Typography.Text>
                            <Typography.Text style={{maxWidth:"5vw"}} ellipsis={{tooltip:true}}>
                              <span style={{color:"#16243280"}}>{record?.userEmployeeId}</span></Typography.Text>
                            </div>
                          </div>
                          </div>
                          <div style={{ width: '23%',display:"flex",flexDirection:"row",justifyContent:"space-between",paddingLeft:"0.5rem",paddingRight:"0.8rem"}}>
                              <span>{record?.pl1 ? record?.pl1 : 0}</span>
                              <span>{record?.cl1 ? record?.cl1 : 0}</span>
                              <span>{record?.sl1 ? record?.sl1 : 0}</span>
                              <span>{record?.oh1 ? record?.oh1 : 0}</span>
                          </div>

                          <div style={{ width: '20%',display:"flex",flexDirection:"row",justifyContent:"space-between",paddingLeft:"0.5rem",paddingRight:"0.8rem"}}>
                              <span>{record?.pl2 ? record?.pl2 : 0}</span>
                              <span>{record?.cl2 ? record?.cl2 : 0}</span>
                              <span>{record?.sl2 ? record?.sl2 : 0}</span>
                              <span>{record?.oh2 ? record?.oh2 : 0}</span>
                          </div>

                          <div style={{ width: '20%',display:"flex",flexDirection:"row",justifyContent:"space-between",paddingLeft:"0.5rem",paddingRight:"0.8rem"}}>
                              <span>{record?.pl3 ? record?.pl3 : 0}</span>
                              <span>{record?.cl3 ? record?.cl3 : 0}</span>
                              <span>{record?.sl3 ? record?.sl3 : 0}</span>
                              <span>{record?.oh3 ? record?.oh3 : 0}</span>
                          </div>

                          <div style={{ width: '20%',display:"flex",flexDirection:"row",justifyContent:"space-between",paddingLeft:"0.5rem",paddingRight:"0.8rem"}}>
                              <span>{record?.pl4 ? record?.pl4 : 0}</span>
                              <span>{record?.cl4 ? record?.cl4 : 0}</span>
                              <span>{record?.sl4 ? record?.sl4 : 0}</span>
                              <span>{record?.oh4 ? record?.oh4 : 0}</span>
                          </div>

                          <div style={{ width: '20%',display:"flex",flexDirection:"row",justifyContent:"space-between",paddingLeft:"0.5rem",paddingRight:"0.8rem"}}>
                              <span>{record?.pl5 ? record?.pl5 : 0}</span>
                              <span>{record?.cl5 ? record?.cl5 : 0}</span>
                              <span>{record?.sl5 ? record?.sl5 : 0}</span>
                              <span>{record?.oh5 ? record?.oh5 :0}</span>
                          </div>

                          <div style={{width:'10%',}}>
                          <div style={{ cursor:viewClass?.superAdmin || viewClass?.hrAdmin? "pointer":"not-allowed",}} onClick={() =>{
                            if(viewClass?.superAdmin || viewClass?.hrAdmin ){
                              setEditLeaveData(record)
                              EditLeaveModal(true)
                            }
                          }}
                            >
                            <img src={EditIcon} alt='' style={{height:"2rem"}}/>
                          </div>
                          </div>
                          </div>
                          // </div>
                        )
                      })}
            </div>  
        </div>
        :
        <div className={classes.ListBodyContainer} style={{height:"48vh", overflowY:'scroll',overflowX:"scroll",width:"100%",}}>
                  {loader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><Spin /></div>:
                     tableData?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><div>No data found</div></div>:
                     tableData?.map((record,id,index)=>{
                        return(
                          <div style={{width:"100%",marginTop:"0.3rem",marginBottom:"1rem",borderRadius:10,backgroundColor:"#FFFFFF",padding:"1rem 1rem"}}>
                            
                            <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between",}}>
                            <div style={{display:"flex",flexDirection:"row",gap:"0.3rem"}}>
                              <img style={{ height: "3.5rem",  width: "3.5rem", }} src={UserIcon} />
                              <div style={{display:"flex",flexDirection:"column",justifyContent:"flex-start",cursor:"pointer"}}
                              onClick={() => {
                                setSelectedData(record)
                                SummaryModal(true)
                                LeaveModal(false)
                              }}>
                              <p style={{color:""}}>
                              <Typography.Text style={{width:"50vw",fontSize:18,fontWeight:550}} ellipsis={{tooltip:true}}>
                                {record?.userName}
                              </Typography.Text>
                              </p>
                              <p style={{color:"#16243280",fontSize:17}}>{record?.userEmployeeId}</p>
                              </div>
                            </div>
                            <div style={{ cursor:viewClass?.superAdmin || viewClass?.hrAdmin? "pointer":"not-allowed",}} onClick={() =>{
                              if(viewClass?.superAdmin || viewClass?.hrAdmin){
                                setEditLeaveData(record)
                                EditLeaveModal(true)
                              }
                              }}
                            >
                              <img src={EditIcon} alt='' style={{height:"2.5rem"}}/>
                            </div>
                            </div>

                            <div style={{display:"flex",flexDirection:"row",width:"100%",marginTop:"0.5rem"}}>
                              <div style={{flex:"55%",}}>{""}</div>
                              <div style={{color:"#16243280",display:"flex",fontWeight:"620",fontSize:"18px",flex:"42%",justifyContent:"space-between",}}>
                                <p>PL</p>
                                <p>CL</p>
                                <p>SL</p>
                                <p>OH</p>
                              </div>
                            </div>

                            <div style={{display:"flex",flexDirection:"row",width:"100%",marginTop:"0.5rem"}}>
                              <div style={{flex:"55%",}}>
                              <p style={{fontSize:"17px",color:"#16243280"}}>Year Opening Balance: </p> 
                              </div>
                              <div style={{display:"flex",fontSize:"17px",flex:"42%",justifyContent:"space-between"}}>
                              <span>{record?.pl1}</span>
                              <span>{record?.cl1}</span>
                              <span>{record?.sl1}</span>
                              <span>{record?.oh1}</span>
                              </div>
                            </div>

                            <div style={{display:"flex",flexDirection:"row",width:"100%",marginTop:"0.3rem"}}>
                              <div style={{flex:"55%",}}>
                              <p style={{fontSize:"17px",color:"#16243280"}}>Opening Balance: </p> 
                              </div>
                              <div style={{display:"flex",fontSize:"17px",flex:"42%",justifyContent:"space-between"}}>
                              <span>{record?.pl2}</span>
                              <span>{record?.cl2}</span>
                              <span>{record?.sl2}</span>
                              <span>{record?.oh2}</span>
                              </div>
                            </div>

                            <div style={{display:"flex",flexDirection:"row",width:"100%",marginTop:"0.3rem"}}>
                              <div style={{flex:"55%",}}>
                              <p style={{fontSize:"17px",color:"#16243280"}}>Current Accural: </p> 
                              </div>
                              <div style={{display:"flex",fontSize:"17px",flex:"42%",justifyContent:"space-between"}}>
                              <span>{record?.pl3}</span>
                              <span>{record?.cl3}</span>
                              <span>{record?.sl3}</span>
                              <span>{record?.oh3}</span>
                              </div>
                            </div>

                            <div style={{display:"flex",flexDirection:"row",width:"100%",marginTop:"0.3rem"}}>
                              <div style={{flex:"55%",}}>
                              <p style={{fontSize:"17px",color:"#16243280"}}>Closing Balance: </p> 
                              </div>
                              <div style={{display:"flex",fontSize:"17px",flex:"42%",justifyContent:"space-between"}}>
                              <span>{record?.pl4}</span>
                              <span>{record?.cl4}</span>
                              <span>{record?.sl4}</span>
                              <span>{record?.oh4}</span>
                              </div>
                            </div>

                            <div style={{display:"flex",flexDirection:"row",width:"100%",marginTop:"0.3rem"}}>
                              <div style={{flex:"55%",}}>
                              <p style={{fontSize:"17px",color:"#16243280"}}>Modified Leaves: </p> 
                              </div>
                              <div style={{display:"flex",fontSize:"17px",flex:"42%",justifyContent:"space-between",alignItems:"center"}}>
                              <span>{record?.pl5}</span>
                              <span>{record?.cl5}</span>
                              <span>{record?.sl5}</span>
                              <span>{record?.oh5 ? record?.oh5 :0}</span>
                              </div>
                            </div>

                          </div>
                        )
                      })}
        </div>  
        }
        {/* {width > 700 && */}
        <div>
           <Pagination position="bottomRight" size="small" style={{display:"flex",justifyContent:"flex-end",marginTop:"0.5rem",fontSize:width<700&&15}}
            current={currentPage}
            pageSize={pageSize}
            total={pageMeta?.totalItems}
            pageSizeOptions={[20, 30, 50, 100]}
            showSizeChanger={true}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            onChange={handlePageChange}
            onShowSizeChange={handlePageChange}
          /> 
        </div>
        {/* } */}
        </div> 
      </div>
      </div>
      {filterModal && (
        <Modal centered
          onCancel={()=>{setFilterModal(false)}}
          title={<div style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",width:"100%",fontSize:"21px"}}>
              Filters
            </div>
          }
          footer={
            <div className="r-c-c" style={{margin:"1rem",marginTop:"2.5rem",display:"flex",flexDirection:"row",gap:"0.5rem"}}>
              <Button 
                onClick={()=>{setFilterModal(false)}}
                style={{background:"#FFFFFF",fontSize:17,fontColor:"#0086FF",height:"2.4rem",width:"5.5rem",borderRadius:8}}
              >Cancel</Button>
              <Button type='primary'
                onClick={() => {
                  setFilterModal(false);
                }}
                style={{fontSize:17,height:"2.4rem",width:"5.5rem",borderRadius:8}}
              >Apply</Button>
            </div>
          }
          open={filterModal} width={750} 
        >
          <Divider />
          <div style={{display:"flex",flexDirection:"column",width:"100%",gap:"0.5rem"}}>
          <p style={{fontSize:"18px",color:"#878787"}}>Select Month</p>
          <Select placeholder="All" 
              getPopupContainer={(trigger) => trigger.parentElement}
              className='m-t-5'
              defaultValue={null}
              size='large'
              style={{height:"2.2rem",border:"0px solid #E6E6E6",display:"flex",alignItems:"center",fontSize:17}}
              value={monthsVal}
              onChange={(e) => { setMonthsVal(e) }}
              disabled={false}
              optionLabelProp="label"
            >
               {months?.map((option, index) => (
                  <Option style={{fontSize:17}} key={option.value} value={option.value} label={option.displayText}>
                    {option.displayText}
                  </Option>
                ))}
            </Select>             
          </div>
        </Modal>
      )}

      {carryForwardModal&&
      <Modal open={carryForwardModal} centered width={700}
      onOk={()=>{
       setCarryForwardModal(false)
       setHalfValNumber(null)
      }} onCancel={()=>{setCarryForwardModal(false);setHalfValNumber(null)}}
       footer={<div style={{margin:width>700?"1rem":"3rem 0.5rem 1rem 1rem",display:"flex",justifyContent:"flex-end",flexDirection:"row",gap:"0rem"}}>
         <Button key="submit" type="primary" disabled={halfValNumber === null ? true :false}
         onClick={()=>{
          carryForwardButton()
          setCarryForwardModal(false)
         }} style={{width:"5rem"}}>
           Save
         </Button>
         <Button key="back" onClick={()=>setCarryForwardModal(false)}>
           Cancel
         </Button>
       </div>}>
       <div style={{height:"32vh",padding:0,margin:0,}}>
       <h3 style={{marginBottom:"0.5rem",fontSize:18}}>Carry Forward Leaves</h3>
       <Divider />
       <span style={{fontSize:16}}>Privilege Leaves: </span>
     
       <div style={{marginTop:"2rem",width:"100%",display:"flex",flexDirection:"row",justifyContent:"space-between",marginBottom:"1rem",}}>
       
       <Radio.Group  onChange={(e)=>{
         setHalfValNumber(e.target.value)
         if(e.target.value === 1){
           setHalfValues(false)
         }
         else{
           setHalfValues(true)
         }
       }} >
         {width > 700 ?
         <div style={{width:"100%",display:"flex",flexDirection:"row",gap:"2rem",}}>
         <Radio value={1} style={{border:"1px solid #878787",height:"2.5rem",width:"17rem",display:"flex:",alignItems:"center",padding:"0 0.5rem",fontSize:15}}>
           Full Privilege Leaves</Radio>
         <Radio value={2} style={{border:"1px solid #878787",height:"2.5rem",width:"17rem",display:"flex:",alignItems:"center",padding:"0 0.5rem",fontSize:15}}>
           Half Privilege Leaves</Radio>
         </div>
         :
         <div style={{display:"flex",flexDirection:"column",gap:"1.5rem",}}>
         <Radio value={1} style={{border:"1px solid #878787",height:"2.5rem",width:"17rem",display:"flex:",alignItems:"center",padding:"0 0.5rem",fontSize:15}}>
           Full Privilege Leaves</Radio>
         <Radio value={2} style={{border:"1px solid #878787",height:"2.5rem",width:"17rem",display:"flex:",alignItems:"center",padding:"0 0.5rem",fontSize:15}}>
           Half Privilege Leaves</Radio>
         </div>
         }
       </Radio.Group>

       </div>
       <Divider style={{marginTop:"2rem"}}/>
     </div>
     </Modal>}
      {downloadModal&&
      <Modal centered
        onCancel={()=>{setDownloadModal(false);setDownloadValue("");
        setSelectedYear(moment().format("YYYY"));setDownloadWise("");
        setSelectedMonth(`${moment().format("MMMM").slice(0, 3)} - ${year}`);setPageCount(1)}}
        title={
          <div className="r-c-c" style={{ color: "#0DAC45" }}>
            <AiOutlineVerticalAlignBottom
              style={{
                border: "0px solid black",
                height: "20px",
                width: "20px",
                color: "#0DAC45",
                marginRight: 5,
              }}
            />
            Download
          </div>
        }
        footer={
          <div className="r-c-c" style={{margin:"1rem",display:"flex",flexDirection:"row",gap:"0.5rem",marginTop:"2rem"}}>
            <Button
              onClick={()=>{setDownloadModal(false);setDownloadValue("");setPageLimit(20);
                          setDownloadWise("");setSelectedYear(moment().format("YYYY"));
                          setSelectedMonth(`${moment().format("MMMM").slice(0, 3)} - ${year}`);setPageCount(1)}}
              style={{fontSize:14,color:"#0086FF",borderColor:"#0086FF",height:"2rem",width:"7.5rem",marginLeft:"5px",borderRadius:8}}
            >Cancel</Button>
            {console.log("sssPahge",pageCount)}
            <Button 
            disabled={downloadValue === "" || (downloadWise === "" && searchVal === "") || (downloadWise==="pagination"&&(pageCount === null || pageCount === "" || pageCount === "0")) ? true : false}  
              type='primary'
              loading={downBtnLoader}
              onClick={() => {
                const year = moment(monthsVal).format("YYYY");
                const selectedMonthVal = moment(downloadValue === "Year" ?monthsVal:selectedMonthDown, "MMM - YYYY").format("MM");

                let params={
                     yearMonth: `${downloadValue === "Year" ? selectedYear : year}-${selectedMonthVal}`,
                    "deleted": tabs === "Active Users" ? false : true,
                    "search": searchVal,
                    "download":true,
                    departmentId: filteredDeptValue,
                    employeeIdSort: sortingEmployee,
                    downloadYear: downloadValue === "Year" ? true : false,
                    "allusers":downloadWise==="pagination" ? false : true,
                }
                if(downloadWise==="pagination"){
                  params={...params,"page":parseInt(pageCount),"limit":parseInt(pageLimit),}
                }
                setDownBtnLoader(true)
                Api.post('/attendance-attendance/leaveSummary')
                .params(params)
                .send((response) => {
                    if (response !== undefined) {
                      if(response?.show){
                        setDownBtnLoader(false);
                        setDownloadModal(false);
                        setPageLimit(20)
                        setDownloadValue("")
                        setDownloadWise("")
                      }else{
                      setDownBtnLoader(false);
                      window.open(response, "_blank")?.focus();
                      setDownloadModal(false);
                      setPageLimit(20)
                      setDownloadValue("")
                      setDownloadWise("")
                      // setDownloadValue("")
                    }
                    setSelectedYear(moment().format("YYYY"));
                    setSelectedMonth(`${moment().format("MMMM").slice(0, 3)} - ${year}`);
                    setPageCount(1)
                  }
                });
                // getLeavesData({
                //   yearMonth: `${year}-${selectedMonth}`,
                //   "deleted": tabs === "Active Users" ? false : true,
                //   "search": searchVal,
                //   "download":true,
                //   downloadYear: downloadValue === "Year" ? true : false,
                //   "page":currentPage,
                //   "limit":pageSize
                // }, (res) => {
                //     setLoader(false);
                //     setBtnLoader(false);
                //     window.open(res, "_blank")?.focus();
                //     setDownloadModal(false);
                // });
              }}
              style={{fontSize:14,color:"#FFFFFF",height:"2rem",width:"7.5rem",marginRight:"0.5rem",borderRadius:8}}
            >Download</Button>
          </div>
        }
        open={downloadModal} width={"40%"} 
      >
        {/* <> */}
        <Divider />
        <div 
        // className="r-c-c"
         style={{height:""}}>
          <h4 style={{margin:"1rem"}}>Select Download Wise:</h4>
          <Radio.Group onChange={(e)=>{setDownloadValue(e.target.value);setPageLimit(20)}} value={downloadValue} style={{width:"10rem",marginLeft:"2rem"}}>
            <Space direction="vertical" style={{display:"flex",flexDirection:"column",gap:"0.5rem",}}>
              <Radio value={"Year"}>Yearly</Radio>
              {
                downloadValue === "Year" &&
                <Select placeholder="All" 
                  getPopupContainer={(trigger) => trigger.parentElement}
                  className='m-t-5'
                  style={{width:"9vw",height:"2rem",border:"0px solid #E6E6E6",display:"flex",alignItems:"center" }}
                  value={selectedYear}
                  onChange={(e) => { setSelectedYear(e) }}
                  disabled={false}
                  optionLabelProp="label"
                >
                  {lastTenYears?.map((option, index) => (
                      <Option key={index} value={option} label={option}>
                        {option}
                      </Option>
                    ))}
                </Select>
              }
              <Radio value={"Month"}>Monthly</Radio>
              {
                downloadValue === "Month" &&
                <Select placeholder="All" 
              getPopupContainer={(trigger) => trigger.parentElement}
              className='m-t-5'
              defaultValue={null}
              style={{width:"9rem",height:"2rem",border:"0px solid #E6E6E6",display:"flex",alignItems:"center" }}
              value={selectedMonthDown}
              onChange={(e) => { setSelectedMonth(e) }}
              disabled={false}
              optionLabelProp="label"
            >
               {months?.map((option, index) => (
                  <Option key={option.value} value={option.value} label={option.displayText}>
                    {option.displayText}
                  </Option>
                ))}
            </Select>
              }
            </Space>
          </Radio.Group>          
        </div>
        
        {downloadValue  && searchVal === "" ? <>
          <Divider />
          <div style={{height:"",marginBottom:downloadWise==="pagination"&&"2rem",}}>
          <h4 style={{margin:"1rem"}}>Select Data Count:</h4>
          <Radio.Group onChange={(e)=>{setDownloadWise(e.target.value);setPageLimit(20)}} value={downloadWise} style={{width:"10rem",marginLeft:"2rem"}}>
            <Space direction="vertical" style={{display:"flex",flexDirection:"column",gap:"0.5rem",}}>
              <Radio value={"pagination"}>Pagination</Radio>
              {downloadWise === "pagination" ?
              <div style={{margin:"0.5rem 1rem",display:"flex",flexDirection:"row",gap:"1rem" }}>
                <div style={{ display: "flex", alignItems: "center",}}>
                <span style={{ marginRight: "0.5rem", color: "black", fontSize: "14px" }}>Page:</span>
                <Input min={1} value={pageCount} placeholder='Select Page' onChange={(e)=>{setPageCount(e.target.value)}} size='small' style={{height:"1.9rem",width:"4rem"}} />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "0.5rem", color: "black", fontSize: "14px" }}>Limit:</span>
                <Input 
                  min={0} 
                  value={pageLimit} 
                  placeholder="Select Limit" 
                  disabled 
                  onChange={(e) => setPageLimit(e.target.value)} 
                  size="small" 
                  style={{ height: "1.9rem", width: "4rem" }} 
                />
                </div>
                </div>:""}
              <Radio value={"all"}>All data</Radio>
            </Space>
          </Radio.Group>
          </div>
          </>
        :""}
        {/* {downloadWise === "pagination" ?
        <div className="r-c-c" style={{margin:"1rem 0 2rem 0"}}>
          <Input type='number' min={0} onChange={(e)=>{setPageLimit(e.target.value)}} style={{height:"2rem",width:"10rem"}} />
        </div>
        :""
        } */}
        </Modal>
      }

      {importModal && 
      <Modal open={importModal} closable={true} maskClosable={true} 
       onCancel={()=>{
         setImportModal(false);
       }}
       footer={false}
      >
       <h3
         style={{
           textAlign: "center",
           margin: "1rem",
           marginBottom: "2rem",
         }}
         level={3}
       >
         Import data
       </h3>
       <Form onFinish={onFinish}>
         <Form.Item
           name="dragger"
           valuePropName="fileList"
           getValueFromEvent={(event) => event.fileList}
           noStyle
         >
          <Upload.Dragger style={{backgroundColor:"#EAF4FF",borderColor:"#1089FF",padding:'10px'}} accept=".xls, .xlsx"
            // showUploadList={{ showRemoveIcon: true }} 
            multiple={false} maxCount={1} 
          >
            <div style={{display:"flex",flexDirection:"row",gap:"1rem",justifyContent:"center",alignItems:"center"}}>
             <div>
             <p className="ant-upload-drag-icon">
             <img src={uploadIcon} style={{height:"2.5rem",width:"2.5rem",}}/>
             </p>
             </div>

             <div style={{textAlign:"center",display:"flex",flexDirection:"column",flexWrap:"nowrap",alignItems:"center"}}>
             <p className="ant-upload-text">
               Click or drag file to this area to upload
             </p>
             <p className="ant-upload-hint">
               Support for a single or bulk upload.
             </p>
            </div>
            </div>
          </Upload.Dragger>
         </Form.Item>
         <br /> <br />
        <Form.Item>
           <div style={{display:"flex",flexDirection:"row",gap:"1rem",justifyContent:"center"}}>
            <Button style={{width:"7rem"}} onClick={()=>{
                  setImportModal(false);
                 }} 
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" style={{width:"7rem"}} loading={btnLoader}>
              UPLOAD
            </Button>
          </div>
        </Form.Item>
       </Form>
     </Modal>}

     {importTableModal && 
      <Modal open={importTableModal} width="75%" closable={false} centered
       onCancel={()=>{
         setImportTableModal(false);
         setChangedData([]);
         setUnChangedData([]);
       }}
       footer={false}
      >
       <h3
         style={{
           textAlign: "center",
           marginBottoms: "1.5rem",fontSize:"1.2rem"
         }}
       >
         Imported data
       </h3>
        <Tabs defaultActiveKey="1" onChange={(key) => {setTabKey(key)}}>
          <TabPane key={1} tab={<span style={{fontWeight:"700",fontSize:"1rem"}}>Changed</span>}> 
            <Table dataSource={changedData} columns={columns}
            // loading={Tabloader}
            // bordered
            size="small"
            scroll={{y:330,x:"79vw"}} style={{fontSize:"0.8rem"}}
            />
          </TabPane>
          <TabPane key={2} tab={<span style={{fontWeight:"700",fontSize:"1rem"}}>Unchanged</span>}> 
            <Table dataSource={unChangedData} columns={columns}
            // loading={Tabloader}
            // bordered
            size="small"
            scroll={{y:330,x:"79vw"}} style={{fontSize:"0.8rem"}}
            />
          </TabPane>
        </Tabs>
        
        <div style={{display:"flex",justifyContent:"center"}}>
          <Button type='primary' style={{width:"7rem"}} onClick={onFinishHandler} >Save</Button>
        </div>
     </Modal>}

      <EditLeave refresh = {getData.bind(this)} year = {moment(monthsVal).format("YYYY")} />
    </div>
    // </div>
  )
  }

export default AttendanceLeave;