import React, { useEffect, useState } from 'react';
import { AiFillDelete} from 'react-icons/ai';
import {  Modal, Pagination, Typography } from 'antd';
import DeleteModel from './deleteModel';
import { allCity, allHolidays,allHolidaysDeptAssociate,allDeptandAssociate } from './Action/action';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { CiLocationOn } from "react-icons/ci";
import { useNotificationStore } from './store';
import FixedHolidayModel from './fixedHolidayModel';
import Views from "../../../services/ViewRole"
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import classes from "./setting.module.css";
import DeleteIcon from "../../../assets/SettingDeleteIcon.svg";

function LocationHoliday() {

    const { allCityStore,searchVal,setAllCityStore, SetDeleteStore,currentPageHoliday,setCurrentPageHoliday, 
        setrecordStore,setdeleteCity,setfromHoliday,setNotfixed,setFixedStore,setLoad,settypeLoactionStore,
        refresh,setRefresh, holidayListBy,allDeptandAssociateStore,setAllDeptandAssociate,setHolidayListBy,
        totalItems, setTotalItems,pageSizeHoliday,setPageSizeHoliday} = useNotificationStore();
    const [centerModel, setCenterModel] = useState(false);
    const [deletes, setDelete] = useState(false)
    // const [totalItems, setTotalItems] = useState(null);
    const [holiday, setHoliday] = useState(false)
    const [type, setType] = useState('jeofence')
    const {width}=useWindowDimensions();
    const [records, setRecords] = useState(false)
    // const [refresh, setRefresh] = useState(true)
    const [fixed,setFixed]=useState(false)
    // const [currentPage, setCurrentPage] = useState(1);
    // const [pageSize,setPageSize]=useState(10);

    useEffect(() => {
        Views.updateView()
        setRefresh(true)
        setfromHoliday(false)
        if(holidayListBy === "Location"){
            allCity((res)=>{
                if (res) {
                setRefresh(false)
                if (searchVal) {
                        let filteredData = res.filter((item) =>
                        item.cityName.toLowerCase().includes(searchVal.toLowerCase())
                        );
                    setAllCityStore(filteredData);
                }
                else{
                    setAllCityStore(res)
                }    
            }})
        }
        else {
            allDeptandAssociate(holidayListBy,
                currentPageHoliday,
                pageSizeHoliday,
                searchVal,
            (res)=>{
                if (res) {
                setRefresh(false)
                setAllDeptandAssociate(res?.items)
                setTotalItems(res?.meta)
            }})
        }
    }, [searchVal,holidayListBy,pageSizeHoliday,currentPageHoliday])
    
    const handlePageChange = (page,pageSize) => {
        // setCurrentPage(page);
        setCurrentPageHoliday(page)
        setPageSizeHoliday(pageSize);
        
      };
      const startIndex = (currentPageHoliday - 1) * pageSizeHoliday;
      const endIndex = Math.min(startIndex + pageSizeHoliday, allCityStore?.length);
      const paginatedData = allCityStore?.slice(startIndex, endIndex);



    return (
        <div style={{}}>
        
        {refresh ? <Spin style={{ display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",height:"60vh",backgroundColor:"#F9F9F9",marginTop:"0.5rem" }}  />
        :
            <div style={{display:"flex",flexDirection:"column"}}>
            {width > 700 ?
            <div style={{ width: '100%',height:"69vh",overflowY:"scroll",backgroundColor:"#F9F9F9",padding:"0.2rem 0.5rem",marginTop:"0.4rem" }}>

                {holidayListBy === "Location"?<>
                  {allCityStore?.length==0 ?
                      <div style={{display:'flex',alignItems:'center',justifyContent:'center',marginTop:'25vh',fontWeight:'550'}}>
                        No data
                      </div>
                  :
                  (paginatedData.map((item,index) => (
                    <div key={index}
                        style={{
                            display: 'flex',
                            padding: '10px',marginLeft:"5px",marginRight:"5px",
                            backgroundColor: 'white',marginTop:"10px",
                            borderRadius: 8,marginBottom:"0.9rem",
                            height:"4rem",boxShadow: "0 2px 6px rgba(0, 0,0,0.2)",
                            alignItems: 'center',backgroundColor:"white",
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' ,width:'25%',marginRight:'1%'}}>
                            <CiLocationOn color='#0086FF' size={25} style={{ margin: '0 10px' }} />
                            <Typography.Text style={{width:"20vw",fontWeight:550,fontSize:14.2,}} ellipsis={{tooltip:true}}>
                            {item?.cityName}
                          </Typography.Text>
                          {/* <span style={{fontSize:"13px",fontWeight:500}}> 
                          {item.cityName}
                          </span>  */}
                        </div>
                        <span style={{ fontWeight: '500', color: '#8B8B8B',fontSize:'14px',width:'55%' }}>
                            There will be a selection of optional holidays, and we can choose 5 from them.
                        </span>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                            <div
                                onClick={() => {
                                    if(!Views?.hrAssociate){
                                    setFixed(true)
                                    setFixedStore({
                                        type:'Fixed',
                                        id:item?.id,
                                        cityName:item?.cityName,
                                        
                                    })
                                    setCenterModel(true)
                                    setLoad(true)
                                    setDelete(false)
                                    let url = `/attendance-setting/allHolidays?pageNo=${1}&limit=${100}&download=${false}&holidayType=${"Fixed"}&cityId=${item?.id}`;
                                    allHolidays(url,(res)=>{
                                        settypeLoactionStore(res)
                                        setLoad(false)
                                    })
                                  } 
                                 }}
                                style={{
                                    marginLeft: '10px',
                                    display: 'flex',
                                    background: 'white',
                                    marginRight: 10,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderColor: '#0086FF',
                                    borderWidth: 1,
                                    padding: '2.5%',
                                    borderStyle: 'solid',
                                    borderRadius: 5,
                                    fontSize: '14px',
                                    color: '#0086FF',
                                    cursor:Views?.hrAssociate?"not-allowed":'pointer',
                                    transition: 'background-color 0.3s, color 0.3s',
                                }}
                                onMouseEnter={(e) => {
                                    e.target.style.backgroundColor = '#0086FF';
                                    e.target.style.color = 'white';
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = 'white';
                                    e.target.style.color = '#0086FF';
                                }}
                            >
                                Fixed
                            </div>
                            <div
                                onClick={() => { 
                                    if(!Views?.hrAssociate){
                                    setFixed(true)
                                    setFixedStore({
                                        type:'Optional',
                                        id:item?.id,
                                        cityName:item?.cityName,

                                    })
                                    setCenterModel(true)
                                    setDelete(false)
                                    setLoad(true)
                                    let url = `/attendance-setting/allHolidays?pageNo=${1}&limit=${100}&download=${false}&holidayType=${"Optional"}&cityId=${item?.id}`;

                                    allHolidays(url,(res)=>{
                                        settypeLoactionStore(res)
                                        
                                        setLoad(false)
                                    })
                                  }
                                }}
                                style={{
                                    marginLeft: '10px',
                                    display: 'flex',
                                    background: 'white',
                                    marginRight: 10,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderColor: '#0086FF',
                                    borderWidth: 1,
                                    padding: '2.5%',
                                    borderStyle: 'solid',
                                    borderRadius: 5,
                                    fontSize: '14px',
                                    color: '#0086FF',
                                    cursor:Views?.hrAssociate?"not-allowed":'pointer',
                                    transition: 'background-color 0.3s, color 0.3s',
                                }}
                                onMouseEnter={(e) => {
                                    e.target.style.backgroundColor = '#0086FF';
                                    e.target.style.color = 'white';
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = 'white';
                                    e.target.style.color = '#0086FF';
                                }}
                            >
                                Optional
                            </div>
                            <img src={DeleteIcon}
                                style={{ marginLeft: 10,height:"2rem",
                                    cursor:Views?.hrAssociate?"not-allowed":'pointer',
                                }}
                                fontSize={'35px'}
                                onClick={() => {
                                    if(!Views?.hrAssociate){
                                    SetDeleteStore('Location Holidays');
                                    setNotfixed(true)
                                    setrecordStore(item)
                                    setType('holiday');
                                    setFixed(false)
                                    setHoliday(false);
                                    setDelete(true);
                                    setCenterModel(true);
                                    setdeleteCity(true)
                                    }
                                
                                }}
                                color='#FF3232'
                            />
                        </div>
                    </div>
                )))}</>
                :<>
                 {allDeptandAssociateStore?.length==0 ?
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center',marginTop:'25vh',fontWeight:'550'}}>
                      No data
                    </div>
                :(
                  allDeptandAssociateStore.map((item,index) => (
                        <div key={index}
                            style={{
                                display: 'flex',
                                padding: '10px',marginLeft:"5px",marginRight:"5px",
                                backgroundColor: 'white',marginTop:"10px",
                                borderRadius: 8,marginBottom:"0.9rem",
                                height:"4rem",boxShadow: "0 2px 6px rgba(0, 0,0,0.2)",
                                alignItems: 'center',backgroundColor:"white",
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' ,width:'25%',marginRight:'1%'}}>
                                <CiLocationOn color='#0086FF' size={25} style={{ margin: '0 10px' }} />
                                <Typography.Text style={{width:"20vw",fontWeight:550,fontSize:14.2,}} ellipsis={{tooltip:true}}>
                                    {item?.name}
                                </Typography.Text>
                              {/* <span style={{fontSize:"13px",fontWeight:500}}> 
                              {item.name}
                              </span>  */}
                            </div>
                            <span style={{ fontWeight: '500', color: '#8B8B8B',fontSize:'14px',width:'55%' }}>
                                There will be a selection of optional holidays, and we can choose 5 from them.
                            </span>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                <div
                                    onClick={() => {
                                        if(!Views?.hrAssociate){
                                        setFixed(true)
                                        setFixedStore({
                                            type:'Fixed',
                                            id:item?.id,
                                            cityName:item?.name,
                                            holidayBy:holidayListBy
                                        })
                                        setCenterModel(true)
                                        setLoad(true)
                                        setDelete(false)
                                        let url = `/attendance-setting/getHoidayDataByType?holidayType=${"Fixed"}&id=${item?.id}&type=${holidayListBy}`;
                                        // allHolidays(url,(res)=>{
                                        //     settypeLoactionStore(res)
                                        //     setLoad(false)
                                        // })
                                        allHolidaysDeptAssociate(url,(res)=>{
                                            setLoad(false);
                                            settypeLoactionStore(res)
                                            setLoad(false)
                                        })
                                      } 
                                     }}
                                    style={{
                                        marginLeft: '10px',
                                        display: 'flex',
                                        background: 'white',
                                        marginRight: 10,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderColor: '#0086FF',
                                        borderWidth: 1,
                                        padding: '2.5%',
                                        borderStyle: 'solid',
                                        borderRadius: 5,
                                        fontSize: '14px',
                                        color: '#0086FF',
                                        cursor:Views?.hrAssociate?"not-allowed":'pointer',
                                        transition: 'background-color 0.3s, color 0.3s',
                                    }}
                                    onMouseEnter={(e) => {
                                        e.target.style.backgroundColor = '#0086FF';
                                        e.target.style.color = 'white';
                                    }}
                                    onMouseLeave={(e) => {
                                        e.target.style.backgroundColor = 'white';
                                        e.target.style.color = '#0086FF';
                                    }}
                                >
                                    Fixed
                                </div>
                                <div
                                    onClick={() => { 
                                        if(!Views?.hrAssociate){
                                        setFixed(true)
                                        setFixedStore({
                                            type:'Optional',
                                            id:item?.id,
                                            cityName:item?.name,
                                            holidayBy:holidayListBy
                                        })
                                        setCenterModel(true)
                                        setDelete(false)
                                        setLoad(true)
                                        let url = `/attendance-setting/getHoidayDataByType?holidayType=${"Optional"}&id=${item?.id}&type=${holidayListBy}`;
                                        // allHolidays(url,(res)=>{
                                        //     settypeLoactionStore(res)
                                        //     setLoad(false)
                                        // })
                                        allHolidaysDeptAssociate(url,(res)=>{
                                            settypeLoactionStore(res)
                                            setLoad(false)
                                        })
                                      }
                                    }}
                                    style={{
                                        marginLeft: '10px',
                                        display: 'flex',
                                        background: 'white',
                                        marginRight: 10,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderColor: '#0086FF',
                                        borderWidth: 1,
                                        padding: '2.5%',
                                        borderStyle: 'solid',
                                        borderRadius: 5,
                                        fontSize: '14px',
                                        color: '#0086FF',
                                        cursor:Views?.hrAssociate?"not-allowed":'pointer',
                                        transition: 'background-color 0.3s, color 0.3s',
                                    }}
                                    onMouseEnter={(e) => {
                                        e.target.style.backgroundColor = '#0086FF';
                                        e.target.style.color = 'white';
                                    }}
                                    onMouseLeave={(e) => {
                                        e.target.style.backgroundColor = 'white';
                                        e.target.style.color = '#0086FF';
                                    }}
                                >
                                    Optional
                                </div>
                                {/* <img src={DeleteIcon}
                                    style={{ marginLeft: 10,height:"2rem",
                                        cursor:Views?.hrAssociate?"not-allowed":'pointer',
                                    }}
                                    fontSize={'35px'}
                                    onClick={() => {
                                        if(!Views?.hrAssociate){
                                        SetDeleteStore('Location Holidays');
                                        setNotfixed(true)
                                        setrecordStore(item)
                                        setType('holiday');
                                        setFixed(false)
                                        setHoliday(false);
                                        setDelete(true);
                                        setCenterModel(true);
                                        setdeleteCity(true)
                                        }
                                    
                                    }}
                                    color='#FF3232'
                                /> */}
                            </div>
                        </div>
                    )))
                }</>
                }
                {/* {
                    (allCityStore?.length==0 || allDeptandAssociateStore?.length === 0) &&
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center',marginTop:'25vh',fontWeight:'550'}}>
                        No data
                    </div>
                } */}
            </div>
            :
            <div className={classes.ListBodyContainer} style={{height:"64vh", overflowY:'scroll',overflowX:"scroll",width:"100%",backgroundColor:"#F9F9F9",padding:"0.2 2rem",marginTop:"0.5rem"}}>
            {/* {refresh?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'67vh',}}><Spin /></div>:
             paginatedData?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'67vh'}}><div>No data found</div></div>: */}
             {holidayListBy === "Location"?<>
              {allCityStore?.length==0 ?
                <div style={{display:'flex',alignItems:'center',justifyContent:'center',marginTop:'25vh',fontWeight:'550'}}>
                No data
                </div>
               :
              (
              paginatedData?.map((item,index)=>{
                return(
                  <div style={{width:"",marginTop:"0.3rem",borderRadius:10,backgroundColor:"#FFFFFF",padding:"1rem",margin:"1rem"}}>
                        
                        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%"}}>
                        <div style={{ display: 'flex', alignItems: 'center',gap:"0.2rem"}}>
                          <CiLocationOn color='#0086FF' size={25} style={{ height: '',}} />
                          <Typography.Text style={{width:"55vw",fontWeight:700,fontSize:18,}} ellipsis={{tooltip:true}}>
                            {item?.cityName}
                          </Typography.Text>
                          {/* <span style={{fontSize:"18px",fontWeight:500}}> {item?.cityName}</span>  */}
                        </div>
                        <img src={DeleteIcon}
                                style={{ marginLeft: 10,
                                    cursor:Views?.hrAssociate?"not-allowed":'pointer',
                                }}
                                fontSize={'35px'}
                                onClick={() => {
                                    if(!Views?.hrAssociate){
                                    SetDeleteStore('Location Holidays');
                                    setNotfixed(true)
                                    setrecordStore(item)
                                    setType('holiday');
                                    setFixed(false)
                                    setHoliday(false);
                                    setDelete(true);
                                    setCenterModel(true);
                                    setdeleteCity(true)
                                    }
                                
                                }}
                                color='#FF3232'
                            />
                        </div>
                        <span style={{ fontWeight: '500', color: '#8B8B8B',fontSize:'17px',width:'55%',marginTop:"0.5rem" }}>
                            There will be a selection of optional holidays, and we can choose 5 from them.
                        </span>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                            <div
                                onClick={() => {
                                    
                                        setFixed(true)
                                    setFixedStore({
                                        type:'Fixed',
                                        id:item?.id,
                                        cityName:item?.cityName,
                                        

                                    })
                                    setCenterModel(true)
                                    setLoad(true)
                                    setDelete(false)

                                    let url = `/attendance-setting/allHolidays?pageNo=${1}&limit=${100}&download=${false}&holidayType=${"Fixed"}&cityId=${item?.id}`;

                                    allHolidays(url,(res)=>{
                                        settypeLoactionStore(res)
                                        setLoad(false)
                                    })
                                    
                                 }}
                                style={{
                                    // marginLeft: '10px',
                                    display: 'flex',
                                    background: 'white',
                                    marginRight: 10,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderColor: '#0086FF',
                                    borderWidth: 1,
                                    padding: '2.5%',
                                    borderStyle: 'solid',
                                    borderRadius: 5,
                                    width:"13rem",
                                    fontSize: '18px',
                                    color: '#0086FF',
                                    cursor:'pointer',
                                    transition: 'background-color 0.3s, color 0.3s',
                                }}
                                onMouseEnter={(e) => {
                                    e.target.style.backgroundColor = '#0086FF';
                                    e.target.style.color = 'white';
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = 'white';
                                    e.target.style.color = '#0086FF';
                                }}
                            >
                                Fixed
                            </div>
                            <div
                                onClick={() => { 
                                    setFixed(true)
                                    setFixedStore({
                                        type:'Optional',
                                        id:item?.id,
                                        cityName:item?.cityName,

                                    })
                                    setCenterModel(true)
                                    setDelete(false)
                                    setLoad(true)
                                    let url = `/attendance-setting/allHolidays?pageNo=${1}&limit=${100}&download=${false}&holidayType=${"Optional"}&cityId=${item?.id}`;

                                    allHolidays(url,(res)=>{
                                        settypeLoactionStore(res)
                                        
                                        setLoad(false)
                                    })
                                }}
                                style={{
                                    marginLeft: '10px',
                                    display: 'flex',
                                    background: 'white',
                                    cursor:'pointer',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderColor: '#0086FF',
                                    borderWidth: 1,
                                    padding: '2.5%',
                                    width:"13rem",
                                    borderStyle: 'solid',
                                    borderRadius: 5,
                                    fontSize: '18px',
                                    color: '#0086FF',
                                    transition: 'background-color 0.3s, color 0.3s',
                                }}
                                onMouseEnter={(e) => {
                                    e.target.style.backgroundColor = '#0086FF';
                                    e.target.style.color = 'white';
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = 'white';
                                    e.target.style.color = '#0086FF';
                                }}
                            >
                                Optional
                            </div>
                          
                        </div>
                  </div>
                )
              }))}</>
              :<>
              {allDeptandAssociateStore?.length==0 ?
                 <div style={{display:'flex',alignItems:'center',justifyContent:'center',marginTop:'25vh',fontWeight:'550'}}>
                   No data
                 </div>
              :(
                allDeptandAssociateStore?.map((item,index)=>{
                    return(
                      <div style={{width:"",marginTop:"0.3rem",borderRadius:10,backgroundColor:"#FFFFFF",padding:"1rem",margin:"1rem"}}>
                            
                            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%"}}>
                            <div style={{ display: 'flex', alignItems: 'center',gap:"0.2rem"}}>
                              <CiLocationOn color='#0086FF' size={25} style={{ height: '',}} />
                              <Typography.Text style={{width:"55vw",fontWeight:700,fontSize:18,}} ellipsis={{tooltip:true}}>
                                {item?.name}
                              </Typography.Text>
                              {/* <span style={{fontSize:"18px",fontWeight:500}}> {item?.cityName}</span>  */}
                            </div>
                            {/* <img src={DeleteIcon}
                                    style={{ marginLeft: 10,
                                        cursor:Views?.hrAssociate?"not-allowed":'pointer',
                                    }}
                                    fontSize={'35px'}
                                    onClick={() => {
                                        if(!Views?.hrAssociate){
                                        SetDeleteStore('Location Holidays');
                                        setNotfixed(true)
                                        setrecordStore(item)
                                        setType('holiday');
                                        setFixed(false)
                                        setHoliday(false);
                                        setDelete(true);
                                        setCenterModel(true);
                                        setdeleteCity(true)
                                        }
                                    
                                    }}
                                    color='#FF3232'
                                /> */}
                            </div>
                            <span style={{ fontWeight: '500', color: '#8B8B8B',fontSize:'17px',width:'55%',marginTop:"0.5rem" }}>
                                There will be a selection of optional holidays, and we can choose 5 from them.
                            </span>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                <div
                                    onClick={() => {
                                        
                                        setFixed(true)
                                        setFixedStore({
                                            type:'Fixed',
                                            id:item?.id,
                                            cityName:item?.name,
                                            holidayBy:holidayListBy
    
                                        })
                                        setCenterModel(true)
                                        setLoad(true)
                                        setDelete(false)
    
                                        let url = `/attendance-setting/getHoidayDataByType?holidayType=${"Fixed"}&id=${item?.id}&type=${holidayListBy}`;
                                        // allHolidays(url,(res)=>{
                                        //     settypeLoactionStore(res)
                                        //     setLoad(false)
                                        // })
                                        allHolidaysDeptAssociate(url,(res)=>{
                                            settypeLoactionStore(res)
                                            setLoad(false)
                                        })
                                        
                                     }}
                                    style={{
                                        // marginLeft: '10px',
                                        display: 'flex',
                                        background: 'white',
                                        marginRight: 10,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderColor: '#0086FF',
                                        borderWidth: 1,
                                        padding: '2.5%',
                                        borderStyle: 'solid',
                                        borderRadius: 5,
                                        width:"13rem",
                                        fontSize: '18px',
                                        color: '#0086FF',
                                        cursor:'pointer',
                                        transition: 'background-color 0.3s, color 0.3s',
                                    }}
                                    onMouseEnter={(e) => {
                                        e.target.style.backgroundColor = '#0086FF';
                                        e.target.style.color = 'white';
                                    }}
                                    onMouseLeave={(e) => {
                                        e.target.style.backgroundColor = 'white';
                                        e.target.style.color = '#0086FF';
                                    }}
                                >
                                    Fixed
                                </div>
                                <div
                                    onClick={() => { 
                                        setFixed(true)
                                        setFixedStore({
                                            type:'Optional',
                                            id:item?.id,
                                            cityName:item?.name,
                                            holidayBy:holidayListBy
                                        })
                                        setCenterModel(true)
                                        setDelete(false)
                                        setLoad(true)
                                        let url = `/attendance-setting/getHoidayDataByType?holidayType=${"Optional"}&id=${item?.id}&type=${holidayListBy}`;
                                        // allHolidays(url,(res)=>{
                                        //     settypeLoactionStore(res)
                                        //     setLoad(false)
                                        // })
                                        allHolidaysDeptAssociate(url,(res)=>{
                                            settypeLoactionStore(res)
                                            setLoad(false)
                                        })
                                    }}
                                    style={{
                                        marginLeft: '10px',
                                        display: 'flex',
                                        background: 'white',
                                        cursor:'pointer',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderColor: '#0086FF',
                                        borderWidth: 1,
                                        padding: '2.5%',
                                        width:"13rem",
                                        borderStyle: 'solid',
                                        borderRadius: 5,
                                        fontSize: '18px',
                                        color: '#0086FF',
                                        transition: 'background-color 0.3s, color 0.3s',
                                    }}
                                    onMouseEnter={(e) => {
                                        e.target.style.backgroundColor = '#0086FF';
                                        e.target.style.color = 'white';
                                    }}
                                    onMouseLeave={(e) => {
                                        e.target.style.backgroundColor = 'white';
                                        e.target.style.color = '#0086FF';
                                    }}
                                >
                                    Optional
                                </div>
                              
                            </div>
                      </div>
                    )
                }))}</>
             }
            </div> 
          }
            <div>
                <Pagination position="bottomRight" size='small' style={{display:"flex",justifyContent:"flex-end",marginTop:"0.5rem",fontSize:width<700&&15,}}
                current={currentPageHoliday} showSizeChanger={true} pageSize={pageSizeHoliday} 
                total={holidayListBy === "Location" ? allCityStore?.length : totalItems?.totalItems}
                onChange={handlePageChange} onShowSizeChange={handlePageChange} pageSizeOptions={[10,20,30,50]}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} />
            </div>
            </div>
        }

            {centerModel && <Modal
                color='red'
                centered
                open={centerModel}
                footer={null}
                bodyStyle={{height:fixed && width > 700 ?"90vh": fixed && width < 700 ? "80vh" : "30vh"}}
                onOk={() => setCenterModel(false)}
                onCancel={() => setCenterModel(false)}
                width={fixed?900:900}

            >
                {
                fixed&&<FixedHolidayModel/>
                }


                {deletes &&
                    <DeleteModel setCenterModel={setCenterModel} record={records} setDelete={setDelete} type={type} setRefresh={setRefresh} />
                }

            </Modal>}
        </div>
    );
}

export default LocationHoliday;