import { CloseOutlined } from "@ant-design/icons";
import { Button, Modal, Select, Radio } from "antd";
import React from "react";
import PreIcon from "../../../../assets/Appraisal/preIcon.svg";
import dropBlackIcon from "../../../../assets/Appraisal/downBlackIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { notifyAll, savePrivilege, sendAllRequest } from "../actionsAdmin";
import { adminActions } from "../store";
import { globalActions } from "../../store/loader";

function UsersModal({ preModal, setPreModal, sendModal, setSendModal }) {
  // console.log("propsnn",preModal)

  const { users } = useSelector((state) => state.admin);
  const { privileges } = useSelector((state) => state.admin);
  const [multiSelect, setMultiSelect] = useState([]);
  const [selectRole, setSelectRole] = useState();
  console.log("preeloop", users);
  const handlePreOk = () => {
    console.log("okok");
  };

  const dispatch = useDispatch();

  const userIds = users.map((user) => user.id);

  console.log("idddsss", userIds);

  const options = [
    { value: "Admin", label: "Admin" },
    { value: "Associate", label: "Associate" },
    { value: "Department Head", label: "Department Head" },
    { value: "HR", label: "HR" },
    { value: "Manager", label: "Manager" },
  ];

  return (
    <div>
      <Modal
        title={
          <div
            style={{
              display: "flex",
              fontSize: "1.3rem",
              fontWeight: "bold",
              color: "#0086FF",
              borderBottom:"1px solid #dfdfdf",
              paddingBottom:"0.5rem"

            }}
          >
            <img src={PreIcon} />
            <div style={{ marginLeft: "1rem" }}>Privilege Role</div>
          </div>
        }
        centered
        open={preModal}
        onCancel={() => setPreModal(false)}
        onOk={handlePreOk}
        footer={null}
        width={"50%"}
        bodyStyle={{ padding: 0, height: "20rem" }}
        closeIcon={<CloseOutlined style={{ color: "" }} />}
        style={{}}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0",
            marginTop:"2rem"
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label style={{ fontSize: "1rem", fontWeight: "bold" }}>
              Select Associate <span style={{ color: "#FF0000" }}>*</span>
            </label>

            <Select
              // suffixIcon={<img src={dropBlackIcon} />}
              mode="multiple"
              showSearch
              style={{ width: "100%" }}
              placeholder="Select Associate"
              optionFilterProp="label"
              allowClear
              // filterOption={(input, option) =>
              //   option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              // }
              onChange={(e) => {
                setMultiSelect(e);
              }}
            >
              {privileges.map((option, index) => (
                <Select.Option
                  key={index}
                  value={option.id}
                  label={option.name}
                >
                  {/* {console.log("options", option)} */}
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "1.5rem",
            }}
          >
            <label style={{ fontSize: "1rem", fontWeight: "bold" }}>
              Role <span style={{ color: "#FF0000" }}>*</span>
            </label>

            <Select
            placeholder="Select the Role"
              suffixIcon={<img src={dropBlackIcon} />}
              onChange={(e) => {
                setSelectRole(e);
              }}
            >
              {options.map((option) => (
                <Select.Option
                  key={option.value}
                  value={option.value}
                  label={option.label}
                >
                  {option?.value}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "4rem",
            }}
          >
            <Button
              style={{
                width: "7rem",
                height:"2.3rem",
                marginRight: "1rem",
                borderRadius: "4px",
              }}
              onClick={() => {
                setPreModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              style={{ width: "7rem", borderRadius: "4px", height:"2.3rem", }}
              onClick={() => {
                savePrivilege(
                  { userIds: multiSelect, role: selectRole },
                  (res) => {
                    setPreModal(res?.show?.type === "success" ? false : false);
                  }
                );
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>

      {/* {console.log("multiOverrr",multiSelect,selectRole)} */}

      <div>
        <Modal
          title={
            <div
              style={{
                display: "flex",
                fontSize: "1.3rem",
                fontWeight: "bold",
                color: "#0086FF",justifyContent:"center",textAlign:"center"
              }}
            >
              Send All
            </div>
          }
          open={sendModal}
          onCancel={() => setSendModal(false)}
          //   onOk={handlePreOk}
          footer={null}
          width={"50%"} centered
          // bodyStyle={{ padding: 0, height: "12rem" }}
          closeIcon={<CloseOutlined style={{ color: "" }} />}
          style={{}}
        >
          <div
            style={{
              fontSize: "1.1rem",
              fontWeight: 600,
              width: "100%",
              height: "100%",
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              {" "}
              Are you sure to send feedback form to all?
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "3rem 0 1rem 0",
              }}
            >
              <Button
                style={{
                  width: "7rem",
                  marginRight: "1rem",
                  borderRadius: "4px",
                }}
                onClick={() => {
                  setSendModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                style={{ width: "7rem", borderRadius: "4px" }}
                onClick={() => {
                  dispatch(globalActions.updateLoader({ loader: true }));
                  notifyAll((res) => {
                    if (res.show.type === "success") {
                      setSendModal(false);
                      dispatch(
                        adminActions.updateRefreshEffect(
                          (olddata) => olddata + 1
                        )
                      );
                    }
                    dispatch(globalActions.updateLoader({ loader: false }));
                  });
                }}
              >
                Send
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default UsersModal;
