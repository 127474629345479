import React, { useEffect } from 'react';
import classes from "./style.module.css";
import Logo from '../../assets/loginLogo.svg';
import CheckImage from "../../assets/checkImage.png";
import Union from "../../assets/Union.svg";
import Image from "../../assets/checkMail.svg";
import Circle from "../../assets/Circle.svg";
import Union1 from "../../assets/Union 1.svg";
import Union2 from "../../assets/Union 2.svg";
import { Card, Form, Input,Button,Row,Col } from 'antd';
import { ArrowRightOutlined,MinusOutlined } from '@ant-design/icons';
import useStore from '../../pages/store';
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";

const CheckMail=({onResend})=>{

  const {newUser,user}=useStore(state=>state);
  const {width}=useWindowDimensions();

  useEffect(()=>{
    if(user){
      window.localStorage.clear()
    }
  },[])

    return (
      
              
                  <div className={classes.checkMail}>
                    <h2 style={{fontSize:width<700&&"21px",color:"#162432"}}>Please Check your email</h2>
                  
                    <p style={{marginTop:"0.5rem",color:"#16243280",fontSize:13,textAlign:"center"}}>Please click on the verify email link sent to your registered email</p>
                    <div style={{display:"flex",alignItems:"center",gap:"0.5rem",margin:"4rem 0 1rem 0",fontWeight:"600",fontSize:"0.9rem"}}>
                        <span>Didn’t receive the link?</span>
                        <span
                        style={{color:"#0086ff",fontSize:"1rem",cursor:"pointer"}}
                        onClick={onResend}
                        >Resend</span>
                    </div>
                   
                    <div className={classes.checkEmail} style={{marginBottom:width<700&&"2rem"}}>
                      <Button type="primary" onClick={()=>{window.open("https://mail.zoho.in")}}
                        style={{fontSize:width<700&&"17px",height:width<700&&"2.6rem"}}>
                          Open Email 
                      </Button>
                    </div>
                   </div>
               
         
            
          
    )
}
export default CheckMail