import { Select, Modal, Radio, Space, Divider, Spin, Pagination, DatePicker, Input, Form, Upload , Button as AntdButton,Tooltip} from "antd";
import React, { useEffect, useRef, useState } from "react";
import Button from "../../../components/buttonCustom";
import Api from "../../../services/Api";
import { AiOutlineVerticalAlignBottom, AiOutlineReload, } from "react-icons/ai";
import { DownLoadAttendnaceLogs, getLogsData, getRecalculateApi, getLogsDetails, userSelectCalendar, applyLeaveModalApi, } from "../action/action";
import searchIcon from "../../../assets/search_Icon.svg";
import { useAttendnaceStore } from "../store/store";
import { getFilterValues } from "../../ProjectManagement/action/action";
import { useProjecteStore } from "../../ProjectManagement/store/store";
import moment from "moment";
import UpdateAttendanceModal from "./updateAttendance";
import ManualPunchModal from "./multiplePunches";
import DownloadModal from "./customDownload";
import GeoFence from "./geoFence";
import DownloadIcon from "../../../assets/Download Icon Blue.svg";
import FilterIcon from "../../../assets/filterIcon.svg";
import classes from "../../AttendancePortal/Logs/Calender.module.css";
import ApplyLeave from "./applyLeave";
import LogCalenderIcon from "../../../assets/LogCalenderIcon.svg";
import LogMapIcon from "../../../assets/LogMapIcon.svg";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Views from "../../../services/ViewRole";
import AscendingSortIcon from "../../../assets/AscendingSortIcon.svg";
import DescendingSortIcon from "../../../assets/DescendingSortIcon.svg";
import dayjs from "dayjs";
import { useNavigate } from 'react-router-dom';
import Excel from "../../../assets/excel.png";
import uploadIcon from '../../../assets/UploadResume.svg';
import Notification from "../../../services/Notification";
import viewClass from "../../../services/ViewRole";


const { Option } = Select;
const { RangePicker } = DatePicker;

const AttendanceLogs = () => {
  const navigate = useNavigate();
  const year = moment().format("YYYY");
  const tableHeader = [
    "Associate Name",
    "Contact Details",
    "Department",
    "Manager",
    "Working Hour",
    "W D",
    "P",
    "A",
    "O H",
    "L",
    "Actions",
  ];
  const {
    setLogData, logsTableData, logFilters, tableMetaData, setTableMetaData, render, setRender,
    selectedUser, setLoader, loader, attendanceLogsData, setAttendanceLogsData, openGeofence,
    setOpenGeofence, userId, setSelectedUser, setApplyLeaveModal, updateModal, setUpdateModal,
    manualPunchModal, setManualPunchModal, userCalendarDrop, setUserCalendarDrop, setMonthsData,
    selectAssociate, tabs, setTabs, value, setValue, Data, setData, selectData, setSelectData,
    setSelectAssociate, uiSelectId, setUiSelectId, filterModal, setFilterModal, filterModalLogs, setFilterModalLogs,
    setApplyLeaveData, monthsVal, setMonthsVal, monthsValSub, setMonthsValSub, open, setOpen, search, setSearch,
    date, setDate, currentPage, setCurrentPage, pageSize, setPageSize, filteredDeptValue, setFilteredDeptValue,
    filteredManagerValue, setFilteredManagerValue, sortingEmployee, setSortingEmployee, isApplyFiltersModal,
    applyFiltersModalHandler, btnLoader, setBtnLoader, selectedRowId, setSelectedRowId, mapCenter, setMapCenter,
    startDate, endDate, setStartDate, setEndDate, setAssociateIdInLog
  } = useAttendnaceStore((state) => state);

  const disabledEndDate = current => {
    if (!startDate) {
      return false;
    }
    const startMoment = moment(startDate);
    const endMoment = moment(startDate).add(3, 'months');
    return current && (current < startMoment || current > endMoment);
  };

  const { setFilterValues, filteredData } = useProjecteStore((state) => state);
  const [tableHeaderData, setTableHeadrData] = useState([]);
  const [importModal, setImportModal] = useState(false);
  // const [tabs, setTabs] = useState("Active Users");
  // const [value, setValue] = useState(1);
  // const [isApplyFiltersModal, applyFiltersModalHandler] = useState(false);
  // const [monthsVal, setMonthsVal] = useState(
  //   `${moment().format("MM").slice(0, 3)},${year}`
  // );
  // const [monthsValSub, setMonthsValSub] = useState(
  //   `${moment().format("MMM")}, ${moment().year()}`
  // );
  // const [selectedMonth, setSelectedMonth] = useState();
  // const [selectedYear, setSelectedYear] = useState(moment().year());
  // const [btnLoader, setBtnLoader] = useState(false);
  // const [Data, setData] = useState(null);
  // const [selectData, setSelectData] = useState(null);
  // const [openGeofence,setOpenGeofence] =useState(false);
  // const [open, setOpen] = useState(false);
  // const [search,setSearch]=useState("");
  // const [date,setDate]=useState( `${moment().format("MM").slice(0, 3)},${year}`);
  // const [uiSelectId,setUiSelectId]=useState(null);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [pageSize,setPageSize]=useState(10);
  // const [filteredOptions,setFilteredOptions]=useState([]);
  // const [filteredDeptValue,setFilteredDeptValue]=useState(null);
  // const [filteredManagerValue,setFilteredManagerValue]=useState(null);
  // const [filterModal,setFilterModal,] = useState(false);
  // const [filterModalLogs,setFilterModalLogs] = useState(false);
  // const [sortingEmployee,setSortingEmployee] = useState('ASC');
  const { width } = useWindowDimensions();
  const dates = `${moment().format("MM").slice(0, 3)},${year}`
  const selectedRowRef = useRef(null);
  const [shouldScroll, setShouldScroll] = useState(false);

  const setColor = {
    absent: "#F64A3F",
    leave: "#FD8600",
    holiday: "linear-gradient(100deg, #FDDC7A 20%,red )",
    "Forgot Punch": "#FB29FF ",
    present: "#0AAD43",
    "Week Off": "#999999",
    admin: "",
    optional: "#34FFDA",
    "half day": "#CC6760",
    "Outdoor Punch": "#005000",
    // "paternity" : "#9A8745",
    // "wedding" : "#C2F339",
    // maternity:"#9A8745"
  };
  const colors = {
    present: "#0AAD43",
    leave: "#FD8600",
    "Week Off": "#999999",
    admin: "",
    optional: "#34FFDA",
    "Forgot Punch": "#FB29FF ",
    holiday: "linear-gradient(100deg, #FDDC7A 20%,red )",
    "half day": "#CC6760",
    "Outdoor Punch": "#005000",
    // "paternity" : "#9A8745",
    // "wedding" : "#C2F339",
    // "maternity": "#9A8745"
  };

  const colorsType = {
    "paternity": "#9A8745",
    "wedding": "#C2F339",
    "leave": "#FD8600",
    holiday: "linear-gradient(100deg, #FDDC7A 20%,red )",
    "Forgot Punch": "#FB29FF",
    "present": "#0AAD43",
    "Week Off": "#999999",
    admin: "",
    "optional": "#34FFDA",
    "half day": "#CC6760",
    "Outdoor Punch": "#005000",
  };

  const monthNameToNumber = {
    Jan: '01',
    Feb: '02',
    Mar: '03',
    Apr: '04',
    May: '05',
    Jun: '06',
    Jul: '07',
    Aug: '08',
    Sep: '09',
    Oct: '10',
    Nov: '11',
    Dec: '12',

    '01': '01',
    '02': '02',
    '03': '03',
    '04': '04',
    '05': '05',
    '06': '06',
    '07': '07',
    '08': '08',
    '09': '09',
    '10': '10',
    '11': '11',
    '12': '12',
  };

  const monthNameToNumberMonth = {
    '01': "Jan",
    '02': "Feb",
    '03': "Mar",
    '04': "Apr",
    '05': "May",
    '06': "Jun",
    '07': "Jul",
    '08': "Aug",
    '09': "Sep",
    '10': "Oct",
    '11': "Nov",
    '12': "Dec",

    Jan: 'Jan',
    Feb: 'Feb',
    Mar: 'Mar',
    Apr: 'Apr',
    May: 'Mar',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sep: 'Sep',
    Oct: 'Oct',
    Nov: 'Nov',
    Dec: 'Dec',
  }

  const monthFullName = {
    '01': "January",
    '02': "February",
    '03': "March",
    '04': "April",
    '05': "May",
    '06': "June",
    '07': "July",
    '08': "August",
    '09': "September",
    '10': "October",
    '11': "November",
    '12': "December",

    Jan: 'January',
    Feb: 'February',
    Mar: 'March',
    Apr: 'April',
    May: 'May',
    Jun: 'June',
    Jul: 'July',
    Aug: 'August',
    Sep: 'September',
    Oct: 'October',
    Nov: 'November',
    Dec: 'December',
  }

  const setMonths = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
  };
  const getMonth = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    Views.updateView();
  }, [])

  const showModal = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const onChange = (e) => {
    setValue(e.target.value);
  };

  // useEffect(() => {
  //   if (filteredDeptValue) {
  //     // const filteredDatas = filteredData.filter(
  //     //   item =>
  //     //     item.department === filteredDeptValue
  //     // );
  //     // setTableDatasValue(filteredDatas);
  //     // setTotalDatas(filteredDatas.length);
  //   } else if (filteredManagerValue) {
  //     // const filteredDatas = logsTableData.filter(
  //     //   item =>
  //     //     item.Manager.props.children.props.children === filteredManagerValue
  //     // );
  //     // setTableDatasValue(filteredDatas);
  //     // setTotalDatas(filteredDatas.length);
  //   } else {
  //     setTableDatasValue(logsTableData);
  //     setTotalDatas(tableMetaData?.totalItems);
  //   }
  // }, [filteredDeptValue, filteredManagerValue, logsTableData]);

  const getData = ()=>{
    let params = {
      ExEmployee: tabs === "Inactive Users" ? true : false,
      search: search,
      page: currentPage,
      limit: pageSize,
      departmentId: filteredDeptValue,
      managerId: filteredManagerValue,
      employeeIdSort: sortingEmployee
    }
    if (!startDate && !endDate) {
      params = {
        ...params, month: date === null ? monthNameToNumber[dates.split(",")[0]] : monthNameToNumber[date?.split(",")[0]],
        year: date === null ? dates?.split(",")[1] : date?.split(",")[1],
      }
    }
    else if (startDate && endDate) {
      params = { ...params, startDate: startDate, endDate: endDate }
    }
    getLogsData(params,
      (res) => {
        setLogData(res);
        setTableMetaData(res?.meta)
      },
      setLoader
    );
  }

  useEffect(() => {
    let params = {
      ExEmployee: tabs === "Inactive Users" ? true : false,
      search: search,
      page: currentPage,
      limit: pageSize,
      departmentId: filteredDeptValue,
      managerId: filteredManagerValue,
      employeeIdSort: sortingEmployee
    }
    if (!startDate && !endDate) {
      params = {
        ...params, month: date === null ? monthNameToNumber[dates.split(",")[0]] : monthNameToNumber[date?.split(",")[0]],
        year: date === null ? dates?.split(",")[1] : date?.split(",")[1],
      }
    }
    else if (startDate && endDate) {
      params = { ...params, startDate: startDate, endDate: endDate }
    }
    getLogsData(params,
      (res) => {
        setLogData(res);
        setTableMetaData(res?.meta)
      },
      setLoader
    );

  }, [search, date, pageSize, currentPage, filteredDeptValue, filteredManagerValue, sortingEmployee, endDate !== null && endDate !== undefined ? startDate : "", endDate])



  useEffect(() => {
    let params = {
      ExEmployee: tabs === "Inactive Users" ? true : false, page: currentPage, search: search,
      limit: pageSize, departmentId: filteredDeptValue, managerId: filteredManagerValue,
      employeeIdSort: sortingEmployee,
    }
    if (!startDate && !endDate) {
      params = {
        ...params, month: date === null ? monthNameToNumber[dates.split(",")[0]] : monthNameToNumber[date?.split(",")[0]],
        year: date === null ? dates?.split(",")[1] : date?.split(",")[1],
      }
    }
    else if (startDate && endDate) {
      params = { ...params, startDate: startDate, endDate: endDate }
    }
    setLoader(true);
    getFilterValues(setFilterValues, "departmentAttendance");
    getLogsData(params,
      (res) => {
        setLogData(res);
        setTableMetaData(res?.meta)
      },
      setLoader
    );
    userSelectCalendar((res) => {
      console.log('kaushal12345', res, tabs)
      if (tabs === 'Active Users') {
        setUserCalendarDrop(res?.activeUsers);
      }
      else if (tabs === 'Inactive Users') {
        setUserCalendarDrop(res?.inactiveUsers);

      }
    });



    return () => {
      setRender(1);
    };
  }, [tabs, pageSize, currentPage, filteredDeptValue, filteredManagerValue, sortingEmployee, endDate !== null && endDate !== undefined ? startDate : "", endDate])

  const onDownload = (values) => {
    DownLoadAttendnaceLogs(
      applyFiltersModalHandler(false)
      , `/attendance-logs/custom-download`, {
      departmentId: values.departmentId,
      teamId: values.teamId,
      startDate: values.startDate,
      endDate: values.endDate,
      // month: setMonths[monthsVal.slice(0, 3)],
      // year: moment().format("YYYY"),
      exEmployee: tabs === "Inactive Users" ? true : false
    }, 1);
  };

  useEffect(() => {
    if (logFilters?.department.length) {
      let tempArr = [];
      const filteredOptionss = logFilters?.department.filter(option => option.value !== '');
      // setFilteredOptions(filteredOptionss)
      // setManagerFilterOption(logFilters?.manager)
      tableHeader.forEach((element) => {
        if (element === "Department") {
          tempArr.push({
            title: element,
            dataIndex: element,
            width: "11%",
          });
        } else if (element === "Manager") {
          tempArr.push({
            title: element,
            dataIndex: element,
            width: "10%",
          });
        } else {
          if (element === "Associate Name") {
            tempArr.push({
              title: element,
              dataIndex: element,
              width: "15%",
            });
          } else if (element === "Working Hour") {
            tempArr.push({
              title: element,
              dataIndex: element,
              width: "8%",
            });
          } else if (element === "Contact Details") {
            tempArr.push({
              title: element,
              dataIndex: element,
              width: "13%",
            });
          } else if (element === "Actions") {
            tempArr.push({
              title: element,
              dataIndex: element,
              width: "8%",

            });
          } else if (element === "Contact Details") {
            tempArr.push({
              title: element,
              dataIndex: element,
              width: "35%",
            });
          } else if (element === "W D") {
            tempArr.push({
              title: element,
              dataIndex: element,
              width: "4%",
            });
          } else if (element === "O H") {
            tempArr.push({
              title: element,
              dataIndex: element,
              width: "4%",
            });
          } else {
            tempArr.push({
              title: element,
              dataIndex: element,
              width: "5%",
            });
          }
        }
      });
      setTableHeadrData([...tempArr]);
    }
  }, [logFilters?.department.length]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  function handleSearch(e){
    setCurrentPage(1);
    setPageSize(pageSize)
    setSearch(e.target.value);

  }

  const [scrollPosition, setScrollPosition] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [Tabloader,setTabloader] = useState(false)

  const onFinish = (values) => {
    console.log("triggered475",values);
    if(values?.dragger?.length){
      if ((!values?.dragger)) {
        let title = "please import file"
        Notification.error(title)
        return
  
      }
      const params = {
        files: [values?.dragger[0]?.originFileObj],
      };
      setTabloader(true)
      Api.post("/attendance-attendance/uploadLogData")
        .upload(params, (percent, response) => {
          if (typeof response != "undefined") {
            setBtnLoader(false)
            setImportModal(false);
            // setDetailData(response.data);
            // setImportTableModal(true);
            setTabloader(false);
            // getData();
          }
        });

    }else{
      Notification.error("Select file to upload")
    }

    // setSaveFile(values?.dragger[0]?.originFileObj)
  };


  useEffect(()=>{
    localStorage.setItem('setAssociateIdInLog', '');
   setAssociateIdInLog();
   setUiSelectId();
  },[])


  return (
    <>

      <div style={{ width: width > 700 ? "100%" : "100vw", backgroundColor: "#FFFFFF", padding: "0.7rem 0 0.1rem 0", height: "100%", display: "flex", borderSizing: "border-box", flexDirection: "column" }}>
        <div className="r-jsb" style={{ padding: width > 700 ? "0 0.8rem" : "0 0.8rem" }}>
          <div style={{ color: "#162432", fontSize: width > 700 ? "1.5vw" : 25, fontWeight: 600 }}>
            Logs
          </div>
          {width > 700 && <div style={{ display: "flex", flexDirection: "row", gap: "0.8rem", alignItems: "center", marginTop: "-0.3rem" }}>
            <div>
              <p style={{ color: "#6BCB77", cursor: "pointer", font: "normal normal bold 13px/22px Open Sans", borderRadius: 5, padding: "0 0.4rem", border: "1px solid", display: "flex", flexDirection: "row", gap: "0.3rem", alignItems: "center" }}
                onClick={() => {
                  Api.get("/attendance-attendance/downloadLogTemplate")
                    .send(res => {
                      if (res) {
                        window.open(res, "_blank")?.focus();
                      }
                    })
                }}
              >
                <img style={{ width: "1rem", height: "1rem" }} src={Excel} />
                <span>Download Excel Template</span>
              </p>
            </div>
            <div className={classes.searchDiv}>
              {" "}
              <img
                src={searchIcon}
                style={{ width: "1rem", height: "1rem", opacity: "1" }}
                alt="searchIcon"
              />
              <Input allowClear={true} bordered={false} type="text" value={search}
                placeholder="Search" onChange={handleSearch}
              />
            </div>
            <div>
              <Button
                onclick={() => {
                  let params = {
                    ExEmployee: tabs === "Inactive Users" ? true : false, page: search === "" ? currentPage : "",
                    search: search, limit: pageSize, departmentId: filteredDeptValue, managerId: filteredManagerValue,
                    employeeIdSort: sortingEmployee, recalculate: true
                  }
                  if (!startDate && !endDate) {
                    params = {
                      ...params, month: date === null ? monthNameToNumber[dates.split(",")[0]] : monthNameToNumber[date?.split(",")[0]],
                      year: date === null ? dates?.split(",")[1] : date?.split(",")[1],
                    }
                  }
                  else if (startDate && endDate) {
                    params = { ...params, startDate: startDate, endDate: endDate }
                  }
                  getLogsData(params,
                    (res) => {
                      setLogData(res);
                      setTableMetaData(res?.meta)
                    },
                    setLoader
                  );
                  // setSearch("");
                  // setFilteredDeptValue(null);
                }}
                borderRadius={5}
                name="Recalculate Data"
                fontSize={14}
                background={"#0086FF"}
                fontColor={"#FFFFFF"}
                height="5.2vh"
                disabled={!filteredDeptValue}
                width="12vw"
                borderColor="2px solid red"
                Prefix={() => (
                  <AiOutlineReload
                    style={{
                      border: "0px solid black",
                      height: "18px",
                      width: "18px",
                      color: "#FFFFFF",
                      marginLeft: 5,
                      marginRight: 5,
                    }}
                  />
                )}
              />
            </div>
            <div className="m-t-10" style={{ cursor: "pointer", color: "#0086FF" }}
              onClick={() => {
                showModal(true);
              }}
            >
              Download{" "}
              <span style={{ marginLeft: "0.2rem" }}>
                <img src={DownloadIcon} style={{ height: "1rem" }} />
              </span>
            </div>
          </div>}
        </div>
        {width < 700 && <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", marginTop: "0.7rem", padding: "0 0.8rem" }}>
          <div className={classes.searchDiv} style={{ width: "100%", height: "2.5rem", }}>

            <input allowClear={true} type="text" value={search} style={{ width: "100%", height: "100%", fontSize: 16, paddingLeft: "0.5rem" }}
              placeholder="Search" onChange={handleSearch}
            />
          </div>

        </div>}
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: width > 700 ? "0 0.8rem" : "0.4rem 0.8rem" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              // border:"2px solid",
              gap: "1.8rem",
              cursor: "pointer",
            }}
          >
            <div
              onClick={() => {
                setTabs("Active Users");
                // setSearch("")
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: tabs === "Active Users" ? "#0086FF" : "#A6A9B8", fontSize: width < 700 ? 18 : "1.2vw"
                }}
              >
                Active Users
              </div>
              {tabs === "Active Users" ? (
                <div
                  style={{
                    height: 1,
                    border: "2px solid #0086FF",
                    borderRadius: 5,
                  }}
                ></div>
              ) : (
                <div style={{ height: 1 }}></div>
              )}
            </div>
            <div
              onClick={() => {
                setTabs("Inactive Users");
                // setSearch("")
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: tabs === "Inactive Users" ? "#0086FF" : "#A6A9B8", fontSize: width < 700 ? 18 : "1.2vw"
                }}
              >
                Inactive Users
              </div>
              {tabs === "Inactive Users" ? (
                <div
                  style={{
                    height: 1,
                    border: "2px solid #0086FF",
                    borderRadius: 5,
                  }}
                ></div>
              ) : (
                <div style={{ height: 1 }}></div>
              )}
            </div>

          </div>
          {width > 700 ?
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                // width: "40%",
                // marginTop:"0.4rem",
              }}
            >
              <div
                style={{
                  display: "flex", flexDirection: "row", gap: "0.7rem",
                  justifyContent: "flex-end",
                  alignItems: "center"
                }}
              >
                {/*  <Button  style={{ width: "6rem", height: "2rem", fontWeight: 500, borderRadius: "5px", color: "#FFFFFF", backgroundColor: "#0086FF", }}
                  onClick={() => setImportModal(true)} 
                >
                    IMPORT
                </Button> */}
                <AntdButton style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "6rem", height: "2rem", fontWeight: 500, borderRadius: "5px", color: "#FFFFFF", backgroundColor: "#0086FF", }}
                  onClick={() => setImportModal(true)}
                  disabled={viewClass?.hrAssociate}
                >
                  IMPORT
                </AntdButton>
                <RangePicker allowClear={false}
                  value={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : null}
                  onChange={(date, dateString) => {
                    if (date) {
                      let datesAndYear = dateString[0].split('-');
                      const formattedDate = `${moment(datesAndYear[1]).format("MM").slice(0, 3)},${datesAndYear[0]}`;
                      // console.log("ssssDateValue",date,dateString,formattedDate)

                      setStartDate(dateString[0]);
                      setEndDate(dateString[1]);
                      setDate(formattedDate);
                      setMonthsVal(formattedDate);
                    } else {
                      setStartDate(null);
                      setEndDate(null);
                      setDate(`${moment().format("MM").slice(0, 3)},${moment().format("YYYY")}`);
                      setMonthsVal(`${moment().format("MM").slice(0, 3)},${moment().format("YYYY")}`);
                    }
                  }} style={{ height: "2rem" }}
                />

                <DatePicker allowClear={false}
                  disabled={(startDate && endDate) ? true : false}
                  value={date ? dayjs(date, "MM,YYYY") : null}
                  onChange={(date, dateString) => {

                    if (dateString) {
                      let datesAndYear = dateString.split('-');
                      const formattedDate = `${moment(datesAndYear[1]).format("MM").slice(0, 3)},${datesAndYear[0]}`;

                      setDate(formattedDate);
                      setMonthsVal(formattedDate);
                    } else {
                      setDate(null);
                      setMonthsVal(null);
                    }
                  }} picker="month" style={{ height: "2rem" }}
                />
                <Select placeholder="Manager" allowClear
                  getPopupContainer={(trigger) => trigger.parentElement}
                  className="m-t-5"
                  showSearch
                  value={filteredManagerValue}
                  style={{
                    width: "10vw", height: "2.3rem"
                  }}
                  onChange={(e) => {
                    setFilteredManagerValue(e);
                    setCurrentPage(1);
                  }}
                  disabled={false}
                  optionFilterProp="children"

                >
                  {filteredData?.department?.allManager?.map((option, index) => {
                    return (<>{option.name !== undefined &&
                      <Option key={index} value={option.id}>
                        {option.name}
                      </Option>}</>)
                  })}
                </Select>
                <Select placeholder="Department" allowClear
                  getPopupContainer={(trigger) => trigger.parentElement}
                  className="m-t-5"
                  showSearch
                  value={filteredDeptValue}
                  style={{
                    width: "10vw", height: "2.3rem"
                  }}
                  onChange={(e) => {
                    setFilteredDeptValue(e);
                    setCurrentPage(1);
                  }}
                  disabled={false}
                  optionFilterProp="children"

                >
                  {filteredData?.department?.result?.map((option, index) => {
                    return (<>{option.department !== undefined &&
                      <Option key={index} value={option.departmentId}>
                        {option.department}
                      </Option>}</>)
                  })}
                </Select>

              </div>
            </div>
            :
            <div style={{ display: "flex", flexDirection: "row", gap: "0.6rem" }}>
              <div className="m-t-10" style={{ cursor: "pointer" }}
                onClick={() => {
                  setFilterModal(true);
                }}>
                <img src={FilterIcon} style={{ height: "1.4rem" }} />
              </div>
              <div
                className="m-t-10"
                style={{ cursor: "pointer", color: "#0086FF" }}
                onClick={() => {
                  showModal(true);
                }}
              >
                <img src={DownloadIcon} style={{ height: "1.5rem" }} />
              </div>
            </div>
          }
        </div>

        {width > 700 ?
          <div style={{ backgroundColor: "#F9F9F9", width: "100%", marginTop: "0.5rem" }}>
            <div className={classes.ListContainer}>
              <div className={classes.ListHeader} style={{ fontSize: "0.9rem", color: "#16243280", fontWeight: 700 }}>
                <div className={classes.FixedColumn}>
                  <span style={{ paddingLeft: "0.4rem", minWidth: "9rem", display: "flex", flexDirection: "row", alignItems: "top" }}>
                    Employee Id {sortingEmployee === 'ASC' ? <img src={AscendingSortIcon} alt='' onClick={() => { setSortingEmployee('DESC'); setCurrentPage(1); }}
                      style={{ cursor: "pointer", marginLeft: "0.2rem" }} />
                      : <img src={DescendingSortIcon} alt='' onClick={() => { setSortingEmployee('ASC'); setCurrentPage(1); }} style={{ cursor: "pointer", marginLeft: "0.2rem" }} />}
                  </span>
                  <span style={{ minWidth: "15rem", paddingLeft: "0.4rem" }} >Associate Name</span>
                </div>
                {/* <span style={{minWidth:"12rem"}}> Contact Details</span> */}
                <span style={{ minWidth: "10rem" }}>Department</span>
                <span style={{ minWidth: "9rem" }}>Manager</span>
                <span style={{ minWidth: "9.5rem" }}>Working Hours</span>
                <span style={{ minWidth: "4rem" }}> WFO</span>
                <span style={{ minWidth: "4rem" }}> WFH</span>
                <span style={{ minWidth: "6rem" }}> W D</span>
                <span style={{ minWidth: "6rem" }}>P</span>
                <span style={{ minWidth: "6rem" }}>A</span>
                <div style={{ minWidth: "6rem" }} >H</div>
                <span style={{ minWidth: "6rem" }}>O H</span>
                <span style={{ minWidth: "6rem" }}>L</span>
                <span style={{ minWidth: "6rem" }}>AL</span>
                <span style={{ minWidth: "10rem" }}>Actions</span>
                {/* <span style={{ minWidth: "8rem" }}>Recalculate</span> */}
              </div>
              {/* <div className={classes.bodyScroll}>    */}
              {loader ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '71%', width: "100%" }}><Spin /></div> :
                logsTableData?.length === 0 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh', width: "100%" }}><div>No data found</div></div> :
                  logsTableData?.map((record, index) => {
                    console.log(record, "recclklll");
                    return (
                      <div ref={record['UserId'] === selectedRowId ? selectedRowRef : null}
                        className={`${classes.rowContainer} ${record['UserId'] === selectedRowId ? classes.selectedRow : ''
                          }`}
                        onScroll={(e) => setScrollPosition(e.target.scrollTop)}
                        style={{ color: "#162432", fontSize: "1vw", fontWeight: 400, fontStyle: "Lato", }}>
                        <div className={classes.FixedColumns}>
                          <div style={{ minWidth: "9rem", paddingLeft: "0.4rem" }} >{record['Employee Id']}</div>
                          <div style={{ minWidth: "15rem" }}>{record['Associate Name']}</div>
                        </div>
                        {/* <div style={{minWidth:"12rem"}}>{record['Contact Details']}</div> */}
                        <div style={{ minWidth: "10rem" }} >{record['Department']}</div>
                        <div style={{ minWidth: "9rem" }} >{record['Manager']}</div>
                        <div style={{ minWidth: "9.5rem" }} >{record['Working Hour']}</div>
                        <div style={{ minWidth: "4rem" }} >{record?.Element?.wfo}</div>
                        <div style={{ minWidth: "4rem" }} >{record?.Element?.wfh}</div>
                        <div style={{ minWidth: "6rem" }} >{record['W D']}</div>
                        <div style={{ minWidth: "6rem" }} >{record['P']}</div>
                        <div style={{ minWidth: "6rem" }} >{record['A']}</div>
                        <div style={{ minWidth: "6rem" }} >{record['H']}</div>
                        <div style={{ minWidth: "6rem" }} >{record['O H']}</div>
                        <div style={{ minWidth: "6rem" }} >{record['L']}</div>
                        <div style={{ minWidth: "6rem" }} >{record['AL']}</div>
                        <div style={{ minWidth: "10rem" }} >
                          {/* {record['Actions']} */}
                          <div className='r-jsb' style={{ display: "flex", flexDirection: "row", gap: "0.6rem" }}>
                            <Tooltip title={"Map"}>
                            <img src={LogMapIcon} 
                              onContextMenu={() => {
                                setSelectedUser(record["Element"])
                                localStorage.setItem("record", record["Element"])

                                setOpenGeofence(true)
                              }}
                              onClick={() => {
                                setSelectedUser(record["Element"])
                                localStorage.setItem("record", record["Element"])

                                setOpenGeofence(true)

                              }}
                              style={{ height: width > 700 ? "1.8rem" : "2.3rem", width: width > 700 ? "1.8rem" : "2.3rem", border: "0px solid black", color: "#39CA6C", cursor: "pointer" }}
                            /> 
                            </Tooltip>
                            <Tooltip title={"Recalculate"}>
                            <AiOutlineReload
                                onClick={() => {
                                  let params = {
                                    // ExEmployee: tabs === "Inactive Users" ? true : false,
                                    // search: search, departmentId: filteredDeptValue, managerId: filteredManagerValue,
                                    // employeeIdSort: sortingEmployee, 
                                    uid:record["employeeId"]
                                  }
                                  if (!startDate && !endDate) {
                                    params = {
                                      ...params, month: `${date === null ? dates?.split(",")[1] : date?.split(",")[1]}-${date === null ? monthNameToNumber[dates.split(",")[0]] : monthNameToNumber[date?.split(",")[0]]}`,
                                      // year: date === null ? dates?.split(",")[1] : date?.split(",")[1],
                                    }
                                  }
                                  else if (startDate && endDate) {
                                    params = { ...params, startDate: startDate, endDate: endDate }
                                  }
                                  getRecalculateApi(params,
                                    (res) => {
                                      // setLogData(res);
                                      // setTableMetaData(res?.meta)
                                      getData();
                                    },
                                    setLoader
                                  );
                                }} 
                                style={{ height: width > 700 ? "1.9rem" : "2.3rem", backgroundColor: "#FFE4CE", width: width > 700 ? "1.9rem" : "2.3rem",borderRadius:"50%",padding:"0.3rem", border: "0px solid black", color: "#FD9644", cursor: "pointer"}}
                              />
                              </Tooltip>
                              <Tooltip title={"Calendar"}>
                            <a href={`${window.location.href}/calendar/${record['UserId']}`} target="_self"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setAssociateIdInLog(record['UserId']);
                              }}>
                              <img src={LogCalenderIcon}
                                onClick={() => {
                                  console.log(record?.UserId,"kjdskjfkkd");
                                  localStorage.setItem('setAssociateIdInLog', record?.UserId);
                                  setAssociateIdInLog(record?.UserId)
                                  let dates = `${moment().format("MM").slice(0, 3)},${year}`
                                  setSelectedRowId(record['UserId'])
                                  setSelectedIndex(index)
                                  setShouldScroll(true);
                                  // if (!isContextMenuOpened) {
                                  setMonthsVal(date === null ? dates : date)
                                  setLoader(true)
                                  getLogsDetails((res) => {
                                    setLoader(false)
                                    setSelectAssociate("")
                                    setAttendanceLogsData(res)
                                  }, {
                                    userId: record['UserId'],
                                    month: date === null ? monthNameToNumber[dates.split(",")[0]] : monthNameToNumber[date.split(",")[0]],
                                    year: date === null ? dates?.split(",")[1] : date?.split(",")[1],
                                  })
                                  // set(state=>({render:2,selectedUser:record}));
                                  setRender(2)
                                  navigate(`/attendancePortal/attendance-logs/calendar/${record['UserId']}`);
                                  setSelectedUser(record["Element"])
                                localStorage.setItem("record", record["Element"])

                                  applyLeaveModalApi(
                                    {
                                      userId: record['UserId'],
                                    },
                                    (res) => {
                                      setApplyLeaveData(res);
                                    }
                                  );
                                }} style={{ height: width > 700 ? "1.8rem" : "2.3rem", backgroundColor: "white", width: width > 700 ? "1.8rem" : "2.3rem", borderRadius: "50%", border: "0px solid black", color: "#0086FF", cursor: "pointer" }}
                              />
                            </a>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    )
                  })
              }
              {/* </div> */}
            </div>
          </div>
          :
          <div className={classes.ListBodyContainer} style={{ height: "65vh", overflowY: 'scroll', overflowX: "scroll", width: "99%", backgroundColor: "#F9F9F9", padding: "0.4rem 1rem", }}>
            {loader ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '69vh', }}><Spin /></div> :
              logsTableData?.length === 0 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '69vh' }}><div>No data found</div></div> :
                logsTableData?.map((record, id, index) => {
                  return (
                    <div style={{ width: "100%", marginTop: "0.3rem", marginBottom: "1rem", borderRadius: 10, backgroundColor: "#FFFFFF", padding: "1rem 1rem" }}>

                      <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", }}>
                        <div style={{ display: "flex", flexDirection: "row", gap: "0.3rem" }}>
                          {record['Associate Name']}
                        </div>
                        <div style={{ height: "" }}>
                          {/* {record['Actions']} */}
                          <div className='r-jsb' style={{ display: "flex", flexDirection: "row", gap: "0.6rem" }}>
                            <img src={LogMapIcon}
                              onClick={() => {
                                setSelectedUser(record["Element"])
                                localStorage.setItem("record", record["Element"])
                                setOpenGeofence(true)
                              }}
                              style={{ height: width > 700 ? "1.8rem" : "2.3rem", width: width > 700 ? "1.8rem" : "2.3rem", border: "0px solid black", color: "#39CA6C", cursor: "pointer" }} />
                            <img src={LogCalenderIcon} onClick={() => {

                              setLoader(true)
                              getLogsDetails((res) => {
                                setLoader(false)
                                setSelectAssociate("")
                                setAttendanceLogsData(res)
                              }, {
                                userId: record['UserId'],
                                // month: monthNameToNumber[date?.split(",")[0]],
                                // year: date?.split(",")[1],
                                month: date === null ? monthNameToNumber[dates.split(",")[0]] : monthNameToNumber[date.split(",")[0]],
                                year: date === null ? dates?.split(",")[1] : date?.split(",")[1],
                              })
                              // set(state=>({render:2,selectedUser:record}));
                              setRender(2)
                              setSelectedUser(record["Element"])
                              localStorage.setItem("record", record["Element"])

                              applyLeaveModalApi(
                                {
                                  userId: record['UserId'],
                                },
                                (res) => {
                                  setApplyLeaveData(res);
                                }
                              );
                            }} style={{ height: width > 700 ? "1.8rem" : "2.3rem", backgroundColor: "white", width: width > 700 ? "1.8rem" : "2.3rem", borderRadius: "50%", border: "0px solid black", color: "#0086FF", cursor: "pointer" }} />
                          </div>
                        </div>
                      </div>

                      <div style={{ fontSize: "16px", display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0.5rem", marginLeft: "0.5rem" }}>
                        <p style={{ fontSize: "19px", color: "#16243280", marginTop: "0.2rem", }}>Email: </p>
                        {record['Email']}
                      </div>

                      <div style={{ fontSize: "14px", display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0.1rem", marginLeft: "0.5rem" }}>
                        <p style={{ fontSize: "19px", color: "#16243280", marginTop: "0.2rem", }}>Phone: </p>
                        {record['Phone']}
                      </div>

                      <div style={{ fontSize: "19px", display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0.1rem", marginLeft: "0.5rem" }}>
                        <p style={{ fontSize: "19px", color: "#16243280", marginTop: "0.2rem", }}>Department: </p>
                        {record['Department']}
                      </div>

                      <div style={{ fontSize: "19px", display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0.1rem", marginLeft: "0.5rem" }}>
                        <p style={{ fontSize: "19px", color: "#16243280", marginTop: "0.2rem", }}>Manager: </p>
                        {record['Manager']}
                      </div>

                      <div style={{ fontSize: "19px", display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0.1rem", marginLeft: "0.5rem", alignItems: "center" }}>
                        <p style={{ fontSize: "19px", color: "#16243280", marginTop: "0.2rem", }}>Working Hours: </p>
                        {record['Working Hour']}
                      </div>
                      <div style={{ fontSize: "19px", display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0.1rem", marginLeft: "0.5rem", alignItems: "center" }}>
                        <p style={{ fontSize: "19px", color: "#16243280", marginTop: "0.2rem", }}>WFH: </p>
                        {record?.Element?.wfh}
                      </div>
                      <div style={{ fontSize: "19px", display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0.1rem", marginLeft: "0.5rem", alignItems: "center" }}>
                        <p style={{ fontSize: "19px", color: "#16243280", marginTop: "0.2rem", }}>WFO: </p>
                        {record?.Element?.wfo}
                      </div>
                    </div>
                  )
                })}
          </div>
        }
        <Pagination position="bottomRight" size="small" style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem", fontSize: width < 700 && 15, }}
          showSizeChanger={true}
          pageSizeOptions={[10, 20, 30, 50]}
          total={tableMetaData?.totalItems}
          pageSize={pageSize}
          current={currentPage}
          showTotal={search === "" ? (total, range) => `${range[0]}-${range[1]} of ${total} items` : null}
          onChange={handlePageChange}
          onShowSizeChange={handlePageChange}
        />
      </div>
      {importModal &&
        <Modal open={importModal} closable={true} maskClosable={true}
          onCancel={() => {
            setImportModal(false);
          }}
          footer={false}
        >
          <h3
            style={{
              textAlign: "center",
              margin: "1rem",
              marginBottom: "2rem",
            }}
            level={3}
          >
            Import data
          </h3>
          <Form onFinish={onFinish}>
            <Form.Item
              name="dragger"
              valuePropName="fileList"
              getValueFromEvent={(event) => event.fileList}
              noStyle
            >
              <Upload.Dragger style={{ backgroundColor: "#EAF4FF", borderColor: "#1089FF", padding: '10px' }} 
                // showUploadList={{ showRemoveIcon: true }} 
                multiple={false} maxCount={1}
              >
                <div style={{ display: "flex", flexDirection: "row", gap: "1rem", justifyContent: "center", alignItems: "center" }}>
                  <div>
                    <p >
                      <img src={uploadIcon} style={{ height: "2.5rem", width: "2.5rem", }} />
                    </p>
                  </div>

                  <div style={{ textAlign: "center", display: "flex", flexDirection: "column", flexWrap: "nowrap", alignItems: "center" }}>
                    <p >
                      Click or drag file to this area to upload
                    </p>
                    <p >
                      Support for a single or bulk upload.
                    </p>
                  </div>
                </div>
              </Upload.Dragger>
            </Form.Item>
            <br /> <br />
            <Form.Item>
              <div style={{ display: "flex", flexDirection: "row", gap: "1rem", justifyContent: "center" }}>
              <div style={{cursor:"pointer", display: "flex", alignItems: "center", justifyContent: "center", width: "6rem", height: "2rem", fontWeight: 500, borderRadius: "5px", color: "#FFFFFF", backgroundColor: "#0086FF", }}
                  onClick={() => setImportModal(false)}
                >
                  Cancel
                </div>
                <AntdButton disabled={Tabloader} type="primary" htmlType="submit" style={{ width: "7rem" }} loading={false}>
                  UPLOAD
                </AntdButton>
              {/*   <Button style={{ width: "7rem" }} onClick={() => {
                  setImportModal(false);
                }}
                >
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" style={{ width: "7rem" }} loading={btnLoader}>
                  UPLOAD
                </Button> */}
              </div>
            </Form.Item>
          </Form>
        </Modal>}

      {open && (
        <Modal centered
          onCancel={handleCancel}
          title={
            <div className="r-c-c" style={{ color: "#0DAC45", fontSize: width < 700 && "21px" }}>
              <AiOutlineVerticalAlignBottom
                style={{
                  border: "0px solid black",
                  height: "20px",
                  width: "20px",
                  color: "#0DAC45",
                  marginRight: 5,
                }}
              />
              Download
            </div>
          }
          footer={
            <div className="r-c-c" style={{ margin: "1rem", display: "flex", flexDirection: "row", gap: "0.5rem" }}>
              <Button
                name="Cancel"
                background={"#FFFFFF"}
                onclick={handleCancel}
                fontSize={width < 700 ? 17 : 15}
                fontColor={"#0086FF"}
                height={width > 700 ? "2.1rem" : "2.4rem"}
                width="5.5rem"
                marginLeft="5px"
                borderRadius={8}
              />
              <Button
                name={value === 1 ? "Next" : "Download"}
                loader={btnLoader}
                background={"#0086FF"}
                onclick={() => {
                  if (value === 1) {
                    handleCancel();
                    applyFiltersModalHandler(true);
                  } else if (value === 3) {
                    let params = {
                      exEmployee: tabs === "Inactive Users" ? true : false,
                      search: search,
                      departmentId: filteredDeptValue,
                      managerId: filteredManagerValue,
                    }
                    if (!startDate && !endDate) {
                      params = {
                        ...params, month: date === undefined ? moment().format("MM") : monthNameToNumber[date?.split(",")[0]],
                        year: date === undefined ? moment().format("YYYY") : date?.split(",")[1],
                      }
                    }
                    else if (startDate && endDate) {
                      params = { ...params, startDate: startDate, endDate: endDate }
                    }
                    setBtnLoader(true);

                    Api.get(`/attendance-logs/detailReport`)
                      .params({
                        exEmployee: tabs === "Inactive Users" ? true : false,
                        search: search,
                        departmentId: filteredDeptValue,
                        managerId: filteredManagerValue,
                        month: date === undefined ? moment().format("MM") : monthNameToNumber[date?.split(",")[0]],
                        year: date === undefined ? moment().format("YYYY") : date?.split(",")[1],
                      })
                      .send((response) => {

                        window.open(response.file, "_blank")?.focus();
                        setBtnLoader(false);
                        handleCancel();
                      })
                  } else if (value === 2) {
                    setBtnLoader(true);
                    DownLoadAttendnaceLogs(
                      () => {
                        setBtnLoader(false);
                        handleCancel();
                      },
                      `/attendance-logs/summaryReport`,
                      {
                        exEmployee: tabs === "Inactive Users" ? true : false,
                        search: search,
                        departmentId: filteredDeptValue,
                        managerId: filteredManagerValue,
                        month: date === undefined ? moment().format("MM") : monthNameToNumber[date?.split(",")[0]],
                        year: date === undefined ? moment().format("YYYY") : date?.split(",")[1],
                      }
                    );
                    handleCancel();
                  }
                }}
                fontSize={width < 700 ? 17 : 15}
                fontColor={"#FFFFFF"}
                height={width > 700 ? "2.1rem" : "2.4rem"}
                width="6rem"
                borderRadius={8}
                style={{ marginRight: "0.5rem" }}
              />
            </div>
          }
          open={open} width={850}
        >
          <Divider />
          <div className="r-c-c" style={{ height: "18vh" }}>
            <Radio.Group onChange={onChange} value={value}>
              <Space direction="vertical" style={{ display: "flex", flexDirection: "column", gap: "0.5rem", }}>
                <Radio value={1} style={{ fontSize: width < 700 && "18px" }}>Custom download</Radio>
                <Radio value={2} style={{ fontSize: width < 700 && "18px" }}>Summary Report</Radio>
                <Radio value={3} style={{ fontSize: width < 700 && "18px" }}>Detail Report</Radio>
              </Space>
            </Radio.Group>
          </div>
        </Modal>
      )}
      {filterModal && (
        <Modal centered
          onCancel={() => { setFilterModal(false); setFilterModalLogs(false) }}
          title={<div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", fontSize: "21px" }}>
            Filters
          </div>
          }
          footer={
            <div className="r-c-c" style={{ margin: "1rem", marginTop: "1.4rem", display: "flex", flexDirection: "row", gap: "0.5rem" }}>
              <Button
                name="Cancel"
                background={"#FFFFFF"}
                onclick={() => { setFilterModal(false); setFilterModalLogs(false) }}
                fontSize={17}
                fontColor={"#0086FF"}
                height="2.4rem"
                width="5.5rem"
                marginLeft="5px"
                borderRadius={8}
              />
              <Button
                name="Apply"
                background={"#0086FF"}
                onclick={() => {
                  if (filterModalLogs === true) {
                    setFilterModal(false)
                    setFilterModalLogs(false)
                  } else {
                    // getLogsData(
                    //   { ExEmployee: tabs === "Inactive Users" ? true : false, page:search === ""?currentPage:"",search: search,
                    //   limit:search === ""?pageSize:"",departmentId:filteredDeptValue,managerId:filteredManagerValue },
                    //   (res) => {
                    //     setLogData(res);
                    //     setTableMetaData(res?.meta)
                    //   },
                    //   setLoader
                    // );  
                    setFilterModalLogs(false)
                    setFilterModal(false)
                  }
                }}
                fontSize={17}
                fontColor={"#FFFFFF"}
                height="2.4rem"
                width="5.5rem"
                borderRadius={8}
                style={{ marginRight: "0.5rem" }}
              />
            </div>
          }
          open={filterModal} width={750}
        >
          <Divider />
          {filterModalLogs ?
            <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "0.5rem" }}>
              <p style={{ fontSize: "18px", color: "#878787" }}>Select Associate</p>
              <Select
                placeholder="Select Associate"
                value={
                  selectAssociate
                    ? selectAssociate
                    : selectedUser?.name
                }
                onChange={(e, val) => {
                  setLoader(true);
                  setSelectAssociate(val?.children);
                  setUiSelectId(e)
                  getLogsDetails(
                    (res) => {
                      setAttendanceLogsData(res);
                    },
                    {
                      userId: e,

                      month: monthsVal === null ? monthNameToNumber[dates.split(",")[0]] : monthNameToNumber[monthsVal.split(",")[0]],
                      year: monthsVal === null ? dates?.split(",")[1] : monthsVal?.split(",")[1],
                    },
                    setLoader
                  );
                  applyLeaveModalApi(
                    {
                      userId: e,
                    },
                    (res) => {
                      setApplyLeaveData(res);
                    }
                  );
                }}
                style={{ height: "3rem", fontSize: "19px", }}
                showSearch
                size="large"
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                allowClear
              >
                {userCalendarDrop.map((user, index) => (
                  <>
                    <option key={user?.key} value={user.userId} style={{ fontSize: "19px" }}>
                      {user?.userName}
                    </option>
                  </>
                ))}
              </Select>
              <p style={{ fontSize: "18px", color: "#878787" }}>Select Month</p>


              <DatePicker allowClear={false}
                value={monthsVal ? dayjs(monthsVal, "MM,YYYY") : null}
                onChange={(date, dateString) => {
                  if (dateString) {
                    let datesAndYear = dateString.split('-');
                    setMonthsVal(`${moment(datesAndYear[1]).format("MM").slice(0, 3)},${datesAndYear[0]}`);

                    //  setSelectedMonth(datesAndYear[1]);
                    //  setSelectedYear(datesAndYear[0]);
                    getLogsDetails(
                      (res) => {
                        setAttendanceLogsData(res);
                      },
                      {
                        userId: uiSelectId ? uiSelectId : selectedUser?.userId,
                        month: datesAndYear[1],
                        year: datesAndYear[0],
                      },
                      setLoader
                    );
                  } else {
                    // setSelectedMonth(null);
                    // setSelectedYear(null);
                    setMonthsVal(null);
                    getLogsDetails(
                      (res) => {
                        setAttendanceLogsData(res);
                      },
                      {
                        userId: uiSelectId ? uiSelectId : selectedUser?.userId,
                        month: monthNameToNumber[dates.split(",")[0]],
                        year: dates?.split(",")[1],
                      },
                      setLoader
                    );
                  }
                }} picker="month" style={{ height: "2rem" }}
              />
            </div>
            :
            <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "0.5rem" }}>
              <p style={{ fontSize: "18px", color: "#878787" }}>Select Date</p>
              <RangePicker allowClear={false}
                // value={date ? dayjs(date, "MM,YYYY") : null} 
                onChange={(date, dateString) => {
                  if (date) {
                    let datesAndYear = dateString[0].split('-');
                    const formattedDate = `${moment(datesAndYear[1]).format("MM").slice(0, 3)},${datesAndYear[0]}`;
                    console.log("ssssDateValue", date, dateString, formattedDate)

                    setStartDate(dateString[0]);
                    setEndDate(dateString[1]);
                    setDate(formattedDate);
                    setMonthsVal(formattedDate);
                  } else {
                    setStartDate(null);
                    setEndDate(null);
                    setDate(`${moment().format("MM").slice(0, 3)},${moment().format("YYYY")}`);
                    setMonthsVal(`${moment().format("MM").slice(0, 3)},${moment().format("YYYY")}`);
                  }
                }} style={{ height: "2.5rem" }}
              />
              <p style={{ fontSize: "18px", color: "#878787" }}>Select Date</p>{console.log("sssss",date)}
              <DatePicker allowClear={false}
                disabled={(startDate && endDate) ? true : false}
                value={date ? dayjs(date, "MM,YYYY") : null}
                onChange={(date, dateString) => {
                  let datesAndYear = dateString.split('-');
                  setDate(`${moment(datesAndYear[1]).format("MM").slice(0, 3)},${datesAndYear[0]}`);
                  setMonthsVal(`${moment(datesAndYear[1]).format("MM").slice(0, 3)},${datesAndYear[0]}`);

                }} picker="month" style={{ height: "2.5rem" }}
              />
              <p style={{ fontSize: "18px", color: "#878787" }}>Select Manager</p>
              <Select placeholder="Manager" allowClear
                getPopupContainer={(trigger) => trigger.parentElement}
                showSearch
                value={filteredManagerValue}
                size="large"
                style={{
                  height: "2.5rem", fontSize: "18px"
                }}
                onChange={(e) => {
                  setFilteredManagerValue(e);
                  setCurrentPage(1);
                }}
                disabled={false}
                optionFilterProp="children"

              >
                {filteredData?.department?.allManager?.map((option, index) => {
                  return (<>{option.name !== undefined &&
                    <Option key={index} value={option.id} style={{ fontSize: "19px" }}>
                      {option.name}
                    </Option>}</>)
                })}
              </Select>
              <p style={{ fontSize: "18px", color: "#878787" }}>Select Department</p>
              <Select placeholder="Department" allowClear
                getPopupContainer={(trigger) => trigger.parentElement}
                showSearch
                value={filteredDeptValue}
                size="large"
                style={{
                  height: "2.5rem", fontSize: "18px"
                }}
                onChange={(e) => {
                  setFilteredDeptValue(e);
                  setCurrentPage(1);
                }}
                disabled={false}
                optionFilterProp="children"

              >
                {filteredData?.department?.result?.map((option, index) => {
                  return (<>{option.department !== undefined &&
                    <Option key={index} value={option.departmentId} style={{ fontSize: "19px" }}>
                      {option.department}
                    </Option>}</>)
                })}
              </Select>
            </div>
          }
        </Modal>
      )}
      <DownloadModal
        onDownload={onDownload}
        visible={isApplyFiltersModal}
        applyFiltersModalHandler={applyFiltersModalHandler}
        onCancel={() => {
          applyFiltersModalHandler(false);
        }}
        Tabs={tabs}
      />
      {/* <ApplyFiltersModalG onSave={onSave} reference={1} getFilterValues={getFilterValues} isApplyFiltersModal={isApplyFiltersModal} applyFiltersModalHandler={applyFiltersModalHandler} /> */}
      <UpdateAttendanceModal uiSelectId={uiSelectId}
        visible={updateModal}
        reload={() => {
          getLogsDetails(
            (res) => {
              setAttendanceLogsData(res);
            },
            {
              userId: uiSelectId ? uiSelectId : selectedUser?.userId,
              // month : monthNameToNumber[monthsVal.split(",")[0]],
              // year: monthsVal?.split(",")[1],
              month: monthsVal === null ? monthNameToNumber[dates.split(",")[0]] : monthNameToNumber[monthsVal.split(",")[0]],
              year: monthsVal === null ? dates?.split(",")[1] : monthsVal?.split(",")[1],
              download: undefined,
            },
            setLoader
          );
        }}
        onCancel={() => {
          setUpdateModal(false);
        }}
        Data={Data}
        setData={setData}
        selectDate={selectData}
      />
      <ManualPunchModal
        visible={manualPunchModal}
        reload={() => {
          getLogsDetails(
            (res) => {
              setAttendanceLogsData(res);
            },
            {
              userId: uiSelectId ? uiSelectId : selectedUser?.userId,
              // month : monthNameToNumber[monthsVal.split(",")[0]],
              // year: monthsVal?.split(",")[1],
              month: monthsVal === null ? monthNameToNumber[dates.split(",")[0]] : monthNameToNumber[monthsVal.split(",")[0]],
              year: monthsVal === null ? dates?.split(",")[1] : monthsVal?.split(",")[1],
              download: undefined,
            },
            setLoader
          );
        }}
        onCancel={() => {
          setManualPunchModal(false);
        }}
      />
      <GeoFence
        modalVisible={openGeofence} uiSelectId={uiSelectId} selectDate={selectData}
        dateMonthYear={date}
        closeModal={() => {
          setOpenGeofence(false);
          setMapCenter({ lat: 12.9002874, lng: 77.650783 })
        }}
      />
      <ApplyLeave uiSelectId={uiSelectId} selectDate={selectData} reload={() => {
        getLogsDetails(
          (res) => {
            setAttendanceLogsData(res);
          },
          {
            userId: uiSelectId ? uiSelectId : selectedUser?.userId,

            month: monthsVal === null ? monthNameToNumber[dates.split(",")[0]] : monthNameToNumber[monthsVal.split(",")[0]],
            year: monthsVal === null ? dates?.split(",")[1] : monthsVal?.split(",")[1],
            download: undefined,
          },
          setLoader
        );
      }} />
    </>
  );
};

export default AttendanceLogs;
