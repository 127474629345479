import ModalCompo from "../../../design/ModalCompo";
import DeleteIcon from "../../../assets/DeleteIcon.svg"; 
import { ModalPrimaryButton, ModalSecondaryButton } from "../../../components/Button/Button";
import { DeleteTaskApi, deleteProjectApi } from "./ProjectAction";
import Modal from "../../../components/Modal/Modal";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";

function DeleteModal({modal,setModal,refresh}){
    const {width}=useWindowDimensions();
   const DeleteHandler=()=>{
    if(modal?.type==="project"){
    let param={projectId:modal?.id}
    deleteProjectApi(param,(res)=>{
        if(res){
            setModal();
            refresh();
        }
   })
    }
    else if(modal?.type==="task"){
        let param={taskId:modal?.id}
        DeleteTaskApi(param,(res)=>{
            if(res){
                setModal();
                refresh();
            }
        })
    }
   }
    return(
        <>
        <Modal show={modal!==undefined} style={{top:'20vh',width:width<700?"90%":'45vw'}}
        closableRight={true}
        onCancel={()=>{
            setModal();
        }}
        header={<div style={{display:'flex',justifyContent:'center',gap:'0',alignItems:'center',width:"100%"}}>
        <img src={DeleteIcon} style={{height:'2rem',width:'2rem'}} />
        <div style={{color:'#F96363',fontSize:"1.2rem",fontWeight:"600"}}>Delete</div>
</div>}
        >
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'1.5rem',flexDirection:'column',margin:"0 2rem 2rem 2rem"}}>
            
            <div style={{color:'#162432',fontWeight:"600"}}>Are you sure to delete <span style={{color:'#0086FF'}}>"{modal?.name}"</span> {modal?.type} ?</div>
            <div style={{display:'flex',gap:'1.5rem',justifyContent:'center',marginTop:'1rem'}}>
            <ModalSecondaryButton  onCancel={()=>{setModal()}}>No</ModalSecondaryButton>
            <ModalPrimaryButton onSave={()=>{DeleteHandler();}}>Yes</ModalPrimaryButton>
            
            </div>
        </div>
        
        </Modal>
        </>
    )       
}
export default DeleteModal;