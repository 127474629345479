export const getHodDetailsApi = (hodDetailsHandler, Api) => {
   Api.get("/project-department/hodDropDown").send(res => {
      hodDetailsHandler(res);
   })
}
export const addDepartmentApi = (Api, headId, name, managementDept,departmentType,compOff) => {
   Api.post("/project-department/createDept").params({
      name: name,
      headId: headId,
      managementDept: managementDept,
      compOff:compOff,
      departmentType:departmentType

   }).send(res => {
   })
}
export const getDepartmentApi = (Tooltip,Actions,IoMdContact,departmentDataHandler, setLoader, limit, page, Api, search="") => {
   setLoader(true);
   let arr = [];
   Api.get(`/project-department/allDepartments?limit=${limit}&page=${page}&search=${search}`).send(res => {
      let temp = res;
      console.log(temp,"temoppp");
      temp?.allDepartments?.forEach(element => {
         let obj = {
            ...element,
            headc:(
               <Tooltip title={element?.head}>
                  <div className="r-ac">
                     {
                        element?.profilePicture?
                        <img style={{height:30, width:30, margin:10, borderRadius:30}} src={element?.profilePicture} alt="profilePicture"/>
                        :
                        <IoMdContact color="#EDEDED" fontSize={34} style={{ margin: 10 }} />

                     }
                     <div >
                        <div style={{width:"12rem", textOverflow:"ellipsis", overflow:"hidden"}}>{element?.head}</div>
                        <div style={{ marginTop: 8 }}>{element?.employeeId}</div>
                     </div>
                  </div>
               </Tooltip>
               ),
             contactDetails:(
               <Tooltip title={`${element?.email} | ${element?.phone}`}>
               <div>
                  {`${element?.email} | ${element?.phone}`}
               </div>
               
               </Tooltip>
             )  ,
             actions:(
               <div>
                  <Actions element={element}/>
               </div>
             )
          }
          arr.push(obj)
      })
      departmentDataHandler({...res,["allDepartments"]:[...arr]});
      setLoader(false);
      console.log(arr,"resarrm");
   })
}
export const editDepartmentApi = (Api, headId, name, editableDeptId, managementDept,departmentType,compOff) => {
   Api.patch(`/project-department/updateDept/${editableDeptId}`).params({
      name: name,
      headId: headId,
      managementDept: managementDept,
      compOff:compOff,
      departmentType:departmentType
   }).send(res => {
   })
}
export const deleteDeptApi = (Api, deletableDeptId) => {
   Api.delete(`/project-department/delete?departmentId=${deletableDeptId}`).send(res => {
   })
}