import React from "react";
import classes from './PreviewModal.module.css';
import Modal from "../../components/Modal/Modal";
import { ModalPrimaryButton } from "../../components/Button/Button";

const PreviewModal=({showPreview,setShowPreview,previewContent})=>{

    function removeHtmlTagsWithLineBreaks(input) {
        var div = document.createElement('div');
        div.innerHTML = input;
    
        // Insert line breaks after each closing tag
        for (const child of div.childNodes) {
            if (child.nodeType === Node.ELEMENT_NODE && child.tagName !== 'BR') {
                div.insertBefore(document.createElement('br'), child.nextSibling);
            }
        }
    
        return div.textContent || div.innerText || '';
    }
    return(
        <Modal show={showPreview}
        onCancel={()=>{
            setShowPreview(false);
        }}
        closableRight={true}
        header={<div style={{width:"100%",justifyContent:"left"}}>
              <h4>{previewContent?.name}</h4>
        </div>}
        >
            <div className={classes.preview}>
              
                <span>Subject</span>
                <div>
                    <span>{previewContent?.subject}</span>
                </div>
                <span>Description</span>
                <div>
                    <span>{removeHtmlTagsWithLineBreaks(previewContent?.body)}</span>
                </div>
                <div></div>
                <div>
                    <ModalPrimaryButton 
                    onSave={()=>{
                        setShowPreview(false)
                    }}>Close</ModalPrimaryButton>
                </div>


            </div>

        </Modal>
    )
}
export default PreviewModal;