import React, { useState, useEffect } from 'react';
import {  Input, Button, Select } from 'antd';
import '../../../design/framework.css'
import { CiLocationOn } from 'react-icons/ci';
import { addGeoLocation, allCity, updateGeoLocation } from './Action/action';
import { useNotificationStore } from './store';
const Option = Select.Option;

// const AutocompleteInput = ({ value, onChange }) => {
//     const [autocompleteService, setAutocompleteService] = useState(null);

//     useEffect(() => {
//         const script = document.createElement('script');
//         script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD00K_aHGQCG-OnncMViiRaKPNyZ-xOtE4&libraries=places`;
//         script.async = true;
//         script.onload = initAutocompleteService;
//         document.body.appendChild(script);

//         return () => {
//             document.body.removeChild(script);
//         };
//     }, []);

//     const initAutocompleteService = () => {
//         if (window.google && window.google.maps && !autocompleteService) {
//             const autocompleteService = new window.google.maps.places.AutocompleteService();
//             setAutocompleteService(autocompleteService);
//         }
//     };

//     const handlePlaceSelect = (placeId) => {
//         if (!placeId) return;
//         if (!autocompleteService) return;

//         const request = { placeId, fields: ['formatted_address'] };
//         autocompleteService.getDetails(request, (place, status) => {
//             if (status === 'OK' && place) {
//                 onChange(place.formatted_address);
//             }
//         });
//     };

//     const handleChange = (e) => {
//         const value = e.target.value;
//         onChange(value);

//         if (value && autocompleteService) {
//             const request = { input: value };
//             autocompleteService.getPlacePredictions(request, (predictions, status) => {
//                 if (status === 'OK' && predictions) {
//                     // You can handle the predictions here if needed
//                 }
//             });
//         }
//     };

//     return (
//         <Input
//             value={value}
//             onChange={handleChange}
//             onFocus={initAutocompleteService}
//             placeholder="Enter a location"
//         />
//     );
// };
const InputComponent = ({ width, borderRadius, borderColor, marginTop, value, onChange, numericOnly ,placeholder}) => {
    const handleKeyPress = (e) => {
        if (numericOnly) {
            const keyCode = e.keyCode || e.which;
            const keyValue = String.fromCharCode(keyCode);
            const numericRegex = /^[0-9]*$/;
            const dotRegex = /\./;
            const currentValue = e.target.value;

            if (!numericRegex.test(keyValue) && !(keyValue === '.' && !dotRegex.test(currentValue))) {
                e.preventDefault();
            }
        }
    };


    return (
        <div style={{ width: width, borderRadius: borderRadius, borderColor: borderColor, marginTop: marginTop }}>
            <Input value={value} onChange={onChange} onKeyPress={handleKeyPress} placeholder={placeholder} />
        </div>
    );
};





const TextComponent = ({ text, width, fontSize, backgroundColor, marginLeft, color, marginRight, fontWeight }) => {
    return (
        <div style={{ fontSize: '20px', width: width, fontSize: fontSize, marginLeft: marginLeft, color: color, marginRight: marginRight, fontWeight: fontWeight }}>
            {text}
        </div>
    );

};



function GeofenceModel({ setCenterModel,refreshApi, edit, setEdit, record, setRefresh, setDelete }) {
  const [loading, setloading] = useState(false);
  const { allCityStore, setAllCityStore} = useNotificationStore();

  const s = {
    name: '',
    location: '',
    latitude: '',
    longitude: '',
    radius: null,
    updatedAt: null,
    createdAt: null,
  };

  const [fieldData, setFieldData] = useState(edit ? { ...record } : { ...s });


  useEffect(() => {
    let activeLocations=[];
    setloading(true);
    allCity((res) => {
      setloading(true);
      activeLocations = res.filter(location => location.status === 'Active');
      setAllCityStore(activeLocations);
    });

    if (edit) {
      const cityName = record?.city?.cityName || '';
      setFieldData({ ...record, location: cityName });
    }
  }, []);

  const isDataFilled = () => {
    const { name, location, latitude, longitude, radius ,cityName} = fieldData;
  
    console.log("data",fieldData);
    if (edit) {
      // Check if any of the pre-filled fields are empty
      return name !== '' && (location !== ''||cityName!=='') && latitude !== '' && longitude !== '' && radius !== '';
    } else {
      // Check if all the fields are filled
      return name && location && latitude && longitude && radius;
    }
  };

  return (
    <>
      <div style={{ padding: 10 }}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div className='r-jc' style={{ display: 'flex', alignItems: 'start', justifyContent: 'start' }}>
                <CiLocationOn color='#0086FF' fontSize={25} />
                {edit ?
                 <TextComponent className='p-2' text="Edit Geofence" color={'#0086FF'} fontWeight={700} fontSize={'18px'} /> 
                 : 
                <TextComponent className='p-2' text="Add Geofence" color={'#0086FF'} fontWeight={700} fontSize={'18px'} />
                } 
            </div>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 20 }}>
            <TextComponent text="Location Name" fontWeight={500} />
            <InputComponent
              placeholder={'Enter Location Name'} 
              onChange={(e) => {
                setFieldData({ ...fieldData, name: e.target.value });
              }}
              borderRadius={10}
              borderColor={'#E6E6E6'}
              marginTop={10}
              value={fieldData?.name}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 20 }}>
            <TextComponent text="Location city (Please create the name of your city if it is not listed)" fontWeight={500} />
            <Select
              style={{ marginTop: '10px' }} showSearch
              filterOption={(input, option) =>
                option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
              }
              onSelect={(e) => {
                setFieldData({ ...fieldData, location: e });
              }}
              defaultValue={edit ? fieldData?.cityName : null}
              placeholder="Select City"
              size="large"
            >
              {allCityStore.map((item, index) => {
                return <Option key={index} value={item.id}>{item?.cityName}</Option>;
              })}
            </Select>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 20 }}>
            <TextComponent text="Latitude" fontWeight={500} />
            <InputComponent
              placeholder={'Enter Latitude'}
              value={fieldData?.latitude}
              onChange={(e) => {
                setFieldData({ ...fieldData, latitude: e.target.value });
              }}
              borderRadius={10}
              borderColor={'#E6E6E6'}
              marginTop={10}
              numericOnly={true}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 20 }}>
            <TextComponent text="Longitude" fontWeight={500} />
            <InputComponent
              placeholder={'Enter Longitude'}
              borderRadius={10}
              borderColor={'#E6E6E6'}
              marginTop={10}
              value={fieldData?.longitude}
              onChange={(e) => {
                setFieldData({ ...fieldData, longitude: e.target.value });
              }}
              numericOnly={true}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 20 }}>
            <TextComponent text="Radius (in meters)" fontWeight={500} />
            <InputComponent
              placeholder={'Enter Radius'}
              borderRadius={10}
              borderColor={'#E6E6E6'}
              marginTop={10}
              value={fieldData?.radius}
              onChange={(e) => {
                setFieldData({ ...fieldData, radius: e.target.value });
              }}
              numericOnly={true}
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginTop: 30 }}>
          <Button
            disabled={!isDataFilled()}
            onClick={() => {
              console.log('fieldData', fieldData);
              setRefresh(true);
              if (!edit) {
                let s = {
                  locationName: fieldData?.name,
                  latitude: fieldData?.latitude,
                  longitude: fieldData?.longitude,
                  cityId: fieldData?.location,
                  radius: parseInt(fieldData?.radius),
                };

                addGeoLocation(s, (res) => {
                
                    // allGeolocation({ limit: 100, pageNo: 1 }, (res) => {
                    //     setgeoLoactionStore(res)
                        refreshApi()
                        setRefresh(false);
                        setCenterModel(false);
                        // setDelete(false);
                      // })
                 
                    

                 
                });
              } else {
                let s = {
                  locationName: fieldData?.name,
                  latitude: fieldData?.latitude,
                  longitude: fieldData?.longitude,
                  cityId: fieldData?.location,
                  radius: fieldData?.radius,
                  id: fieldData?.id,
                };
                updateGeoLocation(s, () => {
                  // allGeolocation({ limit: 100, pageNo: 1 }, (res) => {
                    refreshApi()
                    setRefresh(false);
                    setCenterModel(false);
                    // setDelete(false);
                    // setgeoLoactionStore(res);
                  // });
                });
              }
            }}
            style={{
                background: isDataFilled() ? '#0086FF' : '#D3D3D3',
                color: 'white',
                borderRadius: 7,
                alignItems: 'center',
                paddingLeft: 16,
                paddingRight: 10,
                marginRight: 20,
                width:"6rem",height:"2.2rem",
              }}
          >
            <TextComponent text={'Save'} color={'white'} fontSize={15}></TextComponent>
          </Button>
          <Button
            onClick={() => {
              setCenterModel(false);
            }}
            style={{
              borderRadius: 7,
              alignItems: 'center',
              // margin: 10,
              width:"6rem",height:"2.2rem",
              borderWidth: 1,
              border: '1px solid #0086FF',
              paddingLeft: 5,
              paddingRight: 5,
            }}
          >
            <TextComponent text={'Cancel'} color={'#0086FF'} fontSize={15}></TextComponent>
          </Button>
        </div>
      </div>
    </>
  );
}

export default GeofenceModel;

