import ModalCompo from "../../../design/ModalCompo";
import DeleteIcon from "../../../assets/DeleteIcon.svg"; 
import { ModalPrimaryButton, ModalSecondaryButton } from "../../../components/Button/Button";
import { DeleteTasksApi, updateTasksApi,deleteProjectApi, TransferTasksApi,UpdateTasksApi, TaskAssociatesApi } from "./ProjectAction";
import Modal from "../../../components/Modal/Modal";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import { Space, Select, Button, message } from "antd";
import { useEffect, useState } from "react";
import { useFlagButtonStore } from "../Release/store";

function StatusModal({modal,setModal,refresh}){
    const {width}=useWindowDimensions();
    const [newStatus, setNewStatus] = useState();
    const [newAssociate, setNewAssociate] = useState();
    const [associateOptions, setAssociateOptions] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('');

    const statusActiveOptions=[
        {
            value: 'New',
            label: 'New',
        },
        {
            value: 'Ongoing',
            label: 'Ongoing',
        },
        {
            value: 'Over Due',
            label: 'Over Due',
        },
        
        ]

    const statusInactiveOptions = [{
        value: 'Completed',
        label: 'Completed',
    },
    {
        value: 'On Hold',
        label: 'On Hold',
    },
    {
        value: 'Suspended',
        label: 'Suspended',
    },
    {
        value: 'New',
        label: 'New',
    },]
    const statusOptions = [{
        value: 'New',
        label: 'New',
    },
    {
        value: 'Ongoing',
        label: 'Ongoing',
    },
    {
        value: 'Over Due',
        label: 'Over Due',
    },
    {
        value: 'Completed',
        label: 'Completed',
    },
    {
        value: 'On Hold',
        label: 'On Hold',
    },
    {
        value: 'Suspended',
        label: 'Suspended',
    }

    ]

    const {checkedUserIdsList, setCheckedUserIdsList, overallStatus} = useFlagButtonStore(state=>state)

    // const associateOptionsNew = associateOptions?.map(associate => ({
    //     value: associate?.name,
    //     label: associate?.name?.charAt(0).toUpperCase() + associate?.name?.slice(1),
    // }));

    const associateOptionsNew = associateOptions?.map(associate => ({
        value: associate?.id,
        label: associate?.name
    }));

    const onChange = (value)=>{
        if(modal?.type === "multipleTaskStatus"){
            setNewStatus(value)
        }
        if(modal?.type === "multipleTasksTransfer"){
            setNewAssociate(value)
        }
        if(modal?.type === 'singleTaskTransfer'){
            setNewAssociate(value)
        }
    }

    useEffect(()=>{
        if(modal?.type==="multipleTasksTransfer"){
            let param={userId: checkedUserIdsList?checkedUserIdsList[0]:''}
            //let param = {userId: modal?.userId}

                TaskAssociatesApi(param,(res)=>{
                    console.log(res,"QWERTY");
                    if(res){
                       setAssociateOptions(res);
                       
                    }
                })
        }
        else if(modal?.type==="singleTaskTransfer"){
            let param={userId: modal?.userId}

                TaskAssociatesApi(param,(res)=>{
                    console.log(res,"QWERTY");
                    if(res){
                       setAssociateOptions(res);
                       
                    }
                })
        }

    },[modal?.type]);

   const statusHandler=()=>{
    
        if(modal?.type==="multipleTaskStatus"){
            console.log(newStatus,"QWERTYCHECK");
                if(newStatus===undefined){
                    message.warning('Please select a new status')
                }
                else{
                    let param={taskIds:modal?.ids?.join(','), status: newStatus}

                    UpdateTasksApi(param,(res)=>{
                        if(res){
                            setModal();
                            refresh();

                        }
                        setNewStatus();
                    })
                }
               
            }
            else if(modal?.type==="multipleTasksTransfer"){
                if(newAssociate===undefined){
                    message.warning('Please Select an associate to transfer the task(s)')
                }
                else{
                
                    let param={taskIds:modal?.ids?.join(','), userId: newAssociate}

                    TransferTasksApi(param,(res)=>{
                        if(res){
                            setModal();
                            refresh();
                        }
                    })
                    setNewAssociate();
                }
            }
            else if(modal?.type ==='singleTaskTransfer'){
                if(newAssociate===undefined){
                    message.warning('Please Select an associate to transfer the task')
                }
                else{
                
                    let param={taskIds:modal?.ids?.join(','), userId: newAssociate}

                    TransferTasksApi(param,(res)=>{
                        if(res){
                            setModal();
                            refresh();
                        }
                    })
                    setNewAssociate();
                }
            }
   }

   const cancelHandler = ()=>{
    setModal();
    if(modal?.type==="multipleTaskStatus"){
        setNewStatus();
    }
    else if(modal?.type==="multipleTasksTransfer"){
        setNewAssociate();
    }
   }

    return(
        <>
        <Modal show={modal!==undefined} style={{top:'20vh',width:width<700?"90%":'45vw'}}
                closableRight={true}
                onCancel={()=>{
                    setModal();
                }}
                header={<div style={{display:'flex',justifyContent:'center',gap:'0',alignItems:'center',width:"100%"}}>
                            {/* <img src={DeleteIcon} style={{height:'2rem',width:'2rem'}} /> */}
                            {modal?.type==="multipleTaskStatus" ?
                            <div style={{color:'#161616',fontSize:"18px",fontWeight:"600"}}>Change Status ({modal?.ids?.length})</div>:
                            <div style={{color:'#161616',fontSize:"18px",fontWeight:"600"}}> Transfer  ({modal?.ids?.length})</div>
                            }
                        </div>}
        >
            {modal?.type==="multipleTaskStatus"?
            <p style={{ color:'#9B9B9B', marginLeft:'32px', marginBottom:'4px'}}>Status</p>:
            <p style={{ color:'#9B9B9B', marginLeft:'32px', marginBottom:'4px'}}>Search</p>}
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'1.5rem',flexDirection:'column',margin:"0 2rem 2rem 2rem"}}>
           
            {modal?.type==="multipleTaskStatus"?
                <Select

                        showSearch
                        placeholder="Select a Status"
                        optionFilterProp="label"
                        style={{width:'100%'}}
                        onChange={onChange}
                        value={newStatus}
                        // onSearch={onSearch}'All', "Active", 'New', 'Ongoing', "Over Due", "Completed", "On Hold", "Suspended", "Draft", "Rejected"
                        options={statusInactiveOptions}
                />:
        
                <Select
                    showSearch
                    placeholder="Search an Associate Name"
                    optionFilterProp="label"
                    style={{width:'100%'}}
                    onChange={onChange}
                    value={newAssociate}
                    options={associateOptionsNew??[]}
                />
            }
           
        </div>
        {modal?.type === "multipleTaskStatus"?
                
                <div style={{display:'flex', justifyContent:'flex-end',gap:'10px'}}>
                    <Button  style={{color:'#6A6A6A', borderRadius:'4px'}} onClick={()=>{setModal()}}>Cancel</Button>
                    <Button type="primary" style={{backgroundColor:'#FF8B2F', borderRadius:'4px'}} onClick={()=>{statusHandler()}}>Change Status</Button>
                </div>
            
            
          
        :
            <div style={{display:'flex',gap:'1.5rem',justifyContent:'flex-end',marginTop:'1rem'}}>
            
            <div>
                <Space>
                <Button  style={{color:'#6A6A6A'}} onClick={()=>{cancelHandler()}}>Cancel</Button>
                <Button type="primary" style={{backgroundColor:'#FF8B2F'}} onClick={()=>{statusHandler()}}> Transfer</Button>
                </Space>
            </div>
        
        
            </div>
        }
        
        </Modal>
        </>
    )       
}
export default StatusModal;