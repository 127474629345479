import React, { useEffect, useState } from "react";
import classes from "./Project.module.css";
import { useManageTasks, useStore } from "../Release/store";

import { Button, Select, Pagination } from "antd";

import ProjectCards from "./ProjectCards";
import { actiondis, ionsdis, progressdis } from "./ProjectTable";
import ProjectDetails from "./ProjectDetails";
import SearchComponent from "./SearchComponent";

import TaskTableRelease from "./TaskTableRelease";
import "../../../App.css";
import AddTask from "./AddTask";


import SearchCompo from "../../../design/SearchCompo";
import DeleteProjectModal from "./DeleteTasksModal";
import StatusTasksModal from "./statusTasksModal";
import LogEffort from "./LogEffort";

import { getAllProjectApi, getFilterApi, getAllProjectsCountApi } from "./ProjectAction";
import {getUserSearchApi} from "./ProjectAction"

import useProjectStore from "./store";
import transferIconWhite from "../../../assets/transferIconWhite.svg";
import "./comp.css";
import { SECRET_KEY, decryptData } from "../../../services/fileCode";
import useApiStore from "../../../services/store";
import viewClass from "../../../services/ViewRole";

import { useProjecteStore } from "../store/store";
import { updateHeader } from "../../Header/main-header";
import moment from "moment";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";


import { getAllTaskApi, getAllTeamMember , getAllUserTaskApi} from "./ProjectAction";
import { useParams } from "react-router";

import { useLocation } from "react-router-dom";
import { useFlagButtonStore } from "../Release/store";

const onResetFilter = (setMetadata, setAllProject, updatedData, setFilterOption, filterOption) => {
    console.log("its here");
    // 
    getAllProjectApi(setMetadata, {}, (res) => {
        console.log(res);
        setAllProject(res);
        updatedData(res)
        getFilterApi(false, {}, (res) => {
            setFilterOption({ ...filterOption, departments: res?.departments });
        })
    })
}

// export let onBackPress;
const ManageTasks = () => {
   


    // const {userReleaseId,
    //     userReleaseDepId,
    //     finalCount,
    //     setUserReleaseId,
    //     setUserReleaseDepId,
    //     setFinalCount,
    //     getUserReleaseId,
    //     getUserReleaseDepId,
    // } = useStore(state =>state);

    const location = useLocation();
    const depId = location.state || {}; // Use an empty object as a fallback

    
    const { setLoader } = useApiStore(state => state)
    const { projectClickedData, setProjectClickedData } = useProjecteStore(state => state)
    const viewRole = localStorage?.getItem('user');
    let role;
    if (viewRole) {
        role = decryptData(viewRole, SECRET_KEY);
    }
    console.log(role,"ROLECHECK");
    const [appliedFilter, setFilterOption, filterOption] = useProjectStore((state) => [state.appliedFilter, state.setFilterOption, state.filterOption])
    const [search, setSearch] = useState("");
    const [tableData, setdata] = useState([])
    const [filterModal, setFilterModal] = useState(false)
    const statusList = ['All', "Active", "In-active"]
    const [view, setView] = useState("list");
    const [status, setStatus] = useState("Active");
    //const [overallStatus, setOverallStatus] = useState("Active");
    const [allProject, setAllProject] = useState([
    ]);
    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(1);
    const [paramData, setParamsData] = useState({});
    const [projectModal, setProjectModal] = useState();
    const [deleteModal, setDeleteModal] = useState();
    const [updateModal, setUpdateModal] = useState();
    const [transferModal, setTransferModal] =useState();
    const [metaData, setMetadata] = useState();
    const { width } = useWindowDimensions();
    
    const [filterParams, setparams] = useState(undefined)
    const [ondownload, setdownload] = useState(false);
    //const [pageMeta, setPageMeta] = useState()
    //const [allTask, setAllTask] = useState();
    const [taskStatus, setTaskStatus] = useState("Active");
    const [taskModal, setTaskModal] = useState();
    const [deleteTask, setDeleteTask] = useState();
    const [transferTask, setTransferTask] = useState();
    const [logEffort, setLogEffort] = useState();
    const [filter, setFilter] = useState('All');
    //const { userId, departmentId } = useParams();
    //const [buttonFlag,setButtonFlag] = useState(false);
    const [deleteTaskModal,setDeleteTaskModal] = useState(false);
    //const [checkedIds,setCheckedIds] =useState([]);
    //const [filtersList, setFiltersList] = useState([]);
    //const [cancelFlag, setCancelFlag] = useState(false);
   

    const {flagButton,
        setFlagButton,
        cancelFlag,
        allTask,
        tasksUserId,
        allProjectNames,
        setAllProjectNames,
        filtersList,
        setFiltersList,
        checkedIdsList,
        setCheckedIdsList,
        setCancelFlag,
        setAllTask,
        setTasksUserId,
        allTeamNames,
        setAllTeamNames,
        overallStatus,
        setOverallStatus,
        tasksTeamId,
        setTasksTeamId,
        pageMeta,
        setPageMeta,
        setAllAssociateNames,
        allAssociateNames,
        allSearch,
        setProjectsCount,

    } = useFlagButtonStore(state=>state);

    const {manageTasks} = useManageTasks(state=>state);

    
    

    
    


    const getUserTask = () => {
        //console.log(filtersList?filtersList?.teamId: tasksTeamId? tasksTeamId:null, "CHECK321");
        console.log("CHECKINGTASK",filtersList);
        let param = {
            search: allSearch, page: pageMeta?.currentPage,
            overallStatus: overallStatus,
            limit: pageMeta?.itemsPerPage,
            userId: tasksUserId?tasksUserId:null,
            status: filtersList?filtersList?.status: null,
            //teamId: tasksTeamId? tasksTeamId.toString():filtersList?filtersList?.teamId: null,
            teamId: (filtersList && filtersList?.teamId)? filtersList?.teamId : tasksTeamId? tasksTeamId.toString(): null,
            projectId: filtersList?filtersList?.projectId:null,
            associateId: (filtersList && filtersList?.associateId)?filtersList?.associateId:null,
        }
       /*  if (taskStatus !== undefined && taskStatus !== "All") {
            param = { ...param, status: taskStatus }
        } */
        getAllUserTaskApi(param, (res) => {
            setAllTask(res?.data);

            setAllProjectNames(res?.projects);
            setAllTeamNames(res?.teams);

            setPageMeta(res?.meta)

            console.log(res?.meta?.totalItems,"CHECKRENDER","first");
            
        })
        getUserSearchApi((res)=>{
            console.log("ASSOCIATE",res);
            setAllAssociateNames(res);
        })
        
    }
    // useEffect(()=>{
    //     getUserTask();
    // },[])

    useEffect(()=>{
        let param ={}
      getAllProjectsCountApi(param,res=>{
        setProjectsCount(res?.meta?.totalItems);
      })
    },[])

 

    // const getAllTasks = ()=>{
    //     getAllTasksApi((res)=>{
    //         setAllTask(res?.data);
    //         setPageMeta(res?.meta)
    //     })
    // }
    


    

    // const updatedData = (res) => {
    //     let arrr = [];
    //     let temp = res;
    //     for (let index = 0; index < temp.length; index++) {
    //         console.log("helloooo");
    //         const element = temp[index];
    //         arrr.push({
    //             ...element,
    //             "Due Ond": (
    //                 <div>
    //                     {moment(element?.dueDate).format("DD-MMM-YYYY")}
    //                 </div>
    //             ),
    //             "statusd": (<>{ionsdis(element)}</>),
    //             "progressd": (<>{progressdis(element)}</>),
    //             "actiond": (<>{actiondis(element, index)}</>)
    //         })

    //     }
    //     /*    temp?.forEach((element,index) => {
    //            console.log(element,"shaata");
                   
    //            }); */
    //     console.log(arrr, "09876543234567890");
    //     setdata(arrr)



    // }
    const TaskEditHandler = (data) => {
        console.log("edit Clicked", data);
        setTaskModal({ type: 'edit', data: data });
    }
    const TaskDeleteHandler = (data) => {
        console.log("delete click", data);
        //setDeleteTask({ type: "task", id: data?.id, name: data?.name });
        //setDeleteModal({ type: "multipleTask", id: data?.id, name: data?.name });
        setDeleteModal({ type: "multipleTask", ids: [data?.id.toString()]});
    }
    const TaskTransferHandler = (data) => {
        console.log("transfer clicked", data);
        //setTransferTask(data);
        setTransferModal({type: "singleTaskTransfer", ids: [data?.id.toString()], userId: data?.userId})
        //setTransferModal({type: "multipleTasksTransfer", ids: [data?.id.toString()], userId: data?.userId})

    }
    const LogEffortHandler = (data) => {
        setLogEffort(data);
    }


    // const handleDeleteButton = ()=>{
    //     setDeleteModal({ type: "multipleTask", ids: checkedIds })
    // }
    // const handleStatusButton = ()=>{
    //     setUpdateModal({type: "multipleTaskStatus", ids: checkedIds})
    // }
    // const handletransferButton = ()=>{
    //     setTransferModal({type: "multipleTasksTransfer", ids: checkedIds, userId: userId})
    // }
    const handleCancelButton =() =>{
        
        //setCheckedIds([]);
        //setCancelFlag(!cancelFlag);
    }

    const handleCancel = () => {
        setDeleteTaskModal(false);
      };
    
      const handleOk = () => {
        // Add your delete logic here
        // console.log('Deleted items:', checkedList);
        setDeleteTaskModal(false);
    };


    const refreshProject = () => {
        // let param = { search: search, ...paramData, ...filterParams, overallStatus: overallStatus   }
        // let param = {
        //     search: search, page: pageCurrent,
        //     overallStatus: overallStatus,
        //     limit: pageSize,
        //     userId: tasksUserId?tasksUserId:null,
        // }
        // let param = { 
        //     search:allSearch,
        //     page: pageMeta?.currentPage ,
        //     overallStatus: overallStatus, 
        //     userId:tasksUserId ,
        //     limit: pageMeta?.itemsPerPage,
        //     status: filtersList?filtersList?.status: null,
        //     teamId: filtersList? filtersList?.teamId : tasksTeamId? tasksTeamId.toString(): null,
        //     projectId: filtersList?filtersList?.projectId:null,
        //     associateId: filtersList?filtersList?.associateId:null,
        //   }
          let param = {
            search: allSearch, page: pageCurrent,
            overallStatus: overallStatus,
            limit: pageSize,
            userId: tasksUserId?tasksUserId:null,
            status: filtersList?filtersList?.status: null,
            //teamId: tasksTeamId? tasksTeamId.toString():filtersList?filtersList?.teamId: null,
            teamId: (filtersList && filtersList?.teamId)? filtersList?.teamId : tasksTeamId? tasksTeamId.toString(): null,
            projectId: filtersList?filtersList?.projectId:null,
            associateId: (filtersList && filtersList?.associateId)?filtersList?.associateId:null,
        }
        getAllUserTaskApi(param, (res) => {
            setAllTask(res?.data);
            setPageMeta(res?.meta)
            console.log(res?.meta?.totalItems,"CHECKRENDER1");
            console.log("KVS")
        })
        //setCancelFlag(true);
    }
    // onBackPress = () => {
    //     // setSearch('');
    //     // getAllProjectApi(setMetadata, { ...filterParams,...paramData, status: status !== "All" ? status : undefined }, (res) => {
    //     //     setAllProject(res);
    //     //     updatedData(res);
    //     // })
    //     console.log(depId,userId,"ASDF");
    // }

    // useEffect(() => {
    //     setParamsData({
    //         page: pageCurrent,
    //         limit: pageSize
    //     })
    // }, [pageCurrent, pageSize])

    // useEffect(()=>{
    //     console.log(buttonFlag,"CCCC");
    // },[buttonFlag])

    // useEffect(()=>{
    //     setFinalCount(0);
    //     //setFinalCount(pageMeta?.totalItems);

    // },[pageMeta?.totalItems]);

    // console.log(paramData, "paramDataparamDataparamDataparamData",filterParams);
    // const DownloadHandler = () => {
    //     setdownload(true)
    //     let param = { search: search, download: true, ...paramData, ...appliedFilter ? appliedFilter : null, status: status }
    //     if (appliedFilter) {
    //         param = { ...param, ...appliedFilter }
    //     }
    //     if (status !== undefined && status !== "All") {
    //         param = { ...param, status: status }
    //     }
    //     getAllProjectApi(setMetadata, param, (res) => {
    //         console.log('Download', res)
    //         window.open(res, "_blank")?.focus();
    //     })

    // }
    // const onApplyfilter = (param) => {
    //     setparams(param)
    //     getAllProjectApi(setMetadata, { search: search,...param, status: status !== "All" ? status : undefined }, (res) => {
    //         setAllProject(res);
    //         updatedData(res)
    //         getFilterApi(false, { ...param, status: status !== "All" ? status : undefined }, (res) => {
    //             setFilterOption({ ...filterOption, departments: res?.departments });
    //         })
    //     })
    // }

    useEffect(()=>{
        console.log(overallStatus,pageMeta?.currentPage,pageMeta?.itemsPerPage, tasksUserId, filtersList, allSearch, manageTasks,"RENDERCHECK")
        if(manageTasks){
        getUserTask();
        console.log("MANAGETASKSCHECK")
        }
    },[ overallStatus, pageMeta?.currentPage, pageMeta?.itemsPerPage,manageTasks,allSearch,filtersList])//userId tasksUserId,was here


    // useEffect(() => {overallStatus,pageMeta?.currentPage,pageMeta?.itemsPerPage,  filtersList, allSearch, manageTasks
    //     viewClass.updateView();
    //     let param = { search: search, ...paramData, ...filterParams, ...appliedFilter ? appliedFilter : null }

    //     if (status !== undefined && status !== "All") {
    //         param = { ...param, status: status }
    //     }
    //     getFilterApi(false, param, (res) => {
    //         setFilterOption({ ...filterOption, departments: res?.departments });
    //     })
    //     getAllProjectApi(setMetadata, param, (res) => {
    //         console.log("inside action3");
    //         setAllProject(res);
    //         updatedData(res);
    //     })

    // }, [search, status, paramData])



    // useEffect(() => {
    //     return (() => {
    //         if (projectClickedData?.navigationLocation !== 'resourceDash') {
    //             setProjectClickedData(undefined);
    //             onResetFilter(setdownload, setMetadata, setAllProject, updatedData, setFilterOption, filterOption);
    //             //resetData();

    //         }

    //         // onResetFilter();
    //         // resetData();

    //     })
    // }, [])

    const handlePageApproveChange = (page, pageSize) => {
        setPageCurrent(page);
        setPageSize(pageSize)
        setPageMeta({currentPage: page, itemsPerPage: pageSize})
    }

    return (
        <div style={{ height: "86vh", overflowY: "auto", padding: 10, backgroundColor:'#F6F6F6' , marginTop:'-16px'}}>
                    <>
                        {
                            width < 700 ?
                                <>
                                    
                                       

                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: "100%" }}>
                                        {/* <SearchCompo style={{ width: "14rem",height:"2.5rem" }} setSearch={(val) => { setPageCurrent(1); setSearch(val) }}></SearchCompo> */}
                                        <SearchComponent></SearchComponent>
                                        {/* {(viewClass.superAdmin || viewClass.hod || viewClass.manager) ? <ButtonCompo title={"Add Project"} style={{ backgroundColor: '#0086FF', color: "white", borderRadius: 5, height:40, marginLeft:10, fontSize:7 }} prefixIcon={AddProjectIcon} onClick={() => { setProjectModal({ type: "add", data: '' }) }}></ButtonCompo> : ''} */}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection:'row',justifyContent: 'space-between', width: "100%", marginTop:15, marginBottom:10 }}>
                                        <p>Overall status</p>
                                        <Select allowClear style={{ width: "110px" }} size="large"  value={overallStatus} onChange={(val) => {console.log(val,val==undefined,"bcvallll"); setPageCurrent(1); setOverallStatus(val===undefined?"Active":val) }}>
                                            {statusList?.map((val) => {
                                                return (
                                                    <Select.Option key={val}>{val}</Select.Option>
                                                )
                                            })}
                                        </Select>
                                      
                                    </div>
                                </>
                                :
                                <>
                                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                       
                                      
                                            {/* <SearchCompo style={{ width: "16rem",height:"2.3rem",marginRight:"0.5rem" }} setSearch={(val) => { setPageCurrent(1); setSearch(val) }}></SearchCompo> */}
                                            <div>
                                            <SearchComponent></SearchComponent>
                                            </div>
                                            <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'8px'}}>
                                                <div >Overall status</div>
                                                <div>
                                                <Select  style={{ width: '200px' }}  allowClear size="large"  value={overallStatus} onChange={(val) => {console.log(val,val==undefined,"bcvallll"); setPageCurrent(1); setOverallStatus(val==undefined?"Active":val) }}>
                                                    {statusList?.map((val) => {
                                                        return (
                                                            <Select.Option key={val}>{val}</Select.Option>
                                                        )
                                                    })}
                                                </Select>
                                                </div>
                                            </div>
                                           
                                        
                                       
                                    </div>

                                   
                                </>
                        }
                        {/* {
                            width < 700 ?
                                <>
                                    <div >
                                        <ProjectCards data={allProject} onEdit={(res) => { console.log("edit clicked", res); setProjectModal({ type: "edit", data: res }) }} onDelete={(res) => { console.log("delete clicked", res); setDeleteModal({ type: "project", name: res?.projectName, id: res?.projectId }); }} onClick={(res) => { console.log(res); setProjectClickedData(res) }} />
                                    </div>
                                </>
                                :
                                <> */}
                                    <div className={classes.projectContainer} style={{marginTop:'10px',height:window.innerHeight-290}}>
                                        {/* {view === "grid" ? <ProjectCards data={allProject} onEdit={(res) => { console.log("edit clicked", res); setProjectModal({ type: "edit", data: res }) }} onDelete={(res) => { console.log("delete clicked", res); setDeleteModal({ type: "project", name: res?.projectName, id: res?.projectId }); }} onClick={(res) => { console.log(res); setProjectClickedData(res) }} /> : view === "list" ? */}
                                            {/* // <ProjectTable tableData={tableData} data={allProject} onEdit={(res) => { console.log(res); setProjectModal({ type: "edit", data: res }) }} onDelete={(res) => { console.log("delete clicked", res); setDeleteModal({ type: "project", name: res?.projectName, id: res?.projectId }); }} onClick={(res) => { console.log(res); setProjectClickedData(res) }} /> : ""} */}
                                            <TaskTableRelease onLogChange={(res) => { LogEffortHandler(res) }} onTransfer={(res) => { TaskTransferHandler(res) }} onDelete={(res) => { TaskDeleteHandler(res); }} onEdit={(res) => { TaskEditHandler(res); }} 
                                            data={allTask} 
                                            dataProject={allProjectNames}
                                            // setFlag={(flag)=>{setButtonFlag(flag)}} 
                                            // handleCheckedList={(checkedList)=>{setCheckedIds(checkedList)}} 
                                            // cancelFlag={cancelFlag} 
                                            // handleFiltersList={(filtersList)=>{setFiltersList(filtersList) }}
                                            //  setCancelFlag={setCancelFlag}
                                             /> 
                                            {/* //  : ""} */}
                                    </div>
                                {/* </>
                        } */}

                        <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", marginTop: "auto", paddingTop: 5 }}>
                            <Pagination
                                size={width < 700 ? "small" : "large"}
                                current={pageMeta?.currentPage??1}
                                pageSize={pageMeta?.itemsPerPage??10}
                                total={pageMeta?.totalItems}
                                pageSizeOptions={[10, 30, 50, 100]}
                                showSizeChanger={true}
                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                onChange={handlePageApproveChange}
                                onShowSizeChange={handlePageApproveChange}
                                style={{borderRadius:'2px'}}
                            />
                        </div>
                    </>
         
           
            {deleteModal !== undefined ? <DeleteProjectModal modal={deleteModal} setModal={setDeleteModal} refresh={refreshProject.bind(this)} /> : ''}
            {<StatusTasksModal modal={updateModal} setModal={setUpdateModal} refresh={refreshProject.bind(this)}/>}
            {<StatusTasksModal modal={transferModal} setModal={setTransferModal} refresh={refreshProject.bind(this)}/>}
            {taskModal !== undefined ? <AddTask modal={taskModal} setModal={setTaskModal} refresh={refreshProject.bind(this)} /> : ''}
            {logEffort !== undefined? <LogEffort role={role} modal={logEffort} setModal={setLogEffort} refresh={refreshProject.bind(this)}/>:''}

        </div>
    )
}
export default ManageTasks;