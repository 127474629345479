import { Select, Input } from "antd";
import moment from "moment";
import React from "react";
import DatePickerCompo from "../../../components/DatePicker/DatePickerCompo.tsx";
import ButtonCompo from "../../../design/ButonCompo.js";
import { useResourceStore } from "../store/store.js";
import "./Employees/Employee.css";
import { BsCheck2Circle } from "react-icons/bs";
import { useState } from "react";
import { useEffect } from "react";
import Notification from "../../../services/Notification.js";
import { KeyIsNotEmpty } from "./Employees/formData.js";
const LabelDiv =  {
    color: "#636363",
    marginBottom: "7px",
    fontWeight: "600",
    fontFamily: "roboto",
    fontSize: "12px"
}
const {Option} = Select

const onKeyDown = (e) => {
    e.preventDefault();
}


  
const ProfileInput = ({otpType=false,verify={}, label, type, disabled = false, required, value, onChange, options, placeholder, error=null, onFocusChange, disabledDate,  addmultilple=false, index}) => {
const {setOtpModal,otpVerified}=useResourceStore(state=>state)
const [verifyCheck,setVerify]=useState(verify?.status);
useEffect(()=>{
    setVerify(verify?.status)
},[verify?.status])
useEffect(()=>{
   if(verify?.show && verify.status){
    if(value===undefined || value===''){
        onChange({method:'verify',status:false});
        setVerify(false)
    }
}
},[value])
    return (
        <div style={{width: "100%"}}>
            <div>
                <div className="r-jsb">
                    {
                        label!==""?
                        <div style={{color: "#898989",font:'normal 500 16px/30px Poppins'}}>{label}{required && <span style={{ color: "red" }}> *</span>}</div>
                        :
                        <div style={{height:20}}></div>
                    }
                    {error && <div
                    style={{
                        color: "red",
                        marginLeft: "30%",
                    }}
                    >
                    {error}
                </div>}
                </div>
            </div>
            <div style={{display:'flex',width:'100%',alignItems:'center'}}>
            {type === "text" ||type === "number" ?
            <div style={{display:'flex',alignItems:'center',gap:'16px',width:'100%'}}>
                <Input
                    style={{
                        width: "100%",
                        marginBottom: "20px",
                        border: "none",
                        borderRadius: "10px",
                        height: "2.5rem",
                        border:"1px solid #dfdfdf",
                        marginTop:5,
                        // boxShadow:'0px 4px 4px  rgba(0,0,0,0.2)',
                       
                    }}
                    // placeholder={(value!=="")?value:placeholder}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => {
                        if(label==="Phone" || label==="Secondary Phone"){
                            if(e.target.value.length<=10){
                                onChange(e.target.value)
                            }
                        }else{
                            if(e.target.value.charAt(0) !== ' '){
                                if(addmultilple){
                                    onChange(e.target.value,addmultilple,index)
                                }else{
                                      onChange(e.target.value)
                                }
                        }
                       
                    }
                    
                }}
                    type={type}
                    onWheel={(e) => e.target.blur()}
                    disabled={disabled}
                    onBlur={onFocusChange ? (e) => onFocusChange(e) : () => {}}
                />

                </div> : type === "select" ?
                <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    placeholder={placeholder}
                    className="profileInputSelector"
                    defaultValue={null}
                    mode={(label==='Roles' || label==='Privileges')?'multiple':null} 
                    style={{ width: "100%",  marginBottom: 20, 
                            borderRadius: "10px",
                            border:"1px solid #dfdfdf",
                            // height:'3rem',
                            // boxShadow:'0px 4px 4px  rgba(0,0,0,0.2)',
                }}
                size="large"
                    value={value}
                    onChange={(e) => onChange(e)}
                    disabled={disabled}
                >
                    {options?.map((option, index) => <Option key={index} value={option.value ? option.value : option}>
                        {option.label ? option.label : option}
                    </Option>)}
                </Select> 
                : type === "date" ?
                    <DatePickerCompo
                        key={Math.random()}
                        allowClear={false}
                        className="profileDatepicker"
                        placeholder={placeholder}
                        value={KeyIsNotEmpty(value)?moment(value):undefined}
                        onChange={(e,dateString) =>{
                            if(e){
                            let t=new moment();
                            // console.log('e',dateString+'T'+t.toISOString().split('T')[1])
                            // console.log('joined',moment(dateString+'T'+t.toISOString().split('T')[1]))
                            // console.log('string',dateString)
                              onChange(moment(dateString+'T'+t.toISOString().split('T')[1]))
                            }
                            else{
                            onChange(undefined)
                            }
                              }}
                        style={{
                            marginBottom: 20, width: "100%", height: 30, 
                            border: "1px solid #dfdfdf",
                            borderRadius: "10px",
                            height: "2.5rem",
                            cursor:'pointer',
                            // boxShadow:'0px 4px 4px  rgba(0,0,0,0.2)',

                        }}
                        onKeyDown={(e) => onKeyDown(e)}
                        disabledDate={disabledDate}
                    />
                : null
            }
            {verify?.show && <div style={{fontSize: 14, marginBottom: 20,}}>
                        {
                            
                            <BsCheck2Circle onClick={()=>{
                                if(value!==undefined && value!==''){
                                    onChange({method:'verify',status:!verifyCheck});
                                    setVerify(!verifyCheck)
                                }
                                else{
                                    Notification.error('Error!','Please fill the details');
                                    return;
                                }
                              
                                  }} style={{  fontSize: 25, marginLeft: 5, color: verifyCheck ? "#0DAC45" : "#ABABAB" }} />
                        }
                        
                    </div>}
                    {otpType===true?<ButtonCompo disabled={otpVerified==='true'} title={otpVerified==='true'?'Verified':'Verify'} style={{color:otpVerified==='true'?'#0dac45':'#fff',backgroundColor:otpVerified==='true'?'#fff':'#0086ff',marginBottom:'16px',marginLeft: 10}} onClick={()=>{
                    if(otpVerified!=='true'){
                        setOtpModal({flag:true,place:'PrimaryPhone'});
                    }
                    }} />:'' }
            </div>
        </div>
    )
}
export {ProfileInput, LabelDiv}









