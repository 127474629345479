import {Pagination,Spin,Typography } from "antd";
import React, { useEffect, useState } from "react";

import classes from "./commonpage.module.css";
import userIcon from "../../../../assets/profileIcon.svg";
import { getTableData,getHodsData,getManagersData,getDepartmentData, getProjectByID } from "./action";
import { useGlobalStore } from "../../../../store";
import { useProjecteStore } from "../../../ProjectManagement/store/store";
import { useNavigate } from "react-router";
import { EyeOutlined, LeftOutlined } from "@ant-design/icons";
import ButtonCompo from "../../../../design/ButonCompo";
import ModalCompo from "../../../../design/ModalCompo";
import { KeyIsNotEmpty } from "../Employees/formData";
import SearchCompo from "../Employees/SearchCompo";
import { useWindowDimensions } from "../../../../components/windowDimention/windowDimension";

import ascIcon from "../../../../assets/AscendingSortIcon.svg";
import dscIcon from "../../../../assets/DescendingSortIcon.svg";
function HODdashboard() {
  const [paramsData, setParamsData] = useState({});
  const [sort,setSort]=useState('ASC');
  const tableHeight=window.innerHeight-240;
  const Navigate=useNavigate();
  const [status,setStatus]=useState("All");
  const [tableData, setTableData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [totalItems, setTotalItems] = useState(null);
  const [loader, setLoader] = useState(false);
  const [projectModal,SetProjectModal]=useState();
  const [deptModal,setDeptModal]=useState();
  const [teamModal,setTeamModal]=useState();
  const {setProjectClickedData}=useProjecteStore(state=>state)
  const { dashboardPage, changePage } = useGlobalStore((state) => ({
    dashboardPage: state.dashboardPage,
    changePage: state.changePage,
  }));
  const [projectNavLoader,setProjectNavLoader]=useState(false);
  const {width}=useWindowDimensions();
  

  const projectClickHandler=(projectId)=>{
    setProjectNavLoader(true);
    getProjectByID({projectId:projectId},(res)=>{
      setProjectClickedData({'navigationLocation':'resourceDash', ...res});
      Navigate('/project/projects');
      setProjectNavLoader(false);
    })
  }

  const handlePageChange=(page,pageSize)=>{
    console.log("SssPage",page,pageSize);
    setPageCurrent(page);
    setPageLimit(pageSize);
  }

  const handleSearch = (newSearch) => {
    setSearchValue(newSearch);
    setPageCurrent(1);
  };

  useEffect(() => {
      if (dashboardPage === "Head Of Departments") {
      setParamsData({
        search: searchValue,
        // limit: searchValue === "" ? pageLimit : "",
        // page: searchValue === "" ? pageCurrent : "",
      });
    }
    if (dashboardPage === "Managers") {
      setParamsData({
        search: searchValue,
        // limit: searchValue === "" ? pageLimit : "",
        // page: searchValue === "" ? pageCurrent : "",
      });
    } 
    if (dashboardPage === "Associates") {
      let pstatus=status!=="All"?status:'';
      setParamsData({
        role: "",
        department: "",
        search: searchValue,
        status: pstatus,
        limit: pageLimit,
        page: pageCurrent,
        tab:'Active',
        uidFilter:sort,
      });
    } 
    if(dashboardPage === "Departments") {
      setParamsData({
        search: searchValue,
        limit: pageLimit,
        page: pageCurrent,
      });
    }
  }, [searchValue,pageLimit,pageCurrent,status,sort]);
  

  useEffect(() => {
    if(dashboardPage === "Head Of Departments") {
      setLoader(true)
      getHodsData(paramsData, (res) => {
        setTableData(res);
        setLoader(false);
      });
    }
    if(dashboardPage === "Managers") {
      setLoader(true)
      getManagersData(paramsData, (res) => {
        setTableData(res);
        setLoader(false);
      });
    }
    if(dashboardPage === "Associates") {
    setLoader(true)
    getTableData(paramsData, (res) => {
      setTableData(res.data);
      setTotalItems(res?.meta?.totalItems);
      setLoader(false);
    });
    }
    if(dashboardPage === "Departments") {
      setLoader(true)
      getDepartmentData(paramsData, (res) => {
        setTableData(res?.allDepartments);
        setTotalItems(res?.meta?.totalItems);
        setLoader(false);
    });
    }
  }, [paramsData]);
  

  

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div className={classes.HeaderViewlist} >
      
      <div style={{display:'flex',alignItems:'center',gap:'6px'}}>
        <LeftOutlined  onClick={() => {
            changePage("mainPage");
          }}  style={{fontSize:'18px'}} />
        {dashboardPage === "Head Of Departments" ? (
          <h3 style={{ textAlign: "center" }}>Head of Departments</h3>
        ) : dashboardPage === "Managers" ? (
          <h3 style={{ textAlign: "center" }}>Managers</h3>
        ) : dashboardPage === "Associates" ? (
          <h3
            style={{
              textAlign: "center",
            }}
          >
            Associates
          </h3>
        ) : dashboardPage === "Departments" ? (
          <h3
            style={{
              textAlign: "center",
            }}
          >
            Departments
          </h3> )
          : (
          <></>
        )}
        </div>
        <div><SearchCompo style={{width:width>800?"20rem":"80vw",height:"2rem"}} value={searchValue} onSearch={handleSearch} setSearchValue={handleSearch} /> </div>
        </div>
  
      <div
        style={{
          width: "100%",
          background: "#F8F8F8",
          height:'85vh'
        }}
      >

<div className={classes.WebViewList} >
                <div className={classes.ListContainer}>
                    <div className={classes.ListHeader} style={{display:'flex',width:'100%'}}>
                    {dashboardPage === "Head Of Departments" && <>
                          <div style={{width:'27%',justifyContent:'flex-start'}}>HOD's Name</div>
                          <div style={{width:'18%'}}> Employee Id</div>
                          <div style={{width:'40%',paddingLeft:"10%"}}> Contact Details</div>
                          <div style={{width:'15%',}}>No. Of Department</div>
                          </>} 
                          {dashboardPage === "Managers" && <>
                                <div style={{width:'20%',justifyContent:'flex-start'}}>Manager Name</div>
                                <div style={{width:'15%'}}> Employee Id</div>
                                <div style={{width:'36%',paddingLeft:"10%"}}> Contact Details</div>
                                <div style={{width:'15%'}}>No. Of Project</div>
                                <div style={{width:'14%'}}>Department Details</div>
                          </>}

                   
                    {dashboardPage === "Associates" && <>
                          <div style={{width:'20%',justifyContent:'flex-start',}}>Associate Name</div>
                          <div style={{width:'15%',}}> Employee Id
                          {sort === 'ASC' ? <img src={ascIcon} alt='' onClick={()=>{setSort('DESC')}} 
                            style={{cursor:"pointer",marginLeft:"0.2rem"}} /> 
                            :<img src={dscIcon} alt='' onClick={()=>{setSort('ASC');}} style={{cursor:"pointer",marginLeft:"0.2rem"}}/>}
                          </div>
                          <div style={{width:'35%',paddingLeft:"10%"}}> Contact Details</div>
                          <div style={{width:'15%',}}>Department</div>
                          <div style={{width:'15%',}}>Team Details</div>
                    </>} 
                    {dashboardPage === "Departments" && <>
                    <div style={{width:'25%',}}>HOD's Name</div>
                    <div style={{width:'20%',justifyContent:'flex-start'}}>Department</div>
                    
                          <div style={{width:'20%'}}>Employee Id</div>
                          <div style={{width:'35%'}}>Contact Details</div>
                          
                    </>}  
                    </div>
                    <div className={classes.ListScroll}>
                    <div className={classes.ListBodyContainer}>
                     {loader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><Spin /></div>:
                     tableData?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'45vh'}}><div>No data found!</div></div>:
                      tableData?.map((record,id)=>{
                        return(
                          <div className={classes.rowContainer}>
                          {dashboardPage === "Head Of Departments" && <>
                          <div style={{width:'27%',justifyContent:'flex-start'}}>
                            <div style={{display:'flex',alignItems:'center',gap:'6px'}}>
                              <img src={record?.profileImage?record?.profileImage:userIcon} style={{height:'26px',width:'26px',borderRadius:'50%'}} />
                              <Typography.Text ellipsis={{tooltip:true}} style={{maxWidth:'15vw'}} className='header3Font' >{record?.name}</Typography.Text>
                            </div>
                          </div>
                          <div style={{width:'18%',justifyContent:'flex-start'}}>
                              <div className='header4Font'>{record?.employeeId}</div>
                          </div>
                          <div style={{width:'40%',}}>
                          <div style={{display:'flex',alignItems:'center',gap:'4px',font:'normal 400 16px/22px Lato',}}>
                          
                          <Typography.Text ellipsis={{tooltip:true}} style={{maxWidth:'15vw',font:'normal 400 16px/22px Lato',}} >
                            {record?.email}
                          </Typography.Text>
                            <span className='header3Font'>|</span>
                            <span className='header3Font'>{record?.phone}</span>
                         </div>
                          </div>
                          <div style={{width:'15%',justifyContent:'center'}}>
                            <div className='header3Font' style={{cursor:'pointer',color:'#0086ff'}} onClick={()=>{setDeptModal(record?.departments)}}>{record?.departmentCount}</div>
                          </div>
                    </>} 
                    {dashboardPage === "Managers" && <>
                          <div style={{width:'20%',justifyContent:'flex-start'}}>
                           <div style={{display:'flex',alignItems:'center',gap:'6px',flexDirection:'row',}}>
                              <img src={record?.profileImage?record?.profileImage:userIcon} style={{width:"26px",height:"26px",borderRadius:"50%"}}></img>
                              <Typography.Text ellipsis={{tooltip:true}} style={{maxWidth:'10vw'}} className='header3Font'>{record?.name}</Typography.Text>
                            </div>
                          </div>
                          <div style={{width:'15%'}}>
                          <div className='header4Font'>{record?.uid}</div>

                          </div>
                          <div style={{width:'36%'}}>
                          <div style={{display:'flex',alignItems:'center',gap:'6px'}}>
                            <Typography.Text ellipsis={{tooltip:true}} style={{maxWidth:'15vw'}} className='header3Font'>{record?.email}</Typography.Text>
                            <div>|</div>
                            <div className='header3Font'>{record?.phone}</div>
                        </div>
                          </div>
                          <div style={{width:'15%'}}>
                          <ButtonCompo title={'View'}  style={{color:'#0086ff'}} onClick={()=>{SetProjectModal(record?.projectDetails)}}/>
                          </div>
                          <div style={{width:'14%',justifyContent:'center'}}>

                          <EyeOutlined style={{cursor:'pointer',}} onClick={()=>{setTeamModal(record?.teamDetails)}} />
                          </div>
                        
                       
                    </>} 
                          {dashboardPage === "Associates" && <>
                                <div style={{width:'20%',justifyContent:'flex-start',}}>
                                <div style={{display:'flex',alignItems:'center',gap:'6px',flexDirection:'row',}}>
                                    <img src={record?.profileImage?record?.profileImage:userIcon} style={{width:"26px",height:"26px",borderRadius:"50%"}}></img>
                                    <Typography.Text ellipsis={{tooltip:true}} style={{maxWidth:'10vw'}} className='header3Font'>{record?.name}</Typography.Text>
                                    </div>
                                </div>
                                <div style={{width:'15%',}}>
                                <div className='header4Font'>{record?.uid}</div>
                                </div>
                                <div style={{width:'35%',justifyContent:'flex-start',}}>
                                  <div style={{display:'flex',alignItems:'center',gap:'4px',font:'normal 400 16px/22px Lato'}}>
                                    <Typography.Text ellipsis={{tooltip:true}} style={{maxWidth:'15vw',font:'normal 400 16px/22px Lato'}} >
                                      {record?.email}
                                    </Typography.Text>
                                      <span className='header3Font'>|</span>
                                      <span className='header3Font'>{record?.phone?record?.phone:"NA"}</span>
                                  </div>
                                </div>
                                <div style={{width:'15%'}}>
                                <Typography.Text ellipsis={{tooltip:true}} style={{maxWidth:'10vw'}} className='header3Font'>
                                    { (KeyIsNotEmpty(record?.department)&&record?.status!=="Waiting for Approval") ? record?.department : "NA"}
                                </Typography.Text>
                                </div>
                                <div style={{width:'15%',}}>
                                <div className='header3Font'>
                                    { KeyIsNotEmpty(record?.teamsAndProjectsDetails) ?
                                  <div style={{display:'flex',gap:'6px',alignItems:'center'}}>
                                    <Typography.Text ellipsis={{tooltip:true,symbol:'..'}} style={{maxWidth:'10vw',display:'flex',gap:'4px'}} >
                                      {record?.teamsAndProjectsDetails?.map((item,id)=>{
                                        return(<div style={{display:'flex',gap:'6px',alignItems:'center',padding:'5px 0px'}}>
                                   <Typography.Text ellipsis={{tooltip:true,symbol:'..'}} style={{maxWidth:'100px'}}>  {item?.teamName ? item?.teamName :"NA"}{ (record?.teamsAndProjectsDetails?.length>0 && id<(record?.teamsAndProjectsDetails?.length-1)) ?',':''} </Typography.Text>
                                    <EyeOutlined style={{cursor:'pointer'}} onClick={()=>{SetProjectModal(item?.projects)}} />
                                      </div>)
                                      })}
                                    </Typography.Text> 
                                    </div> : "NA"}
                                </div>
                                </div>
                          </>} 
                          {dashboardPage === "Departments" && <>
                         
                          <div style={{width:'25%'}}>
                            <div style={{display:'flex',alignItems:'center',gap:'6px',flexDirection:'row',}}>
                            <img src={record?.profileImage?record?.profileImage:userIcon} style={{width:"26px",height:"26px",borderRadius:"50%"}}></img>
                              <Typography.Text ellipsis={{tooltip:true}} style={{maxWidth:'15vw'}} className='header3Font'>{record?.head}</Typography.Text>
                              </div>
                          </div>
                          <div style={{width:'20%',justifyContent:'flex-start'}}>
                                <Typography.Text ellipsis={{tooltip:true}} style={{maxWidth:'20vw'}} className='header3Font'>{record?.name ? record?.name : "NA"}</Typography.Text>
                            </div>
                          <div style={{width:'20%'}}>
                            <div className='header4Font'>{record?.employeeId}</div>
                          </div>
                                <div style={{width:'35%'}}>
                                <div style={{display:'flex',alignItems:'center',gap:'4px',font:'normal 400 16px/22px Lato'}}>
                                    <Typography.Text ellipsis={{tooltip:true}} style={{maxWidth:'15vw',font:'normal 400 16px/22px Lato'}} >
                                      {record?.email}
                                    </Typography.Text>
                                      <span className='header3Font'>|</span>
                                      <span className='header3Font'>{record?.phone}</span>
                                  </div>
                                </div>
                              
                          </>}
                          </div>
                         
                        )
                      })}
                    </div>
                    </div>
                </div>
             {dashboardPage !== "Head Of Departments" && dashboardPage !== "Managers" &&   <div style={{display:'flex',justifyContent:'flex-end',width:'100%',padding:'0.5rem 2rem'}} >
                      <Pagination 
                      onChange={handlePageChange}
                      showSizeChanger={true}
                      pageSize={pageLimit}
                      current={pageCurrent}
                      total={totalItems}
                      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                      />
                </div>}
    </div>

<div className={classes.MobViewList}>
<div className={classes.ListScrollMob}>

              <div className={classes.ListContainerMob}>
              {loader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><Spin /></div>:
              tableData?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><div>No data found !</div></div>:
                     tableData?.map((record,id)=>{

                        return(
                          <div className={classes.MobListBody}>

                          {dashboardPage === "Head Of Departments" && <>
                          <div>
                            <div style={{display:'flex',alignItems:'center',gap:'12px'}}>
                              <img src={record?.profileImage?record?.profileImage:userIcon} style={{height:'50px',width:'50px',borderRadius:'50%'}} />
                             <div>
                              <Typography.Text ellipsis={{tooltip:true}} style={{maxWidth:'25vw'}} className='header3Font' >{record?.name}</Typography.Text>
                              <div>{record?.employeeId}</div>
                              </div>
                            </div>
                            <div className={classes.listLine}>
                              <div className="header4Font">Email Id: </div>
                              <Typography.Text className="header3Font" ellipsis={{tooltip:true}} style={{maxWidth:'200px',font:'normal 400 16px/22px Lato'}} >
                                {record?.email}
                              </Typography.Text>
                            </div>
                            <div className={classes.listLine}>
                              <div className="header4Font">Phone No.: </div>
                              <div className="header3Font"> {record?.phone}</div>
                            </div>
                            <div className={classes.listLine}>
                              <div className="header4Font">No. Of Department: </div>
                              <div className="header3Font" style={{cursor:'pointer',color:'#0086ff'}} onClick={()=>{setDeptModal(record?.departments)}}> {record?.departmentCount}</div>
                            </div>
                          </div>
                          </>} 

                          {dashboardPage === "Managers" && <>
                          <div>
                            <div style={{display:'flex',alignItems:'center',gap:'12px'}}>
                              <img src={record?.profileImage?record?.profileImage:userIcon} style={{height:'50px',width:'50px',borderRadius:'50%'}} />
                             <div>
                              <Typography.Text ellipsis={{tooltip:true}} style={{maxWidth:'25vw'}} className='header3Font' >{record?.name}</Typography.Text>
                              <div>{record?.uid}</div>
                              </div>
                            </div>
                            <div className={classes.listLine}>
                              <div className="header4Font">Email Id: </div>
                              <Typography.Text className="header3Font" ellipsis={{tooltip:true}} style={{maxWidth:'200px',font:'normal 400 16px/22px Lato'}} >
                                {record?.email}
                              </Typography.Text>
                            </div>
                            <div className={classes.listLine}>
                              <div className="header4Font">Phone No.: </div>
                              <div className="header3Font"> {record?.phone}</div>
                            </div>
                            <div className={classes.listLine}>
                              <div className="header4Font">Projects: </div>
                              <ButtonCompo title={'View'}  style={{color:'#0086ff'}} onClick={()=>{SetProjectModal(record?.projectDetails)}}/>
                            </div>
                            <div className={classes.listLine}>
                              <div className="header4Font">Department: </div>
                              <EyeOutlined  style={{cursor:'pointer',}} onClick={()=>{setTeamModal(record?.teamDetails)}}/>
                            </div>
                          
                          </div>
                          </>}
                          {dashboardPage === "Associates" && <>
                          <div>
                            <div style={{display:'flex',alignItems:'center',gap:'12px'}}>
                              <img src={record?.profileImage?record?.profileImage:userIcon} style={{height:'50px',width:'50px',borderRadius:'50%'}} />
                             <div>
                              <Typography.Text ellipsis={{tooltip:true}} style={{maxWidth:'250px'}} className='header3Font' >{record?.name}</Typography.Text>
                              <div>{record?.uid}</div>
                              </div>
                            </div>
                            <div className={classes.listLine}>
                              <div className="header4Font">Email Id: </div>
                              <Typography.Text className="header3Font" ellipsis={{tooltip:true}} style={{maxWidth:'200px',font:'normal 400 16px/22px Lato'}} >
                                {record?.email}
                              </Typography.Text>
                            </div>
                            <div className={classes.listLine}>
                              <div className="header4Font">Phone No.: </div>
                              <div className="header3Font"> {record?.phone}</div>
                            </div>
                            <div className={classes.listLine}>
                              <div className="header4Font">Department: </div>
                              <div className="header3Font">{KeyIsNotEmpty(record?.department)? record?.department : "NA"}</div>
                            </div>
                            <div className={classes.listLine}>
                              <div className="header4Font">Team Details: </div>
                              <div className="header3Font">
                              { KeyIsNotEmpty(record?.teamsAndProjectsDetails) ?
                                  <div style={{display:'flex',gap:'6px',alignItems:'center'}}>
                                    <Typography.Text ellipsis={{tooltip:true}} style={{maxWidth:'40vw'}}>
                                      {record?.teamsAndProjectsDetails?.map((item,id)=>{
                                        return(<div style={{display:'flex',gap:'6px',alignItems:'center',padding:'5px 0px'}}>
                                   <span>  {item?.teamName ? item?.teamName :"NA"}{ (record?.teamsAndProjectsDetails?.length>0 && id<(record?.teamsAndProjectsDetails?.length-1)) ?',':''} </span>
                                    <EyeOutlined style={{cursor:'pointer'}} onClick={()=>{SetProjectModal(item?.projects)}} />
                                      </div>)
                                      })}
                                    </Typography.Text> 
                                    </div> : "NA"}
                              </div>
                            </div>
                          </div>
                          </>} 
                          {dashboardPage === "Departments" && <>
                          <div>
                            <div style={{display:'flex',alignItems:'center',gap:'12px'}}>
                              <img src={record?.profileImage?record?.profileImage:userIcon} style={{height:'50px',width:'50px',borderRadius:'50%'}} />
                             <div>
                              <Typography.Text ellipsis={{tooltip:true}} style={{maxWidth:'250px'}} className='header3Font' >{record?.head}</Typography.Text>
                              <div>{record?.employeeId}</div>
                              </div>
                            </div>
                            <div className={classes.listLine}>
                              <div className="header4Font">Email Id: </div>
                              <Typography.Text className="header3Font" ellipsis={{tooltip:true}} style={{maxWidth:'200px',font:'normal 400 16px/22px Lato'}} >
                                {record?.email}
                              </Typography.Text>
                            </div>
                            <div className={classes.listLine}>
                              <div className="header4Font">Phone No.: </div>
                              <div className="header3Font"> {record?.phone}</div>
                            </div>
                            <div className={classes.listLine}>
                              <div className="header4Font">Department: </div>
                              <div className="header3Font">{record?.name ? record?.name : "NA"}</div>
                            </div>
                          </div>
                          </>} 

                        </div>)
                      })  } 
                    </div> 
</div>
             {dashboardPage !== "Head Of Departments" && dashboardPage !== "Managers" &&   <div style={{display:'flex',justifyContent:'flex-end',width:'100%',}} >
                      <Pagination 
                      size="small"
                      onChange={handlePageChange}
                      showSizeChanger={true}
                      pageSize={pageLimit}
                      current={pageCurrent}
                      total={totalItems}
                      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                      />
                </div> } 
              </div>





      <ModalCompo  open={projectModal!==undefined} onCancel={()=>{SetProjectModal()}} closableRight={true}
      className={classes.projectModal}
      >
      <div style={{width:'100%'}}>
        <div style={{font:'normal 700 22px/26px Lato',marginTop:'-2rem',borderBottom:'1px solid #16243280',height:'3rem'}}>Projects</div>
        {projectNavLoader?<Spin style={{display:"flex",width:"100%",height:"15vh",alignItems:"center",justifyContent:"center"}}></Spin>:<div>
          <div style={{width:'100%', maxHeight:'60vh',minHeight:'20vh', overflowY:'scroll',display:'flex',flexDirection:'column',margin:'10px 0px'}}>
            { projectModal?.length===0? <div style={{width:'100%',height:'20vh',display:'flex',justifyContent:'center',alignItems:'center'}}> <div>No data</div> </div>:
               projectModal?.map((val,id)=>{
              return(
                <div style={{color:'#0086ff',font:'normal 600 18px/24px Lato',paddingTop:'1rem',}} key={id} >
                 <span style={{cursor:'pointer',}} onClick={()=>{projectClickHandler(val?.projectId)}}>{val?.projectName}</span> 
                </div>
              )
            })}
          </div>
        </div>}
        
</div>
      </ModalCompo>
      <ModalCompo  open={deptModal!==undefined} onCancel={()=>{setDeptModal()}} closableRight={true}
      className={classes.projectModal}
      >
      <div style={{width:'100%'}}>
        <div style={{font:'normal 700 22px/26px Lato',marginTop:'-2rem',borderBottom:'1px solid #16243280',height:'3rem'}}>Departments</div>
        <div>
          <div style={{width:'100%', maxHeight:'60vh',minHeight:'20vh', overflowY:'scroll',display:'flex',flexDirection:'column',margin:'10px 0px'}}>
            { deptModal?.length===0? <div style={{width:'100%',height:'20vh',display:'flex',justifyContent:'center',alignItems:'center'}}> <div>No data</div> </div>:
               deptModal?.map((val,id)=>{
              return(
                <div style={{color:'#0086ff',font:'normal 600 18px/24px Lato',paddingTop:'1rem'}} key={id} >
                  {val?.name}
                </div>
              )
            })}
          </div>
        </div>
        
</div>
      </ModalCompo>
      <ModalCompo  open={teamModal!==undefined} onCancel={()=>{setTeamModal()}} closableRight={true}
      className={classes.projectModal}
      >
      <div style={{width:'100%'}}>
        <div style={{font:'normal 700 22px/26px Lato',marginTop:'-2rem',borderBottom:'1px solid #16243280',height:'3rem'}}>Department Details</div>
        <div style={{display:'flex',flexDirection:'column',gap:'10px',paddingTop:'16px'}}>
          <div style={{display:'flex',color:'#16243280'}}>
              <div style={{width:'30%',justifyContent:'flex-start',display:'flex'}}>Departments</div>
              <div style={{width:'70%',justifyContent:'center',display:'flex'}}>Teams</div>
          </div>
          <div style={{background:'#f8f8f8',padding:'10px',maxHeight:'55vh',overflowY:'scroll'}}>
          {teamModal?.map((item,id)=>{
            return(
              <div style={{display:'flex',background:'#fff',padding:'6px',boxShadow:'0px 4px 10px #1624321a',marginBottom:'10px'}}>
              <div style={{width:'30%',justifyContent:'flex-start',display:'flex',}}>{item?.deptName?item?.deptName:"NA"}</div>
              <div style={{width:'70%',justifyContent:'center',display:'flex'}}>
                <Typography.Paragraph ellipsis={{tooltip:false,symbol:'..more',expandable:true,rows:2}} style={{marginBlock:'0px'}}>
                  {item?.teams?.map((val,index)=>{
                    return(
                    <> <span style={{color:'#0086ff',padding:'0px 8px'}}>{val?.teamName?val?.teamName:"NA"}</span>
                      {(item?.teams?.length>1 && index<item?.teams?.length-1)?' ,':''}
                      </>
                    )
                  })}
                </Typography.Paragraph>
              </div>
              </div>
            )
          })}

          </div>
        </div>
        
</div>
      </ModalCompo>
      </div>
    </div>
  );
}

export default HODdashboard;
