import { useEffect, useState } from "react";
import classes from "./SearchCompo.module.css";

function SearchCompo({style,value,setSearch=()=>{},onSearch=()=>{},placeholder='Search',isDisabled=false}){

    const changeHandler=(event)=>{
        event.preventDefault();
        setQuery(event.target.value);
        // setTimeout(()=>{
        // setSearch(event.target.value);
        // },600)
        // setTimeout(()=>{
        //     onSearch(event.target.value);
        // },600)
        
        

    }
    const [query, setQuery] = useState(value || '');
    useEffect(()=>{
        const timeOutId = setTimeout(()=>{
            onSearch(query)
            setSearch(query)
        },1000)
        return ()=> clearTimeout(timeOutId)
    },[query])
    useEffect(() => {
        setQuery(value); // Update the internal state when the value prop changes
      }, [value]);
    
    return (
            <input disabled={isDisabled}  value={query} onChange={changeHandler} className={classes.inputSearch} style={{...style,height:style?.height?style?.height:"2rem"}} type="text" placeholder={placeholder}></input>
    )
}

export default SearchCompo;

/*

*/