import React, { useState } from "react";
import classes from './AllEvents.module.css';
import back_icon from '../../../assets/backIcon.svg';
import { Typography } from "antd";
import { useLoginStore } from "../store";
import EventModal from "./EventMdoal";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";


const AllEvents=({events,setPage})=>{
    const [eventModal,eventModalHandler]=useState(false);
    const { event,setEvent}=useLoginStore();
    const {width}=useWindowDimensions()
    return(
        <div className={classes.allevents}>
            <div className={classes.container}>
                <div onClick={()=>{
                    setPage({page:1,events:false,videos:false, newsletter:false})
                }}>
                    <img src={back_icon}/>
                    <span>Back</span>
                </div>
                <span>Events</span>
                <span>Experience a glimpse of our past events and immerse yourself in the essence of our vibrant culture.</span>
                <section className={classes.events}>
                    {events?.map((item,index)=>{
                        return(
                            <div className={classes.eachEvents} onClick={
                                ()=>{ 
                                    eventModalHandler(true);
                                    setEvent(item);
                                }}>
                                <div>
                                    <div>
                                        <img src={item.galleryImages[0]?.locationPath}/>
                                    </div>
                                    {width>700?  <div className={classes.imageGroup}>
                                        <div>
                                            <img src={item.galleryImages[1]?.locationPath}/>
                                        </div>
                                  <div>
                                            <div>
                                                <img src={item.galleryImages[2]?.locationPath}/>
                                            </div>
                                            <div>
                                                <img src={item.galleryImages[3]?.locationPath}/>
                                            </div>
                                        </div>
                                    </div>:null}
                                </div>
                                <span>{item.title}</span>
                                {/* <Typography.Paragraph ellipsis={{tooltip:false,rows:2,}}> */}
                                <span>{item.description.length>75?item.description.substring(0, 75)+"...":item.description} {item.description.length>90?<span style={{color:"#1089FF",marginLeft:"0.5vw"}}>View more</span>:""} </span>
                                {/* </Typography.Paragraph> */}
                                
                            </div>
                        )
                    })}

                </section>

            </div>

            {eventModal&&<EventModal event={event} eventModalHandler={eventModalHandler} eventModal={eventModal}/>}

        </div>
    )
}
export default AllEvents;