import React from "react";
import classes from './NewsletterModal.module.css';
import { Modal } from "antd";
import { useLoginStore } from "../store";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";

const NewsletterModal=()=>{
    const {width}=useWindowDimensions();
    
    const {newsletterModal,newsletterModalHandler,newsletter}=useLoginStore();
    console.log("newsletternewsletter",newsletter)
    return(
        <Modal open={newsletterModal}
        onCancel={()=>{newsletterModalHandler(false)}}
        centered
        footer={false}
         width={width>700?"60vw":"100vw"}
        >
            <div className={classes.container}>
                    <div>                       
                        <img src={newsletter?.image}/>
                    </div>
                    <div>
                         <span>{newsletter?.author}</span>
                         <span>{newsletter?.createdAt?.split("T0")?.[0]}</span>
                    </div>
                    <span>{newsletter?.title}</span>
                   
                    <span style={{fontWeight:"1.5vmin",fontWeight:"500"}}>{newsletter?.content}</span>
                   

            </div>

        </Modal>
        
    )
}
export default NewsletterModal;