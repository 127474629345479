import Api from '../../../services/Api'

export const getUserDetailsById = (callback,userId) => {
    const params = {};
    Api.get(`/resource-user/userProfile?userId=${userId}`)
        .params(params)
        .send((response) => {
            if (response) {
                if (callback)
                    callback(response)
            }
        });
}

export const editProfilePatch = (param,userId,callback) => {
    Api.patch(`/resource-user/editProfile?userId=${userId}`)
        .params(param)
        .send((response) => {
            if (response!==undefined && response?.show?.type==='success') {
               callback(true)
            }
            else{
                callback(false)
            }
           
        });
}

export const postUploadedDocuments = (params, callback) => {
    Api.post("/resource-user/uploadDocuments")
      .upload(params, (progressEvent, response) => {
        if (response) {
          callback(response.data);
        }
      })
     
  };
  
  export const publicEmployeeApi=(params,callback)=>{

    Api.get('/resource-public/newUserProfileEdit').params(params).send((res)=>{
        if(res!==undefined){
            callback(res);
        }
    })
  }

  export const generateOtp=(params,callback)=>{
    Api.patch(`/attendance-mobileauth/otp-send/${params}`).params(params).send((res)=>{

        if(res!==undefined){
            callback(res);
        }
    })
  }
  export const verifyOtp=(params,callback)=>{

    Api.patch(`/attendance-mobileauth/verify-otp/${params}`).send((res)=>{
        if(res!==undefined){
            callback(res);
        }
    })
  }
  export const revokeNoticePeriod=(params,callback)=>{
    Api.patch(`/resource-user/revokeNotice`).params(params).send((res)=>{
        if(res!==undefined){
            callback(res);
        }
    })
  }