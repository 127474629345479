import React from "react";
import AssociateImage from "../../../assets/AssociateImage.png";
import classes from "./style.module.css";
import useStore from "./store";
import profileIcon from "../../../assets/Profile Icon.svg";

const ProfileDetails=({data})=>{

    const { summaryData, } = useStore(state => state);


    return(
        <div className={classes.detailLeaves}>
            <div>
                <div>
                    <img src={summaryData?.profileImage !== null ?summaryData?.profileImage:profileIcon} 
                     alt="" />
                </div>
                <div>
                    
                    <h3>{summaryData?.user?.name ? summaryData?.user?.name :"NA"}</h3>
                    <p>{summaryData?.user?.email ? summaryData?.user?.email :"NA"}</p>
                    <p>{summaryData?.user?.uid ? summaryData?.user?.uid :"NA"}</p>
                    <p>{summaryData?.user?.departmentName ? summaryData?.user?.departmentName:"NA"}</p>
                    <p>{summaryData?.user?.teamName ? summaryData?.user?.teamName :"NA"}</p>
                    <p>{summaryData?.user?.designation ? summaryData?.user?.designation :"NA"}</p>
                </div>
            </div>
            
            <div style={{display:"flex",flexDirection:"column",height:"22vh",justifyContent:"space-between"}}>
                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                        <div>Total Comp off available : </div>
                        <div>{summaryData?.compOffData?.available}</div>
                    </div>
                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                        <div>Total Comp off used : </div>
                        <div>{summaryData?.compOffData?.used}</div>
                    </div>
                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                        <div>Total Comp off lapsed : </div>
                        <div>{summaryData?.compOffData?.lapsed}</div>
                    </div>
                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                        <div>Total Comp off converted : </div>
                        <div>{summaryData?.compOffData?.converted}</div>
                    </div>
            </div>
        </div>
    )
}
export default ProfileDetails;