
import { Checkbox, Spin } from "antd";
import { useState } from "react";
import moment from "moment";
import CircleProgressBar from "../../../components/CircleProgressBar/CircleProgressBar";
import classes from "./Project.module.css";
import "./comp.css";
import newStatus from "../../../assets/new.svg";
import ongoingStatus from "../../../assets/ongoing.svg";
import overdueStatus from "../../../assets/overdue.svg";
import completedStatus from "../../../assets/Group.svg";
import vectorStatus from "../../../assets/VectorStatus.svg";
import suspendedStatus from "../../../assets/suspended.svg";
import useApiStore from "../../../services/store";
import viewClass from "../../../services/ViewRole";
import { AiFillEdit } from "react-icons/ai";
import editIcon from '../../../assets/editicon.svg'
import deleteIcon from '../../../assets/deleteiconWB.svg'
import { NoData } from "../../Training_essentialDocuments";
import TableCustom from "../../../components/tableCustom/customTable";

export const icons = {
    'New': newStatus,
    'Ongoing': ongoingStatus,
    'Over Due': overdueStatus,
    "Completed": completedStatus,
    "On Hold": vectorStatus,
    "Suspended": suspendedStatus,
}

export const ionsdis = (item)=>{
    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                                        <div className={item?.status}>{item?.status}</div>
                                       
                                        <img src={icons[item.status]} style={{ height: '16px', width: '16px' }}></img>
                                    </div>
    )
}

export const progressdis = (item) =>{
    return(
        <div>
        <CircleProgressBar strokeWidth={5} sqSize={50} percentage={item?.progress ? item?.progress : 0} />
    </div>
    )
}

export let actiondis;

const tableColumn = {
    0: {
      cols: "Name",
      width: "45%",
      dataKey: "projectName",
      onclick: () => { }
    },
    1: {
      cols: "Manager",
      width: "30%",
      dataKey: "managerName",
      onclick: () => { }
    },
    2: {
      cols: "Team",
      width: "25%",
      dataKey: "teamName",
      onclick: () => { }
    },
    3: {
      cols: "Department",
      width: "25%",
      dataKey: "departmentName",
      onclick: () => { }
    },
    4: {
        cols: "Task",
        width: "25%",
        dataKey: "totalTask",
        onclick: () => { }
      },
      5: {
        cols: "Due On",
        width: "25%",
        dataKey: "Due Ond",
        onclick: () => { }
      },
      6: {
        cols: "Status",
        width: "25%",
        dataKey: "statusd",
        onclick: () => { }
      },
      7: {
        cols: "Progress",
        width: "25%",
        dataKey: "progressd",
        onclick: () => { }
      },
      8: {
        cols: "Action",
        width: "25%",
        dataKey: "actiond",
        onclick: () => { }
      },
      9: {
        cols: "Select",
        width: "25%",
        dataKey: "selectd",
        onclick: () => { }
      },

  }

function ProjectTable({ data, onClick, onEdit, onDelete ,tableData}) {
    const { loader } = useApiStore(state => state)
    
    const defaultData = data.slice(0,-1);
    console.log(defaultData,"CHECKER");

    const rowItemStyle = {
        display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'
    }
    console.log("table", data)
    const clickHandler = (data) => {

        onClick(data);
    }

    const EditHandler = (data) => {
        onEdit(data);
    }
    const DeleteHandler = (data) => {
        onDelete(data);
    }

    const CheckboxGroup = Checkbox.Group;
    const [checkedList, setCheckedList] = useState(defaultData);
    const indeterminate = checkedList.length > 0 && checkedList.length < data.length;
    const checkAll = data.length === checkedList.length;


    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? data : []);
    };

    const options = data?.map((item, id) => ({
        label: item?.projectName,  // You can use any property you like for the label
        value: item?.projectName,  // You can use any property you like for the value
      }));

     actiondis = (item, id) =>{
        return(
            <div>
            {
                (viewClass.superAdmin || viewClass.hod || viewClass.hrAdmin || viewClass.hrAssociate || viewClass.manager) &&
    
                <div style={{}}>
                    {
                        id === 0 ?
                            <div style={{ width: 30, marginBottom: 20 }}>Action</div>
                            : <div></div>
                    }
                    {
                      
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "fit-content", marginTop: 30 }} >
                        <div onClick={(e) => { e.stopPropagation(); EditHandler(item) }}>
                            <img src={editIcon} style={{ height: 25, width: 25 }} />
                        </div>
                        <div style={{marginLeft:10, marginRight:5}} onClick={(e) => { e.stopPropagation(); DeleteHandler(item); }}>
                            <img src={deleteIcon} style={{ height: 25, width: 25 }} />
                        </div>
                    
                    </div>
                    }
                </div>
            }
        </div>
        )
    }
    return (
        // <>
        //  <TableCustom columns={tableColumn} dataSource={tableData} loading={loader} />
        // </>
        <>
            {loader ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}><Spin /></div> : (data?.length === 0 || data === undefined) ? 
<NoData/>
:
        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center', cursor:"pointer"}}>
            
            <div style={{width:'100%',height:'5vh',display:'flex',justifyContent:'space-around',alignItems:'center',gap:'0.5rem',backgroundColor:'#fafafa'}}>
                <div style={{width:'100%',height:'100%', fontWeight:600,marginLeft:'1.2rem',display:'flex',alignItems:'center'}}><p style={{marginLeft:'0.5rem'}}>Name</p></div>
                <div style={{width:'100%',height:'100%',fontWeight:600 ,display:'flex',alignItems:'center',marginLeft:'0.2rem'}}>Manager</div>
                <div style={{width:'100%',height:'100%',fontWeight:600,marginRight:'0.5rem',display:'flex',alignItems:'center',marginLeft:'0.2rem'}}>Team</div>
                <div style={{width:'100%',height:'100%',fontWeight:600,display:'flex',alignItems:'center',marginRight:'0.5rem' }}>Department</div>
                <div style={{width:'100%',height:'100%',fontWeight:600,display:'flex',alignItems:'center',justifyContent:'center' }}><p style={{marginRight:'1.5rem'}}>Task</p></div>
                <div style={{width:'100%',height:'100%',fontWeight:600,display:'flex',alignItems:'center',justifyContent:'center'  }}><p style={{marginRight:'1.5rem'}}>Due On</p></div>
                <div style={{width:'100%',height:'100%',fontWeight:600,display:'flex',alignItems:'center',justifyContent:'center'  }}>Status</div>
                <div style={{width:'100%',height:'100%',fontWeight:600,display:'flex',alignItems:'center',justifyContent:'center'  }}>Progress</div>
                {(viewClass.superAdmin || viewClass.hod || viewClass.hrAdmin || viewClass.hrAssociate || viewClass.manager) && <div style={{width:'100%',height:'100%',fontWeight:600,marginRight:'1.5rem',display:'flex',alignItems:'center',justifyContent:'center'}}>Action</div>}

            </div>
            <div style={{ display: 'flex',width:'100%', flexDirection: 'column', backgroundColor: '#f9f9f9', height: '55vh', overflowY: 'auto' }}>
                {/* <div style={{width:'98.5%',border:'1px solid black',margin:'0.5rem'}}></div> */}
                
                {data?.map((item, id) => {
                    return (
                        <div onClick={() => { clickHandler(item); }} key={id} className={classes.ListConatiner} style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            
                          
                            <div style={{ cursor: 'pointer',...rowItemStyle }}>
                                {/* {
                                    id === 0 &&
                                        <div style={{ width: 100, marginBottom: 20 ,fontWeight:600}}>Name</div>
                                        
                                } */}
                                <div style={{ width: 100, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", fontSize: 14 }} title={item?.projectName}>
                                    {item?.projectName}
                                </div>
                                
                            </div>
                            <div style={rowItemStyle}>
                                {/* {
                                    id === 0 &&
                                        <div style={{ width: 100, marginBottom: 20,fontWeight:600 }}>Manager</div>
                                        
                                } */}
                                <div style={{ width: 100, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", fontSize: 14 }} title={item?.managerName}>
                                    {item?.managerName}
                                </div>
                            </div>
                            <div style={rowItemStyle}>
                                {/* {
                                    id === 0 &&
                                        <div style={{ width: 100, marginBottom: 20 ,fontWeight:600}}>Team</div>
                                        
                                } */}
                                <div style={{ width: 100, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", fontSize: 14 }} title={item?.teamName}>
                                    {item?.teamName}
                                </div>
                            </div>
                            <div style={rowItemStyle}>
                                {/* {
                                    id === 0 &&
                                        <div style={{ width: 100, marginBottom: 20,fontWeight:600 }}>Department</div>
                                        
                                } */}
                                <div style={{ width: 100, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", fontSize: 14 }} title={item?.departmentName}>
                                    {item?.departmentName}
                                </div>
                                
                            </div>
                            <div style={rowItemStyle}>
                                {/* {
                                    id === 0 &&
                                        <div style={{ width: 30, marginBottom: 20,fontWeight:600 }}>Task</div>
                                        
                                } */}
                                <div style={{ width: 30, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", fontSize: 14 }} title={item?.totalTask ? item?.totalTask : '0/0'}>
                                    {item?.totalTask ? item?.totalTask : '0/0'}
                                </div>
                            </div>
                            <div style={rowItemStyle}>
                                {/* {
                                    id === 0 &&
                                    <div style={{ width: 60, marginBottom: 20,fontWeight:600 }}>Due On</div>
                                } */}
                                <div style={{ color: '#FF3434' }}>{moment(item?.dueDate).format("DD-MMM-YYYY")}</div>
                            </div>
                            <div style={rowItemStyle}>
                                {/* {
                                    id === 0 &&
                                        <div style={{ width: 40, marginBottom: 20,fontWeight:600 }}>Status</div>
                                        
                                } */}
                                <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                                    <div className={item?.status}>{item?.status}</div>
                                    {
                                        item?.status==="Draft"?
                                        <>
                                        <AiFillEdit style={{ height: '16px', width: '16px', color:"grey" }}/>
                                        </>
                                        :
                                        <>
                                            <img src={icons[item.status]} style={{ height: '16px', width: '16px' }}></img>
                                        </>
                                    }
                                    
                                </div>
                            </div>
                            <div style={rowItemStyle}>
                                {/* {
                                    id === 0 &&
                                        <div style={{ marginBottom: 0,fontWeight:600 }}>Progress</div>
                                        
                                } */}
                                <div style={{}}>
                                    <CircleProgressBar strokeWidth={5} sqSize={40} percentage={item?.progress ? item?.progress : 0} />
                                </div>
                            </div>
                            {
                                (viewClass.superAdmin || viewClass.hod || viewClass.hrAdmin || viewClass.hrAssociate || viewClass.manager) && <div style={rowItemStyle}>
                                
                                    

                                <div style={{width:'100%',height:'100%',gap:'1rem',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                                    {/* {
                                        id === 0 && !(item?.isCoManager) &&
                                            <div style={{ width: '100%',textAlign:'center',marginBottom:'0.7rem',fontWeight:600}}>Action</div>
                                            
                                    } */}
                                    {
                                        !(item?.isCoManager)&&
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "fit-content"}} >
                                        <div onClick={(e) => { e.stopPropagation(); EditHandler(item) }}>
                                            <img src={editIcon} style={{ height: 25, width: 25 }} />
                                        </div>
                                        <div style={{marginLeft:10, marginRight:5}} onClick={(e) => { e.stopPropagation(); DeleteHandler(item); }}>
                                            <img src={deleteIcon} style={{ height: 25, width: 25 }} />
                                        </div>
                                    
                                    </div>

                                    }
                                </div> 
                            
                        </div>
                            }
                            
                        </div>)
                })}

            </div>
        </div>}
        </>

    )
}
export default ProjectTable;