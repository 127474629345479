import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  associate: {},
  workSummary: {},
  appraisal: {},
  feedback: {},
};

const associateSlice = createSlice({
  name: "associate",
  initialState,
  reducers: {
      
    updateAssociate(state, action) {
      state.associate = { ...action.payload.associate };
    },

    updateWorkSummary(state = initialState, action) {
      state.workSummary = { ...action.payload.workSummary };
    },

    updateAppraisal(state = initialState, action) {
      state.appraisal = { ...action.payload.appraisal };
    },
    updateFeedback(state = initialState, action) {
      state.feedback = { ...action.payload.feedback };
    },
    updateAssociateRank(state = initialState, action) {
      state.associateRank = { ...action.payload.associateRank };
    },
  },
});

export default associateSlice.reducer;

export const associateActions = associateSlice.actions;