import { create } from "zustand";

const conferenceStore = create((set) => ({
  localStreamData: {},
  updateLocalStreamData: (event) => set(state => ({ localStreamData: event })),
  meetIdG: "",
  updateMeetIdG: (event) => set(state => ({ meetIdG: event })),
  userG: {},
  updateUserG: (event) => set(state => ({ userG: event })),
  chatClient: null,
  setChatClient: (event) => set(state => ({ chatClient: event })),
  groupChat: [],
  updateGroupChat: (newMessages) => set((state) => {
    if (state?.groupChat?.some(element => element.token !== newMessages.data?.token)) {
      if (state?.userG?.id === newMessages.data?.userId) {
        let newObj = { ...newMessages?.data, sender: "user" }
        return ({ groupChat: [...state.groupChat, newObj] })
      } else {
        let newObj = { ...newMessages?.data, sender: "rec" };
        return ({ groupChat: [...state.groupChat, newObj] })
      }
    } else {
      let newObj = { ...newMessages?.data, sender: state?.userG?.id === newMessages.data?.userId ? "user" : "rec" }
      return ({ groupChat: [...state.groupChat, newObj] })
    }
  }),
  firstTimeMic: true,
  updateFirstTimeMic: (event) => set(state => ({ firstTimeMic: event })),
  totalMicStreams: null,
  updateTotalMicStream: (event) => set(state => ({ totalMicStream: event })),
  mssgSent: false,
  updateMssgsent: (event) => set(state => ({ mssgSent: event })),
  approveEvent: false,
  updatePanApprvModal: (event) => set(state => ({ approveEvent: event })),
  mediaReady: false,
  updateMediaReady: (event) => set(state => ({ mediaReady: event })),
  activeUsers: [],
  updateActiveUser: (event) => set(state => ({ activeUsers: event })),
  mssgToken: "",
  updateMssgToken: (event) => set(state => ({ mssgToken: event })),
  userList: [],
  updateUserListIn: (event) => set(state => ({ userList: event })),
  updateUserList: () => set(state => {
    const uniqueIds = {};
    const uniqueUsers = state.userList.filter((user) => {
      if (!uniqueIds[user.id]) {
        uniqueIds[user.id] = true;
        return true;
      }
      return false;
    });
    console.log(uniqueUsers, "uniqueUsersuniqueUsers");
    return { userList: uniqueUsers };
  }

  ),

  share: false,
  setshare: (event) => set(state => ({ share: event })),
  roundDetails: {},
  setroundDetails: (event) => set(state => ({ roundDetails: event })),
  meetView: {
    mainScrean: undefined,

  },
  joinedUser: {},
  storeJoinedUser: (event) => set(state => ({ joinedUser: event })),
  meetView: {
    mainScrean: undefined,

  },
  setMeetView: (event, type) => {
    if (type === "mainScrean") {
      return set(state => ({ ...state.meetView, mainScrean: event }))
    } else {
      return set(state => ({ ...state.meetView }))
    }

  },
  RequestedUser: {},
  updateRequestedUser: (event) => set(state => ({ RequestedUser: event })),
  isCameraOn: false,
  setIsCameraOn: (event) => set(state => ({ isCameraOn: event })),
  isMicOn: false,
  setIsMicOn: (event) => set(state => ({ isMicOn: event })),
  chatSocketConnected: false,
  setChatSocketConnected: (event) => set(state => ({ chatSocketConnected: event })),
  initialVideoStream: null,
  setInitialVideoStream: (event) => set(state => ({ initialVideoStream: event })),
  Remotedara: false,
  setRemotedara: (event) => set(state => ({ Remotedara: event })),
  mssgRead: false,
  updateMssgsentRed: (event) => set(state => ({ mssgRead: event })),
  activeUserList: [],
  updateActiveUserList: (event) => set(state => {
    console.log('====>> user ka aana jana userLeft',event,state.activeUserList)
 
    return({activeUserList:[...event]})
   }),
  activeMicList: {},
  updateActiveMicList: (event) => set(state => {
  

   return({activeMicList:event})
  }),
  userLeft:{},
  updateUserLeft:(event)=>set(state=>{
    
   let temp= state?.activeUserList;
   console.log('====>> user ka aana jana userLeft',event,state.activeUserList)
   temp = temp?.filter((item)=>item?.id!==event?.user?.id)
     return({activeUserList:[...temp]})
  })
}));

export default conferenceStore;