import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import Mailicons from "../assets/MailIcons.svg";
import ExclamationIcon from "../assets/ExclamationIcon.svg";
import Api from "../services/Api";

const VerifyEmailPage = () =>{
    const [pageValidaty,setPageValidity] = useState(null);
    const [loading,setLoading] = useState(false);
    const url = new URL(window.location.href);
    
    const token = url.searchParams.get("token");


    useEffect(()=>{
        setLoading(true);
        Api.post(`/auth-auth/verifyEmail?token=${token}`)
           .send((res)=>{
            setLoading(false);
            if(res?.show?.type === "success"){
                setPageValidity("success");
            }else{
                setPageValidity("error");
                
            }
           })
    },[]);


    return(
        <div style={{height:"100vh",width:"100%",display:"flex",flexDirection:"column",gap:"0.3rem",alignItems:"center",justifyContent:"center"}}>
         {loading ? 
            <Spin />
            :
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",textAlign:"center",width:"100%"}}>
                <img src={Mailicons} alt="Mail" />
                {pageValidaty === "success" ?<>
                <h2>Success.</h2>
                <span style={{fontSize:"1rem"}}>Email verified successfully.</span>
                </>
                :<div style={{width:"100%",display:"flex",gap:"0.5rem",justifyContent:"center",alignItems:"center",textAlign:"center"}}>
                <img src={ExclamationIcon} alt="Error" />
                <span style={{color:"#808080",fontSize:18,fontWeight:550}}>Action already taken, contact Technical Team.</span>
               </div>
                }
            </div>
         }
        </div>
    )
}
export default VerifyEmailPage;