import { Spin, Pagination } from "antd";
import React, { useEffect, useRef } from "react";
// import RowComponent from "./RowComponent";
import classes from "./associate_table.module.css";

import left_arrow from "../../../../assets/left_arrow.svg";
import right_arrow from "../../../../assets/right_arrow.svg";
import { forwardRef } from "react";
import NoDataImg from '../../../../assets/nodata.svg';
import { useWindowDimensions } from "../../../../components/windowDimention/windowDimension";
import { useSelector } from "react-redux";

export const NoData = (props) =>{
 
  const {width} =useWindowDimensions();
  return(
    <>
    <div style={{height:width<700?"20vh":"40vh",width:"100%", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:'column', marginTop:20,}}>
      <img src={NoDataImg}></img>
      <div>{props?.text?props?.text:"No Data"}</div>
    </div>
    </>
  )
}

const AssociateTable = ({
  loading,
  dataSource,
  columns,
  rowKey,
  pagination,
  scroll,
  style,
  meta,
  onChange,
}) => {
  const rowRef = useRef([]);
  const {
    page,
  pageSize,
  } = useSelector((state) => state.admin);
  
  
  const RowComponent = forwardRef(({ data, columnItem, columns }, ref) => {
    
    useEffect(()=>{
      console.log('columns data for table',ref)
      const observer = new IntersectionObserver((entries)=>{
        // const entry = entries[0]
      })
      // observer.observe(ref)
    },[])

    return (
      <div
        style={{
          width: "98.5%",
          // height: "13vh",
          margin: "0 0 1rem 0.5rem",
          display: "flex",
          borderRadius: "5px",
          // justifyContent: "space-around",
          boxShadow: "0 2px 10px 0 rgb(0, 0, 0, 0.1)",
          background: "#fff",
          // border:"2px solid red",
          padding:"1rem 0",
          alignItems:"center"
        }}
        className={classes.eachRow}
        ref={ref}
      >
        {columns.map((item) => {
          return (
            <div
              style={{
                // border:'1px solid black',
                width: "100%",
                height: "100%",
                display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
                // border:"2px solid red"
               
              }}
            >
              {item?.render("", data)}
            </div>
          );
        })}
      </div>
    );
  });

  // const Pagination = () => {
  //   return (
  //     <div
  //       style={{
  //         // border: "1px solid blue",
  //         width: "30vw",
  //         height: "100%",
  //         display: "flex",
  //         justifyContent: "space-between",
  //         alignItems: "center",
  //         marginRight: "1rem",
  //       }}
  //     >
  //       <div
  //         style={{
  //           width: "65%",
  //           // border: "1px solid black"
  //         }}
  //       >
  //         <h5>1-30 of 743 items</h5>
  //       </div>

  //       <div
  //         style={{
  //           display: "flex",
  //           width: "100%",
  //           // height: "100%",
  //           justifyContent: "space-between",
  //           alignItems: "center",
  //           // border: "1px solid black",
  //         }}
  //       >
  //         <button
  //           style={{
  //             border: "none",
  //             background: "none",
  //             width: "1rem",
  //             height: "1rem",
  //           }}
  //         >
  //           <img style={{ width: "100%", height: "100%" }} src={right_arrow} />
  //         </button>
  //         <div
  //           style={{
  //             width: "100%",
  //             border: "1px solid black",
  //             display: "flex",
  //             justifyContent: "space-between",
  //             alignItems: "center",
  //             margin:'0 0.5rem'
  //           }}
  //         >
  //           <button
  //             style={{
  //               border: "none",
  //               background: "none",
  //               width: "1rem",
  //               height: "1rem",
  //             }}
  //           >
  //             1
  //           </button>
  //           <button
  //             style={{
  //               border: "none",
  //               background: "none",
  //               width: "1rem",
  //               height: "1rem",
  //             }}
  //           >
  //             {pagination.totalPages}
  //           </button>
  //         </div>
  //         <button
  //           style={{
  //             border: "none",
  //             background: "none",
  //             width: "1rem",
  //             height: "1rem",
  //           }}
  //         >
  //           <img style={{ width: "100%", height: "100%" }} src={left_arrow} />
  //         </button>
  //       </div>

  //       <div
  //         style={{
  //           width: "50%",
  //           height: "2.5vh",
  //           // border: "1px solid black"
  //         }}
  //       >
  //         {/* Menu card */}
  //         <div></div>
  //         {/* //title */}
  //         <div></div>
  //         {/* //menu items */}
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div className={classes.table_container} style={style ? { ...style } : {}}>
      <div
        style={{
          // border: "1px solid black",
          height: "7vh",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          gap: "0.5rem",
          margin: "0 0.5rem",
        }}
      >
        {columns?.map((item) => (
          <div key={item?.key} className={classes.header_container}>
            {item?.title}
          </div>
        ))}
      </div>
      <div className={classes.row_parent_container}>
        {loading ? (
          <div className={classes.spiner_container}>
            <Spin />
          </div>
        ) : (
          dataSource&&dataSource?.length>0?
          <div className={classes.row_container}>
            {dataSource?.map((datasourceItem, index) => {
              return (
                <RowComponent
                  key={index}
                  data={datasourceItem}
                  columns={columns}
                  ref={(el) => {
                    if (el && !rowRef.current.includes(el)) {
                      rowRef.current.push(el);
                    }
                    // console.log('columns data for table',rowRef.current)
                  }}
                  // ref={(el) => {
                  //   if (el && rowRef.current.includes(el)) {
                  //     rowRef.current.push(el);
                  //   }
                  // }}
                />
              );
            })}
          </div>:
          <NoData/>
        )}
      </div>

      {!loading && meta && (dataSource.length !== 0) &&(
        <div className={classes.pagination_conatiner}>
          <div>
            {/* <Pagination /> */}
            <Pagination
              total={Number(meta?.totalItems)}
              current={Number(page)}
              pageSize={pageSize}
              showSizeChanger
              showQuickJumper={false}
              showTotal={(total) => `Total ${total} items`}
              onChange={onChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AssociateTable;
