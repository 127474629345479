import initListeners from "./listeners";
import BrigoshaMedia from "./media";
import { EventRegister } from 'react-native-event-listeners';
import { useNavigate } from "react-router-dom";
import moment from 'moment'

export const connectMedia = (user, roomId, navigate,updateActiveUserList,updateLocalStreamData,updateMediaReady) => {

  /*  const { activeClass } = getState().schedule;
   const { currentClassMode } = getState().liveClass; */
  let time = moment().format('HH:mm:ss A');
  const isTeacher = false;
  const platform = 'web';
  // user.roomId = roomName;
  // user.platform = platform;
  console.log("sdsdsds",user);
  const media = new BrigoshaMedia();
  if(user!=="undefined"){

    media.init(
      user?.id,
      roomId,
      user?.name,
      {
        ...user, org: {
          "_id": "625f9fae34522312ff9fb09f",
          "url": "test.aulas.in",
          "id": "4cb2509d-70f5-435e-8792-d24937743b53",//orgid
          "name": "TEST",
        },
        profileImage:user?.profileImage
        ,
        joined:time
        // streamId = ${orgId}xxxx${roomId}
      },
      isTeacher,
      platform,
      "conference",
    );
    initListeners({
      ...user,
      profileImage:user?.profileImage
        ,
      //  org: {
      //   "_id": "625f9fae34522312ff9fb09f",
      //   "url": "eks2.aulas.one",
      //   "id": "4cb2509d-70f5-435e-8792-d24937743b53",
      //   "name": "TEST",
      // },
      joined:time
    }, isTeacher, navigate,updateActiveUserList,updateLocalStreamData,updateMediaReady);
  }
 



  EventRegister.addEventListener('joinRcv', data => {
    // inform brigosha media that you are in class
    media.updateClassJoinStatus(true);

    if (data.url) {
      media.setBaseUrl("https://livestream.aulas.in/api");
    }
    // if user is already in handRaiseQ, enable audio/video

    //   dispatch(updateConnectionState(false));
  });

  EventRegister.addEventListener('settingupServerRcv', () => {
    // dispatch(updateStartLoaderText('Setting up your shiny new classroom...'));
  });

  EventRegister.addEventListener('serverInitialized', () => {
    media.initSockets(true);
  });
};