import { Device } from 'mediasoup-client';
import { EventRegister } from 'react-native-event-listeners';
const mediaType = {
    audio: 'audioType',
    video: 'videoType',
    screen: 'screenType'
}
const _EVENTS = {
    exitRoom: 'exitRoom',
    openRoom: 'openRoom',
    startVideo: 'startVideo',
    stopVideo: 'stopVideo',
    startAudio: 'startAudio',
    stopAudio: 'stopAudio',
    startScreen: 'startScreen',
    stopScreen: 'stopScreen'
}

class RoomClient {

    constructor({snackbar, localMediaEl, remoteVideoEl, remoteAudioEl, mediasoupClient, socket, user_id, room_id, user, successCallback, eventCallback, refer=false, setStreamCallback=null, fileName=null}) {
        this.user_id = user_id
        this.user = user
        this.snackbar = snackbar
        this.localMediaEl = localMediaEl
        this.remoteVideoEl = remoteVideoEl
        this.remoteAudioEl = remoteAudioEl
        this.mediasoupClient = mediasoupClient

        this.socket = socket
        this.producerTransport = null
        this.consumerTransport = null
        this.device = null
        this.room_id = room_id
        this.activeSpeaker = null
        this.offline = false
        this.eventCallback = eventCallback;
        this.refer = refer
        this.setStreamCallback = setStreamCallback
        this.audioStream = null
        this.videoStream = null

        this.consumers = new Map()
        this.producers = new Map()
        this.producerStats = new Map()
        this.consumerStats = new Map()
        this.producerLabel = new Map()
        this.allTracks = new Map()
        this.producerAudio=new Map()
        this.camerastream = null
        this.fileName = fileName

        this._useDataChannel = true
        this._boardDataProducer = null
        this._dataConsumers = new Map()
        this._nextDataChannelTestNumber = 0

        this._isOpen = false
        this.eventListeners = new Map()
        // not required for frontend e6
        Object.keys(_EVENTS).forEach(function (evt) {
            this.eventListeners.set(evt, [])
        }.bind(this))

        this.createRoom(room_id).then(async function () {
            await this.join(user_id, user, room_id)
            this.initSockets()
            this._isOpen = true
            if (successCallback){
                successCallback()
            }
        }.bind(this))

    }

    // async networkStats() {
    //     await new Promise(resolve => setTimeout(resolve, 2000)); 
    //     this.networkStats()
    // }

    ////////// INIT /////////
    stopcamera() {
        if (this.camerastream) {
          this.camerastream.getVideoTracks()[0].stop();
          this.camerastream = null;
          this.eventCallback("cameraOff")
        }
    }

    opencamera = async () => {
        let thiz = this;
        navigator.mediaDevices
          .getUserMedia({
            video: true,
            audio: false,
          })
          .then(function (cameraStream) {
            thiz.camerastream = cameraStream;
            thiz.eventCallback("cameraOn", cameraStream);

          })
          .catch((e) => {
            // thiz.callback({
            //   command: "error",
            //   error: "can not turn on the video",
            //   consoleError: e,
            // });
          });
      };

    async createRoom(room_id) {
     let status = await this.socket.request('createRoom', {
            room_id
        }).catch(err => {
            console.log(err)
        })
        if (status==='already exists'){
            this.eventCallback('roomAlreadyExists')
        }
        console.log("80", status)
    }

    async join(user_id, user, room_id, joinCallback) {

        this.socket.request('join', {
            user_id,
            user,
            room_id
        }).then(async function (roomstate) {
            console.log('joined successfully', roomstate)

            try
            {
                const data = await this.socket.request('getRouterRtpCapabilities');
                let device = await this.loadDevice(data)
                this.device = device
                await this.socket.request('resetClosedProducers')
                const teansportCreated = await this.initTransports(device)
                if(!teansportCreated){
                    console.log('Failed to join');
                    
                }
                // const getProducer = await this.socket.request('getProducers',{room_id, user_id})
                // getProducer.forEach(async(data)=>{
                //     await this.consume(data.producer_id, data.user_id)
                //    })
                // await this.socket.request('getProducers',{})
                // if(roomstate.state.video){
                //     this.produce(mediaType.video)
                // }
                // if(roomstate.state.audio){
                //     this.produce(mediaType.audio)
                // }
                if (joinCallback){
                    joinCallback();
                }
            }
            catch (error){
                console.log(error);
                
            }
        }.bind(this)).catch(e => {
            console.log(e)
        })
    }

    async loadDevice(routerRtpCapabilities) {
        let device
        try {
            device = new Device();
        } catch (error) {
            if (error.name === 'UnsupportedError') {
                console.error('browser not supported');
            }
            console.error(error)
        }
        await device.load({
            routerRtpCapabilities
        })
        return device

    }

    async initTransports(device) {

        // init producerTransport
        {
            const data = await this.socket.request('createWebRtcTransport', {
                forceTcp: false,
                rtpCapabilities: device.rtpCapabilities,
                sctpCapabilities: this._useDataChannel ?  device.sctpCapabilities :   undefined,
                producing: true,
				consuming: false,
            })
            if (data.error) {
                console.error('createWebRtcTransport failed', data.error);
                return false;
            }

            this.producerTransport = device.createSendTransport(data);

            this.producerTransport.on('connect', async function ({
                dtlsParameters
            }, callback, errback) {
                this.socket.request('connectTransport', {
                        dtlsParameters,
                        transport_id: data.id
                    })
                    .then(callback)
                    .catch(errback)
            }.bind(this));
            const thiz= this
            this.producerTransport.on('produce', async function ({
                kind,
                rtpParameters
            }, callback, errback) {
                try {
                    let params = {
                        producerTransportId: this.producerTransport.id,
                        kind,
                        rtpParameters,
                    }
                    if (this.fileName){
                        params.fileName = this.fileName
                    }
                    const {
                        producer_id,
                        kindRcv
                    } = await this.socket.request('produce', params);
                    callback({
                        id: producer_id
                    });
                    console.log("kind receive after audio producer created", kindRcv)
                    // kind
                    if(kindRcv==="audio"){
                        thiz.eventCallback('audioAdded')
                    }
                } catch (err) {
                    errback(err);
                }
            }.bind(this))

            this.producerTransport.on('connectionstatechange', function (state) {
                switch (state) {
                    case 'connecting':
                        console.log("producer transport state connecting");
                        break;

                    case 'connected':
                        console.log('producer transport state change connected');
                        break;

                    case 'failed':
                        console.log('producer state change failed');
                        if (this.producerTransport){
                            this.producerTransport.close();
                        }
                        break;

                    case "closed":
                        console.log('producer transport state change closed');
                        break;

                    case "disconnected":
                        console.log('producer transport state change disconnected');
                        if (this.producerTransport){
                            this.producerTransport.close();
                        }
                        break;

                    default:
                        break;
                }
            }.bind(this));
            this.producerTransport.on('producedata', async (
                {
                    sctpStreamParameters,
                    label,
                    protocol,
                    appData
                },
                callback,
                errback
            ) =>
            {
                // sctpStreamParameters.streamId = parseInt(this.user_id);
                console.log(
                    '"producedata" event: [sctpStreamParameters:%o, appData:%o]',
                    sctpStreamParameters, appData);

                try
                {
                    // eslint-disable-next-line no-shadow
                    const dataProducer = await this.socket.request(
                        'produceData',
                        {
                            user_id: this.user_id,
                            room_id: this.room_id,
                            transportId : this.producerTransport.id,
                            sctpStreamParameters,
                            label,
                            protocol,
                            appData
                        });
                        // console.log('produceData socket request resolved', dataProducer._data.id);
                    callback({ id: dataProducer.id });
                }
                catch (error)
                {
                    errback(error);
                }
            });
        }


        // init consumerTransport
        {
            const data = await this.socket.request('createWebRtcTransport', {
                forceTcp: false,
                rtpCapabilities: device.rtpCapabilities,
                sctpCapabilities: this._useDataChannel ?  device.sctpCapabilities :   undefined,
                producing: false,
				consuming: true,
            });
            if (data.error) {
                console.error(data.error);
                return;
            }

            // only one needed
            this.consumerTransport = device.createRecvTransport(data);

            let stats = await this.consumerTransport.getStats();
            console.log('consumerTransport##', stats)

            this.consumerTransport.on('connect', function ({
                dtlsParameters
            }, callback, errback) {
                this.socket.request('connectTransport', {
                        transport_id: this.consumerTransport.id,
                        dtlsParameters
                    })
                    .then(callback)
                    .catch(errback);
            }.bind(this));

            this.consumerTransport.on('connectionstatechange', async function (state) {
                switch (state) {
                    case 'connecting':
                        console.log("consumer transport state connecting");
                        break;

                    case 'connected':
                        console.log('consumer transport state change connected');
                        //remoteVideo.srcObject = await stream;
                        //await socket.request('resume');
                        break;

                    case 'failed':
                        console.log('consumer transport state change failed');
                        if (this.consumerTransport){
                            this.consumerTransport.close();
                        }
                        break;
                    case "closed":
                        console.log('consumer transport state change closed');
                        break;

                    case "disconnected":
                        console.log('consumer transport state change disconnected');
                        if (this.consumerTransport){
                            this.consumerTransport.close();
                        }
                        break;

                    default:
                        break;
                }
            }.bind(this));
        }

        this._boardDataProducer = await this.enableDataProducer({ label: 'board'});
        console.log('chat board producer created', this._boardDataProducer.id);
        EventRegister.emit('broadcast', 'connectionCompleted');

        return true;

    }

    async enableDataProducer({ label })
	{
		// console.log('enableChatDataProducer()');
            
		if (!this._useDataChannel)
            return;
		try
		{
			const dataProducer = await this.producerTransport.produceData(
				{
					ordered        : false,
					maxRetransmits : 1,
					label          : label,
					priority       : 'medium',
                    appData        : { info: label+'-DataProducer' }
                });
                
            // console.log('data producer created', dataProducer);
			dataProducer.on('transportclose', () =>
			{
				console.log('chat DataProducer "transportclose" event');
				// dataProducer = null;
			});

			dataProducer.on('open', () =>
			{
				console.log('chat DataProducer "open" event');
			});

			dataProducer.on('close', () =>
			{
				console.log('chat DataProducer "close" event');
				// dataProducer = null;
			});

			dataProducer.on('error', (error) =>
			{
				console.log('chat DataProducer "error" event:%o', error);
			});

			dataProducer.on('bufferedamountlow', () =>
			{
				console.log('chat DataProducer "bufferedamountlow" event');
            });
            
            return dataProducer;
		}
		catch (error)
		{
			console.log('enableChatDataProducer() | failed:%o', error);
			// throw error;
		}
    }

    async sendBoardMessage(text)
	{
		console.log('sendBoardMessage() [text:"%s]', text);

		if (!this._boardDataProducer)
		{
			console.log(
				{
					type : 'error',
					text : 'No board DataProducer'
				});
			return;
		}

		try
		{
			this._boardDataProducer.send(text, 51);
		}
		catch (error)
		{
			console.log('board _boardDataProducer.send() failed:%o', error);
		}
    }


    async _handleNewDataConsumer(data){
        const {
            peerId, // NOTE: Null if bot.
            dataProducerId,
            id,
            sctpStreamParameters,
            label,
            protocol,
            appData
        } = data;
        console.log("handle new data consumer 399", data)
        try
		{
            const dataConsumer = await this.consumerTransport.consumeData(
                {
                    id,
                    dataProducerId,
                    sctpStreamParameters,
                    label,
                    protocol,
                    // appData : { ...appData, peerId } // Trick.
                });	
            this._dataConsumers.set(dataConsumer.id, dataConsumer);
            console.log('dataConsumer created in client', dataConsumer.id);
            
            dataConsumer.on('transportclose', () =>
            {
                this._dataConsumers.delete(dataConsumer.id);
            });

            dataConsumer.on('open', () =>
            {
                console.log('DataConsumer "open" event');
            });

            dataConsumer.on('close', () =>
            {
                console.log('DataConsumer "close" event');
                this._dataConsumers.delete(dataConsumer.id);
            });

            dataConsumer.on('error', (error) =>
            {
                console.log('DataConsumer "error" event:%o', error);
            });
            dataConsumer.on('message', (message) =>
            {
                console.log(
                    'DataConsumer "message" event [streamId:%d]',
                    dataConsumer.sctpStreamParameters.streamId);

                if (message instanceof ArrayBuffer)
                {
                    const view = new DataView(message);
                    const number = view.getUint32();
                    if (number == Math.pow(2, 32) - 1)
                    {
                        console.log('dataChannelTest finished!');
                        this._nextDataChannelTestNumber = 0;
                        return;
                    }
                    if (number > this._nextDataChannelTestNumber)
                    {
                        console.log(
                            'dataChannelTest: %s packets missing',
                            number - this._nextDataChannelTestNumber);
                    }
                    this._nextDataChannelTestNumber = number + 1;
                    return;
                }
                else if (typeof message !== 'string')
                {
                    console.warn('ignoring DataConsumer "message" (not a string)')
                    // logger.warn('ignoring DataConsumer "message" (not a string)');
                    return;
                }	
                switch (dataConsumer.label)
                {
                    case 'chat':
                    {
                        console.log(
                            {
                                title   : `says:`,
                                text    : message,
                                timeout : 5000
                            });

                        break;
                    }

                    case 'board':
                    {
                        this.eventCallback('headlessCommunication', message);
                        console.log('dataChannel board data', message);
                        break;
                    }
                    case 'bot':
                        {
                            console.log('bot data');
                            break;
                        }
                }
                
			});
            console.log("dataconsumer created successfully 492")
        }
        catch (error)
        {
            console.log('"newDataConsumer"  failed to create :%o', error);
            return;
        }
    }

    initSockets() {
        this.socket.on('consumerClosed', function ({
            consumer_id
        }) {
            console.log('closing consumer:', consumer_id)
            this.removeConsumer(consumer_id)
        }.bind(this))

        this.socket.on('newProducers', async function (data) {
            console.log('new producers', data)
            for (let {
                    producer_id, user_id
                } of data) {
                // await this.consume(producer_id, user_id)
            }
        }.bind(this))

        this.socket.on('newDataConsumer', async function (data) {
            console.log('newDataConsumer', data)
            this._handleNewDataConsumer(data);
        }.bind(this))
        
        this.socket.on('dataConsumerClosed', function (data) {
            console.log('dataConsumerClosed', data);
            const { dataConsumerId } = data;
			const dataConsumer = this._dataConsumers.get(dataConsumerId);

			if (!dataConsumer)
				return;

			dataConsumer.close();
			this._dataConsumers.delete(dataConsumerId);
            
        }.bind(this))

        this.socket.on(
            'boardDataRcv',
            function (data) {
              console.log('boardDataRcv', data);
            //   this.userList = data;
            //   EventRegister.emit('userList', this.userList);
            }.bind(this),
          );


        this.socket.on(
        'openBoardRcv',
        function (data) {
            console.log('chatRcv', data);
        //   EventRegister.emit('userList', this.userList);
        }.bind(this),
        );
        this.socket.on(
        'closeBoardRcv',
        function (data) {
            console.log('chatRcv', data);
        //   EventRegister.emit('userList', this.userList);
        }.bind(this),
        );

        this.socket.on('roomDetails', function (data) {
            console.log('roomDetails', data);
            
        }.bind(this))

        this.socket.on('userName', function (data) {
            console.log('userName', data);
            
        }.bind(this))


  
      this.socket.on(
        'userList',
        function (data) {
          console.log('userList', data);
        //   this.userList = data;
        //   EventRegister.emit('userList', this.userList);
        }.bind(this),
      );
  
      this.socket.on(
        'userJoined',
        function (data) {
            console.log('newUserJoined', data);
            
        //   this.userList.push(data);
        //   EventRegister.emit('userList', this.userList);
        }.bind(this),
      );

      this.socket.on(
        'userRejoined',
        function (data) {
            console.log('userRejoined', data);
            
        //   this.userList.push(data);
        //   EventRegister.emit('userList', this.userList);
        }.bind(this),
      );
      
      this.socket.on(
        'userLeft',
        function (data) {
            console.log('userLeft', data);
            
        //   this.userList = this.userList.filter(
        //     (user) => user.user_socket_id !== data.user_socket_id,
        //   );
        //   EventRegister.emit('userList', this.userList);
        }.bind(this),
      );
  
      this.socket.on(
        'eventNotification',
        function (data) {
          console.log('got eventNotification ', data);
          this.eventCallback('headlessCommunication', data)
        //   var x = document.getElementById("snackbar");
        //     x.className = "show";
        //     x.innerHTML = `${data.user} ${data.action} ${data.option}`
        //     setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
        //   this.eventNotification.push(data);
        //   EventRegister.emit('eventNotification', this.eventNotification);
        }.bind(this),
      );
  
      this.socket.on(
        'activeSpeaker',
        function (data) {
        //   console.log('activeSpeaker', data);
        //   this.activeSpeaker = this.remoteMedia.filter(
        //     (media) => media.audio && media.audio.producer_id === data.producerId,
        //   );
        //   this.activeSpeaker =
        //     this.activeSpeaker.length === 0
        //       ? {userName: 'Me'}
        //       : this.activeSpeaker[0];
        //   console.log('filter ative spker', this.activeSpeaker);
        //   EventRegister.emit('activeSpeaker', this.activeSpeaker);
        }.bind(this),
      );
  
      this.socket.on(
        'pauseProducerRemoteRcv',
        function (producer_id) {
          console.log('pausing producer as requested', producer_id);
          if (this.producers.has(producer_id)) {
            this.producers.get(producer_id).pause();
          } else {
            console.log('not my producer to pause');
          }
        }.bind(this),
      );
  
      this.socket.on(
        'resumeProducerRemoteRcv',
        function (producer_id) {
          console.log('resuming producer as requested', producer_id);
          if (this.producers.has(producer_id)) {
            this.producers.get(producer_id).resume();
          } else {
            console.log('not my producer to pause');
          }
        }.bind(this),
      );


      this.socket.on('disconnect', function () {
        this.offline = true;
        // this.exit(true)
        console.log('socket disconnected');
        
        // this.socket.connect();
      }.bind(this))

      this.socket.on('connect_error', (error) => {
        console.log('connect_error', error);
        
      });
      this.socket.on('connect_timeout', (timeout) => {
        console.log('connect_timeout', timeout);

      });
      this.socket.on('error', (error) => {
        console.log('error', error);

      });
      this.socket.on('reconnect', (attemptNumber) => {
        console.log('reconnect', attemptNumber);
        
      });
      this.socket.on('reconnect_attempt', (attemptNumber) => {
        console.log('reconnect_attempt', attemptNumber);

      });
      this.socket.on('reconnecting', (attemptNumber) => {
        console.log('reconnecting', attemptNumber);

      });
      this.socket.on('reconnect_error', (error) => {
        console.log('reconnect_error', error);

      });
      this.socket.on('reconnect_failed', () => {
        console.log('reconnect_failed');
        
      });
      this.socket.on('numberOfConsumer', (noConsumer) => {
        if(noConsumer.numOfConsumer!=8){
            this.eventCallback('consumererror')
        }
      });
      this.socket.on('monitoringMessage', (data) => {
          if (data.message==='file not creating'){
              this.eventCallback('fileNotCreating')
          }else if(data.message==='file is creating'){
            this.eventCallback('fileCreating')
          }
        console.log('monitoringMessage', data.message);
      });
      this.socket.on('ping', () => {
        // console.log('ping');

      });
      this.socket.on('pong', (ms) => {
        // console.log('pong latency', ms);
      });

    

      this.socket.on('connect', function ()  {
        this.offline = false;
        console.log('socket connected', this.user_id, this.room_id);
        this.socket.request('join', { 
            user_id: this.user_id,
            user: this.user,
            room_id: this.room_id
        }).then(async ()=> {
            await this.resumeServices()
        })
      }.bind(this));
      let thiz = this;
      this.socket.on('rtmpStarted', function (link)  {
        console.log('rtmp Link created  ', link);
        if (thiz.user_id==link.userId){
            thiz.eventCallback('rtmpStarted');
        }
        // var x = document.getElementById("snackbar");
        //     x.className = "show";
        //     x.innerHTML = `rtmp stream active`
        //     setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
      })
      this.socket.on('rtmpStopped', function ()  {
        console.log('rtmp Link removed  ');
        thiz.eventCallback('rtmpRemoved');
        // var x = document.getElementById("snackbar");
        //     x.className = "show";
        //     x.innerHTML = `rtmp stream stopped`
        //     setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
      })

    }
  async replaceAudioTracks(dataTrack){
    console.log("data Track",dataTrack,this.producerAudio.get("audio"));
    let clone=this.producerAudio.get("audio")
    const mergedAudioTrack = await this.mergeAudioTracks(dataTrack.getAudioTracks());

// You can now use mergedAudioTrack in a WebRTC call or elsewhere
                 const combinedStream = new MediaStream([mergedAudioTrack]);
                 
                console.log("combinedStream",combinedStream);
     clone.replaceTrack({track:combinedStream.getAudioTracks()[0]})

  }
async resumeServices(){
            if (!this.consumerTransport){
                return
            }
            let thiz = this;
            let transport_id = this.consumerTransport.id
            this.socket.request('iceRestart', {transport_id}).then(async (iceParameters) => {
                console.log('iceParameters received for consumer', iceParameters);
                try{
                    await this.consumerTransport.restartIce({iceParameters: iceParameters.iceParameters})
                    console.log('resuming consumer', this.consumers);
                    this.consumers.forEach(async (consumer)=>{
                        await consumer.resume()
                    })
                }
                catch(error){
                    console.log(error);
                }
            }).catch((e)=>{
                thiz.exit()
                console.log(e);
            })

            let producerTransportId = this.producerTransport.id
            console.log(producerTransportId);
            this.socket.request('iceRestart', {transport_id: producerTransportId}).then(async (iceParameters) => {
                console.log('iceParameters received for producer', iceParameters);
                try{
                    await this.producerTransport.restartIce({iceParameters: iceParameters.iceParameters})
                    console.log('resuming producer', this.producers);
                    this.producers.forEach(async (producer)=>{
                        console.log('resume producer',producer.id)
                        await producer.resume()
                    })
                }
                catch(error){
                    console.log(error);
                }
            }).catch((e)=>{
                thiz.exit()
                console.log(e);
            })
}

async   mergeAudioTracks(mediaTracksArray) {
    console.log("entered,merge",mediaTracksArray);
    // Create an AudioContext
    const audioContext = new AudioContext();

    // Create a destination node that we will output the mixed audio to
    const destination = audioContext.createMediaStreamDestination();

    // Iterate over the array of MediaStreamTracks (audio tracks)
    mediaTracksArray.forEach(track => {
        const mediaStream = new MediaStream([track]);

        // Create a MediaStreamAudioSourceNode from each track
        const sourceNode = audioContext.createMediaStreamSource(mediaStream);

        // Connect each source node to the destination
        sourceNode.connect(destination);
    });

    // destination.stream will contain the combined audio stream
    return destination.stream.getAudioTracks()[0]; // Single merged MediaStreamTrack
}
    //////// MAIN FUNCTIONS /////////////

    async produce(type,totalMicStream, callback) {
        console.log("totinsideproduce",totalMicStream);
       
        
        let mediaConstraints = {}
        let audio = false
        let screen = false
        switch (type) {
            case mediaType.audio:
                mediaConstraints = {
                    audio: true,
                    video: false
                }
                audio = true
                break
            case mediaType.video:
                mediaConstraints = {
                    audio: true,
                    video: {
                        width: {
                            ideal: 1980
                        },
                        height: {
                            ideal: 1080
                        },
                        aspectRatio: {
                            ideal: 1.7777777778
                        },
                        frameRate: { ideal: 30, min: 25 }
                    }
                }
                break
            case mediaType.screen:
                mediaConstraints = { 
                    audio: true,
                    video: {
                        width: { ideal: 1280 },
                        height: { ideal: 720 },
                        frameRate: { ideal: 30 },
                    }
                }
                screen = true
                break;
            default:
                return
                break;
        }
        if (!this.device?.canProduce('video') && !audio) {
            console.error('cannot produce video');
            return;
        }
        if (this.producerLabel.has(type)) {
            console.log('producer already exists for this type ' + type)
            return
        }
        let stream;
        let thiss = this;
        // let canvas = document.getElementById("broadcast-canvas")
        // var ctx = canvas.getContext("2d");

        // ctx.fillStyle = "white";
        // ctx.fillRect(0, 0, canvas.width, canvas.height);
        try {
            if (this.refer){
                if (audio){
                    stream = this.audioStream.clone()
                }else{
                    stream = this.videoStream.clone()
                }
            }else{
                if(screen){
                    stream=await navigator.mediaDevices.getDisplayMedia(mediaConstraints)
                }
                else{
                    console.log("totalMicStream",totalMicStream);
                    const mergedAudioTrack = await this.mergeAudioTracks(totalMicStream.getAudioTracks());

// You can now use mergedAudioTrack in a WebRTC call or elsewhere
                 const combinedStream = new MediaStream([mergedAudioTrack]);
                 
                    stream=combinedStream
                }
            // stream = screen ? await navigator.mediaDevices.getDisplayMedia(mediaConstraints) : await navigator.mediaDevices.getUserMedia(mediaConstraints)
            console.log("streamm",stream.getAudioTracks());
            if (this.setStreamCallback){
                this.setStreamCallback(audio, stream)
            }
            }
            // if (!screen && !audio && !this.refer){
            //     this.eventCallback("cameraOn", stream);
            // }
           
            const tracks = audio ? stream?.getAudioTracks()[0] : stream.getVideoTracks()[0]
          
            const params = {
                track:audio?tracks:tracks.clone()
            };
            console.log("tracks",params);
            let thiz = this;
            console.log("just before check this.producerTransport",this.producerTransport)
            if(!this.producerTransport){
                await this.producerTransport?.close();
                await this.consumerTransport?.close();
                this.producerTransport=null;
                this.consumerTransport=null;
                console.log("producer transport close using the button");
                console.log("producer transport not available")
                const transportCreated = await thiz.initTransports(thiz.device)
                if(!transportCreated){
                    console.log('Failed to join');
                    
                }
            }
            let producer
            try{
             producer = await this.producerTransport.produce(params)
             console.log("producer",producer,);
             if(producer._kind==="audio"){
                this.producerAudio.set("audio",producer)
             }
            }
            catch(err){
                console.log("error",err);
            }
            this.allTracks.set(producer.id, stream)

            if (callback){
                callback();
            }

            this.producers.set(producer.id, producer)

            // let elem
            // if (!audio) {
            //     elem = document.createElement('video')
            //     elem.srcObject = stream
            //     elem.id = producer.id
            //     elem.playsinline = false
            //     elem.autoplay = true
            //     elem.className = "vid"
            //     this.localMediaEl.appendChild(elem)
            // }
            producer.on('trackended', () => {
                console.log("the trackended")
                thiz.closeProducer('screenType');
                setTimeout(()=>{
                    thiz.closeProducer('audioType');
                }, 2000);

            })

            producer.on('transportclose', () => {
                let thiz = this
                console.log('producer transport close', this.allTracks.get(producer.id))
                thiz.endTracks(thiz.allTracks.get(producer.id))
                this.producers.delete(producer.id)

            })

            producer.on('close', () => {
                console.log('closing producer')
                this.producers.delete(producer.id)

            })

            this.producerLabel.set(type, producer.id)

            switch (type) {
                case mediaType.audio:
                    this.event(_EVENTS.startAudio)
                    break
                case mediaType.video:
                    this.event(_EVENTS.startVideo)
                    break
                case mediaType.screen:
                    this.event(_EVENTS.startScreen)
                    break;
                default:
                    return
                    break;
            }
        } catch (err) {
            if (err.name=='NotAllowedError'){
                thiss.eventCallback('SharePermissionDenied');
            }
            console.log("982", err)
        }
    }

    async consume(producer_id, user_id) {

        // let info = await this.roomInfo()
        // console.log('roomInfo', info);
        
        console.log('tring to consume', producer_id, user_id);
        
        this.getConsumeStream(producer_id, user_id).then(async function ({
            consumer,
            stream,
            kind,
            userName
        }) {
            // let stats = await consumer.getStats()
            // for (const now of stats.values()) {
            //     console.log(now);
            // }
            console.log('got stream ', consumer, stream, kind, producer_id, userName);
            
            this.consumers.set(consumer.id, consumer)

            let elem;
            if (kind === 'video') {
                // elem = document.createElement('video')
                // elem.srcObject = stream
                // elem.id = consumer.id
                // elem.playsinline = false
                // elem.autoplay = true
                // elem.className = "vid"
                // this.remoteVideoEl.appendChild(elem)
            } else {
                // elem = document.createElement('audio')
                // elem.srcObject = stream
                // elem.id = consumer.id
                // elem.playsinline = false
                // elem.autoplay = true
                // this.remoteAudioEl.appendChild(elem)
            }

            consumer.on('trackended', function () {
                this.removeConsumer(consumer.id)
            }.bind(this))
            consumer.on('transportclose', function () {
                console.log('consumer transport closed');
                
                this.removeConsumer(consumer.id)
            }.bind(this))



        }.bind(this)).catch(e=> console.log('consume error', e))
    }

    async getConsumeStream(producerId, user_id) {
        const {
            rtpCapabilities
        } = this.device
        const data = await this.socket.request('consume', {
            rtpCapabilities,
            consumerTransportId: this.consumerTransport.id, // might be 
            producerId,
            user_id
        });
        const {
            id,
            kind,
            rtpParameters,
            userName
        } = data;

        let codecOptions = {};
        const consumer = await this.consumerTransport.consume({
            id,
            producerId,
            kind,
            rtpParameters,
            codecOptions,
        })
        const stream = new MediaStream();
        stream.addTrack(consumer.track);
        return {
            consumer,
            stream,
            kind,
            userName
        }
    }
    endTracks(stream){
        stream.getTracks().forEach(function (track) {
            track.stop()
        })
    }
    mockcloseProducer(type){
        if (!this.producerLabel.has(type)) {
            console.log('there is no producer for this type ' + type)
            return
        }
        let producer_id = this.producerLabel.get(type)
        console.log(producer_id)
        this.socket.emit('producerClosed', {
            producer_id
        })
    }
    closeProducer(type) {
        if (!this.producerLabel.has(type)) {
            console.log('there is no producer for this type ' + type)
            return
        }
        let producer_id = this.producerLabel.get(type)
        console.log(producer_id)
        this.socket.emit('producerClosed', {
            producer_id
        })
        this.producerLabel.delete(type)
        this.producers.get(producer_id)?.close()
        this.endTracks(this.allTracks.get(producer_id))
        this.producers.delete(producer_id)

        switch (type) {
            case mediaType.audio:
                this.event(_EVENTS.stopAudio)
                break
            case mediaType.video:
                this.event(_EVENTS.stopVideo)
                break
            case mediaType.screen:
                this.event(_EVENTS.stopScreen)
                break;
            default:
                return
                break;
        }

    }

    mute(){
        // console.log("mute called in the room client");
        const audioProduerId = this.producerLabel.get("audioType") 
        // console.log("audioProducer Id is", audioProduerId);
        const audioProducer  = this.producers.get(audioProduerId);
        audioProducer?.pause();
        this.event(_EVENTS.mute)
    }
    unMute(){
        const audioProduerId = this.producerLabel.get("audioType") 
        // console.log("audioProducer Id is", audioProduerId);
        const audioProducer  = this.producers.get(audioProduerId);
        audioProducer?.resume();
        this.event(_EVENTS.unMute)
    }

    pauseProducer(type) {
        if (!this.producerLabel.has(type)) {
            console.log('there is no producer for this type ' + type)
            return
        }
        let producer_id = this.producerLabel.get(type)
        this.producers.get(producer_id).pause()

    }

    pauseProducerRemote(producer_id){
        this.producers.get(producer_id).pause()
    }

    resumeProducerRemote(producer_id){
        this.producers.get(producer_id).resume()
    }

    resumeProducer(type) {
        if (!this.producerLabel.has(type)) {
            console.log('there is no producer for this type ' + type)
            return
        }
        let producer_id = this.producerLabel.get(type)
        this.producers.get(producer_id).resume()

    }

    removeConsumer(consumer_id) {
        this.consumers.delete(consumer_id)
    }

    exit() {
        this.closeProducer('audioType')
        this.closeProducer('screenType')
        this.closeProducer('videoType')
        let clean = function () {
            this._isOpen = false
            this.consumerTransport?.close()
            this.producerTransport?.close()
            this.socket?.disconnect();
            console.log('cleaning socket');
        }.bind(this)

        if (!this.offline) {
            this.socket.request('exitRoom').then(e => console.log(e)).catch(e => console.warn(e)).finally(function () {
                clean()
            }.bind(this))
        } else {
            clean()
        }
        this.event(_EVENTS.exitRoom)

    }

    disconnect(){
        this.socket.disconnect();
    }

    connect(){
        this.socket.connect();
    }
    ///////  HELPERS //////////

    // async roomInfo() {
    //     let info = await socket.request('getMyRoomInfo', {a:1, b:2, c:3})
    //     return info
    // }

    static get mediaType() {
        return mediaType
    }

    event(evt) {
        if (this.eventListeners.has(evt)) {
            this.eventListeners.get(evt).forEach(callback => callback())
        }
    }

    on(evt, callback) {
        this.eventListeners.get(evt).push(callback)
    }




    //////// GETTERS ////////

    isOpen() {
        return this._isOpen
    }

    static get EVENTS() {
        return _EVENTS
    }
}
export default RoomClient;