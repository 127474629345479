/* eslint-disable react/display-name */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams,useNavigate } from "react-router-dom";
import Api from "../../../services/Api";
import { managerActions } from "./store";
import { Table, Button } from "antd";
import { Row, Col } from "antd";
import { globalActions } from "../store/loader";
import AssociateTable from "../components/Table/AssociateTable";

const AssociateList = () => {
  const dispatch = useDispatch();
  const { teamId } = useParams();

  const { associateList, selectedTeamId } = useSelector(
    (state) => state.manager
  );
  const { user } = useSelector((state) => state.auth);
  // const history = useHistory();
  const navigate = useNavigate()
  const { loader } = useSelector((state) => state.global);

  useEffect(() => {
    if (!selectedTeamId) {
      // history.goBack();
      navigate(-1)
      return;
    }
    dispatch(globalActions.updateLoader({ loader: true }));
    Api.get(
      "/appraisal-manager/associate-list?teamId=" +
        selectedTeamId +
        "&managerId=" +
        user.id
    )
      .params({})
      .send((response, error) => {
        if (typeof response != "undefined") {
          dispatch(
            managerActions.updateAssociateList({
              associates: response.associates,
            })
          );
    dispatch(globalActions.updateLoader({ loader: false }));

        }
      });
    return () => {
      // dispatch(
      //   managerActions.updateSelectedAssociateId({
      //     selectedAssociateId: null,
      //   })
      // );
    };
  }, []);

  const dataSource = associateList.map((item) => {
    return {
      key: item.user_id,
      id: item.user_id,
      name: item.userView_name,
      managerStatus: item.workSummary_managerStatus,
      associateId: item.user_id,
      appraisalCycle: item.user_appraisalCycle,
    };
  });

  const onAssociateClick = (row) => {
    dispatch(
      managerActions.updateSelectedAssociateId({ selectedAssociateId: row.id })
    );
    // history.push("/manager/appraisal-form");
    navigate("/appraisal/manager/appraisal-form")
  };

  const columns = [
    {
      title:<div style={{width:"100%",paddingLeft:"1rem"}}>Associate Name</div> ,
      dataIndex: "name",
      key: "name",
      render:(text,row)=>{
        return(
          <div style={{paddingLeft:"1rem"}}>{row?.name}</div>
        )
      }
    },
    {
      title:<div style={{width:"100%"}}>Action</div> ,
      dataIndex: "action",
      key: "action",
      render: (val, row) => (
        <Button
          onClick={() => onAssociateClick(row)}
          type="primary"
          disabled={row.managerStatus === "Completed" || row.appraisalCycle==="April"}
        >
          Submit Feedback
        </Button>
      ),
    },
  ];
  if (loader) {
    return null;
  }

  return (
    <div style={{
    width:"100vw",
    height:'90vh',
   alignItems:"center",
    display:"flex",
    flexDirection:"column",
    overflow:'scroll'}}
    >
      <div className="p" style={{
        marginTop:"4vh",
        width:"80%"
        }}>
        <div className="" style={{
          width:"100%",
        
          // border:"1px solid #0086FF"
          }}>
          {/* <Table dataSource={dataSource} columns={columns} pagination={false} /> */}
          <AssociateTable
        // loading={loader}
         dataSource={dataSource}
        columns={columns}
         rowKey={(record) => record?.id}
        // meta={meta}
        style={{ width: "100%", height: "71vh" }}
        // onChange= {(page, pageSize) => handlePageChange(page, pageSize)}
      />
        </div>

        <div style={{ marginTop: "4vh", float: "right" }}>
          <Button
            type="primary"
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              background: "#fff",
              border: "1px solid #0086ff",
              color:"#0086ff",
              width:"8rem",
              height:"2.3rem"
            }}
            onClick={() => {
              navigate(-1)
            }}
          >
            Back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AssociateList;
