import React, { useState, useEffect } from 'react'
import Logs from './Logs'
import EmployeeSummary from './EmployeeSummary'
import viewClass from '../../../services/ViewRole';
// import useProjectStore from '../Projects/store';
import { useProjecteStore } from '../store/store';
import { decryptData, encryptData,SECRET_KEY } from '../../../services/fileCode';


const LogsMain = () => {
   const [render, setRender] = useState(1);
   const {setTaskLogs} = useProjecteStore(state=>state);
   const [roleName,setRoleName] =useState("")
   const [filter, setFilter] = useState(false);
   const [values, setValues] = useState({
    departmentId: "",
    managerId: "",
    teamId: "",
    roleId: "",
    startDate: "",
    endDate: "",
    role:"",
  })

   const storedUser = localStorage.getItem('user');
     let decryptedUser = decryptData(storedUser, SECRET_KEY);
console.log("hello 123");
   useEffect(()=>{
    console.log(( viewClass.hrAdmin || viewClass.hrAssociate || viewClass.associate)&&!(viewClass.superAdmin)&&!(viewClass.manager),"====----------");
    if((viewClass.hrAdmin || viewClass.hrAssociate || viewClass.associate)&&!(viewClass.superAdmin)&&!(viewClass.manager)){
        setRender(2)
        setTaskLogs("userId", decryptedUser?.resourceUser?.id)
    }


   },[])

    return (
        <div style={{ height: '85vh' }}>
            {render === 1
                ?
                <Logs filter={filter} setFilter={setFilter} roleName={roleName} setRoleName={setRoleName} values={values} setValues={setValues} render={render} setRender={setRender} />
                :
                render === 2
                    ?
                    <EmployeeSummary setRender={setRender} />
                    :
                    <div>error 4040</div>}
        </div>
    )
}

export default LogsMain