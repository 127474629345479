import React, { useState } from "react";
import classes from './VideoModal.module.css';
import { Modal } from "antd";
import { useLoginStore } from "../store";
import YouTube from 'react-youtube';
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";


const VideoModal=({video})=>{
   const {width}=useWindowDimensions();
    const {videoModal,videoModalHandler}=useLoginStore();
    console.log("debug vid",video?.videoId)
 
    const id=video?.videoId;
    const url=`https://www.youtube.com/embed/${id}?autoplay=1`;
    
    return(
        <Modal 
        onCancel={()=>{
            videoModalHandler(false);
        }}
        bodyStyle={{padding:'16px'}}
        open={videoModal}
        footer={false}
        centered
        width={width>700?"540px":"340px"}
        >
            
            <div className={classes.videoModal} style={{padding:'16px'}}>
            <YouTube videoId={id}
             opts=
            {{ width:width>700?500:280, height:width>700? 400:200,
                playerVars: {
                    autoplay: 1, // 0 for false, 1 for true
                    controls:1,
                  },
             }} />
            {/* <iframe 
             width={'100%'}
            //  width={width>700?"530":"240"}
              controls autoPlay>
             <source src={"https://www.youtube.com/embed/watch?"+id} type="video/mp4"/>
             
             </iframe> */}
                    {/* <iframe width="560" height="400" src={url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>     */}
            </div>
        </Modal>
    )
}
export default VideoModal;