import React, { useEffect, useState } from "react";
import SearchCompo from "../../../design/SearchCompo";
import classes from "./authentication.module.css";
// import { approvalAwaiting } from "../sampleData";
import informationIcon from "../../../assets/notifyIcon.svg";
import ApproveIcon from "../../../assets/TickIcon.svg";
import ButtonCompo from "../../../design/ButonCompo";
import { Pagination, Spin, Table, Typography } from "antd";
import ModalCompo from "../../../design/ModalCompo";
import approveIcon from "../assets/approve green.svg";
import ApprovedIcons from "../../../assets/ApprovedIcons.svg";
import moment from 'moment';
import {
  approveAuthentication,
  authenticationData,
  recentlyAuthenticationData,
  searchAuthUsers,
} from "./actions";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";

const Authentication = () => {
  const [approverModal, setApproverModal] = useState({ flag: false });
  const [rejectModal, setRejectModal] = useState({ flag: false });
  const [recentAuthenticationTableLoader, setRecentAuthenticationTableLoader] = useState(false);
  const [recentAuthentication, setRecentAuthentication] = useState([]);
  const [recentAuthenticationMeta, setRecentAuthenticationMeta] = useState([]);
  const [approvalAwaiting, setApprovalAwaiting] = useState([]);
  const [approvalAwaitingLoader, setApprovalAwaitingLoader] = useState(false);
  const [approvalAwaitingMeta, setApprovalAwaitingMeta] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [pageSizeRecently, setPageSizeRecently] = useState(10);
  const [pageCurrentRecently, setPageCurrentRecently] = useState(1);
  const [paramData, setParamsData] = useState({});
  const [paramDataRecently, setParamsDataRecently] = useState({});
  const [searchVal, setSearchVal] = useState("");
  const { width } = useWindowDimensions();

  const fetchrecentlyAuthenticationData = () => {
    setRecentAuthenticationTableLoader(true);
    recentlyAuthenticationData(paramDataRecently, (res) => {
      setRecentAuthentication(res?.items);
      setRecentAuthenticationMeta(res?.meta);
      setRecentAuthenticationTableLoader(false);
    });
  };

  const fetchAuthenticationData = () => {
    setApprovalAwaitingLoader(true);
    authenticationData(paramData, (res) => {
      setApprovalAwaiting(res?.items);
      setApprovalAwaitingMeta(res?.meta);
      setPageCurrent(res?.meta?.currentPage);
      setPageSize(res?.meta?.itemsPerPage);
      setApprovalAwaitingLoader(false);
    });
  };

  useEffect(() => {
    if (searchVal === "") {
      setParamsDataRecently({
        page: pageCurrentRecently,
        limit: pageSizeRecently,
      })
    } else {
      setParamsDataRecently({
        search: searchVal,
      })
    }
  }, [pageCurrentRecently, pageSizeRecently, searchVal])

  useEffect(() => {
    if (searchVal === "") {
      setParamsData({
        page: pageCurrent,
        limit: pageSize,
      })
    } else {
      setParamsData({
        search: searchVal,
      })
    }
  }, [pageCurrent, pageSize, searchVal])

  useEffect(() => {
    fetchrecentlyAuthenticationData();
    // fetchAuthenticationData();
  }, [paramDataRecently]);

  useEffect(() => {
    // fetchrecentlyAuthenticationData();
    fetchAuthenticationData();
  }, [paramData]);


  const approveHandler = (val) => {
    setApproverModal({ flag: false });
    approveAuthentication(val.id, (res) => {
    });
    fetchrecentlyAuthenticationData();
    fetchAuthenticationData();
  };

  const searchHandler = (value) => {
    setSearchVal(value)
    // searchAuthUsers(
    //   { search: value },
    //   (res) => {
    //     setApprovalAwaitingLoader(true);
    //     setApprovalAwaiting(res?.items);
    //     setApprovalAwaitingMeta(res?.meta);
    //     setApprovalAwaitingLoader(false);
    //   },
    //   (res) => {
    //     setRecentAuthenticationTableLoader(true);
    //     setRecentAuthentication(res?.items);
    //     setRecentAuthenticationMeta(res?.meta);
    //     setRecentAuthenticationTableLoader(false);
    //   }
    // );
    setPageCurrent(1)
    setPageCurrentRecently(1)
  };
  const handlePageChange = (page, pageSize) => {
    setPageCurrentRecently(page);
    setPageSizeRecently(pageSize)
  }
  const handlePageApproveChange = (page, pageSize) => {
    setPageCurrent(page);
    setPageSize(pageSize)
  }

  return (
    <div
      style={{
        width: width > 700 ? "100%" : "99vw",
        height: width > 700 ? "100%" : "90vh",
        display: width > 700 && "flex",
        flexDirection: width > 700 && "column",
        backgroundColor: "#FFFFFF",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "6vh",
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          justifyContent: "space-between", padding: "0.5rem",
          marginBottom: "rem", borderBottom: width > 700 && "1px solid #DFDFDF"
        }}
      >
        <h3 style={{ fontSize: width < 700 ? "23px" : 20, width: "100%" }}>Authentication</h3>
        {width > 700 && <div style={{ borderBottom: "0.rem", }}>
          <SearchCompo
            setSearch={(val) => {
              // setSearchStr(val);
              searchHandler(val);
              return;
            }}
            style={{
              width: "19rem",
              height: "1.9rem",
              fontSize: "0.9rem",
              display: "flex", flexDirection: "row", alignItem: "center", justifyContent: "center",
            }}
          />
        </div>}
      </div>
      {width < 700 && <div style={{ padding: " 0 0.5rem", marginBottom: "0.7rem" }}>
        <SearchCompo
          setSearch={(val) => {
            // setSearchStr(val);
            searchHandler(val);
            return;
          }}
          style={{
            width: "19rem",
            height: "2.4rem",
            fontSize: "0.9rem",
            display: "flex", flexDirection: "row", alignItem: "center", justifyContent: "center",
            // border:"2px solid"
          }}
        />
      </div>}
      <div style={{ height: width > 700 ? "42%" : "", width: "100%", }}>
        {/* <Table 
          style={{
            // border: "1px solid black",
            borderRadius: "15px",
          }}
          rowClassName={(record, index) => index % 2 === 0 ? classes.tableRow :  classes.tableRowWhite}
          columns={[
            ...tableColumn(),
            {
              title: (
                <div style={{marginLeft: "1rem"}}>
                  Actions
                </div>
              ),
              dataIndex: "status",
              key: "status",
              render: (text, record) => {
                return (
                  <span className={classes.tableAction} style={{marginLeft: "1rem"}}>
                    <img
                      src={ApproveIcon} style={{height:"2rem"}}
                      onClick={() => {
                        setApproverModal({
                          flag: true,
                          name: record?.name,
                          id: record?.id,
                        });
                      }}
                    ></img>
                    <img
                      src={informationIcon} style={{height:"2rem"}}
                      onClick={() => {
                        setRejectModal({
                          flag: true,
                          name: record?.name,
                          id: record?.id,
                          record: record
                        });
                      }}
                    ></img>
                  </span>
                );
              },
            },
          ]?.map((col) => ({
            ...col,
            // align: "center",
          }))}
          scroll={{ y: 130,}}
          pagination={{
            position: "bottomRight",
            size: "small",
            pageSizeOptions: [5,10, 30, 50],
            pageSize: pageSize,
            current: pageCurrent,
            total: approvalAwaitingMeta?.totalItems
              ? approvalAwaitingMeta?.totalItems
              : 0,
            onChange: (page, pageSize) => {setPageCurrent(page);setPageSize(pageSize);},
            onShowSizeChange: (page, pageSize) => {setPageCurrent(page);setPageSize(pageSize);},
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          className={classes.autheticationTable}
          dataSource={approvalAwaiting}
          loading={approvalAwaitingLoader}
        /> */}
        {width > 700 ?
          <div className={classes.ListContainer}>
            <div className={classes.ListHeader} style={{ display: 'flex', width: '100%', gap: "0.5rem", paddingTop: "0.7rem", paddingBottom: "0.5rem", fontSize: 15, color: "#16243280", fontWeight: 700, }}>

              <div style={{ width: '20%', paddingLeft: "0.7rem" }}>Associate</div>
              <div style={{ width: '20%' }}>Contact Details</div>
              <div style={{ width: '20%' }}>Manager</div>
              <div style={{ width: '20%' }}>Timestamp</div>
              <div style={{ width: '20%' }}>Actions</div>
            </div>

            <div className={classes.ListBodyContainer} style={{ height: "26vh", overflowY: 'scroll', overflowX: "scroll", width: "100%" }}>
              {approvalAwaitingLoader ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}><Spin /></div> :
                approvalAwaiting?.length === 0 || approvalAwaiting?.length === undefined ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}><div>No data found</div></div> :
                  approvalAwaiting?.map((record, id, index) => {
                    return (
                      <div className={classes.rowContainer} style={{ gap: "0.5rem", color: "#162432", fontSize: 15, fontWeight: 400, fontStyle: "Lato", }}>

                        <div style={{ width: '20%', paddingLeft: "0.7rem" }}>
                          <Typography.Text style={{ width: "10vw" }} ellipsis={{ tooltip: true }}>
                            <span>{record?.name ? record?.name : "NA"}</span></Typography.Text>
                        </div>
                        <div style={{ width: '20%', }}>
                          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start" }}>
                            <Typography.Text style={{ width: "12vw" }} ellipsis={{ tooltip: true }}>
                              <span>{record?.email ? record?.email : "NA"}</span></Typography.Text>
                            <Typography.Text style={{ width: "12vw" }} ellipsis={{ tooltip: true }}>
                              <span>{record?.phone ? record?.phone : "NA"}</span></Typography.Text>
                          </div>
                        </div>
                        <div style={{ width: '20%', }}>
                          <Typography.Text style={{ width: "9rem" }} ellipsis={{ tooltip: true }}>
                            <span>{record?.managerName ? record?.managerName : "NA"}</span></Typography.Text>
                        </div>
                        <div style={{ width: '20%', }}>
                          <Typography.Text style={{ width: "9rem" }} ellipsis={{ tooltip: true }}>
                            <span>{record?.timeStamp ? record?.timeStamp : "NA"}</span></Typography.Text>
                        </div>
                        <div style={{ width: '20%', }}>
                          <div className={classes.tableAction} >
                            <img
                              src={ApproveIcon} style={{ height: "1.9rem" }}
                              onClick={() => {
                                setApproverModal({
                                  flag: true,
                                  name: record?.name,
                                  id: record?.id,
                                });
                              }}
                            ></img>
                            <img
                              src={informationIcon} style={{ height: "1.9rem" }}
                              onClick={() => {
                                setRejectModal({
                                  flag: true,
                                  name: record?.name,
                                  id: record?.id,
                                  record: record
                                });
                              }}
                            ></img>
                          </div>
                        </div>
                      </div>
                    )
                  })}
            </div>
          </div>
          :
          <div className={classes.ListBodyContainer} style={{ height: "30vh", overflowY: 'scroll', overflowX: "scroll", width: "100%", backgroundColor: "#F9F9F9", padding: "0.5rem" }}>
            {approvalAwaitingLoader ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '37vh' }}><Spin /></div> :
              approvalAwaiting?.length === 0 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '27vh' }}><div>No data found</div></div> :
                approvalAwaiting?.map((record, id, index) => {
                  return (
                    <div style={{ width: "100%", marginTop: "0.3rem", marginBottom: "1rem", borderRadius: 10, backgroundColor: "#FFFFFF", padding: "1rem 0.7rem" }}>
                      <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
                        <Typography.Text style={{ width: "20vw", fontSize: "20px", fontWeight: "550" }} ellipsis={{ tooltip: true }}>
                          {record?.name ? record?.name : "NA"}
                        </Typography.Text>
                        <div style={{ display: "flex", gap: "0.5rem" }}>
                          <img
                            src={ApproveIcon} style={{ height: "2.5rem" }}
                            onClick={() => {
                              setApproverModal({
                                flag: true,
                                name: record?.name,
                                id: record?.id,
                              });
                            }}
                          ></img>
                          <img
                            src={informationIcon} style={{ height: "2.5rem" }}
                            onClick={() => {
                              setRejectModal({
                                flag: true,
                                name: record?.name,
                                id: record?.id,
                                record: record
                              });
                            }}
                          ></img>
                        </div>
                      </div>

                      <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0.5rem", alignItems: "center" }}>
                        <p style={{ fontSize: "19px", color: "#16243280", marginTop: "0.3rem" }}>Email: </p>
                        <p>
                          <Typography.Text style={{ width: "68vw", fontSize: 20, }} ellipsis={{ tooltip: true }}>
                            {record?.email ? record?.email : "NA"}
                          </Typography.Text>
                        </p>
                      </div>

                      <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0.5rem", }}>
                        <p style={{ fontSize: "19px", color: "#16243280" }}>Phone: </p>
                        <p style={{ fontSize: "19px" }}>{record?.phone ? record?.phone : "NA"}</p>
                      </div>

                      <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0.5rem", }}>
                        <p style={{ fontSize: "19px", color: "#16243280" }}>Manager: </p>
                        <p style={{ fontSize: "19px" }}>{record?.managerName ? record?.managerName : "NA"}</p>
                      </div>

                      <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0.7rem", alignItems: "center" }}>
                        <p style={{ fontSize: "19px", color: "#16243280" }}>Timestamp: </p>
                        {/* <p style={{fontSize:"19px"}}>{record?.timeStamp ? record?.timeStamp :"NA"}</p> */}
                        <p><Typography.Text style={{ width: "60vw", fontSize: 20, fontWeight: "650px" }} ellipsis={{ tooltip: true }}>
                          {record?.timeStamp ? record?.timeStamp : "NA"}</Typography.Text></p>
                      </div>

                    </div>
                  )
                })}
          </div>
        }
        {/* {width > 700 && */}
        <div>
          {approvalAwaiting?.length === undefined ? "" :
            <Pagination position="bottomRight" size="small" style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem", marginRight: "0.2rem", fontSize: width < 700 && 15, }}
              current={pageCurrent}
              pageSize={pageSize}
              total={approvalAwaitingMeta?.totalItems ? approvalAwaitingMeta?.totalItems : 0}
              pageSizeOptions={[10, 20, 30, 50]}
              showSizeChanger={true}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              onChange={handlePageApproveChange}
              onShowSizeChange={handlePageApproveChange}
            />
          }
        </div>
        {/* } */}
      </div>
      <div style={{ height: width > 700 ? "47%" : "", width: "100%", }}>
        <h3 style={{ borderBottom: width > 700 && "1px solid #DFDFDF", padding: "0 0.5rem 0.5rem 0.5rem", fontSize: width < 700 ? "23px" : 20 }}>
          Recently Authenticated</h3>
        {/* <Table
        rowClassName={(record, index) => index % 2 === 0 ? classes.tableRow :  classes.tableRowWhite}
          style={{
            // border: "1px solid black",
            borderRadius: "15px",
            marginTop: "0.8rem",
          }}
          columns={[
            ...tableColumn(),
            {
              title: (
                <div style={{}}>
                  Actions
                </div>
              ),
              dataIndex: "timestamp",
              key: "timestamp",
              render: (text, record) => {
                let dateVal=record?.user?.userAuthentications[record?.user?.userAuthentications.length - 1]?.approvedAt
                return(<div style={{backgroundColor:"",height:"2rem",width:"7rem",display:"flex",alignItems:"center",borderRadius:"10px"}}>
                  
                  <div style={{color:"#39CA6C"}}>
                    Aprroved
                  </div>
                  {/* <Typography.Text style={{width:"9rem"}} ellipsis={{tooltip:true}}>
                    <span>{dateVal ? moment(dateVal).format('DD-MMM-YYYY, hh:mm A') :"NA"}</span>
                  </Typography.Text> */}
        {/* </div>)
              },
            },
          ]?.map((col) => ({
            ...col,
            // align: "center",
          }))}
          scroll={{ y: "23vh" }}
          pagination={{
            position: "bottomRight",
            size: "small",
            pageSizeOptions: [10, 30, 50],
            onChange: (page,pageSize)=>{setPageCurrentRecently(page);setPageSizeRecently(pageSize)
            
            onShowSizeChange: (page,pageSize)=>{setPageCurrentRecently(page);setPageSizeRecently(pageSize)},
            pageSize: pageSizeRecently,
            current: pageCurrentRecently,
            total: recentAuthenticationMeta?.totalItems
              ? recentAuthenticationMeta?.totalItems
              : 0,
            // onChange: (page, pageSize) => this.handlePageChange(page, pageSize),
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          className={classes.autheticationTable}
          dataSource={recentAuthentication}
          loading={recentAuthenticationTableLoader}
        /> */}
        {width > 700 ?
          <div className={classes.ListContainer}>
            <div className={classes.ListHeader} style={{ display: 'flex', width: '100%', gap: "0.5rem", paddingTop: "0.7rem", paddingBottom: "0.5rem", fontSize: 15, color: "#16243280", fontWeight: 700, }}>

              <div style={{ width: '30%', paddingLeft: "1rem" }}>Associate</div>
              {/* <div style={{width:'20%'}}>Contact Details</div> */}
              <div style={{ width: '20%' }}>Manager</div>
              <div style={{ width: '20%' }}>Timestamp</div>
              <div style={{ width: '20%' }}>Actions</div>
            </div>

            <div className={classes.ListBodyContainer} style={{ height: "28vh", overflowY: 'scroll', overflowX: "scroll", width: "100%" }}>
              {recentAuthenticationTableLoader ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}><Spin /></div> :
                recentAuthentication?.length === 0 || recentAuthentication?.length === undefined ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}><div>No data found</div></div> :
                  recentAuthentication?.map((record, id) => {
                    return (
                      <div className={classes.rowContainer} style={{ gap: "0.2rem", color: "#162432", fontSize: 15, fontWeight: 400, fontStyle: "Lato", }}>

                       <div style={{width: '30%', paddingLeft:15}}>
                       <div style={{  display:"flex", flexDirection:"column", alignItems:"flex-start" }}>
                          <Typography.Text style={{ width: "17vw", color: "#162432", fontSize: 15, }} ellipsis={{ tooltip: true }}>
                            {record?.name ? record?.name : "NA"}</Typography.Text>
                          <div >
                            <Typography.Text style={{ width: "17vw", color: "#162432", fontSize: 13, }} ellipsis={{ tooltip: true }}>
                              {record?.email ? record?.email : "NA"}</Typography.Text>
                              <div>
                              <Typography.Text style={{ width: "17vw" ,fontSize: 13}} ellipsis={{ tooltip: true }}>
                              {record?.phone ? record?.phone : "NA"}</Typography.Text>
                                </div>
                           
                          </div>
                        </div>
                        </div>
                        {/* <div style={{width:'20%',}}>
                          <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"flex-start"}}>
                          <Typography.Text style={{width:"12vw",color: "#162432", fontSize: 15,}} ellipsis={{tooltip:true}}>
                          {record?.email ? record?.email :"NA"}</Typography.Text>
                          <Typography.Text style={{width:"12vw"}} ellipsis={{tooltip:true}}>
                          {record?.phone ? record?.phone:"NA"}</Typography.Text>
                          </div>
                          </div> */}
                        <div style={{ width: '20%', }}>
                          <Typography.Text style={{ width: "9rem", color: "#162432", fontSize: 15, }} ellipsis={{ tooltip: true }}>
                            {record?.managerName ? record?.managerName : "NA"}</Typography.Text>
                        </div>
                        <div style={{ width: '20%', }}>
                          <Typography.Text style={{ width: "9rem", color: "#162432", fontSize: 15, }} ellipsis={{ tooltip: true }}>
                            {record?.timeStamp ? record?.timeStamp : "NA"}</Typography.Text>
                        </div>
                        <div style={{ width: '20%', }}>
                          {/* <span style={{color:"#39CA6C",}}>
                              Approved   
                            </span> */}
                          <img src={ApprovedIcons} alt='' />
                        </div>
                      </div>
                    )
                  })}
            </div>
          </div>
          :
          <div className={classes.ListBodyContainer} style={{ height: "32vh", overflowY: 'scroll', overflowX: "scroll", width: "100%", backgroundColor: "#F9F9F9", padding: "0.7rem" }}>
            {recentAuthenticationTableLoader ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}><Spin /></div> :
              recentAuthentication?.length === 0 || recentAuthentication?.length === undefined ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}><div>No data found</div></div> :
                recentAuthentication?.map((record, id) => {
                  return (
                    <div style={{ width: "100%", marginTop: "0.3rem", marginBottom: "1rem", borderRadius: 10, backgroundColor: "#FFFFFF", padding: "0.7rem 0.7rem" }}>
                      <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", }}>
                        <Typography.Text style={{ width: "20vw", fontSize: "20px", fontWeight: "550", }} ellipsis={{ tooltip: true }}>
                          {record?.name ? record?.name : "NA"}
                        </Typography.Text>
                        {/* <span style={{color:"#39CA6C",fontSize:"18px"}}>
                              Approved   
                          </span> */}
                        <img src={ApprovedIcons} alt='' />
                      </div>

                      <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0.5rem", alignItems: "center", }}>
                        <p style={{ fontSize: "19px", color: "#16243280", marginTop: "0.3rem" }}>Email: </p>
                        <p>
                          <Typography.Text style={{ width: "68vw", fontSize: 20, }} ellipsis={{ tooltip: true }}>
                            {record?.email ? record?.email : "NA"}
                          </Typography.Text>
                        </p>
                      </div>

                      <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0.5rem", }}>
                        <p style={{ fontSize: "19px", color: "#16243280" }}>Phone: </p>
                        <p style={{ fontSize: "19px" }}>{record?.phone ? record?.phone : "NA"}</p>
                      </div>

                      <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0.5rem", }}>
                        <p style={{ fontSize: "19px", color: "#16243280" }}>Manager: </p>
                        <p style={{ fontSize: "19px" }}>{record?.managerName ? record?.managerName : "NA"}</p>
                      </div>

                      <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0.4rem", alignItems: "center" }}>
                        <p style={{ fontSize: "19px", color: "#16243280" }}>Timestamp: </p>
                        {/* <p style={{fontSize:"19px"}}>{record?.timeStamp ? record?.timeStamp :"NA"}</p> */}
                        <p><Typography.Text style={{ width: "60vw", fontSize: 20, fontWeight: "650px" }} ellipsis={{ tooltip: true }}>
                          {record?.timeStamp ? record?.timeStamp : "NA"}</Typography.Text></p>
                      </div>

                    </div>
                  )
                })}
          </div>
        }
        {/* {width > 700 && */}
        {/* <div> */}
        {recentAuthentication?.length === undefined ? "" :
          <Pagination position="bottomRight" size="small" style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem", marginRight: "0.2rem", fontSize: width < 700 && 15, }}
            current={pageCurrentRecently}
            pageSize={pageSizeRecently}
            total={recentAuthenticationMeta?.totalItems
              ? recentAuthenticationMeta?.totalItems
              : 0}
            pageSizeOptions={[10, 20, 30, 50]}
            showSizeChanger={true}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            onChange={handlePageChange}
            onShowSizeChange={handlePageChange}
          />
        }
        {/* </div> */}
        {/* } */}
      </div>

      <ModalCompo closableRight={true}
        open={approverModal?.flag}
        onCancel={() => {
          setApproverModal({ flag: false });
        }}
        closable={false}
        centered
        allowClear={true}
        contentStyle={{ width: width > 700 && "65vw", height: width > 700 && "37vh", padding: "15px", top: "26vh", borderRadius: "8px", margin: width < 700 && 15 }}
      >
        <div className={classes.confirmationModalMainDiv}>
          <div
            style={{
              color: "#39CA6C",
              font: "normal 600 19px/36px Poppins",
              display: "flex", justifyContent: "center", flexDirection: "row",
              gap: "0.3rem",
              // width:"70%",
              marginTop: "-2rem", marginBottom: "0.3rem"
            }}
          >
            <img src={approveIcon} /> <h4>Approve</h4>
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "1rem" }}>
            <div style={{ border: "1px solid #F1F1F1", width: "100%", }}></div>
            <div
              style={{ font: "normal 700 20px/50px Poppins", color: "#162432" }}
            >
              <h5>Are you sure you want to approve <span style={{ color: "#0086FF", }}>{`"${approverModal?.name}"`}</span> authentication?</h5>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItem: "center",
                gap: "1rem",
                font: "normal 500 17px/36px Poppins",
                color: "#162432",
              }}
            >
              <div
                style={{ color: "#0086FF", font: "normal 500 24px/36px Poppins" }}
              ></div>
              <div> </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItem: "center",
                gap: "1.5rem", marginTop: "0.4rem"
              }}
            >
              <ButtonCompo
                title={"Yes"}
                style={{ backgroundColor: "#0086FF", color: "#fff", width: "5.5rem", height: "2rem" }}
                onClick={() => {
                  approveHandler({ id: approverModal?.id });
                }}
              ></ButtonCompo>
              <ButtonCompo
                title={"No"}
                style={{ border: "1px solid #0086FF", color: "#0086FF", width: "5.5rem", height: "2rem" }}
                onClick={() => {
                  setApproverModal({ flag: false });
                }}
              ></ButtonCompo>
            </div>
          </div>
        </div>
      </ModalCompo>

      <ModalCompo closableRight={true}
        open={rejectModal?.flag}
        onCancel={() => {
          setRejectModal({ flag: false })
        }}
        closable={false}
        contentStyle={{ width: width > 700 && "48vw", height: width > 700 && "50vh", padding: "13px", top: "23vh", borderRadius: "8px", margin: width < 700 && 15 }}
      >
        <div className={classes.confirmationModalMainDiv}>
          <div>
            <h3 style={{ color: "#39CA6C", fontSize: "18px", width: "70%", marginTop: "-1.8rem", marginBottom: "0.4rem" }}>
              Last approved requests</h3>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              background: "#F1F1F1",
            }}
          />
          <div
            style={{
              height: "30vh",
              display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex", justifyContent: "flex-start",
                flexDirection: "column",
                paddingTop: "1rem", paddingLeft: "0.2rem",
              }}
            >{console.log("ssss", rejectModal?.record?.user?.userAuthentications)}
              {
                rejectModal?.record?.user?.userAuthentications[0]?.approvedAt === null ? (
                  <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                    No Request
                  </div>
                ) : (
                  <div style={{ height: "100%", overflowY: "scroll" }}>
                    {rejectModal?.record?.user?.userAuthentications?.map((item, index) => {
                      if (item.approvedAt !== null) {
                        return (
                          <h5
                            key={item.id}
                            style={{
                              marginTop: "0.6rem",
                              fontSize: "15px",
                              height: "2.2rem",
                              backgroundColor: "#F9F9F9",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {moment(item.approvedAt).format("DD-MMM-YYYY  &  hh:mm A")}
                          </h5>
                        );
                      }
                      return null;
                    })}
                  </div>
                )
              }
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: "1rem", justifyContent: "flex-end", width: "100%", marginTop: "0.5rem" }}>
            <ButtonCompo
              title={"OK"}
              style={{
                border: "1px solid #0086FF",
                width: "6rem",
                height: "2.1rem",
                backgroundColor: "#0086FF",
                color: "#FFFFFF",
                marginTop: "0.7rem", borderRadius: "7px"
              }}
              onClick={() => {
                setRejectModal({ flag: false });
              }}
            ></ButtonCompo>
            {/* <ButtonCompo
            title={"Cancel"}
            style={{
              border: "1px solid #0086FF",
              width: "6rem",
              height:"2.1rem",
              color:"#0086FF",
              marginTop:"0.7rem",borderRadius:"7px"
            }}
            onClick={() => {
              setRejectModal({ flag: false });
            }}
          ></ButtonCompo> */}
          </div>
        </div>
      </ModalCompo>
    </div>
  );
};

export default Authentication;
