import NoDataImg from '../../../assets/nodata.svg';
export const NoData = ({height}) =>{
    return(
      <>
      <div style={{height:height,width:"100%", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:'column', }}>
        <img src={NoDataImg}></img>
        <div>No Data</div>
      </div>
      </>
    )
  }