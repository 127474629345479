import React, { useState } from "react";
import classes from './MagazineModal.module.css';
import { Modal } from "antd";
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";
import HTMLFlipBook from "react-pageflip";
import { FullscreenOutlined } from "@ant-design/icons";
import './MagazineModal.css';
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs?.version}/pdf.worker.js`;

const MagazineModal=({setMagazineModal,magazineModal,magazine})=>{
    const [pageNum, setPageNum] = useState();
    const [num, setNum] = useState(1);
    const [fullscreen, setFullscreen] = useState(false);
    const [magazineId, setMagazineId] = useState();
    const {width}=useWindowDimensions();

    const handleFullScreenOpen = () => {
        setFullscreen(false);
    }
    const handleFullScreenCancel = () => {
        setFullscreen(false);
        console.log('close');
        setNum(1);
    }
   

    const openFullScreenModal = () => {
        console.log("hello");
        setFullscreen(true);
        setMagazineModal(false);
        setNum(0);
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        // console.log(numPages)
        setPageNum(numPages)
    }

    const Page = React.forwardRef(({ pageNumber }, ref) => {
        return (
            <div ref={ref}>
                <ReactPdfPage pageNumber={pageNumber} width={num == 1 ? 350 : 580} />
            </div>
        );
    });

    const PageCover = React.forwardRef((props, ref) => {
        return (
            <div className="page page-cover" ref={ref} data-density="hard">
                <div className="page-content">
                    <h2>{props.children}</h2>
                </div>
            </div>
        );
    });
    return(
        <>
        
        {magazineModal&&
        <Modal className='emagazineModal'
                footer={false}
                width={750}
                style={{
                    position: 'fixed',
                    top: '50vh',
                    height: "530px",
                    left: '50vw',
                    translate: '-50% -50%',
                }}
                open={magazineModal} onOk={()=>{setMagazineModal(false)}} onCancel={()=>{setMagazineModal(false)}}
            >
                <div style={{marginTop:" 4vmin"}}>
                    <div className="frontmagazine" style={{ position: 'fixed', top: '200px', left: '80px', font: 'normal normal bold 20px/25px Poppins',color:"white" }}>Click on the page <p>to open the magazine</p></div>
                    {/* <div className="backmagazine" style={{position:'fixed', top:'200px',left:'480px', font:'normal normal bold 30px/35px Poppins'}}>Thank You<p>for reading<p>our magazine</p></p></div> */}
                  
                 
                    <div className='flipbookConatiner' >
                       
                        {magazine&&magazine?.length>0&&<Document
                            file={{
                                url: magazine?.[0]?.emagazine
                            }}
                             onLoadSuccess={onDocumentLoadSuccess}
                        >
                            <HTMLFlipBook
                                className="Flipbookcontent"
                                width={num == 1 ? 350 : 580} height={num == 1 ? 500 : 650}
                                showCover={true}>
                                {Array.from(Array(pageNum).keys()).map(i => {
                                    if (i === 0) {
                                        return <PageCover><Page pageNumber={i + 1} /> </PageCover>
                                    } else if (i == pageNum - 1) {
                                        return <PageCover>
                                            <Page pageNumber={i + 1} />
                                        </PageCover>
                                    } else {
                                        return <Page pageNumber={i + 1} />
                                    }
                                })}
                            </HTMLFlipBook>
                        </Document>}
                    </div>
                    {width > 700 ?
                    <div className="viewFullScreen" style={{ fontFamily: 'poppins', fontWeight: '600',fontSize:'20px !important',marginTop:"2vmin",display:"flex",justifyContent:"center",alignItems:"center" }} ><FullscreenOutlined  onClick={openFullScreenModal}/> </div>
                    : "" }
                </div>
            </Modal>}

            {fullscreen&&
            <Modal className='ModalFullview'
                    maskClosable={false}
                    width={'100vw'}
                    bodyStyle={{ height: '100vh',overflowY:"auto",overflowX:'auto' ,background: 'linear-gradient(45deg, #0086FF, #fff) ' }}
                    style={{
                       top: "1%",
                    }}
                    footer={false}
                    visible={fullscreen} onOk={handleFullScreenOpen} onCancel={handleFullScreenCancel}>
                    <div>
                        <div className="frontmagazine" style={{ position: "absolute", top:"300px",left: '200px', font: ' bold 25px/30px Poppins',color:"white", }}>Click on the page <p>to open the magazine</p></div>
                        <Document
                            file={{
                                url: magazine?.[0]?.emagazine
                            }}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            <HTMLFlipBook  className="FlipbookcontentFullView"
                                style={{ right:0,marginTop:'-20px',height:"fit-content !important", background: 'linear-gradient(45deg, #0086FF, #fff) '}}
                               
                                width={num == 1 ? 350 : (window.innerWidth-100)/2} 
                                height={num == 1 ? 500 : (window.innerHeight)}
                                showCover={true}>
                                {Array.from(Array(pageNum).keys()).map(i => {
                                    if (i === 0) {
                                        return <PageCover><Page pageNumber={i + 1} /> </PageCover>
                                    } else if (i == pageNum - 1) {
                                        return <PageCover>
                                            <Page pageNumber={i + 1} />
                                        </PageCover>
                                    } else {
                                        return <Page pageNumber={i + 1} />
                                    }
                                })}
                            </HTMLFlipBook>
                        </Document>
                    </div>
                </Modal>}
                </>
    )
}
export default MagazineModal;