import { Document, Page, View,StyleSheet,Text, pdf } from "@react-pdf/renderer";
import moment from "moment";
import { KeyIsNotEmpty } from "../formData";
import Notification from "../../../../../services/Notification";

function PDFDesign(data){
 
  const styles = StyleSheet.create({
    PageStyle:{
      padding:'36x 24px',
    },
    container: {
      flex: 1,
      backgroundColor: '#fff',
    },
    detailContainer: {
      border:'1px solid #dfdfdf',
      padding:'10px',
      marginBottom:'16px',
    },
    header: {
      marginBottom: 10,
    },
    gridContainer: {
    display:'flex',
    flexDirection:'row',
    width:'100%',
    },
    gridContainerCol:{
      display:'flex',
      flexDirection:'column',
      gap:'16px',
      width:'100%',
      justifyContent:'space-between',
    },
    gridItem: {
      display:'flex',
      flexDirection:'column',
      gap:'8px',
      width:'100%',
    },
    name: {
      fontSize:'18px',
      color:'#16243280'
    },
    detail: {
      fontSize:'18px',
      color: '#162432',
    },
    headerText: {
      fontSize: 20,
      // fontWeight: 700,
      color: '#0086ff',
    },
  });
    return(
        <Document>
            <Page size={'A4'} style={styles.page}>
              <View style={styles.PageStyle}>                
                <View style={styles.detailContainer}>
                  <View style={styles.header}>
                    <Text style={styles.headerText}>Mandatory Details</Text>
                  </View>
                  <View style={styles.gridContainer}>
                    <View style={styles.gridContainerCol}>
                      <View style={styles.gridItem} >
                          <Text style={styles.name}>{"Name"}</Text>
                          <Text style={styles.detail}>{data?.profile?.associateProfile?.associateName}</Text>
                        </View>
                        <View style={styles.gridItem} >
                          <Text style={styles.name}>{"Email Id"}</Text>
                          <Text style={styles.detail}>{data?.profile?.associateProfile?.email}</Text>
                        </View> 
                        <View style={styles.gridItem} >
                          <Text style={styles.name}>{"Phone No."}</Text>
                          <Text style={styles.detail}>{data?.profile?.associateProfile?.phone}</Text>
                        </View>
                    </View>
                    <View style={styles.gridContainerCol}>
                        <View style={styles.gridItem} >
                            <Text style={styles.name}>{"Employee ID"}</Text>
                            <Text style={styles.detail}>{data?.profile?.associateProfile?.uid}</Text>
                          </View>
                          <View style={styles.gridItem} >
                            <Text style={styles.name}>{"Joining Date"}</Text>
                            <Text style={styles.detail}>{moment(data?.profile?.associateProfile?.joined).format('DD-MMM-YYYY')}</Text>
                          </View>
                          <View style={styles.gridItem} >
                            <Text style={styles.name}>{"Designation"}</Text>
                            <Text style={styles.detail}>{data?.profile?.associateProfile?.designation}</Text>
                          </View> 
                    </View>
                  </View> 
                </View>

                <View style={styles.detailContainer}>
                  <View style={styles.header}>
                    <Text style={styles.headerText}>Basic Details</Text>
                  </View>
                  <View style={styles.gridContainer}>
                    
                  <View style={styles.gridContainerCol}>
                      <View style={styles.gridItem} >
                        <Text style={styles.name}>{'Personal Email Id'}</Text>
                        <Text style={styles.detail}>{data?.profile?.basicInformation?.personalEmail}</Text>
                      </View>
                      <View style={styles.gridItem} >
                        <Text style={styles.name}>{'Blood Group'}</Text>
                        <Text style={styles.detail}>{data?.profile?.basicInformation?.bloodGroup}</Text>
                      </View>
                      <View style={styles.gridItem} >
                        <Text style={styles.name}>{'Wish me On'}</Text>
                        <Text style={styles.detail}>{moment(data?.profile?.basicInformation?.dobActual).format('DD-MMM-YYYY')}</Text>
                      </View>
                      <View style={styles.gridItem} >
                        <Text style={styles.name}>{'Marital Status'}</Text>
                        <Text style={styles.detail}>{data?.profile?.basicInformation?.maritalStatus}</Text>
                      </View>
                    
                  </View>



                  <View style={styles.gridContainerCol}>
                      
                      <View style={styles.gridItem} >
                        <Text style={styles.name}>{'Secondary Phone No.'}</Text>
                        <Text style={styles.detail}>{KeyIsNotEmpty(data?.profile?.basicInformation?.secondaryPhone)?data?.profile?.basicInformation?.secondaryPhone:'-'}</Text>
                      </View>
                      
                      <View style={styles.gridItem} >
                        <Text style={styles.name}>{'DOB on Document'}</Text>
                        <Text style={styles.detail}>{moment(data?.profile?.basicInformation?.dob).format('DD-MMM-YYYY')}</Text>
                      </View>
                      
                      <View style={styles.gridItem} >
                        <Text style={styles.name}>{'Gender'}</Text>
                        <Text style={styles.detail}>{data?.profile?.basicInformation?.gender}</Text>
                      </View>
                     
                      <View style={styles.gridItem} >
                        <Text style={styles.name}>{'Category'}</Text>
                        <Text style={styles.detail}>{data?.profile?.basicInformation?.category}</Text>
                      </View>
                    </View>
                  </View>
                  <View style={[styles.gridItem,{marginTop:'16px'}]} >
                        <Text style={styles.name}>{'Children'}</Text>
                        <Text style={styles.detail}>{KeyIsNotEmpty(data?.profile?.basicInformation?.children)?data?.profile?.basicInformation?.children:'-'}</Text>
                      </View>
                </View>
              
                <View style={styles.detailContainer}>
                  <View style={styles.header}>
                    <Text style={styles.headerText}>Bank Details</Text>
                  </View>
                  <View style={styles.gridContainer}>
                      <View style={styles.gridItem} >
                          <Text style={styles.name}>{"Name as per Acc."}</Text>
                          <Text style={styles.detail}>{KeyIsNotEmpty(data?.profile?.bankAccountDetails?.name)?data?.profile?.bankAccountDetails?.name:'-'}</Text>
                        </View>
                        <View style={styles.gridItem} >
                          <Text style={styles.name}>{"Account No."}</Text>
                          <Text style={styles.detail}>{KeyIsNotEmpty(data?.profile?.bankAccountDetails?.accountNumber)?data?.profile?.bankAccountDetails?.accountNumber:'-'}</Text>
                        </View> 
                        <View style={styles.gridItem} >
                          <Text style={styles.name}>{"IFSC code"}</Text>
                          <Text style={styles.detail}>{KeyIsNotEmpty(data?.profile?.bankAccountDetails?.IFSC)?data?.profile?.bankAccountDetails?.IFSC:'-'}</Text>
                        </View>
                  </View> 
                </View>
                </View>
            </Page>
            <Page>
              <View style={styles.PageStyle}>
              <View style={styles.detailContainer}>
                <View style={styles.header}>
                  <Text style={styles.headerText}>Address Information</Text>
                </View>
                <View style={styles.gridContainer}>
                    <View style={styles.gridContainerCol}>
                        <View style={{fontSize:'18px',color:'black',margin:'1rem,0rem'}}><Text>Current Address:</Text></View>
                      <View style={styles.gridItem} >
                          <Text style={styles.name}>{"Address"}</Text>
                          <Text style={[styles.detail, {padding:'12px'}]}>{(KeyIsNotEmpty(data?.profile?.addressInformation?.current?.addressLine1)?data?.profile?.addressInformation?.current?.addressLine1:'') +", "+(KeyIsNotEmpty(data?.profile?.addressInformation?.current?.addressLine2)?data?.profile?.addressInformation?.current?.addressLine2:'')}</Text>
                        </View>
                        <View style={styles.gridItem} >
                            <Text style={styles.name}>{"City"}</Text>
                            <Text style={styles.detail}>{data?.profile?.addressInformation?.current?.city}</Text>
                        </View>
                        <View style={styles.gridItem} >
                          <Text style={styles.name}>{"State"}</Text>
                          <Text style={styles.detail}>{data?.profile?.addressInformation?.current?.state}</Text>
                        </View> 
                        <View style={styles.gridItem} >
                          <Text style={styles.name}>{"Pincode"}</Text>
                          <Text style={styles.detail}>{data?.profile?.addressInformation?.current?.pincode}</Text>
                        </View>
                    </View>
                    <View style={styles.gridContainerCol}>
                    <View style={{fontSize:'18px',color:'black',margin:'1rem 0rem'}}><Text>Permanent Address:</Text></View>
                      <View style={styles.gridItem} >
                          <Text style={styles.name}>{"Address"}</Text>
                          <Text style={[styles.detail, {padding:'12px'}]}>{(KeyIsNotEmpty(data?.profile?.addressInformation?.permanent?.addressLine1)?data?.profile?.addressInformation?.permanent?.addressLine1:'')+", "+(KeyIsNotEmpty(data?.profile?.addressInformation?.permanent?.addressLine2)?data?.profile?.addressInformation?.permanent?.addressLine2:'')}</Text>
                        </View>
                        <View style={styles.gridItem} >
                            <Text style={styles.name}>{"City"}</Text>
                            <Text style={styles.detail}>{data?.profile?.addressInformation?.permanent?.city}</Text>
                        </View>
                        <View style={styles.gridItem} >
                          <Text style={styles.name}>{"State"}</Text>
                          <Text style={styles.detail}>{data?.profile?.addressInformation?.permanent?.state}</Text>
                        </View> 
                        <View style={styles.gridItem} >
                          <Text style={styles.name}>{"Pincode"}</Text>
                          <Text style={styles.detail}>{data?.profile?.addressInformation?.permanent?.pincode}</Text>
                        </View>
                    </View>
                  </View> 

              </View>
              <View style={styles.detailContainer}>
                  <View style={styles.header}>
                    <Text style={styles.headerText}>Document Details</Text>
                  </View>
                  <View style={styles.gridContainer}>
                    <View style={styles.gridContainerCol}>
                      <View style={styles.gridItem} >
                          <Text style={styles.name}>{"PAN No."}</Text>
                          <Text style={styles.detail}>{KeyIsNotEmpty(data?.documents?.pan?.panNumber)?data?.documents?.pan?.panNumber:'NA'}</Text>
                        </View>
                        <View style={styles.gridItem} >
                          <Text style={styles.name}>{"Aadhar No."}</Text>
                          <Text style={styles.detail}>{KeyIsNotEmpty(data?.documents?.aadhar?.aadharNumber)?data?.documents?.aadhar?.aadharNumber:'NA'}</Text>
                        </View> 
                        <View style={styles.gridItem} >
                          <Text style={styles.name}>{"Driving License"}</Text>
                          <Text style={styles.detail}>{KeyIsNotEmpty(data?.documents?.dl?.dlNumber)?data?.documents?.dl?.dlNumber:'NA'}</Text>
                        </View>
                        <View style={styles.gridItem} >
                          <Text style={styles.name}>{"PF Acc. No."}</Text>
                          <Text style={styles.detail}>{KeyIsNotEmpty(data?.documents?.pf?.pfNumber)?data?.documents?.pf?.pfNumber:'NA'}</Text>
                        </View>
                    </View>
                    <View style={styles.gridContainerCol}>
                        <View style={styles.gridItem} >
                            <Text style={styles.name}>{"Name as per PAN"}</Text>
                            <Text style={styles.detail}>{KeyIsNotEmpty(data?.documents?.pan?.panName)?data?.documents?.pan?.panName:'NA'}</Text>
                          </View>
                          <View style={styles.gridItem} >
                            <Text style={styles.name}>{"Name as per Aadhar"}</Text>
                            <Text style={styles.detail}>{KeyIsNotEmpty(data?.documents?.aadhar?.aadharName)?data?.documents?.aadhar?.aadharName:'NA'}</Text>
                          </View>
                          <View style={styles.gridItem} >
                            <Text style={styles.name}>{"Voter Id"}</Text>
                            <Text style={styles.detail}>{KeyIsNotEmpty(data?.documents?.voterId?.voterIdNumber)?data?.documents?.voterId?.voterIdNumber:'NA'}</Text>
                          </View>
                          <View style={styles.gridItem} >
                            <Text style={styles.name}>{"UAN No."}</Text>
                            <Text style={styles.detail}>{KeyIsNotEmpty(data?.documents?.uan?.uanNumber)?data?.documents?.uan?.uanNumber:'NA'}</Text>
                          </View> 
                    </View>
                  </View>
                  <View style={[styles.gridItem,{marginTop:'16px'}]} >
                        <Text style={styles.name}>{'Passport No.'}</Text>
                        <Text style={styles.detail}>{KeyIsNotEmpty(data?.documents?.passport?.passportNumber)?data?.documents?.passport?.passportNumber:'NA'}</Text>
                  </View>
              </View>
             
              </View>
            </Page>
            <Page>
              <View style={styles.PageStyle}>
                <View style={styles.detailContainer}>
                  <View style={styles.header}>
                    <Text style={styles.headerText}>Emergency Contact</Text>
                  </View>
                  <View style={styles.gridContainer}>
                  
                    <View style={styles.gridContainerCol}>
                      <View style={styles.gridItem} >
                          <Text style={styles.name}>{"Name"}</Text>
                          <Text style={styles.detail}>{(KeyIsNotEmpty(data?.profile?.emergencyContact) && KeyIsNotEmpty(data?.profile?.emergencyContact[0]?.name))?data?.profile?.emergencyContact[0]?.name:'-'}</Text>
                        </View>
                        <View style={styles.gridItem} >
                          <Text style={styles.name}>{"Phone No."}</Text>
                          <Text style={styles.detail}>{(KeyIsNotEmpty(data?.profile?.emergencyContact) && KeyIsNotEmpty(data?.profile?.emergencyContact[0]?.contact))?data?.profile?.emergencyContact[0]?.contact:'-'}</Text>
                        </View> 
                        <View style={styles.gridItem} >
                          <Text style={styles.name}>{"Relation"}</Text>
                          <Text style={styles.detail}>{(KeyIsNotEmpty(data?.profile?.emergencyContact) && KeyIsNotEmpty(data?.profile?.emergencyContact[0]?.relation))?data?.profile?.emergencyContact[0]?.relation:'-'}</Text>
                        </View>
                    </View>
                    <View style={styles.gridContainerCol}>
                      <View style={styles.gridItem} >
                          <Text style={styles.name}>{"Name"}</Text>
                          <Text style={styles.detail}>{(KeyIsNotEmpty(data?.profile?.emergencyContact) && KeyIsNotEmpty(data?.profile?.emergencyContact[1]?.name))?data?.profile?.emergencyContact[1]?.name:'-'}</Text>
                        </View>
                        <View style={styles.gridItem} >
                          <Text style={styles.name}>{"Phone No."}</Text>
                          <Text style={styles.detail}>{(KeyIsNotEmpty(data?.profile?.emergencyContact) && KeyIsNotEmpty(data?.profile?.emergencyContact[1]?.contact))?data?.profile?.emergencyContact[1]?.contact:'-'}</Text>
                        </View> 
                        <View style={styles.gridItem} >
                          <Text style={styles.name}>{"Relation"}</Text>
                          <Text style={styles.detail}>{(KeyIsNotEmpty(data?.profile?.emergencyContact) && KeyIsNotEmpty(data?.profile?.emergencyContact[1]?.relation))?data?.profile?.emergencyContact[1]?.relation:'-'}</Text>
                        </View>
                    </View>
                  </View> 
                </View>
                
                <View style={styles.detailContainer}>
                  <View style={styles.header}>
                    <Text style={styles.headerText}>Academic Details</Text>
                  </View>
                  {data?.academics?.academicsData?.map((val,id)=>{
                    return(
                      <View style={[styles.gridContainer,{paddingBottom:'16px'}]}>
                      <View style={styles.gridItem} >
                          <Text style={styles.name}>{"Qualification"}</Text>
                          <Text style={styles.detail}>{KeyIsNotEmpty(val?.qualification)?val?.qualification:'---'}</Text>
                        </View>
                        <View style={styles.gridItem} >
                          <Text style={styles.name}>{"Year of passing"}</Text>
                          <Text style={styles.detail}>{KeyIsNotEmpty(val?.yearofPassing)?val?.yearofPassing:'---'}</Text>
                        </View> 
                        <View style={styles.gridItem} >
                          <Text style={styles.name}>{"Grade/Percentage"}</Text>
                          <Text style={styles.detail}>{KeyIsNotEmpty(val?.gradePercentage)?val?.gradePercentage:'---'}</Text>
                        </View>
                      </View>
                    )
                  })}
              </View>
              <View style={styles.detailContainer}>
                  <View style={styles.header}>
                    <Text style={styles.headerText}>Work Experience</Text>
                  </View>
                  <View style={{paddingBottom:'24px'}}><Text>Total Experience: {data?.workExperience?.type==="Fresher"?"Fresher":data?.experience}</Text></View>
                  {data?.workExperience?.type!=="Fresher"?data?.workExperience?.experienced?.map((val,id)=>{
                    return(
                      <View style={[styles.gridContainer,{paddingBottom:'16px'}]}>
                      <View style={styles.gridItem} >
                          <Text style={styles.name}>{"Company"}</Text>
                          <Text style={styles.detail}>{KeyIsNotEmpty(val?.companyName)?val?.companyName:'---'}</Text>
                        </View>
                        <View style={styles.gridItem} >
                          <Text style={styles.name}>{"Date of joining"}</Text>
                          <Text style={styles.detail}>{KeyIsNotEmpty(val?.startDate)?moment(val?.startDate).format('DD-MMM-YYYY'):'---'}</Text>
                        </View> 
                        <View style={styles.gridItem} >
                          <Text style={styles.name}>{"Date of exit"}</Text>
                          <Text style={styles.detail}>{KeyIsNotEmpty(val?.endDate)?moment(val?.endDate).format('DD-MMM-YYYY'):'---'}</Text>
                        </View>
                      </View>
                    )
                  }):''}
              </View>
              </View>
            </Page>
            <Page>
              <View style={styles.PageStyle}>
              
             
              <View style={styles.detailContainer}>
                  <View style={styles.header}>
                    <Text style={styles.headerText}>Skills</Text>
                  </View>
                  <View style={[styles.name, {paddingBottom:'24px'}]}><Text>{data?.workExperience?.skills}</Text></View>
                 
              </View>
              </View>
            </Page>
        </Document>
    )
}

// export default PDFDesign;
export const generatePdfBlob= async( data)=>{
    console.log(222,data)
    if(data){
    const docBlob=await pdf(PDFDesign(data)).toBlob();
    console.log(2223333)
    
    // <PDFDesign data={data} />;
    // const blob = new Blob([docBlob], { type: 'application/pdf' });
    // console.log('555',blob)
  return await docBlob;
    }
    else{
      console.log('error in sending pdf to mail','this data',data)
      Notification.error('Error!','Network problem please try after some time')
      return ;
    }
}