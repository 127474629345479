import React, {  } from "react";
import { Button, Table, Collapse } from "antd";

import MinusIcon from "../../../../../assets/Appraisal/minusIconBlack.svg";
import PluseIcon from "../../../../../assets/Appraisal/pluseIconBlack.svg";

import "../../styles/tableHeader.css";
import EditIcon from "../../../../../assets/Appraisal/editIcon.svg";
import AssociateTable from "../../../components/Table/AssociateTable";
const { Panel } = Collapse;

const Department = ({
    setActiveKey,
activeKey,
updateShowEditModal,
updateDetails,
depList,
loader,
}) => {

    const handleCollapse = (e) => {
      setActiveKey(activeKey === e ? null : e);
      // getTeamList({departmentId:e},(res)=>{teamData(res)})
    };

    const columns = [
      {
        title: (
          <div
            style={{
              fontSize: "0.95rem",
              fontWeight: "bold",
              color: "#080808",
              display: "flex",
              justifyContent: "flex-start",
              width:"100%",
              paddingLeft:"1rem",
            }}
          >
            Team Name
          </div>
        ),
        dataIndex: "name",
        key: "name",
        width:"35%",
        render: (val, row) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "0.95rem",
                fontWeight: "500",
                paddingLeft:"1.2rem"
              }}
            >
              {row?.name}
            </div>
          );
        },
      },
      {
        title: (
          <div
            style={{
              fontSize: "0.95rem",
              fontWeight: "bold",
              color: "#080808",
              display: "flex",
              justifyContent: "flex-start",
              width:"100%"
            }}
          >
            Manager Name
          </div>
        ),
        dataIndex: "manager",
        key: "age",
        width:"35%",
        render: (val, row) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "0.95rem",
                fontWeight: "500",
              }}
            >
              {row?.manager}
            </div>
          );
        },
      },
      {
        title: (
          <div
            style={{
              fontSize: "0.95rem",
              fontWeight: "bold",
              color: "#080808",
              display: "flex",
              justifyContent: "flex-start",
              width:"100%",
            }}
          >
            Actions
          </div>
        ),
        dataIndex: "",
        key: "address",
        render: (val, row) => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                shape="circle"
                style={{ border: "none", backgroundColor: "#FAFAFA" }}
                onClick={() => {
                  updateShowEditModal(true);
                  updateDetails(row);
                }}
              >
                <img src={EditIcon} style={{ width: "1rem", height: "1rem" }} />
              </Button>
            </div>
          );
        },
      },
    ];

    return (
      <div
        style={{
          width: "100%",
          padding: "1rem",
          height: "65vh",
          overflowY: "scroll",
          // border:'1px solid blue',
          padding: "1rem",
        }}
      >
        {depList &&
          depList.map((content) => (
            <Collapse
              key={content?.id}
              style={{
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: "10px",
                marginBottom: "1rem",
                backgroundColor: "#FDFDFD",
              }}
              expandIconPosition="end"
              expandIcon={(e) => {
                return <img src={!e.isActive ? PluseIcon : MinusIcon} />;
              }}
              onChange={() => handleCollapse(content?.id)}
              activeKey={activeKey === content.id ? [content.id] : []}
            >
              <Panel
                key={content?.id}
                header={
                  <div style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Department:&nbsp;&nbsp;{content?.name}
                  </div>
                }
              >
                <div style={{ margin: "-0.9rem" }}>
                  <style>
                    {`
                      .ant-table-thead th {
                      background-color: red;   
                      }
                      
                    `}
                  </style>
                  {/* <Table
                    loading={loader}
                    columns={columns}
                    dataSource={content?.teams}
                  /> */}
                  <AssociateTable
                                   loading={loader}
                                  dataSource={content?.teams}
                                  columns={columns}
                                  rowKey={(record) => record?.id}
                                  //  meta={meta}
                                  style={{ width: "100%", maxHeight: "50vh" }}
                                  //  onChange= {(page, pageSize) => {
                                  //   dispatch(adminActions.updatePage({ page: page, pageSize: pageSize }));
                                  //  }}
                                />
                </div>
              </Panel>
            </Collapse>
          ))}
      </div>
    );
  };

  export default Department;