import { usePDFReaderStore } from "./store";

const PDFReader=()=>{
    const {pdfUrl}=usePDFReaderStore();
    console.log("pdfUrl",pdfUrl)
    return(
        <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"100vh",width:"100vw"}}>
      <object data={pdfUrl}
                width="100%"
                height="100%"> 
        </object> 
        </div>
    )
}
export default PDFReader;