import React, { useEffect, useState } from 'react'
import SearchCompo from '../../../../design/SearchCompo';
import ButtonCompo from '../../../../design/ButonCompo';
import { Checkbox, Pagination, Spin, Table } from 'antd';
import userIcon from "../../../../assets/profileIcon.svg";
import rejectHeaderIcon from "../../../../assets/reject_header_icon.svg";
import approveHeaderIcon from "../../../../assets/approve_header_icon.svg";
import classes from "./D_A.module.css";
import ModalCompo from '../../../../design/ModalCompo';
import useApiStore from '../../../../services/store';
import { ApproveApi, RejectApi, getDepartmentData} from './DepartmentAction';
import { Typography } from 'antd/lib';

import ascIcon from "../../../../assets/AscendingSortIcon.svg";
import dscIcon from "../../../../assets/DescendingSortIcon.svg";
import moment from 'moment/moment';
function DepartmentApproval() {
  const [search,setSearch]=useState("");
  const [sort,setSort]=useState('ASC')
  const [loader]=useApiStore(state=>[state.loader]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
    showSizeChanger:true,
      current: 1,
      pageSize: 10,
    },
  });
  const [tableData,setTableData]=useState([]);
  const [toggle,setToggle]=useState(false);
  const [approveModal,setApproveModal]=useState({flag:false});
  const [rejectModal,setRejectModal]=useState({flag:false});
  const getRandomuserParams = (params) => ({
    limit: params.pagination?.pageSize,
    page: params.pagination?.current,
    
  });

const SearchHandler=(res)=>{

  let param={search:res,page:1,limit:tableParams.pagination.pageSize,uidFilter:sort}
  getDepartmentData(param,(res)=>{
    setTableData(res?.data);
    setTableParams({
      pagination: {
          current:1,
          pageSize:tableParams.pagination.pageSize,
          total:res?.meta?.totalItems,
      },
    });
  }) 
}
useEffect(()=>{
  let param={search:search,...getRandomuserParams(tableParams),uidFilter:sort}
  getDepartmentData(param,(res)=>{
    setTableData(res?.data);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
          total:res?.meta?.totalItems,
      },
    });
  })
},[tableParams.pagination.pageSize,tableParams.pagination.current, toggle,sort])

const handleTableChange = (page,pageSize ) => {
  setTableParams({
    pagination:{
      current:page,
      pageSize:pageSize,
    },         
  });
};
const onSelectChange = (id,checked) => {
  let tempArr=[]
      if(id==='all'){
        if(checked===true){
          tableData?.forEach((val)=>(tempArr.push(val?.id)))
        }
      }
      else{
        if(checked===true && !selectedRowKeys.includes(id)){
            
          tempArr= [...selectedRowKeys,id]
        }
        else if(checked===false && selectedRowKeys.includes(id)){
            tempArr=selectedRowKeys?.filter((val)=>(val!==id))
        }
      }

      console.log(tempArr);
  setSelectedRowKeys(tempArr);
};


  const approveHandler=(val)=>{
  console.log(val);
  if(val?.id==="all"){
  ApproveApi({approvalObject:selectedRowKeys},(res)=>{
    if(res===true){
      setToggle(!toggle);
      setApproveModal({flag:false});
      setSelectedRowKeys([])
    }
    console.log("all approve",res);
  })
}
else {
  ApproveApi({approvalObject:[val?.id]},(res)=>{
    console.log("single approve",res)
    if(res===true){
      setToggle(!toggle);
      setApproveModal({flag:false});
    }
  })
}


  }
  const rejectHandler=(val)=>{
    let param={userId:val?.id}
    RejectApi(param,(res)=>{
      console.log(res);
      if(res){
        setToggle(!toggle);
        setRejectModal({flag:false})
      }
    })
  }
  return (
    <div className={classes.DepartmentApprovalContainer} >
    <div className={classes.mainDiv} >
    
    <div className={classes.header}>
    <div style={{color:"#162432",font:"normal 600 20px/36px Poppins"}}> Department Approval Requests </div>
    <div style={{display:'flex',gap:'1rem',alignItems:'center',}}>
      {selectedRowKeys?.length!==0?<ButtonCompo title={"Approve"} type="text"  style={{color:'#fff',backgroundColor:'#0086FF',borderRadius:"5px",width: "8rem",height: "2.3rem"}} 
            onClick={()=>{ setApproveModal({flag:true,id:'all',name:"all"})}}/>:''}
        <SearchCompo style={{height:"2.3rem"}} setSearch={setSearch} onSearch={(res)=>{SearchHandler(res)}} />
          
    </div>
    </div>
    <div className={classes.WebViewList}  style={{background:'#f8f8f8',height:'80vh'}}>
                <div className={classes.ListContainer}>
                    <div style={{display:'flex',alignItems:'center',width:'100%'}}>
                    <div style={{width:'2%'}} > <Checkbox indeterminate={selectedRowKeys.length>0 && selectedRowKeys.length<tableData.length} checked={tableData?.length!==0?selectedRowKeys.length===tableData.length:false} onClick={(e)=>{onSelectChange('all',e.target.checked)}} /> </div> 
                    <div className={classes.ListHeader} style={{display:'flex',width:'100%'}}>
                    <div style={{width:'15%',justifyContent:'flex-start',paddingLeft:"1rem",}}>Emp. ID
                          {sort === 'ASC' ? <img src={ascIcon} alt='' onClick={()=>{setSort('DESC')}} 
                            style={{cursor:"pointer",marginLeft:"0.2rem"}} /> 
                            :<img src={dscIcon} alt='' onClick={()=>{setSort('ASC');}} style={{cursor:"pointer",marginLeft:"0.2rem"}}/>}
                          </div>
                          <div style={{width:'20%',justifyContent:'flex-start',}}>Associate Name</div>
                          
                          <div style={{width:'20%',justifyContent:'flex-start'}}> Contact Details</div>
                          <div style={{width:'15%',justifyContent:'flex-start'}}>Department</div>
                          <div style={{width:'15%',justifyContent:'flex-start'}}>Onboard date</div>
                          <div style={{width:'15%',justifyContent:'flex-start'}}>Assign date</div>
                          <div style={{width:'10%',justifyContent:'flex-start'}}>Action</div>
                    </div>
                    </div>
                    <div className={classes.ListScroll}>
                    <div className={classes.ListBodyContainer}>
                     
                     {loader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><Spin /></div>:
                     tableData?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><div>No data found!</div></div>:
                    tableData?.map((record,id)=>{
                        return(
                          <div style={{display:'flex',alignItems:'center'}}>
                          <div style={{width:'2%',}}> 
                          <Checkbox checked={selectedRowKeys.includes(record?.id)} onClick={(e)=>{onSelectChange(record?.id,e.target.checked)}} /> 
                          </div> 
                          <div className={classes.rowContainer}>
                          <div style={{width:'15%',justifyContent:'flex-start',paddingLeft:"0.5rem",}}>
                          <div style={{display:'flex',alignItems:'center',gap:'8px',flexDirection:'row',}}>
                              
                              <div className={classes.header4Font}>{record?.uid}</div>
                              
                            </div>
                          </div>
                          <div style={{width:'20%',justifyContent:'flex-start'}}>
                          <div style={{display:'flex',alignItems:'center',gap:'8px',flexDirection:'row',}}>
                              <img src={userIcon} style={{height:'30px',width:'30px'}}></img>
                              <div style={{display:'flex',justifyContent:'center',gap:'8px',flexDirection:'column',}}>        
                              <Typography.Text className={classes.header3Font} style={{maxWidth:"8vw",}} ellipsis={{tooltip:true}} >{record?.name}</Typography.Text>
                          
                              </div>
                            </div>
                          </div>
                         
                          <div style={{width:'20%',}}>
                          <div style={{display:'flex',gap:'8px',flexDirection:'column',width:"100%"}}>
                              <Typography.Text className={classes.header3Font} style={{maxWidth:"8vw",}} ellipsis={{tooltip:true}} >{record?.email}</Typography.Text>
                              <div className={classes.header3Font} >{record?.phone}</div>
                            </div>
                          </div>
                          <div style={{width:'15%',justifyContent:'center'}}>
                          <Typography.Text className={classes.header3Font} style={{maxWidth:"8vw",}} >{record?.department}</Typography.Text>
                          </div>
                          <div style={{width:'15%',}}>
                          <div className={classes.header3Font} >{moment(record?.onboardingDate).format('DD-MM-YYYY')}</div>
                          </div>
                          <div style={{width:'15%',}}>
                          <div className={classes.header3Font} >{moment(record?.assigned_date).format('DD-MM-YYYY')}</div>
                          </div>
                          <div style={{width:'10%',justifyContent:'flex-start'}}>
                       
                          <div className={`${classes.tableAction} ${selectedRowKeys?.length!==0?classes.disabled:''}`} >
                                    <svg onClick={()=>{setApproveModal({flag:true,name:record?.name,id:record?.id})}} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40" fill="none">
                                    <circle cx="20" cy="20" r="15" fill="#0DAC45" fill-opacity="0.25"/>
                                    <path d="M17.4955 23.9005L11.5604 17.9917L10 19.5975L17.4955 27L30 14.5511L28.4443 13L17.4955 23.9005Z" fill="#0DAC45"/>
                                    </svg>
                                    <svg onClick={()=>{setRejectModal({flag:true,name:record?.name,id:record?.id})}} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40" fill="none">
                                    <circle cx="20" cy="20" r="15" fill="#FF3434" fill-opacity="0.25"/>
                                    <path d="M11.7956 30L20 21.7956L28.2044 30L30 28.2044L21.7956 20L30 11.7956L28.2044 10L20 18.2044L11.7956 10L10 11.7956L18.2044 20L10 28.2044L11.7956 30Z" fill="#FF3434"/>
                                    </svg>
                          </div>
                          </div>
                          </div>
                          </div>
                        )
                      })}
                    </div>
                <div style={{display:'flex',justifyContent:'flex-end',width:'100%',paddingTop:'0.5rem'}} >
                      <Pagination 
                      onChange={handleTableChange}
                      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                      showSizeChanger={true}
                       {...tableParams.pagination}
                      /> 
                </div>
                </div>
                </div>
    </div>
    <div className={classes.MobViewList}>
    <div className={classes.ListScrollMob}>
              <div className={classes.ListContainerMob}>
              {loader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><Spin /></div>:
                     tableData?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><div>No data found !</div></div>:
                     tableData?.map((record,id)=>{

                        return(
                          <div className={classes.MobListBody}>
                             <div style={{display:'flex',justifyContent:'space-between',width:'100%',alignItems:'center',paddingBottom:'6px'}}>
                             <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%'}}>

                                <div style={{display:'flex',alignItems:'center',gap:'8px',flexDirection:'row',cursor:'pointer'}}>
                                    <img src={record?.profileImage?record?.profileImage:userIcon} alt='image' style={{height:'50px',width:'50px',borderRadius:'50%'}}></img>
                                    <div style={{display:'flex',justifyContent:'center',gap:'8px',flexDirection:'column'}}>        
                                    <Typography.Text className='header3Font' ellipsis={{tooltip:true}} style={{maxWidth:'250px'}}>{record?.name}</Typography.Text>
                                    <div className='header4Font'>{record?.uid}</div>
                                    </div>
                                    </div>
                                    <div>  
                                      <Checkbox checked={selectedRowKeys.includes(record?.id)} onClick={(e)=>{onSelectChange(record?.id,e.target.checked)}} /> 
                                    </div>
                                    </div>
                               
                                </div>
                                <div className={classes.listLine}>
                              <div className="header4Font">Email Id: </div>
                              <Typography.Text className="header3Font" ellipsis={{tooltip:true}} style={{maxWidth:'200px',font:'normal 400 16px/22px Lato'}} >
                                {record?.email}
                              </Typography.Text>
                            </div>
                            <div className={classes.listLine}>
                              <div className="header4Font">Phone No.: </div>
                              <div className="header3Font"> {record?.phone}</div>
                            </div>
                                <div className={classes.listLine}>
                                  <div className='header4Font'>Department: </div>
                                  <div>{record?.department}</div>
                                </div>
                                <div className={classes.listLine} style={{marginTop:'10px'}}>
                                  <div className='header4Font'>Action: </div>
                                  <div><div className={`${classes.tableAction} ${selectedRowKeys?.length!==0?classes.disabled:''}`} >
                                    <svg onClick={()=>{setApproveModal({flag:true,name:record?.name,id:record?.id})}} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                    <circle cx="20" cy="20" r="20" fill="#0DAC45" fill-opacity="0.25"/>
                                    <path d="M17.4955 23.9005L11.5604 17.9917L10 19.5975L17.4955 27L30 14.5511L28.4443 13L17.4955 23.9005Z" fill="#0DAC45"/>
                                    </svg>
                                    <svg onClick={()=>{setRejectModal({flag:true,name:record?.name,id:record?.id})}} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                    <circle cx="20" cy="20" r="20" fill="#FF3434" fill-opacity="0.25"/>
                                    <path d="M11.7956 30L20 21.7956L28.2044 30L30 28.2044L21.7956 20L30 11.7956L28.2044 10L20 18.2044L11.7956 10L10 11.7956L18.2044 20L10 28.2044L11.7956 30Z" fill="#FF3434"/>
                                    </svg>
                          </div></div>
                                </div>
                        </div>)
                      })  } 
                    </div> 
                      <div style={{display:'flex',justifyContent:'flex-end',width:'100%',}} >
                      <Pagination 
                       size="small"
                      onChange={handleTableChange}
                      showSizeChanger={true}
                      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                       {...tableParams.pagination}
                      />
                </div> 
                </div> 
              </div>
    </div>
    
   
    
    <ModalCompo open={approveModal?.flag} onCancel={()=>{setApproveModal({flag:false})}} closable={false} className={classes.approveModal} 
    closableRight={true} centered header={<div style={{color:'#39CA6C',font:'normal 600 20px/36px Poppins',display:'flex',justifyContent:'center',width:"100%",gap:"0.3rem"}}>
    <img src={approveHeaderIcon} />
    <div>Approve</div></div>}>

      <div className={classes.confirmationModalMainDiv} >
       <div style={{border:'1px solid #F1F1F1',width:'100%'}}></div>
       <div style={{font:"normal 500 16px/36px Poppins",color:'#162432'}}>Are you sure you want to approve 
       <span style={{color:'#0086FF',}}>{approveModal?.id==='all'?" all selected requests":` "${approveModal?.name}"`}</span> ?</div>
       <div style={{display:'flex',justifyContent:'center',alignItem:'center',gap:'1.5rem',margin:"1rem"}}>
        <ButtonCompo title={"Yes"} style={{backgroundColor:'#0086FF',color:'#fff',height:"2.1rem",width:"6rem" }} onClick={()=>{approveHandler({id:approveModal?.id})}}></ButtonCompo>
        <ButtonCompo title={"No"} style={{border:"1px solid #0086FF",color:"#0086FF",height:"2.1rem",width:"6rem"}} onClick={()=>{setApproveModal({flag:false})}}></ButtonCompo>
       </div>
      </div>
    </ModalCompo>
    <ModalCompo open={rejectModal?.flag} onCancel={()=>{setRejectModal({flag:false})}} closable={false}  className={classes.approveModal}  
     closableRight={true} centered header={<div style={{color:'#FF3434',font:'normal 600 20px/36px Poppins',display:'flex',justifyContent:'center',width:"100%",gap:"0.3rem"}}>
     <img src={rejectHeaderIcon} />
     <div>Reject</div></div>}>

          <div className={classes.confirmationModalMainDiv} >
          <div  style={{border:'1px solid #F1F1F1',width:'100%'}}></div>
          <div style={{font:"normal 500 16px/36px Poppins",color:'#162432'}}>Are you sure you want to reject the department approval for
          <span style={{color:'#0086FF',}}>{` "${rejectModal?.name}"`}?</span> </div>
          <div style={{display:'flex',justifyContent:'center',alignItem:'center',gap:'1.5rem',margin:"1rem"}}>
            <ButtonCompo title={"Yes"} style={{backgroundColor:'#0086FF',color:'#fff',height:"2.1rem",width:"6rem" }} onClick={()=>{rejectHandler({id:rejectModal?.id})}}></ButtonCompo>
            <ButtonCompo title={"No"} style={{border:"1px solid #0086FF",color:"#0086FF",height:"2.1rem",width:"6rem"}} onClick={()=>{setRejectModal({flag:false})}}></ButtonCompo>
          </div>
          </div>
    </ModalCompo>
    
    </div>
  )
}

export default DepartmentApproval