import React from "react";
import { useState } from "react";
import { Modal, Button } from "antd";
import "../Settings/errorModal.css";

const ErrorModal = ({errorModal, errorData, onClose}) => {
       
    return (
      <>
      <Modal
        title={<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Associate Leaves List</div>}
        centered
        open={errorModal}
        onCancel={onClose}
        width={"50%"}
        // bodyStyle={{ height: "65vh" }}
        footer={[
          <Button key="cancel" onClick={onClose}>
            Close
          </Button>,
          
        ]}
      >
       <div className="scrollable-table" style={{ maxHeight: '300px', overflowY: 'auto' }}> {/* Scrollable container */}
        <table style={{ width: '100%', borderCollapse: 'collapse', alignItems:"center" }}>
          <thead>
            <tr>
            <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Employee Id</th>
              <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Name</th>
              <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Date</th>
              <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Type</th>
            </tr>
          </thead>
          <tbody style={{alignItems:'center'}}>
            {errorData?.map((item, index) => (
              <tr key={index}>
                <td style={{ borderBottom: '1px solid #ddd', padding: '8px' , textAlign:'center'}}>{item?.uid}</td>
                <td style={{ borderBottom: '1px solid #ddd', padding: '8px' , textAlign:'center'}}>{item?.name}</td>
                <td style={{ borderBottom: '1px solid #ddd', padding: '8px' , textAlign:'center'}}>{item?.date}</td>
                <td style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign:'center' }}>{item?.leave}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </Modal>
  
      </>
     
    );
  };
export default ErrorModal;