import { create } from "zustand";

const useStore = create((set) => ({
  token:null,
  updateToken:(event)=>set(state=>({ token:event })),
  user:{},
  updateUser:(event)=>{
    event={...event,id:event?.resourceUser?.id}
    set(state=>({ user: event }))
  },
  newUser:false,
  updateNewUser:(event)=>set(state=>({ newUser: event })),

}));

export default useStore;
