import React, { useEffect, useState } from "react";
import { Card, Input, Rate, Radio, Button, Typography,  Upload, Slider,Tabs,Select, Pagination } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import profile_icon from './../../assets/Profile Icon.svg';
import editIcon from "../../assets/EditIconss.svg";
import classes from "./feedback.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "./store";
import Api from "../../services/Api";
import poweredBy_icon from '../../assets/poweredBy_icon.svg';
import CreateFeedbackModal from "./createFeedbackModal";
import SmileyIcon1 from "../../assets/SmileyIcon1.svg";
import SmileyIcon2 from "../../assets/SmileyIcon2.svg";
import SmileyIcon3 from "../../assets/SmileyIcon3.svg";
import SmileyIcon4 from "../../assets/SmileyIcon4.svg";
import SmileyIcon5 from "../../assets/SmileyIcon5.svg";
import dashboardIcon from "../../assets/Dashboard.svg";
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";
import { useHeaderStore } from "../Header/store";
import MobileNavModal from "../../components/MobileNavModal/MobileNavModal";


export const ViewContent=({viewContent,setView})=>{
  console.log("viewContent",viewContent)
  return(
    <div className={classes.viewContainer}>
          <div>
                          <svg 
                              style={{cursor:"pointer",minWidth:"1rem"}}
                              onClick={() => {
                              setView(false);
                              }}
                              xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 15 26" fill="none">
                              <path d="M13 2L2.35355 12.6464C2.15829 12.8417 2.15829 13.1583 2.35355 13.3536L13 24" stroke="#162432" stroke-width="3" stroke-linecap="round"/>
                              </svg>
                            <div>Back</div>
          </div>
          <div className={classes.viewWrapper}>
            {viewContent?.map((item,index)=>{
              return(
                <div key={index} className={classes.viewItem}>
                  <div>{index+1+". "+item.question}</div>
                  <div>
                
                           {item?.answer&&item?.inputType === "Multiple Choice" ? Object.values(item?.answer)?.join(', ')?Object.values(item?.answer)?.join(', '):"NA"
                           :item?.answer ?(item?.answer&&typeof item?.answer==='string'&&item?.answer?.includes('https://')?<Typography.Text style={{width:"15rem",fontSize:"14px"}} ellipsis={{tooltip:true}}><a href={item?.answer} style={{fontSize:"14px"}}>{item?.answer}</a></Typography.Text>:<Typography.Paragraph ellipsis={{rows:2,tooltip:true}} style={{margin:"auto 0"}}><span>{item?.answer}</span></Typography.Paragraph> ) :"NA"}
                           
                  </div>
                </div>
              )
            })}

          </div>

    </div>
  )
}

export const MobResponse=({datas,setViewContent,setView,meta,limit,setPage,setLimit,page})=>{
  return(
  <div className={classes.mobResponse}>
              {datas?.map((el,indx)=>{                                  
                                         return(
                                          <div className={classes.mobResCard} key={indx}>
                                              <div style={{display:"flex",flexDirection:"row",gap:"0.5rem"}}>
                                              <img src={el?.user?.profileImage?el?.user?.profileImage:profile_icon} style={{width:"2.5rem",height:"2.5rem",borderRadius:"50%"}}/>
                                                      <div style={{display:"flex",flexDirection:"column",}}>
                                                          <span style={{fontSize:"15px"}}>{el?.user?.name}</span>
                                                          <span style={{fontSize:"12px",color:"rgba(22, 36, 50, 0.50)"}}>{el?.user?.uid}</span>
                                                    </div>
                                              </div> 
                                              <div onClick={()=>{setViewContent(el.questions); setView(true); }}>View</div>                                                              
                                          </div>
                                        )                                                                                                
              })}  
               <div style={{marginTop:"auto",padding:"0 0.5rem"}}>
                                      <Pagination position="bottomRight" size="middle" style={{display:"flex",justifyContent:"flex-end",marginTop:"0.5rem"}}
                                      current={page} showSizeChanger={true} total={parseInt(meta?.totalItems)} pageSize={limit} 
                                      onChange={(page,pageSize)=>{
                                        setPage(page)
                                        setLimit(pageSize)
                                      }}
                                      onShowSizeChange={(page,pageSize)=>{
                                        setPage(page)
                                        setLimit(pageSize)
                                      }} 
                                      pageSizeOptions={[10,20,30,50,100,200]}
                                      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} />
                        </div>

                                                                       
</div>
  )
}

function FeedbackPublish() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const {updateFeedbackAddQuestions,updateFeedbackData,openEditFeedbackModal,updateEditFeedbackModal,updateFeedbackSaveState,
  feedbackaddquestions,feedbackData,}=useStore(); 
  const [datas,setData] = useState([]);
  const [questionsInfo,setQuestionsInfo]=useState([]);
  const [meta,setMeta]=useState();
  const [tabName,setTabName] = useState("Questions");
  const [limit,setLimit]=useState(10);
  const [page,setPage]=useState(1);
  const {width}=useWindowDimensions();
  const {sidemenu,setSidemenu}=useHeaderStore();
  const [view,setView]=useState(false);
  const [viewContent,setViewContent]=useState();

  const options = (op) => {
    const Data = [];
    Object.keys(op).forEach((key) => {
      if (op[key] !== "") {
        Data.push(op[key]);
      }
    });
    return <div style={{display:"flex",flexDirection:"column",gap:"1rem"}}>{Data.map((element) => {
      return (
        <Input
          value={element}
          size="large" disabled={true}
          prefix={<Radio></Radio>}
          style={{
            background:"#fff",
            fontSize:"14px",
            color:"#000",
            border:"1px solid #F1F1F1"
          }}
        />
      );
    })}</div>
  };

  useEffect(()=>{
    Api.get(
      `/resource-feedback/feedbackResponse?dataId=${
        feedbackData?.feedbackId && !state?.id
          ? feedbackData?.feedbackId
          : state?.id
      }&limit=${limit}&page=${page}`
    ).send((response) => {
      if (typeof response != "undefined") {
        console.log("sssResponse",response?.items)
        setData(response?.items)
        setMeta(response?.meta);
        setQuestionsInfo(response?.questions)
      }
    })

  },[limit,page])

  useEffect(() => {
    Api.get(
      `/resource-feedback/getQuestions?dataId=${
        feedbackData?.feedbackId && !state?.id
          ? feedbackData?.feedbackId
          : state?.id
      }`
    ).send((response) => {
      if (typeof response != "undefined") {
        
        let data = {
          feedbackId: response?.feedbackData?.id,
          title: response?.feedbackData?.title,
          description: response?.feedbackData?.description,
          feedbackImage: response?.feedbackData?.feedbackImage,
          monthlyFeedback:response?.feedbackData?.monthlyFeedback,
        };
        updateFeedbackData(data);
        updateFeedbackAddQuestions(response?.feedbackquestions)
      }
    });
  }, []);
  
  const DownloadFun=()=>{
    if(tabName === "Questions"){
    Api.get(
      `/resource-feedback/getQuestions?dataId=${
        feedbackData?.feedbackId && !state?.id
          ? feedbackData?.feedbackId
          : state?.id}&download=true`
    ).send((response) => {
      if (typeof response != "undefined") {
        window.open(response,"_blank").focus();
    }
    })
    }
    else if (tabName ==="Responses"){
      Api.get(
        `/resource-feedback/feedbackResponse?dataId=${
          feedbackData?.feedbackId && !state?.id
            ? feedbackData?.feedbackId
            : state?.id
          }&limit=${limit}&page=${page}
            &download=true`
      ).send((response) => {
        if (typeof response != "undefined") {
          window.open(response,"_blank").focus();
      }
    })
}
}

      var questionsArr=[]
      for (var i = 0; i < datas.length; i++) {
        // console.log("sssRRrVar", datas[i].questions);
        const questionsArray = datas[i].questions;
        
        for (var j = 0; j < questionsArray.length; j++) {
          // console.log(`sssrQuestion ${j + 1}:`, questionsArray[j]);
          questionsArr.push(questionsArray[j]);
        }
      }
      console.log("sssRRQuestionArr",questionsArr,questionsInfo)

 

      const handleEditModal = () => {
        updateEditFeedbackModal(true);
       updateFeedbackSaveState("edit");
      };

      const customIcons = {
        1: <div style={{display:"flex",alignItems:"center",flexDirection:"column",marginRight:"1.5rem",gap:"0.5rem"}}>
           <img src={SmileyIcon1} alt="" style={{height:"2rem",width:"2rem"}}/>
           <h6 style={{color:"black",marginTop:"-0.3rem"}}>Poor</h6>
           </div>,
        2: <div style={{display:"flex",alignItems:"center",flexDirection:"column",marginRight:"1.5rem",gap:"0.5rem"}}>
           <img src={SmileyIcon2} alt="" style={{height:"2rem",width:"2rem"}}/>
           <h6 style={{color:"black",marginTop:"-0.3rem"}}>Fair</h6>
           </div>,
        3: <div style={{display:"flex",alignItems:"center",flexDirection:"column",marginRight:"1.5rem",gap:"0.5rem"}}>
            <img src={SmileyIcon3} alt="" style={{height:"2rem",width:"2rem",}}/>
            <h6 style={{color:"black",marginTop:"-0.3rem"}}>Average</h6>
           </div>,
        4: <div style={{display:"flex",alignItems:"center",flexDirection:"column",marginRight:"1.5rem",gap:"0.5rem"}}>
            <img src={SmileyIcon4} alt="" style={{height:"2rem",width:"2rem",}}/>
            <h6 style={{color:"black",marginTop:"-0.3rem"}}>Happy</h6>
           </div>,
        5: <div style={{display:"flex",alignItems:"center",flexDirection:"column",marginRight:"1.5rem",gap:"0.5rem"}}>
            <img src={SmileyIcon5} alt="" style={{height:"2rem",width:"2rem"}}/>
            <h6 style={{color:"black",marginTop:"-0.3rem"}}>Excellent</h6>
            </div>,
      };
 

  return (
    <>
      {width<800&&<MobileNavModal sidemenu={sidemenu} setSidemenu={setSidemenu}>
     <div className={classes.sider}
     onClick={()=>{
      setSidemenu(false);
      navigate('/feedbackmanagement');
      window.location.reload();
     }}
     >
                  <div>
                              <img src={dashboardIcon}/>
                              <span>Dashboard</span>
                </div>
                <div>  
                  <img src={poweredBy_icon}></img>
                </div>
            </div>
      </MobileNavModal>}
      <div className={classes.container}>
        {width>800&&<div className={classes.sider}>
              <div 
              onClick={()=>{
                 navigate('/feedbackmanagement');
                 window.location.reload();
              }}
              >
                          <img src={dashboardIcon}/>
                          <span>Dashboard</span>
            </div>
            <div>  
              <img src={poweredBy_icon}></img>
            </div>
        </div>}
      <div className={classes.mainContent}>
              <div style={{background:"#fff",padding:"1rem",borderBottom:"1px solid #DFDFDF",boxSizing:"border-box"}}>
                      <div
                        style={{                  
                          display: "flex",
                          gap:"0.7rem",
                          width: "100%",                     
                           alignItems:"center",                     
                          marginBottom:"0.7rem",                                                                      
                        }}
                      >                     
                          <svg 
                          style={{cursor:"pointer",minWidth:"1rem"}}
                           onClick={() => {
                            navigate("/feedbackmanagement")
                          }}
                          xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 15 26" fill="none">
                          <path d="M13 2L2.35355 12.6464C2.15829 12.8417 2.15829 13.1583 2.35355 13.3536L13 24" stroke="#162432" stroke-width="3" stroke-linecap="round"/>
                          </svg>
                        
                         
                            <div
                              style={{                            
                                display: "flex",
                                alignItems: "center",                     
                              }}
                            >
                              <img src={feedbackData?.feedbackImage} style={{height:"4rem",width:"6.5rem"}} />
                             
                                <Typography.Text style={{maxWidth:width>800? "50vw":"10rem",fontSize:"1.2rem",fontWeight:"bold",marginLeft:"0.7rem"}} ellipsis={{tooltip:true}}>{feedbackData?.title}</Typography.Text>
                             
                              <Button type="text" onClick={handleEditModal}>
                                <img
                                  style={{ width: "1rem", height: "1rem",marginTop:"0.3rem"}}
                                  src={editIcon}
                                />
                              </Button>
                            </div>
                          
                     
                        
                      </div>
                      
                      <div className={classes.feedbackTab}>
                              <span
                              className={tabName==="Questions"?classes.activeKey:""}
                              onClick={()=>{  setTabName("Questions")}}
                              
                              >Questions</span>
                              <span
                               className={tabName==="Responses"?classes.activeKey:""}
                               onClick={()=>{  setTabName("Responses")}}
                              >Responses</span>
                             
                              {tabName==="Responses"&&
                              <div onClick={DownloadFun}>
                                        {width>800&&<span>Download</span>} 
                                      {/* <img src={DownloadIcon} alt=''/> */}
                                   <span>
                                   {width>800?<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M18.4371 14.2647C18.2879 14.2647 18.1448 14.3271 18.0393 14.4381C17.9338 14.5491 17.8746 14.6998 17.8746 14.8568V17.4443C17.8884 17.627 17.7656 17.79 17.5933 17.8173H2.40653C2.23201 17.7898 2.10834 17.6233 2.12529 17.4384V14.8568C2.12529 14.6452 2.01807 14.4497 1.84405 14.344C1.67004 14.2383 1.45559 14.2383 1.28158 14.344C1.10756 14.4497 1.00034 14.6452 1.00034 14.8568V17.4443C0.992054 17.8467 1.13518 18.2363 1.3986 18.5279C1.66203 18.8196 2.02437 18.9899 2.40653 19.0015H17.5933C17.9765 18.9899 18.3398 18.8188 18.6033 18.5258C18.8668 18.2328 19.0093 17.8417 18.9995 17.4383V14.8567C18.9995 14.6997 18.9403 14.5491 18.8348 14.438C18.7293 14.327 18.5862 14.2646 18.4371 14.2646V14.2647Z" fill="#141414" stroke="black"/>
                                            <path d="M9.05434 15.668C9.27846 15.9861 9.63441 16.1687 10.0086 16.1574C10.3828 16.1687 10.7387 15.9861 10.9629 15.668L16.0256 10.1147C16.147 9.96864 16.1887 9.76632 16.1355 9.57981C16.0825 9.39332 15.9423 9.24928 15.765 9.1989C15.5878 9.14866 15.3985 9.19915 15.2648 9.33265L10.5386 14.503V1.56262C10.5386 1.36155 10.4376 1.17582 10.2736 1.07535C10.1096 0.974883 9.90744 0.974883 9.74343 1.07535C9.57942 1.17582 9.47836 1.36156 9.47836 1.56262V14.503L4.75462 9.33265C4.62303 9.18785 4.4286 9.1287 4.24471 9.1773C4.06082 9.2259 3.91526 9.37497 3.86295 9.56837C3.81076 9.76165 3.85963 9.96999 3.99122 10.1147L9.05434 15.668Z" fill="#141414" stroke="black"/>
                                            </svg>:
                                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                                            <path d="M21.3418 16.4744C21.1678 16.4744 21.0008 16.5472 20.8778 16.6767C20.7547 16.8062 20.6856 16.982 20.6856 17.1651V20.1837C20.7017 20.3968 20.5585 20.5869 20.3575 20.6188H2.64083C2.43724 20.5867 2.29297 20.3925 2.31274 20.1768V17.1651C2.31274 16.9183 2.18766 16.6903 1.98466 16.5669C1.78165 16.4436 1.53149 16.4436 1.32848 16.5669C1.12548 16.6903 1.0004 16.9183 1.0004 17.1651V20.1837C0.990731 20.6531 1.1577 21.1076 1.46501 21.4479C1.77231 21.7881 2.19501 21.9867 2.64083 22.0003H20.3575C20.8045 21.9867 21.2283 21.7872 21.5357 21.4454C21.8431 21.1035 22.0094 20.6473 21.9979 20.1767V17.1651C21.9979 16.9819 21.9288 16.8062 21.8058 16.6767C21.6827 16.5471 21.5158 16.4744 21.3418 16.4744V16.4744Z" fill="#0086FF" stroke="#0086FF"/>
                                            <path d="M10.3961 18.1114C10.6575 18.4825 11.0728 18.6955 11.5093 18.6824C11.9458 18.6955 12.3611 18.4825 12.6225 18.1114L18.5286 11.633C18.6702 11.4627 18.7188 11.2266 18.6568 11.0091C18.595 10.7915 18.4314 10.6235 18.2246 10.5647C18.0178 10.5061 17.7971 10.565 17.6411 10.7207L12.1276 16.7524V1.65634C12.1276 1.42178 12.0097 1.20511 11.8184 1.0879C11.6271 0.970699 11.3913 0.970699 11.1999 1.0879C11.0086 1.20511 10.8907 1.42179 10.8907 1.65634V16.7524L5.38009 10.7207C5.22658 10.5518 4.99975 10.4828 4.78524 10.5395C4.57071 10.5962 4.40091 10.7701 4.33988 10.9957C4.279 11.2212 4.33601 11.4642 4.48952 11.633L10.3961 18.1114Z" fill="#0086FF" stroke="#0086FF"/>
                                            </svg>
                                            }
                                      </span>                                   
                              </div>}
                             
                      </div>
               </div>
                <div className={classes.questionTab}>  
                          {tabName==="Questions"&&
                          <div>
                          <div style={{ width: "100%",overflowY:"auto",height:"64vh",padding:" 0.5rem"}}>
                            <div style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
                              {feedbackaddquestions?.map((item, count) => {
                                return (
                                  <Card
                                   className={classes.questionCard}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        marginBottom: "1rem",
                                        padding: "0 1rem",
                                        alignItems: "center",
                                        gap: "0.5rem",
                                        borderRadius:"4px",
                                        border: `border:"1px solid #F1F1F1`,
                                      }}
                                    >
                                      <span style={{color:"#000", fontSize:"14px" }}>Q{count + 1}. </span>
                                      <Input
                                        size="large"
                                        bordered={false}
                                        value={item?.question}
                                         disabled={true}
                                        style={{color:"#000", fontSize:"14px" }}
                                      ></Input>
                                    </div>

                                    {item?.inputType == "Text" ? (
                                      <Input
                                        size="large"
                                        style={{
                                          // marginBottom: "2rem",
                                          color:"#B6B6B6",
                                          background:"#fff",
                                          border:"1px solid #F1F1F1",
                                          fontSize:"14px",
                                        }}
                                        value={"Text input"}
                                        disabled={true}
                                      />
                                    ) : item?.inputType == "Emoji-Rating" ? (
                                      <div className={classes.emojiRating}>
                                      <Rate
                                      size="large"
                                      style={{
                                        border: "1px solid #E5E5E5",
                                        minWidth: "25rem",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                        borderRadius:"5px",
                                        display:"flex",
                                        justifyContent:"center",
                                        alignItems:"center",
                                        marginTop:"0.5rem",                                                       
                                      }}
                                      character={({ index }) => customIcons[index + 1]}
                                    />
                                    </div>
                                    ) : item?.inputType == "Multiple Choice" ? (
                                      <>{options(item?.options)}</>
                                    ) : item?.inputType == "File Attachment" ? (
                                      <Upload
                                        multiple={true}
                                        listType="text"
                                        accept=""
                                        action={"https://www.mocky.io/v2/5cc8019d300000980a055e76"}
                                        showUploadList={{ showRemoveIcon: true }}
                                      >
                                        <Button>
                                          <PaperClipOutlined />
                                          Attach A File
                                        </Button>
                                      </Upload>
                                    ) : (
                                      <Slider />
                                    )}
                                  </Card>
                                );
                              })}
                            </div>
                          </div>
                          </div>}
                            {tabName==="Responses"&&
                            (width>800?
                            <div style={{height:"100%",display:"flex",flexDirection:"column",boxSizing:"border-box"}}>
                     
                            <div className={classes.responses}>
                                    <span>Questions</span>

                                    {datas?.length>0?<div >
                                            <div className={questionsInfo?.length>3?classes.eachRow:classes.eachRow11}>
                                                          <span style={{marginLeft:questionsInfo?.length>3?"0":"1rem"}}>Associates</span>
                                                          {questionsInfo?.map((item,index)=>{                                             
                                                            return(
                                                              <Typography.Paragraph  ellipsis={{tooltip:true,rows:2}} >
                                                              <span key={index}>{item?.question}</span>
                                                              </Typography.Paragraph>
                                                            )
                                                          })}
            
                                            </div>
                                            <div >
                                                    {datas?.map((el,indx)=>{
                                                    
                                                  
                                                   
                                                      return(
                                                        <div className={questionsInfo?.length>3?classes.eachRow2:classes.eachRow21} key={indx}>
                                                          <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",marginLeft:"1rem"}}>
                                                                <img src={el?.user?.profileImage?el?.user?.profileImage:profile_icon} style={{width:"2.5rem",height:"2.5rem",borderRadius:"50%"}}/>
                                                                    <div style={{display:"flex",flexDirection:"column",}}>
                                                                        <span style={{fontSize:"15px"}}>{el?.user?.name}</span>
                                                                        <span style={{fontSize:"12px",color:"rgba(22, 36, 50, 0.50)"}}>{el?.user?.uid}</span>
                                                                  </div>
                                                            </div>
                                                                   
                                                          {el?.questions?.map((item,index)=>{     
                                                               console.log("Answer Count",)
                                                               
                                                            return(
                                                              // <span key={index}>{item?.answer}</span>
                                                            
                                                              <span>
                                                              {item?.answer&&item?.inputType === "Multiple Choice" ? Object.values(item?.answer)?.join(', ')?Object.values(item?.answer)?.join(', '):"NA"
                                                            :item?.answer ?(item?.answer&&typeof item?.answer==='string'&&item?.answer?.includes('https://')?<Typography.Text style={{width:"15rem",fontSize:"14px"}} ellipsis={{tooltip:true}}><a href={item?.answer} style={{fontSize:"14px"}}>{item?.answer}</a></Typography.Text>:<Typography.Paragraph ellipsis={{rows:2,tooltip:true}} style={{margin:"auto 0"}}><span>{item?.answer}</span></Typography.Paragraph> ) :"NA"}
                                                            </span>
                                                            )
                                                          })}
                                                        </div>
                                                      )
                                                    
                                                  
                                                  })}
                                            </div>
                                      </div>:
                                      <div style={{height:"100%",display:"flex",alignItems:"center",marginTop:"5rem",color:"rgba(22, 36, 50, 0.50)",fontWeight:"600"}}>
                                           <span >No data to display!</span>
                                      </div>
                                      }
                            </div>

                            <div style={{marginTop:"auto",padding:"0 0.5rem"}}>
                                      <Pagination position="bottomRight" size="middle" style={{display:"flex",justifyContent:"flex-end",marginTop:"0.5rem"}}
                                      current={page} showSizeChanger={true} total={parseInt(meta?.totalItems)} pageSize={limit} 
                                      onChange={(page,pageSize)=>{
                                        setPage(page)
                                        setLimit(pageSize)
                                      }}
                                      onShowSizeChange={(page,pageSize)=>{
                                        setPage(page)
                                        setLimit(pageSize)
                                      }} 
                                      pageSizeOptions={[10,20,30,50,100,200]}
                                      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} />
                        </div>

                          </div>:
                         
                         view?<ViewContent setView={setView} viewContent={viewContent}/>
                         :
                         <MobResponse meta={meta} limit={limit} setPage={setPage} setLimit={setLimit} page={page} datas={datas} setView={setView} setViewContent={setViewContent}/>)
                          }
                </div>
      </div>
      {openEditFeedbackModal&&<CreateFeedbackModal />}
      </div>
    </>
  );
}
export default FeedbackPublish;
