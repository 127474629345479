import { create } from "zustand";
import ManageNotify from "../../../assets/settingManageNotifi.svg";
import PasswordIcon from "../../../assets/settingPassword.svg";
import DeleteIcon from "../../../assets/settingDelete.svg";
import LogoutIcon from "../../../assets/settingLogout.svg";

export const ProfileStore = create(set => ({
    currentPage:"profile",
    editImageModal:false,
    settingLists : [
                    // { image:ManageNotify,name:"Manage notifications"},
                    { image:PasswordIcon,name:"Change password"},
                    { image:DeleteIcon,name:"Delete account"},
                    { image:LogoutIcon,name:"Logout"}],
    // activeSetting:"Manage notifications",
    activeSetting:"Change password",
    userData:{},
    phoneNumber:'',
    emailId:'', 
    candidateDetails:null, 
    loading:false,
    footerRef:"",
    setLoading:(event)=>set(state=>({loading:event})),
    setCandidateDetails:(event)=>set(state=>({candidateDetails:event})),
    setEmailId:(event)=>set(state=>({emailId:event})),
    setPhoneNumber:(event)=>set(state=>({phoneNumber:event})),
    setUserData:(event)=>set(state=>({userData:event})),
    setActiveSetting:(event)=> set(state => ({activeSetting:event})),
    setEditImageModal: (event)=> set(state => ({editImageModal:event})),
    setCurrentPage: (event)=> set(state => ({currentPage:event})),
    setfooterRef: (event)=> set(state => ({footerRef:event})),
}))