import { ModalPrimaryButton, ModalSecondaryButton } from "../../../../components/Button/Button";
import ModalCompo from "../../../../design/ModalCompo";
import submitIcon from "../../../../assets/approve_header_icon.svg";
import classes from './EditProfile.module.css';
function SubmitProfile({modal,setModal,SubmitHandler}){

    
   
    return(
        <>
        <ModalCompo open={modal} 
        className={classes.SubmitProfileModal}
        header={<div style={{display:'flex',justifyContent:'center',flexDirection:"row",width:"100%",gap:'8px',alignItems:'center'}}>
        <img src={submitIcon} style={{height:'36px',width:'36px'}} />
        <div style={{color:'#39CA6C',font:'normal 600 20px/36px Poppins'}}>Submit</div>
        </div>}
         closableRight={true} onCancel={()=>{setModal(false);}}>

        <div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'1rem',flexDirection:'column',width:"100%"}}>
            
            <div style={{border:'1px solid #F1F1F1',width:'100%'}}></div>
            <div style={{font:'normal 500 17px/36px Poppins',color:'#162432'}}>Are you sure you want to submit the basic profile form?</div>
            <div style={{font:'normal 400 14px/36px Poppins',color:'red'}}>(Please Note! No edits possible after submission)</div>
            <div style={{display:'flex',gap:'2rem',justifyContent:'center',flexDirection:"row",width:"100%",marginTop:'1rem',marginBottom:'1rem'}}>
            <ModalSecondaryButton style={{backgroundColor:"white",height:"2.1rem",width:"5rem"}} onCancel={()=>{setModal(false)}}>NO</ModalSecondaryButton>
            <ModalPrimaryButton style={{height:"2.1rem",width:"5rem"}} onSave={()=>{SubmitHandler();}}>YES</ModalPrimaryButton>
            </div>
        </div>
        
        </ModalCompo>
        </>
    )       
}
export default SubmitProfile;