import React, { useState } from "react";
import classes from "./RejectModal.module.css";
import ModalCompo from "../../design/ModalCompo";
import { useNotificationStore } from "./store";
import { ModalSecondaryButton } from "../../components/Button/Button";
import reject_header_icon from '../../assets/reject_header_icon.svg';
import { manualPunchs, request } from "./action";
import { Button, Input } from "antd";
import Modal from "../../components/Modal/Modal";
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";

const RejectModal = (props) => {
    
    const { rejectModal, rejectModalHandler ,id,user,userSel} = useNotificationStore();
    const [loading, setloading] = useState(false);
    const [comment,setComment] = useState()
    const {width}=useWindowDimensions();
    
    const compArr = ['Sick request','Casual request','Privilege request','Wedding request','Parental request','Lop request','Comp_off Leave request','Sick Leave request','Casual Leave request','Privilege Leave request','Wedding Leave request','Parental Leave request','Lop Leave request']

    console.log(userSel,"propsprops");

    return (
        <Modal
            show={rejectModal}
            closableRight={true}
            onCancel={()=>{
                rejectModalHandler(false)
            }}
            header={<div className={classes.header}>
                <img src={reject_header_icon} />
                <h3>Reject</h3>
            </div>}
     style={{width:width>700?"50vw":"98vw"}}
        >
            <div style={{padding:"1rem"}}>
            <span className={classes.message}>
                        <span> {userSel.message?.length?userSel.message:""}</span>
                        <span>Are you sure, you want to reject?</span>
            </span>
            {
                    compArr.includes(userSel?.title) &&
                    <>
                        {
                                                            userSel.info?.comments &&
                                                            <>

                                                            <div style={{ fontSize: '13px', marginTop: 10,fontWeight:600 }}>{userSel.info?.comments?.commentRejected1 ? `Approver 1 comment : ${userSel.info?.comments?.commentRejected1}` 
                                                            : userSel.info?.comments?.commentApproved1 ? `Approver 1 comment : ${userSel.info?.comments?.commentApproved1}` : null}</div>
                                                            <div style={{ fontSize: '13px', marginTop: 10,fontWeight:600 }}>{userSel.info?.comments?.commentRejected2 ? `Approver 2 comment : ${userSel.info?.comments?.commentRejected2}` 
                                                            : userSel.info?.comments?.commentApproved2 ? `Approver 2 comment : ${userSel.info?.comments?.commentApproved2}` : null}</div>
                                                        </>
                                                        }
                        <div style={{ flex: 1, fontSize: 13}}>Comment</div>
                        <Input style={{ flex: 1, marginTop: 5 }} onChange={(e) => { setComment(e.target.value) }} placeholder="add comment"></Input>
                    </>
                }
                <div className={classes.buttonDiv}>
                    {/* <ModalPrimaryButton onSave={() => {
                        const manualPunch = {
                            id: id,
                            status: 'Rejected'
                        }

                        manualPunchs(manualPunch, (res) => {    
                        })
                    }}>Yes</ModalPrimaryButton> */}
                    <Button 
                style={{color:'white',background:'#0086FF',height:"2.4rem",width:"5rem"}}
                loading={loading}
                onClick={()=>{
                    setloading(true)
                    const manualPunch = {
                        old:userSel?.old,
                        notificationId: userSel?.notificationId,
                        status: 'Rejected',
                        comment:comment
                    }
                    console.log("hjvhjjvugv",manualPunch);
                    manualPunchs(manualPunch, (res) => {

                        // request('Pending','','', (res) => {
                        //  setloading(false)
                        // rejectModalHandler(false)
                        // setgetRequest(res)
                        // },user?.id)
                        if(res?.show?.type === "success"){
                            setloading(false)
                            rejectModalHandler(false)
                            props.getRequest();
                        }
                        else{
                            setloading(false)
                        }
                    })
                }}>Yes</Button>
                    <ModalSecondaryButton onCancel={() => {
                        rejectModalHandler(false)
                    }}>No</ModalSecondaryButton>
                </div>
            </div>
        </Modal>
    )
}
export default RejectModal;