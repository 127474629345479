import React, { useState, useEffect } from "react";
import { Button, Select, Table, Tooltip } from "antd";
import Modal from "antd/lib/modal/Modal";
import Api from "../../../../services/Api";
import { useDispatch, useSelector } from "react-redux";

import { CloseOutlined } from "@ant-design/icons";
import SearchCompo from "../../design/SearchCompo";
import downloadIcon from "../../../../assets/Appraisal/downloadBlackIcon.svg";
import profileIcon from "../../../../assets/Appraisal/userIcon.svg";
import addIcon from "../../../../assets/Appraisal/addIcon.svg";
import { AiFillCaretUp, AiFillCaretDown } from "../../../../services/Icons";
import filterIcon from "../../../../assets/Appraisal/filterIcon.svg";
import contactUs from "../../../../assets/Appraisal/contactUs.svg";
// import { getCycleUsers } from "./actionsAdmin";
import { adminActions } from "../store";
import WorkSummaryModal from "../work-summary-modal";
import { Color } from "../../../../services/color";
import AssociateTable from "../../components/Table/AssociateTable";

import classes from "./appraisal_cycle.module.css";
import SearchCustom from "../../components/SearchCustom";

function AppraisalCycleModal(props) {
  const {
    meta,
    // designationList,
    appraisalFilter,
    designationFilter,
    cycleFilter,
    formStatusFilter,
    page,
    pageSize,
    refreshEffect,
    cycleUsers,
    
  } = useSelector((state) => state.admin);
  const Option = Select.Option;
  const dispatch = useDispatch();
  const [cycle, setCycle] = useState();
  const [disabled, setDisabled] = useState(true);
  const [editButton, setSaveButton] = useState(false);
  const [startAddDate, setStartAddDate] = useState();
  const [endAddDate, setEndAddDate] = useState();
  const [initialFilter, setInitialFilter] = useState({
    appraisalFilter: appraisalFilter,
    designationFilter: designationFilter,
    cycleFilter: cycleFilter,
    formStatusFilter: formStatusFilter,
  });

  const [metaData, setMetaData] = useState();
  const [startEditDate, setStartEditDate] = useState();
  const [endEditDate, setEndEditDate] = useState();
  const [blockButton, setBlockButton] = useState(true);
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [filterStatusChanged, setFilterStChanged] = useState(false);
  const [filterLevelChanged, setFilterLevelChanged] = useState(false);
  const [filterFormChanged, setFilterFormChanged] = useState(false);
  const [filterCycleChanged, setFilterCycleChanged] = useState(false);
  // const [pageSize, setPageSize] = useState(10);
  // const [page,setPage]=useState(1);
  const [associateLevelFilter, setAssociateLevelFilter] = useState("");
  const [appraisalStatusFilter, setAppraisalStatusFilter] = useState("");
  const [sorted, setSorted] = useState("");
  const [designationList, setDesignationList] = useState();
  // const [initialFilter, setInitialFilter] = useState("");

  const data = props?.data;
  const sdate = data?.cycle_startDate;
  const edate = data?.cycle_endDate;

  const appraisalOptions = {
    active: "Active",
    inactive: "Inactive",
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const dateFormat = "DD/MM/YYYY";

  //hanlde Edit button

  useEffect(() => {
    dispatch(adminActions.updateFilter(initialFilter));
    dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
  }, [initialFilter, page]);

 
  const getCycleUsers = (params, callback, loader) => {
    console.log("params.search||params.designation|| params.appraisalStatus",( params.appraisalStatus!=="" ))
    if (loader) loader(true);
    Api.get(
      `/appraisal-admin/getcycleUsers?appraisalMonth=${
        params.appraisalMonth === undefined ? "" : params.appraisalMonth
      }&designation=${
        params.designation ? params.designation : ""
      }&appraisalStatus=${
        params.appraisalStatus === undefined ? "" : params.appraisalStatus
      }&sort=${params.sort ? params.sort : ""}&page=${page
      }&limit=${pageSize}&download=${
        params.download ? params.download : ""
      }`
    )
      .params({search: params.search ? params.search : ""})
      .send((response, error) => {
        if (!params.download) {
          if (response) {
            callback(response);
          }
        } else {
          window.open(response, "_blank")?.focus();
        }
        if (loader) {
          loader(false);
        }
      });
  };

  useEffect(() => {
    setLoader(true);
    getCycleUsers(
      {
         appraisalMonth: data?.cycle_appraisalCycle,       
        designation: associateLevelFilter,
        appraisalStatus: appraisalStatusFilter,
        sort: sorted, 
        search:search
      },
       (res) => {
      setMetaData(res?.users?.meta);
      dispatch(adminActions.updateCycleUsers(res?.users?.items));
      setDesignationList(res?.designation);
      setLoader(false);
    });
  }, [page,pageSize,data,appraisalStatusFilter,sorted,associateLevelFilter,appraisalStatusFilter,search]);


  const handleEditOk = () => {
    Api.patch(`/appraisal-admin/updateCycle`, {
      id: data?.cycle_id,
      appraisalCycle: cycle,
      startDate: startEditDate || sdate,
      endDate: endEditDate || edate,
    }).send((response, error) => {
      if (typeof response !== "undefined") {
        props.refresh();
      }
    });
    props?.updateEditModal(false);
  };



  const handleDesignationFilter = (value) => {
     dispatch(adminActions.updatePage({ page: 1, pageSize: 30 }));
    // handlePageChange(1, 30);
    setAssociateLevelFilter(value);
    // setLoader(true);
    // getCycleUsers(
    //   {
    //     appraisalMonth: data?.cycle_appraisalCycle,
    //     designation: value,
    //     appraisalStatus: appraisalStatusFilter,
    //     search: search,
    //     sort: sorted,
    //   },
    //   (res) => {
    //     setMetaData(res?.users?.meta);
    //     dispatch(adminActions.updateCycleUsers(res?.users?.items));
    //     setLoader(false);
    //   }
    // );
    setFilterLevelChanged(!filterLevelChanged);
  };

  const handleAppraisalfilter = (value) => {
    setAppraisalStatusFilter(
      value === undefined ? "" : value === "Active" ? true : false
    );
    dispatch(adminActions.updatePage({ page: 1, pageSize: 30 }));
    // handlePageChange(1, 30);
    // setLoader(true);
    // getCycleUsers(
    //   {
    //     appraisalMonth: data?.cycle_appraisalCycle,
    //     appraisalStatus:
    //       value === undefined ? "" : value === "Active" ? true : false,
    //     designation: associateLevelFilter,
    //     search: search,
    //     sort: sorted,
    //   },
    //   (res) => {
    //     setMetaData(res?.users?.meta);
    //     dispatch(adminActions.updateCycleUsers(res?.users?.items));
    //     setLoader(false);
    //   }
    // );
    setFilterStChanged(!filterStatusChanged);
  };

  //  handle adds Button
  const handleAddOk = () => {
    props?.updateAddModal(false);
  };


  useEffect(() => {
    if (cycle && startAddDate && endAddDate) {
      setBlockButton(false);
    } else {
      setBlockButton(true);
    }
  }, [cycle, startAddDate, endAddDate]);

  const onWorkSummaryClick = (data) => {
    dispatch(adminActions.updateWorkSummaryModal(true));
    dispatch(adminActions.updateRowData(data));
  };

  const onAppraisalStatusClick = (data) => {
    const appraisalActive = data.appraisalActive ? false : true;
    Api.patch("/appraisal-admin/users/?userId=" + data.id)
      .params({ appraisalActive })
      .send((response, error) => {
        if (response !== "undefined" && response?.show?.type === "success") {
          dispatch(adminActions.updateSingleUser({ user: response.data }));
          dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
        }
      });
  };

  const handleSort = (value) => {
    setSorted(value);
    // if (value === sorted) {
    //   setLoader(true);
    //   getCycleUsers(
    //     {
    //       appraisalMonth: data?.cycle_appraisalCycle,
    //       designation: associateLevelFilter,
    //       appraisalStatus: appraisalStatusFilter,
    //       search: search,
    //       sort: undefined,
    //     },
    //     (res) => {
    //       dispatch(adminActions.updateCycleUsers(res?.users?.items));
    //       setLoader(false);
    //     }
    //   );
    //   setSorted(undefined);
    // } else {
    //   setLoader(true);

    //   getCycleUsers(
    //     {
    //       appraisalMonth: data?.cycle_appraisalCycle,
    //       designation: associateLevelFilter,
    //       appraisalStatus: appraisalStatusFilter,
    //       search: search,
    //       sort: value,
    //     },
    //     (res) => {
    //       dispatch(adminActions.updateCycleUsers(res?.users?.items));
    //       setLoader(false);
    //     }
    //   );
    // }
  };

  const handlePageChange = (page, pageSize) => {
    dispatch(adminActions.updatePage({ page: page, pageSize: pageSize }));
    // setLoader(true);
    // getCycleUsers(
    //   {
    //     appraisalMonth: data?.cycle_appraisalCycle,
    //     designation: associateLevelFilter,
    //     appraisalStatus: appraisalStatusFilter,
    //     search: search,
    //     page: page,
    //     sort: sorted,
    //     limit: pageSize === "undefined" ? "" : pageSize,
    //     // pageSize: pageSize,
    //   },
    //   (res) => {
    //     setMetaData(res?.users?.meta);
    //     dispatch(adminActions.updateCycleUsers(res?.users?.items));
    //     setLoader(false);
    //   }
    // );
    // dispatch(adminActions.updatePage({ page: page, pageSize: pageSize }));
    // dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
  };

  const columns = [
    {
      // title: (
      //   <div style={{ display: "flex", justifyContent: "center" }}>
      //     Associate
      //     <div
      //       style={{
      //         display: "flex",
      //         flexDirection: "column",
      //         marginLeft: "0.5rem",
      //       }}
      //     >
      //       <Tooltip title={"Click to sort ascending"}>
      //         <div style={{ marginBottom: -11, cursor: "pointer" }}>
      //           {" "}
      //           <AiFillCaretUp
      //             style={{
      //               color:
      //                 sorted === "" || sorted === null || sorted === undefined
      //                   ? "#787b83"
      //                   : sorted === "ASC"
      //                   ? Color.modalblue
      //                   : "#787b83",
      //             }}
      //             onClick={() => handleSort("ASC")}
      //           />
      //         </div>
      //       </Tooltip>
      //       <Tooltip title={"Click to sort descending"}>
      //         <div style={{ cursor: "pointer" }}>
      //           {" "}
      //           <AiFillCaretDown
      //             style={{
      //               color:
      //                 sorted === "" || sorted === null || sorted === undefined
      //                   ? "#787b83"
      //                   : sorted === "DESC"
      //                   ? Color.modalblue
      //                   : "#787b83",
      //             }}
      //             onClick={() => handleSort("DESC")}
      //           />
      //         </div>
      //       </Tooltip>
      //     </div>
      //   </div>
      // ),
     
      title: (
        <div style={{ display: "flex",alignItems:"center",width:"100%",paddingLeft:"0.8rem"}}>
          <div>Associate</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "0.5rem",
            }}
          >
            <Tooltip title={"Click to sort ascending"}>
              <div style={{ marginBottom: -11, cursor: "pointer" }}>
                {" "}
                <AiFillCaretUp
                  style={{
                    color:
                      sorted === "" || sorted === null || sorted === undefined
                        ? "#787b83"
                        : sorted === "ASC"
                        ? Color.modalblue
                        : "#787b83",
                  }}
                  onClick={() => handleSort("ASC")}
                />
              </div>
            </Tooltip>
            <Tooltip title={"Click to sort descending"}>
              <div style={{ cursor: "pointer" }}>
                {" "}
                <AiFillCaretDown
                  style={{
                    color:
                      sorted === "" || sorted === null || sorted === undefined
                        ? "#787b83"
                        : sorted === "DESC"
                        ? Color.modalblue
                        : "#787b83",
                  }}
                  onClick={() => handleSort("DESC")}
                />
              </div>
            </Tooltip>
          </div>
        </div>
      ),
      dataIndex: "",
      key: "",
      render: (val, row) => {
        
        return (
          <div
            style={{
              display: "flex",
              // border: "1px solid black",
              justifyContent: "flex-start",
              width: "100%",
              marginLeft: "0.5rem",
            }}
          >
            <div>
              <img
                src={profileIcon}
                style={{ marginTop: "1rem", marginLeft: "0.2rem" }}
              />
            </div>
            <div style={{
               marginLeft: "0.5rem", fontSize: "12px",width:"7rem", textOverflow: "ellipsis",
               overflow: "hidden",
              whiteSpace: "nowrap",
                }}>
              <Tooltip title={row?.name}>
               <span  style={{fontSize:"0.9rem"}}> {row?.name}</span>
              </Tooltip>
              <div>{row?.role}</div>
              <div>{row?.employeeId}</div>
            </div>
          </div>
        );
      },
    },
    {
      // title: (
      //   <div style={{ display: "flex", justifyContent: "center" }}>
      //     Contact Details
      //   </div>
      // ),
      title:<div style={{width:"100%"}}>Contact Details</div> ,
      dataIndex: "",
      key: "",
      width: "10rem",
      ellipsis: {
        showTitle: false,
      },
      render: (val, row) => {
        return (
          <div
            style={{
              // border: "1px solid black",
              fontSize: "0.8rem",
              flexDirection: "column",
              width: "9rem",
            }}
          >
            <div
              style={{
                fontSize: "1rem",
                width: "9rem",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              <Tooltip title={row?.email} style={{fontSize:"0.9rem"}}>{row?.email}</Tooltip>
            </div>
            <div>{row?.phone}</div>
          </div>
        );
      },
    },
    {
      // title: (
      //   <div style={{ display: "flex", justifyContent: "center" }}>
      //     Work Summary
      //   </div>
      // ),
      title:<div style={{width:"100%"}}>Work Summary</div> ,
      dataIndex: "",
      key: "",
      render: (val, row) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={addIcon}
              style={{ height: "16px", marginTop: 3, cursor: "pointer" }}
              onClick={() => onWorkSummaryClick(row)}
            />
            {row?.workSummary?.length > 0 ? (
              <span> Edit Summary</span>
            ) : (
              <span> Add Summary</span>
            )}
          </div>
        );
      },
    },
    {
      title: (
        <div style={{ display: "flex",alignItems:"center",width:"100%" }}>
          <div> Associate Level</div>
          <div style={{ position: "relative", right: 8 }}>
            <Select
              suffixIcon={<img src={filterIcon} />}
              allowClear={filterLevelChanged}
              trigger="click"
              type="text"
              placement="bottomLeft"
              bordered={false}
              dropdownMatchSelectWidth={false}
              onChange={handleDesignationFilter}
              value={initialFilter}
              removeIcon={true}
            >
              {designationList &&
                Object.values(designationList).map((item) => (
                  <>
                    <Option value={item} label={item} title="">
                      {item}
                    </Option>
                  </>
                ))}
            </Select>
          </div>
        </div>
      ),
      // title:<div style={{width:"100%"}}> Associate Level</div>,
      dataIndex: "",
      key: "",
      render: (val, row) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {row?.associateLevel}
          </div>
        );
      },
    },
    {
      // title: (
      //   <div style={{ display: "flex", justifyContent: "center" }}>
      //     Appraisal Cycle
      //   </div>
      // ),
      title:<div style={{width:"100%"}}>Appraisal Cycle</div>,
      dataIndex: "",
      key: "",
      render: (val, row) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {row?.appraisalCycle}
          </div>
        );
      },
    },
    {
      title: (
        <div style={{ display: "flex", alignItems: "center",width:"100%", }}>
          <div>
            {" "}
            Appraisal Status
          </div>
          <div style={{ position: "relative", right: 8 }}>
            <Select
              suffixIcon={<img src={filterIcon} />}
              allowClear={filterStatusChanged}
              trigger="click"
              type="text"
              placement="bottomLeft"
              bordered={false}
              dropdownMatchSelectWidth={false}
              onChange={handleAppraisalfilter}
              value={initialFilter}
              removeIcon={true}
            >
              {Object.values(appraisalOptions).map((item) => (
                <>
                  <Option value={item} label={item} title="">
                    {item}
                  </Option>
                </>
              ))}
            </Select>
          </div>
        </div>
      ),
      // title:<div style={{width:"100%"}}>Appraisal Status</div> ,
      dataIndex: "",
      key: "",
      render: (val, row) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="text"
              style={{
                background: row?.appraisalActive ? "#C2E3FF" : "#FFAAAA",
                color: row?.appraisalActive ? "#0086FF" : "#FF0000",
                // borderColor: row?.appraisalActive
                //   ? "#0086FF"
                //   : "#FF0000",
                width: "6rem",
                fontSize: "1rem",
                borderRadius: "4px",
              }}
              onClick={() => onAppraisalStatusClick(row)}
            >
              {row?.appraisalActive ? "Active" : "Inactive"}
            </Button>
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <Modal // ADD cycle modal
        open={props?.showAddModal}
        onCancel={() => {
          props?.updateAddModal(false);
        }}
        onOk={handleAddOk}
        footer={null} centered
        width={"50%"}
        // bodyStyle={{ padding: 0, height: 550 }}
        closeIcon={false}
        // style={{ marginTop: "-3rem" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",margin:"1.5rem 0"
          }}
        >
          <img src={contactUs} style={{ width: "50%", height: "50%" }} />
          <div
            style={{
              fontSize: "1.1rem",
              fontFamily: "Roboto-Medium",
              fontWeight: 600,
              marginTop: "2rem",
            }}
          >
            Please contact tech team to add cycle
          </div>
          <Button
            type="primary"
            style={{ width: "5rem", marginTop: "2rem" }}
            onClick={() => {
              props?.updateAddModal(false);
            }}
          >
            Close
          </Button>
        </div>
      </Modal>

      <Modal // View cycle modal
        open={props?.showEditModal}
        centered
        onCancel={() => {
          props?.updateEditModal(false);
        }}
        onOk={handleEditOk}
        footer={null}
        width={1200}
        bodyStyle={{ padding: 0, height: "38rem" }}
        closeIcon={<CloseOutlined style={{ color: "" }} />}
        style={{ top: "10px" }}
      >
        <div className={classes.container}>
          <div className={classes.titleBlock}>
            <h2 className={classes.title}>
              {data?.cycle_appraisalCycle} Month Cycle
            </h2>

            <div
              style={{
                // border:'1px solid black',
                width: "60%",
                display: "flex",
                justifyContent: "right",
                gap: "1rem",
                alignItems: "center",
                // border:"2px solid red"
              }}
            >
              <SearchCustom
                style={{ height: "5vh",width:"30vw" }}
                placeholder="Search by name"
                setValue={(e) => {
                  setSearch(e);
                  dispatch(adminActions.updatePage({ page: 1, pageSize: 30 }));
                  // setLoader(true);
                  // getCycleUsers(
                  //   {
                  //     appraisalMonth: data?.cycle_appraisalCycle,
                  //     designation: associateLevelFilter,
                  //     appraisalStatus: appraisalStatusFilter,
                  //     page: 1,
                  //     limit: 30,
                  //     sort: sorted,
                  //     search: e,
                  //   },
                  //   (res) => {
                  //     setMetaData(res?.users?.meta);

                  //     dispatch(
                  //       adminActions.updateCycleUsers(res?.users?.items)
                  //     );
                  //     setLoader(false);
                  //   }
                  // );
                }}
                value={search}
              />
            </div>
          </div>

          <div style={{ width: "100%", height: "78vh", marginTop: "1rem" }}>
            <div
              style={{
                width: "100%",
                height: "4vh",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <div className={classes.tab_Container}></div>
              <div className={classes.action_container}>
                {/* <div>Filter by</div> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap:'0.5rem',
                    cursor:"pointer",
                   
                  }}
                  onClick={() => {
                    getCycleUsers({
                      appraisalMonth: data?.cycle_appraisalCycle,
                      designation: associateLevelFilter,
                      appraisalStatus: appraisalStatusFilter,
                      download: true,
                      search: search,
                      sort:sorted
                    });
                  }}
                >
                  <span style={{ color: "#0086FF" }}>Download</span>
                  <img src={downloadIcon} />
                </div>
              </div>
            </div>
            <div
              style={{
                // border: "1px solid black",
                width: "100%",
                height: "65vh",
                // marginTop: "1rem",
                display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
              }}
            >
              <AssociateTable
                loading={loader}
                dataSource={cycleUsers}
                columns={columns}
                rowKey={(record) => record?.id}
                meta={metaData}
                style={{ width: "100%" }}
                onChange={(page, pageSize) => handlePageChange(page, pageSize)}
              />
            </div>
          </div>
        </div>
      </Modal>

      <WorkSummaryModal />
    </div>
  );
}

export default AppraisalCycleModal;
