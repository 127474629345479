import React, { useState } from 'react';
import { CiLocationOn } from 'react-icons/ci';
import '../../../design/framework.css'
import { Button, Input } from 'antd';
import AutoCompleteGeofence from './autoCompleteGeofence';

import { useNotificationStore } from './store';
import { addCityData, allCity, updateCity } from './Action/action';


const TextComponent = ({ text, width, fontSize, backgroundColor, marginLeft, color, marginRight, fontWeight }) => {
    return (
        <div style={{ fontSize: '20px', width: width, fontSize: fontSize, marginLeft: marginLeft, color: color, marginRight: marginRight, fontWeight: fontWeight }}>
            {text}
        </div>
    );
};

// const InputComponent = ({ width, borderRadius, borderColor, marginTop, value, onChange, numericOnly, placeholder, height }) => {
//     const handleKeyPress = (e) => {
//         if (numericOnly) {
//             const keyCode = e.keyCode || e.which;
//             const keyValue = String.fromCharCode(keyCode);
//             const numericRegex = /^[0-9]*$/;
//             const dotRegex = /\./;
//             const currentValue = e.target.value;

//             if (!numericRegex.test(keyValue) && !(keyValue === '.' && !dotRegex.test(currentValue))) {
//                 e.preventDefault();
//             }
//         }
//     };

//     return (
//         <div style={{ width: width, borderRadius: borderRadius, borderColor: borderColor, marginTop: marginTop, height: height }}>
//             <Input placeholder={placeholder} value={value} onChange={onChange} onKeyPress={handleKeyPress} />
//         </div>
//     );
// };

function AddCityModel({ setCenterModel, setEdit, id }) {

    const {  setAllCityStore, addCityName, SetDeleteStore,setAddCity,addCity } = useNotificationStore();
    const [place, setplace] = useState('')
    const [loading, setloading] = useState(false)
    const handlePlaceSelect = (selectedPlaceData) => {
        console.log('lknkjnjkjbkjkjbkjbbk', selectedPlaceData);
        setplace(selectedPlaceData?.label)
        
    };

    return (<>
        <div style={{ padding: 10,}}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}>
                <>
                    <div className='r-jc' style={{ display: 'flex', alignItems: 'start', justifyContent: 'start' }}>
                        <CiLocationOn className='p-2' color='#0086FF' fontSize={25} />
                        {setEdit ?
                            <TextComponent className='p-2' text="Edit City" color={'#0086FF'} fontWeight={700} fontSize={'18px'} /> :

                            <TextComponent className='p-2' text="Add City" color={'#0086FF'} fontWeight={700} fontSize={'18px'} />}
                    </div>
                </>

                <div style={{ width: '100%', height: 0.8, backgroundColor: '#F1F1F1', marginTop: 10 }} />

                <div style={{ paddingTop: '4vh' }}>
                    <h5 style={{ marginBottom: 15 }}>City</h5>
                    {
                        console.log("kiiii", setEdit)
                    }
                    <AutoCompleteGeofence onSelect={handlePlaceSelect} setEdit={setEdit} />

                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '10%' }}>
                    <Button disabled={addCityName ? false :true}
                        loading={loading}
                        onClick={() => {

                            if (addCity && setEdit) {

                                setloading(true)

                                updateCity(({ cityName: addCityName }), id, (ress) => {
                                    if(ress){
                                    allCity((res) => {
                                        setloading(false)
                                        setAllCityStore(res)
                                        setCenterModel(false)
                                        setAddCity(false)
                                    })}else{
                                        setloading(false)
                                    }
                                })
                            } else if (addCity) {
                                setloading(true)
                                addCityData(({
                                    cityName: addCityName,

                                }), (rr) => {
                                    if(rr){
                                    allCity((res) => {
                                        setloading(false)
                                        setAllCityStore(res)
                                        setCenterModel(false)
                                        setAddCity(false)
                                    })}else{
                                        setloading(false)
 
                                    }
                                })
                            }}
                        }
                        style={{ background: place ? 'black':addCityName ?'#0086FF':"#D3D3D3", color: 'white', borderRadius: 5, alignItems: 'center', margin: "px 10px 10px 10px", padding: 5, marginRight: 15, 
                                paddingLeft: 20, paddingRight: 20,width:"6rem",height:"2.2rem" }}>Save</Button>
                    <div onClick={() => {
                        SetDeleteStore('location city')
                        setCenterModel(false)
                        setAddCity(false)
                      }} 
                     style={{ color: '#0086FF', borderRadius: 5, alignItems: 'center', margin: 10, padding: 5, borderWidth: 1, paddingLeft: 20, paddingRight: 20, cursor: 'pointer', border: "1px solid #0086FF" }}
                    >
                    Cancel</div>
                </div>
            </div>
        </div>
    </>);
}

export default AddCityModel;