 import {  Spin, Table, Tooltip, Typography } from "antd";
import { Footer } from "antd/lib/layout/layout";
import React from "react";
 import {useEffect,useState} from  "react";

 import "./BarGraph.css";
import Api from "../../services/Api";
import { getEachRateInfo } from "./Action";
import { useFPStore } from "./store";
import Modal from "../../components/Modal/Modal";
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";
import classes from './BarGraph.module.css';
 function BarGraph({dateMonth,selectedDept,data, graphHeight, TooltipComponent=null, title, barColor, tick=null, question, deptId, date}) {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [mxVal, setMaxVal] =useState(Math.max.apply(Math, data.map(item=>item.y)));
  const [modal,setModal]=useState(false)
  const [index,setIndex]=useState();
  const [loader,setLoader]=useState(false)
const [ratingData,setRatingData]=useState([]);
const {eachRateInfoHandler,eachRateInfo}=useFPStore();
const emoji=["😒","😧",'🤨',"😃","😍"];
const {width}=useWindowDimensions();





    const handler=(index)=>{
        setIndex(index);
        setModal(true);
        setLoader(true);
        // let a=date?.split("-");
        // let data={month:a[1],year:a[0],departmentId:deptId,questionIs:question,ratingValue:index+1}

  Api.get("/dashboard/graph/employees/").params(data).send((res)=>{
    setRatingData(res);
    setLoader(false)
  })

    }

    

    const columns = [
        {
            title: 'Associate',
            dataIndex: 'associateName',
            key: 'associate',
            render: (text, record) => {
                return (
                    <div >
                        <p style={{ font: 'normal normal medium 16px/21px Roboto', color: '#000000', opacity: '1' }}>
                            {text}</p>
                        {/* <p style={{ font: 'normal normal normal 12px/16px Roboto', color: '#000000', opacity: '1' }}>
                            {record?.empyId}</p> */}
                    </div>
                )
            }

        },
        {
            title: 'Team',
            dataIndex: 'team',
            key: "team",
            render: (text, record) => {

                return (
                    <div 
                        style={{ font: 'normal normal normal 12px/16px Roboto', color: '#000000', opacity: '1' }}>
                        {record?.teamDetails?.teamName?record?.teamDetails?.teamName:"NA"}</div>
                )
            }
        },
        {
            title: 'Manager',
            dataIndex: 'manager',
            key: "manager",
            render: (text, record) => {

                return (
                    <div 
                        style={{ font: 'normal normal normal 12px/16px Roboto', color: '#000000', opacity: '1' }}>
                       {record?.teamDetails?.managerName?record?.teamDetails?.managerName:"NA"}</div>
                )
            }
        },]




  useEffect(()=>{
    setMaxVal(Math.max.apply(Math, data.map(item=>item.y)));
  }, data);

let graphlength=Math.ceil(Math.ceil(mxVal/4)/5)*20;
graphlength=graphlength!==0?graphlength:20
  const oneBlock = parseFloat(graphlength/4).toFixed(4)
  data.forEach((item)=>{
      item.y = item.y?item.y:0
  })
  if (mxVal>=0){
    return (
        <>
        <Typography.Paragraph style={{ font: " 15px / 24px Roboto",fontWeight:'550',width:'100%'}} ellipsis={{tooltip:true,rows:2}}><span>{ question}</span></Typography.Paragraph> 
    <div style={{width:"100%", backgroundColor:'white',height:'450px',boxShadow: "0px 3px 6px #00000029"}}>
       
    <div style={{display:'flex',width:'100%'}}>
    <div  style={{ width:40}}><div style={{ transform: 'rotate(270deg)', marginTop: 10, width: 400, marginLeft: -180  }}>{title?.y}</div></div>
   <div style={{ display:'flex',flexDirection:'row',height:"100%", width:"100%", alignItems:"flex-end", backgroundColor:"white", paddingTop:10, paddingLeft:10,paddingBottom:10,}}>
            <div style={{paddingRight:10, borderRightWidth:0.5, borderColor:"#636363", }}>
                {Array(5).fill().map((item, index)=>{
                   if(index===0)
                   {
                        return(
                            <div style={{height:25,display:"flex", justifyContent:"flex-end", alignItems:"flex-end",}}>
                            
                            <div >{ oneBlock<1?parseFloat(oneBlock*(4-index)).toFixed(1): Math.round(oneBlock*(4-index))} {tick?tick:''}</div>
                        </div>
                        )
                   }
                   else{
                   return(
                        <div style={{height:Math.floor(graphHeight/4),display:"flex", justifyContent:"flex-end", alignItems:"flex-end",}}>
                            
                            <div >{ oneBlock<1?parseFloat(oneBlock*(4-index)).toFixed(1): Math.round(oneBlock*(4-index))} {tick?tick:''}</div>
                        </div>
                    )}
                
                })}
                <div style={{height:'30px'}}></div>
            </div>
            <div style={{position:"relative" , width:"100%",} }>
                <div  style={{display:'flex',flexDirection:'row', height:graphHeight+40 , alignItems:'flex-end',}}>
                    {
                            data?.map((item,index)=>{
                               
                               let ht=Math.floor(item.y*graphHeight/graphlength);
                               if(index===4)
                               {
                                console.log("GRAPPITEM",item,ht)
                               }
                               
                            return(
                               
                                <div  id={index} onClick={()=>{
                                    setIndex(index);
                                    setModal(true);
                                    setLoader(true);
                                    // let a=date?.split("-");
                                    // let data={month:a[1],year:a[0],departmentId:deptId,questionIs:question,ratingValue:index+1}
                                    getEachRateInfo((res)=>{
                                        eachRateInfoHandler(res);
                                        setLoader(false)
                                    },selectedDept,dateMonth,data[0]?.id, parseInt(index+1))
                            
                            //   Api.get("/dashboard/graph/employees/").params(data).send((res)=>{
                            //     setRatingData(res);
                            //     setLoader(false)
                            //   })
                                }} className="ratingHeight" style={{width:'100%', display:"flex", alignItems:"center",height:"100%",flexDirection:"column", paddingLeft:'5px',paddingRight:'5px', }}>
                                    <div underlayColor={'#E6E6E6'}  
                                    style={{  height:graphHeight, display:"flex", justifyContent:"flex-end",alignItems:'flex-end',}}>
                                       <Tooltip placement="right" title={item?.y}>  <div style={{backgroundColor:barColor, height:ht, width:20, borderRadius:'4px',cursor:'pointer',}}>
                                        </div> </Tooltip>
                                    </div>
                                    <div style={{fontSize:24 }}> {item.x} </div>
                                </div>
                               
                               
                            )
                        })
                    }
                   
                </div>
                <div style={{position:"absolute", borderTopWidth:0.5, borderColor:"#636363", width:"100%" ,display:'flex' ,bottom:20,}}>
                        
                        </div>
                {isTooltipVisible?<div style={{position:"absolute", top:0, left:10}}>{TooltipComponent}</div>:null}
            </div>
        </div> 
       
   </div> 
   <div style={{width:'100%',display:'flex', justifyContent:'center' }}>{title.x}</div>
    <Modal
        closableRight={true}
        header={<div style={{width:"100%",fontSize:"1.3rem",fontWeight:"700",}}>Ratings {emoji[index]}</div>}
        show={modal}
        onCancel={()=>{setModal(false); eachRateInfoHandler(undefined);}}
       footer={false} 
       style={{height:'80vh',width:width>700?"50vw":"90vw",overflowY:"auto", }}>           
                    {width>700?
                    // <Table
                    // columns={columns}
                    // dataSource={eachRateInfo}
                    // loading={loader}
                    // style={{maxHeight:""}}
                    // // loading={loader}
                    // scroll={{ y: 500 }}
                    // ></Table>
                        loader?<Spin style={{display:"flex",height:"20vh",alignItems:"center",justifyContent:"center"}}></Spin>:<section className={classes.associate}>
                            <div className={classes.header}>
                                <span>Associate</span>
                                <span>Team</span>
                                <span>Manager</span>
                            </div>

                            <div className={classes.mainContent}>
                                {eachRateInfo?.map((item,index)=>{
                                    return(
                                        <div
                                        className={classes.eachRow}
                                        >
                                            <div style={{display:"flex",flexDirection:"column"}}>
                                            <span style={{fontWeight:"600"}}>{item?.associateName}</span>
                                            <span style={{color:"#18181880",fontSize:"0.8rem"}}>{item?.uid}</span>
                                            </div>
                                            <span>{item?.teamDetails?.teamName?item?.teamDetails?.teamName:"NA"}</span>
                                            <span>{item?.teamDetails?.managerName?item?.teamDetails?.managerName:"NA"}</span>

                                        </div>
                                    )
                                })}

                            </div>

                        </section>

                    :
                    loader?<Spin style={{display:"flex",height:"30vh",alignItems:"center",justifyContent:"center"}}></Spin>:<div className="rate-details-wrapper">
                        {eachRateInfo?.map((item,index)=>(
                            <div key={index} className="rate-details">
                                <div>
                                    <span>Associate:</span>
                                    <div style={{display:"flex",flexDirection:"column"}}>
                                        <span>{item?.associateName}</span>
                                        <span>{item?.uid}</span>
                                    </div>
                                </div>
                                <div>
                                    <span>Team:</span>
                                    <span>{item?.teamDetails?.teamName?item?.teamDetails?.teamName:"NA"}</span>
                                </div>
                                <div>
                                    <span>Manager:</span>
                                    <span>{item?.teamDetails?.managerName?item?.teamDetails?.managerName:"NA"}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                    }
    </Modal>


   </div>
   </>
  
  

 
      )
  }else{
          return <div>No Data </div>
    }
}
export default BarGraph;


{/* <div style={{display:"flex",alignItems:"center",marginLeft:"-200px"}}>
<div className="titley" style={{backgroundColor:"red",display:"flex",width:"90%"}}>{title.y}</div>

</div>



<div style={{display:'flex', justifyContent:'center'}}>{title.x}</div></div> */}
