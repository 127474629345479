import React, { useEffect, useState } from "react";
import  classes from './EventModal.module.css';
import { Modal, Spin } from "antd";
import { useLoginStore } from "../store";
import { LeftOutlined, PlayCircleOutlined, RightOutlined } from "@ant-design/icons";
import back_icon from '../../../assets/backIcon.svg';
import axios from "axios";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import CoolLightbox from "../../../components/LightBox/LightBox";


export const EachFile=({file,files,setFile,setPage})=>{
    const [loader,setLoader]=useState(false);
    const {width}=useWindowDimensions();

    console.log("filesfilesfiles",files,file.index)
    useEffect(()=>{
        if(file?.type==="video")
        {
            setLoader(true);
            setTimeout(()=>{
                setLoader(false)
            },500);
        }  
    },[file])
   
    return(
        <div className={classes.file}>
             <div
             className={classes.backDiv}
              onClick={()=>{
                    setPage(1);
                }}>
                    <img src={back_icon}/>
                    <span>Back</span>
                </div>
            {
                loader?<Spin style={{display:"flex",alignItems:"center",justifyContent:"center",}}/>:
            file?.type==="video"?
             <video 
             width={'100%'}
            //  width={width>700?"530":"240"}
              controls autoPlay>
             <source src={files[file?.index]} type="video/mp4"/>
             
             </video>

            :<div>
                <img src={files[file?.index]}/>
            </div>}
            <div>
             
                <div style={{cursor:file.index===0?"not-allowed":"pointer"}} onClick={()=>{
                    if(file.index>0)
                    {
                        setFile(prev=>({type:prev.type, index:prev.index-1}))
                    }
                }}>
                {file.index!==0 &&  <>  <LeftOutlined/>
                   <span>Previous</span> 
                   </>}
                </div>
             
                <div  style={{cursor:file.index===files?.length-1?"not-allowed":"pointer"}} onClick={()=>{
                    if(file.index<files?.length-1)
                    {
                        setFile(prev=>({type:prev.type, index:prev.index+1}))
                    }
                }}>
                   
                   {file.index!==files.length-1 &&<>
                      <span>Next</span>
                   <RightOutlined/> 
                   </>}
                </div>
            </div>


        </div>
    )
}

const EventModal=({eventModalHandler,eventModal})=>{
    const [page,setPage]=useState(1);
    const [file,setFile]=useState({type:"",index:""})
    const {event}=useLoginStore();
    const [eachEvent,setEachEvent]=useState();
    {console.log("eventModaleventModal",eventModal)}
    const {width}=useWindowDimensions();

    //https://testassociate.brigosha.com test url
    //https://controlpanel.brigosha.com prod url

    useEffect(()=>{
    
        axios.get(`https://testassociate.brigosha.com/api/public-apis/eventData?eventId=${event?.id}`)
        .then(res=>{
            setEachEvent(res?.data);  
        })
        .catch(error=>{

        })
    },[event?.id])
    return(
       <Modal 
       open={eventModal}
       onCancel={()=>{
        eventModalHandler();
       }}
       footer={false}
       centered
       width={width>800?"60vw":"100vw"}
       bodyStyle={{margin:"3vmin"}}
       >
        {page===1&&
        
        <div className={classes.container}>
            <span>{event?.title}</span>
            <span>{event?.description}</span>
            <span>Images</span>
            <div className={classes.images}>
                {/* {eachEvent?.imageArray?.map((item,index)=>{
                    return(
                        <div key={index}>
                            <img src={item} onClick={()=>{
                                setFile({type:"image",index:index});
                                setPage(2);
                            }}/>
                        </div>
                    )
                })} */}
                {console.log("filessss",eachEvent?.imageArray)}
                <CoolLightbox files={eachEvent?.imageArray}/>
            </div>
            {eachEvent?.videoArray&&eachEvent?.videoArray.length>0&&<span>Videos</span>}
            {eachEvent?.videoArray&&eachEvent?.videoArray.length>0&&<div className={classes.videos}>
                {eachEvent?.videoArray?.map((item,index)=>{
                    return(
                        <div key={index}  onClick={()=>{
                            setFile({type:"video",index:index});
                            setPage(2);
                        }}
                        style={{position:"relative"}}
                        >
                            <PlayCircleOutlined  style={{position:'absolute',top:'50%',left:'50%',transform:"translate(-50%,-50%)",fontSize:'30px',color:'gray'}}/>
                            <video width="100%">
                                <source src={item} type="video/mp4"/>
                                
                                </video>
                        </div>
                    )
                })}
            </div>}

        </div>}

        {page===2&&<EachFile files={file?.type==="image"?eachEvent?.imageArray:eachEvent?.videoArray} file={file} setFile={setFile} setPage={setPage}/>}

       </Modal>
    )
}

export default React.memo(EventModal);