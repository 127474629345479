import React from 'react'

import SearchIcon from '../../../assets/search_Icon.svg'

const SearchCustom = ({style,placeholder,className,value,setValue,onChange}) => {
    const onSearch = (val) =>{
        setValue(val.target.value)
       
        if(onChange)
        {
          onChange(val.target.value);
        }
    }
  return (
    <div style={{width:'100%',height:'4vh',padding:'0.5rem',border:'1.5px solid #bebebe',borderRadius:'5px',...style}}>
    <div style={{height:'100%',width:'100%', display:'flex',alignItems:'center',justifyContent:'flex-start'}}>
        <img src={SearchIcon} style={{width:'18px',height:'18px',marginRight:'0.5rem'}}/>
        <input style={{border:'none',width:'100%',height:'100%',boxShadow:'none'}} placeholder={placeholder} className={className} value={value} onChange={onSearch} />
    </div>
    </div>
  )
}

export default SearchCustom