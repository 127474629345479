import { create } from "zustand";

export const DashboardStore = (set) => ({
  dashboardMainPage: "mainPage",
  dashboardPage: "",
  selectedTeamsDepartmentId:0,
  changePage: (page) => set((state) => ({ dashboardMainPage: page })),
  changeTablePage: (page) => set((state) => ({ dashboardPage: page })),
  setTeamsDepartmentFilter: (id) => set((state)=>({selectedTeamsDepartmentId: id}))
});

