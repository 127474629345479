import React, { useEffect, useState } from 'react';
import '../../../design/framework.css';
import { Input, Button } from 'antd';
import { DatePicker, Select } from 'antd';
import { allCity, allHolidays,allDepartmentList,allAssociateList,allHolidaysDeptAssociate,allDeptandAssociate } from './Action/action';
import { addHoliday } from './Action/action';
import { useNotificationStore } from './store';
import calenderIcon from "../../../assets/calendarSetting.svg";

const Option = Select.Option
const InputComponent = ({ width, borderRadius, borderColor, marginTop, value, onChange, numericOnly,placeholder }) => {
  const handleKeyPress = (e) => {
    if (numericOnly) {
      const keyCode = e.keyCode || e.which;
      const keyValue = String.fromCharCode(keyCode);
      const numericRegex = /^[0-9]*$/;
      const dotRegex = /\./;
      const currentValue = e.target.value;

      if (!numericRegex.test(keyValue) && !(keyValue === '.' && !dotRegex.test(currentValue))) {
        e.preventDefault();
      }
    }
  };

  return (
    <div style={{ width: width, borderRadius: borderRadius, borderColor: borderColor, marginTop: marginTop }}>
      <Input value={value} onChange={onChange} placeholder={placeholder} onKeyPress={handleKeyPress} />
    </div>
  );
};

const TextComponent = ({ text, width, fontSize, backgroundColor, marginLeft, color, marginRight, fontWeight }) => {
  return (
    <div style={{ fontSize: '20px', width: width, fontSize: fontSize, marginLeft: marginLeft, color: color, marginRight: marginRight, fontWeight: fontWeight }}>
      {text}
    </div>
  );
};

const AddHolidayModel = ({ setCenterModel, setHoliday }) => {
  const [formData, setFormData] = useState([{ accountOf: '', date: '', typeOfLeave: '', location: '' }]);
  const [CityList, setCityList] = useState([])

  // const [refresh, setRefresh] = useState(true)
  const { allCityStore, setAllCityStore,allDepartment,setAllDepartment,allAssociate,setAllAssociate,load,
    setLoad, fromHoliday,settypeLoactionStore,fixedStore,setRefresh,refresh,holidayListBy,setAllDeptandAssociate,
    searchVal,totalItems, setTotalItems,currentPageHoliday,pageSizeHoliday,setPageSizeHoliday } = useNotificationStore();


  const [details, setDetails] = useState({
    accountOf: '',
    date: '',
    cityId: fixedStore?.type === 'Optional' || 'Fixed' ? fixedStore?.id : null,
    typeOfLeave: fixedStore?.type === 'Optional' ? 'Optional' : 'Fixed',
    basedOnLeave: 'Location',
    departmentIds: fixedStore?.type === 'Optional' || 'Fixed' ? fixedStore?.id : null,
    associatesIds: fixedStore?.type === 'Optional' || 'Fixed' ? fixedStore?.id : null,
  });

  useEffect(() => {
    let activeLocations=[];
    setRefresh(true)
    allCity((res) => {
      if (res) {
        setRefresh(false)
        // if (searchVal) {
        //         let filteredData = res.filter((item) =>
        //         item.cityName.toLowerCase().includes(searchVal.toLowerCase())
        //         );
            // setAllCityStore(filteredData);
            activeLocations = res.filter(location => location.status === 'Active');
            setCityList(activeLocations);
        // }
        // else{
        //     // setAllCityStore(res)
        // }  
      } 
      // setRefresh(false)
      // activeLocations = res.filter(location => location.status === 'Active');
      // setAllCityStore(activeLocations);
      // setAllCityStore(res)
    })
    allDepartmentList((res) => {
      setRefresh(false)
      setAllDepartment(res)
    })
    allAssociateList((res) => {
      
      setRefresh(false)
      setAllAssociate(res)
    })
  }, [])

  // useEffect(() => {
  //   let options = []
  //   options = allCityStore?.map((city) => ({
  //     value: city?.location,
  //     label: city?.location,
  //   }));
  //   setOptions([...options])
  // }, [allcity]);

  const isSaveButtonDisabled = fixedStore?.type == 'Add' ? !(details.accountOf && details.date && (details.cityId||details?.departmentIds||details?.associatesIds) && details.typeOfLeave) 
                              : !(details.accountOf && details.date && details.accountOf.trim() !=="" );


  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>

        <div className='r-jc' style={{ display: 'flex',justifyContent:"flex-start",flexDirection:"row",fontSize:"17px"}}>
        <div className='r-jc' style={{ display: 'flex', alignItems: 'start', justifyContent: 'start' }}>
          <img src={calenderIcon} className='p-2' style={{color:'#0086FF',marginRight:"0.4rem"}} />
          <TextComponent className='p-2' text="Add Holiday" color={'#0086FF'} fontWeight={700} />
        </div>
        </div>
        <div style={{ width: '100%', height: 0.8, backgroundColor: '#F1F1F1', marginTop: 10 }} />

        {fixedStore?.type === 'Add' ?
        <div style={{height:"68vh",overflow:"auto"}}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 20 }}>
          <TextComponent text="Holiday Name" fontWeight={500} fontSize={14} />
          <InputComponent
            borderRadius={10}
            placeholder="Enter holiday name"
            borderColor={'#E6E6E6'}
            marginTop={10}
            value={details.accountOf}
            onChange={(e) => setDetails({ ...details, accountOf: e.target.value })}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 20 }}>
          <TextComponent text="Date" fontWeight={500} fontSize={14} />
          <DatePicker
            style={{ marginTop: 10 }}
            format={'DD-MM-YYYY'}
            onChange={(date, dateString) => {
              setDetails({ ...details, date: dateString });
            }}
          />
        </div>

        {fixedStore?.type === 'Add' &&
          <>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 20 }}>
              <TextComponent text="Holiday Type" fontWeight={500} fontSize={14} />

              <Select
                defaultValue="Fixed"
                style={{ marginTop: 10 }}
                onChange={(value) => setDetails({ ...details, typeOfLeave: value })}
                options={[
                  { value: 'Fixed', label: 'Fixed' },
                  { value: 'Optional', label: 'Optional' },
                ]}
              />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 20 }}>
              <TextComponent text="Holiday Determined By" fontWeight={500} fontSize={14} />
              <Select
                defaultValue="Location"
                style={{ marginTop: 10 }}
                onChange={(value) => setDetails({ ...details, basedOnLeave: value,cityId: "",departmentIds:[],associatesIds:[] })}
                options={[
                  { value: 'Location', label: 'Location' },
                  { value: 'Department', label: 'Department' },
                  { value: 'Associate', label: 'Associate' },
                ]}
              />
            </div>

            {details.basedOnLeave === "Location" ?
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 20 }}>
              <TextComponent text="Location City Name" fontWeight={500} fontSize={14} />
              <Select
                style={{ marginTop: 10 }}
                placeholder="Select location"
                value={details?.cityId}
                onChange={(value) => setDetails({ ...details, cityId: value })}
                tokenSeparators={[',']}
              >
                {CityList?.map((item) => (
                  <Option key={item?.id}>{item?.cityName}</Option>
                ))}
              </Select>
            </div>
            :details.basedOnLeave === "Department" ?
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 20 }}>
              <TextComponent text="Department Name" fontWeight={500} fontSize={14} />
              <Select
                style={{ marginTop: 10 }}
                placeholder="Select department"
                value={details?.departmentIds}
                mode='multiple'
                showSearch
                optionFilterProp="children"
                onChange={(value) => {
                setDetails({ ...details, departmentIds: value })
                }}
              >
                {allDepartment?.map((item) => (
                  
                  <Option key={item?.id} value={item?.id}>{item?.name}</Option>
                ))}
              </Select>
            </div>
            :
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 20 }}>
              <TextComponent text="Associate Name" fontWeight={500} fontSize={14} />
              <Select
                style={{ marginTop: 10 }}
                placeholder="Select associate"
                // loading={refresh}
                value={details?.associatesIds}
                mode='multiple'
                showSearch
                optionFilterProp="children"
                onChange={(value) => {
                  setDetails({ ...details, associatesIds: value })
                }}
              >
                {allAssociate?.map((item) => (
                  <Option key={item?.id} value={item?.id}>{item?.name}</Option>
                ))}
              </Select>
            </div>
            }
          </>
        }
        </div>
        :
        <div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 20 }}>
          <TextComponent text="Holiday Name" fontWeight={500} fontSize={14} />
          <InputComponent
            borderRadius={10}
            placeholder="Enter holiday name"
            borderColor={'#E6E6E6'}
            marginTop={10}
            value={details.accountOf}
            onChange={(e) => setDetails({ ...details, accountOf: e.target.value })}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 20 }}>
          <TextComponent text="Date" fontWeight={500} fontSize={14} />
          <DatePicker
            style={{ marginTop: 10 }}
            format={'DD-MM-YYYY'}
            onChange={(date, dateString) => {
              setDetails({ ...details, date: dateString });
            }}
          />
        </div>
        </div>
        }

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
          <Button type='primary'
            disabled={isSaveButtonDisabled}
            style={{borderRadius: 5, alignItems: 'center',width:"6rem",  margin: 10, padding: 5, borderWidth: 1, paddingLeft: 20, paddingRight: 20,}}
            onClick={() => {
              // setRefresh(true);
              let params = {};
              if (details.basedOnLeave === 'Location' && fixedStore?.holidayBy !== "Associate"&&fixedStore?.holidayBy !== "Department" ) {
                params={
                  accountOf: details.accountOf,
                  date: details.date,
                  typeOfLeave: details.typeOfLeave,
                  cityId: details.cityId, 
                }
              }
              else if (details.basedOnLeave === 'Department'){
                params={
                  accountOf: details.accountOf,
                  date: details.date,
                  typeOfLeave: details.typeOfLeave,
                  departmentIds : details.departmentIds, 
                }
              }
              else if (details.basedOnLeave === 'Associate'){
                params={
                  accountOf: details.accountOf,
                  date: details.date,
                  typeOfLeave: details.typeOfLeave,
                  associatesIds : details.associatesIds, 
                }
              }
              else if (fixedStore?.holidayBy === 'Location'){
                params={
                  accountOf: details.accountOf,
                  date: details.date,
                  typeOfLeave: details.typeOfLeave,
                  cityId: details.cityId, 
                }
              }
              else if (fixedStore?.holidayBy === 'Department'){
                params={
                  accountOf: details.accountOf,
                  date: details.date,
                  typeOfLeave: details.typeOfLeave,
                  departmentIds : [details.departmentIds], 
                }
              }
              else if (fixedStore?.holidayBy === 'Associate'){
                params={
                  accountOf: details.accountOf,
                  date: details.date,
                  typeOfLeave: details.typeOfLeave,
                  associatesIds : [details.associatesIds], 
                }
              }
              addHoliday(params, (res) => {
                if(res?.show?.type !== "error"){
                  if(fromHoliday && fixedStore?.type !== 'Add'){
                    let url = ""
                    setLoad(true);
                    if (holidayListBy === 'Location') {
                      url = `/attendance-setting/allHolidays?pageNo=${1}&limit=${100}&download=${false}&holidayType=${fixedStore?.type}&cityId=${fixedStore?.id}`;
                      allHolidays(url,(res)=>{
                        settypeLoactionStore(res)
                        setLoad(false)
                      })
                    }
                    else if (holidayListBy === 'Department'){
                      let url = `/attendance-setting/getHoidayDataByType?holidayType=${fixedStore?.type}&id=${fixedStore?.id}&type=${holidayListBy}`;
                                        
                      allHolidaysDeptAssociate(url,(res)=>{
                        settypeLoactionStore(res)
                        setLoad(false)
                      })                  
                    }
                    else if (holidayListBy === 'Associate'){
                      let url = `/attendance-setting/getHoidayDataByType?holidayType=${fixedStore?.type}&id=${fixedStore?.id}&type=${holidayListBy}`;
                                        
                      allHolidaysDeptAssociate(url,(res)=>{
                        settypeLoactionStore(res)
                        setLoad(false)
                      })
                    }
                    
                    setHoliday(false);
                    setCenterModel(false);
                  }
                  else if (fromHoliday && fixedStore?.type === 'Add') {
                    // setLoad(true);
                    let url = `/attendance-setting/allHolidays?pageNo=${1}&limit=${10}&download=${false}&holidayType=${fixedStore?.type}&cityId=${fixedStore?.id}`;
                    // allHolidays(url,(res)=>{
                    //     settypeLoactionStore(res)
                    //     setLoad(false)
                    // })
                    setHoliday(false);
                    setCenterModel(false);
                  } 
                  else {
                    setRefresh(true)
                  if (holidayListBy === 'Location') {
                      allCity((res) => {
                        // if (res) {
                        // setAllCityStore(res)
                        // setRefresh(false);
                        // }
                        if (res) {
                          setRefresh(false)
                          if (searchVal) {
                                  let filteredData = res.filter((item) =>
                                  item.cityName.toLowerCase().includes(searchVal.toLowerCase())
                                  );
                              setAllCityStore(filteredData);
                          }
                          else{
                              setAllCityStore(res)
                          }  
                        } 
                      })
                  }else{
                      allDeptandAssociate(holidayListBy,
                        currentPageHoliday,
                        pageSizeHoliday,
                        searchVal,
                    (res)=>{
                        if (res) {
                        setRefresh(false)
                        setAllDeptandAssociate(res?.items)
                        setTotalItems(res?.meta)
                    }})
                  } 
                    setHoliday(false);
                    setCenterModel(false);
                  
                  }
                } 
              });

            }}
          >
            Save
          </Button>
          <Button onClick={() => {
            setHoliday(false)
            setCenterModel(false)
          }} style={{ color: '#0086FF', borderRadius: 5, alignItems: 'center', margin: 10, padding: 5, borderWidth: 1, paddingLeft: 20, paddingRight: 20, border: "1px solid #0086FF" }}>Cancel</Button>

        </div>


      </div>
    </>
  );
};

export default AddHolidayModel;
