import React, { useEffect } from "react";
import ApplyFilter from "../../components/FilterModal/ApplyFilter";

import closeIcon from "../../../../assets/closeIcon.svg";
import filterIcon from "../../../../assets/filter.svg";
import { Button, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { adminActions } from "../store";
const FilterModal = ({ visible, setVisible }) => {
  const {
    tabState,
    appraisalFilter,
    designationFilter,
    cycleFilter,
    formStatusFilter,
    managerFormStatusFilter,
    summaryNotificationFilter,
    designationList,
    cycleList,
    statusList,
    managerStatusList,
    sorting,
    emailSort,
    sortByEmployeeID,
    roleFilter,
  } = useSelector((state) => state.admin);
  const [initialFilter, setInitialFilter] = useState({
    appraisalFilter: appraisalFilter,
    designationFilter: designationFilter,
    cycleFilter: cycleFilter,
    formStatusFilter: formStatusFilter,
    managerFormStatusFilter: managerFormStatusFilter,
    roleFilter: roleFilter,
    summaryNotificationFilter: summaryNotificationFilter,
    
  });

  console.log("sorting,sortByEmployeeID",sorting,sortByEmployeeID)
  const [initialSortState, setInitialSortState] = useState({
    sortByName: sorting,
    sortByEmail: emailSort,
    sortByEmployeeID: sortByEmployeeID,
  });
  //   const [reset,setReset] = useState()
  const [sortByNameChanged, setSortByNameChanged] = useState(false);
  const [sortByIDChanged, setSortByIDChanged] = useState(false);
  const [filterStatusChanged, setFilterStChanged] = useState(false);
  const [filterLevelChanged, setFilterLevelChanged] = useState(false);
  const [filterFormChanged, setFilterFormChanged] = useState(false);
  const [filterManagerFormChanged, setFilterManagerFormChanged] = useState(false);
  const [filterCycleChanged, setFilterCycleChanged] = useState(false);
  const [filterSummaryChanged, setfilterSummaryChanged] = useState(false);

  const formItems = {
    width: "90%",
    // height: "10vh",
    margin: "0 1rem",
    // border: "1px solid black",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap:"0.5rem"

  };

  const { Option } = Select;

  const h5 = { fontSize: "1rem", fontWeight: "600" };

  const appraisalOptions = {
    active: "Active",
    inactive: "Inactive",
  };

  const sortOptions = {
    ASC: "Ascending",
    DESC: "Descending",
  };

  const summaryNotificationStatusList = {
    notSent: "Not Sent",
    approved: "Approved",
    rejected: "Rejected",
  };

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("Sorting Filter by name", initialSortState);
  }, [initialSortState]);

  const handleDesignationFilter = (value) => {
    dispatch(adminActions.updatePage({ page: 1, pageSize: 30 }));
    if (tabState === "active users") {
      setInitialFilter({
        ...initialFilter,
        appraisalFilter: true,
        cycleFilter: initialFilter?.cycleFilter,
        designationFilter: value,
      });
    } else {
      setInitialFilter({ ...initialFilter, designationFilter: value });
    }
    setFilterLevelChanged(!filterLevelChanged);
  };

  const handleAppraisalfilter = (value) => {
    dispatch(adminActions.updatePage({ page: 1, pageSize: 30 }));
    if (tabState === "active users") {
      setInitialFilter({
        ...initialFilter,
        appraisalFilter: true,
        cycleFilter: initialFilter?.cycleFilter,
      });
    } else {
      setInitialFilter({
        ...initialFilter,
        appraisalFilter:
          value === undefined ? "" : value === "Active" ? true : false,
      });
    }

    setFilterStChanged(!filterStatusChanged);
  };

  const handleCycleFilter = (value) => {
    dispatch(adminActions.updatePage({ page: 1, pageSize: 30 }));
    setInitialFilter({ ...initialFilter, cycleFilter: value });
    setFilterCycleChanged(!filterCycleChanged);
  };

  const handleSortByName = (value) => {
    // value === sorting
    //   ? dispatch(
    //       adminActions.updateSortFilter({ ...{}, sortByName: undefined })
    //     )
    //   : dispatch(adminActions.updateSortFilter({ ...{}, sortByName: value }));
    // dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
    setInitialSortState({
      sortByEmail:sortByEmployeeID?.sortByEmail,
      sortByEmployeeID: initialSortState?.sortByEmployeeID,
      sortByName: value,
    });
    // setSortByIDChanged(false);
    if(!value)
    {
      setSortByNameChanged(false);
      return;
    }
    setSortByNameChanged(true);
   
  };

  const handleSortByID = (value) => {
    setInitialSortState({
      sortByEmail: initialSortState?.sortByEmail,
      sortByName: initialSortState?.sortByName,
      sortByEmployeeID: value,
    });
    // setSortByNameChanged(false);
    if(!value)
    {
      setSortByIDChanged(false);
      return;
    }
    setSortByIDChanged(true);
  };

  const handleFormStatusFilter = (value) => {
    dispatch(adminActions.updatePage({ page: 1, pageSize: 30 }));
    if (tabState === "active users") {
      setInitialFilter({
        ...initialFilter,
        appraisalFilter: true,
        cycleFilter: initialFilter?.cycleFilter,
        formStatusFilter: value,
      });
    } else {
      setInitialFilter({ ...initialFilter, formStatusFilter: value });
    }
    setFilterFormChanged(!filterFormChanged);
  };

  const handleManagerFormStatusFilter = (value) => {
    dispatch(adminActions.updatePage({ page: 1, pageSize: 30 }));
    if (tabState === "active users") {
      setInitialFilter({
        ...initialFilter,
        appraisalFilter: true,
        cycleFilter: initialFilter?.cycleFilter,
        managerFormStatusFilter: value,
      });
    } else {
      setInitialFilter({ ...initialFilter, managerFormStatusFilter: value });
    }
    setFilterManagerFormChanged(!filterManagerFormChanged);
  };

  const handleRoleFilter = (value) => {
    dispatch(adminActions.updatePage({ page: 1, pageSize: 30 }));
    if (tabState === "active users") {
      setInitialFilter({
        ...initialFilter,
        appraisalFilter: true,
        cycleFilter: initialFilter?.cycleFilter,
        roleFilter: value,
      });
    } else {
      setInitialFilter({ ...initialFilter,   roleFilter: value, });
    }
    setFilterFormChanged(!filterFormChanged);
    setFilterManagerFormChanged(!filterManagerFormChanged);
  };

  const handleReset = () => {
    setInitialFilter({
     
      appraisalFilter:tabState === "active users"?true:tabState === "inactive users"?false: null,
      designationFilter: null,
       cycleFilter:tabState === "active users"?"October": null,
      formStatusFilter: null,
      managerFormStatusFilter: null,
      summaryNotificationFilter: null,
      roleFilter:null,
    });
    setInitialSortState({
      sortByEmail:undefined,
      sortByEmployeeID:undefined,
      sortByName: undefined,
    });
    setFilterStChanged(false);
    dispatch(adminActions.updateFilter({
      appraisalFilter:tabState === "active users"?true:tabState === "inactive users"?false: null,
      designationFilter: null,
      cycleFilter:tabState === "active users"?"October": null,
      formStatusFilter: null,
      managerFormStatusFilter: null,
      summaryNotificationFilter: null,
      roleFilter:null
    }));
    dispatch(adminActions.updateSortFilter({
      sortByEmail:undefined,
      sortByEmployeeID:undefined,
      sortByName: undefined,
    }))
    dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
    setVisible(false);
  };

  const handleSubmit = () => {
    dispatch(adminActions.updateFilter(initialFilter));
    dispatch(adminActions.updateSortFilter(initialSortState))
    dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
    setVisible(false);
  };

  return (
    <>
    <ApplyFilter visible={visible} setVisible={setVisible}>
      <div style={{ margin: "0.5rem", height:"95vh",overflowY:"auto"}}>
        <div
          style={{
            // border: "1px solid black",
            width: "100%",
            // height: "6vh",
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            style={{
              border: "none",
              background: "none",
              boxShadow: "none",
            }}
            onClick={() => {
              setVisible(false);
            }}
          >
            <img src={closeIcon} />
          </Button>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            {" "}
            {/*Header*/}
            <img
              src={filterIcon}
              style={{ width: "1.5rem", height: "1.5rem" }}
            />
            <h2>Apply filters</h2>
          </div>
        </div>
        <div style={{display:"flex",flexDirection:"column",gap:"2rem",margin:"2rem 0 0 0"}}>
              <div
                style={{
                  // border: "1px solid black",
                  width: "100%",
                  // height: "75%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap:"1.5rem"
                }}
              >
                <div style={formItems}>
                  <h5 style={h5}>Sort by name</h5>
                  <Select
                    placeholder="Select option to sort"
                    value={
                    
                        initialSortState.sortByName === "ASC"
                          ? sortOptions.ASC
                          :initialSortState.sortByName === "DESC"? sortOptions.DESC
                        : null
                    }
                    allowClear={sortByNameChanged}
                    onChange={handleSortByName}
                  >
                    {console.log("sortByIDChanged",sortByIDChanged)}
                    {Object.keys(sortOptions).map((item, index) => (
                      <Option key={index} value={item}>
                        {sortOptions[item]}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div style={formItems}>
                  <h5 style={h5}>Sort by employee ID</h5>
                  <Select
                    placeholder="Select option to sort"
                    value={
                      
                        initialSortState.sortByEmployeeID === "ASC"
                          ? sortOptions.ASC
                          :  initialSortState.sortByEmployeeID === "DESC"?sortOptions.DESC
                        : null
                    }
                    allowClear={sortByIDChanged}
                    onChange={handleSortByID}
                  >
                    {Object.keys(sortOptions).map((item, index) => (
                      <Option key={index} value={item}>
                        {sortOptions[item]}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div style={formItems}>
                  <h5 style={h5}>Associate Level</h5>
                  <Select
                    placeholder="Select option to filter"
                    value={initialFilter?.designationFilter?initialFilter?.designationFilter:undefined}
                    allowClear={filterLevelChanged}
                    onChange={handleDesignationFilter}
                  >
                    {Object.values(designationList).map((item, index) => (
                      <Option key={index} value={item} />
                    ))}
                  </Select>
                </div>
                <div style={formItems}>
                  <h5 style={h5}>Appraisal Cycle</h5>
                  <Select
                    placeholder="Select option to filter"
                    value={initialFilter?.cycleFilter?initialFilter?.cycleFilter:undefined}
                    onChange={handleCycleFilter}
                    allowClear={filterCycleChanged}
                  >
                    {Object.values(cycleList).map((item) => (
                      <>
                        <Option
                          value={item?.appraisalCycle}
                          label={item?.appraisalCycle}
                          title=""
                        >
                          {item?.appraisalCycle}
                        </Option>
                      </>
                    ))}
                  </Select>
                </div>
                <div style={formItems}>
                  <h5 style={h5}>Associate's Form Status</h5>
                  <Select
                    placeholder="Select option to filter"
                    value={initialFilter?.formStatusFilter?initialFilter?.formStatusFilter:undefined}
                    allowClear={filterFormChanged}
                    onChange={handleFormStatusFilter}
                  >
                    {(Object.values(statusList)).map((item) => (
                      <Option value={item} label={item} title="" />
                    ))}
                  </Select>
                </div>
                <div style={formItems}>
                  <h5 style={h5}>Manager's Form Status</h5>
                  <Select
                    placeholder="Select option to filter"
                    value={initialFilter?.managerFormStatusFilter?initialFilter?.managerFormStatusFilter:undefined}
                    allowClear={filterManagerFormChanged}
                    onChange={handleManagerFormStatusFilter}
                  >
                    {(Object.values(managerStatusList)).map((item) => (
                      <Option value={item} label={item} title="" />
                    ))}
                  </Select>
                </div>
                <div style={formItems}>
                  <h5 style={h5}>Status</h5>
                  <Select
                    placeholder="Select option to filter"
                    value={
                    
                        initialFilter?.appraisalFilter===true
                          ? "Active"
                          : initialFilter?.appraisalFilter===false? "Inactive":null
                        
                    }
                    allowClear={filterStatusChanged}
                    onChange={handleAppraisalfilter}
                  >
                    {Object.values(appraisalOptions).map((item) => (
                      <Option value={item} label={item} title="" />
                    ))}
                  </Select>
                </div>

                <div style={formItems}>
                  <h5 style={h5}>Role</h5>
                  <Select
                    placeholder="Select option to filter"
                    value={initialFilter?.roleFilter?initialFilter?.roleFilter:undefined}
                    allowClear={filterStatusChanged}
                    onChange={handleRoleFilter}
                  >
                    {["Manager","Associate","Department Head"].map((item) => (
                      <Option value={item} label={item} title="" />
                    ))}
                  </Select>
                </div>

              </div>
              <div
                style={{
                  // border: "1px solid black",
                  width: "100%",
                  // height: "10vh",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginTop:"auto"
                }}
              >
                {/** Footer */}
                <Button
                  type="default "
                  style={{ width: "10rem" }}
                  onClick={handleReset}
                >
                  Reset
                </Button>
                <Button
                  onClick={handleSubmit}
                  type="primary"
                  style={{ width: "10rem" }}
                >
                  Apply
                </Button>
              </div>
        </div>
      </div>
    </ApplyFilter>
    </>
  );
};

export default FilterModal;
