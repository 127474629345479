import styled from 'styled-components';
import { Layout } from 'antd';

const { Header } = Layout;

export const NavbarContents = styled(Header)`
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 2;
    align-items: center;
    padding: 0 10vw;
    font-weight: 400;
    .navbar-links{
        display: flex;
        justify-content: space-around;
        align-items: center;
        list-style-type: none;
        width: 100%;
        padding: 0;
    }
  
  

    .Menu{
        border-bottom: 1px solid #EEEEEE;
        height: 79px;
        background-color:white;
    }

    .header-nav-icon{
        display: flex;
        justify-content: start;
        align-items: center;
        padding: 0;
        background-color: white;
    }

    .header-company-icon{
        width: auto;
        padding: 5px;
    }

    .navbar-links li{
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .menu-content{
        height: 78px;
    }

    .navTabs{
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        height: 78px;
        font-size: calc(1vw + 8px);
        font-weight: 700;
        font-family: 'Sofia Sans', sans-serif;
    }

    .active{
        border-bottom: 4px solid #0086FF;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        font-weight: 700;
        color: #0086FF;
        height:50px;
        font-family: 'Sofia Sans', sans-serif;
    } 

    .hamburger-icon{
        display: none;
        margin-right: 2vw;
        margin-left: -5vw ;
        cursor: pointer;
    }

   

    .hamburger-icon img{
        width: calc(2vw + 15px);
    }

    .sidemenu{
        position: fixed;
        top: 0;
        font-size: calc(1vw + 12px);
        font-family: 'Sofia Sans', sans-serif;
        width: 100%;
        z-index: 200;
        opacity: 0;
        background-color: #fff;
        height: 105vh;
        border-bottom: none;
        left: -100%;
        transition: all 0.8s ease-out;
        padding: 20px 4vw;
        overflow-y:scroll;
        margin-top:-10vh;
    }
  
 

    .sidemenu .sidemenu-close-btn{
        cursor: 'pointer';
        // position: absolute;
        // top: 10px;
        // right: 30px;
        // border:2px solid red;
    }
    
    .sidemenu .sidemenu-options{
        list-style: none;
        font-weight: 700;
        color: black;
        display: flex;
        flex-direction: column;
        
    }

    .sidemenu .sidemenu-suboptions{
        list-style: none;
        font-weight: 400;
        display: flex;
        flex-direction: column;
         font-family: 'Sofia Sans', sans-serif;
        padding: 0
    }

    .sidemenu .options{
        color: black;
    }

    @media screen and (max-width: 767px){
        .navTabs{
            font-size: calc(1vw + 11px);
        }
    }

    @media screen and (max-width: 700px){   
        height: 0px !important;
        filter: drop-shadow(2px 2px 24px rgba(0, 0, 0, 0.08)) !important;
        .navbar-links{
            display: none;
        }
       
        .menu-content{
            height: 64px !important;
        }

        .Menu{
            height: 64px !important;
        }

        .header-company-icon{
            width: auto;
            padding: 5px;
        }

        .hamburger-icon{
            display: block;
            float:right;
        }
        .header-nav-icon{
          flex:1;
          justify-content:space-between;
          max-width:100%;
        }
        .menu-content{
            display:none;
        }
        .sidemenu.active{
            left: 0;
            opacity: 1;
            top:0;
            right:0;
            bottom:0;
            transition: all 0.8s ease;
        }

    }

`
export const SubNavbarContents = styled(Header)`
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 2;
    align-items: center;
    padding: 0 10vw;
    font-weight: 400;

    .subMenu{
         border-bottom: 1px solid #EEEEEE;
          background-color:white;

       
    }

    .hamburger-icon img{
        width: calc(2vw + 15px);
    }

    .sidemenu{
        position: absolute;
        top: 0;
        font-size: calc(1vw + 12px);
        width: 100%;
        z-index: 200;
        opacity: 0;
        background-color: white;
        height: 100vh;
        border-bottom: none;
        left: -100%;
        transition: all 0.8s ease-out;
        padding: 80px 5vw;
        overflow-y: none;
    }

    .sidemenu .sidemenu-close-btn{
        cursor: 'pointer';
        // position: absolute;
        // top: 10px;
        // right: 30px
    }
    
    .sidemenu .sidemenu-options{
        list-style: none;
        font-weight: 700;
        color: black;
        display: flex;
        flex-direction: column;
        
    }

    .sidemenu .sidemenu-suboptions{
        list-style: none;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        padding: 0
    }

    .sidemenu .options{
        color: black;
    }

 

    @media screen and (max-width: 425px){   
       
        display:none !important;
    
    }
`