import React, { useEffect, useState } from "react";
import { Button, Collapse, Table, Select, Tooltip } from "antd";
import { RiFilter2Fill } from "react-icons/ri";
import { AiOutlineVerticalAlignBottom } from "react-icons/ai";

import { DownLoadLogs, getFilterValues, getLogsData } from "../action/action";
import { useProjecteStore } from "../store/store";
import { Spin } from "antd";
import PluseIcon from "../../../assets/pluseIcon.svg";
import MinusIcon from "../../../assets/minusIcon.svg";
import { getLogsDepartmentApi, getLogsList } from "./Action";
import { useLogStore } from "./Store";
import edit_blue_icon from '../../../assets/edit_blue_icon.svg';
import editBlackIcon from '../../../assets/edit_black_icon.svg';
import ApplyFiltersModalG from "../../../components/AppyFiltersModal/applyFilterModalGlobal"
import DetailsModal from "./DetailsModal";
import moment from "moment";
import { SECRET_KEY, decryptData } from '../../../services/fileCode';
import Modal from "../../../components/Modal/Modal";
import classes2 from './DeatilsModal.module.css';
import viewClass from "../../../services/ViewRole";
import Filters from '../../../assets/filterBlack.svg'
import Downl from '../../../assets/downloadBlue.svg'
// import downloadIcon from "../../../assets/download_Icon_black.svg";

import Filter from "../../../assets/filter.svg";


import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import { MobileCard } from "../Release/Release";
import { NoData } from "../../Training_essentialDocuments";
import { ResetButton } from "../../../components/Button/Button";
import { getSecDeptAndTeam, getTeamsList } from "../Team/Action";
import SearchCompo from "../../../design/SearchCompo";
import ListViewLogData from "./logsListView";



const { Panel } = Collapse;


const Logs = ({ setRender, setValues, values, roleName, setRoleName, filter, setFilter }) => {
  const user = decryptData(localStorage.getItem('user'), SECRET_KEY);
  const [openDetails, setOpenDetails] = useState(false);
  const [isApplyFiltersModal, applyFiltersModalHandler] = useState(false);
  const [centerModel, setCenterModel] = useState(false);
  const [activeDept, setActiveDept] = useState([]);
  const [activeTeam, setActiveTeam] = useState([]);
  const [activeDate, setActiveDate] = useState([]);
  const [logDate, setLogDate] = useState();
  const [tabs, setTabs] = useState("Log");
  const [depts, setDepts] = useState([])
  const [teamsDD, setTeamsDD] = useState([])
  const [search, setSearch] = useState("")
  const [ids, setIds] = useState({
    deptId: undefined,
    teamIds: [],
    list: undefined
  })
  const [secLoader, setSecLoader] = useState(false);
  const Option = Select.Option;


  const {
    setFilterValues,
    filteredData,
    setTaskLogs,
  } = useProjecteStore((state) => state);
  // const {loader}=useApiStore(state=>state);
  const { logsDepartmentHandler, logsDepartment, setrecordStore } = useLogStore();
  const [loader, setLoader] = useState(false);



  console.log("logsDepartment", logsDepartment)
  const { width } = useWindowDimensions();
  const [screen, setscreen] = useState(width < 700)



  const teamsTableColumn = (props) => {
    const columns = [
      {
        title: (
          <h3 style={{ fontWeight: "bold", marginLeft: "1rem" }}>
            Associate Name
          </h3>
        ),
        dataIndex: "name",
        key: "name",
        render: (text, record) => {

          return (
            <div style={{ cursor: 'pointer' }} onClick={() => { setRender(2); setTaskLogs('name', record?.name); setTaskLogs('userId', record?.userId) }}>
              <div>{record?.name}</div>
              <div>{record?.employeeId}</div>
            </div>
          )
        },
      },
      {
        title: (
          <h3 style={{ fontWeight: "bold", marginLeft: "1rem" }}>Contact Details</h3>
        ),
        dataIndex: "contactDetails",
        key: "contactDetails",
        render: (text, record) => <div>
          <div>{record?.email}</div>
          <div>{record?.phone}</div>
        </div>,
      },
      {
        title: (
          <h3 style={{ fontWeight: "bold", marginLeft: "1rem" }}>
            Effort
          </h3>
        ),
        dataIndex: "effort",
        key: "effort",
        render: (text, record) => <div>{text ? text : 0} hrs</div>,
      },
      {
        title: (
          <h3 style={{ fontWeight: "bold", marginLeft: "1rem" }}>
            Actions
          </h3>
        ),
        dataIndex: "action",
        key: "action",
        render: (text, record) =>
          <Button type="text" onClick={() => {
           
            setOpenDetails(true)
            setCenterModel(true)
            setrecordStore(record)
          
          }}>

            <img id={text} src={record?.approve ? editBlackIcon : edit_blue_icon} style={{ width:record?.approve?"0.8rem": "1.2rem", height: record?.approve?"0.8rem": "1.2rem", cursor: "pointer" }} />
            {/* <img src={edit_blue_icon} style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} /> */}

          </Button>,
      },
    ];
    return columns;
  };

  const RenderButton = ({ record }) => {
    return (
      <Button type="text" onClick={() => {
        console.log("clicked12345");

        setOpenDetails(true)
        setCenterModel(true)
        setrecordStore(record)

      }}>

        <img src={record?.approve ? editBlackIcon : edit_blue_icon} style={{ width: record?.approve?"0.8rem": "1.2rem", height: "1.2rem", cursor: "pointer" }} />
        {/* <img src={edit_blue_icon} style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} /> */}

      </Button>
    )
  }

  const onAction = () =>{
    setSecLoader(true)
    getLogsList((res) => {
      setIds({ ...ids, list: res });
      setSecLoader(false)
    }, { teamIds: ids?.teamIds , search:search}, Tooltip, RenderButton, NameComp)
  }

  useEffect(() => {
    if (!((viewClass.hrAdmin || viewClass.hrAssociate || viewClass.associate) && !(viewClass.superAdmin) && !(viewClass.manager))) {

      setLoader(true);
      getLogsDepartmentApi((res) => {
        logsDepartmentHandler(res)
        setLoader(false);
      }, values = { ...values, role: roleName });
    }
  }, [])
  useEffect(() => {
    getSecDeptAndTeam((res) => { setDepts(res?.allDepartments) })
  }, [])
  useEffect(() => {
    getFilterValues(setFilterValues, "department");
  }, [])

  const DownloadHandler = () => {
    let param = {
      ...values, role: roleName,
      download: true,
      teamIds:ids?.teamIds,
      search:search
    }
    DownLoadLogs(param, (res) => {
      console.log(res);
      let fileName = res?.split('/')[4] + "_" + user?.name;
      const link = document.createElement('a');
      link.download = fileName;
      link.href = res;
      link.click();
    })
  }



  const NameComp = ({element}) => {
    return (
      <>
        <div style={{cursor:"pointer", width:"70%", textOverflow:"ellipsis", overflow:"hidden", whiteSpace:"nowrap"}} onClick={() => { setRender(2); setTaskLogs('name', element?.name); setTaskLogs('userId', element?.userId) }}>
          {element?.name}
        </div>
      </>
    )
  }
  // useEffect(() => {
  //   setLoader(true);
  //   getLogsData(
  //     setLogData,
  //     "",
  //     RiPencilFill,
  //     setOpenDetails,
  //     setTaskLogs,
  //     () => {
  //       setLoader(false);
  //     }
  //   );

  //   getLogsDepartmentApi((res)=>{
  //     logsDepartmentHandler(res)
  //     setLoader(false);
  //   });

  //   getFilterValues(setFilterValues, "department");
  //   console.log(logsData, "logsDatalogsData");
  // }, []);

  return (
    <div style={{ width: "100%", height: "100%", padding: 20, paddingTop: 5 }}>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", }}>
        {width < 700 ? <span style={{ fontWeight: '600', fontSize: 20 }}>Active Logs</span> : <h2>Active Logs</h2>}
        {
          tabs !== "Log" && <SearchCompo /* value={search} */ isDisabled={(ids?.list === undefined && (!ids?.list?.length || ids?.list?.length))} placeholder="Search by associate name" setSearch={
            (sval) => {
              setSecLoader(true)
              setSearch(sval)
              getLogsList((res) => {
                setIds({ ...ids, list: res });
                setSecLoader(false)
              }, { teamIds: ids?.teamIds, search: sval }, Tooltip, RenderButton, NameComp)
            }

          } style={{ width: width < 700 ? "45vw" : "25vw", marginRight: 10, height: "2.2rem" }} />
        }
        {
          <>
            {filter && width < 700 &&
              <div style={{ display: 'flex' }}>
                {
                  tabs === "Log" ?
                    <>
                      <div
                        onClick={() => {
                          applyFiltersModalHandler(true);

                        }
                        }
                        style={width < 700 ? { marginRight: 10 } : {


                          borderColor: "#FFFFFF",
                          color: "#00000F",
                          fontSize: 13,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                          borderRadius: '6px',
                          border: "0.6px solid #000000",
                          paddingTop: '8px',
                          paddingBottom: '8px',

                          paddingLeft: '35px',
                          paddingRight: '35px',

                          fontWeight: "600",
                          margin: '0 20px 0 20px'
                        }}
                      >
                        {width < 700 &&
                          <div
                            style={{ backgroundColor: "#FFFFFF", borderRadius: 5, color: "#162432", display: "flex", justifyContent: "center", alignItems: "center" }}
                          >  <img src={Filter} style={{ height: 25, width: 25 }} />
                          </div>
                        }
                        {width > 700 && 'Filter'}
                      </div>
                    </>
                    :
                    <>
                      {/* <SearchCompo /> */}
                    </>
                }

                <div
                  onClick={DownloadHandler}
                  style={width < 700 ? {
                    color: "black",
                    fontSize: 16,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginRight: '13px',
                    marginLeft: '13px',

                    cursor: 'pointer',
                  } : {
                    background: "transparent",
                    borderColor: "#FFFFFF",
                    color: "black",
                    fontSize: 16,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginRight: '13px',
                    cursor: 'pointer',

                  }}
                >
                  {width > 700 && <div style={{ marginRight: 5, fontWeight: "600", color: "#0086FF" }}>Download</div>}
                  {width < 700 ? <img
                    src={Downl}

                  /> : <img
                    src={Downl}

                  />}
                </div>
              </div>
            }
          </>

        }

      </div>
      <div style={{ color: '#545454', display: 'flex', alignItems: 'center', fontWeight: '500', marginTop: '1.5rem', justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: width < 700 ? 'column' : "row", gap: "1.5rem", alignItems: "center" }}>
          {filter ?
            <>
            {
               tabs === "Log"?
               <>
               {width > 700 ?
                <>

                  <span style={{ fontSize: "14px", fontWeight: "600" }}>{'Department :'}</span>
                  <h5 style={{ color: 'black', fontSize: "15px", fontWeight: "600" }}>{logsDepartment?.departments?.[0]?.name}</h5>
                  <span style={{ fontSize: "14px", fontWeight: "600" }}>{'Team : '}</span>
                  <h5 style={{ color: 'black', fontSize: "15px", fontWeight: "600" }}>{logsDepartment?.departments?.[0]?.team?.[0]?.name}</h5>
                  <span style={{ fontSize: "14px", fontWeight: "600" }}>{'Selected Date Range : '}</span>
                  <h5 style={{ color: 'black', fontSize: "15px", fontWeight: "600" }}>{logsDepartment?.date}</h5>
                </> :
                <>
                  <div style={{ display: 'flex', gap: 10, alignSelf: 'self-start' }}>
                    <span style={{ fontSize: "14px", fontWeight: "600" }}>{'Department :'}</span>
                    <h5 style={{ color: 'black', fontSize: "15px", fontWeight: "600" }}>{logsDepartment?.departments?.[0]?.name}</h5>
                  </div>
                  <div style={{ display: 'flex', gap: 10, alignSelf: 'self-start' }}>
                    <span style={{ fontSize: "14px", fontWeight: "600" }}>{'Team :'}</span>
                    <h5 style={{ color: 'black', fontSize: "15px", fontWeight: "600" }}>{logsDepartment?.departments?.[0]?.team?.[0]?.name}</h5>
                  </div>
                  <div style={{ display: 'flex', gap: 10, alignSelf: 'self-start' }}>
                    <span style={{ fontSize: "14px", fontWeight: "600" }}>{'Selected Date Range :'}</span>
                    <h5 style={{ color: 'black', fontSize: "15px", fontWeight: "600" }}>{logsDepartment?.date}</h5>
                  </div>
                </>

              }
               </>
               :
               <></>
            }
              

            </> :
            <div style={width < 700 ? { display: 'flex', flexDirection: 'column' } : { display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: "14px", fontWeight: width < 700 ? '400' : "600" }}>{'Date Range'}</span>
              <h5 style={{ marginLeft: width < 700 ? '0px' : "17px", color: 'black', fontSize: "15px", fontWeight: "600", marginTop: width < 700 ? '10px' : '0' }}>{moment().subtract(1, 'month').format("YYYY-MM-DD")} to {moment().format("YYYY-MM-DD")}</h5>
            </div>
          }

        </div>
        <div style={{ display: 'flex' }}>
          {
            tabs === "Log" ?
              <>
                <div
                  onClick={() => {
                    applyFiltersModalHandler(true);
                    // getFilterValues(setFilterValues, "department");
                  }
                  }
                  style={width < 700 ? { marginRight: 10 } : {


                    borderColor: "#FFFFFF",
                    color: "#00000F",
                    fontSize: 13,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                    borderRadius: '6px',
                    border: "0.6px solid #000000",
                    paddingTop: '8px',
                    paddingBottom: '8px',

                    paddingLeft: '35px',
                    paddingRight: '35px',

                    fontWeight: "600",
                    margin: '0 20px 0 20px'
                  }}
                >
                  {!filter && width < 700 &&
                    <div
                      style={{ backgroundColor: "#FFFFFF", borderRadius: 5, color: "#162432", display: "flex", justifyContent: "center", alignItems: "center" }}
                    >  <img src={Filter} style={{ height: 25, width: 25 }} />
                    </div>
                  }
                  {width > 700 && 'Filter'}
                </div>
              </>
              :
              <>

              </>
          }

          <div
            onClick={DownloadHandler}
            style={width < 700 ? {
              color: "black",
              fontSize: 16,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginRight: '13px',
              marginLeft: '13px',

              cursor: 'pointer',
            } : {
              background: "transparent",
              borderColor: "#FFFFFF",
              color: "black",
              fontSize: 16,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginRight: '13px',
              cursor: 'pointer',

            }}
          >
            {width > 700 && <div style={{ marginRight: 5, fontWeight: "600", color: "#0086FF" }}>Download</div>}

            {!filter && width < 700 ? <img
              src={Downl}

            /> : <>{width > 700 &&
              <img
                src={Downl}

              />
            }</>}
          </div>
        </div>
      </div>
      <div style={{ width: "100%", height: "fit-content", marginTop: 15, display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: width < 700 ? "column" : "row" }}>
        <div style={{ width: width < 700 ? "80%" : 170 }}>
          <div style={{ gap: "0.5rem", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div onClick={() => { setTabs("Log") }} style={{ display: "flex", flexDirection: "column", fontSize: "16ppx" }}>
              <div style={{ color: tabs === "Log" ? "#0086FF" : "#A6A9B8", cursor: "pointer", fontSize: width < 700 ? "19px" : "16px" }}>
                Log
              </div>
              {
                tabs === "Log" ?
                  <div style={{ height: 1, border: "2px solid #0086FF", borderRadius: 5, }}></div>
                  :
                  <div style={{ height: 1 }}></div>
              }
            </div>
            <div onClick={() => { setTabs("List view log data") }} style={{ display: "flex", flexDirection: "column", fontSize: "16px" }}>
              <div style={{ color: tabs === "List view log data" ? "#0086FF" : "#A6A9B8", cursor: "pointer", fontSize: width < 700 ? "19px" : "16px" }}>
                List view log data
              </div>
              {
                tabs === "List view log data" ?
                  <div style={{ height: 1, border: "2px solid #0086FF", borderRadius: 5 }}></div>
                  :
                  <div style={{ height: 1 }}></div>
              }
            </div>
          </div>
        </div>
        <div>
          {
            tabs === "List view log data" ?
              <>
                {
                  width < 700 ?
                    <>
                      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: 10 }}>
                        <div >

                          <Select
                            style={{ width: 150, marginLeft: 5 }}
                            size="medium"
                            allowClear={true}
                            value={ids?.deptId}

                            placeholder="Select Department"
                            onChange={(e) => {

                              setIds({ teamIds: [], deptId: e })
                              getTeamsList((res) => {
                                setTeamsDD(res?.allTeams)
                              }, e)

                            }}>
                            {depts?.map((item, index) => {
                              return (
                                <Option value={item?.id} key={index}>{item?.name}</Option>
                              )
                            })}
                          </Select>

                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>

                          <Select
                            style={{ width: 200, marginLeft: 5, height: 33 }}
                            size="medium"
                            allowClear={true}
                            mode='multiple'
                            showSearch={false}
                            value={ids?.teamIds}
                            maxTagCount={'responsive'}
                            placeholder="Select Team "
                            onChange={(e) => {
                              setIds({ ...ids, teamIds: e })
                              if (!e?.length)
                                setIds({ ...ids, list: undefined, teamIds: e })
                              setSearch("")

                            }}>
                            {teamsDD?.map((item, index) => {
                              return (
                                <Option value={item?.id} key={index}>{item?.name}</Option>
                              )
                            })}
                          </Select>
                        </div>
                      </div>
                      <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", marginTop: 15 }}>
                        <ResetButton
                          disabled={!ids?.teamIds?.length}
                          style={{ height: 32, borderRadius: 3, backgroundColor: "#0086FF" }}
                          // icon={<img src={reset_icon} />}    
                          onClick={() => {

                            setSecLoader(true)
                            getLogsList((res) => {
                              setIds({ ...ids, list: res });
                              setSecLoader(false)
                            }, { teamIds: ids?.teamIds }, Tooltip, RenderButton, NameComp)

                          }}
                          title="Save"
                        />
                        {/*  <ResetButton

                          style={{ height: 32, borderRadius: 3, marginLeft: 5 }}
                           
                          onClick={() => {
                            setIds({
                              deptId: undefined,
                              teamIds: [],
                              list: []
                            })
                          }}
                          title="Reset"
                        /> */}
                      </div>
                    </>
                    :
                    <>
                      <div style={{ display: "flex", alignItems: "center", gap: "2rem" }}>

                        <div >

                          <Select
                            style={{ width: 150, marginLeft: 5 }}
                            size="medium"
                            allowClear={true}
                            value={ids?.deptId}

                            placeholder="Select Department"
                            onChange={(e) => {

                              setIds({ teamIds: [], deptId: e })
                              getTeamsList((res) => {
                                setTeamsDD(res?.allTeams)
                              }, e)

                            }}>
                            {depts?.map((item, index) => {
                              return (
                                <Option value={item?.id} key={index}>{item?.name}</Option>
                              )
                            })}
                          </Select>

                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>

                          <Select
                            style={{ width: 200, marginLeft: 5, height: 33 }}
                            size="medium"
                            allowClear={true}
                            mode='multiple'
                            showSearch={false}
                            value={ids?.teamIds}
                            maxTagCount={'responsive'}
                            placeholder="Select Team "
                            onChange={(e) => {
                              setIds({ ...ids, teamIds: e })
                              if (!e?.length) {
                                setIds({ ...ids, list: undefined, teamIds: e })
                                setSearch("")
                              }

                            }}>
                            {teamsDD?.map((item, index) => {
                              return (
                                <Option value={item?.id} key={index}>{item?.name}</Option>
                              )
                            })}
                          </Select>
                        </div>
                        <div style={{ display: "flex" }}>
                          <ResetButton
                            disabled={!ids?.teamIds?.length}
                            style={{ height: 32, borderRadius: 3, backgroundColor: "#0086FF" }}
                            // icon={<img src={reset_icon} />}    
                            onClick={() => {
                              setSecLoader(true)
                              getLogsList((res) => {
                                setIds({ ...ids, list: res });
                                setSecLoader(false)

                              }, { teamIds: ids?.teamIds }, Tooltip, RenderButton, NameComp)

                            }}
                            title="Save"
                          />
                          {/*   <ResetButton
                            style={{ height: 32, borderRadius: 3, marginLeft: 5 }}
                            // icon={<img src={reset_icon} />}    
                            onClick={() => {
                              setIds({
                                deptId: undefined,
                                teamIds: [],
                                list: []
                              })
                          
                            }}
                            title="Reset"
                          /> */}

                        </div>

                      </div>
                    </>
                }

              </>
              :
              <div></div>
          }
        </div>
      </div>
      {
        tabs === "Log" ?
          <>
            {loader ? (
              <div className="r-c-c" style={{ height: "80%" }}>
                <Spin />
              </div>
            ) : logsDepartment?.departments?.length === 0 ?

              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}><NoData /></div>

              : (

                <div
                  // className="m-t-20"
                  style={{ height: "75%", flexWrap: "wrap", overflow: "scroll", marginTop: 10 }}
                >
                  <Collapse
                    expandIcon={(data) => (
                      // console.log("icon",data)
                      <img src={activeDept[0] === data?.panelKey ? MinusIcon : PluseIcon} />
                    )}
                    expandIconPosition="end"
                    accordion={true}
                    style={{ display: 'flex', flexDirection: 'column', gap: '10px', background: "#F8F8F8", padding: width < 700 ? '0px' : '16px 20px', borderRadius: '0px' }}
                    onChange={(data) => {
                      console.log("shubham", data)
                      setActiveDept(activeDept === data ? [] : data);
                    }}
                  >
                    {logsDepartment?.departments?.map((val, index) => (
                      <>

                        <Panel
                          key={val?.id}
                          style={{ border: '1px solid #1624321a', background: '#FAFAFA', borderRadius: '10px', boxShadow: '0px 4px 8px #16243230', minHeight: '3rem', }}
                          header={<h4>Department: {val.name}</h4>}
                        >

                          <Collapse
                            style={{ display: 'flex', flexDirection: 'column', gap: '10px', background: "#F8F8F8", padding: width < 700 ? '0px' : '16px 20px', borderRadius: '0px' }}
                            expandIcon={(data) => (
                              <img src={activeTeam[0] === data?.panelKey ? MinusIcon : PluseIcon} />
                            )}
                            expandIconPosition="end"
                            accordion={true}
                            onChange={(data) => {
                              setActiveTeam(activeTeam === data ? [] : data);
                            }}
                          >

                            {val?.team?.map((item, index) => (
                              <>
                                <Panel
                                  key={index}
                                  style={{ border: '1px solid #1624321a', background: '#FAFAFA', borderRadius: '10px', boxShadow: '0px 4px 8px #16243230', minHeight: '3rem', }}
                                  header={<h4>{item?.name}</h4>}
                                >
                                  <Collapse
                                    style={{ display: 'flex', flexDirection: 'column', gap: '10px', background: "#F8F8F8", padding: width < 700 ? '0px' : '16px 20px', borderRadius: '0px' }}
                                    expandIcon={(data) => (
                                      <img src={activeDate[0] === data?.panelKey ? MinusIcon : PluseIcon} />
                                    )}
                                    expandIconPosition="end"
                                    accordion={true}
                                    onChange={(data, res) => {
                                      console.log(" masaal", data, res)
                                      setActiveDate(activeDate === data ? [] : data);
                                    }}
                                  >
                                    {
                                      item?.datas?.map((item2, index3) => {
                                        return (
                                          <Panel
                                          onClick={()=>{ setLogDate({ start: item2?.startDate, end: item2?.endDate }) }}
                                            key={item2?.index}
                                            style={{ border: '1px solid #1624321a', background: '#FAFAFA', borderRadius: '10px', boxShadow: '0px 4px 8px #16243230', minHeight: '3rem', }}
                                            header={<h4>{moment(item2?.startDate).format("DD-MMM-YYYY")} to {moment(item2?.endDate).format("DD-MMM-YYYY")}</h4>}
                                          >
                                            {<div key={index3} style={{ display: "flex", flexDirection: "column", gap: "1rem" }} onClick={() => { setLogDate({ start: item2?.startDate, end: item2?.endDate }) }}>
                                              <span style={{ margin: "1rem 0 0 1rem", fontWeight: "600" }}>{item2?.key}</span>
                                              {
                                                width < 700 ?
                                                  <>
                                                    {
                                                      item2?.value?.length?
                                                      item2?.value?.map((item) => (
                                                        <>
                                                          {
                                                            <MobileCard
                                                              onCardClick={() => { setRender(2); setTaskLogs('name', item?.name); setTaskLogs('userId', item?.userId) }}
                                                              headerLeft={
                                                                (
                                                                  <h3>
                                                                    {item?.name}

                                                                  </h3>
                                                                )
                                                              }
                                                              body={
                                                                (
                                                                  <>
                                                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                      <div style={{ width: "30%", color: "#B1B0B0" }}>Email : </div>
                                                                      <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}><span style={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{item?.email}</span></div>
                                                                    </div>
                                                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                      <div style={{ width: "30%", color: "#B1B0B0" }}>EmployeeId : </div>
                                                                      <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.employeeId}</div>
                                                                    </div>
                                                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                      <div style={{ width: "30%", color: "#B1B0B0" }}>Effort : </div>
                                                                      <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.effort} hrs</div>
                                                                    </div>
                                                                  </>
                                                                )
                                                              }
                                                              headerRight={(
                                                                <>

                                                                  <Button type="text" onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setOpenDetails(true)
                                                                    setCenterModel(true)
                                                                    setrecordStore(item)

                                                                  }}>

                                                                    <img src={item?.approve ? editBlackIcon : edit_blue_icon} style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} />
                                                                    {/* <img src={edit_blue_icon} style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} /> */}

                                                                  </Button>

                                                                </>
                                                              )} />
                                                          }
                                                        </>
                                                      ))
                                                      :
                                                      <NoData/>
                                                    }
                                                  </>
                                                  :
                                                  <Table
                                                    className={classes2.logsTable}
                                                    key={index}
                                                    style={{ width: '100%' }}
                                                    dataSource={item2?.value}
                                                    columns={
                                                      teamsTableColumn()?.map((col) => ({
                                                        ...col,
                                                        align: "center",
                                                      }))}
                                                    scroll={{ y: 150 }}
                                                    pagination={false}
                                                    /*  pagination={{
                                                       position: "bottomRight",
                                                       pageSize: 25,
                                                       size: "small",
                                                       // pageSizeOptions: [10, 30, 50, 100],
                                                       // pageSize: this.state.pageSize,
                                                       // current: this.state.page,
                                                       // total: candidatePageMeta?.totalItems
                                                       //   ? candidatePageMeta?.totalItems
                                                       //   : 0,
                                                       // onChange: (page, pageSize) => this.handlePageChange(page, pageSize),
                                                       showSizeChanger: true,
                                                       showTotal: (total, range) =>
                                                         `${range[0]}-${range[1]} of ${total} items`,
                                                     }} */
                                                    rowKey={({ s_no }) => s_no}
                                                  />

                                              }


                                            </div>}
                                          </Panel>
                                        )
                                      })
                                    }

                                  </Collapse>

                                </Panel>
                              </>


                            ))}
                          </Collapse>
                        </Panel>
                      </>
                    ))}
                  </Collapse>
                </div>)
            }
          </>
          :
          <div style={{ marginTop: 20 }}>
            <ListViewLogData ids={ids} setSecLoader={secLoader} />
          </div>
      }





      {centerModel && <Modal
        show={centerModel}
        closableRight={true}
        style={{ width: width < 700 ? "90%" : "67vw", height: "75vh", overflowY: "auto", }}
        header={<div style={{ width: "100%", color: "#0086FF" }}>
          <h3>Logs</h3>
        </div>}
        onCancel={() => setCenterModel(false)}
      >
        <DetailsModal tabs={tabs} onAction={onAction} close={setCenterModel} logDate={logDate} isCoManager={logsDepartment?.isCoManager} />

      </Modal>}

      {isApplyFiltersModal && <ApplyFiltersModalG
        roleName={roleName}
        setRoleName={setRoleName}
        onSave={(values) => {
          setLoader(true);
          getLogsData((res) => {
            logsDepartmentHandler(res);
            applyFiltersModalHandler(false);
            setFilter(true)
            setLoader(false);
          }, values = { ...values, role: roleName });
        }}
        onReset={() => {
          setLoader(true);
          getLogsData((res) => {
            logsDepartmentHandler(res);
            applyFiltersModalHandler(false);
            setLoader(false);
            setFilter(false)
            setValues({
              departmentId: undefined,
              managerId: undefined,
              teamId: undefined,
              roleId: "",
              startDate: undefined,
              endDate: undefined,
            })
          })
        }}
        getFilterValues={getFilterValues}
        isApplyFiltersModal={isApplyFiltersModal}
        applyFiltersModalHandler={applyFiltersModalHandler}
        values={values}
        setValues={setValues}
      />}
    </div>
  );
};

export default Logs;