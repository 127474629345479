import React, { useState } from 'react';
import 'lightbox.js-react/dist/index.css'
import {SlideshowLightbox, initLightboxJS} from 'lightbox.js-react'
import classes from './Lightbox.module.css';


const CoolLightbox = ({files}) => {

  console.log("filesfiles",files)

    return (
        <>
        {files&&<SlideshowLightbox className={classes.media} showThumbnails={true} >
        {files?.map((item,index)=>(
            <img src={item} key={index}/>
        ))}
      </SlideshowLightbox> }
      </>
    );
};

export default CoolLightbox;