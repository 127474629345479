import classes from "./CircleProgressBar.module.css";
function CircleProgressBar({ strokeWidth=5,sqSize=60,percentage=0,progressColor="#0DAC45",textColor="#000000",strokeColor="#D9D9D9",text,prefix='%',fontSize=12,fontWeight=600}){
    // const sqSize = sqSize;
    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    const radius = (sqSize - strokeWidth) / 2;
    // Enclose cicle in a circumscribing square
    const viewBox = `0 0 ${sqSize} ${sqSize}`;
    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * 2;
    // Scale 100% coverage overlay with the actual percent
    const dashOffset = dashArray - dashArray * percentage / 100;
    return(
        <svg
        width={sqSize}
        height={sqSize}
        viewBox={viewBox}>
        <circle
        style={{stroke:strokeColor}}
          className={classes.circleBackground}
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`} />
        <circle
          className={classes.circleProgress}
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          // Start progress marker at 12 O'Clock
          transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
            stroke:progressColor
          }} />
        <text
         style={{fill:textColor,fontSize:fontSize,fontWeight:fontWeight}}
          className={classes.circleText}
          x="50%"
          y="50%"
          dy=".3em"
          textAnchor="middle">
          {`${text?text:percentage}`}{prefix}
        </text>
    </svg>
    )

}
export default CircleProgressBar;