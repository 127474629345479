import React, { useEffect, useState } from "react";
import classes from './Recommendation.module.css';
import { Modal, Pagination, Spin } from "antd";
import { useFPStore } from "../store";
import { getRecommendation } from "../Action";
import { NoData } from "../Department/NoData";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";

const Recommendation=({selectedDept,loader,setLoader,dateMonth})=>{

    const [page,setPage]=useState(1);
    const [limit,setLimit]=useState(10);
    const [rcomModal,setRcomModal]=useState(false);
    const [modalData,setModalData]=useState();
    const {recommendationData,recommendationDataHandler}=useFPStore();
    const {width}=useWindowDimensions();

    useEffect(()=>{
        setLoader(true);
        getRecommendation((res)=>{
            recommendationDataHandler(res);
            setLoader(false);

        },page,limit,selectedDept,dateMonth);
    },[page,limit,selectedDept,dateMonth])

    return(
        <>
        {loader?<Spin className={classes.spin}></Spin>:<div className={classes.recommendation}>
            {
                (!recommendationData||recommendationData?.data?.length===0)?
                <NoData height="45vh"/>
            :recommendationData?.data?.map((item,index)=>{
                return(
                    <div key={index} className={classes.card} style={{cursor:"pointer"}} onClick={()=>{
                        setModalData(item);
                        setRcomModal(true);
                    }}>
                        <div >
                            <div>
                                <div style={{fontWeight:"600",fontSize:"0.9rem",marginBottom:"0.3rem"}}>{item?.userDetails?.name} {"("+item?.userDetails?.uid+")"}</div>
                                <div style={{color:"#1818187A",fontSize:"0.8rem",}}>{item?.userDetails?.email} | {item?.userDetails?.phone}</div>
                            </div>
                            <div>
                                <div>{item?.teamDetail?.teamName?item?.teamDetail?.teamName:"NA"} |</div>
                                <div style={{fontWeight:"600",fontSize:"0.9rem",marginBottom:"0.3rem"}}>{item?.teamDetail?.managerName?item?.teamDetail?.managerName:"NA"}</div>
                            </div>
                        </div>
                        <div style={{fontSize:"0.9rem"}}>
                                {item?.details?.answer}
                        </div>

                    </div>
                )
            })}

            {recommendationData?.data?.length>0&&<Pagination position="bottomRight" size="middle" style={{display:"flex",justifyContent:"flex-end",marginTop:"0.5rem",marginRight:"1rem"}}
              current={page} showSizeChanger={true} total={recommendationData?.meta?.totalItems?parseInt(recommendationData?.meta?.totalItems):0} pageSize={limit} 
              onChange={(page,pageSize)=>{
                setPage(page)
                setLimit(pageSize)
              }}
               onShowSizeChange={(page,pageSize)=>{
                setPage(page)
                setLimit(pageSize)
              }} 
              pageSizeOptions={[10,20,30,50,100,200]}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} />}

        </div>}

        {rcomModal&&<Modal 
        open={rcomModal}
        onCancel={()=>{setRcomModal(false)}}
        footer={false}
        maskClosable={true}
        closable={false}
        centered
        width={width>800?"45vw":"98vw"}
        bodyStyle={{maxHeight:"70vh",overflowY:"auto"}}
        >
            <div className={classes.rcomModal}>
            <div className={classes.header}>
                            <div>
                                <div style={{fontWeight:"600"}}>{modalData?.userDetails?.name} {"("+modalData?.userDetails?.uid+")"}</div>
                                <div >{modalData?.userDetails?.email} | {modalData?.userDetails?.phone}</div>
                            </div>
                            <div>
                                <div>{modalData?.teamDetail?.teamName?modalData?.teamDetail?.teamName:"NA"} |</div>
                                <div>{modalData?.teamDetail?.managerName?modalData?.teamDetail?.managerName:"NA"}</div>
                            </div>
                        </div>
                        <div>
                                {modalData?.details?.answer}
                        </div>
                      

            </div> 


        </Modal>}
        </>
    )
}
export default Recommendation;