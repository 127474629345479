let  interval; 
class Utils {
  sendMessage(text, user, additionalData, date) {
    const id = Math.round(Math.random() * 1000000);
    return {
      _id: id,
      id,
      text: text || "",
      createdAt: date || new Date(),
      user: {
        _id: user._id || user.id,
        id: user._id || user.id,
        name: user.name?.split(" ")[0],
        avatar: user.image ? user.image : null
      },
      ...additionalData
    };
  }

  sendMessages(text, user, additionalData, date) {
    const id = Math.round(Math.random() * 1000000);
    return {
      _id: id,
      id,
      message: text || "",
      createdAt: date || new Date(),
      user: {
        _id: user?._id || user.id,
        id: user._id || user.id,
        name: user.name.split(" ")[0],
        avatar: user.image ? user.image : null
      },
      ...additionalData
    };
  }

  

  secondsToHms = (d) => {
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    if(h<=9){h= '0'+h};
    if(m<=9){m= '0'+m};
    if(s<=9){s= '0'+s};
    return `${h}:${m}:${s}`;
  }
  secondsToHm = (d) => {
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    if(h<=9){h= '0'+h}
    if(m<=9){m= '0'+m}
    if(s<=9){s= '0'+s}
    return `${h}:${m}`;
  }
  

  startDuation(durationSec, callback){
    interval = setInterval(() => {
      // console.log('ppppppp');
      if(durationSec > 0){
        durationSec = durationSec - 1
        callback(durationSec)
        if(durationSec === 0){
          // console.log('hiiii');
          clearInterval(interval);
        }
      }else{
        clearInterval(interval);
      }
    }, 1000);
  }

  stopDuration() {
    // console.log('eeeeeeee');
    clearInterval(interval);
  }
  throttle(fn, delay=1000) {
    let throttleTimeOut = false
    return (...args) => {
      if (throttleTimeOut){
        return
      }
      fn(...args)
      throttleTimeOut = true
      setTimeout(()=>{
        throttleTimeOut = false
      }, delay);
    }
  }
  clamp(val, lowerlimit, upperlimit){
    if (val<lowerlimit){
      val = lowerlimit
    }else if(val>upperlimit){
      val = upperlimit
    }
    return val
  }
  IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }
  isValidString(inputString) {
    // Define a regular expression pattern for spaces, alphabets, and numerics
    var pattern = /^[a-zA-Z0-9\s]+$/;
  
    // Test the input string against the pattern
    return pattern.test(inputString);
  }
}

export default new Utils();
