import Api from "../../../../services/Api";

export const getAllEmployeeApi=(params,callback)=>{

    Api.get("/resource-user/allUsers").params(params).send((res)=>{
        callback(res);
    })
}
export const addAssociateApi=(params,callback)=>{

    Api.post("/resource-user/addAssociate").params(params).send((res)=>{
        console.log("associate added",res);
        if(res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        }  
    })
}

export const deleteAssociateApi=(params,callback)=>{
    Api.delete("/resource-user/deleteAssociate").params(params).send((res)=>{
        console.log("deleted",res);
        if(res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        }  
    })
}

export const AssignDepartmentApi=(params,callback)=>{

    Api.post("/resource-user/assignDepartment").params(params).send((res)=>{
        if(res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        }  
    })
}
export const sendMailApi=(params,callback)=>{

    Api.post("/resource-user/sendMail").params(params).send((res)=>{
        console.log("mail sent",res);
    })
}

export const getAllDepartment=(callback)=>{
    let res
    Api.get("/resource-user/allDepartments").send((res)=>{
        console.log("user/allDepartments",res)
        if(res!==undefined){
          callback(res)
        }
    })
}
export const DownloadApi=(params,callback)=>{
    Api.get("/resource-user/allUsers").params(params).send((res)=>{
        callback(res);
    })
}
export  const ActivateApi=(params,callback)=>{
    Api.patch("/resource-user/activateUser").params(params).send((res)=>{
        if(res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        }  

    })
}
export const userListApi=(params,callback)=>{
    Api.get("/resource-user/userList").params(params).send((res)=>{
        if(res!==undefined){
            callback(res);
        }
    })
}
export const leaveApproverApi=(params,callback)=>{
    Api.post("/resource-user/postLeaveApprover").params(params).send((res)=>{
        if(res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        }    
    })
}
export const getLeaveApprovarApi=(params,callback)=>{
    Api.get("/resource-user/getLeaveApprover").params(params).send((res)=>{
        if(res!==undefined ){
            callback(res)
        }
    })

}
export const getUserDataApi=(userId,callback)=>{
    Api.get(`/resource-user/userProfile?userId=${userId}`).send(res=>{
        if(res!==undefined){
            callback(res);
        }
    })
}

export const profileUploadApi=(userId,file,callback)=>{   
    console.log(file,"fileeee"); 
    Api.patch(`/resource-user/editProfileImage?userId=${userId}`).uploadAndPatch({files:file}, (percentCompleted, response)=>{
       console.log('loggggg',percentCompleted,response)
       callback(response);
       
    })
}

export const lastDateChangeApi=(param,callback)=>{
    Api.patch("/resource-user/setLastDate").params(param).send(res=>{
       if(res)
       {
        callback();
       }
    })
}

export const mailPDFApi=(params,callBack)=>{
    Api.post("/resource-user/sendPDFToUser").upload(params,(res)=>{
        if(res!==undefined){
            callBack(res);
        }
    })
}
export const rejecteDepartmentApi=(params,callback)=>{
    Api.post('/resource-user/reject').params(params).send((res)=>{
        if(res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        }
    })
}