

export const handleFileDownload = (fileURL, successCallBack = null) => {
  const link = document.createElement('a');
  link.href = fileURL;
  const filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
  link.setAttribute('download', filename);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if (isSafari) {
    /*  const clickEvent = new MouseEvent('click', {
       view: window,
       bubbles: true,
       cancelable: false,
     });
     link.dispatchEvent(clickEvent); */
    link.setAttribute('download', filename);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    link.click();
  }

}