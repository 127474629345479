import React, { useState } from "react";
import classes from './HealthInsuranceCardModal.module.css';
import Modal from "../../../components/Modal/Modal";
import { ModalPrimaryButton, ModalSecondaryButton } from "../../../components/Button/Button";
import { Radio } from "antd";
import { downloadMyCardApi } from "../Actions";
import Notification from "../../../services/Notification";
import { useEffect } from "react";
import viewClass from "../../../services/ViewRole";
import axios from "axios";
import Api from "../../../services/Api";

const HealthInsuranceCardModal=({insuranceCardModal,setInsuranceCardModal,cardClickHandler})=>{
    var interval;
function downloadFile(data){
    var url=data.pop();
    console.log("anill",url)
    fetch('https://cors-anywhere.herokuapp.com/' + url?.filePath, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
    },
    }).then((response) => response.blob()).then((blob) => {
        // console.log("Blob RESponse",response)
    const element = document.createElement("a");
    const file = new Blob([blob], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = url?.fileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    document.body.removeChild(element);
  });
    if(data.length===0){
        clearInterval(interval);
    }
}
useEffect(()=>{
viewClass.updateView();
},[window.location.href])

const downloadHandler=()=>{
    downloadMyCardApi( (res)=>{
        if(res!==undefined && res?.length===0){
            Notification.error('error','No files uploaded yet')
            return ;
        }
        if(res!==undefined && res?.length!==0){
    //  interval=setInterval(downloadFile,200,res) 
//     let urls=[
//         'https://brigosha-hackathon-test-brigosha-5777.s3.ap-south-1.amazonaws.com/emagazine/Magazinefinalcopy.pdf',
//         'https://brigosha-hackathon-test-brigosha-5777.s3.ap-south-1.amazonaws.com/emagazine/Magazinefinalcopy.pdf',
//         'https://brigosha-hackathon-test-brigosha-5777.s3.ap-south-1.amazonaws.com/emagazine/Magazinefinalcopy.pdf',
//     ]
//     urls?.forEach(file=>{
    
//         forceDownload(file, file.substring(file.lastIndexOf('/')+1,file?.length))
// }) 
    res?.forEach(file=>{
    
              forceDownload(file?.filePath, file?.filePath.substring(file?.filePath.lastIndexOf('/')+1,file?.filePath.length))
    })   
          }           
    });

}

function forceDownload(url, fileName){
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function(){
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
    }
    xhr.send();
}

const handleDownload = async () => {
  
    try {
     
        Api.get(`/resource-home/healthCard?download=${true}& responseType=${"blob"}`).send(response=>{
               if(response)
               {
                console.log("responsesdfgh",response)
                const blob = new Blob([response], { type: 'application/zip' });
                // Create a temporary URL to download the Blob
                const downloadUrl = window.URL.createObjectURL(blob);
                // Create an anchor element to trigger the download
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'filename.zip'); // Set the desired filename
                document.body.appendChild(link);
                link.click();
                // Clean up the temporary URL
                window.URL.revokeObjectURL(downloadUrl);

               }
      })
    //   console.log("ZipRESp",response)
      
    
    } catch (error) {
      console.error('Error downloading zip file:', error);
    }
  };

    const [actionType,setActionType]=useState("download");
    return(
        <Modal show={insuranceCardModal}
        closableRight={true}
        header={
        <div className={classes.header}>
            <h3>Select</h3>
        </div>}
         onCancel={()=>{
            setInsuranceCardModal(false);
        }}>
            <div className={classes.container}>
                <Radio.Group 
                className={classes.actionType}
                value={actionType}
                onChange={(e)=>{
                    setActionType(e.target.value);
                }}>
                    <Radio value="download" style={{border:actionType==="download" ?"1.4px solid #0086FF":"1.4px solid #B7B7B7"}}>Download My Insurance Card</Radio>
                  {(viewClass.superAdmin || viewClass.hrAdmin)?<Radio value="dashboard" style={{border:actionType==="dashboard" ?"1.4px solid #0086FF":"1.4px solid #B7B7B7"}}>Admin View</Radio>:null}

                </Radio.Group>

                <div className={classes.buttonDiv}>
                    <ModalPrimaryButton
                    style={{width:"8rem"}}
                    onSave={()=>{
                        if(actionType==="dashboard")
                        {
                            cardClickHandler("/health_insurance");

                        }
                        else{
                              downloadHandler();
                            // handleDownload();
                        }
                    }}
                    >{ actionType==="download"?"Download":"Dashboard"}</ModalPrimaryButton>
                    <ModalSecondaryButton
                      style={{width:"8rem"}}
                     onCancel={()=>{
                         setInsuranceCardModal(false);
                    }}>Cancel</ModalSecondaryButton>
                </div>

            </div>

        </Modal>
    )
}
export default HealthInsuranceCardModal;