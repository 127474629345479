
import React, {useState} from "react";
import classes from "./ApplyFiltersModal.module.css";
import {  Select } from 'antd';
import moment from "moment";
import { useProjecteStore } from "../../pages/ProjectManagement/store/store";
import Button from "../buttonCustom";
import Modal from "../Modal/Modal";
import filter_icon from '../../assets/filter_icon.svg';
import DatePickerCompo from "../DatePicker/DatePickerCompo.tsx";
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";
import { useEffect } from "react";

const ApplyFiltersModalG = ({roleName,setRoleName, isApplyFiltersModal,values,setValues, applyFiltersModalHandler, onReset = null, getFilterValues = null, onSave = null, reference = null }) => {
     const { filteredData, setFilterValues } = useProjecteStore(state => state);
   
     const {width} = useWindowDimensions();

     useEffect(()=>{
        console.log("Applied Filters",roleName)
     },[roleName])
  
    const Option = Select.Option;

    const disabledEndDate = (endValue) => {
        if (!values?.startDate || !endValue) {
          return false;
        }
        return endValue.isBefore(values?.startDate , 'day');
      };
    return (
        <Modal show={isApplyFiltersModal} closableLeft={true} right={true}
            header={
                <div className={classes.modalHeader}>
                    <img src={filter_icon} style={{width:"1.1rem", height:"1.1rem"}}/>
                    <h3>Apply Filter</h3>
                </div>}
           style={width<700?{width:"90vw"}:{width:"35vw"}}
            onCancel={() => applyFiltersModalHandler(false)}>
            <div className={classes.addTeamModal2} style={{ padding: 20 }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {console.log(values,"valuesvaluesvalues")}
                    <span style={{fontSize:"14px",fontWeight:600,margin:"0 0 10px 0" }}>Department</span>
                    <Select
                    size="large"
                    defaultValue={values?.departmentId!=""?values?.departmentId:null}
                        placeholder="Select department" 
                        allowClear={true} 
                        onChange={(e) => {
                            getFilterValues(setFilterValues, "role", e,values);
                            if(reference==1){
                                getFilterValues(setFilterValues, "team", e,values);
                            }else{
                                // getFilterValues(setFilterValues, "manager", e);
                                // getFilterValues(setFilterValues, "role", e);
                            }
                            setValues({ ...values, departmentId: e , roleId: e});
                        }}
                        >

                        {filteredData?.department?.departments ? filteredData?.department?.departments?.map((item, index) => {
                            return (
                                <Option value={item.id} key={index}>{item.name}</Option>
                            )
                        }) : null}

                    </Select>
                </div>
                {
                    reference != 1 &&
                    <>
                        <span style={{fontSize:"14px",fontWeight:600,margin:"20px 0 10px 0" }}>Role</span>
                        <Select
                         size="large"
                         placeholder="Select role"
                         defaultValue={roleName!==""?roleName:null} 
                         allowClear={true} 
                         onChange={(e)=>{
                            setRoleName(e)
                            getFilterValues(setFilterValues, "roleName", e);
                            console.log(e,"mceeee");
                            if(e === "Manager"){
                                console.log("insidemanager");
                                getFilterValues(setFilterValues, "team",0,values,e );
                            }else{
                                getFilterValues(setFilterValues, "manager", e,values);
                            }
                            // getFilterValues(setFilterValues, "role", e);

                            // getFilterValues(setFilterValues, "roleName", e)
                        
                      
                         }}
                          >
                            {filteredData?.role && filteredData?.role?.map((item, index) => {
                                return (
                                    <Option value={item} key={index}>{item}</Option>
                                )
                            })}

                        </Select>
                        {console.log(roleName,"roleNameroleName")}
                        {(roleName === "Manager"|| roleName ==="CoManager" ||roleName === "Associate") ?
                        <></>
                        :
                        
                        (
                            <>
                                <span style={{fontSize:"14px",fontWeight:600,margin:"20px 0 10px 0" }}>Manager</span>
                        <Select 
                         size="large"
                        defaultValue={values?.managerId!==""?values?.managerId:null}
                       placeholder="Select manager" allowClear={true} onChange={(e) => {
                            getFilterValues(setFilterValues, "team", e,values,roleName);
                           
                            setValues({ ...values, managerId: e });
                        }}>
                            {filteredData?.manager?.managers && filteredData?.manager?.managers.map((item, index) => {
                                return (
                                    <Option value={item.id} key={index}>{item.name}</Option>
                                )
                            })}
                        </Select>
                            </>
                        )}
                        
                    </>
                }

                {
                    roleName !== "Associate" && (<>
                    <span style={{fontSize:"14px",fontWeight:600,margin:"20px 0 10px 0" }}>Team</span>
                    {console.log(filteredData,"filteredDatafilteredDatafilteredData")}
                <Select
                 size="large"
                 allowClear={true}
                defaultValue={values?.teamId!=""?values?.teamId:null}
                 placeholder="Select team" onChange={(e) => { setValues({ ...values, teamId: e }); }}>
                    {filteredData?.team&& filteredData?.team?.map((item, index) => {
                        return (
                            <Option value={item.id} key={index}>{item.name}</Option>
                        )
                    })}
                </Select>
                    </>)
                }
                

                <span style={{fontSize:"14px",fontWeight:600,margin:"20px 0 10px 0" }}>Start Date</span>
                <DatePickerCompo
                 size="large"
                 defaultValue={values.startDate && values.startDate !== "0000-00-00" ? moment(values.startDate) : null}
                 format="YYYY-MM-DD" onChange={e => {
                    
                    setFilterValues("startDate", moment(e))
                    setValues({ ...values, startDate: moment(e).format("YYYY/MM/DD") });
                }} />


                <span style={{fontSize:"14px",fontWeight:600,margin:"20px 0 10px 0" }}>End Date</span>
                <DatePickerCompo
                 size="large"
                 disabledDate={disabledEndDate}
                 defaultValue={values.endDate && values.endDate !== "0000-00-00" ? moment(values.endDate) : null}
                 format="YYYY-MM-DD" 
                 onChange={e => {
                    setValues({ ...values, endDate: moment(e).format("YYYY/MM/DD") });
                    setFilterValues("endDate", moment(e))
                }} />

                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%", marginTop: 30 }}>
                    <Button name={"Reset"} background={"#FFFFFF"} fontColor={"#0086FF"} disabled={false} onclick={()=>{setRoleName("");onReset()}} borderColor={"#0086FF"} />
                    <Button name={reference!=1?"Save":"Download"} disabled={values.departmentId != "" &&(roleName === "Manager"|| roleName ==="CoManager" ||roleName === "Associate")?false: values.managerId != "" && values.endDate != "" && values.startDate != "" && values.teamId != ""&&values.roleId != "" ? false : true} background={"#0086FF"} fontColor={"#FFFFFF"} onclick={() => { onSave(values) }} />

                </div>


            </div>
        </Modal>
    )
}

    export default React.memo(ApplyFiltersModalG);