export const Color = {
  black: "#2b2837",
  lightGrey: "#f6f7f8",
  // midGrey: "#e7eaed",
  midGrey: "#d6d8da",
  darkGrey: "#787b83",
  red: "#F64A3F",
  yellow: "#FDC013",
  pink: "#D23FF6",
  primaryBkg: "white",
  primary: "#5843be",
  lightprimary: "#f6f4ff",
  lightblue: "#ecf6ff",
  blue: "#1a54af",
  modalblue: "#1890ff",
  lightorange: "#ffece6",
  orange: "#fa5f1d",
  lightgreen: "#fffbf0",
  green: "#427a09",
  purple: "#5843be",
  lightpurple: "#f6f4ff",
  white: "#ffffff",
  darkGreen: '#006400'
};
