import { create } from "zustand";

const aUserStore=(set)=>({
    userList:[],
    locationList:[],
    setUserList:(payload)=>{
        set((state)=>{
            console.log("init",state.userList,"payload",payload)
            return{
            userList:payload}}
            )
    },
    setLocationList:(payload)=>{
        set((state)=>{
            console.log(state.locationList,payload)
            return{
                locationList:payload
            }
        })
    }

})

const useAUserStore=create(
    // devtools(
        aUserStore
        // persist(aUserStore,{name:"filterOption",})
    // )
);
export default useAUserStore;