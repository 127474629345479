import cs1 from "./Fill.module.css"
import brigoshaLogo from "../../../../assets/brigosha_logo.svg"
import { NavBar } from "../../../../components/topNavBar";
import { Col, Row, Input, Radio, Checkbox, Spin, Select, Typography } from "antd";
import plusIcon from "../../../../assets/plus_icon_white.svg"
import DatePickerCompo from "../../../../components/DatePicker/DatePickerCompo.tsx"
import Button from "../../../../components/buttonCustom";
import { AiOutlinePlus, AiOutlineVerticalAlignBottom } from "react-icons/ai";
import { MdOutlineCancel } from "react-icons/md";
import '../../../../design/framework.css'
import React, { useEffect, useState } from "react";
import { KeyIsNotEmpty, getFormData, getPreviewData, postData, validateAadhar, validateEmail, validatePan, validatePhone, validatePincode } from "./formData";
import { DetailSection } from "../customInputComp";
import classes from '../../../ProjectManagement/Team/Team.module.css';
import EditClasses from './EditProfile.module.css';
import { editProfilePatch, getUserDetailsById, postUploadedDocuments, publicEmployeeApi } from "../../action/action";
import { useResourceStore } from "../../store/store";
import moment from "moment";
import Notification from "../../../../services/Notification";
import { DownLoadAttendnaceLogs } from "../../../AttendancePortal/action/action";
import VerifyModal from "./VerifyModal";
import ModalCompo from "../../../../design/ModalCompo";
import UploadDocument from "./uploadDocument";
import ButtonCompo from "../../../../design/ButonCompo";
import SubmitProfile from "./SubmitProfile";
import { Navigate,useNavigate } from "react-router-dom";
import UploadDocumentMulti from "./uploadDocumentMulti";
function FillProfile(){
  const { otpVerified,otpModal,setOtpModal, ParsedData ,tab,setTab,setOpenPage,openPage,} = useResourceStore(state => state)
  const navData=["Personal and Contact Data", "Documents", "Academics", "Work Experience",];
    const Navigate=useNavigate();
    let  logoDescription=<img src={brigoshaLogo} style={{height:'9vh',width:'100%'}} />;
    const [userData,setUserData]=useState();
    const [employeeProfile, setEmployeeProfile] = useState({})
    const [uploadedFiles,setUploadedFiles]=useState({})
    const [loader,setLoader]=useState(false);
    const  [skills,setSkills]=useState({value:'',verified:false});
    const [submitModal,setSubmitModal]=useState(false);

    useEffect(()=>{
        if(!localStorage.getItem('b1faff')){
            localStorage.setItem('b1faff',false);
        }
        setTab("Personal and Contact Data")
        let params= new  URLSearchParams(window.location.search)
    
        publicEmployeeApi({userId:params.get('userId')},(res)=>{
            if(res?.show?.type==='error' && res?.show?.page==='Thanks'){
                Navigate('/ThankYou')
                return ;
            }
            setUserData(res);
            setOpenPage(res?.id)
            setEmployeeProfile({primaryPhone:res?.phone, ...employeeProfile})
        })
        
    },[window.location])
    const [next, setNext] = useState(0);
    const [uploadedDocuments, setUploadedDocuments] = useState({})
    const [openModal, setOpenModal] = useState(false);
    const [value, setValue] = useState(null);
    const [modalRender, setModalRender] = useState('')
    const [sameAsCurrent, setSameAsCurrent] = useState(false)
    const [render,setRender]=useState(false)
    const [isVerified,setVerified]=useState(true)
    const [emeEmp, setemeEmp] = useState([]);
    const [academics, setAcademics] = useState([]);
    const [workExperience, setWorkexperience] = useState([])
    const NotificationLable={'personalEmail':'personal email',"bloodGroup":'blood group',"dobActual":'wish me on',"dob":'DOB (on document)',"maritalStatus":'marital status','accountNumber':'account Number','addressLine1':'address line 1','addressLine2':'address line 2','panNumber':'pan number','panName':'pan name','aadharNumber':'aadhar number','aadharName':'aadhar name','pfNumber':'pf accound number','uanNumber':'uan number','dlNumber':'driving liscence' ,'voterIdNumber':'voter id','passportNumber':'passport number',' yearofPassing':'year of passing','gradePercentage':'grade/percentage','startDate':'start date','endDate':'end date','associateName':'associate name','joined':'joining date'}

    


    useEffect(() => {
        let flag='false';
        if(localStorage.getItem('b1faff')){
            flag=localStorage.getItem('b1faff');
            
        }

        if(userData?.id && flag==='true'){
            setLoader(true);
            setVerified(false);
        getUserDetailsById((res) => {
            setValue(res?.workExperience?.type ? res?.workExperience?.type : null)
            setSkills({value:res?.workExperience?.skills?res?.workExperience?.skills:'',verified:res?res?.workExperience?.skills_HRVerified:false?.workExperience?.skills_HRVerified});
            setEmployeeProfile({ '':'nothing', ...employeeProfile, ...res?.documents, ...res?.profile?.basicInformation, ...res?.profile?.associateProfile, ...res?.profile?.bankAccountDetails, "bankAccountDetails": { ...res?.profile?.bankAccountDetails }, ...res?.profile?.addressInformation,"noticePeriod":{...res?.profile?.noticePeriod},'workExperience':res?.workExperience,'academics':res?.academics })
            setUploadedFiles({  'Document':{'photo':res?.profile?.associateProfile?.photo,
                                'resume':res?.workExperience?.resumeLink,
                                'pan':res?.documents?.pan?.panLink,
                                'aadhar':res?.documents?.aadhar?.aadharLink,
                                'passport':res?.documents?.passport?.passportLink,
                                'dl':res?.documents?.dl?.dlLink,
                                'voterId':res?.documents?.voterId?.voterIdLink,
                                'bloodGroup':res?.documents?.bloodDoc?.bloodDocLink,
                                'others':res?.documents?.others,
                                            },
                                            'Academics':res?.academics?.academicsDocuments,
                                            'Experience':res?.workExperience?.documents,
                            })
                            setLoader(false);
                            
         if (res?.profile?.emergencyContact?.length > 0 && res?.profile?.emergencyContact?.length <= 2) {
                setemeEmp([...res?.profile?.emergencyContact]);

            } 
        else if (res?.profile?.emergencyContact?.length<=0 || res?.profile?.emergencyContact===null) {
                setemeEmp([{
                    name: "",
                    contact: "",
                    relation: "",
                    contact_Verified:false,
                    name_HRVerified:false,
                    contact_HRVerified:false,
                    relation_HRVerified:false,
                }]);
            }
        setSameAsCurrent(res?.profile?.addressInformation?.sameAsCurrent)
            if (res?.academics?.academicsData?.length>0 &&res?.academics?.academicsData!="") {
                setAcademics([...res?.academics?.academicsData]);
            }
            else {
                setAcademics([
                    {
                        qualification: "",
                        yearofPassing: "",
                        gradePercentage: "",
                        qualification_HRVerified: false,
                        yearofPassing_HRVerified: false,
                        gradePercentage_HRVerified: false,
                    }
                ])
            }
            if(res?.workExperience?.experienced?.length){
                setWorkexperience([...res?.workExperience?.experienced])
            }
            else{
               setWorkexperience([ {
                companyName: "",
                startDate: "",
                endDate: "",
                companyName_HRVerified: false,
                startDate_HRVerified: false,
                endDate_HRVerified: false,
            }])
            }
        }, userData?.id);}
        setWorkexperience([...workExperience, ...ParsedData?.workExp]);
    }, [userData?.id, otpVerified,render]);

    const onSameAsCurrent = (e) => {
        setSameAsCurrent(e.target.checked)
    }


    const handleInputChange = (val, key, childkey1, childkey2 = '') => {
        if (childkey1 === 'upload') {
            uploadedDocuments[key] = val;
            setUploadedDocuments({ ...uploadedDocuments })
        }
        if (!employeeProfile[childkey2]) {
            employeeProfile[childkey2] = {};
            if (!employeeProfile[childkey2][key]) {
                employeeProfile[childkey2][key] = val;
              }
          }else{
            if (childkey2 !== '') {
                employeeProfile[childkey2][key] = val;
                setEmployeeProfile({ ...employeeProfile })
            } else {
                employeeProfile[key] = val;
                setEmployeeProfile({ ...employeeProfile })
            }
          }  
    }

    const onSavePatch = async(files=null,academicsFiles=null,experianceFile=null,callBack=null,submit=false) => {
         let param =await postData(employeeProfile, emeEmp, academics, workExperience, sameAsCurrent, value, files,academicsFiles,experianceFile,skills,submit)
         editProfilePatch(param, openPage,(res)=>{
            if(submit){
                console.log("ssssssssssssss",submit,res)
                if(res){
                console.log("ssssssssssssss kakk",submit)

                    localStorage.removeItem('token');
                    localStorage.removeItem('b1faff');
                    Navigate('/ThankYou')
                }
            }
            if(res){
                setRender(!render);
                setOpenModal(false);
    }
    })
}

    function areAllKeysFilledInArray(arr,section) {
        for (let i = 0; i < arr.length; i++) {
          const obj = arr[i];
          for (let key in obj) { 
            if (obj[key] === undefined || obj[key] === null || obj[key] === '') {
            let label=KeyIsNotEmpty(NotificationLable[key])?NotificationLable[key]:key
            
                Notification.error("Error!",`Please fill the ${label} field ${KeyIsNotEmpty(section)?`in ${section} section`:''}`);
                return false;
            }
            if(validatePhone(obj['contact'])){
                Notification.error('Error!','Please enter 10 digit contact no. in emergency contact');
                return false;
            }
            if(obj['contact_Verified']!==undefined && !obj['contact_Verified']){
                console.log('kaushal',obj)
                Notification.error('Error!','Please verify you emergency contact no.')
                return false;
            }
          }
        }
        return true;
      }

      function areAllKeysDefined(obj, keys,section) {
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (obj?.[key] === undefined || obj?.[key] === null || obj?.[key] === "") {
            let label=KeyIsNotEmpty(NotificationLable[key])?NotificationLable[key]:key
            console.log(key,"this is the empty key");
            Notification.error("Error!",`Please fill the ${label} field ${KeyIsNotEmpty(section)?`in ${section} section`:''}`);
            return false;
          }
        }
        return true;
      }
      
      useEffect(()=>{
        console.log("data change",employeeProfile)
      },[employeeProfile])

      const checkAddressIsFilled=()=>{
        if(KeyIsNotEmpty(employeeProfile?.current)===false){
            Notification.error('Error!','Please fill the current address section')
            return false;
        }
       else if(KeyIsNotEmpty(employeeProfile?.current)){

       
        if(areAllKeysDefined(employeeProfile?.current,['addressLine1','city','pincode','state'],'Current Address')){

            if(sameAsCurrent){
                return true;
            }
            else{
                if(KeyIsNotEmpty(employeeProfile?.permanent)===false){
                    Notification.error('Error!','Please fill the permanent address section')
                    return false;
                   }
            else if( KeyIsNotEmpty(employeeProfile?.permanent)){
                    
                        return areAllKeysDefined(employeeProfile?.permanent,['addressLine1','city','state','pincode',],'Permanent Address');
                    }
                   }
            

           
        }

       
       }
      
      }
    const checkOnPersonalDetailsFill = () => {
        const requiredKeys = [
            "personalEmail",
            "bloodGroup",
            "dobActual",
            "dob",
            "gender",
            "category",
            "maritalStatus",
          ];
          console.log(44444444444)
        // && areAllKeysFilledInArray(employeeProfile?.bankAccountDetails) bank account will not be mandatory from user side.
        if (areAllKeysDefined(employeeProfile,requiredKeys) && areAllKeysFilledInArray(emeEmp,'Emergency Contact') && checkAddressIsFilled() ) {
            if(validatePhone(employeeProfile?.secondaryPhone)){
                Notification.error('Error!','Please enter 10 digit secondary phone number');
                return false;
                }
                if(validateEmail(employeeProfile?.personalEmail)){
                    Notification.error('Error!','Please enter valid personal mail')
                    return false; 
                }
                if(validatePincode(employeeProfile?.current?.pincode)){
                    Notification.error('Error!','Please enter 6 digit pincode in current address')
                    return false;
                }
                if(!sameAsCurrent && validatePincode(employeeProfile?.permanent?.pincode) ){
                    Notification.error('Error!','Please enter 6 digit pincode in permanent address')
                    return false;
                }
            return true;
        } else {

            return false;
            // Notification.error("Error", "please fill all the mandatory fileds");
        }
    }

    const checkOnDocumentDetailsFill = () =>{
        if (areAllKeysDefined({...employeeProfile?.pan,...employeeProfile?.aadhar},["aadharName","aadharNumber","panName","panNumber"])) {
           
            if(validatePan(employeeProfile?.pan?.panNumber)){
                Notification.error('Error!','Please enter valid PAN')
                return false;
            }
            if(validateAadhar(employeeProfile?.aadhar?.aadharNumber)){
                Notification.error('Error!','Please enter valid aadhar')
                return false;
            }
            return true;
        } else {
            return false
            // Notification.error("Error", "please fill all the mandatory fileds");
        }
    }
    const checkAcademicsDetailsFill= () =>{
        if (areAllKeysFilledInArray(academics)) {
            return true;
        } else {
            return false
            // Notification.error("Error", "please fill all the mandatory fileds");
        }
    }
    const checkWorkexpDetailsFill= () =>{
        if((value !== "Fresher"&&value !== null)){
            if (areAllKeysFilledInArray(workExperience)) {
                return true;
            } else {
                return false;
                // Notification.error("Error", "please fill all the mandatory fileds");
            }
        }else{
            if (value===null){
                Notification.error("Error", "please fill the experience details");

            }else
            return true
        }
    }
   
    const SubmitHandler=()=>{
                 if(checkOnPersonalDetailsFill()&& checkOnDocumentDetailsFill()&& checkAcademicsDetailsFill()&& checkWorkexpDetailsFill() ){
                                                            onSavePatch()
                }
                                                        onSavePatch(null,null,null,null,true) 
    }

    const onRemove = (index) => {
        let temp = [...emeEmp]
        temp.splice(index, 1)
        setemeEmp([...temp])
    }
    const handleFocusChange = (val, key) => {
        employeeProfile[key] = val;
        setEmployeeProfile({ ...employeeProfile })
    }

    const handleAdd = (getter, index, type, value, setter) => {
        getter[index][type] = value;
        
        if(type==='contact'){
            getter[index]['contact_Verified']=false;
        }
        setter([...getter]);
    }
    const checkOnFileUpload= (tab,length,submit)=>{
        let ll=  getPreviewData(employeeProfile,false,tab)[0].data?.length;
        if(ll<length){
            if(submit){
                if(tab==='Work Experience' && value==='Fresher'){
                    return true
                }
                else{
                Notification.error('Error!',`Please Upload Documents in ${tab} section`)
                return false
                }
            }
            else{
                if(tab==='Work Experience' && value==='Fresher'){
                    return true
                }
                else{
                    Notification.error('Error!','Please Upload Documents')
                    return false
                }
            }
        }
        else{
            return true
        }
    
    
        }
    const handleAddrDuplicate = (val) => {
    }
   
    const saveVerified=(index)=>{
        console.log('999999999',index)
     let saveData=[...emeEmp];
     saveData[index].contact_Verified=true;
     setemeEmp(saveData);
    //  let param={'profile':{'emergencyContact':saveData}}
    //  editProfilePatch(param,openPage,(res)=>{
    //     if(res){
    //         setRender(!render);
    //     }
    //  })

    }
    return( <div style={{background: "#FFFFFF"}}>
         <div
        style={{
          height: "10vh",
          
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 3.5rem 0 2rem",
          margin:0,
         
        }}
      >
        <div>
         {logoDescription}
        </div>        
        <div className={classes.header1} style={{fontSize:"1.5rem",fontWeight:"700",marginLeft:"10%"}}>Fill Associate Profile</div>
        <div className={cs1.emptyDiv} ></div>
         </div>
         {(otpModal?.flag && otpModal?.place==='PrimaryPhone')?<VerifyModal phone={employeeProfile?.primaryPhone} id={userData?.id} />:''}
    <div style={{border:'1px solid #cfcfcf'}}></div>
    <div  style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
        <div className={cs1.bodyConatiner} >
          <div className={cs1.firstHeading} >
          <div className={cs1.header1}>Associate Profile</div>
          <div className={cs1.AD}>
            <div>
              <div style={{display:'flex',flexDirection:'column',gap:'16px'}}>
                <div className={cs1.title}>Name</div>
                <div className={cs1.title}>Employee ID</div>
                <div className={cs1.title}>Email Id</div>      
              </div>
              <div style={{display:'flex',flexDirection:'column',gap:'16px'}}>
              <Typography.Text ellipsis={{tooltip:true}} style={{maxWidth:'35vw',font:'normal 500 16px/20px Lato'}} className={cs1.detail}>{userData?.name}</Typography.Text>
                <div className={cs1.detail}>{userData?.uid}</div>
                <Typography.Text ellipsis={{tooltip:true}} style={{maxWidth:'35vw',font:'normal 500 16px/20px Lato'}} className={cs1.detail}>{userData?.email}</Typography.Text>
              </div>
            </div>
            <div>
              <div style={{display:'flex',flexDirection:'column',gap:'16px'}}>
                <div className={cs1.title}>Phone No.</div>
                <div className={cs1.title}>Designation</div>
                <div className={cs1.title}>Joining Date</div>

              </div>
              <div style={{display:'flex',flexDirection:'column',gap:'16px'}}>
                <div className={cs1.detail}>{userData?.phone}</div>
                <div className={cs1.detail}>{userData?.designation}</div>
                <div className={cs1.detail}>{moment(userData?.joined).format('DD-MMM-YYYY')}</div>
              </div>
            </div>
          </div>
          </div>
          <div style={{border:'1px solid #cfcfcf', margin:'20px 0px'}}></div>


          <div className={EditClasses.WebViewTab}>
                        <NavBar data={navData} defaultView={tab} onClick={(res) => {
                            setTab(res)
                        }} />
          </div>
          <div className={EditClasses.MobViewTab}>
                        <Select key={tab} disabled={isVerified} style={{width:'65vw'}} onChange={(val)=>{ console.log('kaushal',val); setTab(val);}} defaultValue={tab}>
                        {navData?.map((val,id)=>{
                            return(
                                <Select.Option value={val} key={id}>{val}</Select.Option>
                            )
                        })}    
                        </Select>
                    </div>
          {loader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh',}}><Spin  /></div>:<>
          <div>
                        {
                            tab === "Personal and Contact Data" ?
                            <div style={{ flexDirection: "column", paddingTop: 10, display: "flex",}}>
                                    <div style={{ display: "flex", flexDirection:'column', justifyContent: "flex-start", }}>
                                            <div style={{ height: "fit-content", padding: 10, }}>
                                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                    {
                                                        getFormData(employeeProfile, handleInputChange, handleFocusChange, handleAddrDuplicate, "Basic Information Associate")?.map((section, index) => (
                                                            <DetailSection section={section} index={index}   />
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                    {
                                        next >= 1 &&
                                        <>
                                            <div style={{ height: "fit-content", padding: 10 }}>
                                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="r-jsb">
                                                                        <div style={{ display: "flex", width: "100%", alignItems: "center", gap: "1.5rem", margin: "1rem 0 1rem 0", }}>
                                                                            <div style={{ fontSize: 18, fontWeight: 600, }}>Address Information</div>
                                                                        </div>
                                                                    </div>
                                                    {
                                                        getFormData(employeeProfile, handleInputChange, handleFocusChange, handleAddrDuplicate, "Address Information Associate")?.map((section, index) => (
                                                            <DetailSection section={section} index={index}   />
                                                        ))
                                                    }
                                                    <div style={{paddingBottom:'1rem'}}>
                                                        <Checkbox  defaultChecked={sameAsCurrent} onChange={onSameAsCurrent}> <b>Same as Current Address</b></Checkbox>
                                                    </div>
                                                    {
                                                        getFormData(employeeProfile, handleInputChange, handleFocusChange, handleAddrDuplicate, "Address InformationP Associate", sameAsCurrent)?.map((section, index) => (
                                                            <DetailSection section={section} index={index}   />
                                                        ))
                                                    }
                                                    <div style={{ width: "100%" }}>
                                                        <div className="r-jsb">
                                                            <div style={{display:'flex',width:'95%',justifyContent:'space-between'}} >
                                                                <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 5 }}>Emergency Contact</div>
                                                            {
                                                                emeEmp.length <= 1 &&
                                                                <Button name="Add" height="30px" background={"#0086FF"} borderColor={"white"} fontColor={"white"} Prefix={() => <AiOutlinePlus style={{ border: "0px solid black", height: "15px", width: "15px" }} />} onclick={() => {
                                                                    if (emeEmp.length <= 1) {
                                                                        let temp = [...emeEmp];
                                                                        temp.push({
                                                                            name: "",
                                                                            contact: "",
                                                                            relation: "",
                                                                            contact_Verified:false,
                                                                            name_HRVerified:false,
                                                                            contact_HRVerified:false,
                                                                            relation_HRVerified:false,
                                                                        })
                                                                        setemeEmp(temp)
                                                                    }

                                                                }} />
                                                            }
                                                            </div>
                                                        </div>
                                                        {
                                                            emeEmp.map((i, index) =>{
                                                                console.log('88888888',index)
                                                            let title=i.contact_Verified?'Verified':'Verify';
                                                            return(
                                                                <>
                                                                    {
                                                                        index >= 1 &&
                                                                        <div style={{ color: "red", display: "flex", justifyContent: "flex-end", cursor: "pointer" ,width:'95%'}} onClick={() => {
                                                                            onRemove(index)
                                                                        }} >remove <span style={{ color: "red", fontSize: 10 }}><MdOutlineCancel style={{ border: "0px solid black", height: "20px", width: "20px", color: "red", marginTop: 2 }} /></span></div>
                                                                    }
                                                                    <Row>

                                                                        <Col span={24} style={{ width: "100%", marginBottom: "1rem" }}>
                                                                            <div>
                                                                                <div className="r-jsb">
                                                                                    <div style={{ color: "#898989", fontWeight: 500 }}>{"Person Name"}{true && <span style={{ color: "red" }}> *</span>}</div>
                                                                                </div>
                                                                            </div>

                                                                            <div style={{ width: "95%", display: "flex", alignItems: "center" }}>
                                                                                <Input
                                                                                    onChange={(e) => { handleAdd(emeEmp, index, "name", e.target.value, setemeEmp) }}
                                                                                    value={i?.name}
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        borderRadius: "5px",
                                                                                        height: "2.5rem",
                                                                                        border: " 1px solid #dfdfdf",
                                                                                        // boxShadow:'0px 4px 4px  rgba(0,0,0,0.2)',
                                                                                        
                                                                                    }}
                                                                                />
                
                                                                            </div>
                                                                        </Col>
                                                                        <Col span={24} style={{ width: "100%" }}>
                                                                            <div>
                                                                                <div className="r-jsb">
                                                                                    <div style={{ color: "#898989", fontWeight: 500 }}>{"Contact No"}{true && <span style={{ color: "red" }}> *</span>}</div>
                                                                                    {validatePhone(i?.contact) && <div style={{
                                                                                            color: "red",
                                                                                            marginLeft: "30%",
                                                                                        }}
                                                                                        >
                                                                                        {'Please Enter 10 digit phone no.'}</div>}
                                                                                </div>
                                                                            </div>

                                                                            <div style={{ width: "95%", display: "flex", alignItems: "center" }}>
                                                                                <Input
                                                                                    onChange={(e) => { handleAdd(emeEmp, index, "contact", e.target.value, setemeEmp) }}
                                                                                    value={i?.contact}
                                                                                    type={"number"}
                                                                                    onWheel={(e) => e.target.blur()}
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        borderRadius: "5px",
                                                                                        height: "2.5rem",
                                                                                        border: " 1px solid #dfdfdf",
                                                                                        // boxShadow:'0px 4px 4px  rgba(0,0,0,0.2)',

                                                                                    }}
                                                                                />
                                                                                <div >
                                                                                    <ButtonCompo title={title} style={{color:title==='Verify'?'#fff':'#0086ff',background:title==='Verify'?'#0086ff':'#fff',marginLeft:5}} onClick={()=>{
                                                                                        if(title==='Verify'){
                                                                                    if(validatePhone(i.contact) || i.contact?.length===0 ){
                                                                                        Notification.error('Error!','Please Enter 10 digit valid contact no.')
                                                                                        return;
                                                                                    }
                                                                                    setOtpModal({flag:true,place:'emergencyContact',index:index});
                                                                                    }
                                                                                    }}   />
                                                                                </div>
                                                                                {(otpModal?.flag && otpModal?.place==='emergencyContact')?<VerifyModal phone={i?.contact} id={userData?.id}  saveVerified={saveVerified} />:''}
                                                                                
                                                                            </div>
                                                                        </Col>
                                                                        <Col span={24} style={{ width: "100%", marginBottom: "1rem" }}>
                                                                            <div>
                                                                                <div className="r-jsb">
                                                                                    <div style={{ color: "636363", fontWeight: 500 }}>{"Relation"}{true && <span style={{ color: "red" }}> *</span>}</div>
                                                                                </div>
                                                                            </div>

                                                                            <div style={{ width: "95%", display: "flex" }}>
                                                                                <Input
                                                                                    onChange={(e) => { handleAdd(emeEmp, index, "relation", e.target.value, setemeEmp) }}
                                                                                    value={i?.relation}
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        borderRadius: "5px",
                                                                                        height: "2.5rem",
                                                                                        // boxShadow:'0px 4px 4px  rgba(0,0,0,0.2)',
                                                                                        border: " 1px solid #dfdfdf",
                                                                                    }}
                                                                                />

                                                                                
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )
                                                            }
                                                            
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        next >= 2 &&
                                        <>
                                            <div style={{ height:'fit-content',padding: 10 }}>
                                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                    {
                                                        getFormData(employeeProfile, handleInputChange, handleFocusChange, handleAddrDuplicate, "Bank Account Details Associate")?.map((section, index) => (
                                                            <DetailSection section={section} index={index}  />
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                       ( next >= 2) ?
                                            <>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "95%", marginTop: "1rem", }}>
                                               
                                                   
                                                         <Button name={"save"} background={"#0086FF"} fontColor={"#FFFFFF"} disabled={false} onclick={() => {
                                                            if (checkOnPersonalDetailsFill()) {
                                                                onSavePatch()
                                                            }
                                                            }} />
                                                </div>
                                            </>
                                            :
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", width: "90%", }}>
                                                <Button name={"next"} borderColor={"#0086FF"} fontColor={"#0086FF"} disabled={false}
                                                    onclick={() => {
                                                        let flag=localStorage.getItem('b1faff')
                                                        if(flag){
                                                            if(flag==='false'){
                                                                Notification.error("error",'Please verify your number')
                                                                return;
                                                            }
                                                            else{
                                                            if (next <= 4) {
                                                            setNext(next + 1)
                                                        }
                                                        }
                                                        }
                                                       
                                                       
                                                    }} />
                                            </div>
                                    }
                                </div>
                                </div>
                                :
                                tab === 'Documents' ?
                                <div style={{ height: "fit-content", padding: 20, }}>
                                    <div style={{ display: "flex", justifyContent:'space-between', flexDirection:'column'}}>
                                       
                                                    {
                                                        getFormData(employeeProfile, handleInputChange, handleFocusChange, handleAddrDuplicate, "Document Details Associate")?.map((section, index) => (
                                                            <DetailSection section={section} index={index}  />
                                                        ))
                                                    }
                                              <div style={{display:'flex',justifyContent:'flex-start'}}>  
                                              <ButtonCompo title={"Upload Document"} suffixIcon={plusIcon} style={{background:'#0086ff',color:'#fff',}} onClick={() => {
                                                setOpenModal(true);
                                                setModalRender({type:'Document Details'});
                                                }} />
                                                </div>
                                                </div>
                                           
                                        
                                        {/* <Button name={"Document"} background={"#FDA428"} fontColor={"#FFFFFF"} disabled={false} onclick={() => { setOpenModal(true) }} /> */}
                                        {/* <Button name={"Document"} background={"#FDA428"} fontColor={"#FFFFFF"} disabled={false} onclick={() => { setOpenModal(true); setModalRender('Document Details') }} /> */}

                                        <div style={{ display: "flex", alignItems: "center",width:'95%', justifyContent: "flex-end",  marginTop: 10 }}>
                                         
                                        
                                               <Button name={"save"} background={"#0086FF"} fontColor={"#FFFFFF"} disabled={false} onclick={() => {
                                               if(checkOnDocumentDetailsFill() && checkOnFileUpload('Documents',4)){
                                                   onSavePatch()
                                               }

                                            }} />

                                        </div>
                                    </div>
                                    :
                                    tab === 'Academics' ?
                                    <>
                                            <div style={{ height: "fit-content",  padding: 10 }}>
                                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                    <div style={{ width: "100%" }}>
                                                        <div className="r-jsb">
                                                            <div style={{ display: "flex", width: "100%", alignItems: "center", gap: "1.5rem", margin: "1rem 0 1rem 0", }}>
                                                                <div style={{ fontSize: 16, fontWeight: 600, }}>Academics Details</div>
                                                                <div style={{ display: "flex", width: "20%", justifyContent: "right" }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            academics.map((i, index) => (
                                                                <>
                                                                    {
                                                                        index >= 1 &&
                                                                        <div style={{ color: "red", display: "flex", justifyContent: "flex-end", cursor: "pointer" ,width:'95%',}} onClick={() => {
                                                                            let temp = [...academics]
                                                                            temp.splice(index, 1)
                                                                            setAcademics([...temp])
                                                                        }} >remove <span style={{ color: "red", fontSize: 12 }}><MdOutlineCancel style={{ border: "0px solid black", height: "20px", width: "20px", color: "red" }} /></span></div>
                                                                    }
                                                                    <Row>

                                                                        <Col span={24} style={{ width: "100%", marginBottom: "1rem" }}>
                                                                            <div>
                                                                                <div className="r-jsb">
                                                                                    <div style={{ color: "#898989", fontWeight: 500 }}>Qualification{true && <span style={{ color: "red" }}> *</span>}</div>
                                                                                </div>
                                                                            </div>

                                                                            <div style={{ width: "95%", display: "flex", alignItems: "center",marginTop:'1rem' }}>
                                                                                <Input
                                                                                    onChange={(e) => { handleAdd(academics, index, "qualification", e.target.value, setAcademics) }}
                                                                                    value={i?.qualification}
                                                                                    placeholder="Enter Qualification"
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        borderRadius: "5px",
                                                                                        height: "2.5rem",
                                                                                        border: " 1px solid #dfdfdf",
                                                                                        // boxShadow:'0px 4px 4px  rgba(0,0,0,0.2)',

                                                                                    }}
                                                                                />

                                                                               
                                                                            </div>
                                                                        </Col>
                                                                        <Col span={24} style={{ width: "100%" }}>
                                                                            <div>
                                                                                <div className="r-jsb">
                                                                                    <div style={{ color: "#898989", fontWeight: 500 }}>Year of Passing{true && <span style={{ color: "red" }}> *</span>}</div>
                                                                                </div>
                                                                            </div>

                                                                            <div style={{ width: "95%", display: "flex", alignItems: "center",marginBottom: "1rem",marginTop:'1rem' }}>
                                                                                <Input
                                                                                    onChange={(e) => { handleAdd(academics, index, "yearofPassing", e.target.value, setAcademics) }}
                                                                                    value={i?.yearofPassing}
                                                                                    placeholder="Enter year of passing"
                                                                                    type="number"
                                                                                    onWheel={(e) => e.target.blur()}
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        borderRadius: "5px",
                                                                                        height: "2.5rem",
                                                                                        border: " 1px solid #dfdfdf",
                                                                                        // boxShadow:'0px 4px 4px  rgba(0,0,0,0.2)',

                                                                                    }}
                                                                                />

                                                                               
                                                                            </div>
                                                                        </Col>
                                                                        <Col  style={{ width: "100%", marginBottom: "1rem" }}>
                                                                            <div>
                                                                                <div className="r-jsb">
                                                                                    <div style={{ color: "#898989", fontWeight: 500 }}>Grade/Percentage{true && <span style={{ color: "red" }}> *</span>}</div>
                                                                                </div>
                                                                            </div>

                                                                            <div style={{ width: "95%", display: "flex", alignItems: "center",marginTop:'1rem' }}>
                                                                                <Input
                                                                                    onChange={(e) => { handleAdd(academics, index, "gradePercentage", e.target.value, setAcademics) }}
                                                                                    value={i?.gradePercentage}
                                                                                    placeholder="Enter grade"
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        borderRadius: "5px",
                                                                                        height: "2.5rem",
                                                                                        border: " 1px solid #dfdfdf",
                                                                                        // boxShadow:'0px 4px 4px  rgba(0,0,0,0.2)',

                                                                                    }}
                                                                                />

                                                                               
                                                                            </div>
                                                                        </Col>
                                                                    </Row>

                                                                </>
                                                            ))
                                                        }
                                                    <div style={{display:'flex',justifyContent:'flex-start'}}>  
                                                        <ButtonCompo title={"Upload Document"} suffixIcon={plusIcon} style={{background:'#0086ff',color:'#fff',marginTop:'2rem'}} onClick={() => {
                                                                        setOpenModal(true);
                                                                        setModalRender({type:'Academics'});
                                                                    }} />
                                                    </div>
                                                    <div style={{display:'flex',justifyContent:'flex-start',marginTop:'1rem'}}>  
                                                   {academics.length <= 4 &&    <ButtonCompo title={"+ Add Academic Details"} style={{border:'1px solid #0086ff',color:'#0086ff',}} onClick={() => {
                                                                                if (academics.length <= 4) {
                                                                                    let temp = [...academics];
                                                                                    temp.push({
                                                                                        qualification: "",
                                                                                        yearofPassing: "",
                                                                                        gradePercentage: "",
                                                                                        qualification_HRVerified: false,
                                                                                        yearofPassing_HRVerified: false,
                                                                                        gradePercentage_HRVerified: false,
                                                                                    })
                                                                                    setAcademics(temp)
                                                                                }
                                                                            }} />
                                                   }
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "95%", marginTop: 10 }}>
                                              
                                                <Button name={"save"} background={"#0086FF"} fontColor={"#FFFFFF"} disabled={false} onclick={() => { 
                                                    if(checkAcademicsDetailsFill() && checkOnFileUpload('Academics',3)){
                                                        onSavePatch() 
                                                    }
                                                   }} />
                                               
                                            </div>

                                        </>
                                        :
                                        tab === 'Work Experience' ?
                                        <>
                                                <div style={{ height: "fit-content", padding: 10 }}>
                                                    <div style={{ display: "flex", flexWrap: "wrap",  }}>
                                                        <div style={{ width: "100%" }}>
                                                            <div style={{ display: "flex", flexDirection: "row", gap: "2rem", alignItems: "center", justifyContent: "space-between", margin: "1rem 0" }}>
                                                                <div style={{ display: "flex", }}>
                                                                    <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 5 }}>Experience Details</div>
                                                                </div>
                                                                {
                                                                    (value !== "Fresher"&&value !== null) &&
                                                                    <>
                                                                        <div style={{ display: "flex", width: "70%", justifyContent: "space-around" }}>
                                                                            <ButtonCompo title={"Download Background Verification Form"}  style={{color:'#fff',background:'#0086ff',height:'2rem'}} onClick={()=>{
                                                                                DownLoadAttendnaceLogs(()=>{},"/resource-user/getBackgroundVForm",null,1)
                                                                            }} />
                                                                        </div>

                                                                    </>
                                                                }
                                                            </div>
                                                            <div style={{ display: "flex", margin: "10px 0px 10px 0px", cursor: "pointer", alignItems: "center", justifyContent: "space-between", marginBottom: "1rem" }}>
                                                                <Radio.Group value={value} onChange={(e) => { setValue(e.target.value) }} >
                                                                    <Radio value={"Fresher"}>Fresher</Radio>
                                                                    <Radio value={"Experience"}>Experience</Radio>
                                                                </Radio.Group>
                                                                
                                                            </div>

                                                                        {  (value !== "Fresher"&&value !== null)  &&
                                                                            workExperience.map((i, index) => (
                                                                                <>

                                                                                   
                                                                                    <Row span={24} >

                                                                                        <Col span={24} style={{ width: "100%", marginBottom: "1rem",marginTop:'1rem' }}>
                                                                                            <div>
                                                                                                <div className="r-jsb">
                                                                                                    <div style={{ color: "#898989", fontWeight: 500 }}>{"Company Name"}{true && <span style={{ color: "red" }}> *</span>}</div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div style={{ width: "95%", display: "flex", alignItems: "center",marginTop:'1rem' }}>
                                                                                                <Input
                                                                                                    onChange={(e) => { handleAdd(workExperience, index, "companyName", e.target.value, setWorkexperience) }}
                                                                                                    value={i?.companyName}
                                                                                                    placeholder="Enter company name"
                                                                                                    style={{
                                                                                                        width: "100%",
                                                                                                        borderRadius: "5px",
                                                                                                        height: "2.5rem",
                                                                                                        border: " 1px solid #dfdfdf",
                                                                                                        // boxShadow:'0px 4px 4px  rgba(0,0,0,0.2)',
                                                                                                    }}
                                                                                                />

                                                                                               
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col span={24} style={{ width: "100%" ,marginTop:'1rem'}}>
                                                                                            <div>
                                                                                                <div className="r-jsb">
                                                                                                    <div style={{ color: "#898989", fontWeight: 500 }}>{"Start Date"}{true && <span style={{ color: "red" }}> *</span>}</div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div style={{ width: "95%", display: "flex", alignItems: "center",marginTop:'1rem' }}>
                                                                                                <DatePickerCompo
                                                                                               
                                                                                               onChange={(e) => { 
                                                                                                        if(e){
                                                                                                         handleAdd(workExperience, index, "startDate", moment(e), setWorkexperience)
                                                                                                        }
                                                                                                        else{
                                                                                                         handleAdd(workExperience, index, "startDate", undefined, setWorkexperience)
                                                                                                        }
                                                                                                    
                                                                                                    }}
                                                                                                    allowClear={false}
                                                                                                    className="profileDatepicker"
                                                                                                    placeholder="Select startdate"
                                                                                                    disabledDate={(current) => {return current && current >= moment().endOf('day');}}
                                                                                                    value={KeyIsNotEmpty(i.startDate)?moment(i.startDate):undefined}                                                                                                    style={{
                                                                                                        width: "100%",
                                                                                                        borderRadius: "5px",
                                                                                                        height: "2.5rem",
                                                                                                        border: " 1px solid #dfdfdf",
                                                                                                        // boxShadow:'0px 4px 4px  rgba(0,0,0,0.2)',
                                                                                                    }}
                                                                                                />

                                                                                               
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col span={24} style={{ width: "100%", marginBottom: "1rem",marginTop:'1rem' }}>
                                                                                            <div>
                                                                                                <div className="r-jsb">
                                                                                                    <div style={{ color: "#898989", fontWeight: 500 }}>{"End Date"}{true && <span style={{ color: "red" }}> *</span>}</div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div style={{ width: "95%", display: "flex", alignItems: "center",marginTop:'1rem' }}>
                                                                                                <DatePickerCompo
                                                                                                    onChange={(e) => {
                                                                                                        if(e){
                                                                                                         handleAdd(workExperience, index, "endDate", moment(e), setWorkexperience)
                                                                                                        }
                                                                                                        else{
                                                                                                         handleAdd(workExperience, index, "endDate", undefined, setWorkexperience)
                                                                                                        }
                                                                                                          }}
                                                                                                    allowClear={false}
                                                                                                    className="profileDatepicker"
                                                                                                    value={KeyIsNotEmpty(i.endDate)?moment(i.endDate):undefined}
                                                                                                    disabledDate={(current) => {return (current >= moment().endOf('day') || current < moment(i.startDate))}}
                                                                                                    placeholder={"Select enddate"}
                                                                                                    style={{
                                                                                                        width: "100%", height: 30, border: null,
                                                                                                        borderRadius: "5px",
                                                                                                        height: "2.5rem",
                                                                                                        border: " 1px solid #dfdfdf",
                                                                                                        // boxShadow:'0px 4px 4px  rgba(0,0,0,0.2)',
                                                                                                    }}
                                                                                                    
                                                                                                />
                                                                                                       
                                                                                               
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    {index===0 && 
                                                                                        <Row>
                                                                       <Col  style={{ width: "100%", marginBottom: "1rem",marginTop:'1rem' }}>
                                                                            <div>
                                                                                <div className="r-jsb">
                                                                                    <div style={{ color: "#898989", fontWeight: 500 }}>Skills</div>
                                                                                </div>
                                                                            </div>

                                                                            <div style={{ width: "95%", display: "flex", alignItems: "center",marginTop:'1rem' }}>
                                                                                <Input
                                                                                    onChange={(e) => { setSkills((curr)=>( {...curr,value:e.target.value})) }}
                                                                                    value={skills.value}
                                                                                    placeholder="Enter skills"
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        borderRadius: "5px",
                                                                                        height: "2.5rem",
                                                                                        border: " 1px solid #dfdfdf",
                                                                                        // boxShadow:'0px 4px 4px  rgba(0,0,0,0.2)',
                                                                                    }}
                                                                                />

                                                                                
                                                                            </div>
                                                                        </Col>
                                                                                        </Row>
                                                                                    }
                                                                                    <div style={{display:'flex',justifyContent:'space-between',gap:'16px',width:'95%'}}>
                                                                                    
                                                                                        <div style={{display:'flex',justifyContent:'flex-start'}}>  
                                                                                        <ButtonCompo title={"Upload Document"} suffixIcon={plusIcon} style={{background:'#0086ff',color:'#fff',}} onClick={() => {
                                                                                            setOpenModal(true);
                                                                                            setModalRender({type:'Work Experience',length:workExperience?.length,curr:index});
                                                                                            }} />
                                                                                        </div>
                                                                                        {
                                                                                        index >= 1 && <div style={{ color: "red", display: "flex", justifyContent: "flex-end", cursor: "pointer", }} onClick={() => {
                                                                                            let temp = [...workExperience]
                                                                                            temp.splice(index, 1)
                                                                                            setWorkexperience([...temp])
                                                                                        }} >remove <span style={{ color: "red", fontSize: 12 }}><MdOutlineCancel style={{ border: "0px solid black", height: "20px", width: "20px", color: "red" }} /></span>
                                                                                        </div>}
                                                                                        </div>
                                                                                    

                                                                                </>
                                                                            ))
                                                                        }
                                                            {
                                                                value==="Fresher" && 
                                                                <Row>
                                                                       <Col  style={{ width: "100%", marginBottom: "1rem",marginTop:'1rem' }}>
                                                                            <div>
                                                                                <div className="r-jsb">
                                                                                    <div style={{ color: "#898989", fontWeight: 500 }}>Skills</div>
                                                                                </div>
                                                                            </div>

                                                                            <div style={{ width: "95%", display: "flex", alignItems: "center",marginTop:'1rem' }}>
                                                                                <Input
                                                                                    onChange={(e) => { setSkills((curr)=>( {...curr,value:e.target.value})) }}
                                                                                    value={skills.value}
                                                                                    placeholder="Enter skills"
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        borderRadius: "5px",
                                                                                        height: "2.5rem",
                                                                                        border: " 1px solid #dfdfdf",
                                                                                        // boxShadow:'0px 4px 4px  rgba(0,0,0,0.2)',
                                                                                    }}
                                                                                />

                                                                                
                                                                            </div>
                                                                        </Col>
                                                                                        </Row>
                                                            }
                                                                       
                                                        
                                            {(value !== "Fresher"&&value !== null) ?<>
                                          
                                                <div style={{display:'flex',justifyContent:'flex-start',marginTop:'1.5rem'}}>  
                                                {workExperience.length <= 4 &&  <ButtonCompo title={"+ Add Experience Details"}  style={{border:'1px solid #0086ff',color:'#0086ff',}} onClick={() => {
                                               if (workExperience.length <= 4) {
                                                                                        let temp = [...workExperience];
                                                                                        temp.push({
                                                                                            companyName: "",
                                                                                            startDate: "",
                                                                                            endDate: "",
                                                                                            companyName_HRVerified: false,
                                                                                            startDate_HRVerified: false,
                                                                                            endDate_HRVerified: false,
                                                                                        })
                                                                                        setWorkexperience(temp)
                                                                                    }
                                                }} />}
                                                </div></>:null}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "95%", marginTop: 10 }}>
                                                    <Button name={"Submit"} background={"#0DAC45"} fontColor={"#FFFFFF"} disabled={false} 
                                                    onclick={() => {
                                                        
                                                         if(checkOnPersonalDetailsFill() && checkOnDocumentDetailsFill()&& checkAcademicsDetailsFill()&& checkWorkexpDetailsFill() ){
                                                           if(checkOnFileUpload('Work Experience',5*workExperience?.length,true) && checkOnFileUpload('Academics',3,true) && checkOnFileUpload('Documents',4,true)){
                                                            setSubmitModal(true);
                                                           }
                                                        }
                                                        // onSavePatch(null,null,null,null,true) 
                                                        }} />
                                                        
                                                    <Button name={"Save"} background={"#0086FF"} fontColor={"#FFFFFF"} disabled={false} onclick={() => {
                                                        if(checkWorkexpDetailsFill() && checkOnFileUpload('Work Experience',5*workExperience?.length)){
                                                            onSavePatch()
                                                        }
                                                        }} />
                                                 
                                                </div>
                                            </>
                                            :null
                                           

                        }
            </div></>}
        </div>
    </div>
    <SubmitProfile setModal={setSubmitModal} modal={submitModal} SubmitHandler={SubmitHandler} />
    <ModalCompo
            contentStyle={{
                position: "absolute",
                right: 0,
                top: 0,
                paddingleft: 10,
                height:"100vh",
                borderRadius:"10px 0 0 10px"
              }}

              className={EditClasses.UploadModal}

                open={openModal}
                closableLeft={true}
                onCancel={() => setOpenModal(false)}
                childrenModStyle={{ justifyContent: 'start' }}
                header={
                    <div className={classes.modalHeader} style={{ paddingLeft: '15px',width:'40vh',display:'flex',flexDirection:'column',alignItems:'center',}}>
                        <h3>Upload Document</h3>
                        <p style={{ fontSize: '10px', color: '#FF7B7B' }}>Note: File size should be less than 10 MB</p>
                    </div>
                }>
                <div style={{width:'100%', }}>
                    <div style={{ color: '#ABABAB', fontSize: '10px' }}>Mandatory</div>
                    <div style={{overflowY: 'scroll', height: '82vh'}}>
                       {modalRender?.type!=="Work Experience"?
                    <UploadDocument uploadedFiles={uploadedFiles} onClose={()=>{setOpenModal(false)}} onSavePatch={onSavePatch} modalRender={modalRender} ></UploadDocument>:
                    <UploadDocumentMulti uploadedFiles={uploadedFiles} onClose={()=>{setOpenModal(false)}} onSavePatch={onSavePatch} modalRender={modalRender} />}
                    </div>
                    
                </div>
            </ModalCompo>
</div>
    )
}

export default FillProfile;